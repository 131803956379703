import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal } from '@mui/material/';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, .3)',
  },
  largeImage: {
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
    margin: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  closeIcon: {
    fill: '#fff',
  },
});

const LightboxImage = ({ src, className, alt }) => {
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    /*
     * To prevent blank images from showing up in the ui, preload them
     * and check for a valid width and height before displaying.
     */
    const img = new Image();
    img.src = src;
    img.onload = () => {
      if ((img.width + img.height) > 2) {
        setLoaded(true);
      }
    };
  }, [src]);

  return loaded ?
    (
      <>
        <img src={src} className={className} alt={alt} onClick={() => setOpen(true)} />
        <Modal open={open} className={classes.modal}>
          <>
            <img
              src={src}
              className={classes.largeImage}
              alt={alt}
              onClick={() => setOpen(false)}
              />
            <div className={classes.closeBtn}>
              <IconButton onClick={() => setOpen(false)} size="large">
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
          </>
        </Modal>
      </>
    ) :
    null;
};

LightboxImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

LightboxImage.defaultProps = {
  src: null,
  className: '',
  alt: '',
};

export default LightboxImage;
