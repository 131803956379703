import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Api from '../utils/api';


export const sanitizeFilters = filters => (
  Object.keys(filters).reduce((a, c) => {
    const acc = a;
    if (filters[String(c)]) {
      acc[String(c)] = filters[String(c)];
    }

    return acc;
  }, {})
);

const buildUrl = ({ dashboard, filters }) => {
  const { host, protocol } = window.location;
  let url = `${dashboard}?embed_domain=${protocol}//${host}&sdk=2`;

  if (!isEmpty(filters)) {
    const sanitized = sanitizeFilters(filters);
    Object.keys(sanitized).forEach((filter) => {
      url += `&${filter}=${sanitized[String(filter)]}`;
    });
  }

  return url;
};

export const useLookerApi = ({ dashboard, filters, sid }) => {
  const [data, setData] = useState();
  const [target, setTarget] = useState();

  const BASE_URL = '/api/looker/sso_url';

  const url = buildUrl({ dashboard, filters });

  useEffect(() => {
    if (target !== dashboard) {
      setTarget(dashboard);
    }

    const fetchLookerApi = async () => {
      const res = await Api.post(BASE_URL, {
        target_path: url,
        salesforce_id: sid,
      }, [200], 30000).catch(() => null);

      setData(res?.data?.url);
    };

    fetchLookerApi();
  }, [target, sid]);

  return { iframeUrl: data };
};
