import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import moment from 'moment';

import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './exploits.module.scss';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import SearchActions from '../../actions/searchActions';
import Datapoints from '../../utils/datapoints';
import Table from '../widget/Table/Table';
import Common from '../../utils/common';
import InternalLink from '../utils/InternalLink';
import {
  CVSSV3Metrics,
  CVSSV2Metrics,
  onCopy,
  onExport,
  onRoute,
  linkToExploits,
} from './CVEs';

const MAX_RESULTS = 10000;

const Exploits = ({
  data,
  exploits,
  mentions,
}) => (
  <Grid
    fluid
    name="component.exploits"
    className={cx([style.base, style.exploits])}>
    {!data.has('id') &&
      <Row>
        <Col xs={12}>
          <CircularProgress size={25} thickness={2} />
        </Col>
      </Row>}
    {data.has('id') &&
      <div>
        <Row>
          <Col xs={12} className={style.header}>
            <div className={style.title}>
              <Icon>
                bug_report
              </Icon>
              <div
                className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
                name="exploits.header.title">
                {Text.Highlight(data.getIn(['highlight', 'title', 0]) ||
                  data.get('title'))}
              </div>
            </div>
            {data.hasIn(['sort_date']) &&
            <div>
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Date:</div>
              <div
                className={cx([style.h4, 'h4', style.cap, 'cap'])}
                name="exploits.cve.sort_date">
                {`${moment
                  .utc(data.getIn(['sort_date']))
                  .format('MMM DD, YYYY HH:mm')}`}
              </div>
              <Icon
                name="accounts.date"
                data-for="date.tooltip"
                data-tip="The date the exploit was last seen by Flashpoint."
                className={style.help}>
                help
              </Icon>
            </div>}
          </Col>
          <Col xs={12}>
            <Paper className={style.card}>
              <div className={style.body}>
                {data.getIn(['source_uri']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Source URI:</div>
                  <div>{Text.Highlight(data.getIn(['source_uri'], ''))}</div>
                </div>}
                <div className={style.container}>
                  <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>MITRE Data</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Description:</div>
                  <div>{data.getIn(['cve', 'mitre', 'body', 'text/plain'])}</div>
                </div>
                {data.getIn(['cve', 'nist']) &&
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>NVD Data</div>}
                <CVSSV3Metrics data={data} style={style}/>
                <CVSSV2Metrics data={data} style={style}/>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])} />
                <div>
                  <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>DDW Mentions</div>
                  {/* loading mentions */}
                  {!data.isEmpty() && mentions.isEmpty() &&
                  <Row>
                    <Col xs={12}>
                      <CircularProgress size={25} thickness={2} />
                    </Col>
                  </Row>}
                  {/* mentions loaded */}
                  {!data.isEmpty() && !mentions.isEmpty() && !mentions.get('data').isEmpty() &&
                  <Table
                    pagination
                    header
                    onOffset={skip => SearchActions.search('exploits.inline', data.get('fpid'), false, { skip, cve_title: data.get('title') }, true)}
                    onClick={(_, v, k) => onRoute(v.getIn(['data', k]))}
                    results={fromJS({
                      total: mentions.get('total'),
                      data: mentions
                        .get('data')
                        .filter(v => v.hasIn(['sort_date']))
                        .sortBy(v => -(moment.utc(v.getIn(['sort_date'], '')).unix())),
                      skip: mentions.get('skip') })}
                    values={fromJS([
                        { ...Datapoints.all.sort_date, style: { width: '75px' } },
                        { ...Datapoints.all.site_actor, style: { width: '25%' }, tooltip: true },
                        { ...Datapoints.all.container, tooltip: true },
                      ])}
                  />}
                  {/* no mentions */}
                  {data.has('id') && mentions.get('data') && mentions.get('data').isEmpty() &&
                  <Row>
                    <Col xs={12}>
                      <Paper className={cx([style.card, style.disabled])}>
                        <div className={style.empty}><div>No DDW Mentions</div></div>
                      </Paper>
                    </Col>
                  </Row>}
                </div>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])} />
                <div className={style.table} />
                {data.getIn(['cve', 'nist', 'vulnerability_types']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Vulnerability: </div>
                  <div>{data
                      .getIn(['cve', 'nist', 'vulnerability_types'])
                      .map((v) => {
                        const vulnerabilityNum = v.split('-').pop();
                        return (vulnerabilityNum === 'Other'
                          ? <div>{v}</div>
                          :
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://cwe.mitre.org/data/definitions/${vulnerabilityNum}.html`}
                            onClick={e => Common.Events.DispatchExternalLinkEvent(`https://cwe.mitre.org/data/definitions/${vulnerabilityNum}.html`, e)}
                            key={v}
                            className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal',
                              style.a, 'a', style.vulnerability])}>
                            {v}
                          </a>);
                      })}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'configurations']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>CPEs: </div>
                  <div>{data
                      .getIn(['cve', 'nist', 'configurations'])
                      .map(v => (
                        <InternalLink
                          key={v.get('cpe23_uri')}
                          to={{
                            pathname: '/home/search/exploits',
                            query: { query: v.get('cpe23_uri', '') },
                          }}
                          className={cx([style.a, 'a'])}>
                          {v.get('cpe23_uri')}
                        </InternalLink>))}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'products']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Products:</div>
                  <div>{Text.Highlight(data
                      .getIn(['cve', 'nist', 'products'])
                      .map(v => `${Text.Sentence(v.get('vendor_name'))} ${Text.Sentence(v.get('product_name'))}`)
                      .join('<br />'))}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'references']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>References:</div>
                  <div>{Text.Highlight(data
                      .getIn(['cve', 'nist', 'references'])
                      .map(v => `<b>Type</b> ${(v.get('tags') || []).join(', ') || '-'}<br />
                        <b>URL</b> <a class="a" target="_blank">${v.get('url') || '-'}</a>`)
                      .join('<br /><br />'))}
                  </div>
                </div>}
                {!exploits.isEmpty() && !exploits.get('data').isEmpty() &&
                <div>
                  <div className={style.header}>
                    <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Related Exploits:</div>
                    <div className={style.tools}>
                      <Icon
                        name="table.tools.export"
                        data-for="export.tooltip"
                        data-tip="Export records"
                        onClick={() => onExport(
                          'cves.meta',
                          data.get('fpid'),
                          [
                            Datapoints.cves.observed,
                            Datapoints.cves.site_uri,
                            Datapoints.cves.source_uri,
                        ])}>
                        get_app
                      </Icon>
                    </div>
                  </div>
                  <div
                    data-tip="Click to copy source URI to clipboard"
                    data-for="exploit.tooltip">
                    <Table
                      pagination
                      skipSlice
                      header
                      onClick={(_, v, k) => onCopy(
                        `https://${v.getIn(['data', k, 'site', 'source_uri'])}/${v.getIn(['data', k, 'source_uri'])}`)}
                      onOffset={skip => SearchActions.search('exploits.meta', data.get('fpid'), false, { skip, cve_title: data.get('title') }, true)}
                      results={fromJS({
                        data: exploits.get('data'),
                        total: exploits.get('count') <= MAX_RESULTS ? exploits.get('count') : MAX_RESULTS,
                        skip: exploits.get('skip'),
                        link: linkToExploits(),
                      })}
                      widget={fromJS({ limit: 10 })}
                      values={fromJS([
                        Datapoints.exploits.created,
                        Datapoints.exploits.source_uri,
                      ])}
                    />
                  </div>
                </div>}
              </div>
            </Paper>
          </Col>
        </Row>
      </div>}
    {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
    <ReactTooltip id="cve.tooltip" html place="bottom" effect="solid" />
    <ReactTooltip id="date.tooltip" html place="bottom" effect="solid" />
    <ReactTooltip id="share.tooltip" place="left" effect="solid" />
    <ReactTooltip id="exploit.tooltip" place="right" effect="solid" />
    <ReactTooltip id="export.tooltip" place="right" effect="solid" />
  </Grid>
  );

Exploits.propTypes = {
  data: PropTypes.object,
  exploits: PropTypes.object,
  mentions: PropTypes.object,
};

Exploits.defaultProps = {
  data: map(),
  exploits: map(),
  mentions: list(),
};

export default Exploits;
