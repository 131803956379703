import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
} from '@mui/material';

import Text from '../../utils/text';

const useStyles = makeStyles(() => ({
  checkboxButton: props => ({
    '&.MuiButton-contained': {
      backgroundColor: props.color,
      border: `1px solid ${props.color}`,
      '&:hover': {
        backgroundColor: darken(props.color, 0.5),
        border: `1px solid ${props.color}`,
      },
      '& .MuiButton-label': {
        color: `${Text.Color(0, '', true, props.color)} !important`,
      },
    },
    '&.MuiButton-outlined': {
      padding: '0 1rem',
    },
  }),
}));

const CheckboxButton = ({
  color,
  disabled,
  initialState,
  label,
  tooltip,
  onClick,
}) => {
  const classes = useStyles({ color });
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(initialState);
  }, [initialState]);

  return (
    <Button
      data-for="field.tooltip"
      data-tip={tooltip}
      data-testid={`checkboxbutton.${label?.toLowerCase()?.replace(/\s/, '-')}`}
      className={color ? classes.checkboxButton : null}
      variant={isChecked ? 'contained' : 'outlined'}
      disableElevation
      disabled={disabled}
      onClick={() => onClick(!isChecked)}>
      {label}
      <ReactTooltip html id="field.tooltip" place="bottom" effect="solid" />
    </Button>
  );
};

CheckboxButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  initialState: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};

CheckboxButton.defaultProps = {
  color: '#fff',
  disabled: false,
  initialState: false,
  label: '',
  tooltip: '',
  onClick: null,
};

export default CheckboxButton;
