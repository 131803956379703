import Reflux from 'reflux';

const MetaDataActions = Reflux.createActions({
  addTagAssociations: {},
  deleteTagAssociations: {},
  getChannel: {},
  getChannelTags: {},
  getAllTags: { asyncResult: true },
  getChannels: {},
  updateChannels: {},
  parseFilters: {},
  set: {},
});

export default MetaDataActions;
