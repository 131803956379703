import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';

import makeStyles from '@mui/styles/makeStyles';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  CircularProgress,
  Collapse,
  Icon,
  FormControl,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Select,
} from '@mui/material';

import style from './knowledgebase.module.scss';
import SearchActions from '../../actions/searchActions';
import Invalid from '../utils/Invalid/Invalid';
import Pager from '../utils/Pager';
import Text from '../../utils/text';
import Card from './Card';
import Messages from '../../constants/Messages';
import History from '../../utils/history';
import easyMemo from '../utils/Memoize';

const useStyles = makeStyles(theme => ({
  knowledgebase: {
    '& .MuiCollapse-root': {
      background: 'white',
      border: `1px solid ${theme.palette.primary}`,
      maxHeight: '25rem',
      overflowX: 'hidden',
      overflowY: 'scroll',
      position: 'absolute',
      top: '4.5rem',
      zIndex: 1,
    },
    '& .MuiFormControl-root': {
      margin: `0 0 0 ${theme.spacing(1)}`,
      width: '25rem',
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
}));

const childUseStyles = makeStyles(() => ({
  card: {
    'min-height': '225px',
  },
}));

const toc = (report = map()) => {
  // extract table of contents
  const search = /back to top|((background|operation|indicators)<\/span>)/ig;
  const content = report.get('body');
  const index = content.search(search);
  const anchor = [...content
    .substring(0, index)
    .matchAll(/<h[125].*?<a.*?href="#h\..*?>(.*?)</ig)]
    .map(v =>
      ({ type: v.slice(0).join().substr(1, 2),
        value: Text.unescapeHTML(v.slice(1).join().trim()),
        path: Text.stripEntities(v.slice(1).join().trim()),
      }));
  return anchor;
};

const KBCard = easyMemo(({
  k,
  report,
  data,
  userHistory,
  childClasses,
  icons,
  cdn,
  expand,
  onToggle,
  onSection,
}) =>
   (
     <div
       key={`${k}.${report.get('id')}`}
       className={style.card}
       style={{ zIndex: data.get('data').size - k }}>
       <Card
         age
         mini
         className={childClasses.card}
         userHistory={userHistory}
         report={report}
         icon={icons(data.getIn(['data', 0, 'tags'], list()).toJS())}
         cdnTag={cdn ? '&cdn=true' : ''}
         statusbar={
           <List
             className={cx([style.footer, expand?.includes(report.get('id')) && style.expand])}>
             {toc(report).length === 0 ? null :
             <ListItem
               onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggle(report.get('id'));
            }}>
               <ListItemText
                 primary="Table of Contents" />
               {expand.includes(report.get('id')) ? <ExpandLess /> : <ExpandMore />}
             </ListItem>}
             <Collapse
               unmountOnExit
               timeout="auto"
               in={expand?.includes(report.get('id'))}>
               <List>
                 {Object.entries(toc(report))
                .map(([_k, v]) => (
                  <ListItem
                    key={`${_k}.${v.value}`}
                    value={v.value}
                    className={cx([style.anchor, style[v.type]])}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onSection(report.get('id'), v.path);
                    }}>
                    <ListItemText primary={Text.Sentence(v.value.replace(/&.*?;/ig, ''))} />
                  </ListItem>))}
               </List>
             </Collapse>
           </List>} />
     </div>
));

KBCard.propTypes = {
  k: PropTypes.number.isRequired,
  report: PropTypes.object.isRequired,
  data: PropTypes.object,
  userHistory: PropTypes.object,
  childClasses: PropTypes.object,
  icons: PropTypes.object,
  cdn: PropTypes.object,
  expand: PropTypes.object,
  onToggle: PropTypes.isRequired,
  onSection: PropTypes.isRequired,
};

KBCard.defaultProps = {
  data: {},
  userHistory: {},
  childClasses: {},
  icons: {},
  cdn: {},
  expand: {},
  onToggle: PropTypes.function,
  onSection: PropTypes.function,
};

const KnowledgeBase = ({
  cdn,
  userHistory,
  reports,
}) => {
  const classes = useStyles();
  const childClasses = childUseStyles();
  const [data, setData] = useState();
  const [expand, setExpand] = useState([]);
  const [total, setTotal] = useState();
  const [filters, setFilters] = useState({
    skip: 0,
    limit: 16,
    text: '',
    tag: '',
    type: 'knowledgebase',
  });

  const onSearch = (event, values = filters) => {
    if (event) event.preventDefault();
    setData();
    setFilters(values);
    SearchActions.searchIntel(
      values?.tag,
      values?.type,
      values?.skip,
      values?.limit,
      values?.text,
    );
  };

  const onSection = (id = '', value = '') => {
    if (!id || !value) return;
    const anchor = value.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');

    History.navigateTo(`/home/intelligence/reports/report/${id}/featured/${anchor}`);
  };

  const onToggle = (id = '') => {
    if (!id) return;
    setExpand(current => (current.includes(id)
      ? current.filter(v => v !== id)
      : current.concat(id)));
  };

  const icons = (tags = []) => {
    const icon = {
      malware: 'bug_report',
      countries: 'language',
      actors: 'account_circle',
      tactics: 'bug_report',
      events: 'event',
      communities: 'people',
    };

    return filters?.tag
      ? icon[filters?.tag]
      : tags
        .filter(t => icon[t.toLowerCase()])
        .map(t => icon[t.toLowerCase()])
        .slice(0, 1)
        .join();
  };

  const tags = ['actors', 'countries', 'communities', 'events', 'tactics'];

  useEffect(() => {
    if (reports.isEmpty()) return;
    setData(reports);
    setTotal(reports.get('total'));
  }, [reports]);

  useEffect(() => {
    onSearch();
    return () => {
      setData();
      setExpand();
      setTotal();
    };
  }, []);

  return (
    <Grid fluid className={cx([style.knowledgebase, classes.knowledgebase])}>
      <Row>
        <Col xs={12} className={style.header}>
          <div className={style.controls}>
            <div className="pager">
              <Pager
                paginated
                filters={fromJS(filters)}
                data={fromJS({ total })}
                onFilter={v => onSearch(null, v.toJS() || {})} />
            </div>
            <FormControl variant="outlined">
              <Select
                className={style.dropdown}
                value={filters?.tag}
                onChange={event =>
                  onSearch(event, { ...filters, skip: 0, tag: event.target.value })}>
                <ListItem value="">All Tags</ListItem>
                {tags.map(v => (
                  <ListItem
                    key={v}
                    value={v}>
                    {Text.Sentence(v)}
                  </ListItem>))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <OutlinedInput
                fullWidth
                notched
                data-lpignore="true"
                value={filters?.text}
                placeholder="Quick Filter..."
                onChange={event => setFilters(current => ({
                  ...current,
                  text: event.target.value.replace(String.fromCharCode(92), ''),
                }))}
                onKeyPress={event => (event.key === 'Enter'
                  ? onSearch(event, { ...filters, skip: 0 })
                  : null)}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon
                      color="primary"
                      onClick={event => onSearch(event, { ...filters, skip: 0 })}>
                      search
                    </Icon>
                  </InputAdornment>
                )}
                endAdornment={filters?.text && (
                <InputAdornment position="end">
                  <Icon
                    color="primary"
                    onClick={event => onSearch(event, { ...filters, text: '' })}>
                    close
                  </Icon>
                </InputAdornment>
                )} />
              <FormHelperText />
            </FormControl>
          </div>
        </Col>
        {!data &&
        <CircularProgress />}
        {data && data.get('data', list()).isEmpty() &&
        <Invalid
          inline
          icon="notifications"
          title={Messages.SearchEmpty}
          help={['Try modifying your filters']} />}
        {data &&
        <Col xs={12} className={style.content}>
          {data
          .get('data', list())
          .sort((a, b) => a.get('version_posted_at') - b.get('version_posted_at'))
          .entrySeq()
          .map(([k, report]) => (
            <KBCard
              memoKey={`${k}.${report.get('id')}-${expand?.includes(report.get('id'))}`}
              key={`${k}.${report.get('id')}`}
              k={k}
              report={report}
              data={data}
              userHistory={userHistory}
              childClasses={childClasses}
              icons={icons}
              cdn={cdn}
              expand={expand}
              onToggle={onToggle}
              onSection={onSection}
            />
          ))}
        </Col>}
      </Row>
    </Grid>
  );
};

KnowledgeBase.propTypes = {
  cdn: PropTypes.bool,
  userHistory: PropTypes.object,
  reports: PropTypes.object,
};

KnowledgeBase.defaultProps = {
  cdn: true,
  userHistory: list(),
  reports: map(),
};

export default KnowledgeBase;
export const exportedForTesting = { toc };
