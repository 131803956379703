import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  orgNameInput: {
    marginTop: '3rem',
  },
  exampleText: {
    marginLeft: '1rem',
  },
});

export interface OrgDialogProps {
  /** Set to `true` to open the dialog, `false` to close. */
  open: boolean;

  /** Default name to populate the dialog with. */
  defaultValue?: string;

  /** Called when the user clicks the 'cancel' button. */
  onCancel?: () => void;

  /** Called when the user clicks the 'apply' button. */
  onApply?: (value: string) => void;
}

const OrgNameLength = 2; // Short for names like "LG"
const OrgNameMax = 3000;

/** This dialog allows the user to edit their organization's name as used by the Exposure Dashboard */
export const OrgDialog = ({
  open,
  defaultValue,
  onCancel,
  onApply,
}: OrgDialogProps): JSX.Element => {
    const [orgName, setOrgName] = useState(defaultValue);
    const [err, setErr] = useState(null);
    const styles = useStyles();

    const validate = (value: string): boolean => {
      if (!value || value.length <= OrgNameLength) {
        setErr('Please enter a valid organization name or search string');
        return false;
      }
      if (value.length > OrgNameMax) {
        setErr(`Please enter an organization name that is less than ${OrgNameMax} characters`);
        return false;
      }
      setErr(null);
      return true;
    };

    const handleApply = (): void => {
      if (!validate(orgName)) {
        return;
      }
      if (onApply) {
        onApply(orgName);
      }
    };

    const handleCancel = (): void => {
      if (onCancel) {
        onCancel();
      }
    };

    useEffect(() => {
      if (!validate(defaultValue)) {
        return;
      }
      setOrgName(defaultValue);
    }, [defaultValue]);

    return (
      <Dialog open={open} className="orgDialog">
        <DialogTitle>Organization Name</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            We will use this search query to populate Organization Mentions,{' '}
            Top Sources, Actors Mentioning and Top Actors Widgets.
          </Typography>
          <TextField
            value={orgName}
            onChange={e => setOrgName(e?.target?.value || '')}
            error={err}
            helperText={err}
            className={styles.orgNameInput}
            label="Organization Name"
            placeholder="Please enter your organization name parameters"
            variant="outlined"
            />
          <Typography className={styles.exampleText} variant="caption">
            (Example: Flashpoint | "Flashpoint Intel" | "Flashpoint Intelligence" | "fp.tools")
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCancel}
            className="cancelBtn"
            >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={handleApply}
            className="applyBtn"
            >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

OrgDialog.defaultProps = {
  defaultValue: '',
  onCancel: () => false,
  onApply: () => false,
};
