import Api from '../../../utils/api';

const KEYWORD_CLASS_URI = '/ui/v4/alerts/keyword_classes';


const KeywordClasses = async () => Api
  .get(KEYWORD_CLASS_URI)
  .then(res => res.data);

export default {
  KeywordClasses,
};
