import { cachedPost } from '../../../utils/api';
import { TopSourceDates } from '../../../constants/Dates';
import { SavedSearch } from '../../../core/types/SavedSearch';
import { ExpoDashFilters } from '../../../core/types/ExpoDashFilters';
import { savedSearchToQuery, filtersToQuery } from './utils';
import { SearchUrl } from '../../../stores/searchStore';
import { reports } from './makeCopyPastedQueryTypes';

const reportsSearchType = (savedSearch:SavedSearch) => savedSearch?.type?.searchType === 'reports';

export const getLabel = (filters: ExpoDashFilters) => {
  const { date } = filters;
  const dateString = (date?.toLowerCase() as keyof typeof TopSourceDates);
  // eslint-disable-next-line security/detect-object-injection
  const { label } = date ? TopSourceDates[dateString] : { label: '' };
  return label;
};

const makeExpoDashQuery = ({ savedSearch, filters, aggregations }:
  {savedSearch: SavedSearch, filters: ExpoDashFilters, aggregations?: object}) => {
  const query = savedSearch ?
    savedSearchToQuery(savedSearch, filters) :
    filtersToQuery(filters);

  if (reportsSearchType(savedSearch)) {
    return query;
  }

  return {
    size: 0,
    traditional_query: true,
    aggregations,
    query: query?.query,
    fields: query?.fields,
  };
};


/**
 * This function transforms Raw Reports Data into the format returned by other /search requests
 * For example, we might get the following object from /reports
 * {
 *   count: 10
 *   data: [{id: "Fu5Id4ABohu42BzRIXtp",…}, {id: "_S-YKYABDZl-m4kLaLq3",…},…]
 *   limit: 10
 *   skip: 0
 *   total: 108
 * }
 * And transform it to the typical search results format
 * {
 *   aggregations: {,…}
 *   hits: {hits: [], max_score: null, total: 5354}
 *   num_reduce_phases: 2
 *   timed_out: false
 *   took: 7553
 * }
*/
const transformReportsData = (data:{data: unknown[], total: number}) => (
  {
    hits: {
      hits: data?.data,
      total: data?.total,
    },
  }
);

type StandardTopSourceKeys = Exclude<keyof typeof TopSourceDates, 'custom'>;

const makePrevNowFilters = (filters: ExpoDashFilters) => {
  const { date } = filters;
  const dateString = (date?.toLowerCase() as StandardTopSourceKeys);
  // eslint-disable-next-line security/detect-object-injection
  const { now, previous } = TopSourceDates[dateString];
  const nowFilters = { ...filters, ...now };
  const prevFilters = { ...filters, ...previous };
  return { nowFilters, prevFilters };
};

const reportsPromises = ({ savedSearch, filters }:
  {savedSearch: SavedSearch, filters: ExpoDashFilters}) => {
  const { nowFilters, prevFilters } = makePrevNowFilters(filters);
  const currentQuery = makeExpoDashQuery({ savedSearch, filters: nowFilters });
  const lastQuery = makeExpoDashQuery({ savedSearch, filters: prevFilters });

  return Promise.all([
    reports({ reportQuery: currentQuery, filters: nowFilters }),
    reports({ reportQuery: lastQuery, filters: prevFilters }),
  ]).then(([a, b]) => [
    transformReportsData(a),
    transformReportsData(b),
  ]);
};

export const makeCurrentAndLastQueries = async ({ savedSearch, filters, aggregations }:
  {savedSearch: SavedSearch, filters: ExpoDashFilters, aggregations: object}) => {
  if (reportsSearchType(savedSearch)) {
    return reportsPromises({ savedSearch, filters });
  } else {
    const { nowFilters, prevFilters } = makePrevNowFilters(filters);

    const currentQuery = makeExpoDashQuery({ savedSearch, filters: nowFilters, aggregations });
    const lastQuery = makeExpoDashQuery({ savedSearch, filters: prevFilters, aggregations });

    return Promise.all([
      cachedPost(SearchUrl, currentQuery, [200, 204], 30000, {}, true),
      cachedPost(SearchUrl, lastQuery, [200, 204], 30000, {}, true),
    ]).then(([a, b]) => [a?.data, b?.data]);
  }
};
