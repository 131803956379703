import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import style from './credentials.module.scss';

const HostData = ({ breach }) => {
  const cookies = breach.get('cookies')
    ?.groupBy(item => (item.get('affected_domain') || item.get('domain')).concat(' - ', item.get('path')))
    .map(group => group.map(v => ({ [v.get('key')]: v.get('value') })))
    .toJS() || list();

  return (
    <div>
      <Accordion className={style.resultaccordion}>
        <AccordionSummary
          className={style.accordionsummary}
          expandIcon={<ExpandMoreIcon />}>
          <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Host Data:</div>
        </AccordionSummary>
        <AccordionDetails className={style.accordiondetails}>
          {breach.get('infected_host_attributes') &&
          <Grid className={style.accordiondetails}>
            <Row className={style.accordionrow}>
              <Col xs={6}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>Additional Extracted Metadata:</div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Infected Host FPID:</div>
                  {breach.getIn(['infected_host_attributes', 'fpid']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>IP Address:</div>
                  {breach.getIn(['infected_host_attributes', 'ip']) || '--'}
                </div>
              </Col>
              <Col xs={6}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>Infection/Malware Data:</div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Malware Family</div>
                  {breach.getIn(['infected_host_attributes', 'malware', 'family']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Malware Version:</div>
                  {breach.getIn(['infected_host_attributes', 'malware', 'version']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Scan Timestamp:</div>
                  {breach.getIn(['infected_host_attributes', 'malware', 'scanned_at', 'date-time']) || '--'}
                </div>
              </Col>
            </Row>
            <Row className={style.accordionrow}>
              <Col xs={6}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>Location:</div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Continent:</div>
                  {breach.getIn(['infected_host_attributes', 'location', 'continent_name']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Country:</div>
                  {breach.getIn(['infected_host_attributes', 'location', 'country_name']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>State:</div>
                  {breach.getIn(['infected_host_attributes', 'location', 'subdivision_1_name']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>County:</div>
                  {breach.getIn(['infected_host_attributes', 'location', 'subdivision_2_name']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>City:</div>
                  {breach.getIn(['infected_host_attributes', 'location', 'city_name']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>GPS:</div>
                  {`${breach.getIn(['infected_host_attributes', 'location', 'latitude']) || ''}, ${breach.getIn(['infected_host_attributes', 'location', 'longitude']) || ''}`}<br/>
                  {breach.getIn(['infected_host_attributes', 'location', 'accuracy_radius']) &&
                    `Accuracy Radius: ${breach.getIn(['infected_host_attributes', 'location', 'accuracy_radius'])}`}
                </div>
              </Col>
              <Col xs={6}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>ISP:</div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Name</div>
                  {breach.getIn(['infected_host_attributes', 'isp', 'isp']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Organization:</div>
                  {breach.getIn(['infected_host_attributes', 'isp', 'organization']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>As Organization:</div>
                  {breach.getIn(['infected_host_attributes', 'isp', 'autonomous_system_organization']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>As Number:</div>
                  {breach.getIn(['infected_host_attributes', 'isp', 'autonomous_system_number']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Connection Type:</div>
                  {breach.getIn(['infected_host_attributes', 'isp', 'connection_type']) || '--'}
                </div>
              </Col>
            </Row>
            <Row className={style.accordionrow}>
              <Col xs={6}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>Machine Information:</div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Operating System:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'os']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>CPU:</div>
                  {/* may need to concat with commas */}
                  {breach.getIn(['infected_host_attributes', 'machine', 'cpu']) || '--'}
                </div>
                <div className={style.table}>
                  {/* may need to concat with commas */}
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>GPU:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'gpu']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>RAM:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'ram']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Resolution:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'resolution']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Local Username:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'user']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Language:</div>
                  {/* may need to concat with commas */}
                  {breach.getIn(['infected_host_attributes', 'machine', 'language']) || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Computer Name:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'extra'])?.find(v => v.get('key') === 'computer name')?.get('value') || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>Path:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'extra'])?.find(v => ['bin_path', 'exe_path', 'Exe Path', 'path', 'Working Path'].includes(v.get('key')))?.get('value') || '--'}
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.subtitle])}>File Location:</div>
                  {breach.getIn(['infected_host_attributes', 'machine', 'extra'])?.find(v => v.get('key') === 'filelocation')?.get('value') || '--'}
                </div>
              </Col>
            </Row>
          </Grid>}
          {breach.get('cookies') &&
          <Accordion className={style.resultaccordion}>
            <AccordionSummary className={style.accordionsummary} expandIcon={<ExpandMoreIcon />}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Cookies:</div>
            </AccordionSummary>
            <AccordionDetails className={style.accordiondetails}>
              {Object.entries(cookies).map(cookie => (
                <Grid className={style.detailsgrid}>
                  <Row className={style.detailstitle} xs={12}>{cookie[0]}</Row>
                  {cookie[1].map(v => (
                    <Row>
                      <Col xs={4}>{Object.keys(v)}</Col>
                      <Col xs={8}>{Object.values(v)}</Col>
                    </Row>
                  ))}
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>}
          {breach.getIn(['infected_host_attributes', 'installed_software']) &&
          <Accordion className={style.resultaccordion}>
            <AccordionSummary className={style.accordionsummary} expandIcon={<ExpandMoreIcon />}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Installed Software:</div>
            </AccordionSummary>
            <AccordionDetails className={style.accordiondetails}>
              <Grid className={style.detailsgrid}>
                <Row>
                  <Col xs={7}><b>Application</b></Col>
                  <Col xs={5}><b>Version</b></Col>
                </Row>
                {breach.getIn(['infected_host_attributes', 'installed_software']).map(v => (
                  <Row>
                    <Col xs={7}>{v.get('name')}</Col>
                    <Col xs={5}>{v.get('version') || '--'}</Col>
                  </Row>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

HostData.propTypes = {
  breach: PropTypes.object,
};

HostData.defaultProps = {
  breach: map(),
};


export default HostData;
