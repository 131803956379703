import * as React from 'react';
import { ReactNode } from 'react';

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

export const TabPanel = ({ children, value, index }: TabPanelProps): JSX.Element => (
  <div hidden={value !== index}>
    {(value === index) && children}
  </div>
);
