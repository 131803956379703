import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import FileSaver from 'file-saver';
import { List as list } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { Button, CircularProgress } from '@mui/material';

import style from './links.module.scss';
import Text from '../../utils/text';


const Links = ({ data }) => (
  <Grid
    fluid
    name="component.syntax"
    className={cx([style.base, style.links])}>
    <Row>
      <Col xs={12}>
        {data.isEmpty() && <CircularProgress />}
        {!data.isEmpty() &&
        ['guides'].map(type => (
          <div key={type} className={style.card}>
            <div className={cx([style.h0, 'h0', style.raj, 'raj'])}>{Text.Sentence(type)}</div>
            <div className={style.body}>
              {data
                .map(v => (
                  <div key={v.get('label')} className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>{v.get('label')}:</div>
                    <Button
                      variant="contained"
                      className={style.button}
                      onClick={() => FileSaver.saveAs(v.get('link'), v.get('label'), { type: 'text/plain;charset=utf-8', autoBom: true })}>
                      {v.get('link').includes('//') ? 'View' : 'Download'}
                    </Button>
                  </div>))}
            </div>
          </div>))}
      </Col>
    </Row>
  </Grid>);

Links.propTypes = {
  data: PropTypes.object,
};

Links.defaultProps = {
  data: list(),
};

export default Links;
