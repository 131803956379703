import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map as map } from 'immutable';
import { CircularProgress, Drawer, Icon, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Icons from '../../../constants/Icons';
import Query from './query';
import iocTransform from '../../../utils/transform/iocs';

const useStyles = makeStyles(theme => ({
  main: {
    zIndex: '1300',
    '& .MuiDrawer-paper': {
      width: '600px',
      backgroundColor: theme.palette.navigation.main, // midnight blue
    },
    '& .MuiPaper-root': {
      padding: 0,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },
    '& .MuiIcon-fontSizeSmall span[data-tip="VirusTotal"]': {
      fontSize: '1rem',
    },
    '.table.loading .spinner': {
      alignSelf: 'center',
      display: 'inline-flex',
      margin: 'auto',
    },
    '.table .spinner': {
      display: 'none',
      height: '100%',
      width: '100%',
    },
  },
  vtTitle: {
    width: '100%',
    display: 'flex',
  },
}));

const Virustotal = ({ rowData, type, tableData, title }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [iframeContent, setIframeContent] = useState('');
  const [vtIsLoading, setVtIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState();
  const [show, setShow] = useState(true);
  const styles = useStyles();

  useEffect(() => {
    const iocTransformResult = iocTransform.extractTypeLabelFromPayload(rowData.toJSON());
    if (type === 'enrichments' &&
       rowData?.getIn(['enrichments', 'domains', 'data'])?.size === 0 &&
       rowData?.getIn(['enrichments', 'ips', 'data'])?.size === 0
      ) { setShow(false); }

    if (type === 'ioc' &&
        (rowData?.getIn(['Event', 'Attribute']) === undefined || // Check if Attribute is defined
        rowData?.getIn(['Event', 'Attribute', 0, 'type']) === undefined) && // Check if Attribute returns an array with undefined types
        (iocTransformResult !== 'sha1' && iocTransformResult !== 'sha256' && iocTransformResult !== 'sha512' && iocTransformResult !== 'md5') // Check if indicator is defined as SHA or MD5
        ) {
      setShow(false); } // Logic to determine if VT indictor exist
    else {
      const array = [];
      if (iocTransformResult) { array.push(
        {
          label: iocTransformResult,
          value: iocTransform.extractValueLabelFromPayload(rowData.toJSON()) },
        );
      } // convert basetype to consistent data type 1
      if (rowData?.getIn(['Event', 'Attribute'])) {
        rowData?.getIn(['Event', 'Attribute'])?.forEach((basetypes) => {
          if (basetypes.get('type')) {
            array.push({ label: basetypes.get('type'), value: basetypes.get('value') });
          }
        });
      }// convert basetype to consistent data type 2
      setData(array);
    }// Restructure basetype data to be uniformed
  }, [rowData]);

  // (parts of this block temp disabled, used for context menu)
  // const handleClick = async (event, value) => {
  const handleClick = async (event) => {
    event.preventDefault();
    setVtIsLoading(true);
    setDrawerOpen(true);

    const baseTypeValue = tableData || data.find(x => ['sha1', 'sha256', 'sha512', 'md5'].includes(x.label)).value;
    // switch (type) {
    //   case 'ioc':
    //     baseTypeValue = value;
    //     break;
    //   case 'enrichments':
    //     baseTypeValue = event.target.id;
    //     break;
    //   default: baseTypeValue = '';
    // }

    try {
      const vtHTMLurl = await Query.fetchVtHtmlUrl(baseTypeValue, setAlert);
      const vtHTMLcontents = await Query.fetchVtHtml(vtHTMLurl);
      setIframeContent(vtHTMLcontents);
    } catch (err) {
      setAlert(err.msg);
    } finally {
      setVtIsLoading(false);
    }
  };

  const closeWidget = (e) => {
    // protect from CORS script execution
    if (e.origin !== window.origin) return;
    if (e.data === 'vtCloseWidget') setDrawerOpen(false);
  };
  window.onmessage = e => closeWidget(e);

  return (
    <div id="vt-wrapper" style={{ display: show ? null : 'none' }}>
      <div
        onClick={(e) => {
        handleClick(e);
      }}
        className={styles.vtTitle}
      >
        <div>{title}</div>
        <Icon
          data-for="global.tooltip"
          data-tip="VirusTotal"
          style={{ fontSize: '1.8rem', marginLeft: '1.5rem' }}
        >
          <div dangerouslySetInnerHTML={{ __html: Icons.virustotal }} />
        </Icon>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          }}
        className={styles.main}
      >
        {vtIsLoading
          ? (
            <div>
              <CircularProgress size="8rem" />
            </div>
          ) : (
            <iframe
              srcDoc={iframeContent}
              title="VirusTotal Augment"
              style={{ width: '100%', height: '100%', border: '0px none', display: 'block' }}
              frameBorder="0" />
          )
        }
      </Drawer>
      {alert &&
      <Alert
        severity="info"
        onClose={() => setAlert()}>
        {alert}
      </Alert>}
    </div>
  );
};

Virustotal.propTypes = {
  rowData: PropTypes.object,
  tableData: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

Virustotal.defaultProps = {
  rowData: map(),
  tableData: '',
  title: '',
  type: '',
};
export default Virustotal;
