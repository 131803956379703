import React from 'react';

import Api from '../../../utils/api';

const DmUri = '/ui/v4/domain-monitoring';
const dev = '_self' in React.createElement('div') ? '/dev' : '';

const Query = {
  getIncident: id => Api.get(`${DmUri}${dev}/incidents/${id}`).then(request => request.data),
  getBrand: id => Api.get(`${DmUri}${dev}/brands/${id}`).then(request => request.data),
  getMessages: id => Api.get(`${DmUri}${dev}/incidents/${id}/messages`).then(request => request.data),
  postMessage: (id, message) => Api.post(`${DmUri}${dev}/incidents/${id}/messages`, message, [200, 201]),
  getEvents: id => Api.get(`${DmUri}${dev}/incidents/${id}/events`).then(request => request.data),
  uploadFile: (id, file) => Api.post(`${DmUri}${dev}/incidents/${id}/upload`, file, [200, 201]),
  postTakedown: id => Api.post(`${DmUri}${dev}/incidents/${id}/takedown`, {}, [200, 201]),
  getImageSrc: (id, imageSrc) => `${DmUri}${dev}/incidents/${id}/${imageSrc}`,
  getBrandLimits: (sid = '') => {
    if (!sid) return {};
    return Api.get(`${DmUri}${dev}/organizations/${sid}`)
      .then(res => (res.ok ? res?.data : {}))
      .catch(() => ({}));
  },
};

export default Query;

