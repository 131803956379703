import React from 'react';

import styles from './loader.module.scss';

export const Loader = ({ className, testId, size = 'xs', type = 'spinner', visible }: LoaderProps): JSX.Element => (
  <div
    className={[styles.loader, styles[String(size)], styles[visible ? '' : 'hidden'], styles[String(type as LoaderType)], className].join(' ')}
    data-testid={testId}
  />
);

Loader.defaultProps = {
  className: '',
  size: 'xs',
  testId: '',
  visible: true,
};
