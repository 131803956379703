import React from 'react';

import Api from '../../utils/api';

const dev = ('_self' in React.createElement('div') || window.location.pathname.includes('staging')) ? '/dev' : '';
const PersonSearchURI = `/ui/intelligence/person-search/v1${dev}`;
const SearchURI = '/ui/v4/all/search';

const getBiographicalData = data => Api.post(`${PersonSearchURI}/biographical/search`, data, [200, 201, 204]);
const getResults = reportID => Api.get(`${PersonSearchURI}/report/${reportID}/results`).then(res => res.data);
const postForm = data => Api.post(`${PersonSearchURI}/report`, data, [200, 201, 204]);

const Hits = query => Api.post(SearchURI, query);

export default {
  getBiographicalData,
  getResults,
  postForm,
  Hits,
};
