const Fields = {
  recipients: [
    { label: 'Submitting Team', value: 'Submitting Team' },
    { label: 'Executive Level', value: 'Executive Level' },
    { label: 'Internal Customer', value: 'Internal Customer' },
    { label: 'Internal Customer (Technical Focus)', value: 'Internal Customer (Technical Focus)' },
  ],
  format: [
    { label: 'Email', value: 'Email Response' },
    { label: 'PDF', value: 'Finished PDF Report' },
    { label: 'Either Email or PDF', value: 'Either Email or PDF' },
  ],
};

export default Fields;
