/*
  The component/search/Table.js is packed with logic.
  In order to reduce the maintenence in that file,
  we are moving some complex transformation logic out
  for greater readability, testability, and maintainability
*/

const extractUUIDLabelFromPayload = (payload) => {
  const corruptedUUID = payload.fpid;
  const uuid = corruptedUUID
    .replace('<x-fp-highlight>', '')
    .replace('</x-fp-highlight>', '');
  return uuid;
};

const extractCategoryLabelFromPayload = payload => payload && 'unkown';

const extractTypeLabelFromPayload = payload => (payload?.value
  ? Object.keys(payload.value).find(first => first)
  : '');

const extractValueLabelFromPayload = (payload) => {
  const key = extractTypeLabelFromPayload(payload);
  return payload?.value?.[String(key)];
};

export default {
  extractUUIDLabelFromPayload,
  extractCategoryLabelFromPayload,
  extractTypeLabelFromPayload,
  extractValueLabelFromPayload,
};
