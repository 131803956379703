import React, { useEffect, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import style from './sanctions.module.scss';
import Token from '../../utils/token';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

const NoteComponent = ((props) => {
  const [note, setNote] = useState({});
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    if (props.note) {
      setNote(props.note);
      if (Object.keys(props.note).length === 0) {
        setEdit(true);
      }
    }
    return function cleanup() {
      isOnPage = false;
    };
  }, [props.note]);

  const handleDelete = () => {
    if (note.id === undefined || props.onDelete === undefined) {
      // if this note hasn't been saved yet, delete in from the UI side
      if (props.index !== undefined) {
        props.onDelete(props.index);
        setConfirm(false);
      }
      return;
    }
    Api.delete(`/ui/v4/sanctions/saved_search/notes/${note.id}`, {}, [200], 30000, {}, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then(() => {
        if (!isOnPage) {
          return;
        }
        props.onDelete(props.index);
        setConfirm(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        setConfirm(false);
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(data?.detail, data));
      });
  };

  const handleTextChange = (event) => {
    const temp = { ...note };
    if (note.note_text !== undefined) {
      temp.note_text = event.target.value;
    } else {
      Object.assign(temp, { note_text: event.target.value });
    }
    setNote(temp);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if ((note.id === undefined && props.id === undefined)
      || (note.note_text === undefined
      || note.note_text.length === 0)) {
      setEdit(false);
      return;
    }
    if (note.id !== undefined) {
      Api.patch(`/ui/v4/sanctions/saved_search/notes/${note.id}`, { newNote: note.note_text }, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
        .then(res => (res.ok ? res.data : null))
        .then((res) => {
          if (res.display === false) {
            return { data: [] };
          }
          return res;
        })
        .then(() => {
          if (!isOnPage) {
            return;
          }
          setEdit(false);
        })
        .catch((e) => {
          if (!isOnPage) {
            return;
          }
          const { data } = e.response;
          SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(data?.detail, data));
        });
    } else {
      Api.post(`/ui/v4/sanctions/saved_search/${props.id}/notes`,
        { newNote: note.note_text },
        [200],
        30000,
        { Authorization: `Bearer ${Token.cke()}` },
      )
        .then(res => (res.ok ? res.data : null))
        .then((res) => {
          if (res.display === false) {
            return { data: [] };
          }
          return res;
        })
        .then((res) => {
          if (!isOnPage) {
            return;
          }
          setNote(res);
          setEdit(false);
        })
        .catch((e) => {
          if (!isOnPage) {
            return;
          }
          const { data } = e.response;
          SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(data?.detail, data));
        });
    }
  };

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleClose = () => {
    setConfirm(false);
  };

  return (
    <React.Fragment>
      <div className={style.titleedit}>
        <div className={style.subtitle}>{note.created_by ? note.created_by : 'NOTE'} - {note.created_date ? new Date(note.created_date).toLocaleString() : ''}</div>
        <div>
          <button type="button" className={style.editbutton} onClick={handleConfirm}><Delete />Delete</button>
          <Dialog
            data-cy="noteDeleteConfirmation"
            open={confirm}
            onClose={handleClose}>
            <DialogTitle>Are you sure you want to delete this note?</DialogTitle>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleDelete}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <button type="button" className={style.editbutton} onClick={() => setEdit(true)}><Edit />Edit</button>
        </div>
      </div>

      {edit ?
      (
        <form onSubmit={handleSubmit}>
          <textarea
            className={style.noteinput}
            value={note.note_text}
            onChange={event => handleTextChange(event)} />
          <input className={style.sectionsubmit} type="submit" value="Save" />
        </form>
      ) : (
        <div className={style.note}>
          {note.note_text ? note.note_text : ''}
        </div>
      )}
    </React.Fragment>
  );
});

NoteComponent.propTypes = {
  note: PropTypes.object,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.number,
};

NoteComponent.defaultProps = {
  note: undefined,
  onDelete: undefined,
  index: undefined,
  id: undefined,
};

export default NoteComponent;
