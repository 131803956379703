import React from 'react';

import { useRecoilState } from 'recoil';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';

import PersonSearchState from '../../../stores/recoil/personSearch';

const PageThree = () => {
  const [legalOptIn, setLegalOptIn] = useRecoilState(PersonSearchState.legalOptIn);

  const handleRadioChange = (e) => {
    e.preventDefault();
    setLegalOptIn(!legalOptIn);
  };
  return (
    <Grid>
      <Grid item>
        <Typography style={{ marginBottom: '1.5rem' }}variant="subtitle1">
          This request will require Flashpoint to acquire new data that is not currently
          in Flashpoint’s collections. Please confirm that you consent to Flashpoint taking
          such actions on your behalf in connection with this request.
        </Typography>
        <RadioGroup
          defaultValue="no"
          value={legalOptIn ? 'yes' : 'no'}
          name="legalOptIn"
          onChange={e => handleRadioChange(e)}>
          <FormControlLabel value="yes" control={<Radio color="secondary" />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default PageThree;
