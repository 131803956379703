import * as React from 'react';
import * as moment from 'moment';
import { useState } from 'react';

import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import Query from '../../query';

import { IntelReport } from '../../../../../core/types/IntelReport';
import History from '../../../../../utils/history';
import { FancyDot } from './FancyDot';
import Dashboard from '../../../Dashboard';
import easyMemo from '../../../../../components/utils/Memoize';

const useStyles = makeStyles({
  empty: {
    color: grey[500],
    margin: '2rem 0 1rem 0',
  },
  reportTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
  },
  reportDate: {
    fontWeight: 400,
    color: '#757575',
    fontSize: '12px',
    marginBottom: '8px',
    lineHeight: '16px',
  },
  flexLeft: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'stretch',
  },
  flexRow: {
    display: 'flex',
    flexFlow: 'row',
  },
  mouseOver: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  marginTop: {
    marginTop: '10px',
  },
});
interface RecentIntelReportsWidgetProps {
  tagIds?: string[];
  settings: VoidFunction;
}

export const RecentIntelReportsWidget = easyMemo(({
  tagIds,
  settings,
}: RecentIntelReportsWidgetProps): JSX.Element => {
  const [rows, setRows] = useState(null);
  const classes = useStyles();

  const onRoute = (ir: IntelReport) => {
    if (ir?.id?.length > 0) {
      const path = `/home/intelligence/reports/report/${ir.id}`;
      History.navigateTo(path);
    }
  };

  easyUseEffect(() => {
    if (rows?.length > 0) {
      setRows(null);
    }
    Query.RecentIntelReports(tagIds).then((res:IntelReport[]) => {
      setRows(res);
    });
  }, [tagIds]);

  const renderBody = ():JSX.Element => (
    !rows || rows.length < 1 ?
      <div className={classes.empty}>No intel reports to display</div> :
      <>
        {rows.map((ir:IntelReport, irIndex:number) => {
          const relativeTime = ir.version_posted_at ? moment(ir.version_posted_at).fromNow() : 'unknown';
          const hasLineUnderneath = irIndex < rows.length - 1;
          return (
            <div
              onClick={() => onRoute(ir)}
              key={ir.id}
              className={cx([classes.flexRow, classes.mouseOver])}>
              <FancyDot hasLineUnderneath={hasLineUnderneath} />
              <span>
                <div className={classes.reportTitle}>{ir.title}</div>
                <div className={classes.reportDate}>Posted {relativeTime}</div>
              </span>
            </div>
          );
        })}
      </>
  );
  return (
    <Dashboard
      data={rows}
      title="Recent Intelligence Reports"
      help="Offers quick access to our latest intelligence reports. Click the gear icon to customize your reports."
      settings={settings ? () => settings() : null}>
      <div className={cx([classes.marginTop, classes.flexLeft])}>
        { renderBody() }
      </div>
    </Dashboard>
  );
});

RecentIntelReportsWidget.defaultProps = {
  tagIds: [],
};
