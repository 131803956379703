import * as React from 'react';

import {
  Paper as MuiPaper,
  TableContainer as MuiTableContainer,
} from '@mui/material';

interface TableContainerProps {
  /** The component used for the root node */
  component?: React.ElementType;

  /** The content of the table container */
  children?: React.ReactNode;
}

/** Generic container for a table */
export const TableContainer = ({
  component,
  children,
  ...rest
}: TableContainerProps): JSX.Element =>
  <MuiTableContainer
    component={component}
    {...rest}>
    {children}
  </MuiTableContainer>;

TableContainer.defaultProps = {
  component: MuiPaper,
  children: '',
};
