import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useDotStyles = makeStyles({
  blueDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#4f5269',
    display: 'inline-block',
    marginTop: '6px',
    marginBottom: '5px',
  },
  blueLine: {
    borderLeft: '1px solid #1890ff',
    height: '20px',
    flex: '1',
  },
  flexCenterColumn: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginRight: '10px',
  },
});

interface FancyDotProps {
  hasLineUnderneath?: boolean
}

export const FancyDot = ({ hasLineUnderneath }: FancyDotProps): JSX.Element => {
  const classes = useDotStyles();

  const blueLine = hasLineUnderneath ? <div className={classes.blueLine}/> : null;
  return (
    <span className={classes.flexCenterColumn}>
      <div className={classes.blueDot} />
      {blueLine}
    </span>
  );
};

FancyDot.defaultProps = {
  hasLineUnderneath: true,
};
