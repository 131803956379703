import { atom, selector, selectorFamily } from 'recoil';

import { Map as map } from 'immutable';

const sectionsDataState = atom({
  key: 'searchSectionsSearch.sectionsDataState',
  default: map(),
});

const sectionsFilterState = atom({
  key: 'searchSectionsSearch.sectionsFilterState',
  default: map(),
});

const sectionsTypeState = atom({
  key: 'searchSectionsSearch.sectionsTypeState',
  default: '',
});

const selectedSectionState = atom({
  key: 'searchSectionsSearch.selectedSectionState',
  default: '',
});

const selectedSection = selectorFamily({
  key: 'searchSectionsSearch.selectedSection',
  get: suppliedType => ({ get }) => {
    const selected = get(selectedSectionState);
    const type = get(sectionsTypeState);
    if (!selected || ['tagging.channels'].includes(suppliedType)) return suppliedType || type;
    return (selected.indexOf('posts') === 0) ? selected.split('.').slice(-1).shift() : selected;
  },
});

const selectedDataState = selector({
  key: 'searchSectionsSearch.selectedDataState',
  get: ({ get }) => {
    const selected = get(selectedSectionState);
    const data = get(sectionsDataState);

    return data.get(selected, map());
  },
});

const SearchSectionsSearch = {
  sectionsDataState,
  sectionsFilterState,
  sectionsTypeState,
  selectedSectionState,
  // selectors
  selectedSection,
  selectedDataState,
};

export default SearchSectionsSearch;
