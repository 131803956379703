import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import { Button, Dialog, DialogTitle, FormGroup, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey, red } from '@mui/material/colors';

const REQUIRED_ERROR_MESSAGE = 'This field is required';
// Enable CIDR ranges in IP Field
// eslint-disable-next-line security/detect-unsafe-regex
const IP_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$/;

const useStyles = makeStyles(() => ({
  dialogBody: {
    padding: '0 2.4rem 2.4rem 2.4rem',
  },
  dialogFooter: {
    borderTop: `1px ${grey[200]} solid`,
  },
  dialogFormGroup: {
    padding: '0 2.4rem',
  },
  dialogTitle: {
    padding: '1.6rem 0',
    '& .MuiTypography-root': {
      fontSize: '2.4rem',
      fontWeight: '600',
    },
  },
  errorText: {
    color: red[500],
    fontSize: '1.4rem',
  },
  fieldGap: {
    marginBottom: '2rem',
  },
}));

const ImDialog = ({ open, selectedIp, onSubmit, onCancel }) => {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const classes = useStyles();

  return (
    <Dialog open={open} className="imDialog">
      <form onSubmit={handleSubmit(onSubmit)} className="iiForm">
        <Grid container>
          <Grid item xs={12} className={classes.dialogBody}>
            <DialogTitle className={classes.dialogTitle}>{selectedIp ? 'IP Address' : 'Add a new IP address'}</DialogTitle>
            <FormGroup className={classes.dialogFormGroup}>
              <div className={classes.fieldGap}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={selectedIp?.name || ''}
                  rules={{
                    required: true,
                  }}
                  shouldUnregister
                  render={({ field }) => <TextField
                    error={!!errors?.name}
                    disabled={selectedIp !== null}
                    label="IP Address Name"
                    variant="outlined"
                    data-testid="name-field"
                    {...field} />}
                  />
                <div className={classes.errorText}>{errors?.name && REQUIRED_ERROR_MESSAGE}</div>
              </div>
              <div>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={selectedIp?.value || ''}
                  rules={{
                    required: true,
                    pattern: IP_REGEX,
                  }}
                  shouldUnregister
                  render={({ field }) => <TextField
                    error={!!errors?.address}
                    disabled={selectedIp !== null}
                    label="IP Address"
                    placeholder="Single IP address or Range"
                    variant="outlined"
                    data-testid="ip-field"
                    {...field} />}
                  />
                <div className={classes.errorText}>{errors?.address?.type === 'required' && REQUIRED_ERROR_MESSAGE}</div>
                <div className={classes.errorText}>{errors?.address?.type === 'pattern' && 'Please enter a valid IP address'}</div>
              </div>
            </FormGroup>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" className={classes.dialogFooter}>
            <Grid item><Button onClick={onCancel} className="cancelBtn">{selectedIp ? 'Close' : 'Cancel'}</Button></Grid>
            { !selectedIp && <Grid item><Button color="secondary" type="submit" className="saveBtn">Save</Button></Grid> }
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

ImDialog.propTypes = {
  open: PropTypes.bool,
  selectedIp: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

ImDialog.defaultProps = {
  open: false,
  selectedIp: {},
  onSubmit: null,
  onCancel: null,
};

export default ImDialog;
