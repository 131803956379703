import * as LDClient from 'launchdarkly-js-client-sdk';

import Token from './token';

const DEV_ID = '609ac5576454590ca16bc90d';
const PROD_ID = '609ac5576454590ca16bc90e';

const launchdarkly = {
  client: null,
  storage: window.localStorage.getItem('okta-token-storage'),
  id: /(fp.tools|fpint.net)$/ig.test(window.location.origin) ? PROD_ID : DEV_ID,
  init: async () => {
    const { idToken } = launchdarkly.storage ? JSON?.parse(launchdarkly.storage) : {};
    if (!idToken?.claims?.id) return;

    const user = {
      key: idToken?.claims?.id,
      email: idToken?.claims?.email || idToken?.claims?.name,
      name: idToken?.claims?.preferred_username,
      custom: {
        groups: Token?.get('sf_id'),
      },
    };

    launchdarkly.client = LDClient.initialize(launchdarkly.id, user);
    await launchdarkly.client.waitUntilReady();
  },
  load: async () => {
    if (!launchdarkly.client) await launchdarkly.init();
    return launchdarkly?.client?.allFlags();
  },
};

export default launchdarkly;
