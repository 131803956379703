import * as React from 'react';

import { Tooltip } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';

interface ArrowIndicatorProps {
  percent: number;
  timePeriod: string;
  className?: string;
}

export const ArrowIndicator = ({
  percent,
  timePeriod,
  className,
}: ArrowIndicatorProps): JSX.Element => {
  const Threshold = 5;

  const getIcon = (v: number): JSX.Element => {
    if (v < 0) return <ArrowDropDown />;
    if (v > 0) return <ArrowDropUp />;
    return null;
  };

  const getBgColor = (v: number): string => {
    if (v < -Threshold) return green[100];
    if (v > Threshold) return red[100];
    return grey[200];
  };

  const getColor = (v: number): string => {
    if (v < -Threshold) return green[800];
    if (v > Threshold) return red[800];
    return grey[800];
  };

  const getLabel = (v: number): string => {
    if (v < -Threshold || v > Threshold) return `${Math.abs(percent)}%`;
    return '--';
  };

  const iconStyle = {
    backgroundColor: getBgColor(percent),
    borderRadius: '2.1rem',
    color: getColor(percent),
    marginLeft: '.8rem',
    paddingRight: '.8rem',
  };
  const label = getLabel(percent);

  return (
    <Tooltip title={timePeriod || 'About the Same'}>
      <div className={className} style={{ ...iconStyle, paddingLeft: label === '--' ? '.8rem' : '' }}>
        {getIcon(percent)}{label}
      </div>
    </Tooltip>
  );
};

ArrowIndicator.defaultProps = {
  className: '',
};
