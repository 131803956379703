import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '10rem auto 0 auto',
    maxWidth: '700px',
    width: '100%',
  },
  body: {
    marginBottom: '2.4rem',
    textAlign: 'center',
  },
  button: {
    padding: '7.5px 28px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'none',
  },
  icon: {
    color: green[500],
    fontSize: '3.3rem',
    marginBottom: '2.4rem',
  },

}));

const PersonSearchConfirmation = ({ refreshForm }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={4}>
      <CheckCircleOutlineIcon className={classes.icon} />
      <Typography className={classes.body} variant="h5">We've received your request!</Typography>
      <Typography className={classes.body} variant="body1">Thank you for submitting a request using the Person Search form. We are currently reviewing your request internally with our analyst team and will respond shortly.</Typography>
      <Typography className={classes.body} variant="body1">If this request is urgent and requires immediate assistance, please contact your Customer Success representative directly.</Typography>
      <Button className={classes.button} onClick={e => refreshForm(e)} variant="contained" color="secondary">Submit Another Request</Button>
    </Grid>
  );
};

PersonSearchConfirmation.propTypes = {
  refreshForm: PropTypes.func,
};

PersonSearchConfirmation.defaultProps = {
  refreshForm: null,
};

export default PersonSearchConfirmation;
