export const WidgetDefaults = {
  version: '2',
  topRow: [
    {
      id: 0,
      type: 'result_count',
    },
    {
      id: 1,
      type: 'top_source',
    },
    {
      id: 2,
      type: 'actor_count',
    },
    {
      id: 3,
      type: 'top_actor',
    },
  ],
  centerRow: [
    {
      id: 0,
      type: 'my_alerts',
    },
    {
      id: 1,
      type: 'my_recent_searches',
    },
  ],
  bottomRow: [
    {
      id: 0,
      type: 'recent_intel_reports',
      tagIds: [],
    },
    {
      id: 1,
      type: 'explore_topics',
    },
  ],
};

export const FilterDefaults = {
  name: 'Default',
  filters: {
    date: 'Last 7 Days',
    since: 'now-7d',
    until: 'now',
  },
  default: 'true',
};
