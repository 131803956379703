import * as React from 'react';
import { useEffect, useState } from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

import { ChartData } from '../../../../../core/types/ChartData';

interface MentionsChartProps {
  data: ChartData[]
}

const NullData = [{
  name: 'mention',
  count: 0,
  date: 1,
}, {
  name: 'mention',
  count: 0,
  date: 2,
}];

export const LineChart = ({ data }: MentionsChartProps): JSX.Element => {
  const [chartData, setChartData] = useState(NullData);

  useEffect(() => {
    if (data && data.length > 0) {
      setChartData([...data]);
    }
  }, [data]);

  return (
    <ResponsiveContainer>
      <AreaChart data={chartData}>
        <Area type="monotone" dataKey="count" stroke="#008bd8" strokeWidth={2} fill="#b7daf1" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
