import * as React from 'react';
import cx from 'classnames';
import { Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useColorStyles = makeStyles({
  percentChangeWrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  arrowColorGreen: {
    '& .MuiIcon-colorPrimary': {
      color: '#008BD8',
    },
  },
  arrowColorRed: {
    '& .MuiIcon-colorPrimary': {
      color: '#eb002b',
    },
  },
  red: {
    color: '#eb002b',
  },
  green: {
    color: '#25a277',
  },
});

interface PercentChangeNodeProps {
  rawPercentChange:number
}

const makeHorizontalRuleIcon = () => (
  <span style={{ width: '20px' }}>
    <hr style={{ width: '9px', border: 'solid black 1px' }}/>
  </span>
);

export const PercentChangeNode = ({
  rawPercentChange,
}:PercentChangeNodeProps) => {
  const classes = useColorStyles();
  const fixedPercentChange = Math.round(rawPercentChange * 10) / 10;
  let keyboardIcon = makeHorizontalRuleIcon();
  if (fixedPercentChange > 0) {
    keyboardIcon = <Icon>keyboard_arrow_up</Icon>;
  } else if (fixedPercentChange < 0) {
    keyboardIcon = <Icon>keyboard_arrow_down</Icon>;
  }
  const colorClass = fixedPercentChange !== 0 &&
    (fixedPercentChange > 0 ? classes.green : classes.red);
  const arrowColorClass = fixedPercentChange !== 0 &&
    (fixedPercentChange > 0 ? classes.arrowColorGreen : classes.arrowColorRed);
  return (
    <div className={cx([arrowColorClass, classes.percentChangeWrapper, colorClass])}>
      { keyboardIcon } {fixedPercentChange.toFixed(1)} %
    </div>
  );
};
