const necessaryLegacyStylesProps = `
.MuiSvgIcon-colorPrimary {
  color: #9e9e9e;
}

.MuiSvgIcon-colorSecondary {
  color: #5c6ae0;
}

.MuiSvgIcon-colorAction {
  color: rgba(0, 0, 0, 0.54);
}

.MuiSvgIcon-colorError {
  color: #f44336;
}

.MuiSvgIcon-colorDisabled {
  color: rgba(0, 0, 0, 0.26);
}

.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 2rem;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 3.5rem;
}



.MuiPaper-rounded {
  border-radius: 4px;
}

.MuiPaper-outlined {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiPaper-elevation1 {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation2 {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation3 {
  box-shadow: 0 3px 3px -2px rgba(0,0,0,0.2),0 3px 4px 0 rgba(0,0,0,0.14),0 1px 8px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation4 {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation5 {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 5px 8px 0 rgba(0,0,0,0.14),0 1px 14px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation6 {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12);
}

.MuiPaper-elevation7 {
  box-shadow: 0 4px 5px -2px rgba(0,0,0,0.2),0 7px 10px 1px rgba(0,0,0,0.14),0 2px 16px 1px rgba(0,0,0,0.12);
}

.MuiPaper-elevation8 {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
}

.MuiPaper-elevation9 {
  box-shadow: 0 5px 6px -3px rgba(0,0,0,0.2),0 9px 12px 1px rgba(0,0,0,0.14),0 3px 16px 2px rgba(0,0,0,0.12);
}

.MuiPaper-elevation10 {
  box-shadow: 0 6px 6px -3px rgba(0,0,0,0.2),0 10px 14px 1px rgba(0,0,0,0.14),0 4px 18px 3px rgba(0,0,0,0.12);
}

.MuiPaper-elevation11 {
  box-shadow: 0 6px 7px -4px rgba(0,0,0,0.2),0 11px 15px 1px rgba(0,0,0,0.14),0 4px 20px 3px rgba(0,0,0,0.12);
}

.MuiPaper-elevation12 {
  box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2),0 12px 17px 2px rgba(0,0,0,0.14),0 5px 22px 4px rgba(0,0,0,0.12);
}

.MuiPaper-elevation13 {
  box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2),0 13px 19px 2px rgba(0,0,0,0.14),0 5px 24px 4px rgba(0,0,0,0.12);
}

.MuiPaper-elevation14 {
  box-shadow: 0 7px 9px -4px rgba(0,0,0,0.2),0 14px 21px 2px rgba(0,0,0,0.14),0 5px 26px 4px rgba(0,0,0,0.12);
}

.MuiPaper-elevation15 {
  box-shadow: 0 8px 9px -5px rgba(0,0,0,0.2),0 15px 22px 2px rgba(0,0,0,0.14),0 6px 28px 5px rgba(0,0,0,0.12);
}

.MuiPaper-elevation16 {
  box-shadow: 0 8px 10px -5px rgba(0,0,0,0.2),0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12);
}

.MuiPaper-elevation17 {
  box-shadow: 0 8px 11px -5px rgba(0,0,0,0.2),0 17px 26px 2px rgba(0,0,0,0.14),0 6px 32px 5px rgba(0,0,0,0.12);
}

.MuiPaper-elevation18 {
  box-shadow: 0 9px 11px -5px rgba(0,0,0,0.2),0 18px 28px 2px rgba(0,0,0,0.14),0 7px 34px 6px rgba(0,0,0,0.12);
}

.MuiPaper-elevation19 {
  box-shadow: 0 9px 12px -6px rgba(0,0,0,0.2),0 19px 29px 2px rgba(0,0,0,0.14),0 7px 36px 6px rgba(0,0,0,0.12);
}

.MuiPaper-elevation20 {
  box-shadow: 0 10px 13px -6px rgba(0,0,0,0.2),0 20px 31px 3px rgba(0,0,0,0.14),0 8px 38px 7px rgba(0,0,0,0.12);
}

.MuiPaper-elevation21 {
  box-shadow: 0 10px 13px -6px rgba(0,0,0,0.2),0 21px 33px 3px rgba(0,0,0,0.14),0 8px 40px 7px rgba(0,0,0,0.12);
}

.MuiPaper-elevation22 {
  box-shadow: 0 10px 14px -6px rgba(0,0,0,0.2),0 22px 35px 3px rgba(0,0,0,0.14),0 8px 42px 7px rgba(0,0,0,0.12);
}

.MuiPaper-elevation23 {
  box-shadow: 0 11px 14px -7px rgba(0,0,0,0.2),0 23px 36px 3px rgba(0,0,0,0.14),0 9px 44px 8px rgba(0,0,0,0.12);
}

.MuiPaper-elevation24 {
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2),0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12);
}



.MuiTouchRipple-ripple {
  opacity: 0;
  position: absolute;
}

.MuiTouchRipple-rippleVisible {
  animation: MuiTouchRipple-keyframes-enter 550ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.3;
  transform: scale(1);
}

.MuiTouchRipple-ripplePulsate {
  animation-duration: 200ms;
}

.MuiTouchRipple-child {
  background-color: currentColor;
  border-radius: 50%;
  display: block;
  height: 100%;
  opacity: 1;
  width: 100%;
}

.MuiTouchRipple-childLeaving {
  animation: MuiTouchRipple-keyframes-exit 550ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.MuiTouchRipple-childPulsate {
  animation: MuiTouchRipple-keyframes-pulsate 2500ms cubic-bezier(0.4, 0, 0.2, 1) 200ms infinite;
  left: 0;
  position: absolute;
  top: 0;
}


.MuiIconButton-edgeStart {
  margin-left: -12px;
}

.MuiIconButton-sizeSmall.MuiIconButton-edgeStart {
  margin-left: -3px;
}

.MuiIconButton-edgeEnd {
  margin-right: -12px;
}

.MuiIconButton-sizeSmall.MuiIconButton-edgeEnd {
  margin-right: -3px;
}

.MuiIconButton-colorInherit {
  color: inherit;
}

.MuiIconButton-colorPrimary {
  color: #9e9e9e;
}

.MuiIconButton-colorPrimary:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiIconButton-colorPrimary:hover {
    background-color: transparent;
  }
}

.MuiIconButton-colorSecondary {
  color: #5c6ae0;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
}

.MuiIconButton-sizeSmall {
  font-size: 1.8rem;
  padding: 3px;
}

.MuiIconButton-label {
  align-items: inherit;
  display: flex;
  justify-content: inherit;
  width: 100%;
}


.MuiBackdrop-invisible {
  background-color: transparent;
}


.MuiDialog-scrollPaper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.MuiDialog-scrollBody {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

.MuiDialog-scrollBody:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.MuiDialog-container {
  height: 100%;
  outline: 0;
}
@media print {
  .MuiDialog-container {
    height: auto;
  }
}

.MuiDialog-paper {
  margin: 32px;
  overflow-y: auto;
  padding: 0;
  position: relative;
}
@media print {
  .MuiDialog-paper {
    box-shadow: none;
    overflow-y: visible;
  }
}

.MuiDialog-paperScrollPaper {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
}

.MuiDialog-paperScrollBody {
  background-color: #fff;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.MuiDialog-paperWidthFalse {
  max-width: calc(100% - 64px);
}

.MuiDialog-paperWidthXs {
  max-width: 444px;
}
@media (max-width:507.95px) {
  .MuiDialog-paperWidthXs.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
}
@media (max-width:663.95px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}

.MuiDialog-paperWidthMd {
  max-width: 960px;
}
@media (max-width:1023.95px) {
  .MuiDialog-paperWidthMd.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}

.MuiDialog-paperWidthLg {
  max-width: 1280px;
}
@media (max-width:1343.95px) {
  .MuiDialog-paperWidthLg.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}

.MuiDialog-paperWidthXl {
  max-width: 1920px;
}
@media (max-width:1983.95px) {
  .MuiDialog-paperWidthXl.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}

.MuiDialog-paperFullWidth {
  width: calc(100% - 64px);
}

.MuiDialog-paperFullScreen {
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: 100%;
  width: 100%;
}

.MuiDialog-paperFullScreen.MuiDialog-paperScrollBody {
  margin: 0;
  max-width: 100%;
}


.MuiTypography-body2 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
}

.MuiTypography-body1 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}

.MuiTypography-caption {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.66;
}

.MuiTypography-button {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
}

.MuiTypography-h1 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 9.6rem;
  font-weight: 300;
  line-height: 1.167;
}

.MuiTypography-h2 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.MuiTypography-h3 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1.167;
}

.MuiTypography-h4 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 3.4rem;
  font-weight: 400;
  line-height: 1.235;
}

.MuiTypography-h5 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.334;
}

.MuiTypography-h6 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
}

.MuiTypography-subtitle1 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
}

.MuiTypography-subtitle2 {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.57;
}

.MuiTypography-overline {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.66;
  text-transform: uppercase;
}

.MuiTypography-srOnly {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.MuiTypography-alignLeft {
  text-align: left;
}

.MuiTypography-alignCenter {
  text-align: center;
}

.MuiTypography-alignRight {
  text-align: right;
}

.MuiTypography-alignJustify {
  text-align: justify;
}

.MuiTypography-noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiTypography-gutterBottom {
  margin-bottom: 0.35em;
}

.MuiTypography-paragraph {
  margin-bottom: 16px;
}

.MuiTypography-colorInherit {
  color: inherit;
}

.MuiTypography-colorPrimary {
  color: #9e9e9e;
}

.MuiTypography-colorSecondary {
  color: #5c6ae0;
}

.MuiTypography-colorTextPrimary {
  color: rgba(0, 0, 0, 0.87);
}

.MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.54);
}

.MuiTypography-colorError {
  color: #f44336;
}

.MuiTypography-displayInline {
  display: inline;
}

.MuiTypography-displayBlock {
  display: block;
}


.MuiDialogContent-dividers {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


.MuiSnackbar-anchorOriginTopCenter {
  top: 8px;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopCenter {
    left: 50%;
    right: auto;
    top: 24px;
    transform: translateX(-50%);
  }
}

.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 8px;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    bottom: 24px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.MuiSnackbar-anchorOriginTopRight {
  justify-content: flex-end;
  top: 8px;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopRight {
    left: auto;
    right: 24px;
    top: 24px;
  }
}

.MuiSnackbar-anchorOriginBottomRight {
  bottom: 8px;
  justify-content: flex-end;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomRight {
    bottom: 24px;
    left: auto;
    right: 24px;
  }
}

.MuiSnackbar-anchorOriginTopLeft {
  justify-content: flex-start;
  top: 8px;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginTopLeft {
    left: 24px;
    right: auto;
    top: 24px;
  }
}

.MuiSnackbar-anchorOriginBottomLeft {
  bottom: 8px;
  justify-content: flex-start;
}
@media (min-width:600px) {
  .MuiSnackbar-anchorOriginBottomLeft {
    bottom: 24px;
    left: 24px;
    right: auto;
  }
}


.MuiChip-sizeSmall {
  height: 24px;
}

.MuiChip-colorPrimary {
  background-color: #eff0fb;
  border: 1px solid #8e96b1;
  border-radius: 4px;
  color: #2a2c3c;
}

.MuiChip-colorPrimary .MuiIcon-root {
  color: #2a2c3c;
}

.MuiChip-colorSecondary {
  background-color: #eff0fb;
  border: 1px solid #8e96b1;
  border-radius: 4px;
  color: #2a2c3c;
}

.MuiChip-colorSecondary .MuiIcon-root {
  color: #2a2c3c;
}

.MuiChip-clickable {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.MuiChip-clickable:hover, .MuiChip-clickable:focus {
  background-color: #e5e7fa;
  border-color: #8e96b1;
}

.MuiChip-clickable:active {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12);
}

.MuiChip-clickableColorPrimary:hover, .MuiChip-clickableColorPrimary:focus {
  background-color: #e5e7fa;
  border-color: #8e96b1;
}

.MuiChip-clickableColorSecondary:hover, .MuiChip-clickableColorSecondary:focus {
  background-color: #e5e7fa;
  border-color: #8e96b1;
}

.MuiChip-deletable:focus {
  border: 2px solid #2434bc;
}

.MuiChip-deletableColorPrimary:focus {
  border: 2px solid #2434bc;
}

.MuiChip-deletableColorSecondary:focus {
  border: 2px solid #2434bc;
}

.MuiChip-outlined {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.MuiChip-clickable.MuiChip-outlined:hover, .MuiChip-clickable.MuiChip-outlined:focus, .MuiChip-deletable.MuiChip-outlined:focus {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiChip-outlined .MuiChip-avatar {
  margin-left: 4px;
}

.MuiChip-outlined .MuiChip-avatarSmall {
  margin-left: 2px;
}

.MuiChip-outlined .MuiChip-icon {
  margin-left: 4px;
}

.MuiChip-outlined .MuiChip-iconSmall {
  margin-left: 2px;
}

.MuiChip-outlined .MuiChip-deleteIcon {
  margin-right: 5px;
}

.MuiChip-outlined .MuiChip-deleteIconSmall {
  margin-right: 3px;
}

.MuiChip-outlinedPrimary {
  border: 1px solid #9e9e9e;
  color: #9e9e9e;
}

.MuiChip-clickable.MuiChip-outlinedPrimary:hover, .MuiChip-clickable.MuiChip-outlinedPrimary:focus, .MuiChip-deletable.MuiChip-outlinedPrimary:focus {
  background-color: rgba(158, 158, 158, 0.04);
}

.MuiChip-outlinedSecondary {
  border: 1px solid #5c6ae0;
  color: #5c6ae0;
}

.MuiChip-clickable.MuiChip-outlinedSecondary:hover, .MuiChip-clickable.MuiChip-outlinedSecondary:focus, .MuiChip-deletable.MuiChip-outlinedSecondary:focus {
  background-color: rgba(255, 152, 0, 0.04);
}

.MuiChip-icon {
  color: #616161;
  margin-left: 5px;
  margin-right: -6px;
}

.MuiChip-iconSmall {
  height: 18px;
  margin-left: 4px;
  margin-right: -4px;
  width: 18px;
}

.MuiChip-iconColorPrimary {
  color: inherit;
}

.MuiChip-iconColorSecondary {
  color: inherit;
}

.MuiChip-label {
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiChip-labelSmall {
  padding-left: 8px;
  padding-right: 8px;
}

.MuiChip-deleteIcon {
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
  height: 22px;
  margin: 0 5px 0 -6px;
  -webkit-tap-highlight-color: transparent;
  width: 22px;
}

.MuiChip-deleteIcon:hover {
  color: rgba(0, 0, 0, 0.4);
}

.MuiChip-deleteIconSmall {
  height: 16px;
  margin-left: -4px;
  margin-right: 4px;
  width: 16px;
}

.MuiChip-deleteIconColorPrimary {
  color: rgba(255, 255, 255, 0.7);
}

.MuiChip-deleteIconColorPrimary:hover, .MuiChip-deleteIconColorPrimary:active {
  color: #fff;
}

.MuiChip-deleteIconColorSecondary {
  color: rgba(255, 255, 255, 0.7);
}

.MuiChip-deleteIconColorSecondary:hover, .MuiChip-deleteIconColorSecondary:active {
  color: #fff;
}

.MuiChip-deleteIconOutlinedColorPrimary {
  color: rgba(158, 158, 158, 0.7);
}

.MuiChip-deleteIconOutlinedColorPrimary:hover, .MuiChip-deleteIconOutlinedColorPrimary:active {
  color: #9e9e9e;
}

.MuiChip-deleteIconOutlinedColorSecondary {
  color: rgba(255, 152, 0, 0.7);
}

.MuiChip-deleteIconOutlinedColorSecondary:hover, .MuiChip-deleteIconOutlinedColorSecondary:active {
  color: #5c6ae0;
}


.MuiAutocomplete-fullWidth {
  width: 100%;
}
.MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
}
.MuiAutocomplete-tagSizeSmall {
  margin: 2px;
  max-width: calc(100% - 4px);
}
.MuiAutocomplete-inputRoot {
  color: #000;
  padding: 0 39px 0 4px !important;
  flex-wrap: wrap;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 30px;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 56px;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 30px;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
  padding-bottom: 1px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 9px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 6px;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 19px;
  padding-left: 8px;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"] {
  padding-bottom: 1px;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"] .MuiAutocomplete-input {
  padding: 4.5px 4px;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-right: 39px;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-right: 65px;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
  padding: 9px 4px;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
  right: 9px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input {
  padding: 4.5px 4px;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 39px;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 65px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 9.5px 4px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding-left: 6px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
  right: 9px;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input {
  padding: 4px 4px 5px;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child {
  padding: 3px 0 6px;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
  padding: 4px;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  padding: 6px 0;
}
.MuiAutocomplete-input {
  opacity: 0;
  flex-grow: 1;
  text-overflow: ellipsis;
}
.MuiAutocomplete-inputFocused {
  opacity: 1;
}
.MuiAutocomplete-endAdornment {
  top: calc(50% - 14px);
  right: 0;
  position: absolute;
}
.MuiAutocomplete-clearIndicator {
  padding: 4px;
  visibility: hidden;
  margin-right: -2px;
}
.MuiAutocomplete-popupIndicator {
  padding: 2px;
  margin-right: -2px;
}
.MuiAutocomplete-popupIndicatorOpen {
  transform: rotate(180deg);
}
.MuiAutocomplete-popper {
  z-index: 1300;
}
.MuiAutocomplete-popperDisablePortal {
  position: absolute;
}
.MuiAutocomplete-paper {
  margin: 4px 0;
  padding: 0;
  overflow: hidden;
  font-size: 1.4rem;
  font-family: "Open Sans",Arial,sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
.MuiAutocomplete-listbox {
  margin: 0;
  padding: 8px 0;
  overflow: auto;
  list-style: none;
  max-height: 40vh;
}
.MuiAutocomplete-loading {
  color: rgba(0, 0, 0, 0.54);
  padding: 14px 16px;
}
.MuiAutocomplete-noOptions {
  color: rgba(0, 0, 0, 0.54);
  padding: 14px 16px;
}
.MuiAutocomplete-option {
  cursor: pointer;
  display: flex;
  outline: 0;
  box-sizing: border-box;
  min-height: 48px;
  align-items: center;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width:600px) {
  .MuiAutocomplete-option {
    min-height: auto;
  }
}
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.08);
}
.MuiAutocomplete-option[data-focus="true"] {
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiAutocomplete-option:active {
  background-color: rgba(0, 0, 0, 0.08);
}
.MuiAutocomplete-option[aria-disabled="true"] {
  opacity: 0.38;
  pointer-events: none;
}
.MuiAutocomplete-groupLabel {
  top: -8px;
  background-color: #fff;
}
.MuiAutocomplete-groupUl {
  padding: 0;
}
.MuiAutocomplete-groupUl .MuiAutocomplete-option {
  padding-left: 24px;
}


.MuiFormControl-marginNormal {
  margin-bottom: 8px;
  margin-top: 16px;
}

.MuiFormControl-marginDense {
  margin-bottom: 4px;
  margin-top: 8px;
}

.MuiFormControl-fullWidth {
  width: 100%;
}


.MuiFormLabel-colorSecondary.Mui-focused {
  color: #5c6ae0;
}
.MuiFormLabel-asterisk.Mui-error {
  color: #f44336;
}


.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
}
.MuiInputLabel-marginDense {
  transform: translate(0, 21px) scale(1);
}
.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}
.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}
.MuiInputLabel-filled {
  z-index: 1;
  transform: translate(12px, 20px) scale(1);
  pointer-events: none;
}
.MuiInputLabel-filled.MuiInputLabel-marginDense {
  transform: translate(12px, 17px) scale(1);
}
.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.75);
}
.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(12px, 7px) scale(0.75);
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  padding-left: 8px;
  padding-right: 8px;
  pointer-events: none;
  background-color: #fff;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: translate(14px, 12px) scale(1);
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 1.6rem;
}


.MuiButton-label {
  align-items: inherit;
  display: inherit;
  font-size: 1.4rem;
  justify-content: inherit;
  white-space: nowrap;
  width: 100%;
}

.MuiButton-text {
  padding: .6rem 1.2rem;
}

.MuiButton-textPrimary {
  color: #9e9e9e;
}

.MuiButton-textPrimary:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiButton-textPrimary:hover {
    background-color: transparent;
  }
}

.MuiButton-textSecondary {
  color: #5c6ae0;
}

.MuiButton-textSecondary:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiButton-textSecondary:hover {
    background-color: transparent;
  }
}

.MuiButton-outlined {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
}

.MuiButton-outlined.Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiButton-outlinedPrimary {
  border: 1px solid rgba(158, 158, 158, 0.5);
  color: #9e9e9e;
}

.MuiButton-outlinedPrimary:hover {
  background-color: rgba(158, 158, 158, 0.04);
  border: 1px solid #9e9e9e;
}
@media (hover: none) {
  .MuiButton-outlinedPrimary:hover {
    background-color: transparent;
  }
}

.MuiButton-outlinedSecondary {
  border: 1px solid rgba(255, 152, 0, 0.5);
  color: #5c6ae0;
}

.MuiButton-outlinedSecondary:hover {
  background-color: rgba(255, 152, 0, 0.04);
  border: 1px solid #5c6ae0;
}

.MuiButton-outlinedSecondary.Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiButton-outlinedSecondary:hover {
    background-color: transparent;
  }
}

.MuiButton-contained {
  background-color: #e0e0e0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 1rem;
  text-transform: capitalize;
}

.MuiButton-contained:hover {
  background-color: #d5d5d5;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12);
}

.MuiButton-contained.Mui-focusVisible {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12);
}

.MuiButton-contained:active {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
}

.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiButton-contained:hover {
    background-color: #e0e0e0;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
  }
}

.MuiButton-contained:hover.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.MuiButton-containedPrimary {
  background-color: #9e9e9e;
  color: #fff !important;
}

.MuiButton-containedPrimary:hover {
  background-color: rgb(110, 110, 110);
}

.MuiButton-containedPrimary .MuiIcon-root {
  color: #fff;
}
@media (hover: none) {
  .MuiButton-containedPrimary:hover {
    background-color: #9e9e9e;
  }
}

.MuiButton-containedSecondary {
  background-color: #5c6ae0;
  color: #fff !important;
}

.MuiButton-containedSecondary:hover {
  background-color: #2434bc;
}

.MuiButton-containedSecondary .MuiButton-label {
  color: #fff !important;
}

.MuiButton-containedSecondary .MuiIcon-root {
  color: #fff;
}
@media (hover: none) {
  .MuiButton-containedSecondary:hover {
    background-color: #5c6ae0;
  }
}

.MuiButton-disableElevation {
  box-shadow: none;
}

.MuiButton-disableElevation:hover {
  box-shadow: none;
}

.MuiButton-disableElevation.Mui-focusVisible {
  box-shadow: none;
}

.MuiButton-disableElevation:active {
  box-shadow: none;
}

.MuiButton-disableElevation.Mui-disabled {
  box-shadow: none;
}

.MuiButton-colorInherit {
  border-color: currentColor;
  color: inherit;
}

.MuiButton-textSizeSmall {
  font-size: 1.3rem;
  padding: 4px 5px;
}

.MuiButton-textSizeLarge {
  font-size: 1.5rem;
  padding: 8px 11px;
}

.MuiButton-outlinedSizeSmall {
  font-size: 1.3rem;
  padding: 3px 9px;
}

.MuiButton-outlinedSizeLarge {
  font-size: 1.5rem;
  padding: 7px 21px;
}

.MuiButton-containedSizeSmall {
  font-size: 1.3rem;
  padding: 4px 10px;
}

.MuiButton-containedSizeLarge {
  font-size: 1.5rem;
  padding: 8px 22px;
}

.MuiButton-fullWidth {
  width: 100%;
}

.MuiButton-startIcon {
  display: inherit;
  margin-left: -4px;
  margin-right: 8px;
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-left: -2px;
}

.MuiButton-endIcon {
  display: inherit;
  margin-left: 8px;
  margin-right: -4px;
}

.MuiButton-endIcon.MuiButton-iconSizeSmall {
  margin-right: -2px;
}

.MuiButton-iconSizeSmall > *:first-child {
  font-size: 18px;
}

.MuiButton-iconSizeMedium > *:first-child {
  font-size: 20px;
}

.MuiButton-iconSizeLarge > *:first-child {
  font-size: 22px;
}


.MuiIcon-colorPrimary {
  color: #7a84a1;
}

.MuiIcon-colorSecondary {
  color: #3547d9;
}

.MuiIcon-colorAction {
  color: rgba(0, 0, 0, 0.54);
}

.MuiIcon-colorError {
  color: #dd002b;
}

.MuiIcon-colorDisabled {
  color: rgba(0, 0, 0, 0.26);
}

.MuiIcon-fontSizeInherit {
  font-size: inherit;
}

.MuiIcon-fontSizeSmall {
  font-size: 2rem;
}

.MuiIcon-fontSizeLarge {
  font-size: 3.6rem;
}


.MuiPopover-paper {
  max-height: calc(100% - 32px);
  max-width: calc(100% - 32px);
  min-height: 16px;
  min-width: 16px;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
}


.MuiList-padding {
  padding-bottom: 8px;
  padding-top: 8px;
}

.MuiList-subheader {
  padding-top: 0;
}


.MuiListItem-container {
  position: relative;
}

.MuiListItem-dense {
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.MuiListItem-alignItemsFlexStart {
  align-items: flex-start;
}

.MuiListItem-divider {
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiListItem-gutters {
  padding-left: 16px;
  padding-right: 16px;
}

.MuiListItem-button {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
  text-decoration: none;
}
@media (hover: none) {
  .MuiListItem-button:hover {
    background-color: transparent;
  }
}

.MuiListItem-secondaryAction {
  padding-right: 48px;
}


.MuiInputBase-multiline {
  padding: 6px 0 7px;
}

.MuiInputBase-multiline.MuiInputBase-marginDense {
  padding-top: 3px;
}

.MuiInputBase-fullWidth {
  width: 100%;
}

.MuiInputBase-input {
  animation-duration: 10ms;
  animation-name: mui-auto-fill-cancel;
  background: none;
  border: 0;
  box-sizing: content-box;
  color: currentColor;
  display: block;
  font: inherit;
  height: 1.1876em;
  letter-spacing: inherit;
  margin: 0;
  min-width: 0;
  padding: 6px 0 7px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.MuiInputBase-input::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInputBase-input:focus {
  outline: 0;
}

.MuiInputBase-input:invalid {
  box-shadow: none;
}

.MuiInputBase-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
}

.MuiInputBase-input:-webkit-autofill {
  animation-duration: 5000s;
  animation-name: mui-auto-fill;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder {
  opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-moz-placeholder {
  opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:-ms-input-placeholder {
  opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-ms-input-placeholder {
  opacity: 0 !important;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.MuiInputBase-inputMarginDense {
  padding-top: 3px;
}

.MuiInputBase-inputMultiline {
  height: auto;
  padding: 0;
  resize: none;
}

.MuiInputBase-inputTypeSearch {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}


label + .MuiInput-formControl {
  margin-top: 16px;
}

.MuiInput-colorSecondary.MuiInput-underline:after {
  border-bottom-color: #5c6ae0;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #9e9e9e;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiInput-underline.Mui-focused:after {
  transform: scaleX(1);
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: #f44336;
  transform: scaleX(1);
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  bottom: 0;
  content: '\0o0a0';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: dotted;
}
@media (hover: none) {
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
}


.PrivateNotchedOutline-legend-17 {
  line-height: 11px;
  padding: 0;
  text-align: left;
  transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.PrivateNotchedOutline-legendLabelled-18 {
  display: block;
  font-size: 0.75em;
  height: 11px;
  max-width: 0.01px;
  padding: 0;
  text-align: left;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  visibility: hidden;
  width: auto;
}

.PrivateNotchedOutline-legendLabelled-18 > span {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.PrivateNotchedOutline-legendNotched-19 {
  max-width: 1000px;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}


.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5c6ae0;
}

.MuiOutlinedInput-adornedStart {
  padding-left: 14px;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 14px;
}

.MuiOutlinedInput-multiline {
  padding: 18.5px 14px;
}

.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
  padding-bottom: 10.5px;
  padding-top: 10.5px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedInput-input {
  padding: 1rem;
}

.MuiOutlinedInput-input:-webkit-autofill {
  border-radius: inherit;
}

.MuiOutlinedInput-inputMarginDense {
  padding-bottom: 10.5px;
  padding-top: 10.5px;
}

.MuiOutlinedInput-inputMultiline {
  padding: 0;
}

.MuiOutlinedInput-inputAdornedStart {
  padding-left: 0;
}

.MuiOutlinedInput-inputAdornedEnd {
  padding-right: 0;
}


.MuiMenu-paper {
  max-height: calc(100% - 96px);
  -webkit-overflow-scrolling: touch;
}
.MuiMenu-list {
  outline: 0;
  max-height: 250px;
}


.MuiFormHelperText-marginDense {
  margin-top: 4px;
}

.MuiFormHelperText-contained {
  margin-left: 14px;
  margin-right: 14px;
}



.MuiSelect-select {
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.MuiSelect-select:focus {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.MuiSelect-select::-ms-expand {
  display: none;
}
.MuiSelect-select.Mui-disabled {
  cursor: default;
}
.MuiSelect-select[multiple] {
  height: auto;
}
.MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
  background-color: #fff;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
}
.MuiSelect-filled.MuiSelect-filled {
  padding-right: 32px;
}
.MuiSelect-outlined {
  border-radius: 4px;
}
.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;
}
.MuiSelect-selectMenu {
  height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
}
.MuiSelect-icon.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiSelect-iconOpen {
  transform: rotate(180deg);
}
.MuiSelect-iconFilled {
  right: 7px;
}
.MuiSelect-iconOutlined {
  right: 7px;
}
.MuiSelect-nativeInput {
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}


.MuiCircularProgress-static {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiCircularProgress-indeterminate {
  animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite;
}

.MuiCircularProgress-determinate {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiCircularProgress-colorPrimary {
  color: #9e9e9e;
}

.MuiCircularProgress-colorSecondary {
  color: #5c6ae0;
}

.MuiCircularProgress-svg {
  display: block;
}

.MuiCircularProgress-circle {
  stroke: currentColor;
}

.MuiCircularProgress-circleStatic {
  transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiCircularProgress-circleIndeterminate {
  animation: MuiCircularProgress-keyframes-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
}

.MuiCircularProgress-circleDeterminate {
  transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
@-webkit-keyframes MuiCircularProgress-keyframes-circular-rotate {
  0% {
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes MuiCircularProgress-keyframes-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.MuiCircularProgress-circleDisableShrink {
  animation: none;
}


.MuiMenuItem-dense {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
  min-height: auto;
}


.MuiCheckbox-colorPrimary.Mui-checked {
  color: #5c6ae0;
}
.MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorPrimary.Mui-checked:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color: transparent;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #5c6ae0;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #5c6ae0;
}
.MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorPrimary.Mui-checked:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color: transparent;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #5c6ae0;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}


.MuiRadio-colorPrimary.Mui-checked {
  color: #5c6ae0;
}
.MuiRadio-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiRadio-colorPrimary.Mui-checked:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiRadio-colorPrimary.Mui-checked:hover {
    background-color: transparent;
  }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #5c6ae0;
}
.MuiRadio-colorSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}



.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
  margin-top: 16px;
}

.MuiInputAdornment-positionStart {
  margin-right: 8px;
}

.MuiInputAdornment-positionEnd {
  margin-left: 8px;
}

.MuiInputAdornment-disablePointerEvents {
  pointer-events: none;
}


.MuiMenuItem-dense {
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
  min-height: auto;
}


.MuiListItemText-multiline {
  margin-bottom: 6px;
  margin-top: 6px;
}

.MuiListItemText-dense {
  margin: 0;
}

.MuiListItemText-inset {
  padding-left: 56px;
}


.MuiTooltip-popper {
  z-index: 1500;
  pointer-events: none;
}
.MuiTooltip-popperInteractive {
  pointer-events: auto;
}
.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
  top: 0;
  left: 0;
  margin-top: -0.71em;
  margin-left: 4px;
  margin-right: 4px;
}
.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow {
  left: 0;
  bottom: 0;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: -0.71em;
}
.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow {
  left: 0;
  width: 0.71em;
  height: 1em;
  margin-top: 4px;
  margin-left: -0.71em;
  margin-bottom: 4px;
}
.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow {
  right: 0;
  width: 0.71em;
  height: 1em;
  margin-top: 4px;
  margin-right: -0.71em;
  margin-bottom: 4px;
}
.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow::before {
  transform-origin: 0 0;
}
.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow::before {
  transform-origin: 100% 100%;
}
.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before {
  transform-origin: 100% 0;
}
.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow::before {
  transform-origin: 0 100%;
}
.MuiTooltip-tooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 1rem;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Open Sans",Arial,sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}
.MuiTooltip-tooltipArrow {
  margin: 0;
  position: relative;
}
.MuiTooltip-arrow {
  color: rgba(97, 97, 97, 0.9);
  width: 1em;
  height: 0.71em;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
}
.MuiTooltip-arrow::before {
  width: 100%;
  height: 100%;
  margin: auto;
  content: "";
  display: block;
  transform: rotate(45deg);
  background-color: currentColor;
}
.MuiTooltip-touch {
  padding: 8px 16px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.14286em;
}
.MuiTooltip-tooltipPlacementLeft {
  margin: 0 24px ;
  transform-origin: right center;
}
@media (min-width:600px) {
  .MuiTooltip-tooltipPlacementLeft {
    margin: 0 14px;
  }
}
.MuiTooltip-tooltipPlacementRight {
  margin: 0 24px;
  transform-origin: left center;
}
@media (min-width:600px) {
  .MuiTooltip-tooltipPlacementRight {
    margin: 0 14px;
  }
}
.MuiTooltip-tooltipPlacementTop {
  margin: 24px 0;
  transform-origin: center bottom;
}
@media (min-width:600px) {
  .MuiTooltip-tooltipPlacementTop {
    margin: 14px 0;
  }
}
.MuiTooltip-tooltipPlacementBottom {
  margin: 24px 0;
  transform-origin: center top;
}
@media (min-width:600px) {
  .MuiTooltip-tooltipPlacementBottom {
    margin: 14px 0;
  }
}


.MuiSwitch-edgeStart {
  margin-left: -8px;
}

.MuiSwitch-edgeEnd {
  margin-right: -8px;
}

.MuiSwitch-switchBase {
  color: #fafafa;
  left: 0;
  position: absolute;
  top: 0;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
}

.MuiSwitch-switchBase.Mui-checked {
  transform: translateX(20px);
}

.MuiSwitch-switchBase.Mui-disabled {
  color: #bdbdbd;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 0.5;
}

.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  opacity: 0.12;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #9e9e9e;
}

.MuiSwitch-colorPrimary.Mui-disabled {
  color: #bdbdbd;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #9e9e9e;
}

.MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-colorPrimary.Mui-checked:hover {
  background-color: rgba(158, 158, 158, 0.04);
}
@media (hover: none) {
  .MuiSwitch-colorPrimary.Mui-checked:hover {
    background-color: transparent;
  }
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #5c6ae0;
}

.MuiSwitch-colorSecondary.Mui-disabled {
  color: #bdbdbd;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #5c6ae0;
}

.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: rgba(255, 152, 0, 0.04);
}
@media (hover: none) {
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}

.MuiSwitch-sizeSmall {
  height: 24px;
  padding: 7px;
  width: 40px;
}

.MuiSwitch-sizeSmall .MuiSwitch-thumb {
  height: 16px;
  width: 16px;
}

.MuiSwitch-sizeSmall .MuiSwitch-switchBase {
  padding: 4px;
}

.MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(16px);
}

.MuiSwitch-input {
  left: -100%;
  width: 300%;
}

.MuiSwitch-thumb {
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12);
  height: 20px;
  width: 20px;
}

.MuiSwitch-track {
  background-color: #000;
  border-radius: 7px;
  height: 100%;
  opacity: 0.38;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  z-index: -1;
}


.MuiFormGroup-row {
  flex-direction: row;
}


.MuiCollapse-entered {
  height: auto;
  overflow: visible;
}

.MuiCollapse-hidden {
  visibility: hidden;
}

.MuiCollapse-wrapper {
  display: flex;
}

.MuiCollapse-wrapperInner {
  width: 100%;
}


.MuiListItemIcon-alignItemsFlexStart {
  margin-top: 8px;
}


.MuiToolbar-gutters {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width:600px) {
  .MuiToolbar-gutters {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.MuiToolbar-regular {
  min-height: 56px;
}
@media (min-width:0) and (orientation: landscape) {
  .MuiToolbar-regular {
    min-height: 48px;
  }
}
@media (min-width:600px) {
  .MuiToolbar-regular {
    min-height: 64px;
  }
}

.MuiToolbar-dense {
  min-height: 48px;
}


.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
}
.MuiGrid-zeroMinWidth {
  min-width: 0;
}
.MuiGrid-direction-xs-column {
  flex-direction: column;
}
.MuiGrid-direction-xs-column-reverse {
  flex-direction: column-reverse;
}
.MuiGrid-direction-xs-row-reverse {
  flex-direction: row-reverse;
}
.MuiGrid-wrap-xs-nowrap {
  flex-wrap: nowrap;
}
.MuiGrid-wrap-xs-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.MuiGrid-align-items-xs-center {
  align-items: center;
}
.MuiGrid-align-items-xs-flex-start {
  align-items: flex-start;
}
.MuiGrid-align-items-xs-flex-end {
  align-items: flex-end;
}
.MuiGrid-align-items-xs-baseline {
  align-items: baseline;
}
.MuiGrid-align-content-xs-center {
  align-content: center;
}
.MuiGrid-align-content-xs-flex-start {
  align-content: flex-start;
}
.MuiGrid-align-content-xs-flex-end {
  align-content: flex-end;
}
.MuiGrid-align-content-xs-space-between {
  align-content: space-between;
}
.MuiGrid-align-content-xs-space-around {
  align-content: space-around;
}
.MuiGrid-justify-content-xs-center {
  justify-content: center;
}
.MuiGrid-justify-content-xs-flex-end {
  justify-content: flex-end;
}
.MuiGrid-justify-content-xs-space-between {
  justify-content: space-between;
}
.MuiGrid-justify-content-xs-space-around {
  justify-content: space-around;
}
.MuiGrid-justify-content-xs-space-evenly {
  justify-content: space-evenly;
}
.MuiGrid-spacing-xs-1 {
  width: calc(100% + 8px);
  margin: -4px;
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 4px;
}
.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px);
  margin: -8px;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 8px;
}
.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 12px;
}
.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -16px;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 16px;
}
.MuiGrid-spacing-xs-5 {
  width: calc(100% + 40px);
  margin: -20px;
}
.MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 20px;
}
.MuiGrid-spacing-xs-6 {
  width: calc(100% + 48px);
  margin: -24px;
}
.MuiGrid-spacing-xs-6 > .MuiGrid-item {
  padding: 24px;
}
.MuiGrid-spacing-xs-7 {
  width: calc(100% + 56px);
  margin: -28px;
}
.MuiGrid-spacing-xs-7 > .MuiGrid-item {
  padding: 28px;
}
.MuiGrid-spacing-xs-8 {
  width: calc(100% + 64px);
  margin: -32px;
}
.MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 32px;
}
.MuiGrid-spacing-xs-9 {
  width: calc(100% + 72px);
  margin: -36px;
}
.MuiGrid-spacing-xs-9 > .MuiGrid-item {
  padding: 36px;
}
.MuiGrid-spacing-xs-10 {
  width: calc(100% + 80px);
  margin: -40px;
}
.MuiGrid-spacing-xs-10 > .MuiGrid-item {
  padding: 40px;
}
.MuiGrid-grid-xs-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
}
.MuiGrid-grid-xs-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
}
.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
}
.MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}
.MuiGrid-grid-xs-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}
.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
}
.MuiGrid-grid-xs-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
}
.MuiGrid-grid-xs-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
}
.MuiGrid-grid-xs-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
}
.MuiGrid-grid-xs-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
}
.MuiGrid-grid-xs-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
}
.MuiGrid-grid-xs-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
}
.MuiGrid-grid-xs-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
}
.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width:600px) {
  .MuiGrid-grid-sm-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-sm-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-sm-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-sm-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-sm-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-sm-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-sm-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-sm-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-sm-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:960px) {
  .MuiGrid-grid-md-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-md-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-md-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-md-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-md-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-md-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-md-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:1280px) {
  .MuiGrid-grid-lg-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-lg-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-lg-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-lg-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-lg-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-lg-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-lg-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-lg-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-lg-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-lg-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-lg-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-lg-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-lg-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-lg-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width:1920px) {
  .MuiGrid-grid-xl-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-xl-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-xl-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-xl-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-xl-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-xl-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-xl-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-xl-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-xl-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-xl-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-xl-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-xl-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-xl-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-xl-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}


.MuiLinearProgress-colorPrimary {
  background-color: #5c6ae0;
}

.MuiLinearProgress-colorSecondary {
  background-color: #e5e7fa;
}

.MuiLinearProgress-buffer {
  background-color: transparent;
}

.MuiLinearProgress-query {
  transform: rotate(180deg);
}

.MuiLinearProgress-dashed {
  animation: MuiLinearProgress-keyframes-buffer 3s infinite linear;
  height: 100%;
  margin-top: 0;
  position: absolute;
  width: 100%;
}

.MuiLinearProgress-dashedColorPrimary {
  background-image: radial-gradient(rgb(218, 218, 218) 0%, rgb(218, 218, 218) 16%, transparent 42%);
  background-position: 0 -23px;
  background-size: 10px 10px;
}

.MuiLinearProgress-dashedColorSecondary {
  background-image: radial-gradient(rgb(255, 215, 158) 0%, rgb(255, 215, 158) 16%, transparent 42%);
  background-position: 0 -23px;
  background-size: 10px 10px;
}

.MuiLinearProgress-bar {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left;
  transition: transform 0.2s linear;
  width: 100%;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #9e9e9e;
}

.MuiLinearProgress-barColorSecondary {
  background-color: #5c6ae0;
}

.MuiLinearProgress-bar1Indeterminate {
  animation: MuiLinearProgress-keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  width: auto;
}

.MuiLinearProgress-bar1Determinate {
  transition: transform .4s linear;
}

.MuiLinearProgress-bar1Buffer {
  transition: transform .4s linear;
  z-index: 1;
}

.MuiLinearProgress-bar2Indeterminate {
  animation: MuiLinearProgress-keyframes-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  width: auto;
}

.MuiLinearProgress-bar2Buffer {
  transition: transform .4s linear;
}
@-webkit-keyframes MuiLinearProgress-keyframes-indeterminate1 {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes MuiLinearProgress-keyframes-indeterminate2 {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes MuiLinearProgress-keyframes-buffer {
  0% {
    background-position: 0 -23px;
    opacity: 1;
  }

  50% {
    background-position: 0 -23px;
    opacity: 0;
  }

  100% {
    background-position: -200px -23px;
    opacity: 1;
  }
}


.MuiDrawer-docked {
  flex: 0 0 auto;
}

.MuiDrawer-paper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 1200;
}

.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
}

.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
}

.MuiDrawer-paperAnchorTop {
  bottom: auto;
  height: auto;
  left: 0;
  max-height: 100%;
  right: 0;
  top: 0;
}

.MuiDrawer-paperAnchorBottom {
  bottom: 0;
  height: auto;
  left: 0;
  max-height: 100%;
  right: 0;
  top: auto;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiDrawer-paperAnchorDockedTop {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiDrawer-paperAnchorDockedRight {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiDrawer-paperAnchorDockedBottom {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}


.MuiTable-stickyHeader {
  border-collapse: separate;
}


.MuiTableCell-head {
  color: #9e9e9e;
  font-weight: 500;
  line-height: 2.4rem;
  max-width: 25rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87);
}

.MuiTableCell-footer {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.2rem;
  line-height: 2.1rem;
}

.MuiTableCell-sizeSmall {
  padding: 6px 24px 6px 16px;
}

.MuiTableCell-sizeSmall:last-child {
  padding-right: 16px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
  padding: 0 12px 0 16px;
  width: 24px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:last-child {
  padding-left: 12px;
  padding-right: 16px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox > * {
  padding: 0;
}

.MuiTableCell-paddingCheckbox {
  padding: 0 0 0 4px;
  padding-top: .5rem !important;
  width: 48px;
}

.MuiTableCell-paddingCheckbox:last-child {
  padding-left: 0;
  padding-right: 4px;
}

.MuiTableCell-paddingNone {
  padding: 0;
}

.MuiTableCell-paddingNone:last-child {
  padding: 0;
}

.MuiTableCell-alignLeft {
  text-align: left;
}

.MuiTableCell-alignCenter {
  text-align: center;
}

.MuiTableCell-alignRight {
  flex-direction: row-reverse;
  text-align: right;
}

.MuiTableCell-alignJustify {
  text-align: justify;
}

.MuiTableCell-stickyHeader {
  background-color: #fafafa;
  left: 0;
  position: sticky;
  top: 0;
  z-index: 2;
}


.MuiPaginationItem-page {
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiPaginationItem-page:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiPaginationItem-page.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.MuiPaginationItem-page.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.MuiPaginationItem-page.Mui-disabled {
  opacity: 0.38;
}

.MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.MuiPaginationItem-page.Mui-selected.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.26);
  opacity: 1;
}
@media (hover: none) {
  .MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
@media (hover: none) {
  .MuiPaginationItem-page:hover {
    background-color: transparent;
  }
}

.MuiPaginationItem-sizeSmall {
  border-radius: 13px;
  height: 26px;
  margin: 0 1px;
  min-width: 26px;
  padding: 0 4px;
}

.MuiPaginationItem-sizeSmall .MuiPaginationItem-icon {
  font-size: 1.8rem;
}

.MuiPaginationItem-sizeLarge {
  border-radius: 20px;
  font-size: 1.5rem;
  height: 40px;
  min-width: 40px;
  padding: 0 10px;
}

.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 2.2rem;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #9e9e9e;
  color: #fff;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
  background-color: rgb(110, 110, 110);
}

.MuiPaginationItem-textPrimary.Mui-selected.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
    background-color: #9e9e9e;
  }
}

.MuiPaginationItem-textSecondary.Mui-selected {
  background-color: #5c6ae0;
  color: #fff;
}

.MuiPaginationItem-textSecondary.Mui-selected:hover, .MuiPaginationItem-textSecondary.Mui-selected.Mui-focusVisible {
  background-color: rgb(178, 106, 0);
}

.MuiPaginationItem-textSecondary.Mui-selected.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiPaginationItem-textSecondary.Mui-selected:hover, .MuiPaginationItem-textSecondary.Mui-selected.Mui-focusVisible {
    background-color: #5c6ae0;
  }
}

.MuiPaginationItem-outlined {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.MuiPaginationItem-outlined.Mui-selected.Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiPaginationItem-outlinedPrimary.Mui-selected {
  background-color: rgba(158, 158, 158, 0.12);
  border: 1px solid rgba(158, 158, 158, 0.5);
  color: #9e9e9e;
}

.MuiPaginationItem-outlinedPrimary.Mui-selected:hover, .MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-focusVisible {
  background-color: rgba(158, 158, 158, 0.16);
}

.MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiPaginationItem-outlinedPrimary.Mui-selected:hover, .MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-focusVisible {
    background-color: transparent;
  }
}

.MuiPaginationItem-outlinedSecondary.Mui-selected {
  background-color: rgba(255, 152, 0, 0.12);
  border: 1px solid rgba(255, 152, 0, 0.5);
  color: #5c6ae0;
}

.MuiPaginationItem-outlinedSecondary.Mui-selected:hover, .MuiPaginationItem-outlinedSecondary.Mui-selected.Mui-focusVisible {
  background-color: rgba(255, 152, 0, 0.16);
}

.MuiPaginationItem-outlinedSecondary.Mui-selected.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiPaginationItem-outlinedSecondary.Mui-selected:hover, .MuiPaginationItem-outlinedSecondary.Mui-selected.Mui-focusVisible {
    background-color: transparent;
  }
}

.MuiPaginationItem-rounded {
  border-radius: 4px;
}

.MuiPaginationItem-ellipsis {
  height: auto;
}

.MuiPaginationItem-ellipsis.Mui-disabled {
  opacity: 0.38;
}

.MuiPaginationItem-icon {
  font-size: 2rem;
  margin: 0 -8px;
}


.MuiPagination-ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}


.MuiTablePagination-toolbar {
  min-height: 0;
  padding: 0;
  padding-right: 2px;
}

.MuiTablePagination-spacer {
  flex: 1 1 100%;
}

.MuiTablePagination-caption {
  flex-shrink: 0;
}

.MuiTablePagination-selectRoot {
  margin-left: 8px;
  margin-right: 32px;
}

.MuiTablePagination-select {
  padding-left: 8px;
  padding-right: 24px;
  text-align: right;
  text-align-last: right;
}

.MuiTablePagination-input {
  color: inherit;
  flex-shrink: 0;
  font-size: inherit;
  margin: 0 .5rem;
}

.MuiTablePagination-actions {
  display: none;
  flex-shrink: 0;
  margin-left: 20px;
}


.MuiBadge-badge {
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 1.2rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Open Sans",Arial,sans-serif;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.MuiBadge-colorPrimary {
  color: #fff;
  background-color: #9e9e9e;
}
.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #5c6ae0;
}
.MuiBadge-colorError {
  color: #fff;
  background-color: #f44336;
}
.MuiBadge-dot {
  height: 8px;
  padding: 0;
  min-width: 8px;
  border-radius: 4px;
}
.MuiBadge-anchorOriginTopRightRectangle {
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible {
  transform: scale(0) translate(50%, -50%);
}
.MuiBadge-anchorOriginTopRightRectangular {
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-invisible {
  transform: scale(0) translate(50%, -50%);
}
.MuiBadge-anchorOriginBottomRightRectangle {
  right: 0;
  bottom: 0;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
}
.MuiBadge-anchorOriginBottomRightRectangle.MuiBadge-invisible {
  transform: scale(0) translate(50%, 50%);
}
.MuiBadge-anchorOriginBottomRightRectangular {
  right: 0;
  bottom: 0;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
}
.MuiBadge-anchorOriginBottomRightRectangular.MuiBadge-invisible {
  transform: scale(0) translate(50%, 50%);
}
.MuiBadge-anchorOriginTopLeftRectangle {
  top: 0;
  left: 0;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.MuiBadge-anchorOriginTopLeftRectangle.MuiBadge-invisible {
  transform: scale(0) translate(-50%, -50%);
}
.MuiBadge-anchorOriginTopLeftRectangular {
  top: 0;
  left: 0;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.MuiBadge-anchorOriginTopLeftRectangular.MuiBadge-invisible {
  transform: scale(0) translate(-50%, -50%);
}
.MuiBadge-anchorOriginBottomLeftRectangle {
  left: 0;
  bottom: 0;
  transform: scale(1) translate(-50%, 50%);
  transform-origin: 0% 100%;
}
.MuiBadge-anchorOriginBottomLeftRectangle.MuiBadge-invisible {
  transform: scale(0) translate(-50%, 50%);
}
.MuiBadge-anchorOriginBottomLeftRectangular {
  left: 0;
  bottom: 0;
  transform: scale(1) translate(-50%, 50%);
  transform-origin: 0% 100%;
}
.MuiBadge-anchorOriginBottomLeftRectangular.MuiBadge-invisible {
  transform: scale(0) translate(-50%, 50%);
}
.MuiBadge-anchorOriginTopRightCircle {
  top: 14%;
  right: 14%;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.MuiBadge-anchorOriginTopRightCircle.MuiBadge-invisible {
  transform: scale(0) translate(50%, -50%);
}
.MuiBadge-anchorOriginTopRightCircular {
  top: 14%;
  right: 14%;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.MuiBadge-anchorOriginTopRightCircular.MuiBadge-invisible {
  transform: scale(0) translate(50%, -50%);
}
.MuiBadge-anchorOriginBottomRightCircle {
  right: 14%;
  bottom: 14%;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
}
.MuiBadge-anchorOriginBottomRightCircle.MuiBadge-invisible {
  transform: scale(0) translate(50%, 50%);
}
.MuiBadge-anchorOriginBottomRightCircular {
  right: 14%;
  bottom: 14%;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
}
.MuiBadge-anchorOriginBottomRightCircular.MuiBadge-invisible {
  transform: scale(0) translate(50%, 50%);
}
.MuiBadge-anchorOriginTopLeftCircle {
  top: 14%;
  left: 14%;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.MuiBadge-anchorOriginTopLeftCircle.MuiBadge-invisible {
  transform: scale(0) translate(-50%, -50%);
}
.MuiBadge-anchorOriginTopLeftCircular {
  top: 14%;
  left: 14%;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.MuiBadge-anchorOriginTopLeftCircular.MuiBadge-invisible {
  transform: scale(0) translate(-50%, -50%);
}
.MuiBadge-anchorOriginBottomLeftCircle {
  left: 14%;
  bottom: 14%;
  transform: scale(1) translate(-50%, 50%);
  transform-origin: 0% 100%;
}
.MuiBadge-anchorOriginBottomLeftCircle.MuiBadge-invisible {
  transform: scale(0) translate(-50%, 50%);
}
.MuiBadge-anchorOriginBottomLeftCircular {
  left: 14%;
  bottom: 14%;
  transform: scale(1) translate(-50%, 50%);
  transform-origin: 0% 100%;
}
.MuiBadge-anchorOriginBottomLeftCircular.MuiBadge-invisible {
  transform: scale(0) translate(-50%, 50%);
}
.MuiBadge-invisible {
  transition: transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.MuiFab-label {
  align-items: inherit;
  display: inherit;
  justify-content: inherit;
  width: 100%;
}

.MuiFab-primary {
  background-color: #9e9e9e;
  color: #fff;
}

.MuiFab-primary:hover {
  background-color: rgb(110, 110, 110);
}
@media (hover: none) {
  .MuiFab-primary:hover {
    background-color: #9e9e9e;
  }
}

.MuiFab-secondary {
  background-color: #5c6ae0;
  color: #fff;
}

.MuiFab-secondary:hover {
  background-color: #3547d9;
}

.MuiFab-secondary .MuiIcon-root {
  color: #fff;
}
@media (hover: none) {
  .MuiFab-secondary:hover {
    background-color: #5c6ae0;
  }
}

.MuiFab-extended {
  border-radius: 24px;
  height: 48px;
  min-height: auto;
  min-width: 48px;
  padding: 0 16px;
  width: auto;
}

.MuiFab-extended.MuiFab-sizeSmall {
  border-radius: 17px;
  height: 34px;
  min-width: 34px;
  padding: 0 8px;
  width: auto;
}

.MuiFab-extended.MuiFab-sizeMedium {
  border-radius: 20px;
  height: 40px;
  min-width: 40px;
  padding: 0 16px;
  width: auto;
}

.MuiFab-colorInherit {
  color: inherit;
}

.MuiFab-sizeSmall {
  height: 40px;
  width: 40px;
}

.MuiFab-sizeMedium {
  height: 48px;
  width: 48px;
}
`;
export default necessaryLegacyStylesProps;
