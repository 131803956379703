/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import moment from 'moment';
import cx from 'classnames';

import style from './slide.module.scss';
import Text from '../../../../utils/text';

type Extraction = {
  value: string,
}

type Analysis = {
  classifications: Extraction[],
  logos: Extraction[],
  text: Extraction[],
}
type Slide = {
  author: string,
  basetype: string
  body: string,
  fpid: string,
  analysis: Analysis,
  room: string,
  sort_date: string,
  source: string,
  site: { title: string } | string,
}
interface SlideProps {
  data: Slide,
  type: 'video' | 'image',
}

export const Slide = ({
  data,
  type,
}: SlideProps): JSX.Element => (
  <div className={cx([style.slide, type === 'video' ? style.video : ''])}>
    <div className={style.header}>
      <table>
        <thead>
          <tr><th colSpan={2} className={cx([style.left, 'h2'])}>Media Details</th></tr>
        </thead>
        <tbody>
          <tr>
            <td>Date:</td><td>{moment.utc(data?.sort_date).format('MM/DD/YYYY')}</td>
          </tr>
          {Boolean(data?.basetype) &&
            <tr>
              <td>Source:</td><td>{Text.Sentence(data?.basetype)}</td>
            </tr>}
          {Boolean(data?.site) &&
            <tr>
              <td>Site:</td><td>{Text.Highlight(data?.site?.title)}{data?.room ? `:${data?.room}` : ''}</td>
            </tr>}
          {Boolean(data?.author) &&
            <tr>
              <td>Author:</td><td>{Text.Highlight(data?.author)}</td>
            </tr>}
          {Boolean(data?.body) &&
            <tr>
              <td>Post:</td><td>{Text.Highlight(data?.body)}</td>
            </tr>}
        </tbody>
      </table>
    </div>
    <div className={style.content}>
      <video
        id="video"
        autoPlay={false}
        src={data?.source}
        controls
        height="auto"
        width="auto" />
    </div>
    <div className={style.footer}>
      <table>
        <thead>
          <tr><th colSpan={2} className={cx([style.left, 'h2'])}>Extracted Entities</th></tr>
        </thead>
        <tbody>
          {Boolean(data?.analysis?.text?.length) &&
            <tr>
              <td>Text:</td><td>{data?.analysis?.text?.map(v => v?.value)?.join(', ')}</td>
            </tr>}
          {Boolean(data?.analysis?.classifications?.length) &&
            <tr>
              <td>Classes:</td><td>{data?.analysis?.classifications?.map(v => v?.value)?.join(', ')}</td>
            </tr>}
          {Boolean(data?.analysis?.logos?.length) &&
            <tr>
              <td>Logos:</td><td>{data?.analysis?.logos?.map(v => v?.value)?.join(', ')}</td>
            </tr>}
        </tbody>
      </table>
    </div>
  </div>
    );
