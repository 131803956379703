import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  Collapse,
  CircularProgress,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Tab,
} from '@mui/material';

import style from './threads.module.scss';
import Text from '../../utils/text';
import Chips from '../filters/Chips';
import TextFilter from '../filters/TextFilter';
import DateFilter from '../filters/DateFilter';
import InternalLink from '../utils/InternalLink';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';
import { ThreadNoResults, ListThreadResults } from './Board';

const useStyles = makeStyles(theme => ({
  thread: {
    '& .MuiTabPanel-root': {
      padding: 0,
      '& .MuiButton-root': {
        margin: `${theme.spacing(1)} 0`,
      },
    },
  },
}));

const Thread = ({
  filters,
  inline,
  meta,
  search,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [tab, setTab] = useState('search');
  const [load, setLoad] = useState(true);
  const [values, setValues] = useState(map());
  const [text, setText] = useState('');

  const onToggle = (show) => {
    const btn = document.getElementsByName('thread.filters')[0];
    const searchValue = document.getElementsByName('thread.search')[0];
    if (searchValue || show) btn.click();
  };

  const onClear = () => {
    setValues(map());
    setLoad(true);
  };

  const onThread = (k, v) => ({
    pathname: '/home/search/forums',
    query: { query: '', [k]: v },
  });

  const onDelete = (option) => {
    const fpid = meta.getIn(['container', 'fpid']);
    const value = option.values
      ? option.values.reduce((acc, cur) => ({ ...acc, [cur]: search.getIn(['defaults', 'forums', cur]) }), {})
      : ({ [option.value]: search.getIn(['defaults', 'forums', option.value]) });
    const query = { ...values.toJS(), ...value, query: text };
    SearchActions.set(['search', 'result', 'thread_inline'], map());
    SearchActions.search('inline.thread', fpid, query);
    setLoad(true);
  };

  const onSearch = (clear) => {
    const fpid = meta.getIn(['container', 'fpid']);
    if (clear) {
      const query = { query: text };
      SearchActions.search('inline.threads', fpid, false, query);
      onClear();
      onToggle();
    } else {
      const query = { ...values.toJS(), query: text };
      SearchActions.search('inline.threads', fpid, false, query);
      setLoad(true);
      setTab(3);
      onToggle();
    }
  };

  const onRoute = (e, v) => {
    e.stopPropagation();
    const { pathname, query, hash } = History.getCurrentLocation();
    const fpid = v.getIn(['fpid']);
    const id = moment.utc(v.getIn(['sort_date'])).unix();
    const updates = { ...query, fpid, id, query: text };
    History.navigateTo({ pathname, hash, query: updates });
    SearchActions.search('threads', v.getIn(['container', 'fpid']));
  };

  const count = (
    filters
      .filter(v => v)
      .filterNot((v, k) => ['id', 'fpid', 'query', 'sort', 'skip', 'since', 'until', 'limit', 'interval'].includes(k))
      .filterNot((v, k) => (v === search.getIn(['defaults', 'forums', k])))
      .size
  );

  const options = (
    !values ? [] : [
      { type: ['forums'],
        key: 'date',
        label: 'Date',
        icon: 'date_range',
        fields: ['since', 'until'],
        values: ['since', 'until'],
        dialog: (
          <DateFilter
            inline
            icon="date_range"
            text="Filter results by date"
            since={values.get('since')}
            until={values.get('until')}
            date={values.get('date')}
            dates={search.get('dates')}
            onFilter={v => setValues(values.merge(v))} />) },
      { type: ['forums'],
        key: 'body',
        label: 'Post Contains',
        icon: 'text_fields',
        fields: ['body'],
        dialog: (
          <TextFilter
            inline
            icon="text_fields"
            text="Search for post contains"
            fields={[{ value: 'body', label: 'Post Contains', type: 'text' }]}
            defaults={search.getIn(['defaults', 'forums']) || map()}
            filters={values}
            onFilter={v => setValues(values.merge(v))} />) },
      { type: ['forums'],
        key: 'author',
        label: 'Username',
        icon: 'account_circle',
        fields: ['author', 'author_exact'],
        dialog: (
          <TextFilter
            inline
            icon="account_circle"
            text="Search by Username"
            fields={[
              { value: 'author', label: 'Username', type: 'text' },
              { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' }]}
            defaults={search.getIn(['defaults', 'forums']) || map()}
            filters={values}
            onFilter={v => setValues(values.merge(v))} />) },
    ]
  );

  useEffect(() => {
    setValues(filters);
    setText(filters.get('query'));
  }, [filters]);

  return (
    <Grid
      fluid
      className={cx([
      style.base,
      style.thread,
      classes.thread,
      ])}>
      <Row>
        <Col xs={12}>
          <TabContext value={tab}>
            <TabList
              variant="fullWidth"
              indicatorColor="secondary"
              onChange={(event, val) => setTab(val)}
              className={style.tabs}>
              <Tab label="Rooms" value="rooms" />
              <Tab label="Forum" value="forum" />
              <Tab label="Thread" value="thread" />
              <Tab label="Search" value="search" />
            </TabList>
            <TabPanel value="rooms">
              {(meta.isEmpty() || meta.get('rooms', list()).isEmpty()) &&
              <CircularProgress />}
              {!meta.isEmpty() && !meta.get('rooms', list()).isEmpty() &&
              <div className={style.room}>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Room:</div>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Post Count:</div>
                </div>
                {meta.getIn(['rooms']).map(v => (
                  <div className={style.table} key={v.get('fpid')}>
                    <InternalLink
                      className={cx([v.get('fpid') && style.a, 'a'])}
                      to={onThread('room_title', Text.Strip(`${v.get('fpid')}::${Text.Sentence(v.get('title'))}`))}>
                      {v.get('title')}
                    </InternalLink>
                    <div>{(v.get('count') || '0').toLocaleString()}</div>
                  </div>
                ))}
              </div>}
            </TabPanel>
            <TabPanel value="forum">
              <div className={style.body}>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Forum:</div>
                  <InternalLink
                    data-cy="forum"
                    className={cx([style.a, 'a'])}
                    to={onThread('sites', meta.getIn(['site', 'title']))}>
                    {Text.Highlight(meta.getIn(['site', 'title']))}
                  </InternalLink>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Forum URL:</div>
                  <div data-cy="url">{meta.getIn(['site', 'source_uri'])}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Room Title:</div>
                  <InternalLink
                    data-cy="room"
                    className={cx([style.a, 'a'])}
                    to={onThread('room_title', Text.Strip(`${meta.getIn(['container', 'container', 'fpid'])}::${meta.getIn(['container', 'container', 'title'])}`))}>
                    {Text.Highlight(
                      meta.getIn(['highlight', 'container.container.title', 0]) ||
                      meta.getIn(['container', 'container', 'title']))}
                  </InternalLink>
                </div>
                {meta.hasIn('tags') && search.getIn(['properties', 'forums'])
                  .filter(v => ['Cyber Threat', 'Physical Threat', 'Reputation', 'Accessibility', 'Language'].includes(v.get('name')))
                  .sortBy(v => ['Cyber Threat', 'Physical Threat', 'Reputation', 'Accessibility', 'Language'].indexOf(v.get('name')))
                  .entrySeq()
                  .map(([k, property]) => (
                    <div key={k} className={style.table}>
                      <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>{property.get('name')}</div>
                      <div>{meta.getIn('tags')
                        .filter(v => v.getIn(['parent_tag', 'name']) === property.get('name'))
                        .map(v => v.get('name'))
                        .join(', ') || '-'}
                      </div>
                    </div>))}
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Post Count:</div>
                  <div data-cy="post-count">{meta.getIn(['forum_total'])}</div>
                </div>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Description:</div>
                <div>{Text.Highlight(
                  meta.getIn(['highlight', 'site.description.raw', 0]) ||
                  meta.getIn(['site', 'description', 'raw']))}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="thread">
              <div className={style.body}>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Title:</div>
                  <InternalLink
                    data-cy="thread"
                    className={cx([style.a, 'a'])}
                    to={onThread('thread_title', Text.Strip(`"${meta.getIn(['container', 'title'])}"`))}>
                    {Text.Highlight(
                      meta.getIn(['highlight', 'container.title', 0]) ||
                      meta.getIn(['container', 'title']))}
                  </InternalLink>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Thread URL:</div>
                  <div data-cy="url">{meta.getIn(['container', 'source_uri'])}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Thread Size:</div>
                  <div data-cy="size">{meta.getIn(['total'])}</div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="search">
              <div className={style.search}>
                <form
                  className={style.form}
                  onSubmit={(e) => { e.preventDefault(); onSearch(); }}>
                  <FormControl variant="outlined" name="text">
                    <InputLabel>Filter Results</InputLabel>
                    <OutlinedInput
                      value={text || ''}
                      data-lpignore="true"
                      onChange={event => setText(event.target.value)}
                      startAdornment={(
                        <InputAdornment position="start">
                          <Icon
                            onClick={() => onSearch()}>
                            search
                          </Icon>
                        </InputAdornment>)}
                      endAdornment={text && (
                      <InputAdornment position="end">
                        <Icon
                          onClick={() => setText()}>
                          close
                        </Icon>
                      </InputAdornment>
                      )} />
                  </FormControl>
                </form>
                <div className={style.filters}>
                  <List>
                    <ListItem
                      name="thread.filters"
                      onClick={() => setExpanded(!expanded)}>
                      <ListItemText primary={`Filters (${count})`} />
                      <ListItemIcon>
                        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      in={expanded}
                      onKeyUp={e => (e.key === 'Enter' && onSearch(true))}>
                      {options
                        .filter(v => !v.hidden)
                        .map(option => (
                          <div key={option.key} className={style.option}>
                            {option.dialog}
                          </div>))}
                      <Button
                        fullWidth
                        name="thread.clear"
                        color="primary"
                        variant="contained"
                        onClick={() => onSearch(false)}
                        endIcon={<Icon>close</Icon>}>
                        Clear Filters
                      </Button>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        name="thread.search"
                        onClick={() => onSearch(true)}
                        endIcon={<Icon>search</Icon>}>
                        Search
                      </Button>
                    </Collapse>
                  </List>
                </div>
                <div className={style.chips}>
                  {options
                    .filter(v => values.get(v.value))
                    .map(option => (
                      <Chips
                        key={option.key}
                        option={option}
                        filters={filters}
                        defaults={search.getIn(['defaults', 'forums']) || map()}
                        onToggle={() => onToggle(true)}
                        onDelete={() => onDelete(option)} />))}
                </div>
                <div className={style.results}>
                  <ThreadNoResults
                    inline={inline}
                    load={load}
                    style={style}
                  />
                  <ListThreadResults
                    inline={inline}
                    search={search}
                    onRoute={onRoute}
                    style={style}
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Col>
      </Row>
      <ReactTooltip id="action.tooltip" place="left" effect="solid" />
    </Grid>
  );
};

Thread.propTypes = {
  filters: PropTypes.object,
  inline: PropTypes.object,
  meta: PropTypes.object,
  search: PropTypes.object,
};

Thread.defaultProps = {
  filters: map(),
  inline: map(),
  meta: map(),
  search: map(),
};

export default Thread;
