import * as React from 'react';
import { useState, useRef } from 'react';
import { List, fromJS } from 'immutable';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import easyMemo from '../../../../utils/Memoize';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import Query from '../../query';
import { ExploreTopic } from '../../../../../core/types/ExploreTopic';
import {
  editBottomRowDialogStyles,
  setFunction,
} from '../EditIntelReportsDialog/EditIntelReportsDialog';
import { CategoryMenu } from '../../../../utils/CategoryMenu/CategoryMenu';
import { MenuItem } from '../../../../utils/CategoryMenu/MenuItem';
import Text from '../../../../../utils/text';
import Token from '../../../../../utils/token';


interface CategoryModel {
  id: string;
  checked: boolean;
}

const useStyles = makeStyles(() => editBottomRowDialogStyles);

export type ExpoWidgetPref = {
  id: number,
  type: string,
  options: object
};

export type expoWidgetPrefsRows = {
  topRow: ExpoWidgetPref[],
  centerRow: ExpoWidgetPref[],
  bottomRow: ExpoWidgetPref[],
};

export type UserPrefs = {
  preferences: {
    expo_dash_widgets: expoWidgetPrefsRows
  }
}

const categoryModelList = (categoryModels:CategoryModel[]) => (
  fromJS(categoryModels) as List<CategoryModel>);
interface EditExploreTopicsDialogProps {
  open: boolean;
  onCancel: () => void;
  onApply: (userPrefsCandidate: object) => void;
  categoryIdPrefs?: string[];
}

const convertCategoriesModelToStringArray = (categoriesModel:CategoryModel[]) => (
  categoriesModel
    .filter((categoryModel:CategoryModel) => categoryModel.checked)
    .map((categoryModel:CategoryModel) => categoryModel.id)
);

const renamePrivateTopicItemText = (itemText:string) => (
  Text.Sentence(itemText.replace('private_topic', `${Token.get('ogn')}’s Topics (Private)`))
);

const extractCategoryIdsFromExploreTopics = (exploreTopics: ExploreTopic[]) => (
  [...new Set(exploreTopics.map((v:ExploreTopic) => (v.categories)).flat())]
);

const makeCategoriesModelFromExploreTopics = (
  exploreTopics: ExploreTopic[], categoryIdPrefs: string[]):CategoryModel[] => (
    extractCategoryIdsFromExploreTopics(exploreTopics)
  .map((categoryId: string) => ({ id: categoryId, checked: categoryIdPrefs.includes(categoryId) }))
);

const selectAllCategoryIdsIfEmpty = (exploreTopics: ExploreTopic[],
  categoryIdPrefs: string[]):string[] => (
  categoryIdPrefs.length > 0 ? categoryIdPrefs : extractCategoryIdsFromExploreTopics(exploreTopics)
);

export const EditExploreTopicsDialog = easyMemo(({
  open,
  onCancel,
  onApply,
  categoryIdPrefs,
}: EditExploreTopicsDialogProps): JSX.Element => {
  const classes = (useStyles() as { [key:string]: never });
  const exploreTopicsPromise = (useRef(Query.ExploreTopics()) as
    { current: Promise<ExploreTopic[]> });
  const [categoriesModel, setCategoriesModel] = (useState({}) as [CategoryModel[], setFunction]);

  easyUseEffect(() => {
    exploreTopicsPromise.current.then((res:ExploreTopic[]) => {
      setCategoriesModel(
        makeCategoriesModelFromExploreTopics(
          res,
          selectAllCategoryIdsIfEmpty(res, categoryIdPrefs),
        ),
      );
    });
  }, [categoryIdPrefs]);

  const toggleCheckboxPath = (categoryIndex: number) => {
    setCategoriesModel(categoryModelList(categoriesModel)
      .updateIn([categoryIndex, 'checked'], checked => (!checked)).toJS());
  };

  const handleApply = (): void => {
    if (onApply) onApply(convertCategoriesModelToStringArray(categoriesModel));
    onCancel();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog open={open} id="dialog-settings">
      <div className={classes.header}>
        <DialogTitle>
          Recent Explore Topics
        </DialogTitle>
        <IconButton onClick={handleCancel} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.contentRoot}>
        <Box className={classes.content}>
          <div className={classes.description}>
            Refine your list of recent Explore Topics
            by selecting topic categories that interest you.
          </div>
          <div>
            <CategoryMenu
              menuText="Topic Categories"
              alwaysOpen
              menuChildren={categoriesModel}
              makeMenuChild={(categoryModel:CategoryModel, myIndex: number) => (
                <MenuItem
                  itemText={renamePrivateTopicItemText(categoryModel.id)}
                  checked={categoryModel.checked}
                  onChange={() => toggleCheckboxPath(myIndex)}
                />
              )}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleCancel}
          id="ds-cancelBtn"
            >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={handleApply}
          id="ds-applyBtn"
            >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});

EditExploreTopicsDialog.defaultProps = {
  categoryIdPrefs: [],
};
