import React, { useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircleOutline, CloudOff, ErrorOutline, ExpandMore, GetApp } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import style from '../sanctions.module.scss';

const OFACResults = ((props) => {
  const [downloading, setDownloading] = useState(false);
  const ofacRef = useRef(null);

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;
    // attach to body for Firefox
    document.body.appendChild(link);
    // simulate a click
    link.click();
    // cleanup
    document.body.removeChild(link);
  };

  const handleDownload = (ref, filename) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    html2canvas(ref.current, { scale: 2, height: Math.min(ref.current.clientHeight, 8192) })
      .then((canvas) => {
        saveImage(canvas.toDataURL(), filename);
      })
      .then(() => {
        setDownloading(false);
      });
  };

  return (
    <Paper ref={ofacRef} className={style.card}>
      <Accordion data-cy="ofacAccordion" className={style.resultaccordion}>
        <AccordionSummary
          className={style.accordionsummary}
          expandIcon={<ExpandMore />}
        >
          <div data-cy="ofacList" className={style.accordionsummarycontent}>
            <div className={style.accordiontitle}>
              <div data-cy="ofacListTitle" className={style.listtitle}>
                <div className={style.medtitle}>OFAC List</div>
                {props.listAvailable ?
                  <>
                    {props.result.ofac.results && props.result.ofac.results.length !== 0 &&
                      <span className={style.someresults}>
                        <ErrorOutline className={style.resultswarn} />
                        {props.result.ofac.results.length} Result
                        {props.result.ofac.results.length > 1 && <span>s</span>}
                      </span>
                    }
                    {props.result.ofac.results && props.result.ofac.results.length === 0 &&
                      <span className={style.noresults}>
                        <CheckCircleOutline className={style.noresultscheck} />
                        No Results
                      </span>
                    }
                  </>
                :
                  <>
                    <span className={style.offlineresults}>
                      <CloudOff className={style.offlinecloud} />
                      List Offline
                    </span>
                  </>
                }
              </div>
              {downloading && <CircularProgress />}
              <button
                type="button"
                className={style.resultdownload}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownload(ofacRef, `${props.result.searchTerm}-OFAC-results.png`);
                }}><GetApp />
              </button>
            </div>
            <div>Term Searched:   {props.result.searchTerm}</div>
            {props.listAvailable &&
              <>
                <div>URL: <a target="_blank" href={props.result.ofac.resourceUrl} rel="noreferrer">{props.result.ofac.resourceUrl}</a></div>
                <div className={style.searchgrid}>
                  <div>
                    Data Source Published Date:   {props.result.ofac.resourcePublishDate}
                  </div>
                  <div>
                    Total Records Searched:   {props.result.ofac.totalNumberOfRecordsInDataSource}
                  </div>
                  <div>
                    Date Searched:   {new Date(
                      props.result.ofac.responseDateTime,
                    ).toLocaleString()}
                  </div>
                  <div>
                    Data Source Hash Value:   {props.result.ofac.sha256}
                  </div>
                </div>
              </>
            }

          </div>
        </AccordionSummary>
        <AccordionDetails className={style.accordiondetails}>
          {!props.listAvailable ?
            <>
              <div data-cy="ofacListOffline" className={style.noresultsmessage}>
                <div className={style.offlineunderline}> </div>
                <div className={style.resulttitle}>List Offline</div>
                <div className={style.lighttext}>Search for{' '}
                  <span className={style.bolded}>"{props.result.searchTerm}"</span>
                  {' '}could not be completed on{' '}
                  {props.result.ofac.responseDateTime ?
                    new Date(props.result.ofac.responseDateTime).toLocaleString()
                    : 'Undefined Date'
                  }.
                </div>
              </div>
            </>
          :
            <>
              {props.result.ofac.results && props.result.ofac.results.length !== 0 &&
                <div className={style.resultunderline}> </div>
              }
              {props.result.ofac.results && props.result.ofac.results.length === 0 &&
                <div className={style.noresultsmessage}>
                  <div className={style.noresultunderline}> </div>
                  <div className={style.resulttitle}>No Results Found</div>
                  <div className={style.lighttext}>Search for <span className={style.bolded}>"{props.result.searchTerm}"</span> returned no results on {props.result.ofac.responseDateTime ? props.result.ofac.responseDateTime : 'Undefined Date'}.</div>
                </div>
              }
              {props.result.ofac.results && props.result.ofac.results.map((entry, index) => (
                <React.Fragment key={`${entry.lastName ? entry.lastName : ''},${entry.firstName ? entry.firstName : ''}`}>
                  <div className={style.resulttitle}>Result #{index + 1}</div>
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Details</div>
                    <div className={style.detailsgrid}>
                      <div>Type:   {entry.sdnType ? entry.sdnType : ''}</div>
                      <div>Last Name:   {entry.lastName ? entry.lastName : ''}</div>
                      <div>First Name:   {entry.firstName ? entry.firstName : ''}</div>
                      <div>Title:   {entry.title ? entry.title : ''}</div>
                      <ul>Place of Birth:   {entry.placeOfBirthList
                          && entry.placeOfBirthList.placeOfBirthItem
                          && (Array.isArray(entry.placeOfBirthList.placeOfBirthItem) ?
                          entry.placeOfBirthList.placeOfBirthItem.map(item => (
                            item.placeOfBirth && <li key={item.placeOfBirth}>{item.placeOfBirth}{item.mainEntry && item.mainEntry === 'true' && ' (main entry)'}</li>
                          ))
                        :
                          entry.placeOfBirthList.placeOfBirthItem.placeOfBirth && `${entry.placeOfBirthList.placeOfBirthItem.placeOfBirth}`
                        )}
                      </ul>
                      <ul>Date of Birth:   {entry.dateOfBirthList
                          && entry.dateOfBirthList.dateOfBirthItem
                          && (Array.isArray(entry.dateOfBirthList.dateOfBirthItem) ?
                          entry.dateOfBirthList.dateOfBirthItem.map(item => (
                            item.dateOfBirth && <li key={item.dateOfBirth}>{item.dateOfBirth}{item.mainEntry && item.mainEntry === 'true' && ' (main entry)'}</li>
                          ))
                        :
                          entry.dateOfBirthList.dateOfBirthItem.dateOfBirth && `${entry.dateOfBirthList.dateOfBirthItem.dateOfBirth}`
                        )}
                      </ul>
                      <div>List:   SDN</div>
                      <ul>Program:   {entry.programList
                          && entry.programList.program
                          && (Array.isArray(entry.programList.program) ?
                          entry.programList.program.map(program => (
                            <li key={program}>{program}</li>
                          ))
                        :
                          entry.programList.program && `${entry.programList.program}`
                        )}
                      </ul>
                      <ul>Nationality:   {entry.nationalityList
                          && entry.nationalityList.nationality
                          && (Array.isArray(entry.nationalityList.nationality) ?
                          entry.nationalityList.nationality.map(item => (
                            item.country && <li key={item.country}>{item.country}{item.mainEntry && item.mainEntry === 'true' && ' (main entry)'}</li>
                          ))
                        :
                          entry.nationalityList.nationality.country && `${entry.nationalityList.nationality.country}`
                        )}
                      </ul>
                      <ul>Citizenship:   {entry.citizenshipList
                          && entry.citizenshipList.citizenship
                          && (Array.isArray(entry.citizenshipList.citizenship) ?
                          entry.citizenshipList.citizenship.map(item => (
                            item.country && <li key={item.country}>{item.country}{item.mainEntry && item.mainEntry === 'true' && ' (main entry)'}</li>
                          ))
                        :
                          entry.citizenshipList.citizenship.country && `${entry.citizenshipList.citizenship.country}`
                        )}
                      </ul>
                    </div>
                  </div>
                  {entry.idList && entry.idList.id && (
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Identifications</div>
                    <Table className={style.resultstable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>ID#</TableCell>
                          <TableCell>Country</TableCell>
                          <TableCell>Issue Date</TableCell>
                          <TableCell>Expiry Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(entry.idList.id) ?
                          entry.idList.id.map(item => (
                            <TableRow key={JSON.stringify(item)}>
                              <TableCell>{item.idType ? item.idType : ''}</TableCell>
                              <TableCell>{item.idNumber ? item.idNumber : ''}</TableCell>
                              <TableCell>{item.idCountry ? item.idCountry : ''}</TableCell>
                              <TableCell>{item.issueDate ? item.issueDate : ''}</TableCell>
                              <TableCell>{item.expirationDate ? item.expirationDate : ''}</TableCell>
                            </TableRow>
                          ))
                        :
                          <TableRow>
                            <TableCell>{entry.idList.id.idType ? entry.idList.id.idType : ''}</TableCell>
                            <TableCell>{entry.idList.id.idNumber ? entry.idList.id.idNumber : ''}</TableCell>
                            <TableCell>{entry.idList.id.idCountry ? entry.idList.id.idCountry : ''}</TableCell>
                            <TableCell>{entry.idList.id.issueDate ? entry.idList.id.issueDate : ''}</TableCell>
                            <TableCell>{entry.idList.id.expirationDate ? entry.idList.id.expirationDate : ''}</TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </div>
                              )}
                  {entry.akaList && entry.akaList.aka && (
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Aliases</div>
                    <Table className={style.resultstable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>First Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(entry.akaList.aka) ?
                          entry.akaList.aka.map(item => (
                            <TableRow key={`${item.lastName && `${item.lastName}`},${item.firstName && `${item.firstName}`}`}>
                              <TableCell>{item.type ? item.type : ''}</TableCell>
                              <TableCell>{item.category ? item.category : ''}</TableCell>
                              <TableCell>{item.lastName ? item.lastName : ''}</TableCell>
                              <TableCell>{item.firstName ? item.firstName : ''}</TableCell>
                            </TableRow>
                          ))
                        :
                          <TableRow>
                            <TableCell>{entry.akaList.aka.type ? entry.akaList.aka.type : ''}</TableCell>
                            <TableCell>{entry.akaList.aka.category ? entry.akaList.aka.category : ''}</TableCell>
                            <TableCell>{entry.akaList.aka.lastName ? entry.akaList.aka.lastName : ''}</TableCell>
                            <TableCell>{entry.akaList.aka.firstName ? entry.akaList.aka.firstName : ''}</TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </div>
                              )}
                  {entry.addressList && entry.addressList.address && (
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Addresses</div>
                    <Table className={style.resultstable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Address</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State/Province</TableCell>
                          <TableCell>Postal Code</TableCell>
                          <TableCell>Country</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(entry.addressList.address) ?
                          entry.addressList.address.map(item => (
                            <TableRow key={JSON.stringify(item)}>
                              <TableCell>
                                {item.address1 && `${item.address1}`}{item.address2 && `, ${item.address2}`}{item.address3 && `, ${item.address3}`}
                              </TableCell>
                              <TableCell>{item.city ? item.city : ''}</TableCell>
                              <TableCell>{item.stateOrProvince ? item.stateOrProvince : ''}</TableCell>
                              <TableCell>{item.postalCode ? item.postalCode : ''}</TableCell>
                              <TableCell>{item.country ? item.country : ''}</TableCell>
                            </TableRow>
                          ))
                        :
                          <TableRow>
                            <TableCell>{entry.addressList.address.address1 ? `${entry.addressList.address.address1}${entry.addressList.address.address2 ? `, ${entry.addressList.address.address2}${entry.addressList.address.address3 ? `, ${entry.addressList.address.address3}` : ''}` : ''}` : ''}</TableCell>
                            <TableCell>{entry.addressList.address.city ? entry.addressList.address.city : ''}</TableCell>
                            <TableCell>{entry.addressList.address.stateOrProvince ? entry.addressList.address.stateOrProvince : ''}</TableCell>
                            <TableCell>{entry.addressList.address.postalCode ? entry.addressList.address.postalCode : ''}</TableCell>
                            <TableCell>{entry.addressList.address.country ? entry.addressList.address.country : ''}</TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </div>
                )}
                </React.Fragment>
              ))}
            </>
          }
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
});

OFACResults.propTypes = {
  result: PropTypes.object,
  listAvailable: PropTypes.bool,
};

OFACResults.defaultProps = {
  result: undefined,
  listAvailable: true,
};

export default OFACResults;
