import React, { useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import style from './sanctions.module.scss';
import Token from '../../utils/token';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';
import { getPrettyStatusString, getStatusString, searchStatusEnum } from './Constants/SearchStatus';

const SanctionsSearchStatus = ((props) => {
  const [status, setStatus] = useState('');
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    if (props.data && props.data.status) {
      setStatus(props.data.status);
    }
    return function cleanup() {
      isOnPage = false;
    };
  }, [props.data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!props.data || !props.data.id) {
      return;
    }
    const query = { newStatus: status };
    if (!loading) {
      setLoading(true);
    }
    Api.patchWithParams(`/ui/v4/sanctions/saved_search/${props.data.id}/status`,
      query,
      [200],
      30000,
      { Authorization: `Bearer ${Token.cke()}` },
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        throw res.data;
      })
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then(() => {
        if (!isOnPage) {
          return;
        }
        setEdit(false);
        setLoading(false);
        setConfirm(false);
        // if a callback is passed via props, call it to set the new status
        if (props.onStatusChange) {
          props.onStatusChange(status);
        }
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setLoading(false);
        setConfirm(false);
      });
  };

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleClose = () => {
    if (props.data && props.data.status) {
      setStatus(props.data.status);
    }
    setConfirm(false);
    setEdit(false);
  };

  return (
    <React.Fragment>
      <div data-cy={props['data-cy']}>
        {loading && <CircularProgress />}
        <div className={style.tooltip}>
          Status
        </div>
        {edit ? (
          <React.Fragment>
            <select
              value={status}
              className={style.statusselect}
              onChange={event => setStatus(event.target.value)}>
              <option value={getStatusString(searchStatusEnum.PREPAYMENT_CHECK)}>
                {getPrettyStatusString(searchStatusEnum.PREPAYMENT_CHECK)}
              </option>
              <option value={getStatusString(searchStatusEnum.PAYMENT_MADE)}>
                {getPrettyStatusString(searchStatusEnum.PAYMENT_MADE)}
              </option>
              <option value={getStatusString(searchStatusEnum.EXCLUDED)}>
                {getPrettyStatusString(searchStatusEnum.EXCLUDED)}
              </option>
            </select>
            <button type="button" className={style.sectionsubmit} onClick={handleConfirm}>Save</button>
            <Dialog
              data-cy="statusSaveConfirmation"
              open={confirm}
              onClose={handleClose}>
              <DialogTitle>Are you sure you want to save this status?</DialogTitle>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={handleSubmit}>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={style.statusedit}>
              {status === 'prepaymentCheck' && <span className={style.draftstatus}>Pre-Payment Check</span>}
              {status === 'paymentMade' && <span className={style.submittedstatus}>Payment Made</span>}
              {status === 'excluded' && <span className={style.nullstatus}>Excluded</span>}
              {status === 'null' && <span className={style.nullstatus}>Null</span>}
              <button type="button" onClick={() => { setEdit(true); }} className={style.editbutton}>
                <Edit />Edit
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

SanctionsSearchStatus.propTypes = {
  data: PropTypes.object,
  onStatusChange: PropTypes.func,
  'data-cy': PropTypes.string,
};

SanctionsSearchStatus.defaultProps = {
  data: undefined,
  onStatusChange: undefined,
  'data-cy': undefined,
};

export default SanctionsSearchStatus;
