import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Popover,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { fromJS, List as list } from 'immutable';
import _ from 'lodash';
import moment from 'moment';

import Table from '../../../widget/Table/Table';
import History from '../../../../utils/history';
import SearchActions from '../../../../actions/searchActions';
import SearchUtils from '../../../../utils/searchUtils';
import DateFilter from '../../../filters/DateFilter';
import TextFilter from '../../../filters/TextFilter';
import {
  getAllIncidents,
  getAllBrands,
  getVisitedLinks,
  setVisitedLink,
  getSessionFilters,
  setSessionFilters,
  getSessionSkip,
  setSessionSkip,
} from './query';
import {
  StatusOptions,
  TypeOptions,
} from '../../../../constants/DomainMonitoring';
import ReportIncidentDialog from '../shared/ReportIncidentDialog';
import Messages from '../../../../constants/Messages';
import { SearchContext } from '../../../../components/utils/Context';

const useStyles = makeStyles({
  incidentDetails: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    '& li': {
      margin: '0',
      padding: '0',
    },
  },
  filterRoot: {
    marginBottom: '2.4rem',
    '& .MuiFormControl-root ': {
      marginRight: '1rem',
    },
  },
  filterLabel: {
    color: grey[500],
    fontSize: '1.4rem',
    marginRight: '2rem',
    textTransform: 'uppercase',
  },
  visitedLink: {
    color: grey[400],
  },
});

const DefaultFilters = { date: 'All Time', brand: 'all', status: 'all' };

const DomainMonitoringDashboard = () => {
  const search = useContext(SearchContext);

  const dialogEl = useRef(null);
  const [incidents, setIncidents] = useState([]);
  const [skip, setSkip] = useState(0);
  const [filters, setFilters] = useState(DefaultFilters);
  const [dialog, setDialog] = useState({});
  const [brands, setBrands] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();

  const filterByDate = (data) => {
    const filter = SearchUtils.parseDate({ date: filters.date });
    return data.filter(item => new Date(item.details.startDate) >= new Date(filter.sinceDate));
  };

  const filterByBrand = (data) => {
    if (filters.brand === 'all') return data;
    return data.filter(item => item.brand.id === filters.brand);
  };

  const filterByStatus = (data) => {
    if (filters.status === 'all') return data;
    return data.filter(item => item.details.status === filters.status);
  };

  const filterByType = (data) => {
    if (filters.type === 'all') return data;
    return data.filter(item => item.brand.type === filters.type);
  };

  const handleFilterChange = (changedFilters) => {
    const oldFilters = {
      ...filters,
    };
    const newFilters = _.merge(oldFilters, changedFilters);
    setFilters(newFilters);
  };

  useEffect(() => {
    const presetFilters = getSessionFilters();
    setSkip(getSessionSkip());
    if (presetFilters) setFilters(presetFilters);
    SearchActions.loadResources();
  }, []);

  useEffect(() => {
    const visited = getVisitedLinks();
    setSessionFilters(filters);
    getAllIncidents()
      .then((results) => {
        const all = results.sort((a, b) => {
          if (moment(a.created_at).isBefore(b.created_at)) {
            return 1;
          } else if (moment(a.created_at).isAfter(b.created_at)) {
            return -1;
          }
          return 0;
        })
          .map(item => ({
            id: item?.id,
            brand: {
              name: item?.brand_name,
              type: item?.type,
              id: item?.brand_id,
              visited: visited ? visited[item?.id] : false,
            },
            details: {
              url: item?.urls ? item.urls[0] : '-',
              startDate: item?.opened_at ? moment(item.opened_at).format('M/D/Y h:mm A') : '-',
              endDate: item?.closed_at ? moment(item.closed_at).format('M/D/Y h:mm A') : '-',
              status: item.status || '-',
              visited: visited ? visited[item?.id] : false,
            },
          }));
        const dateFiltered = filters.date ? filterByDate(all) : all;
        const brandFiltered = filters.brand ? filterByBrand(dateFiltered) : dateFiltered;
        const statusFiltered = filters.status ? filterByStatus(brandFiltered) : brandFiltered;
        const filtered = filters.type ? filterByType(statusFiltered) : statusFiltered;
        setIncidents(filtered);
        setSkip(getSessionSkip() || 0);
      })
      .catch((error) => {
        if (error?.message === 'canceled') return;
        SearchActions.set(['search', 'info', 'message'], Messages.IncidentFetchError);
      });
    getAllBrands()
      .then(results => setBrands(results))
      .catch((error) => {
        if (error?.message === 'canceled') return;
        SearchActions.set(['search', 'info', 'message'], Messages.IncidentBrandError);
      });
  }, [filters, refresh]);

  useEffect(() => {
    setSessionSkip(skip);
  }, [skip]);

  const brandOptions = brands ? [{ label: 'All', value: 'all' }, ...brands.map(item => ({ label: item.name, value: item.id }))] : [{ label: 'All', value: 'all' }];

  const onNewIncident = (submitted) => {
    if (submitted) {
      setRefresh(!refresh);
      SearchActions.set(['search', 'info', 'message'], Messages.IncidentCreation);
    }
  };

  const sortByBrand = (a, b, direction) => {
    const left = a?.get('name')?.toLowerCase();
    const right = b?.get('name')?.toLowerCase();
    return (direction === 'asc' ? 1 : -1) * left.localeCompare(right);
  };

  const sortByDate = (a, b, direction) => {
    const left = a?.get('startDate');
    const right = b?.get('startDate');
    return (direction === 'asc' ? 1 : -1) * left.localeCompare(right);
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.filterRoot}>
        <Grid item>
          <Typography className={classes.filterLabel}>
            Filters:
          </Typography>
        </Grid>
        <Grid item style={{ margin: '0 1rem .25rem 0' }}>
          <FormControl
            variant="outlined"
            name="date"
            ref={dialogEl}
          >
            <InputLabel>Date</InputLabel>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDialog({
                target: dialogEl.current,
                key: 'date',
              })}
              endIcon={<ExpandMoreIcon />}
            >
              {filters.date}
            </Button>
            <Popover
              className="popover"
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog?.key === 'date')}
              onClose={() => setDialog({})}
            >
              <DateFilter
                disableCustom
                date={filters.date}
                dates={search.get('dates', list())}
                toggle={() => setDialog({})}
                onFilter={value => setFilters({ ...filters, date: value.get('date') })}
              />
            </Popover>
          </FormControl>
        </Grid>
        <TextFilter
          fields={[
            { type: 'dropdown', value: 'brand', label: 'Brand', key: 'value', opts: fromJS(brandOptions) },
            { type: 'dropdown', value: 'status', label: 'Status', opts: fromJS(StatusOptions) },
            { type: 'dropdown', value: 'type', label: 'Types', opts: fromJS(TypeOptions) },
          ]}
          filters={fromJS(filters)}
          onFilter={handleFilterChange}
          stacked
        />
        <Button onClick={() => setFilters(DefaultFilters)}>Clear All</Button>
        <Grid item style={{ marginLeft: 'auto' }}>
          Have a malicious link that you don't see here? <Link href="#" onClick={() => setDialogOpen(true)} color="primary">Request a takedown</Link>
        </Grid>
      </Grid>
      <Table
        pagination
        topPagination
        leftPagination
        onOffset={v => setSkip(v)}
        results={fromJS({
          data: incidents,
          total: incidents.length,
          skip,
        })}
        values={fromJS([
          { key: 'brand', label: 'Brand Details', sort: true, sortFunc: sortByBrand, render: v => (
            <ul className={[classes.incidentDetails, ...[v.get('visited') ? classes.visitedLink : '']].join(' ')}>
              <li><b>Brand: </b>{v.get('name')}</li>
              <li><b>Type: </b>{v.get('type')}</li>
            </ul>) },
          { key: 'details', label: 'Incident Details', sort: true, sortFunc: sortByDate, render: v => (
            <ul className={[classes.incidentDetails, ...[v.get('visited') ? classes.visitedLink : '']].join(' ')}>
              <li><b>Incident URL: </b>{v.get('url')}</li>
              <li><b>Incident Start Date: </b>{v.get('startDate')}</li>
              <li><b>Incident End Date: </b>{v.get('endDate')}</li>
              <li><b>Incident Status: </b>{v.get('status')}</li>
            </ul>
          ) },
        ])}
        onClick={(incident) => {
          setVisitedLink(incident.get('id'));
          History.navigateTo({ pathname: `/home/search/dmtm/incidents/items/${incident.get('id')}` }, false);
        }}
      />
      <ReportIncidentDialog
        onClose={() => setDialogOpen(false)}
        onSubmit={onNewIncident}
        open={dialogOpen}
        brands={brands} />
    </>
  );
};

DomainMonitoringDashboard.propTypes = {};

export default DomainMonitoringDashboard;
