import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { fromJS, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Chip,
  Icon,
} from '@mui/material';

import Text from '../../utils/text';
import style from './chips.module.scss';

const useStyles = makeStyles(theme => ({
  chips: {
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
    },
  },
}));

const Chips = ({
  defaults,
  filters,
  option,
  onDelete,
  onToggle,
}) => {
  const classes = useStyles();
  const [combinedFilters, setCombinedFilters] = useState(map());
  const [maintainedDefaults, setMaintainedDefaults] = useState(map());
  const limitTags = 3;

  useEffect(() => {
    const combined = fromJS({ ...defaults.toJS(), ...filters.toJS() });
    setCombinedFilters(combined);
    const maintained = defaults.filterNot((v, k) => filters.hasIn(k.split('.')) && filters.getIn(k.split('.')) !== v);
    setMaintainedDefaults(maintained);
  }, [filters, defaults]);

  const renderDisplayText = (_option, field) => {
    let displayText = '';
    if (field !== 'site_tags'
      && field !== 'keyword'
      && field !== 'is_verified'
      && field !== 'is_analyst'
      && field !== 'analyst_unassigned'
      && field !== 'analyst_assigned'
    ) {
      const label = `${Text.Sentence(field)}: `;
      let contentText = '';

      if (field !== 'date' && combinedFilters.get(field).includes('::')) {
        contentText = combinedFilters.get(field).split('::').slice(1).shift();
      } else {
        contentText = combinedFilters.get(field);
      }

      contentText = contentText
        .split(',')
        .map(v => (_option.format ? _option.format(v, field) : v))
        .slice(0, limitTags)
        .join(', ');

      if (option.blurFields && option.blurFields.includes(field)) {
        contentText = Text.BlurAll(contentText, option.blur);
      }

      let tail = '';
      if (combinedFilters.get(field).split(',').splice(limitTags).length) {
        tail = ` +${combinedFilters.get(field).split(',').splice(limitTags).length}`;
      }
      displayText = `${label}${contentText}${tail}`;
    }
    if (field === 'password_reset_policy') displayText = displayText.replace('Password Reset Policy:', 'Within The Last X Days:');
    return displayText;
  };

  const renderDisplayCount = field => `
      ${field !== 'site_tags' ? '' : `${Text.Sentence(field)}:${combinedFilters.get(field)
    .split(':')
    .slice(0, limitTags)
    .map(v => v.split(',').map(t => (option.format ? option.format(t, field) : t)).slice(0, limitTags).join(', '))
    .join(', ')}
        ${combinedFilters.get(field).split(':').splice(limitTags).length
    ? `+${combinedFilters.get(field).split(':').splice(limitTags).length}`
    : ''}`}

      ${field !== 'keyword' ? '' : `${Text.Sentence(field)}:
        ${combinedFilters.get(field)
    .split(',')
    .map(v => v.split('::')[0])
    .slice(0, limitTags)
    .join(', ')}
        ${combinedFilters.get(field).split(',').splice(limitTags).length
    ? `+${combinedFilters.get(field).split(',').splice(limitTags).length}`
    : ''}`}
      ${field !== 'is_verified' ? '' : `Confirmed Seeding/Leeching:
        ${combinedFilters.get(field) === 'true' ? 'Yes' : `${combinedFilters.get(field) === 'false' ? 'No' : 'Either'}`}`}
      ${field !== 'is_analyst' ? '' : `Source Added by Me:
        ${combinedFilters.get(field) === 'false' ? 'No' : 'Yes'}`}
      ${field !== 'analyst_unassigned' ? '' : `Source Added by Unknown Analyst:
        ${combinedFilters.get(field) === 'false' ? 'No' : 'Yes'}`}
      ${field !== 'analyst_assigned' ? '' : `Source Added by: ${combinedFilters.get(field)}`}
    `;

  return (
    <Box className={cx([
      style.chips,
      classes.chips,
      ])}>
      {option
        .fields
        .filter(v => combinedFilters.getIn(v.split('.'))?.length)
        .map(field => (
          <Chip
            clickable
            key={field}
            color={maintainedDefaults.getIn(field.split('.')) ? 'primary' : 'secondary'}
            className={cx([
              style.chip,
              maintainedDefaults.getIn(field.split('.')) && style.default,
            ])}
            icon={<Icon>{option.icon}</Icon>}
            deleteIcon={<Icon>close</Icon>}
            onDelete={maintainedDefaults?.getIn(field.split('.'))
              ? null
              : () => (maintainedDefaults.getIn(field.split('.')) != null ? null : onDelete(field))}
            onClick={e => onToggle({ target: e.currentTarget, key: option.key })}
            label={`${renderDisplayText(option, field)}${renderDisplayCount(field)}`}
            data-testid={`chip.${field}`} />))}
    </Box>
  );
};

Chips.propTypes = {
  defaults: PropTypes.object,
  filters: PropTypes.object,
  option: PropTypes.object,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
};

Chips.defaultProps = {
  defaults: map(),
  filters: map(),
  option: {},
  onDelete: null,
  onToggle: null,
};

export default Chips;
