import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import WarningIcon from '@mui/icons-material/Warning';

export const textFieldStyle = {
  root: {
    border: '1px rgba(0, 0, 0, .30) solid',
    borderRadius: '4px',
    padding: '10px',
    overflow: 'hidden',
    '& ul': {
      listStyle: 'none',
      margin: '0',
      padding: '0',
      overflow: 'hidden',
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, .87)',
    },
  },
  textField: {
    '& div:before': {
      background: 'none',
      border: 'none',
    },
    '& div:after': {
      background: 'none',
      border: 'none',
    },
    '& div:hover:not(.Mui-disabled):before': {
      background: 'none',
      border: 'none',
    },
  },
  chipRequired: {
    background: '#e5e7fa',
  },
  chipWarning: {
    background: 'none',
    border: '1px #5c6ae0 dashed',
  },
  tagList: {
    display: 'inline-block',
  },
};

const useStyles = makeStyles(() => (textFieldStyle));

const TagTextField = ({ defaultValue, value, onChange, regEx, placeholder, inputProps }) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [tags, setTags] = useState([]);
  const inputEl = useRef(null);
  const classes = useStyles();

  const TagStyles = {
    required: classes.chipRequired,
    warning: classes.chipWarning,
    optional: '',
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length > 0) {
      setTags(defaultValue.filter(v => (v?.value)));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value?.length > 0) {
      setTags(value.filter(v => (v?.value)));
    }
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(tags);
  }, [tags]);

  const testTag = (tag) => {
    if (!regEx) return true;
    return regEx.test(tag);
  };

  const processTags = () => {
    const tag = textFieldValue;
    setTextFieldValue('');
    if (!tag) return;
    if (!tags.includes(tag)) setTags([...tags, testTag(tag) ? { type: 'optional', value: tag } : { type: 'warning', value: tag }]);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      processTags();
    }
  };

  const handleDelete = (tag) => {
    const splicedTags = [...tags];
    splicedTags.splice(tags.indexOf(tag), 1);
    setTags(splicedTags);
  };

  const handleBlur = () => processTags();

  const getIcon = (type) => {
    if (type === 'warning') {
      return <WarningIcon style={{ fill: '#5c6ae0' }} />;
    }
    return null;
  };


  return (
    <div className={classes.root} onClick={() => inputEl.current.focus()}>
      <ul>
        {tags.map(v => (
          <li
            className={classes.tagList}
            key={v.value}>
            {v.type === 'required' ?
              <Chip
                className={TagStyles[v.type]}
                label={v.value}
                /> :
              <Chip
                className={TagStyles[v.type]}
                icon={getIcon(v.type)}
                label={v.value}
                onDelete={() => handleDelete(v)}
                />}
          </li>),
        )}
      </ul>
      <TextField
        className={classes.textField}
        onKeyDown={handleKeyDown}
        onChange={e => setTextFieldValue(e.target.value)}
        onBlur={handleBlur}
        inputRef={inputEl}
        value={textFieldValue}
        placeholder={placeholder}
        InputProps={inputProps}
      />
    </div>
  );
};

TagTextField.propTypes = {
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  regEx: PropTypes.object,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
};

TagTextField.defaultProps = {
  defaultValue: [],
  value: [],
  onChange: null,
  regEx: null,
  placeholder: '',
  inputProps: {},
};

export default TagTextField;
