import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { List as list } from 'immutable';
import { ResponsiveContainer, LineChart, Line } from 'recharts';

import makeStyles from '@mui/styles/makeStyles';
import style from './hero.module.scss';
import Text from '../../../utils/text';
import Common from '../../../utils/common';

const useStyles = makeStyles({
  overflowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  my100PercentWidth: {
    width: '100%',
  },
});

const Hero = ({
  abbreviate,
  disabled,
  labels,
  spark,
  values,
  results,
  small,
  styles,
  linkStyles,
  text,
  tooltip,
  onClick,
  onClickLabel,
  narrowStyle,
  useEllipsis,
}) => {
  const classes = useStyles();
  const disable = disabled || !onClick || !results.getIn(values.getIn([0, 'key']).split('.'));
  const extended = small || (styles.width && +styles.width.slice(0, -1) < 30) || text ? [] : [style.h0, 'h0'];
  const abbreviateFn = !text && abbreviate ? Text.AbbreviateNumber : v => (v || 0).toLocaleString();
  const testId = labels?.get(0)?.toLowerCase()?.replace(/\s/ig, '-');

  return (
    <div
      className={cx([style.hero, classes.my100PercentWidth])}
      style={{ ...styles }}>
      <div
        className={cx([style.chart, narrowStyle && style.narrow, disable && style.disabled])}>
        <div className={classes.my100PercentWidth}>
          {results.isEmpty() &&
          <div
            style={{ fontSize: styles.width ? `${+styles.width.slice(0, -1) < 30 ? '2rem' : ''}` : '' }}
            className={cx([
              style.count,
              style.raj,
              'raj',
              ...styles.width ? extended : [],
              ])}>0
          </div>}
          {!results.isEmpty() &&
          <div
            role="presentation"
            data-for="hero.tooltip"
            data-tip={tooltip}
            data-testid={`hero.${testId}`}
            onClick={() => !disable && onClick(Common.Generic.Route(results), results)}
            style={{ fontSize: styles.width || small ? `${small || +styles.width.slice(0, -1) < 30 || text ? '2rem' : ''}` : '' }}
            className={cx([
              style.count,
              ...styles.width || small ? extended : [style.h0, 'h0', style.raj, 'raj'],
              useEllipsis ? classes.overflowEllipsis : '',
              ])}>
            {abbreviateFn(values.hasIn([0, 'render'])
              ? values.getIn([0, 'render'])(!values.getIn([0, 'key']) ? results : results.getIn(values.getIn([0, 'key']).split('.'))) || '0'
              : results.getIn(values.getIn([0, 'key']).split('.')) || '0')}
          </div>}
          {!results.isEmpty() &&
          <div
            role="link"
            tabIndex={0}
            onKeyUp={() => null}
            onClick={() => !disable && (onClickLabel ?
              onClickLabel(Common.Generic.Route(results), results) :
              onClick(Common.Generic.Route(results), results))
            }
            data-testid={`hero.${testId}`}
            style={{ ...linkStyles, color: '#000', fontSize: '1.2rem' }}
            className={cx([
              style.cap,
              'cap',
              useEllipsis ? classes.overflowEllipsis : '',
            ])}>
            {Text.Sentence(
              (typeof labels.get(0) === 'string' && labels.get(0)) ||
              results.getIn(labels.getIn([0, 'key']).split('.')) ||
              'No Results')}
          </div>}
          {!spark.isEmpty() &&
          <ResponsiveContainer height={75} className={style.spark}>
            <LineChart data={spark.isEmpty()
              ? [...new Array(30).fill({ doc_count: 1 })]
              : spark.toJS()}>
              <Line
                isAnimationActive={false}
                dataKey="doc_count"
                dot={false}
                strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>}
        </div>
        <ReactTooltip id="hero.tooltip" html place="bottom" effect="solid" />
      </div>
    </div>
  );
};

Hero.propTypes = {
  abbreviate: PropTypes.bool,
  disabled: PropTypes.bool,
  labels: PropTypes.object,
  results: PropTypes.object,
  values: PropTypes.object,
  small: PropTypes.bool,
  spark: PropTypes.object,
  styles: PropTypes.object,
  linkStyles: PropTypes.object,
  text: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  onClickLabel: PropTypes.func,
  narrowStyle: PropTypes.bool,
  useEllipsis: PropTypes.bool,
};

Hero.defaultProps = {
  abbreviate: true,
  disabled: false,
  results: list(),
  labels: list(),
  values: list(),
  small: false,
  spark: list(),
  styles: {},
  linkStyles: {},
  text: false,
  tooltip: '',
  onClick: null,
  onClickLabel: null,
  narrowStyle: false,
  useEllipsis: false,
};

export default Hero;
