import * as React from 'react';

import {
  TableRow as MuiTableRow,
} from '@mui/material';

interface TableRowProps {
  /** The content of the table */
  children?: React.ReactNode;

  /** CSS classes */
  className?: string;

  /** Mouse click handler */
  onClick?: () => void,

  /** Mouse hover handler */
  onMouseOver?: () => void,
  onMouseOut?: () => void,
}

/** A table content row */
export const TableRow = ({
  children,
  className,
  onMouseOver,
  onMouseOut,
  onClick,
  ...rest
}: TableRowProps): JSX.Element =>
  <MuiTableRow
    className={className}
    onClick={onClick}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    {...rest}
  >
    {children}
  </MuiTableRow>;

TableRow.defaultProps = {
  children: '',
  className: '',
  onClick: () => false,
  onMouseOver: () => false,
  onMouseOut: () => false,
};
