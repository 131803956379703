import * as React from 'react';
import { ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const HelpUrl = 'https://docs.flashpoint.io/flashpoint/docs/section-three-conducting-searches';

const useStyles = makeStyles(() => ({
  title: {
    color: '#000',
    fontFamily: '"Open Sans" sans-serif',
    fontSize: '1.6rem',
    fontWeight: 400,
    marginBottom: '14px',
  },
  body: {
    color: '#000',
    fontFamily: '"Open Sans" sans-serif',
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  section: {
    marginTop: 0,
    marginBottom: '24px',
  },
  small: {
    color: '#000',
    fontFamily: '"Open Sans" sans-serif',
    fontSize: '1.2rem',
    fontWeight: 400,
    marginBottom: '5px',
  },
}));

interface TabFormProps {
  title: string,
  description: string,
  savedSearch: ReactNode,
  graph?: ReactNode,
  safeSearch?: ReactNode,
}

export const TabForm = ({
  title,
  description,
  savedSearch,
  graph,
  safeSearch,
}: TabFormProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <h2 className={classes.title}>{title}</h2>
      <p className={[classes.body, classes.section].join(' ')}>{description}</p>
      <div className={classes.body}>Saved Search</div>
      <div>{savedSearch}</div>
      <small className={classes.small}>Not sure how to create a saved search? Learn how <a target="_blank" rel="noreferrer" href={HelpUrl}>here.</a></small>
      {!!(graph) &&
        <div>
          <p className={classes.title}>Count Display</p>
          {graph && <div>{graph}</div>}
        </div>}
      {!!(safeSearch) &&
        <div>
          <p className={classes.title}>Safe Search Filter</p>
          {safeSearch && <div>{safeSearch}</div>}
        </div>}
    </div>
  );
};

TabForm.defaultProps = {
  graph: null,
  safeSearch: null,
};

