import * as React from 'react';
import { useEffect, useState } from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

import { ChartData } from '../../../../../core/types/ChartData';

interface MentionsChartProps {
  data: ChartData[]
}

const NullData = [{
  name: 'mention',
  count: 0,
  date: 1,
}, {
  name: 'mention',
  count: 0,
  date: 2,
}];

export const MentionsChart = ({ data }: MentionsChartProps): JSX.Element => {
  const [chartData, setChartData] = useState(NullData);

  useEffect(() => {
    if (data && data.length > 0) {
      setChartData([...data]);
    }
  }, [data]);

  return (
    <ResponsiveContainer>
      <AreaChart data={chartData}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#008BD8" stopOpacity={0.75} />
            <stop offset="95%" stopColor="#008BD8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="count" stroke="#008BD8" fillOpacity={1} fill="url(#color)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
