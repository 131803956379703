import React from 'react';

import style from './sanctions.module.scss';
import SanctionsForm from './SearchComponents/SanctionsForm';
import SanctionsTable from './SearchComponents/SanctionsTable';

const SanctionsSearch = (() => (
  <React.Fragment>
    <div className={style.sanctions}>
      <SanctionsForm />
      <SanctionsTable />
    </div>
  </React.Fragment>
));

export default SanctionsSearch;
