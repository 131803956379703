export default {
  1: 'DEPRECATED: Location',
  2: '7PK - Environment',
  3: 'DEPRECATED: Technology-specific Environment Issues',
  4: 'J2EE Environment Issues',
  5: 'J2EE Misconfiguration: Data Transmission Without Encryption',
  6: 'J2EE Misconfiguration: Insufficient Session-ID Length',
  7: 'J2EE Misconfiguration: Missing Custom Error Page',
  8: 'J2EE Misconfiguration: Entity Bean Declared Remote',
  9: 'J2EE Misconfiguration: Weak Access Permissions for EJB Methods',
  10: 'DEPRECATED: ASP.NET Environment Issues',
  11: 'ASP.NET Misconfiguration: Creating Debug Binary',
  12: 'ASP.NET Misconfiguration: Missing Custom Error Page',
  13: 'ASP.NET Misconfiguration: Password in Configuration File',
  14: 'Compiler Removal of Code to Clear Buffers',
  15: 'External Control of System or Configuration Setting',
  16: 'Configuration',
  17: 'Code',
  18: 'Source Code',
  19: 'Data Processing Errors',
  20: 'Improper Input Validation',
  21: 'Pathname Traversal and Equivalence Errors',
  22: 'Improper Limitation of a Pathname to a Restricted Directory ("Path Traversal")',
  23: 'Relative Path Traversal',
  24: 'Path Traversal: "../filedir"',
  25: 'Path Traversal: "/../filedir"',
  26: 'Path Traversal: "/dir/../filename"',
  27: 'Path Traversal: "dir/../../filename"',
  28: 'Path Traversal: "\\..\\filedir"',
  29: 'Path Traversal: "\\..\\filename"',
  30: 'Path Traversal: "\\dir\\..\\filename',
  31: 'Path Traversal: "\\dir\\..\\..\\filename"',
  32: 'Path Traversal: "..." (Triple Dot)',
  33: 'Path Traversal: "...." (Multiple Dot)',
  34: 'Path Traversal: "....//"',
  35: 'Path Traversal: ".../...//"',
  36: 'Absolute Path Traversal',
  37: 'Path Traversal: "/absolute/pathname/here"',
  38: 'Path Traversal: "\\absolute\\path\\name\\here"',
  39: 'Path Traversal: "C:dirname"',
  40: 'Path Traversal: "\\UNC\\share\\name (Windows UNC Share"',
  41: 'Improper Resolution of Path Equivalence',
  42: 'Path Equivalence: "filename." (Trailing Dot)',
  43: 'Path Equivalence: "filename...." (Multiple Trailing Dot)',
  44: 'Path Equivalence: "file.name" (Internal Dot)',
  45: 'Path Equivalence: "file...name" (Multiple Internal Dot)',
  46: 'Path Equivalence: "filename " (Trailing Space)',
  47: 'Path Equivalence: " filename" (Leading Space)',
  48: 'Path Equivalence: "file name" (Internal Whitespace)',
  49: 'Path Equivalence: "filename/" (Trailing Slash)',
  50: 'Path Equivalence: "//multiple/leading/slash"',
  51: 'Path Equivalence: "/multiple//internal/slash"',
  52: 'Path Equivalence: "/multiple/trailing/slash//"',
  53: 'Path Equivalence: "\\multiple\\internal\\backslash"',
  54: 'Path Equivalence: "filedir\\" (Trailing Backslash)',
  55: 'Path Equivalence: "/./" (Single Dot Directory)',
  56: 'Path Equivalence: "filedir*" (Wildcard)',
  57: 'Path Equivalence: "fakedir/../realdir/filename"',
  58: 'Path Equivalence: Windows 8.3 Filename',
  59: 'Improper Link Resolution Before File Access ("Link Following")',
  60: 'DEPRECATED: UNIX Path Link Problems',
  61: 'UNIX Symbolic Link (Symlink) Following',
  62: 'UNIX Hard Link',
  63: 'DEPRECATED: Windows Path Link Problems',
  64: 'Windows Shortcut Following (.LNK)',
  65: 'Windows Hard Link',
  66: 'Improper Handling of File Names that Identify Virtual Resources',
  67: 'Improper Handling of Windows Device Names',
  68: 'DEPRECATED: Windows Virtual File Problems',
  69: 'Improper Handling of Windows ::DATA Alternate Data Stream',
  70: 'DEPRECATED: Mac Virtual File Problems',
  71: 'DEPRECATED: Apple ".DS_Store"',
  72: 'Improper Handling of Apple HFS+ Alternate Data Stream Path',
  73: 'External Control of File Name or Path',
  74: 'Improper Neutralization of Special Elements in Output Used by a Downstream Component ("Injection")',
  75: 'Failure to Sanitize Special Elements into a Different Plane (Special Element Injection)',
  76: 'Improper Neutralization of Equivalent Special Elements',
  77: 'Improper Neutralization of Special Elements used in a Command ("Command Injection")',
  78: 'Improper Neutralization of Special Elements used in an OS Command ("OS Command Injection")',
  79: 'Improper Neutralization of Input During Web Page Generation ("Cross-site Scripting")',
  80: 'Improper Neutralization of Script-Related HTML Tags in a Web Page (Basic XSS)',
  81: 'Improper Neutralization of Script in an Error Message Web Page',
  82: 'Improper Neutralization of Script in Attributes of IMG Tags in a Web Page',
  83: 'Improper Neutralization of Script in Attributes in a Web Page',
  84: 'Improper Neutralization of Encoded URI Schemes in a Web Page',
  85: 'Doubled Character XSS Manipulations',
  86: 'Improper Neutralization of Invalid Characters in Identifiers in Web Pages',
  87: 'Improper Neutralization of Alternate XSS Syntax',
  88: 'Argument Injection or Modification',
  89: 'Improper Neutralization of Special Elements used in an SQL Command ("SQL Injection")',
  90: 'Improper Neutralization of Special Elements used in an LDAP Query ("LDAP Injection")',
  91: 'XML Injection (aka Blind XPath Injection)',
  92: 'DEPRECATED: Improper Sanitization of Custom Special Characters',
  93: 'Improper Neutralization of CRLF Sequences ("CRLF Injection")',
  94: 'Improper Control of Generation of Code ("Code Injection")',
  95: 'Improper Neutralization of Directives in Dynamically Evaluated Code ("Eval Injection")',
  96: 'Improper Neutralization of Directives in Statically Saved Code ("Static Code Injection")',
  97: 'Improper Neutralization of Server-Side Includes (SSI) Within a Web Page',
  98: 'Improper Control of Filename for Include/Require Statement in PHP Program ("PHP Remote File Inclusion")',
  99: 'Improper Control of Resource Identifiers ("Resource Injection")',
  100: 'DEPRECATED: Technology-Specific Input Validation Problems',
  101: 'DEPRECATED: Struts Validation Problems',
  102: 'Struts: Duplicate Validation Forms',
  103: 'Struts: Incomplete validate() Method Definition',
  104: 'Struts: Form Bean Does Not Extend Validation Class',
  105: 'Struts: Form Field Without Validator',
  106: 'Struts: Plug-in Framework not in Use',
  107: 'Struts: Unused Validation Form',
  108: 'Struts: Unvalidated Action Form',
  109: 'Struts: Validator Turned Off',
  110: 'Struts: Validator Without Form Field',
  111: 'Direct Use of Unsafe JNI',
  112: 'Missing XML Validation',
  113: 'Improper Neutralization of CRLF Sequences in HTTP Headers ("HTTP Response Splitting")',
  114: 'Process Control',
  115: 'Misinterpretation of Input',
  116: 'Improper Encoding or Escaping of Output',
  117: 'Improper Output Neutralization for Logs',
  118: 'Incorrect Access of Indexable Resource ("Range Error")',
  119: 'Improper Restriction of Operations within the Bounds of a Memory Buffer',
  120: 'Buffer Copy without Checking Size of Input ("Classic Buffer Overflow")',
  121: 'Stack-based Buffer Overflow',
  122: 'Heap-based Buffer Overflow',
  123: 'Write-what-where Condition',
  124: 'Buffer Underwrite ("Buffer Underflow")',
  125: 'Out-of-bounds Read',
  126: 'Buffer Over-read',
  127: 'Buffer Under-read',
  128: 'Wrap-around Error',
  129: 'Improper Validation of Array Index',
  130: 'Improper Handling of Length Parameter Inconsistency',
  131: 'Incorrect Calculation of Buffer Size',
  132: 'DEPRECATED (Duplicate): Miscalculated Null Termination',
  133: 'String Errors',
  134: 'Use of Externally-Controlled Format String',
  135: 'Incorrect Calculation of Multi-Byte String Length',
  136: 'Type Errors',
  137: 'Representation Errors',
  138: 'Improper Neutralization of Special Elements',
  139: 'DEPRECATED: General Special Element Problems',
  140: 'Improper Neutralization of Delimiters',
  141: 'Improper Neutralization of Parameter/Argument Delimiters',
  142: 'Improper Neutralization of Value Delimiters',
  143: 'Improper Neutralization of Record Delimiters',
  144: 'Improper Neutralization of Line Delimiters',
  145: 'Improper Neutralization of Section Delimiters',
  146: 'Improper Neutralization of Expression/Command Delimiters',
  147: 'Improper Neutralization of Input Terminators',
  148: 'Improper Neutralization of Input Leaders',
  149: 'Improper Neutralization of Quoting Syntax',
  150: '\'Improper Neutralization of Escape',
  151: 'Improper Neutralization of Comment Delimiters',
  152: 'Improper Neutralization of Macro Symbols',
  153: 'Improper Neutralization of Substitution Characters',
  154: 'Improper Neutralization of Variable Name Delimiters',
  155: 'Improper Neutralization of Wildcards or Matching Symbols',
  156: 'Improper Neutralization of Whitespace',
  157: 'Failure to Sanitize Paired Delimiters',
  158: 'Improper Neutralization of Null Byte or NUL Character',
  159: 'Failure to Sanitize Special Element',
  160: 'Improper Neutralization of Leading Special Elements',
  161: 'Improper Neutralization of Multiple Leading Special Elements',
  162: 'Improper Neutralization of Trailing Special Elements',
  163: 'Improper Neutralization of Multiple Trailing Special Elements',
  164: 'Improper Neutralization of Internal Special Elements',
  165: 'Improper Neutralization of Multiple Internal Special Elements',
  166: 'Improper Handling of Missing Special Element',
  167: 'Improper Handling of Additional Special Element',
  168: 'Improper Handling of Inconsistent Special Elements',
  169: 'DEPRECATED: Technology-Specific Special Elements',
  170: 'Improper Null Termination',
  171: '"Cleansing',
  172: 'Encoding Error',
  173: 'Improper Handling of Alternate Encoding',
  174: 'Double Decoding of the Same Data',
  175: 'Improper Handling of Mixed Encoding',
  176: 'Improper Handling of Unicode Encoding',
  177: 'Improper Handling of URL Encoding (Hex Encoding)',
  178: 'Improper Handling of Case Sensitivity',
  179: 'Incorrect Behavior Order: Early Validation',
  180: 'Incorrect Behavior Order: Validate Before Canonicalize',
  181: 'Incorrect Behavior Order: Validate Before Filter',
  182: 'Collapse of Data into Unsafe Value',
  183: 'Permissive Whitelist',
  184: 'Incomplete Blacklist',
  185: 'Incorrect Regular Expression',
  186: 'Overly Restrictive Regular Expression',
  187: 'Partial String Comparison',
  188: 'Reliance on Data/Memory Layout',
  189: 'Numeric Errors',
  190: 'Integer Overflow or Wraparound',
  191: 'Integer Underflow (Wrap or Wraparound)',
  192: 'Integer Coercion Error',
  193: 'Off-by-one Error',
  194: 'Unexpected Sign Extension',
  195: 'Signed to Unsigned Conversion Error',
  196: 'Unsigned to Signed Conversion Error',
  197: 'Numeric Truncation Error',
  198: 'Use of Incorrect Byte Ordering',
  199: 'Information Management Errors',
  200: 'Information Exposure',
  201: 'Information Exposure Through Sent Data',
  202: 'Exposure of Sensitive Data Through Data Queries',
  203: 'Information Exposure Through Discrepancy',
  204: 'Response Discrepancy Information Exposure',
  205: 'Information Exposure Through Behavioral Discrepancy',
  206: 'Information Exposure of Internal State Through Behavioral Inconsistency',
  207: 'Information Exposure Through an External Behavioral Inconsistency',
  208: 'Information Exposure Through Timing Discrepancy',
  209: 'Information Exposure Through an Error Message',
  210: 'Information Exposure Through Self-generated Error Message',
  211: 'Information Exposure Through Externally-Generated Error Message',
  212: 'Improper Cross-boundary Removal of Sensitive Data',
  213: 'Intentional Information Exposure',
  214: 'Information Exposure Through Process Environment',
  215: 'Information Exposure Through Debug Information',
  216: 'Containment Errors (Container Errors)',
  217: 'DEPRECATED: Failure to Protect Stored Data from Modification',
  218: 'DEPRECATED (Duplicate): Failure to provide confidentiality for stored data',
  219: 'Sensitive Data Under Web Root',
  220: 'Sensitive Data Under FTP Root',
  221: 'Information Loss or Omission',
  222: 'Truncation of Security-relevant Information',
  223: 'Omission of Security-relevant Information',
  224: 'Obscured Security-relevant Information by Alternate Name',
  225: 'DEPRECATED (Duplicate): General Information Management Problems',
  226: 'Sensitive Information Uncleared Before Release',
  227: '7PK - API Abuse',
  228: 'Improper Handling of Syntactically Invalid Structure',
  229: 'Improper Handling of Values',
  230: 'Improper Handling of Missing Values',
  231: 'Improper Handling of Extra Values',
  232: 'Improper Handling of Undefined Values',
  233: 'Improper Handling of Parameters',
  234: 'Failure to Handle Missing Parameter',
  235: 'Improper Handling of Extra Parameters',
  236: 'Improper Handling of Undefined Parameters',
  237: 'Improper Handling of Structural Elements',
  238: 'Improper Handling of Incomplete Structural Elements',
  239: 'Failure to Handle Incomplete Element',
  240: 'Improper Handling of Inconsistent Structural Elements',
  241: 'Improper Handling of Unexpected Data Type',
  242: 'Use of Inherently Dangerous Function',
  243: 'Creation of chroot Jail Without Changing Working Directory',
  244: 'Improper Clearing of Heap Memory Before Release ("Heap Inspection")',
  245: 'J2EE Bad Practices: Direct Management of Connections',
  246: 'J2EE Bad Practices: Direct Use of Sockets',
  247: 'DEPRECATED (Duplicate): Reliance on DNS Lookups in a Security Decision',
  248: 'Uncaught Exception',
  249: 'DEPRECATED: Often Misused: Path Manipulation',
  250: 'Execution with Unnecessary Privileges',
  251: 'Often Misused: String Management',
  252: 'Unchecked Return Value',
  253: 'Incorrect Check of Function Return Value',
  254: '7PK - Security Features',
  255: 'Credentials Management',
  256: 'Unprotected Storage of Credentials',
  257: 'Storing Passwords in a Recoverable Format',
  258: 'Empty Password in Configuration File',
  259: 'Use of Hard-coded Password',
  260: 'Password in Configuration File',
  261: 'Weak Cryptography for Passwords',
  262: 'Not Using Password Aging',
  263: 'Password Aging with Long Expiration',
  264: 'Permissions',
  265: 'Privilege / Sandbox Issues',
  266: 'Incorrect Privilege Assignment',
  267: 'Privilege Defined With Unsafe Actions',
  268: 'Privilege Chaining',
  269: 'Improper Privilege Management',
  270: 'Privilege Context Switching Error',
  271: 'Privilege Dropping / Lowering Errors',
  272: 'Least Privilege Violation',
  273: 'Improper Check for Dropped Privileges',
  274: 'Improper Handling of Insufficient Privileges',
  275: 'Permission Issues',
  276: 'Incorrect Default Permissions',
  277: 'Insecure Inherited Permissions',
  278: 'Insecure Preserved Inherited Permissions',
  279: 'Incorrect Execution-Assigned Permissions',
  280: 'Improper Handling of Insufficient Permissions or Privileges',
  281: 'Improper Preservation of Permissions',
  282: 'Improper Ownership Management',
  283: 'Unverified Ownership',
  284: 'Improper Access Control',
  285: 'Improper Authorization',
  286: 'Incorrect User Management',
  287: 'Improper Authentication',
  288: 'Authentication Bypass Using an Alternate Path or Channel',
  289: 'Authentication Bypass by Alternate Name',
  290: 'Authentication Bypass by Spoofing',
  291: 'Reliance on IP Address for Authentication',
  292: 'DEPRECATED (Duplicate): Trusting Self-reported DNS Name',
  293: 'Using Referer Field for Authentication',
  294: 'Authentication Bypass by Capture-replay',
  295: 'Improper Certificate Validation',
  296: 'Improper Following of a Certificate\'s Chain of Trust',
  297: 'Improper Validation of Certificate with Host Mismatch',
  298: 'Improper Validation of Certificate Expiration',
  299: 'Improper Check for Certificate Revocation',
  300: 'Channel Accessible by Non-Endpoint ("Man-in-the-Middle")',
  301: 'Reflection Attack in an Authentication Protocol',
  302: 'Authentication Bypass by Assumed-Immutable Data',
  303: 'Incorrect Implementation of Authentication Algorithm',
  304: 'Missing Critical Step in Authentication',
  305: 'Authentication Bypass by Primary Weakness',
  306: 'Missing Authentication for Critical Function',
  307: 'Improper Restriction of Excessive Authentication Attempts',
  308: 'Use of Single-factor Authentication',
  309: 'Use of Password System for Primary Authentication',
  310: 'Cryptographic Issues',
  311: 'Missing Encryption of Sensitive Data',
  312: 'Cleartext Storage of Sensitive Information',
  313: 'Cleartext Storage in a File or on Disk',
  314: 'Cleartext Storage in the Registry',
  315: 'Cleartext Storage of Sensitive Information in a Cookie',
  316: 'Cleartext Storage of Sensitive Information in Memory',
  317: 'Cleartext Storage of Sensitive Information in GUI',
  318: 'Cleartext Storage of Sensitive Information in Executable',
  319: 'Cleartext Transmission of Sensitive Information',
  320: 'Key Management Errors',
  321: 'Use of Hard-coded Cryptographic Key',
  322: 'Key Exchange without Entity Authentication',
  323: '"Reusing a Nonce',
  324: 'Use of a Key Past its Expiration Date',
  325: 'Missing Required Cryptographic Step',
  326: 'Inadequate Encryption Strength',
  327: 'Use of a Broken or Risky Cryptographic Algorithm',
  328: 'Reversible One-Way Hash',
  329: 'Not Using a Random IV with CBC Mode',
  330: 'Use of Insufficiently Random Values',
  331: 'Insufficient Entropy',
  332: 'Insufficient Entropy in PRNG',
  333: 'Improper Handling of Insufficient Entropy in TRNG',
  334: 'Small Space of Random Values',
  335: 'Incorrect Usage of Seeds in Pseudo-Random Number Generator (PRNG)',
  336: 'Same Seed in Pseudo-Random Number Generator (PRNG)',
  337: 'Predictable Seed in Pseudo-Random Number Generator (PRNG)',
  338: 'Use of Cryptographically Weak Pseudo-Random Number Generator (PRNG)',
  339: 'Small Seed Space in PRNG',
  340: 'Predictability Problems',
  341: 'Predictable from Observable State',
  342: 'Predictable Exact Value from Previous Values',
  343: 'Predictable Value Range from Previous Values',
  344: 'Use of Invariant Value in Dynamically Changing Context',
  345: 'Insufficient Verification of Data Authenticity',
  346: 'Origin Validation Error',
  347: 'Improper Verification of Cryptographic Signature',
  348: 'Use of Less Trusted Source',
  349: 'Acceptance of Extraneous Untrusted Data With Trusted Data',
  350: 'Reliance on Reverse DNS Resolution for a Security-Critical Action',
  351: 'Insufficient Type Distinction',
  352: 'Cross-Site Request Forgery (CSRF)',
  353: 'Missing Support for Integrity Check',
  354: 'Improper Validation of Integrity Check Value',
  355: 'User Interface Security Issues',
  356: 'Product UI does not Warn User of Unsafe Actions',
  357: 'Insufficient UI Warning of Dangerous Operations',
  358: 'Improperly Implemented Security Check for Standard',
  359: 'Exposure of Private Information ("Privacy Violation")',
  360: 'Trust of System Event Data',
  361: '7PK - Time and State',
  362: 'Concurrent Execution using Shared Resource with Improper Synchronization ("Race Condition")',
  363: 'Race Condition Enabling Link Following',
  364: 'Signal Handler Race Condition',
  365: 'Race Condition in Switch',
  366: 'Race Condition within a Thread',
  367: 'Time-of-check Time-of-use (TOCTOU) Race Condition',
  368: 'Context Switching Race Condition',
  369: 'Divide By Zero',
  370: 'Missing Check for Certificate Revocation after Initial Check',
  371: 'State Issues',
  372: 'Incomplete Internal State Distinction',
  373: 'DEPRECATED: State Synchronization Error',
  374: 'Passing Mutable Objects to an Untrusted Method',
  375: 'Returning a Mutable Object to an Untrusted Caller',
  376: 'Temporary File Issues',
  377: 'Insecure Temporary File',
  378: 'Creation of Temporary File With Insecure Permissions',
  379: 'Creation of Temporary File in Directory with Incorrect Permissions',
  380: 'Technology-Specific Time and State Issues',
  381: 'J2EE Time and State Issues',
  382: 'J2EE Bad Practices: Use of System.exit()',
  383: 'J2EE Bad Practices: Direct Use of Threads',
  384: 'Session Fixation',
  385: 'Covert Timing Channel',
  386: 'Symbolic Name not Mapping to Correct Object',
  387: 'Signal Errors',
  388: '7PK - Errors',
  389: '"Error Conditions',
  390: 'Detection of Error Condition Without Action',
  391: 'Unchecked Error Condition',
  392: 'Missing Report of Error Condition',
  393: 'Return of Wrong Status Code',
  394: 'Unexpected Status Code or Return Value',
  395: 'Use of NullPointerException Catch to Detect NULL Pointer Dereference',
  396: 'Declaration of Catch for Generic Exception',
  397: 'Declaration of Throws for Generic Exception',
  398: '7PK - Code Quality',
  399: 'Resource Management Errors',
  400: 'Uncontrolled Resource Consumption',
  401: 'Improper Release of Memory Before Removing Last Reference',
  402: 'Transmission of Private Resources into a New Sphere ("Resource Leak")',
  403: 'Exposure of File Descriptor to Unintended Control Sphere ("File Descriptor Leak")',
  404: 'Improper Resource Shutdown or Release',
  405: 'Asymmetric Resource Consumption (Amplification)',
  406: 'Insufficient Control of Network Message Volume (Network Amplification)',
  407: 'Algorithmic Complexity',
  408: 'Incorrect Behavior Order: Early Amplification',
  409: 'Improper Handling of Highly Compressed Data (Data Amplification)',
  410: 'Insufficient Resource Pool',
  411: 'Resource Locking Problems',
  412: 'Unrestricted Externally Accessible Lock',
  413: 'Improper Resource Locking',
  414: 'Missing Lock Check',
  415: 'Double Free',
  416: 'Use After Free',
  417: 'Channel and Path Errors',
  418: 'DEPRECATED: Channel Errors',
  419: 'Unprotected Primary Channel',
  420: 'Unprotected Alternate Channel',
  421: 'Race Condition During Access to Alternate Channel',
  422: 'Unprotected Windows Messaging Channel ("Shatter")',
  423: 'DEPRECATED (Duplicate): Proxied Trusted Channel',
  424: 'Improper Protection of Alternate Path',
  425: 'Direct Request ("Forced Browsing")',
  426: 'Untrusted Search Path',
  427: 'Uncontrolled Search Path Element',
  428: 'Unquoted Search Path or Element',
  429: 'Handler Errors',
  430: 'Deployment of Wrong Handler',
  431: 'Missing Handler',
  432: 'Dangerous Signal Handler not Disabled During Sensitive Operations',
  433: 'Unparsed Raw Web Content Delivery',
  434: 'Unrestricted Upload of File with Dangerous Type',
  435: 'Improper Interaction Between Multiple Correctly-Behaving Entities',
  436: 'Interpretation Conflict',
  437: 'Incomplete Model of Endpoint Features',
  438: 'Behavioral Problems',
  439: 'Behavioral Change in New Version or Environment',
  440: 'Expected Behavior Violation',
  441: 'Unintended Proxy or Intermediary ("Confused Deputy")',
  442: 'Web Problems',
  443: 'DEPRECATED (Duplicate): HTTP response splitting',
  444: 'Inconsistent Interpretation of HTTP Requests ("HTTP Request Smuggling")',
  445: 'DEPRECATED: User Interface Errors',
  446: 'UI Discrepancy for Security Feature',
  447: 'Unimplemented or Unsupported Feature in UI',
  448: 'Obsolete Feature in UI',
  449: 'The UI Performs the Wrong Action',
  450: 'Multiple Interpretations of UI Input',
  451: 'User Interface (UI) Misrepresentation of Critical Information',
  452: 'Initialization and Cleanup Errors',
  453: 'Insecure Default Variable Initialization',
  454: 'External Initialization of Trusted Variables or Data Stores',
  455: 'Non-exit on Failed Initialization',
  456: 'Missing Initialization of a Variable',
  457: 'Use of Uninitialized Variable',
  458: 'DEPRECATED: Incorrect Initialization',
  459: 'Incomplete Cleanup',
  460: 'Improper Cleanup on Thrown Exception',
  461: 'Data Structure Issues',
  462: 'Duplicate Key in Associative List (Alist)',
  463: 'Deletion of Data Structure Sentinel',
  464: 'Addition of Data Structure Sentinel',
  465: 'Pointer Issues',
  466: 'Return of Pointer Value Outside of Expected Range',
  467: 'Use of sizeof() on a Pointer Type',
  468: 'Incorrect Pointer Scaling',
  469: 'Use of Pointer Subtraction to Determine Size',
  470: 'Use of Externally-Controlled Input to Select Classes or Code ("Unsafe Reflection")',
  471: 'Modification of Assumed-Immutable Data (MAID)',
  472: 'External Control of Assumed-Immutable Web Parameter',
  473: 'PHP External Variable Modification',
  474: 'Use of Function with Inconsistent Implementations',
  475: 'Undefined Behavior for Input to API',
  476: 'NULL Pointer Dereference',
  477: 'Use of Obsolete Function',
  478: 'Missing Default Case in Switch Statement',
  479: 'Signal Handler Use of a Non-reentrant Function',
  480: 'Use of Incorrect Operator',
  481: 'Assigning instead of Comparing',
  482: 'Comparing instead of Assigning',
  483: 'Incorrect Block Delimitation',
  484: 'Omitted Break Statement in Switch',
  485: '7PK - Encapsulation',
  486: 'Comparison of Classes by Name',
  487: 'Reliance on Package-level Scope',
  488: 'Exposure of Data Element to Wrong Session',
  489: 'Leftover Debug Code',
  490: 'Mobile Code Issues',
  491: 'Public cloneable() Method Without Final ("Object Hijack")',
  492: 'Use of Inner Class Containing Sensitive Data',
  493: 'Critical Public Variable Without Final Modifier',
  494: 'Download of Code Without Integrity Check',
  495: 'Private Data Structure Returned From A Public Method',
  496: 'Public Data Assigned to Private Array-Typed Field',
  497: 'Exposure of System Data to an Unauthorized Control Sphere',
  498: 'Cloneable Class Containing Sensitive Information',
  499: 'Serializable Class Containing Sensitive Data',
  500: 'Public Static Field Not Marked Final',
  501: 'Trust Boundary Violation',
  502: 'Deserialization of Untrusted Data',
  503: 'DEPRECATED: Byte/Object Code',
  504: 'DEPRECATED: Motivation/Intent',
  505: 'DEPRECATED: Intentionally Introduced Weakness',
  506: 'Embedded Malicious Code',
  507: 'Trojan Horse',
  508: 'Non-Replicating Malicious Code',
  509: 'Replicating Malicious Code (Virus or Worm)',
  510: 'Trapdoor',
  511: 'Logic/Time Bomb',
  512: 'Spyware',
  513: 'DEPRECATED: Intentionally Introduced Nonmalicious Weakness',
  514: 'Covert Channel',
  515: 'Covert Storage Channel',
  516: 'DEPRECATED (Duplicate): Covert Timing Channel',
  517: '"DEPRECATED: Other Intentional',
  518: 'DEPRECATED: Inadvertently Introduced Weakness',
  519: '.NET Environment Issues',
  520: '.NET Misconfiguration: Use of Impersonation',
  521: 'Weak Password Requirements',
  522: 'Insufficiently Protected Credentials',
  523: 'Unprotected Transport of Credentials',
  524: 'Information Exposure Through Caching',
  525: 'Information Exposure Through Browser Caching',
  526: 'Information Exposure Through Environmental Variables',
  527: 'Exposure of CVS Repository to an Unauthorized Control Sphere',
  528: 'Exposure of Core Dump File to an Unauthorized Control Sphere',
  529: 'Exposure of Access Control List Files to an Unauthorized Control Sphere',
  530: 'Exposure of Backup File to an Unauthorized Control Sphere',
  531: 'Information Exposure Through Test Code',
  532: 'Information Exposure Through Log Files',
  533: 'DEPRECATED: Information Exposure Through Server Log Files',
  534: 'DEPRECATED: Information Exposure Through Debug Log Files',
  535: 'Information Exposure Through Shell Error Message',
  536: 'Information Exposure Through Servlet Runtime Error Message',
  537: 'Information Exposure Through Java Runtime Error Message',
  538: 'File and Directory Information Exposure',
  539: 'Information Exposure Through Persistent Cookies',
  540: 'Information Exposure Through Source Code',
  541: 'Information Exposure Through Include Source Code',
  542: 'DEPRECATED: Information Exposure Through Cleanup Log Files',
  543: 'Use of Singleton Pattern Without Synchronization in a Multithreaded Context',
  544: 'Missing Standardized Error Handling Mechanism',
  545: 'DEPRECATED: Use of Dynamic Class Loading',
  546: 'Suspicious Comment',
  547: '"Use of Hard-coded',
  548: 'Information Exposure Through Directory Listing',
  549: 'Missing Password Field Masking',
  550: 'Information Exposure Through Server Error Message',
  551: 'Incorrect Behavior Order: Authorization Before Parsing and Canonicalization',
  552: 'Files or Directories Accessible to External Parties',
  553: 'Command Shell in Externally Accessible Directory',
  554: 'ASP.NET Misconfiguration: Not Using Input Validation Framework',
  555: 'J2EE Misconfiguration: Plaintext Password in Configuration File',
  556: 'ASP.NET Misconfiguration: Use of Identity Impersonation',
  557: 'Concurrency Issues',
  558: 'Use of getlogin() in Multithreaded Application',
  559: 'Often Misused: Arguments and Parameters',
  560: 'Use of umask() with chmod-style Argument',
  561: 'Dead Code',
  562: 'Return of Stack Variable Address',
  563: 'Assignment to Variable without Use',
  564: 'SQL Injection: Hibernate',
  565: 'Reliance on Cookies without Validation and Integrity Checking',
  566: 'Authorization Bypass Through User-Controlled SQL Primary Key',
  567: 'Unsynchronized Access to Shared Data in a Multithreaded Context',
  568: 'finalize() Method Without super.finalize()',
  569: 'Expression Issues',
  570: 'Expression is Always False',
  571: 'Expression is Always True',
  572: 'Call to Thread run() instead of start()',
  573: 'Improper Following of Specification by Caller',
  574: 'EJB Bad Practices: Use of Synchronization Primitives',
  575: 'EJB Bad Practices: Use of AWT Swing',
  576: 'EJB Bad Practices: Use of Java I/O',
  577: 'EJB Bad Practices: Use of Sockets',
  578: 'EJB Bad Practices: Use of Class Loader',
  579: 'J2EE Bad Practices: Non-serializable Object Stored in Session',
  580: 'clone() Method Without super.clone()',
  581: 'Object Model Violation: Just One of Equals and Hashcode Defined',
  582: '"Array Declared Public',
  583: 'finalize() Method Declared Public',
  584: 'Return Inside Finally Block',
  585: 'Empty Synchronized Block',
  586: 'Explicit Call to Finalize()',
  587: 'Assignment of a Fixed Address to a Pointer',
  588: 'Attempt to Access Child of a Non-structure Pointer',
  589: 'Call to Non-ubiquitous API',
  590: 'Free of Memory not on the Heap',
  591: 'Sensitive Data Storage in Improperly Locked Memory',
  592: 'DEPRECATED: Authentication Bypass Issues',
  593: 'Authentication Bypass: OpenSSL CTX Object Modified after SSL Objects are Created',
  594: 'J2EE Framework: Saving Unserializable Objects to Disk',
  595: 'Comparison of Object References Instead of Object Contents',
  596: 'DEPRECATED: Incorrect Semantic Object Comparison',
  597: 'Use of Wrong Operator in String Comparison',
  598: 'Information Exposure Through Query Strings in GET Request',
  599: 'Missing Validation of OpenSSL Certificate',
  600: 'Uncaught Exception in Servlet',
  601: 'URL Redirection to Untrusted Site ("Open Redirect")',
  602: 'Client-Side Enforcement of Server-Side Security',
  603: 'Use of Client-Side Authentication',
  604: 'Deprecated Entries',
  605: 'Multiple Binds to the Same Port',
  606: 'Unchecked Input for Loop Condition',
  607: 'Public Static Final Field References Mutable Object',
  608: 'Struts: Non-private Field in ActionForm Class',
  609: 'Double-Checked Locking',
  610: 'Externally Controlled Reference to a Resource in Another Sphere',
  611: 'Improper Restriction of XML External Entity Reference ("XXE")',
  612: 'Information Exposure Through Indexing of Private Data',
  613: 'Insufficient Session Expiration',
  614: 'Sensitive Cookie in HTTPS Session Without "Secure" Attribute',
  615: 'Information Exposure Through Comments',
  616: 'Incomplete Identification of Uploaded File Variables (PHP)',
  617: 'Reachable Assertion',
  618: 'Exposed Unsafe ActiveX Method',
  619: 'Dangling Database Cursor ("Cursor Injection")',
  620: 'Unverified Password Change',
  621: 'Variable Extraction Error',
  622: 'Improper Validation of Function Hook Arguments',
  623: 'Unsafe ActiveX Control Marked Safe For Scripting',
  624: 'Executable Regular Expression Error',
  625: 'Permissive Regular Expression',
  626: 'Null Byte Interaction Error (Poison Null Byte)',
  627: 'Dynamic Variable Evaluation',
  628: 'Function Call with Incorrectly Specified Arguments',
  629: 'Weaknesses in OWASP Top Ten (2007)',
  630: 'DEPRECATED: Weaknesses Examined by SAMATE',
  631: 'DEPRECATED: Resource-specific Weaknesses',
  632: 'DEPRECATED: Weaknesses that Affect Files or Directories',
  633: 'DEPRECATED: Weaknesses that Affect Memory',
  634: 'DEPRECATED: Weaknesses that Affect System Processes',
  635: 'Weaknesses Originally Used by NVD from 2008 to 2016',
  636: 'Not Failing Securely ("Failing Open")',
  637: 'Unnecessary Complexity in Protection Mechanism (Not Using "Economy of Mechanism")',
  638: 'Not Using Complete Mediation',
  639: 'Authorization Bypass Through User-Controlled Key',
  640: 'Weak Password Recovery Mechanism for Forgotten Password',
  641: 'Improper Restriction of Names for Files and Other Resources',
  642: 'External Control of Critical State Data',
  643: 'Improper Neutralization of Data within XPath Expressions ("XPath Injection")',
  644: 'Improper Neutralization of HTTP Headers for Scripting Syntax',
  645: 'Overly Restrictive Account Lockout Mechanism',
  646: 'Reliance on File Name or Extension of Externally-Supplied File',
  647: 'Use of Non-Canonical URL Paths for Authorization Decisions',
  648: 'Incorrect Use of Privileged APIs',
  649: 'Reliance on Obfuscation or Encryption of Security-Relevant Inputs without Integrity Checking',
  650: 'Trusting HTTP Permission Methods on the Server Side',
  651: 'Information Exposure Through WSDL File',
  652: 'Improper Neutralization of Data within XQuery Expressions ("XQuery Injection")',
  653: 'Insufficient Compartmentalization',
  654: 'Reliance on a Single Factor in a Security Decision',
  655: 'Insufficient Psychological Acceptability',
  656: 'Reliance on Security Through Obscurity',
  657: 'Violation of Secure Design Principles',
  658: 'Weaknesses in Software Written in C',
  659: 'Weaknesses in Software Written in C++',
  660: 'Weaknesses in Software Written in Java',
  661: 'Weaknesses in Software Written in PHP',
  662: 'Improper Synchronization',
  663: 'Use of a Non-reentrant Function in a Concurrent Context',
  664: 'Improper Control of a Resource Through its Lifetime',
  665: 'Improper Initialization',
  666: 'Operation on Resource in Wrong Phase of Lifetime',
  667: 'Improper Locking',
  668: 'Exposure of Resource to Wrong Sphere',
  669: 'Incorrect Resource Transfer Between Spheres',
  670: 'Always-Incorrect Control Flow Implementation',
  671: 'Lack of Administrator Control over Security',
  672: 'Operation on a Resource after Expiration or Release',
  673: 'External Influence of Sphere Definition',
  674: 'Uncontrolled Recursion',
  675: 'Duplicate Operations on Resource',
  676: 'Use of Potentially Dangerous Function',
  677: 'Weakness Base Elements',
  678: 'Composites',
  679: 'DEPRECATED: Chain Elements',
  680: 'Integer Overflow to Buffer Overflow',
  681: 'Incorrect Conversion between Numeric Types',
  682: 'Incorrect Calculation',
  683: 'Function Call With Incorrect Order of Arguments',
  684: 'Incorrect Provision of Specified Functionality',
  685: 'Function Call With Incorrect Number of Arguments',
  686: 'Function Call With Incorrect Argument Type',
  687: 'Function Call With Incorrectly Specified Argument Value',
  688: 'Function Call With Incorrect Variable or Reference as Argument',
  689: 'Permission Race Condition During Resource Copy',
  690: 'Unchecked Return Value to NULL Pointer Dereference',
  691: 'Insufficient Control Flow Management',
  692: 'Incomplete Blacklist to Cross-Site Scripting',
  693: 'Protection Mechanism Failure',
  694: 'Use of Multiple Resources with Duplicate Identifier',
  695: 'Use of Low-Level Functionality',
  696: 'Incorrect Behavior Order',
  697: 'Incorrect Comparison',
  698: 'Execution After Redirect (EAR)',
  699: 'Development Concepts',
  700: 'Seven Pernicious Kingdoms',
  701: 'Weaknesses Introduced During Design',
  702: 'Weaknesses Introduced During Implementation',
  703: 'Improper Check or Handling of Exceptional Conditions',
  704: 'Incorrect Type Conversion or Cast',
  705: 'Incorrect Control Flow Scoping',
  706: 'Use of Incorrectly-Resolved Name or Reference',
  707: 'Improper Enforcement of Message or Data Structure',
  708: 'Incorrect Ownership Assignment',
  709: 'Named Chains',
  710: 'Improper Adherence to Coding Standards',
  711: 'Weaknesses in OWASP Top Ten (2004)',
  712: 'OWASP Top Ten 2007 Category A1 - Cross Site Scripting (XSS)',
  713: 'OWASP Top Ten 2007 Category A2 - Injection Flaws',
  714: 'OWASP Top Ten 2007 Category A3 - Malicious File Execution',
  715: 'OWASP Top Ten 2007 Category A4 - Insecure Direct Object Reference',
  716: 'OWASP Top Ten 2007 Category A5 - Cross Site Request Forgery (CSRF)',
  717: 'OWASP Top Ten 2007 Category A6 - Information Leakage and Improper Error Handling',
  718: 'OWASP Top Ten 2007 Category A7 - Broken Authentication and Session Management',
  719: 'OWASP Top Ten 2007 Category A8 - Insecure Cryptographic Storage',
  720: 'OWASP Top Ten 2007 Category A9 - Insecure Communications',
  721: 'OWASP Top Ten 2007 Category A10 - Failure to Restrict URL Access',
  722: 'OWASP Top Ten 2004 Category A1 - Unvalidated Input',
  723: 'OWASP Top Ten 2004 Category A2 - Broken Access Control',
  724: 'OWASP Top Ten 2004 Category A3 - Broken Authentication and Session Management',
  725: 'OWASP Top Ten 2004 Category A4 - Cross-Site Scripting (XSS) Flaws',
  726: 'OWASP Top Ten 2004 Category A5 - Buffer Overflows',
  727: 'OWASP Top Ten 2004 Category A6 - Injection Flaws',
  728: 'OWASP Top Ten 2004 Category A7 - Improper Error Handling',
  729: 'OWASP Top Ten 2004 Category A8 - Insecure Storage',
  730: 'OWASP Top Ten 2004 Category A9 - Denial of Service',
  731: 'OWASP Top Ten 2004 Category A10 - Insecure Configuration Management',
  732: 'Incorrect Permission Assignment for Critical Resource',
  733: 'Compiler Optimization Removal or Modification of Security-critical Code',
  734: 'Weaknesses Addressed by the CERT C Secure Coding Standard (2008)',
  735: 'CERT C Secure Coding Standard (2008) Chapter 2 - Preprocessor (PRE)',
  736: 'CERT C Secure Coding Standard (2008) Chapter 3 - Declarations and Initialization (DCL)',
  737: 'CERT C Secure Coding Standard (2008) Chapter 4 - Expressions (EXP)',
  738: 'CERT C Secure Coding Standard (2008) Chapter 5 - Integers (INT)',
  739: 'CERT C Secure Coding Standard (2008) Chapter 6 - Floating Point (FLP)',
  740: 'CERT C Secure Coding Standard (2008) Chapter 7 - Arrays (ARR)',
  741: 'CERT C Secure Coding Standard (2008) Chapter 8 - Characters and Strings (STR)',
  742: 'CERT C Secure Coding Standard (2008) Chapter 9 - Memory Management (MEM)',
  743: 'CERT C Secure Coding Standard (2008) Chapter 10 - Input Output (FIO)',
  744: 'CERT C Secure Coding Standard (2008) Chapter 11 - Environment (ENV)',
  745: 'CERT C Secure Coding Standard (2008) Chapter 12 - Signals (SIG)',
  746: 'CERT C Secure Coding Standard (2008) Chapter 13 - Error Handling (ERR)',
  747: 'CERT C Secure Coding Standard (2008) Chapter 14 - Miscellaneous (MSC)',
  748: 'CERT C Secure Coding Standard (2008) Appendix - POSIX (POS)',
  749: 'Exposed Dangerous Method or Function',
  750: 'Weaknesses in the 2009 CWE/SANS Top 25 Most Dangerous Programming Errors',
  751: '2009 Top 25 - Insecure Interaction Between Components',
  752: '2009 Top 25 - Risky Resource Management',
  753: '2009 Top 25 - Porous Defenses',
  754: 'Improper Check for Unusual or Exceptional Conditions',
  755: 'Improper Handling of Exceptional Conditions',
  756: 'Missing Custom Error Page',
  757: 'Selection of Less-Secure Algorithm During Negotiation ("Algorithm Downgrade")',
  758: '"Reliance on Undefined',
  759: 'Use of a One-Way Hash without a Salt',
  760: 'Use of a One-Way Hash with a Predictable Salt',
  761: 'Free of Pointer not at Start of Buffer',
  762: 'Mismatched Memory Management Routines',
  763: 'Release of Invalid Pointer or Reference',
  764: 'Multiple Locks of a Critical Resource',
  765: 'Multiple Unlocks of a Critical Resource',
  766: 'Critical Data Element Declared Public',
  767: 'Access to Critical Private Variable via Public Method',
  768: 'Incorrect Short Circuit Evaluation',
  769: 'DEPRECATED: Uncontrolled File Descriptor Consumption',
  770: 'Allocation of Resources Without Limits or Throttling',
  771: 'Missing Reference to Active Allocated Resource',
  772: 'Missing Release of Resource after Effective Lifetime',
  773: 'Missing Reference to Active File Descriptor or Handle',
  774: 'Allocation of File Descriptors or Handles Without Limits or Throttling',
  775: 'Missing Release of File Descriptor or Handle after Effective Lifetime',
  776: 'Improper Restriction of Recursive Entity References in DTDs ("XML Entity Expansion")',
  777: 'Regular Expression without Anchors',
  778: 'Insufficient Logging',
  779: 'Logging of Excessive Data',
  780: 'Use of RSA Algorithm without OAEP',
  781: 'Improper Address Validation in IOCTL with METHOD_NEITHER I/O Control Code',
  782: 'Exposed IOCTL with Insufficient Access Control',
  783: 'Operator Precedence Logic Error',
  784: 'Reliance on Cookies without Validation and Integrity Checking in a Security Decision',
  785: 'Use of Path Manipulation Function without Maximum-sized Buffer',
  786: 'Access of Memory Location Before Start of Buffer',
  787: 'Out-of-bounds Write',
  788: 'Access of Memory Location After End of Buffer',
  789: 'Uncontrolled Memory Allocation',
  790: 'Improper Filtering of Special Elements',
  791: 'Incomplete Filtering of Special Elements',
  792: 'Incomplete Filtering of One or More Instances of Special Elements',
  793: 'Only Filtering One Instance of a Special Element',
  794: 'Incomplete Filtering of Multiple Instances of Special Elements',
  795: 'Only Filtering Special Elements at a Specified Location',
  796: 'Only Filtering Special Elements Relative to a Marker',
  797: 'Only Filtering Special Elements at an Absolute Position',
  798: 'Use of Hard-coded Credentials',
  799: 'Improper Control of Interaction Frequency',
  800: 'Weaknesses in the 2010 CWE/SANS Top 25 Most Dangerous Programming Errors',
  801: '2010 Top 25 - Insecure Interaction Between Components',
  802: '2010 Top 25 - Risky Resource Management',
  803: '2010 Top 25 - Porous Defenses',
  804: 'Guessable CAPTCHA',
  805: 'Buffer Access with Incorrect Length Value',
  806: 'Buffer Access Using Size of Source Buffer',
  807: 'Reliance on Untrusted Inputs in a Security Decision',
  808: '2010 Top 25 - Weaknesses On the Cusp',
  809: 'Weaknesses in OWASP Top Ten (2010)',
  810: 'OWASP Top Ten 2010 Category A1 - Injection',
  811: 'OWASP Top Ten 2010 Category A2 - Cross-Site Scripting (XSS)',
  812: 'OWASP Top Ten 2010 Category A3 - Broken Authentication and Session Management',
  813: 'OWASP Top Ten 2010 Category A4 - Insecure Direct Object References',
  814: 'OWASP Top Ten 2010 Category A5 - Cross-Site Request Forgery(CSRF)',
  815: 'OWASP Top Ten 2010 Category A6 - Security Misconfiguration',
  816: 'OWASP Top Ten 2010 Category A7 - Insecure Cryptographic Storage',
  817: 'OWASP Top Ten 2010 Category A8 - Failure to Restrict URL Access',
  818: 'OWASP Top Ten 2010 Category A9 - Insufficient Transport Layer Protection',
  819: 'OWASP Top Ten 2010 Category A10 - Unvalidated Redirects and Forwards',
  820: 'Missing Synchronization',
  821: 'Incorrect Synchronization',
  822: 'Untrusted Pointer Dereference',
  823: 'Use of Out-of-range Pointer Offset',
  824: 'Access of Uninitialized Pointer',
  825: 'Expired Pointer Dereference',
  826: 'Premature Release of Resource During Expected Lifetime',
  827: 'Improper Control of Document Type Definition',
  828: 'Signal Handler with Functionality that is not Asynchronous-Safe',
  829: 'Inclusion of Functionality from Untrusted Control Sphere',
  830: 'Inclusion of Web Functionality from an Untrusted Source',
  831: 'Signal Handler Function Associated with Multiple Signals',
  832: 'Unlock of a Resource that is not Locked',
  833: 'Deadlock',
  834: 'Excessive Iteration',
  835: 'Loop with Unreachable Exit Condition ("Infinite Loop")',
  836: 'Use of Password Hash Instead of Password for Authentication',
  837: '"Improper Enforcement of a Single',
  838: 'Inappropriate Encoding for Output Context',
  839: 'Numeric Range Comparison Without Minimum Check',
  840: 'Business Logic Errors',
  841: 'Improper Enforcement of Behavioral Workflow',
  842: 'Placement of User into Incorrect Group',
  843: 'Access of Resource Using Incompatible Type ("Type Confusion")',
  844: 'Weaknesses Addressed by The CERT Oracle Secure Coding Standard for Java (2011)',
  845: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 2 - Input Validation and Data Sanitization (IDS)',
  846: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 3 - Declarations and Initialization (DCL)',
  847: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 4 - Expressions (EXP)',
  848: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 5 - Numeric Types and Operations (NUM)',
  849: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 6 - Object Orientation (OBJ)',
  850: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 7 - Methods (MET)',
  851: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 8 - Exceptional Behavior (ERR)',
  852: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 9 - Visibility and Atomicity (VNA)',
  853: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 10 - Locking (LCK)',
  854: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 11 - Thread APIs (THI)',
  855: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 12 - Thread Pools (TPS)',
  856: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 13 - Thread-Safety Miscellaneous (TSM)',
  857: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 14 - Input Output (FIO)',
  858: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 15 - Serialization (SER)',
  859: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 16 - Platform Security (SEC)',
  860: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 17 - Runtime Environment (ENV)',
  861: 'The CERT Oracle Secure Coding Standard for Java (2011) Chapter 18 - Miscellaneous (MSC)',
  862: 'Missing Authorization',
  863: 'Incorrect Authorization',
  864: '2011 Top 25 - Insecure Interaction Between Components',
  865: '2011 Top 25 - Risky Resource Management',
  866: '2011 Top 25 - Porous Defenses',
  867: '2011 Top 25 - Weaknesses On the Cusp',
  868: 'Weaknesses Addressed by the SEI CERT C++ Coding Standard (2016 Version)',
  869: 'CERT C++ Secure Coding Section 01 - Preprocessor (PRE)',
  870: 'CERT C++ Secure Coding Section 02 - Declarations and Initialization (DCL)',
  871: 'CERT C++ Secure Coding Section 03 - Expressions (EXP)',
  872: 'CERT C++ Secure Coding Section 04 - Integers (INT)',
  873: 'CERT C++ Secure Coding Section 05 - Floating Point Arithmetic (FLP)',
  874: 'CERT C++ Secure Coding Section 06 - Arrays and the STL (ARR)',
  875: 'CERT C++ Secure Coding Section 07 - Characters and Strings (STR)',
  876: 'CERT C++ Secure Coding Section 08 - Memory Management (MEM)',
  877: 'CERT C++ Secure Coding Section 09 - Input Output (FIO)',
  878: 'CERT C++ Secure Coding Section 10 - Environment (ENV)',
  879: 'CERT C++ Secure Coding Section 11 - Signals (SIG)',
  880: 'CERT C++ Secure Coding Section 12 - Exceptions and Error Handling (ERR)',
  881: 'CERT C++ Secure Coding Section 13 - Object Oriented Programming (OOP)',
  882: 'CERT C++ Secure Coding Section 14 - Concurrency (CON)',
  883: 'CERT C++ Secure Coding Section 49 - Miscellaneous (MSC)',
  884: 'CWE Cross-section',
  885: 'SFP Primary Cluster: Risky Values',
  886: 'SFP Primary Cluster: Unused entities',
  887: 'SFP Primary Cluster: API',
  888: 'Software Fault Pattern (SFP) Clusters',
  889: 'SFP Primary Cluster: Exception Management',
  890: 'SFP Primary Cluster: Memory Access',
  891: 'SFP Primary Cluster: Memory Management',
  892: 'SFP Primary Cluster: Resource Management',
  893: 'SFP Primary Cluster: Path Resolution',
  894: 'SFP Primary Cluster: Synchronization',
  895: 'SFP Primary Cluster: Information Leak',
  896: 'SFP Primary Cluster: Tainted Input',
  897: 'SFP Primary Cluster: Entry Points',
  898: 'SFP Primary Cluster: Authentication',
  899: 'SFP Primary Cluster: Access Control',
  900: 'Weaknesses in the 2011 CWE/SANS Top 25 Most Dangerous Software Errors',
  901: 'SFP Primary Cluster: Privilege',
  902: 'SFP Primary Cluster: Channel',
  903: 'SFP Primary Cluster: Cryptography',
  904: 'SFP Primary Cluster: Malware',
  905: 'SFP Primary Cluster: Predictability',
  906: 'SFP Primary Cluster: UI',
  907: 'SFP Primary Cluster: Other',
  908: 'Use of Uninitialized Resource',
  909: 'Missing Initialization of Resource',
  910: 'Use of Expired File Descriptor',
  911: 'Improper Update of Reference Count',
  912: 'Hidden Functionality',
  913: 'Improper Control of Dynamically-Managed Code Resources',
  914: 'Improper Control of Dynamically-Identified Variables',
  915: 'Improperly Controlled Modification of Dynamically-Determined Object Attributes',
  916: 'Use of Password Hash With Insufficient Computational Effort',
  917: 'Improper Neutralization of Special Elements used in an Expression Language Statement ("Expression Language Injection")',
  918: 'Server-Side Request Forgery (SSRF)',
  919: 'Weaknesses in Mobile Applications',
  920: 'Improper Restriction of Power Consumption',
  921: 'Storage of Sensitive Data in a Mechanism without Access Control',
  922: 'Insecure Storage of Sensitive Information',
  923: 'Improper Restriction of Communication Channel to Intended Endpoints',
  924: 'Improper Enforcement of Message Integrity During Transmission in a Communication Channel',
  925: 'Improper Verification of Intent by Broadcast Receiver',
  926: 'Improper Export of Android Application Components',
  927: 'Use of Implicit Intent for Sensitive Communication',
  928: 'Weaknesses in OWASP Top Ten (2013)',
  929: 'OWASP Top Ten 2013 Category A1 - Injection',
  930: 'OWASP Top Ten 2013 Category A2 - Broken Authentication and Session Management',
  931: 'OWASP Top Ten 2013 Category A3 - Cross-Site Scripting (XSS)',
  932: 'OWASP Top Ten 2013 Category A4 - Insecure Direct Object References',
  933: 'OWASP Top Ten 2013 Category A5 - Security Misconfiguration',
  934: 'OWASP Top Ten 2013 Category A6 - Sensitive Data Exposure',
  935: 'OWASP Top Ten 2013 Category A7 - Missing Function Level Access Control',
  936: 'OWASP Top Ten 2013 Category A8 - Cross-Site Request Forgery (CSRF)',
  937: 'OWASP Top Ten 2013 Category A9 - Using Components with Known Vulnerabilities',
  938: 'OWASP Top Ten 2013 Category A10 - Unvalidated Redirects and Forwards',
  939: 'Improper Authorization in Handler for Custom URL Scheme',
  940: 'Improper Verification of Source of a Communication Channel',
  941: 'Incorrectly Specified Destination in a Communication Channel',
  942: 'Overly Permissive Cross-domain Whitelist',
  943: 'Improper Neutralization of Special Elements in Data Query Logic',
  944: 'SFP Secondary Cluster: Access Management',
  945: 'SFP Secondary Cluster: Insecure Resource Access',
  946: 'SFP Secondary Cluster: Insecure Resource Permissions',
  947: 'SFP Secondary Cluster: Authentication Bypass',
  948: 'SFP Secondary Cluster: Digital Certificate',
  949: 'SFP Secondary Cluster: Faulty Endpoint Authentication',
  950: 'SFP Secondary Cluster: Hardcoded Sensitive Data',
  951: 'SFP Secondary Cluster: Insecure Authentication Policy',
  952: 'SFP Secondary Cluster: Missing Authentication',
  953: 'SFP Secondary Cluster: Missing Endpoint Authentication',
  954: 'SFP Secondary Cluster: Multiple Binds to the Same Port',
  955: 'SFP Secondary Cluster: Unrestricted Authentication',
  956: 'SFP Secondary Cluster: Channel Attack',
  957: 'SFP Secondary Cluster: Protocol Error',
  958: 'SFP Secondary Cluster: Broken Cryptography',
  959: 'SFP Secondary Cluster: Weak Cryptography',
  960: 'SFP Secondary Cluster: Ambiguous Exception Type',
  961: 'SFP Secondary Cluster: Incorrect Exception Behavior',
  962: 'SFP Secondary Cluster: Unchecked Status Condition',
  963: 'SFP Secondary Cluster: Exposed Data',
  964: 'SFP Secondary Cluster: Exposure Temporary File',
  965: 'SFP Secondary Cluster: Insecure Session Management',
  966: 'SFP Secondary Cluster: Other Exposures',
  967: 'SFP Secondary Cluster: State Disclosure',
  968: 'SFP Secondary Cluster: Covert Channel',
  969: 'SFP Secondary Cluster: Faulty Memory Release',
  970: 'SFP Secondary Cluster: Faulty Buffer Access',
  971: 'SFP Secondary Cluster: Faulty Pointer Use',
  972: 'SFP Secondary Cluster: Faulty String Expansion',
  973: 'SFP Secondary Cluster: Improper NULL Termination',
  974: 'SFP Secondary Cluster: Incorrect Buffer Length Computation',
  975: 'SFP Secondary Cluster: Architecture',
  976: 'SFP Secondary Cluster: Compiler',
  977: 'SFP Secondary Cluster: Design',
  978: 'SFP Secondary Cluster: Implementation',
  979: 'SFP Secondary Cluster: Failed Chroot Jail',
  980: 'SFP Secondary Cluster: Link in Resource Name Resolution',
  981: 'SFP Secondary Cluster: Path Traversal',
  982: 'SFP Secondary Cluster: Failure to Release Resource',
  983: 'SFP Secondary Cluster: Faulty Resource Use',
  984: 'SFP Secondary Cluster: Life Cycle',
  985: 'SFP Secondary Cluster: Unrestricted Consumption',
  986: 'SFP Secondary Cluster: Missing Lock',
  987: 'SFP Secondary Cluster: Multiple Locks/Unlocks',
  988: 'SFP Secondary Cluster: Race Condition Window',
  989: 'SFP Secondary Cluster: Unrestricted Lock',
  990: 'SFP Secondary Cluster: Tainted Input to Command',
  991: 'SFP Secondary Cluster: Tainted Input to Environment',
  992: 'SFP Secondary Cluster: Faulty Input Transformation',
  993: 'SFP Secondary Cluster: Incorrect Input Handling',
  994: 'SFP Secondary Cluster: Tainted Input to Variable',
  995: 'SFP Secondary Cluster: Feature',
  996: 'SFP Secondary Cluster: Security',
  997: 'SFP Secondary Cluster: Information Loss',
  998: 'SFP Secondary Cluster: Glitch in Computation',
  999: 'Weaknesses without Software Fault Patterns',
  1000: 'Research Concepts',
  1001: 'SFP Secondary Cluster: Use of an Improper API',
  1002: 'SFP Secondary Cluster: Unexpected Entry Points',
  1003: 'Weaknesses for Simplified Mapping of Published Vulnerabilities',
  1004: 'Sensitive Cookie Without "HttpOnly" Flag',
  1005: '7PK - Input Validation and Representation',
  1006: 'Bad Coding Practices',
  1007: 'Insufficient Visual Distinction of Homoglyphs Presented to User',
  1008: 'Architectural Concepts',
  1009: 'Audit',
  1010: 'Authenticate Actors',
  1011: 'Authorize Actors',
  1012: 'Cross Cutting',
  1013: 'Encrypt Data',
  1014: 'Identify Actors',
  1015: 'Limit Access',
  1016: 'Limit Exposure',
  1017: 'Lock Computer',
  1018: 'Manage User Sessions',
  1019: 'Validate Inputs',
  1020: 'Verify Message Integrity',
  1021: 'Improper Restriction of Rendered UI Layers or Frames',
  1022: 'Use of Web Link to Untrusted Target with window.opener Access',
  1023: 'Incomplete Comparison with Missing Factors',
  1024: 'Comparison of Incompatible Types',
  1025: 'Comparison Using Wrong Factors',
  1026: 'Weaknesses in OWASP Top Ten (2017)',
  1027: 'OWASP Top Ten 2017 Category A1 - Injection',
  1028: 'OWASP Top Ten 2017 Category A2 - Broken Authentication',
  1029: 'OWASP Top Ten 2017 Category A3 - Sensitive Data Exposure',
  1030: 'OWASP Top Ten 2017 Category A4 - XML External Entities (XXE)',
  1031: 'OWASP Top Ten 2017 Category A5 - Broken Access Control',
  1032: 'OWASP Top Ten 2017 Category A6 - Security Misconfiguration',
  1033: 'OWASP Top Ten 2017 Category A7 - Cross-Site Scripting (XSS)',
  1034: 'OWASP Top Ten 2017 Category A8 - Insecure Deserialization',
  1035: 'OWASP Top Ten 2017 Category A9 - Using Components with Known Vulnerabilities',
  1036: 'OWASP Top Ten 2017 Category A10 - Insufficient Logging & Monitoring',
  1037: 'Processor Optimization Removal or Modification of Security-critical Code',
  1038: 'Insecure Automated Optimizations',
  1039: 'Automated Recognition Mechanism with Inadequate Detection or Handling of Adversarial Input Perturbations',
  1040: 'Quality Weaknesses with Indirect Security Impacts',
  1041: 'Use of Redundant Code',
  1042: 'Static Member Data Element outside of a Singleton Class Element',
  1043: 'Data Element Aggregating an Excessively Large Number of Non-Primitive Elements',
  1044: 'Architecture with Number of Horizontal Layers Outside of Expected Range',
  1045: 'Parent Class with a Virtual Destructor and a Child Class without a Virtual Destructor',
  1046: 'Creation of Immutable Text Using String Concatenation',
  1047: 'Modules with Circular Dependencies',
  1048: 'Invokable Control Element with Large Number of Outward Calls',
  1049: 'Excessive Data Query Operations in a Large Data Table',
  1050: 'Excessive Platform Resource Consumption within a Loop',
  1051: 'Initialization with Hard-Coded Network Resource Configuration Data',
  1052: 'Excessive Use of Hard-Coded Literals in Initialization',
  1053: 'Missing Documentation for Design',
  1054: 'Invocation of a Control Element at an Unnecessarily Deep Horizontal Layer',
  1055: 'Multiple Inheritance from Concrete Classes',
  1056: 'Invokable Control Element with Variadic Parameters',
  1057: 'Data Access Operations Outside of Expected Data Manager Component',
  1058: 'Invokable Control Element in Multi-Thread Context with non-Final Static Storable or Member Element',
  1059: 'Incomplete Documentation',
  1060: 'Excessive Number of Inefficient Server-Side Data Accesses',
  1061: 'Insufficient Encapsulation',
  1062: 'Parent Class with References to Child Class',
  1063: 'Creation of Class Instance within a Static Code Block',
  1064: 'Invokable Control Element with Signature Containing an Excessive Number of Parameters',
  1065: 'Runtime Resource Management Control Element in a Component Built to Run on Application Servers',
  1066: 'Missing Serialization Control Element',
  1067: 'Excessive Execution of Sequential Searches of Data Resource',
  1068: 'Inconsistency Between Implementation and Documented Design',
  1069: 'Empty Exception Block',
  1070: 'Serializable Data Element Containing non-Serializable Item Elements',
  1071: 'Empty Code Block',
  1072: 'Data Resource Access without Use of Connection Pooling',
  1073: 'Non-SQL Invokable Control Element with Excessive Number of Data Resource Accesses',
  1074: 'Class with Excessively Deep Inheritance',
  1075: 'Unconditional Control Flow Transfer outside of Switch Block',
  1076: 'Insufficient Adherence to Expected Conventions',
  1077: 'Floating Point Comparison with Incorrect Operator',
  1078: 'Inappropriate Source Code Style or Formatting',
  1079: 'Parent Class without Virtual Destructor Method',
  1080: 'Source Code File with Excessive Number of Lines of Code',
  1082: 'Class Instance Self Destruction Control Element',
  1083: 'Data Access from Outside Expected Data Manager Component',
  1084: 'Invokable Control Element with Excessive File or Data Access Operations',
  1085: 'Invokable Control Element with Excessive Volume of Commented-out Code',
  1086: 'Class with Excessive Number of Child Classes',
  1087: 'Class with Virtual Method without a Virtual Destructor',
  1088: 'Synchronous Access of Remote Resource without Timeout',
  1089: 'Large Data Table with Excessive Number of Indices',
  1090: 'Method Containing Access of a Member Element from Another Class',
  1091: 'Use of Object without Invoking Destructor Method',
  1092: 'Use of Same Invokable Control Element in Multiple Architectural Layers',
  1093: 'Excessively Complex Data Representation',
  1094: 'Excessive Index Range Scan for a Data Resource',
  1095: 'Loop Condition Value Update within the Loop',
  1096: 'Singleton Class Instance Creation without Proper Locking or Synchronization',
  1097: 'Persistent Storable Data Element without Associated Comparison Control Element',
  1098: 'Data Element containing Pointer Item without Proper Copy Control Element',
  1099: 'Inconsistent Naming Conventions for Identifiers',
  1100: 'Insufficient Isolation of System-Dependent Functions',
  1101: 'Reliance on Runtime Component in Generated Code',
  1102: 'Reliance on Machine-Dependent Data Representation',
  1103: 'Use of Platform-Dependent Third Party Components',
  1104: 'Use of Unmaintained Third Party Components',
  1105: 'Insufficient Encapsulation of Machine-Dependent Functionality',
  1106: 'Insufficient Use of Symbolic Constants',
  1107: 'Insufficient Isolation of Symbolic Constant Definitions',
  1108: 'Excessive Reliance on Global Variables',
  1109: 'Use of Same Variable for Multiple Purposes',
  1110: 'Incomplete Design Documentation',
  1111: 'Incomplete I/O Documentation',
  1112: 'Incomplete Documentation of Program Execution',
  1113: 'Inappropriate Comment Style',
  1114: 'Inappropriate Whitespace Style',
  1115: 'Source Code Element without Standard Prologue',
  1116: 'Inaccurate Comments',
  1117: 'Callable with Insufficient Behavioral Summary',
  1118: 'Insufficient Documentation of Error Handling Techniques',
  1119: 'Excessive Use of Unconditional Branching',
  1120: 'Excessive Code Complexity',
  1121: 'Excessive McCabe Cyclomatic Complexity',
  1122: 'Excessive Halstead Complexity',
  1123: 'Excessive Use of Self-Modifying Code',
  1124: 'Excessively Deep Nesting',
  1125: 'Excessive Attack Surface',
  1126: 'Declaration of Variable with Unnecessarily Wide Scope',
  1127: 'Compilation with Insufficient Warnings or Errors',
  1128: 'CISQ Quality Measures (2016)',
  1129: 'CISQ Quality Measures - Reliability',
  1130: 'CISQ Quality Measures - Maintainability',
  1131: 'CISQ Quality Measures - Security',
  1132: 'CISQ Quality Measures - Performance',
  1133: 'Weaknesses Addressed by the SEI CERT Oracle Coding Standard for Java',
  1134: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 00. Input Validation and Data Sanitization (IDS)',
  1135: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 01. Declarations and Initialization (DCL)',
  1136: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 02. Expressions (EXP)',
  1137: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 03. Numeric Types and Operations (NUM)',
  1138: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 04. Characters and Strings (STR)',
  1139: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 05. Object Orientation (OBJ)',
  1140: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 06. Methods (MET)',
  1141: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 07. Exceptional Behavior (ERR)',
  1142: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 08. Visibility and Atomicity (VNA)',
  1143: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 09. Locking (LCK)',
  1144: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 10. Thread APIs (THI)',
  1145: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 11. Thread Pools (TPS)',
  1146: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 12. Thread-Safety Miscellaneous (TSM)',
  1147: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 13. Input Output (FIO)',
  1148: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 14. Serialization (SER)',
  1149: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 15. Platform Security (SEC)',
  1150: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 16. Runtime Environment (ENV)',
  1151: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 17. Java Native Interface (JNI)',
  1152: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 49. Miscellaneous (MSC)',
  1153: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 50. Android (DRD)',
  1154: 'Weaknesses Addressed by the SEI CERT C Coding Standard',
  1155: 'SEI CERT C Coding Standard - Guidelines 01. Preprocessor (PRE)',
  1156: 'SEI CERT C Coding Standard - Guidelines 02. Declarations and Initialization (DCL)',
  1157: 'SEI CERT C Coding Standard - Guidelines 03. Expressions (EXP)',
  1158: 'SEI CERT C Coding Standard - Guidelines 04. Integers (INT)',
  1159: 'SEI CERT C Coding Standard - Guidelines 05. Floating Point (FLP)',
  1160: 'SEI CERT C Coding Standard - Guidelines 06. Arrays (ARR)',
  1161: 'SEI CERT C Coding Standard - Guidelines 07. Characters and Strings (STR)',
  1162: 'SEI CERT C Coding Standard - Guidelines 08. Memory Management (MEM)',
  1163: 'SEI CERT C Coding Standard - Guidelines 09. Input Output (FIO)',
  1164: 'Irrelevant Code',
  1165: 'SEI CERT C Coding Standard - Guidelines 10. Environment (ENV)',
  1166: 'SEI CERT C Coding Standard - Guidelines 11. Signals (SIG)',
  1167: 'SEI CERT C Coding Standard - Guidelines 12. Error Handling (ERR)',
  1168: 'SEI CERT C Coding Standard - Guidelines 13. Application Programming Interfaces (API)',
  1169: 'SEI CERT C Coding Standard - Guidelines 14. Concurrency (CON)',
  1170: 'SEI CERT C Coding Standard - Guidelines 48. Miscellaneous (MSC)',
  1171: 'SEI CERT C Coding Standard - Guidelines 50. POSIX (POS)',
  1172: 'SEI CERT C Coding Standard - Guidelines 51. Microsoft Windows (WIN)',
  1173: 'Improper Use of Validation Framework',
  1174: 'ASP.NET Misconfiguration: Improper Model Validation',
  1175: 'SEI CERT Oracle Secure Coding Standard for Java - Guidelines 18. Concurrency (CON)',
  1176: 'Inefficient CPU Computation',
  1177: 'Use of Prohibited Code',
  2000: 'Comprehensive CWE Dictionary',
};

