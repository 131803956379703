/* eslint-disable import/prefer-default-export, eqeqeq */
import moment from 'moment';

/**
 * Sort an object by property value
 *
 * @param {obj} object
 * @returns sorted array of [key, value]
 */
const sortObjectData = (obj) => {
  const sortable = Object.entries(obj);

  // sorts by DESC value
  sortable.sort((a, b) => b[1] - a[1]);

  return sortable;
};

/**
 * Get top 10 data by breakdown
 * All other counts will be labeled as other.
 */
const getTopBreakdownData = (data, dateRange, count = 10) => {
  const topDataSorted = data.slice(0, dateRange).map((week) => {
    const sortedArr = sortObjectData(week);
    const topObj = sortedArr.reduce((a, c, i) => {
      const accum = a;
      const key = c[0];
      const val = c[1];

      if (i < count) {
        accum[String(key)] = val;
        accum.Other = 0;
      } else {
        accum.Other += val;
      }

      return accum;
    }, {});

    return topObj;
  });

  return topDataSorted;
};

const getDataKeyBasedOnChartValue = (currentChart) => {
  const CHART_VALUE_TO_DATA_KEY = {
    first: 'cardsAdded',
    observations: 'cardsObserved',
    last: 'cardsDropped',
  };

  return CHART_VALUE_TO_DATA_KEY[String(currentChart)];
};

export const normalizeStackedData = (data, currentChart, breakdown, dateRange) => {
  let normalizedData;

  /**
   * If a breakdown is selected, then normalize for stacked bar chart
   * depending on the breakdown
   */
  if (breakdown) {
    normalizedData = data.numCardsByWeek.counts.reduce((a, c) => {
      const accum = a;
      const date = moment.utc(c.week).valueOf();
      let breakdownType;

      switch (breakdown) {
        case 'BIN_TAGS':
          breakdownType = c.binTag || 'Other';
          break;
        case 'BIN_NUMBER':
          breakdownType = c.binNumber || 'Other';
          break;
        case 'SHOP_NAME':
          breakdownType = c.shopName || 'Other';
          break;
        case 'RELEASE_NAME':
          breakdownType = c.releaseName || 'Other';
          break;
        case 'SOURCE_TYPE':
          breakdownType = c.sourceType || 'Other';
          break;
        default:
          breakdownType = 'ERROR';
      }

      // checks to see if an object exists with the same date
      const isDateFound = accum.findIndex(ele => ele.key === date);

      // if date is found then add type and count
      // else create new object and push
      if (isDateFound > -1) {
        accum[Number(isDateFound)][String(breakdownType)]
          = c[getDataKeyBasedOnChartValue(currentChart)];
      } else {
        accum.push({
          key: date,
          [breakdownType]: c[getDataKeyBasedOnChartValue(currentChart)],
        });
      }

      return accum;
    }, []);
  } else {
    normalizedData = data.numCardsByWeek.counts.map(i => ({
      key: i.week,
      count: i[getDataKeyBasedOnChartValue(currentChart)],
    }));
  }

  // combines the "n/a" and "Other" data together,
  // counting them as the same then deletes the "n/a" data
  if (breakdown === 'RELEASE_NAME') {
    normalizedData.forEach((weekData) => {
      // eslint-disable-next-line no-param-reassign
      weekData.Other += weekData['n/a'];

      // eslint-disable-next-line no-param-reassign
      delete weekData['n/a'];
    });
  }

  if (breakdown && breakdown !== 'RELEASE_NAME') {
    return getTopBreakdownData(normalizedData, dateRange);
  }

  return normalizedData.slice(0, dateRange);
};

export const findTag = (source, tag) => source.findIndex(sourceTag => sourceTag.name === tag.name);

export const sanitizeBins = (obj) => {
  const cleaned = obj.map((bin) => {
    const cleansedObj = bin;
    delete cleansedObj.__typename;

    if (cleansedObj.binTags) {
      cleansedObj.binTags.map((tag) => {
        const cleansedBinTags = tag;
        delete cleansedBinTags.__typename;
        return { ...cleansedBinTags };
      });
    }

    return { ...cleansedObj };
  });
  return [...cleaned];
};

export const clean = (obj) => {
  const cleanedObj = obj;
  Object.keys(obj).forEach((prop) => {
    if (cleanedObj[String(prop)] === null || cleanedObj[String(prop)] === undefined || cleanedObj[String(prop)] === '') {
      delete cleanedObj[String(prop)];
    }
  });

  return cleanedObj;
};

export const sanitizeBinTags = (tags) => {
  const filtered_tags = tags.filter(Boolean).filter(tag => /\S/.test(tag));
  const trimmed_tags = filtered_tags.map(tag => (tag.trim()));
  return trimmed_tags;
};

export const sanitizeBinPFL = (pfl) => {
  /*
    pfl = 'Potential Fraud Loss'

    purposely using == instead of === here, if the string `pfl` equals the
    number `value` then the there are no more than 2 decimal places

    If pfl is an empty string, return null instead of an empty string so that the
    shops-api mutations don't error due to type errors
  */
  if (!pfl) {
    return null;
  }

  // toFixed, converts the Number into a String, re-casting with Number
  const value = pfl ? Number(Number(pfl).toFixed(2)) : pfl;
  return pfl == value ? { value } : { value: pfl };
};
