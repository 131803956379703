import React from 'react';

import qs from 'qs';
import Api from '../../../utils/api';
import Common from '../../../utils/common';
import SearchUtils from '../../../utils/searchUtils';

const dev = '_self' in React.createElement('div') ? '' : '';
const url = `/ui/v4/all/search${dev}`;

const Hits = async (searchUrl = '') => {
  const pathname = `/${searchUrl.split('/').slice(3).join('/')}`;
  const params = qs.parse(pathname.split('?')?.[1]);
  const type = Common.Generic.PathToApp(pathname)?.searchType;
  const query = SearchUtils.buildQuery(type, null, params);
  return Api.post(url, { ...query, size: 0 })
    .then(res => (res.ok ? res.data : {}))
    .then(res => ({
      hits: res.hits.total,
      api: query,
    }))
    .catch(() => ({ hits: 0, apiUrl: '' }));
};

const Organizations = async () => Api
    .get('/ui/v4/organizations/')
    .then(res => (res.ok ? res.data : []))
    .catch(() => ([]));

export default {
  Hits,
  Organizations,
};
