import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Map as map } from 'immutable';

import style from './pietable.module.scss';
import Pie from '../Pie/Pie';
import Table from '../Table/Table';
import Invalid from '../../utils/Invalid/Invalid';

const PieTable = ({
  actionable,
  aid,
  cells,
  color,
  header,
  legend,
  labels,
  limit,
  margin,
  pagination,
  results,
  size,
  styles,
  titles,
  values,
  widget,
  onOffset,
  onClick,
}) => {
  const [offset, setOffset] = useState(0);
  const isEmpty = results.has('data') && !results.get('data').size;

  return (
    <div
      className={cx([
        style.pietable,
        styles && styles.transparent && style.transparent,
        styles && styles.centered && style.centered,
      ])}>
      {isEmpty &&
      <Invalid
        icon="error_outline"
        title="No Results"
        subtitle="Try selecting a different record" />}
      {!isEmpty &&
      <div className={style.chart}>
        <div className={style.pie}>
          <Pie
            aid={aid}
            color={color}
            compact
            styles={styles}
            size={size}
            margin={margin}
            offset={offset}
            limit={limit}
            legend={legend}
            titles={titles}
            labels={labels}
            values={cells}
            results={results}
            onClick={(v, k) => onClick(v, k)} />
        </div>
        <div className={style.table}>
          <Table
            actionable={actionable}
            header={header}
            styles={styles}
            limit={limit}
            results={results}
            values={values}
            labels={labels}
            widget={widget}
            pagination={pagination}
            onClick={(v, k, rest) => onClick(v, k, rest)}
            onOffset={(skip) => {
              setOffset(skip);
              if (onOffset) onOffset(skip);
            }} />
        </div>
      </div>}
    </div>
  );
};

PieTable.propTypes = {
  actionable: PropTypes.object,
  aid: PropTypes.string,
  color: PropTypes.string,
  cells: PropTypes.object,
  header: PropTypes.bool,
  legend: PropTypes.bool,
  labels: PropTypes.object,
  limit: PropTypes.number,
  margin: PropTypes.object,
  pagination: PropTypes.bool,
  results: PropTypes.object,
  size: PropTypes.object,
  styles: PropTypes.object,
  titles: PropTypes.object,
  values: PropTypes.object,
  widget: PropTypes.object,
  onOffset: PropTypes.func,
  onClick: PropTypes.func,
};

PieTable.defaultProps = {
  actionable: {},
  aid: '',
  color: '',
  cells: map(),
  header: true,
  legend: false,
  labels: map(),
  limit: 5,
  margin: {},
  pagination: true,
  results: map(),
  size: {},
  styles: null,
  titles: map(),
  values: map(),
  widget: map(),
  onOffset: null,
  onClick: null,
};

export default PieTable;
