import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import style from './internalLink.module.scss';
import Common from '../../utils/common';
import { UserContext } from '../utils/Context';

const InternalLink = ({ children, to, className, ...options }) => {
  const user = useContext(UserContext);

  if (!to || Object.entries(to).length === 0) {
    return (
      <span className={cx([className, style.internal])}>{children}</span>
    );
  }

  let navigation = 'self';
  if (sessionStorage && sessionStorage.getItem('navigation')) navigation = sessionStorage.getItem('navigation');

  return (
    <Link
      rel="noreferrer"
      target={navigation === 'self' ? '' : '_blank'}
      to={typeof to === 'string'
        ? to
        : `${to?.pathname}${qs.stringify(to?.query, { addQueryPrefix: true, encodeValuesOnly: true })}`}
      onClick={(e) => {
        // checks if link is external. if it is, trigger a confirmation dialog
        Common.Events.DispatchExternalLinkEvent(to, e);
      }}
      className={cx([
        className,
        !user.getIn(['prefs', 'href']) && style.internal,
      ])}
      {...options}>
      {children}
    </Link>
  );
};

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

InternalLink.defaultProps = {
  className: '',
  to: {},
};

export default InternalLink;
