import React from 'react';

import { fromJS } from 'immutable';

import Api from '../../../utils/api';
import Apps from '../../../constants/Apps';
import Token from '../../../utils/token';
import UserActions from '../../../actions/userActions';

const cache = {};
const limit = 2;
const dev = '_self' in React.createElement('div') ? '/dev' : '';
const documentsUrl = `/ui/v4/documents${dev}`;
const reportsUrl = '/ui/v4/reports';

const topics = async (text = '', prm = []) => {
  const restrictCVEs = entry => (!prm.some(p => /(vln|cve).r/.test(p))
    ? !entry?.categories?.some(c => /cve/ig.test(c))
    : entry);

  const restrictPrivate = (entry) => {
    const orgId = Token.get('sf_id') || '';
    if (entry?.is_private_topic === true) {
      const found = entry?.organizations.find(v => v === orgId);
      if (!found) return false;
      return found?.length > 0;
    }
    return true;
  };

  const restrictText = text
    /* eslint-disable-next-line security/detect-non-literal-regexp */
    ? v => [v.topic_name].some(_v => new RegExp(text.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig').test(_v))
    : v => v.is_trending;

  const restrictVuln = entry => (!prm.some(p => /vln.r/.test(p))
    ? !entry?.categories?.some(c => /vulnerabilities/ig.test(c))
    : entry);

  const data = cache?.topics
    ? cache?.topics
    : await Api
      .get(`${documentsUrl}/topics/wiki`)
      .then(res => (res.ok ? res.data.data : null))
      .then(res => Object.assign(cache, { topics: res }))
      .then(res => res?.topics)
      .then(res => res.filter(restrictPrivate));

  const restricted = data
    ?.filter(restrictText)
    ?.filter(restrictCVEs)
    ?.filter(restrictPrivate)
    ?.filter(restrictVuln)
    ?.map(v => ({
      ...v,
      name: v.topic_name,
      value: `/home${v?.queries?.[0]?.query?.split('/home')?.[1]}`,
      url: `/home${v?.queries?.[0]?.query?.split('/home')?.[1]}`,
      type: 'Explore Topic',
      icon: 'explore',
    }));

  const generateLabel = (item) => {
    switch (item) {
      case 'Cyber Crime':
        return 'Cybercrime';
      case 'private_topic':
        return `${Token.get('ogn') ?? ''} Topics (Private)`;
      default:
        return item?.replace(/[+]/ig, '');
    }
  };

  const categories = fromJS([...new Set(data
    ?.map(v => v?.categories?.[0]?.trim()))])
    ?.filter(v => (/vulnerabilities/ig.test(v) ? prm.some(p => /vln.r/.test(p)) : true))
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(v => fromJS({
      value: `explore.${v}`,
      parent: 'explore',
      label: generateLabel(v),
      icon: 'explore',
      alt: `Explore - ${v}`,
      path: [`/home/explore/topics/${v}`],
      ui: true,
      home: true,
      subnav: true,
      test: /ddw/,
    }));

  UserActions.set(['user', 'apps'], fromJS(Apps).concat(categories));

  return restricted;
};

const reports = async (text = '', prm = []) => {
  const tags = !prm.some(p => /(vln|cve).r/.test(p)) ? '-VULNERABILITY' : '';

  const restrictText = text
    /* eslint-disable-next-line security/detect-non-literal-regexp */
    ? v => [v.title].some(_v => new RegExp(text, 'ig').test(_v))
    : v => v;

  const data = cache?.reports?.[String(text)] ? cache?.reports?.[String(text)] : await Api
    .get(`${reportsUrl}`, { sort: 'version_posted_at', tags, title: text, limit })
    .then(res => (res.ok ? res.data.data : null))
    .then(res => Object.assign(cache, { reports: { ...cache?.reports, [String(text)]: res } }))
    .then(res => res?.reports?.[String(text)]);
  return data
    .filter(restrictText)
    .slice(0, text ? limit : 1)
    .map(v => ({
      ...v,
      name: v.title,
      value: `/home/intelligence/reports/report/${v.id}`,
      url: `/home/intelligence/reports/report/${v.id}`,
      type: 'Intelligence Report',
      icon: 'lightbulb_outline',
    }));
};

const dashboards = (text = '', prm = []) => {
  const restrictCVEs = entry => (!prm.some(p => /(vln|cve).r/.test(p))
    ? !/cve/ig.test(entry?.value)
    : entry);

  const restrictText = text
    /* eslint-disable-next-line security/detect-non-literal-regexp */
    ? v => [v.text, v.label].some(_v => new RegExp(text, 'ig').test(_v))
    : v => v;

  return Apps
    .filter(v => /analytics\./ig.test(v.value))
    .filter(restrictText)
    .filter(restrictCVEs)
    .slice(0, 1)
    .map(v => ({
      ...v,
      name: v?.label,
      value: v?.path?.[0],
      url: v?.path?.[0],
      type: 'Dashboard',
      icon: 'assessments',
    }));
};

const Suggestions = async (text = '', prm = []) => Promise.all([
  topics(text, prm),
  reports(text, prm),
  dashboards(text, prm),
]);

export default {
  Suggestions,
};
