import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import qs from 'qs';
import { CircularProgress } from '@mui/material';
import { fromJS, Map as map } from 'immutable';

import Common from '../../utils/common';
import History from '../../utils/history';
import Invalid from '../../components/utils/Invalid/Invalid';

const Redirect = ({
  match: {
    params,
  },
}) => {
  const [error, setError] = useState();

  const fpid = params.id;
  const SearchUrl = '/ui/v4/all/search';
  const AlertingUrl = '/ui/v4/alerts';
  const { query } = History.getCurrentLocation();
  const { keywordId } = query;

  const items = (signal) => {
    const queryRequest = map()
      .set('query', `+fpid:("${fpid}")`)
      .set('traditional_query', true)
      .set('size', 1)
      .toJS();
    const opts = {
      signal,
      credentials: 'include',
      headers: {
        'X-FP-Version': process.env.VERSION,
      },
    };
    const qOpts = { addQueryPrefix: true, encode: false };
    const url = `${SearchUrl}${qs.stringify({ ...queryRequest }, qOpts)}`;
    return [fetch(url, opts)];
  };

  const keywords = (signal) => {
    if (!keywordId) return [];
    const url = `${AlertingUrl}/keywords/${keywordId}`;
    const opts = {
      signal,
      credentials: 'include',
      headers: {
        'X-FP-Version': process.env.VERSION,
      },
    };
    return [fetch(url, opts)];
  };

  const load = async (controller, clear) => {
    const { signal } = controller;
    const promises = [...items(signal), ...keywords(signal)];

    try {
      await Promise.all(promises)
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(([itemJson, keywordJson]) => {
          const data = fromJS(itemJson.hits.hits[0]._source);
          const queryString = (!keywordJson) ? query.query : keywordJson.value;
          const validatedQueryString = (queryString || '').replace(/(\|)(\*|''|""|\s?\)|$)/gmi, '$2');
          const updatedQuery = { ...query, query: validatedQueryString };
          const route = Common.Generic.Route(data, updatedQuery);
          History.push(route);
        });
    } catch {
      setError(true);
    }

    clearTimeout(clear);
  };

  useEffect(() => {
    const timeout = 60000; // 60 seconds
    const controller = new AbortController();
    const clear = setTimeout(() => controller.abort(), timeout);
    load(controller, clear);
  }, [fpid]);

  return !error
    ? <CircularProgress />
    : <Invalid
        icon="error"
        title="Link Timeout"
        help={['Try reloading the current page', 'Confirm forwarding link is valid']} />;
};

Redirect.propTypes = {
  match: PropTypes.object.isRequired,
};


export default Redirect;
