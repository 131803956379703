import * as React from 'react';

import {
  TableCell as MuiTableCell,
  TableCellBaseProps,
} from '@mui/material';

interface TableCellProps {
  /** The component used for the root node. Defaults to 'td', use 'th' for table headers */
  component?: React.ElementType<TableCellBaseProps>;

  /** The content of the table cell */
  children?: React.ReactNode;
}

/** A table column */
export const TableCell = ({
  component,
  children,
  ...rest
}: TableCellProps): JSX.Element =>
  <MuiTableCell component={component} {...rest} >
    {children}
  </MuiTableCell>;

TableCell.defaultProps = {
  component: 'td',
  children: '',
};
