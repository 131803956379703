import React, { useState, useEffect } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Pagination } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

import style from '../sanctions.module.scss';
import History from '../../../utils/history';
import Token from '../../../utils/token';
import Api from '../../../utils/api';
import Messages from '../../../constants/Messages';
import SearchActions from '../../../actions/searchActions';
import { getPrettyStatusString, getStatusString, searchStatusEnum } from '../Constants/SearchStatus';

const SanctionsTable = (() => {
  const [savedSearches, setSavedSearches] = useState();
  const [page, setPage] = useState(1);
  const [filterClient, setFilterClient] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterNotes, setFilterNotes] = useState('');
  const [filterTerms, setFilterTerms] = useState('');
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  const handleFilterChange = () => {
    const query = { page };
    if (filterTerms !== '') {
      Object.assign(query, { searchTerms: filterTerms });
    }
    if (filterNotes !== '') {
      Object.assign(query, { noteText: filterNotes });
    }
    if (filterClient !== '') {
      Object.assign(query, { clientName: filterClient });
    }
    if (filterStatus !== '') {
      Object.assign(query, { searchStatus: filterStatus });
    }
    if (!loading) {
      setLoading(true);
    }
    Api.get('/ui/v4/sanctions/savedSearch', query, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        setSavedSearches(res);
        setLoading(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setLoading(false);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => handleFilterChange(), 500);
    return () => clearTimeout(timeoutId);
  }, [page, filterTerms, filterNotes, filterClient, filterStatus]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    if (query.filterClient) {
      setFilterClient(query.filterClient);
    }
    if (query.filterTerms) {
      setFilterTerms(query.filterTerms);
    }
    return function cleanup() {
      isOnPage = false;
    };
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      <div className={style.sanctionssection}>
        <div className={style.title}>Past Searches</div>
        <Paper className={style.card}>
          <div className={style.filters}>
            <label data-cy="filterClient" htmlFor="savedSearchFilterClient" className={style.pastsearchfilter}>
              <span className={style.insetspan}>Filter by Client Name:</span>
              <input
                type="text"
                name="client"
                id="savedSearchFilterClient"
                value={filterClient}
                onChange={(event) => {
                setFilterClient(event.target.value);
                setPage(1);
              }} />
            </label>
            <label data-cy="filterTerms" htmlFor="savedSearchFilterTerms" className={style.pastsearchfilter}>
              <span className={style.insetspan}>Filter by Search Terms:</span>
              <input
                type="text"
                name="terms"
                id="savedSearchFilterTerms"
                value={filterTerms}
                onChange={(event) => {
                  setFilterTerms(event.target.value);
                  setPage(1);
                }}
              />
            </label>
            <label data-cy="filterStatus" htmlFor="savedSearchFilterStatus" className={style.pastsearchfilter}>
              <span className={style.insetspan}>Filter by Status:</span>
              <select
                value={filterStatus}
                id="savedSearchFilterStatus"
                onChange={(event) => {
                setFilterStatus(event.target.value);
                setPage(1);
              }}>
                <option value=""> </option>
                <option value={getStatusString(searchStatusEnum.PREPAYMENT_CHECK)}>
                  {getPrettyStatusString(searchStatusEnum.PREPAYMENT_CHECK)}
                </option>
                <option value={getStatusString(searchStatusEnum.PAYMENT_MADE)}>
                  {getPrettyStatusString(searchStatusEnum.PAYMENT_MADE)}
                </option>
              </select>
            </label>
            <label data-cy="filterNotes" htmlFor="savedSearchFilterNotes" className={style.notesfilter}>
              <span className={style.insetspan}>Filter by Notes:</span>
              <input
                type="text"
                name="notes"
                id="savedSearchFilterNotes"
                value={filterNotes}
                onChange={(event) => {
                setFilterNotes(event.target.value);
                setPage(1);
              }} />
            </label>
          </div>
          <Table aria-label="simple table" data-cy="savedSearchTable">
            <TableHead>
              <TableRow>
                <TableCell className={style.savedcellhead}>Client Name</TableCell>
                <TableCell className={style.savedcellhead}>Search Term(s)</TableCell>
                <TableCell className={style.savedcellhead}>Returned Results</TableCell>
                <TableCell className={style.savedcellhead}>Date of Search</TableCell>
                <TableCell className={style.savedcellhead}>Status</TableCell>
                <TableCell className={style.savedcellhead}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {savedSearches
              && savedSearches.results
              && savedSearches.results.map(savedSearch => (
                <TableRow key={savedSearch.search_date}>
                  <TableCell className={style.savedcell} >{savedSearch.client_names !== undefined && savedSearch.client_names.join(', ').length !== 0 ? savedSearch.client_names.join(', ') : 'None'}</TableCell>
                  <TableCell className={style.savedcell}>{savedSearch.search_terms.join(', ')}</TableCell>
                  <TableCell className={style.savedcell}>
                    {savedSearch.any_results === false ? (
                      <React.Fragment>
                        <div className={style.reportinfo}>
                          <CheckCircleOutline className={style.noresultscheck} />
                          No risk factors found
                        </div>
                      </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className={style.reportinfo}>
                            <ErrorOutline className={style.resultswarn} />
                            Risk factors found
                          </div>
                        </React.Fragment>
                    )}
                  </TableCell>
                  <TableCell className={style.savedcell}>
                    {new Date(savedSearch.search_date).toLocaleString()}
                  </TableCell>
                  <TableCell className={style.savedcell}>
                    {savedSearch.search_status === 'prepaymentCheck' && <span className={style.draftstatus}>Pre-Payment Check</span>}
                    {savedSearch.search_status === 'paymentMade' && <span className={style.submittedstatus}>Payment Made</span>}
                    {savedSearch.search_status !== 'prepaymentCheck' && savedSearch.search_status !== 'paymentMade' && savedSearch.search_status !== 'excluded' && <span className={style.nullstatus}>Null</span>}
                  </TableCell>
                  <TableCell className={style.linkcell}><a href={`/home/sanctions/savedSearch/${savedSearch.id}`}>View</a></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loading && <CircularProgress />}
          {savedSearches && savedSearches.pageInfo && savedSearches.results && (
            <div data-cy="savedSearchPagination" className={style.savedpaginate}>
              {(savedSearches.pageInfo.pageSize * (savedSearches.pageInfo.pageNumber - 1)) + 1}
              -
              {(savedSearches.pageInfo.pageSize * (savedSearches.pageInfo.pageNumber - 1))
              + savedSearches.results.length}{' '}
              of {savedSearches.pageInfo.totalResults}
              <Pagination
                count={Math.ceil(
                  savedSearches.pageInfo.totalResults / savedSearches.pageInfo.pageSize,
                )}
                page={page}
                onChange={handlePageChange} />
            </div>
          )}
        </Paper>
      </div>
    </React.Fragment>
  );
});

export default SanctionsTable;
