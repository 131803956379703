import PropTypes from 'prop-types';
import * as React from 'react';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { fromJS, Map as map } from 'immutable';

import Editor from '../../manage/Editor';
import Query from './query';
import SearchActions from '../../../actions/searchActions';

export const SaveAlertDialog = ({
  data,
  keywordClasses,
  notificationProfiles,
  filters,
  onClose,
  open,
  ownerId,
  }) => {
  const onSave = (_, values) => {
    const subscriptionIds = [];
    values.getIn([0, 'keywordSubscriptions'])?.forEach((subs) => {
      subscriptionIds.push(subs.get('id'));
    });

    const updates = {
      active: values.getIn([0, 'active']),
      is_curated: false,
      keyclass_id: values.getIn([0, 'keyclass', 'id']),
      name: values.getIn([0, 'name']),
      owner_id: ownerId,
      value: `+(${values.getIn([0, 'value']) ? values.getIn([0, 'value']).replace('+body.raw:', 'body.text\\/plain') : String.raw`""`})`,
      simple_query: false,
      filters: values.getIn([0, 'filtered']) ? data.getIn(['requestBody', 'query'])
                     .replace(/\+sort_date:\[.*\]/, '')
                     .replaceAll('+(|', '+(') // remove leading pipe
                     .replaceAll('|', '| ') // Add space
                     .replace(`+(${filters.get('query')})`, '') : '',
    };
    Query.saveAlertingKeyword(updates, ownerId).then((res) => {
      if (res.status === 201) {
        const payload = {
          keyword_id: res.data[0].id,
          profile_ids: subscriptionIds,
          receives_email: subscriptionIds,
        };
        if (subscriptionIds.length) Query.subscription(payload);
        onClose();
        SearchActions.set(['search', 'info', 'message'], 'Keywords Added');
      }
    }).catch((err) => {
      SearchActions.set(['search', 'info', 'message'], `Error: ${err.response.data.detail}`);
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={Boolean(open)}
      onClose={() => onClose()}>
      <DialogTitle>Create new Keyword</DialogTitle>
      <DialogContent>
        <Editor
          fields={[
          {
            type: 'inline-toggle',
            fields: [{
              value: 'active',
              label: 'Active',
              type: 'toggle',
              bulk: true,
              default: true,
            },
           ],
          },
          {
            value: 'name',
            label: 'Name',
            type: 'text',
            req: false,
            placeholder: 'Please name your keyword',
          },
          {
            value: 'keyclass',
            label: 'Category',
            type: 'dropdown',
            key: 'name',
            valueKey: 'name',
            options: keywordClasses,
            disabled: true,
          },
          {
            value: 'keywordSubscriptions',
            label: 'recipient',
            type: 'multiselect',
            key: 'name',
            valueKey: 'name',
            options: notificationProfiles,
          },
          { value: 'value', label: 'Query', type: 'textarea', length: 3000 },
          {
            value: 'filtered', label: 'Include filters', type: 'checkbox',
            name: 'searches.filtered',
            help: `*Note: date filters, once applied, will be fixed in
            accordance with the date on which the search was saved.`, bulk: true,
          },
        ]}
          data={fromJS([{
            keyclass: keywordClasses?.find(v => v.get('name') === 'search'),
            value: filters.get('query'),
            filtered: true,
          }])}
          save={(searchType, values) => onSave(searchType, values)}
          toggle={() => onClose()}
          />
      </DialogContent>
    </Dialog>
  );
};

SaveAlertDialog.propTypes = {
  data: PropTypes.object,
  filters: PropTypes.object,
  keywordClasses: PropTypes.object,
  notificationProfiles: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  ownerId: PropTypes.string,
};

SaveAlertDialog.defaultProps = {
  data: map(),
  filters: map(),
  keywordClasses: map(),
  notificationProfiles: map(),
  onClose: null,
  open: false,
  ownerId: '',
};

export default SaveAlertDialog;
