import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { fromJS, Map as map } from 'immutable';
import { Grid, Col } from 'react-flexbox-grid/lib';
import {
    CircularProgress,
    Icon,
    Paper,
  } from '@mui/material';

import HostData from './HostData';
import SearchActions from '../../../actions/searchActions';
import Query from './query';
import style from './credentials.module.scss';
import Text from '../../../utils/text';
import Token from '../../../utils/token';

const CustomerCredentialDetail = ({
  dataId,
  blur,
}) => {
  const [data, setData] = useState(map());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!dataId) return;
    const fpid = dataId.split('::')[0];
    const key = dataId.split('::')[1];
    Query.getCredentialDetails(fpid, key)
      .then(res => setData(fromJS(res.data?.results?.[0].results[0])))
      .catch((err) => {
        if (err.message !== 'canceled') {
          SearchActions.set(['search', 'info', 'message'], err.message);
        }
        return err;
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isEmpty(data)) return;
    SearchActions.set(['search', 'result', 'customer-credentials'], data);
  }, [data]);

  return (
    <Grid
      fluid
      name="component.credentials"
      className={cx([style.base, style.credentials])}>
      {isLoading && <CircularProgress />}
      {!isLoading && !isEmpty(data) &&
      <Col xs={12} key={data.get('fpid')}>
        <Paper className={style.card}>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Username:</div>
            <div>
              {data.getIn(['username']) || '--'}
            </div>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Password:</div>
            <div>
              {data.getIn(['password']) || '--'}
            </div>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Date of Breach:</div>
            <div>
              {data.getIn(['breach', 'created_at', 'date-time'])
                ? moment.utc(data.getIn(['breach', 'created_at', 'date-time'])).format('MMM D, YYYY')
                : '--'}
            </div>
            <Icon
              data-for="global.tooltip"
              data-tip="The date the credential became available as part of a breach."
              className={style.help}>
              help
            </Icon>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last Observed Date:</div>
            <div>
              {data.getIn(['previously_observed_at', 'date-time'])
               ? moment.utc(data.getIn(['previously_observed_at', 'date-time'])).format('MMM D, YYYY')
               : '--'}
            </div>
            <Icon
              data-for="global.tooltip"
              data-tip="The date the credential was last seen by Flashpoint."
              className={style.help}>
              help
            </Icon>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Title:</div>
            {data.getIn(['breach', 'title']) || '--'}
            <Icon
              className={cx([style.launch])}
              data-for="global.tooltip"
              data-tip="View in breach dashboard"
              // onClick={e => onBreach(e, data.getIn(['breach']))}
              >
              dashboard
            </Icon>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Sourced <br />From:</div>
            <div>
              {data.getIn(['breach', 'source']) || '--'}
            </div>
          </div>
          {data.getIn(['breach', 'context']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Context:</div>
              <div>{data.getIn(['breach', 'context'])}</div>
            </div>}
          {data.get('affected_url') &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Affected URL:</div>
              <div>{data.get('affected_url')}</div>
            </div>}
          {data.get('affected_domain') &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Affected Domain:</div>
              <div>{data.get('affected_domain')}</div>
            </div>}
          {data.getIn(['body', 'raw']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Raw Content:</div>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {Text.BlurAll(Text.StripHtml(data.getIn(['body', 'raw'])), blur)}
              </pre>
            </div>}
          {(data.has('cookies') || data.has('infected_host_attributes')) && Token.get('prm').some(p => /dat.ccm.cus.ha.r|dat.ccm.cus.hd.r/ig.test(p)) &&
            <HostData breach={data} />
          }
          {data.get('meetsProfileSpec') &&
            <Icon
              className={cx([style.icon, style.star, 'material-icons'])}
              data-for="body.tooltip"
              data-tip="Meets Profile Specifications">
              star
            </Icon>}
        </Paper>
      </Col>}
    </Grid>
  );
};

CustomerCredentialDetail.propTypes = {
    dataId: PropTypes.string.isRequired,
    blur: PropTypes.bool,
  };

CustomerCredentialDetail.defaultProps = {
  blur: false,
};

export default CustomerCredentialDetail;
