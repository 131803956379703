import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Chip,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
} from '@mui/material';
import CancelRounded from '@mui/icons-material/CancelRounded';
import Close from '@mui/icons-material/Close';
import EditRounded from '@mui/icons-material/EditRounded';

const useStyles = makeStyles(() => ({
  chip: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#eff0fb',
      border: '1px solid #8e96b1',
      borderRadius: '4px',
      color: '#2a2c3c',
    },
    '& .MuiSvgIcon-root': {
      fill: '#7a84a1',
      '&:hover': {
        fill: '#2a2c3c',
      },
    },
    '& .MuiChip-deleteIcon': {
      width: '44px',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
      '& .MuiSvgIcon-root:hover': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
  },

  popover: {
    '& .MuiPaper-root': {
      width: '400px',
      padding: '0',
    },
  },
  resetBtn: {
    marginRight: 'auto',
  },
}));

const EditableChip = (props) => {
  const classes = useStyles();
  const { onEdit, inputLabel, label, ...rest } = props;
  const [dialog, setDialog] = useState();
  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    setFieldValue(label || '');
  }, [label]);

  const onChange = (value) => {
    if (props.disabled) return;
    onEdit(value);
  };

  const onOpenEdit = (event) => {
    if (props.disabled) return;
    setDialog({
      key: 'edit',
      target: event.target,
    });
  };

  return (
    <div className={classes.chip}>
      <Chip
        className={classes.chip}
        deleteIcon={
          <div>
            <EditRounded
              onClick={(e) => {
                e.stopPropagation();
                onOpenEdit(e);
              }} />
            <CancelRounded />
          </div>
        }
        {...rest}
        label={label}
        disabled={props.disabled}
      />
      <Popover
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        className={classes.popover}
        anchorEl={dialog?.target}
        open={!!(dialog?.key === 'edit')}
        onClose={() => setDialog()}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Paper
          open
          tabIndex={0}
          onClick={e => e.stopPropagation()}
          onFocus={e => e.stopPropagation()}
          onBlur={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          >
          <DialogContent>
            <form onSubmit={(e) => {
              e.preventDefault();
              onChange(fieldValue);
              setDialog();
              }}>
              <TextField
                onChange={e => setFieldValue(e.target.value)}
                value={fieldValue}
                label={inputLabel}
                variant="outlined"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setFieldValue('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                />
            </form>
          </DialogContent>
          <DialogActions>
            <Button className={classes.resetBtn} onClick={() => setFieldValue(label)}>Reset</Button>
            <Button onClick={() => setDialog()}>Cancel</Button>
            <Button
              color="secondary"
              onClick={() => {
                onChange(fieldValue);
                setDialog();
              }}>
              Apply
            </Button>
          </DialogActions>
        </Paper>
      </Popover>
    </div>
  );
};

EditableChip.propTypes = {
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
};

EditableChip.defaultProps = {
  inputLabel: '',
  label: '',
  onEdit: null,
  disabled: false,
};

export default EditableChip;
