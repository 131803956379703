import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { hideMenu } from 'react-contextmenu/modules/actions';
import { ContextMenu as CM, SubMenu, connectMenu } from 'react-contextmenu';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  Icon,
  ListItem,
} from '@mui/material';

import style from './contextMenu.module.scss';
import Text from '../../utils/text';
import Common from '../../utils/common';
import History from '../../utils/history';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

/**
 * Example props:
 * props: {
  data: {
    basetypes: ['conversation', 'chat', 'telegram', 'message', 'chat', 'chats', 'channels']
    body: {raw: '海18320', text/html+sanitized: '海18320'}
    container: {created_at: {…}, fpid: 'j_SlkCptVxiHPAmb4pnWQA', name: '51PAY道', native_id: '1163472334', type: 'supergroup'}
    container_position: {index_number: 1}
    enrichments: {card-numbers: {…}, v1: {…}}
    fpid: "hWXtmwX2WYyWlX6E0GGEQA"
    highlight: {fpid: Array(1), container.type: Array(1), site_actor.names.aliases: Array(1), basetypes: Array(4), container.title: Array(1), …}
    is_media: false
    site: {title: 'Telegram'}
    site_actor: {fpid: '3Ybc5bQdWWSzYMR9Gd4W0g', names: {…}, native_id: '5583314488'}
    sort_date: "2022-07-06T18:14:16Z"
  },
  items: [
    { "action": [ "copy", "filter" ], "filterKey": "author", "field": [ "site_actor.names.handle", "site_actor.username", "site_actor.name" ], "fieldValue": "3Ybc5bQdWWSzYMR9Gd4W0g::Eartha Haley", "fieldName": "Username", "overrideQuery": {} },
    { "action": [ "copy" ], "field": [ "site_actor.names.aliases" ], "fieldName": "Aliases" },
    { "action": [ "copy" ], "field": [ "site_actor.first_name" ], "fieldName": "First Name" },
    { "action": [ "copy" ], "field": [ "site_actor.last_name" ], "fieldName": "Last Name" },
    { "action": [ "copy" ], "field": [ "site_actor.native_id" ], "fieldName": "ID" }
  ],
  target: div,
  type: "",
}
 */
const DynamicMenu = (props) => {
  const { trigger } = props;
  let actions = list();
  if (trigger) {
    actions = (typeof trigger.items === 'function')
      ? trigger.items(trigger.data)
      : trigger.items;
  }

  const [menuHoverStatus, setMenuHoverStatus] = useState(map());

  function onMenuHover(key, open = true) {
    const updatedStatus = map({ ...menuHoverStatus.map(() => false).toJS(), [key]: open });
    setMenuHoverStatus(updatedStatus);
  }

  function copy(value, render) {
    let copyValue = value;
    if (list.isList(value) || Array.isArray(value)) {
      copyValue = (render)
        ? value.map(v => render(v)).join(', ')
        : value.join(', ');
    } else if (render) {
      copyValue = render(value);
    }
    navigator.clipboard.writeText(copyValue);
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  }

  function filter(value, filterKey, overrideQuery) {
    const { query } = History.getCurrentLocation();
    const route = Common.Generic.SearchRoute(trigger.data, overrideQuery || query);
    History.navigateTo({
      pathname: route.pathname,
      query: { ...route.query, [filterKey]: value, skip: undefined },
      hash: route.hash,
    });
  }

  const copyableStringValue = maybeString => (
    typeof maybeString.join === 'function' ? maybeString.join(', ') : maybeString);

  function onClick(value, action, type) {
    hideMenu();
    switch (type) {
      case 'copy':
        copy(Text.StripHighlight(copyableStringValue(value)), action.get('render'));
        break;

      case 'filter':
        filter(Text.StripHighlight(value), action.get('filterKey'), (action.get('overrideQuery')) ? action.get('overrideQuery').toJS() : null);
        break;

      default: break;
    }
  }

  const menuItems = () => {
    const topLevel = [];
    const grouped = {};
    fromJS(actions).filter(v => !v.get('top')).forEach((v) => {
      v.get('action').forEach((a) => {
        if (grouped[String(a)]) {
          grouped[String(a)].push(v);
        } else {
          grouped[String(a)] = [v];
        }
      });
    });

    map(grouped).forEach((v, k) => {
      const subMenuItems = [];
      v.forEach((o) => {
        let fieldValue = (o.get('fieldValueOp') && o.get('fieldValueOp')(trigger.data)) || o.get('fieldValue');
        o.get('field').forEach((f) => {
          const key = f.split('.');
          if (!fieldValue) fieldValue = trigger.data.getIn(key);
        });
        // you'll notice sometimes that props.items has 5 values but the ContextMenu only has 3
        // here we check if trigger.data actually has the value specified by the item's 'field' key
        // if it doesn't have a value, we don't add it to the menu
        if (fieldValue
          && (!(fieldValue.isEmpty && fieldValue.isEmpty()) || fieldValue.length > 0)) {
          subMenuItems.push((
            <ListItem
              key={o.get('fieldName')}
              className={cx([style.item])}
              onClick={() => onClick(fieldValue, o, k)}>
              {`${o.get('fieldName')} value`}
            </ListItem>
          ));
        }
      });
      if (subMenuItems.length === 0) {
        subMenuItems.push((
          <ListItem
            key="none"
            className={cx([style.item])}
            onClick={() => false}>
            No actionable fields
          </ListItem>
        ));
      }
      topLevel.push((
        <SubMenu
          key={k} // eslint-disable-line react/no-array-index-key
          className={style['sub-menu']}
          attributes={{ className: `${cx([style.item, style.top])}` }}
          title={(
            <span>
              {Text.Sentence(k)}
              <Icon className={cx([style.icon, style.action, 'material-icons'])}>
                keyboard_arrow_right
              </Icon>
            </span>
          )}>
          {subMenuItems.filter(f => f)}
        </SubMenu>
      ));
    });
    fromJS(actions).filter(v => v.get('top')).forEach((v) => {
      topLevel.push((
        <ListItem
          key={v.getIn(['action', 0])}
          className={cx([style.item])}
          onClick={() => v.get('onClick')(trigger.data)}>
          {Text.Sentence(v.getIn(['action', 0]))}
        </ListItem>
      ));
    });
    return topLevel;
  };

  // if (!trigger) return <div />;

  return (
    <CM
      id="global.context"
      className={style['context-menu']}
      onHide={() => onMenuHover('', false)}>
      {!trigger && <div />}
      {trigger && menuItems()}
    </CM>
  );
};

DynamicMenu.propTypes = {
  trigger: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    items: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.func,
    ]).isRequired,
  }),
};

DynamicMenu.defaultProps = {
  trigger: {},
};

const ConnectedMenu = connectMenu('global.context')(DynamicMenu);

function ContextMenu() {
  return (
    <ConnectedMenu />
  );
}

export default ContextMenu;
