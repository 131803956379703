import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    padding: '16px',
  },
  label: {
    margin: 0,
    padding: '0 !important',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    margin: '2px 0',
    padding: 0,
  },
  radio: {
    margin: '0 4px 0 0',
    padding: 0,
  },
  row: {
    display: 'flex',
    '& .MuiIconButton-root': {
      padding: '3px 0',
      visibility: 'hidden',
      '& .MuiIcon-root': {
        fontSize: '1.8rem',
      },
    },
    '&:hover': {
      '& .MuiIconButton-root': {
        visibility: 'visible',
      },
    },
  },
});

const SearchTypesMenu = ({ config, onChange }) => {
  const [menu, setMenu] = useState([]);
  const [selected, setSelected] = useState('communities');
  const classes = useStyles();

  useEffect(() => {
    if (config?.topLevel) {
      setMenu(config.topLevel);
    }

    if (config?.preselected) {
      // Find id and index of the parent category for preselected basetypes
      let parentId = '';
      let parentIndex = -1;
      config?.topLevel?.forEach((parent, index) => {
        if (config.preselected.includes(parent.id)) {
          parentId = parent.id;
          parentIndex = index;
        }
        if (parent?.children?.length > 0) {
          parent.children.forEach((child) => {
            if (config.preselected.includes(child.id)) {
              parentId = parent.id;
              parentIndex = index;
            }
          });
        }
      });

      if (!parentId || parentIndex === -1) {
        return;
      }

      // If parent has children, preselect them
      const newMenu = (menu?.length > 0) ? [...menu] : [...config?.topLevel];

      const newChildren = newMenu[Number(parentIndex)]?.children;
      if (!newChildren) {
        setSelected(parentId);
        return;
      }

      const processedChildren = newChildren.map((child) => {
        if (config.preselected.includes(child.id)) {
          return {
            ...child,
            checked: true,
          };
        }
        return {
          ...child,
        };
      });
      newMenu[Number(parentIndex)].children = processedChildren;
      setMenu(newMenu || config?.topLevel);
      setSelected(parentId);
    }
  }, [config]);

  const updateParent = (newMenu, newSelected) => {
    if (!newMenu) return;

    // Gather basetypes that have been selected
    const basetypes = {};
    newMenu.forEach((parent) => {
      basetypes[parent.id] = false;
      if (parent.id === newSelected) {
        basetypes[parent.id] = true;
        if (parent?.children?.length > 0) {
          parent.children.forEach((child) => {
            basetypes[child.id] = child.checked;
          });
        }
      } else if (parent.id !== selected) {
        if (parent?.children?.length > 0) {
          parent.children.forEach((child) => {
            basetypes[child.id] = false;
          });
        }
      }
    });

    setMenu([...newMenu]);

    // Send them up to search
    onChange?.(fromJS(basetypes));
  };

  const onRadioChange = (value) => {
    if (value === selected) return;

    // Preselect all of selection's child checkboxs
    const newMenu = menu.map((parent) => {
      if (parent.id === value) {
        if (parent?.children) {
          const newChildren = parent.children.map(child => ({ ...child, checked: true }));
          return { ...parent, children: newChildren };
        }
      }
      return parent;
    });

    setSelected(value);
    updateParent([...newMenu], value);
  };

  const onCheckboxChange = (parentIndex, childIndex, checked, only) => {
    const newMenu = [...menu];

    // clear checked entries when selecting `only` item
    const newChildren = newMenu[Number(parentIndex)]
      ?.children
      ?.map(child => (only ? { ...child, checked: false } : child));
    newChildren[Number(childIndex)].checked = checked;
    newMenu[Number(parentIndex)].children = newChildren;

    setSelected(only ?? selected);
    updateParent([...newMenu], only ?? selected);
  };

  return (
    <FormControl className={classes.container}>
      <RadioGroup name="search-menu" value={selected} onChange={(event, value) => onRadioChange(value)}>
        <ul className={classes.list}>
          {menu.map((parent, parentIndex) => (
            <li key={parent.id} className={classes.listItem}>
              <FormControlLabel
                className={classes.label}
                value={parent.value}
                label={parent.label}
                control={<Radio className={classes.radio}/>}
                  />
              <ul className={classes.list}>
                {parent?.children?.map((child, childIndex) => (
                  <div className={classes.row}>
                    <IconButton
                      size="small"
                      edge={false}
                      className={classes.icon}
                      onClick={() => onCheckboxChange(
                        parentIndex,
                        childIndex,
                        true,
                        menu[Number(parentIndex)]?.id,
                      )}>
                      <Icon>add_circle_outline</Icon>
                    </IconButton>
                    <li key={child.id} className={classes.listItem}>
                      <FormControlLabel
                        className={classes.label}
                        value={child.value}
                        label={child.label}
                        control={
                          <Checkbox
                            checked={(parent.value === selected) && child.checked}
                            disabled={parent.value !== selected}
                            onChange={
                              (event, checked) => onCheckboxChange(parentIndex, childIndex, checked)
                            }
                          />}
                          />
                    </li>
                  </div>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </RadioGroup>
    </FormControl>
  );
};

SearchTypesMenu.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
};

SearchTypesMenu.defaultProps = {
  config: null,
  onChange: null,
};

export default SearchTypesMenu;
