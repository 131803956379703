import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { List as list, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './updates.module.scss';
import updates from '../../../config/changelog.yml';

const Updates = ({
  anchor,
}) => {
  const [data, setData] = useState(list());

  useEffect(() => {
    const el = document.getElementById(anchor);
    setData(fromJS(updates?.updates));
    setTimeout(() => (el ? el.scrollIntoView() : null), 300);
  }, []);

  return (
    <Grid
      fluid
      name="component.updates"
      className={cx([style.base, style.updates])}>
      <Row>
        <Col xs={12} className={style.header}>
          <div className={style.title}>
            <Icon className={style.icon}>update
            </Icon>
            <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>Release Notes</div>
          </div>
        </Col>
        <Col xs={12}>
          {data.isEmpty() && <CircularProgress />}
          {!data.isEmpty() && data.entrySeq().map(([index, update]) => (
            <Paper key={index} className={style.card} id={update.get('date')}>
              <div className={style.body}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>
                  {update.get('date')}
                </div>
                {update.has('title') && <div className={cx([style.h1, 'h1', style.raj, 'raj', style.cap, 'cap'])}>{update.get('title')}</div>}
                <div>{update.get('summary')}</div>
                <div>{update.has('sections') && update
                  .get('sections')
                  .entrySeq()
                  .map(([sid, section]) => (
                    <div key={sid}>
                      <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>{section.get('title')}</div>
                      <ul>{section
                        .get('notes', list())
                        .entrySeq()
                        .map(([k, v]) => (
                          <li key={v}>
                            {(Number.isNaN(parseFloat(k)) || list.isList(v)) &&
                              <div>
                                <div className={cx([style.h4, 'h4', style.mont, 'mont',
                                    style.a, 'a', style.disable])}>
                                  {k}
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: v.first() }} />
                              </div>}
                            {!Number.isNaN(parseFloat(k)) && !list.isList(v) &&
                              <div>
                                <div dangerouslySetInnerHTML={{ __html: v }} />
                              </div>}
                          </li>))}
                      </ul>
                    </div>))}
                </div>
              </div>
            </Paper>))}
        </Col>
      </Row>
    </Grid>
  );
};

Updates.propTypes = {
  anchor: PropTypes.string,
};

Updates.defaultProps = {
  anchor: '',
};

export default Updates;
