import React from 'react';
import Api from '../../../../utils/api';

const dev = '_self' in React.createElement('div') ? '/dev' : '';

const postNewIncident = details => Api.post(`/ui/v4/domain-monitoring${dev}/incidents`, details);

const uploadAttachment = (uuid, formData) => Api.post(`/ui/v4/domain-monitoring${dev}/incidents/${uuid}/attachments`, formData);

export {
  // eslint-disable-next-line import/prefer-default-export
  postNewIncident,
  uploadAttachment,
};
