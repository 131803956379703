import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { List as list } from 'immutable';
import { CircularProgress, Button, Icon } from '@mui/material';

import style from './infinite.module.scss';
import Hooks from '../../../utils/hooks';

export const infiniteBody = ({
  container,
  headers,
  children,
  loading,
  hasPrev,
  hasNext,
  loadPrev,
  loadNext,
}) => (
  <div className={style.infinite}>
    {container && hasPrev &&
      <Button
        fullWidth
        className={cx([style.load, loading && style.loading])}
        icon={loading
          ? (<CircularProgress />)
          : (<Icon className={cx([style.icon, style.action])}>keyboard_arrow_up</Icon>)
        }
        onClick={() => loadPrev()}>
        Load
      </Button>}
    <div className={!hasPrev || container ? style.hidden : style.loading}>
      <CircularProgress />
    </div>
    {!headers.isEmpty() &&
    <div className={style.header}>
      {headers.map(v => (
        <div style={{ ...v.get('style').toJS() }} key={v.get('label')}>{v.get('label')}</div>
          ))}
    </div>
      }
    <div id="content">{children}</div>
    <div className={!hasNext || container ? style.hidden : style.loading}>
      <CircularProgress />
    </div>
    {container && hasNext &&
      <Button
        fullWidth
        className={cx([style.load, loading && style.loading])}
        icon={loading
          ? (<CircularProgress />)
          : (<Icon className={cx([style.icon, style.action])}>keyboard_arrow_down</Icon>)
        }
        onClick={() => loadNext()}>
        Load
      </Button>}
  </div>
);

const Infinite = ({
  active,
  children,
  data,
  container,
  hasNext,
  hasPrev,
  headers,
  loading,
  loadNext,
  loadPrev,
  maxRecords,
  offset,
  requestCount,
  setIndex,
  setLoaded,
  setOffset,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('next');
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const { innerHeight, scrollY } = window;

    const th = 200;
    const el = document.querySelector('#content');
    const ht = el ? el.clientHeight : 0;
    const prv = el && scrollY < th;
    const nxt = el && scrollY > (ht - th - innerHeight);
    const hasLoadedPrev = data.isEmpty() ? false : startIndex > 0;
    const hasLoadedNext = data.isEmpty() ? false : startIndex + maxRecords < data.count();

    if (prv && (hasPrev || hasLoadedPrev)) {
      // setTick(true);
      if (hasLoadedPrev) {
        const newIndex = startIndex - requestCount;
        setStartIndex(newIndex);
        if (setIndex) setIndex(newIndex);
        setLoaded((data || list()).slice(newIndex, newIndex + maxRecords));
        setScrollDirection('prev');
        if (setOffset) setOffset('prev');
      } else {
        loadPrev();
      }
      setScrollDirection('prev');
    } else if (nxt && (hasNext || hasLoadedNext)) {
      // setTick(true);
      if (hasLoadedNext) {
        const newIndex = startIndex + requestCount;
        setStartIndex(newIndex);
        if (setIndex) setIndex(newIndex);
        setLoaded((data || list()).slice(newIndex, newIndex + maxRecords));
        setScrollDirection('next');
        if (setOffset) setOffset('next');
      } else {
        loadNext();
      }
      setScrollDirection('next');
    }
  };

  Hooks.useEventListener('scroll', handleScroll);

  useEffect(() => {
    if (data && !data.isEmpty()) {
      if (data.count() > maxRecords) {
        const newIndex = (scrollDirection === 'next') ? startIndex + requestCount : startIndex;
        setStartIndex(newIndex);
        if (setIndex) setIndex(newIndex);
        setLoaded((data || list()).slice(newIndex, newIndex + maxRecords));
      } else {
        setStartIndex(0);
        if (setIndex) setIndex(0);
        setLoaded(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data.isEmpty()) return;
    if (scrolled) return;
    // Make sure we select browser's idea of the element, so scroll is consistent
    const anchor = active
      ? document.querySelector(`[data-fpid="${active}"]`)
      : document.getElementById(offset);
    if (container) {
      window.removeEventListener('scroll', handleScroll);
    }
    if (anchor && offset) {
      const rec = anchor.getBoundingClientRect();
      const headerHeight = 125 + (!headers || headers.isEmpty() ? 0 : 34);

      // needs to be in a timeout for loading previous asynchronously...
      setTimeout(() => {
        const scrollY = Math.min(window.innerHeight / 2, rec.height)
            + (headerHeight - rec.height);
        anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
        setScrolled(true);
        if (container) {
          container.scrollBy(0, scrollY);
        }
      });
    }
    // if (tick) setTick(false);
  }, [container, offset]);

  return infiniteBody({
    container,
    headers,
    children,
    loading,
    hasPrev,
    hasNext,
    loadPrev,
    loadNext,
  });
};

export const infinitePropTypes = {
  active: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.object,
  container: PropTypes.object,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  headers: PropTypes.object,
  loading: PropTypes.bool,
  loadNext: PropTypes.func,
  loadPrev: PropTypes.func,
  maxRecords: PropTypes.number,
  offset: PropTypes.string,
  requestCount: PropTypes.number,
  setIndex: PropTypes.func,
  setLoaded: PropTypes.func,
  setOffset: PropTypes.func,
};
Infinite.propTypes = infinitePropTypes;

Infinite.defaultProps = {
  children: {},
  container: null,
  data: list(),
  hasNext: null,
  hasPrev: null,
  headers: list(),
  loading: null,
  loadNext: null,
  loadPrev: null,
  maxRecords: 50,
  offset: '',
  requestCount: 25,
  setIndex: null,
  setLoaded: null,
  setOffset: null,
};

export default Infinite;
