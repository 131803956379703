import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Icon,
  Paper,
} from '@mui/material';

import style from './integration.module.scss';
import Text from '../../utils/text';
import Common from '../../utils/common';

const Integration = ({ description, image, docs, types, datasets }) => {
  const GetIcon = (type) => {
    switch (type) {
      case 'setup': return 'settings';
      case 'installation guide': return 'code';
      default: return 'help';
    }
  };

  return (
    <Paper className={style.integration}>
      <div className={style.actions}>
        <div className={style.image}>
          <img src={image} alt="" />
        </div>
        <div>
          {types.map(type => (
            <Button
              disabled
              key={type.label}>
              {type.label}
            </Button>))}
        </div>
      </div>
      <div className={style.text}>
        {description}
      </div>
      <div className={style.actions}>
        <div>
          {datasets.map(set => (
            <Button
              disabled
              key={set.label}>
              {set.label}
            </Button>))}
        </div>
        <div>
          {docs.map(doc => (
            <Icon
              key={doc.label}
              color="secondary"
              name="integration.doc"
              data-for="docs.tooltip"
              data-tip={Text.Sentence(doc.label)}
              className={cx([style.doc, !!doc.disabled && style.hidden])}
              onClick={(e) => {
              if (!doc.disabled) {
                const external = Common.Events.DispatchExternalLinkEvent(doc.value, e);
                const val = doc.value || '';
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                if (!external) window.open(`${val}?${new Date().getTime()}`, '_blank');
              }
            }}>
              {GetIcon(doc.label.toLowerCase())}
            </Icon>))}
        </div>
      </div>
      <ReactTooltip id="docs.tooltip" html place="bottom" effect="solid" />
    </Paper>
  );
};

Integration.propTypes = {
  datasets: PropTypes.array,
  description: PropTypes.string,
  image: PropTypes.string,
  docs: PropTypes.array,
  types: PropTypes.array,
};

Integration.defaultProps = {
  datasets: [],
  description: '',
  image: '',
  docs: [],
  types: [],
};

export default Integration;
