export default {
  Focus: {
    category: 'Focus',
    mouse_leave: 'mouseLeave',
    switched_tabs: 'switchedTabs',
  },
  Mark: {
    navigation: 'navigation:start',
  },
  Performance: {
    category: 'Performance',
    first_interaction: 'firstInteraction',
    load: 'load',
  },
  Search: {
    category: 'Search',
    no_results: 'searchNoResults',
    record_actor: 'recordActor',
    record_site: 'recordSite',
    results: 'searchResults',
    site_actor: 'siteActors',
    sites: 'sites',
    timeout: 'timeout',
  },
};
