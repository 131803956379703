/* eslint-disable */
export default [
  {
    "name": ".bash_profile and .bashrc",
    "id": "T1156",
    "phase": "persistence"
  },
  {
    "name": "Access Token Manipulation",
    "id": "T1134",
    "phase": "defense-evasion"
  },
  {
    "name": "Accessibility Features",
    "id": "T1015",
    "phase": "persistence"
  },
  {
    "name": "Account Access Removal",
    "id": "T1531",
    "phase": "impact"
  },
  {
    "name": "Account Discovery",
    "id": "T1087",
    "phase": "discovery"
  },
  {
    "name": "Account Manipulation",
    "id": "T1098",
    "phase": "credential-access"
  },
  {
    "name": "AppCert DLLs",
    "id": "T1182",
    "phase": "persistence"
  },
  {
    "name": "AppInit DLLs",
    "id": "T1103",
    "phase": "persistence"
  },
  {
    "name": "AppleScript",
    "id": "T1155",
    "phase": "execution"
  },
  {
    "name": "Application Access Token",
    "id": "T1527",
    "phase": "defense-evasion"
  },
  {
    "name": "Application Deployment Software",
    "id": "T1017",
    "phase": "lateral-movement"
  },
  {
    "name": "Application Shimming",
    "id": "T1138",
    "phase": "persistence"
  },
  {
    "name": "Application Window Discovery",
    "id": "T1010",
    "phase": "discovery"
  },
  {
    "name": "Audio Capture",
    "id": "T1123",
    "phase": "collection"
  },
  {
    "name": "Authentication Package",
    "id": "T1131",
    "phase": "persistence"
  },
  {
    "name": "Automated Collection",
    "id": "T1119",
    "phase": "collection"
  },
  {
    "name": "Automated Exfiltration",
    "id": "T1020",
    "phase": "exfiltration"
  },
  {
    "name": "BITS Jobs",
    "id": "T1197",
    "phase": "defense-evasion"
  },
  {
    "name": "Bash History",
    "id": "T1139",
    "phase": "credential-access"
  },
  {
    "name": "Binary Padding",
    "id": "T1009",
    "phase": "defense-evasion"
  },
  {
    "name": "Bootkit",
    "id": "T1067",
    "phase": "persistence"
  },
  {
    "name": "Browser Bookmark Discovery",
    "id": "T1217",
    "phase": "discovery"
  },
  {
    "name": "Browser Extensions",
    "id": "T1176",
    "phase": "persistence"
  },
  {
    "name": "Brute Force",
    "id": "T1110",
    "phase": "credential-access"
  },
  {
    "name": "Bypass User Account Control",
    "id": "T1088",
    "phase": "defense-evasion"
  },
  {
    "name": "CMSTP",
    "id": "T1191",
    "phase": "defense-evasion"
  },
  {
    "name": "Change Default File Association",
    "id": "T1042",
    "phase": "persistence"
  },
  {
    "name": "Clear Command History",
    "id": "T1146",
    "phase": "defense-evasion"
  },
  {
    "name": "Clipboard Data",
    "id": "T1115",
    "phase": "collection"
  },
  {
    "name": "Cloud Instance Metadata API",
    "id": "T1522",
    "phase": "credential-access"
  },
  {
    "name": "Cloud Service Dashboard",
    "id": "T1538",
    "phase": "discovery"
  },
  {
    "name": "Cloud Service Discovery",
    "id": "T1526",
    "phase": "discovery"
  },
  {
    "name": "Code Signing",
    "id": "T1116",
    "phase": "defense-evasion"
  },
  {
    "name": "Command-Line Interface",
    "id": "T1059",
    "phase": "execution"
  },
  {
    "name": "Commonly Used Port",
    "id": "T1043",
    "phase": "command-and-control"
  },
  {
    "name": "Communication Through Removable Media",
    "id": "T1092",
    "phase": "command-and-control"
  },
  {
    "name": "Compile After Delivery",
    "id": "T1500",
    "phase": "defense-evasion"
  },
  {
    "name": "Compiled HTML File",
    "id": "T1223",
    "phase": "defense-evasion"
  },
  {
    "name": "Component Firmware",
    "id": "T1109",
    "phase": "defense-evasion"
  },
  {
    "name": "Component Object Model Hijacking",
    "id": "T1122",
    "phase": "defense-evasion"
  },
  {
    "name": "Component Object Model and Distributed COM",
    "id": "T1175",
    "phase": "lateral-movement"
  },
  {
    "name": "Connection Proxy",
    "id": "T1090",
    "phase": "command-and-control"
  },
  {
    "name": "Control Panel Items",
    "id": "T1196",
    "phase": "defense-evasion"
  },
  {
    "name": "Create Account",
    "id": "T1136",
    "phase": "persistence"
  },
  {
    "name": "Credential Dumping",
    "id": "T1003",
    "phase": "credential-access"
  },
  {
    "name": "Credentials from Web Browsers",
    "id": "T1503",
    "phase": "credential-access"
  },
  {
    "name": "Credentials in Files",
    "id": "T1081",
    "phase": "credential-access"
  },
  {
    "name": "Credentials in Registry",
    "id": "T1214",
    "phase": "credential-access"
  },
  {
    "name": "Custom Command and Control Protocol",
    "id": "T1094",
    "phase": "command-and-control"
  },
  {
    "name": "Custom Cryptographic Protocol",
    "id": "T1024",
    "phase": "command-and-control"
  },
  {
    "name": "DCShadow",
    "id": "T1207",
    "phase": "defense-evasion"
  },
  {
    "name": "DLL Search Order Hijacking",
    "id": "T1038",
    "phase": "persistence"
  },
  {
    "name": "DLL Side-Loading",
    "id": "T1073",
    "phase": "defense-evasion"
  },
  {
    "name": "Data Compressed",
    "id": "T1002",
    "phase": "exfiltration"
  },
  {
    "name": "Data Destruction",
    "id": "T1485",
    "phase": "impact"
  },
  {
    "name": "Data Encoding",
    "id": "T1132",
    "phase": "command-and-control"
  },
  {
    "name": "Data Encrypted",
    "id": "T1022",
    "phase": "exfiltration"
  },
  {
    "name": "Data Encrypted for Impact",
    "id": "T1486",
    "phase": "impact"
  },
  {
    "name": "Data Obfuscation",
    "id": "T1001",
    "phase": "command-and-control"
  },
  {
    "name": "Data Staged",
    "id": "T1074",
    "phase": "collection"
  },
  {
    "name": "Data Transfer Size Limits",
    "id": "T1030",
    "phase": "exfiltration"
  },
  {
    "name": "Data from Cloud Storage Object",
    "id": "T1530",
    "phase": "collection"
  },
  {
    "name": "Data from Information Repositories",
    "id": "T1213",
    "phase": "collection"
  },
  {
    "name": "Data from Local System",
    "id": "T1005",
    "phase": "collection"
  },
  {
    "name": "Data from Network Shared Drive",
    "id": "T1039",
    "phase": "collection"
  },
  {
    "name": "Data from Removable Media",
    "id": "T1025",
    "phase": "collection"
  },
  {
    "name": "Defacement",
    "id": "T1491",
    "phase": "impact"
  },
  {
    "name": "Deobfuscate/Decode Files or Information",
    "id": "T1140",
    "phase": "defense-evasion"
  },
  {
    "name": "Disabling Security Tools",
    "id": "T1089",
    "phase": "defense-evasion"
  },
  {
    "name": "Disk Content Wipe",
    "id": "T1488",
    "phase": "impact"
  },
  {
    "name": "Disk Structure Wipe",
    "id": "T1487",
    "phase": "impact"
  },
  {
    "name": "Domain Fronting",
    "id": "T1172",
    "phase": "command-and-control"
  },
  {
    "name": "Domain Generation Algorithms",
    "id": "T1483",
    "phase": "command-and-control"
  },
  {
    "name": "Domain Trust Discovery",
    "id": "T1482",
    "phase": "discovery"
  },
  {
    "name": "Drive-by Compromise",
    "id": "T1189",
    "phase": "initial-access"
  },
  {
    "name": "Dylib Hijacking",
    "id": "T1157",
    "phase": "persistence"
  },
  {
    "name": "Dynamic Data Exchange",
    "id": "T1173",
    "phase": "execution"
  },
  {
    "name": "Elevated Execution with Prompt",
    "id": "T1514",
    "phase": "privilege-escalation"
  },
  {
    "name": "Email Collection",
    "id": "T1114",
    "phase": "collection"
  },
  {
    "name": "Emond",
    "id": "T1519",
    "phase": "persistence"
  },
  {
    "name": "Endpoint Denial of Service",
    "id": "T1499",
    "phase": "impact"
  },
  {
    "name": "Execution Guardrails",
    "id": "T1480",
    "phase": "defense-evasion"
  },
  {
    "name": "Execution through API",
    "id": "T1106",
    "phase": "execution"
  },
  {
    "name": "Execution through Module Load",
    "id": "T1129",
    "phase": "execution"
  },
  {
    "name": "Exfiltration Over Alternative Protocol",
    "id": "T1048",
    "phase": "exfiltration"
  },
  {
    "name": "Exfiltration Over Command and Control Channel",
    "id": "T1041",
    "phase": "exfiltration"
  },
  {
    "name": "Exfiltration Over Other Network Medium",
    "id": "T1011",
    "phase": "exfiltration"
  },
  {
    "name": "Exfiltration Over Physical Medium",
    "id": "T1052",
    "phase": "exfiltration"
  },
  {
    "name": "Exploit Public-Facing Application",
    "id": "T1190",
    "phase": "initial-access"
  },
  {
    "name": "Exploitation for Client Execution",
    "id": "T1203",
    "phase": "execution"
  },
  {
    "name": "Exploitation for Credential Access",
    "id": "T1212",
    "phase": "credential-access"
  },
  {
    "name": "Exploitation for Defense Evasion",
    "id": "T1211",
    "phase": "defense-evasion"
  },
  {
    "name": "Exploitation for Privilege Escalation",
    "id": "T1068",
    "phase": "privilege-escalation"
  },
  {
    "name": "Exploitation of Remote Services",
    "id": "T1210",
    "phase": "lateral-movement"
  },
  {
    "name": "External Remote Services",
    "id": "T1133",
    "phase": "persistence"
  },
  {
    "name": "Extra Window Memory Injection",
    "id": "T1181",
    "phase": "defense-evasion"
  },
  {
    "name": "Fallback Channels",
    "id": "T1008",
    "phase": "command-and-control"
  },
  {
    "name": "File Deletion",
    "id": "T1107",
    "phase": "defense-evasion"
  },
  {
    "name": "File System Logical Offsets",
    "id": "T1006",
    "phase": "defense-evasion"
  },
  {
    "name": "File System Permissions Weakness",
    "id": "T1044",
    "phase": "persistence"
  },
  {
    "name": "File and Directory Discovery",
    "id": "T1083",
    "phase": "discovery"
  },
  {
    "name": "File and Directory Permissions Modification",
    "id": "T1222",
    "phase": "defense-evasion"
  },
  {
    "name": "Firmware Corruption",
    "id": "T1495",
    "phase": "impact"
  },
  {
    "name": "Forced Authentication",
    "id": "T1187",
    "phase": "credential-access"
  },
  {
    "name": "Gatekeeper Bypass",
    "id": "T1144",
    "phase": "defense-evasion"
  },
  {
    "name": "Graphical User Interface",
    "id": "T1061",
    "phase": "execution"
  },
  {
    "name": "Group Policy Modification",
    "id": "T1484",
    "phase": "defense-evasion"
  },
  {
    "name": "HISTCONTROL",
    "id": "T1148",
    "phase": "defense-evasion"
  },
  {
    "name": "Hardware Additions",
    "id": "T1200",
    "phase": "initial-access"
  },
  {
    "name": "Hidden Files and Directories",
    "id": "T1158",
    "phase": "defense-evasion"
  },
  {
    "name": "Hidden Users",
    "id": "T1147",
    "phase": "defense-evasion"
  },
  {
    "name": "Hidden Window",
    "id": "T1143",
    "phase": "defense-evasion"
  },
  {
    "name": "Hooking",
    "id": "T1179",
    "phase": "persistence"
  },
  {
    "name": "Hypervisor",
    "id": "T1062",
    "phase": "persistence"
  },
  {
    "name": "Image File Execution Options Injection",
    "id": "T1183",
    "phase": "privilege-escalation"
  },
  {
    "name": "Implant Container Image",
    "id": "T1525",
    "phase": "persistence"
  },
  {
    "name": "Indicator Blocking",
    "id": "T1054",
    "phase": "defense-evasion"
  },
  {
    "name": "Indicator Removal from Tools",
    "id": "T1066",
    "phase": "defense-evasion"
  },
  {
    "name": "Indicator Removal on Host",
    "id": "T1070",
    "phase": "defense-evasion"
  },
  {
    "name": "Indirect Command Execution",
    "id": "T1202",
    "phase": "defense-evasion"
  },
  {
    "name": "Inhibit System Recovery",
    "id": "T1490",
    "phase": "impact"
  },
  {
    "name": "Input Capture",
    "id": "T1056",
    "phase": "collection"
  },
  {
    "name": "Input Prompt",
    "id": "T1141",
    "phase": "credential-access"
  },
  {
    "name": "Install Root Certificate",
    "id": "T1130",
    "phase": "defense-evasion"
  },
  {
    "name": "InstallUtil",
    "id": "T1118",
    "phase": "defense-evasion"
  },
  {
    "name": "Internal Spearphishing",
    "id": "T1534",
    "phase": "lateral-movement"
  },
  {
    "name": "Kerberoasting",
    "id": "T1208",
    "phase": "credential-access"
  },
  {
    "name": "Kernel Modules and Extensions",
    "id": "T1215",
    "phase": "persistence"
  },
  {
    "name": "Keychain",
    "id": "T1142",
    "phase": "credential-access"
  },
  {
    "name": "LC_LOAD_DYLIB Addition",
    "id": "T1161",
    "phase": "persistence"
  },
  {
    "name": "LC_MAIN Hijacking",
    "id": "T1149",
    "phase": "defense-evasion"
  },
  {
    "name": "LLMNR/NBT-NS Poisoning and Relay",
    "id": "T1171",
    "phase": "credential-access"
  },
  {
    "name": "LSASS Driver",
    "id": "T1177",
    "phase": "execution"
  },
  {
    "name": "Launch Agent",
    "id": "T1159",
    "phase": "persistence"
  },
  {
    "name": "Launch Daemon",
    "id": "T1160",
    "phase": "persistence"
  },
  {
    "name": "Launchctl",
    "id": "T1152",
    "phase": "defense-evasion"
  },
  {
    "name": "Local Job Scheduling",
    "id": "T1168",
    "phase": "persistence"
  },
  {
    "name": "Login Item",
    "id": "T1162",
    "phase": "persistence"
  },
  {
    "name": "Logon Scripts",
    "id": "T1037",
    "phase": "lateral-movement"
  },
  {
    "name": "Man in the Browser",
    "id": "T1185",
    "phase": "collection"
  },
  {
    "name": "Masquerading",
    "id": "T1036",
    "phase": "defense-evasion"
  },
  {
    "name": "Modify Existing Service",
    "id": "T1031",
    "phase": "persistence"
  },
  {
    "name": "Modify Registry",
    "id": "T1112",
    "phase": "defense-evasion"
  },
  {
    "name": "Mshta",
    "id": "T1170",
    "phase": "defense-evasion"
  },
  {
    "name": "Multi-Stage Channels",
    "id": "T1104",
    "phase": "command-and-control"
  },
  {
    "name": "Multi-hop Proxy",
    "id": "T1188",
    "phase": "command-and-control"
  },
  {
    "name": "Multiband Communication",
    "id": "T1026",
    "phase": "command-and-control"
  },
  {
    "name": "Multilayer Encryption",
    "id": "T1079",
    "phase": "command-and-control"
  },
  {
    "name": "NTFS File Attributes",
    "id": "T1096",
    "phase": "defense-evasion"
  },
  {
    "name": "Netsh Helper DLL",
    "id": "T1128",
    "phase": "persistence"
  },
  {
    "name": "Network Denial of Service",
    "id": "T1498",
    "phase": "impact"
  },
  {
    "name": "Network Service Scanning",
    "id": "T1046",
    "phase": "discovery"
  },
  {
    "name": "Network Share Connection Removal",
    "id": "T1126",
    "phase": "defense-evasion"
  },
  {
    "name": "Network Share Discovery",
    "id": "T1135",
    "phase": "discovery"
  },
  {
    "name": "Network Sniffing",
    "id": "T1040",
    "phase": "credential-access"
  },
  {
    "name": "New Service",
    "id": "T1050",
    "phase": "persistence"
  },
  {
    "name": "Obfuscated Files or Information",
    "id": "T1027",
    "phase": "defense-evasion"
  },
  {
    "name": "Office Application Startup",
    "id": "T1137",
    "phase": "persistence"
  },
  {
    "name": "Parent PID Spoofing",
    "id": "T1502",
    "phase": "defense-evasion"
  },
  {
    "name": "Pass the Hash",
    "id": "T1075",
    "phase": "lateral-movement"
  },
  {
    "name": "Pass the Ticket",
    "id": "T1097",
    "phase": "lateral-movement"
  },
  {
    "name": "Password Filter DLL",
    "id": "T1174",
    "phase": "credential-access"
  },
  {
    "name": "Password Policy Discovery",
    "id": "T1201",
    "phase": "discovery"
  },
  {
    "name": "Path Interception",
    "id": "T1034",
    "phase": "persistence"
  },
  {
    "name": "Peripheral Device Discovery",
    "id": "T1120",
    "phase": "discovery"
  },
  {
    "name": "Permission Groups Discovery",
    "id": "T1069",
    "phase": "discovery"
  },
  {
    "name": "Plist Modification",
    "id": "T1150",
    "phase": "defense-evasion"
  },
  {
    "name": "Port Knocking",
    "id": "T1205",
    "phase": "defense-evasion"
  },
  {
    "name": "Port Monitors",
    "id": "T1013",
    "phase": "persistence"
  },
  {
    "name": "PowerShell",
    "id": "T1086",
    "phase": "execution"
  },
  {
    "name": "PowerShell Profile",
    "id": "T1504",
    "phase": "persistence"
  },
  {
    "name": "Private Keys",
    "id": "T1145",
    "phase": "credential-access"
  },
  {
    "name": "Process Discovery",
    "id": "T1057",
    "phase": "discovery"
  },
  {
    "name": "Process Doppelgänging",
    "id": "T1186",
    "phase": "defense-evasion"
  },
  {
    "name": "Process Hollowing",
    "id": "T1093",
    "phase": "defense-evasion"
  },
  {
    "name": "Process Injection",
    "id": "T1055",
    "phase": "defense-evasion"
  },
  {
    "name": "Query Registry",
    "id": "T1012",
    "phase": "discovery"
  },
  {
    "name": "Rc.common",
    "id": "T1163",
    "phase": "persistence"
  },
  {
    "name": "Re-opened Applications",
    "id": "T1164",
    "phase": "persistence"
  },
  {
    "name": "Redundant Access",
    "id": "T1108",
    "phase": "defense-evasion"
  },
  {
    "name": "Registry Run Keys / Startup Folder",
    "id": "T1060",
    "phase": "persistence"
  },
  {
    "name": "Regsvcs/Regasm",
    "id": "T1121",
    "phase": "defense-evasion"
  },
  {
    "name": "Regsvr32",
    "id": "T1117",
    "phase": "defense-evasion"
  },
  {
    "name": "Remote Access Tools",
    "id": "T1219",
    "phase": "command-and-control"
  },
  {
    "name": "Remote Desktop Protocol",
    "id": "T1076",
    "phase": "lateral-movement"
  },
  {
    "name": "Remote File Copy",
    "id": "T1105",
    "phase": "command-and-control"
  },
  {
    "name": "Remote Services",
    "id": "T1021",
    "phase": "lateral-movement"
  },
  {
    "name": "Remote System Discovery",
    "id": "T1018",
    "phase": "discovery"
  },
  {
    "name": "Replication Through Removable Media",
    "id": "T1091",
    "phase": "lateral-movement"
  },
  {
    "name": "Resource Hijacking",
    "id": "T1496",
    "phase": "impact"
  },
  {
    "name": "Revert Cloud Instance",
    "id": "T1536",
    "phase": "defense-evasion"
  },
  {
    "name": "Rootkit",
    "id": "T1014",
    "phase": "defense-evasion"
  },
  {
    "name": "Rundll32",
    "id": "T1085",
    "phase": "defense-evasion"
  },
  {
    "name": "Runtime Data Manipulation",
    "id": "T1494",
    "phase": "impact"
  },
  {
    "name": "SID-History Injection",
    "id": "T1178",
    "phase": "privilege-escalation"
  },
  {
    "name": "SIP and Trust Provider Hijacking",
    "id": "T1198",
    "phase": "defense-evasion"
  },
  {
    "name": "SSH Hijacking",
    "id": "T1184",
    "phase": "lateral-movement"
  },
  {
    "name": "Scheduled Task",
    "id": "T1053",
    "phase": "execution"
  },
  {
    "name": "Scheduled Transfer",
    "id": "T1029",
    "phase": "exfiltration"
  },
  {
    "name": "Screen Capture",
    "id": "T1113",
    "phase": "collection"
  },
  {
    "name": "Screensaver",
    "id": "T1180",
    "phase": "persistence"
  },
  {
    "name": "Scripting",
    "id": "T1064",
    "phase": "defense-evasion"
  },
  {
    "name": "Security Software Discovery",
    "id": "T1063",
    "phase": "discovery"
  },
  {
    "name": "Security Support Provider",
    "id": "T1101",
    "phase": "persistence"
  },
  {
    "name": "Securityd Memory",
    "id": "T1167",
    "phase": "credential-access"
  },
  {
    "name": "Server Software Component",
    "id": "T1505",
    "phase": "persistence"
  },
  {
    "name": "Service Execution",
    "id": "T1035",
    "phase": "execution"
  },
  {
    "name": "Service Registry Permissions Weakness",
    "id": "T1058",
    "phase": "persistence"
  },
  {
    "name": "Service Stop",
    "id": "T1489",
    "phase": "impact"
  },
  {
    "name": "Setuid and Setgid",
    "id": "T1166",
    "phase": "privilege-escalation"
  },
  {
    "name": "Shared Webroot",
    "id": "T1051",
    "phase": "lateral-movement"
  },
  {
    "name": "Shortcut Modification",
    "id": "T1023",
    "phase": "persistence"
  },
  {
    "name": "Signed Binary Proxy Execution",
    "id": "T1218",
    "phase": "defense-evasion"
  },
  {
    "name": "Signed Script Proxy Execution",
    "id": "T1216",
    "phase": "defense-evasion"
  },
  {
    "name": "Software Discovery",
    "id": "T1518",
    "phase": "discovery"
  },
  {
    "name": "Software Packing",
    "id": "T1045",
    "phase": "defense-evasion"
  },
  {
    "name": "Source",
    "id": "T1153",
    "phase": "execution"
  },
  {
    "name": "Space after Filename",
    "id": "T1151",
    "phase": "defense-evasion"
  },
  {
    "name": "Spearphishing Attachment",
    "id": "T1193",
    "phase": "initial-access"
  },
  {
    "name": "Spearphishing Link",
    "id": "T1192",
    "phase": "initial-access"
  },
  {
    "name": "Spearphishing via Service",
    "id": "T1194",
    "phase": "initial-access"
  },
  {
    "name": "Standard Application Layer Protocol",
    "id": "T1071",
    "phase": "command-and-control"
  },
  {
    "name": "Standard Cryptographic Protocol",
    "id": "T1032",
    "phase": "command-and-control"
  },
  {
    "name": "Standard Non-Application Layer Protocol",
    "id": "T1095",
    "phase": "command-and-control"
  },
  {
    "name": "Startup Items",
    "id": "T1165",
    "phase": "persistence"
  },
  {
    "name": "Steal Application Access Token",
    "id": "T1528",
    "phase": "credential-access"
  },
  {
    "name": "Steal Web Session Cookie",
    "id": "T1539",
    "phase": "credential-access"
  },
  {
    "name": "Stored Data Manipulation",
    "id": "T1492",
    "phase": "impact"
  },
  {
    "name": "Sudo",
    "id": "T1169",
    "phase": "privilege-escalation"
  },
  {
    "name": "Sudo Caching",
    "id": "T1206",
    "phase": "privilege-escalation"
  },
  {
    "name": "Supply Chain Compromise",
    "id": "T1195",
    "phase": "initial-access"
  },
  {
    "name": "System Firmware",
    "id": "T1019",
    "phase": "persistence"
  },
  {
    "name": "System Information Discovery",
    "id": "T1082",
    "phase": "discovery"
  },
  {
    "name": "System Network Configuration Discovery",
    "id": "T1016",
    "phase": "discovery"
  },
  {
    "name": "System Network Connections Discovery",
    "id": "T1049",
    "phase": "discovery"
  },
  {
    "name": "System Owner/User Discovery",
    "id": "T1033",
    "phase": "discovery"
  },
  {
    "name": "System Service Discovery",
    "id": "T1007",
    "phase": "discovery"
  },
  {
    "name": "System Shutdown/Reboot",
    "id": "T1529",
    "phase": "impact"
  },
  {
    "name": "System Time Discovery",
    "id": "T1124",
    "phase": "discovery"
  },
  {
    "name": "Systemd Service",
    "id": "T1501",
    "phase": "persistence"
  },
  {
    "name": "Taint Shared Content",
    "id": "T1080",
    "phase": "lateral-movement"
  },
  {
    "name": "Template Injection",
    "id": "T1221",
    "phase": "defense-evasion"
  },
  {
    "name": "Third-party Software",
    "id": "T1072",
    "phase": "execution"
  },
  {
    "name": "Time Providers",
    "id": "T1209",
    "phase": "persistence"
  },
  {
    "name": "Timestomp",
    "id": "T1099",
    "phase": "defense-evasion"
  },
  {
    "name": "Transfer Data to Cloud Account",
    "id": "T1537",
    "phase": "exfiltration"
  },
  {
    "name": "Transmitted Data Manipulation",
    "id": "T1493",
    "phase": "impact"
  },
  {
    "name": "Trap",
    "id": "T1154",
    "phase": "execution"
  },
  {
    "name": "Trusted Developer Utilities",
    "id": "T1127",
    "phase": "defense-evasion"
  },
  {
    "name": "Trusted Relationship",
    "id": "T1199",
    "phase": "initial-access"
  },
  {
    "name": "Two-Factor Authentication Interception",
    "id": "T1111",
    "phase": "credential-access"
  },
  {
    "name": "Uncommonly Used Port",
    "id": "T1065",
    "phase": "command-and-control"
  },
  {
    "name": "Unused/Unsupported Cloud Regions",
    "id": "T1535",
    "phase": "defense-evasion"
  },
  {
    "name": "User Execution",
    "id": "T1204",
    "phase": "execution"
  },
  {
    "name": "Valid Accounts",
    "id": "T1078",
    "phase": "defense-evasion"
  },
  {
    "name": "Video Capture",
    "id": "T1125",
    "phase": "collection"
  },
  {
    "name": "Virtualization/Sandbox Evasion",
    "id": "T1497",
    "phase": "defense-evasion"
  },
  {
    "name": "Web Service",
    "id": "T1102",
    "phase": "command-and-control"
  },
  {
    "name": "Web Session Cookie",
    "id": "T1506",
    "phase": "defense-evasion"
  },
  {
    "name": "Web Shell",
    "id": "T1100",
    "phase": "persistence"
  },
  {
    "name": "Windows Admin Shares",
    "id": "T1077",
    "phase": "lateral-movement"
  },
  {
    "name": "Windows Management Instrumentation",
    "id": "T1047",
    "phase": "execution"
  },
  {
    "name": "Windows Management Instrumentation Event Subscription",
    "id": "T1084",
    "phase": "persistence"
  },
  {
    "name": "Windows Remote Management",
    "id": "T1028",
    "phase": "execution"
  },
  {
    "name": "Winlogon Helper DLL",
    "id": "T1004",
    "phase": "persistence"
  },
  {
    "name": "XSL Script Processing",
    "id": "T1220",
    "phase": "defense-evasion"
  },
];