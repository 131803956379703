/* eslint-disable */
export default {
  dev_key: 'AIzaSyCFQD1Hx2Pfwem1auH755D9vfXdX1nu3iU',
  prod_key: 'AIzaSyCliliHAgnEggWwfqxCGdXBoMpAl0svbvo',
  style: [
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#5c6ae0"
            },
            {
                "saturation": 7
            },
            {
                "lightness": 19
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#fff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#fff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": 31
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": 31
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": -2
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": -90
            },
            {
                "lightness": -8
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": 10
            },
            {
                "lightness": 69
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": -78
            },
            {
                "lightness": 67
            },
            {
                "visibility": "simplified"
            }
        ]
    },
  ],
};

export const chartData = (data, cb) => {
  let dataArray;
  let options;
  const region = data.has('geolocation')
    ? data.getIn(['ip_enrichment', 'maxmind', 'data', 'city', 'country_iso_code']) // qualified data
    : data.getIn(['ip_address', 'country_code']); // v4 data
  const resolution = region === 'US' ? 'provinces' : 'countries';

  if (data.isEmpty()) {
    dataArray = [['Latitude', 'Longitude']];
    options = { colorAxis: { colors: ['#fdbc85', '#5c6ae0'] }, defaultColor: '#5c6ae0' };
  } else {
    dataArray = [['Latitude', 'Longitude']];
    dataArray = dataArray
      .concat([data.has('geolocation')
        ? [data.getIn(['geolocation', 'lat']), data.getIn(['geolocation', 'lon'])] // qualified data
        : [data.getIn(['ip_address', 'latitude']), data.getIn(['ip_address', 'longitude'])]]); // v4 data

    options = {
      region,
      displayMode: 'auto',
      colorAxis: { colors: ['#fdbc85', '#5c6ae0'] },
      resolution,
      defaultColor: '#5c6ae0',
    };
  }

  const chartEvents = [
    {
      eventName: 'select',
      callback: () => cb(),
    },
  ];

  return [ dataArray, options, chartEvents ];
}
