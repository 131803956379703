import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
} from '@mui/material';

import style from './mediaGrid.module.scss';
import { Slide } from '../search/Table/Slide/Slide';
import MediaGridImage from './MediaGridImage';
import MediaGridImageFluid from './MediaGridImageFluid';
import MediaGridVideo from './MediaGridVideo';
import Iso from '../../constants/Iso';
import InternalLink from '../utils/InternalLink';
import Text from '../../utils/text';
import History from '../../utils/history';
import Common from '../../utils/common';
import { MediaTypes } from '../../constants/Media';

const MediaGrid = ({
  media,
  type,
}) => {
  const [images, setImages] = useState([]);
  const [carousel, setCarousel] = useState(-1);

  const lowLimit = media.count() <= 25;
  const imageRowHeight = (lowLimit) ? 250 : 200;

  const overlay = (post) => {
    let fileName = post.getIn(['media', 'filename']) || post.getIn(['media', 'file_name']) || (post.getIn(['media', 'storage_uri']) || '').split('/').slice(-1).join();
    if (fileName && fileName.indexOf('.') === -1) fileName += MediaTypes[post.getIn(['media', 'mime_type'])];

    const details = `<b>Date</b>: <span>
    ${post.getIn(['sort_date']) ? `
      <span>
        ${moment.utc(post.getIn(['sort_date'])).format('MMM D, YYYY')}
        <small>${moment.utc(post.getIn(['sort_date'])).format('HH:mm')}</small>
      </span>` : '-'}
    </span><br/>
    ${post.hasIn(['container', 'container']) ? `<b>Server</b>: ${post.getIn(['container', 'container', 'name'])}<br/>` : ''}
    <b>Channel</b>: ${post.getIn(['container', 'name'])}
    ${post.hasIn(['container', 'type']) ? `<br/><b>Channel Type</b>: ${post.getIn(['container', 'type'])}` : ''}<br/>
    <b>Username</b>: ${post.getIn(['site_actor', 'names', 'handle'])}<br/>
    ${post.getIn(['body', 'enrichments', 'language']) ? `<b>Language</b>: ${Iso[post.getIn(['body', 'enrichments', 'language']) || 'n/a'].name}<br/>` : ''}
    <b>Filename</b>: ${fileName}`;
    return details;
  };

  const contextMenuOptions = ({ post, route }) => {
    let fileName = post.getIn(['media', 'filename']) || post.getIn(['media', 'file_name']) || (post.getIn(['media', 'storage_uri']) || '').split('/').slice(-1).join();
    if (fileName && fileName.indexOf('.') === -1) fileName += MediaTypes[post.getIn(['media', 'mime_type'])];
    return {
      items: [
        {
          action: ['copy', 'filter'],
          filterKey: 'server',
          field: ['container.container.name'],
          fieldValue: post.hasIn(['container', 'container']) ? `${post.getIn(['container', 'container', 'fpid'])}::${post.getIn(['container', 'container', 'name'])}` : null,
          fieldName: 'Server',
        },
        {
          action: ['copy'],
          field: ['container.container.native_id'],
          fieldName: 'Server ID',
        },
        {
          action: ['copy', 'filter'],
          filterKey: 'channel',
          field: ['container.name'],
          fieldValue: `${post.getIn(['container', 'fpid'])}::${post.getIn(['container', 'name'])}`,
          fieldName: 'Channel',
        },
        {
          action: ['copy'],
          field: ['container.native_id'],
          fieldName: 'Channel ID',
        },
        {
          action: ['copy', 'filter'],
          filterKey: 'author',
          field: ['site_actor.names.handle'],
          fieldValue: `${post.getIn(['site_actor', 'fpid'])}::${post.getIn(['site_actor', 'names', 'handle'])}`,
          fieldName: 'Username',
        },
        {
          action: ['copy'],
          field: ['site_actor.names.aliases'],
          fieldName: 'Username Aliases',
        },
        {
          action: ['copy', 'filter'],
          filterKey: 'filename',
          field: ['media.filename', 'media.file_name'],
          fieldValue: fileName,
          fieldName: 'Filename',
        },
        {
          action: ['copy', 'filter'],
          filterKey: 'sha1',
          field: ['media.sha1'],
          fieldName: 'SHA1',
        },
        {
          action: ['context'],
          top: true,
          onClick: () => History.navigateTo(route),
        },
        {
          action: ['report'],
          top: true,
          onClick: (v) => {
            const subject = `Media Report - ${v.getIn(['media', 'fpid'])}`;
            const body = `I would like to report the media file contained in the post "${v.get('fpid')}" with the id "${v.getIn(['media', 'fpid'])}".`;
            window.location = `mailto:feedback@flashpoint-intel.com?subject=${subject}&body=${body}`;
          },
        },
      ],
    };
  };

  const imageRenderer = (data) => {
    const image = media.get(data.index);
    return (
      <div
        key={image.post.get('fpid')}
        onClick={() => setCarousel(data.index)}>
        <MediaGridImage
          photo={data.photo}
          post={image.post}
          overlay={overlay(image.post)}
          contextMenuOptions={contextMenuOptions(image)} />
      </div>
    );
  };

  const videoRenderer = (data) => {
    const video = media.get(data.index);
    return (
      <div
        key={video.post.get('fpid')}
        // onClick={() => History.navigateTo(video?.route)}
        onClick={() => setCarousel(data.index)}>
        <MediaGridVideo
          video={data.photo}
          post={video.post}
          overlay={overlay(video.post)}
          contextMenuOptions={contextMenuOptions(video)} />
      </div>);
  };

  const getRenderer = () => {
    switch (type) {
      case 'video':
        return videoRenderer;
      default:
      case 'image':
        return imageRenderer;
    }
  };

  const getDetails = (v) => {
    let width = 3;
    let height = 2;
    switch (type) {
      case 'video':
        width = 4;
        height = 3;
        break;
      default:
      case 'image':
        width = 3;
        height = 2;
        break;
    }
    return !v
      ? {}
      : {
        src: window.location.origin + encodeURI(`/ui/v4/media/assets/?asset_id=${v.media?.get('storage_uri')}`),
        width,
        height,
      };
  };

  const CarouselFooter = (args) => {
    const slide = images?.[args?.currentIndex];
    const context = Common.Generic.Route(fromJS(slide?.post));

    return args.isModal &&
    <>
      <Slide data={slide} type={slide?.media_type} />
      <div {...args.innerProps} className="caption">
        {args.currentIndex > 0 &&
        <Button
          color="secondary"
          onClick={() => setCarousel(index => index - 1)}>Previous
        </Button>}
        {args.currentIndex < images?.length &&
        <Button
          color="secondary"
          onClick={() => setCarousel(index => index + 1)}>Next
        </Button>}
        <InternalLink
          to={context}
          className={cx([style.a, 'a'])}>
          <Button
            color="secondary"
            onClick={() => setCarousel(-1)}>See Context
          </Button>
        </InternalLink>
        <InternalLink
          to={{
            pathname: `/home/assets/media/items/${slide?.media_fpid}`,
            query: {
              media_type: slide?.media_type,
              type: slide?.basetype,
              fpid: Text.StripHighlight(slide?.media_fpid),
              sha1: Text.StripHighlight(slide?.media_sha1),
            },
          }}
          className={cx([style.a, 'a'])}>
          <Button
            color="secondary"
            onClick={() => setCarousel(-1)}>See Media Details
          </Button>
        </InternalLink>
      </div>
    </>;
  };

  useEffect(() => {
    if (!media) return;
    const sources = media
      ?.map(v => ({
        media: v?.media?.toJS(),
        post: v?.post?.toJS(),
        route: v?.route,
      }))
      ?.toJS()
      ?.map(v => ({
        ...v,
        fpid: v?.post?.fpid,
        site: v?.post?.site,
        container: v?.container,
        media_fpid: v?.media?.fpid,
        media_sha1: v?.media?.sha1,
        media_type: v?.media?.media_type,
        basetype: Common.Basetypes.ExtractMainBasetype(fromJS({ basetypes: v?.post?.basetypes })),
        basetypes: v?.post?.basetypes,
        author: v?.post?.site_actor?.names?.handle,
        room: v?.post?.container?.title || v?.post?.container?.container?.title,
        sort_date: v?.post?.sort_date,
        body: v?.post?.body?.['text/plain'],
        source: encodeURI(`/ui/v4/media/assets/?asset_id=${v?.media?.storage_uri}`),
        analysis: v?.media?.[`${v?.media?.media_type}_enrichment`]?.enrichments?.v1[`${v?.media?.media_type}-analysis`],
      }));
    setImages(sources);
  }, [media]);

  return (
    <Grid fluid className={cx([style['media-grid'], style[`${type}-grid`]])}>
      <Row>
        <Col xs={12}>
          {type !== 'fluid' &&
          <Gallery
            targetRowHeight={(type === 'video') ? 350 : imageRowHeight}
            renderImage={getRenderer()}
            photos={media.map(v => (getDetails(v))).toJS()}
          />}
          {type === 'fluid' &&
          <section className={cx([style.fluid, !lowLimit && style.small])}>
            {media.map((v, k) => (
              <div
                key={v.post.get('fpid')}
                onClick={() => setCarousel(k)}>
                <MediaGridImageFluid
                  post={v.post}
                  lowLimit={lowLimit}
                  overlay={overlay(v.post)}
                  contextMenuOptions={contextMenuOptions(v)}
                  photo={{ src: encodeURI(`/ui/v4/media/assets/?asset_id=${v.media.get('storage_uri')}`) }} />
              </div>))}
          </section>}
        </Col>
      </Row>
      <ModalGateway>
        {Boolean(images?.length && carousel > -1) &&
        <Modal onClose={() => setCarousel(-1)}>
          <Carousel
            styles={{
              view: base => ({
                ...base,
                '& img': { opacity: images?.[Number(carousel)]?.media_type === 'video' ? 0 : 1 },
              }),
            }}
            views={images}
            currentIndex={carousel}
            hideControlsWhenIdle={false}
            components={{
              FooterCaption: CarouselFooter,
            }} />
        </Modal>}
      </ModalGateway>
    </Grid>
  );
};

MediaGrid.propTypes = {
  media: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default MediaGrid;
