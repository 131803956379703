import * as React from 'react';

import cx from 'classnames';
import {
  Autocomplete,
  Button,
  FormControl,
  TextField,
} from '@mui/material';

import Query from './query';
import style from './tag.module.scss';
import type {
  Label,
  Post,
} from './types';

interface TagProps {
  post: Post,
  type: string,
  onCancel: () => void,
}

export const Tag = ({
  post,
  type,
  onCancel,
}: TagProps): JSX.Element => {
    const [active, setActive] = React.useState<boolean>();
    const [labels, setLabels] = React.useState<Label[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<Label[]>([]);

    const onApply = async (): Promise<void> => Query.Tag
      .setLabels({ type, post, labels: selected?.map(v => v?.name) })
      .then(() => onCancel());

    const onLoad = async (): Promise<void> => {
      const all: Label[] = await Query.Tag.getLabels({ fpid: post?.fpid, type });
      const set: Label[] = all.filter(v => v?.is_set);
      setLabels(all);
      setSelected(set);
      setLoading(false);
    };

    const onSelect = (event: React.SyntheticEvent, value: Label[]): void => {
      setSelected(value);
    };

    React.useEffect(() => {
      onLoad();
      setLoading(true);
    }, []);

    return (
      <FormControl className={style.tag}>
        <Autocomplete
          multiple
          openOnFocus
          filterSelectedOptions
          value={selected}
          options={labels}
          loading={loading}
          disabled={loading}
          getOptionLabel={option => option?.name}
          onBlur={() => setActive(false)}
          onFocus={() => setActive(true)}
          onChange={onSelect}
          className={cx(['multiselect', active && 'active'])}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Single or comma separated values. Press enter to add" />)} />
        <div className={style.footer}>
          <div className={style.actions}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onApply} className={style.active}>Apply</Button>
          </div>
        </div>
      </FormControl>
    );
};

