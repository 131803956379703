import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import qs from 'qs';
import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import {
  Bookmark,
  BookmarkBorder,
} from '@mui/icons-material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Slide,
  Tab,
} from '@mui/material';

import style from './searchOverlay.module.scss';
import SearchTypeSelection from './SearchTypeSelection';
import Common from '../../utils/common';
import History from '../../utils/history';
import Text from '../../utils/text';
import Filters from '../filters/Filters/Filters';
import DateFilter from '../filters/DateFilter';
import BasicPagination from '../utils/BasicPagination';
import Accordion from '../utils/Accordion/Accordion';
import Editor from '../manage/Editor';
import UserActions from '../../actions/userActions';
import { Track } from '../../utils/track';

const useStyles = makeStyles({
  'search-overlay': {
    '& .MuiTabPanel-root': {
      padding: 0,
    },
    '& .MuiInput-root': {
      marginTop: '5px',
      padding: '15px',
      backgroundColor: '#fff',
      borderRadius: '3px',
    },
    '& .MuiDialog-paperScrollPaper': {
      backgroundColor: grey[300],
    },
  },
});

const DefaultFilters = {
  date: 'Last 7 Days',
  since: 'now-7d',
  until: 'now',
};

const Transition = React.forwardRef((props, ref) =>
  <Slide direction="up" ref={ref} {...props} />);

const SearchOverlay = ({
  apps,
  filters,
  onClose,
  route,
  search,
  show,
  types,
  user,
}) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState();
  const [recentSkip, setRecentSkip] = useState(0);
  const [savedSkip, setSavedSkip] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [searchTypes, setSearchTypes] = useState('');
  const [tabValue, setTabValue] = useState('saved');
  const [text, setText] = useState();
  const [values, setValues] = useState(map(DefaultFilters));

  const allCommunities = (
    `forums,chats,blogs,ransomware,pastes,boards,social${
      (user.get('prm').some(p => /twtr/ig.test(p)))
        ? ',twitter' : ''}`
  );

  const searchTypeHeaders = (
    apps
      .filter(v => v.get('search'))
      .filter(v => !v.get('all'))
      .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
      .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
      .filter(v => user.get('prm').some(p => v.get('test').test(p)))
      .filter(v => v.get('value') !== 'search.all')
      .map(v => ((v.has('subHeader')) ? v.get('subHeader').toLowerCase() : null))
      .filter(v => v)
      .toSet()
      .toList()
  );

  const recentSearches = (
    user.getIn(['prefs', 'history', 'search'])
  );

  const savedSearches = (
    user.getIn(['prefs', 'search', 'data'])
      .sortBy(v => -(v.get('updated')))
      .sortBy(v => !v.get('pin'))
  );

  const determineType = () => {
    let type;
    // On close handle updates to the search
    // If we are on a single search type or all communities, use specific search page
    // otherwise use all search with all filter
    const searchTypesValues = searchTypes.split(',')
      .filter(v => !searchTypeHeaders.includes(v))
      .filter(v => v);
    const communities = allCommunities.split(',');
    if (searchTypesValues.length === 1) {
      type = searchTypes;
    } else if (searchTypesValues.length === 0
        || (searchTypesValues.every(v => communities.includes(v)))) {
      type = 'communities';
    } else {
      type = 'all';
    }
    return type;
  };

  const handleClose = () => {
    ReactTooltip.rebuild();
    setSearchTypes('');
    setText(filters.get('query'));
    setScrolled(false);
    setSavedSkip(0);
    setRecentSkip(0);
    setValues(filters);
    onClose();
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (!show) return;
    if (scrollTop >= 200) setScrolled(true);
    if (scrollTop < 200) setScrolled(false);
  };

  const onSkip = (skip) => {
    setTimeout(() => ReactTooltip.rebuild());
    setRecentSkip(skip);
  };

  const onRecent = (value) => {
    handleClose();
    Common.RecentSearch.Route(value);
  };

  const onReset = () => {
    setText('');
  };

  const onSaved = (value) => {
    handleClose();
    Common.SavedSearch.Route(value);
  };

  const onSaveSearch = (value) => {
    const now = moment.utc().unix();
    const source = user.getIn(['prefs', 'search', 'data']);
    const data = value.get(0).set('created', +now).set('updated', +now);
    const update = map({ total: source.count() + 1, data: source.push(data) });
    UserActions.saveSearch(update, data.get('name'));
    setDialog();
  };

  const onSearch = () => {
    // Replace duplicated spaces with a single space
    const updatedValue = (text || '').replace(/[ ]{2,}/, ' ');
    setText(updatedValue);
    // prevent lengthy searches
    if (updatedValue && updatedValue.length > 3000) return;

    const query = Array.from(values.entries())
      .reduce((main, [key, value]) => ({ ...main, [key]: value }), {});
    const determinedType = determineType();

    const totalQuery = {
      ...map(query).filter(v => v).toJS(),
      query: updatedValue,
      skip: undefined,
    };

    setSearchTypes('');
    handleClose();

    const event = {
      type: searchTypes || types,
      filters: { ...values.toJS(), query: updatedValue },
      origin: '.search-overlay .content textarea#query',
    };

    Track(event, 'Search');

    const basePath = (determinedType === 'reports')
      ? '/home/intelligence/reports/search'
      : `/home/search/${(determinedType) === 'chan' ? 'boards' : (determinedType)}`;

    History.push({
      pathname: basePath,
      query: totalQuery,
    });
  };

  const onSearchType = () => {
    // When the search type changes, we need to remove any filters that relate
    // to the specific search types
    const determinedType = determineType();
    const all = (['all', 'communities'].includes(determinedType))
      ? searchTypes
      : undefined;
    const { date = '', limit = '', since = '', query = '', until = '' } = values.toJS();
    const updatedFilters = {
      ...(search.getIn(['defaults', determinedType]) || map()).toJS(),
      date,
      limit,
      since,
      query,
      until,
      all,
    };
    setValues(fromJS(updatedFilters));
  };

  const options = [
    { id: 'save',
      type: ['save'],
      dialog: (
        <Editor
          fields={[
            { value: 'name', label: 'Search Name', type: 'text', req: true },
            { value: 'cat', label: 'Input Custom Search Category', type: 'text', bulk: true },
            { value: 'value', label: 'Query', type: 'textarea' },
            { value: 'filtered', label: 'Include filters', type: 'checkbox',
              name: 'searches.filtered',
              help: `*Note: date filters, once applied, will be fixed in
              accordance with the date on which the search was saved.`, bulk: true },
            { value: 'pin', label: 'Pin Search', name: 'searches.pin',
              checked: <Bookmark />, unchecked: <BookmarkBorder />,
              type: 'checkbox', bulk: true }]}
          data={fromJS([{
            filtered: true,
            pin: true,
            value: (text || '').replace(/[ ]{2,}/, ' '),
            filters: (!values.isEmpty())
              ? qs.stringify({
                  ...values.filter(v => v).toJS(),
                  query: undefined,
                }, { addQueryPrefix: true })
              : '',
            type: apps
              .find(v => v.get('value') === `search.${determineType()}`),
          }])}
          type={determineType()}
          save={(type, value) => onSaveSearch(value)}
          toggle={() => setDialog()} />),
    },
  ];

  useEffect(() => {
    setValues(map({
      ...values.toJS(),
      ...filters.toJS(),
    }));
    setText(filters.get('query'));
  }, [filters]);

  useEffect(() => {
    if (types && types !== searchTypes) setSearchTypes(types);
  }, [types]);

  useEffect(() => {
    onSearchType();
  }, [searchTypes]);

  return (
    <Dialog
      fullScreen
      open={show}
      scroll="paper"
      className={cx([
        style['search-overlay'],
        classes['search-overlay'],
      ])}
      onClose={() => handleClose()}
      TransitionComponent={Transition}>
      <DialogTitle>
        <div className={style.title}>
          <div className={style.label}>
            <div>Advanced Search</div>
            <Icon
              color="primary"
              data-for="help.tooltip"
              data-tip="Get help with query syntax"
              onClick={() => window.open('/home/resources/syntax')}
              className={style.icon}>help
            </Icon>
          </div>
          <Icon
            color="primary"
            onClick={() => handleClose()}
            className={style.close}>close
          </Icon>
        </div>
      </DialogTitle>
      <DialogContent onScroll={handleScroll}>
        <React.Fragment>
          <Grid
            fluid
            name="component.searchOverlay.content"
            className={style.content}>
            <Row className={cx([style['search-row'], scrolled && style.hidden])}>
              <Col xs={8} className={style['search-container']}>
                <div className={style.top}>
                  <SearchTypeSelection
                    apps={apps}
                    parentTypes={searchTypes}
                    prm={user.get('prm')}
                    route={route}
                    setParentTypes={setSearchTypes}
                    user={user} />
                  <div className={style.date}>
                    <div>Date: </div>
                    <React.Fragment>
                      <Button
                        color="primary"
                        id="date"
                        name="date"
                        className={cx([style.h4, 'h4', style.open, style.dropdown])}
                        onClick={e => setDialog({ target: e.currentTarget, key: 'date' })}
                        endIcon={<Icon>keyboard_arrow_down</Icon>}>
                        {values.get('date') || 'All Time'}
                      </Button>
                      <Popover
                        anchorEl={dialog && dialog.target}
                        open={!!(dialog && dialog.key === 'date')}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onClose={() => setDialog()}>
                        <DateFilter
                          date={values.get('date')}
                          since={values.get('since')}
                          until={values.get('until')}
                          dates={search.get('dates')}
                          toggle={() => setDialog()}
                          onFilter={v => setValues(values.merge(v))} />
                      </Popover>
                    </React.Fragment>
                  </div>
                </div>
                <Input
                  fullWidth
                  multiline
                  disableUnderline
                  id="query"
                  name="find.expanded"
                  className={style.textbox}
                  rows={10}
                  maxRows={10}
                  value={text || ''}
                  inputProps={{ padding: '15px' }}
                  onChange={event => setText(event.target.value)} />
                <div className={style.actions}>
                  <div
                    className={cx([
                      style.textlimit,
                      text && text.length > 3000 && style.error,
                    ])}>
                    {text && text.length > 200 && `${text.length}/3000`}
                  </div>
                  <div>
                    <Button
                      id="save-search"
                      name="saveSearch"
                      disabled={!!(text && text.length > 3000)}
                      className={cx([style.h4, 'h4', style.search, style.save])}
                      onClick={() => setDialog({ key: 'save' })}>
                      Save Search
                    </Button>
                    <Button
                      id="search"
                      name="search"
                      disabled={!!(text && text.length > 3000)}
                      className={cx([style.h4, 'h4', style.search])}
                      onClick={() => onSearch()}>
                      Search
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={4} className={style.existing}>
                <TabContext value={tabValue}>
                  <TabList
                    variant="fullWidth"
                    indicatorColor="secondary"
                    onChange={(event, val) => setTabValue(val)}
                    className={style.tabs}>
                    <Tab label={`Saved (${user.getIn(['prefs', 'search', 'data']).count()})`} value="saved" />
                    <Tab label="Recent" value="recent" />
                  </TabList>
                  <TabPanel value="saved">
                    <div>
                      <List className={cx([style.items, style.searches, style.saved])}>
                        {savedSearches
                        .slice(savedSkip, savedSkip + 6)
                        .map((v, k) => {
                          const { filters: filtersValue } = Common.SavedSearch.Filters(v);
                          return (
                            <ListItem
                              key={`${v.get('created')}.${k.toString()}`}
                              className={cx([style.item])}
                              onMouseEnter={() => ReactTooltip.rebuild()}
                              data-tip={`${Object.keys(filtersValue).map(prop => `<div>${prop}: ${Text.StripHtml(filtersValue[String(prop)])}</div>`).join('')}`}
                              data-for="recentItems"
                              onClick={() => onSaved(v)}>
                              <ListItemIcon>
                                <Icon>{v.get('pin') ? 'bookmark' : ''}</Icon>
                              </ListItemIcon>
                              <ListItemText primary={v.get('name')} />
                            </ListItem>
                          );
                      })}
                      </List>
                      <BasicPagination
                        count={savedSearches.count()}
                        limit={6}
                        onSkip={setSavedSkip} />
                    </div>
                  </TabPanel>
                  {/* If government user, don't store/show recent activity */}
                  {!user.get('ffs').some(p => /log.red.qry/.test(p)) &&
                  <TabPanel value="recent">
                    <div>
                      <List className={cx([style.items, style.searches])}>
                        {recentSearches
                        .filter(v => v.getIn(['type', 'searchType']))
                        .slice(recentSkip, recentSkip + 6)
                        .map((v, k) => {
                          const {
                            text: itemText,
                            filters: filtersValue,
                          } = Common.RecentSearch.Filters(v);
                          return (
                            <ListItem
                              key={`${v.get('created')}.${k.toString()}`}
                              className={cx([style.item])}
                              data-tip={`${Object.keys(filtersValue).map(prop => `<div>${prop}: ${Text.StripHtml(filtersValue[String(prop)])}</div>`).join('')}`}
                              data-for="recentItems"
                              onMouseEnter={() => ReactTooltip.rebuild()}
                              onClick={() => onRecent(v)}>
                              <ListItemText primary={itemText} />
                            </ListItem>
                          );
                        })}
                      </List>
                      <BasicPagination
                        count={recentSearches
                          .filter(v => v.getIn(['type', 'searchType']))
                          .count()}
                        limit={6}
                        onSkip={v => onSkip(v)} />
                    </div>
                  </TabPanel>}
                </TabContext>
              </Col>
            </Row>
            <Row className={cx([style['fixed-search-row'], scrolled && style.show])}>
              <Col xs={12}>
                <div className={style.top}>
                  <div>
                    <SearchTypeSelection
                      apps={apps}
                      parentTypes={searchTypes}
                      route={route}
                      setParentTypes={setSearchTypes}
                      user={user} />
                    <Input
                      fullWidth
                      disableUnderline
                      id="text"
                      data-lpignore="true"
                      className={style.input}
                      placeholder={`Try searching for ${user.getIn(['route', 'placeholder']) || ''}...`}
                      value={text || ''}
                      onChange={event => setText(event.target.value)}
                      endAdornment={text && (
                        <InputAdornment position="end">
                          <Icon
                            onClick={() => onReset()}>
                            close
                          </Icon>
                        </InputAdornment>
                      )} />
                  </div>
                  <div>
                    <div className={style.date}>
                      <div>Date: </div>
                      <Button
                        color="primary"
                        id="date"
                        name="date"
                        className={cx([style.h4, 'h4', style.open, style.dropdown])}
                        onClick={e => setDialog(({ target: e.currentTarget, key: 'date' }))}
                        endIcon={<Icon primary="primary">keyboard_arrow_down</Icon>}>
                        {values.get('date') || 'All Time'}
                      </Button>
                      <Popover
                        anchorEl={dialog && dialog.target}
                        open={!!(dialog && dialog.key === 'date')}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onClose={() => setDialog()}>
                        <DateFilter
                          date={values.get('date')}
                          since={values.get('since')}
                          until={values.get('until')}
                          dates={search.get('dates')}
                          toggle={() => setDialog()}
                          onFilter={v => setValues(values.merge(v))} />
                      </Popover>
                    </div>
                    <Button
                      name="search"
                      disabled={!!(text && text.length > 3000)}
                      className={cx([style.h4, 'h4', style.search])}
                      onClick={() => onSearch()}>
                      Search
                    </Button>
                  </div>
                </div>
                <div className={style.bottom}>
                  <div className={style.applied}>{values.filter(v => v).count() > 0 ? 'Filters Applied' : ''}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Accordion
                  header="Filters"
                  subheader="Narrow down your search results">
                  <Filters
                    advanced
                    type={determineType()}
                    user={user}
                    store={search}
                    defaults={search.getIn(['defaults', determineType()]) || map()}
                    filters={values}
                    onFilter={v => setValues(values.merge(v))}
                  />
                </Accordion>
              </Col>
            </Row>
            <ReactTooltip id="help.tooltip" html place="right" effect="solid" />
            <ReactTooltip id="recentItems" html place="right" effect="solid" />
          </Grid>
          {options
            .filter(v => v.dialog)
            .map(option => (
              <Dialog
                fullWidth
                key={option.id}
                className={style.dialog}
                open={!!(dialog && dialog.key === 'save')}
                onClose={() => setDialog()}>
                <DialogTitle>
                  Save New Search
                </DialogTitle>
                <DialogContent>
                  {option.dialog}
                </DialogContent>
              </Dialog>))}
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
};

SearchOverlay.propTypes = {
  apps: PropTypes.object,
  filters: PropTypes.object,
  route: PropTypes.object,
  onClose: PropTypes.func,
  search: PropTypes.object,
  show: PropTypes.bool,
  types: PropTypes.string,
  user: PropTypes.object,
};

SearchOverlay.defaultProps = {
  apps: list(),
  filters: map(),
  route: map(),
  onClose: null,
  search: map(),
  show: false,
  types: '',
  user: map(),
};

export default SearchOverlay;
