import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import HostData from './HostData';
import style from './credentials.module.scss';
import { UserContext } from '../../utils/Context';
import Text from '../../../utils/text';
import CCM from '../../../utils/ccm';
import Invalid from '../../utils/Invalid/Invalid';
import InternalLink from '../../utils/InternalLink';
import UserActions from '../../../actions/userActions';
import Apps from '../../../constants/Apps';
import { knownHashes } from '../../../constants/org_profiles/Edm';

const Credentials = ({ blur, data, orgProfiles }) => {
  const [first, setFirst] = useState(map());
  const [last, setLast] = useState(map());
  const [orgProfile, setOrgProfile] = useState(map());
  const [breaches, setBreaches] = useState(list());
  const apps = fromJS(Apps);
  const user = useContext(UserContext);
  const prm = user.get('prm');

  const totalBreachCount = data.get('data')?.map(v => v.getIn(['breach', 'fpid'])).toSet().size;

  const countPasswordComplexity = (type) => {
    const password = first.get('password');
    let count = 0;
    [...password].forEach((char) => {
      switch (type) {
        case 'uppercase':
          if (/^[A-Z]*$/.test(char)) count += 1;
          break;
        case 'lowercase':
          if (/^[a-z]*$/.test(char)) count += 1;
          break;
        case 'number':
          if (/^[0-9]*$/.test(char)) count += 1;
          break;
        case 'symbol':
          if (!/^[A-Za-z0-9]*$/.test(char)) count += 1;
          break;
        default:
          break;
      }
    });
    return count;
  };

  const onSearch = (k, v) => {
    const pathname = apps
      .find(a => a.get('value') === 'search.credentials')
      .getIn(['path', 0]);
    const query = { query: '', [k]: v };
    return { pathname, query };
  };

  const getKnownHashes = () => {
    const breachHashes = first.getIn(['password_complexity', 'probable_hash_algorithms']);
    const hashesWeCareAbout = breachHashes.filter(v => (knownHashes.includes(v) ? v : false));
    return hashesWeCareAbout ? hashesWeCareAbout.join(', ') : '';
  };

  useEffect(() => {
    if (!data.isEmpty() && data.has('total') && data.get('total') > 0) {
      setFirst(data.getIn(['data', 0]));
      setLast(data.getIn(['data']).last());
      const sid = data.getIn(['data', 0, 'customer_id']);
      if (orgProfiles.getIn([sid, 'edm'])) {
        setOrgProfile(orgProfiles.getIn([sid, 'edm']));
      } else {
        UserActions.loadOrgProfiles(sid);
      }
    }
  }, [data]);

  useEffect(() => {
    if (orgProfiles.isEmpty() || !orgProfile.isEmpty() || first.isEmpty()) return;

    const profile = orgProfiles.getIn([first.get('customer_id'), 'edm']);
    if (profile) setOrgProfile(profile);
  }, [orgProfiles]);

  useEffect(() => {
    if (orgProfile.isEmpty() || data.isEmpty()) return;
    const breachData = data.get('data').map(v => fromJS({
      ...v.toJS(),
      meetsProfileSpec: CCM.MeetsOrgProfile(v, orgProfile),
    }));
    setBreaches(breachData);
  }, [orgProfile, data]);


  return (
    <Grid
      fluid
      name="component.credentials"
      className={cx([style.base, style.credentials])}>
      {data.isEmpty() &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {!data.isEmpty() && data.has('total') &&
      <Row>
        <Col xs={12} className={style.header}>
          <div className={style.title}>
            <Icon className={style.icon}>
              email
            </Icon>
            <div
              className={cx([style.h0, 'h0', style.raj, 'raj'])}
              name="creds.body.title">
              {Text.Highlight(Text.BlurEmail(`${first.getIn(['username']) || first.getIn(['email'])}:${first.getIn(['password'])}`, blur))}
            </div>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Domain:</div>
            {first.getIn(['domain']) ?
              <InternalLink
                name="creds.domain"
                className={cx([first.getIn(['domain']) && style.a, 'a'])}
                to={onSearch('domain', first.getIn(['domain']))}>
                {Text.BlurDomain(first.getIn(['domain']), blur)}
              </InternalLink>
            :
              <> - </>
            }
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Email:</div>
            {first.getIn(['email']) ?
              <InternalLink
                name="creds.email"
                className={cx([first.getIn(['email']) && style.a, 'a'])}
                to={onSearch('email', first.getIn(['email']))}>
                {Text.BlurEmail(first.getIn(['email']) || '-', blur)}
              </InternalLink>
            :
              <> - </>
            }
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Username:</div>
            {first.getIn(['username']) ?
              <InternalLink
                name="creds.username"
                className={cx([first.getIn(['username']) && style.a, 'a'])}
                to={onSearch('username', first.getIn(['username']))}>
                {Text.BlurPassword(first.getIn(['username']) || '-', blur)}
              </InternalLink>
            :
              <>-</>
            }
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Password:</div>
            {first.getIn(['password']) ?
              <InternalLink
                name="creds.password"
                className={cx([first.getIn(['password']) && style.a, 'a'])}
                to={onSearch('password', first.getIn(['password']))}>
                {Text.BlurPassword(first.getIn(['password']) || '-', blur)}
              </InternalLink>
            :
              <>-</>
            }
          </div>
          {first.get('password_complexity') &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Password <br />Complexity:</div>
              <div>
                {first.getIn(['password_complexity', 'length']) && user.get('prm').some(p => /org.fp.r/.test(p)) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Length:</div>
                  {first.getIn(['password_complexity', 'length']) || '-'}
                </div>}
                {first.hasIn(['password_complexity', 'has_lowercase']) && user.get('prm').some(p => /org.fp.r/.test(p)) &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Lowercase Letter:</div>
                    {first.getIn(['password_complexity', 'has_lowercase']) ? `Yes - ${countPasswordComplexity('lowercase')}` : 'No'}
                  </div>
                  }
                {first.hasIn(['password_complexity', 'has_uppercase']) && user.get('prm').some(p => /org.fp.r/.test(p)) &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Uppercase Letter:</div>
                    {first.getIn(['password_complexity', 'has_uppercase']) ? `Yes - ${countPasswordComplexity('uppercase')}` : 'No'}
                  </div>
                }
                {first.hasIn(['password_complexity', 'has_number']) && user.get('prm').some(p => /org.fp.r/.test(p)) &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Number:</div>
                    {first.getIn(['password_complexity', 'has_number']) ? `Yes - ${countPasswordComplexity('number')}` : 'No'}
                  </div>
                }
                {first.hasIn(['password_complexity', 'has_symbol']) && user.get('prm').some(p => /org.fp.r/.test(p)) &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Symbol:</div>
                    {first.getIn(['password_complexity', 'has_symbol']) ? `Yes - ${countPasswordComplexity('symbol')}` : 'No'}
                  </div>}
              </div>
            </div>}
          {first.get('original_password', '') &&
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Original Password:</div>
            <div>{Text.BlurPassword(first.getIn(['original_password']) || '-', blur)}</div>
          </div>}
          {first.getIn(['password_complexity', 'probable_hash_algorithms']) && getKnownHashes() &&
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Probable Hash Type(s):</div>
            <div>{getKnownHashes()}</div>
          </div>}
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Found In:</div>
            <div>
              {data.get('total') > 1 ? `${data.get('total')} sightings ` : `${data.get('total')} sighting `}
              {totalBreachCount > 1 ? `in ${totalBreachCount} breaches` : `in ${totalBreachCount} breach`}
            </div>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>First Observed:</div>
            <div>
              {last.getIn(['breach', 'first_observed_at', 'timestamp'])
                ? moment.utc(last.getIn(['breach', 'first_observed_at', 'timestamp']) * 1000).format('MMM D, YYYY')
                : '-'}
            </div>
          </div>
          <div className={style.table}>
            <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last Observed:</div>
            <div>
              {first.getIn(['last_observed_at', 'timestamp'])
                ? moment.utc(first.getIn(['last_observed_at', 'timestamp']) * 1000).format('MMM D, YYYY')
                : '-'}
            </div>
          </div>
        </Col>
        {breaches.map(v => (
          <Col xs={12} key={v.get('fpid')}>
            <Paper className={style.card}>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Date of Breach:</div>
                <div>
                  {v.getIn(['breach', 'created_at', 'timestamp'])
                    ? moment.utc(v.getIn(['breach', 'created_at', 'timestamp']) * 1000).format('MMM D, YYYY')
                    : '-'}
                </div>
                <Icon
                  name="creds.date.tooltip"
                  data-for="offline.tooltip"
                  data-tip="The date the credential became available as part of a breach."
                  className={style.help}>
                  help
                </Icon>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last Observed Date:</div>
                <div>
                  {moment.utc(v.getIn(['last_observed_at', 'timestamp']) * 1000).format('MMM D, YYYY')}
                </div>
                <Icon
                  name="cards.body.offline"
                  data-for="offline.tooltip"
                  data-tip="The date the credential was last seen by Flashpoint."
                  className={style.help}>
                  help
                </Icon>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Title:</div>
                <InternalLink
                  name="creds.breach.title"
                  className={cx([v.getIn(['breach', 'title']) && style.a, 'a'])}
                  to={onSearch('title',
                    `${v.getIn(['breach', 'fpid'])}::
                    ${v.getIn(['breach', 'title'])}`)}>
                  {v.getIn(['breach', 'title']) || '-'}
                </InternalLink>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Sourced <br />From:</div>
                <div>
                  {v.getIn(['breach', 'source']) || '-'}
                </div>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Source Type:</div>
                <div>
                  {v.getIn(['breach', 'source_type']) || '-'}
                </div>
              </div>
              {v.getIn(['breach', 'context']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Breach Context:</div>
                <div>
                  {v.getIn(['breach', 'context'])}
                </div>
              </div>}
              {v.get('affected_url') &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Affected URL:</div>
                <div>
                  {v.get('affected_url')}
                </div>
              </div>}
              {v.get('affected_domain') &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Affected Domain:</div>
                <div>
                  {v.get('affected_domain')}
                </div>
              </div>}
              {v.getIn(['body', 'raw']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Raw Content:</div>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  {Text.BlurAll(Text.StripHtml(v.getIn(['body', 'raw'])), blur)}
                </pre>
              </div>}
              {(v.has('cookies') || v.has('infected_host_attributes')) && prm.some(p => /dat.edm.hd/ig.test(p)) &&
                <HostData breach={v} credentialType="credentials" />
              }
              {v.get('meetsProfileSpec') &&
              <Icon
                className={cx([style.icon, style.star, 'material-icons'])}
                data-for="body.tooltip"
                data-tip="Meets Profile Specifications">
                star
              </Icon>}
            </Paper>
          </Col>))}
      </Row>}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
      <ReactTooltip id="offline.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="body.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Credentials.propTypes = {
  data: PropTypes.object,
  orgProfiles: PropTypes.object,
  blur: PropTypes.bool,
};

Credentials.defaultProps = {
  data: map(),
  orgProfiles: map(),
  blur: false,
};

export default Credentials;
