import aIcon from '../../public/images/dm-icon-a.svg';
import bIcon from '../../public/images/dm-icon-b.svg';
import cIcon from '../../public/images/dm-icon-c.svg';
import dIcon from '../../public/images/dm-icon-d.svg';
import eIcon from '../../public/images/dm-icon-e.svg';
import fIcon from '../../public/images/dm-icon-f.svg';
import gIcon from '../../public/images/dm-icon-g.svg';
import hIcon from '../../public/images/dm-icon-h.svg';
import iIcon from '../../public/images/dm-icon-i.svg';
import jIcon from '../../public/images/dm-icon-j.svg';
import kIcon from '../../public/images/dm-icon-k.svg';
import lIcon from '../../public/images/dm-icon-l.svg';
import mIcon from '../../public/images/dm-icon-m.svg';
import nIcon from '../../public/images/dm-icon-n.svg';
import oIcon from '../../public/images/dm-icon-o.svg';
import pIcon from '../../public/images/dm-icon-p.svg';
import qIcon from '../../public/images/dm-icon-q.svg';
import rIcon from '../../public/images/dm-icon-r.svg';
import sIcon from '../../public/images/dm-icon-s.svg';
import tIcon from '../../public/images/dm-icon-t.svg';
import uIcon from '../../public/images/dm-icon-u.svg';
import vIcon from '../../public/images/dm-icon-v.svg';
import wIcon from '../../public/images/dm-icon-w.svg';
import xIcon from '../../public/images/dm-icon-x.svg';
import yIcon from '../../public/images/dm-icon-y.svg';
import zIcon from '../../public/images/dm-icon-z.svg';
import fpIcon from '../../public/images/flashpoint-icon.svg';

const StatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Takedown Requested', value: 'Takedown Requested' },
  { label: 'Unresolved', value: 'Unresolved' },
  { label: 'Details Needed', value: 'Details Needed' },
  { label: 'Removed', value: 'Removed' },
  { label: 'Takedown Not Possible', value: 'Takedown Not Possible' },
  { label: 'Unknown', value: 'Unnkown' },
];

const TypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Brand Abuse', value: 'Brand Abuse' },
  { label: 'Phishing', value: 'Phishing' },
  { label: 'Mobile Apps', value: 'Mobile App (Unauthorized)' },
  { label: 'Social Media', value: 'Social Media Brand Abuse' },
  { label: 'Vishing', value: 'Vishing' },
];

const ChatIcons = {
  a: aIcon,
  b: bIcon,
  c: cIcon,
  d: dIcon,
  e: eIcon,
  f: fIcon,
  g: gIcon,
  h: hIcon,
  i: iIcon,
  j: jIcon,
  k: kIcon,
  l: lIcon,
  m: mIcon,
  n: nIcon,
  o: oIcon,
  p: pIcon,
  q: qIcon,
  r: rIcon,
  s: sIcon,
  t: tIcon,
  u: uIcon,
  v: vIcon,
  w: wIcon,
  x: xIcon,
  y: yIcon,
  z: zIcon,
  default: fIcon,
  flashpoint: fpIcon,
};

export { ChatIcons, StatusOptions, TypeOptions };
