import React from 'react';

import Api from '../../../utils/api';

const dev = '_self' in React.createElement('div') ? '/dev' : '';
const KeywordClassUrl = '/ui/v4/alerts/keyword_classes';
const FraudwatchUrl = `/ui/v4/domain-monitoring${dev}`;

const readBrandsLimit = async (sid = '') => {
  if (!sid) return {};
  return Api.get(`${FraudwatchUrl}/organizations/${sid}`)
    .then(res => (res.ok ? res?.data : {}))
    .catch(() => ({}));
};

const readKeywordClasses = () => Api.get(KeywordClassUrl)
  .then((res) => {
    if (!res.ok) {
      throw new Error('Unable to load Keyword Classes');
    }
    return res.data;
  })
  .then((data) => {
    const twitter = data?.filter(item => /twitter/ig.test(item.name));
    const [internetInfrastructure] = data?.filter(item => /internet_infrastructure/ig.test(item.name));
    const [cloudInfrastructure] = data?.filter(item => /cloud_infrastructure/ig.test(item.name));
    return { twitter, internetInfrastructure, cloudInfrastructure };
  });

export default {
  readBrandsLimit,
  readKeywordClasses,
};
