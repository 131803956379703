import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  Collapse,
  CircularProgress,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Tab,
} from '@mui/material';
import style from './board.module.scss';
import Text from '../../utils/text';
import InternalLink from '../utils/InternalLink';
import SearchActions from '../../actions/searchActions';
import TextFilter from '../filters/TextFilter';
import DateFilter from '../filters/DateFilter';
import Chips from '../filters/Chips';
import History from '../../utils/history';
import Iso from '../../constants/Iso';
import Media from '../utils/Media';
import {
  UserContext,
} from '../../components/utils/Context';

// eslint-disable-next-line @typescript-eslint/no-shadow
export const ListThreadResults = ({ inline, search, onRoute, style, yieldPerItem }) => {
  const { query: { query_i18n } } = History.getCurrentLocation();
  const user = useContext(UserContext);
  const prm = user.get('prm', list());

  return (
    !inline.isEmpty() && inline.get('total') > 0 &&
    <div>
      <div>
        <b>
          {`Showing ${inline.get('data').size.toLocaleString()} of
                            ${inline.get('total').toLocaleString()} results in this thread`}
        </b>
      </div>
      {inline.get('data').map((v, k) => (
        <div
          key={`inline.${v.get('fpid')}`}
          className={style.result}>
          <div className={cx([style.h3, style.index])}>{`${k + 1}.`}</div>
          <div
            className={cx([
                              style.post,
                              search.getIn(['filters', 'fpid']) === v.getIn(['fpid']).toString() && style.selected])}>
            <div className={cx([style.h3])}>
              {`${moment.utc(v.getIn(['sort_date']))
                                .format('MMMM DD, YYYY HH:mm:ss')}`}
            </div>
            <div
              className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal',
                              style.a, 'a', style.author])}>
              {Text.Highlight(
                                v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                                v.getIn(['site_actor', 'names', 'handle']) ||
                                v.getIn(['highlight', 'site_actor.username', 0]) ||
                                v.getIn(['site_actor', 'username']) ||
                                v.getIn(['site_actor', 'name']) ||
                                v.getIn(['highlight', 'native_id', 0]) ||
                                v.getIn(['native_id']) || '-')}
            </div>
            {yieldPerItem(v)}
            <div dir="auto" className={style.clamp}>
              {Text.Highlight(
                  `${Text.Trim(
                    Text.Post(
                      (query_i18n && v.getIn(['body', 'text/plain_en'])) ||
                      v.getIn(['highlight', 'body.text/plain', 0]) ||
                      v.getIn(['body', 'text/plain']) || '-'), 150)}<br/>
                        <span class="translation">${
                          query_i18n && v.hasIn(['body', 'text/plain_native_language']) ?
                          `Translated from <b>${Iso?.[v.getIn(['body', 'text/plain_native_language'])]?.name || 'Unknown'}</b> to <b>English</b>`
                          : ''}
                        </span>`,
                )
              }
              {v.get('media_v2') &&
                prm.some(p => /org.fp.r/.test(p)) &&
                v.get('media_v2')
                  .filter(media => media && !['webpage'].includes(media.getIn(['media_v2', 'type'])))
                  .map(media => (
                    <Media
                      mini
                      data={media}
                      postFPID={media?.get('fpid')} />
                  ))}
            </div>
            <Icon
              className={cx([style.launch])}
              onClick={e => onRoute(e, v)}>
              launch
            </Icon>
          </div>
        </div>))}
    </div>
  );
};

ListThreadResults.propTypes = {
  inline: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  onRoute: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  yieldPerItem: PropTypes.func,
};

ListThreadResults.defaultProps = {
  yieldPerItem: Function.prototype,
};


// eslint-disable-next-line @typescript-eslint/no-shadow
export const ThreadNoResults = ({ inline, load, style }) => (
  <React.Fragment>
    {inline.isEmpty() && load &&
    <CircularProgress />}
    {inline.isEmpty() && !load &&
    <div className={cx([style.h4, 'h4', style.open, style.empty])}>
      Enter a query or apply some filters above to get a list
      of all messages that match your request.
    </div>}
    {!inline.isEmpty() && inline.get('total') === 0 &&
    <div className={cx([style.h4, 'h4', style.open, style.empty])}>
      No results found, try changing your search parameters.
    </div>}
  </React.Fragment>
);

ThreadNoResults.propTypes = {
  inline: PropTypes.object.isRequired,
  load: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
};

const useStyles = makeStyles({
  board: {
    '& .MuiTabPanel-root': {
      padding: 0,
    },
  },
});

const Board = ({
  filters,
  inline,
  meta,
  search,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [load, setLoad] = useState(true);
  const [values, setValues] = useState(map());
  const [text, setText] = useState('');
  const [tab, setTab] = useState('search');

  const count = (
    filters
      .filter(v => v)
      .filterNot((v, k) => ['id', 'date', 'query', 'sort', 'since', 'unti', 'skip', 'limit'].includes(k))
      .filterNot((v, k) => v === search.getIn(['defaults', 'boards', k]))
      .size
  );

  const options = (
    !values ? [] : [
      { type: ['board'],
        key: 'date',
        label: 'Date',
        icon: 'date_range',
        fields: ['since', 'until'],
        values: ['since', 'until'],
        dialog: (
          <DateFilter
            time
            inline
            icon="date_range"
            text="Filter results by date"
            date={values.get('date')}
            since={values.get('since')}
            until={values.get('until')}
            dates={search.get('dates')}
            onFilter={v => setValues(values.merge(v))} />) },
      { type: ['board'],
        key: 'author',
        label: 'User',
        icon: 'account_circle',
        fields: ['author', 'author_exact'],
        dialog: (
          <TextFilter
            inline
            icon="account_circle"
            text="Search by username"
            fields={[
              { value: 'author', label: 'User Name', type: 'text' }]}
            defaults={search.getIn(['defaults', 'boards']) || map()}
            filters={values}
            onFilter={v => setValues(values.merge(v))} />) },
    ]
  );

  const onToggle = (show) => {
    const btn = document.getElementsByName('board.filters')[0];
    const searchValue = document.getElementsByName('board.search')[0];
    if (searchValue || show) btn.click();
  };

  const onBoard = (k, v) => ({
    pathname: '/home/search/boards',
    query: { query: '', [k]: v },
  });

  const onDelete = (option) => {
    const id = meta.get('fpid');
    const value = option.values
      ? option.values.reduce((acc, cur) => ({ ...acc, [cur]: search.getIn(['defaults', 'boards', cur]) }), {})
      : ({ [option.value]: search.getIn(['defaults', 'boards', option.value]) });
    const query = { ...values.toJS(), ...value, query: text };
    SearchActions.set(['search', 'result', 'board_inline'], map());
    SearchActions.search('inline.board', id, undefined, query);
    setLoad(true);
  };

  const onSearch = () => {
    const fpid = meta.getIn(['container', 'fpid']);
    const query = { ...values.toJS(), query: text };
    SearchActions.search('inline.board', fpid, undefined, query);
    setLoad(true);
    setTab('search');
    onToggle();
  };

  const onRoute = (e, v) => {
    e.stopPropagation();
    const { pathname, query, hash } = History.getCurrentLocation();
    const fpid = v.getIn(['fpid']);
    const id = moment.utc(v.getIn(['sort_date'])).unix();
    const updates = { ...query, fpid, id, query: text };
    History.navigateTo({ pathname, hash, query: updates });
    SearchActions.search('board', v.getIn(['container', 'fpid']));
  };

  useEffect(() => {
    setValues(filters);
    setText(filters.get('query'));
  }, [filters]);

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.board,
        classes.board,
      ])}>
      <Row>{/* tabs */}
        <Col xs={12}>
          <TabContext value={tab}>
            <TabList
              variant="fullWidth"
              indicatorColor="secondary"
              onChange={(event, val) => setTab(val)}
              className={style.tabs}>
              <Tab label="Thread" value="thread" />
              <Tab label="Search" value="search" />
            </TabList>
            <TabPanel value="thread">
              <div className={style.body}>
                {meta.isEmpty() &&
                <CircularProgress />}
                {!meta.isEmpty() &&
                <div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Site:</div>
                    <InternalLink
                      className={cx([meta.get('fpid') && style.a, 'a'])}
                      to={onBoard('sites', `${meta.getIn(['site', 'title'])}`)}>
                      {meta.getIn(['site', 'title'])}
                    </InternalLink>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Board:</div>
                    <InternalLink
                      className={cx([meta.get('fpid') && style.a, 'a'])}
                      to={onBoard('board', `${meta.getIn(['container', 'container', 'title'])}`)}>
                      {`/${meta.getIn(['container', 'container', 'title'])}/`}
                    </InternalLink>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Board URI:</div>
                    <div>{meta.getIn(['container', 'container', 'source_uri'])}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Thread URI:</div>
                    <div>{meta.getIn(['container', 'source_uri']).split('/').slice(-2).join('/')}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Posts:</div>
                    <div>{meta.get('total').toLocaleString()}</div>
                  </div>
                </div>}
              </div>
            </TabPanel>
            <TabPanel value="search">
              <div className={style.search}>
                <form
                  className={style.form}
                  onSubmit={(e) => { e.preventDefault(); onSearch(); }}>
                  <FormControl variant="outlined" name="text">
                    <InputLabel>Filter Results</InputLabel>
                    <OutlinedInput
                      value={text || ''}
                      data-lpignore="true"
                      onChange={event => setText(event.target.value)}
                      startAdornment={(
                        <InputAdornment position="start">
                          <Icon
                            onClick={() => onSearch()}>
                            search
                          </Icon>
                        </InputAdornment>)}
                      endAdornment={text && (
                        <InputAdornment position="end">
                          <Icon
                            onClick={() => setText()}>
                            close
                          </Icon>
                        </InputAdornment>
                      )} />
                  </FormControl>
                </form>
                <div className={style.filters}>
                  <List>
                    <ListItem
                      name="board.filters"
                      onClick={() => setExpanded(true)}>
                      <ListItemText primary={`Filters (${count})`} />
                      <ListItemIcon>
                        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      in={expanded}
                      onKeyUp={e => (e.key === 'Enter' && onSearch())}>
                      {options
                        .filter(v => !v.hidden)
                        .map(option => (
                          <div key={option.key} className={style.option}>
                            {option.dialog}
                          </div>))}
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        name="board.search"
                        onClick={() => onSearch()}
                        endIcon={<Icon>search</Icon>}>
                        Search
                      </Button>
                    </Collapse>
                  </List>
                </div>
                <div className={style.chips}>
                  {options
                    .filter(v => values.get(v.value))
                    .map(option => (
                      <Chips
                        key={option.key}
                        option={option}
                        filters={filters}
                        defaults={search.getIn(['defaults', 'boards']) || map()}
                        onToggle={() => onToggle(true)}
                        onDelete={() => onDelete(option)} />))}
                </div>
                <div className={style.results}>
                  <ThreadNoResults
                    inline={inline}
                    load={load}
                    style={style}
                  />
                  <ListThreadResults
                    inline={inline}
                    search={search}
                    onRoute={onRoute}
                    style={style}
                    yieldPerItem={v => (
                      <React.Fragment>
                        {v.get('basetypes').includes('boards') && v.get('native_id') &&
                          <div className={cx([style.h3])}>
                            Post ID: {v.get('native_id')}
                          </div>}
                        {v.hasIn(['enrichments', 'quotes']) &&
                        <div className={style.quotes}>
                          {v.getIn(['enrichments', 'quotes'])
                                    .entrySeq()
                                    .map(([key, quote]) => (
                                      <div key={key}>
                                        {`>>${quote.get('native_id')}`}
                                      </div>))}
                        </div>}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Col>
      </Row>
      <ReactTooltip id="action.tooltip" place="left" effect="solid" />
      <ReactTooltip id="board.username.tooltip" html place="left" effect="solid" />
    </Grid>
  );
};

Board.propTypes = {
  filters: PropTypes.object,
  inline: PropTypes.object,
  meta: PropTypes.object,
  search: PropTypes.object,
};

Board.defaultProps = {
  filters: map(),
  inline: map(),
  meta: map(),
  search: map(),
};

export default Board;
