import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './content.module.scss';
import History from '../../utils/history';
import Syntax from '../../components/content/Syntax';
import Marketplaces from '../../components/content/Marketplaces';
import Ransomware from '../../components/content/Ransomware/Ransomware';
import Glossary from '../../components/content/Glossary';
import Settings from '../../components/content/Settings/Settings';
import Updates from '../../components/content/Updates';
import Pastes from '../../components/content/Pastes/Pastes';
import Cards from '../../components/content/Cards';
import Credentials from '../../components/content/Credentials/Credentials';
import CustomerCredentialDetail from '../../components/content/Credentials/CustomerCredentialDetail';
import Links from '../../components/content/Links';
import CVEs from '../../components/content/CVEs';
import Exploits from '../../components/content/Exploits';
import IOCs from '../../components/content/IOCs';
import Accounts from '../../components/content/Accounts';
import Taxii from '../../components/content/Tokens/Taxii';
import Tokens from '../../components/content/Tokens/Tokens';
import Twitter from '../../components/content/Twitter/Twitter';
import Admin from '../../components/content/Admin';
import DomainMonitoring from '../../components/content/DomainMonitoring/DomainMonitoring';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import Text from '../../utils/text';
import Apps from '../../constants/Apps';
import RequestForInformation from '../../components/content/RequestForInformation';
import PersonSearch from '../../components/content/PersonSearch';
import { SearchContext, UserContext } from '../../components/utils/Context';
import PersonSearchResults from '../../components/personSearch/PersonSearchResults/PersonSearchResults';

const Sources = ['accounts', 'cards', 'credentials', 'cves', 'exploits', 'iocs', 'marketplaces', 'pastes', 'ransomware'];
const Meta = ['cves', 'exploits', 'iocs'];
const Inline = ['cves', 'exploits'];
const Expanded = ['iocs', 'domain-monitoring', 'form', 'incidents'];
const Resources = ['updates', 'glossary', 'syntax', 'links'];

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Content = ({
  location,
  match: {
    params,
  },
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);
  const prevState = usePrevious({ location, params });

  const pageHistory = () => {
    const { type, id } = params;
    if (!type.includes('.') && type !== 'users') {
      const { search: querySearch, pathname } = History.getCurrentLocation();
      const now = moment.utc().unix();
      const app = Apps.find(v => v.value === `collections.${type}.view`);
      const title = search.getIn(['result', ...(app.title || 'title')]);
      if (!title) return;
      document.title = Text.StripHtml(Text.Strip(`Flashpoint - ${Text.Sentence(title)}`));
      const saveQuery = map({
        path: `${pathname}${querySearch}`,
        title,
        created: now,
      });

      SearchActions.set(['search', 'viewed'], search.get('viewed').push(id));
      UserActions.saveHistory('items', saveQuery);
    }
  };

  const onLoad = () => {
    const { type, id } = params;
    SearchActions.search(type, id);
    SearchActions.search.completed.listen(() => {
      if (Meta.includes(type)) SearchActions.search(`${type}.meta`, id);
      if (Inline.includes(type)) SearchActions.search(`${type}.inline`, id);
      pageHistory();
    });
  };

  useEffect(() => {
    if (!prevState || !location) return;
    if (params.type !== prevState.params.type && Sources.includes(params.type)) onLoad();
  }, [location, params]);

  useEffect(() => {
    if (Sources.includes(params.type)) onLoad();
    if (params.type === 'users') SearchActions.userPreferences(params.id);
  }, []);

  /* Force 'resources' pages to start at top of page if not anchor linked */
  useEffect(() => {
    if (Resources.includes(params.type) && !params.anchor) {
      window.scrollTo(0, 0);
    }
  }, [params]);

  return (
    <Grid
      fluid
      className={cx([style.content, Expanded.includes(params.type) && style.expanded])}>
      <Row>
        <Col xs={12}>
          {params.type === 'accounts' &&
          <Accounts
            data={search.getIn(['result', params.type])}
            search={search} />}
          {params.type === 'incidents' &&
          <DomainMonitoring id={params.id} user={user} />}
          {params.type === 'cards' &&
          <Cards
            data={search.getIn(['result', params.type])}
            search={search} />}
          {params.type === 'marketplaces' &&
          <Marketplaces
            data={search.getIn(['result', params.type])}
            location={location}
            search={search} />}
          {params.type === 'pastes' &&
          <Pastes
            data={search.getIn(['result', params.type])} />}
          {params.type === 'credentials' &&
          <Credentials
            data={search.getIn(['result', params.type])}
            orgProfiles={user.getIn(['org_profiles'])}
            blur={user.getIn(['prefs', 'blur_credentials'])}
            search={search} />}
          {params.type === 'customer-credentials' &&
          <CustomerCredentialDetail
            dataId={params.id}
            orgProfiles={user.getIn(['org_profiles'])}
            blur={user.getIn(['prefs', 'blur_credentials'])}
            search={search} />}
          {params.type === 'cves' &&
          <CVEs
            exploits={search.getIn(['result', 'meta', params.type])}
            mentions={search.getIn(['result', 'inline', params.type])}
            data={search.getIn(['result', params.type])}
            search={search} />}
          {params.type === 'exploits' &&
          <Exploits
            exploits={search.getIn(['result', 'meta', params.type])}
            mentions={search.getIn(['result', 'inline', params.type])}
            data={search.getIn(['result', params.type])}
            search={search} />}
          {params.type === 'iocs' &&
          <IOCs
            data={search.getIn(['result', params.type])}
            indicators={search.getIn(['result', 'meta', params.type])} />}
          {params.type === 'users' &&
          <Admin
            data={search.getIn(['admin', 'user'])}
            defaults={user.get('defaults')}
            help={user.get('defaultsHelp')}
            id={params.id}
            prefs={user.get('prefs')} />}
          {params.type === 'ransomware' &&
          <Ransomware
            data={search.getIn(['result', params.type])} />}
          {params.type === 'settings' &&
          <Settings
            apps={user.get('apps')}
            location={location}
            prm={user.get('prm')}
            prefs={user.get('prefs')}
            user={user} />}
          {params.type === 'tokens' &&
          <Tokens
            apps={user.get('apps')}
            prm={user.get('prm')}
            user={user}
            tokens={user.get('tokens')} />}
          {location.pathname === '/home/profile/taxii' &&
          <Taxii />}
          {params.type === 'updates' &&
          <Updates
            anchor={params.anchor} />}
          {params.type === 'glossary' &&
          <Glossary
            anchor={params.anchor}
            data={search.getIn(['resources', 'glossary'])} />}
          {params.type === 'syntax' &&
          <Syntax
            data={search.getIn(['resources', 'syntax'])} />}
          {params.type === 'links' &&
          <Links
            anchor={params.anchor}
            data={search.getIn(['resources', 'links'])} />}
          {params.type === 'twitter' &&
          <Twitter
            id={params?.id}
            fpid={params?.fpid}
            handle={params?.handle} />}
          {location.pathname === '/home/rfi/form' &&
          <RequestForInformation
            user={user} />}
          {location.pathname === '/home/intelligence/person-search/form' &&
          <PersonSearch
            user={user} />}
          {location.pathname.includes('/home/intelligence/person-search/results') &&
          <PersonSearchResults />}
        </Col>
      </Row>
    </Grid>
  );
};

Content.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};


export default Content;
