import { makeLinkJanitor } from './makeLinkJanitor';

export const convert = (path: string) => {
  const linkJanitor = makeLinkJanitor();

  if (/\/$/gi.test(path)) {
    return path;
  } else if (/\/home\/redirect/gi.test(path)) {
    return path?.replace(/\/home\/redirect/gi, '/to/fptools');
  } else {
    // since fptools bookmarks don't include origin, add it here
    return linkJanitor(`https://fp.tools${path}` || '');
  }
};
