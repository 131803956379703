import qs from 'qs';
import moment from 'moment';

import { List as list, Map as map, fromJS } from 'immutable';

import Apps from '../constants/Apps';
import Icons from '../constants/Icons';
import Token from './token';
import History from './history';
import Text from './text';

const removeEmptyProperties = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[String(prop)]) { newObj[String(prop)] = obj[String(prop)]; }
  });
  return newObj;
};

const queryFromFilterStringAndQuery = (filterString, query) => {
  const filterObject = removeEmptyProperties(qs.parse((filterString.indexOf('?') === 0 ? filterString.slice(1) : filterString)));
  const filterRemovedObject = { query };
  Object.keys(filterObject)
    .filter(key => key !== 'query')
    .forEach((key) => {
      filterRemovedObject[String(key)] = filterObject[String(key)];
    });
  return qs.stringify(filterRemovedObject, { addQueryPrefix: true });
};

const Common = {
  Basetypes: {
    CommunitySearchTypes: () => [
      'blogs',
      'ransomware',
      'boards',
      'chats',
      'forums',
      'pastes',
      'social',
      (Token.get('prm').some(p => /twtr/ig.test(p))) ? 'twitter' : '',
    ].filter(v => v),
    LabelToSearchType: (label = '') => {
      const labelMapping = new Map([
        ['chat', 'chats'],
      ]);
      const { searchType = '' } = Apps
        .filter(v => v.searchType)
        .find(v => v.label.toLowerCase() === label.toLowerCase() || v.label.toLowerCase() === labelMapping.get(label.split(' ')[0].toLowerCase()));
      return searchType;
    },
    BasetypeToSearchType: (record = map()) => {
      const recordBasetypes = record.has('basetypes') ? record.get('basetypes') : record;
      const generalBasetypes = fromJS(Apps).filter(v => v.get('search')).map(v => v.get('searchType'));
      const intersection = recordBasetypes.filter(v => generalBasetypes.includes(`${v}s`)).first();
      let type = record.getIn(['type', 'value']);

      if (intersection) {
        type = `${intersection}s`;
      } else {
        if (recordBasetypes.includes('chan')) type = 'boards';
        if (recordBasetypes.includes('gab') || recordBasetypes.includes('reddit')) type = 'social';
        if (recordBasetypes.includes('generic-product')) type = 'marketplaces';
        if (recordBasetypes.includes('image-enrichment')) type = 'images';
        if (recordBasetypes.includes('video-enrichment')) type = 'videos';
        if (recordBasetypes.includes('exploit')) type = 'exploits';
        if (recordBasetypes.includes('indicator')) type = 'iocs';
        if (recordBasetypes.includes('credential-sighting')) type = 'credentials';
        if (recordBasetypes.includes('customer-credentials')) type = 'customer-credentials';
        if (recordBasetypes.includes('repository')) type = 'code_repository';
        if (recordBasetypes.includes('malware')) type = 'malware';
        if (recordBasetypes.includes('breach')) type = 'breaches';
        if (recordBasetypes.includes('ransomware')) type = 'ransomware';
        if (recordBasetypes.includes('twitter')) type = 'twitter';
        /* Shodan alerts break without this line */
        if (recordBasetypes.includes('shodan')) type = 'internet_infrastructure';
        if (recordBasetypes.includes('cloud')) type = 'cloud_infrastructure';
      }
      return type;
    },
    ExtraBasetypes: (basetypes = []) => {
      let newBasetypes = [...basetypes];
      switch (basetypes.length > 0) {
        case basetypes.includes('account'): newBasetypes = newBasetypes.concat(['account', 'accounts']); break;
        case basetypes.includes('blog'): newBasetypes = newBasetypes.concat(['blog', 'blogs']); break;
        case basetypes.includes('chan'): newBasetypes = newBasetypes.concat(['chan', 'boards']); break;
        case basetypes.includes('card'): newBasetypes = newBasetypes.concat(['card', 'cards']); break;
        case basetypes.includes('chat'): newBasetypes = newBasetypes.concat(['chat', 'chats']); break;
        case basetypes.includes('image-enrichment'): newBasetypes = newBasetypes.concat(['images']); break;
        case basetypes.includes('video-enrichment'): newBasetypes = newBasetypes.concat(['videos']); break;
        case basetypes.includes('credential-sighting'): newBasetypes = newBasetypes.concat(['credential', 'credentials']); break;
        case basetypes.includes('cves'):
        case basetypes.includes('exploits'):
        case basetypes.includes('cves.exploits'):
        case basetypes.includes('cves.vulnerabilities'): newBasetypes = newBasetypes.concat(['cve', 'cves']); break;
        case basetypes.includes('indicator'): newBasetypes = newBasetypes.concat(['indicator', 'iocs']); break;
        case basetypes.includes('forum'): newBasetypes = newBasetypes.concat(['forum', 'forums']); break;
        case basetypes.includes('generic-product'): newBasetypes = newBasetypes.concat(['generic-product', 'marketplaces']); break;
        case basetypes.includes('paste'): newBasetypes = newBasetypes.concat(['paste', 'pastes']); break;
        case basetypes.includes('gab'):
        case basetypes.includes('reddit'): newBasetypes = newBasetypes.concat([basetypes[2] === 'web' ? basetypes[1] : basetypes[2], 'social', 'social']); break;
        case basetypes.includes('report'): newBasetypes = newBasetypes.concat(['report', 'reports']); break;
        default: return [...basetypes];
      }
      return newBasetypes;
    },
    ExtractMainBasetype: (record = map()) => {
      if (!record.get('basetypes')) return null;
      const recordBasetypes = record.has('basetypes') ? record.get('basetypes') : record;
      const generalBasetypes = fromJS(Apps).filter(v => v.get('search')).map(v => v.get('searchType'));
      const intersection = recordBasetypes.filter(v => generalBasetypes.includes(`${v}s`)).first();
      let type = record.getIn(['type', 'value']);
      if (intersection) {
        type = intersection;
      } else {
        const toFind = ['chan', 'gab', 'ransomware', 'reddit', 'generic-product', 'image-enrichment', 'video-enrichment', 'exploit', 'indicator', 'credential-sighting', 'repository', 'malware'];
        toFind.forEach((v) => {
          if (recordBasetypes.includes(v)) type = v;
        });
      }
      return type;
    },
    SearchTypeToBasetype: (searchType = '') => {
      if (!searchType) return '';
      switch (searchType) {
        case 'all':
        case 'communities': return 'blog OR chan OR (chat AND message) OR (forum AND post) OR paste OR (gab OR reddit) OR twitter';
        case 'accounts': return 'account';
        case 'blogs': return 'blog';
        case 'ransomware': return 'ransomware';
        case 'boards': return 'chan';
        case 'breaches': return 'breach';
        case 'cards': return 'card';
        case 'chats': return 'chat';
        case 'images': return 'image-enrichment';
        case 'videos': return 'video-enrichment';
        case 'credentials': return 'credential-sighting';
        case 'cves': return 'vulnerability';
        case 'exploit': return 'exploit';
        case 'cves.exploit': return 'exploit';
        case 'cves.vulnerability': return 'vulnerability';
        case 'iocs': return 'indicator_attribute';
        case 'forums': return 'forum';
        case 'code_repository': return 'repository';
        case 'marketplaces': return 'generic-product';
        case 'pastes': return 'paste';
        case 'reports': return 'report';
        case 'social': return 'gab OR reddit';
        case 'twitter': return 'twitter';
        default: return searchType;
      }
    },
    SearchTypesToBasetypesQuery: (searchTypes) => {
      const types = searchTypes.map((v) => {
        switch (v) {
          case 'communities': return 'blog OR chan OR (chat AND message) OR (forum AND post) OR paste OR (gab OR reddit) OR twitter';
          case 'accounts': return 'account';
          case 'blogs': return 'blog';
          case 'ransomware': return 'ransomware';
          case 'boards': return 'chan';
          case 'breaches': return 'breach';
          case 'cards': return 'card';
          case 'chats': return '(chat AND message)';
          case 'chats.discord': return '(discord AND message)';
          case 'chats.element': return '(element AND message)';
          case 'chats.qq': return '(qq AND message)';
          case 'chats.telegram': return '(telegram AND message)';
          case 'chats.rocketchat': return '(rocketchat AND message)';
          case 'media': return '(chat AND message)';
          case 'credentials': return 'credential-sighting';
          case 'cves': return 'vulnerability';
          case 'exploits': return 'exploit';
          case 'cves.exploit': return 'exploit';
          case 'cves.vulnerability': return 'vulnerability';
          case 'iocs': return 'indicator_attribute';
          case 'forums': return '(forum AND post)';
          case 'forums.aggregation': return '(forum AND site AND aggregations)';
          case 'marketplaces': return 'generic-product';
          case 'pastes': return 'paste';
          case 'reports': return 'report';
          case 'social': return 'gab OR reddit';
          case 'twitter': return 'twitter';
          // threads search
          case 'threads.forums': return 'thread aggregations';
          default: return v;
        }
      });
      return types.join(' OR ');
    },
  },
  Bookmark: {
    // Needs to handle bookmarks from table results, header, posts
    Path: (entity = map()) => {
      const { pathname, query } = History.getCurrentLocation();

      const type = Common.Basetypes.BasetypeToSearchType(entity);
      const app = Apps.find(v => v.value.includes(`${type}.view`));
      const base = app ? app.path[0] : '';
      const detail = ['boards', 'chats', 'forums', 'marketplaces'].includes(type);
      let uid = (!entity.isEmpty()) ? entity.get('fpid') : '';

      switch (type) {
        case 'forums':
        case 'chats':
        case 'blogs':
        case 'ransomware':
        case 'boards': {
          uid = uid || query.fpid;
          const id = (!entity.isEmpty())
            ? moment.utc(entity.getIn(['sort_date'])).unix()
            : query.id;
          const search = `${qs.stringify(map({ ...query, skip: null, limit: null, id, fpid: uid }).filter(v => v).toJS())}`;
          let parent = '';
          if (!entity.isEmpty()) {
            parent = entity.getIn(['container', 'fpid']) || entity.get('fpid');
          } else {
            parent = pathname.split('/').splice(-1).join();
          }
          return { uid, path: `${base}/${parent}?${search}${detail ? '#detail' : ''}` };
        }
        case 'social': {
          uid = uid || query.fpid;
          const id = (!entity.isEmpty())
            ? moment.utc(entity.getIn(['sort_date'])).unix()
            : query.id;
          const search = `${qs.stringify(map({ ...query, skip: null, limit: null, id, fpid: uid }).filter(v => v).toJS())}`;
          let parent = '';
          if (!entity.isEmpty()) {
            parent = entity.getIn(['parent_message', 'fpid']) || (entity.hasIn(['container', 'basetypes']) && entity.getIn(['container', 'basetypes']).includes('post') ? entity.getIn(['container', 'fpid']) : entity.get('fpid'));
          } else {
            parent = pathname.split('/').splice(-1).join();
          }
          return { uid, path: `${base}/${parent}?${search}${detail ? '#detail' : ''}` };
        }
        case 'reports': {
          uid = uid || pathname.split('/').splice(-1).join();
          let isFeatured = false;
          // Handle standups and featured reports
          if (uid === 'featured') {
            [uid] = pathname.split('/').splice(-2);
            isFeatured = true;
          }
          const search = `${qs.stringify(map({ ...query, skip: null, id: null }).filter(v => v).toJS())}`;
          return { uid, path: `${base}/${uid}${isFeatured ? '/featured' : ''}?${search}` };
        }
        case 'malware': {
          uid = uid || pathname.split('/').splice(-1).join();
          return { uid, path: `${base}/${uid}` };
        }
        case 'credentials': {
          uid = entity.get('credential_record_fpid') || query.credential_record_fpid;
          const search = `${qs.stringify(map({ ...query, skip: null, id: null }).filter(v => v).toJS())}`;
          return { uid, path: `${base}/${uid}::${entity.get('fpid')}?${search}` };
        }
        case 'customer-credentials': {
          return { uid, path: `${base}/${uid}::${entity.get('key')}` };
        }
        case 'media':
        case 'images':
        case 'videos': {
          uid = uid || pathname.split('/').splice(-1).join();
          const search = `${qs
            .stringify(map({
              fpid: Text.StripHighlight(entity.getIn(['media_v2', 0, 'fpid']) || entity.get('fpid') || ''),
              sha1: Text.StripHighlight(entity.getIn(['media_v2', 0, 'sha1']) || entity.get('sha1') || ''),
              media_type: Text.StripHighlight(entity.getIn(['media_v2', 0, 'media_type']) || entity?.slice(0, -1) || ''),
              type: Common.Basetypes.ExtractMainBasetype(fromJS({
                basetypes: entity.get('source_basetypes') || entity.get('basetypes') || '',
              })),
            })
            .filter(v => v)
            .toJS())}`;
          return { uid, path: `${base}/${uid}?${search}` };
        }
        case 'accounts':
        case 'marketplaces':
        case 'cards':
        case 'pastes':
        case 'cves':
        case 'exploits':
        case 'cves.exploits':
        case 'cves.vulnerabilities':
        default: {
          uid = uid || pathname.split('/').splice(-1).join();
          const search = `${qs.stringify(map({ ...query, skip: null, id: null }).filter(v => v).toJS())}`;
          return { uid, path: `${base}/${uid}?${search}` };
        }
      }
    },
    // TODO: make use of qs here
    Route: (path = '') => {
      if (!path) return;
      let queryParams = path.match(/\?.+/) ? path.match(/\?.+/)[0].substr(1) : '';
      let hash = '';
      const hashIndex = queryParams.indexOf('#');
      if (hashIndex !== -1) {
        hash = queryParams.substr(hashIndex, queryParams.length);
        queryParams = queryParams.substr(0, hashIndex);
      }
      const queryParamIndex = path.indexOf('?') !== -1 ? path.indexOf('?') : path.length;
      const pathname = path.substr(0, queryParamIndex);
      const query = !queryParams
        ? ''
        : qs.parse(queryParams);
      History.navigateTo({ pathname, query, hash });
    },
  },
  Events: {
    DispatchExternalLinkEvent: (link, event) => {
      if (!link) return false;
      // check to see if link is on the same host as the current app
      // if same host, don't need to trigger this
      let external = false;
      const path = (typeof link === 'string') ? link : link.pathname;
      // eslint-disable-next-line security/detect-unsafe-regex
      if (/(https?:)?\/\//.test(path || '')) {
        // check if on same host
        // eslint-disable-next-line security/detect-non-literal-regexp
        const host = new RegExp(`/${window.location.host}|flashpoint\.my\.site\.com|flashpoint\.io|fp\.tools/`);
        external = !host.test(path);
      }
      if (!external) return false;

      if (event) event.preventDefault();
      const target = (event) ? event.currentTarget : document;
      const externalEvent = new CustomEvent('onExternalLinkClick', {
        bubbles: true,
        detail: { to: path },
      });
      target.dispatchEvent(externalEvent);
      return true;
    },
    DispatchGlobalDialogEvent: (dialog, event) => {
      if (!dialog) return;
      if (event) event.preventDefault();
      const target = (event) ? event.currentTarget : document;
      const externalEvent = new CustomEvent('onGlobalDialogOpen', {
        bubbles: true,
        detail: { dialog },
      });
      target.dispatchEvent(externalEvent);
    },
  },
  RecentSearch: {
    Path: (search = map()) => {
      if (search.isEmpty()) return '';

      const pathname = search.getIn(['type', 'path', 0]) || search.getIn(['type', 'path']);
      const filters = search.get('filters') || '';
      const rel = /date=Last\+(.*?)[&$]/.test(filters) ? filters.match(/date=Last\+(.*?)[&$]/)[1].split('+') : null;
      const since = rel ? `&since=${moment.utc().subtract(rel[0], rel[1]).format()}` : '';
      const until = rel ? `&until=${moment.utc().format()}` : '';
      const value = list.isList(search.get('value')) ? search.get('value').join() : search.get('value');
      const query = search.get('filtered')
        ? queryFromFilterStringAndQuery(filters, value)
        : `?query=${value}`;
      const q = rel ? query.replace(/((since|until).*?)($|&)/g, '') : query;

      return [pathname, q, since, until].join('');
    },
    Activity: (search = map()) => {
      if (search.isEmpty()) return '';

      const pathname = search.getIn(['type', 'path', 0]) || search.getIn(['type', 'path']) || '/home/search/all';
      const filters = search.get('filters') || '';
      const value = list.isList(search.get('value')) ? search.get('value').join() : search.get('value');
      const query = search.get('filtered')
        ? queryFromFilterStringAndQuery(filters, value)
        : `?query=${value}`;

      return [pathname, query, '&', filters].join('');
    },
    Route: (search = map()) => {
      if (search.isEmpty()) return;
      const route = Common.RecentSearch.Path(search);
      History.navigateTo(route);
    },
    Filters: (search = map()) => {
      if (search.isEmpty()) return '';

      const query = search.get('value') || '';
      const filters = qs.parse(search.get('filters').slice(1));
      const normalized = removeEmptyProperties(filters);
      const filterLen = Object.keys(normalized).length;
      const text = `${search.getIn(['type', 'searchType'])} ${filterLen > 0 ? `+${filterLen}` : ''}`;

      return {
        text,
        filters: { ...normalized, query },
      };
    },
  },
  Request: {
    Translate: {
      AsApi: (path = '') => {
        const type = Common.Generic.PathToApp(path)?.searchType;
        const basetype = Common.Basetypes.SearchTypeToBasetype(type);
        switch (type) {
          case 'all':
          default: {
            const { query = '', since = '*', until = 'now' } = qs.parse(path.split('?')?.[1]);
            const filters = query ? `+(${query})` : '';
            const basetypes = basetype ? `+basetypes:(${basetype})` : '';
            const range = since ? `+sort_date:[${since} TO ${until}]` : '';
            return {
              query: `${basetypes} ${filters} ${range}`,
              traditional_query: true,
            };
          }
        }
      },
      AsCurl: (request, type = '') => {
        const url = `${window.location.origin}/api/v4`;
        const auth = `-H "Authorization: Bearer ${Token.cke()}"`;

        switch (type) {
          case 'reports': {
            const { query, body, remove_styles, tags, title, date, since, until } = request.toJS();
            const params = { query, body, remove_styles, tags, title, date, since, until };
            const opts = { addQueryPrefix: true, format: 'RFC1738' };
            return `curl -X GET "${url}/reports${qs.stringify(params, opts)}" ${auth}`;
          }
          case 'customer-credentials': {
            const ccmcType = window.location.pathname.split('customer-credentials/')[1];
            const endPoint = ccmcType === 'credentials-search' ? 'search' : 'analysis';
            const ccmcUrl = `${window.location.origin}/api/v4/credentials/${endPoint}?page_size=100&scroll=true`;
            return `curl -d '${JSON.stringify(request)}' -H "Content-Type: application/json" ${auth} -X POST ${ccmcUrl}`;
          }
          default:
            return `curl -d '${JSON.stringify(request)}' -H "Content-Type: application/json" ${auth} -X POST ${url}/all/search`;
        }
      },
      AsLink: (highlight = false) => {
        const url = window.location.origin;
        const { pathname, query, hash } = History.getCurrentLocation();
        const { id, limit, skip, sort } = query;
        const search = !highlight
          ? { id, limit, skip, sort, ...query }
          : { ...query, id };
        return `${url}${pathname}?${qs.stringify(search)}${hash}`;
      },
    },
  },
  SavedSearch: {
    Route: (search = map(), route = true, string = false) => {
      if (search.isEmpty()) return {};

      const hash = `#save:${search.get('created')}`;
      const pathname = search.getIn(['type', 'path', 0]) || search.getIn(['type', 'path']);
      const filters = search.get('filters') || '';
      let rel = null;
      if (/date=Last/.test(filters)) {
        const match = filters.match(/date=Last\+(.*?)[&$]/) || filters.match(/date=Last%20(.*?)[&$]/);
        rel = /date=Last\+/.test(filters) ? match[1].split('+') : match[1].split('%20');
      }
      const since = rel ? `&since=${moment.utc().subtract(rel[0], rel[1]).format()}` : '';
      const until = rel ? `&until=${moment.utc().format()}` : '';
      const value = list.isList(search.get('value') || '') ? search.get('value').join() : search.get('value') || '';
      const query = (search.get('filtered') || search.get('filters'))
        ? queryFromFilterStringAndQuery(filters, value)
        : `?query=${value}`;
      const q = rel ? query.replace(/((since|until).*?)($|&)/g, '') : query;
      if (string) {
        return [pathname, q, since, until, hash].join('');
      } else if (route) {
        History.navigateTo([pathname, q, since, until, hash].join(''));
      }
      return ({
        pathname,
        query: { ...qs.parse(q.slice(1) + since + until) },
        hash,
      });
    },
    Filters: (search = map()) => {
      if (search.isEmpty()) return '';

      const query = search.get('value') || '';
      const filters = qs.parse(search.get('filters').slice(1));
      const normalized = removeEmptyProperties(filters);
      const filterLen = Object.keys(normalized).length;
      const text = `${search.getIn(['type', 'searchType'])} ${filterLen > 0 ? `+${filterLen}` : ''}`;

      return {
        text,
        filters: { ...normalized, query },
      };
    },
  },
  Tagging: {
    // Tagging routes should go to the first post in the thread
    Route: (entity = map(), query = {}) => {
      const type = Common.Basetypes.BasetypeToSearchType(entity);
      const app = fromJS(Apps)
        .find(v => (v.get('aliases') || [])
         .some(a => a.includes(type)) || v.get('value').includes(`${type}.view`));
      const path = app ? app.getIn(['path', 0]) : '';
      switch (type) {
        case 'chats':
        case 'channels':
          return ({
            pathname: `${path}/${entity.getIn(['fpid'])}`,
            query: { ...query, skip: null, limit: null, fpid: null, id: null },
            hash: '#detail' });
        default:
          return {};
      }
    },
  },
  Generic: {
    PathToApp: (path = '') => {
      const target = list.isList(path) ? path.get(0) : path;
      if (!target || target.split('/').length < 3) return '';
      const value = target
        .split('/')
        .slice(3)
        .shift()
        .split('?')
        .shift();
      return Apps.find(a => a.value.includes(`search.${value}`)) || {};
    },
    FpidFromHistoryItem: (path = '') => {
      if (!path) return '';
      const lastSection = path.split('/')
        .splice(-1)
        .join();
      // check if fpid is included in url
      const matches = lastSection.match(/fpid=([^&]*)/);
      if (matches) {
        return matches[1];
      }
      return lastSection.replace(/[?&].*/, '');
    },
    // for all data, intelligence.latest
    // for edm only, domain dashboard
    Homepage: (prm = list()) => fromJS(Apps)
      .find(v => v.get('home') && prm.some(p => v.get('test').test(p)))
      || fromJS(Apps).find(v => v.get('value') === 'intelligence.finished'),
    Icon: (types = list()) => {
      const basetypes = list.isList(types) ? types.toJS() : types;
      if (basetypes.length === 0) return '';
      switch (true) {
        case basetypes.includes('acounts'):
          return 'account_balance';
        case basetypes.includes('blogs'):
          return 'web';
        case basetypes.includes('ransomware'):
          return 'lock';
        case basetypes.includes('boards'):
          return 'view_agenda';
        case basetypes.includes('cards'):
          return 'credit_card';
        case basetypes.includes('chats'):
        case basetypes.includes('chat'):
          return 'chat';
        case basetypes.includes('cves'):
        case basetypes.includes('exploits'):
        case basetypes.includes('cves.exploits'):
        case basetypes.includes('cves.vulnerabilities'):
          return 'bug_report';
        case basetypes.includes('image-enrichment'):
          return 'media';
        case basetypes.includes('communities'):
        case basetypes.includes('forums'):
        case basetypes.includes('forum'):
          return 'forums';
        case basetypes.includes('gab'):
        case basetypes.includes('reddit'):
          return 'chat_bubble';
        case basetypes.includes('marketplaces'):
        case basetypes.includes('market'):
        case basetypes.includes('generic-product'):
          return 'shopping_cart';
        case basetypes.includes('pastes'):
          return 'assignment';
        case basetypes.includes('reports'):
          return 'lightbulb_outline';
        case basetypes.includes('credentials'):
          return 'email';
        case basetypes.includes('twitter'):
          return Icons.twitter;
        default:
          return '';
      }
    },
    Route: (search = map(), query = {}) => {
      let type = Common.Basetypes.BasetypeToSearchType(search);
      if (type === 'code_repository') {
        return search.get('url');
      }

      type = (type) ? `${type}.view` : search.getIn(['type', 'value']);
      const app = fromJS(Apps)
        .find(v => (v.get('aliases') || [])
        .some(a => a.includes(type)) || v.get('value').includes(type));
      const path = app ? app.getIn(['path', 0]) : '';

      if (search.has('time')) return search.get('query'); // history
      if (search.has('path')) return search.get('path'); // bookmark
      if (search.has('pin')) return Common.SavedSearch.Route(search, false); // saved search
      if (!type || search.has('doc_count') || search.has('docCount')) return search; // not user generated

      switch (true) {
        case type.includes('search'):
          return ({
            pathname: path || '/home/search/communities',
            query: search.get('value')
              ? { ...qs.parse(search.get('filters').slice(1)), query: search.get('value') }
              : `${search.has('query') ? search.getIn(['query']).delete('aggregations').delete('limit').toJS() : ''}`,
            hash: null,
          });
        case type.includes('reports'):
          return ({
            pathname: `${path}/${search.get('fpid')}`,
            query: { ...query },
            hash: '' });
        case type.includes('view'):
        default: {
          const basetype = type.split('.')[0];
          switch (basetype) {
            case 'blogs':
              return ({
                pathname: `${path}/${search.getIn(['fpid'])}`,
                query: { ...query, type: null, skip: null, limit: null, fpid: search.get('fpid'), id: moment.utc(search.getIn(['sort_date'])).unix() } });
            case 'ransomware':
              return ({
                pathname: `${path.replace(/posts/ig, 'items')}/${search.getIn(['fpid'])}`,
                query: { ...query, type: null, skip: null, limit: null, fpid: search.get('fpid'), id: moment.utc(search.getIn(['sort_date'])).unix() } });
            case 'forums':
            case 'post':
              return ({
                pathname: `${path}/${search.getIn(['message_count', 'last_resource', 'container', 'fpid']) || search.getIn(['container', 'fpid'])}`,
                query: {
                  ...query,
                  ...(['en'].includes(query?.query_i18n) &&
                    (search.getIn(['body', 'text/plain_native_language']) ||
                      search.getIn(['container', 'title_native_language']))
                    ? { translate: true }
                    : {}),
                  skip: null,
                  limit: null,
                  fpid: search.getIn(['message_count', 'last_resource', 'fpid']) || search.get('fpid'), id: moment.utc(search.getIn(['sort_date'])).unix(),
                },
                hash: '#detail' });
            case 'chats':
            case 'channels':
              return ({
                pathname: `${path}/${search.getIn(['container', 'fpid'])}`,
                query: { ...query, skip: null, limit: null, fpid: search.get('fpid'), site: search.getIn(['site', 'title']), id: moment.utc(search.getIn(['sort_date'])).unix() },
                hash: '#detail',
              });
            case 'media':
            case 'images':
            case 'videos': {
              return ({
                pathname: `/home/assets/media/items/${search.get('fpid')}`,
                query: {
                  fpid: Text.StripHighlight(search.getIn(['media_v2', 0, 'fpid']) ?? search.get('fpid') ?? ''),
                  sha1: Text.StripHighlight(search.getIn(['media_v2', 0, 'sha1']) ?? search.get('sha1') ?? ''),
                  media_type: Text.StripHighlight(search.getIn(['media_v2', 0, 'media_type']) ?? basetype?.slice(0, -1) ?? ''),
                  type: Common.Basetypes.ExtractMainBasetype(fromJS({
                    basetypes: search.get('source_basetypes') ?? search.get('basetypes') ?? '',
                  })),
                },
              });
            }
            case 'boards':
            case 'board':
              return ({
                pathname: `${path}/${search.getIn(['container', 'fpid'])}`,
                query: { ...query, skip: null, limit: null, fpid: search.get('fpid'), id: moment.utc(search.getIn(['sort_date'])).unix() },
                hash: '#detail' });
            case 'cves':
            case 'exploits':
            case 'cves.vulnerabilities':
            case 'cves.exploits':
              return ({
                pathname: `${path.replace(/cves/ig, search.get('basetypes', []).includes('exploit') ? 'exploits' : 'cves')}/${search.get('fpid')}`,
                query: {
                  ...query,
                  skip: null,
                  limit: null,
                  date: null,
                  since: null,
                  until: null,
                  cve_severity: null,
                  cve_product_names: null,
                  cve_title: search.get('title'),
                },
                hash: null });
            case 'gab':
              return ({
                pathname: `${path}/${search.getIn(['parent_message', 'fpid']) || search.get('fpid')}`,
                query: { ...query, skip: null, limit: null, fpid: search.get('fpid'), id: moment.utc(search.getIn(['sort_date'])).unix() },
                hash: null });
            case 'credentials': {
              return ({
                pathname: `${path}/${search.get('credential_record_fpid')}::${search.get('fpid')}`,
                query: { },
                hash: null });
            }
            case 'twitter': {
              const fpid = search.getIn(['fpid']);
              const author = Text.StripHighlight(search.getIn(['site_actor', 'names', 'handle']));
              const nativeId = search.getIn(['native_id']);
              return ({
                pathname: `${path}/${fpid}/${author}/${nativeId}`,
                query: { ...query, skip: null, limit: null, group: null, id: null },
                hash: null });
            }
            case 'iocs':
              return ({
                pathname: `${path}/${search.get('fpid')}`,
                query: { skip: null, limit: null, id: null },
                hash: null });
            case 'marketplaces':
              return ({
                pathname: `${path}/${search.get('fpid')}`,
                query: { ...query, skip: null, limit: null, group: null, id: null },
                hash: '' });
            case 'cards':
            case 'pastes':
            case 'reports':
            default:
              return ({
                pathname: `${path}/${search.get('fpid')}`,
                query: { ...query, skip: null, limit: null, group: null, id: null },
                hash: null });
          }
        }
      }
    },
    SearchRoute: (record = map(), query = {}) => {
      const type = Common.Basetypes.BasetypeToSearchType(record);
      const app = fromJS(Apps).find(v => v.get('searchType') === type);
      const searchPath = app.getIn(['path', 0]);
      return {
        pathname: searchPath,
        query,
        hash: null,
      };
    },
  },
};

export default Common;
