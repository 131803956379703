import * as React from 'react';

export const LineGraphIcon = () => (
  <svg width="189" height="100" viewBox="0 0 189 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1377_11873)">
      <rect x="7" y="7" width="171" height="82" rx="4" fill="white" />
    </g>
    <path d="M13 75.7143L27.5109 59.3213C33.9999 51.9907 44.6933 50.1165 53.2886 54.8032L55.5959 56.0613C63.9586 60.6212 74.4198 58.0266 79.6823 50.0872L80.1942 49.3149C87.386 38.465 103.419 38.7772 110.183 49.899L113.481 55.3224C119.043 64.4682 132.385 64.2702 137.674 54.9633V54.9633C141.059 49.0056 148.252 46.3636 154.688 48.7134L173 55.3984" stroke="#186AA6" strokeWidth="4" />
    <path d="M27.5109 59.3213L13 75.7143V83H173V55.3984L154.688 48.7134C148.252 46.3636 141.059 49.0056 137.674 54.9633C132.385 64.2701 119.043 64.4682 113.481 55.3224L110.183 49.899C103.419 38.7772 87.386 38.465 80.1942 49.3149L79.6823 50.0872C74.4198 58.0266 63.9586 60.6212 55.5959 56.0613L53.2886 54.8032C44.6933 50.1165 33.9999 51.9907 27.5109 59.3213Z" fill="#F5FBFF" />
    <defs>
      <filter id="filter0_d_1377_11873" x="0" y="0" width="189" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1377_11873" />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="3.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1377_11873" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1377_11873" result="shape" />
      </filter>
    </defs>
  </svg>
);
