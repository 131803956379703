import React from 'react';
import Api from '../../../../utils/api';

const SESSION_STORAGE_KEY = 'fp-dm-mu-filters';

const dev = '_self' in React.createElement('div') ? '/dev' : '';

const getAllUrls = filter => Api.get(`/ui/v4/domain-monitoring${dev}/monitoredurls?text=${filter || ''}`).then(request => request.data);

const getAllBrands = () => Api.get(`/ui/v4/domain-monitoring${dev}/brands`).then(request => request.data);

const getSessionFilters = () => {
  const store = window.sessionStorage;
  if (!store) return null;
  return JSON.parse(store.getItem(SESSION_STORAGE_KEY));
};

const setSessionFilters = (filters) => {
  const store = window.sessionStorage;
  if (!store) return;
  store.setItem(SESSION_STORAGE_KEY, JSON.stringify(filters));
};

export {
  getAllBrands,
  getSessionFilters,
  setSessionFilters,
  getAllUrls,
};
