import Text from '../../utils/text';

// Allows multiple bins
// eslint-disable-next-line security/detect-unsafe-regex
export const validBinRegex = /^(\s?(\d{6}\*?|\d{2,6}\*?\s?\|?))+$/gm;

export const validateKeywordValue = (keyword) => {
  let error = '';
  const value = keyword.get('value');
  let hasExceeded = false;
  let isMisbalanced = false;
  let invalidBin = false;
  let isRequired = false;
  if (value.length === 0) isRequired = true;
  if (value.length > 300) hasExceeded = true;
  isMisbalanced = Text.BalancedString(value) !== true;
  if (keyword.getIn(['category', 'label'], '').toLowerCase() === 'bin') {
    invalidBin = !value.match(validBinRegex);
  }
  if (hasExceeded) error = 'Keyword exceeds the maximum length of 300 characters';
  if (isMisbalanced) error = 'Keyword has mismatched quotes';
  if (invalidBin) error = 'Keyword is not a valid bin format';
  if (isRequired) error = 'Keyword is required';
  return { key: keyword.get('key'), error };
};
