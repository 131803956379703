import { Grid } from './Grid';
import { Typography } from './Typography';
import { Breakpoints } from './Breakpoints';
import { blue, darkBlue, green, red, orange, white } from './colors';

export const Theme = {
  breakpoints: Breakpoints,
  grid: Grid,
  typography: Typography,
  palette: {
    primary: {
      cta: {
        light: blue[400],
        main: blue[500],
        dark: blue[900],
      },
      text: {
        light: darkBlue[600],
        main: darkBlue[900],
        dark: '#000016',
      },
      success: green[700],
      warning: orange[900],
      error: red[800],
    },
    secondary: {
      text: {
        light: darkBlue[500],
        main: darkBlue[600],
        dark: darkBlue[800],
      },
      white,
      canvas: darkBlue[100],
      dark: darkBlue[200],
    },
  },
};
