import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, fromJS } from 'immutable';
import AdapterMoment from '@mui/lab/AdapterMoment';
import KeyboardDateTimePicker from '@mui/lab/DateTimePicker';
import MuiPickersUtilsProvider from '@mui/lab/LocalizationProvider';
import {
  Button,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  TextField,
} from '@mui/material';

import style from './datefilter.module.scss';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';
import Messages from '../../constants/Messages';
import SearchUtils from '../../utils/searchUtils';
import { Track } from '../../utils/track';

const useStyles = makeStyles(theme => ({
  datefilter: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    '& .custom': {
      borderTop: '1px solid #e6e7e8',
    },
    '& .custom:not(.inline)': {
      padding: '1rem 4.8rem',
      width: '30rem',
    },
  },
}));

const DateFilter = ({
  advanced,
  date,
  dateKey,
  dates,
  disableCustom,
  inline,
  onApply,
  onFilter,
  since,
  sinceKey,
  toggle,
  type,
  until,
  untilKey,
}) => {
  const classes = useStyles();
  const [selection, setSelection] = useState({});

  const format = 'MM/DD/YYYY hh:mm';
  const timepickerFormat = 'MM/DD/YYYY HH:mm';

  const onSearch = (target) => {
    const dateFilters = dateKey
      ? { [dateKey]: target.date, [sinceKey]: target.since, [untilKey]: target.until }
      : target;
    const { sinceDate, untilDate } = SearchUtils.parseDate(dateFilters);

    if (moment.utc(sinceDate).isAfter(moment.utc(untilDate))) {
      SearchActions.set(['search', 'info', 'message'], Messages.DateSinceError);
    } else if (/Invalid/.test(target.date)) {
      SearchActions.set(['search', 'info', 'message'], Messages.DateToFromError);
    } else if (onApply) {
      onApply(fromJS(dateFilters));
      toggle();
    } else if (onFilter) {
      onFilter(fromJS(dateFilters));
      toggle();
    } else {
      setSelection(dateFilters);
      toggle();
      const { pathname, query, hash } = History.getCurrentLocation();

      const event = {
        type,
        filters: { ...query, ...dateFilters },
        origin: '.menu .datefilter',
      };

      Track(event, 'Search');

      History.push({
        pathname,
        query: { ...query, ...dateFilters, interval: undefined, skip: undefined },
        hash,
      });
    }
  };

  useEffect(() => {
    if (!inline) return;
    onFilter?.(fromJS(selection));
  }, [selection]);

  useEffect(() => {
    moment.tz.setDefault('UTC');
    const { sinceDate, untilDate } = SearchUtils.parseDate({ date, since, until });
    setSelection({ date, since, until, sinceDate, untilDate });
  }, [date]);

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.datefilter,
        classes.datefilter,
        (disableCustom || inline) && style.inline,
      ])}>
      {!inline &&
      <Row>
        <Col xs={12}>
          <List>
            {dates.map(v => (
              <ListItem
                key={v.get('date')}
                onClick={() => onSearch(v.toJS())}>
                <ListItemIcon>
                  <Icon>{`${date === v.get('date') ? 'done' : ''}`}</Icon>
                </ListItemIcon>
                <Typography>
                  {v.get('date')}
                </Typography>
              </ListItem>))}
          </List>
        </Col>
      </Row>}
      {(!disableCustom || inline) &&
      <Row>
        <Col
          xs={12}
          className={cx([
            'custom',
            inline && 'inline',
          ])}>
          <MuiPickersUtilsProvider dateAdapter={AdapterMoment}>
            <KeyboardDateTimePicker
              renderInput={myParams => <TextField {...myParams} />}
              autoOk
              ampm={false}
              disableFuture
              inputFormat={timepickerFormat}
              variant="inline"
              inputVariant="outlined"
              animateYearScrolling={false}
              allowKeyboardControl
              label="Start date"
              value={!/all/ig.test(selection.date)
                ? moment.utc(selection.sinceDate)
                : moment.utc()}
              onChange={v => setSelection(state => ({
                ...state,
                date: `${moment.utc(v, format).format()} - ${moment.utc(state.untilDate).format()}`,
                since: moment.utc(v, format).format(),
                sinceDate: moment.utc(v, format).format(),
                until: moment.utc(state.untilDate).format(),
                untilDate: moment.utc(state.untilDate).format(),
              }))} />
            <KeyboardDateTimePicker
              renderInput={myParams => <TextField {...myParams} />}
              autoOk
              ampm={false}
              disableFuture
              inputFormat={timepickerFormat}
              variant="inline"
              inputVariant="outlined"
              animateYearScrolling={false}
              allowKeyboardControl
              label="End date"
              value={!/all/ig.test(selection.date)
                ? moment.utc(selection.untilDate)
                : moment.utc()}
              onChange={v => setSelection(state => ({
                ...state,
                date: `${moment.utc(state.sinceDate).format()} - ${moment.utc(v, format).format()}`,
                since: moment.utc(state.sinceDate).format(),
                sinceDate: moment.utc(state.sinceDate).format(),
                until: moment.utc(v, format).format(),
                untilDate: moment.utc(v, format).format(),
              }))} />
          </MuiPickersUtilsProvider>
        </Col>
      </Row>}
      {!disableCustom && !inline &&
      <Row>
        <Col xs={12} className={advanced ? style.advanced : style.footer}>
          <div className={style.error} />
          <div className={style.actions}>
            <Button
              name="sitesfilter.cancel"
              onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              className={style.active}
              name="sitesfilter.apply"
              onClick={() => onSearch(selection)}>
              Apply
            </Button>
          </div>
        </Col>
      </Row>}
      <ReactTooltip id="date.tooltip" html place="left" effect="solid" />
    </Grid>
  );
};

DateFilter.propTypes = {
  advanced: PropTypes.bool,
  date: PropTypes.string,
  dateKey: PropTypes.string,
  dates: PropTypes.object,
  disableCustom: PropTypes.bool,
  inline: PropTypes.bool,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  since: PropTypes.string,
  sinceKey: PropTypes.string,
  toggle: PropTypes.func,
  type: PropTypes.string,
  until: PropTypes.string,
  untilKey: PropTypes.string,
};

DateFilter.defaultProps = {
  advanced: false,
  date: 'All Time',
  dateKey: '',
  dates: list(),
  disableCustom: false,
  inline: false,
  onApply: null,
  onFilter: null,
  since: '*',
  sinceKey: '',
  toggle: null,
  type: '',
  until: 'now',
  untilKey: '',
};

export default DateFilter;
