import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '10rem auto 0 auto',
    maxWidth: '766px',
    width: '100%',
  },
  body: {
    fontSize: '1.8rem',
    fontStyle: 'italic',
    marginBottom: '2.4rem',
    textAlign: 'center',
  },
  button: {
    padding: '.75rem 1.5rem',
    textTransform: 'uppercase',
  },
  icon: {
    color: green[500],
    fontSize: '3.3rem',
    marginBottom: '2.4rem',
  },
  title: {
    fontSize: '2.4rem',
    marginBottom: '2.4rem',
  },
}));

const RfiConfirmation = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CheckCircleOutlineIcon className={classes.icon} />
      <Typography className={classes.title} variant="h3">We've received your request!</Typography>
      <Typography className={classes.body} variant="body1">Thank you for submitting a request for information. We are currently reviewing your request internally with our analyst team and will respond shortly.</Typography>
      <Typography className={classes.body} variant="body1">If this request is urgent and requires immediate assistance, please contact your Customer Success representative directly.</Typography>
      { onSubmit && <Button className={classes.button} onClick={onSubmit} variant="contained" color="secondary">Submit another request</Button> }
    </div>
  );
};

RfiConfirmation.propTypes = {
  onSubmit: PropTypes.func,
};

RfiConfirmation.defaultProps = {
  onSubmit: null,
};

export default RfiConfirmation;
