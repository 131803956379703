import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';

import style from './thread.module.scss';
import Text from '../../../utils/text';
import History from '../../../utils/history';
import Common from '../../../utils/common';
import Hooks from '../../../utils/hooks';
import Post from '../Post/Post';
import Infinite from '../../utils/Infinite/Infinite';
import Placeholder from '../../utils/Placeholder';
import InternalLink from '../../utils/InternalLink';
import SearchActions from '../../../actions/searchActions';
import { Slide } from '../../search/Table/Slide/Slide';
import { Tag } from '../Tag';

const dedupeByFPID = myArr => fromJS([...new Map(myArr.map(m => [m.get('fpid'), m])).values()]);

const Thread = ({
  compact,
  container,
  data,
  location,
  meta,
  search,
  site,
  title,
}) => {
  const [dialog, setDialog] = useState();
  const [images, setImages] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!!location.hash);
  const [loaded, setLoaded] = useState(list());
  const [loading, setLoading] = useState(true);
  const [carousel, setCarousel] = useState(-1);
  const [swap, setSwap] = useState(false);
  const { query } = History.getCurrentLocation();

  const setOffset = (direction) => {
    if (!container || !loaded) return;
    const offset = (direction === 'next')
      ? moment.utc(loaded.last().getIn(['sort_date'])).unix()
      : moment.utc(loaded.first().getIn(['sort_date'])).unix();
    const update = container.merge({ offset });
    const basetypes = loaded.first().getIn(['basetypes']);
    SearchActions.set(['search', 'result', basetypes.last()], update);
  };

  const checkFirst = (record) => {
    if (!loaded || loaded.isEmpty() || !query.id) return record;
    const showingFpids = loaded.map(v => v.get('fpid'));
    if (!showingFpids.includes(meta.get('post_fpid'))) return record;
    if (record.first().get('fpid') === meta.get('post_fpid') || record.first().getIn(['container_position', 'index_number']) > 2) {
      return record;
    }
    const type = Common.Basetypes.BasetypeToSearchType(record);
    const firstIndex = record.findIndex(v => v.getIn(['site_actor', 'fpid']) === meta.getIn(['site_actor', 'fpid']));
    const updatedIds = record.map(v => (record.indexOf(v) < firstIndex ? v.setIn(['container_position', 'index_number'],
      v.getIn(['container_position', 'index_number']) + 1) : v));
    const oldItem = record.get(firstIndex).setIn(['container_position', 'index_number'], 2);
    const fixedData = updatedIds.delete(firstIndex).insert(0, oldItem);
    SearchActions.set(['search', 'result', type, 'data'], fixedData);
    setSwap(true);
    return fixedData;
  };

  const load = (direction) => {
    const first = moment.utc(data.first().getIn(['sort_date'])).unix();
    const last = moment.utc(data.last().getIn(['sort_date'])).unix();
    const update = container.merge({ offset: direction === 'prev' ? first : last });
    SearchActions.searchThread(update, direction);
  };

  const onMediaClick = (src, entry) => {
    if (src === -1) {
      setImages([]);
      setCarousel(-1);
    } else {
      const values = entry.toJS();
      setImages([{
        ...values?.media,
        fpid: values?.post?.fpid,
        site: values?.post?.site,
        container: values?.post?.container,
        media_fpid: values?.media?.fpid,
        media_sha1: values?.media?.sha1,
        media_type: values?.media?.media_type,
        basetype: Common.Basetypes.ExtractMainBasetype(fromJS({
          basetypes: values?.post?.basetypes,
        })),
        basetypes: values?.post?.basetypes,
        author: values?.post?.site_actor?.names?.handle,
        room: values?.post?.container?.title || values?.post?.container?.container?.title,
        sort_date: values?.post?.sort_date,
        body: values?.post?.body?.['text/plain'],
        source: src,
        analysis: values?.media?.[`${values?.media?.media_type}_enrichment`]?.enrichments?.v1[`${values?.media?.media_type}-analysis`],
      }]);
      setCarousel(0);
    }
  };

  const onAction = (event, key = '', context = map()) => {
    switch (key) {
      case 'tag': {
        const type = Common.Basetypes.ExtractMainBasetype(context);
        const component = <Tag
          type={type}
          post={context.toJS()}
          onCancel={() => setDialog()} />;
        setDialog({
          key,
          component,
          text: 'Tag selected post',
          target: event.target,
        });
        break;
      }
      default: break;
    }
  };

  const onSearch = (basetypes = list(), k, v) => {
    const type = basetypes.slice(-2).first();
    const pathname = `/home/search/${type}`;
    const urlQuery = { [k]: Text.Strip(v), query: '' };
    return ({ pathname, query: urlQuery });
  };

  const changeSidebarStyle = () => {
    setIsSidebarOpen(!!location.hash);
  };

  const setLoadedDedupe = myData => setLoaded(dedupeByFPID(myData));

  useEffect(() => {
    const bounds = container.getIn(['bounds']) || map();
    setSwap(false);
    if (data.isEmpty()) setLoading(true);
    if (!bounds.hasIn(['start'])) SearchActions.searchThread(container);
    if (loading && bounds.has('start')) setLoading(false);
  }, [container, data]);

  useEffect(() => {
    if (!data.isEmpty() && !loaded.isEmpty()) setLoadedDedupe(data);
  }, [data]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loaded]);

  Hooks.useEventListener('transitionstart', changeSidebarStyle, document.querySelector('[class*="sidebar"]'));

  const CarouselFooter = (args) => {
    const slide = images?.[args?.currentIndex];
    return args.isModal &&
    <>
      <Slide data={slide} type={slide?.media_type} />
      <div {...args.innerProps} className="caption">
        <InternalLink
          to={{
            pathname: `/home/assets/media/items/${slide?.fpid}`,
            query: {
              media_type: slide?.media_type,
              type: slide?.basetype,
              fpid: Text.StripHighlight(slide?.media_fpid),
              sha1: Text.StripHighlight(slide?.media_sha1),
            },
          }}
          className={cx([style.a, 'a'])}>
          <Button
            color="secondary"
            onClick={() => setCarousel(-1)}>See Media Details
          </Button>
        </InternalLink>
      </div>
    </>;
  };

  return (
    <Grid
      fluid
      className={cx([
        style.thread,
        isSidebarOpen && style.pull,
        compact && style.compact])}>
      <Row>
        <Col xs={12}>
          {loading &&
          <Placeholder height={135} width={800} total={10} />}
          {data.isEmpty() && !loading &&
            <div className={style.empty} ><p>There is no data associated with this thread</p></div>}
          {!data.isEmpty() &&
          <div style={{ opacity: +!loading }}>
            <div className={style.header}>
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                {Text.Highlight(site)}
              </div>
              <div className={style.title}>
                <Icon className={style.icon}>forums</Icon>
                <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                  {Text.Highlight(Text.Post(title))}
                </div>
              </div>
              <div className={style.title}>
                <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                  Created by
                </div>
                <InternalLink
                  key="username"
                  to={onSearch(meta.getIn(['basetypes']), 'author',
                    `${meta.getIn(['site_actor', 'fpid'])}::${meta.getIn(['site_actor', 'names', 'handle'])}`)}
                  className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal', style.a, 'a'])}>
                  &nbsp;{meta.getIn(['site_actor', 'names', 'handle']) ||
                    meta.getIn(['site_actor', 'names', 'username']) ||
                    meta.getIn(['native_id']) || '-'}
                </InternalLink>
              </div>
            </div>
            <Infinite
              active={query.fpid}
              data={(!swap && checkFirst(data)) || list()}
              maxRecords={compact ? 1000 : 50}
              headers={compact ? fromJS([{ label: 'Observed On', style: { paddingLeft: '10px', minWidth: '200px' } }, { label: 'User', style: { padding: '0 10px', minWidth: '168px' } }, { label: 'Message', style: { padding: '0 10px', width: '100%' } }, { label: 'Actions', style: { minWidth: '130px', paddingLeft: '10px' } }]) : list()}
              setLoaded={setLoadedDedupe}
              requestCount={compact ? 100 : 25}
              setOffset={setOffset}
              offset={!loading ? container.getIn(['offset']) : ''}
              hasPrev={!loading && !container.getIn(['bounds', 'start'])}
              hasNext={!loading && !container.getIn(['bounds', 'end'])}
              loadNext={() => load('next')}
              loadPrev={() => load('prev')}>
              {loaded.map(v => (
                <Post
                  compact={compact}
                  translate
                  post={v}
                  search={search}
                  key={v.get('fpid')}
                  index={false}
                  active={query.native_id
                    ? v.get('native_id') === query.native_id
                    : (query.fpid) && (v.get('fpid') === query.fpid || v.get('legacy_fpid') === query.fpid)}
                  author={Text.Strip(v.getIn(['site_actor', 'fpid'])) === meta.getIn(['site_actor', 'fpid'])}
                  onMediaClick={onMediaClick}
                  onAction={onAction} />))}
            </Infinite>
          </div>}
        </Col>
      </Row>
      <Dialog open={Boolean(dialog && dialog?.key)}>
        <DialogTitle>{dialog?.text}</DialogTitle>
        <DialogContent>
          {dialog?.component}
        </DialogContent>
      </Dialog>
      <ModalGateway>
        {Boolean(images?.length && carousel > -1) &&
        <Modal onClose={() => setCarousel(-1)}>
          <Carousel
            styles={{
              view: base => ({
                ...base,
                '& img': { opacity: images?.[Number(carousel)]?.media_type === 'video' ? 0 : 1 },
              }),
            }}
            views={images}
            currentIndex={carousel}
            hideControlsWhenIdle={false}
            components={{
              FooterCaption: CarouselFooter,
            }} />
        </Modal>}
      </ModalGateway>
    </Grid>);
};

Thread.propTypes = {
  location: PropTypes.object,
  compact: PropTypes.bool,
  container: PropTypes.object,
  data: PropTypes.object,
  meta: PropTypes.object,
  search: PropTypes.object,
  site: PropTypes.string,
  title: PropTypes.string,
};

Thread.defaultProps = {
  location: {},
  compact: false,
  container: map(),
  data: list(),
  meta: map(),
  search: map(),
  site: '',
  title: '',
};

export default Thread;
