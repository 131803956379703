import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { grey, red } from '@mui/material/colors';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import MessageIcon from './MessageIcon';
import Query from '../query';
import Messages from '../../../../constants/Messages';
import ProgressBar from '../../../search/ProgressBar';
import SearchActions from '../../../../actions/searchActions';

const useStyles = makeStyles({
  messageHeader: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1rem',
  },
  message: {
    borderBottom: '1px #E4E4E4 solid',
    display: 'block',
    marginBottom: '2rem',
    maxWidth: 'inherit',
    overflowWrap: 'break-word',
    paddingBottom: '2rem',
  },
  messageAttachment: {
    backgroundColor: grey[100],
    color: '#5c6ae0',
    fontFamily: '"Open Sans", sans-serif',
    padding: '1rem 2rem',
    whiteSpace: 'pre-wrap',
  },
  messageProgress: {
    '& .MuiLinearProgress-root': {
      backgroundColor: '#fff',
      border: `1px ${grey[500]} solid`,
      height: '2rem',
    },
    '& .MuiLinearProgress-root .MuiLinearProgress-bar': {
      border: '1px #fff solid',
    },
  },
  messageAuthor: {
    fontWeight: '700',
  },
  messageText: {
    fontFamily: '"Open Sans", sans-serif',
    whiteSpace: 'pre-wrap',
  },
  messageErrorText: {
    color: red[500],
    fontSize: '1.4rem',
    textAlign: 'right',
    width: '100%',
  },
  messageDate: {
    fontSize: '1.4rem',
  },
  messageIcon: {
    alignItems: 'center',
    backgroundColor: '#5c6ae0',
    borderRadius: '50px',
    color: '#fff',
    display: 'flex',
    fontSize: '2.4rem',
    fontWeight: '700',
    height: '50px',
    justifyContent: 'center',
    marginRight: '1rem',
    textTransform: 'uppercase',
    width: '50px',
  },
  row: {
    margin: '2rem 0',
  },
});

const Message = ({ item, scrollRef }) => {
  const [error, setError] = useState('');
  const [requests, setRequests] = useState(0);
  const [retry, setRetry] = useState(0);

  const classes = useStyles();

  const retryUpload = () => {
    setRetry(retry + 1);
  };

  const displayPostError = (errorText = 'Message not delivered') => {
    SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(errorText));
    setError(<>{errorText} <Link onClick={retryUpload}>retry</Link></>);
  };

  useEffect(() => {
    const _postMessage = (id, message) => {
      Query.postMessage(id, {
        incident_id: id,
        content: message.get('content'),
      })
        .then((res) => {
          if (!res.ok) {
            displayPostError();
          }
        })
        .catch(() => displayPostError());
    };

    const _postFile = (id, file) => {
      const formData = new FormData();
      formData.append('attachment', file);
      Query.uploadFile(id, formData)
        .then((request) => {
          if (!request.ok) {
            displayPostError('Unable to deliver attachment');
          }
          setRequests(0);
        })
        .catch(() => {
          displayPostError('Unable to deliver attachment');
          setRequests(0);
        });
    };

    if (!item.get('new_post')) {
      return;
    }

    if (item.get('file_upload')) {
      /* It's a file upload */
      setRequests(1);
      _postFile(item.get('incident_id'), item.get('file'));
    } else {
      /* It's just text */
      _postMessage(item.get('incident_id'), item);
    }

    /* Scroll to bottom of page so form stays in view */
    scrollRef.current.scrollIntoView(false);
  }, [retry]);

  return (
    <div
      className={classes.message}
      key={item.get('id')}>
      <div className={classes.messageHeader}>
        <MessageIcon
          type={item.get('author') ? item.get('author') : 'Anonymous'}
          letter={item.get('author') ? item.get('author')[0] : 'A'}
        />
        <div>
          <div className={classes.messageAuthor}>{item.get('author') ? item.get('author') : 'Anonymous'}</div>
          <div className={classes.messageDate}>{moment(item.get('created_at')).calendar()}</div>
        </div>
      </div>
      {
        requests > 0
        ? <div className={classes.messageProgress}><ProgressBar remaining={requests} /></div>
        : <div><pre className={item.get('file_upload') ? classes.messageAttachment : classes.messageText}>{item.get('content')}</pre></div>
      }
      <div className={classes.messageErrorText}>{error}</div>
    </div>
  );
};

Message.propTypes = {
  item: PropTypes.object,
  scrollRef: PropTypes.object,
};

Message.defaultProps = {
  item: {},
  scrollRef: null,
};

export default Message;
