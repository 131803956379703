import * as qs from 'qs';
import { fromJS } from 'immutable';
import { SavedSearch } from '../core/types/SavedSearch';
import { ExpoDashFilters } from '../core/types/ExpoDashFilters';

export const assignFiltersToFilterString = (filterStr:string, filters:object) => {
  if (!filters) return filterStr;
  const searchParams = new URLSearchParams(filterStr);
  Object.entries(filters).forEach(([k, v]:[string, string]) => {
    if (k) {
      if (v) {
        searchParams.set(k, v);
      } else {
        searchParams.delete(k);
      }
    }
  });
  return searchParams.toString();
};

export const assignFiltersToSavedSearch = (mySavedSearch:SavedSearch, filters:object) => (
  fromJS(mySavedSearch).setIn(
    ['filters'],
    assignFiltersToFilterString(mySavedSearch.filters, filters),
  ).toJS()
);

export const makeFiltersFromSavedSearch = (savedSearch:SavedSearch):ExpoDashFilters => (
  {
    ...qs.parse(savedSearch.filters, { ignoreQueryPrefix: true }),
    ...{ query: savedSearch.value },
  }
);

export const defaultFilterString = '?date=Last+7+Days&since=now-7d&until=now&query_i18n=en';
export const defaultFilterObj:ExpoDashFilters =
  Object.fromEntries(new URLSearchParams(defaultFilterString));
