import Api from '../../../../utils/api';

const ALERTING_URI = '/ui/v4/alerts/keywords';

const readAllKeywords = (ownerId, keywordClasses) => {
  const requests = keywordClasses.map((kw) => {
    const query = {
      owner_id: ownerId,
      keyclass_id: kw.id,
    };
    return Api.get(ALERTING_URI, query);
  });
  return Promise.all(requests)
    .then((res) => {
      const data = [];
      res.forEach(v => data.push(...v.data));
      return data;
    })
    .then(data => data.sort((a, b) => {
      const left = new Date(a.created_timestamp);
      const right = new Date(b.created_timestamp);
      return right - left;
    }));
};

const createKeyword = (ownerId, keywordClass, name, value) => (
  Api.post(ALERTING_URI, {
    active: true,
    is_curated: false,
    keyclass_id: keywordClass,
    name,
    value,
    owner_id: ownerId,
  },
  [200, 201, 400])
);

const updateKeyword = (keywordClass, name, value, id) => (
  Api.put(`${ALERTING_URI}?keyword_id=${id}`, {
    active: true,
    is_curated: false,
    keyclass_id: keywordClass,
    name,
    value,
  },
  [200, 201, 204])
);

const deleteKeyword = (ownerId, keywordId) => {
  const query = {
    owner_id: ownerId,
    keyword_id: keywordId,
  };
  return Api.delete(`${ALERTING_URI}`, query, [200, 201, 204]);
};

export {
  readAllKeywords,
  createKeyword,
  updateKeyword,
  deleteKeyword,
};
