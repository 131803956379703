import * as React from 'react';

import { Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useMenuButtonStyles = makeStyles(() => ({
  menuHeader: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: '8px',
  },
}));

interface MenuButtonProps {
  buttonText: string;
  isOpen: boolean;
  alwaysOpen?: boolean;
}

export const MenuButton = ({ buttonText, isOpen, alwaysOpen }: MenuButtonProps) => {
  const classes = useMenuButtonStyles();
  const keyboardArrowIcon = isOpen ?
    <Icon>keyboard_arrow_up</Icon> :
    <Icon>keyboard_arrow_down</Icon>;
  return (
    <div className={classes.menuHeader}>
      <span style={{ marginRight: '15px' }}>
        {buttonText}
      </span>
      {alwaysOpen ? null : keyboardArrowIcon}
    </div>
); };

MenuButton.defaultProps = {
  alwaysOpen: false,
};
