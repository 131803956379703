import React, { useState } from 'react';
import { CircularProgress, Paper, TextareaAutosize } from '@mui/material';

import style from './sanctions.module.scss';
import Api from '../../utils/api';
import Token from '../../utils/token';
import Messages from '../../constants/Messages';
import SanctionsSearchResults from './SanctionsSearchResults';
import SearchActions from '../../actions/searchActions';

const Sanctions = (() => {
  const [data, setData] = useState();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (search === '' || loading) {
      return;
    }
    const query = { search_strings: search.replaceAll('\n', ',') };
    setLoading(true);
    Api.get('/ui/v4/sanctions/search', query, [200], 60000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        setData({
            results: res.results.resultList,
            availableLists: {
              unAvailable: res.results.unAvailable,
              ofacAvailable: res.results.ofacAvailable,
              euAvailable: res.results.euAvailable,
              chainalysisAvailable: res.results.chainalysisAvailable,
            },
            responseDateTime: res.responseDateTime,
            clientNames: res.client_names,
            status: res.search_status,
            reports: res.fp_urls,
            notes: res.notes,
            id: res.id,
            is_fuzzy: res.is_fuzzy,
          });
      })
      .catch((e) => {
        const { data: d } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(d?.detail, d));
      })
      .finally(() => setLoading(false));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div className={style.sanctions}>
      <div data-cy="newSearch" className={style.sanctionssection}>
        <Paper className={style.card}>
          <form className={style.form} onSubmit={handleSubmit}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="searchTerm" className={style.searchterm}>
              <span className={style.insetspan}>Search Term:</span>
              <TextareaAutosize
                id="searchTerm"
                name="term"
                placeholder="Try searching for e.g. country, business, group, individual..."
                value={search}
                onChange={event => handleSearchChange(event)} />
            </label>
            <input
              className={style.formsubmit}
              type="submit"
              value="Submit"
              disabled={loading} />
          </form>
        </Paper>
      </div>
      <div data-cy="results" className={style.sanctionssection}>
        {loading && <CircularProgress />}
        {data &&
        <SanctionsSearchResults
          hideInfo
          data={data} />}
      </div>
    </div>
  );
});

export default Sanctions;
