import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
} from '@mui/material';

import style from './news.module.scss';

const News = ({
  meta,
  search,
}) => (
  <Grid fluid className={cx([style.base, style.page])}>
    <Row>
      <Col xs={12}>
        <div className={style.body}>
          {meta.isEmpty() &&
            <CircularProgress />}
          {!meta.isEmpty() &&
            <div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Page URI:</div>
                <div>{meta.getIn(['source_uri']) ? meta.getIn(['source_uri']).split('posts')[0] : '-'}</div>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Post URI:</div>
                <div>{meta.getIn(['source_uri']) ? meta.getIn(['source_uri']) : '-'}</div>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Responses:</div>
                <div>{(search.getIn(['result', 'inline', 'news', 'total']) || 0).toLocaleString()}</div>
              </div>
            </div>}
        </div>
      </Col>
    </Row>
    <ReactTooltip id="action.tooltip" place="left" effect="solid" />
    <ReactTooltip id="news.username.tooltip" html place="left" effect="solid" />
  </Grid>
);

News.propTypes = {
  meta: PropTypes.object,
  search: PropTypes.object,
};

News.defaultProps = {
  meta: map(),
  search: map(),
};

export default News;
