import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';

import { fromJS, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from '../Search/search.module.scss';
import History from '../../utils/history';
import Text from '../../utils/text';
import Filters from '../../components/filters/Filters/Filters';
import Table from '../../components/search/Table/Table';
import MetaDataActions from '../../actions/metaDataActions';
import SearchActions from '../../actions/searchActions';
import { MetaDataContext, UserContext } from '../../components/utils/Context';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Tagging = ({
  location,
  match: {
    params,
  },
}) => {
  const metaData = useContext(MetaDataContext);
  const user = useContext(UserContext);

  const prevState = usePrevious({
    location,
    params,
    metaData,
    query: History.getCurrentLocation().query,
  });

  const sidebar = (hash, { type }) => {
    const id = hash
      .split(':')
      .splice(-1)
      .join();
    const name = hash.split(':').shift();
    const ignore = ['#help', '#save'];
    if (!hash || ignore.includes(name)) return;

    // make a request to the search api to get the details of the entity
    SearchActions.search(type, id);
    SearchActions.search(`inline.${type}`, id);
    SearchActions.search(`meta.${type}`, id);
  };

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    const current = fromJS(query || {}).filter(v => v);
    MetaDataActions.set(['metaData', 'type'], `tagging.${params.type}`);
    MetaDataActions.parseFilters(current);
    MetaDataActions.getChannels(Text.getUsernameFromEmail(user.get('usn')));
  }, []);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    const { hash } = location;
    const previous = fromJS(prevState?.query || {}).filter(v => v);
    const current = fromJS(query || {}).filter(v => v);

    // sidebar hash changed
    const _hash = hash;
    if (_hash !== location.hash) sidebar(_hash, params);

    if (!previous.equals(current)) {
      MetaDataActions.parseFilters(current);
      MetaDataActions.getChannels(Text.getUsernameFromEmail(user.get('usn')));
    }
  }, [location, metaData]);

  return (
    <Grid fluid className={style.search}>
      {location.pathname.startsWith('/home/search/tagging') &&
      <Row>{/* Filters */}
        <Col xs={12} className={style.filters}>
          <Filters
            advanced={false}
            user={user}
            store={metaData}
            type={`tagging.${params.type}`}
            defaults={metaData.getIn(['defaults', metaData.get('type')]) || map()}
            filters={metaData.get('filters')} />
        </Col>
      </Row>}
      <Table
        pager
        key={params.type}
        metaData={metaData}
        type={metaData.get('type')}
        prm={user.get('prm')}
        apps={user.get('apps')}
        limits={fromJS([
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: '100', value: 100 },
        ])}
        sorts={metaData.get('sorts')}
        data={metaData.getIn([params.type])}
        filters={metaData.get('filters')} />
    </Grid>
  );
};

Tagging.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};


export default Tagging;
