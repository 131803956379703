import React from 'react';

import { Map as map } from 'immutable';

export const UserContext = React.createContext(map());
export const SearchContext = React.createContext(map());
export const AlertingContext = React.createContext(map());
export const CredentialsContext = React.createContext(map());
export const MetaDataContext = React.createContext(map());

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export const SearchProvider = SearchContext.Provider;
export const SearchConsumer = SearchContext.Consumer;

export const AlertingProvider = AlertingContext.Provider;
export const AlertingConsumer = AlertingContext.Consumer;

export const CredentialsProvider = CredentialsContext.Provider;
export const CredentialsConsumer = CredentialsContext.Consumer;

export const MetaDataProvider = MetaDataContext.Provider;
export const MetaDataConsumer = MetaDataContext.Consumer;

