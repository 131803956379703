import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import style from './mediaGridVideo.module.scss';
import ContextMenuTrigger from '../utils/ContextMenuTrigger';
import Text from '../../utils/text';

const MediaGridVideo = ({
  contextMenuOptions,
  overlay,
  post,
  video,
}) => (
  <div className={cx([style['media-grid-video']])}>
    { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
    <video id="video" type={post.get('mime_type')} {...video} controls />
    <div
      className={style.overlay}
      onMouseEnter={() => ReactTooltip.rebuild()}
      data-for="global.tooltip"
      data-tip={Text.Strip(overlay)}>
      <ContextMenuTrigger
        id="global.context"
        data={post}
        options={contextMenuOptions}
        triangleStyle={{ borderWidth: '0 17px 17px 0', right: '5px' }}>
        <div />
      </ContextMenuTrigger>
    </div>
  </div>
);

MediaGridVideo.propTypes = {
  contextMenuOptions: PropTypes.object.isRequired,
  overlay: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
};

export default MediaGridVideo;
