import { atom, selector } from 'recoil';

export const cfmFiltersMc = atom({
  key: 'cfmFiltersMc',
  default: {},
});

export const getFiltersMc = selector({
  key: 'cfm.filtersMc',
  get: ({ get }) => get(cfmFiltersMc),
});

export const cfmFiltersFc = atom({
  key: 'cfmFiltersFc',
  default: {},
});

export const getFiltersFc = selector({
  key: 'cfm.filtersFc',
  get: ({ get }) => get(cfmFiltersFc),
});

export const cfmFiltersBins = atom({
  key: 'cfmFiltersBins',
  default: {},
});

export const getFiltersBins = selector({
  key: 'cfm.filtersBins',
  get: ({ get }) => get(cfmFiltersBins),
});
