import { useRef } from 'react';
import easyUseEffect from './easyUseEffect';

const useDidUpdate = (callback, deps) => {
  const hasMount = useRef(false);

  easyUseEffect(() => {
    if (hasMount.current) {
      return callback();
    }
    hasMount.current = true;
    return undefined;
  }, deps);
};

export default useDidUpdate;
