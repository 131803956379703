export const getTabNumber = (v: string, TabStrings: string[]): number | null => {
  if (!TabStrings.includes(v)) {
    return null;
  }
  return TabStrings.indexOf(v);
};

export const getTabString = (v: number, TabStrings: string[]): string | null => {
  if ((v < 0) || (v >= TabStrings.length)) {
    return null;
  }
  return TabStrings[Number(v)];
};
