import React from 'react';

import WebFont from 'webfontloader';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Theme as StyledTheme } from './shared/theme/Theme';
import './utils/fa';
import 'material-icons/iconfont/material-icons.css';
import Theme from './theme';
import History from './utils/history';
import Home from './containers/Home/Home';
import { client } from './gql/apolloClient';
import { config } from './constants/okta';
import { createFpTheme } from './theme/createFpTheme';
import { Auth } from './components/utils/Auth';

WebFont.load({
  google: {
    families: [
      'Open+Sans:400,600,700',
      'Libre+Franklin:600,700',
      'Montserrat:600,700',
      'Rajdhani:600',
    ],
  },
});

const Routes = () => (
  <>
    <Route path="/home" component={Home} />
    <Switch>
      /_ redirects and index routes_/
      <Redirect exact from="/" to="/home" />
      <Redirect exact from="/home/sanctions" to="/home/sanctions/main" />
      <Redirect exact from="/home/graphql" to="/graphql" />
      <Redirect exact from="/home/admin" to="/home/admin/profile/users" />
      <Redirect exact from="/home/alerting" to="/home/alerting/overview" />
      <Redirect exact from="/home/dashboards" to="/home/dashboards/expodash" />
      <Redirect exact from="/home/dashboards/mycards" to="/home/dashboards/mycards/overview" />
      <Redirect exact from="/home/dashboards/fullcards" to="/home/dashboards/fullcards/overview" />
      <Redirect exact from="/home/fraud/mycards" to="/home/fraud/mycards/overview" />
      <Redirect exact from="/home/fraud/fullcards" to="/home/fraud/fullcards/overview" />
      <Redirect exact from="/home/fraud/cfm/manage" to="/home/fraud/managebins" />
      <Redirect exact from="/home/assets" to="/home/assets/domain-monitoring" />
      <Redirect exact from="/home/assets/domain-monitoring" to="/home/dmtm/:type" />
      <Redirect exact from="/home/assets/domain-monitoring/items/:id" to="/home/dmtm/incidents/items/:id" />
      <Redirect exact from="/home/compromised" to="/home/compromised/search/credentials" />
      <Redirect exact from="/home/ddw" to="/home/ddw/search/forums" />
      <Redirect exact from="/home/ddw/:type" to="/home/ddw/:type/search" />
      <Redirect exact from="/home/ddw/:type/search" to="/home/search/:type" />
      <Redirect exact from="/home/ddw/markets/items/:id" to="/home/ddw/marketplaces/items/:id" />
      <Redirect exact from="/home/domain-monitoring" to="/home/assets/domain-monitoring/" />
      <Redirect exact from="/home/domain-monitoring/assets" to="/home/assets/domain-monitoring/" />
      <Redirect exact from="/home/domain-monitoring/items/assets/:id" to="/home/assets/domain-monitoring/items/:id" />
      <Redirect exact from="/home/explore" to="/home/explore/topics/trending_now" />
      <Redirect exact from="/home/explore/topics" to="/home/explore/topics/trending_now" />
      <Redirect exact from="/home/forums" to="/home/ddw/forums/search" />
      <Redirect exact from="/home/forums/search" to="/home/ddw/forums/search" />
      <Redirect exact from="/home/help" to="/home/help/:type/:anchor?" />
      <Redirect exact from="/home/help/settings" to="/home/profile/settings" />
      <Redirect exact from="/home/integrations" to="/home/integrations/all" />
      <Redirect exact from="/home/intelligence" to="/home/intelligence/latest" />
      <Redirect exact from="/home/manage" to="/home/profile/searches" />
      <Redirect exact from="/home/resources" to="/home/resources/glossary" />
      <Redirect exact from="/home/search" to="/home/search/communities" />
      <Redirect exact from="/home/search/advanced" to="/home" />
      <Redirect exact from="/home/settings/alerts" to="/home/profile/settings" />
      <Redirect exact from="/home/shops" to="/home/shops/search/marketplaces" />
      <Redirect exact from="/home/shops/:type/search" to="/home/search/:type" />
      <Redirect exact from="/home/tagging" to="/home/search/tagging/channels" />
      <Redirect exact from="/home/technical_data" to="/home/technical_data/search/cves" />
      <Redirect exact from="/home/technical_data/:type/search" to="/home/search/:type" />
      <Redirect exact from="/home/wiki" to="/home/wiki/malware" />
      <Redirect exact from="/home/analytics/:type" to="/home/dashboards/:type" />

      /_ ignite links _/
      <Redirect exact from="/cti/intelligence/report/:fpid" to="/home/intelligence/reports/report/:fpid" />
    </Switch>
  </>
);

const App = () => (
  <Auth.Provider config={config}>
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Router history={History}>
          <Routes />
        </Router>
      </RecoilRoot>
    </ApolloProvider>
  </Auth.Provider>
);

const FPTheme = () => {
  const mainTheme = createFpTheme(Theme());
  const fpTheme = JSON.parse(localStorage.getItem('fp_theme'));
  if (fpTheme) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <StyledThemeProvider theme={StyledTheme}>
            <App />
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
};

render(
  <FPTheme/>,
  document.getElementById('app'),
);
