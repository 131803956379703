import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useRouteMatch } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import { useSetRecoilState } from 'recoil';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from '@mui/material';

import { useOktaAuth } from '@okta/okta-react';
import { Close } from '@mui/icons-material';
import necessaryLegacyStylesRoot from '../../theme/necessaryLegacyStylesRoot';
import necessaryLegacyStylesProps from '../../theme/necessaryLegacyStylesProps';
import style from './home.module.scss';
import Text from '../../utils/text';
import Token from '../../utils/token';
import History from '../../utils/history';
import AlertingQuery from '../Alerting/query';
import AlertingStore from '../../stores/recoil/alerting';
import UserStore from '../../stores/userStore';
import SearchStore from '../../stores/searchStore';
import MetaDataStore from '../../stores/metaDataStore';
import CredentialsStore from '../../stores/credentialsStore';
import UserActions from '../../actions/userActions';
import SearchActions from '../../actions/searchActions';
import MetaDataActions from '../../actions/metaDataActions';
import Header from '../../components/search/Header';
import Sidebar from '../../components/sidebar/Sidebar';
import Invalid from '../../components/utils/Invalid/Invalid';
import Prompt from '../../components/utils/Prompt';
import Navigation from '../../components/utils/Navigation';
import ErrorBoundary from '../../components/utils/ErrorBoundary';
import ContextMenu from '../../components/utils/ContextMenu';
import Metrics from '../../constants/Metrics';
import Routing from './routing';
import {
  CredentialsProvider,
  MetaDataProvider,
  UserProvider,
  SearchProvider,
} from '../../components/utils/Context';
import {
  Suite,
  Resources,
  Profile,
} from '../../constants/Bentobox';

const BaseAlertTimeout = 6000;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const createStyleTagIfNecessary = (styleId, styleContent) => {
  let legacyStyleTag = document.getElementById(styleId);
  if (!legacyStyleTag) {
    legacyStyleTag = document.createElement('style');
    legacyStyleTag.id = styleId;
    legacyStyleTag.textContent = styleContent;
  }
  return legacyStyleTag;
};

const prependLegacyStylesRoot = () => {
  const styleId = 'necessaryLegacyStylesRoot';
  const legacyStyleTag = createStyleTagIfNecessary(styleId, necessaryLegacyStylesRoot);
  document.head.prepend(legacyStyleTag);
};

const insertLegacyStylesProps = () => {
  const styleId = 'necessaryLegacyStylesProps';
  const legacyStyleTag = createStyleTagIfNecessary(styleId, necessaryLegacyStylesProps);
  // Insert the necessaryLegacyStylesProps tag just before the first makeStyle tag
  const makeStylesTags = document.head.querySelectorAll('style[data-meta="makeStyles"]');
  if (makeStylesTags.length > 0) {
    document.head.insertBefore(legacyStyleTag, makeStylesTags[0]);
  } else {
    document.head.appendChild(legacyStyleTag);
  }
};

const Home = ({
  location,
}) => {
  const { oktaAuth } = useOktaAuth();
  const [externalWarning, setExternalWarning] = useState(false);
  const [externalWarningAcceptPath, setExternalWarningAcceptPath] = useState('');
  const [globalDialog, setGlobalDialog] = useState({});
  const [hideIgniteOverlay, setHideIgniteOverlay] = useState(Token.cke('X-FP-Ignite'));
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const [alert, setAlert] = useState();
  const [stores, setStores] = useState(map());
  const routeMatch = useRouteMatch(Routing.Match());
  const prm = stores.getIn(['user', 'prm'], list());
  const setOwners = useSetRecoilState(AlertingStore.owners);
  const setActiveOrgs = useSetRecoilState(AlertingStore.activeOrgs);
  const setLoadErr = useSetRecoilState(AlertingStore.loadErr);

  const prevState = usePrevious({
    location,
    params: routeMatch?.params,
    query: History.getCurrentLocation().query,
  });

  const access = () => {
    const apps = stores.getIn(['user', 'apps']) || list();
    const byLongestPath = (a, b) => (b?.getIn(['path', 0], '').length - a?.getIn(['path', 0], '').length);
    const app = apps
      .sort(byLongestPath)
      .find(v => window.location.pathname.includes(v.getIn(['path', 0])));
    const result = (app &&
      prm.some(v => app.get('test').test(v)) &&
      (!app.get('internal') || prm.some(p => /org.fp/.test(p))) &&
      !(prm.some(v => app.has('exclude') && app.get('exclude').test(v))) &&
      (!app.get('ui') || (app.get('ui') && prm.some(v => /acc.ui/.test(v)))))
      || (window.location.pathname.includes('agreements/ccmc') && prm.some(v => /ccm.cus/.test(v)));
    return result;
  };

  const authenticate = async () => {
    const auth = await oktaAuth.isAuthenticated()
      .then(() => Routing.LegacyRoutes())
      .then(() => UserActions.preview())
      .then(() => UserActions.route())
      .then(() => UserActions.checkAdminPrefs())
      .then(() => true)
      .catch((err) => {
        console.error(err); // eslint-disable-line
        UserActions.logout();
      });
    return Boolean(auth);
  };

  const bentoboxRoute = async (event) => {
    const redirect = History.getCurrentLocation();
    const anchor = event?.target?.closest("[class*='links-module_link']");
    const pathname = anchor?.pathname || anchor?.textContent;
    if (!pathname) return;
    if (/logout/ig.test(pathname)) {
      event?.preventDefault();
      await UserActions.logout();
    } else if (/switch/ig.test(pathname)) {
      event?.preventDefault();
      await UserActions.switchEnv(redirect);
    } else if (anchor?.origin === window?.location?.origin) {
      event?.preventDefault();
      History.push({ pathname: anchor?.href?.replace(/.*?\/home/ig, '/home') || pathname });
    }
  };

  const bootstrap = async () => {
    // add accesstoken claims to user store
    UserActions.set(['user'], UserStore?.state?.get('user').concat(fromJS(Token.get())));

    Promise.all([
      UserActions.loadPrefs,
      UserActions.loadTokens,
      UserActions.loadAnalytics,
      UserActions.loadOrgProfiles,
      UserActions.loadFlags,
      SearchActions.loadResources,
      MetaDataActions.getAllTags,
    ].map(v => new Promise((resolve, reject) => {
      v.completed.listen(() => resolve());
      v.failed.listen(() => reject());
    })))
      .then(() => {
        setStores(v => v.withMutations(state => state
          .set('user', UserStore?.state?.get('user'))
          .set('search', SearchStore?.state?.get('search'))
          .set('metaData', MetaDataStore?.state?.get('metaData'))
          .set('credentials', CredentialsStore?.state?.get('credentials'))));
      })
      .then(() => {
        // Load alerting owner dependencies
        AlertingQuery.load('owners', Token.get('prm'))
          .then((owners) => {
            UserActions.set(['user', 'activeOrganizations'], fromJS(owners.activeOrgs));
            setActiveOrgs(fromJS(owners.activeOrgs));
            setOwners(fromJS(owners.owners));
          }).catch(() => {
            setLoadErr(true);
          });
      })
      .finally(() => {
        UserStore.listen(({ user: userStore }) => setStores(v => v.set('user', userStore)));
        SearchStore.listen(({ search: searchStore }) => setStores(v => v.set('search', searchStore)));
        MetaDataStore.listen(({ metaData: metaDataStore }) => setStores(v => v.set('metaData', metaDataStore)));
        CredentialsStore.listen(({ credentials: credsStore }) => setStores(v => v.set('credentials', credsStore)));
      })
      .catch((err) => {
        // services failed to load; route to login
        console.error(err); // eslint-disable-line
        Routing.Unauth();
      });

    UserActions.loadPrefs();
    UserActions.loadTokens();
    UserActions.loadAnalytics();
    UserActions.loadOrgProfiles();
    UserActions.loadFlags();
    SearchActions.loadResources();
    MetaDataActions.getAllTags();
  };

  const closeGlobalDialog = () => {
    setGlobalDialog({});
  };

  const handleAccessError = (type) => {
    switch (type) {
      case 'credentials':
        return <Invalid
          icon="error_outline"
          title="Interested in Account Takeover protection?"
          subtitle="It looks like you’re trying to access compromised credentials data. To start leveraging this data and better protect your organization from Account Takeover,
            please contact <a href='mailto:support@flashpoint.io'> support@flashpoint.io</a> and we'll get you up and running."
        />;
      default:
        return <Invalid icon="error_outline" denied />;
    }
  };

  const hideExternalLinkPrompt = () => {
    setExternalWarning(false);
    setExternalWarningAcceptPath('');
  };

  const updateIgniteOverlay = () => {
    // expire after access token expires 8 hours
    const expires = new Date(new Date().getTime() + (8 * 60 * 60 * 1000));
    Token.set('X-FP-Ignite', true, { expires });
    setHideIgniteOverlay(true);
  };

  const accept = () => {
    const externalTab = window.open();
    // Doing this way to use noopener and open in new tab. noopener opens
    // in new window.
    externalTab.opener = null;
    externalTab.location = externalWarningAcceptPath;
    externalTab.target = '_blank';
    hideExternalLinkPrompt();
  };

  const sidebar = () => {
    const { pathname } = History.getCurrentLocation();
    const apps = stores.getIn(['user', 'apps']) || list();
    const app = apps
      .filter(v => v.has('sidebar'))
      .find(v => pathname.includes(v.getIn(['path', 0])));
    if (!app) return false;
    return app.get('sidebar');
  };

  const type = () => {
    const helpers = ['save'];
    const hash = location.hash.substring(1).split(':')[0];
    const searchType = stores.getIn(['search', 'type']);
    return searchType === 'all' || helpers.includes(hash) ? hash : searchType;
  };

  useEffect(() => {
    // extend auto-hide by 1s per 120 characters
    const text = stores.getIn(['search', 'info', 'message']);
    const defaultTimeout = BaseAlertTimeout + (Math.floor(text?.length || 120 / 120) * 1000);
    const timeout = stores.getIn(['search', 'info', 'timeout'], defaultTimeout);
    if (text) setAlert({ text, timeout });
    else setAlert();
  }, [stores.getIn(['search', 'info', 'message'])]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    if (!prevState || !location) return undefined;
    if (externalWarning) hideExternalLinkPrompt();
    if (prevState.location.pathname !== location.pathname || !_.isEqual(prevState.query, query)) {
      performance.mark(Metrics.Mark.navigation);
    }
  }, [location]);

  useEffect(() => {
    authenticate();
    prependLegacyStylesRoot();
    insertLegacyStylesProps();
  }, [location]);

  useEffect(() => {
    // load required assets and store initialization
    bootstrap();

    // External link event listener
    document.addEventListener('onExternalLinkClick', (e) => {
      setExternalWarning(true);
      setExternalWarningAcceptPath(e.detail.to);
    });

    // Global Dialog event listener
    document.addEventListener('onGlobalDialogOpen', (e) => {
      setGlobalDialog(e.detail.dialog);
    });

    // Event listener to determine when control/command button is held to allow
    // opening of new tab when using navigateTo method without passing the event
    // all the way through components
    const setControlCommand = (e) => {
      if (!sessionStorage) return undefined;
      if (e.type === 'keydown') {
        sessionStorage.setItem('clickNewTab', e.key === 'Control' || e.key === 'Meta');
      } else if (e.type === 'keyup') {
        sessionStorage.setItem('clickNewTab', false);
      }
    };
    document.addEventListener('keyup', setControlCommand);
    document.addEventListener('keydown', setControlCommand);
    document.addEventListener('visibilitychange', () => {
      if (sessionStorage && document.hidden) {
        sessionStorage.setItem('clickNewTab', false);
      }
    });
  }, []);

  return (
    !stores.isEmpty() &&
    <UserProvider value={stores.get('user')}>
      <SearchProvider value={stores.get('search')}>
        <CredentialsProvider value={stores.get('credentials')}>
          <MetaDataProvider value={stores.get('metaData')}>
            <Grid
              fluid
              className={cx([style.home])}>
              <Navigation
                location={location}
                setShowSearchOverlay={setShowSearchOverlay}
                showSearchOverlay={showSearchOverlay} />
              <Row className={style.header}>
                <Col xs={12}>
                  <div className={style.sticky}>
                    <Header
                      access={access()}
                      params={routeMatch?.params}
                      setShowSearchOverlay={setShowSearchOverlay}
                      showSearchOverlay={showSearchOverlay} />
                    <Sidebar
                      pin={Boolean(location.hash && sidebar())}
                      type={type()} />
                  </div>
                </Col>
              </Row>
              <ErrorBoundary
                userId={stores.getIn(['user', 'uid'])}
                location={location}>
                <Row className={cx([
                  style.content,
                  location.pathname.startsWith('/home/search') && style.search])}>
                  <Col xs={12}>
                    {/* permission denied */}
                    {access() === false &&
                      handleAccessError(routeMatch?.params?.type)
                    }
                    {/* 404 */}
                    {access() === undefined &&
                      <Invalid title="Page Not Found" icon="error_outline" />}
                    {/* user has access */}
                    {access() && <Routing.Routes />}
                  </Col>
                </Row>
              </ErrorBoundary>
              {!stores.getIn(['search', 'resources', 'prompts'], list())
                .filter(v => v)
                .filter(v => prm.some(p => v.get('test').test(p)))
                .map(v => (
                  <Prompt
                    key={v}
                    manual={v.get('manual')}
                    open={!stores.getIn(['user', 'prefs', `${v.get('id')}_ack`])}
                    title={Text.Sentence(`${v.get('id')} Acknowledgement`)}
                    acceptText="I Accept"
                    accept={() => {
                      UserActions.set(['user', 'prefs', `${v.get('id')}_ack`], moment.utc().unix());
                      UserActions.savePrefs();
                    }}
                    cancelText="Decline"
                    cancel={() => UserActions.logout()}>{v.get('html')}
                  </Prompt>))}
              <Prompt
                open={externalWarning}
                title="Warning: Leaving Flashpoint Webpage"
                acceptText="Continue"
                accept={accept}
                cancelText="Cancel"
                cancel={hideExternalLinkPrompt}>
                You are about to navigate to a webpage not hosted by Flashpoint in a new tab.
              </Prompt>
              <Dialog
                open={Boolean(!hideIgniteOverlay)}
                className={style.overlayIgnite}>
                <DialogTitle className={style.overlaytitle}>
                  <IconButton onClick={() => updateIgniteOverlay()}>
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent className={style.overlaycontent}>
                  <div className={cx([style.h0, 'h0', style.open, 'open'])}>Ignite is replacing the FPTools platform and API</div>
                  <div className={cx([style.h0, 'h1', style.open, 'open'])}>For more details contact <a target="_blank" href="mailto:support@flashpoint.io" rel="noreferrer">support@flashpoint.io</a></div>
                  <Button color="primary" variant="text" className={style.textbutton} onClick={() => History.navigateTo('//flashpoint.my.site.com/helpcenter/s/article/Flashpoint-Intelligence-Platform-FPTools-Decommissioning-Statement', true)}>
                    Learn more
                  </Button>
                  <Button
                    data-testid="home.modal-ignite-link"
                    color="secondary"
                    variant="contained"
                    className={style.button}
                    onClick={() => {
                      updateIgniteOverlay();
                      window.location.href = '//app.flashpoint.io/home/dashboard';
                    }}>
                    Take me to Ignite
                  </Button>
                </DialogContent>
              </Dialog>
              {Object.keys(globalDialog).length > 0 &&
                <Dialog
                  name="global.dialog"
                  open={Boolean(Object.keys(globalDialog).length > 0)}
                  onClose={() => closeGlobalDialog()}>
                  <DialogTitle>{globalDialog.title}</DialogTitle>
                  <DialogContent>
                    {globalDialog.dialog
                      && globalDialog.dialog({ close: closeGlobalDialog })}
                  </DialogContent>
                  <DialogActions>
                    {globalDialog.actions
                      && globalDialog.actions({ close: closeGlobalDialog })}
                  </DialogActions>
                </Dialog>}
              <Snackbar
                open={Boolean(alert)}
                onClose={() => setAlert()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={alert ? alert.timeout : BaseAlertTimeout}>
                <Alert
                  severity="info"
                  onClose={() => setAlert()}>
                  {alert?.text}
                </Alert>
              </Snackbar>
              <ReactTooltip id="global.tooltip" html clickable delayHide={100} place="bottom" effect="solid" />
              <ContextMenu />
              <div className={style.bentobox}>
                <fp-utils-bentobox
                  anchor="bottom-start"
                  icon="question"
                  header=""
                  data-testid="util-help"
                  onClick={event => bentoboxRoute(event)}
                  user={stores.getIn(['user', 'id'])}
                  token={Token?.cke()}
                  products={JSON.stringify(
                    Resources
                      .filter(r => !r.test || prm.some(p => r?.test?.test(p)))
                      .map(r => ({
                        ...r,
                        active: Boolean(window.location.pathname === r?.loginUrl),
                      })),
                  )} />
                <fp-utils-bentobox
                  anchor="bottom-start"
                  icon="user"
                  header=""
                  class="fp-settings"
                  data-testid="util-settings"
                  onClick={event => bentoboxRoute(event)}
                  user={stores.getIn(['user', 'id'])}
                  token={Token?.cke()}
                  products={JSON.stringify([
                    ...Profile
                      .filter(r => !r.test || prm.some(p => r?.test?.test(p)))
                      .map(r => ({
                        ...r,
                        active: Boolean(window.location.pathname === r?.loginUrl),
                      })),
                  ])} />
                <fp-utils-bentobox
                  anchor="bottom-start"
                  icon="bentobox"
                  header="Switch To"
                  data-testid="util-moreapp"
                  onClick={event => bentoboxRoute(event)}
                  user={stores.getIn(['user', 'id'])}
                  token={Token?.cke()}
                  products={JSON.stringify([
                    ...Suite
                      .filter(r => !r.test || prm.some(p => r?.test?.test(p)))
                      .map(r => ({
                        ...r,
                        active: Boolean(window.location.pathname === r?.loginUrl),
                      })),
                  ])} />
              </div>
            </Grid>
          </MetaDataProvider>
        </CredentialsProvider>
      </SearchProvider>
    </UserProvider>
  );
};

Home.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Home;
