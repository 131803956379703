import * as React from 'react';

import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import { MenuButton } from './MenuButton';

export type CheckedObj = {
  checked: boolean
}

const useMenuStyles = makeStyles(() => ({
  menuWrapper: {
    padding: '12px 0 18px 0',
  },
  menuHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  looksClickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  menuContentWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
  },
}));

type onToggleFunction = (menuName: string) => void;
type reactNodeFunction = (params: object, index:number) => JSX.Element;

interface MenuProps {
  menuText: string;
  isOpen?: boolean;
  alwaysOpen?: boolean;
  onToggle?: onToggleFunction;
  makeMenuChild: reactNodeFunction;
  menuChildren: CheckedObj[];
}

export const CategoryMenu = ({
  menuText,
  isOpen,
  alwaysOpen,
  onToggle,
  menuChildren,
  makeMenuChild,
}: MenuProps) => {
  const classes = useMenuStyles();
  const numChecked = menuChildren.reduce((acc:number, tagObj:CheckedObj) =>
    acc + (tagObj.checked ? 1 : 0)
  , 0);
  return (
    <div className={classes.menuWrapper} >
      <div
        className={cx([classes.menuHeader, alwaysOpen ? {} : classes.looksClickable])}
        onClick={() => { if (onToggle) onToggle(menuText); }}
      >
        <MenuButton
          buttonText={menuText}
          isOpen={isOpen}
          alwaysOpen={alwaysOpen}
        />
        <div>
          {numChecked}/{menuChildren.length} Selected
        </div>
      </div>
      <div className={classes.menuContentWrapper}>
        {isOpen ? menuChildren.map((tagObj:CheckedObj, myIndex:number) => (
          makeMenuChild(tagObj, myIndex)
        )) : null}
      </div>
    </div>
); };

CategoryMenu.defaultProps = {
  onToggle: null,
  alwaysOpen: false,
  isOpen: true,
};
