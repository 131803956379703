import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import defaultTheme from './defaultTheme';

const theme = createTheme(adaptV4Theme(defaultTheme));

export default {
  root: {
    '--vt-red-500': 'black!important',
  },
  MuiAlert: {
    root: {
      bottom: '15px',
      position: 'fixed',
      right: '100px',
      width: '35rem',
    },
    standardInfo: {
      backgroundColor: '#eee',
      color: '#1a1b33',
      '& .MuiAlert-icon': {
        color: '#1a1b33',
      },
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      color: '#000',
      padding: '0 39px 0 4px !important',
    },
    paper: {
      padding: 0,
    },
    root: {
      '&.active': {
        '& .MuiButton-root': {
          borderColor: '#5c6ae0',
        },
        '& .MuiFormLabel-root': {
          color: '#5c6ae0',
        },
        '& .MuiIconButton-root': {
          color: '#5c6ae0',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#5c6ae0',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#5c6ae0',
          },
      },
    },
  },
  MuiButton: {
    contained: {
      boxShadow: 'none',
      padding: '0 1rem',
      textTransform: 'capitalize',
    },
    containedSecondary: {
      color: '#fff !important',
      '& .MuiButton-label': {
        color: '#fff !important',
      },
      '& .MuiIcon-root': {
        color: '#fff',
      },
    },
    containedPrimary: {
      color: '#fff !important',
      '& .MuiIcon-root': {
        color: '#fff',
      },
    },
    label: {
      fontSize: '1.4rem',
      whiteSpace: 'nowrap',
    },
    text: {
      padding: '.6rem 1.2rem',
    },
  },
  MuiChip: {
    root: {
      margin: theme.spacing(0.5),
    },
    colorPrimary: {
      '& .MuiIcon-root': {
        color: '#ffff',
      },
    },
    colorSecondary: {
      '& .MuiIcon-root': {
        color: '#ffff',
      },
    },
  },
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: '62.5%' /* 62.5% of 16px = 10px = 1rem, 14px = 1.4rem... */,
      },
      body: {
        backgroundColor: '#F7F7F8',
      },
      a: {
        color: '#5c6ae0',
        cursor: 'pointer',
        textDecoration: 'none',
      },
      blockquote: {
        marginBlockEnd: 0,
        marginBlockStart: 0,
        marginInlineEnd: 0,
        marginInlineStart: 0,
      },
      /* accessibility */
      '*:focus': {
        outline: 'none',
      },
      '*::-moz-placeholder': {
        textTransform: 'none',
      },
      '*::-webkit-input-placeholder': {
        textTransform: 'none',
      },
      'input:-webkit-autofill': {
        transition:
          'background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s',
        '&:active': {
          transition:
            'background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s',
        },
        '&:focus': {
          transition:
            'background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s',
        },
        '&:hover': {
          transition:
            'background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s',
        },
      },
      /* tooltips */
      '[data-id="tooltip"]': {
        background: '#222',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '1.6rem',
        fontWeight: '400',
        maxHeight: '35rem',
        maxWidth: '45rem',
        overflowX: 'hidden',
        overflowY: 'auto',
        '& *': {
          background: '#222',
          color: '#fff',
          fontSize: '1.6rem',
          fontWeight: '400',
          textTransform: 'initial',
        },
      },
      /* highlighting */
      '.glossary-term': {
        borderBottom: '.2rem dashed #5c6ae0',
        cursor: 'pointer',
        wordBreak: 'normal',
      },
      '.highlight': {
        backgroundColor: '#5c6ae0',
        borderRadius: '.5rem',
        fontWeight: 400,
        color: '#fff !important',
        padding: '0 .5rem',
        wordBreak: 'normal',
      },
      /* typography */
      '.a': {
        color: '#5c6ae0 !important',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          opacity: '0.6',
        },
      },
      '.h0': {
        color: '#000',
        fontSize: '3rem',
        fontWeight: 600,
        letterSpacing: '0.05rem',
      },
      '.h1': {
        color: '#000',
        fontSize: '2.4rem',
        fontWeight: 600,
        letterSpacing: '0.05rem',
      },
      '.h2': {
        fontSize: '1.8rem',
        letterSpacing: '0.075rem',
        textTransform: 'uppercase',
      },
      '.h3': {
        color: grey[500],
        fontSize: '1.2rem',
        letterSpacing: '0.05rem',
      },
      '.h4': {
        color: grey[500],
        fontSize: '1.4rem',
        fontWeight: 600,
        letterSpacing: '0.05rem',
        textTransform: 'uppercase',
      },
      /* transforms */
      '.cap': {
        textTransform: 'capitalize',
      },
      '.raj': {
        fontFamily: 'Rajdhani',
      },
      '.mont': {
        fontFamily: 'Montserrat',
      },
      '.normal': {
        textTransform: 'initial',
      },
      '.right': {
        direction: 'rtl !important',
      },
      '.left': {
        direction: 'ltr !important',
      },
      '.srOnly': {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden',
      },
      /* Flow */
      '.rdk-portal': {
        background: 'var(--color-background)',
        color: 'var(--color-on-background)',
        fontFamily: 'var(--font-family)',
        fontSize: '14px',
        fontWeight: 100,
        lineHeight: 1.2,
        overflow: 'hidden',
        '-moz-osx-font-smoothing': 'grayscale',
        '-moz-font-feature-settings': "'liga' on",
        textRendering: 'optimizeLegibility',
        '-webkit-font-smoothing': 'antialiased',

        '& a': {
          color: 'var(--color-primary)',
          textDecoration: 'none',

          '&:hover': {
            textDecoration: 'underline',
          },
        },

        '& code': {
          fontFamily: 'var(--mono-font-family)',
        },

        '& input': {
          fontFamily: 'var(--font-family)',
        },
        '& button': {
          fontFamily: 'var(--font-family)',
        },
        '& h1': {
          fontWeight: 100,
        },
        '& h2': {
          fontWeight: 100,
        },
        '& h3': {
          fontWeight: 100,
        },
      },
      /* Flow Upload Container */
      '.uppy-Root': {
        '&.uppy-DragDrop-container': {
          background: 'var(--color-input)',
          borderRadius: '2px',
          color: 'var(--color-on-input)',
          border: '1px solid transparent',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            outline: 'none',
          },
          '&:active': {
            outline: 'none',
          },
          '&:focus': {
            outline: 'none',
          },
          '& .uppy-DragDrop-inner': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .uppy-DragDrop-arrow': {
            width: '16px',
            height: '16px',
            margin: '0 4px',
            fill: '#e0e0e0',
          },
          '& .uppy-DragDrop-label': {
            fontSize: '13px',
            color: 'var(--color-on-input)',
            marginBottom: 0,
            '&:after': {
              content: '"."',
              marginRight: '4px',
            },
            '& .uppy-DragDrop-browse': {
              color: 'var(--color-primary)',
            },
          },
          '& .uppy-DragDrop-note': {
            fontSize: '13px',
          },
        },
        '&.uppy-StatusBar': {
          backgroundColor: 'var(--nav-background)',
          '&:before': {
            backgroundColor: 'var(--nav-background)',
          },
          '&.is-complete': {
            '.uppy-StatusBar-progress': {
              backgroundColor: 'var(--color-success)',
            },
            '& .uppy-StatusBar-statusIndicator': {
              color: 'var(--color-success)',
            },
          },
          '&.is-error': {
            '& .uppy-StatusBar-progress': {
              backgroundColor: 'var(--color-error)',
            },
            '& .uppy-StatusBar-statusIndicator': {
              color: 'var(--color-error)',
            },
          },
          '&.is-waiting .uppy-StatusBar-actions': {
            backgroundColor: 'var(--nav-background)',
          },
          '&:not([aria-hidden="true"]).is-waiting': {
            backgroundColor: 'var(--nav-background)',
            borderTop: '1px solid var(--nav-background)',
            '&:before': {
              backgroundColor: 'var(--nav-background)',
            },
            '& .uppy-StatusBar-actions': {
              backgroundColor: 'var(--nav-background)',
            },
          },
          '& .uppy-StatusBar-progress': {
            backgroundColor: 'var(--nav-background)',
          },
          '& .uppy-StatusBar-actionBtn': {
            backgroundColor: 'var(--color-button-success)',
          },
          '&.is-uploading': {
            '& .uppy-StatusBar-statusIndicator': {
              color: 'var(--color-primary)',
            },
            '& .uppy-StatusBar-progress': {
              backgroundColor: 'var(--color-primary)',
            },
            '& .uppy-StatusBar-spinner': {
              fill: 'var(--color-primary)',
            },
          },
          '& .uppy-StatusBar-statusPrimary': {
            color: 'var(--color-on-surface)',
          },
          '& .uppy-StatusBar-statusIndicator': {
            color: 'var(--color-error)',
          },
          '& .uppy-StatusBar-details': {
            backgroundColor: 'var(--nav-background)',
          },
        },
      },
      // CodeEditor.module.css
      '.monaco-editor': {
        '& .details': {
          backgroundColor: 'var(--nav-background) !important',
          borderColor: 'transparent !important',
        },
        '& .suggest-widget': {
          '& .tree': {
            backgroundColor: 'var(--nav-background) !important',
            borderColor: 'transparent !important',
          },
          '& .monaco-list-row': {
            '&.focused': {
              backgroundColor: 'var(--color-surface-primary) !important',
            },
            '&:hover': {
              backgroundColor: 'var(--color-surface-secondary) !important',
            },
          },
        },
        '& .monaco-hover': {
          backgroundColor: 'var(--nav-background) !important',
          borderColor: 'transparent !important',
          color: '#eefff6 !important',
          fontFamily: 'var(--mono-font-family) !important',
        },
        // override FP Tools highlighting
        '& .highlight': {
          backgroundColor: 'inherit',
          padding: 0,
        },
        /* End of Flow Globals */
      },
      // TokenEditor.module.css
      // Reference: https://github.com/facebook/draft-js/issues/401
      '.public-DraftEditorPlaceholder-inner': {
        color: 'var(--color-input-placeholder)',
        userSelect: 'none',
      },
    },
  },
  MuiDialog: {
    paper: {
      padding: 0,
    },
    paperScrollBody: {
      backgroundColor: '#fff',
    },
  },
  MuiDialogContent: {
    root: {
      '& .MuiFormControl-root': {
        '& .MuiButton-root': {
          justifyContent: 'space-between',
          color: 'currentColor',
        },
      },
    },
  },
  MuiDialogActions: {
    root: {
      borderTop: `1px solid ${grey[300]}`,
    },
  },
  MuiDialogTitle: {
    root: {
      '& .MuiIcon-root': {
        float: 'right',
      },
    },
  },
  MuiFab: {
    secondary: {
      '& .MuiIcon-root': {
        color: '#fff',
      },
    },
  },
  MuiFilledInput: {
    input: {
      paddingBottom: '.5rem',
      paddingTop: '.5rem',
    },
    colorSecondary: {
      '& .MuiSelect-icon': {
        color: '#fff',
      },
      '& .MuiSelect-root': {
        backgroundColor: '#5c6ae0',
        borderRadius: '.4rem',
        color: '#fff',
        '&:focus': {
          backgroundColor: '#5c6ae0',
          borderRadius: '.4rem',
        },
      },
    },
  },
  MuiFormControl: {
    root: {
      '&.active': {
        '& .MuiButton-root': {
          borderColor: '#5c6ae0',
        },
        '& .MuiIconButton-root': {
          color: '#5c6ae0',
        },
        '& .MuiFormLabel-root': {
          color: '#5c6ae0',
        },
        '& .MuiIcon-root': {
          color: '#5c6ae0',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
          borderColor: '#5c6ae0',
        },
        '& .MuiSelect-icon': {
          color: '#5c6ae0',
        },
      },
      '& .MuiButton-label': {
        color: '#000',
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        '& .MuiIcon-root': {
          color: grey[500],
          fontSize: '2.4rem',
        },
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      '& .MuiCheckbox-root': {
        padding: '3px',
      },
    },
    label: {
      textTransform: 'capitalize',
    },
  },
  MuiFormLabel: {
    root: {
      textTransform: 'capitalize',
    },
  },
  MuiIcon: {
    root: {
      cursor: 'pointer',
      overflow: 'visible',
    },
  },
  MuiInputLabel: {
    outlined: {
      backgroundColor: '#fff',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '&.MuiInputLabel-shrink': {
        fontSize: '1.6rem',
      },
    },
  },
  MuiList: {
    root: {
      width: '100%',
      '& .MuiMenuItem-root': {
        display: 'flex',
      },
    },
  },
  MuiListItem: {
    dense: {
      paddingBottom: '.5rem',
      paddingTop: '.5rem',
    },
  },
  MuiListItemIcon: {
    root: {
      margin: '0 .5rem',
      minWidth: 'inherit',
    },
  },
  MuiListItemText: {
    dense: {
      margin: 0,
    },
  },
  MuiMenu: {
    list: {
      maxHeight: '250px',
    },
  },
  MuiOutlinedInput: {
    root: {},
  },
  MuiPaper: {
    root: {
      padding: '1.5rem',
    },
  },
  MuiRadio: {
    root: {
      padding: '.25rem 1rem',
    },
  },
  MuiPickersBasePicker: {
    container: {
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#5c6ae0',
      },
      '& .MuiTabs-scroller': {
        backgroundColor: '#5c6ae0',
        color: '#fff',
      },
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: '#5c6ae0',
      },
      '& .MuiPaper-root': {
        padding: 0,
      },
    },
  },
  MuiPopover: {
    root: {
      '&.dropdown': {
        '& .active': {
          fontWeight: 'bold',
        },
        '& .MuiPaper-root': {
          maxHeight: '25rem',
          maxWidth: '25rem',
        },
      },
    },
    paper: {
      padding: 0,
    },
  },
  MuiSelect: {
    root: {
      '&.active': {
        '& .MuiFormLabel-root': {
          color: '#5c6ae0',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#5c6ae0',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#5c6ae0',
        },
      },
      '& .MuiListItemIcon-root': {
        display: 'none',
      },
    },
  },
  MuiSnackbar: {
    root: {
      '& .MuiSnackbarContent-root': {
        justifyContent: 'center',
      },
      '& .MuiSnackbarContent-action': {
        color: '#5c6ae0',
        cursor: 'pointer',
        textTransform: 'capitalize',
      },
    },
  },
  MuiTab: {
    fullWidth: {
      minWidth: '5rem',
    },
  },
  MuiTableContainer: {
    root: {
      backgroundColor: '#fff',
      overflowX: 'scroll',
      padding: 0,
    },
  },
  MuiTablePagination: {
    root: {
      '& p': {
        marginTop: 0,
        marginBottom: 0,
      },
      '& .MuiTablePagination-select': {
        minHeight: 'auto !important',
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiOutlinedInput-root': {
        minHeight: 'inherit',
      },
    },
    actions: {
      display: 'none',
    },
    input: {
      margin: '0 .5rem',
    },
    toolbar: {
      minHeight: 0,
      padding: 0,
    },
  },
  MuiTabPanel: {
    root: {
      padding: 0,
    },
  },
  MuiTableCell: {
    head: {
      color: grey[500],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      maxWidth: '25rem',
    },
    root: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      maxWidth: '25rem',
      '& a': {
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& div': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&.actions': {
        maxWidth: '12rem',
        padding: '6px 0',
        textAlign: 'center',
        width: '.3rem',
      },
      '&.description': {
        maxWidth: '40rem',
        whiteSpace: 'normal',
      },
      '& .MuiButtonBase-root': {
        paddingTop: 0,
      },
    },
    paddingCheckbox: {
      paddingTop: '.5rem !important',
    },
  },
  PrivateSwitchBase: {
    root: {
      padding: '3px',
    },
  },
  MuiButtonBase: {
    root: {
      zIndex: 'inherit !important',
    },
  },
};
