import { fromJS, List as list, Map as map, OrderedMap as orderedMap, Set as set } from 'immutable';

import moment from 'moment';
import FileSaver from 'file-saver';
import { NIL as NIL_UUID } from 'uuid';

import Api, { cachedGet, cachedPost, cachedPut, cachedDelete } from '../../utils/api';
import History from '../../utils/history';
import SearchActions from '../../actions/searchActions';
import Text from '../../utils/text';
import Common from '../../utils/common';
import SearchUtils from '../../utils/searchUtils';
import Apps from '../../constants/Apps';
import Messages from '../../constants/Messages';
import SearchFields from '../../constants/SearchFields';

const AlertingUrl = '/ui/v4/alerts';
const SearchUrl = '/ui/v4/all/search';
const Status = [200, 201, 204, 500, 501, 502, 503, 504];
const DeaAlerts = ['internet_infrastructure', 'code_repository', 'cloud_infrastructure'];
const Sorts = fromJS({
  keywords: [
    { label: 'Updated At (desc)', value: 'updated_timestamp:desc' },
    { label: 'Updated At (asc)', value: 'updated_timestamp:asc' },
  ],
  recipients: [
    { label: 'Updated At (desc)', value: 'updated_timestamp:desc' },
    { label: 'Updated At (asc)', value: 'updated_timestamp:asc' },
  ],
  code_repository: [
    { detail: { file: 'file', owner: 'owner', repo: 'repo', source: 'source' }, description: 'snippet' },
  ],
  internet_infrastructure: [
    { detail: { source: 'source', country: 'country', asn: 'asn', last_update: 'last update' }, description: 'snippet' },
  ],
  cloud_infrastructure: [
    { detail: { bucket_name: 'bucket_name', source: 'source', region: 'bucket_region' }, description: 'snippet' },
  ],
  ...SearchFields.Sorts,
});
const basetypeColorMap = {
  Boards: Text.Color(0, '', false, '#5c6ae0'),
  'Chat Discord': Text.Color(1, '', false, '#5c6ae0'),
  'Chat Telegram': Text.Color(2, '', false, '#5c6ae0'),
  'Chat Qq': Text.Color(3, '', false, '#5c6ae0'),
  'Chat Rocketchat': Text.Color(0, '', false, '#0D5D53'),
  'Chat Mewe': Text.Color(1, '', false, '#0D5D53'),
  Marketplaces: Text.Color(2, '', false, '#0D5D53'),
  'Paste Sites': Text.Color(3, '', false, '#0D5D53'),
  Blogs: Text.Color(0, '', false, '#007DCC'),
  'Social News': Text.Color(1, '', false, '#007DCC'),
  Forums: Text.Color(2, '', false, '#007DCC'),
  Ransomware: Text.Color(3, '', false, '#007DCC'),
  'Code Repositories': Text.Color(0, '', false, '#18AD9B'),
  Twitter: Text.Color(1, '', false, '#18AD9B'),
  'Data Exposure Alerts': Text.Color(2, '', false, '#18AD9B'),
  Images: Text.Color(4, '', false, '#007DCC'),
  Videos: Text.Color(3, '', false, '#18AD9B'),
};

const getSourceIds = (basetypesList, prm, sourcesFilter) => {
  const sources = (basetypesList || list())
    .filter(b => prm.some(p => b.get('test').test(p)));
  const dea = sources.filter(b => DeaAlerts.includes(b.get('searchType')));
  const collected = sources.filter(b => !DeaAlerts.includes(b.get('searchType')));
  let sourceIds = list();

  switch (sourcesFilter) {
    case 'DEA':
      sourceIds = dea.map(b => b.get('id')).flatten();
      break;
    case 'COLLECTED':
      sourceIds = collected.map(b => b.get('id')).flatten();
      break;
    default:
      sourceIds = list();
      break;
  }
  return sourceIds;
};

const alertDetails = (alert) => {
  const searchType = Common.Basetypes.BasetypeToSearchType(fromJS(alert));
  const fields = Sorts.getIn([searchType, 0, 'detail']);
  const fieldValues = [
    { fieldName: 'Platform', value: `${Text.Sentence(searchType)}`, icon: Common.Generic.Icon(alert.get('basetypes')) },
  ];

  const values = fields
    ?.map((value, label) => {
      const either = typeof value === 'string' ? value.split('|') : value.get('field').split('|');
      let result = null;
      either.forEach((e) => {
        if (!result) {
          result = alert.hasIn(['highlight', e, 0])
            ? alert.getIn(['highlight', e, 0])
            : alert.getIn(e.split('.').map(f => (Number.isNaN(Number(f)) ? f : parseInt(f, 10))));
        }
      });
      if (typeof value === 'string') {
        fieldValues.push({ fieldName: Text.Sentence(label), value: Text.StripHtml(result) });
        return (label === 'text'
          ? `<b>Source</b>: ${value}`
          : `<b>${Text.Sentence(label)}</b>: ${Text.StripHtml(result) || '-'}`);
      }
      const field = value.get('field');
      const transformation = value.get('func');
      const orAnd = either.map(e => ((e.indexOf('&') !== -1) ? e.split('&') : e));
      result = null;
      orAnd.forEach((e) => {
        if (!result) {
          if (typeof e === 'string') {
            result = alert.hasIn(['highlight', e, 0])
              ? alert.getIn(['highlight', e, 0])
              : alert.getIn(e.split('.'));
          } else if (Array.isArray(e)) {
            result = e.map(a =>
              (alert.hasIn(['highlight', a, 0])
                ? alert.getIn(['highlight', a, 0])
                : alert.getIn(a.split('.'))),
            );
          }
        }
      });
      const transformed = transformation ? transformation(result) : result;
      if (transformed !== 'IGNORE') fieldValues.push({ fieldName: Text.Sentence(label), value: Text.StripHtml(transformed) });
      return (label === 'text'
        ? `<b>Source</b>: ${transformation(field)}`
        : `${transformed !== 'IGNORE' ? `<b>${Text.Sentence(label)}</b>: ${Text.StripHtml(transformed || '-')}` : ''}`);
    })
    ?.merge(map({ location: `<b>Platform</b>: ${Text.Sentence(searchType)}` }))
    ?.filter(d => d)
    ?.reverse()
    ?.join('<br />');
  return fromJS([values, fieldValues]);
};


const basetypes = permissions => cachedGet(`${AlertingUrl}/data_sources`, {}, Status, 30000, {}, true)
  .then(res => (res.ok ? res.data : []))
  .then((res) => {
    const sources = res.map((v) => {
      const searchType = Common.Basetypes.BasetypeToSearchType(
        fromJS({ basetypes: v.basetype_terms }),
      );
      if (!searchType) return false;
      const app = fromJS(Apps).find(a => a.get('searchType') === searchType) || map();
      if (!permissions.some(p => app.get('test').test(p))) return false;
      return {
        id: v.id,
        basetypeTerms: v.basetype_terms,
        searchType,
        app,
        dataSourceName: v.name,
        title: app.get('label'),
        name: app.get('label'),
        test: app.get('test'),
      };
    })
      .filter(v => v);
    const heirarchy = fromJS(sources)
      .groupBy(v => v.get('searchType'))
      .map((v, k) => {
        if (v.count() === 1) return v.get(0);
        if (k === 'social') {
          // useNested is helpful for when  there isn't a
          // direct parent so need to use a list for ids
          return fromJS({
            id: v.map(s => s.get('id')),
            basetypeTerms: v.map(s => s.get('basetypeTerms')),
            searchType: k,
            app: v.getIn([0, 'app']),
            dataSourceName: v.getIn([0, 'app', 'label']),
            title: v.getIn([0, 'title']),
            name: v.getIn([0, 'name']),
            test: v.getIn([0, 'test']),
            useNested: true,
          });
        } else if (DeaAlerts.includes(k) || k === 'chats') {
          const parentIds = v.map(s => s.get('id'));
          const children = v.filterNot(c => c.get('dataSourceName') === (k === 'chats' ? 'chat' : k));
          return fromJS({
            id: parentIds,
            basetypeTerms: v.map(s => s.get('basetypeTerms')),
            searchType: k,
            app: v.getIn([0, 'app']),
            dataSourceName: v.getIn([0, 'app', 'label']),
            title: v.getIn([0, 'title']),
            name: v.getIn([0, 'name']),
            test: v.getIn([0, 'test']),
            children: children.map(s => fromJS({
              id: s.get('id'),
              basetypeTerms: s.get('basetypeTerms'),
              searchType: k,
              app: s.get('app'),
              parent: parentIds,
              dataSourceName: s.get('dataSourceName'),
              title: Text.Sentence(s.get('dataSourceName')),
              name: Text.Sentence(s.get('dataSourceName')),
              test: s.get('test'),
            })),
          });
        }
        return v;
      })
      .reduce((a, b) => a.push(b), list());
    return fromJS(heirarchy);
  })
  .catch(() => {
    // if (err.message !== 'canceled') retry;
  });

const bulkSaveSubscriptions = (profileId, ownerId, details) => {
  const useDelete = details.status === 'none' && details.receivesEmail == null;
  if (!useDelete) {
    const query = {
      owner_id: ownerId,
      profiles: [{
        id: profileId,
        receives_email: details.receivesEmail,
      }],
      keyword_active: true,
    };
    if (details.category) query.keyclass_ids = [details.category.get('id')];
    if (details.keywordText) query.keyword_text = details.keywordText;
    cachedPost(`${AlertingUrl}/keyword_subscriptions.bulk`, query, Status);
  } else {
    const query = {
      owner_id: ownerId,
      profile_ids: [profileId],
    };
    if (details.category) query.keyclass_ids = [details.category.get('id')];
    if (details.keywordText) query.keyword_text = details.keywordText;
    cachedDelete(`${AlertingUrl}/keyword_subscriptions.bulk`, {}, Status, 30000, query);
  }
};

const curatedLimit = (orgId, owners) => {
  const curatedQuery = {
    embed: 'curated_keyword_limit,industries,keyword_statistics,num_active_curated_keywords,keyword_curated_statistics_by_keyclass,has_enterprise_domains',
  };

  // check to see if the org's curated_limit and industries have already been fetched
  const index = owners.findIndex(v => v.get('id') === orgId);
  if (index && owners.hasIn([index, 'keyword_statistics'])) return null;

  return cachedGet(`${AlertingUrl}/owners/${orgId}`, curatedQuery, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then(res => ({
      curated_limit: res.curated_keyword_limit,
      industries: fromJS(res.industries),
      keyword_statistics: fromJS(res.keyword_statistics),
      num_active_curated_keywords: res.num_active_curated_keywords,
      keyword_curated_statistics_by_keyclass: fromJS(res.keyword_curated_statistics_by_keyclass),
      has_enterprise_domains: res.has_enterprise_domains,
    }))
    .catch(() => {
      // if (err.message !== 'canceled') retry;
    });
};

const deleteKeywords = (keywordIds, ownerId) => {
  const query = { owner_id: ownerId, keyword_id: keywordIds.join(',') };
  return cachedDelete(`${AlertingUrl}/keywords`, query, Status)
    .then(res => (res.ok ? res.data : {}))
    .then(() => {
      SearchActions.set(['search', 'info', 'message'], Messages.KeywordsDeleted);
    })
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
    });
};

const deleteNotificationProfiles = (recipientIds, ownerId) => {
  const query = { owner_id: ownerId, notification_profile_id: recipientIds.join(',') };
  return cachedDelete(`${AlertingUrl}/notification_profiles`, query, Status)
    .then(res => (res.ok ? res.data : {}))
    .then(() => {
      SearchActions.set(['search', 'info', 'message'], Messages.RecipientsDeleted);
    })
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
    });
};

const genBasetypeMappings = (basetypesList) => {
  const basetypeMappings = [];

  basetypesList.forEach((v) => {
    if (!DeaAlerts.includes(v.get('searchType')) && v.get('children')) {
      v.get('children').forEach((c) => {
        const basetype = c.getIn(['basetypeTerms', 2], '');
        const basetypeInfo = {
          key: basetype,
          label: c.get('name'),
          value: [basetype],
          id: c.get('id'),
          parent: c.get('searchType'),
        };
        basetypeMappings.push(basetypeInfo);
      });
    } else {
      const basetype = Common.Basetypes.SearchTypeToBasetype(v.get('searchType'));
      const basetypeInfo = {
        key: v.get('searchType'),
        label: v.get('name'),
        value: basetype.split(','),
        id: v.get('id'),
      };
      basetypeMappings.push(basetypeInfo);
    }
  });
  return basetypeMappings;
};

const keywordAggregation = (search, basetypesList, fqdn = false) => {
  const basetypeMappings = genBasetypeMappings(basetypesList);
  const basetypeQuery = basetypeMappings.map(v => v.value.join(' OR ')).join(' OR ');
  const query = map()
    .set('query', `+basetypes:(${basetypeQuery}) ${search} +sort_date:[now-30d/d TO now]`)
    .set('traditional_query', true)
    .set('size', 1)
    .set('fields', fqdn ? list(['enrichments']) : set([...SearchFields.Fields.communities, ...SearchFields.Fields.marketplaces]).toList())
    .set('aggregations', {
      'date-histogram-by-basetype': {
        field: 'sort_date',
        interval: 'day',
        basetypes: basetypeMappings.map(v => v.value).flat(),
      },
    })
    .filter(v => v)
    .toJS();
  return cachedPost(`${SearchUrl}`, query, Status)
    .then(res => (res.ok ? res.data : []))
    .then((res) => {
      const basetypeOrder = basetypeMappings.map(m => m.label);
      const data = [];
      const totalCounts = basetypeMappings.reduce((a, b) => ({
        ...a,
        [b.key]: 0,
      }), {});

      const dayBuckets = res.aggregations['date-histogram-by-basetype'].buckets;
      dayBuckets.forEach((v) => {
        const dataPoint = { key: moment.utc(v.key_as_string).format('YYYY-MM-DD') };
        const basetypeBuckets = v.group_by_basetype.buckets;

        basetypeBuckets.forEach((b) => {
          const basetypeInfo = basetypeMappings.find(m => m.value.includes(b.key));
          const dataPointKey = basetypeInfo.label;
          // add for nested basetypes without children like social
          dataPoint[String(dataPointKey)] = (dataPoint[String(dataPointKey)] || 0) + b.doc_count;
          totalCounts[basetypeInfo.key] += b.doc_count;
        });
        const toSortDataPoint = orderedMap(dataPoint);
        const sortedDataPoint = toSortDataPoint.sortBy((_, k) => basetypeOrder.indexOf(k));
        data.push(sortedDataPoint);
      });
      return { data, totals: totalCounts, colors: basetypeColorMap, basetypeMappings };
    })
    .catch((err) => {
      if (err.message !== 'canceled') {
        if (/ECONNABORTED/.test(err.code)) {
          SearchActions.set(['search', 'info', 'message'], Messages.SearchTimeout);
          return { data: [], totals: {}, colors: {}, timeout: true };
        }
        SearchActions.set(['search', 'info', 'message'], Messages.SearchError);
        return { data: [], totals: {}, colors: {} };
      }
      return 'canceled';
    });
};

const keywordDatasources = (ids, keywords, basetypesList) => {
  const query = {
    keyword_ids: ids.join(),
  };
  return cachedGet(`${AlertingUrl}/keyword_data_sources`, query, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then((res) => {
      const updatedKeywords = [];
      res.forEach((d) => {
        const codeRepositorySource = basetypesList.find(v => DeaAlerts.includes(v.get('searchType'))) || map();
        let dataSources = d.data_sources
          .map(v => (v.enabled ? v.data_source_id : null))
          .filter(v => v);
        // due to thee AlertingKeywordDialog using toggle switches for DEA sources, need to update
        // the format dea is stored on the keyword
        const dea = dataSources.filter(v => (codeRepositorySource.get('id') || []).includes(v)) || [];
        dataSources = dataSources.filter(v => !(codeRepositorySource.get('id') || []).includes(v));
        const keywordIndex = keywords.findIndex(v => v.get('id') === d.keyword_id);
        const keyword = (keywordIndex !== -1)
          ? keywords.get(keywordIndex)
          : map();
        updatedKeywords.push({
          index: (keywordIndex !== -1 || res.length > 1) ? keywordIndex : 0,
          keyword: keyword
            .set('dataSources', fromJS(dataSources))
            .set('dea', fromJS(dea)
              .reduce((obj, v) => obj.set(v, true), map())),
        });
      });
      return fromJS(updatedKeywords);
    });
};

const keywordSubscriptions = (id, recipients, keywords) => {
  const query = {
    keyword_ids: id,
  };
  return cachedGet(`${AlertingUrl}/keyword_subscriptions`, query, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then((res) => {
      const profiles = recipients.map((v) => {
        const isSubscribed = res[0].profile_ids.find(s => s === v.get('id'));
        const receivesEmail = res[0].receives_email.find(s => s === v.get('id'));
        return map({ endpoint: v.get('endpoint'), id: v.get('id'), isSubscribed: isSubscribed != null, receivesEmail: receivesEmail != null });
      });
      const keywordIndex = keywords.findIndex(v => v.get('id') === id);
      const updatedKeywords = keywords.set(keywordIndex, keywords.get(keywordIndex).set('keywordSubscriptions', profiles.sortBy(v => v.get('endpoint'))));
      return {
        keyword: keywords.get(keywordIndex).set('keywordSubscriptions', profiles.sortBy(v => v.get('endpoint'))),
        keywords: fromJS(updatedKeywords),
      };
    });
};

const load = async (key, permissions = list()) => {
  const query = (key === 'owners')
    ? {
      is_curator: false,
      active: true,
      ...(!permissions.some(p => /dat.rta.r/.test(p)) && { embed: 'has_enterprise_domains' }),
    }
    : {};
  return cachedGet(`${AlertingUrl}/${key}`, query, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then((res) => {
      const response = fromJS(res.map(v => ({
        ...v,
        salesforce_id: Text.SFIDConversion(v.salesforce_id)['18'],
        originalSID: v.salesforce_id,
        active: true,
      })));
      if (key === 'keyword_classes') {
        const enterpriseDomainKeyclassId = response.find(v => v.get('name') === 'enterprise_domain')?.get('id');
        return {
          keywordClasses: response,
          enterpriseDomainKeyclassId,
        };
      }
      if (key === 'owners') {
        const activeOrgs = response.map(v => fromJS({ salesforce_id: Text.SFIDConversion(v.get('salesforce_id'))['18'], originalSID: v.get('originalSID') || v.get('salesforce_id'), name: v.get('name'), owner_id: v.get('id') }));
        // TODO:
        // const keyclassId = this.state.getIn(['alerting', 'enterprise_domain_keyclass_id']);
        // const keywordQuery = {
        //   keyclass_id: keyclassId,
        // };
        // if (keyclassId) {
        //   cachedGet(`${AlertingUrl}/keywords`, keywordQuery, Status, 30000, {}, true)
        //     .then(keywords => (keywords.ok ? keywords.data : []))
        //     .then((keywords) => {
        //       const grouped = fromJS(keywords).groupBy(v => v.get('owner_id'));
        //       const hasEnterprise = activeOrgs.filter(v => grouped.has(v.get('owner_id')));
        //       UserActions.set(['user', 'activeOrganizations'], hasEnterprise);
        //     });
        // }
        // UserActions.set(['user', 'activeOrganizations'], activeOrgs);
        const { query: params } = History.getCurrentLocation();
        const { owner_id } = params;
        return {
          owners: response,
          ownerId: owner_id || res?.[0]?.id,
          activeOrgs,
        };
      }
      return response;
    });
};

const loadFilters = (existingFilters = {}) => {
  const params = History.getCurrentLocation().query;
  const { limit, skip } = params;
  const filters = fromJS({ ...{ text: existingFilters.text || null }, ...params }).map(v => v);
  return filters.merge({ limit, skip }).filter(v => v);
};

const loadAlerts = (
  email,
  cursor_id,
  dea = false,
  previousAlerts,
  recipients,
  keywordClasses,
  basetypesList,
) => {
  const query = {};
  const filters = { ...loadFilters().toJS() };
  if (!recipients) return fromJS({ total: 0, data: [], no_profile: true });
  const notificationProfile = (!filters.notification_profile)
    ? recipients.find(v => v.get('value').toLowerCase() === email.toLowerCase())
    : recipients.find(v => v.get('id') === filters.notification_profile);

  if (!notificationProfile) {
    return fromJS({ total: 0, data: [], no_profile: true });
  }

  if (filters.notification_profile_id !== 'true') {
    query.notification_profile_id = !filters.notification_profile
      ? notificationProfile.get('id')
      : filters.notification_profile;
  }
  if (filters.date) {
    const { sinceDate, untilDate } = SearchUtils.parseDate({
      date: filters.date,
      since: filters.since,
      until: filters.until,
    });
    query.time_range = `${sinceDate},${untilDate}`;
  }

  if (dea) {
    const basetype_ids = basetypesList
      .filter(v => DeaAlerts.includes(v.get('searchType')))
      .map(v => Common.Basetypes.SearchTypeToBasetype(v.get('searchType')));
    query.basetypes = basetype_ids.join(',');
    query.basetypes = `${query.basetypes},infrastructure`;
  } else if (filters.platform) {
    const basetypes_ids = [];
    filters.platform.split(',').forEach((v) => {
      // eslint-disable-next-line security/detect-non-literal-regexp
      const basetype = basetypesList.find(bv => new RegExp(v || '', 'ig').test(bv.get('searchType')));
      if (basetype) {
        basetypes_ids.push(Common.Basetypes.SearchTypeToBasetype(basetype.get('searchType')));
      }
    });

    query.basetypes = basetypes_ids.join();
  } else if (!dea) {
    const basetype_ids = basetypesList
      .filterNot(v => DeaAlerts.includes(v.get('searchType')))
      .map(v => Common.Basetypes.SearchTypeToBasetype(v.get('searchType')));
    query.basetypes = basetype_ids.join(',')?.replace(/\s?OR\s?/, ',');
  }

  if (filters.categories) {
    const keyclass_ids = [];
    filters.categories.split(',').forEach((v) => {
      const kc = keywordClasses.find(kcv => kcv.get('name') === v);
      if (kc) {
        keyclass_ids.push(kc.get('id'));
      }
    });
    query.keyclass_ids = keyclass_ids.join(',');
  }
  if (filters.keyword) {
    [, query.keyword_ids] = filters.keyword.split('::');
  }
  if (filters.tags) {
    query.tags = filters.tags.replace('starred', 'flagged');
  }
  if (cursor_id) {
    query.cursor_id = cursor_id;
  }

  const simplifiedQuery = fromJS(query)
    .filter(v => v)
    .toJS();

  const parseHighlights = (v) => {
    const highlightSections = v?.highlight_sections || v?.source?.highlight_sections; // Shodan response return differently
    switch (v.source.basetypes.length > 0) {
      case v.source.basetypes.includes('cloud'):
        return [
          `<b>Status:</b> ${v?.highlights[0] ? Text.StripHtml(v.highlights[0]) : '-'}<br />
           <b>Num File:</b> ${v?.highlights[1] ? Text.StripHtml(v.highlights[1]) : '-'}<br />`,
        ];
      case v.source.basetypes.includes('internet'):
        return [
          `<b>Ports:</b> ${highlightSections?.ports ? Text.StripHtml(highlightSections.ports.join(', ')) : '-'}<br />
           <b>Services:</b> ${highlightSections?.services ? Text.StripHtml(highlightSections.services.join(', ')) : '-'}<br />
           <b>Num. of Vulnerabilities</b> ${v?.source?.vulns?.length ? v?.source?.vulns?.length : 'N/A'}`,
        ];
      default: return [Text.StripHtml(v.highlights[0])];
    }
  };

  return cachedGet(`${AlertingUrl}/alerts`, simplifiedQuery, Status)
    .then(res => (res.ok ? res.data : []))
    .then(res => ({
      skip: 0,
      limit: 10000,
      updated: moment.utc(),
      count: res.count,
      total: res.count,
      data: [
        ...res.alerts
          .map((v, k) => ({
            ...v,
            index: k,
            fpid: v.fpid,
            keyword_id: v.keyword.keyword_id,
            keyword_text: v.keyword.keyword_text,
            keyword_name: v.keyword.keyword_name,
            keyword_ip: v.source.ip_address,
            highlights: parseHighlights(v),
            basetypes: Common.Basetypes.ExtraBasetypes(v.basetypes),
            source: { ...v.source,
              basetypes: Common.Basetypes.ExtraBasetypes(v.source.basetypes) },
            icon: Common.Generic.Icon(list(Common.Basetypes.ExtraBasetypes(v.source.basetypes))),
            details: alertDetails(fromJS({
              ...v.source,
              basetypes: Common.Basetypes.ExtraBasetypes(v.source.basetypes),
            })),
            time_stamp: v.ts,
          })),
      ],
      cursor_id: res.cursor_id,
    }))
    .then((res) => {
      if (cursor_id) {
        res.count += previousAlerts.get('count');
        res.total += previousAlerts.get('total');
        res.data = [...previousAlerts.get('data').toJS(), ...res.data];
      }
      return fromJS(res);
    })
    .catch((err) => {
      if (err.message !== 'canceled') {
        SearchActions.set(['search', 'info', 'message'], Messages.SearchError);
        // if (!cursor_id) empty()([...key, !dea ? 'alerts' : 'dea']);
        return previousAlerts;
      }
      return 'canceled';
    });
};

const loadKeyword = (keywordId, ownerId, keywordClasses) => {
  const keywordsQuery = {
    embed: 'subscriptions,keywords,curator,profiles,keyword_subscriptions',
  };
  return cachedGet(`${AlertingUrl}/keywords/${keywordId}`, keywordsQuery, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : {}))
    .then(res => ({
      ...res,
      name: res.name !== '<Not Set>' ? res.name : '',
      index: 0,
      curation: { status: res.is_curated },
      automation: { status: !res.is_curated },
      // Needed for editor deletion
      created: moment.utc(res.created_timestamp).toISOString(),
      keyclass: keywordClasses.find(c => c.get('id') === res.keyclass_id),
    }))
    .catch(() => {
      // if (err.message !== 'canceled') retry;
    });
};

const loadExcludedKeywordClasses = () => Api.get('/ui/v4/alerts/keyword_classes')
  .then((res) => {
    if (!res.ok) {
      throw new Error('Unable to fetch keyword classes');
    }
    return res.data;
  })
  .then(data => data
    .filter(item => /twitter|email_domain|enterprise_domain|internet_infrastructure|cloud_infrastructure|bin|cookie_domain|enterprise_affected_domain/ig.test(item.name)));

const loadKeywords = async (
  ownerId,
  keywordClasses,
  filters,
  basetypesList,
  prm,
  filterByPerms = false,
) => {
  const {
    page_size,
    page_number,
    status,
    keyclass,
    delivery,
    sort,
    sources: sourcesFilter,
    ...rest
  } = filters;

  // Remove excluded keywords
  const excludeKeyclassIds = [];
  const excludedClasses = await loadExcludedKeywordClasses(keywordClasses);
  let filtered = [];
  // Enable bin keywords for users with permission
  if (prm && prm?.some(p => /csb/ig.test(p))) {
    filtered = excludedClasses.filter(item => !/bin/ig.test(item.name));
  } else {
    filtered = [...excludedClasses];
  }

  if (prm && filterByPerms) {
    // On the alerting recipients tab, we need to show keywords based on user permissions
    filtered = excludedClasses.filter((v) => {
      if (/twitter/ig.test(v.name) && !prm.some(p => /twtr/.test(p))) return true;
      if (/bin/ig.test(v.name) && !prm.some(p => /csb/.test(p))) return true;
      if (/internet_infrastructure|cloud_infrastructure/ig.test(v.name) && !prm.some(p => /dm/.test(p))) return true;
      if (/enterprise_domain|email_domain/ig.test(v.name) && !prm.some(p => /edm/.test(p))) return true;
      if (/cookie_domain/ig.test(v.name)) return false;
      if (/enterprise_affected_domain/ig.test(v.name)) return false;
      return false;
    });
  }

  filtered.forEach(item => excludeKeyclassIds.push(item.id));

  const keywordsQuery = {
    page_number,
    page_size,
    active: !status ? undefined : status === 'ACTIVE',
    keyclass_id: keyclass,
    is_curated: (delivery === '') ? undefined : delivery === 'CURATED',
    embed: 'subscriptions,keywords,curator,profiles,keyword_subscriptions',
    owner_id: ownerId,
    sort: sort || '-created_timestamp',
    ...rest,
  };
  if (excludeKeyclassIds.length > 0) {
    keywordsQuery.exclude_keyclass_ids = excludeKeyclassIds.join();
  }
  // handle sourceIds
  const sourceIds = getSourceIds(basetypesList, prm, sourcesFilter);
  if (sourceIds.size) {
    keywordsQuery.data_source_ids = sourceIds.join();
  }

  return cachedGet(`${AlertingUrl}/keywords`, keywordsQuery, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then((res) => {
      const fetchingAll = !filters || filters.page_number == null;
      const data = [...(fetchingAll ? res : res.data)
        .map((v, k) => ({
          ...v,
          name: v.name !== '<Not Set>' ? v.name : '',
          index: k,
          curation: { status: v.is_curated },
          automation: { status: !v.is_curated },
          // Needed for editor deletion
          created: moment.utc(v.created_timestamp).toISOString(),
          keyclass: keywordClasses.find(c => c.get('id') === v.keyclass_id),
        }))].filter(v => v);
      return fetchingAll ? {
        keywords: fromJS({
          skip: 0,
          limit: 10000,
          count: data.length,
          total: data.length,
          data,
          partial: false,
        }),
      } : {
        keywords: fromJS({
          ...res,
          data,
          partial: true,
        }),
      };
    })
    .catch(err =>
       err,
      // if (err.message !== 'canceled') retry;
    );
};

const loadNotificationProfiles = (ownerId, products) => {
  const recipientsQuery = {
    embed: 'subscriptions',
    owner_id: ownerId,
  };
  return cachedGet(`${AlertingUrl}/notification_profiles?sort=endpoint`, recipientsQuery, Status, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then(res => fromJS({
      skip: 0,
      limit: 10000,
      updated: moment.utc(),
      count: res.length,
      total: res.length,
      data: [
        ...res
          .map((v, k) => ({
            ...v,
            index: k,
            // updated_timestamp: moment.utc(v.created_timestamp).toISOString(),
            name: v.endpoint,
            value: v.endpoint,
            curated: (v.subscriptions.find((prod) => {
              const curatedProduct = products.find(p => p.get('pretty_name') === 'Curated');
              return prod.product_id === curatedProduct.get('id');
            }) || {}).active || false,
            industry: v.subscriptions.some(sub => sub.industry_id != null && sub.active),
            credential: (v.subscriptions.find((prod) => {
              const credentialProduct = products.find(p => p.get('pretty_name') === 'Credentials');
              return prod.product_id === credentialProduct.get('id');
            }) || {}).active || false,
            domain_monitoring: (v.subscriptions.find((prod) => {
              const domainMonitoringProduct = products.find(p => p.get('pretty_name') === 'Domain Monitoring');
              return prod.product_id === domainMonitoringProduct.get('id');
            }) || {}).active || false,
            infrastructure_monitoring: (v?.subscriptions?.find((prod) => {
              const infrastructureMonitoringProduct = products?.find(p => p.get('pretty_name') === 'Internet Infrastructure Monitoring');
              return prod.product_id === infrastructureMonitoringProduct?.get('id');
            }) || {}).active || false,
            cloud_infrastructure: (v?.subscriptions?.find((prod) => {
              const CloudInfrastructureMonitoringProduct = products?.find(p => p.get('pretty_name') === 'Cloud Infrastructure Monitoring');
              return prod.product_id === CloudInfrastructureMonitoringProduct?.get('id');
            }) || {}).active || false,
            twitter: (v?.subscriptions?.find((prod) => {
              const twitterProduct = products?.find(p => p.get('pretty_name') === 'Twitter Alerting');
              return prod.product_id === twitterProduct?.get('id');
            }) || {}).active || false,
            products: v.subscriptions.map(prod =>
              products.find(p => p.get('id') === prod.product_id)
                .set('active', prod.active)),
          })),
      ],
    }))
    .then((res) => {
      if (!res) return map();
      return res;
    })
    .catch(() => {
      // if (err.message !== 'canceled') retry;
    });
};

const exportData = async (
  type,
  fieldList,
  filters,
  ownerId,
  keywordClasses,
  recipients,
  basetypesList,
  prm,
) => {
  let buffer = '';
  const date = moment.utc().format('MMM-DD-YYYY_HHmm');
  const filename = `Flashpoint-Alerting-${type}-${date}.csv`;
  const keyclass = (filters.get('category')) ? keywordClasses.find(v => v.get('name') === filters.get('category')) : null;

  let data;
  if (type === 'recipients') data = recipients;
  else {
    await Promise.all([
      loadKeywords(
        ownerId,
        keywordClasses,
        {
          embed: 'keyword_subscriptions',
          status: filters.get('status', ''),
          keyclass: keyclass ? keyclass.get('id') : null,
          delivery: filters.get('delivery', ''),
          sources: filters.get('sources', ''),
          text: filters.get('text', null),
        },
        basetypesList,
        prm),
    ]).then(([res]) => {
      data = fromJS(res.keywords);
    });
  }
  const indexed = fieldList.map(v => v.id).join('').includes('#');
  const fields = [...fieldList]
    .filter(v => !/^id$|recipient.subscriptions$/.test(v.id))
    .reduce((a, b) => (b.labels
      ? a.concat(b.id.split('|').map((f, k) => ({ ...b, id: f, label: b.labels.split('|').splice(k).shift() })))
      : a.concat(b)), []);

  fields.forEach((v) => { buffer += `${Text.Sentence(v.label)},`; });
  const conversion = {
    'curation.status': v => (v ? 'Curated' : 'Automated'),
    name: v => Text.SanitizeCsv(v),
    value: v => Text.SanitizeCsv(v),
    active: v => (v ? 'Active' : 'Inactive'),
    curated: v => (v ? 'Yes' : 'No'),
    industry: v => (v ? 'Yes' : 'No'),
    credential: v => (v ? 'Yes' : 'No'),
    products: v => v.map(p => p.pretty_name).join('/'),
    keyword_subscriptions: v => `${v.length} Subscriber(s)`,
  };

  buffer = `${buffer.slice(0, -1)}\n`;
  data.get('data').forEach((row, index) => {
    buffer += indexed ? `${index + 1},` : '';
    [...fields
      .filter(field => field.label !== '#')]
      .forEach((field) => {
        const value = row.getIn(field.id.split('.')) || '';
        const parsed = list.isList(value) || map.isMap(value) ? value.toJS() : value;
        const text = conversion[field.id] ? conversion[field.id](parsed) : Text.StripHtml(parsed);
        buffer += `${Text.SanitizeCsv(text)},`;
      });
    buffer = `${buffer.slice(0, -1).trim()}\n`; });

  const blob = new Blob([`\ufeff${buffer}`], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
  SearchActions.set(['search', 'info', 'message'], null);
};

const saveAlertTags = (action, body, alerts) => {
  if (action === 'add') {
    return cachedPost(`${AlertingUrl}/alerts/tags`, body, Status)
      .then(res => (res.ok ? res.data : {}))
      .then(() => {
        const alertIndex = alerts.findIndex(v => v.get('alert_id') === body[0].alert_id);
        const updateAlert = alerts.get(alertIndex)
          .set('tags', map(body[0].tags));
        SearchActions.set(['search', 'info', 'message'], Messages.TagsUpdated);
        return {
          alert: updateAlert,
          index: alertIndex,
        };
      });
  }
  return cachedDelete(`${AlertingUrl}/alerts/tags`, {}, Status, 30000, body)
    .then(res => (res.ok ? res.data : {}))
    .then(() => {
      const alertIndex = alerts.findIndex(v => v.get('alert_id') === body[0].alert_id);
      const alert = alerts.get(alertIndex);
      const updateAlert = alert
        .set('tags', alert.get('tags').filterNot((v, k) => Object.prototype.hasOwnProperty.call(body[0].tags, k)));
      SearchActions.set(['search', 'info', 'message'], Messages.TagsUpdated);
      return {
        alert: updateAlert,
        index: alertIndex,
      };
    });
};

const saveKeywordDataSources = (dataSources, ids = list(), type, basetypesList, data) => {
  if (!ids.isEmpty()) {
    const sources = basetypesList.map((v) => {
      if (!v.has('children')) {
        const enabled = dataSources.includes(v.get('id'));
        if (typeof v.get('id') === 'string') return { data_source_id: v.get('id'), enabled };
        const multipleSources = v.get('id').map(m => ({ data_source_id: m, enabled: dataSources.includes(m) }));
        return list([...multipleSources]);
      }
      // If any of the parent's children are in the list, set the parent as true
      // though if the parent doesn't have a direct id (social), ignore it because
      // its children will have already set themselves as true or false
      const parentId = v.get('id');
      const childrenIds = v.get('children').map(c => c.get('id'));
      const childrenEnabled = childrenIds.map(c => ({
        data_source_id: c,
        enabled: dataSources.includes(c),
      }));
      const isParentEnabled = childrenEnabled.some(c => c.enabled);
      return (typeof parentId === 'string')
        ? list([{ data_source_id: parentId, enabled: isParentEnabled }, ...childrenEnabled])
        : list([...childrenEnabled]);
    })
      .flatten();
    const requestData = {
      keyword_ids: ids,
      data_sources: sources
        .filterNot(v => DeaAlerts.includes(v.data_source_id))
        .filter(v => v.data_source_id !== NIL_UUID),
    };
    return cachedPost(`${AlertingUrl}/keyword_data_sources`, requestData, Status)
      .then(res => (res.ok ? res.data : {}))
      .then(() => {
        let updatedData = data;
        ids.forEach((id) => {
          const index = data.findIndex(v => v.get('id') === id);
          if (index === -1) return;
          const updateKeyword = data.get(index)
            .set('dataSources', dataSources);
          updatedData = updatedData.set(index, updateKeyword);
        });
        return updatedData;
      })
      .catch((err) => {
        const id = moment.utc().unix();
        const { data: d } = err.response;
        SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
      });
  }
  return new Promise().resolve(data);
};

const saveKeywordSubscriptions = (keywordId, subscriptions, notification) => {
  const promises = [];
  const added = [];
  const addedEmails = [];
  const deleted = [];
  const updatedAdd = [];
  const updatedDelete = [];
  subscriptions.forEach((v) => {
    if (v.get('hasAdded')) {
      added.push(v.get('notification_profile_id', v.get('id')));
      if (v.get('hasChangedEmail')) addedEmails.push(v.get('notification_profile_id', v.get('id')));
    } else if (v.get('hasDeleted')) {
      deleted.push(v.get('notification_profile_id', v.get('id')));
    } else if (v.get('hasChangedEmail')) {
      if (v.get('receivesEmail')) {
        updatedAdd.push(v.get('notification_profile_id', v.get('id')));
      } else {
        updatedDelete.push(v.get('notification_profile_id', v.get('id')));
      }
    }
  });
  if (added.length > 0) {
    const addedQuery = [{
      keyword_id: keywordId,
      profile_ids: added,
      receives_email: addedEmails,
    }];
    const addedPromise = cachedPost(`${AlertingUrl}/keyword_subscriptions`, addedQuery, Status)
      .then(res => (res.ok ? res.data : []));
    promises.push(addedPromise);
  }
  if (deleted.length > 0) {
    const deleteBody = [{ keyword_id: keywordId, profile_ids: deleted }];
    const deletedPromise = cachedDelete(`${AlertingUrl}/keyword_subscriptions`, {}, Status, 30000, deleteBody)
      .then(res => (res.ok ? res.data : []));
    promises.push(deletedPromise);
  }
  if (updatedAdd.length > 0) {
    const updatedAddQuery = [{
      keyword_id: keywordId,
      profile_ids: [],
      receives_email: updatedAdd,
    }];
    const updatedAddPromise = cachedPost(`${AlertingUrl}/keyword_subscriptions`, updatedAddQuery, Status, 30000)
      .then(res => (res.ok ? res.data : []));
    promises.push(updatedAddPromise);
  }
  if (updatedDelete.length > 0) {
    const updatedDeleteQuery = [{
      keyword_id: keywordId,
      profile_ids: [],
      receives_email: updatedDelete,
    }];
    const updatedDeletePromise = cachedDelete(`${AlertingUrl}/keyword_subscriptions`, {}, Status, 30000, updatedDeleteQuery)
      .then(res => (res.ok ? res.data : []));
    promises.push(updatedDeletePromise);
  }

  Promise.all(promises)
    .then(() => notification && SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess))
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
    });
};

const saveKeywords = (
  toSave,
  ids = list(),
  type,
  filters,
  keywordClasses,
  basetypesList,
  data,
  ownerId,
  // prm,
) => {
  if (!ids.isEmpty()) {
    const {
      status,
      keyclass,
      delivery,
      // sources: sourcesFilter,
    } = filters.toJS();
    const update = { ...toSave };
    let url = `${AlertingUrl}/keywords?keyword_id=${ids.join(',')}`;
    // Selected more than visible to user
    const useBulk = ids.some(v => !v);
    if (useBulk) {
      url = `${AlertingUrl}/keywords.bulk?owner_id=${ownerId}`;
      if (status) {
        url += `&keyword_active=${status === 'ACTIVE'}`;
      }
      if (keyclass) {
        url += `&keyclass_ids=${keyclass}`;
      }
      if (delivery) {
        url += `&keyword_curated=${delivery === 'CURATED'}`;
      }
      // const sourceIds = getSourceIds(basetypesList, prm, sourcesFilter);
      // if (sourceIds.size) {
      //   url += `&data_source_ids=${sourceIds.join()}`;
      // }

      const selectedIds = ids.filter(v => v);
      let excludedIds = [];
      if (selectedIds.size !== data.size) {
        excludedIds = data.filter(v => !selectedIds.includes(v.get('id'))).map(v => v.get('id'));
        if (excludedIds.size) {
          url += `&exclude_keyword_ids=${excludedIds.join()}`;
        }
      }
    }

    return cachedPut(url, update, Status)
      .then(res => (res.ok ? res.data : {}))
      .then(async () => {
        let updatedData = data;
        const subscriptionPromises = [];
        const dataSourcePromises = [];
        ids.forEach((id) => {
          const index = data.findIndex(v => v.get('id') === id);
          if (index === -1) return;
          const name = (toSave.name != null) ? toSave.name : data.getIn([index, 'name'], '');
          const keyclass_id = (toSave.keyclass_id != null) ? toSave.keyclass_id : data.getIn([index, 'keyclass_id']);
          const updateKeyword = data.get(index)
            .set('name', (name === '<Not Set>') ? '' : name)
            .set('value', (toSave.value != null) ? toSave.value : data.get(index).get('value'))
            .set('active', (toSave.active != null) ? toSave.active : data.get(index).get('active'))
            .set('is_curated', (toSave.is_curated != null) ? toSave.is_curated : data.get(index).get('is_curated'))
            .setIn(['curation', 'status'], (toSave.is_curated != null) ? toSave.is_curated : data.get(index).get('is_curated'))
            .setIn(['automation', 'status'], (toSave.is_curated != null) ? !toSave.is_curated : !data.get(index).get('is_curated'))
            .set('keyclass_id', keyclass_id)
            .set('keyclass', keywordClasses.find(c => c.get('id') === keyclass_id));
          updatedData = updatedData.set(index, updateKeyword);
          // Update subscriptions
          if (toSave.keywordSubscriptions) {
            const newAndChangedSubscriptions = toSave.keywordSubscriptions.map((v) => {
              const currentSub = data.getIn([index, 'keyword_subscriptions'], list()).find(s => s.get('id') === v.get('id') || s.get('notification_profile_id') === v.get('id'));
              return v
                .set('hasAdded', !currentSub)
                .set('hasChangedEmail', (!currentSub && v.get('receivesEmail')) || (currentSub && v.get('receivesEmail') !== currentSub.get('receivesEmail')));
            });
            const deletedSubscriptions = data.getIn([index, 'keyword_subscriptions'], list()).map((v) => {
              const currentSub = toSave.keywordSubscriptions.find(s => s.get('id') === v.get('id') || s.get('id') === v.get('notification_profile_id'));
              if (!currentSub) {
                return v
                  .set('hasDeleted', true);
              }
              return null;
            });

            const changedSubscriptions = newAndChangedSubscriptions.concat(deletedSubscriptions)
              .filter(v => v && (v.get('hasAdded') || v.get('hasDeleted') || v.get('hasChangedEmail')));
            if (changedSubscriptions.size > 0) {
              const subPromise = saveKeywordSubscriptions(id, changedSubscriptions, false);
              subscriptionPromises.push(subPromise);
            }
          }
        });
        if (toSave.dataSources) {
          const dataSourcePromise = saveKeywordDataSources(
            toSave.dataSources,
            ids,
            type,
            basetypesList,
            updatedData,
          );
          dataSourcePromises.push(dataSourcePromise);
        }
        if (dataSourcePromises.length > 0 || subscriptionPromises.length > 0) {
          await Promise.all([
            ...dataSourcePromises,
            ...subscriptionPromises,
          ]).then(([updatedWithDataSources]) => {
            let updatedWithExtras = updatedWithDataSources || updatedData;
            if (toSave.keywordSubscriptions) {
              ids.forEach((s) => {
                const index = updatedWithExtras.findIndex(u => u.get('id') === s);
                if (index === -1) return;
                const subscriptions = toSave.keywordSubscriptions.map(sub => fromJS({
                  keyword_id: s,
                  notification_profile_id: sub.get('id'),
                  receives_email: sub.get('receivesEmail'),
                }));
                const updateKeyword = updatedWithExtras.get(index)
                  .set('keyword_subscriptions', fromJS(subscriptions));
                updatedWithExtras = updatedWithExtras.set(index, updateKeyword);
              });
              updatedData = fromJS(updatedWithExtras);
            }
          });
        }
        SearchActions.set(['search', 'info'], fromJS({ message: 'Keywords Updated' }));
        return updatedData;
      })
      .catch((err) => {
        const id = moment.utc().unix();
        const { data: d } = err.response;
        const messages = d?.detail || d?.title || err.message;
        SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(messages, id));
        return data;
      });
  }
  const updates = toSave.map(v => ({
    ...v,
    owner_id: ownerId,
    keywordSubscriptions: undefined,
    dataSources: undefined,
  }));
  return cachedPost(`${AlertingUrl}/keywords.bulk?owner_id=${ownerId}`, updates, Status)
    .then(res => (res.ok ? res.data : {}))
    .then(async (res) => {
      let newIds = list();
      let updatedData = data;
      res.forEach((v, i) => {
        const addition = { ...v,
          index: data.count() + i,
          curation: map({ status: v.is_curated }),
          automation: map({ status: !v.is_curated }),
          // Needed for editor deletion
          created: moment.utc(v.created_timestamp).toISOString(),
          keyclass: keywordClasses.find(c => c.get('id') === v.keyclass_id),
        };
        updatedData = updatedData.unshift(fromJS(addition));
        newIds = newIds.push(v.id);
      });
      const subscriptionPromises = toSave.map((s, i) => {
        if (s.keywordSubscriptions) {
          return saveKeywordSubscriptions(
            newIds.get(i),
            fromJS(s.keywordSubscriptions.map(sub => fromJS(sub))),
            false,
          );
        }
        return null;
      })
        .filter(s => s);
      await Promise.all([
        saveKeywordDataSources(toSave[0].dataSources, newIds, type, basetypesList, updatedData),
        ...subscriptionPromises,
      ]).then(([updatedWithDataSources]) => {
        let updatedWithSubscriptions = updatedWithDataSources;
        newIds.forEach((s, i) => {
          const index = updatedWithDataSources.findIndex(u => u.get('id') === s);
          if (index === -1) return;
          const subscriptions = toSave[Number(i)].keywordSubscriptions.map(sub => fromJS({
            keyword_id: s,
            notification_profile_id: sub.id,
            receives_email: sub.receivesEmail,
          }));
          const updateKeyword = updatedWithDataSources.get(index)
            .set('keyword_subscriptions', fromJS(subscriptions));
          updatedWithSubscriptions = updatedWithSubscriptions.set(index, updateKeyword);
        });
        updatedData = fromJS(updatedWithSubscriptions);
      });
      SearchActions.set(['search', 'info'], fromJS({ message: 'Keywords Added' }));
      return updatedData;
    })
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      const messages = d?.detail || d?.title || err.message;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(messages, id));
      return data;
    });
};

const saveNotificationProfile = (profile, type, data, products, ownerId, industries = list()) => {
  const {
    curated,
    industry,
    credential,
    domainMonitoring,
    cloudInfrastructure,
    infrastructureMonitoring,
    twitter,
    ...obj
  } = profile;

  // Remove unused subscriptions props
  if (obj.subscriptions) {
    obj.subscriptions = obj.subscriptions
      .map((v) => {
        const sub = { ...v };
        delete sub.id;
        delete sub.keyword_set_id;
        return sub;
      });
  }

  if (obj.id) {
    return cachedPut(`${AlertingUrl}/notification_profiles/${obj.id}`, obj, Status)
      .then(res => (res.ok ? res.data : {}))
      .then((res) => {
        const index = data.findIndex(v => v.get('id') === res.id);
        let update = data.get(index)
          .set('endpoint', res.endpoint)
          .set('active', res.active)
          .set('curated', curated)
          .set('industry', industry)
          .set('credential', credential)
          .set('domain_monitoring', domainMonitoring)
          .set('infrastructure_monitoring', infrastructureMonitoring)
          .set('cloud_infrastructure', cloudInfrastructure)
          .set('twitter', twitter)
          .set('name', res.endpoint)
          .set('value', res.endpoint);
        // Update the subscriptions
        if (obj.subscriptions) {
          obj.subscriptions.forEach((sub) => {
            const subscriptionIndex = update.get('subscriptions').findIndex(s => s.get('product_id') === sub.product_id);
            if (subscriptionIndex !== -1) {
              // Update the subscription
              const subscription = update.getIn(['subscriptions', subscriptionIndex])
                .set('active', sub.active);
              update = update.setIn(['subscriptions', subscriptionIndex], subscription);
              const product = products.find(p => p.get('id') === subscription.get('product_id'))
                .set('active', sub.active);
              update = update.setIn(['products', subscriptionIndex], product);
            } else {
              // Add the subscription
              const subscriptions = update.get('subscriptions')
                .push(map(sub));
              update = update.set('subscriptions', subscriptions);
              const product = products.find(p => p.get('id') === sub.product_id)
                .set('active', sub.active);
              update = update.setIn(['products'], update.get('products').push(product));
            }
          });
        }
        SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess);
        return {
          index,
          recipient: update,
        };
      })
      .catch((err) => {
        const id = moment.utc().unix();
        const { data: d } = err.response;
        SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
      });
  }
  const update = { ...obj, owner_id: ownerId };
  return cachedPost(`${AlertingUrl}/notification_profiles`, update, Status)
    .then(res => (res.ok ? res.data : {}))
    .then(async (res) => {
      const product = products.find(p => p.get('pretty_name') === 'Curated');
      const curatedSubscription = {
        active: curated,
        notification_profile_id: res.id,
        owner_id: res.owner_id,
        product_id: product.get('id'),
      };
      const industryProduct = products.find(p => p.get('pretty_name') === 'Industry');
      const industryIds = industries.map(ind => ind.get('id'));
      const industrySubscriptions = [];
      industryIds.forEach((indId) => {
        const industrySubscription = {
          active: industry,
          notification_profile_id: res.id,
          industry_id: indId,
          product_id: industryProduct.get('id'),
        };
        industrySubscriptions.push(industrySubscription);
      });
      const credentialProduct = products.find(p => p.get('pretty_name') === 'Credentials');
      const credentialSubscription = {
        active: credential,
        notification_profile_id: res.id,
        owner_id: res.owner_id,
        product_id: credentialProduct.get('id'),
      };
      const addition = { ...res,
        index: data.count(),
        products: list(),
        subscriptions: list(),
        name: res.endpoint,
        value: res.endpoint,
      };
      let updatedData;
      const promise = cachedPost(`${AlertingUrl}/subscriptions/bulk`, [curatedSubscription, ...industrySubscriptions, credentialSubscription], Status)
        .then(sub => (sub.ok ? sub.data : {}))
        .then(() => {
          addition.products.push(product);
          addition.subscriptions.push(product.set('active', curated));
          addition.curated = curated;
          addition.products.push(industryProduct);
          addition.subscriptions.push(industryProduct.set('active', industry));
          addition.industry = industry;
          addition.products.push(credentialProduct);
          addition.subscriptions.push(credentialProduct.set('active', credential));
          addition.credential = credential;
          return map(addition);
        });
      await Promise.all([promise])
        .then(([industryRes]) => {
          updatedData = industryRes;
        });
      SearchActions.set(['search', 'info', 'message'], Messages.ProfileAdded);
      return {
        recipient: updatedData,
      };
    })
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
    });
};

const saveNotificationProfileSubscriptions = (profileId, subscriptions) => {
  const promises = [];
  const added = [];
  const deleted = [];
  const updated = [];
  subscriptions.forEach((v) => {
    if (v.get('hasAdded')) {
      added.push({ id: v.get('id'), receivesEmail: v.get('receivesEmail') });
    } else if (v.get('hasDeleted')) {
      deleted.push(v.get('id'));
    } else if (v.get('hasChangedEmail')) {
      updated.push({ id: v.get('id'), receivesEmail: v.get('receivesEmail') });
    }
  });
  if (added.length > 0) {
    const addedQuery = added.map(v => ({
      keyword_id: v.id,
      profile_ids: [profileId],
      receives_email: (v.receivesEmail) ? [profileId] : [],
    }));
    const numRequests = Math.ceil(addedQuery.length / 400);
    for (let i = 0; i < numRequests; i += 1) {
      const addedPromise = cachedPost(`${AlertingUrl}/keyword_subscriptions`, addedQuery.slice(i * 400, (i + 1) * 400), Status)
        .then(res => (res.ok ? res.data : []));
      promises.push(addedPromise);
    }
  }
  if (deleted.length > 0) {
    const deleteBody = deleted.map(v => ({ keyword_id: v, profile_ids: [profileId] }));
    const numRequests = Math.ceil(deleteBody.length / 400);
    for (let i = 0; i < numRequests; i += 1) {
      const deletedPromise = cachedDelete(`${AlertingUrl}/keyword_subscriptions`, {}, Status, 30000, deleteBody.slice(i * 400, (i + 1) * 400))
        .then(res => (res.ok ? res.data : []));
      promises.push(deletedPromise);
    }
  }
  if (updated.length > 0) {
    const updatedAddQuery = updated.filter(v => v.receivesEmail).map(v => ({
      keyword_id: v.id,
      profile_ids: [],
      receives_email: [profileId],
    }));
    const updatedDeleteQuery = updated.filter(v => !v.receivesEmail).map(v => ({
      keyword_id: v.id,
      profile_ids: [],
      receives_email: [profileId],
    }));
    if (updatedAddQuery.length > 0) {
      const numRequests = Math.ceil(updatedAddQuery.length / 400);
      for (let i = 0; i < numRequests; i += 1) {
        const updatedAddPromise = cachedPost(`${AlertingUrl}/keyword_subscriptions`, updatedAddQuery.slice(i * 400, (i + 1) * 400), Status)
          .then(res => (res.ok ? res.data : []));
        promises.push(updatedAddPromise);
      }
    }
    if (updatedDeleteQuery.length > 0) {
      const numRequests = Math.ceil(updatedDeleteQuery.length / 400);
      for (let i = 0; i < numRequests; i += 1) {
        const updatedDeletePromise = cachedDelete(`${AlertingUrl}/keyword_subscriptions`, {}, Status, 30000, updatedDeleteQuery.slice(i * 400, (i + 1) * 400))
          .then(res => (res.ok ? res.data : []));
        promises.push(updatedDeletePromise);
      }
    }
  }
  Promise.all(promises)
    .then(() => SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess))
    .catch((err) => {
      const id = moment.utc().unix();
      const { data: d } = err.response;
      SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(d?.detail, id));
    });
};

const searchKeywords = (searchString = '', ownerId) => {
  const keywordsQuery = {
    embed: 'keywords',
    owner_id: ownerId,
    name: searchString,
  };

  if (searchString.length > 2) {
    return cachedGet(`${AlertingUrl}/keywords`, keywordsQuery, Status, 30000, {})
      .then(res => (res.ok ? res.data : []))
      .then(res => ([
        ...res
          .map((v, k) => (!['5b4fae74-4fb4-467b-bd45-a38e11206b41', 'bd813aa9-5e5e-407a-99e8-e81e00abe52e'].includes(v.keyclass_id)
            ? {
              ...v,
              name: v.name !== v.value ? v.name : '',
              index: k,
            }
            : null)),
      ].filter(v => v)))
      .then(res => (res.length > 0
        ? fromJS(res)
        : fromJS([{ name: 'No results found' }])))
      .catch((err) => {
        if (err.message !== 'canceled') return fromJS([{ name: 'No results found' }]);
        return 'canceled';
      });
  }
  return null;
};

export default {
  basetypes,
  bulkSaveSubscriptions,
  curatedLimit,
  basetypeColorMap,
  deleteKeywords,
  deleteNotificationProfiles,
  exportData,
  genBasetypeMappings,
  keywordAggregation,
  keywordDatasources,
  keywordSubscriptions,
  load,
  loadAlerts,
  loadFilters,
  loadKeyword,
  loadKeywords,
  loadNotificationProfiles,
  saveAlertTags,
  saveKeywordDataSources,
  saveKeywords,
  saveKeywordSubscriptions,
  saveNotificationProfile,
  saveNotificationProfileSubscriptions,
  searchKeywords,
};
