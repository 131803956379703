import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import { List as list, Map as map } from 'immutable';
import {
  BarChart,
  Bar as B,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip } from 'recharts';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  CircularProgress,
  Icon,
  ListItem,
  Popover,
} from '@mui/material';

import style from './histogram.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import SearchUtils from '../../utils/searchUtils';
import HistogramConstants from '../../constants/Histogram';
import SearchActions from '../../actions/searchActions';

const Histogram = ({
  data,
  buckets,
  filters,
  maxHeight,
  onClick,
  type,
  xAxis,
  xTick,
  yAxis,
  yAxisLabel,
  barSelected,
}) => {
  const [aggType, setAggType] = useState('fpid');
  const [aggTooltip, setAggTooltip] = useState('Count');
  const [canAgg, setCanAgg] = useState(true);
  const [beforeDate, setBeforeDate] = useState();
  const [bucketSize, setBucketSize] = useState(map());
  const [dialog, setDialog] = useState();
  const [grouped, setGrouped] = useState(true);
  const [groupedData, setGroupedData] = useState(list());
  const [loading, setLoading] = useState(false);
  const [totalRangeHours, setTotalRangeHours] = useState(0);

  const aggregations = () => {
    switch (type) {
      case 'accounts':
        return [
          { value: 'fpid', label: 'Number of Accounts', tooltip: 'Accounts' },
          { value: 'site_actor.fpid', label: 'Number of Sellers', tooltip: 'Sellers' },
          { value: 'site.fpid', label: 'Number of Sources', tooltip: 'Sources' },
        ];
      case 'blogs':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
          { value: 'site.fpid', label: 'Number of Blogs', tooltip: 'Blogs' },
        ];
      case 'ransomware':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
          { value: 'site.fpid', label: 'Number of Blogs', tooltip: 'Blogs' },
        ];
      case 'boards':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'container.fpid', label: 'Number of Boards', tooltip: 'Boards' },
        ];
      case 'cards':
        return [
          { value: 'fpid', label: 'Number of Cards', tooltip: 'Cards' },
          { value: 'bin', label: 'Number of Bins', tooltip: 'Bins' },
          { value: 'site.fpid', label: 'Number of Shops', tooltip: 'Shops' },
        ];
      case 'chats':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
          { value: 'container.fpid', label: 'Number of Channels', tooltip: 'Channels' },
        ];
      case 'communities':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
        ];
      case 'forums':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
          { value: 'site.fpid', label: 'Number of Forums', tooltip: 'Forums' },
        ];
      case 'marketplaces':
        return [
          { value: 'fpid', label: 'Number of Items', tooltip: 'Items' },
          { value: 'site_actor.fpid', label: 'Number of Vendors', tooltip: 'Vendors' },
          { value: 'site.fpid', label: 'Number of Markets', tooltip: 'Markets' },
        ];
      case 'social':
        return [
          { value: 'fpid', label: 'Number of Posts', tooltip: 'Posts' },
          { value: 'site_actor.fpid', label: 'Number of Actors', tooltip: 'Actors' },
          { value: 'container.fpid', label: 'Number of Threads', tooltip: 'Threads' },
        ];
      default:
        return [];
    }
  };

  const handleAgg = (agg) => {
    setDialog();
    const { query } = History.getCurrentLocation();
    setAggType(agg);
    const options = aggregations(type);
    const selected = options.find(v => v.value === agg);
    setAggTooltip((selected) ? selected.tooltip : 'Count');
    setLoading(true);
    SearchActions.search(type, '', false, { ...query, interval: bucketSize.get('interval'), subField: agg });
  };

  const handleBucket = (size) => {
    setDialog();
    const { query } = History.getCurrentLocation();
    setBucketSize(size);
    setLoading(true);
    SearchActions.search(type, '', false, { ...query, interval: size.get('interval'), subField: aggType });
  };

  const handleClick = (value) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const fmt = 'MM/DD/YYYY HH:mm';
    const momentAdd = bucketSize.get('moment').toJS();
    const since = (grouped && moment.utc(value.key).isSameOrBefore(beforeDate))
      ? moment.utc(data.getIn([0, 'key']))
      : moment.utc(value.key);
    const until = (grouped && moment.utc(value.key).isSameOrBefore(beforeDate))
      ? moment.utc(value.key).add(momentAdd)
      : moment.utc(since).add(momentAdd);
    const date = `${since.format(fmt)} - ${until.format(fmt)}`;
    History.push({
      pathname,
      query: { ...query, date, since: since.format(), until: until.format(), skip: null },
      hash,
    });
  };

  const handleGroup = (checked, before) => {
    const date = before || beforeDate;
    if (!checked || !date) {
      setGrouped(checked);
      setGroupedData(data);
      return;
    }
    let groupedDataValues = list();
    groupedDataValues = groupedDataValues.push(map({
      doc_count: 0,
      key: date.valueOf(),
      key_as_string: date.valueOf().toString(),
    }));
    data.forEach((v) => {
      if (moment.utc(v.get('key')).isSameOrBefore(date)) {
        groupedDataValues = groupedDataValues.setIn([0, 'doc_count'], groupedDataValues.getIn([0, 'doc_count']) + v.get('doc_count'));
      } else {
        groupedDataValues = groupedDataValues.push(v);
      }
    });
    if (groupedDataValues.getIn([0, 'doc_count']) === 0) {
      groupedDataValues = groupedDataValues.delete(0);
    }

    const update = groupedDataValues.map(v => v.set('doc_count', v.getIn(['date_histogram_cardinality', 'value'], v.get('doc_count'))));
    setGroupedData(update);
    setGrouped(checked);
  };

  const dateRanges = HistogramConstants.intervals;

  const formatXTick = (v, format) => {
    switch (format) {
      case 'value':
        return v;
      default:
        return moment.utc(v).format(bucketSize.get('format'));
    }
  };

  const formatTooltipLabel = (v) => {
    if (xTick) {
      return `${xAxis}: ${v}`;
    }

    const momentAdd = bucketSize.get('moment').toJS();
    const since = (grouped && moment.utc(v).isSameOrBefore(beforeDate))
      ? moment.utc(data.getIn([0, 'key']))
      : moment.utc(v);
    const until = (grouped && moment.utc(v).isSameOrBefore(beforeDate))
      ? moment.utc(v).add(momentAdd)
      : moment.utc(since).add(momentAdd);
    return `${since.format(bucketSize.get('format'))} - ${until.format(bucketSize.get('format'))}`;
  };

  useEffect(() => {
    if (!data || data.count() === 0) return;
    // Some datasources may not make sense to aggregate
    // on the data, so regular date-histogram will be used. If that is
    // the case, don't allow agg type to be selected
    const canAggValue = data.hasIn([0, 'date_histogram_cardinality']);
    if (!canAggValue) setCanAgg(false);
    const first = data.first();
    const last = data.last();
    const totalRangeHoursValue = moment.utc(last.get('key')).diff(moment.utc(first.get('key')), 'hours');
    const bucket = dateRanges.find(v => v.get('interval') === filters.get('interval'));
    setTotalRangeHours(totalRangeHoursValue);
    if (bucket) {
      setBucketSize(bucket);
      handleGroup(grouped);
      setLoading(false);
      return;
    }
    const {
      sinceDate,
      untilDate,
    } = SearchUtils.parseDate(filters);
    let bucketSizeValue = 'quarter';
    let hourDiff = null;
    if (filters.get('since')) {
      const since = moment.utc(sinceDate);
      const until = moment.utc(untilDate);
      hourDiff = Math.abs(since.diff(until, 'hours'));
    } else {
      // use the maximum histogram date for the type to determine which interval
      const typeDate = HistogramConstants.beforeDates[String(type)];
      if (typeDate) {
        const since = typeDate;
        const until = moment.utc();
        hourDiff = Math.abs(since.diff(until, 'hours'));
      }
    }
    if (hourDiff) {
      switch (true) {
        case (hourDiff <= 24):
          bucketSizeValue = '30m';
          break;
        case (hourDiff <= 48):
          bucketSizeValue = '1h';
          break;
        case (hourDiff <= 168): // 7 days
          bucketSizeValue = '3h';
          break;
        case (hourDiff <= 744): // 31 days
          bucketSizeValue = '12h';
          break;
        case (hourDiff <= 2160): // 90 days
          bucketSizeValue = 'day';
          break;
        case (hourDiff <= 8760): // 1 year
          bucketSizeValue = 'week';
          break;
        case (hourDiff <= 26280): // 3 years
          bucketSizeValue = 'month';
          break;
        case (hourDiff <= 87600): // 10 years
          bucketSizeValue = 'quarter';
          break;
        default:
          break;
      }
    }

    setBucketSize(dateRanges.find(v => v.get('interval') === bucketSizeValue));
    handleGroup(grouped);
    const options = aggregations(type);
    const selected = options[0];
    setAggTooltip((selected && canAgg) ? selected.tooltip : 'Count');
    setLoading(false);
  }, [data, filters]);

  useEffect(() => {
    const before = HistogramConstants.beforeDates[String(type)] || null;
    setBeforeDate(before);
    if (grouped) handleGroup(grouped, before);
  }, [type]);

  return (
    <Grid fluid className={cx([style.histogram])}>
      {loading &&
      <div className={style.spinner}>
        <CircularProgress />
      </div>}
      <Row>
        <Col xs={12} className={cx([style.container, loading && style.loading])}>
          <ResponsiveContainer width="100%" height={maxHeight}>
            <BarChart
              className={style.chart}
              data={groupedData.toJS()}
              onClick={(details) => {
                // The click handler on the bar chart itself may provide multiple
                // activePayloads if it is a stacked bar chart, but this allows
                // clicking on the full column as opposed to just the bar
                const { payload } = details.activePayload[0];
                if (payload.doc_count === 0) return;
                if (typeof onClick === 'function') {
                  onClick(payload);
                } else {
                  handleClick(payload);
                }
              }}
              data-testid="histogram">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxis}
                tickFormatter={v => formatXTick(v, xTick)} />
              <YAxis
                dataKey={yAxis}
                label={{
                  value: yAxisLabel !== '' ? yAxisLabel : 'Mentions',
                  angle: -90,
                  margin: { top: 15 },
                  position: 'insideLeft' }}
                tickFormatter={v => Text.AbbreviateNumber(v, false)} />
              <Tooltip
                formatter={v => [v.toLocaleString(), aggTooltip]}
                labelFormatter={formatTooltipLabel}
              />
              <B
                isAnimationActive={false}
                type="monotone"
                dataKey={yAxis}>
                {groupedData.map(entry => (
                  <Cell
                    stroke={isEqual(barSelected, entry.toJS()) ? '#ff0000' : '#176ba6'}
                    fill={isEqual(barSelected, entry.toJS()) ? '#ff0000' : '#176ba6'}
                    cursor="pointer"
                    key={entry.get('key')} />
                ))}
              </B>
            </BarChart>
          </ResponsiveContainer>
          {buckets &&
          <div className={style.table}>
            {canAgg &&
            <div className={style.agg}>
              <Button
                variant="contained"
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={event => setDialog({ key: 'type', target: event.target })}
                data-testid="histogram.bucket-size">
                {get(aggregations(type).find(v => v.value === aggType), 'label', 'Select Option')}
              </Button>
              <Popover
                className="dropdown"
                anchorEl={dialog && dialog.target}
                open={Boolean(dialog && dialog.key === 'type')}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => setDialog()}>
                {aggregations(type).map(v => (
                  <ListItem
                    key={v.value}
                    value={v.value}
                    onClick={() => handleAgg(v.value)}
                    className={cx([
                      style.item,
                      aggType === v.value && 'active',
                      aggType === v.value && style.active,
                    ])}>
                    {v.label}
                  </ListItem>))}
              </Popover>
            </div>}
            <div className={style.range}>
              <Button
                variant="contained"
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={event => setDialog({ key: 'range', target: event.target })}
                data-testid="histogram.range">
                {dateRanges
                  .find(v => v.get('key') === bucketSize?.get('key'), null, map())
                  .get('label')}
              </Button>
              <Popover
                className="dropdown"
                anchorEl={dialog && dialog.target}
                open={Boolean(dialog && dialog.key === 'range')}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => setDialog()}>
                {dateRanges
                  .map(v => (
                    <ListItem
                      key={v.get('key')}
                      value={v}
                      onClick={() => handleBucket(v)}
                      disabled={totalRangeHours * v.get('bucketsPerHour') > 100 || totalRangeHours * v.get('bucketsPerHour') <= 1}
                      className={cx([
                        style.item,
                        bucketSize.get('key') === v.get('key') && 'active',
                        bucketSize.get('key') === v.get('key') && style.active,
                      ])}>
                      {v.get('label')}
                    </ListItem>))}
              </Popover>
            </div>
          </div>}
        </Col>
      </Row>
    </Grid>
  );
};


Histogram.propTypes = {
  buckets: PropTypes.bool,
  data: PropTypes.object,
  filters: PropTypes.object,
  maxHeight: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.string,
  xAxis: PropTypes.string,
  xTick: PropTypes.string,
  yAxis: PropTypes.string,
  yAxisLabel: PropTypes.string,
  barSelected: PropTypes.object,
};

Histogram.defaultProps = {
  buckets: true,
  data: list(),
  filters: map(),
  maxHeight: 200,
  onClick: null,
  type: '',
  xAxis: '',
  xTick: '',
  yAxis: '',
  yAxisLabel: '',
  barSelected: {},
};

export default Histogram;
