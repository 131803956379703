import PropTypes from 'prop-types';
import React from 'react';

import { Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useRecoilValue } from 'recoil';

import Query from '../../containers/Alerting/query';
import AlertingStore from '../../stores/recoil/alerting';
import Subscriptions from './Subscriptions';

const useStyles = makeStyles(theme => ({
  alertingDialog: {
    '& .filters': {
      display: 'flex',
      flexDirection: 'row',

      '& .search': {
        marginLeft: 'auto',
      },
    },
    '& .filters .MuiButton-root': {
      margin: `0 ${theme.spacing(1)}`,
    },
  },
}));

const AlertingSubscriptionDialog = ({
  email,
  open,
  onClose,
  selected,
}) => {
  const classes = useStyles();
  const type = useRecoilValue(AlertingStore.type);

  const onChange = (changedSubscriptions) => {
    if (type === 'keywords') {
      Query.saveKeywordSubscriptions(selected.get('id'), changedSubscriptions, true);
    } else {
      Query.saveNotificationProfileSubscriptions(selected.get('id'), changedSubscriptions);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      className={classes.alertingDialog}
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Manage Subscriptions
      </DialogTitle>
      <DialogContent>
        <Subscriptions
          email={email}
          selected={selected}
          filter
          onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertingSubscriptionDialog.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.object,
};

AlertingSubscriptionDialog.defaultProps = {
  email: '',
  onClose: null,
  open: false,
  selected: map(),
};

export default AlertingSubscriptionDialog;
