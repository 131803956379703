import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import SearchActions from '../../actions/searchActions';
import style from './marketplaces.module.scss';
import Text from '../../utils/text';
import Messages from '../../constants/Messages';
import Iso from '../../constants/Iso';
import Invalid from '../utils/Invalid/Invalid';
import Icons from '../../constants/Icons';

const Marketplaces = ({
  data,
  location,
}) => {
  const [translation, setTranslation] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const onTranslate = () => {
    if (data.getIn(['body', 'text/plain']).length < 5000) {
      const id = data.get('id');
      const key = data.getIn(['body', 'text/plain']).toString();
      const query = {
        fpid: data.get('fpid'),
        native_id: data.get('native_id'),
        legacy_id: data.get('legacy_id'),
        basetypes: data.get('basetypes').toJS().join(),
      };
      setTranslation(!translation);
      if (!data.has('translation')) SearchActions.searchTranslate(id, key, query);
    } else {
      SearchActions.set(['search', 'info', 'message'], Messages.TranslationLimit);
    }
  };

  useEffect(() => {
    setIsSidebarOpen(!!location.hash);
  }, [location]);

  return (
    <Grid
      name="component.marketplaces"
      className={cx([style.base, style.marketplaces, isSidebarOpen && style.pull, translation && data.has('translation') && style.wide])}>
      {data.isEmpty() &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {!data.isEmpty() && data.has('id') &&
      <div>
        <Row>
          <Col xs={12} className={style.header}>
            <div>
              <div
                name="marketplaces.header.market"
                className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                {Text.StripHighlight(data.getIn(['site', 'title']))}
              </div>
              {data.getIn(['site', 'description', 'raw']) &&
              <Icon
                name="marketplaces.header.description"
                data-for="market.tooltip"
                data-tip={data
                  .getIn(['site', 'description', 'raw'])
                  .match(/(.{1,75})(?:\n|$| )/g)
                  .join('<br />')}
                className={style.help}>
                info_outline
              </Icon>}
            </div>
            <div
              name="marketplaces.header.source_uri"
              className={cx([style.h3, 'h3'])}>
              Source URI: {data.getIn(['source_uri'])}
            </div>
            <div
              name="marketplaces.site_actor.names.handle"
              className={cx([style.h3, 'h3'])}>
              Seller: {data.getIn(['site_actor', 'names', 'handle'])}
            </div>
            <div className={style.title}>
              <Icon className={style.icon}>
                shopping_cart
              </Icon>
              <div
                className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap', style.title])}
                name="marketplaces.header.title">
                {Text.Highlight(data.getIn(['highlight', 'title', 0]) || data.get('title'))}
              </div>
            </div>
            {data.getIn(['sort_date']) &&
            <div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Date:</div>
                <div>
                  {moment.utc(data.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}
                </div>
              </div>
              <Icon
                name="marketplaces.sort_date"
                data-for="date.tooltip"
                data-tip={`This field is the first date that Flashpoint has seen this item.<br />
                  This date field will not change if we re-scrape<br />
                  the page and observe the same account a second time.`}
                className={style.help}>
                help
              </Icon>
            </div>}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Paper
              className={cx([
                style.card])}>
              <div className={style.header}>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>
                  Item Description:
                </div>
                <div>
                  {translation && !data.has('translation') &&
                  <CircularProgress />}
                  <Icon
                    data-for="global.tooltip"
                    data-tip="Translate post. Translations are limited to 5000 characters."
                    className={cx([style.translate, translation && style.on])}
                    toggled={translation.toString()}
                    onClick={() => setTimeout(() => onTranslate(data))}>
                    g_translate
                  </Icon>
                </div>
              </div>
              <div className={style.hero}>
                <div>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Price(s):</div>
                  {data.getIn(['prices', 0, 'raw']) &&
                  <div name="marketplaces.body.price">
                    {data.get('prices')
                      .entrySeq()
                      .map(([k, v]) => (
                        <div
                          key={k}
                          className={cx([style.h2, 'h2', style.raj, 'raj', style.cap, 'cap'])}>
                          {`${v.getIn(['currency', 'raw']) || ''}${v.get('value') || v.get('raw')}`}
                        </div>))}
                  </div>}
                  {!data.getIn(['prices']) &&
                    <div>
                      <div className={cx([style.h2, 'h2', style.raj, 'raj', style.cap, 'cap'])}>
                        N/A
                      </div>
                    </div>}
                </div>
              </div>
              <div className={style.container}>
                <div>
                  {translation && data.get('translation') && <div className={style.original}>Original text:</div>}
                  <div className={cx([translation && style.ogtext])}>
                    <div name="marketplaces.body.item_description">
                      {Text.Highlight(
                        Text.Market(
                          data.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                            data.getIn(['body', 'text/html+sanitized'])))}
                    </div>
                  </div>
                </div>
                {translation && data.get('translation') &&
                <div>
                  <div className={style.translation}>
                    <div
                      className={cx([style.icon, style.original])}
                      dangerouslySetInnerHTML={{ __html: Icons.googleTranslate }} />
                    <div className={style.original}>translated from
                      <b>&nbsp;{Iso[data.getIn(['translation', 'detectedLanguage'])].name || '-'}</b> to
                      <b>&nbsp;{Iso[data.getIn(['translation', 'translationLanguage'])].name || '-'}</b>
                    </div>
                  </div>
                  <div className={style.item} dir="auto">
                    {Text.Highlight(
                      Text.Market(
                        Text.Translate(data.getIn(['translation', 'translation']))))}
                  </div>
                </div>}
              </div>
            </Paper>
          </Col>
        </Row>
      </div>}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
      <ReactTooltip id="date.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="offline.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="market.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Marketplaces.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

Marketplaces.defaultProps = {
  data: map(),
  location: {},
};

export default Marketplaces;
