import React, { useContext, useEffect, useState } from 'react';

import { isEqual, isEmpty } from 'lodash';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import useQueryString from '../../../hooks/useQueryString';
import Looker from '../../utils/Looker';
import History from '../../../utils/history';
import { UserContext } from '../../../components/utils/Context';
import { sanitizeFilters } from '../../../hooks/useLookerApi';


const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
  },
  tabBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root': {
      borderRadius: '5px',
      minHeight: '2.5rem',
      minWidth: 0,
      padding: '0 1rem',
      '&:hover': {
        color: '#1a1b33',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#1a1b33',
      color: '#fff',
    },
  },
}));

const RansomersDashboard = () => {
  const [observedTime] = useQueryString('Observed Time');
  const [ransomerName] = useQueryString('Ransomer Name');
  const [uniquePosts] = useQueryString('Unique Posts Only');
  const [lastUpdatedDate] = useQueryString('Last Updated Date');
  const [ransomerIsActive] = useQueryString('Ransomer Is Active (Yes / No)');

  const classes = useStyles();
  const user = useContext(UserContext);
  const sid = user.get('sid');
  const [overviewFilters, setOverviewFilters] = useState();

  const { pathname, query } = History.getCurrentLocation();

  useEffect(() => {
    setOverviewFilters({
      'Observed Time': observedTime,
      'Ransomer Name': ransomerName,
      'Unique Posts Only': uniquePosts,
      'Last Updated Date': lastUpdatedDate,
      'Ransomer Is Active (Yes / No)': ransomerIsActive,
    });
  }, [observedTime, ransomerName, uniquePosts, lastUpdatedDate, ransomerIsActive]);

  useEffect(() => {
    if (!isEqual(query, overviewFilters)) {
      const sanitized = sanitizeFilters({ ...overviewFilters });
      if (!isEmpty(sanitized)) {
        setOverviewFilters({
          ...sanitized,
        });

        History.push({
          pathname,
          query: {
            ...sanitized,
          },
        });
      }
    }
  }, [overviewFilters]);

  if (!sid) {
    return <CircularProgress />;
  }

  return (
    <Grid fluid className={classes.container}>
      <Row>
        <Col xs={12}>
          <Looker
            height="160rem"
            dashboard="/dashboards-next/ransomware_production::ransomers"
            filters={overviewFilters}
            setFilters={setOverviewFilters}
            sid={sid} />
        </Col>
      </Row>
    </Grid>
  );
};

RansomersDashboard.propTypes = {};

export default RansomersDashboard;
