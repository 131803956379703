import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete as MuiAutocomplete,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import makeStyles from '@mui/styles/makeStyles';

import ThemeTooltip from './ThemeTooltip';
import Autocomplete from '../../../constants/Autocomplete';

const useStyles = makeStyles(() => ({
  addRemove: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
    padding: '0px',
  },
  iconButton: {
    padding: '0px',
    '& .Mui-disabled': {
      display: 'none',
    },
  },
  inputLabels: {
    fontSize: '16px',
    margin: '0 5px 5px 0',
  },
  tooltipIcon: {
    height: '18px',
    width: '18px',
  },
}));

const LocationGrid = ({
  currentLocation,
  locationNumber,
  setCurrentLocation,
  setLocationNumber,
  validationError,
}) => {
  const classes = useStyles();
  const [isUS, setIsUS] = useState(currentLocation.country === 'US' || !currentLocation.country);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [locationValidationError, setLocationValidationError] = useState(false);

  const locationTooltip = 'Enter a city, state, and country where applicable. In order to begin a search, a city and country are required.';

  // takes in key string and value from form to build currentLocation object
  const handleFieldChange = (obj) => {
    const key = Object.keys(obj).toString();
    if (Object.values(obj).toString() === '') {
      const updatedObject = Object.fromEntries(Object.entries(currentLocation)
        .filter(v => v[0] !== key));
      setCurrentLocation(updatedObject);
    } else {
      setCurrentLocation({ ...currentLocation, ...obj });
    }
  };

  const validateLocation = (addressLocation) => {
    const { city, country, state, zip_code } = addressLocation;
    const hasCity = city?.length;
    const hasState = isUS ? state?.length : true;
    const hasCountry = country?.length || (isUS && hasCity && hasState);

    const validZipCode = () => {
      let isValid = false;
      if (!zip_code || !zip_code?.length || (zip_code?.length > 4 && zip_code?.length < 11)) {
        isValid = true;
      } else {
        setZipcodeError(true);
      }
      return isValid;
    };
    setZipcodeError(false);

    let validate = false;
    if (hasCity && hasCountry && hasState && validZipCode()) {
      validate = true;
    } else {
      setLocationValidationError(true);
      validate = false;
    }
    return validate;
  };

  const handleAddLocation = (e) => {
    e.preventDefault();
    if (validateLocation(currentLocation)) {
      setLocationValidationError(false);
      setLocationNumber(locationNumber + 1);
    }
  };

  const handleRemoveLocation = (e) => {
    e.preventDefault();
    setCurrentLocation({});
    setLocationNumber(locationNumber - 1);
  };

  const handleCountryChange = (e, option) => {
    e.preventDefault();
    if (!option) {
      setCurrentLocation(Object.fromEntries(Object.entries(currentLocation)
        .filter(v => v[0] !== 'state' && v[0] !== 'country')));
      return;
    }
    const newCountry = option[0];
    if (newCountry === 'US') {
      setIsUS(true);
    } else {
      setIsUS(false);
    }
    setCurrentLocation({ ...currentLocation, country: newCountry });
  };

  useEffect(() => {
    if (!isUS && currentLocation.state) handleFieldChange({ state: '' });
  }, [currentLocation]);

  return (
    <Grid container item spacing={2} style={{ borderTop: locationNumber > 0 ? '1px solid #BCBEC0' : 'none' }}>
      {locationValidationError && validationError()}
      <Grid item xs={6}>
        <Grid alignItems="center" container>
          <Typography style={{ marginRight: '5px' }} variant="h6">
            {locationNumber === 0 ? 'Location' : `Location ${locationNumber + 1}`}
          </Typography>
          <ThemeTooltip title={locationTooltip}>
            <HelpIcon className={classes.tooltipIcon} color="primary"/>
          </ThemeTooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.inputLabels}>Address line 1</Typography>
        <TextField
          autoComplete="address-line-1"
          defaultValue={currentLocation.address_line_1}
          name="address_line_1"
          onChange={e => handleFieldChange({ [e.target.name]: e.target.value })}
          placeholder="Street address"
          variant="outlined"
          />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.inputLabels}>Address line 2</Typography>
        <TextField
          autoComplete="new-password"
          defaultValue={currentLocation.address_line_2}
          name="address_line_2"
          onChange={e => handleFieldChange({ [e.target.name]: e.target.value })}
          placeholder="Apartment, suite, etc."
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.inputLabels}>Country</Typography>
        <MuiAutocomplete
          isOptionEqualToValue={(option, value) => option[0] === value}
          openOnFocus
          name="country"
          value={currentLocation.country || 'US'}
          onChange={(e, option) => handleCountryChange(e, option)}
          options={Object
            .entries(Autocomplete.country_codes)
            .sort()
          }
          getOptionLabel={option => option.toString().replace(',', ': ')}
          renderInput={(params) => {
            const { inputProps } = params;
            inputProps.autoComplete = 'disable';
            return (
              <TextField
                {...params}
                inputProps={inputProps}
                required
                variant="outlined"/>
            );
          }}/>
      </Grid>
      <Grid item xs={4}>
        <Typography className={classes.inputLabels}>City</Typography>
        <TextField
          autoComplete="new-password"
          name="city"
          defaultValue={currentLocation.city}
          onChange={e => handleFieldChange({ [e.target.name]: e.target.value })}
          required
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <Typography className={classes.inputLabels}>State</Typography>
        <MuiAutocomplete
          disabled={currentLocation.country && !isUS}
          openOnFocus
          name="state"
          isOptionEqualToValue={(option, value) => option === value}
          value={currentLocation.state || ''}
          onChange={(e, option) => setCurrentLocation({ ...currentLocation, country: 'US', state: option })}
          options={Object
            .keys({ ...Autocomplete.state_codes })
            .sort()
          }
          renderInput={(params) => {
            const { inputProps } = params;
            inputProps.autoComplete = 'disable';
            return (
              <TextField
                {...params}
                required
                variant="outlined"/>
            );
          }}/>
      </Grid>
      <Grid item xs={4}>
        <Typography className={classes.inputLabels}>Zip code</Typography>
        <TextField
          defaultValue={currentLocation.zip_code}
          error={zipcodeError}
          helperText={zipcodeError && 'This field is not valid'}
          name="zip_code"
          onChange={e => handleFieldChange({ [e.target.name]: e.target.value })}
          placeholder="12345-1234"
          variant="outlined"
        />
      </Grid>
      <Grid className={classes.addRemove} item xs={12}>
        {[0, 1].includes(locationNumber) &&
          <IconButton
            className={classes.iconButton}
            onClick={e => handleAddLocation(e)}
            size="large">
            <Icon color="secondary">add_circle</Icon>
          </IconButton>}
        {[1, 2].includes(locationNumber) &&
          <IconButton
            className={classes.iconButton}
            onClick={e => handleRemoveLocation(e)}
            size="large">
            <Icon >remove_circle</Icon>
          </IconButton>}
      </Grid>
    </Grid>
  );
};

LocationGrid.propTypes = {
  currentLocation: PropTypes.object,
  locations: PropTypes.array,
  locationNumber: PropTypes.number.isRequired,
  setCurrentLocation: PropTypes.func,
  setLocationNumber: PropTypes.func,
  validationError: PropTypes.func.isRequired,
};

LocationGrid.defaultProps = {
  currentLocation: {},
  locations: [],
  setCurrentLocation: null,
  setLocationNumber: null,
};

export default LocationGrid;
