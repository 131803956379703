import moment from 'moment';
import { List as list } from 'immutable';
import _ from 'lodash';

import Token from '../utils/token';
import SearchUtils from '../utils/searchUtils';
import { knownHashes } from '../constants/org_profiles/Edm';

// List of fields to be used for searching/highlighting
const Fields = {
  all: [],
  accounts: ['enrichments', 'account_organization', 'bank_accounts.bank_name', 'body.text/html+sanitized', 'email_domain', 'site.title', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'site.source_uri', 'prices.*'],
  blogs: ['enrichments', 'body.text/html+sanitized', 'site_actor.names', 'site_actor.names.handle', 'site_actor.fpid', 'title', 'container.title', 'site.source_uri', 'site.title', 'raw_href'],
  ransomware: ['enrichments', 'body.text/html+sanitized', 'ransomer.names', 'ransomer.fpid', 'ransomer.names.handle', 'title', 'container.title', 'site.source_uri', 'site.title', 'raw_href'],
  boards: ['enrichments', 'body.text/html+sanitized', 'body.text/plain', 'container.title', 'container.fpid', 'container.native_id', 'container.container.title', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'raw_href', 'site.title', 'title', 'media_v2.*'],
  cards: ['enrichments', 'bank_name', 'base.title', 'bin', 'site.source_uri', 'card_type', 'site.title', 'prices.*', 'payment_method', 'expiration', 'last4', 'cardholder_information.*'],
  chats: ['enrichments', 'body.text/plain', 'body.text/html+sanitized', 'container.title', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.username', 'site_actor.fpid', 'site.title'],
  media: ['enrichments', 'container.fpid', 'media.*.value', 'media.sha1', 'media.md5', 'media.phash'],
  communities: ['enrichments', 'body.text/html+sanitized', 'body.text/plain', 'user.names.handle', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'title', 'container.fpid', 'container.title', 'container.container.title', 'site.source_uri', 'site.title', 'native_id'],
  credentials: ['enrichments', 'body', 'breach.created_at', 'breach.source', 'breach.title', 'domain', 'affected_domain', 'email', 'password', 'username', 'is_fresh', 'credential_record_fpid'],
  cves: ['mitre.body.text/html+sanitized', 'nist.products', 'nist.body.text/html+sanitized', 'nist.configurations.cpe23_uri', 'title', 'nist.cvssv2.severity', 'mitre.status'],
  exploits: ['cve.mitre.body.text/html+sanitized', 'cve.nist.products', 'cve.nist.body.text/html+sanitized', 'cve.nist.configurations.cpe23_uri', 'cve.title', 'cve.nist.cvssv2.severity', 'cve.mitre.status', 'cve.title', 'source_uri'],
  iocs: ['Event.date', 'Event.uuid', 'Event.info', 'Event.Tag.name', 'Event.Attribute.value', 'Event.Attribute.type', 'Event.Attribute.uuid', 'type', 'value.*'],
  forums: ['enrichments', 'body.text/html+sanitized', 'container.title', 'container.container.title', 'container.fpid', 'containeer.container.fpid', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'site.title', 'site.source_uri'],
  social: ['enrichments', 'body.text/html+sanitized', 'container.title', 'container.fpid', 'container.url', 'container.basetypes', 'container.site_actor.*', 'container.container.fpid', 'container.container.name', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'title', 'site.title'],
  marketplaces: ['enrichments', 'body.text/html+sanitized', 'body.raw', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'title', 'site.source_uri', 'site.title', 'prices.*'],
  pastes: ['enrichments', 'site_actor.names.handle', 'site_actor.names.aliases', 'site_actor.fpid', 'native_id', 'title', 'body.text/plain', 'body.text/html+sanitized', 'container.title', 'raw_href', 'site.title'],
  reports: ['title', 'tags', 'body'],
  twitter: ['enrichments', 'native_id', 'body.raw', 'site_actor.names.handle', 'in_reply_to.fpid', 'in_reply_to.tweet_id', 'owners.category_id', 'site_actor.is_verified', 'site_actor.number_of_followers', 'tweet_source'],

  // threads
  'threads.forums': ['message_count.last_resource.container.title'],
};

// List of fields to include in the request response
const Includes = {
  all: [],
  accounts: ['basetypes', 'sort_date', 'enrichments', 'account_organization', 'body', 'bank_accounts.bank_name', 'email_domain', 'fpid', 'prices', 'site.title', 'site_actor.fpid', 'site_actor.names'],
  blogs: ['basetypes', 'sort_date', 'enrichments', 'body.text/plain', 'body.text/html+sanitized', 'container.title', 'container.fpid', 'fpid', 'site_actor.fpid', 'site_actor.names', 'title', 'site', 'raw_href'],
  ransomware: ['basetypes', 'sort_date', 'enrichments', 'body.text/plain', 'body.text/html+sanitized', 'container.title', 'container.fpid', 'fpid', 'ransomer.fpid', 'ransomer.names', 'title', 'site', 'raw_href'],
  boards: ['basetypes', 'sort_date', 'enrichments', 'fpid', 'body.text/plain', 'body.text/html+sanitized', 'container.container.title', 'container.fpid', 'container.native_id', 'container.container.title', 'fpid', 'site.title', 'site_actor.fpid', 'site_actor.names', 'site_actor.native_id', 'native_id', 'location.country.abbreviation', 'location.country.full_name', 'title', 'media_v2.*'],
  breaches: ['breach_intersections', 'created_at', 'first_observed_at', 'fpid', 'source', 'source_type', 'title', 'old_records', 'new_records', 'top_domains', 'top_passwords', 'total_records', 'unique_records'],
  cards: ['basetypes', 'sort_date', 'enrichments', 'bank_name', 'base.title', 'bin', 'cardholder_information', 'card_type', 'expiration', 'last4', 'fpid', 'payment_method', 'prices', 'site.title', 'track_information'],
  chats: ['basetypes', 'sort_date', 'enrichments', 'body', 'body.text/plain', 'body.text/html+sanitized', 'body.enrichments.language', 'container.container.name', 'container.container.native_id', 'container.container.fpid', 'container.description', 'container.name', 'container.fpid', 'container.type', 'container.native_id', 'container.username', 'fpid', 'media', 'site_actor.fpid', 'site_actor.names', 'site_actor.username', 'site_actor.native_id', 'site.title', 'storage_uri', 'sha1'],
  media: ['basetypes', 'sort_date', 'enrichments', 'fpid', 'storage_uri', 'sha1', 'site.title'],
  communities: ['enrichments', 'body.text/html+sanitized', 'body.text/plain', 'site_actor.names.handle', 'site_actor.names.aliases', 'title', 'container.title', 'container.container.title', 'site.source_uri', 'site.title'],
  credentials: ['basetypes', 'sort_date', 'enrichments', 'fpid', 'body', 'is_fresh', 'breach.fpid', 'breach.created_at', 'breach.first_observed_at', 'breach.source', 'breach.title', 'breach.basetypes', 'breach.context', 'breach.source_type', 'credential_record_fpid', 'domain', 'affected_domain', 'affected_url', 'email', 'username', 'last_observed_at', 'password', 'password_complexity', 'has_plaintext_recovered', 'original_password', 'customer_id'],
  cves: ['basetypes', 'sort_date', 'fpid', 'fpid', 'mitre.body.text/plain', 'mitre.body.text/html+sanitized', 'mitre.status', 'nist.cvssv2.severity', 'nist.cvssv3.severity', 'nist.vulnerability_types', 'nist.configurations', 'nist.products', 'title'],
  exploits: ['basetypes', 'sort_date', 'fpid', 'cve.mitre.body.text/plain', 'cve.mitre.body.text/html+sanitized', 'cve.mitre.status', 'cve.nist.cvssv2.severity', 'cve.nist.cvssv3.severity', 'cve.nist.vulnerability_types', 'cve.nist.configurations', 'cve.nist.products', 'cve.title', 'source_uri'],
  iocs: ['basetypes', 'sort_date', 'fpid', 'attack_ids', 'Event.Attribute.type', 'Event.Attribute.value', 'Event.attribute_count', 'Event.total_attributes', 'Event.uuid', 'Event.info', 'Event.RelatedEvent', 'Event.Tag', 'Event.date', 'date', 'type', 'value', 'category', 'uuid'],
  forums: ['basetypes', 'sort_date', 'enrichments', 'body.text/plain', 'body.text/html+sanitized', 'container.fpid', 'container.title', 'container.container.fpid', 'container.container.title', 'container_position.index_number', 'fpid', 'site_actor.fpid', 'site_actor.names', 'site_actor.native_id', 'site.tags', 'site.title', 'site.source_uri'],
  marketplaces: ['basetypes', 'sort_date', 'enrichments', 'body.text/plain', 'body.text/html+sanitized', 'fpid', 'prices', 'site.title', 'site.source_uri', 'site_actor.fpid', 'site_actor.names', 'title', 'source_uri'],
  pastes: ['enrichments', 'site_actor.names.handle', 'site_actor.names.aliases', 'native_id', 'title', 'body.text/plain', 'body.text/html+sanitized', 'container.title', 'raw_href'],
  reports: ['assets', 'body', 'is_featured', 'version_posted_at', 'sources', 'title', 'title_asset', 'tags', 'summary', 'published_status', 'google_document_id'],
  social: ['basetypes', 'sort_date', 'enrichments', 'title', 'fpid', 'num_replies', 'parent_message.fpid', 'body.text/plain', 'body.text/html+sanitized', 'container.basetypes', 'container.fpid', 'container.num_replies', 'container.site_actor', 'container.site_actor.names', 'container.title', 'container.name', 'container.container.name', 'container.container.fpid', 'site_actor.fpid', 'site_actor.names', 'site_actor.native_id', 'site.title', 'source_uri', 'container.url'],
  twitter: ['basetypes', 'sort_date', 'enrichments', 'body.raw', 'user.names.handle', 'retweet_count', 'reply_count'],

  // threads
  'threads.forums': ['fpid', 'basetypes', 'message_count', 'site_actor_count'],
};

// List of fields to include in inline/meta request responses
const InlineIncludes = {
  blogs: [...Includes.blogs, 'container.basetypes'],
  ransomware: [...Includes.ransomware],
  boards: [...Includes.boards, 'container.source_uri', 'site.description.raw'],
  chats: [...Includes.chats, 'container.created_at.timestamp', 'container.container', 'container.server', 'server'],
  cves: [...Includes.cves, 'container', 'native_id', 'site_actor', 'site', 'source_uri'],
  exploits: [...Includes.exploits, 'container', 'native_id', 'site_actor', 'site'],
  forums: [...Includes.forums, 'site.fpid', 'site.description.raw', 'container.source_uri'],
  social: [...Includes.social, 'media_v2'],
};

// List of basetype combinations used in search overlay
const TypeGroups = {
  allAssets: 'credentials',
  allMedia: 'images,videos',
  allCommunities: `forums,chats,blogs,ransomware,pastes,boards,social,${(Token.get('prm').some(p => /twtr/ig.test(p))) ? 'twitter' : ''}`,
  allShops: 'marketplaces,cards,accounts',
  allTechnical: 'cves,exploits,iocs',
};

const Limits = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Groups = {
  boards: [
    { label: 'Messages', value: 'messages' },
    { label: 'Channels', value: 'channels' },
  ],
  chats: [
    { label: 'Messages', value: 'messages' },
    { label: 'Channels', value: 'channels' },
  ],
  channel: [
    { label: 'Messages', value: 'messages' },
    { label: 'Channels', value: 'channels' },
  ],
  iocs: [
    { label: 'Events', value: 'event' },
    { label: 'Indicators', value: 'indicator' },
  ],
};

const Sorts = {
  all: [
    { label: 'Date (desc)', value: '' },
    { label: 'Date (asc)', value: '' },
  ],
  communities: [
    { label: 'Date (desc)', value: 'sort_date:desc' },
    { label: 'Date (asc)', value: 'sort_date:asc' },
  ],
  accounts: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { source: { field: 'site.title', filter: 'sites' }, site: { field: 'account_organization', filter: 'account_organization' }, email_domain: { field: 'email_domain', filter: 'email_domain' } }, description: { seller: 'site_actor.names.handle', price: 'prices.0.value' } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { source: { field: 'site.title', filter: 'sites' }, site: { field: 'account_organization', filter: 'account_organization' }, email_domain: { field: 'email_domain', filter: 'email_domain' } }, description: { seller: 'site_actor.names.handle', price: 'prices.0.value' } },
  ],
  blogs: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { title: { field: 'title|container.title', filter: 'title' }, blog: { field: 'site.title', filter: 'sites' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { title: { field: 'title|container.title', filter: 'title' }, blog: { field: 'site.title', filter: 'sites' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  ransomware: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { title: { field: 'title|container.title' }, blog: { field: 'site.title', filter: 'sites' } }, description: { preview: 'body.text/html+sanitized' } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { title: { field: 'title|container.title' }, blog: { field: 'site.title', filter: 'sites' } }, description: { preview: 'body.text/html+sanitized' } },
  ],
  boards: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { author: { field: 'site_actor.names.handle|native_id', filter: 'author' }, site: { field: 'site.title', filter: 'sites' }, board: { field: 'container.container.title', filter: 'channel' }, native_id: 'native_id' }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { author: { field: 'site_actor.names.handle|native_id', filter: 'author' }, site: { field: 'site.title', filter: 'sites' }, board: { field: 'container.container.title', filter: 'channel' }, native_id: 'native_id' }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  cards: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { shop: { field: 'site.title', filter: 'sites' } }, description: { release: 'base.title' } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { shop: { field: 'site.title', filter: 'sites' } }, description: { release: 'base.title' } },
  ],
  chats: [
    // description.preview array allows the highlighting to fall back onto a second field
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { 'chat type': { field: 'basetypes.2', filter: 'sites' }, channel: { field: 'container.title', filter: 'channel' }, author: { field: 'site_actor.names.handle', filter: 'author' }, native_id: 'native_id' }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { 'chat type': { field: 'basetypes.2', filter: 'sites' }, channel: { field: 'container.title', filter: 'channel' }, author: { field: 'site_actor.names.handle', filter: 'author' }, native_id: 'native_id' }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  channel: [
    { label: 'Date (desc)', value: 'sort_date:desc' },
    { label: 'Date (asc)', value: 'sort_date:asc' },
  ],
  media: [
    // description.preview array allows the highlighting to fall back onto a second field
    { label: 'Date (desc)', value: 'sort_date:desc', detail: {}, description: {} },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: {}, description: {} },
  ],
  images: [
    // description.preview array allows the highlighting to fall back onto a second field
    { label: 'Date (desc)', value: 'sort_date:desc', detail: {}, description: {} },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: {}, description: {} },
  ],
  videos: [
    // description.preview array allows the highlighting to fall back onto a second field
    { label: 'Date (desc)', value: 'sort_date:desc', detail: {}, description: {} },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: {}, description: {} },
  ],
  credentials: [
    { label: 'Date Observed At (desc)', value: 'last_observed_at.timestamp:desc', detail: { domain: { field: 'domain', filter: 'domain' }, breach_title: { field: 'breach.title', filter: 'title' } }, description: { preview: ['email', 'password'] } },
    { label: 'Date Observed At (asc)', value: 'last_observed_at.timestamp:asc', detail: { domain: { field: 'domain', filter: 'domain' }, breach_title: { field: 'breach.title', filter: 'title' } }, description: { preview: ['email', 'password'] } },
  ],
  'customer-credentials': [
    { label: 'Date Observed At (desc)', value: 'last_observed_at.timestamp:desc', detail: { domain: { field: 'domain', filter: 'domain' }, breach_title: { field: 'breach.title', filter: 'title' } }, description: { preview: ['email', 'password'] } },
    { label: 'Date Observed At (asc)', value: 'last_observed_at.timestamp:asc', detail: { domain: { field: 'domain', filter: 'domain' }, breach_title: { field: 'breach.title', filter: 'title' } }, description: { preview: ['email', 'password'] } },
  ],
  cves: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { severity: 'nist.cvssv3.base_score' }, description: { preview: 'mitre.body.text/html+sanitized' } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { severity: 'nist.cvssv3.base_score' }, description: { preview: 'mitre.body.text/html+sanitized' } },
  ],
  exploits: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { severity: 'cve.nist.cvssv3.base_score' }, description: { preview: 'source_uri' } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { severity: 'cve.nist.cvssv3.base_score' }, description: { preview: 'source_uri' } },
  ],
  iocs: [
    { label: 'Date Observed (desc)', value: 'Event.date:desc', detail: { UUID: { field: 'Event.uuid', filter: 'uuid' }, indicators: 'Event.attribute_count' }, description: { preview: 'Event.info' } },
    { label: 'Date Observed (asc)', value: 'Event.date:asc', detail: { UUID: { field: 'Event.uuid', filter: 'uuid' }, indicators: 'Event.attribute_count' }, description: { preview: 'Event.info' } },
  ],
  forums: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { forum: { field: 'site.title', filter: 'sites' }, thread: { field: 'container.title' }, author: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { forum: { field: 'site.title', filter: 'sites' }, thread: { field: 'container.title' }, author: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  social: [
    // description.preview array allows the highlighting to fall back onto a second field
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { author: { field: 'site_actor.names.handle', filter: 'author' }, title: { field: 'title|container.title' }, page_type: { field: 'container.basetypes', func: (value) => { if (!value || !value?.includes('gab')) return 'IGNORE'; return (value.includes('group') ? 'Group' : 'Profile'); }, filter: 'type' }, subreddit: { field: 'container', func: (value) => { if (!value || value.get('basetypes')?.includes('gab')) return 'IGNORE'; return (value.has('container') ? value.getIn(['container', 'name']) : value.get('name')); }, filter: 'subreddit' }, site: { field: 'site.title', filter: 'sites' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { author: { field: 'site_actor.names.handle', filter: 'author' }, title: { field: 'title|container.title' }, page_type: { field: 'container.basetypes', func: (value) => { if (!value || !value?.includes('gab')) return 'IGNORE'; return (value.includes('group') ? 'Group' : 'Profile'); }, filter: 'type' }, subreddit: { field: 'container', func: (value) => { if (!value || value.get('basetypes')?.includes('gab')) return 'IGNORE'; return (value.has('container') ? value.getIn(['container', 'name']) : value.get('name')); }, filter: 'subreddit' }, site: { field: 'site.title', filter: 'sites' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  marketplaces: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { marketplace: { field: 'site.title', filter: 'sites' }, title: { field: 'title' }, vendor: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { marketplace: { field: 'site.title', filter: 'sites' }, title: { field: 'title' }, vendor: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  pastes: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { site: { field: 'site.title', filter: 'sites' }, title: { field: 'title' }, author: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { site: { field: 'site.title', filter: 'sites' }, title: { field: 'title' }, author: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.text/html+sanitized', 'body.text/plain'] } },
  ],
  reports: [
    { label: 'Relevancy', value: 'score:desc', detail: { title: { field: 'title' } }, description: { preview: 'summary' } },
    { label: 'Date (desc)', value: 'version_posted_at:desc', detail: { title: { field: 'title' } }, description: { preview: 'summary' } },
    { label: 'Date (asc)', value: 'version_posted_at:asc', detail: { title: { field: 'title' } }, description: { preview: 'summary' } },
  ],
  thread: [
    { label: 'Date (asc)', value: 'sort_date:asc' },
    { label: 'Date (desc', value: 'sort_date:desc' },
  ],
  twitter: [
    { label: 'Date (desc)', value: 'sort_date:desc', detail: { username: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.raw'] } },
    { label: 'Date (asc)', value: 'sort_date:asc', detail: { username: { field: 'site_actor.names.handle', filter: 'author' } }, description: { preview: ['body.raw'] } },
  ],

  // threads
  'threads.forums': [
    { label: 'Newest Post Date (desc)', value: 'message_count.last_resource.created_at.timestamp:desc' },
    { label: 'Newest Post Date (asc)', value: 'message_count.last_resource.created_at.timestamp:asc' },
  ],
};

const AllowedFilters = {
  all: [],
  common: [
    { filter: 'query', isQuery: true },
    { filter: 'sites', field: 'site.title', fpid: 'site.fpid', multiple: true, exact: true, exclude: true },
    { filter: 'since', field: 'sort_date', filterCorollary: 'until', date: true, defaultDate: true },
    { filter: 'required_enrichments', field: 'NA', generate: value => `+_exists_:_meta.enrichments.v1.social_media ${value
      .split(',')
      .map(v => v.split('::').slice(0, -1))
      .map(v => `+_exists_:enrichments.${v}`)
      .join(' ')}` },
    { filter: 'cves', field: 'NA', generate: value => `+enrichments.v1.vulnerability.cve.vulnerability.keyword:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v.replace(/"/g, '\\"')}"`)
      .join(' OR ')})` },
    { filter: 'bins', field: 'NA', generate: value => `+enrichments.card-numbers.card-numbers.bin:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v}"`)
      .join(' OR ')})` },
    { filter: 'ips', field: 'NA', generate: value => `+enrichments.v1.ip_addresses.ip_address:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v}"`)
      .join(' OR ')})` },
    { filter: 'urls', field: 'NA', generate: value => `+enrichments.v1.urls.url:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v}"`)
      .join(' OR ')})` },
    { filter: 'domains', field: 'NA', generate: value => `+enrichments.v1.urls.domain:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v}"`)
      .join(' OR ')})` },
    { filter: 'monero-wallets', field: 'NA', generate: value => `+enrichments.v1.monero_addresses.monero_address:(${value
      .replace(/\*/ig, '')
      .split(',')
      .join(' OR ')})` },
    { filter: 'ethereum-wallets', field: 'NA', generate: value => `+enrichments.v1.ethereum_addresses.ethereum_address:(${value
      .replace(/\*/ig, '')
      .split(',')
      .join(' OR ')})` },
    { filter: 'bitcoin-wallets', field: 'NA', generate: value => `+enrichments.v1.bitcoin_addresses.bitcoin_address:(${value
      .replace(/\*/ig, '')
      .split(',')
      .join(' OR ')})` },
    { filter: 'emails', field: 'NA', generate: (value, filters) => `+enrichments.v1.email_addresses.email_address${filters.email_exact ? '.keyword' : ''}:(${value
      .replace(/\*/ig, '')
      .split(',')
      .map(v => `"${v}"`)
      .join(' OR ')})` },
    { filter: 'handles', field: 'NA', generate: value => `+enrichments.v1.social_media.handle:${value
      .replace(/\*/ig, '')
      .split(',')
      .join(' OR ')})` },
    { filter: 'profiles', field: 'NA', generate: value => `+enrichments.v1.social_media.site:${value
      .replace(/\*/ig, '')
      .split(',')
      .join(' OR ')})` },
    { filter: 'author', field: 'site_actor', exclude: true, exact: true, generate: (value, filters) =>
      (value.includes(':')
        ? `${filters.exclude_author === 'true' ? '-' : '+'}site_actor.fpid:"${value.split(':').shift()}"`
        : `${filters.exclude_author === 'true' ? '-' : '+'}site_actor.names.handle${filters.author_exact === 'true' ? '.keyword' : ''}:(${value.split(',').map(v => `"${v.replace(/"/g, '\\"')}"`).join()})`) },
  ],
  accounts: [
    { filter: 'account_organization', field: 'account_organization', generate: value =>
      `+account_organization:("${value.replace(/"/g, '')}")` },
    { filter: 'email_domain', field: 'email_domain', generate: value =>
      `+email_domain:("${value.replace(/"/g, '')}")` },
    { filter: 'bank_account_type', field: 'bank_accounts.account_type', exact: true },
    { filter: 'bank_name', field: 'bank_accounts.bank_name', exact: true },
    { filter: 'has_bank_account', field: 'bank_accounts.has_bank_account' },
    { filter: 'seller', field: 'site_actor', exclude: true, exact: true, generate: (value, filters) =>
      (value.includes(':')
        ? `${filters.exclude_seller === 'true' ? '-' : '+'}site_actor.fpid:"${value.split(':').shift()}"`
        : `${filters.exclude_seller === 'true' ? '-' : '+'}site_actor.names.handle:(${value.split(',').map(v => `"${v.replace(/"/g, '\\"')}"`).join()}`) },
  ],
  blogs: [
    { filter: 'title', field: 'title' },
    { filter: 'raw_href', field: 'raw_href' },
  ],
  ransomware: [
    { filter: 'title', field: 'title' },
    { filter: 'raw_href', field: 'raw_href' },
  ],
  boards: [
    { filter: 'body', field: 'body.text/plain' },
    { filter: 'site', field: 'site.title' },
    { filter: 'board', field: 'container.container.title' },
    { filter: 'thread_id', field: 'container.native_id' },
    { filter: 'message', field: 'body.text/plain' },
    { filter: 'media_v2', field: 'body.enrichments.media_v2', generate: value =>
      `${value === 'true' ? '+_exists_:media_v2 +_exists_:media_v2.storage_uri' : '-_exists_:media_v2'}` },
      { filter: 'media_type', field: 'media_v2', generate: (value, filters) => {
        const mimetype = () => {
          switch (filters.media_type) {
            case 'Application':
              return 'application/*';
            case 'Image':
              return 'image/*';
            case 'Video':
              return 'video/*';
            default:
              return '';
          }
        };
        return !filters.mime_type
          ? `+media_v2.mime_type.keyword:(${mimetype(value)}) ${value === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}`
          : null; } },
      { filter: 'mime_type', field: 'media_v2.mime_type' },
      { filter: 'media_caption', field: 'media_v2.title' },
      { filter: 'filename', field: 'media_v2', generate: value =>
        `+(media_v2.filename:("${value}") |media_v2.file_name:("${value}") |media_v2.storage_uri:("${value}") |media_v2.sha256:("${value.split('.')[0]}"))` },
      { filter: 'sha1', field: 'media_v2.sha1', exclude: true },
      { filter: 'language', field: 'body.enrichments.language', generate: value =>
        `+body.enrichments.language:(${value.split(':').slice(0, 1).toString()})` },
  ],
  breaches: [
    { filter: 'key', field: 'title', generate: value =>
      `+title:(${value.split(',').map(v => `"${v.replace(/"/g, '\\"')}"`).join()})` },
    { filter: 'domain', field: 'domain.keyword', multiple: true, exact: true },
    { filter: 'breach_id', field: 'fpid' },
    { filter: 'breach_title', field: 'title', multiple: true },
    { filter: 'source_type', field: 'source_type', multiple: true, exact: true },
    { filter: 'top_domains', field: 'top_domains.value', multiple: true, exact: true },
    { filter: 'unique_records', field: 'unique_records' },
    { filter: 'breached_since', field: 'created_at.date-time', filterCorollary: 'breached_until', date: true },
    { filter: 'since', field: 'first_observed_at.date-time', filterCorollary: 'until', date: true, defaultDate: true },
  ],
  cards: [
    { filter: 'bin', field: 'bin', generate: (value) => {
      const bins = [];
      const split = value.split(/,| OR /);
      split.forEach((v) => {
        if (v.indexOf('-') !== -1) {
          const range = v.split('-').map(k => k.trim());
          if (range.length > 2 || Number.isNaN(range[0]) || Number.isNaN(range[1])) {
            // Not a valid range, add to list
            bins.push(v);
          } else {
            // Set the range of bins in format XXX TO XYY
            const [start, end] = range.map(m => parseInt(m, 10));
            bins.push(`[${start} TO ${end}]`);
          }
        } else {
          // No range, add to list
          bins.push(v);
        }
      });
      return bins.join(' OR '); } },
    { filter: 'country', field: 'cardholder_information.location.country.raw' },
    { filter: 'city', field: 'cardholder_information.location.city.raw' },
    { filter: 'zip_code', field: 'cardholder_information.location.zip_code' },
    { filter: 'card_type', field: 'card_type' },
    { filter: 'expiration', field: 'expiration', generate: value =>
      `${value === 'true' ? '+' : '-'}_exists_:expiration` },
    { filter: 'last4', field: 'last4', generate: value =>
      `${value === 'true' ? '+' : '-'}_exists_:last4` },
    { filter: 'tr1', field: 'track_information', generate: () => 'track_information:(TR1)' },
    { filter: 'release_name', field: 'base.title', generate: (value, filters) =>
      `${(filters.release_name_exact || filters.release_name_exact === 'true') && filters.release_name_exact !== 'false' ? `+base.title.keyword:("${value.replace(new RegExp('"', 'g'), '\\"')}")` : `+base.title:("${value.replace(new RegExp('"', 'g'), '\\"')}")`}` },
    { filter: 'payment_method', field: 'payment_method', generate: value =>
      `+payment_method:"(${value.replace('–', '')})"` },
    { filter: 'bank_name', field: 'bank_name' },
    { filter: 'is_verified_by_visa', field: 'is_verified_by_visa' },
    { filter: 'is_pin_available', field: 'is_pin_available' },
    { filter: 'track_information', field: 'track_information' },
    { filter: 'service_code', field: 'service_code' },
  ],
  chats: [
    { filter: 'server', field: 'container', generate: (value) => {
      if (value && value.includes('::')) {
        return `+(|container.container.fpid:("${value.split('::').shift()}") |container.server.fpid:("${value.split('::').shift()}") |server.fpid:("${value.split('::').shift()}"))`;
      } else if (value) {
        return `+(|container.container.name.keyword:("${value}") |container.server.name.keyword:("${value}") |server.name.keyword:("${value}"))`;
      }
      return null; } },
    { filter: 'server_id', field: 'container', generate: value =>
      `+(|container.container.native_id:(${value}) |container.server.native_id:(${value})) |server.native_id:(${value})` },
    { filter: 'channel', field: 'container', exclude: true, generate: (value, filters) => {
      if (value) {
        return value.includes('::')
          ? `(|container.fpid:(${value.split('::').shift()}) |channel.fpid:(${value.split('::').shift()}))`
          : `(|container.name.keyword:(${value.split(',').map(v => `${v.replace(/"/g, '\\"')}`).join()}) |container.username:(${value.split(',').map(v => `${v.replace(/"/g, '\\"')}`).join()}))`;
      } else if (filters.server) {
        return `+(|container.name:(${value}) |channel.name:(${value}))`;
      }
      return ''; } },
    { filter: 'channel_id', field: 'container', generate: value =>
      `+(|container.native_id:(${value}) |channel.native_id:(${value}))` },
    { filter: 'author_id', field: 'site_actor.native_id' },
    { filter: 'message', field: 'body.text/plain' },
    { filter: 'media', field: 'media_v2', generate: value =>
      `${value === 'true' ? '+_exists_:media +_exists_:media_v2.storage_uri' : '-_exists_:media_v2'}` },
    { filter: 'media_type', field: 'media_v2', generate: (value, filters) => {
      const mimetype = () => {
        switch (filters.media_type) {
          case 'Application':
            return 'application/*';
          case 'Image':
            return 'image/*';
          case 'Video':
            return 'video/*';
          default:
            return '';
        }
      };
      return !filters.mime_type
        ? `+media_v2.mime_type.keyword:(${mimetype(value)}) ${value === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}`
        : null; } },
    { filter: 'mime_type', field: 'media_v2.mime_type' },
    { filter: 'media_caption', field: 'media_v2.title' },
    { filter: 'filename', field: 'media_v2', generate: value =>
      `+(media_v2.filename:("${value}") |media_v2.file_name:("${value}") |media_v2.storage_uri:("${value}") |media_v2.sha256:("${value.split('.')[0]}"))` },
    { filter: 'sha1', field: 'media_v2.sha1', exclude: true },
    { filter: 'language', field: 'body.enrichments.language', generate: value =>
      `+body.enrichments.language:(${value.split(':').slice(0, 1).toString()})` },
  ],
  images: [
    { filter: 'message', field: 'body.text/plain' },
    { filter: 'media', field: 'media', generate: value =>
      `${value === 'true' ? '+_exists_:media +_exists_:media.storage_uri' : '-_exists_:media'}` },
    { filter: 'media_type', field: 'media', generate: (value, filters) => {
      const mimetype = () => {
        switch (filters.media_type) {
          case 'Application':
            return 'application/*';
          case 'Image':
            return 'image/*';
          case 'Video':
            return 'video/*';
          default:
            return '';
        }
      };
      return !filters.mime_type
        ? `+media.mime_type.keyword:(${mimetype(value)}) ${value === 'Application' ? '-media.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}`
        : null; } },
    { filter: 'mime_type', field: 'media.mime_type' },
    { filter: 'media_caption', field: 'media.title' },
    { filter: 'filename', field: 'media', generate: value =>
      `+(media.filename:("${value}") |media.file_name:("${value}") |media.storage_uri:("${value}") |media.sha256:("${value.split('.')[0]}"))` },
    { filter: 'sha1', field: 'media.sha1', exclude: true },
    { filter: 'language', field: 'body.enrichments.language', generate: value =>
      `+body.enrichments.language:(${value.split(':').slice(0, 1).toString()})` },
  ],
  videos: [
    { filter: 'message', field: 'body.text/plain' },
    { filter: 'media', field: 'media', generate: value =>
      `${value === 'true' ? '+_exists_:media +_exists_:media.storage_uri' : '-_exists_:media'}` },
    { filter: 'media_type', field: 'media', generate: (value, filters) => {
      const mimetype = () => {
        switch (filters.media_type) {
          case 'Application':
            return 'application/*';
          case 'Image':
            return 'image/*';
          case 'Video':
            return 'video/*';
          default:
            return '';
        }
      };
      return !filters.mime_type
        ? `+media.mime_type.keyword:(${mimetype(value)}) ${value === 'Application' ? '-media.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}`
        : null; } },
    { filter: 'mime_type', field: 'media.mime_type' },
    { filter: 'media_caption', field: 'media.title' },
    { filter: 'filename', field: 'media', generate: value =>
      `+(media.filename:("${value}") |media.file_name:("${value}") |media.storage_uri:("${value}") |media.sha256:("${value.split('.')[0]}"))` },
    { filter: 'sha1', field: 'media.sha1', exclude: true },
    { filter: 'language', field: 'body.enrichments.language', generate: value =>
      `+body.enrichments.language:(${value.split(':').slice(0, 1).toString()})` },
  ],
  media: [
    { filter: 'message', field: 'body.text/plain' },
    { filter: 'media', field: 'media', generate: value =>
      `${value === 'true' ? '+_exists_:media +_exists_:media.storage_uri' : '-_exists_:media'}` },
    { filter: 'media_type', field: 'media', generate: (value, filters) => {
      const mimetype = () => {
        switch (filters.media_type) {
          case 'Application':
            return 'application/*';
          case 'Image':
            return 'image/*';
          case 'Video':
            return 'video/*';
          default:
            return '';
        }
      };
      return !filters.mime_type
        ? `+media.mime_type.keyword:(${mimetype(value)}) ${value === 'Application' ? '-media.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}`
        : null; } },
    { filter: 'mime_type', field: 'media.mime_type' },
    { filter: 'media_caption', field: 'media.title' },
    { filter: 'filename', field: 'media', generate: value =>
      `+(media.filename:("${value}") |media.file_name:("${value}") |media.storage_uri:("${value}") |media.sha256:("${value.split('.')[0]}"))` },
    { filter: 'sha1', field: 'media.sha1', exclude: true },
    { filter: 'language', field: 'body.enrichments.language', generate: value =>
      `+body.enrichments.language:(${value.split(':').slice(0, 1).toString()})` },
  ],
  communities: [
    { filter: 'channel', field: 'container.title', fpid: 'container.fpid', multiple: true, exact: true, exclude: true },
  ],
  credentials: [
    { filter: 'alert_date_type', field: 'breach', generate: (value, filters) => {
      const { since, until, sinceDate } = SearchUtils.parseDate(filters);
      const credentialSince = filters.password_reset_policy &&
        filters.password_reset_policy !== null
        ? `${since !== '*' || moment.utc().subtract(parseInt(filters.password_reset_policy, 10), 'days').isAfter(moment.utc(sinceDate))
          ? `${filters.password_reset_policy}d`
          : since}`
        : since;
      return `+breach.${value === 'breached_at'
        ? 'created_at'
        : 'first_observed_at'}.date-time:[${credentialSince} TO ${until}]`; } },
    { filter: 'domain', field: 'domain', multiple: true },
    { filter: 'email', field: 'email', exact: true },
    { filter: 'password', field: 'password', exact: true },
    { filter: 'hash_types', field: 'hash_types', generate: (value) => {
      let hashes = [];
      let hashTypesQuery = '';
      if (value) {
        if (value.split(',').includes('Plaintext')) {
          hashes = value.split(',').filter(v => v !== 'Plaintext');
          hashTypesQuery = (hashes.length > 0)
            ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.filter(v => !hashes.includes(v)).map(v => `"${v}"`).join(' | ')})`
            : `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})`;
        } else {
          hashes = value.split(',');
          hashTypesQuery = `+password_complexity.probable_hash_algorithms.keyword:(${hashes.map(v => `"${v}"`).join(' | ')})`;
        }
      }
      return hashTypesQuery;
    } },
    { filter: 'customer_id', field: 'customer_id', generate: () => {
      const customerIdFilter = v => Text.SFIDConversion(v);
      return `+customer_id:("${customerIdFilter['15']}" | "${customerIdFilter['18']}")`; } },
    { filter: 'source_type', field: 'breach.source_type', exact: true },
    { filter: 'meets_org_profile_password_complexity', field: 'password_complexity.probable_hash_algorithms', generate: (value, filters) =>
      (value && filters.ignore_hashes === 'true'
        ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})`
        : null) },
    { filter: 'meets_org_profile_password_complexity', field: 'password_complexity.probable_hash_algorithms', generate: (value, filters) =>
      (value && filters.ignore_hashes === 'true'
        ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})`
        : null) },
    { filter: 'is_fresh', field: 'is_fresh', generate: value =>
      (value === 'true' ? '+is_fresh:(true)' : null) },
    { filter: 'length', field: 'password_complexity.length', generate: value =>
      `+password_complexity.length:[${value} TO *]` },
    { filter: 'title', field: 'breach', exact: true, generate: (value, filters) =>
      (value.includes('::')
        ? `+breach.fpid:"${value.split('::').shift()}"`
        : `+breach.title${(filters.title_exact || filters.title_exact === 'true') && filters.title_exact !== 'false' ? `.keyword:("${value}")` : `:(${value})`}`) },
    { filter: 'required', field: 'password_complexity', generate: (value) => {
      const required = value.split(',');
      return required.length ? required.map(v => `+password_complexity.has_${v}:(true)`).join(' ') : null;
    } },
    { filter: 'excluded', field: 'password_complexity', generate: (value) => {
      const excluded = value.split(',');
      return excluded.length ? excluded.map(v => `-password_complexity.has_${v}:(true)`).join(' ') : null;
    } },
    { filter: 'optional', field: 'password_complexity', generate: (value, filters) => {
      const optional = value.split(',');
      if (!optional.length) return null;
      let optionalQuery = null;
      if (filters.num_optional) {
        const numRequired = filters.num_optional;
        const indicies = [...Array(numRequired).keys()];
        switch (numRequired) {
          case 1:
            optionalQuery = `+(${optional.map(v => `|password_complexity.has_${v}:(true)`).join(' ')})`;
            break;
          default:
            optionalQuery = `+(${_.combinations(optional, numRequired).map(v => `|(${indicies.map(i => `+password_complexity.has_${v[String(i)]}:(true)`).join(' ')})`).join(' ')})`;
            break;
        }
      }
      return optionalQuery;
    } },
    { filter: 'has_lowercase', field: 'password_complexity.has_lowercase' },
    { filter: 'has_uppercase', field: 'password_complexity.has_uppercase' },
    { filter: 'has_symbol', field: 'password_complexity.has_symbol' },
    { filter: 'has_number', field: 'password_complexity.has_number' },
    { filter: 'key', field: 'breach.title', generate: value =>
      `+breach.title:(${value.split(',').map(v => `"${v.replace(/"/g, '\\"')}"`).join()})` },
    { filter: 'since', field: 'breach.created_at.date-time', filterCorollary: 'until', date: true, defaultDate: true },
  ],
  cves: [
    { filter: 'query', field: 'query', generate: value =>
      `+(${/".*?"/.test(value) ? value : `"${value}"`})` },
    { filter: 'cves', field: 'title', exact: true, multiple: true },
    { filter: 'cve_reporter', field: 'nist', generate: value =>
      `+_exists_:${value === 'nist' ? '(|nist.cvssv2 |nist.cvssv3)' : value}` },
    { filter: 'cve_base_score_2', field: 'nist.cvssv2.base_score', generate: value =>
      `+nist.cvssv2.base_score:[${value.split(',')[0]} TO ${value.split(',')[1]}]` },
    { filter: 'cve_base_score_3', field: 'nist.cvssv3.base_score', generate: value =>
      `+nist.cvssv3.base_score:[${value.split(',')[0]} TO ${value.split(',')[1]}]` },
    { filter: 'cve_vulnerability_types', field: 'nist.vulnerability_types', exact: true },
    { filter: 'cve_vendor_names', field: 'nist.products.vendor_names', generate: (value, filters) =>
      (filters.cve_vendor_names || filters.cve_product_names
        ? `${filters.cve_vendor_names
          ? `+(|${filters.cve_vendor_names.split(',').map(v => `nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
          : ''}${filters.cve_product_names
          ? `+(|${filters.cve_product_names.split(',').map(v => `nist.products.product_name:(${v})`).join(' |')})`
          : ''}`
        : null) },
    { filter: 'cve_product_names', field: 'nist.products.product_names', generate: (value, filters) =>
      (filters.cve_vendor_names || filters.cve_product_names
        ? `${filters.cve_vendor_names
          ? `+(|${filters.cve_vendor_names.split(',').map(v => `nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
          : ''}${filters.cve_product_names
          ? `+(|${filters.cve_product_names.split(',').map(v => `nist.products.product_name:(${v})`).join(' |')})`
          : ''}`
        : null) },
    { filter: 'required_cve_tags', field: 'nist', generate: value =>
      value
        .split(',')
        .map(v => `+_exists_:(${v.split('::').shift()})`)
        .join(' ') },
    { filter: 'cve_severity', field: 'nist.cvssv3.severity', generate: value =>
      `+nist.cvssv3.severity:(${value})` },
    { filter: 'since', field: 'sort_date', generate: (value, filters) =>
      (!filters.since
        ? '+sort_date:[* TO now]'
        : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`) },
  ],
  exploits: [
    { filter: 'query', field: 'query', generate: value =>
      `+(${/".*?"/.test(value) ? value : `"${value}"`})` },
    { filter: 'cves', field: 'title', exact: true, multiple: true },
    { filter: 'cve_reporter', field: 'nist', generate: value =>
      `+_exists_:${value === 'nist' ? '(|cve.nist.cvssv2 |cve.nist.cvssv3)' : value}` },
    { filter: 'cve_base_score_2', field: 'nist.cvssv2.base_score', generate: value =>
      `+cve.nist.cvssv2.base_score:[${value.split(',')[0]} TO ${value.split(',')[1]}]` },
    { filter: 'cve_base_score_3', field: 'nist.cvssv3.base_score', generate: value =>
      `+cve.nist.cvssv3.base_score:[${value.split(',')[0]} TO ${value.split(',')[1]}]` },
    { filter: 'cve_vulnerability_types', field: 'nist.vulnerability_types', exact: true },
    { filter: 'cve_vendor_names', field: 'nist.products.vendor_names', generate: (value, filters) =>
      (filters.cve_vendor_names || filters.cve_product_names
        ? `${filters.cve_vendor_names
          ? `+(|${filters.cve_vendor_names.split(',').map(v => `cve.nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
          : ''}${filters.cve_product_names
          ? `(|${filters.cve_product_names.split(',').map(v => `cve.nist.products.product_name:(${v})`).join(' |')})`
          : ''}`
        : null) },
    { filter: 'cve_product_names', field: 'nist.products.product_names', generate: (value, filters) =>
      (filters.cve_vendor_names || filters.cve_product_names
        ? `${filters.cve_vendor_names
          ? `+(|${filters.cve_vendor_names.split(',').map(v => `cve.nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
          : ''}${filters.cve_product_names
          ? `(|${filters.cve_product_names.split(',').map(v => `cve.nist.products.product_name:(${v})`).join(' |')})`
          : ''}`
        : null) },
    { filter: 'required_cve_tags', field: 'nist', generate: value =>
      value
        .split(',')
        .map(v => `+_exists_:(cve.${v.split('::').shift()})`)
        .join(' ') },
    { filter: 'cve_severity', field: 'nist.cvssv3.severity', generate: value =>
      `+cve.nist.cvssv3.severity:(${value})` },
    { filter: 'since', field: 'sort_date', generate: (value, filters) =>
      (!filters.since
        ? '+sort_date:[* TO now]'
        : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`) },
  ],
  iocs: [
    { filter: 'uuid', field: 'Event.uuid' },
    { filter: 'query', field: 'Event' },
    { filter: 'event_type', field: 'Event.Tag.name', generate: value =>
      `+Event.Tag.name:("${value.split('::').shift()}")` },
    { filter: 'ioc_tags', field: 'Event.Tag.name', multiple: true, exclude: true },
    { filter: 'required_ioc_tags', field: 'Event.Tag.name', generate: value =>
      value
        .split(',')
        .map(v => (v === 'attack_ids' ? '+_exists_:Event.attack_ids' : `+Event.Tag.name:(${v.split('::').shift()}*)`))
        .join(' ') },
    { filter: 'attack_ids', field: 'Event', generate: value =>
      `'Event.attack_ids:(${value.split(',').map(v => `"${v.split(':').shift()}"`).join()})` },
    { filter: 'ioc_category', field: 'category', multiple: true },
    { filter: 'ioc_type', field: 'type', multiple: true, exclude: true },
    { filter: 'ioc_value', field: 'value', generate: (value, filters) => (filters.ioc_type
      ? `+(${filters.ioc_type.split(',').map(v => `|value.${v.replace('|', '\\|')}:(${filters.ioc_value.split(',').join()})`).join(' ')})`
      : `+value.\\*:(${filters.ioc_value.split(',').join()})`) },
    { filter: 'has_related_events', field: 'Event.RelatedEvent', generate: value =>
      `${value === 'true' ? '+_exists_:Event.RelatedEvent' : '-_exists:Event.RelatedEvent'}` },
    { filter: 'has_report', field: 'Event.Tag.name', generate: value =>
      `${value === 'true' ? '+Event.Tag.name:(report*)' : '-Event.Tag.name:(report*)'}` },
    { filter: 'has_config', field: 'Event', generate: value =>
      `${value === 'true'
        ? '+Event.Tag.name:("extracted_config:true") +type:text'
        : '-Event.Tag.name:("extracted_config:true")'}` },
    { filter: 'since', field: 'Event.date', filterCorollary: 'until', date: true, defaultDate: true, generate: (value, filters) => {
      const { sinceDate, untilDate } = SearchUtils.parseDate({ date: filters.date });
      return `+Event.date:[${moment.utc(sinceDate).unix()} TO ${moment.utc(untilDate).unix()}]`;
    } },
  ],
  forums: [
    { filter: 'body', field: 'body.text/plain', generate: value => `+(|body.text/plain:(${value}) |enrichments.v1.translation.\\*:(${value}))` },
    { filter: 'room_title', field: 'container.container.title', fpid: 'container.container.fpid', exact: true, exclude: true },
    { filter: 'thread_title', field: 'container.title', fpid: 'container.fpid', exact: true, exclude: true },
    { filter: 'site_tags', field: 'site.tags.name', exclude: true, exact: true, generate: (value) => {
      let siteTags = '';
      if (value) {
        const topics = value.split(':');
        topics.forEach((v) => {
          const tagFilter = v.split(',');
          const hasClearnet = tagFilter.findIndex(t => t === 'Clearnet') !== -1;
          const hasTor = tagFilter.findIndex(t => t === 'Tor') !== -1;
          const hasPasswordProtected = tagFilter.findIndex(t => t === 'Password-Protected') !== -1;
          const toAdd = !hasClearnet
            ? tagFilter
            : list(tagFilter).filterNot(t => (hasTor && t === 'Tor') || (hasPasswordProtected && t === 'Password-Protected') || (t === 'Clearnet'));
          if (toAdd.length > 0) siteTags += ` +site.tags.name:(${toAdd.map(t => `"${t.replace(/"/g, '\\"')}"`).join(' ')})`;
          if (hasClearnet && !(hasTor && hasPasswordProtected)) siteTags += ` -site.tags.name:(${!hasTor ? '"Tor",' : ''}${!hasPasswordProtected ? '"Password-Protected"' : ''})`;
        });
      }
      return siteTags;
    } },
  ],
  social: [
    { filter: 'id', field: 'fpid', generate: value =>
      `+fpid:("${value.split('|')[0]}")` },
    { filter: 'body', field: 'body.text/plain' },
    { filter: 'type', field: 'container.basetypes', generate: value =>
      `${value === 'group' ? '+container.basetypes:(group)' : '+container.basetypes:(site-actor-profile)'}` },
    { filter: 'subreddit', field: 'container.container.name', generate: value =>
      `+(|container.container.name:(${value}) |container.name:(${value}))` },
    { filter: 'page', field: 'container.title', fpid: 'container.fpid', multiple: true, exact: true, exclude: true },
  ],
  marketplaces: [
    { filter: 'title', field: 'title' },
    { filter: 'description', field: 'body.text/plain' },
    { filter: 'since', field: 'sort_date', filterCorollary: 'until', date: true, defaultDate: true },
  ],
  pastes: [
    { filter: 'title', field: 'title' },
    { filter: 'syntax', field: 'syntax' },
    { filter: 'native_id', field: 'native_id' },
  ],
  reports: [
    { filter: 'remove_styles' },
    { filter: 'report_ids' },
    { filter: 'body' },
    { filter: 'title' },
    { filter: 'tags' },
  ],
  twitter: [
    { filter: 'id', field: 'fpid', generate: value =>
      `+fpid:("${value.split('|')[0]}")` },
    { filter: 'body', field: 'body.text/plain' },
    { filter: 'type', field: 'container.basetypes', generate: value =>
      `${value === 'group' ? '+container.basetypes:(group)' : '+container.basetypes:(site-actor-profile)'}` },
    { filter: 'subreddit', field: 'container.container.name', generate: value =>
      `+(|container.container.name:(${value}) |container.name:(${value}))` },
    { filter: 'page', field: 'container.title', fpid: 'container.fpid', multiple: true, exact: true, exclude: true },
  ],

  // threads
  'threads.forums': [
    { filter: 'since', field: 'message_count.last_resource.created_at.date-time', filterCorollary: 'until', date: true, defaultDate: true },
  ],
};

const StatusOK = [200, 400, 500, 501, 502, 503, 504];

export default {
  Fields,
  Includes,
  InlineIncludes,
  Limits,
  Groups,
  Sorts,
  AllowedFilters,
  StatusOK,
  TypeGroups,
};
