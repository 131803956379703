import * as React from 'react';
import { CountWidget } from '../CountWidget';
import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import type { ResultCountPreferences } from '../../../../../core/types/ExpoDashPreferences';
import Query from './query';

export interface ResultCountWidgetProps {
  config: ResultCountPreferences,
  filters: ExpoDashFilters,
  /** Mouse click handler */
  setDialog: (value: object) => void,
  onClick?: (defaultSearch: boolean) => void,
}

export const ResultCountWidget = (props: ResultCountWidgetProps) => (
  <CountWidget
    label="Result Count"
    tooltip="Displays the number of results for your saved search"
    query={Query.Results}
    {...props}
    />
);

ResultCountWidget.defaultProps = {
  onClick: null,
};
