import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { useMutation } from '@apollo/client';

import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { devClient } from '../../../gql/apolloClient';

import { sanitizeBins, sanitizeBinTags, sanitizeBinPFL } from '../../../utils/cfm';
import query, { createEditBin } from './ManageBins/query';
import BulkAddBinsTable from './ManageBins/BulkAddBinsTable';

const useStyles = makeStyles(theme => ({
  controller: {
    margin: '4%',
    width: '90%',
  },
  editor: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: '#f00',
    display: 'block',
    margin: 0,
    position: 'absolute',
    marginLeft: '4%',
  },
  warning: {
    display: 'flex',
    margin: 0,
    position: 'absolute',
  },
  success: {
    marginBottom: '5%',
    marginLeft: '4%',
  },
  hidden: {
    display: 'none',
  },
  delete: {
    paddingLeft: '2.5rem',
  },
  button: {
    margin: '2.5%',
  },
  bulkAddButton: {
    marginLeft: '3.5%',
    width: '25%',
  },
  chips: {
    paddingTop: '-2.5rem',
    paddingBottom: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const Editor = ({ variables, bins, close, unselect, values, edit }) => {
  const classes = useStyles();
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm();
  const [chips, setChips] = useState(values.binTags.map(v => v.name));

  // separate variable to not immediately render BulkAddBinsTable after submitting a bin edit
  const [edited, setEdited] = useState(false);

  const [createEdit, {
    loading,
    error,
    data,
  }] = useMutation(createEditBin, {
    refetchQueries: [{ query, variables }],
    client: devClient,
  });

  useEffect(() => {
    register('tags');
  }, [register]);

  const onChipDelete = (inputChip) => {
    const updated = [...chips];
    const index = updated.indexOf(inputChip);
    if (index > -1) {
      updated.splice(index, 1);
    }
    setChips(updated);
    setValue('tags', updated);
  };

  const onSubmit = (binData) => {
    const newBins = sanitizeBins(bins);
    const findBin = bins.findIndex(bin => bin.number === parseInt(binData.bin, 10));

    let newBinTags = binData.tags ? binData.tags.split(',') : [];
    newBinTags = sanitizeBinTags(newBinTags);

    // get current tags and then combine them with the new tags
    let tags = chips ? chips.map(name => ({ name })) : [];
    tags = tags.concat(binData.tags ? newBinTags.map(name => ({ name })) : []);

    const newBinPFL = sanitizeBinPFL(binData.pfl);

    let updateData = {
      ...variables,
      binNumber: Number(binData.bin),
      binTags: tags,
      binRoi: newBinPFL,
    };

    if (findBin === -1) {
      newBins.push({
        number: Number(binData.bin),
        binTags: tags,
      });
    } else {
      newBins[Number(findBin)] = merge(newBins[Number(findBin)], {
        number: Number(binData.bin),
        binTags: tags,
      });

      // use tags from updateData only
      delete newBins[Number(findBin)].binTags;

      updateData = {
        ...updateData,
        ...newBins[Number(findBin)],
      };
    }

    if (findBin > -1) {
      updateData.id = newBins[Number(findBin)].id;
    }

    createEdit({ variables: updateData });
    setEdited(true);
    unselect();
  };

  const onClose = () => {
    close();
    setEdited(false);
  };

  return (
    <div>
      { !edit && !edited ?
        <BulkAddBinsTable
          variables={variables}
          close={close}
        /> :
        <form
          className={classes.editor}
          onSubmit={handleSubmit(formData => onSubmit(formData))}>
          <Controller
            render={({ field }) => <TextField className={classes.controller} id="standard-basic" label="Bin" disabled {...field} />}
            shouldUnregister
            name="bin"
            control={control}
            rules={{
              required: 'Field Requried!',
              maxLength: 6,
              validate: value => !Number.isNaN(Number(value)),
            }}
            defaultValue={values.number}
            className={classes.controller}
              />
          <p className={errors.bin ? classes.error : classes.hidden}>
            {errors.bin && errors.bin.type === 'required' && errors.bin.message}
            {errors.bin && errors.bin.type === 'maxLength' && 'Bin should be at most 6 digits!'}
            {errors.bin && errors.bin.type === 'validate' && 'Bin should not contain non numerical values!'}
          </p>
          <Controller
            render={({ field }) => <TextField className={classes.controller} id="standard-basic" label="Potential Fraud Loss" {...field} />}
            shouldUnregister
            name="pfl"
            control={control}
            rules={{
              maxLength: 15,
              validate: value => /^\d{0,15}\.?(\d{0,2})$/.test(value) || !value,
            }}
            defaultValue={values.roi}
            className={classes.controller}
          />
          <p className={errors.pfl ? classes.error : classes.hidden}>
            {errors.pfl && errors.pfl.type === 'maxLength' && 'Potential Fraud Loss should be at most 15 digits!'}
            {errors.pfl && errors.pfl.type === 'validate' && 'Potential Fraud Loss should not contain more than two decimal places or non numerical values!'}
          </p>
          <Controller
            render={({ field }) => <TextField className={classes.controller} id="standard-basic" label="Tags" {...field} />}
            shouldUnregister
            name="tags"
            control={control}
            helperText="Add tags as a comma-separated list"
            className={classes.controller}
          />
          <div className={classes.chips}>
            {chips.map(chip => (
              <Chip key={chip} label={chip} onDelete={() => onChipDelete(chip)} />
            ))}
          </div>
          <div>
            {loading && <CircularProgress size={25} thickness={2} />}
            {data &&
              <div className={classes.success}>
                Bin has been saved successfully!
                <p className={classes.warning}>
                  NOTE: Data for newly added or modified BINs will appear in approximately 24 hours.
                </p>
              </div>
            }
            {
              !loading && !data ?
                <React.Fragment>
                  <Button className={classes.button} type="submit" label="submit">Submit</Button>
                  <Button onClick={onClose} label="close">Close</Button>
                </React.Fragment> :
                <Button className={classes.button} onClick={onClose} label="close">Close</Button>
            }
            {error &&
              <div>
                An error has occurred while updating a BIN. Please reach out to your
                Customer Success Representative.
              </div>
            }
          </div>
        </form>
      }
    </div>
  );
};

Editor.propTypes = {
  variables: PropTypes.object,
  bins: PropTypes.array, // not required because of new customers with no bins in shops
  close: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  values: PropTypes.object,
  edit: PropTypes.bool.isRequired,
};

Editor.defaultProps = {
  values: {
    number: '',
    binTags: [],
  },
  variables: {},
  bins: [],
};

export default Editor;
