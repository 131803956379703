import React, { useEffect, useState } from 'react';
import { CircularProgress, Paper, Switch, TextareaAutosize } from '@mui/material';

import style from '../sanctions.module.scss';
import History from '../../../utils/history';
import Api from '../../../utils/api';
import Token from '../../../utils/token';
import Messages from '../../../constants/Messages';
import { getStatusString, searchStatusEnum } from '../Constants/SearchStatus';
import SearchActions from '../../../actions/searchActions';

const SanctionsForm = (() => {
  const [search, setSearch] = useState('');
  const [client, setClient] = useState('');
  const [fuzzySearch, setFuzzySearch] = useState(false);
  const [loading, setLoading] = useState(false);
  // boolean to keep track of whether the component is loaded or not
  let isOnPage = true;

  // set isOnPage if the component unloads
  useEffect(() => function cleanup() {
    isOnPage = false;
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (search === '' || loading) {
      return;
    }
    const query = { search_strings: search.replaceAll('\n', ','), is_fuzzy: fuzzySearch };
    if (client !== '') {
      Object.assign(query, { clientNames: client });
    }
    setLoading(true);
    Api.get('/ui/v4/sanctions/search', query, [200], 60000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        if (!res.status) {
          Object.assign(res, { status: getStatusString(searchStatusEnum.PREPAYMENT_CHECK) });
        }
        History.push({
          pathname: `/home/sanctions/savedSearch/${res.id}`,
        });
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setLoading(false);
      });
  };

  const toggleFuzzySearch = () => {
    setFuzzySearch(prev => !prev);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <div data-cy="newSearch" className={style.sanctionssection}>
        <div className={style.title}>
          New Search
        </div>
        <Paper className={style.card}>
          <form className={style.form} onSubmit={handleSubmit}>
            <label htmlFor="searchClient" className={style.clientname}>
              <span className={style.insetspan}>Client Name:</span>
              <input id="searchClient" value={client} onChange={event => setClient(event.target.value)} />
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="searchIsFuzzy" className={style.fuzzysearch}>
              <span className={style.insetspan}>Fuzzy Search:</span>
              <Switch id="searchIsFuzzy" checked={fuzzySearch} onChange={toggleFuzzySearch} />
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="searchTerm" className={style.searchterm}>
              <span className={style.insetspan}>Search Term:</span>
              <TextareaAutosize id="searchTerm" name="term" value={search} onChange={event => handleSearchChange(event)} />
            </label>
            <input className={style.formsubmit} type="submit" value="Submit" disabled={loading} />
            {loading && <CircularProgress />}
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
});

export default SanctionsForm;
