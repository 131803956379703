import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  Button,
  Icon,
  Paper,
  Popover,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import style from './filters.module.scss';
import Chips from '../Chips';
import Query from './query';
import Iso from '../../../constants/Iso';
import History from '../../../utils/history';
import DateFilter from '../../filters/DateFilter';
import ListFilter from '../../filters/ListFilter';
import SitesFilter from '../../filters/SitesFilter';
import ForumsFilter from '../../filters/ForumsFilter';
import TagsFilter from '../../filters/TagsFilter';
import TextFilter from '../../filters/TextFilter';
import SelectFilter from '../../filters/SelectFilter';
import SaveSearchIcon from '../../utils/SaveSearchIcon';
import Autocomplete from '../../../constants/Autocomplete';
import SearchActions from '../../../actions/searchActions';
import SearchSectionsSearch from '../../../stores/recoil/searchSectionsSearch';
import AlertingStore from '../../../stores/recoil/alerting';
import AlertingQuery from '../../../containers/Alerting/query';
import { MediaTypes, SafeSearchTypes } from '../../../constants/Media';
import CCM from '../../../utils/ccm';
import Text from '../../../utils/text';
import { knownHashes } from '../../../constants/org_profiles/Edm';
import HtmlUtils from '../../../utils/htmlUtils';
import { Track } from '../../../utils/track';

const Filters = ({
  advanced,
  defaults,
  filters,
  setFilters,
  onFilter,
  store,
  searches,
  type,
  user,
}) => {
  const selectedSection = useRecoilValue(SearchSectionsSearch.selectedSection(type));
  const alertingBasetypes = useRecoilValue(AlertingStore.basetypes);
  const alertingRecipients = useRecoilValue(AlertingStore.notificationProfiles);
  const alertingOwnerId = useRecoilValue(AlertingStore.ownerId);
  const [dialog, setDialog] = useState({});
  const [alertingKeywordClasses, setAlertingKeywordClasses]
    = useRecoilState(AlertingStore.keywordClasses);
  const [alertingKeywordsFilter, setAlertingKeywordsFilter]
    = useRecoilState(AlertingStore.keywordsFilter);

  /**
  NOTE: Filter updates can be managed in two ways:
    1. Pushing to the URL query params and using that as SSoT for filter state
    2. Managing filter state in a controlled fashion using a setFilters function

  If setFilters is not provided, onApply will be null and the default URL
  query param behavior will be used.

  If a setFilters function is provided as a prop, this component will use an
  update function (onApply) in its children filter components to control state.
  Using controlled filters, this component will also have to track dirty state.
  (aka, when filters have been changed but search has not yet been reloaded)

  Controlled filter management via setFilters & onApply is currently only used
  in the Search container.
   */
  const [dirty, setDirty] = useState(false);
  const onApply = setFilters
    ? ((updates) => {
      // filters object should correspond to query params, so make sure values are strings
      const stringified = updates
        .map(v => (v ? v.toString() : ''));
      setDirty(true);
      if (setFilters) {
        setFilters(filters.merge(stringified));
      }
    })
    : null;

  const defaultDateLabels = {
    'tagging.channels': 'Date Joined',
  };

  const empty = !filters ? true : filters
    .filter(v => v)
    .filterNot((v, k) => [
      'id',
      'interval',
      'group',
      'query',
      'sort',
      'skip',
      'limit',
      'latest',
      'status',
      'owner_id',
      'query_i18n',
      ...(type === 'alerting.keywords' ? ['text'] : []),
      'section',
    ].includes(k))
    .filterNot((v, k) => (typeof v === 'object' ? v.equals(defaults.get(k)) : v === defaults.get(k)))
    .isEmpty();

  const options = !filters ? [] : [
    !['alerting.keywords', 'alerting.recipients'].includes(type) &&
    { key: 'date',
      label: defaultDateLabels[String(type)] || 'Date',
      icon: 'date_range',
      size: 2,
      fields: ['date'],
      values: { date: ['date', 'since', 'until'] },
      hidden: v => v === 'All Time',
      hiddenOnAdvanced: true,
      dialog: advanced ? (
        <React.Fragment>
          <Button
            color="primary"
            id="date"
            name="advanced.date"
            className={cx([style.h4, 'h4', style.open, style.dropdown])}
            onClick={e => setDialog(({ target: e.currentTarget, key: 'date' }))}
            endIcon={<Icon className={cx([style.icon])}>keyboard_arrow_down</Icon>}>
            {filters.get('date') || 'All Time'}
          </Button>
          <Popover
            anchorEl={dialog && dialog.target}
            open={Boolean(dialog && dialog.key === 'date')}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={() => setDialog()}>
            <DateFilter
              user={user}
              type={type}
              date={filters.get('date')}
              since={filters.get('since')}
              until={filters.get('until')}
              dates={store.get('dates')}
              toggle={() => setDialog()}
              onApply={onApply}
              onFilter={onFilter} />
          </Popover>
        </React.Fragment>)
        : (
          <DateFilter
            user={user}
            type={type}
            date={filters.get('date')}
            since={filters.get('since')}
            until={filters.get('until')}
            dates={store.get('dates')}
            onApply={onApply}
            toggle={() => setDialog()}
          />) },
    { type: ['all', 'communities'],
      key: 'all',
      label: 'Platform',
      icon: 'language',
      size: 6,
      fields: ['all', 'all_exact', 'exclude_all'],
      dialog: (<SelectFilter
        user={user}
        type={type}
        advanced={advanced}
        option="all"
        onApply={(value) => {
          const [k] = value.valueSeq();
          // when platform selection contains only one value
          // route to specific basetype search
          const { query, hash } = History.getCurrentLocation();
          if (k?.split(',')?.length < 2 && !['media'].includes(type)) {
            History.push({
              pathname: `/home/search/${k}`,
              query,
              hash,
            });
          }
          onApply(value);
        }}
        onFilter={onFilter}
        item={filters.get('all')}
        items={user.get('apps', list())
          .filter(v => v.get('search'))
          .filter(v => !v.get('hidden'))
          .filter(v => v.get('parent') === 'collections')
          .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
          .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
          .filter(v => user.get('prm').some(p => v.get('test').test(p)))
          .filter(v => ((v.get('searchType') === 'twitter') ? user.get('prm').some(p => /twtr/ig.test(p)) : true))
          .filter(v => ((type === 'communities')
            ? (v.get('subHeader') || '').toLowerCase() === type && v.get('searchType') !== type
            : true))
          .filter(v => (type === 'media' ? /boards|chat|ransomware|social/ig.test(v.get('value')) : v))
          .map(v => v.get('value').split('.').pop())}
        toggle={() => setDialog()} />) },
    { type: ['all', 'communities'],
      key: 'username',
      label: 'Username',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['all', 'communities'],
      key: 'channel',
      label: 'Site & Channel',
      icon: 'line_style',
      size: 4,
      fields: ['sites', 'sites_exact', 'exclude_sites', 'channel', 'channel_exact', 'exclude_channel', 'title', 'title_exact', 'title_channel'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'sites', label: 'Site Name', type: 'text', canExclude: true },
            { value: 'sites_exact', label: 'Exact Site Match', type: 'toggle' },
            { value: 'channel', label: 'Channel Name', type: 'text', canExclude: true },
            { value: 'channel_exact', label: 'Exact Channel Match', type: 'toggle' },
            { value: 'title', label: 'Title', type: 'text', canExclude: true },
            { value: 'title_exact', label: 'Exact Title Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['reports'],
      key: 'keywords',
      label: 'Keywords',
      icon: 'text_fields',
      fields: ['body', 'title'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'body', label: 'Report Contains', type: 'text' },
            { value: 'title', label: 'Title Contains', type: 'text' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['reports'],
      key: 'topics',
      label: 'Topics',
      icon: 'local_offer',
      size: 6,
      fields: ['tags'],
      dialog: (
        <ListFilter
          user={user}
          type={type}
          onApply={onApply}
          onFilter={onFilter}
          advanced={advanced}
          option="tags"
          options={store
            .get('tags')
            .filter(t => (!user.get('prm').some(p => /(vln|cve).r/.test(p))
              ? !/exploits & vulnerabilities/ig.test(t)
              : t))}
          selectedTags={filters.get('tags')}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['reports'],
      key: 'types',
      label: 'Report Type',
      icon: 'ballot',
      fields: ['types'],
      dialog: (
        <SelectFilter
          user={user}
          type={type}
          onFilter={onFilter}
          onApply={onApply}
          advanced={advanced}
          option="types"
          item={filters.get('types')}
          items={fromJS([
            'Standup',
            'Actor Profile',
            'Knowledge Base',
            'Malware',
            'Technical Intelligence',
          ])}
          toggle={() => setDialog()} />) },
    { type: ['alerting.alerts'],
      key: 'platform',
      label: 'Platform',
      icon: 'web_asset',
      size: 3,
      fields: ['platform'],
      dialog: (
        <SelectFilter
          user={user}
          type={type}
          advanced={advanced}
          option="platform"
          onFilter={onFilter}
          item={(filters.get('platform') || '').toLowerCase()}
          items={alertingBasetypes
            .filter(v => user.get('prm').some(p => v.get('test').test(p)))
            .filter(v => ((type === 'alerting.alerts')
              ? v.get('searchType') !== 'code_repository'
              : v.get('searchType') === 'code_repository'))
            .map(v => (v.get('title') || '').toLowerCase())}
          toggle={() => setDialog()} />) },
    { type: ['alerting.alerts', 'alerting.dea'],
      key: 'categories',
      label: 'Categories',
      icon: 'text_fields',
      size: 12,
      fields: ['categories'],
      dialog: (
        <SelectFilter
          user={user}
          advanced={advanced}
          option="categories"
          onFilter={onFilter}
          item={filters.get('categories')}
          items={alertingKeywordClasses
            .map(v => v.get('name'))}
          toggle={() => setDialog()} />) },
    { type: ['alerting.alerts', 'alerting.dea'],
      key: 'name',
      label: 'Keyword Name',
      icon: 'label',
      size: 4,
      fields: ['keyword'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          fields={[
            {
              value: 'keyword',
              label: 'Keyword Name',
              type: 'autocomplete',
              placeholder: 'Search keyword label',
              noOptionsText: 'Begin typing to see a list of options',
              required: true,
              opts: alertingKeywordsFilter
                .filter(v => v.get('name'))
                .sortBy(v => v.get('name'))
                .map(v => ({
                  label: v.get('name'),
                  value: `${v.get('name')}:${v.get('value')}::${v.get('id')}`,
                  query: v.get('value'),
                })),
              onInputChange: async (searchString) => {
                if (searchString.includes('::')) return;
                await Promise.all([
                  AlertingQuery.searchKeywords(searchString, alertingOwnerId),
                ]).then(([res]) => {
                  if (!res || res === 'canceled') return;
                  setAlertingKeywordsFilter(res);
                });
              },
              optionLabel: value => (value ? `${value.label.replace(/"/g, '')}${value.query ? `::${value.query}` : ''}` : ''),
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['alerting.alerts', 'alerting.dea'],
      key: 'tags',
      label: 'Tags',
      icon: 'star',
      size: 4,
      fields: ['tags'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          fields={[
            { value: 'tags', label: 'Tags', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'starred', label: 'Starred' }, { value: 'archived', label: 'Archived' }, { value: '-starred', label: 'Not Starred' }, { value: '-archived', label: 'Not Archived' }]) },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['alerting.alerts', 'alerting.dea'],
      key: 'notification_profile',
      label: 'Notification Profile',
      icon: 'account_circle',
      size: 4,
      hiddenOnAdvanced: true,
      noPermission: !user.get('prm').some(p => /dat.rta.r/.test(p)),
      fields: ['notification_profile'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          fields={[{
            value: 'notification_profile',
            label: 'Notification Profile',
            type: 'autocomplete',
            placeholder: 'Search Notification Profile',
            opts: fromJS(alertingRecipients?.get('data', list())
              .map(v => map({ label: v.get('endpoint'), value: v.get('id') }))),
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['alerting.keywords'],
      key: 'keyword',
      label: 'Keyword',
      icon: 'text_fields',
      size: 4,
      format: v => v,
      fields: ['status', 'delivery', 'sources', 'category'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          fields={[
            { value: 'status', label: 'Keyword Status', type: 'dropdown', opts: fromJS([{ value: '', label: 'Active/Inactive' }, { value: 'ACTIVE', label: 'Active' }, { value: 'INACTIVE', label: 'Inactive' }]) },
            { value: 'delivery', label: 'Delivery', type: 'dropdown', opts: fromJS([{ value: '', label: 'Automated/Curated' }, { value: 'AUTOMATED', label: 'Automated' }, { value: 'CURATED', label: 'Curated' }]) },
            { value: 'sources', label: 'Sources', type: 'dropdown', opts: fromJS([{ value: '', label: 'All Data Sources' }, { value: 'COLLECTED', label: 'Flashpoint Collected Sources' }, { value: 'DEA', label: 'Data Exposure Alerting' }]) },
            { value: 'category', label: 'Category', type: 'dropdown', opts: fromJS([
              { value: '', label: 'All Categories' },
              ...alertingKeywordClasses
                .sortBy(v => v.get('name'))
                .map(v => fromJS({ label: Text.Sentence(v.get('name')), value: v.get('name') })).toJS(),
              ]) },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['alerting.recipients'],
      key: 'keyword',
      label: 'Recipient',
      icon: 'account_circle',
      size: 4,
      format: v => v,
      fields: ['status'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          fields={[
            { value: 'status', label: 'Notifications', type: 'dropdown', opts: fromJS([{ value: '', label: 'Any Notifications' }, { value: 'ACTIVE', label: 'Receiving Automated Alerts' }, { value: 'INACTIVE', label: 'Not Receiving Automated Alerts' }]) },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['forums'],
      key: 'keywords',
      label: 'Keywords',
      icon: 'text_fields',
      size: 4,
      fields: ['body', 'room_title', 'thread_title', 'room_title_exact', 'thread_title_exact', 'exclude_room_title', 'exclude_thread_title'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'body', label: 'Post Contains', type: 'text', canExclude: true },
            { value: 'room_title', label: 'Room Title', type: 'text', canExclude: true },
            { value: 'room_title_exact', label: 'Exact Room Title Match', type: 'toggle' },
            { value: 'thread_title', label: 'Thread Title', type: 'text', canExclude: true },
            { value: 'thread_title_exact', label: 'Exact Thread Title Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['forums'],
      key: 'username',
      label: 'Username',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['forums'],
      key: 'forums',
      label: 'Forums',
      icon: 'forum',
      value: 'forums',
      size: 6,
      fields: ['exclude_sites', 'sites', 'site_tags'],
      dialog: (
        <ForumsFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={store.get('filters')}
          sites={store.getIn(['sites', 'forums'])}
          properties={store.getIn(['properties', 'forums'])}
          toggle={() => setDialog()} />) },
    { type: ['marketplaces'],
      key: 'author',
      label: 'Username',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'author', label: 'Username', type: 'text', canExclude: true }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['marketplaces'],
      key: 'markets',
      value: 'sites',
      label: 'Markets',
      icon: 'shopping_cart',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'marketplaces'])}
          toggle={() => setDialog()} />) },
    { type: ['marketplaces'],
      key: 'title',
      label: 'detail',
      icon: 'text_fields',
      size: 4,
      fields: ['title', 'description', 'source_uri'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'title', label: 'Item Title', type: 'text' },
            { value: 'description', label: 'Item Description', type: 'text' },
            { value: 'source_uri', label: 'URI', type: 'text' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['blogs'],
      key: 'blogs',
      label: 'Blogs',
      icon: 'web',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'blogs'])}
          properties={store.getIn(['properties', 'blogs'])}
          toggle={() => setDialog()} />) },
    { type: ['blogs'],
      key: 'details',
      label: 'Details',
      icon: 'web_asset',
      size: 4,
      fields: ['type', 'title', 'raw_href', 'author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'type', label: 'Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'post', label: 'Post' }, { value: 'comment', label: 'Comment' }]) },
            { value: 'title', label: 'Title', type: 'text' },
            { value: 'raw_href', label: 'URL', type: 'text' },
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['ransomware'],
      key: 'sites',
      label: 'Sites',
      icon: 'web',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'ransomware'])}
          properties={store.getIn(['properties', 'ransomware'])}
          toggle={() => setDialog()} />) },
    { type: ['ransomware'],
      key: 'details',
      label: 'Details',
      icon: 'web_asset',
      size: 4,
      fields: ['type', 'title', 'raw_href', 'author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'type', label: 'Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'post', label: 'Post' }, { value: 'comment', label: 'Comment' }]) },
            { value: 'title', label: 'Title', type: 'text' },
            { value: 'raw_href', label: 'URL', type: 'text' },
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['ransomware'],
      key: 'message',
      label: 'Message & Media',
      icon: 'message',
      size: 4,
      fields: ['message', 'language', 'media_caption', 'media_v2', 'media_type', 'mime_type', 'filename', 'sha1', 'exclude_sha1', 'phash'],
      values: { sha1: ['sha1', 'exclude_sha1'] },
      dialog: (
        <div className={style['media-filter']}>
          <TextFilter
            user={user}
            type={type}
            advanced={advanced}
            onApply={onApply}
            onFilter={onFilter}
            fields={[
              { value: 'message', label: 'Post/Comment Contains', type: 'text' },
              { value: 'language', label: 'Language', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Object.keys(Iso).filter(k => !['-', ''].includes(k)).map(k => ({ label: Iso[String(k)].name, value: Iso[String(k)].name }))]) },
              { value: 'media_v2', label: 'Includes Media?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
              { value: 'media_type', label: 'Media Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Autocomplete.media_type.filter(v => v === 'Image').map(v => ({ label: v, value: v }))]) },
              { value: 'mime_type', label: 'Mime Type', type: 'autocomplete', opts: fromJS(Object.keys(MediaTypes).filter(k => /image\//ig.test(k))) },
              { value: 'filename', label: 'Filename', type: 'text' },
              { value: 'sha1', label: 'SHA1', type: 'text', canExclude: true },
              { value: 'phash', label: 'pHash', type: 'phash' },
            ]}
            defaults={defaults}
            filters={filters}
            toggle={() => setDialog()} />
        </div>) },
    { type: ['pastes'],
      key: 'pastes',
      label: 'Sites',
      icon: 'assignment',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'pastes'])}
          toggle={() => setDialog()} />) },
    { type: ['pastes'],
      key: 'details',
      label: 'Details',
      icon: 'assignment_ind',
      size: 4,
      fields: ['native_id', 'title', 'syntax', 'author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'native_id', label: 'Paste ID', type: 'text' },
            { value: 'title', label: 'Title', type: 'text' },
            { value: 'syntax', label: 'Syntax', type: 'text' },
            { value: 'author', label: 'Author', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['cards'],
      key: 'shops',
      label: 'Shops',
      icon: 'shopping_cart',
      value: 'shops',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'cards'])}
          toggle={() => setDialog()} />) },
    { type: ['cards'],
      key: 'card',
      label: 'Card Details',
      icon: 'credit_card',
      size: 4,
      fields: ['bin', 'payment_method', 'service_code', 'card_type', 'card_category', 'last4', 'tr1', 'expiration', 'track_information', 'is_verified_by_visa'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'bin', label: 'BIN #', type: 'chips', placeholder: 'eg. 2345xx, 560xxx-560xyy Press enter to add' },
            { value: 'payment_method', label: 'Payment Method', type: 'text' },
            { value: 'service_code', label: 'Service Code', type: 'text' },
            { value: 'card_type', label: 'Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'DISCOVER', label: 'Discover' }, { value: 'VISA', label: 'Visa' }, { value: 'MASTERCARD', label: 'Mastercard' }, { value: 'AMERICAN EXPRESS,AMEX', label: 'American Express' }]) },
            { value: 'card_category', label: 'Category', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'dump', label: 'Card Present (dumps)' }, { value: 'cvv', label: 'Card Not Present (cvv)' }]) },
            { value: 'last4', label: 'Last 4 Available?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
            { value: 'tr1', label: 'Track 1 Available?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
            { value: 'expiration', label: 'Has Expiration?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
            { value: 'is_verified_by_visa', label: 'Visa Verification?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
          ].filter(v => (user.get('prm').some(p => /csb/.test(p)) ? true : v.value !== 'bin'))}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['cards'],
      key: 'base',
      label: 'Release',
      icon: 'new_releases',
      size: 4,
      fields: ['release_name', 'release_name_exact'],
      dialog: (
        <TextFilter
          user={user}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'release_name', label: 'Release Name', type: 'text' },
            { value: 'release_name_exact', label: 'Exact Release Name Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['cards'],
      key: 'geo',
      label: 'Geolocation',
      icon: 'language',
      size: 4,
      fields: ['zip_code', 'country'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'zip_code', label: 'Zip Code', type: 'text' },
            {
              value: 'country',
              label: 'Country',
              type: 'autocomplete',
              opts: fromJS(Object
                .entries(Autocomplete.country_codes)
                .map(([, v]) => ({ label: v, value: v }))),
            }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['boards'],
      key: 'boards',
      label: 'Sites',
      value: 'boards',
      icon: 'assignment',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'boards'])}
          toggle={() => setDialog()} />) },
    { type: ['boards'],
      key: 'details',
      label: 'Board Details',
      icon: 'web_asset',
      size: 4,
      fields: ['author', 'board', 'thread_id', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'board', label: 'Board', type: 'text', canExclude: true },
            { value: 'thread_id', label: 'Thread ID', type: 'text' },
            { value: 'author', label: 'Author', type: 'text' },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['boards'],
        key: 'message',
        label: 'Message & Media',
        icon: 'message',
        size: 4,
        fields: ['message', 'language', 'media_caption', 'media_v2', 'media_type', 'mime_type', 'filename', 'sha1', 'exclude_sha1', 'phash'],
        values: { sha1: ['sha1', 'exclude_sha1'] },
        dialog: (
          <div className={style['media-filter']}>
            <TextFilter
              user={user}
              type={type}
              advanced={advanced}
              onApply={onApply}
              onFilter={onFilter}
              fields={[
                { value: 'message', label: 'Message Contains', type: 'text' },
                { value: 'language', label: 'Language', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Object.keys(Iso).filter(k => !['-', ''].includes(k)).map(k => ({ label: Iso[String(k)].name, value: Iso[String(k)].name }))]) },
                { value: 'media_v2', label: 'Includes Media?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
                { value: 'media_type', label: 'Media Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Autocomplete.media_type.filter(v => v === 'Image').map(v => ({ label: v, value: v }))]) },
                { value: 'mime_type', label: 'Mime Type', type: 'autocomplete', opts: fromJS(Object.keys(MediaTypes).filter(k => /image\//ig.test(k))) },
                { value: 'filename', label: 'Filename', type: 'text' },
                { value: 'sha1', label: 'SHA1', type: 'text', canExclude: true },
                { value: 'phash', label: 'pHash', type: 'phash' },
              ]}
              defaults={defaults}
              filters={filters}
              toggle={() => setDialog()} />
          </div>) },
    { type: ['chats', 'images', 'videos', 'media'],
      key: 'sites',
      label: 'Type',
      value: 'chats',
      icon: 'assignment',
      size: 6,
      fields: ['sites', 'exclude_sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', type])}
          toggle={() => setDialog()} />) },
    { type: ['chats', 'images', 'videos', 'media'],
      key: 'author',
      label: 'User',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_id', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_id', label: 'Author ID', type: 'text' },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['chats'],
      key: 'server',
      label: 'Server',
      icon: 'storage',
      size: 4,
      fields: ['server', 'server_id'],
      enabled: [{ filter: 'sites', value: ['discord', 'rocketchat'] }],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'server', label: 'Server Name', type: 'text' },
            { value: 'server_id', label: 'Server ID', type: 'text' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['chats'],
      key: 'channel',
      label: 'Channel',
      icon: 'line_style',
      size: 4,
      fields: ['channel', 'channel_id', 'channel_exact', 'exclude_channel'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'channel', label: 'Channel Name', type: 'text', canExclude: true },
            { value: 'channel_id', label: 'Channel ID', type: 'text' },
            { value: 'channel_exact', label: 'Exact Channel Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['chats'],
      key: 'message',
      label: 'Message & Media',
      icon: 'message',
      size: 4,
      fields: ['message', 'language', 'media_caption', 'media', 'media_type', 'mime_type', 'filename', 'sha1', 'exclude_sha1', 'phash'],
      values: { sha1: ['sha1', 'exclude_sha1'] },
      dialog: (
        <div className={style['media-filter']}>
          <div className={style.buttons}>
            {user.getIn(['prefs', 'safe_search_toggle']) !== 'NONE' &&
            <Button
              fullWidth
              variant="outlined"
              className={style.image}
              onClick={() => {
                const { pathname, query } = History.getCurrentLocation();
                History.push({
                  pathname,
                  query: {
                    ...query,
                    ...{ media_type: 'Image', media: 'true', search: 'image' },
                  },
                });
                setDialog();
              }}
              endIcon={<Icon className={style.icon}>image</Icon>}>
              Image Search
            </Button>}
            {user.getIn(['prefs', 'safe_search_toggle']) !== 'NONE' &&
            <Button
              fullWidth
              variant="outlined"
              className={style.video}
              onClick={() => {
                const { pathname, query } = History.getCurrentLocation();
                History.push({
                  pathname,
                  query: {
                    ...query,
                    ...{ media_type: 'Video', media: 'true', search: 'video' },
                  },
                });
                setDialog();
              }}
              endIcon={<Icon className={style.icon}>video_library</Icon>}>
              Video Search
            </Button>}
          </div>
          <TextFilter
            user={user}
            type={type}
            advanced={advanced}
            onApply={onApply}
            onFilter={onFilter}
            fields={[
              { value: 'message', label: 'Message Contains', type: 'text' },
              { value: 'language', label: 'Language', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Object.keys(Iso).filter(k => !['-', ''].includes(k)).map(k => ({ label: Iso[String(k)].name, value: Iso[String(k)].name }))]) },
              { value: 'media', label: 'Includes Media?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
              { value: 'media_type', label: 'Media Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Autocomplete.media_type.map(v => ({ label: v, value: v }))]) },
              { value: 'mime_type', label: 'Mime Type', type: 'autocomplete', opts: fromJS(Object.keys(MediaTypes)) },
              { value: 'filename', label: 'Filename', type: 'text' },
              { value: 'sha1', label: 'SHA1', type: 'text', canExclude: true },
              { value: 'phash', label: 'pHash', type: 'phash' },
            ]}
            defaults={defaults}
            filters={filters}
            toggle={() => setDialog()} />
        </div>) },
    { type: ['cves'],
      key: 'cve',
      label: 'CVEs',
      icon: 'bug_report',
      size: 4,
      fields: ['cves', 'cve_vulnerability_types', 'cve_mentions', 'cve_reporter', 'required_fields'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cves', label: 'CVEs', type: 'chips' },
            { value: 'cve_vulnerability_types', label: 'Vulnerability Type', type: 'text' },
            { value: 'cve_reporter', label: 'Source', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'mitre', label: 'MITRE' }, { value: 'nist', label: 'NIST' }]) },
            {
              label: 'Required Data',
              value: 'required_fields',
              type: 'checkboxes',
              opts: [
                'nist.configurations::CPE',
              ],
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['cves'],
      key: 'products',
      label: 'Products',
      icon: 'bug_report',
      size: 4,
      fields: ['cve_product_names', 'cve_vendor_names', 'required_cve_tags'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cve_vendor_names', label: 'Vendor Name (s)', type: 'chips', placeholder: 'eg. Microsoft, Mozilla, Press enter to add' },
            { value: 'cve_product_names', label: 'Product Name (s)', type: 'chips', placeholder: 'eg. Office, Redhat, Press enter to add' },
            {
              flex: true,
              inverted: true,
              label: 'Required Data',
              value: 'required_cve_tags',
              type: 'checkboxes',
              opts: [
                'nist.products::products',
              ],
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['cves'],
      key: 'status',
      label: 'Status',
      icon: 'show_chart',
      size: 4,
      fields: ['cve_severity', 'cve_base_score_2', 'cve_base_score_3'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cve_severity', label: 'Severity', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'critical', label: 'Critical' }, { value: 'high', label: 'High' }, { value: 'medium', label: 'Medium' }, { value: 'low', label: 'Low' }]) },
            { value: 'cve_base_score_2', label: 'CVSS 2.0 Base Score', type: 'range' },
            { value: 'cve_base_score_3', label: 'CVSS 3.0 Base Score', type: 'range' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['exploits'],
      key: 'cves',
      label: 'CVEs',
      icon: 'bug_report',
      size: 4,
      fields: ['cves', 'cve_vulnerability_types', 'cve_mentions', 'cve_reporter', 'source_uri', 'required_fields'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cves', label: 'CVEs', type: 'chips' },
            { value: 'cve_vulnerability_types', label: 'Vulnerability Type', type: 'text' },
            { value: 'cve_reporter', label: 'Source', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'mitre', label: 'MITRE' }, { value: 'nist', label: 'NIST' }]) },
            {
              label: 'Required Data',
              value: 'required_fields',
              type: 'checkboxes',
              opts: [
                'nist.configurations::CPE',
              ],
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['exploits'],
      key: 'products',
      label: 'Products',
      icon: 'bug_report',
      size: 4,
      fields: ['cve_product_names', 'cve_vendor_names', 'required_cve_tags'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cve_vendor_names', label: 'Vendor Name (s)', type: 'chips', placeholder: 'eg. Microsoft, Mozilla, Press enter to add' },
            { value: 'cve_product_names', label: 'Product Name (s)', type: 'chips', placeholder: 'eg. Office, Redhat, Press enter to add' },
            {
              flex: true,
              inverted: true,
              label: 'Required Data',
              value: 'required_cve_tags',
              type: 'checkboxes',
              opts: [
                'nist.products::products',
              ],
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['exploits'],
      key: 'status',
      label: 'Status',
      icon: 'show_chart',
      size: 4,
      fields: ['cve_severity', 'cve_base_score_2', 'cve_base_score_3'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'cve_severity', label: 'Severity', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'critical', label: 'Critical' }, { value: 'high', label: 'High' }, { value: 'medium', label: 'Medium' }, { value: 'low', label: 'Low' }]) },
            { value: 'cve_base_score_2', label: 'CVSS 2.0 Base Score', type: 'range' },
            { value: 'cve_base_score_3', label: 'CVSS 3.0 Base Score', type: 'range' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['iocs'],
      key: 'ioc',
      label: 'Details',
      icon: 'event_note',
      size: 4,
      fields: ['analysis_event', 'has_report', 'has_config', 'event_type'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'event_type', label: 'Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All Events' }, { value: 'event:analysis::Analysis Events', label: 'Only Analysis Events' }, { value: 'event:observation::Observation Events', label: 'Only Observation Events' }]) },
            { value: 'has_report', label: 'Has Intel Report?', type: 'toggle' },
            { value: 'has_config', label: 'Contains Extracted Configuration Files', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['iocs'],
      key: 'indicators',
      label: 'Filters',
      icon: 'local_offer',
      size: 4,
      fields: ['ioc_category', 'ioc_type', 'ioc_malware', 'ioc_tool', 'ioc_actor'],
      dialog: (
        <TextFilter
          user={user}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={['category', 'type', 'malware', 'tool', 'actor'].map(field => (
            {
              label: field,
              placeholder: `Select ${field}`,
              value: `ioc_${field}`,
              type: 'multiselect',
              opts: fromJS([...new Set(store
                .getIn(['terms', field], list())
                .sortBy(v => v.get('key').trim())
                .map(v => v.get('key')))]),
              }))}
          defaults={defaults}
          filters={filters}
          onMount={() => SearchActions.loadTerms()}
          toggle={() => setDialog()} />) },
    { type: ['iocs'],
      key: 'tags',
      label: 'Tags',
      icon: 'local_offer',
      size: 4,
      noPermission: true,
      fields: [
        'uuid',
        'ioc_tags',
        'exclude_ioc_tags',
        'required_ioc_tags',
        'attack_ids',
      ] },
    { type: ['accounts'],
      key: 'account_shops',
      label: 'Source',
      value: 'accounts',
      icon: 'account_balance',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'accounts'])}
          toggle={() => setDialog()} />) },
    { type: ['accounts'],
      key: 'seller',
      label: 'Seller',
      icon: 'supervisor_account',
      size: 4,
      fields: ['seller', 'exclude_seller'],
      values: { seller: ['seller', 'exclude_seller'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'seller', label: 'Seller', type: 'text', canExclude: true },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['accounts'],
      key: 'account_details',
      label: 'Account Details',
      icon: 'account_box',
      size: 4,
      fields: ['account_organization', 'email_domain'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'account_organization', label: 'Account Site', type: 'text' },
              { value: 'email_domain', label: 'Email Domain', type: 'text' },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['social'],
      key: 'sites',
      label: 'Source',
      value: 'social',
      icon: 'assignment',
      size: 6,
      fields: ['exclude_sites', 'sites'],
      values: { sites: ['sites', 'sites_exact'], exclude_sites: ['exclude_sites', 'sites_exact'] },
      dialog: (
        <SitesFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          filtered
          option="sites"
          filters={filters}
          sites={store.getIn(['sites', 'social'])}
          toggle={() => setDialog()} />) },
    { type: ['social'],
      key: 'username',
      label: 'Username',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'author', label: 'Username', type: 'text', canExclude: true },
              { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['social'],
      key: 'details',
      label: 'Details',
      icon: 'web_asset',
      size: 4,
      fields: ['type', 'subreddit', 'page', 'page_exact', 'exclude_page'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onFilter={onFilter}
          onApply={onApply}
          fields={[
              { value: 'type', label: 'Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'group', label: 'Group' }, { value: 'profile', label: 'Profile' }]) },
              { value: 'subreddit', label: 'Subreddit', type: 'text' },
              { value: 'page', label: 'Title', type: 'text', canExclude: true },
              { value: 'page_exact', label: 'Exact Page Match', type: 'toggle' }]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['social'],
        key: 'message',
        label: 'Message & Media',
        icon: 'message',
        size: 4,
        fields: ['message', 'language', 'media_caption', 'media_v2', 'media_type', 'mime_type', 'filename', 'sha1', 'exclude_sha1', 'phash'],
        values: { sha1: ['sha1', 'exclude_sha1'] },
        dialog: (
          <div className={style['media-filter']}>
            <TextFilter
              user={user}
              type={type}
              advanced={advanced}
              onApply={onApply}
              onFilter={onFilter}
              fields={[
                { value: 'message', label: 'Message Contains', type: 'text' },
                { value: 'language', label: 'Language', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Object.keys(Iso).filter(k => !['-', ''].includes(k)).map(k => ({ label: Iso[String(k)].name, value: Iso[String(k)].name }))]) },
                { value: 'media_v2', label: 'Includes Media?', type: 'checkboxes', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
                { value: 'media_type', label: 'Media Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Autocomplete.media_type.filter(v => v === 'Image').map(v => ({ label: v, value: v }))]) },
                { value: 'mime_type', label: 'Mime Type', type: 'autocomplete', opts: fromJS(Object.keys(MediaTypes).filter(k => /image\//ig.test(k))) },
                { value: 'filename', label: 'Filename', type: 'text' },
                { value: 'sha1', label: 'SHA1', type: 'text', canExclude: true },
                { value: 'phash', label: 'pHash', type: 'phash' },
              ]}
              defaults={defaults}
              filters={filters}
              toggle={() => setDialog()} />
          </div>) },
    { type: ['credentials'],
      key: 'credential',
      label: 'Credential',
      icon: 'email',
      size: 4,
      fields: ['domain', 'affected_domain', 'email', 'password', 'username', 'hash_types', 'is_fresh', 'meets_org_profile_password_complexity', 'password_reset_policy'],
      blur: user.getIn(['prefs', 'blur_credentials']),
      blurFields: ['domain', 'email', 'password'],
      values: { meets_org_profile_password_complexity: ['meets_org_profile_password_complexity', 'is_fresh', 'length', 'required', 'excluded', 'optional', 'num_optional', 'ignore_hashes', 'alert_date_type'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            {
              value: 'domain',
              label: 'Domains',
              type: 'multiselect',
              nullable: 'All Domains',
              info: 'Only domains that have compromised credentials in Flashpoint’s collections will show in the dropdown. To see a list of all domains being monitored, please reach out to your Customer Success representative.',
              opts: store.getIn(['charts', type, 'aggregations', 'domain', 'buckets'])
                  ? fromJS([
                    ...store
                      .getIn(['charts', type, 'aggregations', 'domain', 'buckets'], list())
                      .sortBy(v => v.get('key'))
                      .map(v => ({
                        value: v.get('key'),
                        label: v.get('key'),
                      })),
                    ])
                  : list(),
            },
            {
              value: 'affected_domain',
              label: 'Affected Domain',
              type: 'multiselect',
              nullable: 'All Affected Domains',
              info: 'Only domains that have compromised credentials in Flashpoint’s collections will show in the dropdown. To see a list of all affected domains being monitored, please reach out to your Customer Success representative.',
              opts: store.getIn(['charts', type, 'aggregations', 'affected_domain', 'buckets'])
                  ? fromJS([
                    ...store
                      .getIn(['charts', type, 'aggregations', 'affected_domain', 'buckets'], list())
                      .sortBy(v => v.get('key'))
                      .map(v => ({
                        value: v.get('key'),
                        label: v.get('key'),
                      })),
                    ])
                  : list(),
            },
            { value: 'email', label: 'Email', type: 'text' },
            { value: 'username', label: 'Username', type: 'text' },
            { value: 'password', label: 'Password', type: 'text' },
            { value: 'hash_types',
              label: 'Hash Types',
              type: 'multiselect',
              placeholder: 'Hash Types',
              opts: fromJS(['Plaintext', ...knownHashes].map(v => ({ label: v, value: v }))) },
            { value: 'is_fresh', label: 'First Time Seen', type: 'toggle' },
            { value: 'meets_org_profile_password_complexity', label: 'Meets Org Profile Password Complexity', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          blur={user.getIn(['prefs', 'blur_credentials'])}
          blurFields={['domain', 'email', 'password']}
          blurFunc={Text.BlurOrg}
          toggle={() => setDialog()} />) },
    { type: ['credentials'],
      key: 'edm_profile',
      label: 'Organization',
      icon: 'book',
      size: 4,
      blur: user.getIn(['prefs', 'blur_credentials']),
      blurFields: ['customer_id'],
      noPermission: !user.get('prm').some(p => /dat.edm.w/.test(p)),
      fields: ['customer_id'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            {
              value: 'customer_id',
              label: 'Organization',
              sort: true,
              type: user.getIn(['prefs', 'blur_credentials'])
                ? 'dropdown'
                : 'autocomplete',
              default: user.get('sid'),
              opts: (user.get('activeOrganizations').isEmpty()
                ? user.get('sfids')
                : user.get('activeOrganizations'))
                  .sortBy(v => v.get('name'))
                  .map(v => fromJS({
                    value: v.get('salesforce_id'),
                    label: v.get('name'),
                  })),
            },
          ]}
          defaults={defaults}
          filters={filters}
          blur={user.getIn(['prefs', 'blur_credentials'])}
          blurFields={['customer_id']}
          blurFunc={Text.BlurOrg}
          toggle={() => setDialog()} />) },
    { type: ['credentials'],
      key: 'breach',
      label: 'Breach',
      icon: 'account_balance',
      size: 4,
      fields: ['title', 'source_type', 'title_exact'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'title', label: 'Breach Title', type: 'text' },
              { value: 'source_type', label: 'Breach Source', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'Analyst Research', label: 'Analyst Research' }, { value: 'Credential Stealer', label: 'Credential Stealer' }, { value: 'VirusTotal', label: 'VirusTotal' }, { value: 'Paste', label: 'Paste' }]) },
              { value: 'title_exact', label: 'Exact Breach Title Match', type: 'toggle' },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['credentials', 'customer-credentials'],
      key: 'breached_at',
      label: 'Date of Breach',
      icon: 'date_range',
      size: 2,
      fields: ['breached_at'],
      values: { breached_at: ['breached_at', 'breached_since', 'breached_until'] },
      dialog: advanced ? (
        <React.Fragment>
          <Button
            color="primary"
            id="breached_at"
            name="advanced.breached_at"
            className={cx([style.h4, 'h4', style.open, style.dropdown])}
            onClick={e => setDialog(({ target: e.currentTarget, key: 'breached_at' }))}
            endIcon={<Icon className={cx([style.icon])}>keyboard_arrow_down</Icon>}>
            {filters.get('breached_at') || 'All Time'}
          </Button>
          <Popover
            anchorEl={dialog && dialog.target}
            open={!!(dialog && dialog.key === 'date')}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClose={() => setDialog()}>
            <DateFilter
              user={user}
              type={type}
              date={filters.get('breached_at')}
              since={filters.get('breached_since')}
              until={filters.get('breached_until')}
              dates={store.get('dates')}
              toggle={() => setDialog()}
              onApply={onApply}
              onFilter={onFilter} />
          </Popover>
        </React.Fragment>)
        : (
          <DateFilter
            user={user}
            type={type}
            date={filters.get('breached_at')}
            dateKey="breached_at"
            since={filters.get('breached_since')}
            sinceKey="breached_since"
            until={filters.get('breached_until')}
            untilKey="breached_until"
            dates={store.get('dates')}
            onApply={onApply}
            toggle={() => setDialog()}
            />) },
    user.get('prm').some(p => /dat.edm.hd/ig.test(p)) &&
    { type: ['credentials'],
      key: 'host_data',
      label: 'Host Data',
      icon: 'ballot',
      fields: ['host_data'],
      dialog: (
        <TextFilter
          user={user}
          advanced={advanced}
          fields={[
            { label: 'Host Attributes', value: 'host_attributes', hint: 'host attr hint' },
            { label: 'Cookies', value: 'cookies', hint: 'cookies hint' },
          ]
            .concat(['host_data'].map(field => (
              {
                flex: true,
                inverted: true,
                label: 'Contains',
                value: 'host_data',
                type: 'checkboxes',
                style: { width: '100%' },
                opts: {
                  host_data: [
                    'host_attributes',
                    'cookies',
                  ],
                }[String(field)],
              }
            )))
            }
          defaults={defaults}
          filters={filters}
          onApply={onApply}
          onFilter={onFilter}
          onMount={() => SearchActions.loadTerms()}
          toggle={() => setDialog()} />
      ) },
    { type: ['customer-credentials'],
      key: 'credential',
      label: 'Credential',
      icon: 'email',
      size: 4,
      fields: ['domain', 'affected_domain', 'email', 'password', 'username', 'hash_types', 'is_fresh', 'meets_org_profile_password_complexity'],
      blur: user.getIn(['prefs', 'blur_credentials']),
      blurFields: ['domain', 'email', 'password'],
      values: { meets_org_profile_password_complexity: ['meets_org_profile_password_complexity', 'password_reset_policy', 'is_fresh', 'length', 'required', 'excluded', 'optional', 'num_optional', 'ignore_hashes', 'alert_date_type'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'affected_domain', label: 'Affected Domain', type: 'text' },
            { value: 'username', label: 'Username', type: 'text' },
            { value: 'password', label: 'Password', type: 'text' },
          ]}
          defaults={defaults}
          filters={filters}
          blur={user.getIn(['prefs', 'blur_credentials'])}
          blurFields={['domain', 'email', 'password']}
          blurFunc={Text.BlurOrg}
          toggle={() => setDialog()} />) },
    { type: ['customer-credentials'],
          key: 'breach',
          label: 'Breach',
          icon: 'account_balance',
          size: 4,
          fields: ['title', 'source', 'title_exact', 'fpid', 'context'],
          dialog: (
            <TextFilter
              user={user}
              type={type}
              advanced={advanced}
              onApply={onApply}
              onFilter={onFilter}
              fields={[
                  { value: 'title', label: 'Breach Title', type: 'text' },
                  { value: 'fpid', label: 'Breach FPID', type: 'text' },
                  { value: 'context', label: 'Breach Context', type: 'text' },
                  { value: 'source', label: 'Breach Source', type: 'text' },
                  // once source_type is available in response
                  // { value: 'source', label: 'Breach Source', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'Analyst Research', label: 'Analyst Research' }, { value: 'Credential Stealer', label: 'Credential Stealer' }, { value: 'VirusTotal', label: 'VirusTotal' }, { value: 'Paste', label: 'Paste' }]) },
                  { value: 'title_exact', label: 'Exact Breach Title Match', type: 'toggle' },
                ]}
              defaults={defaults}
              filters={filters}
              toggle={() => setDialog()} />) },
    { type: ['media', 'images', 'videos'],
      key: 'details',
      label: 'Details',
      icon: 'line_style',
      size: 4,
      fields: ['channel', 'channel_id', 'channel_exact'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'channel', label: 'Name/Title', type: 'text', canExclude: true },
            { value: 'channel_id', label: 'ID/URL', type: 'text' },
            { value: 'channel_exact', label: 'Exact Channel Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['media', 'images', 'videos'],
      key: 'extractions',
      label: 'Extractions',
      icon: 'line_style',
      size: 4,
      fields: ['classifications', 'exclude_classifications', 'text', 'exclude_text', 'logos', 'exclude_logos'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'classifications', label: 'Classifications', type: 'text', canExclude: true },
            { value: 'text', label: 'Text', type: 'text', canExclude: true },
            { value: 'logos', label: 'Logos', type: 'text', canExclude: true },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['media', 'images', 'videos'],
      key: 'safe_search',
      label: 'Safe Search',
      icon: 'warning',
      size: 4,
      fields: ['safe_search'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'safe_search', label: 'Level', type: 'dropdown', hint: 'Default value can be changed via settings page', opts: fromJS([{ value: SafeSearchTypes.NONE, label: 'None' }, { value: SafeSearchTypes.MODERATE, label: 'Moderate' }, { value: SafeSearchTypes.STRICT, label: 'Strict' }]) },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['twitter'],
      key: 'username',
      label: 'Username',
      icon: 'account_circle',
      size: 4,
      fields: ['author', 'author_exact', 'exclude_author'],
      values: { author: ['author', 'author_exact', 'exclude_author'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'author', label: 'Username', type: 'text', canExclude: true },
            { value: 'author_exact', label: 'Exact Username Match', type: 'toggle' },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['twitter'],
      key: 'category_id',
      label: 'Category',
      icon: 'category',
      size: 4,
      fields: ['category_id'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            { value: 'category_id', label: 'Use Case', type: 'dropdown', opts: fromJS([
              { value: '', label: 'All' },
              ...alertingKeywordClasses
                ?.toJS()
                ?.filter(v => /twitter/ig.test(v?.name))
                ?.map(v => ({ value: v?.id, label: Text.Sentence(v?.name)?.replace(/twitter/ig, '') })),
            ]) },
          ]}
          defaults={defaults}
          filters={filters}
          onMount={() => Query
            .KeywordClasses()
            .then(classes => setAlertingKeywordClasses(fromJS(classes)))}
          toggle={() => setDialog()} />) },
    { type: ['twitter'],
      key: 'customer_id',
      label: 'Organization',
      icon: 'book',
      size: 4,
      noPermission: !user.get('prm').some(p => /dat.twtr.adm/ig.test(p)),
      fields: ['customer_id'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
            {
              value: 'customer_id',
              label: 'Organization',
              sort: true,
              type: 'autocomplete',
              default: user.get('sid'),
              opts: (user.get('activeOrganizations').isEmpty()
                ? user.get('sfids')
                : user.get('activeOrganizations'))
                  .sortBy(v => v.get('name'))
                  .map(v => fromJS({
                    value: v.get('salesforce_id'),
                    label: v.get('name'),
                  })),
            },
          ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['tagging.channels'],
      key: 'channel_name',
      label: 'Channel',
      icon: 'chat',
      size: 4,
      fields: ['channel_name', 'channel_id', 'channel_name_exact'],
      values: { channel_name: ['channel_name', 'channel_name_exact'] },
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'channel_name', label: 'Channel Name', type: 'text' },
              { value: 'channel_id', label: 'Channel ID', type: 'text' },
              { value: 'channel_name_exact', label: 'Exact Channel Name Match', type: 'toggle' },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['tagging.channels'],
      key: 'analyst_assigned',
      label: 'Source Added By',
      icon: 'account_circle',
      size: 4,
      fields: ['is_analyst', 'analyst_unassigned', 'analyst_assigned'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'is_analyst', label: 'Source Added by Me', type: 'toggle' },
              { value: 'analyst_unassigned', label: 'Source Added by Unknown Analyst', type: 'toggle' },
              { value: 'analyst_assigned', label: 'Source Added by (username)', type: 'chips' },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['tagging.channels'],
      key: 'tags',
      label: 'Tags',
      icon: 'label',
      size: 4,
      fields: ['tags'],
      dialog: (
        <TagsFilter
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          metaData={store}
          prm={user.get('prm')}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['tagging.channels'],
      key: 'tagged_by',
      label: 'Tagged By',
      icon: 'account_circle',
      size: 4,
      fields: ['tagged_by'],
      dialog: (
        <TextFilter
          user={user}
          type={type}
          advanced={advanced}
          onApply={onApply}
          onFilter={onFilter}
          fields={[
              { value: 'tagged_by', label: 'Source Tagged by (username)', type: 'text' },
            ]}
          defaults={defaults}
          filters={filters}
          toggle={() => setDialog()} />) },
    { type: ['tagging.channels'],
      key: 'tagged_at',
      label: 'Date Tagged',
      icon: 'date_range',
      size: 2,
      fields: ['tagged_at'],
      values: { tagged_at: ['tagged_at', 'tagged_since', 'tagged_until'] },
      dialog: (
        <DateFilter
          user={user}
          type={type}
          date={filters.get('tagged_at')}
          dateKey="tagged_at"
          since={filters.get('tagged_since')}
          sinceKey="tagged_since"
          until={filters.get('tagged_until')}
          untilKey="tagged_until"
          dates={store.get('dates')}
          onApply={onApply}
          toggle={() => setDialog()}
          />) },
    { type: ['all', 'accounts', 'boards', 'blogs', 'cards', 'chats', 'communities', 'forums', 'marketplaces', 'media', 'images', 'videos', 'pastes', 'ransomware', 'social', 'twitter'],
      key: 'enrichments',
      label: 'Enrichments',
      icon: 'attachment',
      size: 4,
      fields: ['bins', 'cves', 'emails', 'ips', 'urls', 'domains', 'monero-wallets', 'ethereum-wallets', 'bitcoin-wallets', 'handles', 'profiles', 'email_exact', 'required_enrichments'],
      values: {
        bins: ['bins', 'transformed'],
        cves: ['cves', 'transformed'],
        emails: ['emails', 'email_exact', 'transformed'],
        ips: ['ips', 'transformed'],
        urls: ['urls', 'transformed'],
        domains: ['domains', 'transformed'],
        'monero-wallets': ['monero-wallets', 'transformed'],
        'ethereum-wallets': ['ethereum-wallets', 'transformed'],
        'bitcoin-wallets': ['bitcoin-wallets', 'transformed'],
        handles: ['handles', 'transformed'],
        profiles: ['profiles', 'transformed'],
      },
      format: v => v,
      dialog: (
        <TextFilter
          user={user}
          advanced={advanced}
          help="Note: Highlighting is currently disabled on enrichment searches"
          fields={[
              { label: 'Card Bins', value: 'bins', hint: 'This will look for matches on full credit card numbers (12-19 digt card numbers in a variety of formats). To search, enter the full 6 digits of the bin like: 100074. Wildcards (asterisks) will not work in this field.' },
              { label: 'CVEs', value: 'cves', hint: 'To search enter a full CVE including the letters CVE & the dashes (e.g. CVE-2020-1206). Wildcards (asterisks) will not work in this field.' },
              { label: 'IP Addresses', value: 'ips', hint: 'This will accept either complete IP addresses or CIDR ranges, asterisks & TO-FROM ranges will not work in this field. To search enter something like: 1.1.1.1 or 1.0.0.0/8.' },
              { label: 'Email Addresses', value: 'emails', hint: 'This will look for email addresses & can be searched using a full email, just the domain, or just the username. To search enter something like: user@example.com or user or example.com.' },
              { label: 'Domains', value: 'domains', hint: 'Domains' },
              { label: 'Monero Wallets', value: 'monero-wallets', hint: 'This will look for monero wallets, to search, paste in a full wallet address. Wildcards (asterisks) will not work in this field.' },
              { label: 'Ethereum Wallets', value: 'ethereum-wallets', hint: 'This will look for Ethereum wallets, to search, paste in a full wallet address. Wildcards (asterisks) will not work in this field.' },
              { label: 'Bitcoin Wallets', value: 'bitcoin-wallets', hint: 'This will look for Bitcoin wallets, to search, paste in a full wallet address. Wildcards (asterisks) will not work in this field.' },
              { label: 'Social Media Handles', value: 'handles', hint: 'This will look for what appear to be social media handles (typically @ followed by some letters, or part of a social media url, etc). To search enter the name of the handle you’re looking for. The @ is optional. This can be combined with the social media site search by selecting a site and then putting in the handle you’re looking for. So if you’re looking for elonmusk on twitter, enter @elonmusk here and then select twitter from social media sites. Wildcards (asterisks) will not work in this field.' },
              ...(user.get('prm').some(p => /org.fp.r/ig.test(p))
                ? []
                : []
              ),
            ]
            .filter(field => (user.get('prm').some(p => /(vln|cve).r/.test(p)) ? true : field.value !== 'cves'))
            .filter(field => (user.get('prm').some(p => /csb/.test(p)) ? true : field.value !== 'bins'))
            .map(field => ({
              ...field,
              value: field.value,
              label: Text.Sentence(field.label || field.value),
              type: 'chips',
            }))
            .concat([{
              value: 'profiles',
              label: 'Social Media Sites',
              type: 'multiselect',
              hint: 'This will look for full social media profile users like https://twitter.com/username or https://twitter.com/username/status/9992. To search select the site you want to search across. This can be combined with the social media handles search by selecting a site and then putting in the handle you’re looking for. So if you’re looking for elonmusk on twitter, select twitter here, and enter elonmusk in social media handles.',
              opts: fromJS([
                'Telegram',
                'Twitter',
                'Gab',
                'Facebook',
                'Instagram',
                'VK',
                'YouTube',
                'Pinterest',
                'Reddit',
                'TikTok',
                'Linkedin',
                'Snapchat',
                'Jabber',
                'ICQ',
              ]
              .sort((a, b) => a.localeCompare(b))
              .map(v => ({ label: v, value: v }))),
            }])
            .concat([{
              value: 'email_exact',
              label: '',
              type: 'checkbox',
              opts: fromJS([{ value: 'true', label: 'Exact Email Match' }]),
            }])
            .concat({
                value: 'enrichments_cond',
                type: 'toggle',
                label: v => `Conditional logic: ${v ? 'AND' : 'OR'}`,
            })
            .concat(['required_enrichments'].map(field => (
              {
                flex: true,
                inverted: true,
                label: 'Contains Enrichments',
                value: 'required_enrichments',
                type: 'checkboxes',
                style: { width: '100%' },
                opts: {
                  required_enrichments: [
                    'card-numbers.card-numbers.bin::card numbers',
                    'v1.vulnerability.cve.vulnerability::cves',
                    'v1.ip_addresses.ip_address::ip addresses',
                    'v1.email_addresses.email_address::email addresses',
                    'v1.urls.domain::domains',
                    'v1.monero_addresses.monero_address::monero wallets',
                    'v1.ethereum_addresses.ethereum_address::ethereum wallets',
                    'v1.bitcoin_addresses.bitcoin_address::bitcoin wallets',
                    'v1.social_media.handle::social media handles',
                    'v1.social_media.site::social media sites',
                    ...(user.get('prm').some(p => /org.fp.r/ig.test(p))
                      ? []
                      : []),
                  ]
                  .filter(v => v)
                  .filter(v => (user.get('prm').some(p => /(vln|cve).r/.test(p)) ? true : v !== 'v1.vulnerability.cve.vulnerability::cves'))
                  .filter(v => (user.get('prm').some(p => /csb/.test(p)) ? true : v !== 'card-numbers.card-numbers.bin::card numbers')),
                }[String(field)],
              }
            )))
            }
          defaults={defaults}
          filters={filters}
          onApply={onApply}
          onFilter={onFilter}
          onMount={() => SearchActions.loadTerms()}
          toggle={() => setDialog()} />
      ),
    },
  ];

  const setToolTipMsg = (option) => {
    if (option.key === 'forums' && (store.getIn(['sites', 'forums']) || list()).isEmpty()) {
      return 'Loading...';
    }

    if (option.key === 'server' && !option.enabled.every(
      v => v.value.map(k => k.toLowerCase()).includes(filters.get(v.filter, '').toLowerCase()))) {
      return 'To enable, select Discord or RocketChat as a chat type';
    }

    return null;
  };

  const shouldEnableBtn = (option) => {
    if (option.key === 'forums' && (store.getIn(['sites', 'forums']) || list()).isEmpty()) {
      return false;
    }
    return option.enabled && !option.enabled.every(
      v => v.value.map(k => k.toLowerCase()).includes(filters.get(v.filter, '').toLowerCase()));
  };

  const onClear = () => {
    if (onApply) {
      if (filters.size > 0) {
        setDirty(true);
      }
      // Since we want onClear to overwrite existing values,
      // create a filters object with all falsy vals, then update
      setFilters(filters.map(v => (typeof (v) === 'string' ? '' : null)));
      return;
    }
    // Fetch current state of text input
    const currentText = HtmlUtils.getFindText().replace(/[ ]{2,}/, ' ').trim();

    const { pathname, query: { owner_id }, hash } = History.getCurrentLocation();
    History.push({
      pathname: pathname.includes('all') ? '/home/search/communities' : pathname,
      query: { currentText, owner_id, skip: undefined },
      hash,
    });
  };

  const onDelete = (option, field) => {
    // Fetch current state of text input
    const currentText = HtmlUtils.getFindText().replace(/[ ]{2,}/, ' ').trim();

    const update = option.values && option.values[String(field)]
      ? option.values[String(field)]
        .reduce((acc, cur) => ({ ...acc, [cur]: defaults.get(cur) }), {})
      : ({ [field]: defaults.get(field) });
    const combined = { ...filters.toJS(), ...update, query: currentText, skip: undefined };
    // If using the chat image search, need to enforce media: true and media_type: image/*
    if (filters.has('search') &&
      ((!combined.media || combined.media === 'false')
      || (!combined.media_type || !['Image', 'Video'].includes(combined.media_type)))) {
      combined.search = undefined;
      combined.media = undefined;
      combined.media_type = undefined;
    }

    // if url link from alerting email contains meets_org_profile_password_complexity and user deletes filter,
    // or, if a user selects a different organization from the dropdown,
    // remove associated org profile filters
    if (type === 'credentials' && (field === 'customer_id' || filters.has('meets_org_profile_password_complexity'))) {
      combined.meets_org_profile_password_complexity = undefined;
      combined.ignore_hashes = undefined;
      combined.length = undefined;
      combined.lowercase = undefined;
      combined.number = undefined;
      combined.num_optional = undefined;
      combined.optional = undefined;
      combined.required = undefined;
      combined.symbol = undefined;
      combined.uppercase = undefined;
    }

    if (onApply) {
      onApply(fromJS(combined));
      return;
    }

    const { pathname, hash } = History.getCurrentLocation();
    History.push({
      pathname: field === 'all' ? '/home/search/communities' : pathname,
      query: combined,
      hash,
    });
  };

  const refreshSearch = () => {
    const { pathname, hash } = History.getCurrentLocation();

    const event = {
      type,
      filters: { ...filters.toJS() },
      origin: '.base .form input#filters',
    };

    Track(event, 'Search');

    setDirty(false);

    // Replace duplicated spaces with a single space
    const currentText = HtmlUtils.getFindText().replace(/[ ]{2,}/, ' ').trim();

    const newQuery = fromJS({ ...filters.toJS(), query: currentText, skip: undefined }).filter(f => (f !== '' && f != null)).toJS();

    History.push({
      pathname,
      query: newQuery,
      hash,
    });
  };

  useEffect(() => {
    // If type changed (meaning we switched pages),
    // or query changed (meaning search was reloaded from header),
    // set dirty to false
    setDirty(false);
  }, [type, (filters.get('query') || '')]);

  useEffect(() => {
    // for ccm-e only, if a user comes from a looker table link and has
    // meets_org_profile_password_complexity as a query param
    // get users EDM profile and add related password complexity filters to query
    if (!filters.size || type !== 'credentials') return;
    const { pathname, hash, query } = History.getCurrentLocation();
    if (query.meets_org_profile_password_complexity === 'true') {
      const updatedValues = CCM.GetOrgPasswordComplexity(filters, user);
      const newQuery = { ...query, ...updatedValues.toJS() };
      History.push({
        pathname,
        query: newQuery,
        hash,
      });
    }
  }, [filters]);

  if (History.getCurrentLocation().pathname.includes('customer-credentials/credentials-search')) return <div />;

  return (
    <Grid fluid className={style.filters}>
      <Row>
        <Col xs={12} className={cx([advanced ? style.advanced : style.items])}>
          {!advanced && <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Filters:</div>}
          <Row>
            {options
              .filter(v => v && (!v.type || v.type.includes(selectedSection) || (!type && v.type.includes(store.get('type')))))
              .filter(v => !advanced || (!v.hiddenOnAdvanced && advanced))
              .filter(v => !v.noPermission)
              .map(option => (!advanced
                  ? (
                    <span
                      key={option.key}
                      className={cx([
                        style.option,
                        option.fields.some(f => filters.has(f))
                          && !(dialog && dialog.key) && style.active,
                        !!(dialog && dialog.key === option.key) && style.active,
                      ])}
                      data-for="btn.loading"
                      data-tip={setToolTipMsg(option)}
                      data-tip-disable={false}>
                      <Button
                        disableElevation
                        color="primary"
                        id={option.key}
                        disabled={shouldEnableBtn(option)}
                        name={`filter.${option.key}`}
                        className={cx([style.h4, 'h4', style.mont, 'mont'])}
                        onClick={e => setDialog({ target: e.currentTarget, key: option.key })}
                        endIcon={<Icon className={style.icon}>keyboard_arrow_down</Icon>}
                        startIcon={<Icon className={style.icon}>{option.icon}</Icon>}
                        data-testid={`filters.${option?.key}`}>
                        {option.label}
                      </Button>
                      <Popover
                        style={{ overflowY: 'none' }}
                        anchorEl={dialog && dialog.target}
                        open={!!(dialog && dialog.key === option.key)}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onClose={() => (!option.open || !option.open()) && setDialog()}>
                        {option.dialog}
                      </Popover>
                    </span>
                  )
                  : (
                    <Col
                      xs={option.size}
                      key={option.key}
                      name={`advanced.filter.${option.label}`}
                      className={cx([style.option, advanced && style.advanced])}>
                      {option.label &&
                      <div className={cx([style['advanced-label']])}>
                        <Icon>{option.icon}</Icon>
                        {option.label}:
                      </div>}
                      <Paper key={option.key} className={style.container}>
                        {option.dialog}
                      </Paper>
                    </Col>
                  )))}
            {onApply &&
            <Icon
              data-for="global.tooltip"
              data-tip="Refresh search"
              onClick={refreshSearch}
              data-testid="filters.refresh"
              className={cx([
                style.refresh,
                dirty ? style.dirty : ''])}>
              refresh
            </Icon>}
          </Row>
        </Col>
      </Row>
      {!advanced &&
      <Row>
        <Col xs={12} className={style.chips}>
          {options
            .filter(v => v && (!v.type || v.type.includes(selectedSection) || (!type && v.type.includes(store.get('type')))))
            .map(option => (
              <Chips
                key={option.key}
                option={option}
                filters={filters}
                defaults={defaults}
                onToggle={v => setDialog(v)}
                onDelete={v => onDelete(option, v)} />))}
          {type === 'alerting' &&
          <SaveSearchIcon
            alerting
            filters={filters}
            saved={searches}
            type={type} />}
          {!empty &&
          <Button
            className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
            onClick={() => onClear()}
            data-testid="filters.clear-all">
            Clear All
          </Button>}
        </Col>
      </Row>}
      <ReactTooltip id="btn.loading" html place="bottom" effect="solid" />
    </Grid>
  );
};

Filters.propTypes = {
  advanced: PropTypes.bool,
  defaults: PropTypes.object,
  filters: PropTypes.object,
  onFilter: PropTypes.func,
  setFilters: PropTypes.func,
  searches: PropTypes.object,
  store: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.object,
};

Filters.defaultProps = {
  advanced: false,
  defaults: map(),
  filters: map(),
  onFilter: null,
  setFilters: null,
  searches: map(),
  store: map(),
  type: '',
  user: map(),
};

export default Filters;
