import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  LinearProgress,
} from '@mui/material';

const ProgressBar = ({
  initialTimeout,
  remaining,
}) => {
  const [completed, setCompleted] = useState(0);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [initialCount, setInitialCount] = useState(null);

  const slowingTimeout = (left, completedSoFar) => {
    const maxBarComplete = 100 / (left || 1);
    if (remaining === 0 || completedSoFar >= 100) {
      setCompleted(0);
      return;
    }
    if (remaining > 0 && completedSoFar < 100) {
      const time = Math.max(initialTimeout,
        initialTimeout / (1 - (completedSoFar / maxBarComplete)));
      const timer = setTimeout(() => {
        setCompleted(completedSoFar + 1);
        slowingTimeout(left, completedSoFar + 1);
      }, time);
      setCurrentTimeout(timer);
    }
  };

  useEffect(() => {
    if (remaining === 0) setInitialCount(null);
    if (initialCount == null && remaining !== 0) setInitialCount(remaining);
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }
    const minComplete = (1 - ((remaining + 0.75) / initialCount)) * 100;
    let nextComplete = Math.max(minComplete, completed + 1);
    if (initialCount == null) nextComplete = completed + 1;
    setCompleted(nextComplete);
    slowingTimeout(remaining, nextComplete);
  }, [remaining]);

  return (
    <LinearProgress
      variant="determinate"
      color="secondary"
      value={completed}
      style={{ ...remaining === 0 && { display: 'none' } }} />
  );
};

ProgressBar.propTypes = {
  initialTimeout: PropTypes.number,
  remaining: PropTypes.number,
};

ProgressBar.defaultProps = {
  initialTimeout: 100,
  remaining: 0,
};

export default ProgressBar;
