import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Map as map } from 'immutable';
import CreateIcon from '@mui/icons-material/Create';
import { AvatarGroup,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import SearchActions from '../../../../actions/searchActions';

import style from './fraudwatch.module.scss';
import Prompt from '../../../utils/Prompt';
import Messages from '../../../../constants/Messages';
import Query from './query';
import Brand from './Brand';
import ClientSetup from './ClientSetup';

const Fraudwatch = ({
  profile,
  brandLimits,
  onRefreshBrandLimits,
  user,
  disabled,
}) => {
  const [sfid, setSfid] = useState();
  const [assets, setAssets] = useState();
  const [brand, setBrand] = useState();
  const [limit, setLimit] = useState();
  const [allLimits, setAllLimits] = useState();
  const [data, setData] = useState();
  const [dialog, setDialog] = useState();
  const [limitExceeded, setLimitExceeded] = useState(true);

  const loadBrandLimits = () => {
    if (onRefreshBrandLimits) {
      onRefreshBrandLimits();
    }
  };

  const onLoadBrands = () => {
    Query.Brands(profile.get('salesforce_id'))
      .then(res => setData(res));
  };
  const onCancel = () => {
    setAssets();
    setBrand();
  };

  const onCreate = () => {
    if (limitExceeded) return;
    setBrand({
      salesforce_id: profile.get('salesforce_id'),
      type: 'domain',
    });
  };

  const onPovCreate = () => {
    setDialog({ key: 'pov' });
  };

  const onPovCancel = () => {
    setDialog();
  };

  const onDelete = (_brand) => {
    Query.BrandDelete(_brand?.id)
      .then(() => setData(current => current.filter(v => v.id !== _brand?.id)))
      .then(() => setDialog());
  };

  const onSave = async (_brand, _assets) => {
    Query.Brand(_brand, _assets)
      .then(res => data.concat(res))
      .then(res => setData(res))
      .then(() => onCancel())
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
      .finally(() => loadBrandLimits())
      .catch((err) => {
        SearchActions.set(['search', 'info', 'message'], Messages.FraudwatchApiError);
        throw err;
      });
  };

  const onPovSave = async (brands) => {
    const requests = brands.map(b => Query.BrandPov(b));
    Promise.all(requests)
      .then(() => setDialog())
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
      .then(() => onCancel())
      .finally(() => {
        loadBrandLimits();
        onLoadBrands();
      })
      .catch((err) => {
        SearchActions.set(['search', 'info', 'message'], Messages.FraudwatchApiError);
        throw err;
      });
  };

  const onUpdate = (_brand, _assets) => {
    Query.BrandUpdate(_brand, _assets)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
      .then(() => onCancel())
      .finally(() => {
        loadBrandLimits();
        onLoadBrands();
      })
      .catch((err) => {
        SearchActions.set(['search', 'info', 'message'], Messages.FraudwatchApiError);
        throw err;
      });
  };

  useEffect(() => {
    if (sfid) return;
    if (!profile.get('salesforce_id')) return;
    setSfid(profile.get('salesforce_id'));
    onLoadBrands();
  }, [profile]);

  useEffect(() => {
    setAllLimits(brandLimits);
    const {
      domain_monitoring_count,
      domain_monitoring_limit,
      mobile_app_monitoring_count,
      mobile_app_monitoring_limit,
      social_media_brand_count,
      social_media_brand_limit,
      social_media_executive_count,
      social_media_executive_limit,
    } = brandLimits;
    const totalCount = domain_monitoring_count + mobile_app_monitoring_count
      + social_media_brand_count + social_media_executive_count;
    const totalLimit = domain_monitoring_limit + mobile_app_monitoring_limit
      + social_media_brand_limit + social_media_executive_limit;
    setLimit(totalLimit);
    setLimitExceeded(totalCount >= totalLimit);
  }, [brandLimits]);

  const onClick = (item, state = 'view') => {
    Query.Assets(item.id).then((files) => {
      const _brand = {
        ...item,
        attachedFiles: files,
        state,
      };
      setBrand(_brand);
    });
  };

  return (
    <div className={style.fraudwatch}>
      {!data && <div><CircularProgress /></div>}
      {data &&
      <List className={style.list}>
        {uniqBy(data, 'id')
          ?.sort((a, b) => a?.name.localeCompare(b?.name))
          ?.map(v => (
            <ListItem key={v?.id} onClick={() => onClick(v)} disabled={disabled}>
              <ListItemAvatar className={style.avatar}>
                <AvatarGroup max={2}>
                  {!v?.assets &&
                  <Avatar>
                    <Icon>image</Icon>
                  </Avatar>}
                  {uniqBy(v?.assets, 'id')
                    .map(asset => (
                      <Avatar
                        key={`${v?.id} - ${asset?.name}`}
                        alt={asset?.name}
                        src={`data:image/png;base64,${asset?.data}`}>
                        <Icon>image</Icon>
                      </Avatar>))}
                </AvatarGroup>
              </ListItemAvatar>
              <ListItemText
                className={style.label}
                primary={v?.name}
                secondary={v?.allowed_domains?.split(',')[0]} />
              <ListItemText
                className={style.label}
                primary={v?.type ? v.type : 'Unknown Type'} />
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onClick(v, 'edit');
                }}
                size="large">
                <CreateIcon style={{ color: grey[500] }} fontSize="small" />
              </IconButton>
              <Icon
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setDialog({ key: 'delete', value: v });
                }}>
                close
              </Icon>
            </ListItem>))}
      </List>}
      <FormControl fullWidth={false}>
        <Button
          className={cx([style.button, (disabled || limitExceeded) && style.disabled])}
          data-for="global.tooltip"
          data-tip={limitExceeded ? `Max count of ${limit} brands reached` : ''}
          disabled={disabled || limitExceeded}
          variant="contained"
          color="secondary"
          onClick={() => onCreate()}
          data-testid="fraudwatch.complete-profile">
          {limitExceeded ? 'Domain Limit Exceeded' : 'Complete Profile Setup'}
        </Button>
      </FormControl>
      {user.get('prm').some(p => /dat.dm.pov.w/.test(p)) &&
        <FormControl fullWidth={false}>
          <Button
            disabled={disabled}
            className={cx([style.button, (disabled || limitExceeded) && style.disabled])}
            variant="contained"
            color="secondary"
            style={{ marginLeft: '1rem' }}
            onClick={() => onPovCreate()}>
            Add a POV Client
          </Button>
        </FormControl>}
      <Dialog
        maxWidth="sm"
        open={Boolean(brand)}
        onClose={() => setBrand()}>
        <DialogTitle>
          Brand Details
        </DialogTitle>
        <DialogContent>
          <Brand
            assets={assets}
            brand={brand}
            limits={allLimits}
            save={onSave}
            update={onUpdate}
            cancel={onCancel} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={Boolean(dialog?.key === 'pov')}
        >
        <DialogTitle>
          POV Client Setup
        </DialogTitle>
        <DialogContent style={{ paddingLeft: '0', paddingRight: '0' }}>
          <ClientSetup
            onCancel={onPovCancel}
            onSubmit={onPovSave}
            />
        </DialogContent>
      </Dialog>
      <Prompt
        open={Boolean(dialog?.key === 'delete')}
        title="Warning: Delete Brand?"
        acceptText="Continue"
        accept={() => onDelete(dialog.value)}
        cancelText="Cancel"
        cancel={() => setDialog()}>
        You are about to permanently delete this brand. Are you sure?
      </Prompt>
    </div>
  );
};

Fraudwatch.propTypes = {
  profile: PropTypes.object,
  brandLimits: PropTypes.object,
  onRefreshBrandLimits: PropTypes.func,
  user: PropTypes.object,
  disabled: PropTypes.bool,
};

Fraudwatch.defaultProps = {
  profile: map(),
  brandLimits: {},
  onRefreshBrandLimits: null,
  user: null,
  disabled: false,
};

export default Fraudwatch;
