import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './pastes.module.scss';
import Text from '../../../utils/text';
import Invalid from '../../utils/Invalid/Invalid';
import InternalLink from '../../utils/InternalLink';
import Prompt from '../../utils/Prompt';
import { takedownBackend, takedownBackends, takedownSite } from '../../../stores/recoil/takedowns';
import Query from './query';

const Pastes = ({
  data,
}) => {
  const [dialog, setDialog] = useState();
  const [removed, setRemoved] = useState();
  const setTakedownSite = useSetRecoilState(takedownSite);
  const [takedownBackendsValue, setTakedownBackends] = useRecoilState(takedownBackends);
  const takedownBackendValue = useRecoilValue(takedownBackend);

  const onSearch = (k, v) => {
    const pathname = '/home/search/pastes';
    const query = { query: '', [k]: v };
    return ({ pathname, query });
  };

  const onTakedown = () => {
    Query.takedownRequest(
      takedownBackendValue.get('id'),
      data.get('native_id'),
      data.get('fpid'),
    )
      .then(() => setRemoved(false))
      .then(() => setDialog());
  };

  useEffect(() => {
    if (data.isEmpty()) return;
    if (data.getIn(['site', 'title'], '') === 'pastebin.com' && data.has('native_id')) {
      if (takedownBackendsValue.isEmpty()) {
        Query.takedownBackend().then(res => setTakedownBackends(res));
      }
      Query.takedownConfirm(data.get('native_id'))
        .then(res => setRemoved(res))
        .then(() => setTakedownSite(data.getIn(['site', 'title'])));
    }
  }, [data]);

  return (
    <Grid
      fluid
      name="component.pastes"
      className={cx([style.base, style.pastes])}>
      {/* loading paste */}
      {data.isEmpty() &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {/* paste loaded */}
      {!data.isEmpty() && data.has('id') &&
      <Row>
        <Col xs={12} className={style.header}>
          <div>
            <InternalLink
              to={((data.getIn(['site', 'title']))
                ? onSearch('sites', data.getIn(['site', 'title']))
                : '')}
              name="pastes.site.title"
              className={cx([data.getIn(['site', 'title']) && style.a, 'a'])}>
              {Text.Highlight(
                data.getIn(['highlight', 'site.title', 0]) ||
                  data.getIn(['site', 'title']))}
            </InternalLink>
          </div>
          <div className={style.title}>
            <Icon className={style.icon}>web
            </Icon>
            <div
              className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
              name="pastes.header.title">
              {Text.Highlight(
                Text.StripHtml(
                Text.Sentence(
                  data.getIn(['highlight', 'title', 0]) ||
                  data.get('title'))))}
            </div>
          </div>
          <div>
            <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Date:</div>
            <div
              className={cx([style.h4, 'h4', style.cap, 'cap'])}
              name="pastes.sort_date">
              {`${moment
                .utc(data.getIn(['sort_date']))
                .format('MMM DD, YYYY HH:mm')} UTC`}
            </div>
            <Icon
              name="pastes.header.offline"
              data-for="offline.tooltip"
              data-tip={`The data on this page reflects information from<br />
              when Flashpoint last successfully observed it`}
              className={style.help}>
              help
            </Icon>
          </div>
          {data.getIn(['site_actor', 'names', 'handle']) &&
          <div>
            <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Author:</div>
            <InternalLink
              to={((data.getIn(['site_actor', 'names', 'handle']))
                ? onSearch('author', data.getIn(['site_actor', 'names', 'handle']))
                : '')}
              name="pastes.site_actor.names.handle"
              className={cx([data.getIn(['site_actor', 'names', 'handle']) && style.a, 'a'])}>
              {Text.Highlight(
                data.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                  data.getIn(['site_actor', 'names', 'handle']) || '-')}
            </InternalLink>
          </div>}
          {data.getIn(['raw_href']) &&
          <div>
            <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Source:</div>
            <div>{data.getIn(['raw_href']) || '-'}</div>
          </div>}
          {data.getIn(['native_id']) &&
          <div>
            <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Native ID:</div>
            <InternalLink
              to={((data.getIn(['native_id']))
                ? onSearch('native_id', data.getIn(['native_id']))
                : '')}
              name="pastes.native_id"
              className={cx([data.getIn(['native_id']) && style.a, 'a'])}>
              {Text.Highlight(
                data.getIn(['highlight', 'native_id', 0]) ||
                  data.getIn(['native_id']) || '-')}
            </InternalLink>
          </div>}
          {data.getIn(['site', 'title']) === 'pastebin.com' && data.has('native_id') && removed === null &&
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setDialog({ target: 'remove' })}>
            Remove from Pastebin
          </Button>}
        </Col>
        {data.getIn(['site', 'title']) === 'pastebin.com' && data.has('native_id') && removed !== null &&
        <Col xs={12} className={style.removed}>
          <i>{`[Flashpoint has ${removed === true ? 'removed' : 'requested the removal of'} this paste from ${takedownBackendValue.get('name', 'the domain')}]`}</i>
        </Col>}
        <Col xs={12}>
          <Paper className={style.card} dir="auto">
            <div name="pastes.body.html/sanitized">
              {Text.Highlight(
                Text.Market(
                  Text.SanitizeLinks(
                    data.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                    data.getIn(['body', 'text/html+sanitized']) ||
                    data.getIn(['highlight', 'body.text/plain', 0]) ||
                    data.getIn(['body', 'text/plain']))))}
            </div>
          </Paper>
        </Col>
      </Row>}
      <Prompt
        open={Boolean(dialog?.target === 'remove')}
        title="Warning: Delete Entry?"
        acceptText="Continue"
        accept={() => onTakedown()}
        cancelText="Cancel"
        cancel={() => setDialog()}>
        You are about to permanently delete this entry from the site. Are you sure?
      </Prompt>
      {/* invalid id/paste not found */}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Post not found" />}
      <ReactTooltip id="offline.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="paste.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Pastes.propTypes = {
  data: PropTypes.object,
};

Pastes.defaultProps = {
  data: map(),
};

export default Pastes;
