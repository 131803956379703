
import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

const useIsEqualMemoize = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const easyUseEffect = (callback, dependencies) => (
  useEffect(
    callback,
    dependencies.map(useIsEqualMemoize),
  )
);

export default easyUseEffect;
