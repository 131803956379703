import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  green,
} from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import {
  Icon,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Switch,
} from '@mui/material';

import style from './listfilter.module.scss';
import UserActions from '../../actions/userActions';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';
import Messages from '../../constants/Messages';
import { Track } from '../../utils/track';

const useStyles = makeStyles({
  listfilter: {
    '& .MuiListItem-root': {
      '& .MuiListItemIcon-root': {
        '& .add': {
          color: green[500],
        },
        opacity: 0,
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          opacity: 1,
        },
      },
    },
  },
});

const ListFilter = ({
  advanced,
  custom,
  filters,
  onApply,
  onFilter,
  option,
  options,
  required,
  selectedTags,
  toggle,
  type,
}) => {
  const classes = useStyles();
  const [cond, setCond] = useState();
  const [filter, setFilter] = useState('');
  const [available, setAvailable] = useState(list());
  const [selected, setSelected] = useState(list());

  const flatten = () => options
    .reduce((r, v) => r.concat(v.get('children')), list())
    .filterNot(v => ['Glossary'].includes(v.get('name')));

  const onClear = () => {
    setAvailable(flatten());
    setSelected(list());
  };

  const onSearch = () => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const all = selected.size === flatten().size;
    const values = selected.map(v => v.get('id')).join(',');
    setFilter('');
    toggle();
    SearchActions.set(['search', 'info', 'message'], null);
    const topics = selected.toJS().map(topic => topic.name);

    if (onApply) {
      onApply(fromJS({
        [option]: all ? '' : values,
        tags_cond: cond,
      }));
      return;
    }

    const event = {
      type,
      filters: { query: query.query, topics },
      origin: '.selected .list',
    };

    Track(event, 'Search');

    History.push({
      pathname,
      query: { ...query, [option]: all ? '' : values, skip: undefined },
      hash,
    });
  };

  const onStore = () => {
    if (required && selected.isEmpty()) {
      SearchActions.set(['search', 'info', 'message'], Messages.ListOptionError);
    } else {
      const update = { [option]: selected.toJS(), tags_cond: cond };
      UserActions.savePrefs(update);
      SearchActions.set(['search', 'info', 'message'], Messages.PreferencesSaved);
      setFilter('');
      toggle();
    }
  };

  const onAll = (onFilter
    ? () => {
      onFilter(map({
        tags: selected.concat(
          available
            .filter(v => (v.get('id').toLowerCase() || '').includes(filter.toLowerCase())))
          .map(v => v.get('id'))
          .join(','),
      }));
      const filtered = available
        .filter(v => (v.get('id').toLowerCase() || '')
          .includes(filter.toLowerCase()));
      const values = available
        .filterNot(v => (v.get('id').toLowerCase() || '').includes(filter.toLowerCase()));
      setAvailable(values);
      setSelected(selected.concat(filtered));
    }
    : () => {
      const filtered = available
        .filter(v => (v.get('id').toLowerCase() || '')
          .includes(filter.toLowerCase()));
      const values = available
        .filterNot(v => (v.get('id').toLowerCase() || '').includes(filter.toLowerCase()));
      setAvailable(values);
      setSelected(selected.concat(filtered));
    }
  );

  const onRemove = (onFilter
    ? (value) => {
      onFilter(map({
        tags: selected
          .filterNot(v => value.get('id') === v.get('id'))
          .map(v => v.get('id'))
          .join(','),
      }));
      setAvailable(available.push(value));
      setSelected(selected.filterNot(v => value.get('id') === v.get('id')));
    }
    : (value) => {
      setAvailable(available.push(value));
      setSelected(selected.filterNot(v => value.get('id') === v.get('id')));
    }
  );

  const onSelect = (onFilter
    ? (value) => {
      onFilter(map({
        tags: selected
          .push(value)
          .map(v => v.get('id'))
          .join(','),
      }));
      setAvailable(available.filterNot(v => value.get('id') === v.get('id')));
      setSelected(selected.push(value));
    }
    : (value) => {
      setAvailable(available.filterNot(v => value.get('id') === v.get('id')));
      setSelected(selected.push(value));
    }
  );

  useEffect(() => {
    const availableFilters = !filters.get(option)
      ? list()
      : flatten().filterNot(v => (filters.get(option) || '').includes(v));
    const selectedFilters = !filters.get(option)
      ? flatten().filterNot(v => (filters.get(option) || '').includes(v))
      : flatten().filter(v => (filters.get(option) || '').includes(v));
    const querySelected = availableFilters.filter(v => selectedTags && selectedTags.includes(v.get('id')));
    setAvailable(availableFilters);
    setSelected(selectedFilters.concat(querySelected));
    setCond(filters?.get('tags_cond'));
  }, [filters]);

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.listfilter,
        classes.listfilter,
      ])}
      name="component.listfilter">
      <Row>
        <Col xs={6} className={style.unselected}>
          <div className={cx([style.h2, 'h2', style.mont, 'mont', style.primary])}>
            {`Available: (${available.size})`}
            <Button
              color="secondary"
              variant="contained"
              name="listfilter.selectall"
              onClick={() => onAll()}>
              Select All
            </Button>
          </div>
          <form
            className={style.form}
            name="listfilter.search">
            <FormControl variant="outlined">
              <InputLabel>Filter Available</InputLabel>
              <OutlinedInput
                data-lpignore="true"
                value={filter}
                placeholder="Filter Available..."
                onChange={event => setFilter(event.target.value)}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon
                      onClick={() => setFilter(filter)}>
                      search
                    </Icon>
                  </InputAdornment>)}
                endAdornment={filter && (
                  <InputAdornment position="end">
                    <Icon
                      color="primary"
                      onClick={() => setFilter()}>
                      close
                    </Icon>
                  </InputAdornment>
                )} />
            </FormControl>
          </form>
          <div className={style.list} name="listfilter.list.available">
            {available
              .filter(v => (v.get('id').toLowerCase() || '').includes(filter.toLowerCase()))
              .sortBy(v => v.get('name'))
              .map(v => (
                <ListItem
                  key={v.get('id')}
                  name={`listfilter.list.available.${v.get('name')}`}
                  onClick={() => onSelect(v)}>
                  <ListItemText>
                    {v.get('name')}
                  </ListItemText>
                  <ListItemIcon>
                    <Icon className="add">add_circle</Icon>
                  </ListItemIcon>
                </ListItem>))}
          </div>
        </Col>
        <Col xs={6} className={style.selected}>
          <div className={cx([style.h2, 'h2', style.mont, 'mont', style.primary])}>
            {`Selected (${selected.size}):`}
            <Button
              color="secondary"
              variant="contained"
              name="listfilter.removeall"
              onClick={() => onClear()}>
              Remove All
            </Button>
          </div>
          <div className={style.list} name="listfilter.list.selected">
            {selected
              .sortBy(v => v.get('name'))
              .map(v => (
                <ListItem
                  key={v.get('id')}
                  name={`listfilter.list.selected.${v.get('name')}`}
                  onClick={() => onRemove(v)}>
                  <ListItemText>
                    {v.get('name')}
                  </ListItemText>
                  <ListItemIcon>
                    <Icon color="error">remove_circle</Icon>
                  </ListItemIcon>
                </ListItem>))}
          </div>
        </Col>
      </Row>
      {toggle &&
      <Row>
        <Col xs={12} className={advanced ? style.advanced : style.footer}>
          <div className={style.error}>
            {selected?.length === 0 ? Messages.ListOptionError : ''}
          </div>
          <div className={style.actions}>
            <FormControl name="blur">
              <FormControlLabel
                label={`Conditional Logic: ${cond ? 'AND' : 'OR'}`}
                control={
                  <Switch
                    name="listfilter.toggle"
                    checked={Boolean(cond)}
                    onChange={(event, v) => setCond(v ? 'true' : '')}
                    inputProps={{ 'data-testid': 'listfilter.toggle' }}/>} />
            </FormControl>
            <Button
              name="listfilter.cancel"
              onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              name="listfilter.apply"
              className={style.active}
              onClick={() => (custom ? onStore() : onSearch())}>
              Apply
            </Button>
          </div>
        </Col>
      </Row>}
    </Grid>
  );
};

ListFilter.propTypes = {
  advanced: PropTypes.bool,
  custom: PropTypes.bool,
  filters: PropTypes.object,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  options: PropTypes.object,
  option: PropTypes.string,
  required: PropTypes.bool,
  selectedTags: PropTypes.string,
  toggle: PropTypes.func,
  type: PropTypes.string,
};

ListFilter.defaultProps = {
  advanced: false,
  custom: false,
  filters: map(),
  onApply: null,
  onFilter: null,
  options: list(),
  option: '',
  required: false,
  selectedTags: '',
  toggle: null,
  type: '',
};

export default ListFilter;
