/** Adds a tertiary palette color to our theme */
import { createTheme, DeprecatedThemeOptions, adaptV4Theme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

export const createFpTheme = (options: DeprecatedThemeOptions) =>
  createTheme(adaptV4Theme({ ...options }));
