import density from './density';
import palette from './palette';
import typography from './typography';
import props from './props';

const type = 'light';

const defaultTheme = {
  density,
  palette,
  typography,
  props,
  type,
};

export type DefaultThemeType = {
  density: object,
  palette: {
    background: {
      paper: object,
    }
  },
  typography: object,
  props: object,
  type: string,
};

export default defaultTheme;
