import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';


const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '10rem auto 0 auto',
    maxWidth: '766px',
    width: '100%',
  },
  body: {
    fontSize: '1.8rem',
    fontStyle: 'italic',
    marginBottom: '2.4rem',
    textAlign: 'center',
  },
  button: {
    padding: '.75rem 1.5rem',
    textTransform: 'uppercase',
  },
  icon: {
    color: red[500],
    fontSize: '3.3rem',
    marginBottom: '2.4rem',
  },
  title: {
    fontSize: '2.4rem',
    marginBottom: '2.4rem',
  },
}));

const PersonSearchError = ({ setError, message }) => {
  const classes = useStyles();

  const handleAcknowledgeError = (e) => {
    e.preventDefault();
    setError(false);
  };

  return (
    <Grid className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Typography className={classes.title} variant="h3">Error!</Typography>
      <Typography className={classes.body} variant="body1">{message}</Typography>
      <Button className={classes.button} onClick={e => handleAcknowledgeError(e)} variant="contained" color="secondary">Submit another request</Button>
    </Grid>
  );
};

PersonSearchError.propTypes = {
  message: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

PersonSearchError.defaultProps = {
  message: 'An unknown error occured.',
};

export default PersonSearchError;
