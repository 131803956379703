import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import { List as list, Map as map } from 'immutable';
import {
  Line as L,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import {
  Fab,
  Icon,
} from '@mui/material';

import style from './line.module.scss';
import Text from '../../../utils/text';
import Invalid from '../../utils/Invalid/Invalid';

const useStyles = makeStyles(theme => ({
  line: {
    '& .tooltip': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#fff',
      padding: '5px',
    },
  },
}));

const Line = ({
  aid,
  color,
  labels,
  legend,
  limit,
  values,
  results,
  selectedCordinates,
  styles,
  onClick,
}) => {
  const classes = useStyles();
  const format = () => 'MMM, DD YYYY';
  const [currentIndex, setCurrentIndex] = useState([]);
  const [data, setData] = useState();
  const [left, setLeft] = useState('');
  const [right, setRight] = useState('');
  const [top, setTop] = useState('auto');
  const isEmpty = results.isEmpty();


  useEffect(() => {
    if (results.isEmpty()) return;
    const first = map.isMap(results.first()) ? results.first().toJS() : results.first();
    const last = map.isMap(results.last()) ? results.last().toJS() : results.last();
    if (limit && limit > 0 && limit <= results.size) {
      const from = results.get(+`-${limit}`);
      setLeft(moment.utc(from[labels.getIn([0, 'key'])]).format(format()));
      setData(results.slice(+`-${limit}`));
      setCurrentIndex([results.size - limit, results.size]);

      // set yaxis domain to maintain scale across time ranges
      const maxCount = results.maxBy(v => v[values.getIn([0, 'key'])]);
      setTop(maxCount[values.getIn([0, 'key'])]);
    } else {
      setLeft(moment.utc(first[labels.getIn([0, 'key'])]).format(format()));
      setCurrentIndex([0, results.size]);
    }
    setRight(moment.utc(last[labels.getIn([0, 'key'])]).format(format()));
  }, [results, limit]);

  useEffect(() => {
    setData(results);
  }, [results]);

  useEffect(() => {
    const leftTimestamp = moment.utc(left).valueOf();
    const rightTimestamp = moment.utc(right).valueOf();
    const isDifferentRange = selectedCordinates
      && (selectedCordinates.x < leftTimestamp || selectedCordinates.x > rightTimestamp);
    if (isDifferentRange && limit && limit > 0 && limit <= results.size) {
      const dateIndex = results.findIndex(v => v[labels.getIn([0, 'key'])] === selectedCordinates.x);
      if (dateIndex !== -1) {
        const limitSection = Math.floor(limit / 2);
        let startIndex = (dateIndex - limitSection > 0) ? dateIndex - limitSection : 0;
        // slice is inclusive so endIndex is actually the size
        let endIndex = (dateIndex + limitSection <= results.size)
          ? dateIndex + limitSection
          : results.size;
        if (endIndex - startIndex < limit) {
          if (startIndex === 0) endIndex = limit;
          else if (endIndex === results.size) startIndex = results.size - limit;
        } else if (endIndex - startIndex > limit) {
          if (startIndex !== 0) startIndex += 1;
          else endIndex -= 1;
        }
        const from = results.get(startIndex);
        const to = results.get(endIndex - 1);
        setLeft(moment.utc(from[labels.getIn([0, 'key'])]).format(format()));
        setRight(moment.utc(to[labels.getIn([0, 'key'])]).format(format()));
        setData(results.slice(startIndex, endIndex));
        setCurrentIndex([startIndex, endIndex]);
      }
    }
  }, [selectedCordinates]);

  const changeRange = (direction) => {
    let startIndex = 0;
    // slice is inclusive so endIndex is actually the size
    let endIndex = 0;
    if (direction === 'back') {
      startIndex = (currentIndex[0] - limit >= 0) ? currentIndex[0] - limit : 0;
      endIndex = currentIndex[1] - limit;
      if (endIndex - startIndex < limit) endIndex = startIndex + limit;
    } else {
      startIndex = currentIndex[0] + limit;
      endIndex = (currentIndex[1] + limit <= results.size)
        ? currentIndex[1] + limit
        : results.size;
      if (endIndex - startIndex < limit) startIndex = endIndex - limit;
    }
    const from = results.get(startIndex);
    const to = results.get(endIndex - 1);
    setLeft(moment.utc(from[labels.getIn([0, 'key'])]).format(format()));
    setRight(moment.utc(to[labels.getIn([0, 'key'])]).format(format()));
    setData(results.slice(startIndex, endIndex));
    setCurrentIndex([startIndex, endIndex]);
  };

  return (
    <div
      className={cx([
      style['line-chart'],
      classes.line,
      ])}
      style={{ height: styles.height || 600, width: styles.width || '100%' }}>
      {isEmpty &&
      <Invalid
        icon="error_outline"
        title="No Results"
        subtitle="Try selecting a different record" />}
      {!isEmpty &&
      <ResponsiveContainer
        className={style.lines}>
        <LineChart
          margin={styles.margin || { left: -30, top: 35 }}
          className={style.chart}
          data={data ? data.toJS() : []}
          onClick={v => (v && onClick(results.get(v.activeTooltipIndex), results))}>
          <XAxis
            label={false}
            dataKey={labels.getIn([0, 'key'])}
            domain={[left, right]}
            height={110}
            angle={-65}
            textAnchor="end"
            tickFormatter={v => moment.utc(v).format(format())} />
          <YAxis
            label={false}
            domain={[0, top]}
            tickFormatter={v => Text.AbbreviateNumber(v)} />
          <Tooltip
            content={({ active, payload }) => active && payload && payload[0] && (
              <div className="tooltip">
                <div>{moment.utc(payload[0].payload[labels.getIn([0, 'key'])]).format(format())}</div>
                {payload
                    .filter(v => v.value && v.value !== 0)
                    .map(v => (
                      <div
                        key={v.dataKey}
                        style={{ color: v.stroke }}>
                        {`${v.dataKey}:${v.value}`}
                      </div>
                  ))}
                {payload?.[0]?.payload?.values?.map?.(v => (
                  <div
                    key={v.id}>
                    {`${v.name}:${v.value}`}
                  </div>
                  ))}
              </div>)}
            formatter={v => v.toLocaleString()}
            labelFormatter={v => moment.utc(v).format(format())} />
          {selectedCordinates && <ReferenceDot {...selectedCordinates} r={10} fill="#5c6ae0" />}
          {legend && <Legend formatter={v => Text.Sentence(v)} />}
          {values.map((a, k) => (
            <L
              fillOpacity={1}
              key={a.get('key')}
              dataKey={values.getIn([k, 'key'])}
              isAnimationActive={false}
              activeDot={{ r: 8 }}
              strokeWidth={2}
              stroke={Text.Color(k, aid, false, color)}
              fill={Text.Color(k, aid, false, color)} />))}
        </LineChart>
      </ResponsiveContainer>}
      {limit > 0 && currentIndex.length && !isEmpty &&
      <div className={style.range}>
        <Fab
          color="secondary"
          size="small"
          disabled={currentIndex[0] === 0}
          onClick={() => changeRange('back')}>
          <Icon>keyboard_arrow_left</Icon>
        </Fab>
        <Fab
          color="secondary"
          size="small"
          disabled={currentIndex[1] === results.size}
          onClick={() => changeRange('forward')}>
          <Icon>keyboard_arrow_right</Icon>
        </Fab>
      </div>}
    </div>
  );
};

Line.propTypes = {
  aid: PropTypes.string,
  color: PropTypes.string,
  labels: PropTypes.object,
  legend: PropTypes.bool,
  limit: PropTypes.number,
  values: PropTypes.object,
  results: PropTypes.object,
  selectedCordinates: PropTypes.object,
  styles: PropTypes.object,
  onClick: PropTypes.func,
};

Line.defaultProps = {
  aid: '',
  color: '',
  labels: list(),
  legend: true,
  limit: 0,
  values: list(),
  results: map(),
  selectedCordinates: null,
  styles: {},
  onClick: null,
};

export default Line;
