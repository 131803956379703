import React from 'react';

import { fromJS, List as list } from 'immutable';
import { matchPath, Route, Switch } from 'react-router-dom';

import History from '../../utils/history';
import Apps from '../../constants/Apps';
import Sanctions from '../SanctionsSearch/Sanctions';
import SanctionsSearch from '../SanctionsSearch/SanctionsSearch';
import SanctionsSavedSearch from '../SanctionsSearch/SanctionsSavedSearch';
import MonthlyReports from '../SanctionsSearch/RetroComponents/MonthlyReports';
import SanctionsReport from '../SanctionsSearch/RetroComponents/SanctionsReport';
import Intelligence from '../Intelligence/Intelligence';
import Redirect from '../Redirect/Redirect';
import Alerting from '../Alerting/Alerting';
import Search from '../Search/Search';
import Manage from '../Manage/Manage';
import Reports from '../Reports/Reports';
import Tagging from '../Tagging/Tagging';
import Content from '../Content/Content';
import Media from '../../components/content/Media/Media';
import Demos from '../../containers/Demos/Demos';
import Dashboards from '../../containers/Dashboards/Dashboards';
import Threads from '../Threads/Threads';

import Webhooks from '../../containers/Webhooks/Webhooks';
import Integrations from '../../containers/Integrations/Integrations';

const GraphQL = () => {
  window.location.href = `${window.location.protocol}//${window.location.host}/graphql`;
  return null;
};

// Used to check when coming into the platform via an external link (email)
const LegacyRoutes = () => {
  const { pathname, query, hash } = History.getCurrentLocation();
  const apps = fromJS(Apps) || list();
  const app = apps
    .filter(v => !v.has('nav'))
    .find(v => v.get('path') && v.get('path').some(p => pathname.includes(p)));

  if (app && app.get('path').count() > 1) {
    // The route is either a new route or a legacy route
    const newRoute = app.getIn(['path', 0]);
    if (!pathname.includes(newRoute)) {
      // It is a legacy route, so update the path and reload
      let newPath = pathname;
      app.get('path').forEach((v) => {
        newPath = newPath.replace(v, newRoute);
      });
      History.navigateTo({ pathname: newPath, query, hash });
    }
  }
};

const Routes = () => (
  <Switch>
    /_ graphql _/
    <Route exact path="/home/graphql" component={GraphQL} />
    /_ explore _/
    <Route exact path="/home/explore/ato/:section/:subsection" component={Intelligence} />
    <Route exact path="/home/explore/fraud/:section/:subsection" component={Intelligence} />
    <Route exact path="/home/explore/vulndb/:section/:subsection" component={Intelligence} />
    <Route exact path="/home/explore/:section/:subsection/:id/:edit?" component={Intelligence} />
    <Route exact path="/home/explore/:section/:subsection" component={Intelligence} />
    /_rfi _/
    <Route exact path="/home/rfi/:type" component={Content} />
    /_ intelligence _/
    <Route exact path="/home/intelligence/cti/:section" component={Intelligence} />
    <Route exact path="/home/intelligence/fraud/:section/:tags" component={Intelligence} />
    <Route exact path="/home/intelligence/vulndb/:section/:tags" component={Intelligence} />
    <Route exact path="/home/intelligence/person-search/:type" component={Content} />
    <Route exact path="/home/intelligence/person-search/results/:id" component={Content} />
    <Route exact path="/home/intelligence/reports/report/:id/:anchor?" component={Reports} />
    <Route exact path="/home/intelligence/reports/report/:id/:featured?/:anchor?" component={Reports} />
    <Route exact path="/home/intelligence/search/:type" component={Search} />
    <Route exact path="/home/intelligence/:type/search" component={Search} />
    <Route exact path="/home/intelligence/:section/:tags" component={Intelligence} />
    <Route exact path="/home/intelligence/:section" component={Intelligence} />
    /_ communities _/
    <Route exact path="/home/search/sanctions" component={Sanctions} />
    <Route exact path="/home/search/tagging/:type" component={Tagging} />
    <Route exact path="/home/search/dmtm/:type" component={Dashboards} />
    <Route exact path="/home/search/ato/:type/:category?" component={Search} />
    <Route exact path="/home/search/cti/:type" component={Search} />
    <Route exact path="/home/search/vulndb/:type" component={Search} />
    <Route exact path="/home/search/:type" component={Search} />
    <Route exact path="/home/search/:type/:category?" component={Search} />
    /_ alerting _/
    <Route exact path="/home/alerting/manage/:type?" component={Manage} />
    <Route exact path="/home/alerting/:type" component={Alerting} />
    /_ dashboards _/
    <Route exact path="/home/dashboards/ato/:type" component={Dashboards} />
    <Route exact path="/home/dashboards/cti/:type" component={Dashboards} />
    <Route exact path="/home/dashboards/:type" component={Dashboards} />
    <Route exact path="/home/dashboards/:type/:subtype" component={Dashboards} />
    /_ fraud _/
    <Route exact path="/home/fraud/:type" component={Dashboards} />
    <Route exact path="/home/fraud/:type/:subtype" component={Dashboards} />
    /_ monit _/
    <Route exact path="/home/monit/alerting/:type" component={Alerting} />
    <Route exact path="/home/monit/:type/items/:id" component={Content} />
    <Route exact path="/home/monit/:type" component={Dashboards} />
    /_ smtm _/
    <Route exact path="/home/search/dmtm/:type/items/:id" component={Content} />
    /_ resources _/
    <Route exact path="/home/resources/:type/:anchor?" component={Content} />
    /_ integrations _/
    <Route exact path="/home/integrations/ccmc" component={Demos} />
    <Route exact path="/home/integrations/webhook/:type" component={Webhooks} />
    <Route exact path="/home/integrations/:type" component={Integrations} />
    /_ profile _/
    <Route exact path="/home/profile/admin/:type/:id" component={Content} />
    <Route exact path="/home/profile/admin/:type" component={Content} />
    <Route exact path="/home/profile/manage/:type" component={Manage} />
    <Route exact path="/home/profile/:type/:id?" component={Content} />
    /_ wiki _/
    <Route exact path="/home/wiki/:section/:id?/:edit?" component={Intelligence} />
    /_ ddw _/
    <Route exact path="/home/ddw/search/:type" component={Search} />
    <Route exact path="/home/ddw/:type/search" component={Search} />
    <Route exact path="/home/ddw/:type/items/:id" component={Content} />
    <Route exact path="/home/ddw/:type/:subtype/:id" component={Threads} />
    <Route exact path="/home/ddw/:type/:subtype/:fpid/:handle/:id" component={Content} />
    /_ shops _/
    <Route exact path="/home/shops/search/:type" component={Search} />
    <Route exact path="/home/shops/:type/items/:id" component={Content} />
    <Route exact path="/home/shops/:type/search" component={Search} />
    /_ technical data _/
    <Route exact path="/home/technical_data/search/:type" component={Search} />
    <Route exact path="/home/technical_data/:type/items/:id" component={Content} />
    <Route exact path="/home/technical_data/:type/search" component={Search} />
    /_ assets _/
    <Route exact path="/home/assets/media/items/:id" component={Media} />
    <Route exact path="/home/assets/:type/items/:id" component={Content} />
    <Route exact path="/home/assets/:type" component={Dashboards} />
    /_ ccm _/
    <Route exact path="/home/compromised/:type/items/:id" component={Content} />
    /_ sanctions _/
    <Route exact path="/home/sanctions/main" component={SanctionsSearch} />
    <Route exact path="/home/sanctions/savedSearch/:id" component={SanctionsSavedSearch} />
    <Route exact path="/home/sanctions/reports" component={MonthlyReports} />
    <Route exact path="/home/sanctions/report/:id" component={SanctionsReport} />
    /_ redirect _/
    <Route exact path="/home/redirect/:id" component={Redirect} />
  </Switch>
);

const Match = () => {
  const { pathname } = History.getCurrentLocation();
  const routes = Routes()
    ?.props?.children
    ?.filter(v => v.props)
    ?.map(v => v.props.path);
  return routes.find(v => matchPath(pathname, v));
};

const Routing = {
  LegacyRoutes,
  Match,
  Routes,
};

export default Routing;
