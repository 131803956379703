import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { escape } from 'lodash';
import moment from 'moment';

import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  ListItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import style from './bookmarks.module.scss';
import Editor from './Editor';
import History from '../../utils/history';
import Pager from '../utils/Pager';
import Invalid from '../utils/Invalid/Invalid';
import Text from '../../utils/text';
import UserActions from '../../actions/userActions';
import SearchActions from '../../actions/searchActions';
import Common from '../../utils/common';
import Messages from '../../constants/Messages';
import { QuickFilter } from './Searches';

const Bookmarks = ({
  data,
  location,
  type,
}) => {
  const [allTags, setAllTags] = useState([]);
  const [dialog, setDialog] = useState();
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState(map());
  const [filtered, setFiltered] = useState(list());
  const [limited, setLimited] = useState(list());

  const onFilter = (values) => {
    const text = values.get('text') || '';
    const categorized = values.get('categorized');
    // eslint-disable-next-line security/detect-non-literal-regexp
    const test = new RegExp(text, 'i');
    const highlight = `<x-fp-highlight>${text}</x-fp-highlight>`;
    const filteredValues = data
      .filter(v => ['name', 'folders'].some(t => (t === 'folders' ? test.test(v.get(t).join(', ')) : test.test(v.get(t)))))
      .filter(v => (categorized === true ? v.get('folders').count() > 0 : v))
      .filter(v => (categorized === false ? v.get('folders').count() === 0 : v))
      .map(v => (!text || !v.get('name') ? v : v.set('name', v.get('name').replace(test, highlight))))
      .map(v => (!text || !v.get('folders') ? v : v.set('folders', v.get('folders').map(f => f.replace(test, highlight)))));
    setFilters(values);
    setFiltered(list(([...filteredValues.values()])));
  };

  const onDelete = () => {
    const update = data.filterNot(v => selected.includes(v.get('updated')));
    UserActions.savePrefs({ bookmarks: update });
    SearchActions.set(['search', 'info', 'message'], Messages.BookmarkDeleted);
  };

  const onSave = (_, values) => {
    const now = +moment.utc().unix();
    const updates = values
      .map(v => v
        .set('name', escape(v.get('name')))
        .set('folders', v.get('folders').map(f => escape(f)))
        .set('updated', +now)
        .set('updated', v.get('updated') || +now));
    const update = map({
      bookmarks: !selected.length ? data.concat(updates) :
        data.map(v => (selected.includes(v.get('updated')) ?
          updates.find(s => s.get('updated') === v.get('updated')) : v)),
    });
    UserActions.savePrefs(update.toJS());
    SearchActions.set(['search', 'info', 'message'], Messages.BookmarkUpdated);
    setDialog();
    setSelected([]);
  };

  const onSearch = (bookmark) => {
    Common.Bookmark.Route(bookmark.get('path'));
  };

  const onSelect = (value) => {
    switch (value) {
      case 'all':
        if (selected.length === filtered.count()) return;
        setSelected(selected.length !== filtered.count() ?
          filtered.map(v => v.get('updated')).toJS() :
          []);
        break;
      case 'none':
        setSelected([]);
        break;
      default:
        setSelected(selected.includes(value) ?
          selected.filter(v => v !== value) :
          selected.concat(value));
        break;
    }
  };

  const onReset = () => {
    setSelected([]);
    onFilter(map());
  };

  useEffect(() => {
    setDialog(false);
    setFilters(map());
  }, [type]);

  useEffect(() => {
    let allTagValues = [];
    const values = data;
    values.map((v) => {
      allTagValues = allTags.concat(v.get('folders').toJS());
      return true;
    });
    setAllTags(Array.from(new Set(allTagValues)));
    onFilter(filters);
  }, [data]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    onFilter(map({ ...filters.toJS(), ...query }));
    setDialog({ key: query.edit ? 'editor' : '' });
  }, [location]);

  useEffect(() => {
    const limit = filters.get('limit') || '50';
    const skip = filters.get('skip') || '0';
    const limitedValue = filtered
      .slice(parseInt(skip, 10), parseInt(skip, 10) + parseInt(limit, 10));
    setLimited(limitedValue);
  }, [filters, filtered]);

  const columns = () => {
    switch (type) {
      case 'searches':
      default:
        return [
          { id: 'name',
            label: 'Bookmark Name',
            text: 'Label for the bookmark',
            render: v => v.get('name'),
          },
          { id: 'tags',
            label: 'Tags',
            text: 'The tags containing the bookmark',
            render: v => Text.Highlight(v.getIn(['folders']).join(', ') || 'Uncategorized'),
          },
          { id: 'updated',
            label: 'Last Edited (UTC)',
            text: 'Date when bookmark was updated',
            render: (v) => {
              const timestamp = v.get('updated');
              if (moment.utc(timestamp).year() !== 1970) {
                return (
                  <span>
                    {moment.utc(timestamp).format('MMM D, YYYY')}
                    <small>{` ${moment.utc(timestamp).format('HH:mm')}`}</small>
                  </span>
                );
              }
              return (
                <span>
                  {moment.unix(timestamp).utc().format('MMM D, YYYY')}
                  <small>{` ${moment.unix(timestamp).utc().format('HH:mm')}`}</small>
                </span>
              );
            },
          },
        ];
    }
  };

  const control = () => {
    const size = selected.length;
    return `Edit Bookmark${size > 1 ? `s (${size})` : ''}`;
  };

  const options = () => [
    { id: 'bookmark.editor',
      type: ['bookmarks'],
      dialog: (
        <Editor
          fields={[
            { value: 'name', label: 'Bookmark Name', type: 'text', req: true },
            { value: 'folders', label: 'Bookmark Tags', type: 'chips', bulk: true, dataSource: allTags }]}
          type={type}
          data={((filtered) || list())
            .filter(v => selected.includes(v.get('updated')))}
          save={(typeValue, values) => onSave(typeValue, values)}
          deleteActions={typeValue => onDelete(typeValue)}
          toggle={() => { setDialog(); setSelected([]); }} />
      ),
    },
  ];

  return (
    <Grid
      fluid
      name="component.bookmarks"
      className={cx([style.base, style.search])}>
      {limited &&
      <Row>
        <Col xs={12} className={style.filters}>
          {['bookmarks'].includes(type) &&
          <div>
            <div>
              <Button
                className={style.dropdown}
                icon={<Icon>keyboard_arrow_down</Icon>}
                onClick={e => setDialog({ key: 'select', target: e.currentTarget })}>
                <Icon className={style.icon}>
                  {selected.length > 0 ? 'check_box' : 'crop_square'}
                </Icon>Select
              </Button>
              <Popover
                className="dropdown"
                open={Boolean(dialog && dialog.key === 'select')}
                anchorEl={dialog && dialog.target}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                s
                onClose={() => setDialog()}>
                {['all', 'none'].map(v => (
                  <ListItem
                    key={v}
                    className={style.listItem}
                    onClick={() => {
                      onSelect(v);
                      setDialog();
                    }}>
                    {Text.Sentence(v)}
                  </ListItem>))}
              </Popover>
            </div>
            <div>
              <Button
                className={style.dropdown}
                icon={<Icon>keyboard_arrow_down</Icon>}
                onClick={e => setDialog({ key: 'filter', target: e.currentTarget })}>
                <Icon className={style.icon}>
                  {!filters.filterNot((v, k) => ['skip', 'limit'].includes(k)).isEmpty() ? 'check_box' : 'crop_square'}
                </Icon>Filter
              </Button>
              <Popover
                className="dropdown"
                open={Boolean(dialog && dialog.key === 'filter')}
                anchorEl={dialog && dialog.target}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                onClose={() => setDialog()}>
                {['categorized', 'not_categorized'].map(v => (
                  <ListItem
                    key={v}
                    className={style.listItem}
                    onClick={() => {
                      onFilter(filters.set('categorized', v === 'categorized'));
                      setDialog();
                    }}>
                    {Text.Sentence(v)}
                  </ListItem>))}
              </Popover>
            </div>
            <QuickFilter
              filters={filters}
              onFilter={onFilter}
            />
            <Button
              color="primary"
              className={cx([style.button])}
              onClick={() => onReset()}>Clear All Filters
            </Button>
            <div className={style.header}>
              {!!selected.length &&
              <Button
                color="secondary"
                variant="contained"
                className={cx([style.button, style.edit])}
                onClick={() => setDialog({ key: 'editor' })}>
                {control()}
              </Button>}
            </div>
          </div>}
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <div className={cx([style.card, !data.isEmpty() && style.loaded])}>
            <div className={style.header}>
              <div>
                {!limited.isEmpty() &&
                <Pager
                  limit
                  paginated
                  filters={filters}
                  data={fromJS({ total: filtered.count() })}
                  limits={fromJS([
                    { value: 25, label: '25' },
                    { value: 50, label: '50' },
                    { value: 75, label: '75' },
                    { value: 100, label: '100' },
                  ])} />}
              </div>
            </div>
            {data.isEmpty() &&
            <div className={style.empty}>
              <Invalid
                inline
                icon="warning"
                classes={['link']}
                title="You haven't bookmarked any items yet." />
            </div>}
            {!data.isEmpty() && data.size > 0 &&
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.length >= (limited || list()).count()}
                        onChange={() => onSelect(selected.length >= (limited || list()).count() ? 'none' : 'all')} />
                    </TableCell>
                    {columns().map(column => (
                      <TableCell
                        key={column.id}
                        style={column.style}>
                        {column.text &&
                        <div
                          data-for="header.tooltip"
                          data-tip={column.text.match(/(.{1,75})(?:\n|$| )/g).join('<br />')}
                          className={cx([column.sort ? style.sort : null])}>
                          {column.label}
                        </div>}
                        {!column.text && <div>{column.label}</div>}
                      </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(limited || list())
                  .sortBy(v => -(v.get('updated')))
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      key={`${row.get('updated')}`}
                      selected={selected.includes(row.get('updated'))}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.includes(row.get('updated'))}
                          onChange={() => onSelect(row.get('updated'))} />
                      </TableCell>
                      {columns().map(column => (
                        <TableCell
                          key={column.id}
                          style={column.style}
                          onClick={() => onSearch(row)}>
                          {column.render(row, rowIndex)}
                        </TableCell>))}
                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>}
          </div>
        </Col>
      </Row>
      {options()
        .filter(v => v.type.includes(type))
        .filter(v => v.dialog)
        .map(option => (
          <Dialog
            fullWidth
            key={option.id}
            className={style.dialog}
            open={!!(dialog && dialog.key === 'editor')}
            onClose={() => setDialog()}>
            <DialogTitle>
              {`${!selected.length ? 'Add New Item' : 'Edit Selected'}${selected.length ? `(${selected.length})` : ''}`}
            </DialogTitle>
            <DialogContent>
              {option.dialog}
            </DialogContent>
          </Dialog>))}
      <ReactTooltip id="header.tooltip" html place="top" effect="solid" />
    </Grid>
  );
};

Bookmarks.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  type: PropTypes.string,
};

Bookmarks.defaultProps = {
  data: list(),
  location: {},
  type: '',
};

export default Bookmarks;
