import React from 'react';

import styles from './logo.module.scss';

export interface FlashpointLogoProps {
  /** style class */
  className?: string;

  /** Testing ID for Cypress */
  testId?: string;

  /** variant to display, either full or just the icon */
  variant?: 'full' | 'icon';
}

export const FlashpointLogo = ({ className, testId, variant }: FlashpointLogoProps) =>
  (variant === 'full' ? (
    <svg
      data-testid={testId}
      className={[styles.logoFull, className].join(' ')}
      width="150"
      height="31"
      viewBox="0 0 150 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M33.017 8.65237H35.2047V22.1075H42.2726L41.5077 24.3394H33.017V8.65237Z" fill="var(--t-label)" />
      <path
        // eslint-disable-next-line max-len
        d="M60.8604 20.5427C62.0001 21.5748 63.4634 22.1396 64.9757 22.1312C66.8676 22.0836 67.8135 21.3581 67.8135 19.9549C67.8135 18.7873 67.194 18.0884 65.9242 17.8501L64.0042 17.5562C62.917 17.4182 61.9105 16.89 61.1587 16.0629C60.4804 15.2465 60.1251 14.1936 60.1643 13.1162C60.1396 12.5019 60.2392 11.8891 60.4566 11.3173C60.674 10.7454 61.0043 10.2272 61.4264 9.79606C61.8949 9.35773 62.4422 9.01982 63.0369 8.80173C63.6315 8.58364 64.2618 8.48967 64.8915 8.52521C66.5794 8.52604 68.2236 9.08217 69.5882 10.1138L68.3643 12.0677C67.3106 11.3039 66.0616 10.8836 64.7768 10.8604C64.1695 10.8367 63.5728 11.0302 63.0863 11.4085C62.8447 11.6122 62.6529 11.8724 62.5266 12.1678C62.4003 12.4632 62.3431 12.7855 62.3596 13.1082C62.3568 13.3468 62.4 13.5836 62.4867 13.8046C62.5734 14.0256 62.7018 14.2263 62.8645 14.395C63.2809 14.8207 63.8164 15.0987 64.3943 15.1892C64.7921 15.2607 65.3352 15.3481 66.0313 15.4434C67.1303 15.5071 68.1618 16.0148 68.9051 16.8578C69.6484 17.7009 70.0446 18.8125 70.0089 19.9549C69.9324 22.9467 68.2368 24.4664 64.9221 24.5141C62.8818 24.5435 60.9072 23.7653 59.3994 22.3378L60.8604 20.5427Z"
        fill="var(--t-label)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M103.054 16.4998C103.025 15.2744 103.076 14.0485 103.207 12.8302C103.283 12.3072 103.446 11.8019 103.689 11.337C104.09 10.5585 104.671 9.89539 105.379 9.40688C106.218 8.81065 107.216 8.50233 108.232 8.52523C109.258 8.50152 110.266 8.80963 111.116 9.40688C111.813 9.90111 112.38 10.567 112.768 11.3449C113.038 11.7992 113.21 12.3085 113.273 12.8382C113.384 14.0551 113.427 15.2777 113.403 16.4998C113.426 17.714 113.383 18.9286 113.273 20.1376C113.212 20.6753 113.04 21.1929 112.768 21.6547C112.335 22.5115 111.682 23.2273 110.881 23.7235C110.081 24.2196 109.164 24.4767 108.232 24.4665C107.215 24.4805 106.218 24.1669 105.379 23.5689C104.67 23.0882 104.088 22.4299 103.689 21.6547C103.589 21.4164 103.49 21.1861 103.406 20.9716C103.312 20.7013 103.246 20.4219 103.207 20.1376C103.077 18.9299 103.026 17.7146 103.054 16.4998V16.4998ZM105.249 16.4998C105.217 17.5617 105.271 18.6244 105.41 19.6769C105.477 20.1647 105.673 20.6236 105.976 21.0034C106.223 21.3337 106.536 21.6046 106.894 21.7977C107.299 22.0446 107.763 22.1684 108.232 22.1551C108.709 22.1662 109.179 22.0427 109.594 21.7977C109.941 21.608 110.239 21.3358 110.466 21.0034C110.772 20.6237 110.976 20.1657 111.055 19.6769C111.186 18.6237 111.237 17.5614 111.208 16.4998C111.235 15.4383 111.184 14.3761 111.055 13.3227C110.974 12.8415 110.771 12.3916 110.466 12.0201C110.238 11.689 109.94 11.4171 109.594 11.2258C109.17 11.0103 108.704 10.8963 108.232 10.8922C107.767 10.8944 107.309 11.0086 106.894 11.2258C106.537 11.4205 106.224 11.6912 105.976 12.0201C105.675 12.3919 105.479 12.8427 105.41 13.3227C105.273 14.3754 105.219 15.438 105.249 16.4998V16.4998Z"
        fill="var(--t-label)"
      />
      <path d="M117.947 8.65237H120.142V24.3394H117.947V8.65237Z" fill="var(--t-label)" />
      <path
        d="M124.938 8.65237H127.011L133.574 19.7723H133.597V8.65237H135.793V24.3394H133.712L127.164 13.2195H127.118V24.3394H124.923L124.938 8.65237Z"
        fill="var(--t-label)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M51.8801 8.65237H50.0443L44.7739 24.3394H47.0687L48.1778 20.8366L48.8127 18.7239L50.9851 11.8613L53.081 18.7239H51.0387C49.0193 18.7239 48.2161 20.8366 48.2161 20.8366H53.7083L54.8098 24.3394H57.1045L51.8801 8.65237Z"
        fill="var(--t-label)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M99.1755 11.2575C98.9307 10.6654 98.5467 10.1465 98.0588 9.74841C97.5811 9.32776 97.0233 9.0165 96.4218 8.83499C95.878 8.72031 95.3245 8.66178 94.7696 8.66025H89.5145V24.3394H91.7099V10.8922H94.8843C95.4414 10.8659 95.9924 11.0214 96.4601 11.337C96.7587 11.5294 96.9998 11.8044 97.1561 12.1313C97.3268 12.5531 97.4077 13.0083 97.3933 13.4656C97.3979 14.0942 97.1894 14.7043 96.8043 15.1892C96.5766 15.46 96.2918 15.6726 95.972 15.8104C95.6523 15.9481 95.3062 16.0074 94.9608 15.9835H95.1673C93.0944 15.9835 91.7099 18.2155 91.7099 18.2155H95.0526C95.6965 18.2464 96.3385 18.1228 96.9289 17.8541C97.5193 17.5855 98.042 17.1791 98.4565 16.6666C99.1885 15.7828 99.5903 14.6552 99.5886 13.4895C99.6165 12.7225 99.4752 11.9591 99.1755 11.2575V11.2575Z"
        fill="var(--t-label)"
      />
      <path
        d="M139.388 8.65237V10.7652H145.507C145.507 10.7652 143.327 12.2108 143.327 14.3553V24.3394H145.522V10.7652H148.735L149.5 8.65237H139.388Z"
        fill="var(--t-label)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M22.4152 17.6357H27.6014L28.3663 15.5308H25.5361C24.9076 15.6498 24.3081 15.8971 23.7724 16.2584C23.2367 16.6197 22.7753 17.0878 22.4152 17.6357V17.6357Z"
        fill="var(--t-label)"
      />
      <path d="M20.1433 8.65237V24.3394H22.3386V17.7469V15.515V10.8922H27.9532H28.6416L29.4065 8.65237H20.1433Z" fill="var(--t-label)" />
      <path d="M76.5108 15.3799V8.65237H74.3154V24.3394H76.5108V17.4848V15.3799Z" fill="var(--t-label)" />
      <path
        d="M82.2554 8.65237V15.3799H79.9606C77.9029 15.3799 76.5184 17.4848 76.5184 17.4848H82.263V24.3394H84.4584V8.65237H82.2554Z"
        fill="var(--t-label)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.64647 10.7095C7.38519 13.5962 6.4978 16.6436 6.00747 19.7723C6.45906 21.2683 6.71631 22.8203 6.7724 24.387C6.7724 27.3577 5.15075 28.4617 5.15075 28.4617C5.15075 28.4617 3.55971 27.3577 3.55971 24.387C3.59582 22.8104 3.85351 21.2477 4.32463 19.7484C5.08956 17.1432 8.37109 9.62931 8.37109 9.62931C8.37109 9.62931 7.69796 7.10349 7.03247 5.12573L6.91008 4.7683C6.84937 5.46638 6.72906 6.15747 6.55057 6.83343C6.33231 4.5431 5.87317 2.28447 5.18135 0.0979309C5.18135 0.0979309 5.18135 1.74209 2.84067 9.08125C1.81567 12.306 0.5 15.9836 0.5 19.1607C0.5 27.1035 5.18135 30.0979 5.18135 30.0979C5.18135 30.0979 9.8627 27.1591 9.8627 19.1607C9.7859 16.3031 9.37754 13.4656 8.64647 10.7095V10.7095Z"
        fill="var(--color-fp-orange)"
      />
    </svg>
  ) : (
    <svg data-testid={testId} className={[styles.logoIcon, className].join(' ')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 242.77 750">
      <path
        // eslint-disable-next-line max-len
        d="M280.35,274c-40.91,85.22-60.65,179.68-68.55,226.41,10.4,34.95,20.68,78.76,20.68,115.41,0,74.16-42.09,101.75-42.09,101.75S148.3,690,148.3,615.83c0-36.84,9.51-81.19,20.84-115.94,18.65-65.15,104.07-253,104.07-253S255.68,184,238.39,134.66c-1-2.93-2.1-6-3.21-9a280.74,280.74,0,0,1-9.3,51.55C223.09,108.64,190.39,9,190.39,9s0,41-60.69,224.41C103,314,69,406.68,69,485.85,69,684.93,190.39,759,190.39,759s121.39-74.07,121.39-273.15C311.78,419.56,298.31,344.58,280.35,274Z"
        transform="translate(-69.01 -9)"
        fill="var(--color-fp-orange)"
      />
    </svg>
  ));

FlashpointLogo.defaultProps = {
  className: null,
  testId: null,
  variant: 'full',
};
