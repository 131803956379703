import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogTitle,
  FormGroup,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import SettingsButton from './SettingsButton';
import useStyles from './style';
import style from './CloudInfrastructure.module.scss';
import bucket_regions from '../../../../constants/S3BucketRegions';
import CSVImport from './CSVImport/CSVImport';

const REQUIRED_ERROR_MESSAGE = 'This field is required';

export const S3BUCKETREGEX = /(^(?!(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(\d|[1-2]\d|3[0-2]))?$))(^(?!xn--).*)(?!.*-s3alias)(^([a-z0-9]|[a-z0-9][a-z0-9\-|.]*[a-z0-9])$)/;

const ClDialogBucket = ({
  open,
  selected,
  onSubmit,
  onCancel,
  dialog,
  handleDrop,
  files,
  onDeleteBulk,
  csvContent,
  preview,
  setPreview,
  featureFlag, // remove FF
}) => {
const { control, handleSubmit, formState: { errors }, setValue } = useForm();

  const classes = useStyles();

  const handleChange = (e, newValue) => newValue;

  const handleInputChange = (e, data) => {
    let value = data;
    if (!Number.isNaN(Number(value))) {
      value = selected?.credential?.region || '';
    }
    setValue('region', value);
    return value;
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)} className="cloudInfraBucket">
        { !preview ?
          <Grid container>
            <Grid item xs={12} className={style.cloudInfrastructureBucket}>
              <FormGroup className={classes.dialogFormGroup}>
                <DialogTitle className={classes.dialogTitle}>{selected ? 'AWS Bucket' : 'Add New AWS Bucket'}</DialogTitle>
                <div className={classes.fieldGap}>
                  <Controller
                    name="name"
                    defaultValue={selected?.credential.name || ''}
                    control={control}
                    rules={{
                     required: !files.length && true,
                     minLength: 3,
                     maxLength: 63,
                     pattern: S3BUCKETREGEX,
                    }}
                    shouldUnregister
                    render={({ field }) =>
                      <TextField
                        error={errors?.name}
                        disabled={selected !== null && dialog.key !== 'edit'}
                        label="Bucket Name"
                        variant="outlined"
                        data-testid="name-field"
                        {...field} />
                    }
                  />
                  <div className={classes.errorText}>{errors?.name?.type === 'required' && REQUIRED_ERROR_MESSAGE}</div>
                  <div className={classes.errorText}>{errors?.name?.type === 'pattern' && 'Invalid Bucket Name'}</div>
                  <div className={classes.errorText}>{(errors?.name?.type === 'maxLength' || errors?.name?.type === 'minLength')
                  && 'Bucket name length must be between 3 and 63'}
                  </div>
                </div>
                <div className={classes.fieldGap}>
                  <Controller
                    name="region"
                    control={control}
                    rules={{
                      required: !files.length && true,
                    }}
                    shouldUnregister
                    render={({ field }) =>
                      <Autocomplete
                        id="region"
                        value={selected?.credential.region}
                        onChange={value => handleChange(null, value, null)}
                        onInputChange={(e, data) => handleInputChange(e, data)}
                        filterSelectedOptions={false}
                        freeSolo
                        autoSelect
                        options={bucket_regions}
                        disabled={selected !== null && dialog.key !== 'edit'}
                        onBlur={field.onBlur}
                        name={field.name}
                        ref={field.ref}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={errors?.region}
                            label="Bucket Region"
                            variant="outlined"
                            data-testid="region-field" />
                        )}
                      />
                    }
                  />
                  <div className={classes.errorText}>{errors?.region?.type === 'required' && REQUIRED_ERROR_MESSAGE}</div>
                </div>
              </FormGroup>
              { dialog.key === 'create' && featureFlag && // remove FF
              <div>
                <div className={classes.breakline}>
                  <span className={classes.breaklineInner}>OR</span>
                </div>
                <div className={classes.dialogBody}>
                  <CSVImport
                    externalHandleDrop={handleDrop}
                    formats={['csv']}
                    description="Upload must include name and region for each bucket"
                    title="Bulk Upload a List of Buckets"
                    />
                  {files.map((file, index) =>
                    <div className={style.fileList}>
                      <div>
                        {file.name}
                      </div>
                      <CloseIcon onClick={() => { onDeleteBulk(index); }} fontSize="small" />
                    </div>,
                  )}
                </div>
              </div>
            }
            </Grid>
            {files.length ?
              <Grid container item xs={12} justifyContent="flex-end" className={classes.dialogFooter}>
                <Grid item>
                  <Button onClick={onCancel}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button color="secondary" onClick={() => setPreview(true)}>Preview</Button>
                </Grid>
              </Grid>
               :
              <SettingsButton onCancel={onCancel} isForm={selected !== null && dialog.key !== 'edit'} />
          }
          </Grid>
        :
          <Grid container>
            <Grid item xs={12} className={style.cloudInfrastructureBucket}>
              <DialogTitle className={classes.dialogTitle}> Bulk Upload Bucket Preview</DialogTitle>
              <div> Number of buckets: {csvContent?.length - 1} </div>
              <div className={style.previewContainer}>
                {csvContent?.slice(1).map((bucket, index) =>
                  <div> {`Line ${index + 2}: `} {bucket.split(',')[0] ? bucket.split(',')[0] :
                  <span className={style.error}>missing name</span>}, {bucket.split(',')[1] ? bucket.split(',')[1] :
                  <span className={style.error}>missing region</span> }
                  </div>)}
              </div>
            </Grid>
            <SettingsButton onCancel={onCancel} isForm={selected !== null && dialog.key !== 'edit'} />
          </Grid>}
      </form>
    </Dialog>
  );
};

ClDialogBucket.propTypes = {
  open: PropTypes.bool,
  preview: PropTypes.bool,
  selected: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  handleDrop: PropTypes.func,
  onDeleteBulk: PropTypes.func,
  dialog: PropTypes.object,
  featureFlag: PropTypes.bool, // remove FF
  files: PropTypes.array,
  csvContent: PropTypes.array,
  setPreview: PropTypes.func,
};

ClDialogBucket.defaultProps = {
  open: false,
  preview: false,
  selected: {},
  onSubmit: null,
  onCancel: null,
  handleDrop: null,
  onDeleteBulk: null,
  dialog: {},
  files: [],
  csvContent: null,
  setPreview: [],
  featureFlag: true, // remove FF
};

export default ClDialogBucket;
