import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import {
  CircularProgress,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import { faSparkles } from '@flashpoint/fp-icons/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './suggestions.module.scss';
import Common from '../../../utils/common';
import Text from '../../../utils/text';
import History from '../../../utils/history';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import Query from './query';

const CONVERSION_URL = '/conversion/convert';
const IGNITE_URL = '//app.flashpoint.io/search/communities/home';

const Suggestions = ({
  focus,
  prm,
  text,
  type,
  onBlur,
  onSearch,
}) => {
  const [topics, setTopics] = useState();
  const [data, setData] = useState();

  const convert = async () =>
    fetch(CONVERSION_URL, {
      method: 'POST',
      body: JSON.stringify({ query: text }),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json())
    .then(res => res?.query || text)
    .catch(() => text);

  const load = async () => {
    if (text && text?.length < 3) return;
    const limit = 4;
    const [explore, reports, dashboards] = await Query.Suggestions(text, prm);
    const result = [...reports, ...dashboards];
    const suggestions = result.slice(0, limit);

    setTopics(explore);
    setData(suggestions);
  };

  const onRoute = async (route, app = 'fptools') => {
    onBlur();

    switch (app) {
      case 'ignite': {
        const conversion = await convert(route);
        History.navigateTo(`${IGNITE_URL}?query=${encodeURIComponent(conversion)}`, true);
        break;
      }
      default:
        return History.navigateTo(route);
    }
  };

  useDebouncedEffect(() => load(), [text], 1000);

  useEffect(() => {
    setTopics();
    setData();
  }, [text]);

  return (
    <div className={style.suggestions}>
      <MenuList
        variant="menu"
        autoFocusItem={focus}>
        <MenuItem
          dense
          data-testid="suggestions.ignite-search"
          tabIndex={0}
          className={cx([style.listitem, style.ignite])}
          onClick={() => onRoute(text, 'ignite')}>
          <ListItemIcon>
            <FontAwesomeIcon className={cx([style.icon, style.search])} icon={faSparkles} />
          </ListItemIcon>
          <ListItemText
            primary="Check out search in Ignite - it’s 5x faster than FPTools!" />
        </MenuItem>
        {!text && !data &&
        <MenuItem disabled>
          <CircularProgress />
        </MenuItem>}
        {text &&
        <MenuItem
          dense
          tabIndex={0}
          className={cx([style.listitem, style.search])}
          onClick={() => onSearch(text)}>
          <ListItemIcon>
            <Icon className={cx([style.icon, style.search])}>search</Icon>
          </ListItemIcon>
          <ListItemText
            primary={`${Text.Trim(text, 50)} - ${Text.Sentence(type)} Search`} />
          {!data && text?.length > 5 && <CircularProgress />}
        </MenuItem>}
        {data && topics
          ?.slice(0, text ? 2 : 1)
          ?.concat(data)
          ?.map(v => (
            <MenuItem
              dense
              key={v.name}
              tabIndex={0}
              disabled={!v.value}
              className={style.listitem}
              onClick={() => onRoute(v.value)}>
              <ListItemIcon>
                <Icon className={cx([style.icon, style.active])}>
                  {v.icon || Common.Generic.Icon([v.type])}
                </Icon>
              </ListItemIcon>
              <ListItemText
                primary={v.name}
                secondary={Text.Sentence(v.type)} />
            </MenuItem>))}

      </MenuList>
    </div>
  );
};

Suggestions.propTypes = {
  focus: PropTypes.bool.isRequired,
  prm: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default Suggestions;
