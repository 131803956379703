import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './webhooks.module.scss';
import CCMc from '../../components/webhooks/CCMc';
import CredentialsActions from '../../actions/credentialsActions';
import { CredentialsContext, UserContext } from '../../components/utils/Context';

const Webhooks = ({
  match: {
    params,
  },
}) => {
  const credentials = useContext(CredentialsContext);
  const user = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
    switch (params.type) {
      case 'ccmc':
        CredentialsActions.getSettings();
        break;
      default:
        break;
    }
  }, []);

  return (
    <Grid
      fluid
      className={cx([style.webhooks])}>
      <Row>
        <Col xs={12}>
          {params.type === 'ccmc' &&
          <CCMc
            activePromises={credentials.get('activePromises')}
            importStatus={credentials.get('customerImportStatus')}
            settings={credentials.get('settings')}
            stats={credentials.get('customerStats')}
            user={user} />}
        </Col>
      </Row>
    </Grid>
  );
};

Webhooks.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Webhooks;
