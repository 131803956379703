import React, { useContext, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import PersonSearchConfirmation from '../personSearch/PersonSearchForm/PersonSearchConfirmation';
import PersonSearchError from '../personSearch/PersonSearchError';
import PersonSearchForm from '../personSearch/PersonSearchForm/PersonSearchForm';
import Query from '../personSearch/query';
import { UserContext } from '../utils/Context';
import UserActions from '../../actions/userActions';
import Token from '../../utils/token';
import PersonSearchState from '../../stores/recoil/personSearch';
import Autocomplete from '../../constants/Autocomplete';

const PersonSearch = () => {
  const user = useContext(UserContext);

  const formData = useRecoilValue(PersonSearchState.formData);
  const setEmail = useSetRecoilState(PersonSearchState.email);
  const setBiographicalLocation = useSetRecoilState(PersonSearchState.biographicalLocation);
  const setName = useSetRecoilState(PersonSearchState.name);
  const setIpAddress = useSetRecoilState(PersonSearchState.ipAddress);
  const setPhone = useSetRecoilState(PersonSearchState.phone);

  const [biographicalData, setBiographicalData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reportID, setReportID] = useState('');
  const [sendPersonSearchRequest, setSendPersonSearchRequest] = useState(false);
  const [startRequest, setStartRequest] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const addBiographicalData = (data) => {
    data.forEach((v) => {
      if (v.email) setEmail(prev => [...prev, v.email.address]);
      if (v.name) setName(prev => [...prev, v.name.raw]);
      if (v.ip) setIpAddress(prev => [...prev, v.ip.ip]);
      if (v.phone) setPhone(prev => [...prev, v.phone.raw]);
      if (v.location) {
        // currently only set up for US
        const currentLocation = v.location.raw;
        const city = currentLocation.substring(0, currentLocation.indexOf(','));
        let state = currentLocation.substring(currentLocation.indexOf(',') + 1).trim();
        if (state) {
          const stateObj = Object.fromEntries(
            Object.entries(Autocomplete.state_codes).filter(value => value[1] === state),
          );
          state = Object.keys(stateObj).toString();
        }
        setBiographicalLocation(prev => [...prev, { city, state, country: 'US' }]);
      }
    });
    setSendPersonSearchRequest(true);
  };

  const postPersonSearchApi = (data) => {
    Query.postForm(data)
      .then((res) => {
        if (res.data.created) {
          const hasResultPrms = user.get('prm').some(p => /dat.poi.rep.req$/.test(p)) && user.get('prm').some(p => /dat.poi.rep.r$/.test(p));
          if (hasResultPrms) {
            window.location.pathname = `/home/intelligence/person-search/results/${res.data.id}`;
          } else {
            setSubmitted(true);
            setReportID(res.data.id);
          }
        }
      })
      .catch((err) => {
        setError(true);
        setErrorMessage(err.response?.data?.detail);
        throw err;
      });
  };

  const refreshForm = (e) => {
    e.preventDefault();
    setSubmitted(false);
  };

  const initiateRequest = async (data) => {
    const promise = Query.getBiographicalData(data);
    await Promise.all([promise])
      .then(([res]) => {
        // if no response data, send person search api call, otherwise add biographical data and then send
        if (!res.data.length) {
          setSendPersonSearchRequest(true);
        } else {
          setBiographicalData(res.data);
        }
      })
      .catch((err) => {
        postPersonSearchApi(formData);
        throw err;
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* Log out if user's auth cookie is expired */
    if (!Token.cke()) {
      UserActions.logout();
      window.location.href = '/';
    }
    setStartRequest(true);
  };

  useEffect(() => {
    if (biographicalData.length) addBiographicalData(biographicalData);
  }, [biographicalData]);

  useEffect(() => {
    if (sendPersonSearchRequest) postPersonSearchApi(formData);
  }, [sendPersonSearchRequest]);

  useEffect(() => {
    if (startRequest) initiateRequest(formData);
  }, [startRequest]);

  if (error) {
    return <PersonSearchError message={errorMessage} setError={setError}/>;
  } else if (submitted) {
    return <PersonSearchConfirmation refreshForm={refreshForm} reportID={reportID}/>;
  }

  return (
    <PersonSearchForm handleSubmit={handleSubmit} />
  );
};

export default PersonSearch;
