import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  Button,
} from '@mui/material';

import Tagger from '../tagging/Tagger';
import History from '../../utils/history';
import style from './tagsFilter.module.scss';

const TagsFilter = ({
  advanced,
  filters,
  metaData,
  onApply,
  onFilter,
  prm,
  toggle,
}) => {
  const [tags, setTags] = useState(list());
  const [untagged, setUntagged] = useState(false);

  const onAll = () => {
    setUntagged(false);
    const allTags = metaData.getIn(['tags']);
    setTags(allTags);
  };

  const onClear = () => {
    setUntagged(true);
    setTags(list());
  };

  const onChange = (values, untaggedValue = false) => {
    const allTags = metaData.getIn(['tags']);
    setTags(allTags.filter(v => values.includes(v.get('id'))));
    setUntagged(untaggedValue);
    if (onFilter) onFilter(values);
  };

  const onSearch = () => {
    const { pathname, query, hash } = History.getCurrentLocation();

    const tagsQuery = fromJS({
      ...query,
      tags: (!untagged) ? tags.map(v => v.get('tag_name')).join() : 'Untagged',
      skip: undefined,
    }).filter(v => v).toJS();

    toggle();

    if (onApply) {
      onApply(fromJS(tagsQuery));
      return;
    }

    History.push({
      pathname,
      query: tagsQuery,
      hash,
    });
  };

  useEffect(() => {
    if (filters.getIn(['tags'])) {
      const filterTags = filters.getIn(['tags']);
      const tagsList = fromJS(filterTags.split(','));
      const allTags = metaData.getIn(['tags']);
      setTags(allTags.filter(v => tagsList.includes(v.get('tag_name'))));
      if (tagsList.includes('Untagged')) setUntagged(true);
    }
  }, [filters]);

  return (
    <Grid
      fluid
      className={cx([style.base, style.tagsfilter])}>
      <div>
        <Tagger
          filter
          selected={(!untagged) ? tags : list(['Untagged'])}
          prm={prm}
          metaData={metaData}
          onChange={onChange} />
        {toggle &&
        <Row>
          <Col xs={12} className={cx([style.footer, advanced && style.advanced])}>
            <div className={style.error} />
            <div className={style.actions}>
              <div className={style.left}>
                <Button
                  name="tagsfilter.clearall"
                  onClick={() => onClear()}>
                  Clear All
                </Button>
                <Button
                  name="tagsfilter.addall"
                  onClick={() => onAll()}>
                  Add All
                </Button>
              </div>
              <Button
                name="tagsfilter.cancel"
                onClick={() => toggle()}>
                Cancel
              </Button>
              <Button
                className={style.active}
                name="tagsfilter.apply"
                onClick={() => onSearch()}>
                Apply
              </Button>
            </div>
          </Col>
        </Row>}
      </div>
    </Grid>
  );
};

TagsFilter.propTypes = {
  advanced: PropTypes.bool,
  filters: PropTypes.object,
  metaData: PropTypes.object,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  prm: PropTypes.object,
  toggle: PropTypes.func,
};

TagsFilter.defaultProps = {
  advanced: false,
  filters: map(),
  metaData: map(),
  onApply: null,
  onFilter: null,
  prm: map(),
  toggle: null,
};

export default TagsFilter;
