import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { Cell, PieChart, Pie, Legend, Tooltip } from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  Collapse,
  CircularProgress,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Tab,
} from '@mui/material';

import style from './channels.module.scss';
import Text from '../../utils/text';
import SearchActions from '../../actions/searchActions';
import TextFilter from '../filters/TextFilter';
import DateFilter from '../filters/DateFilter';
import Chips from '../filters/Chips';
import Iso from '../../constants/Iso';
import Autocomplete from '../../constants/Autocomplete';
import Media from '../utils/Media';
import InternalLink from '../utils/InternalLink';
import Tagger from '../tagging/Tagger';
import History from '../../utils/history';

const useStyles = makeStyles({
  board: {
    '& .MuiTabPanel-root': {
      padding: 0,
    },
  },
});

const Channels = ({
  filters,
  inline,
  meta,
  metaData,
  prm,
  search,
  username,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [load, setLoad] = useState(true);
  const [values, setValues] = useState(map());
  const [text, setText] = useState('');
  const [tab, setTab] = useState('search');

  const aliases = user => (
    `<b>User ID</b>: ${user.get('native_id')}<br />
    <b>First Name</b>: ${user.get('first_name') || 'N/A'}<br />
    <b>Last Name</b>: ${user.get('last_name') || 'N/A'}<br />
    <b>Aliases</b>: ${[...new Set((user.getIn(['names', 'aliases']) || list()))]
      .filter(v => !v.includes(user.get('username')))
      .join(', ')}`
  );

  const count = filters
    .filter(v => v)
    .filterNot((v, k) => ['id', 'fpid', 'date', 'query', 'sort', 'since', 'unti', 'skip', 'limit'].includes(k))
    .filterNot((v, k) => v === search.getIn(['defaults', 'chats', k]))
    .size;

  const options = (
    !values ? [] : [
      { type: ['chats'],
        key: 'date',
        label: 'Date',
        icon: 'date_range',
        fields: ['since', 'until'],
        values: ['since', 'until'],
        dialog: (
          <DateFilter
            time
            inline
            icon="date_range"
            text="Filter results by date"
            date={values.get('date')}
            since={values.get('since')}
            until={values.get('until')}
            dates={search.get('dates')}
            onFilter={v => setValues(values.merge(v))} />) },
      { type: ['chats'],
        key: 'author',
        label: 'User',
        icon: 'account_circle',
        fields: ['author', 'author_exact'],
        dialog: (
          <TextFilter
            inline
            icon="account_circle"
            text="Search by username"
            fields={[
              { value: 'author', label: 'User Name', type: 'text' },
              { value: 'author_exact', label: 'Exact Username', type: 'toggle' }]}
            defaults={search.getIn(['defaults', 'chats']) || map()}
            filters={values}
            onFilter={v => setValues(values.merge(v))} />) },
      { type: ['chats'],
        key: 'message',
        label: 'Message',
        icon: 'message',
        fields: ['message', 'language', 'media_caption', 'media', 'media_type'],
        dialog: (
          <TextFilter
            inline
            icon="message"
            text="Search by message content"
            fields={[
              { value: 'message', label: 'Message Contains', type: 'text' },
              { value: 'language', label: 'Language', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Object.keys(Iso).filter(k => k !== 'n/a').map(k => ({ label: `${k.toUpperCase()}: ${Iso[String(k)].name}`, value: `${k.toUpperCase()}: ${Iso[String(k)].name}` }))]) },
              { value: 'media_caption', label: 'Media Caption', type: 'text' },
              { value: 'media', label: 'Includes Media?', type: 'checkbox', opts: fromJS([{ value: 'true', label: 'Y' }, { value: 'false', label: 'N' }]) },
              { value: 'media_type', label: 'Media Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, ...Autocomplete.media_type.map(v => ({ label: v, value: v }))]) }]}
            defaults={search.getIn(['defaults', 'chats']) || map()}
            filters={values}
            onFilter={v => setValues(values.merge(v))} />) },
    ]
  );

  const onToggle = () => (show) => {
    const btn = document.getElementsByName('channel.filters')[0];
    const searchValue = document.getElementsByName('channel.search')[0];
    if (searchValue || show) btn.click();
  };

  const onChannel = (k, v) => ({
    pathname: '/home/search/chats',
    query: { query: '', [k]: v },
  });

  const onDelete = (option) => {
    const id = meta.get('fpid');
    const value = option.values
      ? option.values.reduce((acc, cur) => ({ ...acc, [cur]: search.getIn(['defaults', 'chats', cur]) }), {})
      : ({ [option.value]: search.getIn(['defaults', 'chats', option.value]) });
    const query = { ...values.toJS(), ...value, query: text };
    SearchActions.set(['search', 'result', 'channel_inline'], map());
    SearchActions.search('inline.channels', id, query);
    setLoad(true);
  };

  const onPieClick = (k, v) => {
    const pathname = '/home/search/chats';
    const query = { query: '', [k]: v };
    History.navigateTo({ pathname, query });
  };

  const onSearch = () => {
    const fpid = meta.getIn(['container', 'fpid']);
    const query = { ...values.toJS(), query: text };
    SearchActions.search('inline.channels', fpid, false, query);
    setLoad(true);
    setTab('search');
    onToggle();
  };

  const onServerChannel = (fpid) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const path = pathname.split('/');
    path.pop();
    const channel = `${path.join('/')}/${fpid}`;
    return {
      pathname: channel,
      query: { ...query, fpid: undefined, id: undefined },
      hash,
    };
  };

  const onRoute = (e, v) => {
    e.stopPropagation();
    const { pathname, query, hash } = History.getCurrentLocation();
    const fpid = v.getIn(['fpid']);
    const id = moment.utc(v.getIn(['sort_date'])).unix();
    const updates = { ...query, fpid, id, query: text };
    History.navigateTo({ pathname, hash, query: updates });
    SearchActions.search('channels', v.getIn(['container', 'fpid']));
  };

  const isDiscord = (
    !meta.isEmpty() && meta.has('server')
  );

  const showTagger = (
    !meta.isEmpty() && ['Telegram'].includes(meta.getIn(['site', 'title']))
  );

  const tabs = () => {
    const tabList = [
      ...(isDiscord ? [(<Tab key="server" label="Server" value="server" />)] : []),
      (<Tab key="channel" label="Channel" value="channel" />),
      (<Tab key="search" label="Search" value="search" />),
    ];
    return tabList;
  };

  useEffect(() => {
    setValues(filters);
    setText(filters.get('query'));
  }, [filters]);

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.channel,
        classes.channel,
      ])}>
      <Row>{/* tabs */}
        <Col xs={12}>
          <TabContext value={tab}>
            <TabList
              variant="fullWidth"
              indicatorColor="secondary"
              onChange={(event, val) => setTab(val)}
              className={style.tabs}>
              {tabs()}
            </TabList>
            {isDiscord &&
            <TabPanel value="server">
              {(meta.isEmpty() || meta.get('server').isEmpty()) &&
              <CircularProgress />}
              {!meta.isEmpty() && !meta.get('server').isEmpty() &&
              <div className={style.server}>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Channel:</div>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Thread Size:</div>
                </div>
                {meta.getIn(['server', 'data']).map(v => (
                  <div className={style.table} key={v.get('fpid')}>
                    <InternalLink
                      className={cx([v.getIn(['container', 'fpid']) && style.a, 'a'])}
                      to={onServerChannel(v.getIn(['container', 'fpid']))}>
                      {v.getIn(['container', 'name'])}
                    </InternalLink>
                    <div>{(v.get('messages') || '0').toLocaleString()}</div>
                  </div>
                ))}
              </div>}
            </TabPanel>}
            <TabPanel value="channel">
              <div className={style.body}>
                {meta.isEmpty() &&
                <CircularProgress />}
                {!meta.isEmpty() &&
                <div>
                  {meta.getIn(['container', 'name']) &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Channel:</div>
                    <InternalLink
                      className={cx([meta.get('fpid') && style.a, 'a'])}
                      to={onChannel('channel',
                        `${meta.getIn(['fpid'])}::${meta.getIn(['title'])}`)}>
                      {meta.getIn(['container', 'name'])}
                      {(meta.get('aliases') || list())
                      .map(v => v.get('key'))
                      .includes(inline.getIn(['data', 0, 'site_actor', 'names', 'handle'])) &&
                      <div><b>(Channel)</b></div>}
                    </InternalLink>
                  </div>}
                  {meta.get('aliases') && meta.get('aliases').count() > 1 &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Aliases:</div>
                    <div className={style.truncate}>
                      {meta.get('aliases').map(v => (
                        <InternalLink
                          key={v.get('key')}
                          className={cx(...[meta.get('fpid') ? [style.a, 'a'] : ['']])}
                          to={onChannel('channel',
                            `${meta.getIn(['fpid'])}::${v.get('key')}`)}>
                          {v.get('key')}
                        </InternalLink>))}
                    </div>
                  </div>}
                  {meta.get('nid') && meta.get('nid') !== meta.get('title') &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Channel ID:</div>
                    <InternalLink
                      className={cx([meta.get('nid') && style.a, 'a'])}
                      to={onChannel('channel_id', meta.getIn(['nid']))}>
                      {meta.get('nid')}
                    </InternalLink>
                  </div>}
                  {meta.get('type') &&
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Channel Type:</div>
                    <div>{Text.Sentence(meta.get('type'))}</div>
                  </div>}
                  {meta.get('description') &&
                  <div>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Description:</div>
                    <div>{meta.get('description')}</div>
                  </div>}
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Contributors:</div>
                    <div>{(meta.get('actors') || '0').toLocaleString()}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Messages:</div>
                    <div>{(meta.get('total') || '0').toLocaleString()}</div>
                  </div>
                  {meta.get('lang') && meta.get('lang').count() > 0
                    && (meta.get('lang').count() > 1 || meta.get('lang').first().get('id') !== '-') &&
                    <div className={style.graph}>
                      <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Languages:</div>
                      <div className={cx([style.column, style.chart])}>
                        <PieChart
                          width={250}
                          height={250}>
                          <Pie
                            isAnimationActive={false}
                            dataKey="value"
                            nameKey="label"
                            cx="45%"
                            cy="40%"
                            minAngle={10}
                            outerRadius="100"
                            onClick={v => onPieClick('language', `${v.label}:${Iso[v.label.toLowerCase()].name}`)}
                            data={meta.get('lang').toJS()}>
                            {meta.get('lang').toJS().map((v, k) => (
                              <Cell key={`cell-${v.id}`} fill={Text.Color(k, 'lang')} />))}
                          </Pie>
                          <Tooltip formatter={value => `${value.toLocaleString()}`} />
                          <Legend
                            align="center"
                            layout="vertical"
                            iconType="square"
                            height={1}
                            verticalAlign="bottom"
                            onClick={v => onPieClick('language', `${v.label}:${Iso[v.label.toLowerCase()].name}`)}
                            payload={meta.get('lang').toJS().map((v, k) => ({
                              ...v,
                              type: 'square',
                              color: Text.Color(k, 'lang'),
                              value: `${Iso[v.label.toLowerCase()].name}: ${v.value.toLocaleString()}` }))} />
                        </PieChart>
                      </div>
                    </div>}
                </div>}
              </div>
              {showTagger &&
              <Tagger fpid={meta.get('fpid')} prm={prm} metaData={metaData} username={username} />}
            </TabPanel>
            <TabPanel value="search">
              <div className={style.search}>
                <form
                  className={style.form}
                  onSubmit={(e) => { e.preventDefault(); onSearch(); }}>
                  <FormControl variant="outlined" name="text">
                    <InputLabel>Filter Results</InputLabel>
                    <OutlinedInput
                      value={text || ''}
                      data-lpignore="true"
                      onChange={event => setText(event.target.value)}
                      startAdornment={(
                        <InputAdornment position="start">
                          <Icon
                            onClick={() => onSearch()}>
                            search
                          </Icon>
                        </InputAdornment>)}
                      endAdornment={text && (
                        <InputAdornment position="end">
                          <Icon
                            onClick={() => setText()}>
                            close
                          </Icon>
                        </InputAdornment>
                      )} />
                  </FormControl>
                </form>
                <div className={style.filters}>
                  <List>
                    <ListItem
                      name="channel.filters"
                      onClick={() => setExpanded(!expanded)}>
                      <ListItemText primary={`Filters (${count})`} />
                      <ListItemIcon>
                        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      in={expanded}
                      onKeyUp={e => (e.key === 'Enter' && onSearch())}>
                      {options
                        .filter(v => !v.hidden)
                        .map(option => (
                          <div key={option.key} className={style.option}>
                            {option.dialog}
                          </div>))}
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        name="channel.search"
                        onClick={() => onSearch()}
                        endIcon={<Icon>search</Icon>}>
                        Search
                      </Button>
                    </Collapse>
                  </List>
                </div>
                <div className={style.chips}>
                  {options
                    .filter(v => values.get(v.value))
                    .map(option => (
                      <Chips
                        key={option.key}
                        option={option}
                        filters={filters}
                        defaults={search.getIn(['defaults', 'chats']) || map()}
                        onToggle={() => onToggle(true)}
                        onDelete={() => onDelete(option)} />))}
                </div>
                <div className={style.results}>
                  {inline.isEmpty() && load &&
                  <CircularProgress />}
                  {inline.isEmpty() && !load &&
                  <div className={cx([style.h4, 'h4', style.open, style.empty])}>
                    Enter a query or apply some filters above to get a list
                    of all messages that match your request.
                  </div>}
                  {!inline.isEmpty() && inline.get('total') === 0 &&
                  <div className={cx([style.h4, 'h4', style.open, style.empty])}>
                    No results found, try changing your search parameters.
                  </div>}
                  {!inline.isEmpty() && inline.get('total') > 0 &&
                  <div>
                    <div>
                      <b>
                        {`Showing ${inline.get('data').size.toLocaleString()} of
                          ${inline.get('total').toLocaleString()} results in this channel`}
                      </b>
                    </div>
                    {inline.get('data').map((v, k) => (
                      <div
                        key={`inline.${v.get('fpid')}`}
                        className={style.result}>
                        <div className={cx([style.h3, style.index])}>{`${k + 1}.`}</div>
                        <div
                          className={cx([
                            style.post,
                            search.getIn(['filters', 'fpid']) === v.getIn(['fpid']).toString() && style.selected])}>
                          <div className={cx([style.h3])}>
                            {`${moment.utc(v.getIn(['sort_date']))
                              .format('MMMM DD, YYYY HH:mm:ss')}`}
                          </div>
                          <div
                            data-for="channel.username.tooltip"
                            data-tip={aliases(v.get('site_actor'))}
                            className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal',
                              style.a, 'a', style.author])}>
                            {Text.Highlight(
                              v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                              v.getIn(['site_actor', 'names', 'handle']) ||
                              v.getIn(['site_actor', 'username']) || '-')}
                          </div>
                          <div dir="auto" className={style.clamp}>
                            {v.getIn(['body', 'text/plain'])
                            && v.getIn(['body', 'text/plain']) !== '[]' &&
                            Text.Highlight(
                              Text.Trim(
                                v.getIn(['highlight', 'body.text/plain', 0]) ||
                                v.getIn(['body', 'text/plain']), 150))}
                            {(!v.getIn(['body', 'text/plain']) || v.getIn(['body', 'text/plain']) === '[]')
                              && !v.has('media_v2') &&
                              <div className={style.placeholder}>This post contains no text.</div>}
                          </div>
                          {v.get('media_v2') && !['webpage'].includes(v.getIn(['media_v2', 'type'])) && v.get('media_v2')
                            .map(media => (
                              <Media
                                mini
                                data={media}
                                postFPID={media.get('fpid')} />))}
                          <Icon
                            className={cx([style.launch])}
                            onClick={e => onRoute(e, v)}>
                            launch
                          </Icon>
                        </div>
                      </div>))}
                  </div>}
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Col>
      </Row>
      <ReactTooltip id="action.tooltip" place="left" effect="solid" />
      <ReactTooltip id="channel.username.tooltip" html place="left" effect="solid" />
    </Grid>);
};

Channels.propTypes = {
  filters: PropTypes.object,
  inline: PropTypes.object,
  meta: PropTypes.object,
  metaData: PropTypes.object,
  prm: PropTypes.object,
  search: PropTypes.object,
  username: PropTypes.string,
};

Channels.defaultProps = {
  filters: map(),
  inline: map(),
  meta: map(),
  metaData: map(),
  prm: list(),
  search: map(),
  username: '',
};

export default Channels;
