import React from 'react';


import { Col } from 'react-flexbox-grid/lib';
import { useRecoilValue } from 'recoil';

import Looker from '../../utils/Looker';
import { getSfid } from '../../../stores/recoil/app';

const AntiPhishingDashboard = () => {
  const sfid = useRecoilValue(getSfid);
  return (
    <Col xs={12}>
      <Looker
        height="110rem"
        dashboard="/dashboards-next/fraudwatch::domain_monitoring_analytics"
        sid={sfid} />
    </Col>
  );
};

export default AntiPhishingDashboard;
