export interface IBookmarkVersion {
  number: number; // Okta ID
  type: string; // SalesForce ID
}
interface IUserBookmarkAccount {
  id: string;
  organization_id: string;
}
export interface IBookmark {
  id?: string;
  labels?: string[];
  name?: string;
  path: string;
  updated_at?: number;
  user_account?: IUserBookmarkAccount;
  version?: IBookmarkVersion;
}

export interface ILegacyBookmark {
  id?: string;
  folders?: string[];
  name?: string;
  path?: string;
  updated?: number;
}

export interface IBookmarkDialog {
  fpid?: string;
  item: IBookmark | undefined;
  operation: 'create' | 'delete' | 'edit';
}

export interface IOktaUser {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: string;
  uid: string;
  scp: string[];
  auth_time: number;
  sub: string;
  sf_id: string;
  roles?: string[];
  groups: string[];
  prm: string[];
  usn: string;
  ffs: string[];
  alerting_roles: string[];
  org_id: number;
  usr_id: string;
  ogn: string;
  not_bf: string;
  id: string;
  email: string;
  fp_uid: number;
  lastLogin: string;
  accessToken: string;
  name: string;
}

export const SearchDatasets = {
  BLOG: { label: 'Blogs', key: 'type', value: 'blog' },
  BOARD: { label: 'Boards', key: 'type', value: 'board' },
  CHAT: { label: 'Chat', key: 'type', value: 'chat' },
  FORUM: { label: 'Forums', key: 'type', value: 'forum' },
  PASTE: { label: 'Paste Sites', key: 'type', value: 'paste' },
  RANSOMWARE: { label: 'Ransomware', key: 'type', value: 'ransomware' },
  SOCIAL: { label: 'Social', key: 'type', mapping: ['gab', 'mastodon', 'reddit'], value: 'social' },
} as const;

export type TBookmarks = IBookmark[];

export type TBookmarkSorts = 'name' | 'updated_at';

export type TLegacyBookmarks = ILegacyBookmark[];
