export default [
  // Top level navigation items must be at top
  { value: 'search',
    nav: 'Advanced',
    path: ['/home/'],
    icon: 'search',
    advanced: true,
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw|edm/ },
  { value: 'sanction',
    nav: 'Sanctions (Internal)',
    path: ['/home/sanctions'],
    icon: 'search',
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'explore',
    nav: 'Explore',
    direct: true,
    path: ['/home/explore'],
    icon: 'explore',
    ui: true,
    test: /ddw/ },
  { value: 'intelligence',
    nav: 'Intelligence',
    path: ['/home/intelligence'],
    icon: 'school',
    ui: true,
    test: /rep|ddw/ },
  { value: 'analytics',
    nav: 'Analytics',
    path: ['/home/analytics'],
    icon: 'assessment',
    ui: true,
    test: /ddw|edm|csb/ },
  { value: 'collections',
    nav: 'Collections',
    path: ['/home/search'],
    icon: 'view_column',
    ui: true,
    test: /chat|frm|mkt|tor|vis|edm|ddw/ },
  { value: 'alerting',
    nav: 'Alerting',
    icon: 'notifications',
    ui: true,
    test: /kp|np|alrt/ },
  { value: 'integrations',
    nav: 'Integrations',
    path: ['/home/integrations'],
    icon: 'settings_ethernet',
    exclude: /api\.prv/,
    test: /ddw/ },
  { value: 'integrations.api',
    nav: 'APIs & Integrations',
    path: ['/home/integrations'],
    icon: 'settings_ethernet',
    test: /api\.prv/ },
  { value: 'labs',
    nav: 'Labs',
    path: ['/home/labs'],
    icon: 'labs_white.svg',
    hoverIcon: 'labs_blue.svg',
    test: /ddw/ },
  { value: 'sanctions',
    nav: 'Sanctions',
    direct: true,
    path: ['/home/sanctions/new'],
    icon: 'vpn_lock',
    ui: true,
    test: /ddw/ },
  { value: 'user',
    nav: 'User',
    icon: 'person',
    lower: true,
    test: /ui|api.prv/ },
  { value: 'resources',
    nav: 'Resources',
    path: ['/home/resources'],
    icon: 'help',
    lower: true,
    test: /ui|api.prv/ },
  { value: 'famicom',
    nav: 'Fams',
    external: true,
    path: ['https://famicom.s.fpint.net'],
    icon: 'videogame_asset',
    lower: true,
    test: /org.fp/ },
  { value: 'famicom',
    nav: 'Fams',
    external: true,
    path: ['https://fams.fp.tools'],
    icon: 'videogame_asset',
    lower: true,
    test: /org.bhvos/ },
  { value: 'admin',
    nav: 'Admin',
    path: ['/home/admin/profile/users', '/home/admin'],
    icon: 'gavel',
    lower: true,
    test: /prf.w/ },
  { value: 'redirect',
    path: ['/home/redirect'],
    test: /acc.ui.dat/ },
  { value: 'person-search.results',
    path: ['home/intelligence/person-search/results'],
    subnav: true,
    test: /dat.poi.rep.r/ },
  { value: 'explore.topics',
    home: true,
    hidden: true,
    parent: 'explore',
    label: 'Topic Library',
    icon: 'library_books',
    alt: 'Topics',
    text: 'Topic Library',
    path: ['/home/explore/topics'],
    ui: true,
    test: /ddw/ },
  { value: 'sanctions.new',
    home: true,
    parent: 'sanctions',
    label: 'Sanctions',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Search',
    path: ['/home/sanctions/new'],
    ui: true,
    test: /ddw/ },
  { value: 'sanction.main',
    home: true,
    parent: 'sanction',
    label: 'Searches',
    icon: 'search',
    alt: 'Sanction',
    text: 'Searches',
    path: ['/home/sanctions/main'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.search',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Search Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Search Results',
    path: ['/home/sanctions/search'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.saved',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Search Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Saved Search Results',
    path: ['/home/sanctions/savedSearch'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.allreports',
    home: true,
    parent: 'sanction',
    label: 'Reports',
    icon: 'search',
    alt: 'Sanction',
    text: 'Reports',
    path: ['/home/sanctions/reports'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.report',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Report Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Report Results',
    path: ['/home/sanctions/report'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'explore.topics.view',
    parent: 'wiki', // needed for userStore.onRoute and bookmark
    label: 'Topic ',
    subnav: true,
    alt: 'Explore - Topic',
    icon: 'library_books',
    text: 'Malware Wiki Data',
    title: ['topic', 'topic_name'],
    path: ['/home/wiki/topic'],
    ui: true,
    test: /ddw/ },
  { value: 'intelligence.finished',
    api: true,
    home: true,
    parent: 'intelligence',
    label: 'All Intelligence',
    icon: 'new_releases',
    alt: 'Reports',
    text: 'The latest finished intelligence published by the Flashpoint SME team.',
    path: ['/home/intelligence/latest'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.standup',
    parent: 'intelligence',
    home: true,
    label: 'Standups',
    alt: 'Reports - Standup',
    icon: 'access_time',
    title: ['reports', 'title'],
    text: 'Daily overview of notable news stories with Flashpoint Intelligence Team commentary.',
    path: ['/home/intelligence/standup'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.actor',
    parent: 'intelligence',
    label: 'Actor Profiles',
    home: true,
    alt: 'Reports - Actor Profiles',
    icon: 'assignment_ind',
    text: 'Intelligence gathered on notable threat actors.',
    path: ['/home/intelligence/actors'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.techintel',
    parent: 'intelligence',
    label: 'Technical Intelligence',
    home: true,
    alt: 'Reports - Technical Intelligence',
    icon: 'no_encryption',
    text: 'Intelligence gathered on indicators of compromise.',
    path: ['/home/intelligence/techintel'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.knowledgebase',
    parent: 'intelligence',
    label: 'Knowledge Base',
    home: true,
    alt: 'Reports - Knowledge Base',
    icon: 'no_encryption',
    text: 'Knowledge Base Data',
    path: ['/home/intelligence/knowledgebase'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.malware',
    parent: 'intelligence',
    label: 'Malware',
    home: true,
    alt: 'Wiki - Malware',
    icon: 'bug_report',
    text: 'Malware Wiki Data',
    path: ['/home/intelligence/malware'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.apt',
    parent: 'intelligence',
    label: 'Advanced Persistent Threats',
    home: true,
    alt: 'Wiki - Advanced Persistent Threats',
    icon: 'bug_report',
    text: 'Advanced Persistent Threats',
    path: ['/home/intelligence/apt'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.rfi',
    parent: 'intelligence',
    label: 'Submit Request For Information (RFI)',
    text: 'Submit Request For Information (RFI)',
    title: ['Submit Request For Information (RFI)'],
    subnav: true,
    subHeader: 'Services',
    column: 'single',
    icon: 'assignment',
    path: ['/home/rfi/form'],
    test: /dat.rfi.w/ },
  { value: 'intelligence.person-search',
    parent: 'intelligence',
    label: 'Person Search',
    text: 'Person Search',
    title: ['Person Search'],
    subnav: true,
    subHeader: 'Services',
    column: 'single',
    path: ['/home/intelligence/person-search/form'],
    test: /dat.poi.rep.req/ },
  { value: 'wiki.malware.view',
    parent: 'wiki', // needed for userStore.onRoute and bookmark
    label: 'Malware',
    subnav: true,
    alt: 'Wiki - Malware',
    icon: 'bug_report',
    text: 'Malware Wiki Data',
    title: ['malware', 'malware_family_name'],
    path: ['/home/wiki/malware'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'wiki.apt.view',
    parent: 'wiki', // needed for userStore.onRoute and bookmark
    label: 'Advanced Persistent Threats',
    subnav: true,
    alt: 'Wiki - Advanced Persistent Threats',
    icon: 'bug_report',
    text: 'Advanced Persistent Threats',
    title: ['malware', 'malware_family_name'],
    path: ['/home/wiki/apt'],
    ui: true,
    test: /rep|ddw/ },
  // integrations | api
  { value: 'integrations.types',
    parent: 'integrations|integrations.api',
    label: 'All',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    icon: 'person',
    path: ['/home/integrations/all'],
    test: /ddw/ },
  { value: 'integrations.analysis',
    parent: 'integrations|integrations.api',
    label: 'Analysis',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - Analysis',
    icon: 'no_encryption',
    path: ['/home/integrations/analysis'],
    test: /ddw/ },
  { value: 'integrations.digitalrisk',
    parent: 'integrations|integrations.api',
    label: 'Digital Risk',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - Digital Risk',
    icon: 'no_encryption',
    path: ['/home/integrations/digital'],
    test: /ddw/ },
  { value: 'integrations.orchestration',
    parent: 'integrations|integrations.api',
    label: 'Orchestration',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - Orchestration',
    icon: 'no_encryption',
    path: ['/home/integrations/orchestration'],
    test: /ddw/ },
  { value: 'integrations.productivity',
    parent: 'integrations|integrations.api',
    label: 'Productivity',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - Productivity',
    icon: 'no_encryption',
    path: ['/home/integrations/productivity'],
    test: /ddw/ },
  { value: 'integrations.siem',
    parent: 'integrations|integrations.api',
    label: 'SIEM',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - SIEM',
    icon: 'no_encryption',
    path: ['/home/integrations/siem'],
    test: /ddw/ },
  { value: 'integrations.tip',
    parent: 'integrations|integrations.api',
    label: 'TIP',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - TIP',
    icon: 'no_encryption',
    path: ['/home/integrations/tip'],
    test: /ddw/ },
  { value: 'integrations.vulnerabilities',
    parent: 'integrations|integrations.api',
    label: 'Vulnerabilities',
    subHeader: 'Integrations',
    dropdown: true,
    home: true,
    alt: 'Integrations - Vulnerabilities',
    icon: 'bug_report',
    path: ['/home/integrations/vulnerabilities'],
    test: /ddw/ },
  { value: 'integrations.tokens',
    parent: 'integrations|integrations.api',
    label: 'Manage API Tokens',
    subHeader: 'API',
    dropdown: true,
    home: true,
    icon: 'network_check',
    path: ['/home/integrations/tokens'],
    test: /api.prv/ },
  { value: 'integrations.taxii',
    parent: 'integrations|integrations.api',
    label: 'Manage TAXII Creds',
    subHeader: 'API',
    dropdown: true,
    home: true,
    icon: 'network_check',
    path: ['/home/integrations/taxii'],
    test: /taxii-endpoints/ },
  { value: 'integrations.ccmc',
    parent: 'integrations|integrations.api',
    label: 'CCM-C Demo',
    subHeader: 'CCM-C',
    dropdown: true,
    icon: 'network_check',
    path: ['/home/integrations/ccmc'],
    test: /demo.ccm.c/ },
  { value: 'integrations.ccm.webhook',
    parent: 'integrations|integrations.api',
    label: 'CCM-C Webhook',
    subHeader: 'CCM-C',
    dropdown: true,
    icon: 'network_check',
    path: ['/home/integrations/webhook/ccmc'],
    test: /ui.ccmc.web/ },

  // analytics
  { value: 'analytics.home',
    parent: 'analytics',
    home: true,
    label: 'Home',
    alt: 'Dashboard - Home',
    icon: 'home',
    text: 'Updates to saves searches, bookmarks and reports based on your interests',
    path: ['/home/analytics/home'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.expodash',
    parent: 'analytics',
    home: true,
    label: 'Home - Beta',
    alt: 'Dashboard - Home - Beta',
    icon: 'home',
    text: '',
    path: ['/home/analytics/expodash'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.cves',
    parent: 'analytics',
    home: true,
    settings: true,
    label: 'CVEs',
    alt: 'Dashboard - CVEs',
    icon: 'bug_report',
    text: 'Identify recently released cves and the threat actor discussions about them',
    path: ['/home/analytics/cves'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'analytics.fraud',
    parent: 'analytics',
    home: true,
    settings: true,
    label: 'Card Shops',
    alt: 'Dashboard - Card Shops',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    path: ['/home/analytics/fraud'],
    ui: true,
    test: /cfm|csb/ },
  { value: 'analytics.iocs',
    parent: 'analytics',
    home: true,
    label: 'IOCs',
    alt: 'Dashboard - IOCs',
    icon: 'bug_report',
    text: 'Identify IOCs',
    path: ['/home/analytics/iocs'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.breach',
    parent: 'analytics',
    home: true,
    label: 'Breaches',
    alt: 'Dashboard - Breaches',
    icon: 'lock_open',
    text: 'Identify Breaches',
    path: ['/home/analytics/breach'],
    ui: true,
    test: /ddw|edm/ },
  { value: 'analytics.credbreaches',
    parent: 'analytics',
    home: true,
    label: 'Credential Breaches - Internal Beta',
    alt: 'Dashboard - Credential Breaches',
    icon: 'lock_open',
    text: 'Identify Credential Breaches',
    path: ['/home/analytics/credbreaches'],
    ui: true,
    internal: true, // org.fp.r permission required in addition to test flag
    test: /dat\.edm(\.org)?\.r/ },
  { value: 'analytics.uas',
    parent: 'analytics',
    home: true,
    settings: false,
    label: 'UAS Leak',
    alt: 'Dashboard - UAS Leak',
    icon: 'bug_report',
    text: 'UAS Leak',
    path: ['/home/analytics/uasleak'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.ukraine-crisis',
    parent: 'analytics',
    home: true,
    settings: false,
    label: 'Ukraine Crisis',
    alt: 'Dashboard - Ukraine Crisis',
    text: 'Ukraine Crisis',
    path: ['/home/analytics/ukraine-crisis'],
    ui: true,
    test: /ddw/ },
  // Analytics/Assets
  { value: 'analytics.domain',
    parent: 'analytics|analytics.assets',
    home: true,
    column: 'single',
    subHeader: 'Assets',
    label: 'Enterprise Credentials',
    alt: 'Dashboard - Enterprise Credentials',
    icon: 'public',
    text: 'Identify Enterprise Credentials',
    path: ['/home/analytics/domain'],
    ui: true,
    test: /edm/ },
  { value: 'analytics.exposure',
    parent: 'analytics|analytics.assets',
    home: true,
    column: 'single',
    subHeader: 'Assets',
    label: 'My Credential Exposure - Internal Beta',
    alt: 'Dashboard - My Credential Exposure',
    icon: 'public',
    text: 'Identify My Credential Exposure',
    path: ['/home/analytics/exposure'],
    ui: true,
    internal: true, // org.fp.r permission required in addition to test flag
    test: /dat\.edm(\.org)?\.r/ },
  { value: 'analytics.domainmonitoring',
    parent: 'analytics|analytics.assets',
    home: true,
    column: 'single',
    hidden: false,
    subHeader: 'Assets',
    label: 'Domain Monitoring',
    alt: 'Dashboard - Domain Monitoring',
    icon: 'home',
    path: ['/home/analytics/monitoring'],
    test: /dat.dm.r/ },
  // Analytics / CFM
  { value: 'analytics.cfm.mycards',
    parent: 'analytics',
    subHeader: 'Card Fraud Mitigation',
    home: true,
    label: 'Card Shops',
    alt: 'CFM - Card Shops',
    icon: 'public',
    text: 'Card Shops',
    path: ['/home/analytics/mycards', '/home/analytics/cfm/mycards'],
    test: /cfm/ },
  { value: 'analytics.cfm.fullcards',
    parent: 'analytics',
    subHeader: 'Card Fraud Mitigation',
    home: true,
    label: 'Full Cards',
    alt: 'CFM - Full Cards',
    icon: 'public',
    text: 'Full Cards',
    path: ['/home/analytics/fullcards', '/home/analytics/cfm/fullcards'],
    test: /cfm/ },
  { value: 'analytics.cfm.merchants',
    parent: 'analytics',
    subHeader: 'Card Fraud Mitigation',
    home: true,
    label: 'High-Risk Merchants',
    alt: 'CFM - High-Risk Merchants',
    icon: 'public',
    text: 'High-Risk Merchants',
    path: ['/home/analytics/merchants', '/home/analytics/cfm/merchants'],
    test: /dat.cfm.hrm.r/ },
  { value: 'analytics.cfm.manage',
    parent: 'analytics',
    subHeader: 'Card Fraud Mitigation',
    home: true,
    label: 'Manage Bins',
    alt: 'CFM - Manage Bins',
    icon: 'public',
    text: 'Manage Bins',
    path: ['/home/analytics/managebins', '/home/analytics/cfm/managebins'],
    test: /cfm/ },
  { value: 'api.cfm.export',
    label: 'Card Fraud Mitigation',
    api: true,
    test: /cfm/ },
  // Ransomware
  { value: 'analytics.ransomware.ransomware',
    parent: 'analytics',
    subHeader: 'Ransomware',
    column: 'single',
    home: true,
    label: 'Ransomware',
    alt: 'Ransomware',
    icon: 'public',
    text: 'Ransomware',
    path: ['/home/analytics/ransomware'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.ransomware.victims',
    parent: 'analytics',
    subHeader: 'Ransomware',
    column: 'single',
    home: true,
    label: 'Victims',
    alt: 'Victims',
    icon: 'public',
    text: 'Victims',
    path: ['/home/analytics/victims'],
    ui: true,
    test: /ddw/ },
  { value: 'analytics.ransomware.ransomers',
    parent: 'analytics',
    subHeader: 'Ransomware',
    column: 'single',
    home: true,
    label: 'Ransomers',
    alt: 'Ransomers',
    icon: 'public',
    text: 'Ransomers',
    path: ['/home/analytics/ransomers'],
    ui: true,
    test: /ddw/ },

  { value: 'collections.cards.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    path: ['/home/shops/cards/items', '/home/ddw/cards/items'],
    label: 'Card',
    icon: 'credit_card',
    title: ['cards', 'title'],
    ui: true,
    test: /crd|csb/ },
  { value: 'collections.forums.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'Thread',
    icon: 'forums',
    path: ['/home/ddw/forums/threads', '/home/forums/threads'],
    aliases: ['collections.forums.view', 'collection.post.view'],
    ui: true,
    test: /for|ddw/ },
  { value: 'collections.chats.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'Channel',
    icon: 'chat',
    title: ['meta', 'channels', 'title'],
    path: ['/home/ddw/chats/channels'],
    aliases: ['collections.chats.view', 'collection.channels.view'],
    ui: true,
    exclude: /_no\.chat/,
    test: /chat|ddw/ },
  { value: 'collections.cves.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    label: 'Vulnerability (CVE)',
    icon: 'bug_report',
    title: ['cves', 'title'],
    path: ['/home/technical_data/cves/items', '/home/ddw/cves/items'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'collections.exploits.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    label: 'Exploits',
    icon: 'bug_report',
    title: ['cves', 'title'],
    path: ['/home/technical_data/exploits/items'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'collections.iocs.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    label: '',
    icon: 'bug_report',
    title: ['iocs', 'Event', 'title'],
    path: ['/home/technical_data/iocs/items'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.marketplaces.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    path: ['/home/shops/marketplaces/items', '/home/ddw/marketplaces/items', '/home/ddw/markets/items/'],
    label: 'Marketplace',
    title: ['marketplaces', 'title'],
    icon: 'shopping_cart',
    ui: true,
    test: /mkt|csb/ },
  { value: 'collections.reports.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'Report',
    title: ['reports', 'title'],
    icon: 'lightbulb_outline',
    path: ['/home/intelligence/reports/report', '/home/intelligence/reports'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'collections.credentials.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    label: 'Enterprise Credentials',
    title: ['credentials', 'title'],
    icon: 'email',
    path: ['/home/compromised/credentials/items'],
    ui: true,
    test: /edm/ }, // look for base prms
  { value: 'collections.customer-credentials.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    label: 'Customer Credentials',
    title: ['credentials', 'title'],
    icon: 'email',
    path: ['/home/compromised/customer-credentials/items'],
    ui: true,
    test: /dat.ccm.cus.r/ }, // ccmc prms
  { value: 'collections.pastes.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    path: ['/home/ddw/pastes/items'],
    label: 'Paste',
    title: ['pastes', 'title'],
    icon: 'assignment',
    ui: true,
    test: /ddw/ },
  { value: 'collections.blogs.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    path: ['/home/ddw/blogs/posts', '/home/ddw/blogs/items'],
    label: 'Post',
    title: ['meta', 'posts', 'container', 'title'],
    icon: 'web',
    ui: true,
    test: /ddw/ },
  { value: 'collections.ransomware.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    path: ['/home/ddw/ransomware/items', '/home/ddw/ransomwares/items'],
    label: 'Release',
    title: ['ransomware', 'title'],
    icon: 'lock',
    ui: true,
    test: /ddw/ },
  { value: 'collections.boards.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'Board',
    icon: 'view_agenda',
    title: ['meta', 'board', 'container', 'container', 'title'],
    path: ['/home/ddw/boards/board'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.social.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'News',
    icon: 'people',
    title: ['meta', 'news', 'title'],
    path: ['/home/ddw/social/news', '/home/ddw/gab/pages'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.twitter.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    sidebar: true,
    label: 'Twitter',
    icon: 'twitter',
    title: ['meta', 'twitter', 'title'],
    path: ['/home/ddw/twitter/tweet'],
    ui: true,
    test: /twtr/ },
  { value: 'collections.accounts.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    path: ['/home/shops/accounts/items', '/home/ddw/accounts/items'],
    label: 'Account',
    icon: 'account_balance',
    title: ['accounts', 'account_organization'],
    ui: true,
    test: /ddw/ },

  { value: 'collections.assets.domain-monitoring.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    path: ['/home/assets/domain-monitoring/items'],
    subHeader: 'Assets',
    label: 'Domain Monitoring',
    title: ['Domain Monitoring'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'collections.assets.media.view',
    parent: 'collections',
    hidden: true,
    subnav: true,
    path: ['/home/assets/media/items'],
    icon: 'media',
    label: 'Images',
    title: ['images', 'filename'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'collections.dmtm.incidents.view',
    parent: 'collections.dmtm',
    hidden: true,
    subnav: true,
    path: ['/home/dmtm/incidents/items', '/home/assets/domain-monitoring/items'],
    subHeader: 'Domain Monitoring',
    label: 'Incidents',
    title: ['Incidents'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },

  { value: 'admin',
    parent: 'admin',
    label: 'Users',
    icon: 'gavel',
    placeholder: 'e.g. search username',
    text: 'Search across Flashpoint\'s users by user id and manage their user preferences',
    path: ['/home/profile/admin/users', '/home/admin/users'],
    test: /prf.w/ },
  { value: 'alerting.analytics',
    parent: 'alerting',
    label: 'Overview',
    icon: 'assessment',
    text: 'Alerting Overview',
    home: true,
    settings: true,
    path: ['/home/alerting/overview'],
    test: /dat.alrt.org/ },
  { value: 'alerting.alerts',
    parent: 'alerting',
    label: 'Alerts',
    icon: 'person',
    text: 'Alerting Alerts',
    home: true,
    path: ['/home/alerting/alerts'],
    ui: true,
    test: /dat.alrt.org/ },
  { value: 'alerting.dea',
    parent: 'alerting',
    home: true,
    label: 'Data Exposure Alerts',
    icon: 'person',
    text: 'Data Exposure Alerts',
    searchType: 'code_repository',
    path: ['/home/alerting/dea'],
    ui: true,
    test: /dea/ },
  { value: 'alerting.keywords',
    parent: 'alerting',
    home: true,
    label: 'Keywords',
    icon: 'mail',
    text: 'Alerting Keywords',
    path: ['/home/alerting/keywords'],
    ui: true,
    test: /kw/ },
  { value: 'alerting.recipients',
    parent: 'alerting',
    home: true,
    label: 'Recipients',
    icon: 'person',
    text: 'Alerting Recipients',
    path: ['/home/alerting/recipients'],
    ui: true,
    test: /np/ },

  { value: 'user.searches',
    parent: 'user',
    home: true,
    label: 'Saved Searches',
    icon: 'save',
    path: ['/home/manage/searches'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'user.bookmarks',
    parent: 'user',
    home: true,
    label: 'Bookmarks',
    icon: 'bookmark_border',
    path: ['/home/manage/bookmarks'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'user.settings',
    parent: 'user',
    home: true,
    label: 'Settings',
    icon: 'settings',
    lower: true,
    path: ['/home/manage/user/settings', '/home/help/settings'],
    ui: true,
    test: /acc.ui.dat/ },
  { value: 'user.logout',
    parent: 'user',
    label: 'Logout',
    icon: 'exit_to_app',
    lower: true,
    test: /ui|api.prv/ },

  { value: 'resources.updates',
    parent: 'resources',
    label: 'Release Notes',
    text: 'Release Notes',
    icon: 'new_releases',
    path: ['/home/resources/updates'],
    test: /ui/ },
  { value: 'resources.glossary',
    parent: 'resources',
    label: 'Glossary',
    icon: 'school',
    text: 'A compendium of underground terminology with descriptions penned by Flashpoint SMEs.',
    path: ['/home/resources/glossary'],
    test: /rep|ddw/ },
  { value: 'resources.syntax',
    parent: 'resources',
    label: 'Query Syntax',
    icon: 'search',
    text: 'Query Syntax',
    path: ['/home/resources/syntax'],
    test: /ui/ },
  { value: 'resources.links',
    parent: 'resources',
    label: 'Documentation',
    icon: 'link',
    text: 'Documentation',
    path: ['/home/resources/links'],
    test: /ui|api.prv/ },
  { value: 'resources.feedback',
    parent: 'resources',
    label: 'Provide Feedback',
    icon: 'feedback',
    lower: true,
    text: 'Provide Feedback',
    test: /ui/ },

  { value: 'search.all',
    parent: 'collections',
    label: 'All',
    search: true,
    all: true,
    searchType: 'all',
    home: true,
    hidden: true,
    sidebar: true,
    icon: 'search',
    text: 'Search through all datasets available to Flashpoint',
    alt: 'Search - All',
    placeholder: 'e.g. trickbot, threat landscape, thedarkoverlord, fullz, "yourdomain.com"',
    path: ['/home/search/all'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'intelligence.reports',
    parent: 'intelligence',
    label: 'Intelligence',
    search: true,
    searchType: 'reports',
    home: true,
    sidebar: true,
    icon: 'lightbulb_outline',
    text: 'Search from among over 10,000 published finished intelligence reports.',
    alt: 'Search - Intelligence',
    placeholder: 'e.g. trickbot, threat landscape, thedarkoverlord, fullz, "yourdomain.com"',
    path: ['/home/intelligence/reports/search'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'search.communities',
    parent: 'collections',
    label: 'All Communities',
    search: true,
    all: true,
    hidden: true,
    searchType: 'communities',
    subHeader: 'Communities',
    dropdown: true,
    home: true,
    sidebar: true,
    icon: 'search',
    text: 'Search across all of Flashpoint\'s communities.',
    alt: 'Search - Communities',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/communities'],
    ui: true,
    test: /for|chat|mkt|ddw/ },
  { value: 'search.forums',
    parent: 'collections',
    label: 'Forums',
    api: true,
    search: true,
    searchType: 'forums',
    subHeader: 'Communities',
    dropdown: true,
    home: true,
    sidebar: true,
    icon: 'forums',
    text: 'Search across over five hundred Deep & Dark Web communities.',
    alt: 'Search - Communities > Forums',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/forums'],
    ui: true,
    test: /for|ddw/ },
  { value: 'search.chats',
    parent: 'collections',
    label: 'Chat Services',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'chats',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'chat',
    text: 'Research data from threat actor communities established on chat services.',
    alt: 'Search - Communities > Chat',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/chats'],
    ui: true,
    exclude: /_no\.chat/,
    test: /chat|ddw/ },
  { value: 'search.blogs',
    parent: 'collections',
    label: 'Blogs',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'blogs',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'web',
    text: 'Search across threat actors and security research sites',
    alt: 'Search - Communities > Blogs',
    placeholder: 'e.g. search blog post text',
    path: ['/home/search/blogs'],
    ui: true,
    test: /ddw/ },
  { value: 'search.ransomware',
    parent: 'collections',
    label: 'Ransomware',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'ransomware',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'web',
    text: 'Search across threat actors and security research sites',
    alt: 'Search - Communities > Ransomware',
    placeholder: 'e.g. search ransomware post text',
    path: ['/home/search/ransomware', '/home/search/ransomwares'],
    ui: true,
    test: /ddw/ },
  { value: 'search.pastes',
    parent: 'collections',
    label: 'Paste Sites',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchBar: 'Paste Sites',
    searchType: 'pastes',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'assignment',
    text: 'Search from among the top active and archived paste sites.',
    alt: 'Search - Communities > Pastes',
    path: ['/home/search/pastes'],
    placeholder: 'e.g. search paste sites text',
    ui: true,
    test: /ddw/ },
  { value: 'search.boards',
    parent: 'collections',
    label: 'Boards',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'boards',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'view_agenda',
    text: 'Search within 4chan and 8chan communities',
    alt: 'Search - Communities > Boards',
    path: ['/home/search/boards'],
    placeholder: 'e.g. search chan text',
    ui: true,
    test: /ddw/ },
  { value: 'search.social',
    parent: 'collections',
    label: 'Social News',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'social',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'people',
    text: 'Search within social news communities',
    alt: 'Search - Communities > Social News',
    path: ['/home/search/social', '/home/search/gab'],
    placeholder: 'e.g. search social news text',
    ui: true,
    test: /ddw/ },
  { value: 'search.twitter',
    parent: 'collections',
    label: 'Twitter',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'twitter',
    subHeader: 'Communities',
    dropdown: true,
    icon: 'twitter',
    text: 'Twitter',
    alt: 'Search - Communities > Twitter',
    placeholder: 'e.g. twitter',
    path: ['/home/search/twitter'],
    ui: true,
    test: /twtr/ },
  { value: 'collections.dmtm.incidents',
    parent: 'collections|collections.dmtm',
    subHeader: 'Domain Monitoring',
    label: 'Incidents',
    title: ['Incidents'],
    path: ['/home/dmtm/incidents', '/home/assets/domain-monitoring'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'collections.dmtm.monitored-urls',
    parent: 'collections|collections.dmtm',
    path: ['/home/dmtm/monitored-urls'],
    subHeader: 'Domain Monitoring',
    label: 'Monitored URLs',
    title: ['Monitored URLs'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'search.credentials',
    parent: 'collections',
    label: 'Enterprise Credentials',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'credentials',
    subHeader: 'Assets',
    dropdown: true,
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised enterprise credentials',
    alt: 'Search - Assets > Enterprise Credentials',
    path: ['/home/search/credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /edm/ },
  { value: 'search.media',
    parent: 'collections',
    label: 'All Media',
    search: true,
    all: true,
    hidden: true,
    searchType: 'media',
    subHeader: 'Communities',
    dropdown: true,
    home: true,
    sidebar: true,
    icon: 'media',
    text: 'Search across all of Flashpoint\'s media.',
    alt: 'Search - Media',
    placeholder: 'e.g. image phash and video subtitles',
    path: ['/home/search/media'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.images',
    parent: 'collections',
    label: 'Images',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    dropdown: true,
    searchType: 'images',
    subHeader: 'Media',
    icon: 'media',
    text: 'Media content',
    alt: 'Search - Media > Images',
    placeholder: 'e.g. image phash',
    path: ['/home/search/images'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.videos',
    parent: 'collections',
    label: 'Videos',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    dropdown: true,
    searchType: 'videos',
    subHeader: 'Media',
    icon: 'media',
    text: 'Media content',
    alt: 'Search - Media > Videos',
    placeholder: 'e.g. video subtitles',
    path: ['/home/search/videos'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.marketplaces',
    parent: 'collections',
    label: 'Marketplaces',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    dropdown: true,
    searchType: 'marketplaces',
    subHeader: 'Shops',
    icon: 'shopping_cart',
    text: 'Search from among the top active and archived Deep & Dark Web marketplaces.',
    alt: 'Search - Shops > Marketplaces',
    placeholder: 'e.g. narcotics, fullz, guides, skimmers',
    path: ['/home/search/marketplaces'],
    ui: true,
    test: /mkt|ddw/ },
  { value: 'search.cards',
    parent: 'collections',
    label: 'Card Shops',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'cards',
    subHeader: 'Shops',
    dropdown: true,
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    alt: 'Search - Shops > Card Shops',
    path: ['/home/search/cards'],
    placeholder: 'e.g. search card',
    ui: true,
    test: /csb/ },
  { value: 'search.accounts',
    parent: 'collections',
    label: 'Account Shops',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'accounts',
    subHeader: 'Shops',
    dropdown: true,
    icon: 'account_balance',
    alt: 'Search - Shops > Account Shops',
    placeholder: 'domains or online services e.g. outlook, ebay, paypal',
    path: ['/home/search/accounts'],
    ui: true,
    test: /ddw/ },
  { value: 'search.cves',
    parent: 'collections',
    label: 'Vulnerabilities: CVEs',
    api: true,
    taxii: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'cves',
    subHeader: 'Technical Data',
    dropdown: true,
    icon: 'bug_report',
    text: 'Identify recently released vulnerabilities',
    alt: 'Search - Technical Data > Vulnerabilities: CVEs',
    path: ['/home/search/cves'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'search.exploits',
    parent: 'collections',
    label: 'Exploits',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'exploits',
    subHeader: 'Technical Data',
    dropdown: true,
    icon: 'bug_report',
    text: 'Identify recently released exploits',
    alt: 'Search - Technical Data > Exploits',
    path: ['/home/search/exploits'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'search.iocs',
    parent: 'collections',
    label: 'IOCs',
    api: true,
    taxii: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'iocs',
    subHeader: 'Technical Data',
    dropdown: true,
    icon: 'bug_report',
    text: 'Indicator database',
    alt: 'Search - Technical Data > IOCs',
    path: ['/home/search/iocs'],
    placeholder: 'e.g. "cobaltstrike"',
    ui: true,
    test: /ddw/ },
  { value: 'search.customer-credentials',
    parent: 'collections',
    label: 'Customer Credentials',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    searchType: 'customer-credentials',
    subHeader: 'Assets',
    dropdown: true,
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised customer credentials',
    alt: 'Search - Assets > Customer Credentials',
    path: ['/home/search/customer-credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /dat.ccm.cus.r/ },
  { value: 'search.tagging.channels',
    parent: 'collections',
    label: 'Telegram Sources',
    sidebar: true,
    search: false,
    subHeader: 'Analyst Annotations',
    icon: 'chat',
    path: ['/home/tagging/channels'],
    ui: true,
    test: /meta.chat.w/ },

  { value: 'api.ccm.c',
    label: 'CCM-C',
    api: true,
    test: /ccm.cus/ },


  { value: 'labs.info',
    parent: 'labs',
    desc: true,
    hidden: true,
    label: 'Labs is a dynamic and experimental environment for Flashpoint analysts to share internal dashboards and insights from Flashpoint collections and beyond.',
    alt: 'Dashboard - Labs - Info',
    path: [],
    test: /ddw/ },

  { value: 'labs.bitcoin',
    parent: 'labs',
    home: true,
    label: 'Bitcoin',
    alt: 'Dashboard - Bitcoin',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/bitcoin', '/home/labs/bitcoin'],
    ui: true,
    test: /ddw/ },

  { value: 'labs.botshops',
    parent: 'labs',
    home: true,
    label: 'Bot Shops',
    alt: 'Dashboard - Bot Shops',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/botshops', '/home/labs/botshops'],
    ui: true,
    test: /ddw/ },
  { value: 'labs.access-auctions',
    parent: 'labs',
    home: true,
    label: 'Access Auctions',
    alt: 'Dashboard - Access Auctions',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/access-auctions', '/home/labs/access-auctions'],
    ui: true,
    test: /ddw/ },
];
