import * as React from 'react';
import { CountWidget } from '../CountWidget';
import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import type { ResultCountPreferences } from '../../../../../core/types/ExpoDashPreferences';
import Query from './query';

export interface ActorCountWidgetProps {
  config: ResultCountPreferences,
  filters: ExpoDashFilters,
  /** Mouse click handler */
  setDialog: (value: object) => void,
  onClick?: (defaultSearch: boolean) => void,
}

export const ActorCountWidget = (props: ActorCountWidgetProps) => (
  <CountWidget
    label="Actor Count"
    tooltip="Displays the number of actors posting about your saved search"
    query={Query.Results}
    {...props}
    />
);

ActorCountWidget.defaultProps = {
  onClick: null,
};
