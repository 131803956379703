import qs from 'qs';
import { Map as map } from 'immutable';
import { createBrowserHistory } from 'history';

let Common;
import('./common').then((res) => {
  Common = res.default;
});
let Api;
import('./api').then((res) => {
  Api = res.default;
});

const referrer = null;

const history = createBrowserHistory({
  // ----- createBrowserHistory options ----- //
  // basename: The base URL of the app
  // basename: "",
  // Set true to force full page refreshes
  // forceRefresh: false,
  // The length of location.key
  // keyLength: 6,
  // A function to use to confirm navigation with the user (see below)
  // getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

const createQueryObject = (search) => {
  const query = {};
  const searchParams = new URLSearchParams(search);

  for (const params of searchParams) {
    const [key, value] = params;
    query[String(key)] = value;
  }
  return query;
};

// If the path has changed or any of the non empty query parameters have changed
const checkRouteChange = (prevState, nextState) =>
  Boolean(nextState.pathname !== prevState.pathname ||
    !map(nextState.query)
      .filter(v => v)
      .equals(map(prevState.query).filter(v => v)));

const buildSearchString = (query) => {
  if (!query) return '';

  // PLATFORM-966
  // RFC-1738 `space` encodes to `+` in place of `%20`
  return qs.stringify(query, { format: 'RFC1738', addQueryPrefix: true, encodeValuesOnly: true });
};

const removeSearchString = (preserve = []) => {
  const { location } = history;
  if (!location.search) return;

  let newQueryStr = '';

  if (preserve.length > 0) {
    const queryData = {};
    const queryStringObj = createQueryObject(location.search);
    preserve.forEach((v) => {
      queryData[String(v)] = queryStringObj[String(v)];
    });
    newQueryStr += buildSearchString(queryData);
  }

  history.replace(`${location.pathname}${newQueryStr}`);
};

// override default push method to allow query object
const push = (route = {}) => {
  const pathname = route?.pathname || route;
  const query = buildSearchString(route?.query || {});
  const hash = route?.hash || '';
  const location = `${pathname}${query}${hash}`;
  history.push(location);
};

// override default replace method to allow query object
const replace = (route = {}) => {
  const pathname = route?.pathname || route;
  const query = buildSearchString(route?.query || {});
  const hash = route?.hash || '';
  const location = `${pathname}${query}${hash}`;
  history.replace(location);
};

// update url path without re-render
const update = (route = {}) => {
  const pathname = route?.pathname || route;
  const query = buildSearchString(route?.query || {});
  const hash = route?.hash || '';
  const location = `${pathname}${query}${hash}`;
  window.history.pushState({ path: location }, '', location);
};

const getCurrentLocation = () => ({
  ...history.location,
  query: qs.parse(history.location.search, { ignoreQueryPrefix: true }),
});

const navigateTo = (route = {}, forceNewTab = false) => {
  const { pathname } = getCurrentLocation();
  const hash = route.hash || '';
  const search = buildSearchString(route.query);
  const external = Common?.Events?.DispatchExternalLinkEvent(route);
  const tab = (sessionStorage && (sessionStorage.getItem('navigation') === 'tab' || sessionStorage.getItem('clickNewTab') === 'true')) || forceNewTab;
  const location = `${route?.pathname || route}${search}${hash}`;
  const cancel = checkRouteChange(getCurrentLocation(), route);
  const changed = route.pathname !== pathname;

  if (external) return;
  if (cancel) Api?.cancelRequests();
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  if (tab && changed) window.open(location, '_blank');
  else history.push(location);
};

const replaceSearchString = (query = {}, preserve = []) => {
  let queryData = {};
  const { location } = history;

  if (location.search || Object.keys(query).length > 0) {
    const queryStringObj = createQueryObject(location.search);
    queryData = { ...queryStringObj, ...query };

    if (preserve) {
      preserve.forEach((v) => {
        queryData[String(v)] = queryStringObj[String(v)];
      });
    }
  }

  if (location.search !== buildSearchString(queryData)) {
    navigateTo(location.pathname + buildSearchString(queryData));
  }
};

export default {
  ...history,
  referrer,
  push,
  replace,
  update,
  buildSearchString,
  createQueryObject,
  getCurrentLocation,
  navigateTo,
  replaceSearchString,
  removeSearchString,
};
