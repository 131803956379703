import { blue, darkBlue, white } from './colors';

export const Typography = {
  colors: {
    body: darkBlue[800],
    button: white,
    caption: darkBlue[600],
    link: blue[600],
  },
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Hind", sans-serif',
  },
  letterSpacing: {
    body1: '.15px',
    body2: '.25px',
    button: '1.25px',
    caption: '.4px',
    glossaryLink: '.5px',
    heading1: '1.5px',
    heading2: '.25px',
    heading3: '0',
    heading4: '.15px',
    link: '.5px',
    overline: '1.5px',
    subtitle1: '.15px',
    subtitle2: '.1px',
  },
  lineHeights: {
    body: '1.5',
    heading: '1.25',
  },
  sizes: {
    body1: '1rem',
    body2: '.875rem',
    button: '.875rem',
    caption: '.75rem',
    glossaryLink: '.875rem',
    heading1: '3.25rem',
    heading2: '2.375rem',
    heading3: '1.5rem',
    heading4: '1.25rem',
    link: '1rem',
    overline: '.625rem',
    subtitle1: '1.0625rem',
    subtitle2: '.9375rem',
  },
  weights: {
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
};
