import * as React from 'react';
import { BasicMenu } from '../../../../utils/BasicMenu';
import { SavedSearch } from '../../../../../core/types/SavedSearch';

type updateSavedSearchFn = (newSavedSearch: SavedSearch) => void;
interface SavedSearchesMenuProps {
  /** User preferences object */
  rows: SavedSearch[];
  savedSearch: SavedSearch;
  updateSavedSearch: updateSavedSearchFn;
}

export const SavedSearchesMenu = ({
  rows,
  savedSearch,
  updateSavedSearch,
}: SavedSearchesMenuProps): JSX.Element => {
  const handleListItemClick = (row: SavedSearch): void => {
    updateSavedSearch(row);
  };

  return (
    <>
      {rows && rows.length > 0 ?
        <BasicMenu
          items={rows}
          getItemText={(r:SavedSearch) => r.name}
          selectedItem={savedSearch}
          defaultText="Select a Saved Search..."
          onClickMenuItem={handleListItemClick}
        />
        : <div>You have no saved searches.</div>
      }
    </>
  );
};
