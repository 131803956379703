import PropTypes from 'prop-types';
import * as React from 'react';

import { Icon } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { fromJS, List as list, Map as map } from 'immutable';

import { SaveAlertDialog } from './SaveAlertDialog';
import Query from '../../../containers/Alerting/query';
import { UserContext } from '../Context';
import AlertingStore from '../../../stores/recoil/alerting';

export const SaveAlertIcon = ({ filters, data }) => {
  const [open, setOpen] = React.useState(false);
  const [keywordClasses, setKeywordClasses] = React.useState();
  const [notificationProfiles, setNotificationProfiles] = React.useState(list());
  const ownerId = useRecoilValue(AlertingStore.ownerId);

  const user = React.useContext(UserContext);
  const onClose = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    Query.load('keyword_classes').then((res) => {
      setKeywordClasses(fromJS(res.keywordClasses
        .filter((v) => {
            if (/bin/ig.test(v.get('name')) && !user.get('prm').some(p => /csb/.test(p))) return false;
            if (/internet_infrastructure/ig.test(v.get('name'))) return false;
            if (/cloud_infrastructure/ig.test(v.get('name'))) return false;
            if (/email_domain/ig.test(v.get('name'))) return false;
            if (/enterprise_domain/ig.test(v.get('name'))) return false;
            if (/twitter/ig.test(v.get('name'))) return false;
            if (/cookie_domain/ig.test(v.get('name'))) return false;
            if (/enterprise_affected_domain/ig.test(v.get('name'))) return false;
            return true;
        }))); });
  }, []);

  const loadNotificationProfiles = async (_ownerId) => {
    Query.load('products').then((res) => {
      Query.loadNotificationProfiles(_ownerId, fromJS(res))
      .then((_res) => {
        setNotificationProfiles(_res?.get('data'));
      })
      .catch(() => null);
    });
  };

  React.useEffect(() => {
    if (ownerId) loadNotificationProfiles(ownerId);
    return (() => setNotificationProfiles());
  }, [ownerId]);

  return (
    <div>
      <>
        <Icon
          data-for="global.tooltip"
          data-tip="Create alert from search parameters"
          onClick={() => onClose()}>
          notifications
        </Icon>
        <SaveAlertDialog
          data={data}
          filters={filters}
          keywordClasses={keywordClasses}
          notificationProfiles={notificationProfiles}
          onClose={onClose}
          open={open}
          ownerId={ownerId}
      />
      </>
    </div>
  );
};

SaveAlertIcon.propTypes = {
  data: PropTypes.object,
  filters: PropTypes.object,
};

SaveAlertIcon.defaultProps = {
  data: map(),
  filters: map(),
};
