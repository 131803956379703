import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import style from './sanctions.module.scss';
import NoteComponent from './NoteComponent';

const SanctionsSearchNotes = ((props) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (props.data && props.data.notes) {
      setNotes(props.data.notes);
    }
  }, [props.data]);

  const handleAddNote = () => {
    const temp = notes.slice();
    temp.push({});
    setNotes(temp);
  };

  const handleDeleteNote = (index) => {
    const temp = notes.slice();
    temp.splice(index, 1);
    setNotes(temp);
  };

  return (
    <React.Fragment>
      <div className={style.notescontent}>
        {props.data && props.data.id && notes.map((note, index) => (
          <NoteComponent
            key={`${note.note_text},${note.created_date}`}
            note={note}
            index={index}
            id={props.data.id}
            onDelete={handleDeleteNote} />
        ))}
        {Object.keys(notes).length === 0 &&
        <div>
          <div className={style.subtitle}>Notes section empty</div>
          <div className={style.note}>
            No notes yet
          </div>
        </div>
        }
        <button type="button" data-cy="addNoteButton" color="primary" onClick={handleAddNote} className={style.addnewobject}>+ Add note</button>
      </div>
    </React.Fragment>
  );
});

SanctionsSearchNotes.propTypes = {
  data: PropTypes.object,
};

SanctionsSearchNotes.defaultProps = {
  data: undefined,
};

export default SanctionsSearchNotes;
