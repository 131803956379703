import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './threads.module.scss';
import History from '../../utils/history';
import Text from '../../utils/text';
import Apps from '../../constants/Apps';
import Thread from '../../components/threads/Thread/Thread';
import ColumnThread from '../../components/threads/ColumnThread';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import { SearchContext, UserContext } from '../../components/utils/Context';

const Threads = ({
  location,
  match: {
    params,
  },
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);
  const { query: { query_i18n } } = History.getCurrentLocation();

  const isCommentThread = ['posts', 'releases', 'news'].includes(params.subtype);
  const isCompact = user.getIn(['prefs', 'compact']) && ['channels'].includes(params.subtype);

  const pageHistory = () => {
    if (!params.type.includes('.') && params.type !== 'users') {
      const { search: querySearch, pathname } = History.getCurrentLocation();
      const now = moment.utc().unix();
      const app = Apps.find(v => v.value === `collections.${params.type}.view`);
      const title = search.getIn(['result', ...(app.title || 'title')]);
      if (!title) return;
      document.title = Text.StripHtml(Text.Strip(`Flashpoint - ${Text.Sentence(title)}`));
      const saveQuery = map({
        path: `${pathname}${querySearch}`,
        title,
        created: now,
      });

      SearchActions.set(['search', 'viewed'], search.get('viewed').push(params.id));
      UserActions.saveHistory('items', saveQuery);
    }
  };

  const onLoad = () => {
    SearchActions.search(params.subtype, params.id);
    SearchActions.search(`inline.${params.subtype}`, params.id);
    SearchActions.search(`meta.${params.subtype}`, params.id);
    SearchActions.search.completed.listen(() => {
      pageHistory({ type: params.type, id: params.id });
    });
  };

  const onUnload = () => {
    SearchActions.set(['search', 'result', 'chats'], map());
    SearchActions.set(['search', 'result', 'channels'], map());
    SearchActions.set(['search', 'result', 'inline', 'channels'], map());
    SearchActions.set(['search', 'result', 'meta', 'channels'], map());
  };

  useEffect(() => {
    onUnload();
    onLoad();
    const selector = ['result', params.subtype, 'data', 0, 'container', 'title'];
    const title = search.getIn(selector);
    if (title) document.title = Text.StripHtml(Text.Strip(`Flashpoint - ${Text.Sentence(title)}`));
  }, [params.id, params.subtype]);

  useEffect(() => () => SearchActions.set(['search', 'result', params.subtype], map()), []);

  return (
    <Grid
      fluid
      className={cx([
        style.threads,
        isCommentThread && style.horizontal,
        isCompact && style.compact])}>
      <Row>
        <Col xs={12}>
          {!isCommentThread &&
          <Thread
            location={location}
            compact={isCompact}
            container={search.getIn(['result', params.subtype])}
            data={search.getIn(['result', params.subtype, 'data'])}
            meta={search.getIn(['result', 'meta', params.subtype])}
            search={search}
            site={search.getIn(['result', params.subtype, 'data', 0, 'site', 'title'])}
            title={search.getIn(['result', 'meta', params.subtype, `title${query_i18n ? '_en' : ''}`])} />}
          {isCommentThread &&
          <ColumnThread
            location={location}
            container={search.getIn(['result', params.subtype])}
            data={search.getIn(['result', params.subtype, 'data'])}
            first={search.getIn(['result', 'meta', params.subtype, 'post'])}
            meta={search.getIn(['result', 'meta', params.subtype])}
            replies={search.getIn(['replies', params.type])}
            site={search.getIn(['result', 'meta', params.subtype, 'post', 'site', 'title'])}
            title={search.getIn(['result', 'meta', params.subtype, `title${query_i18n ? '_en' : ''}`])} />}
        </Col>
      </Row>
    </Grid>
  );
};

Threads.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Threads;
