import { gql } from '@apollo/client';

const myBins = gql`
  query manageBins(
    $customerId: Int
    $salesforceId: String
    $skip: Int
    $size: Int
    $sortBy: String
    $binsFilter: String
    $tagsFilter: [String]
  ) {
    shopCustomer(
      customerId: $customerId
      salesforceId: $salesforceId
    ) {
      id
      salesforceId
      name
      binTags {
        name
        id
        bins {
          id
          number
        }
      }
      customerBinsLength(
        binsFilter: $binsFilter
        tagsFilter: $tagsFilter
      )
      customerBins(
        skip: $skip
        size: $size
        sortBy: $sortBy
        binsFilter: $binsFilter
        tagsFilter: $tagsFilter
      ) {
        id
        number
        binTags {
          id
          name
          bin_tag_customer: customer {
            salesforceId
          }
        }
        roi(
          salesforceId: $salesforceId
        )
      }
    }
  }
`;

export const createEditBin = gql`
  mutation CreateEditBin(
    $customerId: Int
    $salesforceId: String
    $usermanagerId: Int
    $id: ID
    $binNumber: Int
    $binTags: [BinTagInput]
    $binRoi: ROIInput
  ) {
    createOrUpdateBin(
      id: $id
      customerId: $customerId
      salesforceId: $salesforceId
      usermanagerId: $usermanagerId
      binNumber: $binNumber
      binTags: $binTags
      binRoi: $binRoi
    ) {
      bin {
        customer {
          id
        }
        id
        number
        binTags {
          id
          name
        }
      }
    }
  }
`;

export const bulkEditBins = gql`
  mutation updateBins(
      $customerId: Int
      $salesforceId: String
      $usermanagerId: Int
      $bins: [BinInput]
  ) {
    updateBins(
      customerId: $customerId
      salesforceId: $salesforceId
      usermanagerId: $usermanagerId
      bins:$bins
    ) {
      updatedBins {
        id
        number
        binTags {
          id
          name
        }
        roi (salesforceId: $salesforceId)
      }
    }
  }
`;

export const deleteBin = gql`
  mutation deleteBin(
    $id: ID
    $customerId: Int
    $salesforceId: String
    $usermanagerId: Int
    $binNumber: Int
  ) {
    deleteBin(
      id: $id
      customerId: $customerId
      salesforceId: $salesforceId
      usermanagerId: $usermanagerId
      binNumber: $binNumber
    ) {
      bin {
        name
      }
    }
  }
`;

export const bulkAddBins = gql`
  mutation createBins(
    $customerId: Int
    $salesforceId: String
    $usermanagerId: Int
    $bins: [BinInput]
  ) {
    createBins(
      customerId: $customerId
      salesforceId: $salesforceId
      usermanagerId: $usermanagerId
      bins: $bins
    ) {
      bins {
        name
      }
    }
  }
`;

export const ownedBin = gql`
  query ownedOrWatchedBins(
    $binNumbers: [Int]
    $salesforceId: String
  ){
    ownedOrWatchedBins(
      binNumbers: $binNumbers
      salesforceId: $salesforceId
    ){
      number
    }
  }
`;

export default myBins;
