import React from 'react';
import moment from 'moment';
import { fromJS } from 'immutable';

import Api from '../../../utils/api';
import Messages from '../../../constants/Messages';
import SearchActions from '../../../actions/searchActions';

const dev = '_self' in React.createElement('div') ? '/dev' : '';
const TakedownBackendUrl = `/ui/v4/takedown${dev}/backends`;
const TakedownRequestUrl = `/ui/v4/takedown${dev}/requests`;
const TakedownConfirmUrl = `/ui/v4/takedown${dev}/pastebin`;
const Status = [200, 201, 204, 500, 501, 502, 503, 504];

const takedownBackend = () =>
  Api.get(TakedownBackendUrl, {}, Status, 30000)
    .then(res => (res.ok ? res.data : []))
    .then(res => fromJS(res));

const takedownConfirm = nativeId => (nativeId
  ? Api.get(`${TakedownConfirmUrl}/${nativeId}`, null, Status)
    .then(res => (res.ok ? res.data : null))
    .catch(() => null)
  : null);

const takedownRequest = (backendId, nativeId, fpid) => {
  if (!backendId || !nativeId || !fpid) {
    SearchActions.set(['search', 'info', 'message'], Messages.TakedownError);
  }
  const request = {
    backend_id: backendId,
    resource_native_id: nativeId,
    resource_fpid: fpid,
  };
  return Api.post(TakedownRequestUrl, request, Status)
    .then(() => SearchActions.set(['search', 'info', 'message'], Messages.TakedownSuccess))
    .catch((err) => {
      const id = moment.utc().unix();
      const { message } = err.response.data;
      SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(id, message));
    });
};

export default {
  takedownBackend,
  takedownConfirm,
  takedownRequest,
};
