import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { List as list } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import style from './browse.module.scss';
import History from '../../utils/history';


const useStyles = makeStyles(theme => ({
  browse: {
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        padding: 0,
        '&.active': {
          '& .MuiListItemText-primary': {
            color: theme.palette.primary.main,
          },
        },
        '&:hover': {
          '& .MuiListItemText-primary': {
            color: theme.palette.primary.main,
          },
        },
        '&.nested': {
          margin: `0 0 0 ${theme.spacing(2)}`,
        },
        '& .MuiListItemText-primary': {
          color: '#2a2c3c',
          fontFamily: 'montserrat',
          textTransform: 'uppercase',
        },
      },
    },
  },
}));

const Browse = ({
  prm,
  tag,
  tags,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    setExpanded(tags
      .sortBy(v => v.get('name'))
      .findIndex(v => v.get('children').find(c => c.get('name') === tag)));
  }, [tag, tags]);

  const onTag = value => History.navigateTo(`/home/intelligence/latest/${value}`);

  return (
    <Grid
      fluid
      className={cx([
        style.browse,
        classes.browse,
      ])}>
      <Row>
        <Col xs={12} className={style.header}>
          <div>
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Browse Reports</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <List>
            <ListItem
              open={!tag}
              className={!tag ? 'active' : ''}
              onClick={() => History.push('/home/intelligence/latest')}>
              <ListItemText primary="All" />
            </ListItem>
          </List>
          {tags
            .sortBy(v => v.get('name'))
            .map((v, k) => (
              <List
                key={v.get('id')}
                color="primary">
                <ListItem onClick={() => setExpanded(k === expanded ? null : k)}>
                  <ListItemText primary={v.get('name')} />
                  <ListItemIcon>
                    {k === expanded
                      ? <KeyboardArrowUp />
                      : <KeyboardArrowDown />}
                  </ListItemIcon>
                </ListItem>
                <Collapse in={k === expanded}>
                  <List disablePadding>
                    {v.get('children')
                      .filterNot(child => ['Glossary'].includes(child.get('name')))
                      .filter(child => (!prm.some(p => /(vln|cve).r/.test(p))
                        ? !/exploits & vulnerabilities/ig.test(child)
                        : child))
                      .sortBy(child => child.get('name'))
                      .toArray()
                      .map(child => (
                        <ListItem
                          key={child.get('id')}
                          className={cx([
                            'nested',
                            child.get('name') === tag ? 'active' : '',
                          ])}
                          onClick={() => onTag(child.get('name'))}>
                          <ListItemText primary={child.get('name')} />
                        </ListItem>))}
                  </List>
                </Collapse>
              </List>))}
        </Col>
      </Row>
    </Grid>
  );
};

Browse.propTypes = {
  prm: PropTypes.object,
  tag: PropTypes.string,
  tags: PropTypes.object,
};

Browse.defaultProps = {
  prm: list(),
  tag: null,
  tags: list(),
};

export default Browse;
