import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import FileSaver from 'file-saver';
import ReactTooltip from 'react-tooltip';
import { createValueFromString } from 'react-rte';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import {
  CircularProgress,
  Icon,
  ListItem,
  Popover,
} from '@mui/material';

import style from './malware.module.scss';
import WikiEdit from '../WikiEdit';
import Text from '../../../utils/text';
import Line from '../../widget/Line/Line';
import Hero from '../../widget/Hero/Hero';
import Table from '../../widget/Table/Table';
import PieTable from '../../widget/PieTable/PieTable';
import SearchActions from '../../../actions/searchActions';
import Messages from '../../../constants/Messages';
import History from '../../../utils/history';
import Query from './query';

const Malware = ({
  category,
  save,
  source,
}) => {
  const [data, setData] = useState(map());
  const [dialog, setDialog] = useState();
  const [filter, setFilter] = useState();
  const [saving, setSaving] = useState(false);

  const edit = /edit$/ig.test(window.location.pathname);

  const attackIdSort = [
    'initial-access',
    'execution',
    'persistence',
    'privilege-escalation',
    'defense-evasion',
    'credential-access',
    'discovery',
    'lateral-movement',
    'collection',
    'command-and-control',
    'exfiltration',
    'impact',
  ];

  const editFields = fromJS([
    // This structure allows for sections of fields
    {
      label: '',
      fields: [
        {
          label: 'Published',
          value: 'is_published',
          type: 'toggle',
          centerLabel: true,
        },
        {
          label: 'Archived',
          value: 'is_archived',
          type: 'toggle',
          centerLabel: true,
        },
        ...(['malware'].includes(category) ?
          [{
            label: 'Malware Family Name',
            value: 'malware_family_name',
            type: 'text',
            required: true,
            centerLabel: true,
          }] : []),
        ...(['apt'].includes(category) ?
          [{
            label: 'APT Group',
            value: 'apt_group',
            type: 'text',
            required: true,
            centerLabel: true,
          }] : []),
        {
          label: 'Description',
          value: 'body.raw',
          type: 'rte',
          transformation: (v = '') => createValueFromString(v, 'html'),
        },
        {
          label: 'Aliases',
          value: 'aliases',
          type: 'chips',
          transformation: (v = []) => v.join(),
        },
        {
          label: 'MISP Tags',
          value: 'misp_tags',
          type: 'chips',
          transformation: (v = []) => v.join(),
        },
        {
          label: 'Mentions Search String',
          value: 'queries.0.query',
          type: 'text',
          centerLabel: true,
        },
        {
          label: 'Reports Search String',
          value: 'queries.1.query',
          type: 'text',
          centerLabel: true,
        },
      ],
    },
  ]);

  const onCancel = () => {
    window.history.back();
  };

  const onExport = (type) => {
    const { origin } = window.location;
    const tags = data.get('misp_tags', list());
    const filename = `${data.get('malware_family_name', 'attributes')}.json`;
    const url = `${origin}/ui/v4/indicators/event?search_tags=${tags.join()}&format=${type}&download=true`;
    FileSaver.saveAs(url, filename, { type: 'text/plain;charset=utf-8', autoBom: true });
  };

  const onFilter = async ({ yAxis }) => {
    const promises = [];
    setFilter(yAxis);
    setData(data
      .withMutations(d => d
        .deleteIn(['indicators', 'results'])
        .deleteIn(['events', 'tags']),
      ),
    );

    promises.push(Query
      .Indicators(data.get('misp_tags').toJS(), yAxis)
      .then(res => fromJS({
        results: res.results,
        skip: res.skip,
        total: res.total,
      })),
    );

    promises.push(Query
      .Events(data.get('misp_tags').toJS(), yAxis)
      .then(res => fromJS({
        tags: res.tags,
      })),
    );

    Promise.all(promises)
      .then(([indicators, events]) => setData(
        data
          .withMutations(d => d
            .mergeIn(['indicators'], indicators)
            .mergeIn(['events'], events),
          ),
      ));
  };

  const onLoad = async (base) => {
    const { misp_tags = [], queries = [], apt_group = '', malware_family_name = '' } = base.toJS();
    const reportQueries = queries.filter(v => v?.name === 'reports');
    const mentionQueries = queries.filter(v => v?.name === 'mentions');
    const title = ['malware'].includes(category) ? malware_family_name : apt_group;

    const setPromiseData = async (dataPromise, fieldName) => {
      dataPromise
        .then((res) => {
          setData(prevState => fromJS({
            ...prevState.toJS(),
            [fieldName]: res,
          }));
        });
    };

    // Rather than waiting for each query to finish, this allows us
    // to update each of the dashboard pieces individually.
    await setPromiseData(Query.Breaches(title), 'breaches');
    await setPromiseData(Query.AttackIDs(misp_tags, title), 'attack_ids');
    await setPromiseData(Query.Indicators(misp_tags), 'indicators');
    await setPromiseData(Query.Reports(reportQueries), 'reports');
    await setPromiseData(Query.Mentions(mentionQueries), 'mentions');
    await setPromiseData(Query.Events(misp_tags, title), 'events');
  };

  const onRoute = (key, value) => {
    switch (key) {
      case 'attack':
        History.navigateTo({
          pathname: '/home/search/iocs',
          query: {
            group: 'indicator',
            query: data.get('malware_family_name'),
            attack_ids: `${value.get('id')}: ${value.get('name')}`,
          },
        });
        break;
      case 'mention':
        History.navigateTo({
          pathname: '/home/search/communities',
          query: {
            sites: value.get('key'),
            query: data
              .get('queries')
              .find(v => v.get('name') === 'mentions')
              .get('query') || '',
          },
        });
        break;
      case 'tag':
        History.navigateTo({
          pathname: '/home/search/iocs',
          query: {
            group: 'event',
            ioc_tags: value.get('key'),
          },
        });
        break;
      default:
        break;
    }
  };

  const onSave = (values = map()) => {
    setSaving(true);
    const title = ['malware'].includes(category)
      ? 'malware_family_name'
      : 'apt_group';
    const payload = map()
      .set(title, values.get(title))
      .set('fpid', source.get('fpid'))
      .set('categories', fromJS([category]))
      .set('aliases', fromJS(values.get('aliases').split(',')))
      .set('misp_tags', fromJS(values.get('misp_tags').split(',')))
      .set('apt_group', fromJS(values.get('apt_group')))
      .set('is_published', fromJS(!!values.get('is_published')))
      .set('is_archived', fromJS(values.get('is_archived', false)))
      .setIn(['updated_at', 'date-time'], `${moment.utc().format().slice(0, -1)}+00:00`)
      .setIn(['body', 'raw'], fromJS(values.get('body.raw').toString('html')))
      .set('queries', fromJS([
        { name: 'mentions', query: values.get('queries.0.query') },
        { name: 'reports', query: values.get('queries.1.query') },
      ]));
    save(payload)
      .then(() => setSaving(false))
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess))
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.MutationError));
  };

  useEffect(() => {
    if (source.isEmpty()) return;
    if (source.equals(data)) return;
    if (!edit) onLoad(source);
    setData(source);
  }, [source]);

  return (
    <Grid fluid className={style.malware}>
      {edit &&
        <WikiEdit
          source={data}
          saving={saving}
          fields={editFields}
          onCancel={onCancel}
          onSave={onSave} />}
      {!edit && /* overview */
        <Row className={style.overview}>
          <Col xs={12}>
            <div className={style.header}>
              <div className={cx([style.h0, 'h0', style.raj, 'raj', style.primary])}>
                {data.get(category === 'malware' ? 'malware_family_name' : 'apt_group')}
              </div>
            </div>
            <div
              className={style.description}
              dangerouslySetInnerHTML={{
                __html: Text.Links(data.getIn(['body', 'raw'])),
              }} />
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={3}>
                {!source.get('aliases').isEmpty() &&
                  <div className={style.aliases}>
                    <div className={style.title}>Also Known As:</div>
                    <div>
                      {source
                        .get('aliases')
                        .join(', ')}
                    </div>
                  </div>}
              </Col>
              <Col xs={3}>
                {data.getIn(['events']) &&
                  <div className={style.dates}>
                    <div>
                      <div className={style.title}>First Seen:</div>
                      {data
                        .getIn(['events', 'dates'])
                        .map(v => v.get('date'))
                        .sort((a, b) => a.localeCompare(b))
                        .slice(0, 1)
                        .map(v => moment.utc(v).format('MMMM DD, YYYY'))
                        .join()}
                    </div>
                    <div>
                      <div className={style.title}>Last Seen:</div>
                      {data
                        .getIn(['events', 'dates'])
                        .map(v => v.get('date'))
                        .sort((a, b) => -a.localeCompare(b))
                        .slice(0, 1)
                        .map(v => moment.utc(v).format('MMMM DD, YYYY'))
                        .join()}
                    </div>
                  </div>}
              </Col>
              <Col xs={6}>
                <div className={style.counts}>
                  <Hero
                    abbreviate
                    styles={{ margin: '0 15px', backgroundColor: 'white' }}
                    labels={fromJS(['Events'])}
                    values={fromJS([{ key: 'events.total' }])}
                    results={data}
                    onClick={() =>
                      History.navigateTo({
                        pathname: '/home/search/iocs',
                        query: map()
                          .set('group', 'event')
                          .set('ioc_tags', data.get('misp_tags').join())
                          .toJS(),
                      })} />
                  <Hero
                    abbreviate
                    styles={{ margin: '0 15px', backgroundColor: 'white' }}
                    labels={fromJS(['IOCs'])}
                    values={fromJS([{ key: 'indicators.total' }])}
                    results={data}
                    onClick={() =>
                      History.navigateTo({
                        pathname: '/home/search/iocs',
                        query: map()
                          .set('group', 'indicator')
                          .set('ioc_tags', data.get('misp_tags').join())
                          .toJS(),
                      })} />
                  <Hero
                    abbreviate
                    styles={{ margin: '0 15px', backgroundColor: 'white' }}
                    labels={fromJS(['Reports'])}
                    values={fromJS([{ key: 'reports.total' }])}
                    results={data}
                    onClick={() =>
                      History.navigateTo({
                        pathname: '/home/search/reports',
                        query: map()
                          .set('query', data.get('queries')
                            .find(q => q.get('name') === 'reports', null, map())
                            .get('query'))
                          .toJS(),
                      })} />
                  <Hero
                    abbreviate
                    styles={{ margin: '0 15px', backgroundColor: 'white' }}
                    labels={fromJS(['Mentions'])}
                    values={fromJS([{ key: 'mentions.total' }])}
                    results={data}
                    onClick={() =>
                      History.navigateTo({
                        pathname: '/home/search/communities',
                        query: map()
                          .set('query', data.get('queries')
                            .find(q => q.get('name') === 'mentions', null, map())
                            .get('query'))
                          .toJS(),
                      })} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>}
      {!edit && /* attack id phases */
        <Row>
          <Col xs={12}>
            <div className={cx([style.section, style.attacks])}>
              {data
                .getIn(['attack_ids', 'results'], list())
                .entrySeq()
                .sortBy(([k]) => attackIdSort.indexOf(k))
                .map(([k, v]) => (
                  <div key={k} className={style.dropdown}>
                    <div
                      role="link"
                      tabIndex={0}
                      onKeyUp={() => null}
                      className={style.button}>
                      {Text.Sentence(k.replace(/-/ig, ' '))}
                    </div>
                    {v.size > 0 &&
                      <div className={style.content}>
                        {v.map(attack => (
                          <div
                            role="link"
                            tabIndex={0}
                            onKeyUp={() => null}
                            className={style.link}
                            key={attack.get('name')}
                            onClick={() => onRoute('attack', attack)}>
                            {Text.Sentence(attack.get('name').replace(/-/ig, ' '))}
                          </div>
                        ))}
                      </div>}
                    {v.size > 0 &&
                      <div className={style.badge}>
                        {v.size}
                      </div>}
                  </div>
                ))}
            </div>
          </Col>
        </Row>}
      {!edit && /* iocs, mentions and reports */
        <Row>
          <Col xs={4}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>IOCs by Type</div>
            </div>
            <div className={cx([style.section, style.iocs])}>
              {!data.get('indicators') && <CircularProgress />}
              {data.get('indicators') &&
              <PieTable
                header={false}
                pagination={false}
                size={{ height: 165 }}
                styles={{
                  centered: true,
                  donut: true,
                  transparent: true,
                }}
                onClick={() => null}
                cells={fromJS([{ key: 'doc_count' }])}
                labels={fromJS([{ key: 'key' }])}
                values={fromJS([
                  { key: 'key' },
                  {
                    key: 'doc_count',
                    style: { width: '35px', textAlign: 'right' },
                    render: v => Text.AbbreviateNumber(v),
                  },
                ])}
                results={fromJS({
                  data: data
                    .getIn(['indicators', 'top'], list())
                    .filter(v => v.get('key')),
                  total: 5,
                })} />}
            </div>
          </Col>
          <Col xs={4}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>Mentions by Community</div>
            </div>
            <div className={cx([style.section, style.mentions])}>
              {!data.get('mentions') && <CircularProgress />}
              {data.get('mentions') &&
              <PieTable
                header={false}
                pagination={false}
                size={{ height: 165 }}
                styles={{
                  centered: true,
                  donut: true,
                  transparent: true,
                }}
                onClick={v => onRoute('mention', v)}
                cells={fromJS([{ key: 'doc_count' }])}
                labels={fromJS([{ key: 'key' }])}
                values={fromJS([
                  { key: 'key' },
                  {
                    key: 'doc_count',
                    style: { width: '35px', textAlign: 'right' },
                    render: v => Text.AbbreviateNumber(v),
                  },
                ])}
                results={fromJS({
                  data: data
                    .getIn(['mentions', 'communities'], list())
                    .filter(v => v.get('key')),
                  total: 5,
                })} />}
            </div>
          </Col>
          <Col xs={4}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>Report Mentions</div>
            </div>
            <div className={cx([style.section, style.reports])}>
              {!data.get('reports') && <CircularProgress />}
              {data.get('reports') &&
              <Table
                header={false}
                pagination={false}
                styles={{
                  transparent: true,
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                }}
                onClick={v => History.navigateTo({
                  pathname: `/home/intelligence/reports/report/${v.get('id')}`,
                  query: {},
                })}
                labels={fromJS([])}
                values={fromJS([
                  { key: 'title' },
                ])}
                results={fromJS({ data: data.getIn(['reports', 'data']), total: 5 })} />}
            </div>
          </Col>
        </Row>}
      {!edit && !data.getIn(['breaches', 'data'], list()).isEmpty() && /* breaches */
        <Row>
          <Col xs={12}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                Flashpoint Collected Breaches
              </div>
            </div>
            <div className={cx([style.section, style.breaches])}>
              <Table
                header
                onOffset={skip => Query.Breaches(data.get('malware_family_name'), skip)
                  .then(res =>
                    setData(fromJS({
                      ...data.toObject(),
                      breaches: res,
                    })),
                  )}
                results={!data.getIn(['breaches', 'data'])
                  ? map()
                  : fromJS({
                    data: data.getIn(['breaches', 'data']),
                    skip: data.getIn(['breaches', 'skip']),
                    total: data.getIn(['breaches', 'total']),
                  })}
                values={fromJS([
                  {
                    key: 'created_at.date-time',
                    label: 'date',
                    style: { width: '10%' },
                    render: v => moment.utc(v).format('MMM DD, YYYY HH:mm'),
                  },
                  {
                    key: 'title',
                    label: 'title',
                    style: { width: '30%' },
                    tooltip: true,
                  },
                  {
                    key: 'total_records',
                    label: 'total records',
                    style: { width: '10%' },
                    render: v => v && v.toLocaleString(),
                  },
                  {
                    key: 'unique_records',
                    label: 'total unique',
                    style: { width: '10%' },
                    render: v => v && v.toLocaleString(),
                  },
                ])} />
            </div>
          </Col>
        </Row>}
      {!edit && ['malware'].includes(category) && /* curated events */
        <Row>
          <Col xs={12}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                Events over Time
              </div>
            </div>
            <div className={cx([style.section, style.events])}>
              {!data.getIn(['events', 'dates']) && <CircularProgress />}
              {data.getIn(['events', 'dates']) &&
              <Line
                legend={false}
                labels={fromJS([{ key: 'date' }])}
                values={fromJS([{ key: 'count' }])}
                results={data.getIn(['events', 'dates'])}
                onClick={onFilter}
                styles={{ height: 450, margin: { left: -15 } }} />
              }
            </div>
          </Col>
        </Row>}
      {!edit && /* indicators and tags */
        <Row>
          <Col xs={8}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                {`Indicators from ${filter || 'Event'}`}
              </div>
              <Icon
                data-for="subnav.tooltip"
                data-tip="Download IOC in FP, MISP CSV or STIX formats"
                onClick={event => setDialog({ key: 'ioc.export', target: event.target })}>
                file_download
              </Icon>
              <Popover
                className="dropdown"
                anchorEl={dialog && dialog.target}
                open={Boolean(dialog && dialog.key === 'ioc.export')}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClick={() => setDialog()}
                onClose={() => setDialog()}>
                {['FP', 'MISP', 'CSV', 'STIX'].map(v => (
                  <ListItem
                    key={v}
                    onClick={() => onExport(v)}>
                    {v}
                  </ListItem>))}
              </Popover>
            </div>
            <div className={cx([style.section, style.indicators])}>
              {!data.get('indicators') && <CircularProgress />}
              {data.get('indicators') &&
              <Table
                header
                styles={{
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                }}
                onClick={(v, k) => {
                  const event_uuid = k.get('data').toJS()[0].Event.uuid;
                  History.navigateTo({
                    pathname: `/home/technical_data/iocs/items/${event_uuid}`,
                    query: {},
                  });
                }}
                onOffset={skip => Query.Indicators(data.get('misp_tags').toJS(), '', skip)
                  .then(res =>
                    setData(fromJS({
                      ...data.toObject(),
                      indicators: res,
                    })),
                  )}
                results={fromJS({
                  data: data.getIn(['indicators', 'results']),
                  skip: data.getIn(['indicators', 'skip']),
                  total: data.getIn(['indicators', 'total']),
                })}
                values={fromJS([
                  {
                    key: 'Event.info',
                    label: 'Event',
                    style: { width: '15%' },
                  },
                  {
                    key: 'type',
                    label: 'type',
                    style: { width: '15%' },
                  },
                  {
                    key: 'Event.date',
                    label: 'created at',
                    style: { width: '25%' },
                    render: v => moment.utc(v).format('MM/DD/YYYY'),
                  },
                  {
                    key: '',
                    label: 'value',
                    render: v => v.getIn(['value', v.get('type', '')], ''),
                  },
                ])} />}
            </div>
          </Col>
          <Col xs={4}>
            <div className={style.title}>
              <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                {`Tags from ${filter || 'Event'}`}
              </div>
            </div>
            <div className={cx([style.section, style.tags])}>
              {!data.get('events') && <CircularProgress />}
              {data.get('events') &&
              <Table
                header={false}
                pagination={false}
                styles={{
                  transparent: true,
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                }}
                onClick={v => onRoute('tag', v)}
                results={fromJS({
                  data: data.getIn(['events', 'tags'], list()),
                  total: data.getIn(['events', 'tags'], list()).size,
                })}
                values={fromJS([
                  {
                    key: 'key',
                    label: 'Tag',
                  },
                  {
                    key: 'doc_count',
                    label: 'Total',
                    style: { width: '35px' },
                  },
                ])} />}
            </div>
          </Col>
        </Row>}
      <ReactTooltip id="malware.tooltip" html place="right" effect="solid" />
    </Grid>
  );
};

Malware.propTypes = {
  category: PropTypes.string,
  save: PropTypes.func,
  source: PropTypes.object,
};

Malware.defaultProps = {
  category: 'malware',
  save: null,
  source: map(),
};

export default Malware;
