import React from 'react';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

const ThemeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2F374C',
      color: '#E5E5E5',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.4px',
      padding: '4px 16px',
      width: '275px',
    },
}));

export default ThemeTooltip;
