/* eslint-disable security/detect-object-injection */
import get from 'lodash/get';

import Token from '../../../utils/token';
import SearchUtils from '../../../utils/searchUtils';
import { getLabel, makeCurrentAndLastQueries } from './utilsTS';
import { cachedPost, cachedGet } from '../../../utils/api';
import { makeReportsQueryObj, ReportsUrl } from '../../../stores/searchStore';
import { communityMentionsReturnValue } from './queryTS';

const cache = {
  /**
   * Read cache
   * @param {string} ckey cache selector
   * @param {string} key unique idenitifier within cache
   * @returns {object|undefined} cache value
   */
  get: (ckey, key) => cache?.[String(ckey)]?.[String(key)],
  /**
   * Write to cache
   * @param {string} ckey cache selector
   * @param {string} key unique idenitifier within cache
   * @param {object} value value associated with unique idenifier
   * @returns {object} cache value
   */
  set: (ckey, key, value) => {
    Object.assign(cache, {
      ...cache,
      [String(ckey)]: {
        ...cache?.[String(ckey)],
        [String(key)]: value,
      },
    });
    return value;
  },
};

const base = (type) => {
  switch (type) {
    case 'reports':
      return '/ui/v4/reports';
    default:
      return '/ui/v4/all/search';
  }
};

const RecentIntelReports = (tagIds) => {
  const query = makeReportsQueryObj({ limit: 5, skip: 0, sort: 'version_posted_at:desc',
    tags: tagIds.join(','), type: 'latest', query: '', remove_styles: true });
  return cachedGet(ReportsUrl, query)
    .then((res) => {
      if (!res.ok) {
        return ([]);
      }
      return res.data.data;
    })
    .catch(() => ([]));
};

const ExploreTopics = () => {
  const restrictCVEs = entry => (!Token.get('prm').some(p => /(vln|cve).r/.test(p))
    ? !entry?.categories?.some(c => /cve/ig.test(c))
    : entry);

  return cachedGet('/ui/v4/documents/topics/wiki')
    .then((res) => {
      if (!res.ok) {
        return ([]);
      }
      return res.data.data;
    })
    .then(res => res?.filter(v => restrictCVEs(v)))
    .catch(() => ([]));
  };

const Hits = (source = {}) => {
  const type = get(source, ['parsed', 'type'], 'all');
  const filters = SearchUtils.parseDate(get(source, ['parsed', 'filters'], {}));
  const query = SearchUtils.buildQuery(type, null, filters);

  /* Remove limiting fields */
  delete query.fields;
  delete query._source_includes;

  return cachedPost(base(type), query)
    .then(res => (res.ok ? res.data : ''))
    .then(res => ({
      query,
      source,
      data: res.hits.hits.map(v => v._source),
      label: get(source, ['name'], ''),
      created: get(source, ['created'], ''),
      total: get(res, ['hits', 'total'], 0),
    }))
    .catch(() => ({
      query,
      source,
      data: [],
      label: get(source, ['name'], ''),
      created: get(source, ['created'], ''),
      total: 0,
    }));
};

const Mentions = (type = '', filters = {}) => {
  const ckey = 'mentions';
  const key = filters?.query;
  const query = SearchUtils.buildQuery(type, null, filters);
  return cache.get(ckey, key) || cachedPost(base(), query)
    .then(res => (res.ok ? res.data : ''))
    .then(res => cache.set(ckey, key, res?.hits));
};

const aggregations = {
  'date-histogram': {
    field: 'sort_date',
    interval: 'day',
  },
  'date-histogram-cardinality': {
    field: 'sort_date',
    interval: 'day',
    sub_field: 'site_actor.names.handle.keyword',
  },
  'multiple-terms': {
    actor: {
      terms: {
        field: 'site_actor.names.handle.keyword',
        size: 5000,
      },
    },
    source: {
      terms: {
        field: 'container.title.keyword',
        size: 500,
      },
    },
  },
  'terms-by-terms': {
    field_1: 'container.title.keyword',
    size_1: 20,
    field_2: 'site.site_type',
    size_2: 1,
  },
};

const CommunityMentions = async (savedSearch, filters) => {
  const keyword = filters.query;
  const [current, last] = await makeCurrentAndLastQueries({
    savedSearch, filters, aggregations });

  const label = getLabel(filters);

  return communityMentionsReturnValue({
    keyword,
    label,
    current,
    last,
  });
};

export default {
  RecentIntelReports,
  CommunityMentions,
  ExploreTopics,
  Hits,
  Mentions,
};
