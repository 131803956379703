import * as React from 'react';

import {
  TableHead as MuiTableHead,
} from '@mui/material';

interface TableHeadProps {
  /** The content of the table header */
  children?: React.ReactNode;
}

/** The table header row */
export const TableHead = ({
  children,
  ...rest
}: TableHeadProps): JSX.Element =>
  <MuiTableHead {...rest} >
    {children}
  </MuiTableHead>;

TableHead.defaultProps = {
  children: '',
};
