/* eslint-disable arrow-parens */
import { SearchDatasets } from '../types';
import { IRouteMapperConfig, IRouteQueryParamMapperConfig } from '../useRouteMapper/useRouteMapper';

type TTagShape = { [index: string]: string | undefined };

export const simpleTopLevelDomainRemaps = [
  {
    oldRoutes: ['https://vulndb.cyberriskanalytics.com', 'vulndb.cyberriskanalytics.com'],
    newRoute: 'https://vulndb.flashpoint.io',
  },
];

// note: these urls will short circut the link translator below
export const knownUnsupportedPatterns = [
  'https://app.flashpoint.io',
  // 'https://fp.tools/home/assets',
  // POST GA:  not going to be able to support this just yet, might be in vuln instead?
  // vuln uses vulndbId, so we'd need to map from ours to theirs or vice versa (in ioc page later)
  // 'https://fp.tools/home/technical_data/iocs/items',
  'https://fp.tools/home/technical_data/cves/items',
  'https://fp.tools/home/search/vulndb/cves',
  'https://fp.tools/home/analytics/breach',
  'https://fp.tools/home/analytics/cves',
  'https://fp.tools/home/analytics/uasleak',
  'https://fp.tools/home/dashboards/bitcoin',
  'https://fp.tools/home/ddw/twitter/tweet',
  'https://fp.tools/home/labs/bitcoin',
  'https://fp.tools/home/sanctions/new',
  'https://fp.tools/home/search/accounts',
  'https://fp.tools/home/search/cards',
  'https://fp.tools/home/search/cves',
  'https://fp.tools/home/shops/cards/items/',
  'https://cyberriskanalytics.com',
  'https://www.cyberriskanalytics.com',
];

// note: these params will short circuit the link translator
// if the url is a search url with params other than known ones. eg. https://fp.tools/home/search/*
export const knownSupportedSearchParams = [
  'all',
  'author',
  'author_id',
  'channel_exact',
  'channel_id',
  'handles',
  'fpid',
  'date',
  'limit',
  'query_i18n',
  'since',
  'sinceDate',
  'sites',
  'sites_exact',
  'channel',
  'skip',
  'query',
  'until',
  'untilDate',
  'title',
  'sort',
  'group',
  'meets_org_profile_password_complexity',
  'meets_org_profile',
  'is_fresh',
  'ignore_hashes',
];

const validSeachTypes = ['communities', 'marketplaces', 'media', 'reports'];

const communityTypePathToQuerystring: { [key: string]: string[] } = {
  blogs: ['blog'],
  boards: ['board'],
  chats: ['chat'],
  forums: ['forum'],
  gabs: ['gab'],
  pastes: ['paste'],
  ransomware: ['ransomware'],
  reddit: ['reddit'],
  social: [...SearchDatasets.SOCIAL.mapping],
};

const searchDateTransformer: IRouteQueryParamMapperConfig['transformer'] = (config, value, { newParams }) => {
  if (newParams.get('date')) {
    newParams.append('include.date.label', newParams.get('date'));
    newParams.delete('date');
  }

  const newValue = value;

  return decodeURIComponent(newValue);
};

function hasDateInParams(params: URLSearchParams) {
  return [
    'since',
    'until',
    'date',
    'sinceDate',
    'untilDate',
    'include.date',
    'include.date.start',
    'include.date.end',
  ].find((dateConcept) => params.get(dateConcept));
}

const searchConfig: IRouteMapperConfig = {
  oldRoute: 'ALWAYS OVERWRITTEN',
  newRoute: '/search/results/:communityType',
  transformer: (_, __, transformedRoute, { pathParams, newParams }) => {
    let finalRoute = transformedRoute;
    const searchType = pathParams[':communityType'];
    const hasDate = hasDateInParams(newParams);
    const paramCount = Array.from(newParams).length;
    if (!hasDate) {
      finalRoute += paramCount ? '&include.date=all+time' : '?include.date=all+time';
    }

    return validSeachTypes.includes(searchType)
      ? finalRoute
      : finalRoute.replace(`/${searchType}`, searchType === 'forums' ? '/media' : '/communities');
  },
  queryParams: [
    {
      oldParam: 'date',
      newParam: 'include.date',
      transformer: searchDateTransformer,
    },
    {
      oldParam: 'since',
      newParam: '',
      transformer: undefined,
    },
    {
      oldParam: 'sinceDate',
      newParam: '',
      transformer: undefined,
    },
    {
      oldParam: 'until',
      newParam: '',
      transformer: undefined,
    },
    {
      oldParam: 'untilDate',
      newParam: '',
      transformer: undefined,
    },
    {
      oldParam: 'limit',
      newParam: 'size',
    },
    {
      oldParam: 'author_exact',
      newParam: undefined,
    },
    {
      oldParam: 'channel_exact',
      newParam: undefined,
    },
    {
      oldParam: 'sites_exact',
      newParam: undefined,
    },
    {
      oldParam: 'sort',
      newParam: undefined,
    },
    {
      oldParam: 'group',
      newParam: undefined,
    },
    {
      oldParam: 'handles',
      newParam: 'include.enrichments.social_media_handles[]',
    },
    {
      oldParam: 'channel_id',
      newParam: 'include.title_id[]',
    },
    {
      oldParam: 'author_id',
      newParam: 'include.author_id[]',
    },
    {
      oldParam: 'author',
      newParam: '',
      transformer(_, value, { pathParams, newParams }) {
        const authors: string[] = value?.split(',') ?? [];
        if (authors.length) {
          const paramName = pathParams[':communityType'] === 'marketplaces' ? 'include.vendor[]' : 'include.author[]';
          authors.forEach((author) => newParams.append(paramName, author));
        }

        return null;
      },
    },
    {
      oldParam: 'all',
      newParam: '',
      transformer(_, value, { newParams }) {
        if (!value) {
          return;
        }
        value.split(',').forEach((val: string) => {
          (communityTypePathToQuerystring[val as string] ?? []).forEach((type) =>
            newParams.append('include.type[]', type),
          );
        });
      },
    },
    {
      oldParam: '',
      newParam: 'include.type[]',
      transformer(_, __, { pathParams, newParams }) {
        /*
            ...but https://fp.tools/home/search/forums would map to /search/results/communities?types[]=forum
            (and this is true for blog, board, chat, forum, gab, paste, ransomware, and reddit
          */
        const oldCommunityType = pathParams[':communityType'];
        const communityTypes: string[] = communityTypePathToQuerystring[oldCommunityType as string] ?? [];
        if (communityTypes.length > 1) {
          communityTypes.slice(1).forEach((type) => newParams.append('include.type[]', type));
        }

        return communityTypes?.[0];
      },
    },
    {
      oldParam: 'sites',
      newParam: '',
      transformer(_, value, { pathParams, newParams }) {
        const sites: string[] = value?.split(',') ?? [];
        if (sites.length) {
          const paramName = pathParams[':communityType'] === 'marketplaces' ? 'include.market[]' : 'include.site[]';
          sites.forEach((site) => newParams.append(paramName, site));
        }

        return null;
      },
    },
    {
      oldParam: 'channel',
      newParam: 'include.title[]',
      transformer(_, value) {
        return value?.match(/(?<=::)(.*)/gi)?.[0] ?? value;
      },
    },
    {
      oldParam: 'title',
      newParam: 'query',
      transformer(_, value) {
        return value === '*' ? null : value;
      },
    },
    {
      oldParam: 'query',
      newParam: 'query',
      transformer(_, value) {
        let query = value.trim();
        if (query?.startsWith('body.text/plain:') || query?.startsWith('(body.text/plain:')) {
          query = query.replace('body.text/plain:', '');
        }
        if (query.startsWith('((') && query.endsWith('))')) {
          query = query.slice(1, -1);
        }
        return query === '*' ? null : query;
      },
    },
  ],
};

export const knownLinkTransformRules: IRouteMapperConfig[] = [
  {
    oldRoute: ['https://fp.tools/home/intelligence/reports/search'],
    newRoute: '/cti/intelligence/search',
    queryParams: [
      { oldParam: 'date', newParam: 'sort_date' },
      { oldParam: 'query', newParam: 'query' },
      { oldParam: 'title', newParam: 'title' },
      { oldParam: 'sort', newParam: 'sort' },
    ],
  },
  {
    oldRoute: ['https://fp.tools/home/intelligence/latest'],
    newRoute: '/cti/intelligence/all',
    queryParams: [],
  },
  {
    oldRoute: [
      'https://fp.tools/cti/intelligence/report/:fpid',
      'https://fp.tools/home/intelligence/reports/:fpid',
      'https://fp.tools/home/intelligence/reports/:fpid/featured',
      'https://fp.tools/home/intelligence/reports/report/:fpid',
      'https://fp.tools/home/intelligence/reports/report/:fpid/featured',
      'https://fp.tools/home/intelligence/reports/report/:fpid/:jumpLink',
      'https://fp.tools/home/intelligence/reports/report/:fpid/:unused/:jumpLink',
    ],
    newRoute: '/cti/intelligence/report/:fpid',
    transformer(_, originalRoute, transformedRoute, { pathParams }) {
      const jumpLink = pathParams[':jumpLink'];
      if (!jumpLink) return transformedRoute;

      const query = originalRoute.match(/(?=\?)(.*)/gi)?.[0];

      if (!query) return `${transformedRoute}#${jumpLink}`;

      return transformedRoute.replace(query, `#${jumpLink}${query}`);
    },
    queryParams: [{ oldParam: 'query', newParam: 'query' }],
  },
  {
    oldRoute: 'https://fp.tools/api/v4/indicators/attribute',
    newRoute: '/api/v4/indicators/attribute',
    queryParams: [{ oldParam: 'search_tags', newParam: 'search_tags' }],
  },
  {
    oldRoute: 'https://fp.tools/api/v4/indicators/event/:uuid',
    newRoute: '/api/v4/indicators/event/:uuid',
    queryParams: [{ oldParam: 'format', newParam: 'format' }],
  },
  {
    oldRoute: 'https://fp.tools/home/intelligence/standup',
    newRoute: '/cti/intelligence/standups',
  },
  {
    oldRoute: 'https://fp.tools/home/shops/marketplaces/items/:fpid',
    newRoute: '/search/context/marketplaces/:fpid',
  },
  {
    oldRoute: 'https://fp.tools/home/shops/accounts/items/:fpid',
    newRoute: '/cti/fraud/accounts',
    transformer: (_, __, transformedRoute, { pathParams }) =>
      `/cti/fraud/accounts?id=${pathParams[':fpid']}&sort_date=All+Time`,
  },
  {
    oldRoute: 'https://fp.tools/home/ddw/social/news/:fpid',
    newRoute: '/search/context/communities/:fpid',
  },
  {
    oldRoute: 'https://fp.tools/home/ddw/:type/items/:fpid',
    newRoute: '/search/context/communities/:fpid',
  },
  {
    oldRoute: 'https://fp.tools/home/ddw/:type/:item/:fpid',
    newRoute: '/search/results/communities/:fpid',
    transformer: (_, __, transformedRoute, { pathParams }) => {
      const acceptedPaths = ['/boards/board', '/forums/threads', '/chats/channels'];
      if (acceptedPaths.includes(`/${pathParams[':type']}/${pathParams[':item']}`)) {
        // eslint-disable-next-line max-len
        return `/search/results/communities?include.container_id[]=${pathParams[':fpid']}&sort=date+oldest+to+newest&include.date=all+time`;
      }
      return transformedRoute;
    },
  },
  {
    oldRoute: 'https://fp.tools/home/wiki/malware/:fpid',
    newRoute: '/cti/malware/reports/:fpid',
  },
  {
    oldRoute: 'https://fp.tools/home/wiki/apt/:fpid',
    newRoute: '/cti/cyber-threats/reports/:fpid',
  },
  {
    oldRoute: 'https://fp.tools/home/technical_data/iocs',
    newRoute: '/home/search/iocs',
    queryParams: [
      {
        oldParam: 'group',
        newParam: undefined,
      },
      {
        oldParam: 'has_config', // true false
        newParam: 'has_config', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
      {
        oldParam: 'has_report', // true false
        newParam: 'has_report', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
      {
        oldParam: 'date',
        newParam: 'sort_date',
      },
      {
        oldParam: 'ioc_category',
        newParam: 'category',
      },
      {
        oldParam: 'ioc_type', // is overwritten by ioc_value
        newParam: 'types',
      },
      {
        oldParam: 'ioc_value', // is overwritten by ioc_type
        newParam: 'types',
      },
      {
        oldParam: 'attack_ids',
        newParam: 'mitre',
        transformer: (_, mitreTag) => {
          // old: attack_ids=T1531:Account+Access+Removal
          // new: mitre=Account+Access+Removal+(T1531)
          const parts = mitreTag.split(':');
          const id = parts[0];
          const name = parts[1];
          return `${name} (${id})`;
        },
      },
      /*
       After deep analysis of the tia/indicators repo, we want to wait to map this until we see these crop up in reports
      {
        oldParam: 'ioc_tags',
        newParam: 'malware',
        transformer: (_, twinParam) => {
          // old: ioc_tags=malware:adwind,actor:apt19
          // new: malware=adwind&actor=apt19

          // note: uncertain how downstream querystring builder will handle this
          //       if it doesn't work, then I'll extend the builder to support splits by detecting an array in newParam
          // in typescript, we can specify a IRouteQueryParamMapperConfig<T>
          // where T will be either string | string[], this can also drive the return type
          const parts = twinParam.split(',')
          const malwareTag = parts[0].split(':')[1]
          const actorTag = parts[1].split(':')[1]
          const bigFatHack = `&actor=${actorTag}`
          return `${malwareTag}${bigFatHack}`
        }
      },
      */
    ],
  },
  {
    oldRoute: 'https://fp.tools/api/v4/assets',
    newRoute: '/api/v4/assets',
  },
  {
    oldRoute: 'https://fp.tools/home/search/credentials',
    newRoute: '/cti/ato/credentials',
    queryParams: [
      {
        oldParam: 'date',
        newParam: 'sort_date',
      },
      {
        oldParam: 'meets_org_profile_password_complexity', // true false
        newParam: 'meets_pw_complexity', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
      {
        oldParam: 'meets_org_profile', // true false
        newParam: 'meets_pw_complexity', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
      {
        oldParam: 'is_fresh', // true false
        newParam: 'is_fresh', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
      {
        oldParam: 'ignore_hashes', // true false
        newParam: 'ignore_hashes', // 1 0
        transformer: (_, bool) => Number(!!bool),
      },
    ],
  },
  {
    ...searchConfig,
    oldRoute: 'https://fp.tools/home/shops',
    newRoute: '/search/results/marketplaces',
  },
  {
    ...searchConfig,
    oldRoute: 'https://fp.tools/home/search/:communityType',
  },
  {
    ...searchConfig,
    oldRoute: 'https://fp.tools/home/ddw/:communityType',
  },
  {
    ...searchConfig,
    oldRoute: ['https://fp.tools/home/search/images'],
    newRoute: '/search/results/media',
  },
  {
    oldRoute: 'https://fp.tools/static/:item',
    newRoute: 'IGNORED',
    transformer(_, __, ___, { pathParams }) {
      const item = pathParams[':item'];
      let path = `/${item}`;

      if (item.includes('.pdf')) path = `/pdfs/${item}`;
      if (item.includes('.mp4')) path = `/videos/${item}`;

      return `https://cdn.flashpoint.io${path}`;
    },
  },
  {
    oldRoute: 'https://fp.tools/home/technical_data/iocs/items/:uuid',
    newRoute: '/cti/malware/iocs',
    queryParams: [
      {
        oldParam: '',
        newParam: 'query',
        transformer(_, __, { pathParams }) {
          return pathParams[':uuid'];
        },
      },
    ],
  },
  {
    oldRoute: 'https://fp.tools/home/analytics/ukraine-crisis',
    newRoute: '/cti/cyber-threats/russian-war-against-ukraine',
  },
  {
    oldRoute: 'https://fp.tools/home/dashboards/access-auctions',
    newRoute: '/cti/cyber-threats/access-auctions',
  },
  {
    oldRoute: 'https://fp.tools/home/dashboards/botshops',
    newRoute: '/cti/cyber-threats/bot-shops',
  },
  {
    oldRoute: 'https://fp.tools/home/intelligence/apt',
    newRoute: '/cti/cyber-threats/reports',
  },
  {
    oldRoute: 'https://fp.tools/home/dashboards/credbreaches',
    newRoute: '/cti/ato/cred-breaches',
  },
  {
    oldRoute: 'https://fp.tools/home/intelligence/malware',
    newRoute: '/cti/malware/reports',
  },
  {
    oldRoute: 'https://fp.tools/home/intelligence/rfi/form',
    newRoute: '/rfi',
  },
  {
    oldRoute: 'https://fp.tools/home/search/all',
    newRoute: '/home/search?type=communities',
  },
  {
    oldRoute: 'https://fp.tools/home/intelligence/latest/:tag',
    newRoute: '/cti/intelligence/search',
    queryParams: [
      {
        oldParam: '',
        newParam: 'tags',
        transformer(_, __, { pathParams }) {
          const limitedTagMap: TTagShape = {
            Russia: 'Russia',
            'Central%20&%20South%20America': 'Latin America and Caribbean',
            events: undefined,
          };

          return limitedTagMap[pathParams[':tag']];
        },
      },
    ],
  },
  {
    oldRoute: 'https://fp.tools/home/dashboards/credbreaches',
    newRoute: '/cti/ato/cred-breaches',
  },
  {
    oldRoute: ['https://fp.tools/home/explore/topics', 'https://fp.tools/home/explore/topics/trending_now'],
    newRoute: '/home/search',
  },
  {
    oldRoute: 'https://fp.tools/api/v4/indicators/:type/:fpid',
    newRoute: '/api/v4/indicators/:type/:fpid',
  },
  {
    oldRoute: [
      'https://fp.tools/home/analytics/ransomware/overview',
      'https://fp.tools/home/analytics/ransomware/ransomer',
      'https://fp.tools/home/analytics/ransomware',
      'https://fp.tools/home/analytics/ransomers',
      'https://fp.tools/home/analytics/industry-ransomware',
      'https://fp.tools/home/analytics/ransomware/ransomer/industry-preview',
      'https://fp.tools/home/dashboards/ransomware',
      'https://fp.tools/home/dashboards/victims',
    ],
    newRoute: '/cti/malware/ransomware',
  },
  {
    oldRoute: ['https://fp.tools/home/analytics/iocs', 'https://fp.tools/home/search/iocs'],
    newRoute: '/cti/malware/iocs',
    queryParams: [{ oldParam: 'group', newParam: undefined }],
  },

  // WARN: do not use https://site.com/:route/:id
  //       otherwise it will catch domains with exactly two "/"
  //       example: darkreading.com/dr-global/iran-linked-apt34...
  {
    oldRoute: 'https://vulndb.cyberriskanalytics.com/vulnerabilities/:id',
    newRoute: 'https://vulndb.flashpoint.io/vulnerabilities/:id',
  },
  {
    oldRoute: 'https://vulndb.cyberriskanalytics.com/incidents/:id',
    newRoute: 'https://vulndb.flashpoint.io/incidents/:id',
  },

  // WARN: do not use https://site.com/:route
  //       otherwise it will catch ALL domains!
  //       example: securityweek.com, hhs.gov, etc become "/"
  {
    oldRoute: 'https://vulndb.cyberriskanalytics.com/pages',
    newRoute: 'https://vulndb.flashpoint.io/pages',
  },
  {
    oldRoute: 'https://vulndb.cyberriskanalytics.com/pages/:route',
    newRoute: 'https://vulndb.flashpoint.io/pages/:route',
    queryParams: [
      { oldParam: 'utf8', newParam: 'utf8' },
      { oldParam: 'query', newParam: 'query' },
      { oldParam: 'per_page', newParam: 'per_page' },
      { oldParam: 'data_type', newParam: 'data_type' },
      { oldParam: 'data_family', newParam: 'data_family' },
      { oldParam: 'inside_outside', newParam: 'inside_outside' },
    ],
  },
  {
    ...searchConfig,
    oldRoute: 'https://fp.tools/home/assets/media/items/:fpid',
    newRoute: '/search/results/media',
    transformer: (_, __, transformedRoute) => {
      const paramString = transformedRoute?.replace(_.newRoute, '');
      const newParams = new URLSearchParams(paramString);
      const sha1 = newParams.get('sha1');

      switch (newParams.get('media_type')) {
        case 'image':
        case 'video':
          return `${_.newRoute}?include.date=all+time&include.media.sha1[]=${sha1}&include.media.type[]=${newParams.get(
            'media_type',
          )}`;
        default:
          return undefined;
      }
    },
  },
  {
    oldRoute: 'https://fp.tools/home/compromised/credentials/items/:id',
    newRoute: '/cti/ato/credential/:id',
  },
];
