import * as React from 'react';

import easyMemo from '../../../../utils/Memoize';
import {
  TopFilterTableWidget,
  StoryBookRawBucketData,
  savedSearchName,
} from '../TopFilterTableWidget/TopFilterTableWidget';
import { SavedSearch } from '../../../../../core/types/SavedSearch';
import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';

export interface TopSourcesTableWidgetProps {
  savedSearch: SavedSearch;
  settings: VoidFunction;
  storyBookRawBucketData?: StoryBookRawBucketData;
  baseFilters: ExpoDashFilters,
}

export const TopSourcesTableWidget = easyMemo(({
  settings,
  savedSearch,
  storyBookRawBucketData,
  baseFilters,
}: TopSourcesTableWidgetProps): JSX.Element => (
  (
    <TopFilterTableWidget
      settings={settings}
      savedSearch={savedSearch}
      storyBookRawBucketData={storyBookRawBucketData}
      baseFilters={baseFilters}
      filterType="source"
      title={`Top Sources for "${savedSearchName(savedSearch, baseFilters?.query)}"`}
      help="Trend information around the sources seen most frequently in your saved search results."
    />
  )
));
