import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { ChatIcons } from '../../../../constants/DomainMonitoring';

const useStyles = makeStyles({
  messageIcon: {
    alignItems: 'center',
    backgroundColor: '#5c6ae0',
    borderRadius: '50px',
    color: '#fff',
    display: 'flex',
    fontSize: '2.4rem',
    fontWeight: '700',
    height: '50px',
    justifyContent: 'center',
    marginRight: '1rem',
    textTransform: 'uppercase',
    width: '50px',
  },
});

const MessageIcon = ({ type, letter }) => {
  const classes = useStyles();
  const icon = ChatIcons[letter.toLowerCase()] || ChatIcons.default;
  if (type === 'System') {
    return <img src={ChatIcons.flashpoint} alt="Flashpoint" className={classes.messageIcon} />;
  }
  return <img src={icon} alt={letter} className={classes.messageIcon} />;
};

MessageIcon.propTypes = {
  type: PropTypes.string,
  letter: PropTypes.string,
};

MessageIcon.defaultProps = {
  type: 'letter',
  letter: 'F',
};

export default MessageIcon;
