import * as React from 'react';

export const BarGraphIcon = () => (
  <svg width="189" height="100" viewBox="0 0 189 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1377_11848)">
      <rect x="7" y="7" width="171" height="82" rx="4" fill="white" />
    </g>
    <rect x="22" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="64" y="46.9229" width="4" height="35.077" fill="#186AA6" />
    <rect x="106" y="61.6924" width="4" height="20.3077" fill="#186AA6" />
    <rect x="148" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="36" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="78" y="34" width="4" height="48" fill="#186AA6" />
    <rect x="120" y="52.4614" width="4" height="29.5385" fill="#186AA6" />
    <rect x="162" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="50" y="52.4614" width="4" height="29.5385" fill="#186AA6" />
    <rect x="92" y="52.4614" width="4" height="29.5385" fill="#186AA6" />
    <rect x="134" y="61.6924" width="4" height="20.3077" fill="#186AA6" />
    <rect x="29" y="37.6924" width="4" height="44.3077" fill="#186AA6" />
    <rect x="71" y="58" width="4" height="24" fill="#186AA6" />
    <rect x="113" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="155" y="58" width="4" height="24" fill="#186AA6" />
    <rect x="43" y="61.6924" width="4" height="20.3077" fill="#186AA6" />
    <rect x="85" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="127" y="72.769" width="4" height="9.23078" fill="#186AA6" />
    <rect x="57" y="72.769" width="4" height="9.23078" fill="#186AA6" />
    <rect x="99" y="43.231" width="4" height="38.7693" fill="#186AA6" />
    <rect x="141" y="52.4614" width="4" height="29.5385" fill="#186AA6" />
    <defs>
      <filter id="filter0_d_1377_11848" x="0" y="0" width="189" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1377_11848" />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="3.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1377_11848" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1377_11848" result="shape" />
      </filter>
    </defs>
  </svg>
);
