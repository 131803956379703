import React, { useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircleOutline, CloudOff, ErrorOutline, ExpandMore, GetApp } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import style from '../sanctions.module.scss';

const EUResults = ((props) => {
  const [downloading, setDownloading] = useState(false);
  const euRef = useRef(null);

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;
    // attach to body for Firefox
    document.body.appendChild(link);
    // simulate a click
    link.click();
    // cleanup
    document.body.removeChild(link);
  };

  const handleDownload = (ref, filename) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    html2canvas(ref.current, { scale: 2, height: Math.min(ref.current.clientHeight, 8192) })
      .then((canvas) => {
        saveImage(canvas.toDataURL(), filename);
      })
      .then(() => {
        setDownloading(false);
      });
  };

  return (
    <Paper ref={euRef} className={style.card}>
      <Accordion data-cy="euAccordion" className={style.resultaccordion}>
        <AccordionSummary
          className={style.accordionsummary}
          expandIcon={<ExpandMore />}
        >
          <div data-cy="euList" className={style.accordionsummarycontent}>
            <div className={style.accordiontitle}>
              <div data-cy="euListTitle" className={style.listtitle}>
                <div className={style.medtitle}>EU List</div>
                {props.listAvailable ?
                  <>
                    {props.result.eu.results && props.result.eu.results.length !== 0 &&
                      <span className={style.someresults}>
                        <ErrorOutline className={style.resultswarn} />
                        {props.result.eu.results.length} Result
                        {props.result.eu.results.length > 1 && <span>s</span>}
                      </span>
                    }
                    {props.result.eu.results && props.result.eu.results.length === 0 &&
                      <span className={style.noresults}>
                        <CheckCircleOutline className={style.noresultscheck} />
                        No Results
                      </span>
                    }
                  </>
                  :
                  <>
                    <span className={style.offlineresults}>
                      <CloudOff className={style.offlinecloud} />
                      List Offline
                    </span>
                  </>
                }
              </div>
              {downloading && <CircularProgress />}
              <button
                type="button"
                className={style.resultdownload}
                onClick={(event) => {
                event.stopPropagation();
                handleDownload(euRef, `${props.result.searchTerm}-EU-results.png`);
              }}><GetApp />
              </button>
            </div>
            <div>Term Searched:   {props.result.searchTerm}</div>
            {props.listAvailable &&
            <>
              <div>URL:   <a target="_blank" href={props.result.eu.resourceUrl} rel="noreferrer">{props.result.eu.resourceUrl}</a></div>
              <div className={style.searchgrid}>
                <div>
                  Data Source Published Date:   {props.result.eu.resourcePublishDate}
                </div>
                <div>
                  Total Records Searched:   {props.result.eu.totalNumberOfRecordsInDataSource}
                </div>
                <div>
                  Date Searched:   {new Date(props.result.eu.responseDateTime).toLocaleString()}
                </div>
                <div>
                  Data Source Hash Value:   {props.result.eu.sha256}
                </div>
              </div>
            </>
            }
          </div>
        </AccordionSummary>
        <AccordionDetails className={style.accordiondetails}>
          {!props.listAvailable ?
            <>
              <div data-cy="euListOffline" className={style.noresultsmessage}>
                <div className={style.offlineunderline}> </div>
                <div className={style.resulttitle}>List Offline</div>
                <div className={style.lighttext}>Search for{' '}
                  <span className={style.bolded}>"{props.result.searchTerm}"</span>
                  {' '}could not be completed on{' '}
                  {props.result.eu.responseDateTime ?
                    new Date(props.result.eu.responseDateTime).toLocaleString()
                    : 'Undefined Date'
                  }.
                </div>
              </div>
            </>
          :
            <>
              {props.result.eu.results && props.result.eu.results.length !== 0 &&
                <div className={style.resultunderline}> </div>
              }
              {props.result.eu.results && props.result.eu.results.length === 0 &&
                <div className={style.noresultsmessage}>
                  <div className={style.noresultunderline}> </div>
                  <div className={style.resulttitle}>No Results Found</div>
                  <div className={style.lighttext}>Search for <span className={style.bolded}>"{props.result.searchTerm}"</span> returned no results on {props.result.eu.responseDateTime ? props.result.eu.responseDateTime : 'Undefined Date'}.</div>
                </div>
              }
              {props.result.eu.results && props.result.eu.results.map((entry, index) => (
                <React.Fragment key={entry['@euReferenceNumber']}>
                  <div className={style.resulttitle}>Result #{index + 1}</div>
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Details</div>
                    <div className={style.detailsgrid}>
                      <div>Type:   {entry.subjectType && entry.subjectType['@code'] ? entry.subjectType['@code'] : ''}</div>
                      <div>Designation Date:   {entry['@designationDate'] ? entry['@designationDate'] : ''}</div>
                      <div>Designation Details:   {entry['@designationDetails'] ? entry['@designationDetails'] : ''}</div>
                      <ul>Birthdate:   {entry.birthdate &&
                        Array.isArray(entry.birthdate) &&
                        entry.birthdate.map(item => (
                          item['@year'] &&
                          <li key={JSON.stringify(item)}>
                            {item['@birthdate'] ? item['@birthdate'] : `${item['@year']}
                            ${item['@monthOfYear'] && item['@dayOfMonth'] ? `-${item['@monthOfYear'].length < 2 ? '0' : ''}
                            ${item['@monthOfYear']}-${item['@dayOfMonth'].length < 2 ? '0' : ''}
                            ${item['@dayOfMonth']}` : ''}`}
                          </li>
                        ))
                        }
                        {entry.birthdate && !Array.isArray(entry.birthdate) &&
                          (entry.birthdate['@birthdate'] ? entry.birthdate['@birthdate'] : `${entry.birthdate['@year']}
                          ${entry.birthdate['@monthOfYear'] && entry.birthdate['@dayOfMonth'] ? `-${entry.birthdate['@monthOfYear'].length < 2 ? '0' : ''}
                          ${entry.birthdate['@monthOfYear']}-${entry.birthdate['@dayOfMonth'].length < 2 ? '0' : ''}
                          ${entry.birthdate['@dayOfMonth']}` : ''}`
                        )}
                      </ul>
                      <div>UN ID:   {entry['@unitedNationId'] ? entry['@unitedNationId'] : ''}</div>
                      <div>EU Reference Number:   {entry['@euReferenceNumber'] ? entry['@euReferenceNumber'] : ''}</div>
                      <ul>Citizenship:   {entry.citizenship && (
                        Array.isArray(entry.citizenship) ?
                        entry.citizenship.map(item => (
                          item['@countryDescription'] && <li key={item['@countryDescription']}>{item['@countryDescription']}</li>
                        ))
                      :
                      entry.citizenship['@countryDescription'] && `${entry.citizenship['@countryDescription']}`
                      )}
                      </ul>
                    </div>
                  </div>
                  {entry.remark && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Remarks</div>
                      <div>{entry.remark}</div>
                    </div>
                  )}
                  {entry.identification && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Identification</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Name on Document</TableCell>
                            <TableCell>Issued By</TableCell>
                            <TableCell>Known Expired</TableCell>
                            <TableCell>Known False</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Remark</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.identification) ?
                              entry.identification.map(item => (
                                <TableRow key={JSON.stringify(item)}>
                                  <TableCell>
                                    {item['@identificationTypeDescription'] ? item['@identificationTypeDescription']
                                    : item['@identificationTypeCode'] && `${item['@identificationTypeCode']}`}
                                  </TableCell>
                                  <TableCell>{item['@number'] ? item['@number'] : ''}</TableCell>
                                  <TableCell>{item['@nameOnDocument'] ? item['@nameOnDocument'] : ''}</TableCell>
                                  <TableCell>{item['@issuedBy'] ? item['@issuedBy'] : ''}</TableCell>
                                  <TableCell>{item['@knownExpired'] ? item['@knownExpired'] : ''}</TableCell>
                                  <TableCell>{item['@knownFalse'] ? item['@knownFalse'] : ''}</TableCell>
                                  <TableCell>{item['@region'] ? item['@region'] : ''}</TableCell>
                                  <TableCell>{item.remark ? item.remark : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>
                                  {entry.identification['@identificationTypeDescription'] ? entry.identification['@identificationTypeDescription']
                                  : entry.identification['@identificationTypeCode'] && `${entry.identification['@identificationTypeCode']}`}
                                </TableCell>
                                <TableCell>{entry.identification['@number'] ? entry.identification['@number'] : ''}</TableCell>
                                <TableCell>{entry.identification['@nameOnDocument'] ? entry.identification['@nameOnDocument'] : ''}</TableCell>
                                <TableCell>{entry.identification['@issuedBy'] ? entry.identification['@issuedBy'] : ''}</TableCell>
                                <TableCell>{entry.identification['@knownExpired'] ? entry.identification['@knownExpired'] : ''}</TableCell>
                                <TableCell>{entry.identification['@knownFalse'] ? entry.identification['@knownFalse'] : ''}</TableCell>
                                <TableCell>{entry.identification['@region'] ? entry.identification['@region'] : ''}</TableCell>
                                <TableCell>{entry.identification.remark ? entry.identification.remark : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.nameAlias && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Aliases</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Middle Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Whole Name</TableCell>
                            <TableCell>Name Language</TableCell>
                            <TableCell>Strong</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.nameAlias) ?
                              entry.nameAlias.map(item => (
                                <TableRow
                                  key={`${item['@firstName'] && `${item['@firstName']}`},
                                    ${item['@middleName'] && `${item['@middleName']}`},
                                    ${item['@lastName'] && `${item['@lastName']}`},
                                    ${item['@wholeName'] && `${item['@wholeName']}`}`
                                    }>
                                  <TableCell>{item['@firstName'] ? item['@firstName'] : ''}</TableCell>
                                  <TableCell>{item['@middleName'] ? item['@middleName'] : ''}</TableCell>
                                  <TableCell>{item['@lastName'] ? item['@lastName'] : ''}</TableCell>
                                  <TableCell>{item['@wholeName'] ? item['@wholeName'] : ''}</TableCell>
                                  <TableCell>{item['@nameLanguage'] ? item['@nameLanguage'] : ''}</TableCell>
                                  <TableCell>{item['@strong'] ? item['@strong'] : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>{entry.nameAlias['@firstName'] ? entry.nameAlias['@firstName'] : ''}</TableCell>
                                <TableCell>{entry.nameAlias['@middleName'] ? entry.nameAlias['@middleName'] : ''}</TableCell>
                                <TableCell>{entry.nameAlias['@lastName'] ? entry.nameAlias['@lastName'] : ''}</TableCell>
                                <TableCell>{entry.nameAlias['@wholeName'] ? entry.nameAlias['@wholeName'] : ''}</TableCell>
                                <TableCell>{entry.nameAlias['@nameLanguage'] ? entry.nameAlias['@nameLanguage'] : ''}</TableCell>
                                <TableCell>{entry.nameAlias['@strong'] ? entry.nameAlias['@strong'] : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.address && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Addresses</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>City</TableCell>
                            <TableCell>Street</TableCell>
                            <TableCell>P.O. Box</TableCell>
                            <TableCell>Zip Code</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Place</TableCell>
                            <TableCell>Country</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.address) ?
                              entry.address.map(item => (
                                <TableRow key={JSON.stringify(item)}>
                                  <TableCell>{item['@city'] ? item['@city'] : ''}</TableCell>
                                  <TableCell>{item['@street'] ? item['@street'] : ''}</TableCell>
                                  <TableCell>{item['@poBox'] ? item['@poBox'] : ''}</TableCell>
                                  <TableCell>{item['@zipCode'] ? item['@zipCode'] : ''}</TableCell>
                                  <TableCell>{item['@region'] ? item['@region'] : ''}</TableCell>
                                  <TableCell>{item['@place'] ? item['@place'] : ''}</TableCell>
                                  <TableCell>
                                    {item['@countryDescription'] ? item['@countryDescription']
                                    : item['@countryIso2Code'] && `${item['@countryIso2Code']}`}
                                  </TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>{entry.address['@city'] ? entry.address['@city'] : ''}</TableCell>
                                <TableCell>{entry.address['@street'] ? entry.address['@street'] : ''}</TableCell>
                                <TableCell>{entry.address['@poBox'] ? entry.address['@poBox'] : ''}</TableCell>
                                <TableCell>{entry.address['@zipCode'] ? entry.address['@zipCode'] : ''}</TableCell>
                                <TableCell>{entry.address['@region'] ? entry.address['@region'] : ''}</TableCell>
                                <TableCell>{entry.address['@place'] ? entry.address['@place'] : ''}</TableCell>
                                <TableCell>
                                  {entry.address['@countryDescription'] ? entry.address['@countryDescription']
                                  : entry.address['@countryIso2Code'] && `${entry.address['@countryIso2Code']}`}
                                </TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          }
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
});

EUResults.propTypes = {
  result: PropTypes.object,
  listAvailable: PropTypes.bool,
};

EUResults.defaultProps = {
  result: undefined,
  listAvailable: true,
};

export default EUResults;
