import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List as list, Map as map } from 'immutable';

import makeStyles from '@mui/styles/makeStyles';
import {
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import style from './navigation.module.scss';
import Icons from '../../constants/Icons';
import InternalLink from '../utils/InternalLink';
import UserActions from '../../actions/userActions';
import History from '../../utils/history';
import { UserContext } from '../../components/utils/Context';

const RC_URL = 'https://rc.fp.tools';
const PROD_URL = 'https://fp.tools';
const IGNITE_URL = '//app.flashpoint.io/investigations/my-investigations';

const useStyles = makeStyles(theme => ({
  navigation: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.navigation.main,
      borderRight: 'none',
      padding: 0,
      width: '17.5rem',
      zIndex: 1201,
      overflowX: 'clip',
    },
    '& .MuiIcon-root': {
      color: `${theme.palette.navigation.contrastText} !important`,
    },
    '& .MuiListItem-root': {
      cursor: 'pointer',
      display: 'flex',
      '& .MuiListItemText-primary': {
        color: theme.palette.navigation.contrastText,
        fontSize: '1.2rem',
      },
    },
  },
  main: {
    '& .MuiListItem-root:hover': {
      backgroundColor: '#F6F7FD',
      '& .MuiIcon-root': {
        color: '#5c6ae0 !important',
      },
      '& .MuiListItemText-primary': {
        color: '#5c6ae0',
      },
    },
  },
  subnavigation: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.navigation.main,
      borderRight: 'none',
      left: '17.5rem',
      padding: 0,
      width: '18rem',
      zIndex: 1200,
    },
    '& .MuiToolbar-root': {
      color: theme.palette.navigation.contrastText,
      height: '7.5rem',
      justifyContent: 'space-between',
      paddingLeft: '1.6rem',
      paddingRight: '1.6rem',
    },
    '& .MuiListItem-root': {
      borderColor: 'transparent !important',
      borderRadius: '2px',
      display: 'flex',
      flexDirection: 'row !important',
      padding: '5px',
      '&:hover': {
        backgroundColor: '#F6F7FD',
        '& .MuiIcon-root': {
          color: '#5c6ae0',
        },
        '& .MuiListItemText-primary': {
          color: '#5c6ae0',
        },
      },
    },
    '& .MuiTypography-h1': {
      textTransform: 'capitalize',
    },
    '& .MuiTypography-root:not(.MuiTypography-h1)': {
      fontSize: '1.2rem',
      textAlign: 'left !important',
    },
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '10px',
  },
  'subnav-header': {
    borderBottom: '1px solid #ccc',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '10px 0 10px 5px',
  },
  'subnav-items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}));

const Navigation = ({
  location,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext) || map();
  const apps = user.get('apps');

  const [active, setActive] = useState();
  const [dialog, setDialog] = useState();
  const [hasUiPermission, setHasUiPermission] = useState();
  const [navItems, setNavItems] = useState(list());
  const [subnavOpen, setSubnavOpen] = useState();
  const [selectedNav, setSelectedNav] = useState();
  const [selectedSubNav, setSelectedSubNav] = useState();
  const [hoverTimers, setHoverTimers] = useState(map());
  const RC = window.location.origin.includes(RC_URL);

  const loadNav = () => {
    const { pathname } = location;
    navItems.forEach((nav) => {
      if (pathname?.includes(nav?.getIn(['path', 0]))) setSelectedNav(nav);
      nav?.get('children')?.forEach((child) => {
        if (pathname?.includes(child?.getIn(['path', '0']))) setSelectedSubNav(child);
      });
    });
  };

  const onLogout = () => {
    UserActions.logout();
  };

  const onHome = () => {
    const pathname = user.getIn(['prefs', 'home', 'path', 0], '/home/intelligence/latest');
    const isSearch = /search/ig.test(pathname);
    History.push({
      pathname,
      query: isSearch && { date: 'last 7 days', since: 'now-7d', until: 'now' },
    });
    setSubnavOpen();
  };

  const onRC = () => {
    const { pathname, search, hash } = History.getCurrentLocation();
    const key = 'X-FP-RCBypass';
    const cookie = Cookies.withConverter({ write: v => v, read: v => v });
    const href = `${PROD_URL}${pathname}${search}${hash}`;
    const domain = window.location.hostname === 'localhost'
      ? 'localhost'
      : `.${window.location.hostname.split('.').splice(-2).join('.')}`;
    cookie.set(key, new Date().getTime(), { domain });
    window.location.href = href;
  };

  const onRoute = (v) => {
    let value = v;
    if (v?.get('route')) {
      // Route user to 1st item in nav based on their perms
      const children = v?.get('children');
      if (!children || children.isEmpty()) return null;
      children.reverse().forEach((child) => {
        if (user.get('prm').some(p => child.get('test').test(p))) {
          value = child;
        }
      });
    }
    if (v?.get('value') === 'user.logout') {
      setDialog('logout');
      return null;
    }

    const { query: { limit, query } } = History.getCurrentLocation();
    const defaults = v.get('searchType') === 'media' ? { date: 'Last 24 Hours', since: 'now-24h', until: 'now' } : { date: 'last 7 days', since: 'now-7d', until: 'now' };
    const isSearch = v.get('search') && (value.get('searchType') !== 'customer-credentials');
    const finalLimit = Array.isArray(limit) ? limit[0] : limit;
    const update = {
      pathname: value.getIn(['path', 0]),
      query: isSearch ? { ...defaults, limit: finalLimit, query } : {},
    };
    return update;
  };

  const clearHoverTimers = () => {
    hoverTimers.forEach(v => clearTimeout(v));
  };

  const onHover = (index) => {
    setActive(index);
    setSubnavOpen(true);
  };

  useEffect(() => {
    loadNav();
  }, [location, navItems]);

  useEffect(() => {
    if (!apps) return;
    let nav = map();
    apps
      .filter(v => !v.get('hidden') || v.get('desc'))
      .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
      .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
      .filter(v => user.get('prm').some(p => v.get('test').test(p)))
      .filter(v => !v.get('ui') || (v.get('ui') && user.get('prm').some(p => /acc.ui/.test(p))))
      .forEach((v) => {
        if (v.has('nav')) {
          const navItem = v.set('children', list());
          nav = nav.set(v.get('value'), navItem.set('index', nav?.size ?? 0));
        } else {
          const parents = v.get('parent');
          const multipleParents = (parents || '').split('|');
          multipleParents.forEach((parent) => {
            if (nav.has(parent) &&
              user.get('prm').some(p => v.get('test').test(p)) &&
              (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p)))) {
                nav = nav.setIn([parent, 'children'], nav.getIn([parent, 'children']).push(v));
            }
          });
        }
      });
    setNavItems(nav.valueSeq().toArray());
  }, [apps]);

  useEffect(() => {
    if (user && user.get('prm') && !hasUiPermission) {
      setHasUiPermission(user.get('prm').some(p => /acc.ui/.test(p)));
    }
  }, [user]);

  return (
    <div className={cx([style.navigation, classes.navigation])}>
      <Drawer
        variant="permanent"
        open
        PaperProps={{
          square: true,
        }}
        className={classes.main}>
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          className={style.brand}
          onClick={() => (RC
            ? onRC()
            : onHome())}>
          <div
            className={style['icon-flame']}
            dangerouslySetInnerHTML={{ __html: Icons.flame }} />
          <div
            className={style['icon-logo']}
            dangerouslySetInnerHTML={{ __html: Icons.logo }} />
        </div>
        <List className={classes.items}>
          {navItems
            .sort((a, b) => a.get('index') - b.get('index'))
            .map((v, k) => (
              <InternalLink
                key={v.get('value')}
                className={style.text}
                data-testid={`#nav-${v.get('value')}`}
                onClick={() => setSubnavOpen()}
                to={onRoute(v)}>
                <ListItem
                  className={cx([
                    style.item,
                    v?.get('value') === selectedNav?.get('value') ? style.active : '',
                  ])}
                  onMouseEnter={() => {
                    if (!v?.get('topLevelNav')) {
                      const timer = setTimeout(() => onHover(k), 200);
                      setHoverTimers(hoverTimers.set(k, timer));
                    }
                  }}
                  onMouseLeave={() => {
                    if (!v?.get('topLevelNav')) {
                      clearHoverTimers();
                    }
                  }}>
                  <ListItemIcon>
                    {v.get('icon').includes('fa')
                    ? <FontAwesomeIcon
                        className={style.icon}
                        icon={['far', v.get('icon')]} />
                    : <Icon className={style.icon}>{v.get('icon')}</Icon>}
                  </ListItemIcon>
                  <ListItemText className={style.text}>
                    {v.get('nav')}
                  </ListItemText>
                </ListItem>
              </InternalLink>))}
        </List>
        <div className={style.footer}>
          <div className={style.investigation}>
            <img
              alt="Investigations"
              src="/images/investigations.png" />
            <div className={style.label}>
              <b>Save, manage and collaborate</b> across Ignite, all in one place.
            </div>
            <Button
              onClick={() => History.navigateTo(IGNITE_URL, true)}
              className={style.ignite}
              variant="contained"
              data-testid="navigation.footer-ignite-link"
              color="secondary">Start Investigations
            </Button>
          </div>
          {`©${new Date().getFullYear()} Flashpoint`}
        </div>
      </Drawer>
      <div onMouseLeave={() => setSubnavOpen()}>
        <Drawer
          className={classes.subnavigation}
          open={subnavOpen}
          variant="persistent"
          onClose={() => setSubnavOpen()}
          PaperProps={{
            square: true,
          }}>
          <List className={classes.items}>
            <div className={classes['subnav-items']}>
              {navItems
                ?.[Number(active)]
                ?.get('children')
                ?.groupBy(v => v.get('subHeader'))
                ?.entrySeq()
                ?.map(([header, children]) =>
                  <React.Fragment key={header ?? children?.getIn([0, 'value'])}>
                    {children?.getIn([0, 'parent']) === 'collections' &&
                    <div className={classes['subnav-header']}>{header}</div>}
                    {children?.map(v => (
                      <InternalLink
                        key={v.get('value')}
                        className={style.text}
                        to={onRoute(v)}
                        data-testid={`#subnav-${v.get('value')}`}>
                        <ListItem
                          button={false}
                          className={cx([style.item, selectedSubNav === v && style.active])}>
                          <ListItemText className={style.text}>
                            {v.get('label')}
                          </ListItemText>
                        </ListItem>
                      </InternalLink>))}
                  </React.Fragment>)}
            </div>
          </List>
        </Drawer>
      </div>
      <Dialog
        name="dialog.logout"
        open={Boolean(dialog && dialog === 'logout')}
        onClose={() => setDialog('')}>
        <DialogTitle>
          Logout
        </DialogTitle>
        <DialogContent>
          Are you sure you want to logout?
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setDialog()}>
            Cancel
          </Button>
          <Button
            name="dialog.button.logout"
            className={style.accept}
            onClick={() => onLogout()}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
