import moment from 'moment';

import { Map as map, List as list } from 'immutable';

import History from './history';
import { knownHashes } from '../constants/org_profiles/Edm';

const CCM = {
  MeetsOrgProfile: (cred, profile, condition) => {
    const { query: {
      meets_org_profile_password_complexity,
      is_fresh,
      ignore_hashes,
      alert_date_type,
      password_reset_policy,
      ...query
    } } = History.getCurrentLocation();
    // If meets_org_profile_password_complexity is in the url, use the query parameters, otherwise use profile
    let passwordResetPolicy = (meets_org_profile_password_complexity)
      ? password_reset_policy
      : profile.get('password_reset_policy');
    let alertDateType = (meets_org_profile_password_complexity)
      ? alert_date_type
      : profile.get('alert_date_type');
    let isFresh = (meets_org_profile_password_complexity)
      ? is_fresh === 'true'
      : profile.get('is_fresh');
    let ignoreHashes = (meets_org_profile_password_complexity)
      ? ignore_hashes === 'true'
      : profile.get('ignore_hashes');
    // Due to change in edm profile template, need to check for new/old version
    if (!meets_org_profile_password_complexity && !profile.isEmpty() && profile.has('alerting')) {
      passwordResetPolicy = profile.hasIn(['alerting', 'password_reset_policy'])
        ? profile.getIn(['alerting', 'password_reset_policy'])
        : profile.get('password_reset_policy');
      alertDateType = profile.hasIn(['alerting', 'alert_date_type'])
        ? profile.getIn(['alerting', 'alert_date_type'])
        : profile.get('alert_date_type');
      isFresh = profile.hasIn(['alerting', 'is_fresh'])
        ? profile.getIn(['alerting', 'is_fresh'])
        : profile.get('is_fresh');
      ignoreHashes = profile.hasIn(['alerting', 'ignore_hashes'])
        ? profile.getIn(['alerting', 'ignore_hashes'])
        : profile.get('ignore_hashes');
    }
    const meetsResetPolicy = profile.isEmpty()
      ? false
      : (!passwordResetPolicy) || cred.getIn(['breach', (alertDateType && alertDateType === 'breached_at') ? 'created_at' : 'first_observed_at', 'timestamp']) > moment.utc().subtract(parseInt(passwordResetPolicy, 10), 'days').unix();
    const meetsIsFresh = profile.isEmpty()
      ? false
      : !isFresh || (cred.get('is_fresh') == null && !isFresh) || cred.get('is_fresh') === isFresh;
    let excludedFields = list();
    let optionalFields = list();
    let requiredFields = list();
    let numOptionalFields = 0;
    let length = null;

    if (meets_org_profile_password_complexity) {
      if (query.excluded) excludedFields = list(query.excluded.split(',')).map(v => ({ field: v, value: false }));
      if (query.optional) optionalFields = list(query.optional.split(',')).map(v => ({ field: v, value: null }));
      if (query.required) requiredFields = list(query.required.split(',')).map(v => ({ field: v, value: true }));
      if (query.num_optional) numOptionalFields = parseInt(query.num_optional, 10);
      if (query.length != null) length = parseInt(query.length, 10);
    } else {
      excludedFields = profile.getIn(['complexity', 'excluded_fields'], list()).map(v => ({ field: v.get('field'), value: false }));
      optionalFields = profile.getIn(['complexity', 'optional_fields'], list()).map(v => ({ field: v.get('field'), value: null }));
      requiredFields = profile.getIn(['complexity', 'required_fields'], list()).map(v => ({ field: v.get('field'), value: true }));
      numOptionalFields = profile.getIn(['complexity', 'num_optional_params_required']);
      length = profile.getIn(['complexity', 'length']);
    }
    const complexityFields = excludedFields.concat(optionalFields).concat(requiredFields);
    let matchedOptionals = 0;
    const hasCCMProfile = !profile.isEmpty();
    const hasPasswordComplexity = !hasCCMProfile || !profile?.get('complexity')
      ? false
      : Object.values(profile?.get('complexity')
        ?.toJS())
        ?.filter(v => (v && v?.length))
        ?.length;
    const meetsPasswordComplexity = !hasPasswordComplexity
      ? 'N/A'
      : cred.get('password_complexity')
        .map((p, key) => ({ key, value: p }))
        .every((p) => {
          const field = p.key.replace('has_', '');
          if (field === 'length') {
            return length == null ? true : p.value >= length;
          }

          const complexityField = complexityFields.find(v => v.field === field);
          // if the field isn't in the list of compleexity fields, it doesn't matter
          // what the value is
          if (!complexityField) return true;
          const complexityValue = complexityField.value;
          if (complexityValue == null) {
            if (p.value) matchedOptionals += 1;
            return true;
          }
          return complexityValue === p.value;
        }) && matchedOptionals >= numOptionalFields;
    const isHashedPassword = !cred.getIn(['password_complexity', 'probable_hash_algorithms'])
      ? false
      : cred.getIn(['password_complexity', 'probable_hash_algorithms'])
        .filter(val => (knownHashes.includes(val) ? val : false))
        .size > 0;
    const meetsIgnoreHash = !ignoreHashes || !isHashedPassword;
    if (condition === 'checkPasswordComplexity') return meetsPasswordComplexity;
    const meetsOrgProfile = meetsResetPolicy
      && meetsIsFresh
      && meetsPasswordComplexity
      && meetsIgnoreHash;
    return meetsOrgProfile;
  },
  GetOrgPasswordComplexity: (filters, user) => {
    const edmProfile = user.getIn(['org_profiles', filters.get('customer_id'), 'edm'])
      || user.getIn(['org_profiles', user.get('sid'), 'edm']);
    let updatedValues = map();
    if (!edmProfile) return updatedValues;

    edmProfile.get('complexity').forEach((c, p) => {
      switch (p) {
        case 'required_fields':
          updatedValues = updatedValues.set('required', c.map(f => f.get('field')).join() || null);
          break;
        case 'excluded_fields':
          updatedValues = updatedValues.set('excluded', c.map(f => f.get('field')).join() || null);
          break;
        case 'optional_fields':
          updatedValues = updatedValues.set('optional', c.map(f => f.get('field')).join() || null);
          break;
        case 'num_optional_params_required':
          updatedValues = updatedValues.set('num_optional', c || null);
          break;
        default:
          updatedValues = updatedValues.set(p, c);
          break;
      }
    });
    return updatedValues;
  },
};

export default CCM;
