import Api from '../../../../utils/api';

const ALERTING_URI = '/ui/v4/ep-credentials';

const saveBucket = (ownerId, keyclassId, credential) => (
  Api.post(`${ALERTING_URI}/`, {
    datasource_id: keyclassId.id,
    owner_id: ownerId,
    credential_type: 'aws_name_region',
    credential,
  },
  [200, 201, 400]));

const bulkUpload = (owner_id, keyclassId, data) => {
  const formData = new FormData();
  formData.append('upload_csv', ...data);
  formData.append('owner_id', owner_id);
  formData.append('datasource_id', keyclassId.id);
  formData.append('credential_type', 'aws_name_region');
  return Api.post(`${ALERTING_URI}/bulk/`, formData, [200, 201, 400]);
};

const saveAccount = (ownerId, keyclassId, credential, account_name) => (
  Api.post(`${ALERTING_URI}/`, {
    datasource_id: keyclassId.id,
    account_name,
    owner_id: ownerId,
    credential_type: 'aws_iam',
    credential,
  },
  [200, 201, 400]));

const loadAllS3 = (ownerId, pageNum, numPerPage) => Api.get(`${ALERTING_URI}/?owner_id=${ownerId}&page=${pageNum}&page_size=${numPerPage}`)
  .then(res => res.data);

const deleteIpAddress = UUID => (
  Api.delete(`${ALERTING_URI}/${UUID}/`, [200, 201, 204, 400])
);

const editS3Bucket = (UUID, credential) => (
  Api.put(`${ALERTING_URI}/${UUID}/`, {
    credential,
  }, [200, 201, 202, 400])
);

const editS3Account = (UUID, credential, account_name) => (
  Api.put(`${ALERTING_URI}/${UUID}/`, {
    account_name,
    credential,
  }, [200, 201, 202, 400])
);

export {
  saveBucket,
  saveAccount,
  loadAllS3,
  deleteIpAddress,
  editS3Account,
  editS3Bucket,
  bulkUpload,
};
