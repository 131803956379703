import React, { createRef, useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Clear, GetApp } from '@mui/icons-material';
import { Paper, CircularProgress, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import style from './sanctions.module.scss';
import History from '../../utils/history';
import Token from '../../utils/token';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';
import SanctionsSearchReports from './SanctionsSearchReports';
import SanctionsSearchStatus from './SanctionsSearchStatus';
import SanctionsSearchNotes from './SanctionsSearchNotes';

import { getStatusString, searchStatusEnum } from './Constants/SearchStatus';
import ChainalysisResults from './ResultComponents/ChainalysisResults';
import OFACResults from './ResultComponents/OFACResults';
import EUResults from './ResultComponents/EUResults';
import UNResults from './ResultComponents/UNResults';


const SanctionsSearchResults = ((props) => {
  const [data, setData] = useState();
  const [confirm, setConfirm] = useState(false);
  const [deleteSearchTerm, setDeleteSearchTerm] = useState(null);
  const [deleteSearchTermIndex, setDeleteSearchTermIndex] = useState(null);
  const pageRef = useRef(null);
  const [resultRefs, setResultRefs] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    setData(props.data);
    if (props.data && props.data.results) {
      setResultRefs(Array(props.data.results.length).fill().map(
        (ref, index) => (resultRefs[Number(index)] ? resultRefs[Number(index)] : createRef()),
      ));
    }
    return function cleanup() {
      isOnPage = false;
    };
  }, [props.data]);

  // method to update the status from the child component
  const onStatusChange = (status) => {
    const temp = { ...data };
    temp.status = status;
    setData(temp);
  };

  // helper method to delete a search term
  const handleDeleteTerm = (searchTerm, index) => {
    setConfirm(false);
    if (!data || !data.id || data.status !== getStatusString(searchStatusEnum.PREPAYMENT_CHECK)) {
      return;
    }
    if (!deleting) {
      setDeleting(true);
    }
    Api.delete(`/ui/v4/sanctions/saved_search/${data.id}/search_term`, { id: data.id, searchTerm }, [200], 30000, {}, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then(() => {
        if (!isOnPage) {
          return;
        }
        // remove the search term from the results on the UI side
        // do not directly modify data, clone it into temp (note this is a shallow clone)
        const temp = { ...data };
        // clone the results array, which we are modifying
        const newResults = data.results.slice();
        newResults.splice(index, 1);
        temp.results = newResults;
        // if there are no more terms, redirect to the main search page
        if (newResults.length === 0) {
          History.push({
            pathname: '/home/sanctions/main',
          });
        } else {
          setData(temp);
          setDeleting(false);
          setDeleteSearchTerm(null);
          setDeleteSearchTermIndex(null);
        }
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data: d } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(d?.detail, deleteSearchTerm));
        setDeleting(false);
        setDeleteSearchTerm(null);
        setDeleteSearchTermIndex(null);
      });
  };

  const handleConfirm = (confirmTerm, confirmTermIndex) => {
    setDeleteSearchTerm(confirmTerm);
    setDeleteSearchTermIndex(confirmTermIndex);
    setConfirm(true);
  };

  const handleClose = () => {
    setConfirm(false);
    setDeleteSearchTerm(null);
    setDeleteSearchTermIndex(null);
  };

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;
    // attach to body for Firefox
    document.body.appendChild(link);
    // simulate a click
    link.click();
    // cleanup
    document.body.removeChild(link);
  };

  const handleDownload = (ref, filename) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    html2canvas(ref.current, { scale: 2, height: Math.min(ref.current.clientHeight, 8192) })
      .then((canvas) => {
        saveImage(canvas.toDataURL(), filename);
      })
      .then(() => {
        setDownloading(false);
      });
  };

  return (
    <React.Fragment>
      {downloading && <CircularProgress />}
      <button type="button" className={style.allresultdownload} onClick={() => handleDownload(pageRef, 'results.png')}><GetApp /> Download all results</button>
      <div ref={pageRef} className={style.search}>
        <div className={style.info}>
          {!props.hideInfo &&
          <div data-cy="searchInfo" className={style.searchcomponent}>
            <div className={style.title}>
              {props.isRetro === true ? 'Search Terms Report' : 'Search Info'}
            </div>
            <Paper className={style.card}>
              <div className={style.searchinfo}>
                {props.isRetro !== true &&
                <div>
                  <div className={style.tooltip}>
                    Client Name
                  </div>
                  <div data-cy="clients" className={style.medtitle}>
                    {data !== undefined ?
                      <div>
                        {data.clientNames && data.clientNames.map(client => (
                          <div key={client}>{client}</div>
                        ))}
                      </div>
                      : ''
                    }
                  </div>
                </div>
                }
                <div>
                  <div data-cy="datetime" className={style.tooltip}>
                    {props.isRetro === true ? 'Date Range of Report' : 'Date of Search'}
                  </div>
                  <div className={style.medtitle}>
                    {props.isRetro === true && data && data.prevResponseDateTime ? `${new Date(data.prevResponseDateTime).toLocaleString()} - ` : ''}
                    {data !== undefined ? new Date(data.responseDateTime).toLocaleString() : ''}
                  </div>
                </div>
                {!props.isRetro &&
                  <div data-cy="isFuzzy">
                    <div className={style.tooltip}>
                      Fuzzy Search
                    </div>
                    <div className={style.medtitle}>
                      {data && data.is_fuzzy ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                }
                {props.isRetro !== true && data && data.status && (
                  <SanctionsSearchStatus data-cy="status" data={data} onStatusChange={onStatusChange} />
                )}
              </div>
            </Paper>
          </div>}
          <div className={style.searchcomponent}>
            <div className={style.title}>
              Search Terms {props.isRetro === true ? ' with New Results' : ''}
            </div>
            <div data-cy="searchTerms" className={style.searchterms}>
              <Dialog
                open={confirm && deleteSearchTerm != null && deleteSearchTermIndex != null}
                onClose={handleClose}>
                <DialogTitle>Are you sure you want to delete this search term ?</DialogTitle>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleDeleteTerm(deleteSearchTerm, deleteSearchTermIndex)}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              {data !== undefined && data.results && data.results.map((result, index) => (
                <div key={`${result.searchTerm}${result.address}`}>
                  <Paper className={style.card}>
                    <div className={style.termtitle}>
                      <div className={style.medtitle}>
                        {result.searchTerm}
                      </div>
                      <a
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                        resultRefs[Number(index)].current.scrollIntoView();
                        window.scrollBy(0, -150);
                      }}>Jump to results
                      </a>
                      {data.id && data.status === getStatusString(searchStatusEnum.PREPAYMENT_CHECK)
                      && (
                        <React.Fragment>
                          <button
                            type="button"
                            className={style.removebutton}
                            onClick={() => handleConfirm(result.searchTerm, index)}><Clear />
                          </button>
                          {deleting && <CircularProgress />}
                        </React.Fragment>
                      )}
                    </div>
                    <React.Fragment>
                      {((result.un && result.un.results && result.un.results.length !== 0)
                        || (result.eu && result.eu.results && result.eu.results.length !== 0)
                        || (result.ofac && result.ofac.results && result.ofac.results.length !== 0)
                        || (result.chainalysis && result.chainalysis.rating === 'highRisk')) &&
                          <div className={style.termunderlineresults}> </div>
                        }
                      {((!result.un || !result.un.results || result.un.results.length === 0)
                        && (!result.eu || !result.eu.results || result.eu.results.length === 0)
                        && (
                          !result.ofac
                          || !result.ofac.results
                          || result.ofac.results.length === 0
                        )
                        && (!result.chainalysis || result.chainalysis.rating !== 'highRisk')) &&
                          <div className={style.termunderlineclear}> </div>
                        }
                      <div className={result.chainalysis ?
                        style.subsectionchain :
                        style.subsection}>
                        <div className={style.text}>OFAC</div>
                        <div className={style.text}>EU</div>
                        <div className={style.text}>UN</div>
                        {result.chainalysis && <div className={style.text}>RATING</div>}
                      </div>
                      <div className={result.chainalysis ?
                        style.subsectionchain :
                        style.subsection}>
                        <div data-cy="ofacResultCount" className={style.num}>{(result.ofac && result.ofac.results) ? result.ofac.results.length : '0'}</div>
                        <div data-cy="euResultCount" className={style.num}>{(result.eu && result.eu.results) ? result.eu.results.length : '0'}</div>
                        <div data-cy="unResultCount" className={style.num}>{(result.un && result.un.results) ? result.un.results.length : '0'}</div>
                        {result.chainalysis &&
                          <div data-cy="unResultCount" className={style.numchain}>
                            {result.chainalysis.rating === 'lowRisk' && <span className={style.lowrisk}>Low Risk</span>}
                            {result.chainalysis.rating === 'highRisk' && <span className={style.highrisk}>High Risk</span>}
                            {result.chainalysis.rating !== 'lowRisk' && result.chainalysis.rating !== 'highRisk' && <span className={style.unknownrisk}>Unknown Risk</span>}
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  </Paper>

                </div>
              ))}
              {props.isRetro === true &&
              <div className={style.noresultscard}>
                {props.data !== undefined
                  && props.data.results
                  && props.data.results.length === 0 &&
                  <Paper>
                    <span className={style.medtitle}>No New Results</span>
                    <div className={style.termunderlineclear}> </div>
                    <span className={style.text}>
                      Terms were searched against all{' '}
                      lists for which they had no results previously.
                    </span>
                  </Paper>
                }
              </div>
              }
            </div>
          </div>
        </div>
        {props.isRetro !== true && !props.hideInfo &&
        <React.Fragment>
          <div className={style.notesreports}>
            <div data-cy="sanctionsNotes" className={style.notes}>
              <div className={style.title}>Notes</div>
              <Paper>
                <SanctionsSearchNotes data={data} />
              </Paper>
            </div>
            <div data-cy="sanctionsSearchReports" className={style.reports}>
              <div className={style.title}>Flashpoint Associated Reports</div>
              <Paper>
                <SanctionsSearchReports data={data} />
              </Paper>
            </div>
          </div>
        </React.Fragment>}
        <div data-cy="results" className={style.resultsfor}>
          {data !== undefined && data.results && data.results.map((result, i) => (
            <React.Fragment key={`${result.searchTerm}${result.address}`}>
              <div ref={resultRefs[Number(i)]} className={style.resultssection}>
                <div data-cy={`${result.searchTerm}_result`} className={style.title}>
                  {props.isRetro === true ? 'New ' : ''}Results for "{result.searchTerm}"
                </div>
                {result.ofac &&
                  <OFACResults result={result} listAvailable={data.availableLists.ofacAvailable} />
                }
                {result.eu &&
                  <EUResults result={result} listAvailable={data.availableLists.euAvailable} />
                }
                {result.un &&
                  <UNResults result={result} listAvailable={data.availableLists.unAvailable} />
                }
                {result.chainalysis &&
                  <ChainalysisResults
                    result={result}
                    listAvailable={data.availableLists.chainalysisAvailable} />
                }
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
});

SanctionsSearchResults.propTypes = {
  data: PropTypes.object,
  hideInfo: PropTypes.bool,
  isRetro: PropTypes.bool,
};

SanctionsSearchResults.defaultProps = {
  data: undefined,
  hideInfo: false,
  isRetro: false,
};

export default SanctionsSearchResults;
