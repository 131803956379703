import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  Paper,
  Switch,
} from '@mui/material';

import style from './accounts.module.scss';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import InternalLink from '../utils/InternalLink';
import { UserContext } from '../utils/Context';

const Accounts = ({ data }) => {
  const user = useContext(UserContext);

  const [showBody, setShowBody] = useState(false);

  const onSearch = (k, v) => {
    const pathname = '/home/search/accounts';
    const query = { query: '', [k]: v };
    return ({ pathname, query });
  };

  return (
    <Grid
      fluid
      name="component.accounts"
      className={cx([style.base, style.accounts])}>
      {/* loading account post */}
      {data.isEmpty() &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {/* account post loaded */}
      {!data.isEmpty() && data.has('id') &&
      <Row>
        <Col xs={12} className={style.header}>
          <div>
            <div
              name="cards.header.title"
              className={cx([style.h4, 'h4', style.cap])}>
              {Text.StripHighlight(data.getIn(['site', 'title']))}
            </div>
            {data.getIn(['site', 'description', 'raw']) &&
            <Icon
              name="accounts.header.description"
              data-for="shop.tooltip"
              data-tip={`
                <b>Created</b>: ${moment.utc(data.getIn(['site', 'created_at', 'timestamp'])).format('MM/DD/YYYY')}<br>
                <b>URI</b>: ${data.getIn(['site', 'source_uri'])}<br/>
                <b>Description</b>: ${data.getIn(['site', 'description', 'raw'])}`}
              className={style.help}>
              info_outline
            </Icon>}
          </div>
          <div className={style.title}>
            <Icon className={style.icon}>
              credit_card
            </Icon>
            <div
              className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
              name="cards.body.title">
              {Text.Highlight(
                data.getIn(['highlight', 'account_organization', 0]) ||
                data.get('account_organization') ||
                data.getIn(['highlight', 'email_domain', 0]) ||
                data.get('email_domain'))}
            </div>
          </div>
          {data.getIn(['sort_date']) &&
          <div>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Date:</div>
              <div>
                {moment.utc(data.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}
              </div>
            </div>
            <Icon
              name="accounts.date"
              data-for="date.tooltip"
              data-tip={`This field is the first date that Flashpoint saw that account<br />
                on the account shop. This date field will not change if we re-scrape<br />
                the page and observe the same account a second time.`}
              className={style.help}>
              help
            </Icon>
          </div>}
        </Col>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Source:</div>
              <InternalLink
                to={onSearch('sites', `${data.getIn(['site', 'title'])}`)}
                name="accounts.body.source"
                className={cx([data.getIn(['site', 'title']) && style.a, 'a'])}>
                {data.getIn(['site', 'title']) || '-'}
              </InternalLink>
            </div>
            {data.hasIn(['account_organization']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Site:</div>
              <InternalLink
                to={(data.getIn(['account_organization'])
                  ? onSearch('account_organization', `"${data.getIn(['account_organization'])}"`)
                  : null)}
                name="accounts.body.account_domain"
                className={cx([data.getIn(['account_organization']) && style.a, 'a'])}>
                {Text.Highlight(
                  data.getIn(['highlight', 'account_organization', 0]) ||
                  data.getIn(['account_organization']) || '-')}
              </InternalLink>
            </div>}
            {data.getIn(['email_domain']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Email Domain:</div>
              <InternalLink
                to={((data.getIn(['email_domain']))
                  ? onSearch('email_domain', `"${data.getIn(['email_domain'])}"`)
                  : null)}
                name="accounts.body.domain"
                className={cx([data.getIn(['email_domain']) && style.a, 'a'])}>
                {Text.Highlight(
                  data.getIn(['highlight', 'email_domain', 0]) ||
                    data.getIn(['email_domain']) || '-')}
              </InternalLink>
            </div>}
            {data.getIn(['site_actor', 'names', 'handle']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Seller:</div>
              <InternalLink
                to={(data.getIn(['site_actor', 'names', 'handle'])
                  ? onSearch('seller', `"${data.getIn(['site_actor', 'names', 'handle'])}"`)
                  : null)}
                name="accounts.body.seller"
                className={cx([data.getIn(['site_actor', 'names', 'handle']) && style.a, 'a'])}>
                {Text.Highlight(
                  data.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                    data.getIn(['site_actor', 'names', 'handle']) || '-')}
              </InternalLink>
            </div>}
            <div className={style.hero}>
              <div>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Price(s):</div>
                {data.getIn(['prices', 0]) &&
                <div name="accounts.body.price">
                  {data.get('prices')
                    .entrySeq()
                    .map(([k, v]) => (
                      <div
                        key={k}
                        className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                        {v.getIn(['value'])
                          ? v.getIn(['currency', 'abbreviation'])
                          : ''}
                        {v.getIn(['value'])
                          ? v.getIn(['value']).toFixed(2)
                          : v.getIn(['raw'])}
                      </div>))}
                </div>}
                {!data.getIn(['prices']) &&
                <div>
                  <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>N/A</div>
                </div>}
              </div>
            </div>
          </Paper>
        </Col>
        <Col xs={12}>
          <Paper className={style.card}>
            {data.getIn(['body', 'text/html+sanitized']) && user.getIn(['prefs', 'enable_pii']) &&
            <div className={style.pii}>
              <div className={style.warning}>
                <div
                  data-tip="If you enable, you may be accessing PII data"
                  data-for="body.tooltip"
                  className={cx([style.h4, 'h4', style.mont, 'mont'])}>View Identifying Details:
                </div>
                <Switch
                  color="secondary"
                  className={style.toggle}
                  checked={showBody}
                  onChange={() => setShowBody(!showBody)} />
              </div>
              {showBody &&
              <div className={style.body}>
                {Text.Highlight(
                  Text.Market(
                    data.getIn(['body', 'text/html+sanitized'])))}
              </div>}
            </div>}
            <div className={style.header}>
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>
                Account Details
              </div>
            </div>
            <div className={style.body}>
              {data.hasIn(['bank_accounts', 0, 'account_type']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Account Type:</div>
                <div>{data.getIn(['bank_accounts', 0, 'account_type'])}</div>
              </div>}
              {data.hasIn(['account_type']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Account Type:</div>
                <div>{data.getIn(['account_type'])}</div>
              </div>}
              {data.hasIn(['balance']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Balance:</div>
                <div>{data.getIn(['balance'])}</div>
              </div>}
              {data.hasIn(['points_balance']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Points Balance:</div>
                <div>{data.getIn(['points_balance'])}</div>
              </div>}
              {data.hasIn(['has_email_access']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Email Access?</div>
                <div>{data.getIn(['has_email_access']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.hasIn(['has_credit_card']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Credit Card?</div>
                <div>{data.getIn(['has_credit_card']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.hasIn(['credit_cards', 0, 'raw']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Credit Card:</div>
                <div>{data.getIn(['credit_cards', 0, 'raw'])}</div>
              </div>}
              {data.hasIn(['has_cookies_available']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Cookies Available?</div>
                <div>{data.getIn(['has_cookies_available']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.hasIn(['last_transaction_at', 'timestamp']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last Transaction At:</div>
                <div>{moment.utc(data.getIn(['last_transaction_at', 'timestamp']) * 1000)
                  .format('MMM D, YYYY HH:mm')}
                </div>
              </div>}
              {data.hasIn(['has_screenshot']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Screenshot?</div>
                <div>{data.getIn(['has_screenshot']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.getIn(['basetypes']).includes('paypal') && data.hasIn(['is_verified']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Is Verified?</div>
                <div>{data.getIn(['is_verified']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.getIn(['basetypes']).includes('ebay') && data.hasIn(['has_paypal']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Paypal?</div>
                <div>{data.getIn(['has_paypal']) ? 'Yes' : 'No'}</div>
              </div>}
              {data.getIn(['basetypes']).includes('ebay') && data.hasIn(['feedback_score']) &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Feedback Score?</div>
                <div>{data.getIn(['feedback_score'])}</div>
              </div>}
            </div>
          </Paper>
        </Col>
      </Row>}
      {/* invalid id/post not found */}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Post not found" />}
      <ReactTooltip id="date.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="shop.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="body.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Accounts.propTypes = {
  data: PropTypes.object,
};

Accounts.defaultProps = {
  data: map(),
};

export default Accounts;
