import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';

import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Icon,
  Paper,
} from '@mui/material';

import style from './report.module.scss';

import SearchActions from '../../actions/searchActions';
import Api from '../../utils/api';
import Common from '../../utils/common';
import History from '../../utils/history';
import Invalid from '../utils/Invalid/Invalid';
import Messages from '../../constants/Messages';
import InternalLink from '../utils/InternalLink';
import Text from '../../utils/text';

const useStyles = makeStyles(theme => ({
  report: {
    '& .MuiChip-root': {
      backgroundColor: '#5c6ae0',
      color: '#fff',
      margin: theme.spacing(0.5),
    },
    '& .MuiChip-icon': {
      color: '#fff',
    },
  },
}));

const Report = ({
  anchor,
  apps,
  cdnTag,
  glossary,
  glossaryHighlight,
  related,
  report,
}) => {
  const classes = useStyles();
  const [carousel, setCarousel] = useState(-1);
  const [images, setImages] = useState(list());
  const [standup, setStandup] = useState(false);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  const link = (source) => {
    const test = /(?:fp\.tools(\/home\/.*?\/.*?)|flashpoint-intel\.com)\//i;
    const fptools = source.get('original', '').match(test);
    const icon = fptools ? apps
      .filter(v => !v.get('hidden') && v.getIn(['path', 0]) !== undefined)
      .find(v => v.getIn(['path', 0]).indexOf(fptools[1]) !== -1) : '';
    const iconName = icon ? icon.get('icon') : '';
    return !fptools ? source.get('title') : (
      <a className={cx([style.a, 'a'])} href={source.get('original')}>
        <Icon className={cx([style.icon, 'material-icons', (iconName === '') ? style.hidden : ''])}>
          {iconName}
        </Icon>{source.get('title') || source.get('type')}
      </a>);
  };

  const replaceTextNodes = (node, definitions, google = false) => {
    if (node && definitions) {
      node.childNodes.forEach((el) => {
        // If this is a text node, replace the text
        if (el.nodeType === 3 && !el.textContent.includes('Image')) {
          // Ignore this node it it an empty text node
          if (el.nodeValue.trim() !== '') {
            if (definitions.some(term => el.nodeValue.toLowerCase().includes(Text.GlossaryKey(term.get('key')).toLowerCase()))) {
              let { textContent } = el;
              const replacements = {};
              definitions.forEach((term) => {
                const newSpan = document.createElement('span');
                newSpan.className = 'glossary-term';
                newSpan.setAttribute('data-offset', "{ 'left': 75 }");
                newSpan.setAttribute('data-for', 'report.tooltip');
                newSpan.setAttribute('data-tip', Text.GlossaryValue(term.get('value')).match(/(.{1,75})(?:\n|$| )/g).join('<br />'));
                // eslint-disable-next-line security/detect-non-literal-regexp
                const re = new RegExp(`\\b${Text.GlossaryKey(term?.get('key') || '')}\\b`, 'i');
                // Was an issue replacing terms if a term is in the definition of
                // another term. So tooltips were being nested. Instead of replacing
                // take note of where in the text the terms occur and replace later
                textContent.replace(re, (word, index) => {
                  const actualIndex = (Number.isNaN(Number(index))) ?
                    textContent.indexOf(word) : index;

                  newSpan.textContent = word;

                  // Check to make sure future glossary terms do not overlap with
                  // existing replacements. This would cause incorrect replacement
                  // in cases where another term is within another term like
                  // "Phishing" in "Spear Phishing"
                  let addReplacement = true;
                  const toIndex = actualIndex + Text.GlossaryKey(term.get('key')).length;
                  const overlappingIndecies = Object.keys(replacements)
                    .filter(key => (parseInt(key, 10) >= actualIndex
                      && parseInt(key, 10) <= toIndex));
                  overlappingIndecies.forEach((key) => {
                    // Make sure we only replace the term that is encapsulating
                    if (replacements[String(key)].key.length > Text.GlossaryKey(term.get('key'))) {
                      addReplacement = false;
                    } else {
                      delete replacements[String(key)];
                    }
                  });
                  if (addReplacement) {
                    // () is not rendering in the html so the length
                    // miss matches the way the term
                    // is displayed so replacing () with spaces fixes the issue
                    replacements[Number(actualIndex)] = { key: Text.GlossaryKey(term.get('key').replace('(', '').replace(')', '')), value: newSpan.outerHTML };
                  }
                });
              });
              // Check for items that match on two glossary terms and remove the shortest.
              // Example: 'digital skimmer' should match the glossary only on 'digital skimmer'
              // and not 'skimmer'
              const arr = Object.entries(replacements)
                .filter((item, index, array) => {
                  if (index > 0) {
                    const left = array[index - 1][1].key;
                    const right = item[1].key;
                    if (left.includes(right) && left.length > right.length) {
                      return false;
                    }
                  }
                  return true;
                });
              // Loop over the indexes of where glossary terms occur starting at
              // the end so the indexes are not messed up by replacements
              for (let i = arr.length - 1; i >= 0; i -= 1) {
                const value = replacements[arr[Number(i)][0]];
                const index = parseInt(arr[Number(i)][0], 10);
                textContent = textContent.substring(0, index) + value.value +
                textContent.substring(index + value.key.length);
              }

              // Handle replacing textNodes that are split by highlighted terms
              // by replacing the child, not the entire element
              const template = document.createElement('template');
              if ((el.nextSibling || el.previousSibling) && google) {
                template.innerHTML = textContent;
                el.parentElement.replaceChild(template.content, el);
              } else if (!google) {
                template.innerHTML = `<${el.parentElement.tagName} ${el.parentElement.classList.value} ${el.parentElement.href ? `href=${el.parentElement.href} target="_blank"` : ''}>${textContent}</${el.parentElement.tagName}>`;
                el.replaceWith(template.content.firstChild);
              } else {
                template.innerHTML = `<${el.parentElement.tagName} ${el.parentElement.href ? `href=${el.parentElement.href} target="_blank"` : ''}>${textContent}</${el.parentElement.tagName}>`;
                el.parentElement.replaceWith(template.content.firstChild);
              }
            }
          }
        } else if (el.hasAttribute && !el.hasAttribute('href')) { // Else recurse on this node, if not a link
          replaceTextNodes(el, definitions, google);
        }
      });
    }
  };

  const formatGlossary = () => {
    if (glossaryHighlight) {
      const body = document.querySelector('[class*="report"] [class*="card"] [class*="body"]');
      replaceTextNodes(body, glossary.filter(term => !term.get('exclude')), (report && report.getIn(['body', 'text/html+sanitized'])) && !report.getIn(['body', 'text/html+sanitized']).startsWith('<html>'));
      ReactTooltip.rebuild();

      // Safari does not implement these events. Alternative available
      window.onbeforeprint = () => {
        document.querySelectorAll('.glossary-term').forEach((el) => {
          const element = el;
          element.outerHTML = el.innerHTML;
        });
      };

      window.onafterprint = () => {
        replaceTextNodes(body, glossary.filter(term => !term.get('exclude'), ((report) && report.getIn(['body', 'text/html+sanitized'])) && !report.getIn(['body', 'text/html+sanitized']).startsWith('<html>')));
        ReactTooltip.rebuild();
      };
    }
  };

  const formatReport = () => {
    if (!report.isEmpty() && report.get('id')) {
      const standupValue = standup || report.get('tags').includes('Standup');
      setStandup(standupValue);
      if (!standupValue) {
        setTitle(report.get('title'));
        setDate(moment.utc(report.get('version_posted_at')).format('MMMM DD, YYYY'));
      } else if (standupValue && report.get('title').indexOf('-') !== -1) {
        setTitle(report.get('title').split('-')[0].trim());
        setDate(moment.utc(report.get('title').split('-')[1].trim()).format('MMMM DD, YYYY'));
      } else {
        const match = report.get('title').match(/(0?[1-9]|1[012]).(0?[1-9]|[12][0-9]|3[01]).\d{4}/g);
        setTitle('Daily Standup');
        if (match?.[0]) {
          setDate(moment.utc(match[0], 'MM.DD.YYYY').format('MMMM DD, YYYY'));
        }
      }
      if (report.get('published_status') === 'draft') {
        setTitle(`${report.get('title')} (DRAFT)`);
      }
    }
  };

  const handleReportLinks = () => {
    // Handle external links within the report
    document.querySelectorAll('div[class*="card"] div[class*="body"] a:not([href*="fp.tools"]):not([href^="#"])').forEach((linkValue) => {
      linkValue.addEventListener('click', (e) => {
        Common.Events.DispatchExternalLinkEvent(linkValue.getAttribute('href'), e);
      });
    });

    // Google Docs API messed up doing what looks like data-saferedirecturl urls
    // which adds &usg=random_string to the end of links. A change to the google
    // apps script is needed, but fix existing links with the messed up links
    document.querySelectorAll('div[class*="card"] div[class*="body"] a:not([href^="#"])').forEach((linkValue) => {
      const href = linkValue.getAttribute('href');
      const decodedUri = decodeURIComponent(href);
      if (href) {
        linkValue.setAttribute('href',
          href !== decodedUri // already encoded uri
            ? decodedUri
            : decodeURIComponent(encodeURIComponent(href.replace(/&usg=.*\/?/g, ''))));
      }
    });


    // Handle anchor links
    let anchorsChanged = false;
    // set anchor links event listeners
    document.querySelectorAll('a[href^="#"]').forEach((linkValue) => {
      const linkText = linkValue.innerText;
      let slug;
      if (linkText !== 'back to top') {
        slug = linkText.toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        const linkedElement = document.getElementById(linkValue.getAttribute('href').slice(1));
        if (linkedElement) {
          linkedElement.setAttribute('id', slug);
          const { pathname } = linkValue;
          const lastCharSlash = pathname.substr(-1) === '/';
          // remove existing anchor prior to building linked urls
          const base = anchor ? pathname?.split('/')?.slice(0, -1)?.join('/') : pathname;
          linkValue.setAttribute('href', lastCharSlash ? `${base}${slug}` : `${base}/${slug}`);
        }
      }
      linkValue.addEventListener('click', (e) => {
        e.preventDefault();
        const { pathname, query, hash } = History.getCurrentLocation();
        const { srcElement } = e;
        const bookmarkElement = document.querySelector(`a[id="${slug}"]`);
        const scrolledElement = document.getElementById(slug || srcElement.getAttribute('href').slice(1));
        if (bookmarkElement) {
          bookmarkElement.scrollIntoView({ block: 'start' });
          window.scrollBy(0, (-1 * bookmarkElement.nextElementSibling.offsetHeight) - 135);
          History.navigateTo({ pathname: srcElement.pathname || pathname, query, hash });
        } else if (scrolledElement) {
          scrolledElement.scrollIntoView({ block: 'start' });
          window.scrollBy(0, (-1 * scrolledElement.nextElementSibling.offsetHeight) - 135);
          if (slug) {
            History.navigateTo({ pathname: srcElement.pathname || pathname, query, hash });
          }
        } else {
          window.scrollTo(0, 0);
        }
      });
      anchorsChanged = true;
    });

    // Scroll to anchor
    if (anchorsChanged && anchor && !['featured'].includes(anchor)) {
      const scrolledElement = document.querySelector(`[id="${anchor}"]`);
      if (scrolledElement) {
        scrolledElement.scrollIntoView({ block: 'start' });
        window.scrollBy(0, (-1 * scrolledElement.nextElementSibling.offsetHeight) - 135);
      } else {
        window.scrollTo(0, 0);
      }
    }

    // Processing finished
    const processedFlag = document.createElement('div');
    processedFlag.style.display = 'none';
    processedFlag.id = 'report-processed';
    document.body.appendChild(processedFlag);
  };

  const onDelete = (reportId) => {
    Api.delete(`/ui/v4/reports/${reportId}`, null, [200, 204], 30000, {})
      .then(res => (res.ok
        ? SearchActions.set(['search', 'info', 'message'], Messages.ReportDeletion)
        : new Error(Messages.ReportDeletionError)))
      .catch(() => {
        SearchActions.set(['search', 'info', 'message'], Messages.ReportDeletionError);
      });
  };

  const onRoute = (value) => {
    const { query } = History.getCurrentLocation();
    return {
      pathname: `/home/intelligence/reports/report/${value.get('id')}`,
      query: { ...query, query: null, skip: null, tags: null, date: '', since: '', until: '' } };
  };

  const onTag = tag =>
    History.push(`/home/intelligence/latest/${encodeURIComponent(tag)}`);

  useEffect(() => {
    formatGlossary();
    formatReport();
    handleReportLinks();
  }, [report]);

  useEffect(() => {
    const captions = [];
    const imageValues = [];
    if ((report.get('body')) && report.getIn(['body', 'text/html+sanitized']).startsWith('<html>')) {
      [...document.querySelectorAll('[class*=report_] span img')]
        .forEach(image => imageValues.push(image)
          && captions.push((image.parentNode.parentNode.nextElementSibling !== null)
          && (image.parentNode.parentNode.nextElementSibling.tagName === 'H3')
          && (image.parentNode.parentNode.nextElementSibling.getElementsByTagName('span').length > 0
            ? image.parentNode.parentNode.nextElementSibling.getElementsByTagName('span')[0]
            : '<p></p>')));
      [...imageValues].forEach((img, k) => {
        if (img) {
          img.addEventListener('click', () => {
            if (k < images.size) setCarousel(k);
          });
          // update the image src to use cdn
          if (!img.src.includes(cdnTag)) {
            // eslint-disable-next-line no-param-reassign
            img.src = `${img.src}?size=orig${cdnTag}`;
          }
          const parent = img.parentNode.parentNode;
          parent.style.textAlign = 'center';
        }
      });
      setImages((report.get('assets') || list())
        .map((v, k) => map({ source: v, caption: (captions[String(k)] || <div />).textContent })));
    } else {
      [...document.querySelectorAll('p.image')].forEach(image =>
        imageValues.push(image.getElementsByTagName('img')[0]) &&
        captions.push((image.nextElementSibling && image.nextElementSibling.classList) &&
        (image.nextElementSibling.classList.toString().includes('caption')
          ? image.nextElementSibling
          : '<p></p>')));
      [...imageValues].forEach((img, k) => {
        if (img) {
          img.addEventListener('click', () => {
            if (k < images.size) setCarousel(k);
          });
          const parent = img.parentNode.parentNode;
          parent.style.textAlign = 'center';
        }
      });
      setImages((report.get('assets') || list())
        .map((v, k) => map({ source: v, caption: (captions[String(k)] || <div />).textContent })));
    }
  }, [report, related]);

  return (
    <Grid
      container
      id="report"
      className={cx([
        style.report,
        classes.report,
      ])}>
      <Grid item xs={12}>
        {report.isEmpty() && <CircularProgress />}
        {!report.isEmpty() && !report.get('id') && !report.get('lookup') &&
          <Invalid title="Report not found" />}
        {!report.isEmpty() && report.get('id') &&
        <div>
          {report.get('published_status') === 'draft' &&
          <div className={style.draft} data-html2canvas-ignore>
            <div>This report is still a draft&nbsp;
              <Button
                color="secondary"
                variant="contained"
                onClick={(e) => { e.preventDefault(); setShowDelete(true); }}
                className={style.button}>
                Delete
              </Button>
            </div>
          </div>}
          <div className={style.header}>
            <div>
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Intelligence Report</div>
              <div className={style.title}>
                <Icon color="secondary" className={style.icon}>
                  {report.get('tags').includes('Standup') ? 'access_time' : 'lightbulb_outline'}
                </Icon>
                <div className={cx([style.h0, 'h0', style.raj, 'raj'])}>
                  {Text.Highlight(title, true)}
                </div>
              </div>
              {date &&
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                {date}
              </div>}
            </div>
            <img
              className={style.watermark}
              alt="flashpoint"
              src="/images/fp-email-logo.png" />
          </div>
          <Paper className={style.card}>
            {!report.get('tags').includes('Standup') && report.get('title_asset') &&
            <img
              height="357"
              width="650"
              alt={Text.Strip(report.get('title'))}
              className={cx([style.image, style.blur])}
              src={report.get('title_asset') ?
                `/ui/v4${report.get('title_asset')}?size=orig${cdnTag}` :
                '/static/placeholder.svg'} />}
            {report.get('tags').includes('Standup') &&
            <img
              height="357"
              width="650"
              alt={Text.Strip(report.get('title'))}
              className={cx([style.image, style.blur])}
              src="/static/daily_standup.png" />}
            <div className={cx([report.getIn(['body', 'text/html+sanitized']).startsWith('<html>') && style.google,
              style.body,
              'inline',
              report.get('tags').includes('Standup') && style.standup])}>
              {Text.Highlight(
                Text.HighlightColor(
                  Text.Style(
                    Text.Report(
                      Text.Links(
                        Text.GoogleLinks(
                          report.getIn(['body', 'text/html+sanitized']),
                        ),
                      ),
                    ),
                  ),
                ), true)}
            </div>
          </Paper>
          <Paper className={style.card} data-html2canvas-ignore >
            {!report
              .get('sources', list())
              .filter(v => map.isMap(v))
              .filter(v => v.get('original'))
              .size ? null :
              <div>
                <div className={cx([style.h2, 'h2', style.libre, 'libre'])}>Report Sources</div>
                <div className={style.sources}>
                  <ol className={style.ol}>
                    {report
                      .get('sources', list())
                      .filter(v => map.isMap(v))
                      .filter(v => v.get('original'))
                      .reduce((a, b) => (a.find(v => v.get('original') === b.get('original'))
                        ? a
                        : a.push(b)), list())
                      .map(v => (
                        <li
                          key={v.get('original')}
                          className={cx([style.source,
                            /https?:/ig.test(v.get('title')) && style.breakall])}>
                          {link(v)}
                        </li>))}
                  </ol>
                </div>
              </div>}
            {!report.get('tags').isEmpty() &&
            <div>
              <div className={cx([style.h2, 'h2', style.libre, 'libre'])}>Topics</div>
              <div className={style.chips}>
                {report
                  .get('tags')
                  .map(v => (
                    <Chip
                      key={v}
                      label={v}
                      icon={<Icon>local_offer</Icon>}
                      onClick={() => onTag(v)} />))}
              </div>
            </div>}
          </Paper>
          {related.isEmpty() && <CircularProgress />}
          {!related.isEmpty() &&
          <Paper className={style.card} data-html2canvas-ignore>
            <div className={cx([style.h2, 'h2', style.libre, 'libre'])}>Related Reports</div>
            <div className={style.related}>
              {related
                .filter(v => v.get('id') !== report.get('id'))
                .take(3)
                .map(v => (
                  <InternalLink
                    key={v.get('id')}
                    to={onRoute(v)}>
                    <CardMedia>
                      <img
                        loading="lazy"
                        height="357"
                        width="650"
                        alt={Text.Strip(v.get('title'))}
                        className={cx([style.image, style.blur])}
                        src={v.get('title_asset') ?
                          `/ui/v4${v.get('title_asset')}?size=thumbnail${cdnTag}` :
                          '/static/placeholder.svg'} />
                    </CardMedia>
                    <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                      {moment.utc(v.get('version_posted_at')).format('MMMM DD, YYYY')}
                    </div>
                    <div className={cx([style.h2, 'h2', style.cap, 'cap', style.raj, 'raj'])}>{v.get('title')}</div>
                  </InternalLink>))}
            </div>
          </Paper>}
          <div className={style.footer}>&copy;{`${moment.utc().format('Y')} Flashpoint`}</div>
        </div>}
      </Grid>
      {showDelete &&
      <Dialog
        data-html2canvas-ignore
        open={showDelete}
        onClose={() => setShowDelete()}>
        <DialogTitle>Are you sure you want to delete this report?</DialogTitle>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
            setShowDelete(false);
          }}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
            onDelete(report.get('id'));
            setShowDelete(false);
          }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>}
      <ReactTooltip
        html
        data-html2canvas-ignore
        id="report.tooltip"
        place="bottom"
        effect="solid"
        event="click"
        globalEventOff="click" />
      <ModalGateway data-html2canvas-ignore>
        {Boolean(images.size && carousel > -1) &&
        <Modal onClose={() => setCarousel(-1)}>
          <Carousel
            views={images
              .map(v => ({
                caption: v.get('caption'),
                source: `/ui/v4${v.get('source')}?size=orig${cdnTag}`,
              }))
              .toArray()}
            currentIndex={carousel} />
        </Modal>}
      </ModalGateway>
    </Grid>);
};

Report.propTypes = {
  anchor: PropTypes.string,
  apps: PropTypes.object,
  cdnTag: PropTypes.string,
  glossary: PropTypes.object,
  glossaryHighlight: PropTypes.bool,
  related: PropTypes.object,
  report: PropTypes.object,
};

Report.defaultProps = {
  anchor: '',
  apps: list(),
  cdnTag: '',
  glossary: list(),
  glossaryHighlight: true,
  related: list(),
  report: map(),
};

export default Report;
