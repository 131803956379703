import moment from 'moment';
import { fromJS } from 'immutable';

import { cachedGet } from '../../../utils/api';
import SearchUtils from '../../../utils/searchUtils';
import Query from '../../../containers/Alerting/query';

const AlertingUrl = '/ui/v4/alerts';

const StatusAlerting = [200, 201, 204, 500, 501, 502, 503, 504];
const StatusSearch = [200, 400, 500, 501, 502, 503, 504];

const removeArchivedTag = (filters) => {
  if (filters?.tags) {
    const tags = filters.tags.split(',');
    const filteredTags = tags.filter(v => v !== '-archived');

    return {
      ...filters,
      tags: (filteredTags.length > 0) ? filteredTags.join(',') : undefined,
    };
  }
  return filters;
};

const onGetTotalActiveKeywords = async (filters = {}) => {
  const query = {
    active: true,
    owner_id: filters.ownerId,
  };
  if (filters.tags) {
    const tags = filters.tags.split(',');
    let is_curated = null;
    if (tags.includes('sent')) {
      is_curated = true;
    } else if (tags.includes('-sent')) {
      is_curated = false;
    }
    if (is_curated !== null) {
      query.is_curated = is_curated;
    }
  }

  const keywordClassIds = filters.keywordClasses
    .filter(kc => !['email_domain', 'enterprise_domain'].includes(kc.name))
    .map(kc => kc.id);

  return cachedGet(`${AlertingUrl}/keywords`, query, StatusAlerting, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    // Limiting count to keywords that belong to the keyword_classes in alerting
    // state (this doesn't include email_domain, enterprise_domain, etc.)
    .then(res => res.filter(kw => keywordClassIds.includes(kw.keyclass_id)));
};

const onGetTotalHits = async (filters = {}) => {
  const format = 'YYYY-MM-DD';
  const { sinceDate, untilDate } = SearchUtils.parseDate(filters);
  const since = moment.utc(sinceDate).startOf('day').format(format);
  // data cached at midnight and time-range is not-inclusive, so subtract 1 day
  const until = moment.utc(untilDate).startOf('day').format(format);
  const query = {
    entity_type: 'organization',
    entity_ids: filters.ownerId,
    time_range: `${since},${until}`,
    ...(filters.tags && { tags: filters.tags }),
  };

  const finalQuery = removeArchivedTag(query);

  return cachedGet(`${AlertingUrl}/alerts/metrics/entity`, finalQuery, StatusAlerting, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then(res => (res.length > 0 ? res[0] : {}))
    .then(res => (res.count ? res.count : 0));
};

const onGetTotalPostsScanned = (filters = {}) => {
  const format = 'YYYY-MM-DD';
  const { sinceDate, untilDate } = SearchUtils.parseDate(filters);
  const since = moment.utc(sinceDate).startOf('day').format(format);
  // data cached at midnight and time-range is not-inclusive, so subtract 1 day
  const until = moment.utc(untilDate).startOf('day').format(format);
  const query = {
    time_range: `${since},${until}`,
  };

  return cachedGet(`${AlertingUrl}/alerts/metrics/posts_scanned`, query, StatusSearch)
    .then(res => (res.ok ? res.data : []))
    .then(res => res.hits.total);
};

const onGetHitsBySource = (filters = {}) => {
  const format = 'YYYY-MM-DD';
  const { sinceDate, untilDate } = SearchUtils.parseDate(filters);
  const since = moment.utc(sinceDate).startOf('day').format(format);
  // data cached at midnight and time-range is not-inclusive, so add 1 day
  const until = moment.utc(untilDate).startOf('day').format(format);
  const query = {
    entity_ids: filters.ownerId,
    entity_type: 'organization',
    time_range: `${since},${until}`,
    ...(filters.tags && { tags: filters.tags }),
  };

  const basetypeMappings = Query.genBasetypeMappings(fromJS(filters.basetypes));

  /**
   * This function accepts the count agg response, shaped like so:
   * [{
   *    date: '2020-01-01,2020-01-02',
   *    basetypes: { basetype: ['a', 'b'],  count: 1 }, { basetype: ['c', 'd'], count: 2 } }
   *  }, ...]
   *
   * and generates a sum for each basetype's info, by checking if the basetype
   * term is included each of the agg reponse's basetype entries
   * It returns data that the stack bar chart can read, like so:
   * [{
   *    key: <timestamp>,
   *    basetypeLabel1: 1,
   *    basetypeLabel2: 2,
   *  }, ...] */
  const transformCountData = counts =>
    counts.map((c) => {
      const transformed = {};
      // For each basetype, generate a corresponding sum by checking if the
      // basetype's term values are in any of the count basetype entries
      basetypeMappings.forEach((basetype) => {
        // Calculate count for all count terms that match a datasources basetypes
        const summedCount = c.basetypes.reduce((sum, b) => {
          const {
            basetype: countBasetypes,
            count,
          } = b;
          for (const term of basetype.value) {
            if (countBasetypes.includes(term)) {
              return sum + count;
            }
          }
          return sum;
        }, 0);
        // Add the basetype label with summed count data to transformed
        transformed[basetype.label] = summedCount;
      });
      return ({
        key: c.date,
        ...transformed,
      });
    });

  const finalQuery = removeArchivedTag(query);

  return cachedGet(`${AlertingUrl}/alerts/metrics/basetype_per_day`,
      finalQuery, StatusAlerting, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then(res => (res && res.counts ? res.counts : []))
    .then(res => ({
      data: transformCountData(res),
      colors: Query.basetypeColorMap,
      basetypeMappings,
    }));
};

const onGetHitsByKeywords = (filters = {}) => {
  const format = 'YYYY-MM-DD';
  const { sinceDate, untilDate } = SearchUtils.parseDate(filters);
  const since = moment.utc(sinceDate).startOf('day').format(format);
  // data cached at midnight and time-range is not-inclusive, so add 1 day
  const until = moment.utc(untilDate).startOf('day').format(format);
  const query = {
    entity_id: filters.ownerId,
    entity_type: 'organization',
    time_range: `${since},${until}`,
    ...(filters.tags && { tags: filters.tags }),
  };

  const transformKeywordData = (keywords) => {
    if (!keywords) return [];
    return keywords.map(kw => ({
      count: kw.count,
      value: kw.keyword_id,
    }));
  };

  const finalQuery = removeArchivedTag(query);

  return cachedGet(`${AlertingUrl}/alerts/metrics/keyword`,
      finalQuery, StatusAlerting, 30000, {}, true)
    .then(res => (res.ok ? res.data : []))
    .then(res => transformKeywordData(res));
};

export default {
  onGetTotalActiveKeywords,
  onGetTotalHits,
  onGetTotalPostsScanned,
  onGetHitsBySource,
  onGetHitsByKeywords,
};
