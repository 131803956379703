import * as React from 'react';
import { useState } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import ReactTooltip from 'react-tooltip';

import { LineChart } from './LineChart';
import { BarChart } from './BarChart';
import { ArrowIndicator } from './ArrowIndicator';
import Dashboard from '../../../Dashboard';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import easyUseState from '../../../../../hooks/easyUseState';

import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import type { ResultCountPreferences } from '../../../../../core/types/ExpoDashPreferences';

const useStyles = makeStyles(({
  root: {
    width: '100%',
  },
  contentGrid: {
    alignItems: 'center',
    display: 'flex',
  },
  count: {
    fontFamily: "'Open Sans', sans-seri",
    fontSize: '3.8rem',
    fontWeight: 300,
    lineHeight: '1',
    margin: '0',
    padding: '0',
  },
  chart: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '.5rem',
  },
  changeIndicator: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    margin: '0',
    padding: '0',
    lineHeight: '1',
  },
  icon: {
    fontSize: '1.8rem',
    marginLeft: '.5rem',
  },
  linkEnabled: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  linkDisabled: {
    cursor: 'not-allowed',
  },
  organization: {
    alignItems: 'center',
    color: '#A5A5A5',
    display: 'flex',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1',
    margin: '.5rem 0',
    padding: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tooltip: {
    overflowY: 'hidden',
  },
}));

export interface CountWidgetProps {
  config: ResultCountPreferences,
  filters: ExpoDashFilters,
  label: string,
  tooltip: string,
  query: (savedSearch: object, filters: object) => Promise<object>;
  /** Mouse click handler */
  setDialog: (value: object) => void,
  onClick?: (defaultSearch: boolean) => void,
}

/** Displays the number of mentions of an organization with a sparkline chart */
export const CountWidget = ({
  config,
  filters,
  label,
  tooltip,
  query,
  onClick,
  setDialog,
}: CountWidgetProps): JSX.Element => {
  const [results, setResults] = easyUseState({});
  const [defaultSearch, setDefaultSearch] = useState<boolean>(false);
  const styles = useStyles();

  easyUseEffect(() => {
    if (!config?.savedSearch) {
      setDefaultSearch(true);
    }
    query(config?.savedSearch ? config.savedSearch : null, filters)
    .then((res) => {
      setResults(res);
    });
    if (Object.keys(results).length > 0) {
      setResults({});
    }
  }, [config.savedSearch, filters.query, filters.date]);

  const calculatePercentage = (now: number, previous: number): number => {
    let a = now || 0;
    let b = previous || 0;
    if (previous < now) {
      a = previous;
      b = now;
    }
    if (b === 0) {
      return a;
    }
    const percent = Math.round((a / b) * 100);
    return ((now > previous) ? percent : -(100 - percent));
  };

  const percent = calculatePercentage(results?.total?.now, results?.total?.previous);

  return (
    <Dashboard
      data={results}
      title={
        config?.savedSearch?.name
        || filters?.query
      }
      settings={setDialog}>
      <div onClick={() => onClick(defaultSearch)} className={styles.root} style={{ cursor: onClick ? 'pointer' : 'not-allowed' }}>
        <h3 className={styles.organization}>
          {label}
          <InfoOutlined
            className={styles.icon}
            data-for="help.tooltip"
            data-tip={tooltip}
            />
        </h3>
        <div className={styles.contentGrid}>
          <div>
            <h2
              className={[onClick ? styles.linkEnabled : styles.linkDisabled, styles.count].join(' ')}>
              {results?.total?.now?.toLocaleString('en-US') || 0}
            </h2>
          </div>
          <ArrowIndicator
            percent={percent}
            timePeriod={results?.timePeriod || ''}
            className={styles.changeIndicator}
          />
        </div>
        <div style={{ height: '44px' }}>
          {config?.graphType === 'bar-graph' ? <BarChart data={results?.chart}/> : <LineChart data={results?.chart}/>}
        </div>
      </div>
      <ReactTooltip id="help.tooltip" html place="right" effect="solid" className={styles.tooltip} />
    </Dashboard>
  );
};

CountWidget.defaultProps = {
  onClick: null,
};
