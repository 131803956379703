import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import style from './prompt.module.scss';

const Prompt = ({
  accept,
  acceptText,
  cancel,
  cancelText,
  children,
  manual,
  open,
  title,
}) => {
  const onManual = (link, auth) => {
    /* eslint-disable-next-line security/detect-non-literal-fs-filename */
    window.open(`${link}?${auth}`);
  };

  return (
    <Dialog open={Boolean(open)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: children }} />
        {manual &&
        <div>&nbsp;Click&nbsp;
          <a
            role="presentation"
            className={cx([style.a, 'a'])}
            onClick={() => onManual(manual, moment.utc().unix())}>here
          </a> to view.
        </div>}
      </DialogContent>
      <DialogActions>
        <Button
          name="cancel"
          onClick={() => cancel()}>
          {cancelText}
        </Button>
        <Button
          color="secondary"
          className={style.accept}
          name="accept"
          onClick={() => accept()}>
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Prompt.propTypes = {
  children: PropTypes.string,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  manual: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  accept: PropTypes.func,
  cancel: PropTypes.func,
};

Prompt.defaultProps = {
  children: '',
  acceptText: '',
  cancelText: '',
  manual: '',
  open: false,
  title: '',
  accept: null,
  cancel: null,
};

export default Prompt;
