import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  ListItemSecondaryAction,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { grey } from '@mui/material/colors';

const EditButtons = ({ item, index, onEdit, onDelete }) => (
  <ListItemSecondaryAction>
    {onEdit &&
      <IconButton onClick={() => onEdit(item, index)} size="large">
        <CreateIcon style={{ color: grey[500] }} fontSize="small" />
      </IconButton>}
    {onDelete &&
      <IconButton onClick={() => onDelete(item, index)} size="large">
        <CloseIcon style={{ color: grey[500] }} fontSize="small" />
      </IconButton>}
  </ListItemSecondaryAction>
);

EditButtons.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

EditButtons.defaultProps = {
  item: null,
  index: -1,
  onEdit: null,
  onDelete: null,
};

export default EditButtons;
