/* eslint-disable no-param-reassign, react/no-array-index-key */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { devClient } from '../../../../gql/apolloClient';

import { sanitizeBins, sanitizeBinTags, sanitizeBinPFL } from '../../../../utils/cfm';
import query, { bulkEditBins } from '../ManageBins/query';

const useStyles = makeStyles(() => ({
  controller: {
    margin: '4%',
    width: '90%',
  },
  editor: {
    display: 'flex',
    flexDirection: 'column',
  },
  pflError: {
    color: '#f00',
    display: 'block',
    margin: 0,
    position: 'absolute',
    marginLeft: '4%',
    marginTop: '-3%',
  },
  tagError: {
    color: '#f00',
    display: 'block',
    margin: 0,
    position: 'absolute',
    marginLeft: '4%',
    marginTop: '-1.5%',
  },
  hidden: {
    display: 'none',
  },
  warning: {
    display: 'flex',
    margin: 0,
    position: 'absolute',
  },
  success: {
    marginBottom: '5%',
    marginLeft: '4%',
  },
  button: {
    margin: '2.5%',
  },
}));

type Bin = {
  id: number;
  name: string;
};

interface customerBins extends Bin {
  binTags: Bin[];
  id: number;
  number: number;
  binNumber: number;
  roi: number;
  binRoi: unknown;
  salesforceId: number;
}

interface BinData {
  salesforceId: number;
  size: number;
  skip: number;
  sortBy: string;
  binsFilter: string;
  tagsFilter: string | string[];
}

interface BinsProps {
  variables: BinData;
  bins: customerBins[];
  unselect: () => void;
  close: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const BulkEditBins = ({ variables, bins, close, unselect }: BinsProps) => {
  const classes = useStyles();
  const { control, handleSubmit, formState: { errors } } = useForm();

  const [bulkEdit, {
    loading,
    error,
    data,
  }] = useMutation(bulkEditBins, {
    client: devClient,
    refetchQueries: [{ query, variables }],
  });

  const onSubmit = (binData: { [x: string]: string; }) => {
    // split and sanitize the new tags
    const newBinTags = binData.tags ? binData.tags.split(',') : [];
    const sanitizedTags = sanitizeBinTags(newBinTags);

    // update sanitized tags to be in the form of {'name': 'tag_name'}
    const tags = binData.tags ? sanitizedTags.map((name: string) => ({ name })) : [];

    bins.forEach((b) => {
      b.binTags = tags;
    });

    const updatedBins = bins.map((b) => {
      b.salesforceId = variables.salesforceId;
      b.binNumber = Number(b.number);
      b.binRoi = sanitizeBinPFL(binData.pfl);
      delete b.number;
      delete b.roi;
      return b;
    });

    bulkEdit({
      variables: {
        salesforceId: variables.salesforceId,
        bins: sanitizeBins(updatedBins),
      },
    });

    unselect();
  };

  const validateBinTags = (value: string) => {
    const tags = value ? value.split(',') : [];
    const bad_tags = [];

    tags.forEach((tag) => {
      if (/[^a-zA-Z0-9-_ ]/.test(tag)) {
        bad_tags.push(tag);
      }
    });

    if (bad_tags.length !== 0) {
      return false;
    }

    return true;
  };

  const validatePFL = (value: string) => {
    if (/^\d{0,15}\.?(\d{0,2})$/.test(value) || value === undefined) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <form
      className={classes.editor}
      onSubmit={handleSubmit(formData => onSubmit(formData))}>
      <Controller
        render={({ field }) => <TextField className={classes.controller} id="standard-basic" label="Potential Fraud Loss" {...field} />}
        shouldUnregister
        name="pfl"
        control={control}
        rules={{
          maxLength: 15,
          validate: value => validatePFL(value),
        }}
      />
      <p className={errors.pfl ? classes.pflError : classes.hidden}>
        {errors.pfl && errors.pfl.type === 'maxLength' && 'Potential Fraud Loss should be at most 15 digits!'}
        {errors.pfl && errors.pfl.type === 'validate' && 'Potential Fraud Loss should not contain more than two decimal places or non numerical values!'}
      </p>
      <Controller
        render={({ field }) => <TextField className={classes.controller} id="standard-basic" label="Tags" {...field} helperText="Add tags as a comma-separated list" />}
        shouldUnregister
        name="tags"
        control={control}
        rules={{
          validate: value => validateBinTags(value),
        }}
        defaultValue=""
      />
      <p className={errors.tags ? classes.tagError : classes.hidden}>
        {errors.tags && errors.tags.type === 'validate' && 'BIN tags should not contain special characters!'}
      </p>
      <div>
        {loading && <CircularProgress size={25} thickness={2} />}
        {data &&
          <div className={classes.success}>
            BINs have been updated successfully!
            <p className={classes.warning}>
              NOTE: Data for modified BINs will appear in approximately 24 hours.
            </p>
          </div>
        }
        {
          !loading && !data ?
            <React.Fragment>
              <Button className={classes.button} type="submit">Submit</Button>
              <Button onClick={close}>Close</Button>
            </React.Fragment> :
            <Button className={classes.button} onClick={close}>Close</Button>
        }
        {error && <div>An errror occurred while updating the selected BINs</div>}
      </div>
    </form>
  );
};

BulkEditBins.propTypes = {
  variables: PropTypes.object,
  bins: PropTypes.array,
  close: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
};

BulkEditBins.defaultProps = {
  variables: {},
  bins: {},
};

export default BulkEditBins;
