import PropTypes from 'prop-types';
import React from 'react';
import { isEqual } from 'lodash';
import { fromJS } from 'immutable';
import { deepDiff } from '../../utils/object';

const debugIsEqual = (a, b, debug) => {
  // eslint-disable-next-line no-console
  if (debug && !isEqual(a, b)) console.log(deepDiff(a, b));
  return isEqual(a, b);
};

const baseComponentShouldNotUpdate = (prevProps, nextProps, debug) => {
  let deps = [prevProps, nextProps];
  if (nextProps.dependencies && prevProps.dependencies) {
    deps = [nextProps.dependencies, prevProps.dependencies];
  } else if (nextProps.memoKey && prevProps.memoKey) {
    deps = [nextProps.memoKey, prevProps.memoKey];
  }
  return debugIsEqual(...deps, debug);
};

const dependenciesComponentShouldNotUpdate = dependencyStrings => (
  (prevProps, nextProps, debug) => {
    const filterForDeps = myProps =>
      dependencyStrings.map(depStr => fromJS(myProps).get(depStr));
    return debugIsEqual(filterForDeps(prevProps), filterForDeps(nextProps), debug);
  }
);

const memoKeyComponentShouldNotUpdate = makeMemoKey => (
  (prevProps, nextProps, debug) => (
    debugIsEqual(makeMemoKey(prevProps), makeMemoKey(nextProps), debug)
  )
);

const easyMemo = (myComponent, opts = { debug: false }) => {
  const { dependencies, makeMemoKey, componentShouldNotUpdate } = (opts || {});
  let myComponentShouldNotUpdate = baseComponentShouldNotUpdate;
  if (componentShouldNotUpdate) {
    myComponentShouldNotUpdate = componentShouldNotUpdate;
  } if (makeMemoKey) {
    myComponentShouldNotUpdate = memoKeyComponentShouldNotUpdate(makeMemoKey);
  } else if (dependencies) {
    myComponentShouldNotUpdate = dependenciesComponentShouldNotUpdate(dependencies);
  }
  return React.memo(myComponent, (prevProps, nextProps) => (
    myComponentShouldNotUpdate(prevProps, nextProps, opts.debug)
  ));
};

/**
 * MemoWrapper inline-memoizes blocks of JSX
 *  within the return value of the render function of a larger compoonent
 *
 * MemoWrapper can be used two ways:
 * 1. <MemoWrapper><p>hello world!</p></MemoWrapper>
 * 2. <MemoWrapper childrenFunc={()=><p>hello world!</p>}>
 * The first way is best for readability, not as much for performance. Use it to wrap up small blocks
 * The second way is best for performance. It prevents huge piles of JSX and other javascript commands
 *  from getting executed during each render function (trust me, I have tested this)
 * Use childrenFunc to wrap big lists of JSX especially those created by array.map()
 *
 * Pass dependencies and memoKey as props to <MemoWrapper> the same way that you would
 *  to any easyMemo'd component:
 * <MemoWrapper memoKey={dataSliceId} childrenFunc={() => (...)} />
 * <MemoWrapper dependencies={[data, filters, prefs]} childrenFunc={() => (...)} />
 *
 * IMO, refactoring blocks of JSX into their own components
 *  is  way to improve performance, coupling, type safety, readability, comprehensibility, etc
 * But refactoring needs to be iterative and easy. So MemoWrapper is vitally important
 */
const ChildWrapper = easyMemo(({ children }) => children);
const ChildFuncWrapper = easyMemo(({ childsFunc }) => (childsFunc()));
export const MemoWrapper = ({ children, childrenFunc, dependencies, memoKey }) => {
  if (children) {
    return <ChildWrapper dependencies={dependencies} memoKey={memoKey}>{children}</ChildWrapper>;
  } else if (childrenFunc) {
    return <ChildFuncWrapper
      dependencies={dependencies}
      memoKey={memoKey}
      childsFunc={childrenFunc}
    />;
  }
  return null;
};

MemoWrapper.propTypes = {
  children: PropTypes.object,
  childrenFunc: PropTypes.func,
  dependencies: PropTypes.array,
  memoKey: PropTypes.string,
};

MemoWrapper.defaultProps = {
  children: null,
  childrenFunc: null,
  dependencies: null,
  memoKey: null,
};

export default easyMemo;
