import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Col } from 'react-flexbox-grid/lib';
import { Map as map, fromJS } from 'immutable';

import style from './frauddashboard.module.scss';
import Dashboard from '../Dashboard';
import Hero from '../../widget/Hero/Hero';
import Map from '../../widget/Map/Map';
import Pie from '../../widget/Pie/Pie';
import Bar from '../../widget/Bar/Bar';
import PieTable from '../../widget/PieTable/PieTable';
import Query from './query';
import History from '../../../utils/history';

const FraudDashboard = ({
  profile,
}) => {
  const [data, setData] = useState(map());
  const [filters, setFilters] = useState(map());

  const onLoad = async () => {
    setData(map());
    Promise.all([
      Query.Cards(profile.toJS()),
    ]).then(([cards]) => setData(fromJS({
      cards,
    })));
  };

  useEffect(() => {
    if (profile.equals(filters)) return;
    setFilters(profile);
    onLoad();
  }, [profile]);

  return (
    <React.Fragment>
      <Col xs={12} className={style.containers}>
        <Dashboard data={data} title="Recently Observed Release Details">
          <Hero
            abbreviate
            styles={{ width: '25%' }}
            labels={fromJS(['Cards'])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              doc_count: data.getIn(['cards', 'total']),
            })}
            onClick={() =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                },
              })} />
          <Hero
            abbreviate
            styles={{ width: '25%' }}
            labels={fromJS(['Releases'])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              doc_count: data.getIn(['cards', 'releases', 'total']),
            })} />
          <Hero
            abbreviate
            styles={{ width: '25%' }}
            labels={fromJS(['Shops'])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              doc_count: data.getIn(['cards', 'shops', 'total']),
            })} />
          <Hero
            abbreviate
            styles={{ width: '25%' }}
            labels={fromJS(['Since'])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              doc_count: filters.get('date'),
            })}
            onClick={() =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                },
              })} />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers}>
        <Dashboard data={data} title="Shop Details">
          <PieTable
            legend
            pagination
            limit={5}
            cells={fromJS([{ key: 'doc_count' }])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([
              { key: 'key', label: 'name' },
              { key: 'doc_count', label: 'count', style: { width: '135px', textAlign: 'right ' }, render: v => v.toLocaleString() },
            ])}
            results={fromJS({
              total: data.getIn(['cards', 'shops', 'total']),
              data: data.getIn(['cards', 'shops', 'data']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  sites: v.get('key'),
                },
              })} />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers}>
        <Dashboard data={data} title="Base Releases">
          <PieTable
            legend
            pagination
            limit={5}
            cells={fromJS([{ key: 'doc_count' }])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([
                { key: 'key', label: 'name' },
                { key: 'doc_count', label: 'count', style: { width: '135px', textAlign: 'right ' }, render: v => v.toLocaleString() },
              ])}
            results={fromJS({
              total: data.getIn(['cards', 'releases', 'total']),
              data: data.getIn(['cards', 'releases', 'data']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  release_name: v.get('key'),
                },
              })} />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers}>
        <Dashboard data={data} title="Location of Data">
          <Map
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({ data: data.getIn(['cards', 'locations']) })} />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers}>
        <Dashboard data={data} title="Base Releases Over Time">
          <Bar
            tooltip
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={data.getIn(['cards', 'histogram'])}
            onClick={(v, k) =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  ...k,
                },
              })} />
        </Dashboard>
      </Col>
      <Col xs={12} className={style.containers}>
        <Dashboard data={data} title="Characteristics Breakdown">
          <Pie
            styles={{ width: '20%' }}
            titles={fromJS(['Payment Method'])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              total: 5,
              data: data.getIn(['cards', 'methods']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  payment_method: v.get('key'),
                },
              })} />
          <Pie
            styles={{ width: '20%' }}
            titles={fromJS(['Card Type'])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              total: 5,
              data: data.getIn(['cards', 'types']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  card_type: v.get('key'),
                },
              })} />
          <Pie
            styles={{ width: '20%' }}
            titles={fromJS(['Card BINs'])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              total: 5,
              data: data.getIn(['cards', 'bins']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  bin: v.get('key'),
                },
              })} />
          <Pie
            styles={{ width: '20%' }}
            titles={fromJS(['Track Info'])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              total: 5,
              data: data.getIn(['cards', 'tracks']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  track_information: v.get('key'),
                },
              })} />
          <Pie
            styles={{ width: '20%' }}
            titles={fromJS(['Service Code'])}
            labels={fromJS([{ key: 'key' }])}
            values={fromJS([{ key: 'doc_count' }])}
            results={fromJS({
              total: 5,
              data: data.getIn(['cards', 'codes']),
            })}
            onClick={v =>
              History.navigateTo({
                pathname: '/home/search/cards',
                query: {
                  ...filters.toJS(),
                  service_code: v.get('key'),
                },
              })} />
        </Dashboard>
      </Col>
    </React.Fragment>
  );
};

FraudDashboard.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default FraudDashboard;
