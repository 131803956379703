import SearchUtils from '../searchUtils';

interface MetaDataFilters {
  analyst_assigned?: string,
  analyst_unassigned?: string,
  channel_id?: string,
  channel_name?: string,
  channel_name_exact?: string,
  date?: string,
  is_analyst?: string,
  limit?: string,
  tags?: string,
  since?: string,
  skip?: string,
  sort?: string,
  tagged_at?: string,
  tagged_by?: string,
  tagged_since?: string,
  tagged_until?: string,
  until?: string,
}
interface MetaDataTag {
  id: number,
  primary: boolean,
  primary_tag: number,
  secondary: boolean,
  tag_name: string,
}

interface MetaDataTimeRange {
  sinceDate: string,
  untilDate: string,
}

const getTagIds = (filterTags: string[], allTags: MetaDataTag[]): string => {
  const ids = allTags
    .filter(v => filterTags.includes(v.tag_name))
    .map(v => v.id);
  return ids.join(',');
};

const parseTagFilter = (filters: MetaDataFilters, allTags: MetaDataTag[]): string => {
  if (/untagged/ig.test(filters.tags)) {
    return '&tagged=false';
  }
  const tags = filters?.tags?.split(',');
  if (!tags || tags.length < 1) {
    return '';
  }
  if (tags.length === allTags.length) {
    return '&tagged=true';
  }
  return `&tag_ids=${getTagIds(tags || [''], allTags)}`;
};

const getDateRange = (filters: MetaDataFilters): string => {
  if (!filters?.since || !filters?.until) {
    return '';
  }
  const time: MetaDataTimeRange = SearchUtils.parseDate(filters, '') as unknown as MetaDataTimeRange;
  return `${time.sinceDate},${time.untilDate}`;
};

/**
 * Builds a query string for the metadata api from a filters object.
 * The `filters` object may contain one or more the following fields:
 * ```
 * {
 *    // Comma delimited list of analysts to filter on. Returns only channels
 *    // assigned to the list
 *    analyst_assigned?: string,
 *
 *    // If set to true, api returns only channels that have not been assigned
 *    // to an analyst
 *    analyst_unassigned?: string,
 *
 *    // Numeric ID of specific channel to find
 *    channel_id?: string,
 *
 *    // Name of channel to find
 *    channel_name?: string,
 *
 *    // Exact match on channel name. One of 'true' or 'false'
 *    channel_name_exact?: string,
 *
 *    // Date label the channel was added at. (Ex: 'Last 48 Hours')
 *    date?: string,
 *
 *    // One of 'true' or 'false', if true it adds `userName` to the
 *    // `analyst_assigned` list.
 *    is_analyst?: string,
 *
 *    // Max number of results to return per page
 *    limit?: string,
 *
 *    // Comma delimited list of tags to filter on
 *    tags?: string,
 *
 *    // Start date in range to limit results to
 *    since?: string,
 *
 *    // Number of results to skip in query. (Used for pagination)
 *    skip?: string,
 *
 *    // One of 'channel_added_at:asc' or 'channel_added_at:desc' to sort by
 *    // date in ascending or descending order
 *    sort?: string,
 *
 *    // Date label the channel was tagged on. (Ex: 'Last 48 Hours')
 *    tagged_at?: string,
 *
 *    // Username of analyst who tagged channels to filter on
 *    tagged_by?: string,
 *
 *    // Starting date channels are tagged in range to limit results to
 *    tagged_since?: string,
 *
 *    // Ending date channels are tagged in range to limit results to
 *    tagged_until?: string,
 *
 *    // End date in range to limit results to
 *    until?: string,
 * }
 * ```
 * @param {MetaDataFilters} filters  An object containing the meta data filters
 * @param {String} userName The current user's username. e.g. The logged-in user
 * @param {MetaDataTag[]} allTags List of all available channel tags
 * @returns {string} A string containing the query
 */
const buildQuery = (
  filters: MetaDataFilters,
  userName = '',
  allTags: MetaDataTag[] = [],
): string => {
  if (!filters) return null;

  const newFilters = { ...filters };

  if (newFilters?.analyst_unassigned === 'true') {
    delete newFilters?.analyst_assigned;
  }

  const keys = Object.keys(newFilters);
  if (keys.length === 0) return '';

  let query = '';
  keys.forEach((k) => {
    switch (k) {
      case 'is_analyst':
        if (filters?.is_analyst === 'true') {
          query = `${query}&assigned_to=${userName}`;
        }
      break;
      case 'tags':
        query = `${query}${parseTagFilter(filters, allTags)}`;
      break;
      case 'date':
        query = `${query}&added_at_time_range=${getDateRange(filters)}`;
        break;
      case 'tagged_at':
          query = `${query}&tagged_at_time_range=${getDateRange({
            date: filters?.tagged_at,
            since: filters?.tagged_since,
            until: filters?.tagged_until,
          })}`;
          break;
      case 'sort':
        query = `${query}&sort=${filters.sort === 'channel_added_at:desc' ? '-' : ''}channel_added_at`;
        break;
      case 'analyst_assigned':
        query = `${query}&assigned_to=${String(filters?.analyst_assigned)}`;
        break;
      case 'channel_name_exact':
        query = `${query}&exact_match=${String(filters[k as keyof MetaDataFilters])}`;
        break;
      case 'channel_id':
      case 'channel_name':
      case 'tagged_by':
        query = `${query}&${k}=${String(filters[k as keyof MetaDataFilters])}`;
        break;
      default:
        // do nothing
        break;
    }
  });

  // Add default filters
  if (!filters.sort) {
    query = `${query}&sort=-channel_added_at`;
  }
  const skip = Number(filters?.skip) || 0;
  const limit = Number(filters?.limit) || 25;
  const page = (skip) ? `&page=${Math.round(skip / limit) + 1}` : '';
  const maxResults = `&max_results=${limit}`;
  query = `embed=channel_tag_ids${page}${maxResults}${query}`;

  return query;
};

export default {
  buildQuery,
};
