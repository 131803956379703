import Api from '../../../utils/api';

const LoadCredentialsUrl = '/ui/v4/login-policy/basic-auth-creds';
const GenerateCredentialUrl = '/ui/v4/login-policy/generate-basic-auth-creds';
const GenerateTFACredentialUrl = '/ui/v4/login-policy/2fa/generate-basic-auth-creds';
const RevokeCredentialUrl = '/ui/v4/login-policy/revoke-basic-auth-creds';
const GenerateTFATokenUrl = '/ui/v4/login-policy/2fa/generate-api-token';
const GenerateTokenUrl = '/ui/v4/login-policy/generate-api-token';
const RevokeTokenUrl = '/ui/v4/login-policy/revoke-api-token';

const Status = [200, 201, 202, 204, 500, 501, 502, 503, 504];

const LoadCredentials = async () =>
  Api.get(LoadCredentialsUrl)
    .then(res => (res.ok ? res.data : {}))
    .then(res => res.basic_auth_creds)
    .catch(() => []);

const GenerateCredential = async ({ credential_name = '', username = '', password = '', otp = '', totp = '' }) => {
  const url = otp ? GenerateTFACredentialUrl : GenerateCredentialUrl;
  const query = otp
    ? { otp, token: totp, credential_name }
    : { username, password, credential_name };
  return Api.post(url, query, [200, 201, 202, 204]);
};

const RevokeCredential = async ({ credential_name = '' }) => Api.post(RevokeCredentialUrl, { credential_name });

const GenerateToken = ({ token_name = '', username = '', password = '', otp = '', totp = '', id_token = '' }) => {
  const url = otp ? GenerateTFATokenUrl : GenerateTokenUrl;
  const query = otp
    ? { otp, token: totp, token_name }
    : { username, password, token_name };
  if (id_token) query.id_token = id_token;
  return Api.post(url, query, Status);
};

const RevokeToken = ({ token_name = '' }) => Api.post(RevokeTokenUrl, { token_name });

export default {
  GenerateCredential,
  GenerateToken,
  LoadCredentials,
  RevokeCredential,
  RevokeToken,
};
