import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import Query from '../query';

const useStyles = makeStyles({
  row: {
    borderRadius: '2px !important',
    boxShadow: '0 0 2px rgba(0, 0, 0, .12), 0 0px 2px rgba(0, 0, 0, .24) !important',
    margin: '8px 0',
    padding: '12px',
  },
});

const BiographicalData = ({
  inputData,
}) => {
  const classes = useStyles();
  const [biographicalData, setBiographicalData] = useState({});

  useEffect(() => {
    const { phone, email } = inputData;
    if (!phone && !email) return;
    Query.getBiographicalData(inputData)
      .then(res => setBiographicalData(res.data))
      .catch((err) => {
        throw err;
      });
  }, []);

  if (!biographicalData) return null;

  if (!biographicalData.length) return <Typography variant="subtitle2">No results found.</Typography>;

  return (
    <Grid>
      {biographicalData.map((v) => {
        const { email, dataset, location, name, ip, phone } = v;
        return (
          <Grid className={classes.row} key={v.fpid}>
            {dataset && <Typography><b>Dataset: </b>{dataset.title}</Typography>}
            {name && <Typography><b>Name: </b>{name.raw}</Typography>}
            {email && <Typography><b>Email: </b>{email.address}</Typography>}
            {location && <Typography><b>Location: </b>{location.raw}</Typography>}
            {phone && <Typography><b>Phone Number: </b>{phone.full_number}</Typography>}
            {ip && <Typography><b>IP Address: </b>{ip.ip}</Typography>}
          </Grid>
        );
      })}
    </Grid>
  );
};

BiographicalData.propTypes = {
  inputData: PropTypes.object.isRequired,
};

export default BiographicalData;
