import Reflux from 'reflux';

import { fromJS, List as list, Map as map } from 'immutable';

import SearchActions from '../actions/searchActions';
import MetaDataActions from '../actions/metaDataActions';
import Api from '../utils/api';
import Messages from '../constants/Messages';
import Dates from '../constants/Dates';
import Token from '../utils/token';
import MetaDataUtils from '../utils/metaDataUtils';

const AdminMetaDataUrl = '/ui/metadata/v2/admin';
const MetaDataUrl = '/ui/metadata/v2';

const DefaultFilters = {
  'tagging.channels': {
    is_analyst: 'true',
    tags: 'Untagged',
  },
};
const Sorts = {
  'tagging.channels': [
    { label: 'Date Joined (desc)', value: 'channel_added_at:desc' },
    { label: 'Date Joined (asc)', value: 'channel_added_at:asc' },
  ],
};

const MetaDataStore = Reflux.createStore({
  listenables: [MetaDataActions],

  init() {
    this.state = fromJS({
      metaData: {
        dates: Dates,
        defaults: DefaultFilters,
        filters: {},
        tags: [],
        tagged: {},
        channels: {},
        sorts: Sorts,
        type: '',
      },
    });
  },

  onGetChannel(fpid, isAdmin) {
    const channels = this.state.getIn(['metaData', 'channels']);
    const data = channels ? channels.get('data') || list() : null;
    // If the channel has already been found, don't look again
    if (data && data.findIndex(v => v.get('fpid') === fpid) !== -1) return;

    Api.get(`${isAdmin ? AdminMetaDataUrl : MetaDataUrl}/channels?fpids=${fpid}`, {}, [200], 30000, { 'Cache-Control': 'no-cache' })
      .then(res => (res.ok ? res.data : {}))
      .then(res => (res._items && res._items.length > 0 ? res._items[0] : null))
      .then((res) => {
        const tags = this.state.getIn(['metaData', 'tags']);
        return (res ? ({
          ...res,
          tags: res.tag_ids.map(t => tags.find(k => k.get('id') === t)),
          assigned_to: `${res.assigned_to}`,
          basetypes: ['tagging', 'chat'],
        }) : null);
      })
      .then((res) => {
        if (res) {
          if (channels) {
            const updated = channels
              .set('total', channels.get('total') + 1)
              .set('data', data.push(fromJS(res)));
            MetaDataActions.set(['metaData', 'channels'], updated);
          } else {
            // channels are not stored anywhere, create starting mapping
            const updated = fromJS({
              total: 1,
              data: [res],
            });
            MetaDataActions.set(['metaData', 'channels'], updated);
          }
        }
      });
  },

  onGetChannelTags(fpid, isAdmin) {
    if (this.state.getIn(['metaData', 'tagged', fpid]) && !this.state.getIn(['metaData', 'tagged', fpid]).isEmpty()) return;
    Api.get(`${isAdmin ? AdminMetaDataUrl : MetaDataUrl}/channel_tags?channel_fpids=${fpid}`, {}, [200], 30000, { 'Cache-Control': 'no-cache' })
      .then(res => (res.ok ? res.data : {}))
      .then(res => MetaDataActions.set(['metaData', 'tagged', fpid], fromJS(res._items)))
      .catch(() => MetaDataActions.set(['metaData', 'tagged', fpid], list()));
  },

  onGetChannels(username) {
    MetaDataActions.set(['metaData', 'channels'], map());
    const filters = this.state.getIn(['metaData', 'filters']);
    const limit = filters.get('limit') || 25;
    const skip = filters.get('skip') || 0;
    const allTags = this.state.getIn(['metaData', 'tags']);
    const query = MetaDataUtils.buildQuery(filters?.toJS() || {}, username, allTags?.toJS());

    Api.get(`${AdminMetaDataUrl}/channels?${query}`, {}, [200, 201], 30000, { 'Cache-Control': 'no-cache' })
      .then(res => (res.ok ? res.data : {}))
      .then((res) => {
        const tags = this.state.getIn(['metaData', 'tags']);
        return {
          total: res._meta.total,
          skip: skip || '0',
          limit,
          data: res._items.map((v) => {
            MetaDataActions.set(['metaData', 'tagged', v.fpid], fromJS(v.channel_tag_ids));
            return {
              ...v,
              channel_tag_ids: (v.channel_tag_ids || []).reverse(),
              tags: (v.tag_ids || []).map(t => tags.find(k => k.get('id') === t)),
              assigned_to: v.assigned_to,
              basetypes: ['tagging', 'chat'],
            };
          }),
        };
      })
      .then(res => MetaDataActions.set(['metaData', 'channels'], fromJS(res)))
      .catch(() => MetaDataActions.set(['metaData', 'channels'], map({
        total: 0,
        skip: 0,
        limit: 25,
        data: list(),
      })));
  },

  onAddTagAssociations(channelFpid, tagIds = list(), username) {
    const body = tagIds.map(v => ({
      channel_fpid: channelFpid,
      tag_id: v,
      tagged_by: username,
    }));
    Api.post(`${AdminMetaDataUrl}/channel_tags`, body, [200, 201], 30000)
      .then(res => (res.ok ? res.data : {}))
      .then((res) => {
        //  Update the channel tags
        const channels = this.state.getIn(['metaData', 'channels', 'data']);
        const channelIndex = channels.findIndex(c => c.get('fpid') === channelFpid);
        if (channelIndex !== -1) {
          const allTags = this.state.getIn(['metaData', 'tags']);
          const channelTags = this.state.getIn(['metaData', 'channels', 'data', channelIndex, 'tags']);
          const newChannelTags = allTags.filter(t => tagIds.includes(t.get('id')));
          MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'tags'], channelTags.concat(newChannelTags));
        }
        // Update the associations
        if (res._items) {
          let newAssociations = list();
          res._items.forEach((v, k) => {
            const association = fromJS({
              ...v,
              channel_fpid: channelFpid,
              tag_id: tagIds.get(k),
              tagged_at: v._created,
              tagged_by: username,
            });
            newAssociations = newAssociations.push(association);
          });
          const associations = this.state.getIn(['metaData', 'tagged', channelFpid]);
          MetaDataActions.set(['metaData', 'tagged', channelFpid], associations.concat(newAssociations));
          // update the embedded channel channel_tag_ids
          if (channelIndex !== -1) {
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'channel_tag_ids'], newAssociations.concat(associations));
          }
        } if (Array.isArray(res)) {
          let newAssociations = list();
          res.forEach((v, k) => {
            const association = fromJS({
              ...v,
              channel_fpid: channelFpid,
              tag_id: tagIds.get(k),
              tagged_at: v._created,
              tagged_by: username,
            });
            newAssociations = newAssociations.push(association);
          });
          const associations = this.state.getIn(['metaData', 'tagged', channelFpid]);
          MetaDataActions.set(['metaData', 'tagged', channelFpid], associations.concat(newAssociations));
          // update the embedded channel channel_tag_ids
          if (channelIndex !== -1) {
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'channel_tag_ids'], newAssociations.concat(associations));
          }
        } else {
          const association = fromJS({
            ...res,
            channel_fpid: channelFpid,
            tag_id: tagIds.get(0),
            tagged_at: res._created,
            tagged_by: username,
          });
          const associations = this.state.getIn(['metaData', 'tagged', channelFpid]);
          MetaDataActions.set(['metaData', 'tagged', channelFpid], associations.push(association));
          // update the embedded channel channel_tag_ids
          if (channelIndex !== -1) {
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'channel_tag_ids'], associations.unshift(association));
          }
        }
      })
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.TagsUpdated))
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.SearchError));
  },

  onDeleteTagAssociations(channelFpid, associationIds = list()) {
    associationIds.forEach((v) => {
      Api.delete(`${AdminMetaDataUrl}/channel_tags/${v}`, {}, [200, 204], 30000)
        .then(res => (res.ok ? res.data : {}))
        .then(() => {
          // Update the associations
          const associations = this.state.getIn(['metaData', 'tagged', channelFpid]);
          const newAssociations = associations.filterNot(a => a.get('id') === v);
          MetaDataActions.set(['metaData', 'tagged', channelFpid], newAssociations);
          // Update the channel tags
          const channels = this.state.getIn(['metaData', 'channels', 'data']);
          const channelIndex = channels.findIndex(c => c.get('fpid') === channelFpid);
          if (channelIndex !== -1) {
            const channelTags = this.state.getIn(['metaData', 'channels', 'data', channelIndex, 'tags']);
            const newChannelTags = channelTags.filter(t => newAssociations.map(a => a.get('tag_id')).includes(t.get('id')));
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'tags'], newChannelTags);
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex, 'channel_tag_ids'], newAssociations.reverse());
          }
        })
        .then(() => SearchActions.set(['search', 'info', 'message'], Messages.TagsUpdated))
        .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.SearchError));
    });
  },

  onUpdateChannels(value, channelIds) {
    const body = {
      ...value,
    };
    channelIds.forEach((v) => {
      Api.patch(`${AdminMetaDataUrl}/channels/${v}`, body, [200, 201], 30000)
        .then(res => (res.ok ? res.data : {}))
        .then(() => {
          const channels = this.state.getIn(['metaData', 'channels', 'data']);
          const channelIndex = channels.findIndex(c => c.get('fpid') === v);
          if (channelIndex !== -1) {
            const updated = fromJS({
              ...this.state.getIn(['metaData', 'channels', 'data', channelIndex]).toJS(),
              ...value,
            });
            MetaDataActions.set(['metaData', 'channels', 'data', channelIndex], updated);
          }
        })
        .then(() => SearchActions.set(['search', 'info', 'message'], Messages.ChannelsUpdated))
        .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.SearchError));
    });
  },

  onParseFilters(filters) {
    // given a list of filters, determine if we want to remove any of the default
    // filters because with other filters applied the query wouldn't make sense
    // ie if tagged_by or tagged_at is applied, the default Untagged filter
    // wouldn't make sense
    const defaults = this.state.getIn(['metaData', 'defaults', this.state.getIn(['metaData', 'type'])]);
    const filterKeys = filters.keySeq().toArray();
    const updatedFilters = fromJS({
      ...filters.toJS(),
      ...defaults.map((v, k) => {
        switch (k) {
          case 'tags':
            // if tagged_at or tagged_by is applied, set tags to whatever tags are applied
            // or empty
            if (['tagged_at', 'tagged_by'].some(f => filterKeys.includes(f))) {
              return filters.get(k) !== 'Untagged' ? filters.get(k) : '';
            }
            return filters.get(k) != null ? filters.get(k) : v;
          default:
            return filters.get(k) != null ? filters.get(k) : v;
        }
      }).toJS(),
    });

    MetaDataActions.set(['metaData', 'filters'], updatedFilters);
  },

  onSet(k, v) {
    this.state = Array.isArray(k) ? this.state.setIn(k, v) : this.state.set(k, v);
    this.trigger(this.state.toObject());
  },

  async onGetAllTags() {
    const prm = Token.get('prm');
    if (!prm.some(v => /meta.r/.test(v))) return MetaDataActions.getAllTags.completed();
    if (!this.state.getIn(['metaData', 'tags']).isEmpty()) return MetaDataActions.getAllTags.completed();
    return Api.get(`${MetaDataUrl}/tags?max_results=50`, null, [200, 201], 30000, { 'Cache-Control': 'no-cache' })
      .then(res => (res.ok ? res.data : {}))
      .then(res => res._items.map(x => ({
        tag_name: x.tag_name,
        id: x.id,
        primary_tag: x.primary_tag,
        primary: x.primary_tag == null,
        secondary: x.primary_tag != null,
      })))
      .then((res) => {
        MetaDataActions.set(['metaData', 'tags'], fromJS(res));
        MetaDataActions.getAllTags.completed();
      })
      .catch(() => {
        MetaDataActions.set(['metaData', 'tags'], list());
        MetaDataActions.getAllTags.completed();
      });
  },
});

export default MetaDataStore;
