import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import KeywordsReview from './KeywordsReview';
import Subscriptions from './Subscriptions';
import Text from '../../utils/text';
import Editor from '../manage/Editor';
import StackedBar from '../widget/StackedBar/StackedBar';

import Query from '../../containers/Alerting/query';
import AlertingStore from '../../stores/recoil/alerting';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';
import { validateKeywordValue, validBinRegex } from './validation';
import Common from '../../utils/common';
import Messages from '../../constants/Messages';

const useStyles = makeStyles(theme => ({
  chart: {
    marginBottom: '5rem',
    marginRight: '2.9rem',
    marginTop: '-5rem',
    overflowX: 'hidden',
    paddingLeft: '2.9rem',
    position: 'relative',

    '& .total': {
      fontWeight: 'normal',
      paddingLeft: '.5rem',
    },
    // stackedbar
    '& > div > div > div': {
      minHeight: '250px',
    },
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: `${theme.palette.primary.main}`,
    bottom: 0,
    color: `${theme.palette.primary.contrastText}`,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '2rem',
    fontWeight: '600',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    opacity: '0.9',
    padding: '2.4rem',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
  },
  warning: {
    color: `${theme.palette.tertiary.main}`,
    maxWidth: '650px',
    fontSize: '1.2rem',
    padding: '.5rem 0',
  },
}));

const AlertingKeywordDialog = ({
  canCurate,
  canEdit,
  email,
  open,
  onClose,
  onDelete,
  prm,
  selected,
  subscription,
}) => {
  const classes = useStyles();
  const [aggColors, setAggColors] = useState(map());
  const [aggCounts, setAggCounts] = useState(map());
  const [aggData, setAggData] = useState(map());
  const [aggReload, setAggReload] = useState(map());
  const [aggTotalCount, setAggTotalCount] = useState(0);
  const [aggValue, setAggValue] = useState(map());
  const [basetypeMappings, setBasetypeMappings] = useState(list());
  const [bulkData, setBulkData] = useState(null);
  const [bulkErrors, setBulkErrors] = useState(map());
  const [bulkKeywords, setBulkKeywords] = useState(list());
  const [bulkSaveData, setBulkSaveData] = useState(null);
  const [data, setData] = useState(list());
  const [filteredAggData, setFilteredAggData] = useState(list());
  const [inReview, setInReview] = useState(false);
  const [keywordSubscriptions, setKeywordSubscriptions] = useState(list());
  const [selectedSearchTypes, setSelectedSearchTypes] = useState(list());
  const [showAggData, setShowAggData] = useState(false);

  const basetypes = useRecoilValue(AlertingStore.basetypes);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);
  const [keywords, setKeywords] = useRecoilState(AlertingStore.keywords);
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const recipients = useRecoilValue(AlertingStore.notificationProfiles);
  const type = useRecoilValue(AlertingStore.type);

  const closeDialog = () => {
    const { pathname, query } = History.getCurrentLocation();
    if (query.keyword_id) {
      History.navigateTo({ pathname, query: { ...query, keyword_id: undefined } });
    }
    setKeywordSubscriptions(list());
    onClose();
  };

  const getAggTotalCount = (value, totals, mappings) => {
    // calculate total count based on which datasources are selected
    let totalCount = 0;
    const datasources = value.get('dataSources') || list();
    const searchTypes = [];
    datasources.forEach((v) => {
      let searchType = '';
      mappings.forEach((b) => {
        if (typeof b.get('id') === 'string' && b.get('id') === v) {
          searchType = b.get('key');
          searchTypes.push(b.get('key'));
        } else if (b.get('id').includes(v) && !searchTypes.includes(b.get('key'))) {
          searchType = b.get('key');
          searchTypes.push(b.get('key'));
        }
      });
      const count = totals.get(searchType, 0);
      totalCount += count;
    });
    setSelectedSearchTypes(fromJS(searchTypes));
    setAggTotalCount(totalCount);
  };

  const keywordSearch = async (value) => {
    const keyclassMap = (v) => {
      switch (v) {
        case 'threat_actor': return 'site_actor.names.handle:';
        case 'bin': return 'enrichments.card-numbers.card-numbers.bin:';
        case 'ip_address': return 'enrichments.v1.ip_addresses.ip_address:';
        case 'fqdn': return 'enrichments.v1.urls.domain:';
        default: return '';
      }
    };
    const keyclass = value.getIn(['keyclass', 'name']);
    let keyword = (typeof value.get('value') !== 'string')
      ? value.getIn(['value', 0], '')
      : value.get('value', '');
    if (keyclass === 'ip_address') keyword = `\"${keyword}\")`;
    const aggKeyword = (typeof aggValue.get('value') !== 'string')
      ? aggValue.getIn(['value', 0], '')
      : aggValue.get('value', '');
    const aggKeyclass = aggValue.getIn(['keyclass', 'name'], '');
    if (keyword === aggKeyword && keyclass === aggKeyclass) {
      // if the datasources have changed, need to update total count
      setAggReload(map());
      getAggTotalCount(value, aggCounts, basetypeMappings);
      return;
    }
    setAggReload(map());
    setAggValue(value);
    setShowAggData(true);
    setAggData(map());
    setAggCounts(map());
    setFilteredAggData(list());
    const promise = Query.keywordAggregation(
      `+${keyclassMap(keyclass)}(${keyword})`,
      (basetypes || list())
        .filter(v => prm.some(p => v.get('test').test(p)))
        .filter(v => v.get('searchType') !== 'twitter')
        .filter(v => v.get('searchType') !== 'code_repository'),
        (keyclass === 'fqdn'),
    );
    await Promise.all([promise])
      .then(([res]) => {
        if (!res.data) return;
        setAggData(fromJS({ data: res.data, timeout: res.timeout }));
        setAggCounts(fromJS(res.totals));
        setAggColors(fromJS(res.colors));
        setBasetypeMappings(fromJS(res.basetypeMappings));
        getAggTotalCount(value, fromJS(res.totals), fromJS(res.basetypeMappings));
      });
  };

  const onKeywordChange = async (values, changedKey) => {
    // not using this dialog for bulk editing multiple keywords, so values will always
    // have a length of 1
    const value = values.get(0, map());
    const val = value.get('value', list());
    // if there is no keyword or more than one, don't search
    if (typeof val !== 'string' && val.size === 1) {
      if (!aggData.isEmpty()) {
        setShowAggData(true);
        setAggReload(value);
      } else {
        keywordSearch(value);
      }
    } else if (typeof val === 'string' && val !== '') {
      if (['value', 'keyclass'].includes(changedKey)) {
        setShowAggData(true);
        setAggReload(value);
      }
      else if (changedKey.includes('dataSources')) getAggTotalCount(value, aggCounts, basetypeMappings);
    } else {
      setShowAggData(false);
      setAggData(map());
      setAggCounts(map());
      setAggColors(map());
      setAggValue(map());
      setFilteredAggData(list());
    }
  };

  const onBulkSave = async () => {
    const toSave = bulkSaveData.map(v => ({
      active: v.active || false,
      is_curated: v.is_curated || false,
      keyclass_id: v.category.value,
      name: v.name || '<Not Set>',
      value: v.value,
      keywordSubscriptions: v.keywordSubscriptions,
      dataSources: v.dataSources.flat(),
    }));
    const errors = [];
    bulkSaveData.forEach((v) => {
      const validation = validateKeywordValue(fromJS(v));
      if (validation.error) errors.push(validation);
    });

    const errorMessages = errors.reduce((obj, error) => ({
      ...obj,
      [error.key]: error.error,
    }), {});
    setBulkErrors(fromJS(errorMessages));
    if (errors.length > 0) return;
    closeDialog();
    SearchActions.set(['search', 'info'], fromJS({
      message: 'Updating Keywords',
    }).set('action', (<CircularProgress />)));
    const promise = Query.saveKeywords(toSave, list(), type, map(), keywordClasses, basetypes, keywords.get('data'), ownerId, prm);
    await Promise.all([promise])
      .then(([res]) => {
        const updated = keywords
          .set('data', res)
          .set('total', keywords.get('total') + toSave.length);
        setKeywords(updated);
      });
  };

  const onDeleteKeywords = () => {
    Query.deleteKeywords(selected, ownerId);
    // remove selected keywords from keywords
    const updatedData = keywords.get('data').filterNot(v => selected.includes(v.get('id')));
    const updated = keywords
      .set('data', updatedData)
      .set('total', keywords.get('total') - selected.length);
    setKeywords(updated);
    onDelete();
  };

  const onPreview = (value) => {
    const keyclassMap = (k, v) => {
      switch (k) {
        case 'threat_actor': return { author: v };
        case 'bin': return { bins: v };
        case 'ip_address': return { ips: v };
        default: return { query: v };
      }
    };

    const keyclass = value.getIn(['keyclass', 'name']);
    const val = value.get('value', list());
    let queryString = '';
    // if there is no keyword or more than one, don't search
    if (typeof val !== 'string' && val.size === 1) {
      queryString = val.get(0);
    } else if (typeof val === 'string' && val !== '') {
      queryString = val;
    }

    let querybase = 'all';
    let filterBasetypes = '';
    if (keyclass === 'search') {
      const communityBase = ['blogs', 'ransomware', 'boards', 'chats', 'forums', 'pastes', 'social', 'twitter', 'chan'];
      const regexbase = value.get('filters')?.split(' +')[0].replace(/\+basetypes:|[()]/ig, '').split(/OR|AND/).map(e => e.trim());
      filterBasetypes = regexbase.map(v =>
        (Common.Basetypes.BasetypeToSearchType(fromJS({ basetypes: [v] }))));
      filterBasetypes = filterBasetypes.filter(Boolean);
      if (filterBasetypes.length === 1) {
        const [a] = filterBasetypes;
        querybase = a;
      }
      if (filterBasetypes.length > 1 && filterBasetypes.every(v => communityBase.includes(v))) querybase = 'communities';
      queryString = value
        ?.get('filters')
        ?.replace(/\+sort_date:\[.*\]/, '')
        ?.replace(/\+basetypes:\(.*?\)\s+(?=[+|])/, '');
    }

    if (selectedSearchTypes.toJS().length === 1 && selectedSearchTypes.some(s => ['media', 'images', 'videos'].includes(s))) {
      querybase = selectedSearchTypes.get(0);
    }

    const all = selectedSearchTypes.map((v) => {
      const basetype = basetypeMappings.find(b => b.get('key') === v, null, map());
      return basetype.get('parent', basetype.get('key'));
    });
    // exclude sites for child chat types: discord, telegram etc
    const excludedSites = basetypeMappings
      .filter(v => !selectedSearchTypes.includes(v.get('key')))
      .filter(v => v.get('parent'))
      // PLATFORM-3614 only exclude sites if chat basetype selected
      .filter(() => all.includes('chats'))
      .map(v => v.get('key'))
      .join();

    const query = {
      all: keyclass === 'search' ? filterBasetypes.join() : all.toSet().join(),
      date: value.get('date', 'Last 30 Days'),
      since: value.get('since', 'now-30d'),
      until: value.get('until', 'now'),
      exclude_sites: Boolean(excludedSites),
      sites: excludedSites,
      ...keyclassMap(keyclass, queryString),
    };
    if (querybase !== 'all') delete query.all;
    const path = query ? History.buildSearchString(query) : '';
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`/home/search/${querybase}${path}`, '_blank');
  };

  const onSaveKeywords = async (_, values) => {
    // not using this dialog for bulk editing multiple keywords, so values will always
    // have a length of 1
    const value = values.get(0, map());
    const val = value.get('value', list());
    // Bulk add new keywords
    if (typeof val !== 'string' && val.size > 1) {
      setInReview(true);
      const category = value.get('keyclass', map());
      const keywordsValue = [];
      const errors = [];
      value.get('value', list()).forEach((v) => {
        const keyword = {
          active: value.get('active') || false,
          is_curated: value.getIn(['curation', 'status']) || false,
          keyclass_id: value.getIn(['keyclass', 'id']),
          category: { value: category.get('id', ''), label: Text.Sentence(category.get('name', '')) },
          name: value.get('name') || '<Not Set>',
          value: v,
          keywordSubscriptions: value.get('keywordSubscriptions', list()).map(k => fromJS({
            ...k.toJS(),
            hasAdded: true,
            hasChangedEmail: k.get('receivesEmail'),
          })),
          dataSources: [
            ...value.get('dataSources', list()),
            ...value.get('dea', list())
              .reduce((accum, deaVal, k) => (deaVal ? accum.push(k) : accum), list())],
          key: uuidv4(),
        };
        keywordsValue.push(keyword);
        errors.push(validateKeywordValue(fromJS(keyword)));
      });
      const errorMessages = errors.reduce((obj, error) => ({
        ...obj,
        [error.key]: error.error,
      }), {});
      setBulkData(fromJS(values));
      setBulkKeywords(fromJS(keywordsValue));
      setBulkErrors(fromJS(errorMessages));
      return;
    }
    setInReview(false);
    closeDialog();
    SearchActions.set(['search', 'info'], fromJS({
      message: 'Updating Keywords',
    }).set('action', (<CircularProgress />)));
    const keywordsValue = [];
    if (typeof val !== 'string') {
      // Add a single new keyword
      value.get('value', list()).forEach((v) => {
        const keyword = {
          active: value.get('active') || false,
          is_curated: value.getIn(['curation', 'status']) || false,
          keyclass_id: value.getIn(['keyclass', 'id']),
          name: value.get('name') || '<Not Set>',
          value: v,
          keywordSubscriptions: value.get('keywordSubscriptions', list()).toJS().map(k => ({
            ...k,
            hasAdded: true,
            hasChangedEmail: k.receivesEmail,
          })),
          dataSources: [
            ...value.get('dataSources', list()),
            ...value.get('dea', list())
              .reduce((accum, deaVal, k) => (deaVal ? accum.push(k) : accum), list())],
        };
        keywordsValue.push(keyword);
      });
    } else {
      const keyword = {
        active: value.get('active') || false,
        is_curated: value.getIn(['curation', 'status']) || false,
        keyclass_id: value.getIn(['keyclass', 'id']),
        name: value.get('name') || '<Not Set>',
        value: val,
        keywordSubscriptions: value.get('keywordSubscriptions', list()).map(k => fromJS({
          ...k.toJS(),
        })),
        dataSources: [
          ...value.get('dataSources', list()),
          ...value.get('dea', list())
            .reduce((accum, deaVal, k) => (deaVal ? accum.push(k) : accum), list())],
      };
      keywordsValue.push(keyword);
    }
    let promise;
    if (!value.get('id')) {
      promise = Query.saveKeywords(keywordsValue, list(), type, map(), keywordClasses, basetypes, keywords.get('data'), ownerId, prm);
    } else {
      promise = Query.saveKeywords(keywordsValue[0], list([value.get('id')]), type, map(), keywordClasses, basetypes, keywords.get('data'), ownerId, prm);
    }
    await Promise.all([promise])
      .then(([res]) => {
        const updated = keywords
          .set('data', res)
          .set('total', !value.get('id') ? keywords.get('total') + keywordsValue.length : keywords.get('total'));
        setKeywords(updated);
      });
  };

  const onSubscriptionChange = (changedSubscriptions, subscriptionsList) => {
    setKeywordSubscriptions(subscriptionsList);
  };

  useEffect(() => {
    if (!selectedSearchTypes.isEmpty() && !basetypeMappings.isEmpty() && !aggData.isEmpty()) {
      const updatedAggData = aggData.get('data').update(v => v.map((d) => {
        let updatedDay = d;
        basetypeMappings.forEach((b) => {
          const searchType = b.get('key');
          if (!selectedSearchTypes.includes(searchType)) {
            const label = b.get('label');
            updatedDay = updatedDay.delete(label);
          }
        });
        return updatedDay;
      }));
      setFilteredAggData(map({ data: updatedAggData, timeout: aggData.get('timeout', false) }));
    } else if (selectedSearchTypes.isEmpty()) {
      setFilteredAggData(fromJS({ data: [] }));
    }
  }, [selectedSearchTypes]);

  useEffect(async () => {
    // fetch the keyword. Only fetch when one is selected because we default to
    // all selected when bulk editing
    if (selected.length === 1) {
      const keywordNotLoaded = keywords.get('data', list()).findIndex(v => v.get('id') === selected[0]) === -1;
      await Promise.all([
        Query.keywordDatasources(selected, keywords.get('data'), basetypes),
        ...(keywordNotLoaded ? [Query.loadKeyword(
          selected[0],
          ownerId,
          keywordClasses,
        )] : []),
      ]).then(([res, loadedKeyword]) => {
        let updates = (!loadedKeyword) ? keywords : fromJS({ data: [loadedKeyword] });
        res.forEach((keyword) => {
          const merged = updates.getIn(['data', keyword.get('index')]).mergeDeep(keyword.get('keyword'));
          updates = updates.setIn(['data', keyword.get('index')], merged);
        });
        const selectedKeywords = (updates.get('data') || list())
          .filter(v => selected.includes(v.get('id')))
          .map((row) => {
            if (!row.has('keywordSubscriptions')) {
              const subscribedProfileIds = row.get('keyword_subscriptions', list()).map(v => v.get('notification_profile_id'));
              const receivesEmailIds = {};
              row.get('keyword_subscriptions', list()).forEach((v) => {
                receivesEmailIds[v.get('notification_profile_id')] = v.get('receives_email');
              });
              const profiles = subscribedProfileIds.map((v) => {
                const recipient = recipients.get('data').find(r => r.get('id') === v);
                const receivesEmail = receivesEmailIds[String(v)];
                return map({ endpoint: recipient.get('endpoint'), id: recipient.get('id'), isSubscribed: true, receivesEmail });
              });
              const updatedRow = row.set('keywordSubscriptions', profiles.sortBy(v => v.get('endpoint')));
              return updatedRow;
            }
            const updatedRow = row
              .set('additional-filters', row
                ?.get('filters')
                ?.replace(/\+sort_date:\[.*\]/, '')
                ?.replace(/\+basetypes:\(.*?\)\s+(?=[+|])/, ''))
              .set('keyclass', keywordClasses
                ?.find(v => v
                  ?.get('id') === row?.get('keyclass_id')) || row?.get('keyclass'));
            return updatedRow;
          });
        keywordSearch(selectedKeywords.get(0, map()));
        setData(selectedKeywords);
      });
    }
  }, [selected]);

  useEffect(() => {
    setKeywordSubscriptions(data.getIn([0, 'keywordSubscriptions'], list()));
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => closeDialog()}>
      <DialogTitle>
        {selected.length === 0 ? 'Add New Keywords' : 'Edit Keyword'}
      </DialogTitle>
      <DialogContent>
        {!inReview &&
        <Editor
          canEdit={canEdit}
          fields={[
            {
              type: 'inline-toggle',
              fields: [
                { value: 'active',
                  label: 'Active',
                  type: 'toggle',
                  bulk: true,
                  default: true,
                },
                { value: 'curation.status',
                  label: 'Curated',
                  type: 'toggle',
                  disable: {
                    create: () => !canCurate,
                    edit: v => (v.get('id')
                      ? !canCurate && !v.getIn(['curation', 'status'])
                      : !canCurate),
                  },
                  bulk: true,
                  default: false,
                  icon: 'help',
                  iconText: canCurate ? `By submitting for curation, this string will
                  be sent to the Flashpoint analyst team where it will be reviewed
                  to ensure that it will yield clean, high-quality results. From there
                  it will be accepted, modified and accepted, or rejected. Once accepted,
                  the alerts for this string will go to the Flashpoint analyst team first,
                  where they will whittle down the results to only those of higher-relevance
                  before forwarding them on to you.` : `Your organization is unable to create additional
                  curated keywords as it would put the organization over its limit. For further information,
                  please contact your customer success representative.`,
                },
              ],
            },
            { value: 'name',
              label: 'Name',
              type: 'text',
              req: false,
              placeholder: 'Please name your keyword',
            },
            ...(selected.length === 0 ? [{
              value: 'value',
              label: 'Keyword',
              type: 'chips',
              req: true,
              balancedString: true,
              maxLength: 3000,
              placeholder: 'Please enter your keyword parameters. To bulk add keywords, enter a comma delimited list',
              validate: (field, values) => {
                const errors = [];
                const value = values.getIn([0, ...field.value.split('.')], list());
                if (value.size > 250) {
                  errors.push({
                    field: field.value,
                    error: `Max keywords exceeded: ${value.length}/250. If you would like to add more,
                    please break them into multiple groups or contact Customer Service representative.` });
                }
                return errors;
              },
            }] : []),
            ...(selected.length === 1 ? [{
              value: 'value',
              label: 'Keyword',
              type: 'text',
              req: true,
              balancedString: true,
              maxLength: 3000,
              validate: (field, values) => {
                const errors = [];
                const value = values.getIn([0, ...field.value.split('.')], '');
                if (value.length > field.maxLength) errors.push({ field: field.value, error: `Max characters exceeded ${value.length} / ${field.maxLength}` });
                if (values.getIn([0, 'keyclass', 'name'], '').toLowerCase() === 'bin') {
                  const validBin = value.match(validBinRegex);
                  if (!validBin) errors.push({ field: field.value, error: 'Invalid bin format' });
                }
                return errors;
              },
            }] : []),
            ...(data?.getIn([0, 'additional-filters']) ? [{
              value: 'additional-filters',
              label: 'Additional Filters (Locked)',
              type: 'text',
              readOnly: true,
            }] : []),
            { value: 'keyclass',
              label: `Category ${data?.getIn([0, 'keyclass', 'name']) === 'search' ? ' (locked)' : ''}`,
              helperText: 'Keywords set to the "Search" category cannot be changed once saved',
              type: 'dropdown',
              key: 'name',
              valueKey: 'name',
              options: keywordClasses,
              req: true,
              placeholder: 'Please select a keyword category',
              readOnly: data?.getIn([0, 'keyclass', 'name']) === 'search',
              validate: (field, values) => {
                const errors = [];
                const value = values.getIn([0, ...field.value.split('.'), 'name'], '');
                if (value.toLowerCase() === 'bin') {
                  const keywordValues = values.getIn([0, 'value'], list());
                  let toCheck = '';
                  if (typeof keywordValues !== 'string') {
                    // vallidation will occur on review
                    if (keywordValues.size > 1) return [];
                    toCheck = keywordValues.get(0, '');
                  } else {
                    toCheck = keywordValues;
                  }
                  const validBin = toCheck.match(validBinRegex);
                  if (!validBin) errors.push({ field: 'value', error: 'Invalid bin format' });
                }
                return errors;
              },
            },
            { value: 'keywordSubscriptions',
              label: 'recipients',
              placeholder: keywordSubscriptions.size === 0
                ? 'Click to edit recipients'
                : `${keywordSubscriptions.size} recipients selected`,
              type: 'popover',
              key: 'endpoint',
              valueKey: 'id',
              req: false,
              onChangeValues: () => keywordSubscriptions,
              component: <Subscriptions
                email={email}
                filter={false}
                pagination
                selected={subscription}
                onChange={onSubscriptionChange} />,
            },
            ...(prm.some(p => /dea/.test(p)) ? [{
              type: 'inline-toggle',
              label: 'Data Exposure Alerting:',
              icon: 'help',
              iconText: 'DEA searches code repositories in real time. Because of this, we are not able to supply counts below.',
              hidden: v => v.getIn(['curation', 'status']),
              fields: (basetypes || list())
                .filter(v => prm.some(p => v.get('test').test(p)))
                .filter(v => v.get('searchType') === 'code_repository')
                .map((v) => {
                  const children = v.get('children');
                  const filteredChildren = children.filter((c) => {
                    if (c.get('dataSourceName') === 'bitbucket') {
                      return prm.some(p => /dea/.test(p));
                    }
                    return true;
                  });
                  return filteredChildren.map(c => ({
                    value: `dea.${c.get('id')}`,
                    label: Text.Sentence(c.get('title')),
                    type: 'toggle',
                    bulk: true,
                    default: false,
                  }));
                })
                .flatten()
                .toJS(),
            }] : []),
            { value: 'dataSources',
              ...(aggCounts.isEmpty()
                ? { label: 'Data Sources' }
                : { label: `Data Sources (${aggTotalCount.toLocaleString()})` }),
              type: 'checkboxes',
              control: 'button',
              colors: Object.keys(Query.basetypeColorMap)
                .filter(k => k !== 'Code Repositories')
                .map(k => Query.basetypeColorMap[String(k)]),
              inline: true,
              styles: { maxWidth: '65%' },
              key: 'title',
              valueKey: 'id',
              // selectAll: true,
              options: (basetypes || list())
                .filter(v => prm.some(p => v.get('test').test(p)))
                .filter(v => v.get('searchType') !== 'code_repository')
                .filter(v => v.get('searchType') !== 'twitter')
                .map((v) => {
                  if (!v.has('children')) return v;
                  return v.get('children');
                })
                .reduce((accum, v) => {
                  if (!list.isList(v)) return accum.push(v);
                  return accum.concat(v);
                }, list())
                .map((v) => {
                  if (!aggCounts.isEmpty()) {
                    let updatedTitle = v;
                    if (v.has('parent')) {
                      // it's a child, so check the basetypeTerms
                      const term = v.getIn(['basetypeTerms', 2], '');
                      const count = aggCounts.get(term, 0);
                      updatedTitle = updatedTitle.set('title', `${updatedTitle.get('title')} (${Text.AbbreviateNumber(count)})`);
                      return updatedTitle;
                    }
                    const count = aggCounts.get(v.get('searchType'), 0);
                    updatedTitle = updatedTitle.set('title', `${updatedTitle.get('title')} (${Text.AbbreviateNumber(count)})`);
                    return updatedTitle;
                  }
                  return v;
                }),
              defaults: (basetypes || list())
                .filter(v => prm.some(p => v.get('test').test(p)))
                .filter(v => v.get('searchType') !== 'code_repository')
                .map((v) => {
                  if (!v.has('children')) return v.get('id');
                  const childrenIds = v.get('children').map(c => c.get('id'));
                  return list([...childrenIds]);
                })
                .filter(v => v)
                .flatten(), bulk: true,
                hidden: v => v.getIn(['curation', 'status']),
              }]}
          type={type}
          data={bulkData || data}
          onEdit={onKeywordChange}
          save={(typeValue, values) => onSaveKeywords(typeValue, values)}
          saveLabel={(values) => {
            const val = values.getIn([0, 'value'], list());
            // if value is a string, we are editing a single keyword
            if (typeof val === 'string') return 'Save';
            return val.size <= 1 ? 'Save' : 'Review';
          }}
          deleteActions={typeValue => onDeleteKeywords(typeValue)}
          extraActions={[
            ...(!aggData.isEmpty() && !selectedSearchTypes.isEmpty()
              ? [{ label: 'Preview', onClick: value => onPreview(value) }]
              : []),
          ]}
          toggle={() => {
            closeDialog();
          }} />}
        {!inReview && showAggData && !selectedSearchTypes.isEmpty() &&
        <div className={classes.chart}>
          <div>
            <h3>Estimated keyword alerts over last 30 days</h3>
            <div className={classes.warning}>{Messages.KeywordAggregationsNote}</div>
            {selectedSearchTypes.includes('images') &&
            <div className={classes.warning}>{Messages.AlertsImageDuplicates}</div>}
            {aggData.isEmpty() && <CircularProgress />}
            {!aggData.isEmpty() &&
            <StackedBar
              scale="auto"
              format="MM/DD/YY"
              results={filteredAggData}
              labels={fromJS([{ key: 'key' }])}
              colors={aggColors}
              onCellClick={(k, v, value) => {
                const since = moment.utc(value.key).startOf('day').format();
                const until = moment.utc(value.key).endOf('day').format();
                const date = `${since} - ${until}`;
                const values = aggValue
                  .set('date', date)
                  .set('since', since)
                  .set('until', until);
                onPreview(values);
              }}
              emptyMessage="No hits to display" />}
          </div>
          {!aggReload.isEmpty() &&
          <div
            role="link"
            tabIndex={0}
            onKeyUp={() => null}
            className={classes.overlay}
            onClick={() => keywordSearch(aggReload)}>
            Keyword definition has changed. Please click here to refresh the alert totals.
          </div>}
        </div>}
        {inReview && bulkData &&
        <KeywordsReview
          email={email}
          errors={bulkErrors}
          keywords={bulkKeywords}
          prm={prm}
          selected={subscription}
          onChange={values => setBulkSaveData(values)}
          canCurate={canCurate} />}
      </DialogContent>
      {inReview &&
      <DialogActions>
        <Button
          onClick={() => closeDialog()}>
          Cancel
        </Button>
        <Tooltip title="Clicking back will revert any changes made to keywords" placement="top">
          <Button
            onClick={() => {
              setInReview(false);
            }}>
            Back
          </Button>
        </Tooltip>
        <Button
          color="secondary"
          onClick={() => onBulkSave()}>
          Save
        </Button>
      </DialogActions>}
    </Dialog>
  );
};

AlertingKeywordDialog.propTypes = {
  canCurate: PropTypes.bool,
  canEdit: PropTypes.bool,
  email: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  prm: PropTypes.object,
  selected: PropTypes.array,
  subscription: PropTypes.object,
};

AlertingKeywordDialog.defaultProps = {
  canCurate: false,
  canEdit: false,
  email: '',
  onClose: null,
  onDelete: null,
  open: false,
  prm: list(),
  selected: [],
  subscription: map(),
};

export default AlertingKeywordDialog;
