import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { fromJS } from 'immutable';

import Message from './Message';
import MessageForm from './MessageForm';
import Text from '../../../../utils/text';

const useStyles = makeStyles({
  body: {
    fontSize: '1.6rem',
  },
  messsageBackground: {
    maxWidth: '100%',
  },
  message: {
    borderBottom: '1px #E4E4E4 solid',
    display: 'block',
    marginBottom: '2rem',
    maxWidth: 'inherit',
    overflowWrap: 'break-word',
    paddingBottom: '2rem',
  },
});

const MessageColumn = ({ incidentId, messages, user }) => {
  const bottomRef = useRef(null);
  const [userMessages, setUserMessages] = useState(fromJS([]));
  const [postedOnce, setPostedOnce] = useState(false);

  const classes = useStyles();

  /* Force user messages to update every time `messages` is passed in */
  useEffect(() => setUserMessages(fromJS(messages.toJS())), [messages]);

  const handleSubmit = (text, file) => {
    const newMessages = [];

    if (text) {
      /* Update the client view */
      const textID = new Date().getMilliseconds();
      const textMsg = fromJS({
        id: textID,
        created_at: new Date().toUTCString(),
        content: Text.StripCss(Text.StripHtml(text)),
        file_upload: false,
        author: user.get('usn') || 'Anonymous',
        new_post: true,
        incident_id: incidentId,
      });
      newMessages.push(textMsg);
    }

    if (file) {
      const fileId = new Date().getMilliseconds();
      const fileMsg = fromJS({
        id: fileId,
        created_at: new Date().toUTCString(),
        content: file?.name,
        file_upload: true,
        author: user.get('usn') || 'Anonymous',
        new_post: true,
        incident_id: incidentId,
        file,
      });
      newMessages.push(fileMsg);
    }

    if (newMessages.length > 0) {
      setPostedOnce(true);
      setUserMessages([...userMessages, ...newMessages]);
    }
  };

  return (
    <Grid container item xs={5} className={classes.body} ref={bottomRef}>
      <Paper elevation={5} className={classes.messsageBackground} >
        {userMessages.map(item => <Message key={item.get('id')} item={item} scrollRef={bottomRef} />)}
        {postedOnce ? <div className={classes.message}><i>Thanks for the message! We will reach out if more detail is needed.</i></div> : ''}
        <div>
          <MessageForm onSubmit={handleSubmit} scrollRef={bottomRef} />
        </div>
      </Paper>
    </Grid>
  );
};

MessageColumn.propTypes = {
  incidentId: PropTypes.string,
  messages: PropTypes.object,
  user: PropTypes.object,
};

MessageColumn.defaultProps = {
  incidentId: '',
  messages: {},
  user: {},
};

export default MessageColumn;
