import React from 'react';

import Api from '../../utils/api';

const dev = '_self' in React.createElement('div') ? '' : '';
const documentsUrl = `/ui/v4/documents${dev}`;

const Load = type => Api
  .get(`${documentsUrl}/${type}/wiki`, {}, [200], null, null, true)
  .then(res => (res.ok ? res.data : null));


const Save = (type = '', data = {}) => Api
  .put(`${documentsUrl}/${type}/wiki`, data)
  .then(res => (res.ok ? res.data : null));

const Organizations = async () => Api
  .get('/ui/v4/organizations/')
  .then(res => (res.ok ? res.data : []))
  .catch(() => ([]));

export default {
  Load,
  Save,
  Organizations,
};
