import * as React from 'react';
import { useEffect, useState } from 'react';
import { BarChart as ReBarChart, Bar, ResponsiveContainer } from 'recharts';

import { ChartData } from '../../../../../core/types/ChartData';

interface BarChartProps {
  data: ChartData[]
}

const NullData = [{
  name: 'mention',
  count: 0,
  date: 1,
}, {
  name: 'mention',
  count: 0,
  date: 2,
}];

export const BarChart = ({ data }: BarChartProps): JSX.Element => {
  const [chartData, setChartData] = useState(NullData);

  useEffect(() => {
    if (data && data.length > 0) {
      setChartData([...data]);
    }
  }, [data]);

  return (
    <ResponsiveContainer>
      <ReBarChart data={chartData}>
        <Bar dataKey="count" fillOpacity={1} fill="#008bd8" />
      </ReBarChart>
    </ResponsiveContainer>
  );
};
