import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    color: '#1b1b1b',
    fontFamily: '"Rajdhani", sans-serif',
    fontSize: '3rem',
    fontWeight: '600',
    margin: '2rem 0',
  },
  heading: {
    color: '#1b1b1b',
    fontFamily: '"Montserrat", "Open Sans", sans-serif',
    fontSize: '1.8rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  subHeading: {
    color: '#B4B4B4',
    fontFamily: '"Montserrat", "Open Sans", sans-serif',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  body: {
    fontSize: '1.6rem',
  },
  link: {
    color: '#5c6ae0',
  },
  thumbnail: {
    background: '#000',
    border: '1px #000 solid',
    height: 'auto',
    marginRight: '2rem',
    width: '100%',
  },
  row: {
    margin: '2rem 0',
  },
  headingRow: {
    margin: '4rem 0 2rem 0',
  },
  rowLast: {
    margin: '2rem 0 0 0',
  },
  extraTopMargin: {
    marginTop: '1rem',
  },
});

export default useStyles;
