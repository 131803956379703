import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  nodeWrapper: {
    display: 'flex',
    width: '100%',
  },
  backgroundBlue: {
    backgroundColor: '#008BD8',
  },
  barContainer: {
    flex: '1 1 100%',
    display: 'flex',
    marginRight: '9px',
    minWidth: '52px',
  },
});

interface TotalHitsNodeProps {
  totalHits:number;
  topHits:number;
  topHitsWidth:number;
}

export const TotalHitsNode = ({
  totalHits,
  topHits,
  topHitsWidth,
}:TotalHitsNodeProps) => {
  const classes = useStyles();
  return (
    <div className={classes.nodeWrapper}>
      <span className={classes.barContainer}>
        <span
          className={classes.backgroundBlue}
          style={{ flex: `0 0 ${((totalHits * 100.0) / topHits)}%` }}
        />
      </span>
      <span style={{ flex: `0 1 ${topHitsWidth}px` }}>
        {totalHits}
      </span>
    </div>
); };
