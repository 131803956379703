import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { BarGraphIcon } from './BarGraphIcon';
import { LineGraphIcon } from './LineGraphIcon';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiToggleButton-root': {
      border: '2px rgba(0, 0, 0, 0) solid',
      borderRadius: 0,
      padding: 0,
    },
    '& .MuiToggleButton-root:hover': {
      background: 'none',
    },
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'none',
      border: '2px #186AA6 solid',
      borderRadius: '4px',
    },
  },
}));

interface GraphMenuProps {
  type: string,
  onChange: (value: string) => void,
}

export const GraphMenu = ({ type, onChange }: GraphMenuProps): JSX.Element => {
  const [graphType, setGraphType] = useState<string>('line-graph');
  const classes = useStyles();

  useEffect(() => {
    if (!!(type) && (type !== graphType)) {
      setGraphType(type);
    }
  }, [type]);

  const handleChange = (event: object, value: string): void => {
    setGraphType(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      value={graphType}
      exclusive
      onChange={handleChange}
      className={classes.root}
    >
      <ToggleButton value="line-graph">
        <LineGraphIcon />
      </ToggleButton>
      <ToggleButton value="bar-graph">
        <BarGraphIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
