import * as React from 'react';

import cx from 'classnames';

import Dashboard from '../../Dashboard';
import {
  ActorCountWidget,
  RecentSearchesWidget,
  ResultCountWidget,
  TopActorWidget,
  TopSourceWidget,
  TopActorsTableWidget,
  TopSourcesTableWidget,
  MediaWidget,
} from '../Widgets';
import type { ExpoDashFilters } from '../../../../core/types/ExpoDashFilters';
import type { WidgetEntry } from '../../../../core/types/ExpoDashPreferences';
import { navigateFromExpoDash } from '../utils';
import Looker from '../../../../components/utils/Looker';

import style from '../homedashboard.module.scss';

import { ExpoDashData } from '../../../../core/types/ExpoDashPreferences';

interface WidgetSelectorProps {
  data: ExpoDashData,
  filters: ExpoDashFilters,
  settings: WidgetEntry,
  user: Map<string, object>,
  setLook: (value: object) => object,
  setDialog: (value: object) => object,
  dialogKey: 'topRow'|'centerRow',
}

export const WidgetSelector = ({
  data,
  filters,
  settings,
  user,
  setLook,
  setDialog,
  dialogKey,
}: WidgetSelectorProps): JSX.Element => {
  switch (settings.type) {
    case 'actor_count':
      return (<ActorCountWidget
        config={settings}
        filters={filters}
        setDialog={() => setDialog({ key: dialogKey, settings })}
        onClick={() => {
          navigateFromExpoDash({ savedSearch: settings?.savedSearch, filters });
        }}
      />);
    case 'result_count':
      return (
        <ResultCountWidget
          config={settings}
          filters={filters}
          setDialog={() => setDialog({ key: dialogKey, settings })}
          onClick={() => {
            navigateFromExpoDash({ savedSearch: settings?.savedSearch, filters });
          }}
          />
      );
    case 'top_actor':
      return (
        <TopActorWidget
          savedSearch={settings?.savedSearch}
          filters={filters}
          settings={() => setDialog({ key: dialogKey, settings })}
          styles={style}
        />
      );
    case 'top_source':
      return (
        <TopSourceWidget
          savedSearch={settings?.savedSearch}
          filters={filters}
          settings={() => setDialog({ key: dialogKey, settings })}
          styles={style}
        />
      );
    case 'top_actors':
      return (
        <div className={cx(['topActors', style.flexGrowContainer])}>
          <TopActorsTableWidget
            savedSearch={settings?.savedSearch}
            settings={() => setDialog({ key: dialogKey, settings })}
            baseFilters={filters}
          />
        </div>
      );
    case 'top_sources':
      return (
        <div className={cx(['topSources', style.flexGrowContainer])}>
          <TopSourcesTableWidget
            savedSearch={settings?.savedSearch}
            filters={filters}
            settings={() => setDialog({ key: dialogKey, settings })}
            baseFilters={filters}
          />
        </div>
      );
    case 'my_alerts':
      return (
        <div className={cx(['alerts', style.flexGrowContainer])}>
          <Dashboard
            data={data}
            settings={() => setDialog({ key: dialogKey, settings })}
            title="My Alerts"
            help="Displays information about your subscribed alerts">
            <Looker
              height="95%"
              width="100%"
              styles={{ margin: '-105px -30px 0 -30px', height: '150%', maxHeight: 'none' }}
              dashboard="/dashboards-next/alerting::alerts_over_time__internal"
              setupDashboard={(dash: object) => setLook(dash)}
              sid={user.get('sid')}
              filters={{
                  'Profile Endpoint': user.get('email'),
                  'Alert Created Date': filters?.date || '7 Day',
                  'Salesforce ID': user.get('sid'),
                }} />
          </Dashboard>
        </div>
      );
    case 'my_recent_searches':
      return (
        <div className={cx(['recentSearches', style.flexGrowContainer])}>
          <Dashboard data={data} settings={() => setDialog({ key: dialogKey, settings })} title="My Recent Searches" help="Displays your most recent searches over the past 30 days" padding="0">
            <RecentSearchesWidget data={data?.preferences}/>
          </Dashboard>
        </div>
      );
    case 'media':
      return (
        <div className={cx(['alerts', style.flexGrowContainer])}>
          <MediaWidget
            config={settings}
            filters={filters}
            setDialog={() => setDialog({ key: 'centerRow', settings })}
          />
        </div>
      );
    default:
      return (
        <>
          <Dashboard
            data={data}
            title={settings.type}
            settings={() => setDialog({ key: dialogKey, settings })}
          >
            <h2>Not implemented</h2>
          </Dashboard>
        </>
      );
  }
};
