import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { textFieldStyle } from '../../rfi/RfiForm/TagTextField';
import PersonSearchState from '../../../stores/recoil/personSearch';

const useStyles = makeStyles(() => (Object.assign(textFieldStyle, {
  root: {
    ...textFieldStyle.root,
    padding: '4px 0px 4px 10px',
  },
})));

const PersonSearchTextField = ({
  formField,
  placeholder,
  setTags,
  setTextFieldValidationError,
  tags,
}) => {
  const classes = useStyles();
  const inputEl = useRef(null);
  const [error, setError] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(tags);
  const [ageFrom, setAgeFrom] = useRecoilState(PersonSearchState.ageFrom);
  const [ageTo, setAgeTo] = useRecoilState(PersonSearchState.ageTo);

  const validateField = (e, fieldText) => {
    e.preventDefault();
    setError(false);
    if (e.target.value === '') return null;

    fieldText.map((v) => {
      const allowedDigitLength = [10, 11, 12, 13];
      // eslint-disable-next-line security/detect-unsafe-regex
      const emailRegEx = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
      const ipAddressRegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      let age;
      if (['ageFrom', 'ageTo'].includes(formField)) {
        age = parseInt(v, 10);
      }

      switch (formField) {
        case 'name':
          if (v.split(' ').length < 2) setError(true);
          break;
        case 'email':
          if (!emailRegEx.test(v.replace('[@]', '@').replace('[.]', '.'))) setError(true);
          break;
        case 'phone':
          if (!allowedDigitLength.includes(v.replaceAll(/[^0-9\.]+/g, '').length)) setError(true);
          break;
        case 'ipAddress':
          if (!ipAddressRegEx.test(v)) setError(true);
          break;
        case 'ageFrom':
          if (age < 18 || age > 99 || age > parseInt(ageTo, 10) || !age) setError(true);
          break;
        case 'ageTo':
          if (age < 19 || age > 100 || age < parseInt(ageFrom, 10) || !age) setError(true);
          break;
        case 'username':
          if (emailRegEx.test(v.replace('[@]', '@').replace('[.]', '.'))) setError(true);
          break;
        default:
          break;
      }
     return null;
    });
    return null;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const trimmedText = value.length ? value.split(',').map(v => v.trim()) : [];
    setError(false);
    setTextFieldValue(value);

    switch (formField) {
      case 'phone':
        setTags(trimmedText.map(v => v.replaceAll(/[^0-9\.]+/g, '')));
        break;
      case 'ageFrom':
        setAgeFrom(trimmedText);
        break;
      case 'ageTo':
        setAgeTo(trimmedText);
        break;
      default:
        setTags(trimmedText);
        break;
    }
  };

  useEffect(() => {
    setTextFieldValidationError(error);
  }, [error]);

  return (
    <TextField
      autoComplete="new-password"
      className={classes.textField}
      error={error}
      helperText={error && 'This field is not valid'}
      inputRef={inputEl}
      onBlur={e => validateField(e, tags)}
      onChange={e => handleChange(e)}
      placeholder={placeholder}
      value={textFieldValue}
      variant="outlined"
    />
  );
};

PersonSearchTextField.propTypes = {
  formField: PropTypes.string,
  placeholder: PropTypes.string,
  setTags: PropTypes.func,
  setTextFieldValidationError: PropTypes.func,
  tags: PropTypes.array,
};

PersonSearchTextField.defaultProps = {
  formField: '',
  placeholder: '',
  setTags: null,
  setTextFieldValidationError: null,
  tags: [],
};

export default PersonSearchTextField;
