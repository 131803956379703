/**
 * List of routes/applications available in platform
 * along with display options where necessary.
 *
 * value - unique identifier
 * parent - parent type of type
 * hidden - valid route but not visible in navigation
 * label - display used in navigation
 * search - is a search category
 * api - included in api token generation access
 * searchType - ES basetype used in search query
 * subHeader - label used in sub navigation
 * home - can be selected as homepage
 * sidebar - displays sidebar overlay when displaying results
 * subnav - displays subnav to jump between similar types
 * icon - iconography used in navigation
 * alt - alternate label to distinguish similar types communities-media vs assets-media
 * placeholder - placeholder text used in inputs
 * path - list of paths (including legacy) used to route request
 * ui - requires UI permissions
 * exclude - negated regexp permissioning
 * test - regexp to test users permissions for access
 */

export default [
  // Top level
  { value: 'search',
    path: ['/home/'],
    nav: 'Search',
    icon: 'search',
    advanced: true,
    hidden: true,
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw|edm/ },
  { value: 'explore',
    nav: 'Explore Topics',
    path: ['/home/explore'],
    route: true,
    icon: 'fa-compass',
    ui: true,
    test: /ddw/ },
  { value: 'intelligence',
    nav: 'Intelligence',
    path: ['/home/intelligence'],
    route: true,
    icon: 'fa-graduation-cap',
    ui: true,
    test: /rep|ddw/ },
  { value: 'collections',
    nav: 'Collections',
    path: ['/home/search'],
    route: true,
    icon: 'fa-columns-3',
    ui: true,
    search: true,
    test: /chat|frm|mkt|tor|vis|edm|ddw/ },
  { value: 'alerting',
    nav: 'Alerting',
    path: ['/home/alerting'],
    route: true,
    icon: 'fa-bell',
    ui: true,
    test: /kp|np|alrt/ },
  { value: 'dashboards',
    nav: 'Dashboards',
    path: ['/home/dashboards'],
    route: true,
    icon: 'fa-chart-mixed',
    ui: true,
    test: /ddw|edm|csb/ },
  { value: 'fraud',
    nav: 'Financial Fraud',
    path: ['/home/fraud'],
    route: true,
    icon: 'fa-building-columns',
    ui: true,
    test: /ddw|edm|csb/ },
  { value: 'vulndb',
    nav: 'Vulnerability Intelligence',
    path: ['/vulndb'],
    route: true,
    icon: 'fa-radiation',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'monit',
    nav: 'Brand Monitoring',
    path: ['/home/monit'],
    icon: 'fa-shield-check',
    ui: true,
    hidden: true,
    test: /ddw|edm|csb/ },
  { value: 'cti',
    nav: 'Cyber Threat Intelligence',
    path: ['/cti'],
    icon: 'fa-network-wired',
    ui: true,
    hidden: true,
    test: /ddw|edm|csb/ },
  { value: 'ato',
    nav: 'Account Takeover',
    path: ['/ato'],
    route: true,
    icon: 'fa-lock-keyhole',
    ui: true,
    test: /ddw|edm|csb/ },
  { value: 'rfi',
    nav: 'Submit an RFI',
    path: ['/home/rfi/form'],
    icon: 'fa-clipboard-list',
    topLevelNav: true, // Routes from top level nav
    ui: true,
    test: /dat.rfi.w/ },

  // Explore
  { value: 'explore.trending_now',
    parent: 'explore',
    home: true,
    label: 'Trending Now',
    alt: 'Explore - Trending Now',
    icon: 'home',
    text: '',
    subnav: true,
    path: ['/home/explore/topics/trending_now'],
    ui: true,
    test: /ddw/ },
  { value: 'explore.topics.view',
    parent: 'wiki', // needed for userStore.onRoute and bookmark
    label: 'Topic ',
    subnav: true,
    alt: 'Explore - Topic',
    icon: 'library_books',
    text: 'Malware Wiki Data',
    title: ['topic', 'topic_name'],
    path: ['/home/wiki/topic'],
    ui: true,
    test: /ddw/ },
  // Intelligence
  { value: 'intelligence.finished',
    api: true,
    home: true,
    subnav: true,
    parent: 'intelligence',
    label: 'All Intelligence',
    icon: 'new_releases',
    alt: 'Intelligence',
    text: 'The latest finished intelligence published by the Flashpoint SME team.',
    path: ['/home/intelligence/latest'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.standup',
    parent: 'intelligence',
    home: true,
    subnav: true,
    label: 'Standups',
    alt: 'Intelligence - Standup',
    icon: 'access_time',
    title: ['reports', 'title'],
    text: 'Daily overview of notable news stories with Flashpoint Intelligence Team commentary.',
    path: ['/home/intelligence/standup'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.actor',
    parent: 'intelligence',
    label: 'Actor Profiles',
    home: true,
    subnav: true,
    alt: 'Intelligence - Actor Profiles',
    icon: 'assignment_ind',
    text: 'Intelligence gathered on notable threat actors.',
    path: ['/home/intelligence/actors'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.techintel',
    parent: 'intelligence',
    label: 'Technical Intelligence',
    home: true,
    subnav: true,
    alt: 'Intelligence - Technical Intelligence',
    icon: 'no_encryption',
    text: 'Intelligence gathered on indicators of compromise.',
    path: ['/home/intelligence/techintel'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.knowledgebase',
    parent: 'intelligence',
    label: 'Knowledge Base',
    home: true,
    subnav: true,
    alt: 'Intelligence - Knowledge Base',
    icon: 'no_encryption',
    text: 'Knowledge Base Data',
    path: ['/home/intelligence/knowledgebase'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.malware',
    parent: 'intelligence',
    label: 'Malware',
    home: true,
    subnav: true,
    alt: 'Intelligence - Malware',
    icon: 'bug_report',
    text: 'Malware Wiki Data',
    path: ['/home/intelligence/malware'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.apt',
    parent: 'intelligence',
    label: 'Advanced Persistent Threats',
    home: true,
    subnav: true,
    alt: 'Intelligence - Advanced Persistent Threats',
    icon: 'bug_report',
    text: 'Advanced Persistent Threats',
    path: ['/home/intelligence/apt'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.person-search',
    parent: 'intelligence',
    label: 'Person Search',
    home: true,
    subnav: true,
    alt: 'Intelligence - Person Search',
    icon: 'search',
    text: 'Person Search',
    path: ['/home/intelligence/person-search/form'],
    ui: true,
    test: /dat.poi.rep.req/ },
  // Collections
  { value: 'search.all',
    parent: 'collections',
    label: 'All',
    search: true,
    all: true,
    searchType: 'all',
    sidebar: true,
    hidden: true,
    icon: 'search',
    text: 'Search through all datasets available to Flashpoint',
    alt: 'Collections - All',
    placeholder: 'e.g. trickbot, threat landscape, thedarkoverlord, fullz, "yourdomain.com"',
    path: ['/home/search/all'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'search.reports',
    parent: 'intelligence',
    label: 'Intelligence Search',
    search: true,
    searchType: 'reports',
    home: true,
    hidden: true,
    sidebar: true,
    subnav: true,
    icon: 'lightbulb_outline',
    text: 'Search from among over 10,000 published finished intelligence reports.',
    alt: 'Collections - Intelligence',
    placeholder: 'e.g. trickbot, threat landscape, thedarkoverlord, fullz, "yourdomain.com"',
    path: ['/home/intelligence/reports/search'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'search.communities',
    parent: 'collections',
    label: 'All Communities',
    search: true,
    all: true,
    searchType: 'communities',
    subHeader: 'Communities',
    home: true,
    sidebar: true,
    subnav: true,
    icon: 'search',
    text: 'Search across all of Flashpoint\'s communities.',
    alt: 'Collections - Communities',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/communities'],
    ui: true,
    test: /for|chat|mkt|ddw/ },
  { value: 'search.forums',
    parent: 'collections',
    label: 'Forums',
    api: true,
    search: true,
    searchType: 'forums',
    subHeader: 'Communities',
    home: true,
    sidebar: true,
    subnav: true,
    icon: 'forums',
    text: 'Search across over five hundred Deep & Dark Web communities.',
    alt: 'Collections - Communities > Forums',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/forums'],
    ui: true,
    test: /for|ddw/ },
  { value: 'search.chats',
    parent: 'collections',
    label: 'Chats',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'chats',
    subHeader: 'Communities',
    icon: 'chat',
    text: 'Research data from threat actor communities established on chat services.',
    alt: 'Collections - Communities > Chats',
    placeholder: 'e.g. "1.1.1.1", "CVE-2017-5715", "yourdomain.com", buy ransomware',
    path: ['/home/search/chats'],
    ui: true,
    exclude: /_no\.chat/,
    test: /chat|ddw/ },
  { value: 'search.blogs',
    parent: 'collections',
    label: 'Blogs',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'blogs',
    subHeader: 'Communities',
    icon: 'web',
    text: 'Search across threat actors and security research sites',
    alt: 'Collections - Communities > Blogs',
    placeholder: 'e.g. search blog post text',
    path: ['/home/search/blogs'],
    ui: true,
    test: /ddw/ },
  { value: 'search.ransomware',
    parent: 'collections',
    label: 'Ransomware',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'ransomware',
    subHeader: 'Communities',
    icon: 'web',
    text: 'Search across threat actors and security research sites',
    alt: 'Collections - Communities > Ransomware',
    placeholder: 'e.g. search ransomware post text',
    path: ['/home/search/ransomware', '/home/search/ransomwares'],
    ui: true,
    test: /ddw/ },
  { value: 'search.pastes',
    parent: 'collections',
    label: 'Paste Sites',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchBar: 'Paste Sites',
    searchType: 'pastes',
    subHeader: 'Communities',
    icon: 'assignment',
    text: 'Search from among the top active and archived paste sites.',
    alt: 'Collections - Communities > Pastes',
    path: ['/home/search/pastes'],
    placeholder: 'e.g. search paste sites text',
    ui: true,
    test: /ddw/ },
  { value: 'search.boards',
    parent: 'collections',
    label: 'Boards',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'boards',
    subHeader: 'Communities',
    icon: 'view_agenda',
    text: 'Search within 4chan and 8chan communities',
    alt: 'Collections - Communities > Boards',
    path: ['/home/search/boards'],
    placeholder: 'e.g. search chan text',
    ui: true,
    test: /ddw/ },
  { value: 'search.social',
    parent: 'collections',
    label: 'Social News',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'social',
    subHeader: 'Communities',
    icon: 'people',
    text: 'Search within social news communities',
    alt: 'Collections - Communities > Social News',
    path: ['/home/search/social', '/home/search/gab'],
    placeholder: 'e.g. search social news text',
    ui: true,
    test: /ddw/ },
  { value: 'search.twitter',
    parent: 'collections',
    label: 'Twitter',
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'twitter',
    subHeader: 'Communities',
    icon: 'twitter',
    text: 'Twitter',
    alt: 'Collections - Communities > Twitter',
    placeholder: 'e.g. twitter',
    path: ['/home/search/twitter'],
    ui: true,
    test: /twtr/ },
  { value: 'search.media',
    parent: 'media',
    label: 'All Media',
    search: true,
    all: true,
    searchType: 'media',
    subHeader: 'Media',
    home: true,
    sidebar: false,
    icon: 'media',
    text: 'Media content',
    alt: 'Search - Media',
    placeholder: 'e.g. image phash or video subtitle',
    path: ['/home/search/media'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.images',
    parent: 'collections',
    label: 'Images',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'images',
    subHeader: 'Media',
    icon: 'media',
    text: 'Media content',
    alt: 'Search - Media > Images',
    placeholder: 'e.g. image phash',
    path: ['/home/search/images'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.videos',
    parent: 'collections',
    label: 'Videos',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'videos',
    subHeader: 'Media',
    icon: 'media',
    text: 'Media content',
    alt: 'Search - Media > Videos',
    placeholder: 'e.g. video subtitles',
    path: ['/home/search/videos'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'search.marketplaces',
    parent: 'collections',
    label: 'Marketplaces',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'marketplaces',
    subHeader: 'Shops',
    icon: 'shopping_cart',
    text: 'Search from among the top active and archived Deep & Dark Web marketplaces.',
    alt: 'Collections - Shops > Marketplaces',
    placeholder: 'e.g. narcotics, fullz, guides, skimmers',
    path: ['/home/search/marketplaces'],
    ui: true,
    test: /mkt|ddw/ },
  { value: 'search.cards',
    parent: 'collections',
    label: 'Card Shops',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'cards',
    subHeader: 'Shops',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    alt: 'Collections - Shops > Card Shops',
    path: ['/home/search/cards'],
    placeholder: 'e.g. search card',
    ui: true,
    test: /csb/ },
  { value: 'search.accounts',
    parent: 'collections',
    label: 'Account Shops',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'accounts',
    subHeader: 'Shops',
    icon: 'account_balance',
    alt: 'Collections - Shops > Account Shops',
    placeholder: 'domains or online services e.g. outlook, ebay, paypal',
    path: ['/home/search/accounts'],
    ui: true,
    test: /ddw/ },
  { value: 'search.credentials',
    parent: 'collections',
    label: 'Enterprise Credentials',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'credentials',
    subHeader: 'Assets',
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised enterprise credentials',
    alt: 'Collections - Assets > Enterprise Credentials',
    path: ['/home/search/credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /edm/ },
  { value: 'search.customer-credentials',
    parent: 'collections',
    label: 'Customer Credentials',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'customer-credentials',
    subHeader: 'Assets',
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised customer credentials',
    alt: 'Collections - Assets > Customer Credentials',
    path: ['/home/search/customer-credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /dat.ccm.cus.r/ },
  { value: 'search.dmtm-incidents',
    alt: 'Collections - Domain Monitoring',
    parent: 'collections',
    subHeader: 'Domain Monitoring',
    home: true,
    subnav: true,
    label: 'Incidents',
    title: ['Incidents'],
    path: ['/home/search/dmtm/incidents', '/home/dmtm/incidents', '/home/assets/domain-monitoring'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'search.monitored-urls',
    parent: 'collections',
    home: true,
    subnav: true,
    path: ['/home/search/dmtm/monitored-urls', '/home/dmtm/monitored-urls'],
    subHeader: 'Domain Monitoring',
    label: 'Monitored URLs',
    alt: 'Collections - Monitored URLs',
    title: ['Monitored URLs'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'search.cves',
    parent: 'collections',
    label: 'Vulnerabilities - CVEs',
    api: true,
    taxii: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'cves',
    subHeader: 'Technical Data',
    icon: 'bug_report',
    text: 'Identify recently released vulnerabilities',
    alt: 'Collections - Technical Data > Vulnerabilities: CVEs',
    path: ['/home/search/cves'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'search.exploits',
    parent: 'collections',
    label: 'Exploits',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'exploits',
    subHeader: 'Technical Data',
    icon: 'bug_report',
    text: 'Identify recently released exploits',
    alt: 'Collections - Technical Data > Exploits',
    path: ['/home/search/exploits'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'search.iocs',
    parent: 'collections',
    label: 'Indicators of Compromise',
    api: true,
    taxii: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'iocs',
    subHeader: 'Technical Data',
    icon: 'bug_report',
    text: 'Indicators database',
    alt: 'Collections - Technical Data > IOCs',
    path: ['/home/search/iocs'],
    placeholder: 'e.g. "cobaltstrike"',
    ui: true,
    test: /ddw/ },
  { value: 'search.sanctions',
    parent: 'collections',
    home: true,
    sidebar: true,
    subnav: true,
    subHeader: 'Technical Data',
    label: 'Sanctions',
    icon: 'search',
    alt: 'Collections - Sanctions',
    text: 'Sanction Search',
    path: ['/home/search/sanctions'],
    ui: true,
    test: /ddw/ },
  { value: 'search.tagging.channels',
    parent: 'collections',
    home: true,
    sidebar: true,
    subnav: true,
    subHeader: 'Analyst Annotations',
    label: 'Telegram Sources',
    alt: 'Collections - Telegram Sources',
    icon: 'chat',
    path: ['/home/search/tagging/channels', '/home/tagging/channels'],
    ui: true,
    test: /meta.chat.w/ },
  // Alerting
  { value: 'dashboards.alerting',
    parent: 'alerting',
    label: 'Alerting Dashboard',
    alt: 'Alerting - Dashboard',
    icon: 'assessment',
    text: 'Alerting Overview',
    home: true,
    settings: true,
    subnav: true,
    path: ['/home/alerting/overview'],
    test: /dat.alrt.org/ },
  { value: 'alerting.alerts',
    parent: 'alerting',
    label: 'My Alerts',
    alt: 'Alerting - My Alerts',
    icon: 'person',
    text: 'Alerting Alerts',
    home: true,
    subnav: true,
    path: ['/home/alerting/alerts'],
    ui: true,
    test: /dat.alrt.org/ },
  { value: 'alerting.dea',
    parent: 'alerting',
    home: true,
    label: 'Data Exposure Alerts',
    alt: 'Alerting - Data Exposure Alerts',
    icon: 'person',
    subnav: true,
    text: 'Data Exposure Alerts',
    searchType: 'code_repository',
    path: ['/home/alerting/dea'],
    ui: true,
    test: /dea/ },
  { value: 'alerting.keywords',
    parent: 'alerting',
    home: true,
    label: 'Keywords',
    alt: 'Alerting - Keywords',
    icon: 'mail',
    subnav: true,
    text: 'Alerting Keywords',
    path: ['/home/alerting/keywords'],
    ui: true,
    test: /kw/ },
  { value: 'alerting.recipients',
    parent: 'alerting',
    home: true,
    label: 'Recipients',
    alt: 'Alerting - Recipients',
    icon: 'person',
    text: 'Alerting Recipients',
    subnav: true,
    path: ['/home/alerting/recipients'],
    ui: true,
    test: /np/ },
  { value: 'alerting.saved',
    parent: 'alerting',
    home: true,
    label: 'Saved Searches',
    alt: 'Alerting - Saved Searches',
    icon: 'save',
    subnav: true,
    path: ['/home/alerting/manage/searches'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  // Dashboards
  { value: 'dashboards.expodash',
    parent: 'dashboards',
    home: true,
    settings: false,
    label: 'Home',
    alt: 'Dashboards - Home',
    icon: 'home',
    text: '',
    subnav: true,
    path: ['/home/dashboards/expodash'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.ransomware.ransomware',
    parent: 'dashboards',
    subHeader: 'Ransomware',
    home: true,
    subnav: true,
    label: 'Ransomware',
    alt: 'Dashboards - Ransomware',
    icon: 'public',
    text: 'Ransomware',
    path: ['/home/dashboards/ransomware'],
    ui: true,
    test: /ddw/ },
    { value: 'dashboards.ransomware.victims',
    parent: 'dashboards',
    subHeader: 'Ransomware',
    home: true,
    subnav: true,
    label: 'Ransomware Victims',
    alt: 'Dashboards - Ransomware Victims',
    icon: 'public',
    text: 'Victims',
    path: ['/home/dashboards/victims'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.ransomware.ransomers',
    parent: 'dashboards',
    subHeader: 'Ransomware',
    home: true,
    subnav: true,
    label: 'Ransomers',
    alt: 'Dashboards - Ransomers',
    icon: 'public',
    text: 'Ransomers',
    path: ['/home/dashboards/ransomers'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.exposure',
    parent: 'dashboards',
    home: true,
    subnav: true,
    subHeader: 'Assets',
    label: 'My Credential Exposure',
    alt: 'Dashboards - My Credential Exposure',
    icon: 'public',
    text: 'Identify My Credential Exposure',
    path: ['/home/dashboards/exposure'],
    ui: true,
    test: /dat\.edm(\.org)?\.r/ },
  { value: 'dashboards.breach',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Credential Breaches',
    alt: 'Dashboards - Breaches',
    icon: 'lock_open',
    text: 'Identify Breaches',
    path: ['/home/dashboards/credbreaches'],
    ui: true,
    test: /ddw|edm/ },
  { value: 'dashboards.cves',
    parent: 'dashboards',
    home: true,
    settings: true,
    subnav: true,
    label: 'CVEs',
    alt: 'Dashboards - CVEs',
    icon: 'bug_report',
    text: 'Identify recently released cves and the threat actor discussions about them',
    path: ['/home/dashboards/cves'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'dashboards.iocs',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Indicators of Compromise',
    alt: 'Dashboards - IOCs',
    icon: 'bug_report',
    text: 'Identify IOCs',
    path: ['/home/dashboards/iocs'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.fraud',
    parent: 'dashboards',
    home: true,
    settings: true,
    subnav: true,
    label: 'Card Shops Overview',
    alt: 'Dashboards - Card Shops Overview',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    path: ['/home/dashboards/fraud'],
    ui: true,
    test: /cfm|csb/ },
  { value: 'dashboards.cards',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Card Shops',
    alt: 'Dashboards - Card Shops',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    path: ['/home/dashboards/mycards'],
    ui: true,
    test: /cfm/ },
  { value: 'dashboards.fullcards',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Full Cards',
    alt: 'Dashboards - Full Cards',
    icon: 'public',
    text: 'Full Cards',
    path: ['/home/dashboards/fullcards'],
    test: /cfm/ },
  { value: 'dashboards.merchants',
    parent: 'dashboards',
    home: true,
    settings: false,
    subnav: true,
    label: 'High-Risk Merchants',
    alt: 'Dashboards - High-Risk Merchants',
    icon: 'public',
    text: 'High-Risk Merchants',
    path: ['/home/dashboards/merchants'],
    test: /dat.cfm.hrm.r/ },
  { value: 'dashboards.managebins',
    parent: 'dashboards',
    subHeader: 'Dahboards - Manage Bins',
    subnav: true,
    home: true,
    label: 'Manage Bins',
    alt: 'Dashboards - Manage Bins',
    icon: 'public',
    text: 'Manage Bins',
    path: ['/home/dashboards/managebins'],
    test: /cfm/ },
  { value: 'dashboards.ukraine-crisis',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Ukraine Crisis',
    alt: 'Dashboards - Ukraine Crisis',
    text: 'Ukraine Crisis',
    path: ['/home/dashboards/ukraine-crisis'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.domainmonitoring',
    parent: 'dashboards',
    home: true,
    subnav: true,
    subHeader: 'Assets',
    label: 'Domain Monitoring',
    alt: 'Dashboards - Domain Monitoring',
    icon: 'home',
    path: ['/home/dashboards/monitoring'],
    test: /dat.dm.r/ },
  { value: 'dashboards.bitcoin',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Bitcoin',
    alt: 'Dashboards - Bitcoin',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/bitcoin', '/home/labs/bitcoin'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.botshops',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Bot Shops',
    alt: 'Dashboards - Bot Shops',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/botshops', '/home/labs/botshops'],
    ui: true,
    test: /ddw/ },
  { value: 'dashboards.access-auctions',
    parent: 'dashboards',
    home: true,
    subnav: true,
    label: 'Access Auctions',
    alt: 'Dashboards - Access Auctions',
    icon: 'home',
    text: '',
    path: ['/home/dashboards/access-auctions', '/home/labs/access-auctions'],
    ui: true,
    test: /ddw/ },
  // Financial Fraud
  { value: 'dashboards.fraud',
    parent: 'fraud',
    home: true,
    settings: true,
    subnav: true,
    label: 'Card Shops Overview',
    alt: 'Fraud - Card Shops Overview',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    path: ['/home/fraud/fraud'],
    ui: true,
    test: /cfm|csb/ },
  { value: 'fraud.cards',
    parent: 'fraud',
    home: true,
    settings: false,
    subnav: true,
    label: 'Card Shops Dashboard',
    alt: 'Fraud - Card Shops',
    icon: 'credit_card',
    text: 'Identify compromised credit cards and dumps exposed by threat actors',
    path: ['/home/fraud/mycards'],
    ui: true,
    test: /cfm/ },
  { value: 'fraud.fullcards',
    parent: 'fraud',
    home: true,
    settings: false,
    subnav: true,
    label: 'Full Cards Dashboard',
    alt: 'Fraud - Full Cards',
    icon: 'public',
    text: 'Full Cards',
    path: ['/home/fraud/fullcards'],
    test: /cfm/ },
  { value: 'fraud.merchants',
    parent: 'fraud',
    home: true,
    settings: false,
    subnav: true,
    label: 'High-Risk Merchants Dashboard',
    alt: 'Fraud - High-Risk Merchants',
    icon: 'public',
    text: 'High-Risk Merchants',
    path: ['/home/fraud/merchants', '/home/dashboards/merchants'],
    test: /dat.cfm.hrm.r/ },
  { value: 'fraud.manage',
    parent: 'fraud',
    subnav: true,
    home: true,
    label: 'Manage Bins',
    alt: 'Fraud - Manage Bins',
    icon: 'public',
    text: 'Manage Bins',
    path: ['/home/fraud/managebins'],
    test: /cfm/ },
  { value: 'fraud.bitcoin',
    parent: 'fraud',
    home: true,
    settings: false,
    subnav: true,
    label: 'Bitcoin Dashboard',
    alt: 'Fraud - Bitcoin',
    icon: 'home',
    text: '',
    path: ['/home/fraud/bitcoin'],
    ui: true,
    test: /cfm/ },
  { value: 'fraud.reports',
    parent: 'fraud',
    home: true,
    settings: false,
    subnav: true,
    label: 'Financial Fraud Reports',
    alt: 'Fraud - Financial Fraud Reports',
    icon: 'home',
    text: '',
    path: ['/home/intelligence/fraud/latest/Financial'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'fraud.topics',
    parent: 'fraud',
    home: true,
    settings: false,
    label: 'Cybercrime Topics',
    alt: 'Fraud - Cybercrime Topics',
    icon: 'home',
    text: '',
    subnav: true,
    path: ['/home/explore/fraud/topics/Cyber Crime'],
    ui: true,
    test: /ddw/ },
  { value: 'fraud.ocr',
    parent: 'fraud',
    home: true,
    settings: false,
    label: 'Optical Character Recognition Search Topics',
    alt: 'Fraud - Optical Character Recognition Search Topics',
    icon: 'home',
    text: '',
    subnav: true,
    path: ['/home/explore/fraud/topics/Optical Character Recognition Search'],
    ui: true,
    test: /ddw/ },
  // VulnDB
  { value: 'vulndb.vulnerabilities',
    parent: 'vulndb',
    label: 'Vulnerability Collections',
    home: true,
    search: true,
    subnav: true,
    searchType: 'cves',
    icon: 'bug_report',
    text: 'Identify recently released vulnerabilities',
    alt: 'Vulnerability Intelligence - Technical Data > Vulnerabilities: CVEs',
    path: ['/home/search/vulndb/cves'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'vulndb.exploits',
    parent: 'vulndb',
    search: true,
    subnav: true,
    label: 'Exploit Collections',
    home: true,
    searchType: 'exploits',
    icon: 'bug_report',
    text: 'Identify recently released exploits',
    alt: 'Vulnerability Intelligence - Technical Data > Exploits',
    path: ['/home/search/vulndb/exploits'],
    placeholder: 'e.g. "CVE-2017-5715"',
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'vulndb.reports',
    parent: 'vulndb',
    home: true,
    subnav: true,
    label: 'Vulnerability Reports',
    alt: 'Vulnerability Intelligence - Reports',
    icon: 'home',
    text: '',
    path: ['/home/intelligence/vulndb/latest/Exploits & Vulnerabilities'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'vulndb.topics',
    parent: 'vulndb',
    home: true,
    subnav: true,
    settings: true,
    label: 'Vulnerability Topics',
    alt: 'Vulnerability Intelligence - Topics',
    icon: 'home',
    text: '',
    path: ['/home/explore/vulndb/topics/Vulnerabilities'],
    ui: true,
    test: /vln.r/ },
  // Brand Monitoring
  // ATO
  { value: 'ato.breach',
    parent: 'ato',
    home: true,
    subnav: true,
    label: 'Credential Breaches Dashboard',
    alt: 'Account Takeover - Breaches Dashboard',
    icon: 'lock_open',
    text: 'Identify Breaches',
    path: ['/home/dashboards/ato/credbreaches'],
    ui: true,
    test: /ddw|edm/ },
  { value: 'ato.exposure',
    parent: 'ato',
    home: true,
    subnav: true,
    subHeader: 'Assets',
    label: 'My Credential Exposure Dashboard',
    alt: 'Account Takeover - My Credential Exposure',
    icon: 'public',
    text: 'Identify My Credential Exposure',
    path: ['/home/dashboards/ato/exposure'],
    ui: true,
    test: /dat\.edm(\.org)?\.r/ },
  { value: 'ato.credentials',
    parent: 'ato',
    label: 'Enterprise Credentials Collections',
    api: true,
    home: true,
    sidebar: true,
    search: true,
    subnav: true,
    searchType: 'credentials',
    subHeader: 'Assets',
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised enterprise credentials',
    alt: 'Account Takeover - Assets > Enterprise Credentials',
    path: ['/home/search/ato/credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /edm/ },
  { value: 'ato.customer-credentials',
    parent: 'ato',
    label: 'Customer Credentials Collections',
    home: true,
    subnav: true,
    searchType: 'customer-credentials',
    subHeader: 'Assets',
    icon: 'email',
    text: 'Search across Flashpoint\'s set of compromised customer credentials',
    alt: 'Account Takeover - Assets > Customer Credentials',
    path: ['/home/search/ato/customer-credentials'],
    placeholder: 'e.g. your company email domain',
    ui: true,
    test: /dat.ccm.cus.r/ },
  { value: 'ato.breached.topics',
    parent: 'ato',
    home: true,
    settings: true,
    subnav: true,
    label: 'Breached Data Topics',
    alt: 'Account Takeover - Breached Data Topics',
    icon: 'home',
    text: '',
    path: ['/home/explore/ato/topics/Breached Data'],
    ui: true,
    test: /ddw/ },
  { value: 'ato.cyber.topics',
    parent: 'ato',
    home: true,
    settings: true,
    subnav: true,
    label: 'Cybercrime Topics',
    alt: 'Account Takeover - Cybercrime Topics',
    icon: 'home',
    text: '',
    path: ['/home/explore/ato/topics/Cyber Crime'],
    ui: true,
    test: /ddw/ },
  { value: 'ato.ocr.topics',
    parent: 'ato',
    home: true,
    settings: true,
    subnav: true,
    label: 'Optical Character Recognition Search Topics',
    alt: 'Account Takeover - Optical Character Recognition Search Topics',
    icon: 'home',
    text: '',
    path: ['/home/explore/ato/topics/Optical Character Recognition Search'],
    ui: true,
    test: /ddw/ },
  // Resources
  { value: 'resources.updates',
    parent: 'resources',
    label: 'Release Notes',
    alt: 'Resources - Release Notes',
    text: 'Release Notes',
    icon: 'new_releases',
    path: ['/home/resources/updates'],
    test: /ui/ },
  { value: 'resources.glossary',
    parent: 'resources',
    label: 'Glossary',
    alt: 'Resources - Glossary',
    icon: 'school',
    text: 'A compendium of underground terminology with descriptions penned by Flashpoint SMEs.',
    path: ['/home/resources/glossary'],
    test: /rep|ddw/ },
  { value: 'resources.syntax',
    parent: 'resources',
    label: 'Query Syntax',
    alt: 'Resources - Query Syntax',
    icon: 'search',
    text: 'Query Syntax',
    path: ['/home/resources/syntax'],
    test: /ui/ },
  { value: 'integrations.all',
    parent: 'integrations',
    label: 'All Integrations',
    alt: 'Integrations - All',
    home: true,
    icon: 'person',
    path: ['/home/integrations/all'],
    test: /ddw/ },
  { value: 'integrations.analysis',
    parent: 'integrations',
    label: 'Analysis',
    home: true,
    alt: 'Integrations - Analysis',
    icon: 'no_encryption',
    path: ['/home/integrations/analysis'],
    test: /ddw/ },
  { value: 'integrations.digitalrisk',
    parent: 'integrations',
    label: 'Digital Risk',
    home: true,
    alt: 'Integrations - Digital Risk',
    icon: 'no_encryption',
    path: ['/home/integrations/digital'],
    test: /ddw/ },
  { value: 'integrations.orchestration',
    parent: 'integrations',
    label: 'Orchestration',
    home: true,
    alt: 'Integrations - Orchestration',
    icon: 'no_encryption',
    path: ['/home/integrations/orchestration'],
    test: /ddw/ },
  { value: 'integrations.productivity',
    parent: 'integrations',
    label: 'Productivity',
    home: true,
    alt: 'Integrations - Productivity',
    icon: 'no_encryption',
    path: ['/home/integrations/productivity'],
    test: /ddw/ },
  { value: 'integrations.siem',
    parent: 'integrations',
    label: 'SIEM',
    home: true,
    alt: 'Integrations - SIEM',
    icon: 'no_encryption',
    path: ['/home/integrations/siem'],
    test: /ddw/ },
  { value: 'integrations.tip',
    parent: 'integrations',
    label: 'TIP',
    home: true,
    alt: 'Integrations - TIP',
    icon: 'no_encryption',
    path: ['/home/integrations/tip'],
    test: /ddw/ },
  { value: 'integrations.vulnerabilities',
    parent: 'integrations',
    label: 'Vulnerabilities',
    home: true,
    alt: 'Integrations - Vulnerabilities',
    icon: 'bug_report',
    path: ['/home/integrations/vulnerabilities'],
    test: /ddw/ },
  { value: 'integrations.ccmc',
    parent: 'integrations',
    label: 'CCM-C Demo',
    icon: 'network_check',
    path: ['/home/integrations/ccmc'],
    test: /demo.ccm.c/ },
  { value: 'integrations.ccm.webhook',
    parent: 'integrations',
    label: 'CCM-C Webhook',
    icon: 'network_check',
    path: ['/home/integrations/webhook/ccmc'],
    test: /ui.ccmc.web/ },
  // Profile
  { value: 'profile.searches',
    parent: 'profile',
    home: true,
    subnav: true,
    label: 'Saved Searches',
    alt: 'Profile - Saved Searches',
    icon: 'save',
    path: ['/home/profile/manage/searches'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'profile.bookmarks',
    parent: 'profile',
    subnav: true,
    home: true,
    label: 'Bookmarks',
    alt: 'Profile - Bookmarks',
    icon: 'bookmark_border',
    path: ['/home/profile/manage/bookmarks'],
    ui: true,
    test: /chat|crd|rep|for|tor|vis|mkt|ddw/ },
  { value: 'profile.settings',
    parent: 'profile',
    home: true,
    subnav: true,
    label: 'Settings',
    alt: 'Profile - Settings',
    icon: 'settings',
    path: ['/home/profile/settings', '/home/manage/user/settings', '/home/help/settings'],
    ui: true,
    test: /acc.ui.dat/ },
  { value: 'profile.tokens',
    parent: 'profile',
    subnav: true,
    label: 'API',
    alt: 'Profile - API Tokens',
    home: true,
    icon: 'network_check',
    path: ['/home/profile/tokens'],
    test: /api.prv/ },
  { value: 'profile.taxii',
    parent: 'profile',
    label: 'Manage TAXII Creds',
    alt: 'Profile - Manage TAXII Creds',
    home: true,
    subnav: true,
    icon: 'network_check',
    path: ['/home/profile/taxii'],
    test: /taxii-endpoints/ },
  { value: 'profile.admin',
    parent: 'profile',
    subnav: true,
    home: true,
    label: 'Admin',
    alt: 'Profile - Administration',
    icon: 'gavel',
    placeholder: 'e.g. search username',
    ui: true,
    text: 'Search across Flashpoint\'s users by user id and manage their user preferences',
    path: ['/home/profile/admin/users', '/home/profile/admin'],
    test: /prf.w/ },
  { value: 'profile.logout',
    parent: 'profile',
    label: 'Logout',
    alt: 'Profile - Logout',
    icon: 'exit_to_app',
    lower: true,
    test: /ui|api.prv/ },
  // Content Pages
  { value: 'collections.cards.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/shops/cards/items', '/home/ddw/cards/items'],
    label: 'Card',
    icon: 'credit_card',
    title: ['cards', 'title'],
    ui: true,
    test: /crd|csb/ },
  { value: 'collections.forums.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'Thread',
    icon: 'forums',
    path: ['/home/ddw/forums/threads', '/home/forums/threads'],
    aliases: ['collections.forums.view', 'collection.post.view'],
    ui: true,
    test: /for|ddw/ },
  { value: 'collections.chats.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'Channel',
    icon: 'chat',
    title: ['meta', 'channels', 'title'],
    path: ['/home/ddw/chats/channels'],
    aliases: ['collections.chats.view', 'collection.channels.view'],
    ui: true,
    exclude: /_no\.chat/,
    test: /chat|ddw/ },
  { value: 'collections.cves.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    label: 'Vulnerability (CVE)',
    icon: 'bug_report',
    title: ['cves', 'title'],
    path: ['/home/technical_data/cves/items', '/home/ddw/cves/items'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'collections.exploits.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    label: 'Exploits',
    icon: 'bug_report',
    title: ['cves', 'title'],
    path: ['/home/technical_data/exploits/items'],
    ui: true,
    test: /(vln|cve).r/ },
  { value: 'collections.iocs.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    label: '',
    icon: 'bug_report',
    title: ['iocs', 'Event', 'title'],
    path: ['/home/technical_data/iocs/items'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.marketplaces.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    path: ['/home/shops/marketplaces/items', '/home/ddw/marketplaces/items', '/home/ddw/markets/items/'],
    label: 'Marketplace',
    title: ['marketplaces', 'title'],
    icon: 'shopping_cart',
    ui: true,
    test: /mkt|csb/ },
  { value: 'collections.reports.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'Report',
    title: ['reports', 'title'],
    icon: 'lightbulb_outline',
    path: ['/home/intelligence/reports/report', '/home/intelligence/reports'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'collections.pastes.view',
    parent: 'collections',
    hidden: true,
    contextnav: true,
    path: ['/home/ddw/pastes/items'],
    label: 'Paste',
    title: ['pastes', 'title'],
    icon: 'assignment',
    ui: true,
    test: /ddw/ },
  { value: 'collections.blogs.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    path: ['/home/ddw/blogs/posts', '/home/ddw/blogs/items'],
    label: 'Post',
    title: ['meta', 'posts', 'container', 'title'],
    icon: 'web',
    ui: true,
    test: /ddw/ },
  { value: 'collections.ransomware.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    path: ['/home/ddw/ransomware/items', '/home/ddw/ransomwares/items'],
    label: 'Release',
    title: ['ransomware', 'title'],
    icon: 'lock',
    ui: true,
    test: /ddw/ },
  { value: 'collections.boards.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'Board',
    icon: 'view_agenda',
    title: ['meta', 'board', 'container', 'container', 'title'],
    path: ['/home/ddw/boards/board'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.social.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'News',
    icon: 'people',
    title: ['meta', 'news', 'title'],
    path: ['/home/ddw/social/news', '/home/ddw/gab/pages'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.twitter.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    sidebar: true,
    label: 'Twitter',
    icon: 'twitter',
    title: ['meta', 'twitter', 'title'],
    path: ['/home/ddw/twitter/tweet'],
    ui: true,
    test: /twtr/ },
  { value: 'collections.accounts.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/shops/accounts/items', '/home/ddw/accounts/items'],
    label: 'Account',
    icon: 'account_balance',
    title: ['accounts', 'account_organization'],
    ui: true,
    test: /ddw/ },

  { value: 'collections.assets.domain-monitoring.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/assets/domain-monitoring/items'],
    subHeader: 'Assets',
    label: 'Domain Monitoring',
    title: ['Domain Monitoring'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'collections.assets.media.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/assets/media/items'],
    icon: 'media',
    label: 'Images',
    title: ['images', 'filename'],
    ui: true,
    exclude: /_no\.chat/,
    test: /ddw/ },
  { value: 'collections.assets.images.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/assets/media/items'],
    icon: 'media',
    label: 'Images',
    title: ['images', 'filename'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.assets.videos.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    path: ['/home/assets/media/items'],
    icon: 'media',
    label: 'Videos',
    title: ['images', 'filename'],
    ui: true,
    test: /ddw/ },
  { value: 'collections.credentials.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    label: 'Enterprise Credentials',
    title: ['credentials', 'title'],
    icon: 'email',
    path: ['/home/compromised/credentials/items'],
    ui: true,
    test: /edm/ },
  { value: 'collections.customer-credentials.view',
    parent: 'collections',
    hidden: true,
    subnav: false,
    contextnav: true,
    label: 'Customer Credentials',
    title: ['credentials', 'title'],
    icon: 'email',
    path: ['/home/compromised/customer-credentials/items'],
    ui: true,
    test: /dat.ccm.cus.r/ },
  { value: 'collections.dmtm-incidents.view',
    parent: 'collections',
    hidden: true,
    contextnav: true,
    alt: 'Domain Monitoring - Incident',
    path: ['/home/search/dmtm/incidents/items'],
    subHeader: 'Domain Monitoring',
    label: 'Incidents',
    icon: 'bug_report',
    text: 'Domain Monitoring Incident',
    title: ['Incidents'],
    ui: true,
    test: /dat.dm.(adm.)?r/ },
  { value: 'intelligence.malware.view',
    label: 'Malware',
    hidden: true,
    subnav: false,
    contextnav: true,
    alt: 'Wiki - Malware',
    icon: 'bug_report',
    text: 'Malware Wiki Data',
    title: ['malware', 'malware_family_name'],
    path: ['/home/wiki/malware'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.apt.view',
    label: 'Advanced Persistent Threats',
    hidden: true,
    subnav: false,
    contextnav: true,
    alt: 'Wiki - Advanced Persistent Threats',
    icon: 'bug_report',
    text: 'Advanced Persistent Threats',
    title: ['malware', 'malware_family_name'],
    path: ['/home/wiki/apt'],
    ui: true,
    test: /rep|ddw/ },
  { value: 'intelligence.person-search.results',
    path: ['/home/intelligence/person-search/results'],
    label: 'Intelligence - Person Search',
    text: 'Intelligence - Person Search',
    title: ['Intelligence', 'Person Search'],
    subnav: true,
    test: /dat.poi.rep.r/ },



  { value: 'api.ccm.c',
    label: 'CCM-C',
    api: true,
    test: /ccm.cus/ },

  // Sanctions
  { value: 'sanctions',
    direct: true,
    path: ['/home/sanctions/new'],
    icon: 'vpn_lock',
    ui: true,
    test: /ddw/ },
  { value: 'sanction',
    nav: 'Sanctions (Internal)',
    path: ['/home/sanctions'],
    icon: 'search',
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.main',
    home: true,
    parent: 'sanction',
    label: 'Searches',
    icon: 'search',
    alt: 'Sanction',
    text: 'Searches',
    path: ['/home/sanctions/main'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.search',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Search Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Search Results',
    path: ['/home/sanctions/search'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.saved',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Search Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Saved Search Results',
    path: ['/home/sanctions/savedSearch'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.allreports',
    home: true,
    parent: 'sanction',
    label: 'Reports',
    icon: 'search',
    alt: 'Sanction',
    text: 'Reports',
    path: ['/home/sanctions/reports'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  { value: 'sanction.report',
    hidden: true,
    subnav: true,
    parent: 'sanction',
    label: 'Sanction Report Results',
    icon: 'search',
    alt: 'Sanction',
    text: 'Sanction Report Results',
    path: ['/home/sanctions/report'],
    ui: true,
    test: /dat\.payrisk\.r/ },
  // Firehose
  { value: 'firehose',
    hidden: true,
    label: 'Firehose',
    api: true,
    test: /FIREHOSE/ },
  // Tools
  { value: 'redirect',
    path: ['/home/redirect'],
    test: /acc.ui.dat/ },
];
