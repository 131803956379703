import PropTypes from 'prop-types';
import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useStyles from './style';

import RowText from './RowText';

const DetailsColumnCard = ({ title, incident }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" className={[classes.heading, classes.headingRow].join(' ')}>{title}</Typography>

      <RowText title="Name" data={incident.name} />
      <RowText title="Country" data={incident.country} />
      <RowText
        title="Email"
        data={/\S+@\S+\.\S+/.test(incident.email) ? // Check if it has standard email structure
          <Link href={`mailto:${incident.email}`} target="_blank" rel="noreferrer" className={classes.link}>{incident.email}</Link>
                        :
          <Typography>{incident.email}</Typography>
                    } />
    </>
  );
};

DetailsColumnCard.propTypes = {
  /** Name of the detail card */
  title: PropTypes.string.isRequired,
  /** Details of the incident to be displayed. */
  incident: PropTypes.object.isRequired,
};

export default DetailsColumnCard;
