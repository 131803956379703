/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo } from 'react';

import { LoginCallback, Security, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, AccessToken, OktaAuthOptions, Token, toRelativeUrl } from '@okta/okta-auth-js';
import { cloneDeep, union } from 'lodash';

import Cookies from '../../../utils/token';
import History from '../../../utils/history';
import { AuthLoading } from './AuthLoading/AuthLoading';
import { AuthError } from './AuthError/AuthError';

interface IAuth {
  children: React.ReactNode;
  config: OktaAuthOptions;
}

interface IRedirect {
  children: React.ReactNode;
}

export const UserStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  OFFSET: 1000,
};

export const Storage = {
  COOKIES_AUTHORIZATION: 'X-FP-Authorization',
  COOKIES_SEARCH_UUID: 'X-FP-Search-UUID',
};

// offline_access gets you a refresh token
const REFRESH_TOKEN_SCOPES = ['offline_access'];
// It seems 'openid' is the only necessary scope for log-in
const NECESSARY_SCOPES = ['openid'];
// If you don't specify a list of scopes, okta-auth-js will use these 3: 'openid', 'email', 'profile'
const DEFAULT_SCOPES = ['openid', 'email', 'profile'];

const handleRedirect = (url: string) => {
  const urlParams = new URLSearchParams(url?.slice(1));
  const redirect = urlParams.get('redirect');
  const exceptions = [
    /^localhost/ig,
    /^aboutface/ig,
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ig,
  ];

  if (exceptions.some(exception => exception.test(redirect))) {
    window.location.href = /^http/ig.test(redirect) ? redirect : `${window.location.protocol}//${redirect}`;
  }

  // fallback to originalUri if redirect not present
  return redirect || url;
};

const Redirect = ({ children }: IRedirect) => {
  const { location } = window;
  const { oktaAuth, authState } = useOktaAuth();
  const redirectUri = location.search;

  const logout = async () => {
    if (toRelativeUrl(location.href, location.origin) === '/login/callback') return;
    await oktaAuth.revokeAccessToken();
    // It's important that here we do not call oktaAuth.closeSession()
    // Often, especially when switching between applications, we rely on the Okta session remaining open
    // If there's no/expired okta tokens in localStorage? No problem, redirect to login.flashpoint.io and get fresh ones
    Cookies.del(Storage.COOKIES_AUTHORIZATION);
    oktaAuth.tokenManager.clear();
    const originalUri = toRelativeUrl(location.href, location.origin);
    oktaAuth.setOriginalUri(originalUri);

    // Passes the pathname+search string as a query param in the /authorize request
    // `refreshPath` will be used by the login page to return to the referring FP Application
    // so the Application can execute a fresh `signInWithRedirect` which will generate a fresh okta_key
    const { pathname, search } = window.location;
    oktaAuth.signInWithRedirect({
      extraParams: { refreshPath: `${pathname}${search}` },
    });
  };

  const add = (key: string, token: Token) => {
    if (!token || key !== 'accessToken') return;
    const now = Math.floor(Date.now() / UserStatuses.OFFSET);
    const { accessToken } = token as AccessToken;
    const { expiresAt: expires } = token;
    if (expires < now) logout();

    Cookies.set(Storage.COOKIES_AUTHORIZATION, String(accessToken), {
      expires: new Date(expires * UserStatuses.OFFSET),
      sameSite: 'None',
      secure: true,
    });
  };

  const renew = (key: string) => {
    oktaAuth.tokenManager.renew(key);
  };

  useEffect(() => {
    if (!authState) return () => null;

    oktaAuth.tokenManager.on('expired', renew);

    oktaAuth.tokenManager.on('added', add);

    oktaAuth.tokenManager.on('renewed', add);

    oktaAuth.isAuthenticated().then((isAuthenticated) => {
      const { accessToken } = authState;
      if (!isAuthenticated) logout();
      else {
        add('accessToken', accessToken as Token);
        handleRedirect(redirectUri);
      }
    });

    return () => {
      oktaAuth.tokenManager.off('expired');
      oktaAuth.tokenManager.off('added');
    };
  }, [oktaAuth, authState?.isAuthenticated]);

  if (!authState?.isAuthenticated) {
    return <LoginCallback errorComponent={AuthError} loadingElement={<AuthLoading />} />;
  }

  return children;
};

const configureForRefreshToken = (config: any) => {
  const newConfig = cloneDeep(config);
  const defaultScopes = newConfig.scopes === undefined ? DEFAULT_SCOPES : [];
  newConfig.scopes = union(newConfig.scopes, NECESSARY_SCOPES, REFRESH_TOKEN_SCOPES, defaultScopes);
  return newConfig;
};

const Provider = ({ children, config }: IAuth) => {
  const oktaAuth = useMemo(() => new OktaAuth(configureForRefreshToken(config)), [config]) as any;

  const restoreOriginalUri = useCallback((_oktaAuth: OktaAuth, originalUri: string) => {
    const redirect = handleRedirect(originalUri);
    History.replace(toRelativeUrl(redirect || '/', window.location.origin));
  }, []);

  useEffect(() => {
    oktaAuth.start();
    return () => {
      oktaAuth.stop();
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Redirect>
        {children}
      </Redirect>
    </Security>
  );
};

Provider.displayName = 'Auth.Provider';

export const Auth = {
  Provider,
};
