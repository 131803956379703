import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  green,
} from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import {
  Icon,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Popover,
} from '@mui/material';

import style from './sitesfilter.module.scss';
import History from '../../utils/history';
import SearchActions from '../../actions/searchActions';
import easyMemo from '../utils/Memoize';
import { Track } from '../../utils/track';

const useStyles = makeStyles({
  sitesfilter: {
    '& .MuiListItem-root': {
      '& .MuiListItemIcon-root': {
        '& .add': {
          color: green[500],
        },
        opacity: 0,
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          opacity: 1,
        },
      },
    },
  },
});

const SitesFilter = ({
  advanced,
  filtered,
  filters,
  option,
  onApply,
  onFilter,
  properties,
  sites,
  toggle,
  type,
}) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({});
  const [available, setAvailable] = useState(list());
  const [selected, setSelected] = useState(list());
  const [filter, setFilter] = useState('');
  const [topics, setTopics] = useState(map());

  const onAll = () => {
    const filteredValues = available
      .filter(v => (v.get('title') || '')
        .toLowerCase()
        .startsWith(filter.toLowerCase()));
    const availableValues = available
      .filterNot(v => (v.get('title') || '')
        .toLowerCase()
        .startsWith(filter.toLowerCase()));
    const selectedValues = selected.concat(filteredValues);
    setAvailable(availableValues);
    setSelected(selectedValues);
    setTopics(map());
    if (onFilter) {
      onFilter(fromJS({
        [option]: selectedValues.map(v => v.get('title')).join(),
        [`exclude_${option}`]: '',
      }));
    }
  };

  const onClear = () => {
    const availableValues = available.concat(selected);
    const selectedValues = list();
    setAvailable(availableValues);
    setSelected(selectedValues);
    setTopics(map());
    if (onFilter) {
      onFilter(fromJS({
        [option]: '',
        [`exclude_${option}`]: availableValues.map(v => v.get('title')).join(),
      }));
    }
  };

  const onTextFilter = (active) => {
    const filterValues = (myTopics, forum, index, negation = '') => {
      if (myTopics.hasIn([index])) {
        if (myTopics.hasIn([index, negation])) {
          return !forum.get('tags').includes(negation);
        }
        return !forum.get('tags').filter(v => myTopics.hasIn([index, v])).isEmpty();
      }
      return true;
    };

    const filterSitesByTopic = myTopics => (
      myTopics.isEmpty() ? list() : sites
        .filter(forum => filterValues(myTopics, forum, 'Cyber Threat'))
        .filter(forum => filterValues(myTopics, forum, 'Physical Threat'))
        .filter(forum => filterValues(myTopics, forum, 'Reputation'))
        .filter(forum => filterValues(myTopics, forum, 'Language'))
        .filter(forum => filterValues(myTopics, forum, 'Accessibility', 'Clearnet'))
        .toSet()
    );

    const sitesSelectedBecauseOfTopics = filterSitesByTopic(topics).toSet();
    const exemptSites = selected.toSet().subtract(sitesSelectedBecauseOfTopics);
    const activelySelectedSites = filterSitesByTopic(active).toSet();
    const selectedValues = activelySelectedSites.union(exemptSites).toList();

    const availableValues = sites.filterNot(v => selectedValues.includes(v));
    const sitesFilter = selectedValues.count() < availableValues.count()
      ? { sites: selectedValues.map(v => v.get('title')).join(), exclude_sites: '' }
      : { exclude_sites: availableValues.map(v => v.get('title')).join(), sites: '' };

    setTopics(active);
    setSelected(selectedValues);
    setAvailable(availableValues);
    if (onFilter) onFilter(sitesFilter);
  };

  const onReset = () => {
    setFilter('');
    setTopics(map());
    setAvailable(list());
    setSelected(available.concat(selected));
  };

  const onSearch = () => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const excludeValues = selected.size > Math.ceil((available.size + selected.size) / 2);
    const availableValues = available.map(v => v.get('title')).join(',');
    const selectedValues = selected.map(v => v.get('title')).join(',');
    setFilter('');
    toggle();

    const sitesValues = selected.toJS().map(site => site.title);

    if (onApply) {
      onApply(fromJS({
        [option]: excludeValues ? undefined : selectedValues,
        [`exclude_${option}`]: excludeValues ? availableValues : undefined,
        sites_exact: true,
      }));
      return;
    }

    const event = {
      type,
      filters: { ...query, sites: sitesValues, sites_exact: true },
      origin: '.form .selected .list',
    };

    Track(event, 'Search');

    History.push({
      pathname,
      query: {
        ...query,
        [option]: excludeValues ? undefined : selectedValues,
        [`exclude_${option}`]: excludeValues ? availableValues : undefined,
        sites_exact: true,
        skip: undefined,
      },
      hash,
    });
  };

  const setSelectedAndAvailableValues = ({ selectedValues, availableValues }) => {
    const excludeValues = selectedValues.size > (availableValues.size + selectedValues.size) / 2;
    setAvailable(availableValues);
    setSelected(selectedValues);
    if (onFilter) {
      onFilter(fromJS({
        [option]: excludeValues
          ? ''
          : selectedValues.map(v => v.get('title')).join(','),
        [`exclude_${option}`]: excludeValues
          ? availableValues.map(v => v.get('title')).join(',')
          : '',
      }));
    }
  };

  const onRemove = (opt) => {
    const availableValues = available.push(opt);
    const selectedValues = selected.filterNot(v => opt.get('fpid') === v.get('fpid'));
    setSelectedAndAvailableValues({ selectedValues, availableValues });
  };

  const onSelect = (opt) => {
    const availableValues = available.filterNot(v => opt.get('title') === v.get('title'));
    const selectedValues = selected.push(opt);
    setSelectedAndAvailableValues({ selectedValues, availableValues });
  };

  const title = (cat) => {
    const count = topics.has(cat.get('name'))
      ? topics
        .get(cat.get('name'))
        .keySeq()
        .filterNot(v => v === 'All')
        .count()
      : 0;
    return `${cat.get('name')} ${count ? `(${count})` : ''}`;
  };

  const all = (cat) => {
    const topic = cat.get('name');
    const didDeselect = topics.hasIn([topic, 'all']);
    const active = didDeselect
      ? topics.delete(topic)
      : topics.setIn([topic, 'all']);
    cat.get('children').forEach(v =>
      Object.assign(active, didDeselect ? active : active.setIn([topic, v.get('name')])));
    onTextFilter(active);
  };

  const reduce = (cat, topic) => {
    const allValues = topics.hasIn([cat, 'all']);
    const deselect = topics.get(cat) && topics.get(cat).size === 1
      ? topics.delete(cat)
      : topics.deleteIn([cat, topic]);
    const clear = allValues
      ? topics.delete(cat).setIn([cat, topic])
      : deselect;
    const active = topics.hasIn([cat, topic])
      ? clear
      : topics.setIn([cat, topic]);
    onTextFilter(active);
  };

  useEffect(() => {
    const availableValues = !filters.get(option) ?
      sites.filter(v => (filters
        .get(`exclude_${option}`) || '')
        .toLowerCase()
        .split(',')
        .includes((v.get('title') || '').toLowerCase())) :
      sites.filterNot(v => (filters
        .get(option) || '')
        .toLowerCase()
        .split(',')
        .includes((v.get('title') || '').toLowerCase()));
    const selectedValues = !filters.get(option) ?
      sites.filterNot(v => (filters
        .get(`exclude_${option}`) || '')
        .toLowerCase()
        .split(',')
        .includes((v.get('title') || '').toLowerCase())) :
      sites.filter(v => (filters
        .get(option) || '')
        .toLowerCase()
        .split(',')
        .includes((v.get('title') || '').toLowerCase()));
    setAvailable(availableValues);
    setSelected(selectedValues);
  }, [filters, sites]);

  useEffect(() => {
    SearchActions.loadSites(type);
  }, []);


  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.sitesfilter,
        classes.sitesfilter,
        advanced && style.advanced])}
      name="component.sitesfilter">
      {sites.isEmpty() &&
      <div>
        <CircularProgress />
      </div>}
      {!sites.isEmpty() && filtered &&
      <React.Fragment>
        <Row>
          <Col xs={12} className={style.filters}>
            {properties
              .filter(v => ['Cyber Threat', 'Physical Threat', 'Reputation', 'Accessibility', 'Language'].includes(v.get('name')))
              .sortBy(v => ['Cyber Threat', 'Physical Threat', 'Reputation', 'Accessibility', 'Language'].indexOf(v.get('name')))
              .toArray()
              .map(prop => (
                <span key={prop.get('fpid')}>
                  <Button
                    variant="contained"
                    className={cx([style.dropdown, style.h4, 'h4', style.mont, 'mont'])}
                    endIcon={<Icon className={style.icon}>keyboard_arrow_down</Icon>}
                    onClick={e => setDialog({ target: e.currentTarget, key: prop.get('fpid') })}>
                    {title(prop)}
                  </Button>
                  <Popover
                    className="dropdown"
                    open={Boolean(dialog && dialog.key === prop.get('fpid'))}
                    anchorEl={dialog && dialog.target}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    onClose={() => setDialog()}>
                    <FormControl>
                      <ListItem>
                        <FormControlLabel
                          label="All"
                          control={<Checkbox
                            checked={topics.hasIn([prop.get('name'), 'all'])}
                            onChange={() => all(prop)} />} />
                      </ListItem>
                    </FormControl>
                    {prop
                      .get('children')
                      .sortBy(v => v.get('name'))
                      .toArray()
                      .map(v => (
                        <FormControl key={v.get('fpid')}>
                          <ListItem>
                            <FormControlLabel
                              label={v.get('name')}
                              control={<Checkbox
                                checked={!topics.hasIn([prop.get('name'), 'all']) &&
                                  topics.hasIn([prop.get('name'), v.get('name')])}
                                onChange={() => reduce(prop.get('name'), v.get('name'))} />} />
                          </ListItem>
                        </FormControl>))}
                  </Popover>
                </span>))}
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={style.unselected}>
            <div className={cx([style.h2, 'h2', style.mont, 'mont', style.primary])}>
              {'Unselected: '}
              <Button
                color="secondary"
                variant="contained"
                name="sitesfilter.selectall"
                onClick={() => onAll()}>
                Select All
              </Button>
            </div>
            <form
              name="sitesfilter.search"
              className={style.form}>
              <FormControl variant="outlined">
                <InputLabel>Filter Available</InputLabel>
                <OutlinedInput
                  data-lpignore="true"
                  placeholder="Filter Available..."
                  value={filter || ''}
                  onChange={event => setFilter(event.target.value)}
                  startAdornment={(
                    <InputAdornment position="start">
                      <Icon
                        onClick={() => setFilter(filter)}>
                        search
                      </Icon>
                    </InputAdornment>)}
                  endAdornment={filter && (
                    <InputAdornment position="end">
                      <Icon
                        color="primary"
                        onClick={() => setFilter('')}>
                        close
                      </Icon>
                    </InputAdornment>
                  )} />
              </FormControl>
            </form>
            <div
              name="sitesfilter.list.available"
              className={style.list}>
              {available
                .filter(v => (v.get('title') || '').toLowerCase().indexOf(filter?.toLowerCase()) !== -1)
                .sortBy(v => (v.get('title') || '').toLowerCase().indexOf(filter?.toLowerCase()))
                .sortBy(v => v.get('title'))
                .sort((a, b) => a.get('title').toLowerCase().localeCompare(b.get('title').toLowerCase()))
                .map(v => (
                  <ListItem
                    key={v.get('fpid')}
                    name={`sitesfilter.list.available.${v.get('title')}`}
                    onClick={() => onSelect(v)}>
                    <ListItemText>
                      {v.get('title')}
                    </ListItemText>
                    <ListItemIcon>
                      <Icon className="add">add_circle</Icon>
                    </ListItemIcon>
                  </ListItem>))}
            </div>
          </Col>
          <Col xs={6} className={style.selected}>
            <div className={cx([style.h2, 'h2', style.mont, 'mont', style.primary])}>
              {'Selected: '}
              <Button
                color="secondary"
                variant="contained"
                name="sitesfilter.removeall"
                onClick={() => onClear()}>
                Unselect All
              </Button>
            </div>
            <div className={style.list} name="sitesfilter.list.selected">
              {selected
                .sortBy(v => v.get('title'))
                .sort((a, b) => a.get('title').toLowerCase().localeCompare(b.get('title').toLowerCase()))
                .map(v => (
                  <ListItem
                    key={v.get('fpid')}
                    name={`sitesfilter.list.selected.${v.get('title')}`}
                    onClick={() => onRemove(v)}>
                    <ListItemText>
                      {v.get('title')}
                    </ListItemText>
                    <ListItemIcon>
                      <Icon color="error">remove_circle</Icon>
                    </ListItemIcon>
                  </ListItem>))}
            </div>
          </Col>
        </Row>
        {toggle &&
        <Row>
          <Col xs={12} className={cx([style.footer, advanced && style.advanced])}>
            <div className={style.error} />
            <div className={style.actions}>
              <Button
                name="sitesfilter.clearall"
                className={style.left}
                onClick={() => onReset()}>
                Clear All
              </Button>
              <Button
                name="sitesfilter.cancel"
                onClick={() => toggle()}>
                Cancel
              </Button>
              <Button
                className={style.active}
                name="sitesfilter.apply"
                onClick={() => onSearch()}>
                Apply
              </Button>
            </div>
          </Col>
        </Row>}
      </React.Fragment>}
    </Grid>
  );
};

SitesFilter.propTypes = {
  advanced: PropTypes.bool,
  filtered: PropTypes.bool,
  filters: PropTypes.object,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  option: PropTypes.string,
  properties: PropTypes.object,
  sites: PropTypes.object,
  toggle: PropTypes.func,
  type: PropTypes.string,
};

SitesFilter.defaultProps = {
  advanced: false,
  filtered: false,
  filters: map(),
  onApply: null,
  onFilter: null,
  option: '',
  properties: map(),
  sites: list(),
  toggle: null,
  type: '',
};

export default easyMemo(SitesFilter);
