import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import Papa from 'papaparse';
import ReactTooltip from 'react-tooltip';
import { fromJS, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  CircularProgress,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  Icon,
  Paper,
  OutlinedInput,
  Radio,
  RadioGroup,
  Tab,
} from '@mui/material';

import style from './ccmc.module.scss';
import CredentialsActions from '../../actions/credentialsActions';
import SearchActions from '../../actions/searchActions';
import ProgressBar from '../search/ProgressBar';
import Messages from '../../constants/Messages';

const useStyles = makeStyles(theme => ({
  ccmc: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
      '& .MuiFormGroup-row': {
        alignItems: 'center',
        '& [role="radiogroup"]': {
          margin: `0 0 0 ${theme.spacing(2)}`,
        },
      },
    },
    '& .MuiTabPanel-root': {
      padding: 0,
    },
  },
}));

const CCMc = ({
  activePromises,
  importStatus,
  settings,
  stats,
  user,
}) => {
  const classes = useStyles();
  const [customerAction, setCustomerAction] = useState('replace');
  const [customerFile, setCustomerFile] = useState(null);
  const [customerFileValidating, setCustomerFileValidating] = useState(false);
  const [customerKeys, setCustomerKeys] = useState([]);
  const [errorText, setErrorText] = useState(map());
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedTab, setSelectedTab] = useState('configuration');
  const [testEvent, setTestEvent] = useState('compromised_credential');
  const [typingTimer, setTypingTimer] = useState(null);
  const [values, setValues] = useState(map());
  const blankSubscription = fromJS({
    auth_data: '',
    auth_type: 'basic',
    batch_size: 1,
    events: ['compromised_credential'],
    hash_response: [],
    url: '',
  });
  const isFPUser = user.get('prm').some(p => /org.fp/.test(p));
  const tenMB = 10000000;
  const fiftyMB = 50000000;
  // eslint-disable-next-line security/detect-unsafe-regex
  const validUrl = /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((https?:\/\/)?(www\.)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:\d{1,4})?[^ "\n]+$/;
  // eslint-disable-next-line security/detect-unsafe-regex
  const validHttpsUrl = /^(https:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((https?:\/\/)?(www\.)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:\d{1,4})?[^ "\n]+$/;

  const onAddSubscription = () => {
    setHasChanged(true);
    const updatedSubscriptions = values.get('subscriptions').push(blankSubscription);
    setValues(values.set('subscriptions', updatedSubscriptions));
    setErrorText(errorText
      .set(`url.${updatedSubscriptions.size - 1}`, 'URL is required')
      .set(`auth_data.${updatedSubscriptions.size - 1}`, 'Authentication Token is required'));
  };

  const onChange = (k, v, subscriptionIndex) => {
    setHasChanged(true);
    const updatedValues = values.setIn(['subscriptions', subscriptionIndex, k], v);
    setValues(updatedValues);
    // handle checking if v is invalid
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      let error = '';
      let updatedErrorText = errorText.set(`${k}.${subscriptionIndex}`, error);
      if (k === 'url') {
        if (!v) error = 'URL is required';
        else if (isFPUser && !validUrl.test(v)) error = 'Invalid URL';
        else if (!isFPUser && !validUrl.test(v)) error = 'Invalid URL';
        else if (!isFPUser && !validHttpsUrl.test(v)) error = 'URL must be https';
        updatedErrorText = errorText.set(`${k}.${subscriptionIndex}`, error);
        const currentUrl = values.getIn(['subscriptions', subscriptionIndex, 'url']);
        const sameUrls = values.get('subscriptions').map((s, i) => {
          if (s.get('url') === v || s.get('url') === currentUrl) return i;
          return null;
        }).filter(s => s != null);
        if (sameUrls.size > 0) {
          let hasSame = false;
          error = 'Cannot have the same url and events for multiple subscriptions';
          const currentEvents = values.getIn(['subscriptions', subscriptionIndex, 'events']);
          sameUrls.forEach((s) => {
            const isSame = values.getIn(['subscriptions', s, 'events']).every(e => currentEvents.includes(e))
              && values.getIn(['subscriptions', s, 'url']) === v;
            const urlError = (isSame) ? error : '';
            updatedErrorText = updatedErrorText.set(`url.${s}`, urlError);
            hasSame = hasSame || isSame;
          });
          if (hasSame) updatedErrorText = updatedErrorText.set(`url.${subscriptionIndex}`, error);
        }
      } else if (k === 'batch_size') {
        if (!v || v < 1 || v > 1000) {
          error = 'Invalid. Must be between 1 and 1000';
          updatedErrorText = errorText.set(`${k}.${subscriptionIndex}`, error);
        }
      } else if (k === 'auth_data' && values.getIn(['subscriptions', subscriptionIndex, 'auth_type']) === 'basic') {
        const usnRegex = '([a-zA-Z0-9_-]+)';
        const pwdRegex = '.+';
        // eslint-disable-next-line security/detect-non-literal-regexp
        const usnPwdRegex = new RegExp(`^(${usnRegex}:${pwdRegex})$`, 'ig');
        const valid = usnPwdRegex.test(v);
        if (!valid) {
          error = v ? 'Invalid format: username:password' : 'Authentication Token is required';
          updatedErrorText = errorText.set(`${k}.${subscriptionIndex}`, error);
        }
      }
      setErrorText(updatedErrorText);
    }, 500);
    setTypingTimer(timer);
  };

  const onCheck = (type, key, value, subscriptionIndex) => {
    setHasChanged(true);
    const toGet = values.getIn(['subscriptions', subscriptionIndex]);
    const current = [...(toGet.get(type).toJS() || [])];
    if (value) current.push(key);
    else current.splice(current.indexOf(key), 1);
    const updatedValues = values.setIn(['subscriptions', subscriptionIndex, type], fromJS(current));
    setValues(updatedValues);
    if (type === 'events') {
      let error = (current.length === 0) ? 'Must have one event per subscription' : '';
      let updatedErrorText = errorText.set(`${type}.${subscriptionIndex}`, error);
      if (!error) {
        let hasSame = false;
        error = 'Cannot have the same url and events for multiple subscriptions';
        const url = values.getIn(['subscriptions', subscriptionIndex, 'url']);
        const sameUrls = values.get('subscriptions').map((s, i) => {
          if (s.get('url') === url) return i;
          return null;
        }).filter(s => s != null);
        if (sameUrls.size > 1) {
          sameUrls.forEach((v) => {
            const events = values.getIn(['subscriptions', v, 'events']);
            const isSame = events.size === current.length && values.getIn(['subscriptions', v, 'events']).every(e => current.includes(e));
            const urlError = (isSame) ? 'Cannot have the same url and events for multiple subscriptions' : '';
            updatedErrorText = updatedErrorText.set(`url.${v}`, urlError);
            hasSame = hasSame || isSame;
          });
          if (hasSame) updatedErrorText = updatedErrorText.set(`url.${subscriptionIndex}`, error);
        }
      }
      setErrorText(updatedErrorText);
    }
  };

  const onDeleteSubscription = (subscriptionIndex) => {
    setHasChanged(true);
    const updatedSubscriptions = values.get('subscriptions').delete(subscriptionIndex);
    setValues(values.set('subscriptions', updatedSubscriptions));
    setErrorText(errorText
      .delete(`url.${subscriptionIndex}`)
      .delete(`auth_data.${subscriptionIndex}`));
  };

  const onFileAdd = (file) => {
    setErrorText(errorText.set('customer-list', ''));
    setCustomerKeys([]);
    setCustomerFile(null);
    if (!file) return;
    // Only validate filesif they'll be used by the POST/DELETE
    // methods. Large files will have more than 50000 rows and wont be allowed
    // other than replace, so ignore gzipped or large files
    if (file.type.includes('gzip') || file.size > tenMB) {
      setCustomerFile(file);
      return;
    }
    setCustomerFileValidating(true);
    let keys = [];
    let hasError = false;
    Papa.parse(file, {
      fastMode: true,
      skipEmptyLines: true,
      worker: true,
      chunk: (results, parser) => {
        if (results.errors.length > 1
          || (results.errors.length === 1 && results.errors[0].code !== 'UndetectableDelimiter')) {
          hasError = true;
          parser.abort();
        }
        for (let i = 0; i < results.data.length; i += 1) {
          if (results.data[Number(i)].length !== 1) {
            hasError = true;
            parser.abort();
            break;
          }
        }
        keys = keys.concat(results.data);
      },
      complete: () => {
        setCustomerFileValidating(false);
        if (hasError) {
          setErrorText(errorText.set('customer-list', 'Malformed customer list. Please upload a .csv file with only one column of hashes'));
          setCustomerKeys([]);
          setCustomerFile(null);
        } else {
          setErrorText(errorText.set('customer-list', ''));
          setCustomerKeys(keys.flat());
          setCustomerFile(file);
        }
      },
    });
  };

  const onUpdateCustomers = () => {
    switch (customerAction) {
      case 'replace': {
        if (customerFile.size > fiftyMB) {
          SearchActions.set(['search', 'info', 'message'], Messages.UploadSizeWarning);
        }
        CredentialsActions.uploadCustomerFile(customerFile);
        break;
      }
      case 'add':
        CredentialsActions.addCustomers(customerKeys);
        break;
      case 'delete':
        CredentialsActions.deleteCustomers(customerKeys);
        break;
      default:
        break;
    }
  };

  const generateFields = (subscriptionIndex) => {
    const toUse = values.getIn(['subscriptions', subscriptionIndex]);
    return (
      <React.Fragment>
        <Row middle="xs" className={style.row}>
          <Col xs={12} className={style.text}>
            <FormControl variant="outlined">
              <InputLabel>URL</InputLabel>
              <OutlinedInput
                name="base_url"
                data-lpignore="true"
                error={Boolean(errorText.get(`url.${subscriptionIndex}`))}
                value={toUse.get('url', '')}
                placeholder="webhook url"
                onChange={event => onChange('url', event.target.value, subscriptionIndex)}
                endAdornment={toUse.get('url') && (
                  <InputAdornment position="end">
                    <Icon
                      onClick={() => onChange('url', '', subscriptionIndex)}>
                      close
                    </Icon>
                  </InputAdornment>
                )} />
              <FormHelperText color="error">
                {errorText.get(`url.${subscriptionIndex}`)}
              </FormHelperText>
            </FormControl>
          </Col>
        </Row>
        <Row middle="xs" className={style.row}>
          <Col xs={12}>
            <FormControl>
              <FormGroup row>
                <FormLabel>Authentication Type</FormLabel>
                <RadioGroup
                  name="auth_type"
                  className={cx([style.horizontal])}
                  value={toUse.get('auth_type') || 'basic'}
                  onChange={event => onChange('auth_type', event.target.value, subscriptionIndex)}>
                  <FormControlLabel
                    label="Basic"
                    value="basic"
                    control={<Radio color="secondary" />} />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Col>
        </Row>
        <Row middle="xs" className={style.row}>
          <Col xs={12} className={style.text}>
            <FormControl variant="outlined">
              <InputLabel>Authentication Token</InputLabel>
              <OutlinedInput
                name="auth_data"
                data-lpignore="true"
                error={Boolean(errorText.get(`auth_data.${subscriptionIndex}`))}
                value={toUse.get('auth_data', '')}
                placeholder={!toUse.get('auth_type') || toUse.get('auth_type') === 'basic'
                  ? 'username:password'
                  : `${toUse.get('auth_type') === 'HMAC' ? '<HMAC>' : '<JWT>'}`}
                onChange={event => onChange('auth_data', event.target.value, subscriptionIndex)}
                endAdornment={toUse.get('auth_data') && (
                  <InputAdornment position="end">
                    <Icon
                      onClick={() => onChange('url', '', subscriptionIndex)}>
                      close
                    </Icon>
                  </InputAdornment>
                )} />
              <FormHelperText color="error">
                {errorText.get(`auth_data.${subscriptionIndex}`)}
              </FormHelperText>
            </FormControl>
          </Col>
        </Row>
        <Row middle="xs" className={style.row}>
          <Col xs={12} className={style.text}>
            <FormControl variant="outlined">
              <InputLabel>Batch Size</InputLabel>
              <OutlinedInput
                name="batch_size"
                data-lpignore="true"
                type="number"
                min="1"
                max="1000"
                error={Boolean(errorText.get(`batch_size.${subscriptionIndex}`))}
                value={toUse.get('batch_size', '')}
                onChange={event => onChange('batch_size', parseInt(event.target.value, 10), subscriptionIndex)} />
              <FormHelperText color="error">
                {errorText.get(`batch_size.${subscriptionIndex}`)}
              </FormHelperText>
            </FormControl>
          </Col>
        </Row>
        <Row middle="xs" className={cx([style.row, style.horizontal])}>
          <Col xs={3}>
            <span className={cx([style.label])}>Events:</span>
          </Col>
          <Col xs={9} className={style.horizontal}>
            <FormControlLabel
              label="Credential Alert"
              control={<Checkbox
                checked={(toUse.get('events') || []).includes('compromised_credential')}
                onChange={event => onCheck('events', 'compromised_credential', event.target.checked, subscriptionIndex)}
              />} />
            <FormControlLabel
              label="Customer Replacement"
              control={<Checkbox
                checked={(toUse.get('events') || []).includes('load_status')}
                onChange={event => onCheck('events', 'load_status', event.target.checked, subscriptionIndex)}
              />} />
          </Col>
          {errorText.get(`events.${subscriptionIndex}`) &&
          <Col xs={12} className={style.horizontal}>
            <Col xs={3} />
            <Col xs={9}>
              <div className={style.error}>
                {errorText.get(`events.${subscriptionIndex}`)}
              </div>
            </Col>
          </Col>}
        </Row>
        <Row middle="xs" className={cx([style.row, style.horizontal])}>
          <Col xs={3}>
            <span className={cx([style.label])}>Hashed Response:</span>
          </Col>
          <Col xs={9} className={style.horizontal}>
            <FormControlLabel
              label="Username"
              control={<Checkbox
                checked={(toUse.get('hash_response') || []).includes('username')}
                onChange={event => onCheck('hash_response', 'username', event.target.checked, subscriptionIndex)}
              />} />
            <FormControlLabel
              label="Password"
              control={<Checkbox
                checked={(toUse.get('hash_response') || []).includes('password')}
                onChange={event => onCheck('hash_response', 'password', event.target.checked, subscriptionIndex)}
              />} />
          </Col>
        </Row>
        {toUse.get('hash_response') && toUse.get('hash_response').size > 0 &&
        <Row middle="xs" className={cx([style.row])}>
          <Col xs={12}>
            <FormControl>
              <FormLabel>Hashed Response Algorithm</FormLabel>
              <RadioGroup
                name="hash-algorithm"
                className={cx([style.horizontal])}
                value={toUse.get('hash_algorithm') || 'sha256'}
                onChange={event => onChange('hash_algorithm', event.target.value, subscriptionIndex)}>
                <FormControlLabel
                  label="SHA-1"
                  value="sha1"
                  control={<Radio color="secondary" />} />
                <FormControlLabel
                  label="SHA-256"
                  value="sha256"
                  control={<Radio color="secondary" />} />
                <FormControlLabel
                  label="MD-5"
                  value="md5"
                  control={<Radio color="secondary" />} />
              </RadioGroup>
            </FormControl>
          </Col>
        </Row>}
      </React.Fragment>
    );
  };

  const onSave = () => {
    setHasChanged(false);
    CredentialsActions.saveSettings(values.toJS());
  };

  useEffect(() => {
    CredentialsActions.getCustomerImportStatus();
    CredentialsActions.getCustomerStats();
  }, []);

  useEffect(() => {
    if (settings.isEmpty()) return;
    let updatedValues = settings;
    if (updatedValues.get('subscriptions').size === 0) updatedValues = updatedValues.set('subscriptions', fromJS([blankSubscription]));
    setValues(updatedValues);
  }, [settings]);

  return (
    <Grid
      fluid
      name="webhook.ccmc"
      className={cx([
        style.base,
        style.ccmc,
        classes.ccmc,
      ])}>
      <Row>
        <Col xs={12}>
          {!importStatus.isEmpty() && !stats.isEmpty() &&
          <div className={style.monitoring}>
            <div>
              {importStatus.get('status') === 'SUCCESS' &&
              <div />}
              {importStatus.get('status') === 'FAILED' &&
              <div>
                <Icon className="material-icons">warning</Icon>                Customer list import from {moment.utc(importStatus.getIn(['updated_at', 'date-time'])).format('MMM D, YYYY')} failed. Please try again.
              </div>}
              {importStatus.get('status') === 'PROCESSING' &&
              <div>
                <Icon className="material-icons">schedule</Icon>                Customer list import is processing.
              </div>}
              <div>
                Monitoring {stats.get('customers_monitored').toLocaleString()} Customers
              </div>
            </div>
          </div>}
          <Paper className={style.card}>
            <TabContext value={selectedTab}>
              <TabList
                variant="fullWidth"
                indicatorColor="secondary"
                onChange={(event, val) => setSelectedTab(val)}
                className={style.tabs}>
                <Tab label="Configuration" value="configuration" />
                <Tab label="Customer Monitoring" value="customer" />
              </TabList>
              <TabPanel value="configuration" className={style.body}>
                {settings.isEmpty() &&
                <Row>
                  <Col xs={12}>
                    <CircularProgress />
                  </Col>
                </Row>}
                {values.get('subscriptions') && values.get('subscriptions').size > 0 &&
                <Row middle="xs">
                  <Col xs={12}>
                    <h2>Subscription URLs</h2>
                    {values.get('subscriptions').map((sub, k) => (
                      <Row
                        middle="xs"
                        between="xs"
                        className={cx([style.row, k !== values.get('subscriptions').size - 1 && style.line])}
                        key={`sub.${k.toString()}`}>
                        <Col xs={11}>
                          {generateFields(k)}
                        </Col>
                        <Col>
                          <Fab
                            size="small"
                            color="secondary"
                            className={cx([style.delete])}
                            data-for="global.tooltip"
                            data-tip="Delete"
                            onMouseEnter={() => ReactTooltip.rebuild()}
                            onClick={() => onDeleteSubscription(k)}>
                            <Icon>close</Icon>
                          </Fab>
                        </Col>
                      </Row>
                      ))}
                  </Col>
                </Row>}
                {!settings.isEmpty() &&
                <div className={cx([style['button-container'], style.center])}>
                  <Button
                    color="secondary"
                    variant="contained"
                    name="override"
                    className={cx([style.mont, 'mont', style.button])}
                    onClick={() => onAddSubscription()}>
                    Add Subscription URL
                  </Button>
                </div>}
                <div className={style.actions}>
                  {!settings.isEmpty() && !settings.get('subscriptions').isEmpty() && !hasChanged &&
                  <div className={style.test}>
                    <FormControl>
                      <FormLabel>Events</FormLabel>
                      <RadioGroup
                        name="auth_type"
                        className={style.horizontal}
                        value={testEvent || 'compromised_credential'}
                        onChange={event => setTestEvent(event.target.value)}>
                        <FormControlLabel
                          label="Credential Alert"
                          value="compromised_credential"
                          control={<Radio color="secondary" />} />
                        <FormControlLabel
                          label="Customer Replacement"
                          value="load_status"
                          control={<Radio color="secondary" />} />
                      </RadioGroup>
                    </FormControl>
                    <Button
                      name="test"
                      variant="contained"
                      color="secondary"
                      data-for="global.tooltip"
                      data-tip="Send a test event to the URLs of the saved configuration settings"
                      onMouseEnter={() => ReactTooltip.rebuild()}
                      className={cx([style.mont, 'mont', style.button])}
                      onClick={() => CredentialsActions.testSettings(testEvent)}>
                      Send Test Event
                    </Button>
                  </div>}
                  {hasChanged &&
                  <div className={cx([style['button-container']])}>
                    <Button
                      variant="contained"
                      name="save"
                      className={cx([style.mont, 'mont', style.button])}
                      disabled={errorText.some(v => v)}
                      onClick={() => onSave()}>
                      Save
                    </Button>
                  </div>}
                </div>
              </TabPanel>
              <TabPanel value="customer" className={style.body}>
                <Row middle="xs" className={style.row}>
                  <Col xs={12}>
                    <FormControl>
                      <FormLabel>I Want To</FormLabel>
                      <RadioGroup
                        name="customer_action"
                        value={customerAction}
                        onChange={event => setCustomerAction(event.target.value)}>
                        <FormControlLabel
                          label="Replace the existing customer list"
                          value="replace"
                          control={<Radio color="secondary" />} />
                        <FormControlLabel
                          label="Add to the customer list (max 50000 hashes)"
                          value="add"
                          control={<Radio color="secondary" />} />
                        <FormControlLabel
                          label="Remove from the customer list (max 50000 hashes)"
                          value="delete"
                          control={<Radio color="secondary" />} />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                </Row>
                <Row middle="xs" className={style.row}>
                  <Col xs={3}>
                    <span className={cx([style.label])}>the File:</span>
                    <div className={style.help}>
                      A single column of SHA256 hashes, no header needed
                    </div>
                  </Col>
                  <Col xs={9}>
                    <input type="file" accept=".csv, .gz" name="customer-list" onChange={e => onFileAdd(e.target.files[0])} />
                    {customerFileValidating &&
                    <div>
                      <span>Validating csv...</span>
                      <CircularProgress />
                    </div>}
                    {errorText.get('customer-list') &&
                    <div className={style.error}>
                      {errorText.get('customer-list')}
                    </div>}
                    {customerKeys.length > 0 &&
                    <div className={style.help}>
                      File contains {customerKeys.length.toLocaleString()} customer hashes
                    </div>}
                  </Col>
                </Row>
                {!customerFileValidating && (customerKeys.length > 0 || (customerFile && (customerFile.type.includes('gzip') || customerFile.size > tenMB))) &&
                <div className={cx([style['button-container'], style.center])}>
                  <Button
                    variant="contained"
                    name="update"
                    disabled={activePromises > 0 || ((customerKeys.length > 50000
                      || (customerFile
                        && (customerFile.type.includes('gzip') || customerFile.size > tenMB)))
                      && customerAction !== 'replace')}
                    className={cx([style.mont, 'mont', style.button])}
                    onClick={() => onUpdateCustomers()}>
                    Update
                  </Button>
                </div>}
                <div className={style.progress}>
                  <ProgressBar initialTimeout={100} remaining={activePromises} />
                </div>
                {customerKeys.length > 50000 && customerAction !== 'replace' &&
                <div className={cx([style.error, style.center])}>
                  Unless replacing the full customer list, the list of
                  hashes cannot be more than {(50000).toLocaleString()} rows.
                </div>}
                {!customerFileValidating && customerFile && (customerFile.type.includes('gzip') || customerFile.size > tenMB) && customerAction !== 'replace' &&
                <div className={cx([style.error, style.center])}>
                  Unless replacing the full customer list, a .csv file must be
                  used with less than {(50000).toLocaleString()} rows.
                </div>}
              </TabPanel>
            </TabContext>
          </Paper>
        </Col>
      </Row>
    </Grid>
  );
};

CCMc.propTypes = {
  activePromises: PropTypes.number,
  importStatus: PropTypes.object,
  settings: PropTypes.object,
  stats: PropTypes.object,
  user: PropTypes.object,
};

CCMc.defaultProps = {
  activePromises: 0,
  importStatus: map(),
  settings: map(),
  stats: map(),
  user: map(),
};

export default CCMc;
