import { gql } from '@apollo/client';

const Exploits = gql`
  query cveDashboardCard3(
    $start: Date!
    $end: Date!
    $v3severity: [String]
    $cves: [String]
    $products: [String]
    $vendors: [String]
  ) {
    cveDashboard(
      filters: {
        dateRange: { start: $start, end: $end }
        v3Severity: $v3severity
        cves: $cves
        productName: $products
        vendorName: $vendors
      }
    ) {
      exploits {
        totalExploits
        totalCriticalExploits
        severity {
          key
          totalExploitedCVEs
        }
        vulnerability {
          key
          totalExploitedCVEs
        }
        products {
          key
          totalExploitedCVEs
        }
        cves {
          fpid
          key
          totalExploitedCVEs
        }
      }
    }
  }
`;

const Mentions = gql`
  query cveDashboardCard2(
  $start: Date!
  $end: Date!
  $v3severity: [String]
  $cves: [String]
  $products: [String]
  $vendors: [String]
  ) {
  cveDashboard(
    filters: {
      dateRange: { start: $start, end: $end }
      v3Severity: $v3severity
      cves: $cves
      productName: $products
      vendorName: $vendors
    }
  ) {
    tad: mentions {
      totalUnique
      totalCriticalUnique
      totalMentions
      totalCriticalMentions
      topMentions {
        products {
          key
          cves
          totalMentionsCount
        }
        severity {
          key
          cves
          totalMentionsCount
        }
        vulnerability {
          key
          cves
          totalMentionsCount
        }
        cves {
          fpid
          key
          totalMentionsCount
        }
        collection {
          key
          cves
          totalMentionsCount
        }
        title {
          key
          cves
          basetypes
          totalMentionsCount
        }
        author {
          key
          cves
          basetypes
          totalMentionsCount
        }
      }
    }
  }
  }
`;

const Vulnerabilities = gql`
  query cveDashboardCard1(
    $start: Date!
    $end: Date!
    $v3severity: [String]
    $cves: [String]
    $products: [String]
    $vendors: [String]
  ) {
    cveDashboard(
      filters: {
        dateRange: { start: $start, end: $end }
        v3Severity: $v3severity
        cves: $cves
        productName: $products
        vendorName: $vendors
      }
    ) {
      cves {
        mitre
        nist
        severity {
          key
          fpid
          totalCVEs
        }
        vulnerability {
          key
          fpid
          totalCVEs
        }
        products {
          key
          fpid
          totalCVEs
        }
      }
    }
  }
`;

const Reports = gql`
  query vulnerabilityReports(
    $start: Date!
    $end: Date!
    $skip: Int
    $limit: Int
    $v3severity: [String]
    $cves: [String]
    $products: [String]
    $vendors: [String]
  ) {
    reports(
      filters: {
        limit: $limit
        tags: ["-DRAFT"]
        sort: versionPostedAt
        query: "CVE-"
        publishedStatus: published
        since: $start
        until: $end
        order: desc
        v3Severity: $v3severity
        cves: $cves
        productName: $products
        vendorName: $vendors
        skip: $skip
      }
    ) {
      reports {
        fpid
        title
        sources {
          title
          sourceID
          platformURL
          type
        }
        isFeatured
        postedAt
        versionPostedAt
        assets
        tags
        publishedStatus
        assets
      }
      total
      count
      skip
    }
  }
`;

export default {
  Exploits,
  Mentions,
  Vulnerabilities,
  Reports,
};
