import Api from '../../../../../utils/api';
import { savedSearchToQuery } from '../../utils';

import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import type { SavedSearch } from '../../../../../core/types/SavedSearch';

const MediaFields = [
  'enrichments',
  'container.fpid',
  'media.image_enrichment.enrichments.v1.image-analysis.text.value',
  'media.image_enrichment.enrichments.v1.image-analysis.classifications.value',
  'media.image_enrichment.enrichments.v1.image-analysis.logos.value',
  'media.video_enrichment.enrichments.v1.video-analysis.text.value',
  'media.video_enrichment.enrichments.v1.video-analysis.classifications.value',
  'media.video_enrichment.enrichments.v1.video-analysis.logos.value',
  'media.file_name',
  'media.media_type',
  'media.mime_type',
  'media.sha1',
  'site_actor.names.handle',
  'site_actor.names.aliases',
  'site.title',
];

const MediaIncludes = [
  'basetypes',
  'fpid',
  'sort_date',
  'enrichments.card-numbers.card-numbers.bin',
  'enrichments.v1.ip_addresses.ip_address',
  'enrichments.v1.email_addresses.email_address',
  'enrichments.v1.urls.domain',
  'enrichments.v1.monero_addresses.monero_address',
  'enrichments.v1.ethereum_addresses.ethereum_address',
  'enrichments.v1.bitcoin_addresses.bitcoin_address',
  'enrichments.v1.image-analysis.classifications.value',
  'enrichments.v1.image-analysis.text.value',
  'enrichments.v1.image-analysis.logos.value',
  'enrichments.v1.video-analysis.classifications.value',
  'enrichments.v1.video-analysis.text.value',
  'enrichments.v1.video-analysis.logos.value',
  'enrichments.v1.social_media.handle',
  'enrichments.v1.social_media.site',
  'enrichments.v1.vulnerability.cve.vulnerability',
  'media.image_enrichment',
  'media.video_enrichment',
  'media.storage_uri',
  'media.media_type',
];

const SearchUrl = '/ui/v4/all/search';

interface MediaData {
  _source: {
    media: {
      storage_uri: string;
      media_type: string;
    };
  };
}

const MediaSearch = async (
  savedSearch: SavedSearch | undefined,
  filters: ExpoDashFilters,
): Promise<HTMLImageElement[]> => {
  const searchType = savedSearch?.type?.searchType ?? 'media';
  const type = ['videos', 'images'].includes(searchType) ? searchType.slice(0, -1) : searchType;
  const searchQuery = savedSearch ? savedSearchToQuery(savedSearch).query : `+(${filters?.query})`;
  const clean = searchQuery?.replace(/\+basetypes:\((media|videos|images)\)/gi, '');
  const media = type === 'media' ? '' : `+_exists_:media.${type}_enrichment`;
  const date = `+sort_date:[${filters?.since || 'now-7d'} TO ${filters?.until || 'now'}]`;
  const query = {
    size: 10,
    query: `+basetypes:(chat AND message) ${clean} +_exists_:media.storage_uri ${media} ${date}`,
    highlight_size: 250,
    traditional_query: true,
    _source_includes: MediaIncludes,
    highlight: true,
    fields: MediaFields,
    sort: ['sort_date:desc'],
  };

  return Api.post(SearchUrl, query, [200], 30000, {}, true)
    .then((res) => {
      const result = res.ok ? res.data : null;
      return result;
    })
    .then((res) => {
      if (!res) return null;
      return res?.hits?.hits?.map((v: MediaData) => ({
        type: v?._source?.media?.media_type,
        src: `/ui/v4/media/assets/?asset_id=${v?._source?.media?.storage_uri}`,
      }));
    })
    .catch(() => null);
};

export default {
  MediaSearch,
};
