import PropTypes from 'prop-types';
import React from 'react';

import { escape } from 'lodash';
import moment from 'moment';

import { fromJS, List as list, Map as map } from 'immutable';
import {
  Bookmark,
  BookmarkBorder,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import Editor from '../manage/Editor';
import History from '../../utils/history';
import Text from '../../utils/text';
import UserActions from '../../actions/userActions';

const SaveSearchDialog = ({
  alerting,
  apps,
  filters,
  onClose,
  open,
  saved,
  type,
}) => {
  function closeDialog() {
    onClose();
  }

  function onSave(_, values) {
    const { pathname, query } = History.getCurrentLocation();
    const source = saved.get('data');
    const now = moment.utc().unix();
    const updates = values
      .map(v => v
        .set('name', escape(v.get('name')))
        .set('cat', escape(v.get('cat')))
        .set('value', Text.StripHtml(v.get('value'))));
    const queryParams = updates.get(0).get('filters').replace(`&skip=${query.skip}`, '');
    const data = updates.get(0).set('created', +now).set('updated', +now).set('filters', queryParams);
    const update = map({ total: source.count() + 1, data: source.push(data) });
    UserActions.saveSearch(update, data.get('name'));
    History.push({ pathname, query, hash: `#save:${now}` });
    closeDialog();
  }

  return (
    <Dialog
      open={Boolean(open)}
      onClose={() => closeDialog()}>
      <DialogTitle>Save New Search</DialogTitle>
      <DialogContent>
        <Editor
          fields={[
          { value: 'name', label: 'Search Name', type: 'text', req: true },
          { value: 'cat', label: 'Input Custom Search Category', type: 'text', bulk: true },
          { value: 'type',
            label: 'Source',
            type: 'dropdown',
            key: 'value',
            valueKey: 'value',
            options: !alerting
              ? apps
                .filter(v => !v.get('hidden'))
                .filter(v => /search\./g.test(v.get('value')))
              : apps.filter(v => v.get('value') === 'alerting.alerts'),
          },
          ...!alerting
            ? [{ value: 'value', label: 'Query', type: 'textarea' }]
            : [],
          { value: 'filtered', label: 'Include filters', type: 'checkbox',
            name: 'searches.filtered',
            help: `*Note: date filters, once applied, will be fixed in
            accordance with the date on which the search was saved.`, bulk: true },
          { value: 'pin', label: 'Pin Search', name: 'searches.pin',
            checked: <Bookmark />, unchecked: <BookmarkBorder />,
            type: 'checkbox', bulk: true }]}
          data={fromJS([{
          filtered: true,
          pin: true,
          value: filters.get('query'),
          filters: window.location.search.replace(/query=.*?(&|$)|transformed=.*?(&|$)/ig, ''),
          type: apps.find(v => ((!alerting)
            ? v.get('value') === `search.${type.split('.')[type.split('.').length - 1]}`
            : v.get('value') === 'alerting.alerts')) }])}
          type={type}
          save={(searchType, values) => onSave(searchType, values)}
          toggle={() => closeDialog()} />
      </DialogContent>
    </Dialog>
  );
};

SaveSearchDialog.propTypes = {
  alerting: PropTypes.bool,
  apps: PropTypes.object,
  filters: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  saved: PropTypes.object,
  type: PropTypes.string,
};

SaveSearchDialog.defaultProps = {
  alerting: false,
  apps: list(),
  filters: map(),
  onClose: null,
  open: false,
  saved: map(),
  type: '',
};

export default SaveSearchDialog;
