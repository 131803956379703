import PropTypes from 'prop-types';
import React, { useState } from 'react';

import cx from 'classnames';
import { Icon } from '@mui/material';
import style from './accordion.module.scss';

const Accordion = ({ children, header, subheader }) => {
  const [show, setShow] = useState(true);
  return (
    <div className={style.accordion}>
      <div
        role="link"
        tabIndex={0}
        onKeyUp={() => null}
        className={style.top}
        onClick={() => setShow(!show)}>
        <div className={style.left}>
          <div className={style.header}>{header}</div>
          <div className={style.subheader}>{subheader}</div>
        </div>
        <Icon className={style.icon}>
          keyboard_arrow_down
        </Icon>
      </div>
      <div className={cx([style.content, !show && style.hide])}>
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  header: PropTypes.string,
  subheader: PropTypes.string,
};

Accordion.defaultProps = {
  children: null,
  header: '',
  subheader: '',
};

export default Accordion;
