import React from 'react';
import PropTypes from 'prop-types';
import { List as list, Map as map } from 'immutable';
import { Chart } from 'react-google-charts';

import style from './map.module.scss';
import Maps from '../../../constants/Maps';

const Map = ({
  labels,
  values,
  results,
}) => {
  if (!results.get('data')) return null;
  const data = results.get('data');
  const name = labels.getIn([0, 'key']);
  const value = values.getIn([0, 'key']);
  const colors = [
    '#FEE7E2',
    '#FCC3B8',
    '#F88670',
    '#DF7965',
    '#AE5E4E',
    '#7C4338',
  ];
  const percentileCache = {};
  // determine the percentile rank of a given value and a list of numbers
  const percentileOfScore = (array, toCheck) => {
    if (percentileCache[Number(toCheck)] != null) return percentileCache[Number(toCheck)];
    let lowerCount = 0;
    let sameCount = 0;
    for (let i = 0; i < array.length; i += 1) {
      if (array[Number(i)] < toCheck) {
        lowerCount += 1;
      } else if (array[Number(i)] === toCheck) {
        sameCount += 1;
      } else {
        break;
      }
    }
    // https://en.wikipedia.org/wiki/Percentile_rank
    const val = (lowerCount + (0.5 * sameCount)) / array.length;
    percentileCache[Number(toCheck)] = val;
    return val;
  };

  // pick a distributed set of numbers of length n from a list of items
  const distributedRange = (items, n) => {
    const elements = [items[0]];
    const totalItems = items.length - 2;
    const interval = Math.floor(totalItems / (n - 2));
    for (let i = 1; i < n - 1; i += 1) {
      elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements;
  };

  let countryMap = data
    .filter(v => (v.getIn(name.split('.')) || '').length > 2)
    .groupBy(v => v.getIn(name.split('.')))
    .map((v, k) => ({
      key: k,
      doc_count: v.reduce((a, b) => a + b.get(value), 0),
    }))
    .sortBy(v => -v.doc_count)
    .toList()
    .reduce((a, b) => a.concat([[b.key, b.doc_count]]), []);

  const max = countryMap[0][1];
  const counts = countryMap.map(v => v[1]).reverse();
  const percentileCounts = counts.map(v => percentileOfScore(counts, v) * max);
  countryMap = countryMap
    .reverse()
    .map((v, k) => [v[0], { v: percentileCounts[Number(k)], f: v[1].toLocaleString() }]);
  const countOptions = [...new Set(percentileCounts)];
  const colorAxisValues = distributedRange(countOptions, colors.length);
  colorAxisValues[0] = 0;
  colorAxisValues[colorAxisValues.length - 1] = max;

  let dataArray;
  let options;

  if (data.size > 1 || !data.hasIn([0, 'geolocation'])) {
    dataArray = [['Country', 'Count']].concat(countryMap);
    options = {
      colorAxis: { colors, values: colorAxisValues },
      defaultColor: colors[0],
      datalessRegionColor: colors[0],
    };
  } else {
    dataArray = [
      ['Latitude', 'Longitude', 'Count'],
      [
        data.getIn([0, 'geolocation', 'lat']),
        data.getIn([0, 'geolocation', 'lon']),
        data.getIn([0, 'doc_count']),
      ],
    ];
    options = {
      displayMode: 'markers',
      colorAxis: { colors: ['#fdbc85', '#5c6ae0'] },
      resolution: 'provinces',
      region: ['US'].includes(data.getIn([0, 'country_code']))
        ? `US-${data.getIn([0, 'region_code'])}`
        : data.getIn([0, 'country_code']),
    };
  }

  return (
    <Chart
      width="100%"
      chartType="GeoChart"
      data={dataArray}
      options={options}
      className={style.map}
      mapsApiKey={Maps[`${'_self' in React.createElement('div') ? 'dev' : 'prod'}_key`]}
    />
  );
};

Map.propTypes = {
  labels: PropTypes.object,
  values: PropTypes.object,
  results: PropTypes.object,
};

Map.defaultProps = {
  labels: map(),
  values: map(),
  results: list(),
};

export default Map;
