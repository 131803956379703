import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Button, CircularProgress, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilValue, useRecoilState } from 'recoil';
import { fromJS } from 'immutable';
import ClDialogAccount from './ClDialogAccount';
import ClDialogBucket from './ClDialogBucket';
import SettingsList from '../shared/SettingsList';
import AlertingStore from '../../../../stores/recoil/alerting';
import Prompt from '../../../utils/Prompt';
import Messages from '../../../../constants/Messages';
import Query from '../../../../containers/Alerting/query';
import { loadAllS3, saveAccount, saveBucket, deleteIpAddress, editS3Account, editS3Bucket, bulkUpload } from './query';
import SearchActions from '../../../../actions/searchActions';
import useStyles from './style';

const CloudInfrastructure = ({ user, keywordClass, disabled }) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({});
  const [responseData, setReponseData] = useState([]);
  const [owners, setOwners] = useRecoilState(AlertingStore.owners);
  const [selected, setSelected] = useState(null);
  const [type, setType] = useState(null); // Determine which button was clicked
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState('');
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [files, setFiles] = React.useState([]);
  const [bulkResponse, setBulkResponse] = useState({});
  const [csvContent, setCsvContent] = useState([]);
  const [preview, setPreview] = useState(false);
  const numPerPage = 10;

  useEffect(() => {
    Query.load('owners', user.get('prm')).then(res => setOwners(fromJS(res.owners)));
    setOwners(owners);
  }, []);

  useEffect(() => {
    if (ownerId) {
      setIsLoading(true);
      loadAllS3(ownerId, page, numPerPage)
        .then((data) => {
          setIsLoading(false);
          if (!data?.pagination?.count) return;
          setPagination(data.pagination);
          setReponseData(data.objects);
        })
        .catch((err) => {
          if (/cancel/ig.test(err.message)) {
            /* Skip */
            return;
          }
          setIsLoading(false);
          SearchActions.set(['search', 'info', 'message'], Messages.CloudBucketFetchError);
          throw err;
        });
      }
  }, [refresh, ownerId, page]);

  const onSubmit = (data) => {
    setResponseErrorMessage('');
    if (dialog.key === 'create') {
      if (type === 'bucket') {
        if (files.length) {
          bulkUpload(ownerId, keywordClass, files)
            .then((res) => {
              if (res.status === 400) {
                throw new Error(`${res.data.error}`);
              }
              setBulkResponse(res.data);
              return res.data;
            })
            .then(() => setRefresh(!refresh))
            .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
            .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
          setDialog({});
          setFiles([]);
          setPreview(false);
        } else {
        const payload = { ...data, region: data.region.toLowerCase() };
        saveBucket(ownerId, keywordClass, payload)
          .then((res) => {
            if (res.status === 400) {
              throw new Error(`${res.data.error}`);
            }
            return res.data;
          })
          .then(() => setRefresh(!refresh))
          .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
          .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
        setDialog({});
        }
      }// post call for creating S3 buckets
      if (type === 'account') {
        const credential = { access_key_id: data.access_key_id, secret_key: data.secret_key };
        const accountName = data.account_name;
        saveAccount(ownerId, keywordClass, credential, accountName)
          .then((res) => {
            if (res.status === 400) {
              throw new Error(`${res.data.error}`);
            }
            setDialog({});
            return res.data;
          })
          .then(() => setRefresh(!refresh))
          .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
          .catch((err) => {
            if (/This account does not have the necessary permissions/ig.test(err.message)) {
              setResponseErrorMessage(err.message);
              return;
            }
            SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message));
            setDialog({});
          },
            );
      } // post call for creating S3 accounts
    }
    if (dialog.key === 'edit') {
      if (type === 'bucket') {
        const payload = { ...data, region: data.region.toLowerCase() };
        editS3Bucket(selected.id, payload)
          .then((res) => {
            if (res.status === 400) {
              throw new Error(`${res.data.error}`);
            }
            return res.data;
          })
          .then(() => setRefresh(!refresh))
          .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
          .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
        setDialog({});
      }
      if (type === 'account') {
        const credential = { access_key_id: data.access_key_id, secret_key: data.secret_key };
        const accountName = data.account_name;
        editS3Account(selected.id, credential, accountName)
          .then((res) => {
            if (res.status === 400) {
              throw new Error(`${res.data.error}`);
            }
            setDialog({});
            return res.data;
          })
          .then(() => setRefresh(!refresh))
          .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
          .catch((err) => {
            if (/This account does not have the necessary permissions/ig.test(err.message)) {
              setResponseErrorMessage(err.message);
              return;
            }
            SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message));
            setDialog({});
          });
      } // post call for creating S3 accounts
    }// put call for updating S3 buckets
  };

  const onDelete = (keyword) => {
    deleteIpAddress(keyword.id)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.KeywordsDeleted))
      .then(() => setRefresh(!refresh))
      .finally(() => setDialog({}))
      .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
  };

  const onViewClick = (index) => {
    setSelected(responseData[Number(index)]);
    if (responseData[Number(index)].credential_type === 'aws_iam') {
      setType('account');
    } else {
      setType('bucket');
    }
    setDialog({ key: 'view' });
  };

  const onEditClick = (index) => {
    setSelected(responseData[Number(index)]);
    if (responseData[Number(index)].credential_type === 'aws_iam') {
      setType('account');
    } else {
      setType('bucket');
    }
    setDialog({ key: 'edit' });
  };

  const onDeleteClick = (index) => {
    setSelected(responseData[Number(index)]);
    if (responseData[Number(index)].credential_type === 'aws_iam') {
      setType('account');
    } else {
      setType('bucket');
    }
    setDialog({ key: 'delete' });
  };

  const openAndClearDialog = (e) => {
    setType(e.target.id);
    setSelected(null);
    setDialog({ key: 'create' });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDrop = (_files) => {
    const fileReader = new FileReader();
    if (_files) {
      fileReader.onload = (e) => {
        const csvOutput = e.target.result;
        setCsvContent(csvOutput.split('\n'));
      };
      fileReader.readAsText(..._files);
    }
    setFiles([..._files]);
  };

  const onDeleteBulk = (index) => {
      const currentFiles = [...files];
      currentFiles.splice(index, 1);
      setFiles(currentFiles);
  };

  return (
    <div className="cloudInfrastructure" data-testid="cloudInfrastructureSetting">
      <SettingsList
        items={responseData}
        disabled={disabled}
        onView={(item, index) => onViewClick(index)}
        onDelete={(item, index) => onDeleteClick(index)}
        onEdit={(item, index) => onEditClick(index)}
        options={{
          loading: isLoading && <CircularProgress size={25} thickness={2} />,
          emptyMessage: !isLoading && 'Nothing to show. Click on the \'Add A Bucket Or Add An Account\' button to get started.',
        }}
        pagination={pagination}
        handleChangePage={handleChangePage}
        page={page}
      />
      <Button
        disabled={disabled}
        onClick={(e) => { openAndClearDialog(e); }}
        variant="contained"
        color="secondary"
        id="bucket"
        data-testid="cloudinfra.add-bucket">
        Add A Bucket
      </Button>
      <Button
        disabled={disabled}
        onClick={(e) => { openAndClearDialog(e); }}
        variant="contained"
        color="secondary"
        id="account"
        style={{ marginLeft: '1rem' }}
        data-testid="cloudinfra.add-account">
        Add An Account
      </Button>
      <ClDialogAccount
        open={(dialog?.key === 'create' || dialog?.key === 'edit' || dialog?.key === 'view') && type === 'account'}
        selected={selected}
        onSubmit={onSubmit}
        errorMessage={responseErrorMessage}
        onCancel={() => {
          setDialog({});
          setType(null);
          setResponseErrorMessage('');
        }}
        dialog={dialog}
        />
      <ClDialogBucket
        open={(dialog?.key === 'create' || dialog?.key === 'edit' || dialog?.key === 'view') && type === 'bucket'}
        selected={selected}
        onSubmit={onSubmit}
        handleDrop={handleDrop}
        files={files}
        csvContent={csvContent}
        preview={preview}
        setPreview={setPreview}
        onCancel={() => {
          setDialog({});
          setType(null);
          setFiles([]);
          setCsvContent([]);
          setPreview(false);
        }}
        dialog={dialog}
        onDeleteBulk={onDeleteBulk}
        featureFlag={user.get('prm').some(p => /bucket-bulk-upload/.test(p))} // remove FF
        />
      <Dialog
        open={!_.isEmpty(bulkResponse)}
        title="Add New AWS Bucket"
        acceptText="Done"
        onClose={() => {
          setBulkResponse({});
        }}
      >
        <div className={classes.dialogBucketUploadContainer}>
          <div className={classes.dialogBucketUploadSuccessContainerTitle}>
            <div className={classes.dialogBucketUploadTitle}>Add New AWS Bucket</div>
            <CloseIcon onClick={() => { setBulkResponse({}); }} style={{ cursor: 'pointer' }}/>
          </div>
          <div className={classes.dialogBucketUploadSuccess}>
            {bulkResponse.total_successes} buckets succesfully uploaded!
          </div>
          <div className={classes.dialogBucketUploadFailed}>
            {bulkResponse.total_duplicates} duplicate buckets.
          </div>
          <div className={classes.dialogBucketUploadFailed}>
            {bulkResponse.total_failures} buckets uploads failed. Details below:
          </div>
          <div className={classes.dialogBucketUploadFailed}>
            <ul>
              { bulkResponse?.failures?.map(failure =>
                <li className={classes.dialogBucketUploadFailedContent}>
                  <div className={classes.dialogBucketUploadFailedContent}>
                    Line {failure.line}:&nbsp;
                  </div>
                  <div className={classes.dialogBucketUploadFailedMessage}>
                    <div> Bucket: {`${failure.bucket_name && failure.bucket_name}`}</div>
                    <div> Error: {failure.error_msg.split('.')[0]}</div>
                  </div>
                </li>,
            )}
            </ul>
          </div>
        </div>
      </Dialog>
      <Prompt
        open={Boolean(dialog?.key === 'delete')}
        title={`Warning: Delete ${type === 'account' ? 'Account' : 'Bucket'}?`}
        acceptText="Continue"
        accept={() => onDelete(selected)}
        cancelText="Cancel"
        cancel={() => {
          setType(null);
          setDialog({});
          }}>
        {`You are about to permanently delete this ${type === 'account' ? 'Account' : 'Bucket'}. Are you sure?`}
      </Prompt>
    </div>
  );
};

CloudInfrastructure.propTypes = {
  user: PropTypes.object,
  keywordClass: PropTypes.object,
  disabled: PropTypes.bool,
};

CloudInfrastructure.defaultProps = {
  user: null,
  keywordClass: null,
  disabled: false,
};

export default CloudInfrastructure;
