/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactTooltip from 'react-tooltip';
import { fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { useRecoilState } from 'recoil';
import {
  Button,
  CircularProgress,
  Icon,
} from '@mui/material';

import style from './media.module.scss';
import Query from './query';
import History from '../../../utils/history';
import Common from '../../../utils/common';
import Text from '../../../utils/text';
import Hero from '../../widget/Hero/Hero';
import Table from '../../widget/Table/Table';
import Invalid from '../../utils/Invalid/Invalid';
import SearchActions from '../../../actions/searchActions';
import { appStore } from '../../../stores/recoil/app';

const Media = ({
  match: {
    params: {
      id,
    },
  },
}) => {
  const [media, setMedia] = useState();
  const [mentions, setMentions] = useState({});
  const [carousel, setCarousel] = useState(-1);
  const [appState, setAppState] = useRecoilState(appStore);
  const { query: { type, query, sha1, fpid } } = History.getCurrentLocation();

  const onRoute = (v) => {
    History.navigateTo(v);
  };

  const onSearch = (k, v) => {
    const searchType = Common.Basetypes.BasetypeToSearchType(v);
    const value = v.getIn(['media_v2', 0, k]);
    History.navigateTo({
      pathname: `/home/search/${searchType}`,
      query: { [k]: value },
    });
  };

  const onLoad = async () => {
    const data = await Query.Media(type, id, fpid, sha1, query);
    const result = data?.data?.[0];
    SearchActions.set(['search', 'result', 'media'], fromJS({ image: result }));
    setMedia(result);
  };

  useEffect(() => {
    if (!id) return;
    onLoad();
    setAppState({ ...appState, params: { id } });
  }, [id]);

  useEffect(() => {
    if (isEmpty(media)) return;
    const sha1Seen = media?.media?.sha1 || media?.sha1;
    Query
      .Mentions(sha1Seen)
      .then(res => setMentions(res));
  }, [media]);

  return (
    <Grid
      fluid
      name="component.media"
      className={cx([style.base, style.media])}>
      {!media &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {media &&
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              {(media.media_v2?.[0]?.media_type === 'video' ||
              media.basetypes?.includes('video-enrichment')) &&
              <video
                autoPlay={false}
                id="video"
                src={media?.src}
                controls
                height="100%"
                width="100%" />}
              {(media?.media_v2?.[0]?.media_type === 'image' ||
              media?.basetypes?.includes('image-enrichment')) &&
              <img
                src={media?.src}
                className={style.image}
                onClick={() => setCarousel(0)}
                alt="" />}
            </Col>
            {!mentions?.data &&
            <Row>
              <Col xs={4}>
                <CircularProgress />
              </Col>
            </Row>}
            {mentions?.data &&
            <Col xs={4}>
              <Row>
                <Col xs={12} className={style.counts}>
                  <Hero
                    tooltip="Number of times this image has been seen within Flashpoint collections."
                    text
                    styles={{ margin: 'auto' }}
                    labels={fromJS(['Times Seen'])}
                    values={fromJS([{ key: 'key' }])}
                    results={fromJS({ key: mentions?.total })} />
                  {mentions?.firstSeen ?
                    <Hero
                      tooltip="Date in which Flashpoint first collected this image."
                      text
                      styles={{ margin: 'auto' }}
                      labels={fromJS(['First Seen'])}
                      values={fromJS([{
                        key: 'key',
                        render: v => moment.utc(v).format('MM/DD/YYYY'),
                      }])}
                      results={fromJS({
                        key: mentions?.firstSeen,
                      })} /> :
                    <Hero
                      tooltip="Date in which Flashpoint first collected this image."
                      text
                      styles={{ margin: 'auto' }}
                      labels={fromJS(['First Seen'])}
                      values={fromJS([{
                        key: 'key',
                      }])}
                      results={fromJS({
                        key: '-- / -- / --',
                      })} />
                  }
                  {mentions?.lastSeen ?
                    <Hero
                      tooltip="Date in which Flashpoint last saw this image."
                      text
                      styles={{ margin: 'auto' }}
                      labels={fromJS(['Last Seen'])}
                      values={fromJS([{
                        key: 'key',
                        render: v => moment.utc(v).format('MM/DD/YYYY'),
                      }])}
                      results={fromJS({
                        key: mentions?.lastSeen,
                      })} /> :
                    <Hero
                      tooltip="Date in which Flashpoint first collected this image."
                      text
                      styles={{ margin: 'auto' }}
                      labels={fromJS(['Last Seen'])}
                      values={fromJS([{
                        key: 'key',
                      }])}
                      results={fromJS({
                        key: '-- / -- / --',
                      })} />
                  }
                </Col>
              </Row>
            </Col>}
          </Row>
          <Row>
            {['text'].map(field => (
              <Col xs={8} key={field}>
                <div className={style.title}>
                  <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                    {Text.Sentence(field)}
                  </div>
                </div>
                <div className={cx([style.section, style.text])}>
                  <Table
                    header={false}
                    pagination={false}
                    emptyMessage={`No ${field} entries extracted`}
                    styles={{
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                    results={fromJS({
                      skip: media?.[String(field)]?.skip,
                      total: [...new Set(media?.[String(field)]
                        ?.data?.map(v => v?.value))]?.length,
                      data: [...new Set(media?.[String(field)]?.data?.map(v => v?.value))]
                        .map(v => ({ value: v })),
                    })}
                    values={fromJS([{
                      key: 'value',
                      label: 'Tag',
                      style: { whiteSpace: 'normal' },
                      render: v => Text.Highlight(v),
                    }])} />
                </div>
              </Col>))}
            <Col xs={4}>
              <Row>
                {['classifications', 'logos'].map(field => (
                  <Col xs={12} key={field}>
                    <div className={style.title}>
                      <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                        {Text.Sentence(field)}
                      </div>
                    </div>
                    <div className={cx([style.section, style.classifications])}>
                      <Table
                        header={false}
                        pagination={false}
                        emptyMessage={`No ${field} entries extracted`}
                        styles={{
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                        results={fromJS({
                          skip: media?.[String(field)]?.skip,
                          total: [...new Set(media?.[String(field)]
                            ?.data?.map(v => v?.value))]?.length,
                          data: [...new Set(media?.[String(field)]?.data?.map(v => v?.value))]
                            .map(v => ({ value: v })),
                        })}
                        values={fromJS([{
                          key: 'value',
                          label: 'Tag',
                          style: { whiteSpace: 'normal' },
                          render: v => Text.Highlight(v),
                        }])} />
                    </div>
                  </Col>))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className={style.title}>
                <div className={cx([style.h1, 'h1', style.open, style.primary])}>
                  Latest posts containing this media
                </div>
                {mentions && mentions?.total > 0 &&
                <Button
                  color="secondary"
                  variant="contained"
                  data-for="help.tooltip"
                  data-tip="Search mentions of related SHA-1 hash"
                  onClick={() => onSearch('sha1', fromJS(mentions?.data?.at(0)))}>
                  See All
                </Button>}
              </div>
              {!mentions?.data &&
              <CircularProgress />}
              {mentions?.data && mentions?.total > 0 &&
              <div className={cx([style.section, style.posts])}>
                <Table
                  header
                  pagination={false}
                  styles={{
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                  }}
                  onClick={v => onRoute(v)}
                  results={fromJS(mentions)}
                  values={fromJS([
                    { key: 'sort_date',
                      label: 'Date',
                      style: { width: '15%' },
                      render: v => moment.utc(v).format('MM/DD/YYYY HH:mm:ss'),
                    },
                    { key: 'site.title',
                      label: 'Service',
                      style: { width: '5%' },
                      render: v => Text.Sentence(v),
                    },
                    { key: 'container',
                      label: 'Channel',
                      style: { width: '15%' },
                      render: v => v?.get('title') ?? v?.get('name'),
                    },
                    { key: 'site_actor.names.handle',
                      label: 'Author',
                      style: { width: '15%' },
                    },
                    { key: 'media_v2.0.file_name',
                      label: 'Filename',
                    },
                    { key: 'body.text/plain',
                      label: 'Preview',
                    },
                    { key: '',
                      label: '',
                      render: v => (
                        <div >
                          <Icon
                            data-tip="All posts from this author"
                            data-for="global.tooltip"
                            onClick={(event) => {
                              const searchType = Common.Basetypes.BasetypeToSearchType(v);
                              event.stopPropagation();
                              History.navigateTo({
                                pathname: `/home/search/${searchType}`,
                                query: {
                                  author: v.getIn(['site_actor', 'names', 'handle']),
                                  author_exact: true,
                                },
                              });
                            }}>record_voice_over
                          </Icon>
                          <Icon
                            data-tip="Start of channel"
                            data-for="global.tooltip"
                            onClick={(event) => {
                              event.stopPropagation();
                              const { pathname } = Common.Generic.Route(v);
                              History.navigateTo({
                                pathname,
                                query: {},
                              });
                            }}>filter_list
                          </Icon>
                        </div>
                      ),
                    },
                  ])} />
                {mentions && mentions?.total === 0 &&
                  <Invalid
                    icon="error_outline"
                    title="Post not found"
                    help="The post(s) that this image was embedded in is not yet available" />}
              </div>}
            </Col>
          </Row>
        </Col>
      </Row>}
      {mentions?.total === 0 &&
      <div className={style.invalid}>
        <Invalid
          center
          help=""
          icon="error_outline"
          title="Item not found" />
      </div>}
      <ModalGateway>
        {Boolean(carousel > -1) &&
        <Modal onClose={() => setCarousel(-1)}>
          <Carousel
            views={[{ source: media?.src }]}
            currentIndex={carousel} />
        </Modal>}
      </ModalGateway>
      <ReactTooltip id="help.tooltip" html place="right" effect="solid" />
    </Grid>
  );
};

Media.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Media;
