import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import { List as list } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './intelligence.module.scss';
import History from '../../utils/history';
import Wiki from '../../components/wiki/Wiki';
import KnowledgeBase from '../../components/reports/KnowledgeBase';
import Reports from '../../components/reports/Reports';
import Browse from '../../components/reports/Browse';
import Tags from '../../components/reports/Tags';
import SearchActions from '../../actions/searchActions';
import { SearchContext, UserContext } from '../../components/utils/Context';

const Intelligence = ({
  match: {
    params,
  },
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);

  useEffect(() => {
    if (['apt', 'malware', 'topics'].includes(params.section)) return;
    SearchActions.searchIntel(params.tags, params.section);
  }, [params.tags, params.section]);

  useEffect(() => {
    // Update the path to use the user's topics
    const { pathname, query } = History.getCurrentLocation();
    const tags = user.getIn(['prefs', 'tags'], list()).map(v => v.get('name')).join(',');
    if (query.filter) History.push(`${pathname}/${tags}`);
  }, []);

  return (
    <Grid fluid className={cx([style.intelligence, style[params.section]])}>
      <Row>
        {['latest'].includes(params.section) &&
        <Col xs={3}>
          {params.section === 'latest' &&
            <div>
              <Browse
                title="Browse"
                tag={params.tags}
                prm={user.get('prm')}
                tags={search.get('tags')} />
              <Tags
                pathname={window.location.pathname}
                prefs={user.get('prefs')}
                tags={search.get('tags')} />
            </div>}
        </Col>}
        <Col xs={['latest', 'standup', 'actors', 'techintel'].includes(params.section) ? 9 : 12}>
          {['latest', 'standup', 'actors', 'techintel'].includes(params.section) &&
            <Reports
              type={params.section}
              tags={params.tags}
              apps={user.get('apps')}
              cdn={user.getIn(['prefs', 'image_cdn'])}
              userHistory={user.getIn(['prefs', 'history', 'items'])}
              title={params.tags || 'All'}
              reports={search.getIn(['results', params.section])} />}
        </Col>
      </Row>
      {['knowledgebase'].includes(params.section) &&
      <Row>
        <Col xs={12}>
          <KnowledgeBase
            cdn={user.getIn(['prefs', 'image_cdn'])}
            reports={search.getIn(['results', params.section])}
            userHistory={user.getIn(['prefs', 'history', 'items'])} />
        </Col>
      </Row>}
      {['apt', 'malware', 'topics'].includes(params.section) &&
      <Row>
        <Col xs={12}>
          <Wiki
            fpid={params.id}
            type={params.section}
            subtype={params.subsection}
            orgName={user.get('ogn')}
            orgId={user.get('sf_id')}
            prm={user.get('prm')} />
        </Col>
      </Row>}
    </Grid>
  );
};

Intelligence.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Intelligence;
