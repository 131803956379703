import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { List as list, Map as map } from 'immutable';
import {
  Badge,
  Button,
  Fab,
  Icon,
  ListItem,
  Popover,
} from '@mui/material';

import style from './bulkEditButton.module.scss';
import Tagger from '../tagging/Tagger';
import TextFilter from '../filters/TextFilter';
import Prompt from '../../components/utils/Prompt';

const BulkEditButton = ({
  actions,
  floating,
  label,
  selected,
}) => {
  const [dialog, setDialog] = useState();
  const [prompt, setPrompt] = useState();

  function onOpenInput(target, fieldType) {
    const action = actions.find(v => v.get('field') === fieldType);
    const {
      onApply,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      label: buttonLabel,
      defaults,
      field,
      filters,
      inputLabel,
      type,
      component,
      ...extra } = action.toObject();
    // For delete actions, handle via warning prompt
    if (['delete'].includes(type)) {
      setPrompt({
        title: inputLabel,
        acceptText: `Delete ${selected.count()} selected items`,
        accept: () => {
          onApply(selected, extra);
          setPrompt();
        },
        cancelText: 'Cancel',
        cancel: () => setPrompt(),
      });
    // For bulk edits, handle via Dialog
    } else {
      let input;
      switch (type) {
        case 'tagger':
          input = (
            <Tagger
              fpid={selected.count() === 1 ? selected.get(0) : selected}
              prm={extra.prm}
              username={extra.username}
              metaData={extra.metaData}
              onAction={() => setDialog()} />
          );
          break;
        case 'component':
          input = component();
          break;
        default:
          input = (
            <TextFilter
              onApply={values => onApply(selected, values, extra)}
              filters={filters || map()}
              defaults={defaults || map()}
              fields={[
                { value: field, label: inputLabel, type, ...extra }]}
              toggle={() => setDialog()} />
          );
          break;
      }

      setDialog({
        field: input,
        key: 'edit',
        target,
      });
    }
  }

  return (
    <div className={style['bulk-edit-button']}>
      {!floating &&
      <Button
        variant="contained"
        onClick={event => setDialog({ key: 'menu', target: event.target })}
        endIcon={
          <Icon>
            keyboard_arrow_down
          </Icon>
        }>
        {label || `Bulk Edit (${selected.count()})`}
      </Button>}
      {floating &&
      <Badge
        color="primary"
        badgeContent={selected.count()}>
        <Fab
          size="small"
          color="secondary"
          onClick={event => setDialog({ key: 'menu', target: event.target })}>
          <Icon>edit</Icon>
        </Fab>
      </Badge>}
      <Popover
        anchorEl={dialog && dialog.target}
        open={Boolean(dialog && dialog.key === 'menu')}
        onClose={() => setDialog()}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {actions.map(v => (
          <ListItem
            key={v.get('field')}
            value={v.get('field')}
            onClick={() => onOpenInput(dialog.target, v.get('field'))}>
            {v.get('label')}
          </ListItem>
        ))}
      </Popover>
      <Popover
        anchorEl={dialog && dialog.target}
        open={Boolean(dialog && dialog.key === 'edit')}
        onClose={() => setDialog()}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {dialog && dialog.field}
      </Popover>
      {prompt &&
      <Prompt
        open
        title={prompt.title}
        acceptText={prompt.acceptText}
        accept={prompt.accept}
        cancelText={prompt.cancelText}
        cancel={prompt.cancel} />}
    </div>
  );
};

BulkEditButton.propTypes = {
  actions: PropTypes.object,
  floating: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.object,
};

BulkEditButton.defaultProps = {
  actions: list(),
  floating: false,
  label: '',
  selected: list(),
};

export default BulkEditButton;
