import * as React from 'react';

import {
  TableBody as MuiTableBody,
} from '@mui/material';

interface TableBodyProps {
  /** The content of the table body */
  children?: React.ReactNode;
}

/** The table body */
export const TableBody = ({
  children,
  ...rest
}: TableBodyProps): JSX.Element =>
  <MuiTableBody {...rest} >
    {children}
  </MuiTableBody>;

TableBody.defaultProps = {
  children: '',
};
