export const white = '#ffffff';

export const blue = {
  50: '#F6F7FD',
  100: '#E5E7FA',
  200: '#C3C8F4',
  300: '#A1A9ED',
  400: '#7E89E7',
  500: '#5c6ae0',
  600: '#3547D9',
  700: '#2434BC',
  800: '#1C2996',
  900: '#151F6F',
};

export const darkBlue = {
  100: '#EEEDF9',
  200: '#B6B8CC',
  300: '#999CB5',
  400: '#8487A2',
  500: '#6F7391',
  600: '#606580',
  700: '#4F5269',
  800: '#3E4054',
  900: '#2A2C3C',
};

export const green = {
  50: '#E1F4EE',
  100: '#B6E3D3',
  200: '#89D2B8',
  300: '#5BBF9C',
  400: '#3BB189',
  500: '#25A277',
  600: '#21946B',
  700: '#1B845C',
  800: '#12744E',
  900: '#035834',
};

export const red = {
  50: '#FEE8ED',
  100: '#FDC5CE',
  200: '#EC8B93',
  300: '#E05C69',
  400: '#E92A46',
  500: '#EB002B',
  600: '#DD002B',
  700: '#CB0025',
  800: '#BE001E',
  900: '#AF0012',
};

export const orange = {
  50: '#FFFDE7',
  100: '#FFF9C4',
  200: '#FFF59D',
  300: '#FEF075',
  400: '#FCEB55',
  500: '#FFEB3B',
  600: '#FDD835',
  700: '#FBC02D',
  800: '#F9A825',
  900: '#FB8721',
};
