import React from 'react';
import Reflux from 'reflux';

import { chunk } from 'lodash';
import { fromJS, Map as map } from 'immutable';

import SearchActions from '../actions/searchActions';
import CredentialsActions from '../actions/credentialsActions';
import Api from '../utils/api';
import Messages from '../constants/Messages';

const CredentialsUrl = `/ui/v4/credentials${'_self' in React.createElement('div') ? '/dev' : ''}`;

const Status = [200, 201, 202, 204, 500, 501, 502, 503, 504];
const defaultSettings = {
  auth_data: '',
  auth_type: 'basic',
  base_url: '',
  batch_size: 1,
  hash_algorithm: 'sha256',
  hash_response: [],
  subscriptions: [],
};

const CredentialsStore = Reflux.createStore({
  listenables: [CredentialsActions],

  init() {
    this.state = fromJS({
      credentials: {
        activePromises: 0,
        customerReplaceUrl: '',
        customerImportStatus: {},
        customerStats: {},
        settings: {},
      },
    });
  },

  async onAddCustomers(hashes) {
    const chunkSize = 10000;
    const chunks = chunk(hashes, chunkSize);
    let remainingRequests = chunks.length;
    CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
    const promiseResponses = [];
    async function asyncMakeRequests(callback) {
      for (let i = 0; i < chunks.length; i += 1) {
        await callback(i);
      }
    }

    await asyncMakeRequests(async (index) => {
      const body = {
        keys: chunks[Number(index)],
      };

      await new Promise((resolve) => {
        Api.post(`${CredentialsUrl}/customers`, body, Status, 30000, {}, true)
          .then(res => (res.ok ? res.data : {}))
          .then(res => promiseResponses.push(res.records_added))
          .then(() => {
            remainingRequests -= 1;
            CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
          })
          .then(() => setTimeout(resolve, 250))
          .catch(() => {
            promiseResponses.push(false);
            remainingRequests -= 1;
            CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
            setTimeout(resolve, 250);
          });
      });
    });
    let errors = '';
    const hasErrors = [];
    let totalAdded = 0;
    promiseResponses.forEach((v, k) => {
      if (v === false) hasErrors.push(k);
      else totalAdded += v;
    });
    if (hasErrors.length > 0) errors += hasErrors.map(v => `${(v * chunkSize) + 1} - ${(v * chunkSize) + chunkSize}`).join(', ');
    SearchActions.set(['search', 'info', 'message'], Messages.HashAdditionError(errors, totalAdded));
  },

  async onDeleteCustomers(hashes) {
    const chunkSize = 10000;
    const chunks = chunk(hashes, chunkSize);
    let remainingRequests = chunks.length;
    CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
    const promiseResponses = [];
    async function asyncMakeRequests(callback) {
      for (let i = 0; i < chunks.length; i += 1) {
        await callback(i);
      }
    }

    await asyncMakeRequests(async (index) => {
      const body = {
        keys: chunks[Number(index)],
      };
      await new Promise((resolve) => {
        Api.delete(`${CredentialsUrl}/customers`, {}, Status, 30000, body)
          .then(res => (res.ok ? res.data : {}))
          .then(res => promiseResponses.push(res.records_deleted))
          .then(() => {
            remainingRequests -= 1;
            CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
          })
          .then(() => setTimeout(resolve, 250))
          .catch(() => {
            promiseResponses.push(false);
            remainingRequests -= 1;
            CredentialsActions.set(['credentials', 'activePromises'], remainingRequests);
            setTimeout(resolve, 250);
          });
      });
    });
    let errors = '';
    const hasErrors = [];
    let totalDeleted = 0;
    promiseResponses.forEach((v, k) => {
      if (v === false) hasErrors.push(k);
      else totalDeleted += v;
    });
    if (hasErrors.length > 0) errors += hasErrors.map(v => `${(v * chunkSize) + 1} - ${(v * chunkSize) + chunkSize}`).join(', ');
    SearchActions.set(['search', 'info', 'message'], Messages.HashDeletionError(errors, totalDeleted));
  },

  onGetCustomerReplaceUrl() {
    const key = ['credentials', 'customerReplaceUrl'];
    Api.get(`${CredentialsUrl}/customers/upload-url`, {}, Status)
      .then(res => (res.ok ? res.data : ''))
      .then(res => CredentialsActions.set(key, res.url))
      .catch(() => CredentialsActions.set(key, ''));
  },

  onGetCustomerImportStatus() {
    const key = ['credentials', 'customerImportStatus'];
    Api.get(`${CredentialsUrl}/customers/upload-status`, {}, Status)
      .then(res => (res.ok ? res.data : {}))
      .then(res => CredentialsActions.set(key, fromJS(res)))
      .catch(() => CredentialsActions.set(key, map()));
  },

  onGetCustomerStats() {
    const key = ['credentials', 'customerStats'];
    Api.get(`${CredentialsUrl}/customers/stats`, {}, Status)
      .then(res => (res.ok ? res.data : {}))
      .then(res => CredentialsActions.set(key, fromJS(res)))
      .catch(() => CredentialsActions.set(key, map()));
  },

  onGetSettings() {
    const key = ['credentials', 'settings'];
    const cached = !this.state.getIn(key).isEmpty();

    if (!cached) {
      Api.get(`${CredentialsUrl}/subscription/settings`, {}, Status)
        .then(res => (res.ok ? res.data : defaultSettings))
        .then(res => ({
          subscriptions: res.subscriptions.map(v => ({
            ...v,
            hash_response: v.hash_response || [],
          })),
        }))
        .then(res => CredentialsActions.set(key, fromJS(res)))
        .catch(() => CredentialsActions.set(key, fromJS(defaultSettings)));
    }
  },

  onSaveSettings(settings) {
    const key = ['credentials', 'settings'];
    Api.put(`${CredentialsUrl}/subscription/settings`, settings, Status)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
      .then(() => CredentialsActions.set(key, fromJS(settings)))
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsSaveFailed));
  },

  onTestSettings(type) {
    Api.post(`${CredentialsUrl}/subscription/test`, { event_type: type }, Status)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.TestEventSent))
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.WebhookFailure));
  },

  onUploadCustomerFile(file) {
    const key = ['credentials', 'customerReplaceUrl'];
    const data = new Blob([file], { type: 'text/csv' });
    // set no timeout for large file uploads
    Api.put(`${this.state.getIn(key)}`, data, Status, 0, { 'Content-Type': 'text/csv' })
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.FileBeingProcessed))
      .then(() => CredentialsActions.set(key, ''))
      .catch(() => CredentialsActions.set(key, ''))
      .finally(() => CredentialsActions.getCustomerReplaceUrl());
  },

  onParseFilters(filters) {
    CredentialsActions.set(['metaData', 'filters'], filters.toJS());
  },

  onSet(k, v) {
    this.state = Array.isArray(k) ? this.state.setIn(k, v) : this.state.set(k, v);
    this.trigger(this.state.toObject());
  },
});

export default CredentialsStore;
