import React from 'react';
import Reflux from 'reflux';

import _ from 'lodash';
import chunk from 'lodash/chunk';
import findKey from 'lodash/findKey';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  fromJS,
  List as list,
  Map as map,
  Set as set,
} from 'immutable';

import SearchActions from '../actions/searchActions';
import Text from '../utils/text';
import Api from '../utils/api';
import Token from '../utils/token';
import Common from '../utils/common';
import History from '../utils/history';
import Iso from '../constants/Iso';
import Resources from '../constants/Resources';
import Tags, { oldTags } from '../constants/Tags';
import SiteTags from '../constants/SiteTags';
import SearchFields from '../constants/SearchFields';
import Dates from '../constants/Dates';
import Messages from '../constants/Messages';
import SearchUtils from '../utils/searchUtils';
import Autocomplete from '../constants/Autocomplete';
import HistogramConstants from '../constants/Histogram';
import { knownHashes } from '../constants/org_profiles/Edm';
import { SafeSearchTypes } from '../constants/Media';

const TranslateUrl = '/cloud/fpt-translate';
export const SearchUrl = '/ui/v4/all/search';
export const ReportsUrl = '/ui/v4/reports';
const UserPrefsUrl = `/ui/v4/documents${'_self' in React.createElement('div') ? '/dev' : ''}/user_preferences`;
const {
  addExactKeyword,
  escapeExactFilter,
  escapeFiltersList,
  computeSitesQueryConditions,
  computeAuthorsExcludeQueryConditions,
} = SearchUtils;

const BasetypeMapping = Common.Basetypes.SearchTypesToBasetypesQuery;
const cleanAggregationQuery = (query) => {
  const newQuery = _.clone(query);
  if (newQuery.aggregations
    && Object.keys(newQuery.aggregations).length > 0) {
    newQuery.size = 1;
    newQuery.highlight = false;
    delete newQuery.highlight_size;
    delete newQuery._source_includes;
    return newQuery;
  }
  return {};
};

// Tests if a query includes a credential
const hasCreds = query => (/credential-sighting|credential|credentials/.test(query));

// Returns a proper date query for an all-communities search that includes credentials
const buildCommunitiesDate = (filters) => {
  if (hasCreds(filters.all)) {
    return !filters.since
      ? '+(|sort_date:[* TO now] |breach.first_observed_at.date-time:[* TO now] |breach.created_at.date-time:[* TO now])'
      : `+(|sort_date:[${filters.since} TO ${filters.until || 'now'}] |breach.first_observed_at.date-time:[${filters.since} TO ${filters.until || 'now'}] |breach.created_at.date-time:[${filters.since} TO ${filters.until || 'now'}])`;
  }
  return !filters.since ? '+sort_date:[* TO now]' : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`;
};

// Reorders query so credential basetype is always last
const fixCredsBasetypeOrder = (filters, excludeFilters) => {
  const basetypes = filters.all ? filters.all.split(',') : Common.Basetypes.CommunitySearchTypes();
  let fixed = basetypes;
  if (basetypes.includes('credentials')) {
    fixed = basetypes.filter(v => v !== 'credentials');
    fixed.push('credentials');
  }
  return `${!excludeFilters.includes('all') ? '+' : '-'}basetypes:(${BasetypeMapping(fixed)})`;
};

const DefaultFilters = {
  chats: {
    group: 'messages',
  },
  iocs: {
    group: 'event',
  },
};

export const makeReportsQueryObj = ({ limit, skip, sort, tags, type, query, remove_styles }) => (
  { limit, skip, sort, tags, type, query, remove_styles });

const determineInterval = (filters, type) => {
  const { pathname, query } = History.getCurrentLocation();
  const {
    sinceDate,
    untilDate,
  } = SearchUtils.parseDate(filters);
  let interval = 'quarter';
  let hourDiff = null;

  if (filters.interval && /\d/.test(filters?.since)) {
    interval = filters?.interval;
  } else if (filters.since) {
    const since = moment.utc(sinceDate);
    const until = moment.utc(untilDate);
    hourDiff = Math.abs(since.diff(until, 'hours'));
  } else {
    // use the maximum histogram date for the type to determine which interval
    const typeDate = HistogramConstants.beforeDates[String(type)] || null;
    if (typeDate) {
      const since = typeDate;
      const until = moment.utc();
      hourDiff = Math.abs(since.diff(until, 'hours'));
    }
  }

  if (hourDiff) {
    switch (true) {
      case (hourDiff <= 24):
        interval = '30m';
        break;
      case (hourDiff <= 48):
        interval = '1h';
        break;
      case (hourDiff <= 168): // 7 days
        interval = '3h';
        break;
      case (hourDiff <= 744): // 31 days
        interval = '12h';
        break;
      case (hourDiff <= 1449): // 60 days
        interval = 'day';
        break;
      case (hourDiff <= 2160): // 90 days
        interval = 'week';
        break;
      case (hourDiff <= 8760): // 1 year
        interval = 'week';
        break;
      case (hourDiff <= 26280): // 3 years
        interval = 'month';
        break;
      case (hourDiff <= 87600): // 10 years
        interval = 'month';
        break;
      default:
        break;
    }
  }

  // platform-2566 prevent small interval on all-time searches
  // confirm interval is valid for specified range
  if (filters.interval && filters.interval !== interval) {
    SearchActions.set(['search', 'filters', 'interval'], interval, false);
    History.update({ pathname, query: { ...query, interval } });
  }

  return interval;
};

const SearchStore = Reflux.createStore({
  listenables: [SearchActions],

  init() {
    this.state = fromJS({
      search: {
        activeSearches: 0,
        exportAdditionalData: false,
        type: '',
        categories: [],
        forum_ids: [],
        properties: {},
        charts: {},
        dates: Dates,
        defaults: DefaultFilters,
        filters: {},
        filters_inline: {},
        limits: SearchFields.Limits,
        sorts: SearchFields.Sorts,
        sites: {},
        groups: SearchFields.Groups,
        query: {},
        viewed: [],
        info: {},
        prefs: {},
        terms: {},
        result: {},
        results: {},
        resources: Resources,
        tags: Tags,
        trueTotal: 0,
        org_profile: {},
      },
    });
  },

  performAggregations(query, type, filters) {
    const withAggregations = cleanAggregationQuery(query);
    if (this.state.getIn(['search', 'results', type, 'export'])) return;

    if (withAggregations.aggregations
      && Object.keys(withAggregations.aggregations).length > 0) {
      SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
      Api.post(`${SearchUrl}`, withAggregations, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : SearchActions.set(['search', 'charts', type], map())))
        .then((res) => {
          const charts = fromJS({ aggregations: res.aggregations, filters });
          const mergedAggregations = this.state.getIn(['search', 'charts', type, 'aggregations'], map()).merge(charts.get('aggregations'));
          SearchActions.set(['search', 'charts', type], charts.set('aggregations', mergedAggregations));
        })
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            SearchActions.set(['search', 'charts', type], fromJS({ aggregations: { status: err.code } }));
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.AggregationsError,
              action: 'Retry',
              fn: () => SearchActions.search(type, '', false, { ...filters, loadAggregations: true }),
            }));
          }
          return err;
        })
        .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
    }
  },

  onText(title = '', body = '') {
    if (!body) return;
    const placeholder = document.createElement('div');
    placeholder.innerHTML = `${title}\n\n${body}`;
    const content = placeholder.textContent || placeholder.innerText || '';
    const blob = new Blob([`\ufeff${content}`], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, title);
  },

  onUserPreferences(uid = '') {
    if (!uid) return;
    Api.get(`${UserPrefsUrl}/${uid}`, null, [200, 400, 500, 501, 502, 503, 504], null, null, true)
      .then(res => (res.ok ? res.data : {}))
      .then(res => SearchActions.set(['search', 'admin', 'user'], fromJS(res)))
      .catch(() => SearchActions.set(['search', 'admin', 'user'], fromJS({ invalid: true })));
  },

  onSearch(type = '', id = '', exporting = false, query = {}) {
    const prm = Token.get('prm');
    const { hash } = History.getCurrentLocation();
    const defaults = {
      ...DefaultFilters,
      images: {
        safe_search: this.state.getIn(['search', 'prefs', 'safe_search_ocr']) || SafeSearchTypes.MODERATE,
      },
      videos: {
        safe_search: this.state.getIn(['search', 'prefs', 'safe_search_ocr']) || SafeSearchTypes.MODERATE,
      },
      media: {
        safe_search: this.state.getIn(['search', 'prefs', 'safe_search_ocr']) || SafeSearchTypes.MODERATE,
      },
      credentials: {
        export_host_data: this.state.getIn(['search', 'exportAdditionalData']),
      },
    };
    this.state = this.state.setIn(['search', 'defaults'], fromJS(defaults));
    const prefs = this.state.getIn(['search', 'prefs']);
    const isCompactView = prefs.get('compact') && ['channels'].includes(type);
    const empty = () => (k, status) => SearchActions.set(k, fromJS({ total: 0, data: [], status }));
    const error = () => SearchActions.search(type, id);
    let requestHasBeenCanceled = false;

    const key = ['search', ...[id ? 'result' : 'results'], ...[exporting && 'export']].filter(v => v);
    const filters = { ...SearchUtils.loadFilters(type, query, exporting, defaults)[0] };
    filters.query = (filters.query || '').trim();
    const { all, subField = 'fpid', loadAggregations = false, ...rest } = filters;
    const { since, until, sinceDate, untilDate } = SearchUtils.parseDate(filters);
    const r = { ...rest, ...(type === 'communities' ? { all } : {}) };
    const edmProfile = filters.meets_org_profile_password_complexity ? this.state.getIn(['search', 'orgProfile']) : fromJS({});
    // remove since and until since this is calculated at time of request so will
    // be different when getting aggregation details
    delete r.since;
    delete r.until;
    delete r.interval;
    const interval = determineInterval(filters, type);

    const skip = filters.skip || 0;
    let limit = filters.limit || (isCompactView ? 100 : 25);

    const lookup = JSON.stringify(filters);
    // remove since and until since this is calculated at time of request so will
    // be different when getting aggregation details
    delete lookup.since;
    delete lookup.until;
    delete lookup.interval;

    const scroll = '2m';
    const highlightEnabled = !exporting;
    // 0 and -1 no longer supported in highlight_size. use largest size available in ES
    const highlightSize = !id ? 250 : 100000;
    const excludingHistogramFiltersLookup = JSON.stringify(r);
    const validOrQuery = !/(\|)(\*|''|""|\s?\)|$)/gmi.test(filters.query);
    const validWildcardQuery = !/(^|\W)\*\w/gm.test(filters.query);
    const validLengthQuery = (filters.query || '').length < 3000;
    const validBalancedString = Text.BalancedString(filters.query);
    const validEnrichmntsQuery = (filters.bins || '').split(',').length < 500 && (filters.cves || '').split(',').length < 500;
    const previousLookupObj = JSON.parse(this.state.getIn([...key, type, 'lookup']) || '{}');
    const currentLookupObj = JSON.parse(lookup || '{}');
    const excludingHistogramFiltersLookupObj = JSON.parse(excludingHistogramFiltersLookup || '{}');
    const cached = validWildcardQuery && validLengthQuery && !id && ['communities', 'all'].includes(type) && _.isEqual(previousLookupObj, currentLookupObj);
    const chatTypes = ['discord', 'element', 'qq', 'telegram', 'rocketchat'];

    let histogramChanged = !exporting && !cached && !id && (!all || (all && type === 'communities')) && type !== 'reports' && _.isEqual(previousLookupObj, excludingHistogramFiltersLookupObj);
    // want to reload the aggregations if the histogram has changed or explicitly
    // trying to load aggregations
    histogramChanged = histogramChanged || loadAggregations;

    // Check to see if only the limit or skip has changed since the last search
    // if those are only thing that has changed, do not run search with date
    // histogram or pie chart aggregations
    let ignoreAggregations = false;

    const hasAggregations = this.state.hasIn(['search', 'charts', type]) && !this.state.getIn(['search', 'charts', type]).isEmpty();
    const previousLookupObjCopy = { ...previousLookupObj };
    const currentLookupObjCopy = { ...currentLookupObj };
    delete previousLookupObjCopy.skip;
    delete previousLookupObjCopy.limit;
    delete previousLookupObjCopy.since;
    delete previousLookupObjCopy.until;
    delete currentLookupObjCopy.skip;
    delete currentLookupObjCopy.limit;
    delete currentLookupObjCopy.since;
    delete currentLookupObjCopy.until;
    ignoreAggregations = hasAggregations && _.isEqual(previousLookupObjCopy, currentLookupObjCopy);

    // ignore aggregations fully if the user's preferences say to unless the
    // user has already gotten the aggregations from a previous search, indicating
    // they want to see the aggregations
    ignoreAggregations = !loadAggregations && (ignoreAggregations || (!hasAggregations && !this.state.getIn(['search', 'prefs', 'search_analytics'])));

    // If only the interval has changed, we don't need to reload the hits result
    if (histogramChanged) limit = 1;


    // Update report tags based on user access
    const tags = [...new Set([
      ...filters?.tags
        ? filters?.tags?.split(',')?.map(v => `${filters?.tags_cond ? '+' : ''}${v.toUpperCase()?.replace(/[\s_"]/ig, ' ')}`)
        : [],
      ...filters?.types
        ? filters?.types?.split(',')?.map(v => v.toUpperCase()?.replace(/[\s_"]/ig, ' '))
        : [],
      ...filters?.types
        ? filters?.types?.split(',')?.map(v => v.replace(/Technical Intelligence/ig, 'EXPLOITS & VULNERABILITIES,INDICATORS OF COMPROMISE, MALWARE'))
        : [],
    ])]
    // redact vuln reports based on user perms
    .concat(!prm.some(p => /(cve|vln).r/.test(p)) ? ['-VULNERABILITY'] : [])
    .join();

    if (!validWildcardQuery) {
      SearchActions.set(['search', 'info', 'error'], 'Query terms beginning with wildcards are no longer supported. Please update your query');
      SearchActions.set([...key, ...type.split('.')], fromJS({ data: [], total: 0 }));
      return;
    }

    if (!validOrQuery) {
      SearchActions.set(['search', 'info', 'error'], 'Queries containing empty OR statements are no longer supported. Please update your query');
      SearchActions.set([...key, ...type.split('.')], fromJS({ data: [], total: 0 }));
      return;
    }

    if (!validEnrichmntsQuery) {
      SearchActions.set(['search', 'info', 'error'], 'Enrichment queries are limited to 500 tokens. Please update your query');
      SearchActions.set([...key, ...type.split('.')], fromJS({ data: [], total: 0 }));
      return;
    }

    if (validBalancedString !== true) {
      SearchActions.set(['search', 'info', 'error'], `Malformed query with ${validBalancedString}. Please update your query`);
    }

    let bins = null;
    if (filters.bin) {
      bins = [];
      const split = filters.bin.split(/,| OR /);
      split.forEach((v) => {
        if (v.indexOf('-') !== -1) {
          const range = v.split('-').map(k => k.trim());
          if (range.length > 2 || Number.isNaN(range[0]) || Number.isNaN(range[1])) {
            // Not a valid range, add to list
            bins.push(v);
          } else {
            // Set the range of bins in format XXX TO XYY
            const [start, end] = range.map(m => parseInt(m, 10));
            bins.push(`[${start} TO ${end}]`);
          }
        } else {
          // No range, add to list
          bins.push(v);
        }
      });
      bins = bins.join(' OR ');
    }
    const customerIdFilter = (filters.customer_id)
      ? Text.SFIDConversion(filters.customer_id)
      : null;
    const excludeFilters = Object.keys(filters)
      .map(v => (/_?exclude_?/ig.test(v) && filters[String(v)] ? v.replace(/_?exclude_?/, '') : ''))
      .filter(v => v);
    const exactFilters = Object.keys(filters)
      .map(v => (/_?exact_?/ig.test(v) && filters[String(v)] ? v.replace(/_?exact_?/, '') : ''))
      .filter(v => v);

    let serverFilter = '';
    if (filters.server && filters.server.includes('::')) {
      serverFilter = `+(|container.container.fpid:("${filters.server.split('::').shift()}") |container.server.fpid:("${filters.server.split('::').shift()}") |server.fpid:("${filters.server.split('::').shift()}"))`;
    } else if (filters.server) {
      serverFilter = `+(|container.container.name.keyword:("${filters.server}") |container.server.name.keyword:("${filters.server}") |server.name.keyword:("${filters.server}"))`;
    }
    let channelFilter = '';
    if (filters.channel) {
      channelFilter = filters.channel.includes('::')
        ? `${!excludeFilters.includes('channel') ? '+' : '-'}(|container.fpid:(${filters.channel.split('::').shift()}) |channel.fpid:(${filters.channel.split('::').shift()}))`
        : `${!excludeFilters.includes('channel') ? '+' : '-'}(|container.name${addExactKeyword('channel', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.channel })}) |container.username${addExactKeyword('channel', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.channel })}))`;
    } else if (filters.server) {
      channelFilter = `+(|container.name:(${filters.channel}) |channel.name:(${filters.channel}))`;
    }
    let mediaType = '';
    if (filters.media_type) {
      switch (filters.media_type) {
        case 'Application':
          mediaType = 'application/*';
          break;
        case 'Image':
          mediaType = 'image/*';
          break;
        case 'Video':
          mediaType = 'video/*';
          break;
        default: break;
      }
    }
    let hashes = [];
    let hashTypesQuery = '';
    if (filters.hash_types) {
      if (filters.hash_types.split(',').includes('Plaintext')) {
        hashes = filters.hash_types.split(',').filter(v => v !== 'Plaintext');
        hashTypesQuery = (hashes.length > 0)
          ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.filter(v => !hashes.includes(v)).map(v => `"${v}"`).join(' | ')})`
          : `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})`;
      } else {
        hashes = filters.hash_types.split(',');
        hashTypesQuery = `+password_complexity.probable_hash_algorithms.keyword:(${hashes.map(v => `"${v}"`).join(' | ')})`;
      }
    }

    const highlightExcludedFields = [
      'type',
      'value',
      'basetypes',
      'fpid',
      'sort_date',
      'breach',
      'domain',
      'affected_domain',
      'email',
      'password',
      'username',
      'is_fresh',
      'credential_record_fpid',
      'password_complexity',
      'ransomer.fpid',
      'ransomer.names',
      'container.fpid',
      'container.name',
      'container.title',
      'container.type',
      'container.native_id',
      'container.container.native_id',
      'container.container.title',
      'body.text/plain',
      'account_organization',
      'infected_host_attributes',
      'cookies',
      'email_domain',
      'prices',
      'site.title',
      'site.source_uri',
      'site_actor.native_id',
      'site_actor.names',
      'site_actor.username',
      'raw_href',
      'title',
      'base.title',
      'card_type',
      'bin',
      'nist',
      'mitre',
      'source_uri',
      'Event.date',
      'Event.uuid',
      'Event.Attribute',
      'Event.Tag',
      'Event.attribute_count',
      'Event.RelatedEvent',
      'attack_ids',
      'category',
      'geolocation',
      'media.caption',
      'media.file_name',
      'media.md5',
      'media.fpid',
      'media.media_type',
      'media.mime_type',
      'media.phash',
      'media.sha1',
      'media.size',
      'media.storage_uri',
      'media.image_enrichment.enrichments.v1.image-analysis',
      'media.video_enrichment.enrichments.v1.video-analysis',
      'enrichments.card-numbers.card-numbers.bin',
      'enrichments.v1.ip_addresses.ip_address',
      'enrichments.v1.email_addresses.email_address',
      'enrichments.v1.urls.domain',
      'enrichments.v1.monero_addresses.monero_address',
      'enrichments.v1.ethereum_addresses.ethereum_address',
      'enrichments.v1.bitcoin_addresses.bitcoin_address',
      'enrichments.v1.social_media.handle',
      'enrichments.v1.social_media.site',
      ...(prefs?.get('enable_pii') === true) ? [
        'card_type',
        'prices',
        'payment_method',
        'expiration',
        'last4',
        'track_information',
        'cardholder_information.first',
        'cardholder_information.last',
        'cardholder_information.location.country',
        'cardholder_information.location.city',
        'cardholder_information.location.region.raw',
        'cardholder_information.location.zip_code',
      ] : [],
      ...(prm.some(p => /(vln|cve).r/.test(p)) ? [
        'cve.nist',
        'cve.mitre',
        'cve.title',
        'enrichments.v1.vulnerability.cve.vulnerability',
      ] : []),
    ];

    const enrichmentsQuery = [
      !id && filters.required_enrichments
        ? `${filters.enrichments_cond !== 'true' ? '+(' : ''}${filters.required_enrichments
          .split(',')
          .map(v => v.split('::').slice(0, -1))
          .map(v => `_exists_:enrichments.${v}`)
          .join(filters.enrichments_cond === 'true' ? ' +' : '|')}${filters.enrichments_cond !== 'true' ? ')' : ''}`
        : null,
      !id && filters.cves
        ? `+enrichments.v1.vulnerability.cve.vulnerability.keyword:(${filters.cves
          .split(',')
          .map(v => `"${escapeExactFilter(v)}"`)
          .join(' OR ')})`
        : null,
      !id && filters.bins
        ? `+enrichments.card-numbers.card-numbers.bin:(${filters.bins
          .split(',')
          .map(v => `"${v}"`)
          .join(' OR ')})`
        : null,
      !id && filters.ips
        ? `+enrichments.v1.ip_addresses.ip_address:(${filters.ips
          .split(',')
          .map(v => `"${v}"`)
          .join(' OR ')})`
        : null,
      !id && filters.emails
        ? `+enrichments.v1.email_addresses.email_address${filters
          .email_exact ? '.keyword' : ''}:(${filters.emails.split(',').map(v => `"${v}"`).join(' OR ')})`
        : null,
      !id && filters.domains
        ? `+(${filters.domains
          .split(',')
          .map(v => `|enrichments.v1.urls.domain:"${v}"`)
          .join(' ')})`
        : null,
      !id && filters['monero-wallets']
        ? `+(${filters['monero-wallets']
          .split(',')
          .map(v => `|enrichments.v1.monero_addresses.monero_address:${v}`)
          .join(' ')})`
        : null,
      !id && filters['ethereum-wallets']
        ? `+(${filters['ethereum-wallets']
          .split(',')
          .map(v => `|enrichments.v1.ethereum_addresses.ethereum_address:${v}`)
          .join(' ')})`
        : null,
      !id && filters['bitcoin-wallets']
        ? `+(${filters['bitcoin-wallets']
          .split(',')
          .map(v => `|enrichments.v1.bitcoin_addresses.bitcoin_address:${v}`)
          .join(' ')})`
        : null,
      !id && filters.handles
        ? `+(${filters.handles
          .split(',')
          .map(v => `|enrichments.v1.social_media.handle:${v}`)
          .join(' ')})`
        : null,
      !id && filters.profiles
        ? `+(${filters.profiles
          .split(',')
          .map(v => `|enrichments.v1.social_media.site:${v}`)
          .join(' ')})`
        : null,
    ];

    const reportQuery = {
      ...filters,
      tags,
      all: null,
      remove_styles: !!exporting,
      fields: filters.fields || SearchFields.Includes.reports.join(),
      highlight: highlightEnabled,
      highlight_size: highlightSize,
      highlight_body: filters.body || filters.query,
      highlight_title: filters.title || filters.query,
      traditional_query: true,
      report_ids: id || undefined,
      body: filters.body || '',
      title: filters.title || '',
      query: filters.query || '',
      // if no query provided use date in place of relevancy
      sort: [filters?.query, filters?.body, filters?.title].every(v => !v)
        ? SearchFields?.Sorts?.reports?.[1]?.value
        : `${filters?.sort ? filters?.sort : SearchFields?.Sorts?.reports?.[0]?.value}`,
      skip: id ? 0 : +skip,
      limit: id ? 1 : +limit,
      ...sinceDate ? { since: sinceDate } : {},
      ...untilDate ? { until: untilDate } : {},
    };

    // used to map looker filters to search api filters when pivoting from looker dash to credentials search
    /* eslint-disable newline-per-chained-call */
     const lookerDateFilters = [
      { date: 'Today', since: `${moment.utc().startOf('day').toISOString()}`, until: 'now' },
      { date: 'Yesterday', since: `${moment.utc().subtract(1, 'days').startOf('day').toISOString()}`, until: `${moment.utc().subtract(1, 'days').endOf('day').toISOString()}` },
      { date: 'Last 14 Days', since: 'now-14d', until: 'now' },
      { date: 'Last 28 Days', since: 'now-28d', until: 'now' },
      { date: 'Last 180 Days', since: 'now-180d', until: 'now' },
      { date: 'Last 365 Days', since: 'now-365d', until: 'now' },
      { date: 'This Week', since: `${moment.utc().startOf('week').add(1, 'day').toISOString()}`, until: 'now' },
      { date: 'This Month', since: `${moment.utc().startOf('month').toISOString()}`, until: 'now' },
      { date: 'This Quarter', since: `${moment.utc().startOf('quarter').toISOString()}`, until: 'now' },
      { date: 'This Year', since: `${moment.utc().startOf('year').toISOString()}`, until: 'now' },
      { date: 'Previous Week', since: `${moment.utc().subtract(1, 'week').startOf('week').add(1, 'day').toISOString()}`, until: `${moment.utc().subtract(1, 'week').endOf('week').add(1, 'day').toISOString()}` },
      { date: 'Previous Month', since: 'now-1M/M', until: 'now-1M/M' },
      { date: 'Previous Quarter', since: `${moment.utc().subtract(1, 'quarter').startOf('quarter').toISOString()}`, until: `${moment.utc().subtract(1, 'quarter').endOf('quarter').toISOString()}` },
      { date: 'Previous Year', since: `${moment.utc().subtract(1, 'years').startOf('year').toISOString()}`, until: `${moment.utc().subtract(1, 'years').endOf('year').toISOString()}` },
      { date: 'Year To Date', since: `${moment.utc().startOf('year').toISOString()}`, until: 'now' },
    ];

    const credentialDate = {
      since: (() => {
        if (filters.password_reset_policy && (filters.password_reset_policy !== null)) {
          return since === '*' || moment.utc().subtract(parseInt(filters.password_reset_policy, 10), 'days').isAfter(moment.utc(sinceDate))
            ? `now-${filters.password_reset_policy}d`
            : since;
        } else if (filters.date) {
          // looker sends filter in format of 'YYYY-MM-DD - YYYY-MM-DD', need to split on '-' and take first value for since date
          const sinceDateTime = filters.date.split(' - ') && moment(filters.date.split(' - ')[0]).utc().startOf('day').toISOString();
          return lookerDateFilters.find(v => v.date === filters.date)?.since
            || sinceDateTime
            || since;
        } else {
          return since;
        }
      }),
      until: (() => {
        if (filters.date) {
          const untilDateTime = filters.date.split(' - ') && moment(filters.date.split(' - ')[1]).utc().endOf('day').toISOString();
          return lookerDateFilters.find(v => v.date === filters.date)?.until
            || untilDateTime
            || until;
        } else {
          return until;
        }
      }),
    };

    let optionalQuery = null;
    if (filters.optional && filters.num_optional) {
      const optional = filters.optional.split(',');
      const numRequired = parseInt(filters.num_optional, 10);
      const indicies = [...Array(numRequired).keys()];
      switch (numRequired) {
        case 1:
          optionalQuery = `+(${optional.map(v => `|password_complexity.has_${v}:(true)`).join(' ')})`;
          break;
        default:
          optionalQuery = `+(${_.combinations(optional, numRequired).map(v => `|(${indicies.map(i => `+password_complexity.has_${v[Number(i)]}:(true)`).join(' ')})`).join(' ')})`;
          break;
      }
    }


    /**
    * @param htmlString is already sanitized because it comes from the text/html+sanitized
    * attribute of the report context
    */
    const getStrippedInnerText = (htmlString) => {
      const tempDivElement = document.createElement('div');
      // Hide the new temporary div
      tempDivElement.style = { visibility: 'hidden', opacity: 0, position: 'absolute', left: 0, top: 0, width: 1, height: 1 };
      tempDivElement.innerHTML = htmlString;
      // innerText can only intuit how the div is rendered if you add it to the document
      document.body.appendChild(tempDivElement);
      const tempInnerText = tempDivElement.innerText;
      // Run the extra sanitization steps just in case
      let strippedInnerText = Text.StripHtml(Text.ReportCard(Text.StripCss(tempInnerText)));
      // Replace all single \n with double \n\n for readability
      strippedInnerText = strippedInnerText.replace(/[^\n]\n[^\n]/g, match => match.replace(/\n/g, '\n\n'));

      tempDivElement.remove();
      return strippedInnerText;
    };


    const forumsQuery = fromJS(SearchUtils.buildQuery('forums', id, filters))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('from', id ? null : +skip)
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.forums)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
          thread: { terms: { field: 'container.title.keyword', size: 5 } },
        },
      })
      .update('fields', v => v.concat(filters?.query_i18n ? ['enrichments.v1.translation.*'] : []))
      .update('query', v => v.replace(`+container.container.title:(${filters.room_title})`, `+container.container.title:${encodeURIComponent(filters.room_title)})`))
      .filter(v => v?.toString()?.length)
      .toJS();
    const threadQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['forums'])})`,
        `+(|container.fpid:"${id}" |container.legacy_fpid:"${id}")`,
        filters.id && !exporting ? `+sort_date:[${moment.utc(filters.id * 1000).format()} TO *]` : '',
        !filters.id && filters.fpid && !exporting ? `+(|fpid:"${filters.fpid}" |legacy_fpid:"${filters.fpid}")` : '',
        exporting ? '+sort_date:[* TO now]' : '',
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.forums)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const threadInlineQuery = fromJS(forumsQuery)
      .filter(v => v)
      .update('query', v => v
        .replace(`+fpid:"${id}"`, `+(|container.fpid:"${id}" |container.legacy_fpid:"${id}")`)
        .replace(/\+sort_date:\[.*\]/ig, ' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.forums)
      .set('sort', ['sort_date:desc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const threadMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['forums'])})`,
        `+(|container.fpid:"${id}" |container.legacy_fpid:"${id}")`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('traditional_query', true)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.forums)
      .set('sort', ['sort_date:asc'])
      .set('size', 1)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const forumPostCountQuery = forumFpid => map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['forums'])})`,
        `+site.fpid:(${forumFpid})`,
      ].filter(v => v).join(' '))
      .set('size', 1)
      .filter(v => v)
      .toJS();
    const accountsQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['accounts'])})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `${!id ? `+(${filters.query})` : ''}` : null,
        filters.account_organization ? `+account_organization:("${filters.account_organization.replace(/"/g, '')}")` : null,
        filters.email_domain ? `+email_domain:("${filters.email_domain.replace(/"/g, '')}")` : null,
        filters.bank_account_type ? `+bank_accounts.account_type.keyword:(${filters.bank_account_type})` : null,
        filters.bank_name ? `+bank_accounts.bank_name:(${filters.bank_name})` : null,
        filters.has_bank_account ? `+bank_accounts.has_bank_account:(${filters.has_bank_account})` : null,
        (filters.seller || filters.author) ? `${(filters.seller || filters.author).includes(':')
          ? `${!excludeFilters.includes('seller') && !excludeFilters.includes('author') ? '+' : '-'}site_actor.fpid:"${(filters.seller || filters.author).split(':').shift()}"`
          : `${!excludeFilters.includes('seller') && !excludeFilters.includes('author') ? '+' : '-'}site_actor.names.handle${addExactKeyword('author', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.seller || filters.author })})`}` : null,
        // filters.sites ? `+site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.accounts)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const credentialsQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['credentials'])})`,
        `+breach.${filters.alert_date_type && filters.alert_date_type === 'breached_at'
          ? 'created_at'
          : 'first_observed_at'}.date-time:[${credentialDate.since()} TO ${credentialDate.until()}]`,
        id ? `${id.includes('::') ? `+credential_record_fpid:("${id.split('::')[0]}")` : `+fpid:("${id}")`}` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.domain ? `${filters.exclude_domain ? '-' : '+'}domain:(${filters.domain.split(',').map(v => `"${v}"`).join(' OR ')})` : null,
        filters.affected_domain ? `${filters.exclude_affected_domain ? '-' : '+'}affected_domain:(${filters.affected_domain.split(',').map(v => `"${v}"`).join(' OR ')})` : null,
        filters.email ? `+email:("${filters.email}")` : null,
        filters.username ? `+username:("${filters.username}")` : null,
        filters.password ? `+password:(${filters.password})` : null,
        filters.hash_types ? hashTypesQuery : null,
        filters.customer_id ? `+customer_id:("${customerIdFilter['15']}" | "${customerIdFilter['18']}")` : null,
        filters.source_type ? `+breach.source_type:("${filters.source_type}")` : null,
        filters.is_fresh === 'true' ? '+is_fresh:(true)' : null,
        !edmProfile?.isEmpty() && filters.ignore_hashes === 'true' ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})` : null,
        !edmProfile?.isEmpty() && filters.length ? `+password_complexity.length:[${filters.length} TO *]` : null,
        !edmProfile?.isEmpty() && filters.required ? filters.required.split(',').map(v => `+password_complexity.has_${v}:(true)`).join(' ') : null,
        !edmProfile?.isEmpty() && filters.excluded ? filters.excluded.split(',').map(v => `-password_complexity.has_${v}:(true)`).join(' ') : null,
        !edmProfile?.isEmpty?.() ? optionalQuery : null,
        filters.title
          ? `${filters.title.includes('::')
            ? `+breach.fpid:"${filters.title.split('::').shift()}"`
            : `+breach.title${(filters.title_exact || filters.title_exact === 'true') && filters.title_exact !== 'false' ? `.keyword:("${filters.title}")` : `:("${filters.title}")`}`}`
          : null,
        filters.breached_since
          ? `+breach.created_at.date-time:[${filters.breached_since || '*'} TO ${filters.breached_until || 'now'}]`
          : `+breach.created_at.date-time:[* TO ${filters.breached_until || 'now'}]`,
        filters.host_data?.includes('cookies') ? '+_exists_:(cookies)' : null,
        filters.host_data?.includes('host_attributes') ? '+_exists_:(infected_host_attributes)' : null,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.credentials)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['breach.first_observed_at.timestamp:desc'])
      .set('size', id ? 100 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', {
        ...(id || exporting || ignoreAggregations || type === 'all'
          ? {
            'multiple-terms': {
              domain: { terms: { field: 'domain.keyword', size: 5000 } },
              affected_domain: { terms: { field: 'affected_domain.keyword', size: 5000 } },
            },
          }
          : {
            'date-histogram': {
              field: 'breach.first_observed_at.date-time',
              interval,
            },
            'multiple-terms': {
              breach_source: { terms: { field: 'breach.source_type.keyword', size: 5 } },
              domain: { terms: { field: 'domain.keyword', size: 5000 } },
              affected_domain: { terms: { field: 'affected_domain.keyword', size: 5000 } },
            },
          }),
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const marketplacesQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['marketplaces'])})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `${!id ? `+(${filters.query})` : ''}` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.title ? `+title:(${filters.title})` : null,
        filters.source_uri ? `+site.source_uri${filters.source_uri_exact ? '.keyword' : ''}:(${filters.source_uri})` : null,
        filters.description ? `+body.text/plain:(${filters.description})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.marketplaces)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        } })
      .filter(v => v?.toString()?.length)
      .toJS();
    const blogsQuery = map()
      .set('query', [
        `+basetypes:(+${BasetypeMapping(['blogs'])} ${filters.type ? `+${filters.type}` : ''})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `+(${id ? '' : filters.query})` : null,
        filters.title ? `+title:(${escapeFiltersList({ stringOfFilters: filters.title })})` : null,
        filters.raw_href ? `+raw_href:(${filters.raw_href})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .update('query', v => (id ? v.replace(/\+site_actor\.fpid:\(.*?\)/ig, '') : v))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.blogs)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const postsQuery = map()
      .set('query', [
        `+basetypes:(+${BasetypeMapping(['blogs'])} +comment)`,
        `+container.fpid:"${id}"`,
        filters.id && !exporting ? `+sort_date:[${moment.utc(filters.id * 1000).format()} TO now]` : '',
        exporting ? '+sort_date:[* TO now]' : '',
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.blogs)
      .set('_source_includes', SearchFields.InlineIncludes.blogs)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const postsInlineQuery = fromJS(blogsQuery)
      .filter(v => v)
      .update('query', v => v.replace('+blog', '+comment'))
      .update('query', v => v.replace('+fpid', '+container.fpid'))
      .update('query', v => v.replace(/\+sort_date:\[.*?\]/ig, ''))
      .set('aggregations', null)
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.blogs)
      .set('_source_includes', SearchFields.InlineIncludes.blogs)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const postsMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['blogs'])})`,
        `+fpid:"${id}"`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_query', filters.query ? `+${filters.query}` : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.blogs)
      .set('_source_includes', SearchFields.InlineIncludes.blogs)
      .set('aggregations', null)
      .set('sort', ['sort_date:asc'])
      .set('size', 1)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const ransomwareQuery = map()
      .set('query', [
        `+basetypes:(+${BasetypeMapping(['ransomware'])} ${filters.type ? `+${filters.type}` : ''})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `+(${id ? '' : filters.query})` : null,
        filters.title ? `+title:(${filters.title})` : null,
        filters.raw_href ? `+raw_href:(${filters.raw_href})` : null,
        filters.author
          ? `${filters.author.includes(':')
            ? `${!excludeFilters.includes('author') ? '+' : '-'}ransomer.fpid:"${filters.author.split(':').shift()}"`
            : `${!excludeFilters.includes('author') ? '+' : '-'}ransomer.names.handle${addExactKeyword('author', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.author })})`}`
          : null,
        filters.sites ? `+site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        filters.message ? `+body.text/plain:(${filters.message})` : null,
        filters.media_v2 ? `${filters.media_v2 === 'true' ? '+_exists_:media_v2 +_exists_:media_v2.storage_uri' : '-_exists_:media_v2'}` : null,
        filters.media_type && !filters.mime_type ? `+media_v2.mime_type.keyword:(${mediaType}) ${filters.media_type === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}` : null,
        filters.mime_type ? `+media_v2.mime_type:"${filters.mime_type}"` : null,
        filters.media_caption ? `+media_v2.title:(${filters.media_caption})` : null,
        filters.filename ? `+(media_v2.filename:("${filters.filename}") |media_v2.file_name:("${filters.filename}") |media_v2.storage_uri:("${filters.filename}") |media_v2.sha256:("${filters.filename.split('.')[0]}"))` : null,
        filters.sha1 ? `${!excludeFilters.includes('sha1') ? '+' : '-'}media_v2.sha1:(${filters.sha1})` : null,
        filters.phash ? `+media_v2.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})` : null,
        filters.language ? `+body.enrichments.language:(${Object.entries(Iso).find(v => v?.[1]?.name === filters.language)?.[0] || ''})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? !id && '+sort_date:[* TO now]'
          : !id && `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .update('query', v => (id ? v.replace(/\+ransomer\.fpid:\(.*?\)/ig, '') : v))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.ransomware)
      .set('_source_includes', id ? null : [...highlightExcludedFields, 'media_v2.fpid'])
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'ransomer.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const cardsQuery = map()
      .set('query', [
        `+basetypes:(${filters.card_category || `+${BasetypeMapping(['cards'])}`})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `${!id ? `+(${filters.query})` : ''}` : null,
        filters.bin ? `+bin:(${bins})` : null,
        filters.country ? `+(cardholder_information.location.country.raw:("${filters.country}" OR "${findKey(Autocomplete.country_codes, v => v === filters.country)}")` : null,
        filters.city ? `+cardholder_information.location.city.raw:(${filters.city})` : null,
        filters.zip_code ? `+cardholder_information.location.zip_code:(${filters.zip_code})` : null,
        filters.card_type ? `+card_type:(${filters.card_type})` : null,
        filters.expiration ? `${filters.expiration === 'true' ? '+' : '-'}_exists_:expiration` : null,
        filters.last4 ? `${filters.last4 === 'true' ? '+' : '-'}_exists_:last4` : null,
        filters.tr1 ? '+track_information:(TR1)' : null,
        filters.release_name ? `${(filters.release_name_exact || filters.release_name_exact === 'true') && filters.release_name_exact !== 'false' ? `+base.title.keyword:("${filters.release_name.replace(new RegExp('"', 'g'), '\\"')}")` : `+base.title:("${filters.release_name.replace(new RegExp('"', 'g'), '\\"')}")`}` : null,
        filters.payment_method ? `+payment_method:"(${filters.payment_method.replace('–', '')})"` : null,
        filters.bank_name ? `+bank_name:"(${filters.bank_name})"` : null,
        filters.is_verified_by_visa ? `+is_verified_by_visa:(${filters.is_verified_by_visa})` : null,
        filters.is_pin_available ? `+is_pin_available:(${filters.is_pin_available})` : null,
        filters.track_information ? `+track_information:"(${filters.track_information})"` : null,
        filters.service_code ? `+service_code:"${filters.service_code}"` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cards)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? [filters.sort] : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        ...(filters.aggregations
          ? filters.aggregations
          : {
            'date-histogram-cardinality': {
              field: 'sort_date',
              interval,
              sub_field: subField,
            },
          }),
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const boardsQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['boards'])})`,
        id ? `+fpid:"${id.split('|')[0]}"` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.body ? `+body.text/plain:(${filters.body})` : null,
        filters.site ? `+site.title:(${filters.site})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.board ? `${filters.exclude_board ? '-' : '+'}container.container.title:(${filters.board})` : null,
        filters.thread_id ? `+container.native_id:(${filters.thread_id})` : null,
        filters.sites ? `+site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        filters.message ? `+body.text/plain:(${filters.message})` : null,
        filters.media_v2 ? `${filters.media_v2 === 'true' ? '+_exists_:media_v2 +_exists_:media_v2.storage_uri +media_v2.size:[5000 TO *]' : '-_exists_:media_v2'}` : null,
        filters.media_type && !filters.mime_type ? `+media_v2.mime_type.keyword:(${mediaType}) ${filters.media_type === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}` : null,
        filters.mime_type ? `+media_v2.mime_type:"${filters.mime_type}"` : null,
        filters.media_caption ? `+media_v2.title:(${filters.media_caption})` : null,
        filters.filename ? `+(media_v2.filename:("${filters.filename}") |media_v2.file_name:("${filters.filename}") |media_v2.storage_uri:("${filters.filename}") |media_v2.sha256:("${filters.filename.split('.')[0]}"))` : null,
        filters.sha1 ? `${!excludeFilters.includes('sha1') ? '+' : '-'}media_v2.sha1:(${filters.sha1})` : null,
        filters.phash ? `+media_v2.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})` : null,
        filters.language ? `+body.enrichments.language:(${Object.entries(Iso).find(v => v?.[1]?.name === filters.language)?.[0] || ''})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.boards)
      .set('_source_includes', id ? null : [...highlightExcludedFields, 'media_v2.fpid'])
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const boardQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['boards'])})`,
        `+container.fpid:"${id}"`,
        filters.native_id ? `+native_id:("${filters.native_id}")` : null,
        filters.id && !filters.native_id && !exporting ? `+sort_date:[${moment.utc(filters.id * 1000).format()} TO now]` : '',
        exporting ? '+sort_date:[* TO now]' : '',
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.boards)
      .set('_source_includes', SearchFields.InlineIncludes.boards)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const boardInlineQuery = fromJS(boardsQuery)
      .filter(v => v)
      .update('query', v => v.replace('+fpid', '+container.fpid'))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.boards)
      .set('_source_includes', SearchFields.InlineIncludes.boards)
      .set('sort', ['sort_date:desc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const boardMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['boards'])})`,
        `+container.fpid:"${id}"`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.boards)
      .set('_source_includes', SearchFields.InlineIncludes.boards)
      .set('aggregations', null)
      .set('sort', ['sort_date:asc'])
      .set('size', 1)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const cvesQuery = map()
      .set('query', [
        `basetypes:(${BasetypeMapping(['cves.vulnerability'])})`,
        id ? `+fpid:"${id}"` : null,
        filters.query
          ? `+(${/".*?"/.test(filters.query) ? filters.query : `"${filters.query}"`})`
          : null,
        filters.cves
          ? `+title.keyword:(${escapeFiltersList({ stringOfFilters: filters.cves, whenFilterContainsStar: 'deleteStars' })})`
          : null,
        filters.cve_reporter
          ? `+_exists_:${filters.cve_reporter === 'nist' ? '(nist.cvssv2 OR nist.cvssv3)' : filters.cve_reporter}`
          : null,
        filters.cve_base_score_2
          ? `+nist.cvssv2.base_score:[${filters.cve_base_score_2.split(',')[0]} TO ${filters.cve_base_score_2.split(',')[1]}]`
          : null,
        filters.cve_base_score_3
          ? `+nist.cvssv3.base_score:[${filters.cve_base_score_3.split(',')[0]} TO ${filters.cve_base_score_3.split(',')[1]}]`
          : null,
        filters.cve_vulnerability_types
          ? `+nist.vulnerability_types.keyword:(${filters.cve_vulnerability_types})`
          : null,
        filters.cve_vendor_names || filters.cve_product_names
          ? `${filters.cve_vendor_names
            ? `+(|${filters.cve_vendor_names.split(',').map(v => `nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
            : ''}${filters.cve_product_names
            ? `(|${filters.cve_product_names.split(',').map(v => `nist.products.product_name:(${v})`).join(' |')})`
            : ''}`
          : null,
        filters.required_cve_tags
          ? filters.required_cve_tags
            .split(',')
            .map(v => `+_exists_:(${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.required_fields
          ? filters.required_fields
            .split(',')
            .map(v => `+_exists_:(${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.cve_severity
          ? `+nist.cvssv3.severity:(${filters.cve_severity})`
          : null,
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('from', id ? null : +skip)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null
        : { 'date-histogram': { field: 'sort_date', interval },
        })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date'])
      .set('size', +limit)
      .set('track_total_hits', 10000)
      .filter(v => v?.toString()?.length)
      .toJS();
    const cvesInlineQuery = map()
      .set('query', [
        `+_exists_:enrichments.v1.vulnerability.cve.vulnerability +enrichments.v1.vulnerability.cve.vulnerability.keyword:(${Text.StripHighlight(filters.cve_title || this.state.getIn([...key, 'cves', 'title']))})`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', SearchFields.InlineIncludes.cves)
      .set('sort', ['sort_date:desc'])
      .set('traditional_query', true)
      .set('from', query.skip ? +query.skip : +skip)
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const cvesMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['cves.exploit'])})`,
        id ? `cve.fpid:(${id})` : null,
        !id && filters.cve_title ? `title.keyword:(${filters.cve_title || this.state.getIn([...key, 'cves', 'title'])})` : null,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', SearchFields.InlineIncludes.cves)
      .set('sort', ['sort_date:desc'])
      .set('traditional_query', true)
      .set('from', query.skip ? +query.skip : +skip)
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const exploitsQuery = map()
      .set('query', [
        id ? `+fpid:"${id}"` : null,
        `+basetypes:(${BasetypeMapping(['cves.exploit'])})`,
        filters.query
          ? `+(${/".*?"/.test(filters.query) ? filters.query : `"${filters.query}"`})`
          : null,
        filters.cves
          ? `+title.keyword:(${escapeFiltersList({ stringOfFilters: filters.cves, whenFilterContainsStar: 'deleteStars' })})`
          : null,
        filters.cve_reporter
          ? `+_exists_:${filters.cve_reporter === 'nist' ? '(cve.nist.cvssv2 OR cve.nist.cvssv3)' : filters.cve_reporter}`
          : null,
        filters.cve_base_score_2
          ? `+cve.nist.cvssv2.base_score:[${filters.cve_base_score_2.split(',')[0]} TO ${filters.cve_base_score_2.split(',')[1]}]`
          : null,
        filters.cve_base_score_3
          ? `+cve.nist.cvssv3.base_score:[${filters.cve_base_score_3.split(',')[0]} TO ${filters.cve_base_score_3.split(',')[1]}]`
          : null,
        filters.cve_vulnerability_types
          ? `+cve.nist.vulnerability_types.keyword:(${filters.cve_vulnerability_types})`
          : null,
        filters.cve_vendor_names || filters.cve_product_names
          ? `${filters.cve_vendor_names
            ? `+(|${filters.cve_vendor_names.split(',').map(v => `cve.nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
            : ''}${filters.cve_product_names
            ? `(|${filters.cve_product_names.split(',').map(v => `cve.nist.products.product_name:(${v})`).join(' |')})`
            : ''}`
          : null,
        filters.required_cve_tags
          ? filters.required_cve_tags
            .split(',')
            .map(v => `+_exists_:(cve.${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.required_fields
          ? filters.required_fields
            .split(',')
            .map(v => `+_exists_:(cve.${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.cve_severity
          ? `+cve.nist.cvssv3.severity:(${filters.cve_severity})`
          : null,
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('from', id ? null : +skip)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.exploits)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null
        : { 'date-histogram': { field: 'sort_date', interval },
        })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', +limit)
      .set('track_total_hits', 10000)
      .filter(v => v?.toString()?.length)
      .toJS();
    const exploitsInlineQuery = map()
      .set('query', [
        `+_exists_:enrichments.v1.vulnerability.cve.vulnerability +enrichments.v1.vulnerability.cve.vulnerability.keyword:(${filters.cve_title || this.state.getIn([...key, 'exploits', 'title'])})`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', SearchFields.InlineIncludes.cves)
      .set('sort', ['sort_date:desc'])
      .set('traditional_query', true)
      .set('from', query.skip ? +query.skip : +skip)
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const exploitsMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['cves.exploit'])})`,
        id ? `cve.fpid:(${id})` : null,
        !id && filters.cve_title ? `title.keyword:(${filters.cve_title || this.state.getIn([...key, 'exploits', 'title'])})` : null,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', SearchFields.InlineIncludes.cves)
      .set('sort', ['sort_date:desc'])
      .set('traditional_query', true)
      .set('from', query.skip ? +query.skip : +skip)
      .set('size', 10)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const iocsQuery = map()
      .set('query', [
        'header_.is_visible:(true)',
        `+basetypes:(${BasetypeMapping(['iocs'])})`,
        id ? `+Event.uuid:"${id}"` : null,
        filters.uuid ? `+Event.uuid:"${filters.uuid}"` : null,
        filters.query ? `+(|${filters.query} |Event.Attribute.value:${filters.query})` : null,
        filters.event_type
          ? `+Event.Tag.name:("${filters.event_type.split('::').shift()}")`
          : null,
        filters.ioc_tags
          ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_tags, whenFilterContainsStar: 'preventEscape', customJoin: filters.ioc_tags_cond === 'true' ? ' +' : ' ,' })})` : null,
        filters.exclude_ioc_tags
          ? `-Event.Tag.name:(${(escapeFiltersList({ stringOfFilters: filters.exclude_ioc_tags, whenFilterContainsStar: 'preventEscape' }))})` : null,
        filters.required_ioc_tags
          ? filters.required_ioc_tags
            .split(',')
            .map(v => (v === 'attack_ids'
              ? '+_exists_:Event.attack_ids'
              : `+Event.Tag.name:(${v.split('::').shift()}*)`))
            .join(' ')
          : null,
        filters.attack_ids ? `+Event.attack_ids:(${filters.attack_ids.split(',').map(v => `"${v.split(':').shift()}"`).join()})` : null,
        filters.ioc_value
          ? `${filters.ioc_type
            ? `+(${filters.ioc_type.split(',').map(v => `|value.${v.replace('|', '\\|')}:(${filters.ioc_value.split(',').join()})`).join(' ')})`
            : `+value.\\*:(${filters.ioc_value.split(',').join()})`}`
          : null,
        filters.has_report ? `${filters.has_report === 'true' ? '+Event.Tag.name:(report*)' : '-Event.Tag.name:(report*)'}` : null,
        filters.has_config
          ? `${filters.has_config === 'true'
            ? '+Event.Tag.name:("extracted_config:true") +type:text'
            : '-Event.Tag.name:("extracted_config:true")'}`
          : null,
        filters.ioc_category
          ? `+type:(${escapeFiltersList({ stringOfFilters: filters.ioc_category, whenFilterContainsStar: 'preventEscape' })})`
          : null,
        filters.ioc_type
          ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_type, whenFilterContainsStar: 'preventEscape' })})`
          : null,
        filters.ioc_malware
          ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_malware, whenFilterContainsStar: 'preventEscape' })})`
          : null,
        filters.ioc_tool
          ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_tool, whenFilterContainsStar: 'preventEscape' })})`
          : null,
        filters.ioc_actor
          ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_actor, whenFilterContainsStar: 'preventEscape' })})`
          : null,
        !filters.since
          ? '+Event.date:[* TO now]'
          : `+Event.date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('from', id ? null : +skip)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.iocs)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('aggregations',
        {
          ...(id || exporting || ignoreAggregations || type === 'all'
            ? {}
            : {
              ...(filters.aggregations
                ? filters.aggregations
                : {
                  'date-histogram': {
                    field: 'date',
                    interval,
                  },
                }
              ),
            }
          ),
        },
      )
      .set('sort', filters.sort ? filters.sort.split(',') : ['Event.date:desc'])
      .set('size', +limit)
      .set('track_total_hits', 10000)
      .filter(v => v?.toString()?.length)
      .toJS();
    const iocsMetaQuery = map()
      .set('query', [
        `basetypes:(${BasetypeMapping(['iocs'])})`,
        filters.ioc_type ? `+type:(${escapeFiltersList({ stringOfFilters: filters.ioc_type, whenFilterContainsStar: 'preventEscape' })})` : null,
        id ? `Event.uuid:(${id})` : null,
      ].filter(v => v).join(' '))
      .set('_source_includes', SearchFields.Includes.iocs)
      .set('sort', ['Event.date:desc'])
      .set('traditional_query', true)
      .set('from', query.skip ? +query.skip : +skip)
      .set('size', +limit)
      .filter(v => v)
      .toJS();
    const chatsQuery = map()
      .set('query', [
        `+basetypes:${BasetypeMapping(['chats'])}`,
        id ? `+fpid:"${id.split('|')[0]}"` : null,
        filters.query ? `+(${filters.query})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.server
          ? serverFilter
          : null,
        filters.server_id ? `+(|container.container.native_id:(${filters.server_id}) |container.server.native_id:(${filters.server_id})) |server.native_id:(${filters.server_id})` : null,
        filters.channel
          ? channelFilter
          : null,
        filters.channel_id
          ? `+(|container.native_id:(${filters.channel_id.split(',').join(' OR ')}) |channel.native_id:(${filters.channel_id.split(',').join(' OR ')}))`
          : null,
        filters.author_id ? `+site_actor.native_id:(\"${filters.author_id}\")` : null,
        filters.message ? `+body.text/plain:${filters.message}` : null,
        filters.media ? `${filters.media === 'true' ? '+_exists_:media +_exists_:media.storage_uri +media.size:[5000 TO *]' : '-_exists_:media'}` : null,
        filters.media_type && !filters.mime_type ? `+media.mime_type.keyword:(${mediaType}) ${filters.media_type === 'Application' ? '-media.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}` : null,
        filters.mime_type ? `+media.mime_type:"${filters.mime_type}"` : null,
        filters.media_caption ? `+media.title:(${filters.media_caption})` : null,
        filters.filename ? `+(media.filename:(${filters.filename}) |media.file_name:(${filters.filename}) |media.sha256:(${filters.filename}))` : null,
        filters.sha1 ? `${!excludeFilters.includes('sha1') ? '+' : '-'}media.sha1:(${filters.sha1})` : null,
        filters.phash ? `+media.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})` : null,
        filters.language ? `+body.enrichments.language:(${Object.entries(Iso).find(v => v?.[1]?.name === filters.language)?.[0] || ''})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', filters.group !== 'channels' && highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.chats)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('group', filters.group ? filters.group : 'messages')
      .set('aggregations', filters.group === 'channels'
        ? {
          ...!ignoreAggregations && {
            'date-histogram': {
              field: 'sort_date',
              interval,
            } },
          'top-hits-messages-by-container': { size: 250, top_hits_size: 1 },
          'container-names-by-container-fpid': { size: 250 },
          'site-actors-cardinality-by-container': { size: 250 },
          'multiple-terms': {
            author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
            channel: { terms: { field: 'container.title.keyword', size: 5 } },
          },
        }
        : { ...!ignoreAggregations && {
          'date-histogram-cardinality': {
            field: 'sort_date',
            interval,
            sub_field: subField,
          },
          'multiple-terms': {
            author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
            channel: { terms: { field: 'container.name.keyword', size: 5 } },
          },
        } })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', filters.group === 'channels' ? 1 : +limit)
      .set('track_total_hits', 10000)
      .filter(v => v?.toString()?.length)
      .toJS();

    const channelQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping([`chats${chatTypes.includes(filters?.site?.toLowerCase()) ? `.${filters?.site?.toLowerCase()}` : ''}`])})`,
        `+container.fpid:"${id}"`,
        filters.id && !exporting ? `+sort_date:[${moment.utc(filters.id * 1000).format()} TO now]` : '',
        exporting ? `+sort_date:[* TO ${moment.utc(filters.id ? filters.id * 1000 : undefined).format()}]` : null,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.chats)
      .set('_source_includes', SearchFields.InlineIncludes.chats)
      .set('sort', (exporting) ? ['sort_date:desc'] : ['sort_date:asc'])
      .set('size', (exporting) ? 1000 : +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const channelInlineQuery = fromJS(chatsQuery)
      .filter(v => v)
      .update('query', v => v
        .replace('+fpid', '+container.fpid')
        .replace('+basetypes:(chat AND message)',
          `+basetypes:(${BasetypeMapping([`chats${chatTypes.includes(filters?.site?.toLowerCase()) ? `.${filters?.site?.toLowerCase()}` : ''}`])})`))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.chats)
      .set('_source_includes', SearchFields.InlineIncludes.chats)
      .set('sort', ['sort_date:desc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const channelMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping([`chats${chatTypes.includes(filters?.site?.toLowerCase()) ? `.${filters?.site?.toLowerCase()}` : ''}`])})`,
        `+container.fpid:"${id}"`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.chats)
      .set('_source_includes', SearchFields.InlineIncludes.chats)
      .set('sort', ['sort_date:asc'])
      .set('aggregations', {
        terms: { field: 'enrichments.language.keyword', size: 10 },
        'container-names-by-container-fpid': { size: 1 },
        'site-actors-cardinality-by-container': { size: 1 } })
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const socialQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['social'])})`,
        id ? `+fpid:("${id.split('|')[0]}")` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.body ? `+body.text/plain:(${filters.body})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.type ? `${filters.type === 'group' ? '+container.basetypes:(group)' : '+container.basetypes:(site-actor-profile)'}` : null,
        filters.subreddit ? `+(|container.container.name:(${filters.subreddit}) |container.name:(${filters.subreddit}))` : null,
        filters.message ? `+body.text/plain:(${filters.message})` : null,
        filters.media_v2 ? `${filters.media_v2 === 'true' ? '+_exists_:media_v2 +_exists_:media_v2.storage_uri +media_v2.size:[5000 TO *]' : '-_exists_:media_v2'}` : null,
        filters.media_type && !filters.mime_type ? `+media_v2.mime_type.keyword:(${mediaType}) ${filters.media_type === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}` : null,
        filters.mime_type ? `+media_v2.mime_type:"${filters.mime_type}"` : null,
        filters.media_caption ? `+media_v2.title:(${filters.media_caption})` : null,
        filters.filename ? `+(media_v2.filename:("${filters.filename}") |media_v2.file_name:("${filters.filename}") |media_v2.storage_uri:("${filters.filename}") |media_v2.sha256:("${filters.filename.split('.')[0]}"))` : null,
        filters.sha1 ? `${!excludeFilters.includes('sha1') ? '+' : '-'}media_v2.sha1:(${filters.sha1})` : null,
        filters.phash ? `+media_v2.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})` : null,

        filters.language ? `+body.enrichments.language:(${Object.entries(Iso).find(v => v?.[1]?.name === filters.language)?.[0] || ''})` : null,
        filters.page
          ? `${filters.page.includes('::')
            ? `${!excludeFilters.includes('page') ? '+' : '-'}container.fpid:"${filters.page.split('::').shift()}"`
            : `${!excludeFilters.includes('page') ? '+' : '-'}container.title${filters.page_exact ? '.keyword' : ''}:(${filters.page})`}`
          : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}])`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.social)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('_source_includes', id ? null : [...highlightExcludedFields, 'media_v2.fpid'])
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
          thread: { terms: { field: 'container.title.keyword', size: 5 } },
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const newsQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['social'])})`,
        `+(|parent_message.fpid:"${id}" |(+container.fpid:"${id}" -_exists_:parent_message))`,
        filters.id && !exporting ? `+sort_date:[${moment.utc(filters.id * 1000).format()} TO now]` : '',
        exporting ? '+sort_date:[* TO now]' : '',
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.social)
      .set('_source_includes', SearchFields.InlineIncludes.social)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const newsInlineQuery = fromJS(socialQuery)
      .filter(v => v)
      .update('query', v => v.replace(/\+sort_date:\[.*?\]/ig, ''))
      .set('aggregations', null)
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.social)
      .set('_source_includes', SearchFields.InlineIncludes.social)
      .set('sort', ['sort_date:asc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const newsMetaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['social'])})`,
        `+fpid:"${id}"`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.social)
      .set('_source_includes', SearchFields.InlineIncludes.social)
      .set('aggregations', null)
      .set('sort', ['sort_date:asc'])
      .set('size', 1)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const pastesQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['pastes'])})`,
        id ? `+fpid:"${id}"` : null,
        filters.query ? `${!id ? `+(${filters.query})` : ''}` : null,
        filters.extract ? `+(|_exists_:enrichments.${filters.extract} |_exists:container.enrichments.${filters.extract}` : null,
        filters.title ? `+title${filters.title_exact ? '.keyword' : ''}:(${filters.title})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.syntax ? `+syntax:(${filters.syntax})` : null,
        filters.native_id ? `+native_id:(${filters.native_id})` : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now])'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.pastes)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram': {
          field: 'sort_date',
          interval,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const mediaQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(filters?.all?.split(',') || ['media'])})`,
        '+_exists_:media.storage_uri',
        type !== 'media'
          ? `+_exists_:media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis`
          : null,
        id ? `+fpid:"${id.split('|')[0]}"` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.channel ? `+container.container.title:(${filters.channel})` : null,
        filters.channel_id ? `+container.native_id:(${filters.channel_id})` : null,
        filters.sites ? `+site.title:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        filters.author_id ? `+site_actor.native_id:(\"${filters.author_id}\")` : null,
        filters.sha1 ?
          `${!excludeFilters.includes('sha1') ? '+' : '-'}(media.sha1:(${filters.sha1}))`
          : null,
        filters.phash
          ? `+(media.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})`
          : null,
        filters.safe_search && type === 'media'
          ? `+(\
              |media.image_enrichment.enrichments.v1.image-analysis.safe_search.violence:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.image_enrichment.enrichments.v1.image-analysis.safe_search.adult:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.image_enrichment.enrichments.v1.image-analysis.safe_search.racy:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.video_enrichment.enrichments.v1.video-analysis.safe_search.violence:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.video_enrichment.enrichments.v1.video-analysis.safe_search.adult:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.video_enrichment.enrichments.v1.video-analysis.safe_search.racy:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search})`
          : null,
        filters.classifications && type === 'media'
          ? `${!excludeFilters.includes('classifications') ? '+' : '-'}(\
            |media.image_enrichment.enrichments.v1.image-analysis.classifications.value:(${filters.classifications.split(',').join()})\
            |media.video_enrichment.enrichments.v1.video-analysis.classifications.value:(${filters.classifications.split(',').join()}))`
          : null,
        filters.text && type === 'media'
          ? `${!excludeFilters.includes('text') ? '+' : '-'}(\
            |media.image_enrichment.enrichments.v1.image-analysis.text.value:(${filters.text.split(',').join()}))\
            |media.video_enrichment.enrichments.v1.video-analysis.text.value:(${filters.text.split(',').join()}))`
          : null,
        filters.logos && type === 'media'
          ? `${!excludeFilters.includes('logos') ? '+' : '-'}(\
            |media.image_enrichment.enrichments.v1.image-analysis.logos.value:(${filters.logos.split(',').join()}))\
            |media.video_enrichment.enrichments.v1.video-analysis.logos.value:(${filters.logos.split(',').join()}))`
          : null,
        filters.safe_search && type === 'images'
          ? `+(\
              +media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.violence:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              +media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.adult:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              +media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.racy:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search})`
          : null,
        filters.safe_search && type === 'videos'
          ? `+(\
              |media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.violence:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.adult:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search}\
              |media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.safe_search.racy:<=${filters?.safe_search?.includes('::') ? filters?.safe_search?.split('::')[0] : filters?.safe_search})`
          : null,
        filters.classifications && ['videos', 'images'].includes(type)
          ? `${!excludeFilters.includes('classifications') ? '+' : '-'}media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.classifications.value:(${filters.classifications.split(',').join()})`
          : null,
        filters.text && ['videos', 'images'].includes(type)
          ? `${!excludeFilters.includes('text') ? '+' : '-'}media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.text.value:(${filters.text.split(',').join()})`
          : null,
        filters.logos && ['videos', 'images'].includes(type)
          ? `${!excludeFilters.includes('logos') ? '+' : '-'}media.${type.slice(0, -1)}_enrichment.enrichments.v1.${type.slice(0, -1)}-analysis.logos.value:(${filters.logos.split(',').join()})`
          : null,
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        computeSitesQueryConditions(filters, exactFilters),
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.media)
      .set('collapse_field', 'media.sha1.keyword')
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        } })
      .filter(v => v?.toString()?.length)
      .toJS();
    const twitterQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['twitter'])})`,
        id ? `+fpid:("${id.split('|')[0]}")` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.body ? `+body.raw:(${filters.body})` : null,
        filters.category_id ? `+owners.category_id:(\"${filters.category_id}\")` : null,
        filters.customer_id
          ? `+owners.salesforce_id:("${customerIdFilter['15']}" | "${customerIdFilter['18']}" ${!filters.category_id || /f1382d8fca53$/ig.test(filters.category_id) ? '| "twitterflashpointintel"' : ''})`
          : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', highlightEnabled ? highlightSize : 0)
      .set('traditional_query', true)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.twitter)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('_source_includes', id ? null : highlightExcludedFields)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', id ? 1 : +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
      })
      .filter(v => v?.toString()?.length)
      .toJS();
    const communitiesQuery = map()
      .set('query', [
        fixCredsBasetypeOrder(filters, excludeFilters),
        `${exporting ? '-basetypes:(twitter)' : ''}`, // disable all twitter exports PLATFORM-3394
        filters.query ? `+(${filters.query})` : null,
        computeAuthorsExcludeQueryConditions(filters, excludeFilters, exactFilters),
        filters.channel
          ? channelFilter
              .replace(/username/ig, 'container.title')
              .replace(/name/ig, 'title')
          : null,
        filters.title
          ? `${!excludeFilters.includes('title') ? '+' : '-'}title:("${filters.title}")`
          : null,
        (filters.sites && !filters.exclude_sites) ? `+site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        (filters.exclude_sites && filters.sites) ? `-site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.sites })})` : null,
        buildCommunitiesDate(filters),
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('traditional_query', true)
      .set('fields', filters.fields
        ? filters.fields.split(',')
        : [...new Set(SearchFields.Fields.communities
          .concat(...filters.all
            ? filters.all.split(',').map(k => SearchFields.Fields[String(k)]).filter(v => v)
            : []))])
      .set('_source_includes', id
        ? null
        : [
          ...highlightExcludedFields,
          ...prm.some(p => /(vln|cve).r/ig.test(p)) ? ['nist', 'cve.nist'] : [],
        ])
      .set('from', +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', +limit)
      .set('track_total_hits', 10000)
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null : {
        'date-histogram-cardinality': {
          field: 'sort_date',
          interval,
          sub_field: subField,
        },
        'multiple-terms': {
          author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
          site: { terms: { field: 'site.title.keyword', size: 5 } },
          channel: { terms: { field: 'container.title.keyword', size: 5 } },
        },
      })
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const credentials = () => {
      if (!cached && prm.some(p => /edm/.test(p))) {
        this.performAggregations(credentialsQuery, 'credentials', filters);
        delete credentialsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, credentialsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'credentials', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: credentialsQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              title: `${v._source.domain} | ${v._source.breach.title}`,
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
              highlight: { ...v.highlight },
            })),
          }))
          .then(res => (id && !id.includes('::') && res.total ? res.data[0] || {} : res))
          .then(async (res) => {
            if ((id && id.includes('::')) || exporting) return res;
            const fpids = [...new Set(res?.data?.map(v => `"${v?.credential_record_fpid}"`))].join(' OR ');
            const q = {
              query: `+basetypes:credential-record +fpid:(${fpids})`,
              limit: 1000,
              source: true,
              _source_includes: ['fpid', 'sightings.breach.fpid'],
            };
            const records = await Api
              .post(SearchUrl, q)
              .then(v => v?.data);
            return {
              ...res,
              data: res?.data?.map(v => ({
                ...v,
                total_sightings: records
                  ?.find(record => record?.fpid === v?.credential_record_fpid)?.sightings
                    ?.filter(sighting => sighting.breach.fpid === v.breach.fpid).length || 0,
              })),
            };
          })
          .then((res) => {
            if (exporting && filters.export_host_data) {
              res.data.forEach((v) => {
                const cookieKeys = [];
                // eslint-disable-next-line no-param-reassign
                v.cookies = v.cookies?.map(k => [...cookieKeys, k.key]).join(' ');
                // eslint-disable-next-line no-param-reassign
                v = {
                  ...v,
                  infected_host_attributes: {
                    ...v?.infected_host_attributes,
                    machine: {
                      ...v?.machine?.infected_host_attributes,
                    },
                  },
                };
                // eslint-disable-next-line no-param-reassign
                v.infected_host_attributes.machine.computer_name = v?.infected_host_attributes?.machine?.extra?.find(field => ['computer name'].includes(field.key))?.value;
                // eslint-disable-next-line no-param-reassign
                v.infected_host_attributes.machine.file_location = v?.infected_host_attributes?.machine?.extra?.find(field => ['filelocation'].includes(field.key))?.value;
                // eslint-disable-next-line no-param-reassign
                v.infected_host_attributes.machine.path = v?.infected_host_attributes?.machine?.extra?.find(field => ['bin_path', 'exe_path', 'Exe Path', 'path', 'Working Path'].includes(field.key))?.value;
              });
            }
            return res;
          })
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'credentials'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'credentials'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const customerCredentials = () => {
      if (!cached && prm.some(p => /ccm.cus/.test(p))) {
        if (!exporting) return false;
        const { pathname } = History.getCurrentLocation();
        const CCMCType = pathname.includes('credentials-search') ? 'search' : 'analysis';
        const CCMCUrl = `/api/v4/credentials${'_self' in React.createElement('div') ? '' : ''}`;
        const getDataBatch = async (scrollId) => {
          const currentQuery = this.state.getIn(['search', 'results', 'customer-credentials', 'request']);
          const CCMCQuery = scrollId
            ? ({ scroll_id: scrollId })
            : currentQuery;
          await Api.post(`${CCMCUrl}/${CCMCType}${scrollId ? '/scroll' : '?page_size=100&scroll=true'}`, CCMCQuery, SearchFields.StatusOK)
            .then(res => ({
              ...res,
              data: res.data.results.map(v => v.results).flat(),
              scroll_id: res.data.scroll_id,
            }))
            .then(res => ({
              ...res,
              data: res.data.map((v) => {
                // eslint-disable-next-line no-param-reassign
                v.basetypes = ['customer-credentials'];
                return v;
              }),
            }))
            .then((res) => {
              const computerNames = ['computer_name', 'computer name'];
              const fileNames = ['filelocation'];
              const pathNames = ['bin_path', 'exe_path', 'Exe Path', 'path', 'Working Path'];
              res.data.forEach((v) => {
                const findExtraField = fieldArray => v?.infected_host_attributes?.machine?.extra
                  ?.find(val => fieldArray.includes(val.key));
                if (v.cookies && typeof v.cookies !== 'string') {
                  // eslint-disable-next-line no-param-reassign
                  v.cookies = v.cookies.map(val => val.key).join(' ');
                }
                if (findExtraField(computerNames)) {
                  // eslint-disable-next-line no-param-reassign
                  v.infected_host_attributes.machine.computer_name = findExtraField(computerNames)
                    ?.value;
                }
                if (findExtraField(fileNames)) {
                  // eslint-disable-next-line no-param-reassign
                  v.infected_host_attributes.machine.file_location = findExtraField(fileNames)
                    ?.value;
                }
                if (findExtraField(pathNames)) {
                  // eslint-disable-next-line no-param-reassign
                  v.infected_host_attributes.machine.path = findExtraField(pathNames)?.value;
                }
              });
              return res;
            })
            .then((res) => {
              if (res.scroll_id) {
                setTimeout(getDataBatch(res.scroll_id), 3000);
              } else {
                SearchActions.set([...key, 'customer-credentials'], fromJS(res));
                SearchActions.export(key, 'customer-credentials', filters.pages);
              }
            })
            .then(() => SearchActions.search.completed())
            .catch((err) => {
              if (err.message !== 'canceled') {
                Api.log(err);
                empty()([...key, 'customer-credentials'], err.code);
                if (/ECONNABORTED/.test(err.code)) return err;
                SearchActions.set(['search', 'info'], fromJS({
                  message: Messages.SearchError,
                  action: 'Retry',
                  fn: () => window.location.reload(true),
                }));
              }
              return err;
            })
            .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
        };
        getDataBatch();
      }
      return false;
    };

    const reports = () => {
      if (!cached && prm.some(p => /rep|ddw/.test(p))) {
        SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return Api.get(`${ReportsUrl}`, reportQuery, [200, 400, 500, 501, 502, 503, 504])
          .then(res => (res.ok ? res.data : this.error(error, 'reports', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { data: [] };
            }
            return res;
          })
          .then(res => ({ ...res, lookup }))
          .then(res => ({
            ...res,
            lookup,
            requestBody: reportQuery,
            data: res.data.map(v => ({
              ...v,
              tags: v.tags.filter(tag => oldTags.has(tag.toLowerCase())),
              fpid: v.id,
              sources: v.sources.map(s => ({
                ...s,
                title: (s.title || '').replace('/api/v4/indicators/event',
                  '/home/technical_data/iocs/items'),
                original: (s.original || '').replace('/api/v4/indicators/event',
                  '/home/technical_data/iocs/items'),
              })),
              body: {
                'text/html+sanitized': v.body,
                'text/plain': Text.StripHtml(Text.ReportCard(Text.StripCss(v.body))),
                strippedInnerText: getStrippedInnerText(v.body),
              },
              basetypes: ['report', 'reports'] })) }))
          .then(res => (id ? { ...res.data[0], related: [] } : res))
          .then(res => SearchActions.set([...key, 'reports'], !requestHasBeenCanceled ? fromJS(res) : map()))
          .then(() => id && SearchActions.searchRelated(id))
          .then(() => SearchActions.search.completed(this.state.getIn([...key, 'reports'])))
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'reports'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };

    const accounts = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(accountsQuery, 'accounts', filters);
        delete accountsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, accountsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'accounts', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: accountsQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/plain'] : '-';
              if (v.highlight && v.highlight['body.text/plain']) {
                [body] = v.highlight['body.text/plain'];
              }
              return {
                ...v._source,
                id: v._source.fpid,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'accounts'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'accounts'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const forums = () => {
      if (!cached && prm.some(p => /for|ddw/.test(p))) {
        this.performAggregations(forumsQuery, 'forums', filters);
        delete forumsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, forumsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'forums', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res, filters?.query_i18n))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: forumsQuery,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            skip: filters.skip || 0,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] || v._source.body['text/plain'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              } else if (v.highlight && v.highlight['body.text/plain']) {
                [body] = v.highlight['body.text/plain'];
              }
              return {
                ...v._source,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'forums'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'forums'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const thread = () => !cached && prm.some(p => /for|ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, threadQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'threads', '', (res.message !== 'canceled'))))
        .then(res => Api.mapHighlightToSource(res, true))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        // sometimes the request goes through but there is no hits. in this case
        // we want to show an error message since the issue is with the data
        .then(res => (!requestHasBeenCanceled ? ({
          lookup,
          bounds: res.hits.hits[0]._source.container_position
            && res.hits.hits[0]._source.container_position.index_number
            && res.hits.hits[0]._source.container_position.index_number <= 1
            ? { start: true } : {},
          fpid: res.hits.hits[0]._source.container.fpid,
          index: res.hits.hits[0]._source.container_position
            && res.hits.hits[0]._source.container_position.index_number
            ? res.hits.hits[0]._source.container_position.index_number
            : 1,
          offset: res.hits.hits[0]._source.sort_date
            ? moment.utc(res.hits.hits[0]._source.sort_date).unix()
            : moment.utc().unix(),
          data: res.hits.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'threads'],
            highlight: { ...v.highlight } })),
        })
          : ({
            data: [],
          })))
        .then(res => ({ ...res, data: res.data.map((v, k) =>
          ({ ...v, container_position: { index_number: +res.index + k } })) }))
        .then(res => SearchActions.set([...key, 'threads'], fromJS(res)))
        .then(() => SearchActions.search.completed())
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'threads'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const threadInline = () => !cached && prm.some(p => /for|ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, threadInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'inline', 'threads', (res.message !== 'canceled'))))
        .then(res => Api.mapHighlightToSource(res, true))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: [], total: 0 };
          }
          return res;
        })
        .then(res => ({
          lookup,
          limit,
          skip: filters.skip || 0,
          total: res.hits.total,
          count: res.hits.total,
          data: res.hits.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'threads'],
            highlight: { ...v.highlight } })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'threads'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'threads'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const threadMeta = () => !cached && prm.some(p => /for|ddw/.test(p)) &&
        Api.post(`${SearchUrl}`, threadMetaQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res, true))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => (!requestHasBeenCanceled ? ({
            ...res.hits.hits.slice(-1)[0]._source,
            total: res.hits.total,
            basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'threads'],
            title: res.hits.hits.slice(-1)[0]._source.container.title,
            title_en: res.hits.hits.slice(-1)[0]._source.container.title_en ||
              res.hits.hits.slice(-1)[0]._source.container.title,
            title_native_language: res.hits.hits.slice(-1)[0]._source
              .container.title_native_language,
            fpid: res.hits.hits.slice(-1)[0]._source.container.fpid,
            post_fpid: res.hits.hits.slice(-1)[0]._source.fpid,
            nid: res.hits.hits.slice(-1)[0]._source.container.native_id,
            description: res.hits.hits.slice(-1)[0]._source.site.description.raw,
            rooms: {} })
            : ({
              site: {},
            })))
          .then((res) => {
            if (!res.site.fpid) {
              SearchActions.set([...key, 'meta', 'threads', 'rooms'], list());
              return res;
            }
            const roomQuery = map()
              .set('query', `+basetypes:(forum AND post) +site.fpid:("${res.site.fpid}")`)
              .set('size', 0)
              .set('aggregations', {
                'terms-by-terms': {
                  field_1: 'container.container.fpid',
                  field_2: 'container.container.title.keyword',
                },
              })
              .toJS();
            Api.post(`${SearchUrl}`, roomQuery, SearchFields.StatusOK)
              .then(roomRes => (roomRes.ok ? roomRes.data : this.error(error, 'threads')))
              .then(roomRes => roomRes.aggregations['terms-by-terms'].buckets.map(v => ({
                fpid: v.key,
                count: v.doc_count,
                title: v['distinct-terms'].buckets[0].key,
              })))
              .then(roomRes => SearchActions.set([...key, 'meta', 'threads', 'rooms'], fromJS(roomRes)));
            return res;
          })
          .then((res) => { SearchActions.set([...key, 'meta', 'threads'], fromJS(res)); return res.site.fpid; })
          .then(forumFpid => (forumFpid ? Api.post(`${SearchUrl}`, forumPostCountQuery(forumFpid), SearchFields.StatusOK)
            .then(res => (res.ok ? res.data : this.error(error)))
            .then(res => SearchActions.set([...key, 'meta', 'threads', 'forum_total'], (res.hits.total || '-').toLocaleString()))
            : forumFpid))
          .then(() => SearchActions.search.completed());
    const marketplaces = () => {
      if (!cached && prm.some(p => /mkt|ddw/.test(p))) {
        this.performAggregations(marketplacesQuery, 'marketplaces', filters);
        delete marketplacesQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, marketplacesQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'marketplaces', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: marketplacesQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/plain'] : '-';
              if (v.highlight && v.highlight['body.text/plain']) {
                [body] = v.highlight['body.text/plain'];
              }
              return {
                ...v._source,
                id: v._source.fpid,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'marketplaces'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'marketplaces'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const blogs = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(blogsQuery, 'blogs', filters);
        delete blogsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, blogsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'blogs', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: blogsQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              }
              return {
                ...v._source,
                id: v._source.fpid,
                title: v._source.title,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'blogs'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'blogs'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const posts = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, postsQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'posts', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then((res) => {
          // Possibility of no results since comment threads dont include the
          // root post in its container
          if (res.hits.hits.length === 0) {
            return {
              lookup,
              loaded: true,
              data: [],
            };
          }
          return !requestHasBeenCanceled ? {
            lookup,
            loaded: true,
            bounds: res.hits.hits[0]._source.native_id <= 1 ? { start: true } : {},
            fpid: res.hits.hits[0]._source.container.fpid || res.hits.hits[0]._source.fpid,
            index: res.hits.hits[0]._source.native_id || 1,
            offset: res.hits.hits[0]._source.sort_date
              ? moment.utc(res.hits.hits[0]._source.sort_date).unix()
              : moment.utc().unix(),
            data: res.hits.hits.map(v => ({
              ...v._source,
              basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'posts'],
              highlight: { ...v.highlight },
              enrichments: {
                ...v._source.enrichments,
              },
            })),
          }
            : {
              data: [],
            };
        })
        .then(res => ({ ...res, data: res.data.map((v, k) =>
          ({ ...v, container_position: { index_number: +res.index + k } })) }))
        .then(res => SearchActions.set([...key, 'posts'], fromJS(res)))
        .then(() => SearchActions.search.completed())
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'posts'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const postsInline = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, postsInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data.hits : this.error(error, 'inline', 'posts', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: [], total: 0 };
          }
          return res;
        })
        .then(res => ({
          lookup,
          limit,
          skip: filters.skip || 0,
          total: res.total.value,
          count: res.total.value,
          data: res.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'posts'],
            highlight: { ...v.highlight },
            enrichments: {
              ...v._source.enrichments,
            },
          })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'posts'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'posts'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const postsMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, postsMetaQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then((res) => {
          if (res.hits.hits.length === 0) {
            return {
              total: res.hits.total,
              loaded: true,
              post: {},
            };
          }
          return {
            ...res.hits.hits.slice(-1)[0]._source,
            total: res.hits.total,
            loaded: true,
            title: res.hits.hits.slice(-1)[0]._source?.title,
            basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'posts'],
            fpid: (res.hits.hits.slice(-1)[0]._source.container)
              ? res.hits.hits.slice(-1)[0]._source.container.fpid
              : res.hits.hits.slice(-1)[0]._source.fpid,

            // Get the first post associated with the comment thread
            post: {
              ...res.hits.hits.slice(-1)[0]._source,
              basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'posts'],
              highlight: { ...res.hits.hits.slice(-1)[0].highlight },
              enrichments: {
                ...res.hits.hits.slice(-1)[0]._source.enrichments,
              },
            } };
        })
        .then(res => SearchActions.set([...key, 'meta', 'posts'], fromJS(res)))
        .then(() => SearchActions.search.completed());

    const ransomware = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(ransomwareQuery, 'ransomware', filters);
        delete ransomwareQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, ransomwareQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'ransomware', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: ransomwareQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              }
              return {
                ...v._source,
                id: v._source.fpid,
                title: v._source.title,
                site_actor: v._source.ransomer,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
                media_v2: v?._source?.media_v2
                    ?? (v?._source?.media?.length ? v?._source?.media : [v?._source?.media])
                    ?? []
                    ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                    ?.sort(a => (a?.fpid === v?.inner_hits
                      ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                        ? -1
                        : 1)),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'ransomware'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'ransomware'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const cards = () => {
      if (!cached && prm.some(p => /crd|ddw/.test(p))) {
        this.performAggregations(cardsQuery, 'cards', filters);
        delete cardsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, cardsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'cards', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: cardsQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
              title: `${v._source.card_type || ''} ${v._source.bin}`,
              highlight: { ...v.highlight },
              enrichments: this.universalEnrichments(fromJS(v._source), exporting),
            })),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'cards'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          // Need to multiply pages by 5 since we reduced the limit from 5000 to 1000
          // Need to add 4 since the first export is only 1000 instead of 5000
          // default is 10000, so if no pages filter, need 9 more 1000 rows
          .then(() => exporting && SearchActions.export(
            key,
            type,
            filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'cards'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const pastes = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(pastesQuery, 'pastes', filters);
        delete pastesQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, pastesQuery, SearchFields.StatusOK, exporting ? 60000 : 30000)
          .then(res => (res.ok ? res.data : this.error(error, 'pastes', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: pastesQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            aggregations: res.aggregations,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              } else if (v.highlight && v.highlight['body.raw']) {
                [body] = v.highlight['body.raw'];
              }
              return {
                ...v._source,
                id: v._source.fpid,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'pastes'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'pastes'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const cves = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(cvesQuery, 'cves', filters);
        delete cvesQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, cvesQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'cves', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup,
            skip,
            limit,
            scroll,
            requestBody: cvesQuery,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: (v._source.cve) ? v._source.cve.fpid : v._source.fpid,
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
              cve: v._source.cve || { mitre: v._source.mitre, nist: v._source.nist },
              highlight: { ...v.highlight },
            })),
          }))
          .then(res => (id && res.total ? res.data[0] : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'cves'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'cves'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const cvesInline = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, cvesInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'cves', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => ({
          lookup,
          skip,
          limit,
          total: res.hits.total,
          count: res.hits.total,
          data: res.hits.hits.map((v, k) => ({
            ...v._source,
            id: v._source.fpid,
            reply_number: k + 1,
            highlight: { ...v.highlight },
            basetypes: [...v._source.basetypes,
              ...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'cves'],
          })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'cves'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'cves'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const cvesMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, cvesMetaQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'cves', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => ({
          lookup,
          skip,
          limit,
          total: res.hits.total,
          count: res.hits.total,
          data: res.hits.hits.map((v, k) => ({
            ...v._source,
            id: v._source.fpid,
            reply_number: k + 1,
            highlight: { ...v.highlight },
            basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
          })),
        }))
        .then(res => SearchActions.set([...key, 'meta', 'cves'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch(err => empty()([...key, 'meta', 'cves'], err.code));
    const exploits = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(exploitsQuery, 'exploits', filters);
        delete exploitsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, exploitsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'exploits', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup,
            skip,
            limit,
            scroll,
            requestBody: exploitsQuery,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes).filter(_v => !['cve'].includes(_v)),
              cve: v._source.cve,
              highlight: { ...v.highlight },
            })),
          }))
          .then(res => (id && res.total ? res.data[0] : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'exploits'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'exploits'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const exploitsInline = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, exploitsInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'exploits', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => ({
          lookup,
          skip,
          limit,
          total: res.hits.total,
          count: res.hits.total,
          data: res.hits.hits.map((v, k) => ({
            ...v._source,
            id: v._source.fpid,
            reply_number: k + 1,
            highlight: { ...v.highlight },
            basetypes: [...v._source.basetypes,
              ...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'exploits'],
          })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'exploits'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'exploits'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const exploitsMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
        Api.post(`${SearchUrl}`, exploitsMetaQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'cves', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup,
            skip,
            limit,
            total: res.hits.total,
            count: res.hits.total,
            data: res.hits.hits.map((v, k) => ({
              ...v._source,
              id: v._source.fpid,
              reply_number: k + 1,
              highlight: { ...v.highlight },
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
            })),
          }))
          .then(res => SearchActions.set([...key, 'meta', 'exploits'], fromJS(res)))
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch(err => empty()([...key, 'meta', 'exploits'], err.code));
    const groupChannel = (res, groupLimit = 1000, groupSkip = 0) => ({
      lookup: excludingHistogramFiltersLookup,
      skip: groupSkip,
      requestBody: chatsQuery,
      limit: groupLimit,
      total: res.aggregations['container-names-by-container-fpid'].buckets.length || 0,
      count: res.aggregations['container-names-by-container-fpid'].buckets.length || 0,
      data: res.aggregations['container-names-by-container-fpid'].buckets
        .map((v, k) => {
          const channelObj = res
            .aggregations['top-hits-messages-by-container']
            .buckets[String(k)]['top-hits-messages']
            .hits.hits[0]._source.container
            || res
              .aggregations['top-hits-messages-by-container']
              .buckets[String(k)]['top-hits-messages']
              .hits.hits[0]._source.channel;
          const serverObj = res
            .aggregations['top-hits-messages-by-container']
            .buckets[String(k)]['top-hits-messages']
            .hits.hits[0]._source.container.container
            || res
              .aggregations['top-hits-messages-by-container']
              .buckets[String(k)]['top-hits-messages']
              .hits.hits[0]._source.container.server
            || res
              .aggregations['top-hits-messages-by-container']
              .buckets[String(k)]['top-hits-messages']
              .hits.hits[0]._source.server;
          return ({
            hits: 1,
            id: v.key,
            basetypes: ['chat', 'chats'],
            fpid: res
              .aggregations['top-hits-messages-by-container']
              .buckets[String(k)]['top-hits-messages']
              .hits.hits[0]._source.fpid,
            created: moment.utc(res
              .aggregations['top-hits-messages-by-container']
              .buckets[String(k)]['top-hits-messages']
              .hits.hits[0]._source.sort_date)
              .unix(),
            container: {
              type: channelObj.type,
              fpid: res
                .aggregations['container-names-by-container-fpid']
                .buckets[String(k)]
                .key,
              name: res
                .aggregations['container-names-by-container-fpid']
                .buckets[String(k)]['distinct-container-names']
                .buckets[0]
                .key,
              container: serverObj,
            },
            site: {
              title: res
                .aggregations['top-hits-messages-by-container']
                .buckets[String(k)]['top-hits-messages']
                .hits.hits[0]._source.site.title },
            messages: res
              .aggregations['container-names-by-container-fpid']
              .buckets[String(k)]
              .doc_count,
            actors: res
              .aggregations['site-actors-cardinality-by-container']
              .buckets[String(k)]['distinct-site-actors']
              .value,
            highlight: { ...v.highlight },
          });
        })
        .slice(groupSkip, groupSkip + groupLimit) });
    const chats = () => {
      if (!cached && prm.some(p => /chat|ddw/.test(p))) {
        // only do aggregations separately for messages, not channels
        if (!filters.group || filters.group === 'messages') {
          this.performAggregations(chatsQuery, 'chats', filters);
          delete chatsQuery.aggregations;
        }
        if (!histogramChanged || filters.group === 'channels') SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return (!histogramChanged || filters.group === 'channels') && Api.post(`${SearchUrl}`, chatsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'chats', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return !filters.group || filters.group === 'messages'
                ? { hits: { hits: [], total: 0 } }
                : { res: { aggregations: { 'container-names-by-container-fpid': [] } } };
            }
            return res;
          })
          .then(res => (!filters.group || filters.group === 'messages' ?
            ({
              lookup: excludingHistogramFiltersLookup,
              requestBody: chatsQuery,
              skip,
              limit,
              scroll,
              scroll_id: res._scroll_id,
              overflow: Boolean(res.hits.total.relation === 'gte'),
              total: res.hits.total.value,
              count: res.hits.total.value,
              data: res.hits.hits.map((v, k) => {
                const discord = v._source.basetypes.includes('discord');
                const channelObj = res.hits.hits[String(k)]._source?.container
                  || res.hits.hits[String(k)]._source.channel;
                const serverObj = res.hits.hits[String(k)]._source?.container?.container
                  || res.hits.hits[String(k)]._source?.container?.server
                  || res.hits.hits[String(k)]._source?.server;
                if (serverObj) channelObj.container = serverObj;
                let body = v._source.body ? v._source.body['text/plain'] : '-';
                if (v.highlight && v.highlight['body.text/plain']) {
                  [body] = v.highlight['body.text/plain'];
                }
                return ({
                  ...v._source,
                  id: v._source.fpid,
                  body: { ...v._source.body, 'text/html+sanitized': v._source.body },
                  basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                  created: moment.utc(v._source.sort_date).unix(),
                  container: channelObj,
                  site_actor: {
                    ...v._source?.site_actor,
                    names: {
                      ...v._source?.site_actor?.names,
                      handle: discord
                        ? v._source?.site_actor?.username
                        : v._source?.site_actor?.names?.handle,
                    },
                  },
                  highlight: {
                    ...v.highlight,
                    'site_actor.names.handle': discord
                      ? v?.highlight?.['site_actor.username']
                      : v?.highlight?.['site_actor.names.handle'],
                  },
                  strippedBody: Text.StripHtml(body),
                  enrichments: this.universalEnrichments(fromJS(v._source), exporting),
                  media_v2: v?._source?.media_v2
                    ?? (v?._source?.media?.length ? v?._source?.media : [v?._source?.media])
                    ?? []
                    ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                    ?.sort(a => (a?.fpid === v?.inner_hits
                      ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                        ? -1
                        : 1)),
                });
              }),
            }) :
            groupChannel(res, limit, skip)))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'chats'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'chats'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const iocs = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(iocsQuery, 'iocs', filters);
        delete iocsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, iocsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'iocs', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: iocsQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              fpid: v._source.Event.uuid,
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
              created: v._source.Event.date,
              highlight: { ...v.highlight },
              Event: {
                ...v._source.Event,
                Attribute: v._source.value
                  ? [{ type: v._source.type, value: v._source.value[v._source.type] }]
                  : v._source.Event.Attribute,
              },
            })),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'iocs'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
            return res;
          })
          .then((res) => {
            const uris = ((res.Event || {}).Attribute || [])
              .filter(a => ['domain', 'url', 'uri', 'link'].includes(a.type))
              .map(a => a.value)
              .join();
            if (uris && !hash) SearchActions.search(`${type}.mentions`, id, false, { uris });
            else SearchActions.set([...key, 'mentions', 'iocs'], fromJS({ total: 0, data: [] }));
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'iocs'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const iocsMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, iocsMetaQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'iocs', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => ({
          lookup,
          skip,
          limit,
          total: res.hits.total,
          count: res.hits.total,
          data: res.hits.hits.map(v => ({
            ...v._source,
            id: v._source.fpid,
            highlight: { ...v.highlight },
            basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
          })),
        }))
        .then(res => SearchActions.set([...key, 'meta', 'iocs'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch(err => empty()([...key, 'meta', 'iocs'], err.code));
    const channels = () => !cached && prm.some(p => /chat|ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, channelQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'channels', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then((res) => {
          if (requestHasBeenCanceled) {
            return {
              data: [],
            };
          }
          const discord = res.hits.hits[0]._source.basetypes.includes('discord');
          const channelObj = res.hits.hits[0]._source.container
            || res.hits.hits[0]._source.channel;
          const serverObj = res.hits.hits[0]._source.container.container
            || res.hits.hits[0]._source.container.server
            || res.hits.hits[0]._source.server;
          if (serverObj) channelObj.container = serverObj;
          return ({
            lookup,
            exportCentered: true,
            query: fromJS(channelQuery),
            bounds: res.hits.hits[0]._source.native_id <= 1 ? { start: true } : {},
            fpid: res.hits.hits[0]._source.container.fpid,
            index: res.hits.hits[0]._source.native_id || 1,
            offset: res.hits.hits[0]._source.sort_date
              ? moment.utc(res.hits.hits[0]._source.sort_date).unix()
              : moment.utc().unix(),
            data: res.hits.hits
              ?.filter((v, k, self) => k === self.findIndex(t => t._source.fpid === v._source.fpid))
              ?.map((v) => {
              let site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
                ? v._source.site_actor.names.aliases || []
                : [];
              site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
                ? site_actor_aliases.filter(a => a !== v._source.site_actor.names.handle)
                : [];
              return ({
                ...v._source,
                container: channelObj,
                site_actor: (!v._source.site_actor)
                  ? null
                  : {
                    ...v._source.site_actor,
                    names: {
                      handle: discord
                        ? v._source?.site_actor?.username
                        : v._source?.site_actor?.names?.handle,
                      aliases: site_actor_aliases,
                    },
                  },
                highlight: {
                  ...v.highlight,
                  'site_actor.names.handle': discord
                    ? v?.highlight?.['site_actor.username']
                    : v?.highlight?.['site_actor.names.handle'],
                },
                basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'channels'],
                media_v2: (v?._source?.media_v2
                    || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
                    ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                    .sort(a => (a?.fpid === v?.inner_hits
                      ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                        ? -1
                        : 1)),
              });
            }),
          });
        })
        .then(res => ({ ...res, data: res.data.map((v, k) =>
          ({ ...v, container_position: { index_number: +res.index + k } })) }))
        .then(res => SearchActions.set([...key, 'channels'], fromJS(res)))
        .then(() => SearchActions.search.completed())
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'channels'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const channelInline = () => !cached && prm.some(p => /chat|ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, channelInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data.hits : this.error(error, 'inline', 'channels', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: [], total: 0 };
          }
          return res;
        })
        .then(res => ({
          lookup,
          skip,
          limit,
          total: res.total.value,
          count: res.total.value,
          data: res.hits.map((v) => {
            const discord = v._source.basetypes.includes('discord');
            let site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
              ? v._source.site_actor.names.aliases || []
              : [];
            site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
              ? site_actor_aliases.filter(a => a !== v._source.site_actor.names.handle)
              : [];
            return ({
              ...v._source,
              site_actor: (!v._source.site_actor)
                  ? null
                  : {
                    ...v._source.site_actor,
                    names: {
                      handle: discord
                        ? v._source.site_actor.username
                        : v._source.site_actor.names.handle,
                      aliases: site_actor_aliases,
                    },
                  },
                highlight: {
                  ...v.highlight,
                  'site_actor.names.handle': discord
                    ? v.highlight['site_actor.username']
                    : v.highlight['site_actor.names.handle'],
                },
              basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'channels'],
              media_v2: (v?._source?.media_v2
                || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
                ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                .sort(a => (a?.fpid === v?.inner_hits
                  ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                    ? -1
                    : 1)),
            });
          }),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'channels'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'channels'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const channelMeta = () => !cached && prm.some(p => /cht|ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, channelMetaQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then((res) => {
          if (requestHasBeenCanceled) {
            return {
              data: [],
              actors: { 'distinct-site-actors': {} },
              aliases: { 'distinct-container-names': {} },
              languages: {},
            };
          }
          const discord = res.hits.hits[0]._source.basetypes.includes('discord');
          const channelObj = res.hits.hits[0]._source.container
            || res.hits.hits[0]._source.channel;
          const serverObj = res.hits.hits[0]._source.container.container
            || res.hits.hits[0]._source.container.server
            || res.hits.hits[0]._source.server;
          if (serverObj) channelObj.container = serverObj;
          const server = serverObj ? serverObj.name : null;
          const serverFpid = serverObj ? serverObj.fpid : null;
          const meta = {
            ...res.hits.hits[0]._source,
            total: res.hits.total,
            basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits[0]._source.basetypes), 'channels'],
            container: channelObj,
            title: (server)
              ? `${server} | ${res.hits.hits[0]._source.container.name}`
              : res.hits.hits[0]._source.container.name,
            fpid: res.hits.hits[0]._source.container.fpid,
            type: res.hits.hits[0]._source.container.type,
            nid: res.hits.hits[0]._source.container.native_id,
            description: res.hits.hits[0]._source.container.description,
            site_actor: {
              ...res.hits.hits[0]._source.site_actor,
              names: {
                ...res.hits.hits[0]._source.site_actor.names,
                handle: discord
                  ? res.hits.hits[0]._source.site_actor.username
                  : res.hits.hits[0]._source.site_actor.names.handle,
              },
            },
            highlight: {
              ...res.hits.hits[0].highlight,
              'site_actor.names.handle': discord
                ? res.hits.hits[0].highlight['site_actor.username']
                : res.hits.hits[0].highlight['site_actor.names.handle'],
            },
            aliases: res.aggregations['container-names-by-container-fpid'].buckets.slice(0, 1)[0],
            actors: res.aggregations['site-actors-cardinality-by-container'].buckets.slice(0, 1)[0],
            languages: res.aggregations.terms.buckets,
          };
          if (serverFpid) {
            meta.server = {};
            const serverQuery = map()
              .set('query', `+basetypes:(${BasetypeMapping([`chats${chatTypes.includes(filters?.site?.toLowerCase()) ? `.${filters?.site?.toLowerCase()}` : ''}`])}) +site.title:("${res.hits.hits[0]._source.site.title}") +container.container.fpid:(${serverFpid}) +sort_date:[* TO now]`)
              .set('size', 1)
              .set('aggregations', {
                'top-hits-messages-by-container': { size: 250, top_hits_size: 1 },
                'container-names-by-container-fpid': { size: 250 },
                'site-actors-cardinality-by-container': { size: 250 } })
              .toJS();
            Api.post(`${SearchUrl}`, serverQuery, SearchFields.StatusOK)
              .then(serverRes => (serverRes.ok ? serverRes.data : this.error(error, 'chats')))
              .then(serverRes => groupChannel(serverRes))
              .then(serverRes => SearchActions.set([...key, 'meta', 'channels', 'server'], fromJS(serverRes)));
          }
          return meta;
        })
        .then(res => ({
          ...res,
          actors: res.actors['distinct-site-actors'].value,
          aliases: res.aliases['distinct-container-names'].buckets,
          lang: res.languages
            .flat()
            .map(b => (b.key === 'null' ? { ...b, key: '-' } : b))
            .map(b => ({ id: b.key, label: b.key.toUpperCase(), value: b.doc_count })),
        }))
        .then(res => SearchActions.set([...key, 'meta', 'channels'], fromJS(res)))
        .then(() => SearchActions.search.completed());
    const boards = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(boardsQuery, 'boards', filters);
        delete boardsQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, boardsQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'boards', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: boardsQuery,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            skip: filters.skip || 0,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              }
              return {
                ...v._source,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                strippedBody: Text.StripHtml(body),
                enrichments: {
                  ...this.universalEnrichments(fromJS(v._source), exporting).toJS(),
                  quotes: !v._source.body ? [] : ((v._source.body['text/html+sanitized'] || '')
                    .match(/&gt;&gt;([0-9]{5,})/igm) || [])
                    .map(m => m.split(';').slice(-1).shift()),
                },
                media_v2: v?._source?.media_v2
                    ?? (v?._source?.media?.length ? v?._source?.media : [v?._source?.media])
                    ?? []
                    ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                    ?.sort(a => (a?.fpid === v?.inner_hits
                      ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                        ? -1
                        : 1)),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'boards'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'boards'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const board = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, boardQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'boards', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => (!requestHasBeenCanceled ? ({
          lookup,
          title: `${res.hits.hits[0]._source.site.title} /${res.hits.hits[0]._source.container.container.title}/ ${res.hits.hits[0]._source.container.native_id}`,
          bounds: res.hits.hits[0]._source.native_id <= 1 ? { start: true } : {},
          fpid: res.hits.hits[0]._source.container.fpid,
          index: res.hits.hits[0]._source.native_id || 1,
          offset: res.hits.hits[0]._source.sort_date
            ? moment.utc(res.hits.hits[0]._source.sort_date).unix()
            : moment.utc().unix(),
          data: res.hits.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'board'],
            highlight: { ...v.highlight },
            enrichments: {
              ...v._source.enrichments,
              quotes: !v._source.body ? [] : ((v._source.body['text/html+sanitized'] || '')
                .match(/&gt;&gt;([0-9]{5,})/igm) || [])
                .map(m => ({ native_id: m.split(';').slice(-1).shift() })),
            },
            media_v2: (v?._source?.media_v2
              || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
              ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
              .sort(a => (a?.fpid === v?.inner_hits
                ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                  ? -1
                  : 1)),
          })),
        })
          : ({
            data: [],
          })))
        .then(res => ({ ...res, data: res.data.map((v, k) =>
          ({ ...v, container_position: { index_number: +res.index + k } })) }))
        .then(res => SearchActions.set([...key, 'board'], fromJS(res)))
        .then(() => SearchActions.search.completed())
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'board'], err.code);
            SearchActions.set(['search', 'info', 'message'], Messages.SearchError);
          }
          return err;
        });
    const boardInline = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, boardInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data.hits : this.error(error, 'inline', 'board', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: [], total: 0 };
          }
          return res;
        })
        .then(res => ({
          lookup,
          limit,
          skip: filters.skip || 0,
          total: res.total.value,
          count: res.total.value,
          data: res.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'board'],
            highlight: { ...v.highlight },
            enrichments: {
              ...v._source.enrichments,
              quotes: !v._source.body ? [] : ((v._source.body['text/plain'] || '')
                .match(/&gt;&gt;([0-9]{5,})/igm) || [])
                .map(m => ({ native_id: m.split(';').slice(-1).shift() })),
            },
            media_v2: (v?._source?.media_v2
              || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
              ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
              .sort(a => (a?.fpid === v?.inner_hits
                ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                  ? -1
                  : 1)),
          })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'board'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'board'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const boardMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
        Api.post(`${SearchUrl}`, boardMetaQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => (!requestHasBeenCanceled
              ? ({
              ...res.hits.hits.slice(-1)[0]._source,
              total: res.hits.total,
              title: `${res.hits.hits[0]._source.site.title} /${res.hits.hits[0]._source.container.container.title}/ ${res.hits.hits[0]._source.container.native_id}`,
              basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'board'],
              fpid: res.hits.hits.slice(-1)[0]._source.container.fpid,
              nid: res.hits.hits.slice(-1)[0]._source.container.native_id,
              description: res.hits.hits.slice(-1)[0]._source.site.description.raw,
            })
            : ({
              total: 0,
            })))
          .then(res => SearchActions.set([...key, 'meta', 'board'], fromJS(res)));
    const social = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(socialQuery, 'social', filters);
        delete socialQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, socialQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'social', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: socialQuery,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            skip: filters.skip || 0,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              let body = v._source.body ? v._source.body['text/html+sanitized'] || v._source.body['text/plain'] : '-';
              if (v.highlight && v.highlight['body.text/html+sanitized']) {
                [body] = v.highlight['body.text/html+sanitized'];
              } else if (v.highlight && v.highlight['body.text/plain']) {
                [body] = v.highlight['body.text/plain'];
              }
              return {
                ...v._source,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight },
                enrichments: this.universalEnrichments(fromJS(v._source), exporting),
                strippedBody: Text.StripHtml(body),
                media_v2: v?._source?.media_v2
                    ?? (v?._source?.media?.length ? v?._source?.media : [v?._source?.media])
                    ?? []
                    ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
                    ?.sort(a => (a?.fpid === v?.inner_hits
                      ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                        ? -1
                        : 1)),
              };
            }),
          }))
          .then(res => (id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'social'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'social'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const news = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, newsQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, 'news', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then((res) => {
          // Possibility of no results since comment threads dont include the
          // root post in its container
          if (res.hits.hits.length === 0) {
            return {
              lookup,
              loaded: true,
              data: [],
            };
          }
          let fpid = '';
          if (res.hits.hits[0]._source.parent_message) {
            ({ fpid } = res.hits.hits[0]._source.parent_message);
          } else if (res.hits.hits[0]._source.container && res.hits.hits[0]._source.container.basetypes.includes('post')) {
            ({ fpid } = res.hits.hits[0]._source.container);
          } else {
            ({ fpid } = res.hits.hits[0]._source);
          }
          let title = res.hits.hits[0]._source.title || res.hits.hits[0]._source.container.title;
          if (res.hits.hits[0].highlight) {
            title = ((res.hits.hits[0].highlight.title && res.hits.hits[0].highlight.title[0])
            || (res.hits.hits[0].highlight['container.title'] && res.hits.hits[0].highlight['container.title'][0]))
            || title;
          }
          const subreddit = res.hits.hits[0]._source.container
            && res.hits.hits[0]._source.container.container
            ? res.hits.hits[0]._source.container.container.name
            : res.hits.hits[0]._source.container.name;
          if (subreddit) title = `${subreddit} | ${title}`;
          return {
            lookup,
            title,
            loaded: true,
            bounds: res.hits.hits[0]._source.native_id <= 1 ? { start: true } : {},
            fpid,
            index: res.hits.hits[0]._source.native_id || 1,
            offset: res.hits.hits[0]._source.sort_date
              ? moment.utc(res.hits.hits[0]._source.sort_date).unix()
              : moment.utc().unix(),
            data: res.hits.hits.map(v => ({
              ...v._source,
              basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'news'],
              highlight: { ...v.highlight },
              enrichments: {
                ...v._source.enrichments,
              },
            })),
          };
        })
        .then(res => ({ ...res, data: res.data.map((v, k) =>
          ({ ...v, container_position: { index_number: +res.index + k } })) }))
        .then(res => SearchActions.set([...key, 'news'], fromJS(res)))
        .then(() => SearchActions.search.completed())
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'news'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const newsInline = () => !cached && prm.some(p => /ddw/.test(p)) &&
      Api.post(`${SearchUrl}`, newsInlineQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data.hits : this.error(error, 'inline', 'news', '', (res.message !== 'canceled'))))
        .then((res) => {
          if (res.display === false) {
            requestHasBeenCanceled = true;
            return { hits: [], total: 0 };
          }
          return res;
        })
        .then(res => ({
          lookup,
          limit,
          skip: filters.skip || 0,
          total: res.total.value,
          count: res.total.value,
          data: res.hits.map(v => ({
            ...v._source,
            basetypes: [...Common.Basetypes.ExtraBasetypes(v._source.basetypes), 'news'],
            highlight: { ...v.highlight },
            enrichments: {
              ...v._source.enrichments,
            },
          })),
        }))
        .then(res => SearchActions.set([...key, 'inline', 'news'], fromJS(res)))
        .then(() => exporting && SearchActions.export(key, type, filters.pages))
        .catch((err) => {
          if (err.message !== 'canceled') {
            Api.log(err);
            empty()([...key, 'inline', 'news'], err.code);
            SearchActions.set(['search', 'info'], fromJS({
              message: Messages.SearchError,
              action: 'Retry',
              fn: () => window.location.reload(true),
            }));
          }
          return err;
        });
    const newsMeta = () => !cached && prm.some(p => /ddw/.test(p)) &&
        Api.post(`${SearchUrl}`, newsMetaQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then((res) => {
            if (res.hits.hits.length === 0) {
              return {
                total: res.hits.total,
                loaded: true,
                post: {},
                basetypes: ['social', 'social', 'news'],
              };
            }
            let title = res.hits.hits[0]._source.title || res.hits.hits[0]._source.container.title;
            if (res.hits.hits[0].highlight) {
              title = ((res.hits.hits[0].highlight.title && res.hits.hits[0].highlight.title[0])
              || (res.hits.hits[0].highlight['container.title'] && res.hits.hits[0].highlight['container.title'][0]))
              || title;
            }
            const subreddit = res.hits.hits[0]._source.container
              && res.hits.hits[0]._source.container.container
              ? res.hits.hits[0]._source.container.container.name
              : res.hits.hits[0]._source.container.name;
            if (subreddit) title = `${subreddit} | ${title}`;
            return {
              ...res.hits.hits.slice(-1)[0]._source,
              total: res.hits.total,
              title,
              basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'news'],

              // Get the first post associated with the comment thread
              post: {
                ...res.hits.hits.slice(-1)[0]._source,
                basetypes: [...Common.Basetypes.ExtraBasetypes(res.hits.hits.slice(-1)[0]._source.basetypes), 'news'],
                highlight: { ...res.hits.hits.slice(-1)[0].highlight },
                enrichments: {
                  ...res.hits.hits.slice(-1)[0]._source.enrichments,
                },
              } };
          })
          .then(res => SearchActions.set([...key, 'meta', 'news'], fromJS(res)))
          .then(() => SearchActions.search.completed());
    const media = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(mediaQuery, type, filters);
        delete mediaQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, mediaQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, type, '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: mediaQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            total: res?.aggregations?.['with-collapse-total-hits']?.value ?? res?.hits?.total ?? 0,
            count: res?.aggregations?.['with-collapse-total-hits']?.value ?? res?.hits?.total ?? 0,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              basetypes: ['media', `${v?._source?.media.media_type}-enrichment`],
              source_basetypes: v?._source?.basetypes,
              highlight: { ...v.highlight },
              media_v2: [v?._source?.media]
                ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000)),
            })),
          }))
          .then(res => ({
            ...res,
            data: res?.data?.map(v => ({
              ...v,
              enrichments: this.universalEnrichments(fromJS(v), exporting),
            })),
          }))
          .then(res => (!exporting && id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, type], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, type], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const twitter = () => {
      if (!cached && prm.some(p => /ddw/.test(p))) {
        this.performAggregations(twitterQuery, 'twitter', filters);
        delete twitterQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        return !histogramChanged && Api.post(`${SearchUrl}`, twitterQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, 'twitter', '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: twitterQuery,
            skip,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map(v => ({
              ...v._source,
              id: v._source.fpid,
              highlight: { ...v.highlight },
              basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
              enrichments: this.universalEnrichments(fromJS(v._source), exporting),
              strippedBody: Text.StripHtml(v?._source?.body?.raw),
            })),
          }))
          .then(res => (!exporting && id && res.total ? res.data[0] || {} : res))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, 'twitter'], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          .then(() => exporting && SearchActions.export(key, type, filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, 'twitter'], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };
    const communities = () => {
      if (!cached && prm.some(p => /cht|frm|ddw/.test(p))) {
        const withAggregations = cleanAggregationQuery(communitiesQuery);
        delete communitiesQuery.aggregations;
        if (!histogramChanged) SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
        if (withAggregations.aggregations
          && Object.keys(withAggregations.aggregations).length > 0) {
          SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) + 1);
          Api.post(`${SearchUrl}`, withAggregations, SearchFields.StatusOK)
            .then(res => (res.ok ? res.data : SearchActions.set(['search', 'charts', type], map())))
            .then(res => Api.mapHighlightToSource(res))
            .then((res) => {
              if (res.aggregations) {
                const { platform, ...aggs } = res.aggregations;
                SearchActions.set(['search', 'charts', type], fromJS({
                  filters,
                  aggregations: {
                    ...aggs,
                    ...(platform
                      ? { platform: {
                        ...platform,
                        buckets: Object.values(
                          platform.buckets
                            .map(v => ({
                              ...v,
                              old: v.key,
                              key: Common.Basetypes.BasetypeToSearchType(fromJS({
                                basetypes: [v.key],
                              })),
                            }))
                            .filter(v => v.key)
                            .reduce((a, b) => ({
                              ...a,
                              [b.key]: {
                                key: b.key,
                                doc_count: b.doc_count + (a[b.key] ? a[b.key].doc_count : 0),
                              },
                            }), {}))
                          .map(v => ({
                            key: v.key,
                            doc_count: v.doc_count,
                          })),
                      } }
                      : {}
                    ),
                  },
                }));
              }
              return res;
            })
            .catch((err) => {
              if (err.message !== 'canceled') {
                Api.log(err);
                SearchActions.set(['search', 'charts', type], fromJS({ aggregations: { status: err.code } }));
                SearchActions.set(['search', 'info'], fromJS({
                  message: Messages.AggregationsError,
                  action: 'Retry',
                  fn: () => SearchActions.search(type, '', false, { ...filters, loadAggregations: true }),
                }));
              }
              return err;
            })
            .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
        }
        return !histogramChanged && Api.post(`${SearchUrl}`, communitiesQuery, SearchFields.StatusOK)
          .then(res => (res.ok ? res.data : this.error(error, type, '', (res.message !== 'canceled'))))
          .then(res => Api.mapHighlightToSource(res))
          .then((res) => {
            if (res.display === false) {
              requestHasBeenCanceled = true;
              return { hits: { hits: [], total: 0 } };
            }
            return res;
          })
          .then(res => ({
            lookup: excludingHistogramFiltersLookup,
            requestBody: communitiesQuery,
            limit,
            scroll,
            scroll_id: res._scroll_id,
            skip: filters.skip || 0,
            overflow: Boolean(res.hits.total.relation === 'gte'),
            total: res.hits.total.value,
            count: res.hits.total.value,
            data: res.hits.hits.map((v) => {
              const source = fromJS(v._source);
              const searchType = Common.Basetypes.BasetypeToSearchType(source);
              return ({
                ...v._source,
                type: searchType,
                platform: searchType,
                basetypes: Common.Basetypes.ExtraBasetypes(v._source.basetypes),
                highlight: { ...v.highlight } });
            }),
          }))
          .then(res => ({
            ...res,
            data: res.data.map((v) => {
              const source = fromJS(v);
              const searchType = Common.Basetypes.BasetypeToSearchType(source);
              return ({
                ...v,
                detail: this.universalDetail(source, searchType),
                description: this.universalDescription(source, searchType),
                enrichments: this.universalEnrichments(source, exporting),
              });
            }),
          }))
          .then((res) => {
            if (!histogramChanged) {
              SearchActions.set([...key, type], !requestHasBeenCanceled ? fromJS(res) : map());
            }
          })
          .then(() => SearchActions.search.completed())
          // Need to multiply pages by 5 since we reduced the limit from 5000 to 1000
          // Need to add 4 since the first export is only 1000 instead of 5000
          // default is 10000, so if no pages filter, need 9 more 1000 rows
          .then(() => exporting && SearchActions.export(
            key,
            type,
            filters.pages))
          .catch((err) => {
            if (err.message !== 'canceled') {
              Api.log(err);
              empty()([...key, type], err.code);
              if (/ECONNABORTED/.test(err.code)) return err;
              SearchActions.set(['search', 'info'], fromJS({
                message: exporting ? Messages.ExportError : Messages.SearchError,
                action: 'Retry',
                fn: () => window.location.reload(true),
              }));
            }
            return err;
          })
          .finally(() => SearchActions.set(['search', 'activeSearches'], this.state.getIn(['search', 'activeSearches']) - 1));
      }
      return false;
    };

    switch (type) {
      case 'videos':
      case 'images':
      case 'media': media(); break;
      case 'accounts': accounts(); break;
      case 'blogs': blogs(); break;
      case 'posts': posts(); break;
      case 'inline.posts': postsInline(); break;
      case 'meta.posts': postsMeta(); break;
      case 'ransomware': ransomware(); break;
      case 'cards': cards(); break;
      case 'board': board(); break;
      case 'boards': boards(); break;
      case 'inline.board': boardInline(); break;
      case 'meta.board': boardMeta(); break;
      case 'credentials': credentials(); break;
      case 'customer-credentials': customerCredentials(); break;
      case 'cves': cves(); break;
      case 'cves.inline': cvesInline(); break;
      case 'cves.meta': cvesMeta(); break;
      case 'exploits': exploits(); break;
      case 'exploits.inline': exploitsInline(); break;
      case 'exploits.meta': exploitsMeta(); break;
      case 'iocs': iocs(); break;
      case 'iocs.meta': iocsMeta(); break;
      case 'forums': forums(); break;
      case 'social': social(); break;
      case 'twitter': twitter(); break;
      case 'news': news(); break;
      case 'inline.news': newsInline(); break;
      case 'meta.news': newsMeta(); break;
      case 'marketplaces': marketplaces(); break;
      case 'pastes': pastes(); break;
      case 'reports': reports(); break;
      case 'threads': thread(); break;
      case 'inline.threads': threadInline(); break;
      case 'meta.threads': threadMeta(); break;
      case 'chats': chats(); break;
      case 'channels': channels(); break;
      case 'inline.channels': channelInline(); break;
      case 'meta.channels': channelMeta(); break;
      case 'all':
      case 'communities': communities(); break;
      default: break;
    }

    // clear true total when search changes
    if (!_.isEqual(previousLookupObjCopy, currentLookupObjCopy)) SearchActions.set(['search', 'trueTotal'], 0);
    if (highlightEnabled && filters.query && filters.query.length > 100 && !exporting && !prm.some(p => /org.fp/.test(p))) SearchActions.set(['search', 'info', 'message'], Messages.SearchQueryLength);
    if (highlightEnabled && filters.query && filters.query.length > 100 && !exporting) SearchActions.set(['search', 'info', 'action'], Messages.SettingUpdateAction);
    if (highlightEnabled && filters.query && filters.query.length > 100 && !exporting) SearchActions.set(['search', 'info', 'fn'], () => History.navigateTo('/home/help/settings'));
    if (!cached && !exporting && !histogramChanged) {
      // the search query has changed, so need to clear everything
      SearchActions.set([...key, ...type.split('.')], map());
      // if the search query has changed other than the skip and limi, clear
      if (!ignoreAggregations) SearchActions.set(['search', 'charts', ...type.split('.')], map());
    }
    // if aggregations errored out, clear when run again
    if (!cached && !id && histogramChanged) {
      if (this.state.getIn(['search', 'charts', ...type.split('.'), 'aggregations', 'status'])) {
        SearchActions.set(['search', 'charts', ...type.split('.')], map());
      }
    }
  },

  onSearchThread(container = map(), direction = 'prev', jump = false) {
    if (container.isEmpty()) return;
    const { query } = History.getCurrentLocation();
    const error = () => SearchActions.searchThread(container, direction, jump);
    const prm = Token.get('prm');
    const id = container.getIn(['offset']);
    const fpid = container.getIn(['fpid']);
    const data = container.getIn(['data']);
    if (data && data.isEmpty()) return;
    const defaults = this.state.getIn(['search', 'defaults']);
    const basetypes = data?.first()?.getIn(['basetypes']);
    const type = basetypes.last();
    const site = data?.getIn([0, 'site', 'title']);
    const isCompactView = this.state.getIn(['search', 'prefs', 'compact']) && ['channels'].includes(type);
    let containerQuery = '';
    switch (type) {
      case 'news':
        containerQuery = `+(|parent_message.fpid:"${fpid}" |(+container.fpid:"${fpid}" -_exists_:parent_message))`;
        break;
      default:
        containerQuery = `+container.fpid:"${fpid}"`;
        break;
    }

    const chatTypes = ['discord', 'element', 'qq', 'telegram', 'rocketchat'];
    const basetypeQuery = `+basetypes:(${BasetypeMapping([Common.Basetypes.BasetypeToSearchType(data.first())])})`
      .replace('+basetypes:((chat AND message))',
        `+basetypes:(${BasetypeMapping([`chats${chatTypes.includes(site?.toLowerCase()) ? `.${site?.toLowerCase()}` : ''}`])})`);
    const key = ['search', 'result', type];
    const filters = SearchUtils.loadFilters(type, query, false, defaults)[0];
    const txn = ['forums', 'threads'].includes(type) && filters?.query_i18n;
    const maxOrs = 3;

    const highlightOrRestriction = Boolean(filters?.query?.match(/\|/ig) || [].length > maxOrs);
    const highlightQuery = !/~(\s|$|\d)/.test(filters.query) && !highlightOrRestriction;
    const highlightEnabled = highlightQuery && this.state.getIn(['search', 'prefs', 'search_highlighting']);
    const limit = filters.limit || (isCompactView ? 100 : 25);
    const start = data.first().getIn(['container_position', 'index_number']) - 1;
    const end = data.last().getIn(['container_position', 'index_number']);
    const back = `[* TO ${moment.utc(id * 1000).format()}]`;
    const forward = `[${moment.utc(id * 1000).format()} TO *]`;
    // Sometimes posts have the same sort_date. When there are more
    // than 25 posts with the same sort_date, this causes an issue with infinite
    // scroll due to incrementing/decrementing the time by one second. When this
    // happens, we will lose any posts with the same time that was not included
    // in the request. To fix this issue, we no longer change the time, and instead
    // filter out fpids which have the same time as the last/first one
    const fpids = data.filter(v => moment.utc(v.getIn(['sort_date'])).unix() === id).map(v => v.get('fpid'));

    const threadQuery = map().set('query', [
      basetypeQuery,
      containerQuery,
      `${!id ? '' : `+sort_date:${direction === 'prev' ? back : forward} -fpid:(${fpids.map(v => `"${escapeExactFilter(v)}"`).join(', ')})`}`,
    ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('highlight_size', 0)
      .set('_source_includes', SearchFields.InlineIncludes[basetypes.slice(-2).first()])
      .set('sort', [`sort_date:${direction === 'prev' ? 'desc' : 'asc'}`])
      .set('size', +limit)
      .toJS();

    const prev = () => prm.some(p => /chat|for|ddw/.test(p)) &&
      Api.post(SearchUrl, threadQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
        .then(res => Api.mapHighlightToSource(res, txn))
        .then((res) => {
          if (res.display === false) {
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => res.hits.hits.map((v, k) => {
          let site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? v._source.site_actor.names.aliases || []
            : [];
          site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? site_actor_aliases.filter(a => a !== v._source.site_actor.names.handle)
            : [];
          return ({
            ...v._source,
            basetypes,
            container_position: { index_number: +start - k },
            highlight: { ...v.highlight },
            site_actor: (!v._source.site_actor)
              ? null
              : { ...v._source.site_actor,
                names: { handle: v._source.site_actor.names.handle,
                  aliases: site_actor_aliases } },
            enrichments: {
              ...v._source.enrichments,
              quotes: !v._source.body ? [] : ((v._source.body['text/plain'] || '')
                .match(/&gt;&gt;([0-9]{5,})/igm) || [])
                .map(m => ({ native_id: m.split(';').slice(-1).shift() })),
            },
            media_v2: (v?._source?.media_v2
              || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
              ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
              .sort(a => (a?.fpid === v?.inner_hits
                ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                  ? -1
                  : 1)),
          });
        }))
        .then(res => fromJS(res.reverse()))
        .then(res => this.state
          .updateIn([...key, 'loaded'], () => true)
          // TODO: fix underlying issue of searchThread being called multiple times
          .updateIn([...key, 'data'], list(), posts => posts.unshift(...res))
          .setIn([...key, 'bounds', 'start'], res.count() < limit)
          .setIn([...key, 'offset'], jump
            ? moment.utc(res.last().getIn(['sort_date'])).unix()
            : id.toString())
          .getIn(key))
        .then(res => SearchActions.set(key, res))
        .catch(err => err);

    const next = () => prm.some(p => /chat|for|ddw/.test(p)) &&
      Api.post(SearchUrl, threadQuery, SearchFields.StatusOK)
        .then(res => (res.ok ? res.data : this.error(error, '', '', (res.message !== 'canceled'))))
        .then(res => Api.mapHighlightToSource(res, txn))
        .then((res) => {
          if (res.display === false) {
            return { hits: { hits: [], total: 0 } };
          }
          return res;
        })
        .then(res => res.hits.hits.map((v, k) => {
          let site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? v._source.site_actor.names.aliases || []
            : [];
          site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? site_actor_aliases.filter(a => a !== v._source.site_actor.names.handle)
            : [];
          return ({
            ...v._source,
            basetypes,
            container_position: { index_number: +end + k },
            highlight: { ...v.highlight },
            site_actor: (!v._source.site_actor)
              ? null
              : { ...v._source.site_actor,
                names: { handle: v._source.site_actor.names.handle,
                  aliases: site_actor_aliases } },
            enrichments: {
              ...v._source.enrichments,
              quotes: !v._source.body ? [] : ((v._source.body['text/plain'] || '')
                .match(/&gt;&gt;([0-9]{5,})/igm) || [])
                .map(m => ({ native_id: m.split(';').slice(-1).shift() })),
            },
            media_v2: (v?._source?.media_v2
              || (v?._source?.media?.length ? v?._source?.media : [v?._source?.media]))
              ?.filter(m => m && (!['image', 'video'].includes(m?.media_type) || m?.size > 1000))
              .sort(a => (a?.fpid === v?.inner_hits
                ?.media_v2?.hits?.hits?.[0]?.fields?.media_v2?.fpid?.[0]
                  ? -1
                  : 1)),
          });
        }))
        .then(res => fromJS(res))
        .then(res => this.state
          .updateIn([...key, 'loaded'], () => true)
          // TODO: fix underlying issue of searchThread being called multiple times
          .updateIn([...key, 'data'], list(), posts => posts.push(...res))
          .setIn([...key, 'bounds', 'end'], res.count() < limit)
          .setIn([...key, 'offset'], jump
            ? moment.utc(res.first().getIn(['sort_date'])).unix()
            : id.toString())
          .getIn(key))
        .then(res => SearchActions.set(key, res))
        .catch(err => err);

    switch (direction) {
      case 'prev': prev(); break;
      case 'next': next(); break;
      default: break;
    }
  },

  onSearchComments(post = map(), more = false) {
    if (post.isEmpty()) return;
    const { query: filters } = History.getCurrentLocation();
    const maxOrs = 3;
    const highlightOrRestriction = Boolean(filters?.query?.match(/\|/ig) || [].length > maxOrs);
    const highlightQuery = !/~(\s|$|\d)/.test(filters.query) && !highlightOrRestriction;
    const highlightEnabled = highlightQuery && this.state.getIn(['search', 'prefs', 'search_highlighting']);
    const fpid = (!more) ? post.get('fpid') : post.getIn(['parent_message', 'fpid']);
    const basetypes = post.get('basetypes');
    const basetype = basetypes.slice(-3).first();
    const type = basetypes.last();
    const forward = `[${moment.utc(post.getIn(['sort_date'])).format()} TO *]`;
    let containerQuery = '';
    switch (type) {
      case 'news':
        containerQuery = `+(|parent_message.fpid:"${fpid}" |(+container.fpid:"${fpid}" -_exists_:parent_message))`;
        break;
      default:
        containerQuery = `+container.fpid:"${fpid}"`;
        break;
    }
    let basetypeQuery = '';
    switch (basetype) {
      case 'social':
        basetypeQuery = '+basetypes:(gab OR reddit)';
        break;
      default:
        basetypeQuery = `+basetypes:(${basetype})`;
        break;
    }
    const key = ['search', 'replies', basetype, fpid];

    const query = map().set('query', [
      basetypeQuery,
      containerQuery,
      `${!more ? '' : `+sort_date:${forward} -fpid:(${post.get('fpid')})`}`,
    ].filter(v => v).join(' '))
      .set('sort', ['sort_date:desc'])
      .set('highlight_query', highlightEnabled && highlightQuery && filters.query)
      .filter(v => v)
      .toJS();
    Api.post(SearchUrl, query, SearchFields.StatusOK, 30000, {}, true)
      .then(res => (res.ok
        ? res.data.hits
        : SearchActions.set(key, map({
          hasMore: false,
          loaded: true,
          data: list(),
        }))))
      .then(res => ({
        data: res.hits.map(v => ({
          ...v._source,
          basetypes,
          highlight: { ...v.highlight },
          enrichments: {
            ...v._source.enrichments,
            quotes: !v._source.body ? [] : ((v._source.body['text/plain'] || '')
              .match(/&gt;&gt;([0-9]{5,})/igm) || [])
              .map(m => ({ native_id: m.split(';').slice(-1).shift() })),
          },
        })),
        total: res.total }))
      .then(res => fromJS({ ...res, data: res.data.reverse() }))
      .then(res => this.state
        .updateIn([...key, 'hasMore'], () => res.get('data').count() > 0 && res.get('data').count() < res.get('total'))
        .updateIn([...key, 'loaded'], () => true)
        .updateIn([...key, 'data'], list(), posts => list(set(posts.unshift(...res.get('data')))))
        .getIn(key))
      .then(res => SearchActions.set(key, res))
      .catch(err => err);
  },

  onSearchIntel(tags = '', type = 'latest', skip = 0, limit = 25, q = '') {
    let key = '';
    let fullTags = '';
    let append = true;
    const cond = this.state.getIn(['search', 'prefs', 'tags_cond']);

    switch (type) {
      case 'standup':
        key = ['search', 'results', type];
        fullTags = 'Standup';
        break;
      case 'actors':
        key = ['search', 'results', type];
        fullTags = 'Actor Profile,Actors';
        break;
      case 'techintel':
        key = ['search', 'results', type];
        fullTags = 'Indicators of Compromise,Malware,Exploits & Vulnerabilities,-Knowledge Base';
        break;
      case 'knowledgebase':
        append = false;
        key = ['search', 'results', type];
        fullTags = `${tags?.split(',').filter(v => v).map(v => `+${v}`)},+Knowledge Base`;
        break;
      default:
        key = ['search', 'results', type];
        fullTags = tags
          ? tags?.split(',')?.map(t => `${cond ? '+' : ''}${t}`)?.join(',')
          : '';
        break;
    }

    const query = makeReportsQueryObj({
      limit,
      skip,
      sort: 'version_posted_at:desc',
      tags: fullTags,
      type,
      query: q,
      remove_styles: false,
    });
    const data = this.state.getIn(key) || map();
    const hash = JSON.stringify(query);
    const cached = data.get('hash') === hash;
    const fetch = () => Api.get(ReportsUrl, query, [200, 400, 500, 501, 502, 503, 504])
      .then(res => (res.ok ? res.data : null))
      .then(res => ({
        ...res,
        data: res.data.map(a => (
          { ...a, tags: a.tags.filter(tag => oldTags.has(tag.toLowerCase())) }
        )),
        hash }))
      .then(res => fromJS(res))
      .then(res => (append && skip ? data.update('data', list(), v => v.concat(res.get('data'))) : res))
      .then(res => (append && skip ? res.update('count', 0, () => res.get('data').count()) : res))
      .then(res => SearchActions.set(key, res));

    if (!cached) fetch();
    if (!skip && !cached) SearchActions.set(key, map());
  },

  onSearchReference(id, field, type) {
    const empty = () => k => SearchActions.set(k, map());
    const key = ['search', 'reference', type, id];
    if (this.state.getIn(key)) return;
    const query = map()
      .set('query', [
        `+basetypes:(${type})`,
        `+${field}:("${id}")`,
      ].join(' '))
      .set('size', 1)
      .toJS();

    Api.post(`${SearchUrl}`, query, SearchFields.StatusOK)
      .then(res => (res.ok ? res.data : empty(key)))
      .then(res => res.hits.hits[0]._source)
      .then(res => SearchActions.set(key, fromJS(res)))
      .catch(() => {
        empty()(key);
      });
  },

  onSearchTranslate(id = '', textKey = [], query = {}, textData = '') {
    const timeout = 60000;
    const type = this.state.getIn(['search', 'type']);
    const errorMessage = 'Sorry there has been an error with your translation.';
    if (query.basetype === undefined) {
      const key = ['search', 'result', 'marketplaces'];
      const text = textKey;
      const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
      Api.post(TranslateUrl, request, undefined, timeout)
        .then(res => (res.ok ? res.data : ''))
        .then(res => SearchActions.set([...key, 'translation'], fromJS(res)))
        .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
    } else if (query.basetype.includes('chan') && !query.basetype.includes('channel')) {
      const key = ['search', 'result', 'board', 'data'];
      const thread = this.state.getIn(key);
      const index = thread.findIndex(v => v.get('fpid') === id);
      const body = [...key, index, ...textKey];
      const text = this.state.getIn(body).replace(/&gt;&gt;[0-9]+?\W/gm, '');
      const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
      Api.post(TranslateUrl, request, undefined, timeout)
        .then(res => (res.ok ? res.data : ''))
        .then(res => SearchActions.set([...key, index, 'translation'], fromJS(res)))
        .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
    } else if (query.basetype.includes('reddit')) {
      if (query.first) {
        const key = ['search', 'result', 'meta', 'news', 'post'];
        const text = textData;
        const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
        Api.post(TranslateUrl, request, undefined, timeout)
          .then(res => (res.ok ? res.data : ''))
          .then(res => SearchActions.set([...key, 'translation'], fromJS(res)))
          .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
      } else if (query.basetype.includes('comment') && !query.first) {
        const key = ['search', 'result', 'news', 'data'];
        const thread = this.state.getIn(key);
        const index = thread.findIndex(v => v.get('fpid') === id);
        if (index < 0) {
          const nestedKey = ['search', 'replies', 'social', query.index, 'data'];
          const nestedReply = this.state.getIn(nestedKey);
          const nestedIndex = nestedReply.findIndex(v => v.get('fpid') === id);
          const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: textData };
          Api.post(TranslateUrl, request, undefined, timeout)
            .then(res => (res.ok ? res.data : ''))
            .then(res => SearchActions.set([...nestedKey, nestedIndex, 'translation'], fromJS(res)))
            .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
        } else {
          const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: textData };
          Api.post(TranslateUrl, request, undefined, timeout)
            .then(res => (res.ok ? res.data : ''))
            .then(res => SearchActions.set([...key, index, 'translation'], fromJS(res)))
            .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
        }
      }
    } else if (query.basetype.includes('blog') || (query.basetype.includes('comment') && !query.basetype.includes('reddit'))) {
      if (query.basetype.includes('comment') && !query.first) {
        const key = ['search', 'result', 'posts', 'data'];
        const thread = this.state.getIn(key);
        const index = thread.findIndex(v => v.get('fpid') === id);
        const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: textData };
        Api.post(TranslateUrl, request, undefined, timeout)
          .then(res => (res.ok ? res.data : ''))
          .then(res => SearchActions.set([...key, index, 'translation'], fromJS(res)))
          .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
      } else if (query.basetype.includes('blog')) {
        const key = ['search', 'result', 'meta', 'posts', 'post'];
        const text = textData;
        const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
        Api.post(TranslateUrl, request, undefined, timeout)
          .then(res => (res.ok ? res.data : ''))
          .then(res => SearchActions.set([...key, 'translation'], fromJS(res)))
          .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
      }
    } else if (query.basetype.includes('gab')) {
      if (query.first) {
        const key = ['search', 'result', 'meta', 'news', 'post'];
        const text = textData;
        const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
        Api.post(TranslateUrl, request, undefined, timeout)
          .then(res => (res.ok ? res.data : ''))
          .then(res => SearchActions.set([...key, 'translation'], fromJS(res)))
          .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
      } else {
        const key = ['search', 'result', 'news', 'data'];
        const thread = this.state.getIn(key);
        const index = thread.findIndex(v => v.get('fpid') === id);
        if (index < 0) {
          const nestedKey = ['search', 'replies', 'social', query.index, 'data'];
          const nestedReply = this.state.getIn(nestedKey);
          const nestedIndex = nestedReply.findIndex(v => v.get('fpid') === id);
          const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: textData };
          Api.post(TranslateUrl, request, undefined, timeout)
            .then(res => (res.ok ? res.data : ''))
            .then(res => SearchActions.set([...nestedKey, nestedIndex, 'translation'], fromJS(res)))
            .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
        } else {
          const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: textData };
          Api.post(TranslateUrl, request, undefined, timeout)
            .then(res => (res.ok ? res.data : ''))
            .then(res => SearchActions.set([...key, index, 'translation'], fromJS(res)))
            .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
        }
      }
    } else {
      if (!id || !textKey.length) return;
      const key = ['search', 'result', type, 'data'];
      const thread = this.state.getIn(key);
      const index = thread.findIndex(v => v.get('fpid') === id);
      const body = [...key, index, ...textKey];
      const text = this.state.getIn(body);
      const request = { ...query, client: 'gtx', sl: 'auto', dt: 't', format: 'text', q: text };
      Api.post(TranslateUrl, request, undefined, timeout)
        .then(res => (res.ok ? res.data : ''))
        .then(res => SearchActions.set([...key, index, 'translation'], fromJS(res)))
        .catch(error => SearchActions.set(['search', 'info', 'message'], error?.response?.data?.message || errorMessage));
    }
  },

  onSearchRelated(id = '') {
    const query = { limit: 5, sort: 'version_posted_at:desc', remove_styles: false };
    const key = ['search', 'result', 'reports', 'related'];
    Api.get(`${ReportsUrl}/${id}/related`, query, SearchFields.StatusOK)
      .then(res => (res.ok ? res.data : null))
      .then(res => fromJS(res.data))
      .then(res => SearchActions.set(key, res));
    SearchActions.set(key, list());
  },

  onSet(k = '', v = '', update = true) {
    this.state = Array.isArray(k) ? this.state.setIn(k, v) : this.state.set(k, v);
    if (update) this.trigger(this.state.toObject());
  },

  onUpCommentThread(type, subtype) {
    const currentOp = this.state.getIn(['search', 'result', 'meta', subtype]);
    const opFpid = currentOp.get('fpid');
    const parentFpid = currentOp.getIn(['parent_message', 'fpid']) || currentOp.getIn(['container', 'fpid']);
    SearchActions.searchComments(currentOp);
    const { pathname, query } = History.getCurrentLocation();
    const newPath = pathname.substr(0, pathname.lastIndexOf('/'));
    History.push({
      pathname: `${newPath}/${parentFpid}`,
      query: {
        ...query,
        fpid: opFpid,
        id: moment.utc(currentOp.getIn(['sort_date'])).unix(),
      },
    });
  },

  loadSiteTags(sites = [], type = '') {
    const properties = {};

    let tags = list();
    let results = sites.map((site) => {
      if (!site.tags) return Object.assign(site, { tags: [] });
      // parent tags
      [...site.tags
        .filter(v => !v.parent_tag)
        .filter(v => !properties[v.name])]
        .forEach((v) => { properties[v.name] = { ...v, name: v.name, children: {} }; });

      // children tags
      [...site.tags
        .filter(v => v.parent_tag)]
        .forEach(v => (properties[v.parent_tag.name]
          ? Object.assign(properties[v.parent_tag.name].children, {
            [v.name]: { ...v, name: v.name },
          })
          : null));

      // map tags to forum names
      return Object.assign(site, { tags:
        site.tags
          .filter(t => t.parent_tag)
          .map(t => t.name) });
    });

    switch (type) {
      case 'forums':
        tags = SiteTags.filter(v => ['Cyber Threat', 'Physical Threat', 'Reputation', 'Accessibility', 'Language'].includes(v.name));
        break;
      case 'blogs':
        tags = SiteTags.filter(v => ['Cyber Threat', 'Physical Threat', 'Language'].includes(v.name));
        break;
      case 'ransomware':
        results = results.filter(v => /ransomware/ig.test(v.title));
        break;
      default:
        break;
    }

    SearchActions.set(['search', 'properties', type], fromJS(tags));
    SearchActions.set(['search', 'sites', type], fromJS(results));
    SearchActions.loadSites.completed(results);
  },

  error(fn = () => false, service = '', action = '', display = true) {
    if (display) {
      SearchActions.set(['search', 'info'], map({
        action,
        fn,
        message: `Unable to contact ${service} service`,
      }));
    }
    // needed to help identify when to properly error handle search responses
    return { display };
  },

  universalDetail(v, k) {
    const dateFields = this.state.getIn(['search', 'sorts', k, 0, 'value'], '')
      .split(':')
      .shift()
      .split('.');
    const isSortDate = ['sort_date', 'hit_at', 'Event.date']
      .some(sort => this.state.getIn(['search', 'sorts', k, 0, 'value'], '').includes(sort));
    const dateValues = v.getIn(dateFields);
    const fields = this.state.getIn(['search', 'sorts', k, 0, 'detail']);
    const fieldValues = [
      { fieldName: 'Date',
        value: moment.utc(isSortDate
          ? dateValues
          : moment.utc(v.get('version_posted_at')).format('MMM D, YYYY HH:mm')).format('MMM D, YYYY HH:mm') },
      { fieldName: 'Platform', value: `${Text.Sentence(k)}`, icon: Common.Generic.Icon(v.get('basetypes')) },
    ];
    const values = map({ date: `<b>Date</b>: ${moment.utc(isSortDate ? dateValues : dateValues * 1000).format('MMM D, YYYY HH:mm')}` })
      .merge(map({ location: `<b>Platform</b>: ${Text.Sentence(k)}` }))
      .concat(fields
        .map((value, label) => {
          const either = typeof value === 'string' ? value.split('|') : value.get('field').split('|');
          let result = null;
          either.forEach((e) => {
            if (!result) {
              result = v.hasIn(['highlight', e, 0])
                ? v.getIn(['highlight', e, 0])
                : v.getIn(e.split('.').map(f => (Number.isNaN(Number(f)) ? f : parseInt(f, 10))));
            }
          });
          if (typeof value === 'string') {
            fieldValues.push({
              fieldName: Text.Sentence(label),
              value: Text.StripHtml(result),
              record: v,
              type: k,
            });
            return (label === 'text'
              ? `<b>Source</b>: ${value}`
              : `<b>${Text.Sentence(label)}</b>: ${Text.StripHtml(result) || '-'}`);
          }
          const field = value.get('field');
          const transformation = value.has('func') ? value.get('func') : _v => _v;
          const orAnd = either.map(e => ((e.indexOf('&') !== -1) ? e.split('&') : e));
          result = null;
          orAnd.forEach((e) => {
            if (!result) {
              if (typeof e === 'string') {
                result = v.hasIn(['highlight', e, 0])
                  ? v.getIn(['highlight', e, 0])
                  : v.getIn(e.split('.'));
              } else if (Array.isArray(e)) {
                result = e.map(a =>
                  (v.hasIn(['highlight', a, 0])
                    ? v.getIn(['highlight', a, 0])
                    : v.getIn(a.split('.'))),
                );
              }
            }
          });
          const transformed = transformation(result);
          if (transformed !== 'IGNORE') {
            fieldValues.push({
              fieldName: Text.Sentence(label),
              value: Text.StripHtml(transformed),
              key: value.get('field'),
              filter: value.get('filter'),
              record: v,
              type: k,
            });
          }
          return (label === 'text'
            ? `<b>Source</b>: ${transformation(field)}`
            : `${transformed !== 'IGNORE' ? `<b>${Text.Sentence(label)}</b>: ${Text.StripHtml(transformed || '-')}` : ''}`);
        }))
      .filter(d => d)
      .join('<br />');
    return fromJS([values, fieldValues]);
  },

  universalDescription(v, k) {
    const fields = this.state.getIn(['search', 'sorts', k, 0, 'description']);
    const values = fields
      .map((value, label) => {
        if (label === 'preview') {
          let response = [];
          if (value.count) {
            value.forEach((vk) => {
              if (!response.length) {
                const text = v.getIn(['highlight', vk, 0]) || v.getIn(vk.split('.'));
                if (text) response.push(text);
              }
            });
            response = response.join('');
          } else {
            response = (v.getIn(['highlight', value, 0]) || v.getIn(value.split('.')));
          }
          return response || '-';
        }
        return `<b>${Text.Sentence(label)}</b> ${(v.hasIn(['highlight', value, 0])
          ? Text.StripHtml(v.getIn(['highlight', value, 0])) || '-'
          : Text.StripHtml(v.getIn(value.split('.'))) || '-')}`;
      })
      .join('<br />');
    return values;
  },

  universalEnrichments(v, exporting = false) {
    const limit = exporting ? 10000 : 5;
    const format = (key, values) => {
      const deduped = [...new Set(values)]
        .sort((a, b) => a.localeCompare(b))
        .filter(_v => _v);
      return {
        [key]: {
          data: deduped,
          total: deduped.size,
        },
      };
    };

    const data = fromJS({
      ...format('bins', [
        ...v.getIn(['enrichments', 'bins', 'data'], list()),
        ...v.getIn(['enrichments', 'card-numbers', 'card-numbers'], list())
          .slice(0, limit)
          .map(_v => _v.get('bin', '').toString().replace(/\"/ig, '').replace(/\n/ig, ' ')
            .substr(0, 6)),
      ]),
      ...format('cves', [
        ...v.getIn(['enrichments', 'cves', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'vulnerability'], list())
          .filter(_v => _v.hasIn(['cve', 0, 'vulnerability']))
          .slice(0, limit)
          .map(_v => _v.getIn(['cve', 0, 'vulnerability'], '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('ips', [
        ...v.getIn(['enrichments', 'ips', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'ip_addresses'], list())
          .slice(0, limit)
          .map(_v => _v.get('ip_address', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('emails', [
        ...v.getIn(['enrichments', 'emails', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'email_addresses'], list())
          .slice(0, limit)
          .map(_v => _v.get('email_address', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('domains', [
        ...v.getIn(['enrichments', 'domains', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'urls'], list())
          .slice(0, limit)
          .map(_v => _v.get('domain', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('handles', [
        ...v.getIn(['enrichments', 'social_media', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'social_media'], list())
          .slice(0, limit)
          .map(_v => _v.get('handle', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('profiles', [
        ...v.getIn(['enrichments', 'social_media', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'social_media'], list())
          .slice(0, limit)
          .map(_v => _v.get('site', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('monero-wallets', [
        ...v.getIn(['enrichments', 'monero-wallets', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'monero_addresses'], list())
          .slice(0, limit)
          .map(_v => _v.get('monero_address', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('ethereum-wallets', [
        ...v.getIn(['enrichments', 'ethereum-wallets', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'ethereum_addresses'], list())
          .slice(0, limit)
          .map(_v => _v.get('ethereum_address', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('bitcoin-wallets', [
        ...v.getIn(['enrichments', 'bitcoin-wallets', 'data'], list()),
        ...v.getIn(['enrichments', 'v1', 'bitcoin_addresses'], list())
          .slice(0, limit)
          .map(_v => _v.get('bitcoin_address', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('image-analysis-classifications', [
        ...v.getIn(['enrichments', 'image-analysis-classifications', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'image_enrichment', 'enrichments', 'v1', 'image-analysis', 'classifications'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('image-analysis-text', [
        ...v.getIn(['enrichments', 'image-analysis-text', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'image_enrichment', 'enrichments', 'v1', 'image-analysis', 'text'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('image-analysis-logos', [
        ...v.getIn(['enrichments', 'image-analysis-logos', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'image_enrichment', 'enrichments', 'v1', 'image-analysis', 'logos'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('video-analysis-classifications', [
        ...v.getIn(['enrichments', 'video-analysis-classifications', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'video_enrichment', 'enrichments', 'v1', 'video-analysis', 'classifications'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('video-analysis-text', [
        ...v.getIn(['enrichments', 'video-analysis-text', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'video_enrichment', 'enrichments', 'v1', 'video-analysis', 'text'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
      ...format('video-analysis-logos', [
        ...v.getIn(['enrichments', 'video-analysis-logos', 'data'], list()),
        ...v.getIn(['media_v2', 0, 'video_enrichment', 'enrichments', 'v1', 'video-analysis', 'logos'], list())
          .slice(0, 10)
          .map(_v => _v.get('value', '').replace(/\"/ig, '').replace(/\n/ig, ' ')),
      ]),
    });

    return data;
  },

  async onExport(key = '', typeKey = '') {
    let buffer = '';
    const data = this.state.getIn(key);
    const type = typeKey.split('.').reverse();
    const worker = new Worker('/buffer.worker.js', import.meta.url);
    const exportCentered = this.state.getIn([...key, ...type, 'exportCentered']);
    const date = moment.utc().format('MMM-DD-YYYY_HHmm');
    const indexed = data.get('fields')
      .filter(v => v)
      .map(v => v?.id)
      .join('')
      .includes('#');

    const filename = `Flashpoint-${type}Search-${date}.csv`;
    const enrichments = data.getIn([...type, 'data', 0, 'enrichments'], map()).keySeq();
    const details = data
      .getIn([...type, 'data', 0, 'detail', 1], list()).toJS()
      .filter(v => v)
      .map((v, k) => ({ id: `detail.1.${k}.value`, label: Text.Sentence(v.fieldName), value: v.value }));
    const fields = details
      .concat([...data.get('fields')]
        .filter(v => v)
        .filter(v => v?.class !== 'actions')
        .filter(v => !/^#|[^_.]id$|featured$|index$|bookmark$|detail$|enrichments$|language$|actions$/.test(v.id))
        .filter(v => !v.omitExport)
        .filter(v => !v.requires || this.state.getIn(['search', ...v.requires.split('.')]))
        .reduce((a, b) => (b.labels
          ? a.concat(b.id.split('|').map((f, k) => ({
            ...b,
            id: f === /html+sanitized/ig.test(f) ? 'body.text/plain' : f,
            label: b.labels.split('|').splice(k).shift(),
            transformation: (b.transformations ? b.transformations[String(k)] : null),
          })))
          : a.concat(b)), []))
      .concat(...enrichments
        ? enrichments
          .map(v => ({ id: `enrichments.${v}.data`, label: Text.Sentence(v?.replace(/-/ig, ' ')) }))
        : []);
    fields.forEach((v) => {
      buffer += `${Text.Sentence(v.label)},`;
    });

    buffer += '\n';

    // JSON.stringify has a memory/length cap. Chunk resultset by 100
    const payload = (!exportCentered) ? data.getIn([...type, 'data']) : data.getIn([...type, 'data']).reverse();
    const chunks = chunk(payload.toJS(), 100);

    for (const rows of chunks) {
      worker.postMessage(JSON.stringify({
        rows,
        ind: indexed,
        cols: fields,
      }));
      // eslint-disable-next-line no-loop-func
      await new Promise((resolve) => {
        worker.onmessage = (e) => {
          buffer += e.data;
          resolve();
        };
      });
    }

    // perform additional query to get records after a sepcific point in time
    // to allow exporting around a central post
    if (exportCentered) {
      const query = this.state.getIn([...key, ...type, 'query']);
      const centeredFpid = data.getIn([...type, 'data', 0, 'fpid']);
      const updatedQuery = query
        .update('sort', v => list([v.get(0).replace('desc', 'asc')]))
        .update('query', v => v.replace(/\[\* TO (.*)\]/g, '[$1 TO *]'))
        .update('query', v => `${v} -fpid:(${centeredFpid})`)
        .toJS();
      await fetch(
        `${SearchUrl}`, {
          credentials: 'same-origin',
          method: 'POST',
          body: JSON.stringify(updatedQuery),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(res => res.json())
        .then(res => fromJS(res.hits.hits.map((v) => {
          let site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? v._source.site_actor.names.aliases || []
            : [];
          site_actor_aliases = (v._source.site_actor && v._source.site_actor.names)
            ? site_actor_aliases.filter(a => a !== v._source.site_actor.names.handle)
            : [];
          return ({
            ...v._source,
            site_actor: (!v._source.site_actor)
              ? null
              : { ...v._source.site_actor,
                names: { handle: v._source.site_actor.names.handle,
                  aliases: site_actor_aliases } } });
        })))
        .then(res => new Promise((resolve) => {
          worker.postMessage(JSON.stringify({
            rows: res,
            ind: indexed,
            cols: fields,
          }));
          worker.onmessage = (e) => {
            buffer += e.data;
            resolve();
          };
        }));
    }

    buffer = `${buffer.slice(0, -1).trim()}\n`;
    const blob = new Blob([`\ufeff${buffer}`], { type: 'text/plain;charset=utf-8' });
    SearchActions.set(['search', 'info'], map());
    FileSaver.saveAs(blob, filename);
  },

  async onLoadResources() {
    const resource = [];
    // api-only
    if (Token.get('prm')?.some(p => /acc.api.only/.test(p))) SearchActions.loadResources.completed();
    if (!this.state.hasIn(['search', 'resources', 'glossary'])) {
      resource.push({ type: 'glossary', path: 'fptools/glossary' });
    }

    Promise.all(resource.map(r =>
      Api.get(`/ui/v4/documents/${r.path}`, null, [200, 400, 500, 501, 502, 503, 504], 30000, {}, true)
        .then(res => (res.ok ? res.data : {}))
        .then(res => SearchActions.set(['search', 'resources', r.type], fromJS(res[r.type])))),
    ).then(() => SearchActions.loadResources.completed());
  },

  async onLoadSites(type) {
    const prm = Token.get('prm');
    if (!type) return;
    if (this.state.getIn(['search', 'sites', type])) return;
    if (!prm.some(v => /for|cht|mkt|vis|tor|ddw/.test(v))) return;

    // filter sites by title - if we add another chat service source that has media we need to add it to the list
    const sites = {
      media: [/telegram$/ig, /discord$/ig, /rocketchat$/ig, /qq$/ig, /element$/ig, /reddit$/ig, /gab$/ig, /ransomware blog$/ig],
    };

    // filter basetypes by type, default to *
    const basetypes = {
      accounts: '"account shop"',
      forums: '"forum"',
      blogs: '"blog"',
      ransomware: '"blog"',
      boards: '"chan"',
      chats: '"chat service"',
      media: '"chat service"',
      images: '"chat service"',
      videos: '"chat service"',
      marketplaces: '"marketplace"',
      cards: '"card shop"',
      pastes: '"paste site"',
      social: '"social network" OR reddit OR \"Social News\"',
    };

    const fields = {
      tagged: [
        'fpid',
        'title',
        'tags.name',
        'tags.parent_tag.name',
      ],
      'not-tagged': ['title', 'fpid', 'site_type'],
    };

    const query = {
      query: `+basetypes:(site)\
        +site_type:(${basetypes[String(type)] || '*'})`,
      size: 5000,
      source: true,
      _source_includes: ['blogs', 'forums'].includes(type)
        ? fields.tagged
        : fields['not-tagged'] || '',
    };

    Api.post(SearchUrl, query, SearchFields.StatusOK, 30000, {}, true)
      .then(res => (res.ok ? res.data : {}))
      .then(res => (['media'].includes(type)
        ? res.filter(v => (['Chan'].includes(v?.site_type)
            || sites[String(type)].some(p => p.test(v?.title))))
        : res))
      .then(res => (['forums', 'blogs', 'ransomware', 'social'].includes(type)
        ? this.loadSiteTags(res, type)
        : SearchActions.set(['search', 'sites', type], fromJS(res))))
      .catch(() => {
        SearchActions.loadSites.completed([]);
      });
  },

  async onLoadTerms() {
    const key = ['search', 'terms'];
    const cache = this.state.getIn(key);
    if (!cache.isEmpty()) return;

    const query = {
      size: 1,
      query: `+basetypes:(indicator_attribute)
        +Event.Tag.name:(type* OR malware* OR actor* OR tool*)"`,
      aggregations: {
        'multiple-terms': {
          category: { terms: { field: 'type', size: 100 } },
          tags: { terms: { field: 'Event.Tag.name.keyword', size: 5000 } },
        },
      },
    };

    const data = await Api.post(SearchUrl, query, SearchFields.StatusOK, 30000, {}, true)
      .then(res => (res.ok ? res.data.aggregations : []))
      .then(res => res.tags.buckets
        .concat(res.category.buckets.map(v => ({ ...v, key: `category:${v.key}` }))))
      .then(res => fromJS(res
        .filter(v => new RegExp(/^(category|type|malware|actor|tool)/, 'ig').test(v.key))
        .reduce((a, b) => {
          const field = b.key?.split(':')?.at(0);
          const value = { ...b, key: b?.key?.replace(/category:/ig, '') };
          return ({
            ...a,
            [field]: (a[String(field)] ?? []).concat(value),
          });
        }, {}),
      ));

    SearchActions.set([...key], data);
  },
});

export default SearchStore;
