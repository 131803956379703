export default
{
  '':
  {
    name: 'Not Available',
  },
  '-':
  {
    name: 'Not Available',
  },
  en:
  {
    name: 'English',
  },
  ru:
  {
    name: 'Russian',
  },
  ar:
  {
    name: 'Arabic',
  },
  ab:
  {
    name: 'Abkhaz',
  },
  aa:
  {
    name: 'Afar',
  },
  af:
  {
    name: 'Afrikaans',
  },
  ak:
  {
    name: 'Akan',
  },
  sq:
  {
    name: 'Albanian',
  },
  'en:':
  {
    name: 'American',
  },
  am:
  {
    name: 'Amharic',
  },
  an:
  {
    name: 'Aragonese',
  },
  hy:
  {
    name: 'Armenian',
  },
  as:
  {
    name: 'Assamese',
  },
  av:
  {
    name: 'Avaric',
  },
  ae:
  {
    name: 'Avestan',
  },
  ay:
  {
    name: 'Aymara',
  },
  az:
  {
    name: 'Azerbaijani',
  },
  bm:
  {
    name: 'Bambara',
  },
  ba:
  {
    name: 'Bashkir',
  },
  eu:
  {
    name: 'Basque',
  },
  be:
  {
    name: 'Belarusian',
  },
  bn:
  {
    name: 'Bengali',
  },
  bh:
  {
    name: 'Bihari',
  },
  bi:
  {
    name: 'Bislama',
  },
  bs:
  {
    name: 'Bosnian',
  },
  br:
  {
    name: 'Breton',
  },
  bg:
  {
    name: 'Bulgarian',
  },
  my:
  {
    name: 'Burmese',
  },
  ca:
  {
    name: 'Catalan; Valencian',
  },
  ch:
  {
    name: 'Chamorro',
  },
  ce:
  {
    name: 'Chechen',
  },
  ny:
  {
    name: 'Chichewa; Chewa; Nyanja',
  },
  zh:
  {
    name: 'Chinese',
  },
  'zh-CN': {
    name: 'Chinese (Simplified)',
  },
  'zh-TW': {
    name: 'Chinese (Traditional)',
  },
  cv:
  {
    name: 'Chuvash',
  },
  kw:
  {
    name: 'Cornish',
  },
  co:
  {
    name: 'Corsican',
  },
  cr:
  {
    name: 'Cree',
  },
  hr:
  {
    name: 'Croatian',
  },
  cs:
  {
    name: 'Czech',
  },
  da:
  {
    name: 'Danish',
  },
  dv:
  {
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  nl:
  {
    name: 'Dutch',
  },
  eo:
  {
    name: 'Esperanto',
  },
  et:
  {
    name: 'Estonian',
  },
  ee:
  {
    name: 'Ewe',
  },
  fo:
  {
    name: 'Faroese',
  },
  fj:
  {
    name: 'Fijian',
  },
  fi:
  {
    name: 'Finnish',
  },
  fr:
  {
    name: 'French',
  },
  ff:
  {
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  gl:
  {
    name: 'Galician',
  },
  ka:
  {
    name: 'Georgian',
  },
  de:
  {
    name: 'German',
  },
  el:
  {
    name: 'Greek, Modern',
  },
  gn:
  {
    name: 'Guaraní',
  },
  gu:
  {
    name: 'Gujarati',
  },
  ht:
  {
    name: 'Haitian; Haitian Creole',
  },
  ha:
  {
    name: 'Hausa',
  },
  he:
  {
    name: 'Hebrew (modern)',
  },
  hz:
  {
    name: 'Herero',
  },
  hi:
  {
    name: 'Hindi',
  },
  ho:
  {
    name: 'Hiri Motu',
  },
  hu:
  {
    name: 'Hungarian',
  },
  ia:
  {
    name: 'Interlingua',
  },
  id:
  {
    name: 'Indonesian',
  },
  ie:
  {
    name: 'Interlingue',
  },
  ga:
  {
    name: 'Irish',
  },
  ig:
  {
    name: 'Igbo',
  },
  ik:
  {
    name: 'Inupiaq',
  },
  io:
  {
    name: 'Ido',
  },
  is:
  {
    name: 'Icelandic',
  },
  it:
  {
    name: 'Italian',
  },
  iu:
  {
    name: 'Inuktitut',
  },
  ja:
  {
    name: 'Japanese',
  },
  jv:
  {
    name: 'Javanese',
  },
  kl:
  {
    name: 'Kalaallisut, Greenlandic',
  },
  kn:
  {
    name: 'Kannada',
  },
  kr:
  {
    name: 'Kanuri',
  },
  ks:
  {
    name: 'Kashmiri',
  },
  kk:
  {
    name: 'Kazakh',
  },
  km:
  {
    name: 'Khmer',
  },
  ki:
  {
    name: 'Kikuyu, Gikuyu',
  },
  rw:
  {
    name: 'Kinyarwanda',
  },
  ky:
  {
    name: 'Kirghiz, Kyrgyz',
  },
  kv:
  {
    name: 'Komi',
  },
  kg:
  {
    name: 'Kongo',
  },
  ko:
  {
    name: 'Korean',
  },
  ku:
  {
    name: 'Kurdish',
  },
  kj:
  {
    name: 'Kwanyama, Kuanyama',
  },
  la:
  {
    name: 'Latin',
  },
  lb:
  {
    name: 'Luxembourgish, Letzeburgesch',
  },
  lg:
  {
    name: 'Luganda',
  },
  li:
  {
    name: 'Limburgish, Limburgan, Limburger',
  },
  ln:
  {
    name: 'Lingala',
  },
  lo:
  {
    name: 'Lao',
  },
  lt:
  {
    name: 'Lithuanian',
  },
  lu:
  {
    name: 'Luba-Katanga',
  },
  lv:
  {
    name: 'Latvian',
  },
  gv:
  {
    name: 'Manx',
  },
  mk:
  {
    name: 'Macedonian',
  },
  mg:
  {
    name: 'Malagasy',
  },
  ms:
  {
    name: 'Malay',
  },
  ml:
  {
    name: 'Malayalam',
  },
  mt:
  {
    name: 'Maltese',
  },
  mi:
  {
    name: 'Māori',
  },
  mr:
  {
    name: 'Marathi (Marāṭhī)',
  },
  mh:
  {
    name: 'Marshallese',
  },
  mn:
  {
    name: 'Mongolian',
  },
  na:
  {
    name: 'Nauru',
  },
  nv:
  {
    name: 'Navajo, Navaho',
  },
  nb:
  {
    name: 'Norwegian Bokmål',
  },
  nd:
  {
    name: 'North Ndebele',
  },
  ne:
  {
    name: 'Nepali',
  },
  ng:
  {
    name: 'Ndonga',
  },
  nn:
  {
    name: 'Norwegian Nynorsk',
  },
  no:
  {
    name: 'Norwegian',
  },
  ii:
  {
    name: 'Nuosu',
  },
  nr:
  {
    name: 'South Ndebele',
  },
  oc:
  {
    name: 'Occitan',
  },
  oj:
  {
    name: 'Ojibwe, Ojibwa',
  },
  cu:
  {
    name: 'Old Church Slavonic',
  },
  om:
  {
    name: 'Oromo',
  },
  or:
  {
    name: 'Oriya',
  },
  os:
  {
    name: 'Ossetian, Ossetic',
  },
  pa:
  {
    name: 'Panjabi, Punjabi',
  },
  pi:
  {
    name: 'Pāli',
  },
  fa:
  {
    name: 'Persian',
  },
  pl:
  {
    name: 'Polish',
  },
  ps:
  {
    name: 'Pashto, Pushto',
  },
  pt:
  {
    name: 'Portuguese',
  },
  qu:
  {
    name: 'Quechua',
  },
  rm:
  {
    name: 'Romansh',
  },
  rn:
  {
    name: 'Kirundi',
  },
  ro:
  {
    name: 'Romanian',
  },
  sa:
  {
    name: 'Sanskrit (Saṁskṛta)',
  },
  sc:
  {
    name: 'Sardinian',
  },
  sd:
  {
    name: 'Sindhi',
  },
  se:
  {
    name: 'Northern Sami',
  },
  sm:
  {
    name: 'Samoan',
  },
  sg:
  {
    name: 'Sango',
  },
  sr:
  {
    name: 'Serbian',
  },
  gd:
  {
    name: 'Scottish Gaelic',
  },
  sn:
  {
    name: 'Shona',
  },
  si:
  {
    name: 'Sinhala, Sinhalese',
  },
  sk:
  {
    name: 'Slovak',
  },
  sl:
  {
    name: 'Slovene',
  },
  so:
  {
    name: 'Somali',
  },
  st:
  {
    name: 'Southern Sotho',
  },
  es:
  {
    name: 'Spanish; Castilian',
  },
  su:
  {
    name: 'Sundanese',
  },
  sw:
  {
    name: 'Swahili',
  },
  ss:
  {
    name: 'Swati',
  },
  sv:
  {
    name: 'Swedish',
  },
  ta:
  {
    name: 'Tamil',
  },
  te:
  {
    name: 'Telugu',
  },
  tg:
  {
    name: 'Tajik',
  },
  th:
  {
    name: 'Thai',
  },
  ti:
  {
    name: 'Tigrinya',
  },
  bo:
  {
    name: 'Tibetan Standard',
  },
  tk:
  {
    name: 'Turkmen',
  },
  tl:
  {
    name: 'Tagalog',
  },
  tn:
  {
    name: 'Tswana',
  },
  to:
  {
    name: 'Tonga',
  },
  tr:
  {
    name: 'Turkish',
  },
  ts:
  {
    name: 'Tsonga',
  },
  tt:
  {
    name: 'Tatar',
  },
  tw:
  {
    name: 'Twi',
  },
  ty:
  {
    name: 'Tahitian',
  },
  ug:
  {
    name: 'Uighur',
  },
  uk:
  {
    name: 'Ukrainian',
  },
  ur:
  {
    name: 'Urdu',
  },
  uz:
  {
    name: 'Uzbek',
  },
  ve:
  {
    name: 'Venda',
  },
  vi:
  {
    name: 'Vietnamese',
  },
  vo:
  {
    name: 'Volapük',
  },
  wa:
  {
    name: 'Walloon',
  },
  cy:
  {
    name: 'Welsh',
  },
  wo:
  {
    name: 'Wolof',
  },
  fy:
  {
    name: 'Western Frisian',
  },
  xh:
  {
    name: 'Xhosa',
  },
  yi:
  {
    name: 'Yiddish',
  },
  yo:
  {
    name: 'Yoruba',
  },
  za:
  {
    name: 'Zhuang, Chuang',
  },
  'n/a':
  {
    name: '-',
  },
};
