import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { Link, Grid, Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    borderRadius: '2px !important',
    boxShadow: '0 0 6px rgba(0, 0, 0, .12), 0 0px 4px rgba(0, 0, 0, .24) !important',
    padding: '20px',
    margin: '16px 0',
  },
  result: {
    borderRadius: '2px !important',
    boxShadow: '0 0 2px rgba(0, 0, 0, .12) !important',
    margin: '0.5rem 1.2rem',
  },
});

const UsernameCheck = ({
  result,
  resultLength,
  resultNumber,
}) => {
  const classes = useStyles();
  const usernameHits = Object.entries(result.results);

  return (
    <Grid className={classes.root} container direction="column">
      <Grid>
        <Typography align="right" variant="subtitle2" style={{ fontSize: '12px' }}>{resultNumber} of {resultLength}</Typography>
        <Typography gutterBottom variant="subtitle1">{result.username}</Typography>
      </Grid>
      <Grid container item spacing={1}>
        {usernameHits
          .filter(v => v[1].safe_for_work)
          .map(v => (
            <Grid className={classes.result} container item justifyContent="center" xs={2}>
              <Link href={v[1].uri} target="_blank">{v[0]}</Link>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
};

UsernameCheck.propTypes = {
    result: PropTypes.object.isRequired,
    resultLength: PropTypes.number.isRequired,
    resultNumber: PropTypes.number.isRequired,
  };

export default UsernameCheck;
