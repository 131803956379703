import * as React from 'react';
import { useEffect } from 'react';

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { isEmpty, isEqual } from 'lodash';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import useQueryString from '../../../hooks/useQueryString';
import Looker from '../../utils/Looker';
import History from '../../../utils/history';
import { getSfid } from '../../../stores/recoil/app';
import { cfmFiltersBins } from '../../../stores/recoil/cfm';
import { sanitizeFilters } from '../../../hooks/useLookerApi';

const useStyles = makeStyles(() => ({
  cards: {
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
  },
}));

const BinsExport = () => {
  const [bin] = useQueryString('BIN');
  const [binTags] = useQueryString('BIN Tags');

  const classes = useStyles();
  const sid = useRecoilValue(getSfid);
  const [BinsExportFilters, setBinsExportFilters] = useRecoilState(cfmFiltersBins);
  const filterReset = useResetRecoilState(cfmFiltersBins);

  const { pathname, query } = History.getCurrentLocation();

  useEffect(() => {
    setBinsExportFilters({
      BIN: bin,
      'BIN Tags': binTags,
    });
  }, [bin, binTags]);

  useEffect(() => {
    const sanitized = sanitizeFilters(BinsExportFilters);

    if (!isEqual(query, sanitized)) {
      if (!isEmpty(sanitized)) {
        setBinsExportFilters({
          ...sanitized,
        });

        History.push({
          pathname,
          query: {
            ...sanitized,
          },
        });
      }
    }
  }, [BinsExportFilters]);

  useEffect(() => () => {
    filterReset();
  }, []);

  if (!sid) {
    return <CircularProgress />;
  }

  return (
    <Grid fluid className={classes.cards}>
      <Row>
        <Col xs={12}>
          <Looker
            height="110rem"
            dashboard="/dashboards-next/shops_production::manage_bins_export"
            filters={BinsExportFilters}
            setFilters={setBinsExportFilters}
            sid={sid} />
        </Col>
      </Row>
    </Grid>
  );
};

export default BinsExport;
