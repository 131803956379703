import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './manage.module.scss';
import History from '../../utils/history';
import Searches from '../../components/manage/Searches';
import Bookmarks from '../../components/manage/Bookmarks';
import { UserContext } from '../../components/utils/Context';

const Manage = ({
  location,
  match: {
    params,
  },
}) => {
  const user = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
    const { pathname, query, hash } = History.getCurrentLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { edit, ...q } = query;
    History.push({ pathname, query: q, hash });
  }, []);

  return (
    <Grid fluid className={style.search}>
      <Row>
        <Col xs={12}>
          {['searches'].includes(params.type) &&
          <Searches
            user={user}
            apps={user.get('apps')}
            data={user.getIn(['prefs', 'search'])}
            location={location}
            type={params.type} />}
          {['bookmarks'].includes(params.type) &&
          <Bookmarks
            user={user}
            apps={user.get('apps')}
            data={user.getIn(['prefs', 'bookmarks'])}
            location={location}
            type={params.type} />}
        </Col>
      </Row>
    </Grid>
  );
};

Manage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};


export default Manage;
