import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Badge,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Icon,
  InputAdornment,
  OutlinedInput,
  Table as TableUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useRecoilState, useRecoilValue } from 'recoil';
import moment from 'moment';

import style from './management.module.scss';
import Editor from '../manage/Editor';
import Aggregations from './Aggregations';
import AlertingSubscriptionDialog from './AlertingSubscriptionDialog';
import AlertingKeywordDialog from './AlertingKeywordDialog';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import ToolTip from '../utils/Ellipsis';
import Messages from '../../constants/Messages';
import Pager from '../utils/Pager';
import BulkEditButton from '../utils/BulkEditButton';
import Query from '../../containers/Alerting/query';
import SearchActions from '../../actions/searchActions';
import AlertingStore from '../../stores/recoil/alerting';
import TimeoutMessage from './TimeoutMessage';
import History from '../../utils/history';
import { validateKeywordValue } from './validation';

const useStyles = makeStyles(theme => ({
  management: {
    width: '100%',
    '& .filters': {
      display: 'flex',
      height: '2.4rem',
      '& .MuiButton-root': {
        margin: `0 ${theme.spacing(1)}`,
      },
      '& .MuiOutlinedInput-input': {
        padding: '.5rem',
      },
    },
    '& .MuiBadge-badge': {
      height: '1.2rem',
      minWidth: '1.2rem',
      right: '-.3rem',
      top: '.5rem',
    },
  },
  alertingDialog: {
    '& .filters .MuiButton-root': {
      margin: `0 ${theme.spacing(1)}`,
    },
  },
  managementTable: {
    '& .MuiTableCell-head': {
      whiteSpace: 'initial',
    },
  },
}));

const Management = ({
  email,
  prefs,
  prm,
}) => {
  const classes = useStyles();
  const location = History.getCurrentLocation();
  const basetypes = useRecoilValue(AlertingStore.basetypes);
  const defaults = useRecoilValue(AlertingStore.defaultFilters);
  const [filters, setFilters] = useRecoilState(AlertingStore.filters);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);
  const [keywords, setKeywords] = useRecoilState(AlertingStore.keywords);
  const [keywordsError] = useRecoilState(AlertingStore.keywordError);
  const [recipients, setRecipients] = useRecoilState(AlertingStore.notificationProfiles);
  const owner = useRecoilValue(AlertingStore.owner);
  const products = useRecoilValue(AlertingStore.products);
  const type = useRecoilValue(AlertingStore.type);

  const [filterText, setFilterText] = useState('');
  const [canEditKeywords, setCanEditKeywords] = useState(false);
  const [canEditNotificationProfiles, setCanEditNotificationProfiles] = useState(false);
  const [hasGridSelection, setHasGridSelection] = useState(false);
  const [data, setData] = useState(map());
  const [dialog, setDialog] = useState();
  const [selected, setSelected] = useState([]);
  const [subscription, setSubscription] = useState(map());
  const [canCurate, setCanCurate] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const prevLocationRef = useRef();

  const hasIndustries = owner.get('industries', list()).size !== 0;
  const hasEnterpriseDomains = owner.get('has_enterprise_domains', false);

  const onCellClick = (rowIndex, columnIndex) => {
    const row = data.getIn(['data', rowIndex, 'id']);
    if (columnIndex >= 0) {
      setSelected([row]);
      setDialog(type);
    } else if (selected.includes(row)) {
      setSelected(selected.filter(v => v !== row));
    } else setSelected([...selected, row]);
  };

  const onDeleteRecipients = () => {
    Query.deleteNotificationProfiles(selected, owner.get('id'));
    // remove selected keywords from keywords
    const updatedData = recipients?.get('data')?.filterNot(v => selected.includes(v.get('id')));
    const updated = recipients
      .set('data', updatedData)
      .set('total', recipients?.get('total') - selected.length);
    setKeywords(updated);
  };

  const onExport = (columns) => {
    SearchActions.set(['search', 'info', 'message'], Messages.Exporting);
    Query.exportData(type, columns, filters, owner.get('id'), keywordClasses, recipients, basetypes, prm);
  };

  const onFilter = (k, v) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    History.push({
      pathname,
      query: fromJS({
        ...query,
        [k]: v,
        skip: undefined,
      }).filter(f => f != null).toJS(),
      hash,
    });
    setFilters(filters.set(k, v));
    setDialog();
  };

  const onSaveRecipients = async (_, values) => {
    const promises = [];
    values.forEach((value) => {
      if (!value.has('id')) {
        // Creating a new notification profile
        const profile = {
          endpoint: Text.StripHtml(value.get('endpoint')),
          active: value.get('active'),
          curated: value.get('curated'),
          industry: value.get('industry'),
          credential: value.get('credential'),
          domainMonitoring: value.get('domain_monitoring'),
          infrastructureMonitoring: value.get('infrastructure_monitoring'),
          cloudInfrastructure: value.get('cloud_infrastructure'),
          twitter: value.get('twitter'),
        };
        const promise = Query.saveNotificationProfile(profile, type, recipients?.get('data'), products, owner.get('id'), owner.get('industries', list()));
        promises.push(promise);
        return;
      }
      const curatedProduct = products.find(v => v.get('pretty_name') === 'Curated');
      const industryProduct = products.find(v => v.get('pretty_name') === 'Industry');
      const credentialProduct = products.find(v => v.get('pretty_name') === 'Credentials');
      const domainMonitoringProduct = products.find(v => v.get('pretty_name') === 'Domain Monitoring');
      const infrastructureMonitoringProduct = products.find(v => v.get('pretty_name') === 'Internet Infrastructure Monitoring');
      const cloudInfrastructureProduct = products.find(v => v.get('pretty_name') === 'Cloud Infrastructure Monitoring');
      const twitterProduct = products.find(v => v.get('pretty_name') === 'Twitter Alerting');

      let subs = value.get('subscriptions').map((v) => {
        switch (true) {
          case v.get('product_id') === curatedProduct.get('id'):
            return v.set('active', value.get('curated'));
          case v.get('product_id') === industryProduct.get('id'):
            return v.set('active', value.get('industry'));
          case v.get('product_id') === credentialProduct.get('id'):
            return v.set('active', value.get('credential'));
          case v.get('product_id') === domainMonitoringProduct.get('id'):
            return v.set('active', value.get('domain_monitoring'));
          case v.get('product_id') === infrastructureMonitoringProduct.get('id'):
            return v.set('active', value.get('infrastructure_monitoring'));
          case v.get('product_id') === cloudInfrastructureProduct.get('id'):
            return v.set('active', value.get('cloud_infrastructure'));
          case v.get('product_id') === twitterProduct.get('id'):
            return v.set('active', value.get('twitter'));
          default:
            return v;
        }
      });
      const curatedSubIndex = subs.findIndex(v => v.get('product_id') === curatedProduct.get('id'));
      if (curatedSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('curated'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: curatedProduct.get('id'),
        }));
      }
      const industrySubIndex = subs.findIndex(v => v.get('product_id') === industryProduct.get('id'));
      if (industrySubIndex === -1) {
        // Need to add the new subscription for each industry
        const industryIds = owner.get('industries', list()).map(ind => ind.get('id'));
        industryIds.forEach((indId) => {
          subs = subs.push(fromJS({
            active: value.get('industry'),
            notification_profile_id: value.get('id'),
            industry_id: indId,
            owner_id: null,
            product_id: industryProduct.get('id'),
          }));
        });
      }
      const credentialSubIndex = subs.findIndex(v => v.get('product_id') === credentialProduct.get('id'));
      if (credentialSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('credential'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: credentialProduct.get('id'),
        }));
      }
      const domainMonitoringSubIndex = prm.some(p => /dat.dm.r/.test(p)) ?
        subs.findIndex(v => v.get('product_id') === domainMonitoringProduct.get('id')) : -2;
      if (prm.some(p => /dat.dm.r/.test(p)) && domainMonitoringSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('domain_monitoring'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: domainMonitoringProduct.get('id'),
        }));
      }
      const infrastructureMonitoringSubIndex = prm.some(p => /dea/.test(p)) ?
        subs.findIndex(v => v.get('product_id') === infrastructureMonitoringProduct.get('id')) : -2;
      if (prm.some(p => /dea/.test(p)) && infrastructureMonitoringSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('infrastructure_monitoring'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: infrastructureMonitoringProduct.get('id'),
        }));
      }
      const cloudInfrastructureSubIndex = prm.some(p => /dea/.test(p)) ?
        subs.findIndex(v => v.get('product_id') === cloudInfrastructureProduct.get('id')) : -2;
      if (prm.some(p => /dea/.test(p)) && cloudInfrastructureSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('cloud_infrastructure'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: cloudInfrastructureProduct.get('id'),
        }));
      }
      const twitterSubIndex = prm.some(p => /twtr/ig.test(p)) ?
        subs.findIndex(v => v.get('product_id') === twitterProduct.get('id')) : -2;
      if (prm.some(p => /twtr/ig.test(p)) && twitterSubIndex === -1) {
        // Need to add the new subscription
        subs = subs.push(fromJS({
          active: value.get('twitter'),
          notification_profile_id: value.get('id'),
          owner_id: value.get('owner_id'),
          product_id: twitterProduct.get('id'),
        }));
      }
      const profile = {
        endpoint: value.get('endpoint'),
        active: value.get('active'),
        curated: value.get('curated'),
        industry: value.get('industry'),
        credential: value.get('credential'),
        domainMonitoring: value.get('domain_monitoring'),
        infrastructureMonitoring: value.get('infrastructure_monitoring'),
        cloudInfrastructure: value.get('cloud_infrastructure'),
        twitter: value.get('twitter'),
        subscriptions: subs.toJS(),
        id: value.get('id'),
      };
      const promise = Query.saveNotificationProfile(profile, type, recipients?.get('data'), products, owner.get('id'), owner.get('industries', list()));
      promises.push(promise);
    });
    await Promise.all(promises)
      .then((res) => {
        let updatedData = recipients?.get('data');
        res.forEach((v) => {
          if (v.index != null) {
            updatedData = updatedData
              .set(v.index, v.recipient);
          } else {
            updatedData = updatedData.push(v.recipient);
          }
        });
        const updated = recipients
          .set('data', updatedData);
        setRecipients(updated);
      });
    setDialog('');
    setSelected([]);
  };

  const onBulkSave = async (selection, values, extra) => {
    const value = { ...values.toJS() };
    if (type === 'keywords') {
      // *** Validate keywords before changing ***
      const rawKeywords = keywords.get('data');
      let invalidKeyword = false;
      // 1. Find keywords that are being changed
      if (values.get('keyclass_id', false)) {
        rawKeywords.filter(v => selection.includes(v.get('id')))
          .map((v) => {
            // 2. Apply category change to each
            const kw = v.toJS();
            const kwc = keywordClasses
              .find(v2 => v2.get('id') === values.get('keyclass_id'))
              .toJS();
            return fromJS({
              ...kw,
              keyclass: kwc,
              keyclass_id: kwc.id,
              category: {
                label: kwc.name,
              },
            });
          })
          .forEach((v) => {
            // 3. Validate each
            const result = validateKeywordValue(v);
            if (result.error) {
              SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(result?.error));
              invalidKeyword = true;
            }
        });
      }
      if (invalidKeyword) {
        return;
      }

      // Update the keywords
      SearchActions.set(['search', 'info'], fromJS({
        message: 'Updating Keywords',
      }).set('action', (<CircularProgress />)));
      const promise = Query.saveKeywords(value, selection, type, filters, keywordClasses, basetypes, keywords.get('data'), owner.get('id'), prm);
      await Promise.all([promise])
        .then(([res]) => {
          const updated = keywords
            .set('data', res);
          setKeywords(updated);
        });
      setSelected([]);
    }
    if (type === 'recipients') {
      const profiles = data.get('data').filter(v => selection.includes(v.get('id')));
      const { opposite } = extra;
      const updatedProfiles = profiles.map((v) => {
        let profile = v;
        values.forEach((val, key) => {
          profile = profile.setIn(key.split('.'), (!opposite) ? val : !val);
        });
        return profile;
      });
      onSaveRecipients(null, updatedProfiles);
    }
  };

  const onBulkDelete = (selection) => {
    if (type === 'keywords') {
      Query.deleteKeywords(selection, owner.get('id')).then(() => setRefresh(!refresh));
      // remove selected keywords from keywords
      const updatedData = keywords.get('data').filterNot(v => selection.includes(v.get('id')));
      const updated = keywords
        .set('data', updatedData)
        .set('total', keywords.get('total') - selection.size);
      setKeywords(updated);
      setSelected([]);
    }
  };

  const onSelect = (rows) => {
    // const total = data.get('total');
    // const indices = rows === 'all' ? Array.from(Array(total).keys()) : rows;
    // const selection = indices.map(v => data.getIn(['data', v, 'id']));
    const selection = rows === 'all'
      ? data.get('data', list()).map(v => v.get('id')).toJS()
      : rows.map(v => data.getIn(['data', v, 'id']));
    setSelected(selection);
  };

  const onSubscriptions = async (row, evt, edit = false) => {
    if (type === 'keywords') {
      let updatedRow = row;
      if (!row.has('keywordSubscriptions')) {
        const subscribedProfileIds = row.get('keyword_subscriptions', list()).map(v => v.get('notification_profile_id'));
        const receivesEmailIds = {};
        row.get('keyword_subscriptions', list()).forEach((v) => {
          receivesEmailIds[v.get('notification_profile_id')] = v.get('receives_email');
        });
        const profiles = subscribedProfileIds.map((v) => {
          const recipient = recipients?.get('data')?.find(r => r.get('id') === v, null, map());
          const receivesEmail = receivesEmailIds[String(v)];
          return map({
            endpoint: recipient.get('endpoint'),
            id: recipient.get('id'),
            isSubscribed: true,
            receivesEmail,
          });
        });
        const keywordIndex = keywords.get('data', list()).findIndex(v => v.get('id') === row.get('id'));
        updatedRow = keywords.getIn(['data', keywordIndex]).set('keywordSubscriptions', profiles.sortBy(v => v.get('endpoint')));
        const updatedKeywords = keywords.setIn(['data', keywordIndex], updatedRow);
        setKeywords(updatedKeywords);
      }
      setSubscription(updatedRow);
    } else {
      setSubscription(row);
    }

    // display subcription edit dialog
    if (edit) {
      evt.stopPropagation();
      setDialog('subscriptions');
    }
  };

  const columns = () => {
    switch (type) {
      case 'recipients':
        return [
          { id: 'value',
            label: 'Recipient',
            text: 'Email address of the alert recipient',
            tooltip: v => Text.StripHtml(v.getIn(['value'])),
            render: v => (<div>{v.getIn(['value'])}</div>) },
          { id: 'active',
            label: 'Automated Alerts',
            text: 'Whether recipient receives automated alerts',
            render: v => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip={v.get('active')
                  ? 'This email address will receive automated alerts on keywords they are subscribed to.'
                  : 'This email address will not receive automated alerts'}>
                {v.get('active') ? 'check_circle' : 'notifications_off'}
              </Icon>) },
          { id: 'curated',
            label: 'Curated Alerts',
            text: 'Whether recipient receives curated alerts',
            render: v => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip={v.get('curated')
                  ? 'This email address will receive curated alerts on keywords they are subscribed to.'
                  : 'This email address will not receive curated alerts'}>
                {v.get('curated') ? 'check_circle' : 'notifications_off'}
              </Icon>) },
          { id: 'industry',
            label: 'Industry Alerts',
            text: 'Whether recipient receives industry alerts',
            render: v => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip={v.get('industry')
                  ? 'This email address will receive industry alerts.'
                  : 'This email address will not receive industry alerts'}>
                {v.get('industry') ? 'check_circle' : 'notifications_off'}
              </Icon>) },
          { id: 'credential',
            label: 'Credential Alerts',
            text: 'Whether recipient receives credential alerts',
            render: v => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip={v.get('credential')
                  ? 'This email address will receive credential alerts on credentials within the organization\'s domains.'
                  : 'This email address will not receive credential alerts'}>
                {v.get('credential') ? 'check_circle' : 'notifications_off'}
              </Icon>) },
          ...(prm.some(p => /dat.dm.r/.test(p)))
            ? [
              { id: 'domain_monitoring',
                label: 'Domain Monitoring Alerts',
                text: 'Whether recipient receives domain monitoring alerts',
                render: v => (
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    data-for="table.tooltip"
                    data-tip={v.get('domain_monitoring')
                    ? 'This email address will receive domain monitoring alerts on phishing within the organization\'s domains.'
                    : 'This email address will not receive domain monitoring alerts'}>
                    {v.get('domain_monitoring') ? 'check_circle' : 'notifications_off'}
                  </Icon>),
              },
            ]
            : [],
          ...(prm.some(p => /dea/.test(p)))
            ? [
              { id: 'infrastructure_monitoring',
                label: 'Internet Infrastructure Monitoring Alerts',
                text: 'Whether recipient receives internet infrastructure monitoring alerts',
                render: v => (
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    data-for="table.tooltip"
                    data-tip={v.get('infrastructure_monitoring')
                    ? 'This email address will receive internet infrastrucure monitoring alerts.'
                    : 'This email address will not receive internet infrastructure monitoring alerts'}>
                    {v.get('infrastructure_monitoring') ? 'check_circle' : 'notifications_off'}
                  </Icon>),
              },
            ]
            : [],
          ...(prm.some(p => /dea/.test(p)))
            ? [
              { id: 'cloud_infrastructure',
                label: 'Cloud Infrastructure Alerts',
                text: 'Whether recipient receives cloud infrastrucure alerts',
                render: v => (
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    data-for="table.tooltip"
                    data-tip={v.get('cloud_infrastructure')
                    ? 'This email address will receive cloud infrastrucure alerts on phishing within the organization\'s domains.'
                    : 'This email address will not receive cloud infrastrucure alerts'}>
                    {v.get('cloud_infrastructure') ? 'check_circle' : 'notifications_off'}
                  </Icon>),
              },
            ]
            : [],
          ...(prm.some(p => /twtr/ig.test(p)))
            ? [
              { id: 'twitter',
                label: 'Twitter Alerts',
                text: 'Whether recipient receives Twitter alerts',
                render: v => (
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    data-for="table.tooltip"
                    data-tip={v.get('twitter')
                    ? 'This email address will receive Twitter alerts.'
                    : 'This email address will not receive Twitter alerts'}>
                    {v.get('twitter') ? 'check_circle' : 'notifications_off'}
                  </Icon>),
              },
            ]
            : [],
          { id: 'recipient.subscriptions',
            label: 'Subscriptions',
            text: 'Manage Subscriptions',
            subscriptions: true,
            render: () => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip="Manage Subscriptions">
                email
              </Icon>) }];
      case 'keywords':
      default:
        return [
          { id: 'active',
            label: 'Status',
            labels: 'status',
            text: 'Status of the keyword',
            render: (v) => {
              let tip;
              let icon;
              let className;
              if (!v.get('active')) {
                tip = 'This keyword is currently disabled. It does not count against your keyword total & is not being searched for actively.';
                icon = 'notifications_off';
                className = style.icon; }
              else if (v.get('is_curated')) {
                tip = 'This keyword is enabled and hits are being sent to Flashpoint\'s Tactical Threat Monitoring team for curation.';
                icon = 'check_circle';
                className = style.icon; }
              // Condition the icon based on if # of subscriptions > 0, see https://jira.s.fpint.net/browse/PLATFORM-312
              else if (v.get('keyword_subscriptions', list()).size > 0) {
                tip = 'This keyword is enabled and has active recipients. Flashpoint is searching newly ingested data for hits.';
                icon = 'check_circle';
                className = style.icon; }
              else {
                tip = 'This keyword is currently paused. It was turned on, but has no active recipients. Add a recipient to enable this keyword & start searching for hits.';
                icon = 'warning';
                className = style.iconOrange; }
              return (
                <Icon
                  className={cx([className, 'material-icons'])}
                  data-for="table.tooltip"
                  data-tip={tip}>
                  {icon}
                </Icon>);
            },
          },
          { id: 'name',
            label: 'Name',
            text: 'Name for the search term',
            tooltip: v => (v.getIn(['name']) ? v.getIn(['name']) : '-'),
            render: (v) => {
              const date = v.getIn(['created']) ? moment(v.getIn(['created'])).format('M[/]D[/]YYYY') : 'N/A';
              return <Tooltip title={`This keyword was created on ${date}`} placement="top-start"><span>{v.getIn(['name']) ? v.getIn(['name']) : '-'}</span></Tooltip>;
            },
          },
          { id: 'keyclass.name',
            label: 'Category',
            text: 'Type of keyword',
            render: (v) => {
              if (v.getIn(['keyclass', 'name'])) {
                return Text.Sentence(v.getIn(['keyclass', 'name']));
              }
              if (!keywordClasses.isEmpty()) {
                const label = keywordClasses.find(kc => kc.get('id') === v.get('keyclass_id'));
                return Text.Sentence(label?.get('name'));
              }
              return 'Content Unavailable';
            } },
          { id: 'value',
            label: 'Keyword',
            text: 'Keyword text to be alerted on',
            tooltip: v => Text.StripHtml(v.getIn(['value'])),
            render: v => (<div>{v.getIn(['value'])}</div>) },
          { id: 'curation.status',
            label: 'Delivery',
            text: 'Status of delivery method',
            render: v => (
              <Icon
                className={cx([style.icon, 'material-icons'])}
                data-for="table.tooltip"
                data-tip={v.getIn(['curation', 'status'])
                  ? 'Curated Alerting Keyword - Alert emails are sent to the Flashpoint team to triage, relevant hits are forwarded to your inbox'
                  : 'Automated Alerting Keyword - Alert emails are sent directly to your inbox'}>
                {v.getIn(['curation', 'status']) ? 'face' : 'track_changes'}
              </Icon>) },
          { id: 'keyword_subscriptions',
            label: 'Recipients',
            text: 'Manage Recipients',
            subscriptions: true,
            render: v => (v.get('is_curated')
              ? (<div>-</div>)
              : (
                <Badge
                  color="secondary"
                  className="badge"
                  badgeContent={v
                      .get('keywordSubscriptions', v.get('keyword_subscriptions', list()))
                      .size}>
                  <Icon
                    data-for="table.tooltip"
                    data-tip="Manage Recipients">
                    email
                  </Icon>
                </Badge>
              )),
          },
        ];
    }
  };

  const bulkActions = {
    keywords: fromJS([
      { label: 'Edit Name',
        inputLabel: 'Name',
        type: 'text',
        field: 'name',
        onApply: onBulkSave,
      },
      { label: 'Edit Category',
        inputLabel: 'Category',
        type: 'dropdown',
        filters: fromJS({
          keyclass_id: (keywordClasses || list())
            ?.sortBy(v => v.get('name'))
            ?.first()
            ?.get('id'),
        }),
        opts: fromJS((keywordClasses || list())
          .sortBy(v => v.get('name'))
          .map(v => map({ value: v.get('id'), label: Text.Sentence(v.get('name')) }))),
        field: 'keyclass_id',
        onApply: onBulkSave,
      },
      { label: 'Edit Active Status',
        inputLabel: 'Active',
        type: 'toggle',
        field: 'active',
        filters: map({ active: true }),
        defaults: map({ active: true }),
        onApply: onBulkSave,
      },
      { label: 'Edit Curation State',
        inputLabel: 'Curated',
        type: 'toggle',
        field: 'is_curated',
        filters: map({ is_curated: false }),
        defaults: map({ is_curated: false }),
        disabled: value => !canCurate && !value,
        onApply: onBulkSave,
      },
      { label: 'Edit Data Sources',
        inputLabel: 'Data Sources',
        width: '100%',
        type: 'checkboxes',
        field: 'dataSources',
        key: 'title',
        valueKey: 'id',
        opts: (basetypes || list())
          .filter(v => prm.some(p => v.get('test').test(p)))
          .map(v => v.set('value', v.get('id'))),
        filters: map({ dataSources: (basetypes || list())
          .filter(v => prm.some(p => v.get('test').test(p)))
          .map((v) => {
            if (v.get('searchType') === 'code_repository') return false;
            if (!v.has('children')) return v.get('id');
            const parentId = v.get('id');
            const childrenIds = v.get('children').map(c => c.get('id'));
            return (typeof parentId === 'string')
              ? list([parentId, ...childrenIds])
              : list([...childrenIds]);
          })
          .filter(v => v)
          .flatten()
          .join(),
        }),
        defaults: map({ dataSources: (basetypes || list())
          .filter(v => prm.some(p => v.get('test').test(p)))
          .map((v) => {
            if (v.get('searchType') === 'code_repository') return false;
            if (!v.has('children')) return v.get('id');
            const parentId = v.get('id');
            const childrenIds = v.get('children').map(c => c.get('id'));
            return (typeof parentId === 'string')
              ? list([parentId, ...childrenIds])
              : list([...childrenIds]);
          })
          .filter(v => v)
          .flatten()
          .join(),
        }),
        onApply: (selection, values) => {
          const splitSources = (values.get('dataSources') || '').split(',');
          onBulkSave(selection, values.setIn(['dataSources'], splitSources));
        },
      },
      { label: 'Delete',
        type: 'delete',
        field: 'delete',
        inputLabel: 'Warning: This cannot be undone',
        onApply: onBulkDelete,
      },
    ]),
    recipients: fromJS([
      { label: 'Edit Receive Automated Alerts',
        inputLabel: 'Pause All Alert Delivery',
        type: 'toggle',
        field: 'active',
        opposite: true,
        filters: map({ active: false }),
        defaults: map({ active: false }),
        onApply: onBulkSave,
      },
      { label: 'Edit Receive Curated Alerts',
        inputLabel: 'Receive Curated Alerts',
        type: 'toggle',
        field: 'curated',
        filters: map({ curated: true }),
        defaults: map({ curated: true }),
        onApply: onBulkSave,
      },
      { label: 'Edit Receive Industry Alerts',
        inputLabel: 'Receive Industry Alerts',
        type: 'toggle',
        field: 'industry',
        filters: map({ industry: hasIndustries }),
        defaults: map({ industry: hasIndustries }),
        disabled: () => !hasIndustries,
        onApply: onBulkSave,
      },
      { label: 'Edit Receive Credential Alerts',
        inputLabel: 'Receive Credential Alerts',
        type: 'toggle',
        field: 'credential',
        filters: map({ credential: false }),
        defaults: map({ industry: false }),
        disabled: () => !hasEnterpriseDomains,
        onApply: onBulkSave,
      },
      ...(prm.some(p => /dat.dm.r/.test(p)))
        ? [
          {
            label: 'Edit Receive Domain Monitoring Alerts',
            inputLabel: 'Receive Domain Monitoring Alerts',
            type: 'toggle',
            field: 'domain_monitoring',
            filters: map({ domainMonitoring: false }),
            defaults: map({ industry: false }),
            disabled: () => !prm.some(p => /dat.dm.r/.test(p)),
            onApply: onBulkSave,
          },
        ]
        : [],
      ...(prm.some(p => /dea/.test(p)))
        ? [
          {
            label: 'Edit Receive Internet Infrastrucure Monitoring Alerts',
            inputLabel: 'Receive Internet Infrastructure Monitoring Alerts',
            type: 'toggle',
            field: 'infrastructure_monitoring',
            filters: map({ infrastrucureMonitoring: false }),
            defaults: map({ industry: false }),
            disabled: () => !prm.some(p => /dat.dea.r/.test(p)),
            onApply: onBulkSave,
          },
        ]
        : [],
      ...(prm.some(p => /dea/.test(p)))
        ? [
          {
            label: 'Edit Receive Cloud Infrastrucure Alerts',
            inputLabel: 'Receive Cloud Infrastrucure Alerts',
            type: 'toggle',
            field: 'cloud_infrastructure',
            filters: map({ cloudInfrastrucure: false }),
            defaults: map({ industry: false }),
            disabled: () => !prm.some(p => /dat.dea.r/.test(p)),
            onApply: onBulkSave,
          },
        ]
        : [],
    ]),
  };

  const options = [
    { id: 'recipient.editor',
      type: ['recipients'],
      dialog: (
        <Editor
          canEdit={canEditNotificationProfiles}
          fields={[
            { value: 'endpoint', label: 'Recipient', type: 'email', req: true, allowed_domains: (owner || map()).get('allowed_domains') },
            { value: 'active', label: 'Pause All Alert Delivery', testId: 'test-id-pause-all-alert-delivery', type: 'toggle', bulk: true, default: true, opposite: true },
            { value: 'curated', label: 'Receive Curated Alerts', testId: 'test-id-curated', type: 'toggle', bulk: true, default: true },
            { value: 'industry',
              label: 'Receive Industry Alerts',
              testId: 'test-id-industry-alerts',
              type: 'toggle',
              bulk: true,
              default: hasIndustries,
              disable: {
                create: () => !hasIndustries,
                edit: () => !hasIndustries,
                text: 'Your organization doesn\'t currently have industry alerting enabled. Please contact your customer success representative who can get you setup!',
              } },
            { value: 'credential',
              label: 'Receive Credential Alerts',
              testId: 'test-id-credential-alerts',
              type: 'toggle',
              bulk: true,
              default: false,
              disable: {
                create: () => !hasEnterpriseDomains,
                edit: () => !hasEnterpriseDomains,
                text: 'Your organization doesn\'t currently have credential alerting enabled. Please contact your customer success representative who can get you setup!',
              } },
              ...(prm.some(p => /dat.dm.r/.test(p)))
                ? [
                  {
                    value: 'domain_monitoring',
                    label: 'Receive Domain Monitoring Alerts',
                    testId: 'test-id-domain-monitoring-alerts',
                    type: 'toggle',
                    bulk: true,
                    default: false,
                    disable: {
                      create: () => !prm.some(p => /dat.dm.r/.test(p)),
                      edit: () => !prm.some(p => /dat.dm.r/.test(p)),
                      text: 'Your organization doesn\'t currently have domain monitoring alerting enabled. Please contact your customer success representative who can get you setup!',
                    },
                  },
                ]
                : [],
              ...(prm.some(p => /dea/.test(p)))
                ? [
                  {
                    value: 'infrastructure_monitoring',
                    label: 'Receive Internet Infrastructure Monitoring Alerts',
                    testId: 'test-id-infrastructure-monitoring',
                    type: 'toggle',
                    bulk: true,
                    default: false,
                    disable: {
                      create: () => !prm.some(p => /dat.dea.r/.test(p)),
                      edit: () => !prm.some(p => /dat.dea.r/.test(p)),
                    },
                  },
                ]
                : [],
                ...(prm.some(p => /dea/.test(p)))
                ? [
                  {
                    value: 'cloud_infrastructure',
                    label: 'Receive Cloud Infrastructure Alerts',
                    testId: 'test-id-cloud-infrastructure',
                    type: 'toggle',
                    bulk: true,
                    default: false,
                    disable: {
                      create: () => !prm.some(p => /dat.dea.r/.test(p)),
                      edit: () => !prm.some(p => /dat.dea.r/.test(p)),
                      text: 'Your organization doesn\'t currently have cloud infrastructure alerting enabled. Please contact your customer success representative who can get you setup!',
                    },
                  },
                ]
                : [],
              ...(prm.some(p => /twtr/ig.test(p)))
                ? [
                  {
                    value: 'twitter',
                    label: 'Receive Twitter Alerts',
                    testId: 'test-id-twitter-alerts',
                    type: 'toggle',
                    bulk: true,
                    default: false,
                    disable: {
                      create: () => !prm.some(p => /twtr.org.w|twtr.adm/ig.test(p)),
                      edit: () => !prm.some(p => /twtr.org.w|twtr.adm/ig.test(p)),
                    },
                  },
                ]
                : [],
              ]}
          type={type}
          data={((data || map()).get('data') || list())
            .filter(v => selected.includes(v.get('id')))}
          save={(typeValue, values) => onSaveRecipients(typeValue, values)}
          deleteActions={typeValue => onDeleteRecipients(typeValue)}
          toggle={() => { setDialog(''); setSelected([]); }} />
      ),
    },
  ];

  const filterKeywords = useCallback(
    debounce(async (text) => {
      setFilters(filters
        .set('text', text || null)
        .set('skip', 0));
      const { pathname, query } = History.getCurrentLocation();
      return History.navigateTo({
        pathname,
        query: { ...query, skip: undefined },
      });
    }, 300),
    [filters, keywordClasses, owner],
  );

  useEffect(() => {
    if (prm.some(p => /kw.*.w/.test(p))) {
      setCanEditKeywords(true);
      if (type === 'keywords') setHasGridSelection(true);
    }
    if (prm.some(p => /np.*.w/.test(p))) {
      setCanEditNotificationProfiles(true);
      if (type === 'recipients') setHasGridSelection(true);
    }
  }, [prm]);

  useEffect(() => {
    // all notification profiles are already loaded, so if filters affecting them
    // change, need to manually filter
    if (type === 'recipients' && data.get('total')) {
      const active = filters.get('status');
      const skip = parseInt(filters.get('skip', 0), 10);
      const limit = parseInt(filters.get('limit', 50), 10);
      const filtered = recipients
        .get('data', map())
        .filter(v => !active || active === '' || v.get('active') === (active === 'ACTIVE'));
      const limited = filtered.slice(skip, skip + limit);
      setData(data.set('data', limited).set('total', filtered.size));
    }
  }, [filters]);

  useEffect(() => {
    if (location.pathname.includes('keywords') && data.has('total') && (!filterText || filterText.length > 2)) {
      filterKeywords(filterText);
    }
  }, [filterText]);

  useEffect(() => {
    const curated = keywords.get('data', list())
      .filter(v => v.get('active') && v.getIn(['curation', 'status']));
    const curationLimit = owner.get('curated_limit', 0);
    setCanCurate(curated.count() < curationLimit);
  }, [owner, keywords]);

  useEffect(() => {
    setFilterText('');
    setDialog('');
    setSelected([]);
    if (type === 'keywords' && canEditKeywords) setHasGridSelection(true);
    if (type === 'recipients' && canEditNotificationProfiles) setHasGridSelection(true);
  }, [type, defaults]);

  useEffect(() => {
    if (type === 'keywords') setData(keywords);
    if (type === 'recipients' && recipients?.has('data')) {
      const active = filters.get('status');
      const skip = parseInt(filters.get('skip', 0), 10);
      const limit = parseInt(filters.get('limit', 50), 10);
      const filtered = recipients
        .get('data')
        .filter(v => !active || active === '' || v.get('active') === (active === 'ACTIVE'));
      const limited = filtered.slice(skip, skip + limit);
      setData(data.set('data', limited).set('total', filtered.size));
    }
  }, [type, keywords, recipients]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    if ((filterText || (prevLocationRef
        && prevLocationRef.current
        && prevLocationRef.current.pathname.includes('keywords')
        && Object.keys(prevLocationRef.current.query).length > 1))
        && !location.pathname.includes('keywords')) {
      // if the keywords were filtered, going to a different page should reset the keywords
      filterKeywords('', map({ owner_id: filters.get('owner_id') }));
    }
    if (selected.length === 0 && keywords.get('data') && query.keyword_id) {
      const id = query.keyword_id;
      setSelected([id]);
      setDialog(type);
    }
    prevLocationRef.current = location;
  }, [location, keywords]);

  return (
    <Grid
      fluid
      className={cx([
        style.management,
        classes.management,
      ])}>
      {['keywords'].includes(type) &&
      <Row>
        <Col xs={12}>
          <Aggregations
            defaults={defaults}
            editable
            filters={prefs.getIn(['alerting', 'keywords', 'filters'])}
            allowedCurated={owner.get('curated_limit') || 0}
            onFilter={onFilter} />
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <div className={cx([style.card, data.has('total') && style.loaded])}>
            <div className={cx([style.header, style['alerting-sticky']])}>
              <div>
                {data.has('total') && data.get('total') > 0 &&
                <Pager
                  limit
                  paginated
                  filters={fromJS({ limit: '50', owner_id: owner.get('id'), ...filters.toJS() })}
                  data={data}
                  limits={fromJS([
                    { value: 25, label: '25' },
                    { value: 50, label: '50' },
                    { value: 75, label: '75' },
                    { value: 100, label: '100' },
                  ])} />}
                {data.has('total') && data.get('total') > 0 &&
                <Icon
                  className={style.export}
                  data-for="help.tooltip"
                  content="Export Alerts"
                  placement="bottom"
                  onClick={() => onExport(columns())}>
                  file_download
                </Icon>}
              </div>
              <div>
                {['keywords'].includes(type) && data.has('total') &&
                <div className={cx([style.filters, 'filters'])}>
                  <FormControl
                    fullWidth={false}
                    variant="outlined">
                    <OutlinedInput
                      data-lpignore="true"
                      name="text"
                      value={filterText}
                      placeholder="Quick Filter"
                      inputProps={{ 'data-testid': 'management.actions-quick-filter' }}
                      onChange={event => setFilterText(event.target.value.replace(String.fromCharCode(92), ''))}
                      startAdornment={(
                        <InputAdornment position="start">
                          <Icon>
                            search
                          </Icon>
                        </InputAdornment>)}
                      endAdornment={(
                        <InputAdornment position="end">
                          <Icon
                            color="primary"
                            onClick={() => setFilterText('')}>
                            close
                          </Icon>
                        </InputAdornment>
                      )} />
                  </FormControl>
                </div>}
              </div>
              <div>
                {((type === 'keywords' && canEditKeywords)
                  || (type === 'recipients' && canEditNotificationProfiles))
                  && data.has('total') &&
                  <div>
                    <div className={style.actions}>
                      {selected.length > 1 &&
                      <BulkEditButton
                        floating
                        actions={bulkActions[String(type)]}
                        selected={fromJS(selected)} />}
                      {selected.length <= 1 &&
                      <Fab
                        size="small"
                        color="secondary"
                        className={cx([style.button, style.edit])}
                        onClick={() => setDialog(type)}>
                        {selected.length === 0 &&
                        <Icon data-testid="management.actions-add">add</Icon>}
                        {selected.length > 0 &&
                        <Icon data-testid="management.actions-edit">edit</Icon>}
                      </Fab>}
                    </div>
                  </div>}
              </div>
            </div>
            {data.isEmpty() && !keywordsError && <CircularProgress />}
            {keywordsError && (
              <Invalid
                inline
                icon="notifications"
                title="An Error has occurred while getting your keywords."
                help="Please reach out to your Customer Success Representative."
                />
                )}
            {data.get('total') === 0 && <TimeoutMessage loaded={!data.isEmpty()} />}
            {type === 'alerts' && data.has('total') && data.get('total') === 0 &&
            <Invalid
              inline
              icon="notifications"
              title={Messages.SearchEmpty}
              help={['Try adding a new keyword', 'Try modifying your filters']} />}
            {type !== 'alerts' && data.has('total') &&
            <TableContainer>
              <TableUI className={classes.managementTable}>
                <TableHead>
                  <TableRow>
                    {hasGridSelection &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        inputProps={{ 'data-testid': 'management.table-actions-checkbox-all' }}
                        indeterminate={selected.length > 0 && selected.length < data.get('data', list()).size}
                        checked={data.get('data', list()).size > 0 && selected.length === data.get('data', list()).size}
                        onChange={() => onSelect(!selected.length ? 'all' : [])} />
                    </TableCell>}
                    {columns().map((col) => {
                      if (col.subscriptions && !hasGridSelection) return null;
                      return (
                        <TableCell
                          key={col.id}
                          onMouseEnter={() => ReactTooltip.rebuild()}>
                          {col.text &&
                            <div
                              data-for="header.tooltip"
                              data-tip={col.text}
                              className={cx([col.sort ? style.sort : null])}
                              name={`table.header.${col.label}`}>{col.label}
                            </div>}
                          {!col.text && <div>{col.label}</div>}
                        </TableCell>);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data
                    .get('data') || list())
                    .map((row, index) => (
                      <TableRow
                        hover
                        key={row.get('id')}
                        selected={selected.includes(row.get('id'))}>
                        {hasGridSelection &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            inputProps={{ 'data-testid': 'management.table-actions-checkbox-single' }}
                            checked={selected.includes(row.get('id'))}
                            onChange={() => onCellClick(index)} />
                        </TableCell>}
                        {columns().map((col, colIndex) => {
                          if (col.subscriptions && !hasGridSelection) return null;
                          return (
                            <TableCell
                              key={col.id}
                              style={col.style}
                              onClick={() => onCellClick(index, colIndex)}
                              onMouseEnter={() => ReactTooltip.rebuild()}>
                              <div
                                role="link"
                                tabIndex={0}
                                onKeyUp={() => null}
                                onClick={(evt) => {
                                  const edit = Boolean(col.subscriptions && !row.get('is_curated'));
                                  onSubscriptions(row, evt, edit);
                                }}>
                                <ToolTip
                                  rowId={row.get('id')}
                                  colId={col.id}
                                  col={col}
                                  row={row}
                                  index={index} />
                              </div>
                            </TableCell>);
                        })}
                      </TableRow>))}
                  {(data.get('data') || list()).count() === 0 &&
                  <TableRow hover>
                    <TableCell style={{ width: '100%' }}>
                      No results to display
                    </TableCell>
                  </TableRow>}
                </TableBody>
              </TableUI>
            </TableContainer>}
          </div>
        </Col>
      </Row>
      {options
        .filter(v => v.type.includes(type))
        .filter(v => v.dialog)
        .map(option => (
          <Dialog
            disabled
            fullWidth
            maxWidth="lg"
            key={option.id}
            className={classes.alertingDialog}
            open={dialog === 'recipients'}
            onClose={() => {
              const { pathname, query } = History.getCurrentLocation();
              if (query.keyword_id) {
                History.navigateTo({ pathname, query: { ...query, keyword_id: undefined } });
              }
              // if user only editing one at a time, likely don't want it to remain selected
              if (selected.length === 1) setSelected([]);
              setDialog();
            }}>
            <DialogTitle>
              {`${!selected.length ? `Add New ${type.charAt(0).toUpperCase() + type.slice(1)}` : 'Edit Selected'}
                ${selected.length ? `(${selected.length})` : ''}`}
            </DialogTitle>
            <DialogContent>
              {option.dialog}
            </DialogContent>
          </Dialog>))}
      {dialog === 'keywords' &&
      <AlertingKeywordDialog
        canEdit={canEditKeywords}
        canCurate={canCurate}
        prm={prm}
        onClose={() => {
          // if user only editing one at a time, likely don't want it to remain selected
          if (selected.length === 1) setSelected([]);
          setDialog();
          setSubscription(map());
        }}
        onDelete={() => setSelected([])}
        open={dialog === 'keywords'}
        email={email}
        selected={selected}
        subscription={subscription} />}
      {subscription && dialog === 'subscriptions' &&
      <AlertingSubscriptionDialog
        email={email}
        onClose={() => setDialog()}
        open={dialog === 'subscriptions'}
        selected={subscription} />}
      <ReactTooltip id="header.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="table.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Management.propTypes = {
  email: PropTypes.string,
  prefs: PropTypes.object,
  prm: PropTypes.object,
};

Management.defaultProps = {
  email: '',
  prefs: map(),
  prm: list(),
};

export default Management;
