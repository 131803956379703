import Api from '../../../utils/api';

const ClassesUrl = '/ui/v4/alerts/keyword_classes';
const SearchUrl = '/ui/v4/all/search';
const TranslateUrl = '/cloud/fpt-translate';

const cache = {};

const Classes = async () => {
  const index = v => [/cyber/ig, /threats/ig, /brand/ig].findIndex(_v => _v.test(v));
  return Api.get(ClassesUrl)
    .then(res => res.data)
    .then(res => res.filter(item => /twitter/ig.test(item.name)))
    .then(res => res
      .map(v => ({
        ...v,
        label: v.name.replace(/twitter|_/ig, ' ').trim(),
      }))
      .sort((a, b) => index(a?.label) - index(b?.label)));
};

const Keywords = async (fpid = '') => {
  const classes = await Classes();
  const query = {
    query: `+basetypes:(twitter)
      +in_reply_to.fpid:"${fpid}"
      +_exists_:owners.category_id`,
    _source_includes: ['owners.category_id', 'native_id'],
    sort: ['sort_date:desc'],
    traditional_query: true,
    source: true,
    size: 100,
  };

  return Api.post(SearchUrl, query)
    .then(res => res?.data)
    .then(res => classes
      .map(c => ({
        ...c,
        data: res.filter(r => r?.owners?.[0]?.category_id === c?.id),
      })));
};

const Post = async (fpid = '') => {
  const query = {
    query: `+basetypes:(twitter)
      +fpid:"${fpid}"`,
    _source_includes: ['fpid', 'native_id', 'body.text/plain'],
    traditional_query: true,
    source: true,
    size: 1,
  };

  return Api.post(SearchUrl, query)
    .then(res => res?.data)
    .then(res => res?.[0]);
};

const Text = async (text = '', fpid = '') => {
  if (!text) return [];
  const query = {
    query: `+basetypes:(twitter)
      +(${text})
      +in_reply_to.fpid:"${fpid}"`,
    _source_includes: ['owners.category_id', 'native_id'],
    sort: ['sort_date:desc'],
    traditional_query: true,
    source: true,
    size: 10,
  };

  return Api.post(SearchUrl, query)
    .then(res => res?.data)
    .then(res => [({
      id: '0',
      label: `"${text}"`,
      name: text,
      data: res,
    })]);
};

const Translate = async (fpid = '', text = '') => {
  const query = {
    fpid,
    native_id: fpid,
    legacy_id: fpid,
    basetypes: 'twitter',
    client: 'gtx',
    sl: 'auto',
    dt: 't',
    format: 'text',
    q: text,
  };
  const data = cache?.translate?.[String(fpid)]
    ? cache?.translate?.[String(fpid)]
    : Api.post(TranslateUrl, query)
      .then(res => (res.ok ? res.data : ''))
      .then(res => Object.assign(cache, {
        ...cache,
        translate: {
          ...cache?.translate,
          [fpid]: res,
        },
      }))
      .then(() => cache?.translate?.[String(fpid)]);
  return data;
};

export default {
  Classes,
  Keywords,
  Post,
  Text,
  Translate,
};
