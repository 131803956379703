import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

import SanctionsSearchResults from './SanctionsSearchResults';
import Token from '../../utils/token';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

const SanctionsSavedSearch = (({
  match: {
    params,
  },
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    const { id } = params;
    if (!loading) {
      setLoading(true);
    }
    Api.get(`/ui/v4/sanctions/savedSearch/${id}`, {}, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        if (res && res.results_json) {
          const parsedRes = JSON.parse(res.results_json);
          const availableLists = {
            unAvailable: parsedRes.unAvailable,
            ofacAvailable: parsedRes.ofacAvailable,
            euAvailable: parsedRes.euAvailable,
            chainalysisAvailable: parsedRes.chainalysisAvailable,
          };
          setData({
            results: parsedRes.resultList,
            availableLists,
            responseDateTime: res.search_date,
            clientNames: res.client_names,
            status: res.search_status,
            reports: res.fp_urls,
            notes: res.notes,
            id: res.id,
            is_fuzzy: res.is_fuzzy,
          });
        } else {
          setData(null);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data: d } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(d?.detail, d));
        setLoading(false);
      });
    return function cleanup() {
      isOnPage = false;
    };
  }, []);

  return (
    <React.Fragment>
      {loading && <CircularProgress />}
      <SanctionsSearchResults data={data} />
    </React.Fragment>
  );
});

SanctionsSavedSearch.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SanctionsSavedSearch;
