import * as React from 'react';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  borderMenu: {
    border: '1px solid #C4C4C5',
    width: '100%',
    maxWidth: '351px',
    borderRadius: 0,
    justifyContent: 'space-between',
    marginBottom: '2px',
    textTransform: 'none',
    overflow: 'hidden',
  },
  dropdown: {
    width: '385px',
    '& .MuiMenu-paper': {
      width: '385px',
    },
  },
}));

type getItemTextFn = (item: object) => string;
type onClickMenuItemFn = (item: object) => void;

interface BasicMenuProps {
  items: object[];
  getItemText: getItemTextFn;
  selectedItem: object;
  defaultText: string;
  onClickMenuItem: onClickMenuItemFn;
}


export const BasicMenu = ({
  items,
  getItemText,
  selectedItem,
  defaultText,
  onClickMenuItem,
}: BasicMenuProps): JSX.Element => {
  const menuClasses = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListItemClick = (item: object) => {
    onClickMenuItem(item);
    handleClose();
  };

  return (
    <>
      <div onClick={handleClick}>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={menuClasses.borderMenu}
          endIcon={open ?
            <Icon>keyboard_arrow_up</Icon> :
            <Icon>keyboard_arrow_down</Icon>}
        >
          {(selectedItem && getItemText(selectedItem)) || defaultText}
        </Button>
      </div>
      <Menu
        className={menuClasses.dropdown}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          items.map((item: object) => (
            <MenuItem onClick={() => handleListItemClick(item)}>{
              getItemText ? getItemText(item) : item}
            </MenuItem>
          ))
        }
      </Menu>
    </>
    );
  };
