import React from 'react';
import PropTypes from 'prop-types';

import style from './integrations.module.scss';
import Integration from '../../components/content/Integration';
import data from '../../../config/integrations.yml';

const Integrations = ({
  match: {
    params,
  },
}) => {
  const type = decodeURIComponent(params.type);

  return (
    <div className={style.integrations}>
      {data
        .integrations
        .filter(v => !v.disabled)
        // eslint-disable-next-line security/detect-non-literal-regexp
        .filter(v => type === 'all' || v?.types.some(t => new RegExp(type || '', 'ig').test(t?.label)))
        .map(int => (
          <div key={int?.name} className={style.integration}>
            <Integration
              description={int?.description}
              image={int?.image}
              docs={int?.docs}
              types={int?.types}
              datasets={int?.datasets} />
          </div>))}
    </div>
  );
};

Integrations.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Integrations;
