/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect, useState } from 'react';

import Carousel, { Modal, ModalGateway } from 'react-images';
import makeStyles from '@mui/styles/makeStyles';

import Dashboard from '../../../Dashboard';
import { navigateFromExpoDash } from '../../utils';
import Query from './query';

import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import type { MediaPreferences } from '../../../../../core/types/ExpoDashPreferences';

const useStyles = makeStyles(({
  empty: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 3.2rem',
    textAlign: 'center',
  },
  grid: {
    display: 'grid',
    gridColumnGap: '10px',
    gridRowGap: '10px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: '1fr 1fr',
    height: '95%',
    marginTop: '10px',
  },
  imgContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
}));

interface MediaWidgetProps {
  config: MediaPreferences,
  filters: ExpoDashFilters,
  setDialog: (value: object) => void,
}

export const MediaWidget = ({ config, filters, setDialog }: MediaWidgetProps): JSX.Element => {
  const [images, setImages] = useState<HTMLImageElement[]>();
  const [carousel, setCarousel] = useState<number>(-1);

  const classes = useStyles();

  const savedSearch = config?.savedSearch;
  const keyword = savedSearch?.value || filters?.query || '';

  useEffect(() => {
    if (images) {
      setImages(undefined);
    }
    Query.MediaSearch(config?.savedSearch, filters)
      .then((res: HTMLImageElement[]) => setImages(res));
  }, [config, filters]);

  return (
    <Dashboard
      data={images}
      title={`Images of "${savedSearch?.name || filters?.query}"`}
      help="Media results from your Assets > Media based saved searches"
      onViewClick={() => navigateFromExpoDash({
        savedSearch,
        filters,
        media: true,
      })}
      settings={setDialog}>
      {
        (images?.length > 0) ?
          <div className={classes.grid}>
            {
              images?.map((img: any, index: number) => (
                <div className={classes.imgContainer}>
                  {img?.type === 'image' &&
                  <img
                    className={classes.image}
                    key={img?.src}
                    onClick={() => setCarousel(index)}
                    src={img?.src}
                    alt="" />}
                  {img?.type === 'video' &&
                 /* eslint-disable-next-line jsx-a11y/media-has-caption */
                  <video
                    controls
                    autoPlay={false}
                    src={img?.src}
                    className={classes.image} />}
                </div>
                ),
              )
            }
          </div> :
          <div className={classes.empty}>
            <p>
              There are no images that meet the criteria for
              {' '}“{keyword}” during this time frame.
            </p>
          </div>
      }
      {
        ((images?.length > 0) && (carousel > -1)) &&
        <ModalGateway>
          <Modal onClose={() => setCarousel(-1)}>
            <Carousel views={images} currentIndex={carousel} />
          </Modal>
        </ModalGateway>
      }
    </Dashboard>
  );
};
