import * as React from 'react';
import { useState } from 'react';

import qs from 'qs';
import CloseIcon from '@mui/icons-material/Close';

import { RecentSearch } from '../../../../../core/types/RecentSearch';
import Text from '../../../../../utils/text';
import { navigateFromExpoDash } from '../../utils';

import { TableCell, TableRow } from '../../../../../shared/data';
import style from './recentsearchestablerow.module.scss';

interface RecentSearchesTableRowProps {
  /** An array of recent searches to display in the widget */
  data: RecentSearch;
  /** Handler when deleting item */
  onDelete: (e: React.MouseEvent, search: RecentSearch) => void;
}

export const filtersTooltip = ({ queryString, filtersObj }:
  {queryString?: string, filtersObj?: object}) => {
  const parsed = queryString ? qs.parse(queryString, { ignoreQueryPrefix: true }) : filtersObj;
  return Object.entries(parsed)
    ?.map(([k, v]) => `${Text.Sentence(k)}: ${v}`)
    ?.join('<br />');
};

const RecentSearchesTableRow = ({
  data,
  onDelete,
}: RecentSearchesTableRowProps) => {
  const [show, setShow] = useState(false);

  return (
    <TableRow
      onClick={() => navigateFromExpoDash({ savedSearch: data })}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <TableCell>
        <div
          data-for="global.tooltip"
          data-place="top"
          data-class={style.reactTooltip}
          data-tip={filtersTooltip({ queryString: data?.filters })}
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}> {data?.value || 'n/a'} </div>
          <CloseIcon
            onClick={e => onDelete(e, data)}
            style={{ display: show ? '' : 'none', lineHeight: '70%' }}
            fontSize="small"
          />
        </div>
      </TableCell>
    </TableRow>
  );
};
export default RecentSearchesTableRow;
