import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Invalid from '../utils/Invalid/Invalid';

const LOADING_TIMEOUT = 30000;

const TimeoutMessage = ({ loaded }) => {
  const [error, setError] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  /* Set up an Error Card if loading times out after 30 seconds */
  useEffect(() => {
    const id = setTimeout(() => setError(true), LOADING_TIMEOUT);
    setTimeoutId(id);
    return (() => clearTimeout(id));
  }, []);

  if (loaded) {
    clearTimeout(timeoutId);
  }

  if (error) {
    return (
      <Invalid
        inline
        icon="notifications"
        title="You are not subscribed to any alerts"
        help={[
          'To subscribe to an alert, please set up your preferences in the Keywords and Recipients tabs',
          'Please contact customer success if you need further assistance',
        ]} />
    );
  }
  return (<></>);
};

TimeoutMessage.propTypes = {
  loaded: PropTypes.bool,
};

TimeoutMessage.defaultProps = {
  loaded: false,
};

export default TimeoutMessage;
