import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useQuery } from '@apollo/client';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';

import ManageBinsTable from './ManageBins/ManageBinsTable';

import query from './ManageBins/query';
import { getSfid } from '../../../stores/recoil/app';
import { devClient } from '../../../gql/apolloClient';

const useStyles = makeStyles({
  buttons: {
    '& .MuiIconButton-root': {
      borderRadius: '15%',
      padding: '0px 12px 0px 12px',
    },
  },
  container: {
    margin: 0,
    width: '100%',
  },
});
interface Customer {
  salesforceId: string;
}

interface Bin {
  id: number;
  name: string;
  bin_tag_customer: Customer;
}

interface Bins {
  id: number;
  name: string;
  salesforceId: string;
  binTags: Bin[];
}

interface customerBins {
  binTags: Bins[];
  id: number;
  number: number;
  roi: number;
}

interface VarData {
  salesforceId: number;
  size: number;
  skip: number;
  sortBy: string;
  binsFilter: string;
  tagsFilter: string[];
}

interface BinsData {
  name: string;
  customerBinsLength: number;
  customerBins: customerBins[];
}

const ManageBins = () => {
  const classes = useStyles();
  const sfid = useRecoilValue(getSfid);
  const [customer, setCustomer] = useState<BinsData>();

  const [size, setSize] = useState(25);
  const [skip, setSkip] = useState(0);
  const [sortBy, setSortBy] = useState('asc');
  const [initialLoad, setInitialLoad] = useState(true);
  const [tagName, setTagName] = useState([]);
  const [binsFilter, setBinsFilter] = useState('');
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const variables = {
    salesforceId: sfid,
    size,
    skip,
    sortBy,
    binsFilter,
    tagsFilter,
  } as VarData;

  const { loading, error, data } = useQuery(query, {
    client: devClient,
    variables,
    skip: !sfid,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data?.shopCustomer?.customerBins) return;

    // only display the tags associated with the current customer
    const customerBinTags = data.shopCustomer.binTags.map(({ name }: { name: string }) => name);
    const customerBins = data.shopCustomer.customerBins
      ?.map((bin: Bins) => ({
        ...bin,
        binTags: bin?.binTags?.filter(tag =>
          customerBinTags?.includes(tag.name)
          && tag?.bin_tag_customer?.salesforceId === sfid),
      }));
    setTagName(customerBinTags);
    setCustomer({
      ...data.shopCustomer,
      customerBins,
    });
    setInitialLoad(false);
  }, [data]);

  if (loading) {
    return <CircularProgress size={25} thickness={2} />;
  }

  if (!loading && !(customer?.customerBins)) {
    return <div>No Data!</div>;
  }

  if (error) {
    return <div><>You are not a cfm customer</></div>;
  }

  return (
    <Grid className={classes.container}>
      <Row>
        <Col>
          <h1>{data.shopCustomer.name}</h1>
        </Col>
      </Row>
      <Row>
        <Col className={classes.container}>
          <ManageBinsTable
            variables={variables}
            data={customer}
            size={size}
            skip={skip}
            sortBy={sortBy}
            tagName={tagName}
            binsFilter={binsFilter}
            tagsFilter={tagsFilter}
            setSize={setSize}
            setSkip={setSkip}
            setSortBy={setSortBy}
            setBinsFilter={setBinsFilter}
            setTagsFilter={setTagsFilter}
            initialLoad={initialLoad}
            />
        </Col>
      </Row>
    </Grid>
  );
};

export default ManageBins;
