import { atom, selector } from 'recoil';

export const appStore = atom({
  key: 'appState',
  default: {},
});

export const getSfid = selector({
  key: 'app.sfid',
  get: ({ get }) => {
    const { salesforce_id } = get(appStore);

    return salesforce_id;
  },
});
