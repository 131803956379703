const Products = {
  AUTOMATE: '0oa3cgh290tOoYjFW5d6',
  ECHOSEC_PSI: '0oa642y10mop4qPwp5d7',
  FP_TOOLS: '0oa5a4k39jdB5xsRn5d6',
  VULN: '0oa672au2tVHqolrP5d7',
  FAMS: '0oa1w7yzw3X24yMwf5d7',
  MANAGED_ATTRIBUTION: '0oa1f5fpj3ZCkjnib5d7',
  IGNITE: '0oa9ftpgswSe0e03m5d7',
};

const Suite = [
  {
    id: Products.IGNITE,
    icon: 'flashpoint',
    title: 'Ignite',
    loginUrl: '//app.flashpoint.io',
    demoUrl: 'https://flashpoint.io/free-trial',
    marketingUrl: 'https://flashpoint.io/ignite',
    entitlements: [],
  },
  {
    id: Products.AUTOMATE,
    icon: 'automate',
    title: 'Automate',
    loginUrl: 'https://automate.fp.tools/',
    demoUrl: 'https://flashpoint.io/free-trial',
    marketingUrl: 'https://flashpoint.io/platform/flashpoint-automate/',
    entitlements: [],
  },
  {
    id: Products.ECHOSEC_PSI,
    icon: 'echosec',
    title: 'Echosec',
    loginUrl: 'https://app.echosec.net/register/redirect/okta',
    demoUrl: 'https://flashpoint.io/free-trial',
    marketingUrl: 'https://flashpoint.io/osint-for-enterprise-security/',
    entitlements: ['PSI'],
  },
  {
    id: Products.VULN,
    icon: 'vulndb',
    title: 'VulnDB',
    loginUrl: 'https://vulndb.flashpoint.io/vulnerabilities/dashboard',
    demoUrl: 'https://flashpoint.io/free-trial',
    marketingUrl: 'https://flashpoint.io/ignite/vulnerability-management/',
    entitlements: [],
  },
  {
    id: Products.MANAGED_ATTRIBUTION,
    icon: 'flashpoint',
    title: 'Managed Attribution',
    loginUrl: 'https://ma-{{vioc}}.app.flashpoint.io',
    demoUrl: 'https://ma-demo.app.flashpoint.io',
    marketingUrl: 'https://flashpoint.io/platform/managed-attribution/',
    entitlements: ['MANAGED_ATTRIBUTION'],
  },
  {
    id: Products.FAMS,
    icon: 'flashpoint',
    title: 'FAMS',
    loginUrl: 'https://famicom.s.fpint.net',
    entitlements: ['Flashpoint'],
  },
];

const Resources = [
  {
    id: 'fp tools',
    title: 'Documentation',
    loginUrl: 'https://docs.fp.tools/flashpoint',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'Help Center',
    loginUrl: 'https://flashpoint.my.site.com/helpcenter',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'Glossary',
    loginUrl: '/home/resources/glossary',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'Query Syntax',
    loginUrl: '/home/resources/syntax',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'Release Notes',
    loginUrl: '/home/resources/updates',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'Integrations Documentation',
    loginUrl: '/home/integrations/all',
    test: /ddw/,
  },
  {
    id: 'fp tools',
    title: 'App Feedback',
    loginUrl: 'mailto:support@flashpoint-intel.com',
    test: /ddw/,
  },
];

const Profile = [
  {
    id: 'fp tools',
    title: 'Saved Searches',
    target: '_self',
    loginUrl: '/home/profile/manage/searches',
    entitlements: ['Flashpoint'],
  },
  {
    id: 'fp tools',
    title: 'Bookmarks',
    target: '_self',
    loginUrl: '/home/profile/manage/bookmarks',
    entitlements: ['Flashpoint'],
  },
  {
    id: 'fp tools',
    title: 'Settings',
    target: '_self',
    loginUrl: '/home/profile/settings',
    entitlements: ['dat.ddw.r'],
  },
  {
    id: 'fp tools',
    title: 'Manage API Tokens',
    target: '_self',
    loginUrl: '/home/profile/tokens',
    entitlements: ['Flashpoint'],
  },
  {
    id: 'fp tools',
    title: 'Manage TAXII Creds',
    target: '_self',
    loginUrl: '/home/profile/taxii',
    prm: /taxii-endpoints/,
  },
  {
    id: 'fp tools',
    title: 'CCMC-C Demo',
    target: '_self',
    loginUrl: '/home/integrations/ccmc',
    prm: /demo.ccm.c/,
  },
  {
    id: 'fp tools',
    title: 'CCM-C Webhook',
    target: '_self',
    loginUrl: '/home/integrations/webhook/ccmc',
    entitlements: /ui.ccmc.web/,
  },
  {
    id: 'fp tools',
    title: 'User Admin',
    target: '_self',
    loginUrl: '/home/profile/admin/users',
    prm: /org.fp.r/,
  },
  {
    id: 'fp tools',
    title: 'Logout',
    target: '_self',
    loginUrl: '/home/profile/logout',
    noRoute: true,
    entitlements: ['Flashpoint'],
  },
];

export {
  Resources,
  Profile,
  Suite,
  Products,
};
