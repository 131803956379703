import moment from 'moment';
import { fromJS } from 'immutable';

export default {
  beforeDates: {
    accounts: moment.utc('2019-09-01', 'YYYY-MM-DD'),
    blogs: moment.utc('2008-01-01', 'YYYY-MM-DD'),
    ransomware: moment.utc('2008-01-01', 'YYYY-MM-DD'),
    boards: moment.utc('2017-03-01', 'YYYY-MM-DD'),
    cards: moment.utc('2018-11-01', 'YYYY-MM-DD'),
    chats: moment.utc('2016-01-01', 'YYYY-MM-DD'),
    communities: moment.utc('2016-01-01', 'YYYY-MM-DD'),
    cves: moment.utc('2015-01-01', 'YYYY-MM-DD'),
    exploitss: moment.utc('2015-01-01', 'YYYY-MM-DD'),
    forums: moment.utc('2006-01-01', 'YYYY-MM-DD'),
    social: moment.utc('2019-04-01', 'YYYY-MM-DD'),
    marketplaces: moment.utc('2017-12-01', 'YYYY-MM-DD'),
    pastes: moment.utc('2016-11-01', 'YYYY-MM-DD'),
  },
  intervals: fromJS([
    { key: 'thirtyMin', label: '30 Min', interval: '30m', format: 'YYYY-MM-DD HH:mm', moment: { minutes: 30 }, bucketsPerHour: 2 },
    { key: 'oneHour', label: '1 Hour', interval: '1h', format: 'YYYY-MM-DD HH:mm', moment: { hours: 1 }, bucketsPerHour: 1 },
    { key: 'threeHour', label: '3 Hour', interval: '3h', format: 'YYYY-MM-DD HH:mm', moment: { hours: 3 }, bucketsPerHour: 1 / 3 },
    { key: 'twelveHour', label: '12 Hour', interval: '12h', format: 'YYYY-MM-DD HH:mm', moment: { hours: 12 }, bucketsPerHour: 1 / 12 },
    { key: 'oneDay', label: '1 Day', interval: 'day', format: 'YYYY-MM-DD', moment: { days: 1 }, bucketsPerHour: 1 / 24 },
    { key: 'oneWeek', label: '1 Week', interval: 'week', format: 'YYYY-MM-DD', moment: { weeks: 1 }, bucketsPerHour: 1 / (7 * 24) },
    { key: 'oneMonth', label: '1 Month', interval: 'month', format: 'YYYY-MM-DD', moment: { months: 1 }, bucketsPerHour: 1 / (31 * 24) },
    { key: 'threeMonth', label: '3 Months', interval: 'quarter', format: 'YYYY-MM-DD', moment: { months: 3 }, bucketsPerHour: 1 / (31 * 24 * 3) },
  ]),
};
