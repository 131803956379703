import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map } from 'immutable';
import {
  Button,
  CircularProgress,
} from '@mui/material';

import style from './reports.module.scss';
import SearchActions from '../../actions/searchActions';
import Card from './Card';
import Invalid from '../utils/Invalid/Invalid';

const Reports = ({
  cdn,
  userHistory,
  reports,
  tags,
  title,
  type,
}) => {
  const [skip, setSkip] = useState(0);

  const tagLimit = 5;

  const formatReport = () => {
    const noAlis = document.querySelectorAll('.standup ul li span a');
    const lies = document.querySelectorAll('.standup ul li');
    if (noAlis.length > 0) {
      // eslint-disable-next-line no-param-reassign
      noAlis.forEach((x) => { x.textContent = ''; });
    }
    if (lies.length > 0) {
      // eslint-disable-next-line no-param-reassign
      lies.forEach((el) => { el.textContent = `${el.textContent.substring(0, 250)}`; });
    }
    const items = document.querySelectorAll('.standup *');
    // eslint-disable-next-line no-param-reassign
    items.forEach((el) => { el.style.display = (el.textContent.length < 35) ? 'none' : ''; });
  };

  const onLoad = () => {
    const index = skip + 25;
    setSkip(index);
    SearchActions.searchIntel(tags, type, index);
    setTimeout(() => formatReport(), 3000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (skip && reports && reports.has('data')) {
      setSkip(reports.get('data').count() - 10);
    }
  }, [type]);

  useEffect(() => {
    if (reports.has('total')) {
      const sortedReports = reports
        .get('data')
        .sort((a, b) => a.get('version_posted_at') - b.get('version_posted_at'));
      const bottomReport = sortedReports.get(skip === 0 ? 0 : skip - 1);
      if (bottomReport && bottomReport.has('id')) {
        const card = document.getElementById(bottomReport.get('id'));
        card.scrollIntoView();
        window.scrollBy(0, -150); // header height + 15px padding
      }
    }
  }, [reports]);

  return (
    <Grid fluid className={style.reportscomp}>
      {title !== 'All' &&
      <Row>
        <Col xs={12} className={style.header}>
          <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>
            {`${tags.split(',').slice(0, tagLimit)}
              ${tags.split(',').length > tagLimit
                ? `+${tags.split(',').length - tagLimit}`
                : ''}`}
          </div>
        </Col>
      </Row>}
      <Row>
        <Col xs={12} className={style.list}>
          {!reports.has('total') &&
          <CircularProgress />}
          {reports.has('total') &&
          reports
            .get('data')
            .sort((a, b) => a.get('version_posted_at') - b.get('version_posted_at'))
            .map(report => (
              <div id={report.get('id')} key={report.get('id')}>
                <Card
                  userHistory={userHistory}
                  cdnTag={cdn ? '&cdn=true' : ''}
                  standup={report.get('tags').includes('Standup')}
                  report={report} />
              </div>))}
        </Col>
      </Row>
      {reports.has('total') && !reports.get('total') &&
      <div className={style.empty}>
        <Invalid
          inline
          icon="lightbulb_outline"
          title="No recent reports found for selected categories"
          help={['Try different content category']} />
      </div>}
      {reports.has('total') && skip + 10 < reports.get('total') &&
      <Row>
        <Col xs={12}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={cx([style.load, skip > reports.get('count') - 1 && style.loading])}
            endIcon={<CircularProgress />}
            onClick={() => onLoad()}>
            Load More
          </Button>
        </Col>
      </Row>}
    </Grid>
  );
};

Reports.propTypes = {
  userHistory: PropTypes.object,
  reports: PropTypes.object,
  tags: PropTypes.string,
  title: PropTypes.string,
  cdn: PropTypes.bool,
  type: PropTypes.string,
};

Reports.defaultProps = {
  userHistory: list(),
  reports: map(),
  tags: '',
  title: '',
  cdn: true,
  type: '',
};

export default Reports;
