import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map } from 'immutable';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
  Paper,
} from '@mui/material';

import style from './columnThread.module.scss';
import Text from '../../utils/text';
import Common from '../../utils/common';
import History from '../../utils/history';
import Post from '../threads/Post/Post';
import CommentThread from './CommentThread';
import Infinite from '../utils/Infinite/Infinite';
import Placeholder from '../utils/Placeholder';
import InternalLink from '../utils/InternalLink';
import SearchActions from '../../actions/searchActions';
import { Tag } from './Tag';

const ColumnThread = ({
  container,
  data,
  first,
  location,
  meta,
  replies,
  title,
  site,
}) => {
  const [dialog, setDialog] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(list());
  const [carousel, setCarousel] = useState(-1);
  const [showReplies, setShowReplies] = useState(map());
  const { query } = History.getCurrentLocation();

  const icon = (basetypes) => {
    switch ((basetypes || []).slice(-1).join()) {
      case 'blogs':
      case 'posts': return 'web';
      case 'social': return 'people';
      case 'ransomware':
      case 'releases': return 'lock';
      default: return '';
    }
  };

  const setOffset = (direction) => {
    if (!container || !loaded) return;
    const offset = (direction === 'next')
      ? moment.utc(loaded.last().getIn(['sort_date'])).unix()
      : moment.utc(loaded.first().getIn(['sort_date'])).unix();
    const update = container.merge({ offset });
    const basetypes = loaded.first().getIn(['basetypes']);
    SearchActions.set(['search', 'result', basetypes.last()], update);
  };

  const load = (direction) => {
    const firstValue = moment.utc(data.first().getIn(['sort_date'])).unix();
    const lastValue = moment.utc(data.last().getIn(['sort_date'])).unix();
    const update = container.merge({ offset: direction === 'prev' ? firstValue : lastValue });
    SearchActions.searchThread(update, direction);
  };

   const onAction = (event, key = '', context = map()) => {
    switch (key) {
      case 'tag': {
        const type = Common.Basetypes.ExtractMainBasetype(context);
        const component = <Tag
          type={type}
          post={context.toJS()}
          onCancel={() => setDialog()} />;
        setDialog({
          key,
          component,
          text: 'Tag selected post',
          target: event.target,
        });
        break;
      }
      default: break;
    }
  };

  const onMediaClick = (src) => {
    if (src === -1) {
      setImages([]);
      setCarousel(-1);
    } else {
      setImages([{ source: src }]);
      setCarousel(0);
    }
  };

  const onOriginalPost = () => {
    const original = meta.get('container');
    const opFpid = original.get('fpid');
    SearchActions.searchComments(original);
    const { pathname, query: urlQuery } = History.getCurrentLocation();
    const newPath = pathname.substr(0, pathname.lastIndexOf('/'));
    History.push({
      pathname: `${newPath}/${opFpid}`,
      query: {
        ...urlQuery,
        fpid: opFpid,
        id: moment.utc(meta.get('sort_date')).unix(),
      },
    });
  };

  const onSearch = (basetypes = list(), k, v) => {
    const type = basetypes.slice(-2).first();
    const pathname = `/home/search/${type}`;
    const urlQuery = { [k]: Text.Strip(v), query: '' };
    return ({ pathname, query: urlQuery });
  };

  useEffect(() => {
    const bounds = container.getIn(['bounds']) || map();
    if (data.isEmpty()) setLoading(true);
    if (loading && bounds.has('start')) setLoading(false);
    if (container.get('loaded') || !data.isEmpty()) setLoading(false);
    window.scrollTo(0, 0);
  }, [container, data]);

  return (
    <Grid fluid className={cx([style.thread])}>
      <Row>
        <Col xs={6} className={cx([style.column, style.op])}>
          {meta.isEmpty() &&
          <Placeholder height={135} width={600} total={1} />}
          {!meta.isEmpty() &&
            <div>
              <div className={style.header}>
                <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                  {Text.Highlight(site)}
                </div>
                <div className={style.title}>
                  <Icon className={style.icon}>
                    {icon(meta.get('basetypes'))}
                  </Icon>
                  <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                    {Text.Highlight(Text.Post(title || meta.getIn(['container', 'title']) || '-'))}
                  </div>
                </div>
                <div className={style.title}>
                  <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                    Created By:
                  </div>
                  <InternalLink
                    key="username"
                    to={onSearch(meta.getIn(['basetypes']), 'author',
                      `${meta.getIn(['container', 'site_actor', 'fpid'])
                        ? meta.getIn(['container', 'site_actor', 'fpid'])
                        : meta.getIn(['site_actor', 'fpid'])}::${meta.getIn(['container', 'site_actor', 'names', 'handle'])
                          ? meta.getIn(['container', 'site_actor', 'names', 'handle'])
                          : meta.getIn(['site_actor', 'names', 'handle'])}`)}
                    className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal', style.a, 'a'])}>
                    &nbsp;{meta.getIn(['container', 'site_actor', 'names', 'handle'])
                      || meta.getIn(['container', 'site_actor', 'names', 'username'])
                      || meta.getIn(['site_actor', 'names', 'handle'])
                      || meta.getIn(['site_actor', 'names', 'username'])
                      || meta.getIn(['native_id'])
                      || '-'}
                  </InternalLink>
                </div>
                <div className={cx([style.h4, 'h4', style.normal, 'normal'])}>
                  URL: {meta.get('raw_href')}
                </div>
                {(meta.getIn(['container', 'first_observed_at', 'timestamp']) || meta.getIn(['first_observed_at', 'timestamp'])) &&
                <div className={style.title}>
                  <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                    {`First Observed on ${moment.utc((meta.getIn(['container', 'first_observed_at', 'timestamp']) || meta.getIn(['first_observed_at', 'timestamp'])) * 1000).format('MMMM DD, YYYY')}`}
                  </div>
                </div>}
                {(meta.getIn(['container', 'last_observed_at', 'timestamp']) || meta.getIn(['last_observed_at', 'timestamp'])) &&
                <div className={style.title}>
                  <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                    {`Last Observed on ${moment.utc((meta.getIn(['container', 'last_observed_at', 'timestamp']) || meta.getIn(['last_observed_at', 'timestamp'])) * 1000).format('MMMM DD, YYYY')}`}
                  </div>
                </div>}
                <div className={style.original}>
                  {meta.has('container')
                    && meta.getIn(['container', 'basetypes'])
                    && meta.getIn(['container', 'basetypes']).includes('post') &&
                    <div
                      role="link"
                      tabIndex={0}
                      onKeyUp={() => null}
                      className={cx([style.link, style.a, 'a'])}
                      onClick={() => onOriginalPost()}>
                      <div className={cx([style.link, style.a, 'a'])}>
                        View original post
                        <Icon className={cx([style.icon, style.action, 'material-icons'])}>
                          keyboard_arrow_up
                        </Icon>
                      </div>
                    </div>}
                </div>
              </div>
              {!first.isEmpty() &&
              <Post
                translate
                parent
                isFirst
                post={first}
                key={first.get('fpid')}
                index={false}
                active={query.native_id
                  ? first.get('native_id') === query.native_id
                  : first.get('fpid') === query.fpid}
                author={Text.Strip(first.getIn(['site_actor', 'fpid'])) === meta.getIn(['site_actor', 'fpid'])}
                onAction={onAction}
                onMediaClick={onMediaClick} />}
              {first.isEmpty() &&
              <Row>
                <Col xs={12}>
                  <Paper className={cx([style['no-post'], style.disabled])}>
                    <div className={style.empty}>
                      <div>Unable to retrieve post <br />
                        Try again later or view the post at <br />
                        {Text.DefangLink(first.get('source_uri') || first.getIn(['container', 'url']) || '-')}
                      </div>
                    </div>
                  </Paper>
                </Col>
              </Row>}
            </div>}
        </Col>
        <Col xs={6} className={style['comment-section']}>
          {loading &&
          <Placeholder height={135} width={800} total={10} />}
          {!loading &&
          <div className={style.header}>
            <div className={style.title}>
              <Icon className={style.icon}>forums</Icon>
              <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                Comments
              </div>
            </div>
          </div>}
          {!data.isEmpty() &&
          <div className={cx([style.column, style.comments])}>
            <div style={{ opacity: +!loading }}>
              <Infinite
                active={query?.fpid}
                data={data}
                maxRecords={50}
                setLoaded={setLoaded}
                requestCount={25}
                setOffset={setOffset}
                container={document.querySelector('[class*="comments"]')}
                offset={!loading ? container.getIn(['offset']) : ''}
                hasPrev={!loading && !container.getIn(['bounds', 'start'])}
                hasNext={!loading && !container.getIn(['bounds', 'end'])}
                loading={!container.get('loaded')}
                loadNext={() => load('next')}
                loadPrev={() => load('prev')}>
                {loaded.map(v => (
                  <CommentThread
                    key={v.get('fpid')}
                    location={location}
                    meta={meta}
                    onAction={onAction}
                    onMediaClick={onMediaClick}
                    post={v}
                    replies={replies}
                    showReplies={showReplies}
                    setShowReplies={setShowReplies} />))}
              </Infinite>
            </div>
          </div>}
          {!loading && data.isEmpty() &&
          <Row>
            <Col xs={12}>
              <Paper className={cx([style.comments, style.disabled])}>
                <div className={style.empty}><div>No Comments</div></div>
              </Paper>
            </Col>
          </Row>}
        </Col>
      </Row>
      <Dialog open={Boolean(dialog && dialog?.key)}>
        <DialogTitle>{dialog?.text}</DialogTitle>
        <DialogContent>
          {dialog?.component}
        </DialogContent>
      </Dialog>
      <ModalGateway>
        {Boolean(images.length && carousel > -1) &&
        <Modal onClose={() => onMediaClick(-1)}>
          <Carousel
            views={images}
            currentIndex={carousel} />
        </Modal>}
      </ModalGateway>
    </Grid>
  );
};


ColumnThread.propTypes = {
  location: PropTypes.object,
  container: PropTypes.object,
  data: PropTypes.object,
  first: PropTypes.object,
  meta: PropTypes.object,
  replies: PropTypes.object,
  site: PropTypes.string,
  title: PropTypes.string,
};

ColumnThread.defaultProps = {
  location: {},
  container: map(),
  data: list(),
  first: map(),
  meta: map(),
  replies: map(),
  site: '',
  title: '',
};

export default ColumnThread;
