const sessionGet = (sessionStorageKey) => {
  const store = window.sessionStorage;
  if (!store) return null;
  return JSON.parse(store.getItem(sessionStorageKey));
};

const sessionSet = (sessionStorageKey, sessionStorageVal) => {
  const store = window.sessionStorage;
  if (!store) return;
  store.setItem(sessionStorageKey, JSON.stringify(sessionStorageVal));
};

export { sessionGet, sessionSet };
