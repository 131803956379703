import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import RichTextEditor, { createEmptyValue } from 'react-rte';
import { Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import {
  Button,
  CircularProgress,
  FormHelperText,
} from '@mui/material';

import style from './wikiEdit.module.scss';
import TextFilter from '../filters/TextFilter';
import Prompt from '../../components/utils/Prompt';

const WikiEdit = ({
  fields,
  saving,
  source,
  onCancel,
  onRemove,
  onSave,
}) => {
  const [requiredErrors, setRequiredErrors] = useState(list());
  const [valueErrors, setValueErrors] = useState(list());
  const [values, setValues] = useState(map());
  const [dialog, setDialog] = useState([]);

  const onGenerateField = f => (
    <Row key={f.get('value')} className={cx([style.row, style.field, f.get('centerLabel') && style.centered])}>
      <Col xs={3}>
        <span className={cx([style.raj, 'raj', style.label])}>{f.get('label')}:</span>
      </Col>
      <Col xs={9}>
        {f.get('type') !== 'rte' &&
        <TextFilter
          inline
          type={f.get('type')}
          opts={f.get('opts')}
          freeSolo={f.get('freeSolo')}
          onFilter={change => setValues(values.merge(change))}
          fields={[
            f.delete('label').toJS(),
          ]}
          filters={values} />}
        {f.get('type') === 'rte' &&
        <React.Fragment>
          <RichTextEditor
            className={style.rte}
            value={values.get(f.get('value')) || createEmptyValue()}
            onChange={value => setValues(values.set(f.get('value'), value))} />
          {f.get('hint') &&
          <FormHelperText className={style.hint}>{f.get('hint')}</FormHelperText>}
        </React.Fragment>}
        {requiredErrors.includes(f.get('value')) &&
        <div className={style.error}>This field is required</div>}
        {valueErrors.includes(f.get('value')) &&
        <div className={style.error}>This field contains an invalid value or length</div>}
      </Col>
    </Row>
  );

  const validate = () => {
    const fieldList = fields.reduce((a, b) => a.concat(b.get('fields')), list());

    // ensure required fields have values
    const required = fieldList
      .filter(v => v.get('required'))
      .filter(v => !values.get(v.get('value')))
      .map(v => v.get('value'));

    // ensure regexp restrictions match
    const vals = fieldList
      .filter(v => v.get('test'))
      // eslint-disable-next-line security/detect-non-literal-regexp
      .filter(v => !new RegExp(v.get('test') || '')
        ?.test(values.get(v.get('value'))
          ?.toString('markdown')
          ?.trim()))
      .map(v => v.get('value'));

    setRequiredErrors(required);
    setValueErrors(vals);
    return [required, vals];
  };

  const remove = () => {
    setDialog(current => current.filter(v => v !== 'remove'));
    onRemove();
  };

  const save = (event) => {
    event.preventDefault();
    const [required, vals] = validate();

    // Make sure private topics is taken into account before saving
    const newValues = values.toJS();
    if (newValues.is_private_topic) {
      newValues.categories = 'private_topic';
    } else {
      delete newValues.organization;
    }

    if (required.isEmpty() && vals.isEmpty()) onSave(fromJS(newValues));
  };

  useEffect(() => {
    const keys = fields.map((v) => {
      if (!v.has('fields')) {
        return { value: v.get('value'), transformation: v.get('transformation') };
      }
      return v.get('fields').map(f => ({ value: f.get('value'), transformation: f.get('transformation') }));
    }).flatten();
    const keyValues = { is_published: source.get('is_published') };
    keys.forEach((v) => {
      keyValues[v.value] = (v.transformation)
        ? v.transformation(source.getIn(v.value.split('.')))
        : source.getIn(v.value.split('.'));
    });
    setValues(fromJS(keyValues));
  }, [source]);

  return (
    <div className={style['wiki-edit']}>
      {fields.map(v => (
        <Row key={v.get('value') || v.get('label')} className={style.row}>
          {v.has('fields') &&
          <Col xs={12}>
            <div className={style.section}>{v.get('label')}</div>
            {v.get('fields').map(f => onGenerateField(f))}
          </Col>}
          {!v.has('fields') &&
          <React.Fragment>
            <Col xs={3}>
              <span className={cx([style.raj, 'raj', style.label])}>{v.get('label')}:</span>
            </Col>
            <Col xs={9}>
              {onGenerateField(v)}
            </Col>
          </React.Fragment>}
        </Row>
      ))}
      <div className={style.footer}>
        <div className={style.error} />
        <div className={style.actions}>
          {onCancel &&
          <Button
            variant="contained"
            onClick={onCancel}>
            Cancel
          </Button>}
          {onRemove &&
          <Button
            variant="contained"
            disabled={saving}
            onClick={() => setDialog(current => current.concat('remove'))}>
            {saving ? <CircularProgress /> : 'Delete'}
          </Button>}
          {onSave &&
          <Button
            variant="contained"
            color="secondary"
            disabled={saving}
            onClick={event => save(event)}>
            {saving ? <CircularProgress /> : 'Save'}
          </Button>}
        </div>
      </div>
      <Prompt
        open={Boolean(dialog?.includes?.('remove'))}
        title="Warning: Delete Entry"
        acceptText="Continue"
        accept={remove}
        cancelText="Cancel"
        cancel={() => setDialog(current => current.filter(v => v !== 'remove'))}>
        You are about permanently delete this entry. Are you sure?
      </Prompt>
    </div>
  );
};

WikiEdit.propTypes = {
  fields: PropTypes.object,
  saving: PropTypes.bool,
  source: PropTypes.object,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
};

WikiEdit.defaultProps = {
  fields: list(),
  saving: false,
  source: map(),
  onCancel: null,
  onRemove: null,
  onSave: null,
};

export default WikiEdit;
