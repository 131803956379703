import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Map as map } from 'immutable';
import {
  Button,
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './commentThread.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import Post from './Post/Post';
import CommentThreadContainer from './CommentThreadContainer';
import SearchActions from '../../actions/searchActions';

const ColumnThread = ({
  location,
  meta,
  post,
  replies,
  showReplies,
  setShowReplies,
  onAction,
  onMediaClick,
}) => {
  const [loading, setLoading] = useState(false);
  const { query } = History.getCurrentLocation();

  const loadNext = () => {
    setLoading(true);
    const lastComment = replies.get(post.get('fpid')).get('data').last();
    SearchActions.searchComments(lastComment, true);
  };

  const onToggleReplies = (show, fpid, event) => {
    if (event && event.target !== event.currentTarget) return;
    setShowReplies(showReplies.set(fpid, show));
  };

  useEffect(() => {
    if (replies.isEmpty()) return;
    replies.forEach((v, k) => {
      setShowReplies(showReplies.set(k, true));
    });
    if (replies.get(post.get('fpid')) && replies.get(post.get('fpid')).get('loaded')) {
      setLoading(false);
    }
  }, [replies]);

  return (
    <React.Fragment>
      <Post
        translate
        post={post}
        key={post.get('fpid')}
        index={false}
        active={query.native_id
          ? post.get('native_id') === query.native_id
          : post.get('fpid') === query.fpid}
        repliesLoaded={replies.get(post.get('fpid')) && replies.get(post.get('fpid')).get('loaded')}
        showReplies={showReplies.get(post.get('fpid'))}
        toggleReplies={(show, fpid) => onToggleReplies(show, fpid)}
        author={Text.Strip(post.getIn(['site_actor', 'fpid'])) === meta.getIn(['site_actor', 'fpid'])}
        location={location}
        originalAuthor={meta.getIn(['site_actor', 'fpid'])}
        onAction={onAction}
        onMediaClick={onMediaClick} />
      {replies.get(post.get('fpid')) && replies.get(post.get('fpid')).get('loaded') &&
      <div
        role="link"
        name="replies.container"
        tabIndex={0}
        onKeyUp={() => null}
        onClick={e => onToggleReplies(false, post.get('fpid'), e)}
        className={cx([style.replies, !showReplies.get(post.get('fpid')) && style.hidden])}>
        {replies.get(post.get('fpid')).has('data') &&
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          onClick={e => onToggleReplies(false, post.get('fpid'), e)}>
          {replies.get(post.get('fpid')).get('data').map(r => (
            <CommentThreadContainer
              key={r.get('fpid')}
              location={location}
              meta={meta}
              onAction={onAction}
              onMediaClick={onMediaClick}
              post={r}
              replies={replies}
              showReplies={showReplies}
              setShowReplies={setShowReplies} />
          ))}
          {replies.get(post.get('fpid')).get('hasMore') &&
          <Button
            fullWidth
            className={cx([style.load, loading && style.loading])}
            icon={loading
              ? (<CircularProgress />)
              : (<Icon className={cx([style.icon, style.action])}>keyboard_arrow_down</Icon>)
            }
            onClick={() => loadNext()}>
            Load
          </Button>}
        </div>}
        {!replies.get(post.get('fpid')).has('data') && showReplies.get(post.get('fpid')) &&
        <div>
          <Paper className={cx([style['no-post'], style.disabled])}>
            <div className={style.empty}>
              <div>Unable to retrieve replies <br />
                Try again later or view the replies at <br />
                {Text.DefangLink(post.get('source_uri') || post.getIn(['container', 'url']) || '-')}
              </div>
            </div>
          </Paper>
        </div>}
      </div>}
    </React.Fragment>
  );
};

ColumnThread.propTypes = {
  location: PropTypes.object,
  meta: PropTypes.object,
  onAction: PropTypes.func,
  onMediaClick: PropTypes.func,
  post: PropTypes.object,
  replies: PropTypes.object,
  showReplies: PropTypes.object,
  setShowReplies: PropTypes.func,
};

ColumnThread.defaultProps = {
  location: {},
  meta: map(),
  onAction: null,
  onMediaClick: null,
  post: map(),
  replies: map(),
  showReplies: map(),
  setShowReplies: null,
};

export default ColumnThread;
