import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import qs from 'qs';
import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useSetRecoilState } from 'recoil';

import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  CircularProgress,
  Icon,
  ListItem,
  Paper,
  Popover,
} from '@mui/material';

import style from './post.module.scss';
import Text from '../../../utils/text';
import History from '../../../utils/history';
import SearchActions from '../../../actions/searchActions';
import Icons from '../../../constants/Icons';
import Bookmark from '../../utils/Bookmark';
import Media from '../../utils/Media';
import ContextMenuTrigger from '../../utils/ContextMenuTrigger';
import InternalLink from '../../utils/InternalLink';
import Common from '../../../utils/common';
import Iso from '../../../constants/Iso';
import Messages from '../../../constants/Messages';
import rfiFormState from '../../../stores/recoil/rfi';
import {
  UserContext,
} from '../../../components/utils/Context';

const useStyles = makeStyles(theme => ({
  post: {
    '& .MuiIcon-root': {
      margin: `0 ${theme.spacing(1)}`,
    },
  },
}));

const Post = ({
  active,
  compact,
  index,
  isFirst,
  onAction,
  onMediaClick,
  search,
  parent,
  post,
  repliesLoaded,
  showReplies,
  toggleReplies,
  translate,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const prm = user.get('prm', list());
  const { query: { query_i18n } } = History.getCurrentLocation();

  const [dialog, setDialog] = useState();
  const [hidden, setHidden] = useState(false);
  const [translation, setTranslation] = useState(false);
  const [dateList, setDateList] = useState(list());
  const [commentsFetched, setCommentsFetched] = useState(false);
  const [loadReplies, setLoadReplies] = useState(false);

  const setRfiForm = useSetRecoilState(rfiFormState);

  const imageDupes = {};

  const usernameContextOptions = {
    items: [
      {
        action: ['copy', 'filter'],
        filterKey: 'author',
        field: ['site_actor.names.handle', 'site_actor.username', 'site_actor.name'],
        fieldValue: `${post.getIn(['site_actor', 'fpid'])}::${post.getIn(['site_actor', 'names', 'handle'])}`,
        fieldName: 'Username',
        overrideQuery: {},
      },
      {
        action: ['copy'],
        field: ['site_actor.names.aliases'],
        fieldName: 'Aliases',
      },
      {
        action: ['copy'],
        field: ['site_actor.first_name'],
        fieldName: 'First Name',
      },
      {
        action: ['copy'],
        field: ['site_actor.last_name'],
        fieldName: 'Last Name',
      },
      {
        action: ['copy'],
        field: ['site_actor.native_id'],
        fieldName: 'ID',
      },
    ],
  };

  const aliases = () => {
    const actor = post.get('site_actor');
    return `<b>User ID</b>: ${actor.get('native_id')}<br />
    ${!actor.has('first_name') ? '' : `<b>First Name</b>: ${actor.get('first_name')}<br />`}
    ${!actor.has('last_name') ? '' : `<b>Last Name</b>: ${actor.get('last_name') || 'N/A'}<br />`}
    ${!actor.hasIn(['names', 'aliases']) ? '' : `<b>Aliases</b>: ${[...new Set((actor.getIn(['names', 'aliases']) || list()))]
    .filter(v => !v.includes(actor.getIn(['names', 'handle'])))
    .join(', ')}`}`;
  };

  const tooltip = (display, loaded) => (
    `<span
      data-for="global.tooltip"
      data-tip="${(loaded) ? Text.StripHtml(loaded.getIn(['body', 'text/html+sanitized'])) : 'Loading...'}">${display || '-'}
    </span>`
  );

  const onLoadComments = (numReplies) => {
    if (numReplies === 0) return;
    const fpid = post.get('fpid');
    if (!commentsFetched) {
      setLoadReplies(true);
      SearchActions.searchComments(post);
      setCommentsFetched(true);
    }
    toggleReplies(!showReplies, fpid);
  };

  const onQuoteHover = (v) => {
    SearchActions.searchReference(v.get('native_id'), 'native_id', 'chan');
  };

  const createPostLink = (strip) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const { limit, skip, sort } = query;
    const fpid = post.get('fpid');
    // Chats/forums use their created times as their id in url but their fpid gets
    // stored in id, so need to check if need to use created
    const id = (post.get('id') && post.get('id') !== fpid) ? post.get('id') : moment.utc(post.getIn(['sort_date'])).unix();
    const searchQuery = `${qs.stringify(strip ? { id, fpid, limit, skip, sort } : { ...query, id, fpid })}`;
    const updatedPathname = post.has('parent_message')
      ? `${pathname.substr(0, pathname.lastIndexOf('/'))}/${post.getIn(['parent_message', 'fpid'])}`
      : pathname;
    return (`${window.location.origin}${updatedPathname}?${searchQuery}${hash}`);
  };

  const onReport = () => {
    setHidden(true);
    const subject = `Media Report - ${post.get('fpid')}`;
    const body = `I would like to report the media file contained in the post "${post.get('fpid')}".`;
    window.open(`mailto:feedback@flashpoint-intel.com?subject=${subject}&body=${body}`, '_blank').focus();
  };

  const onRfi = () => {
    const url = createPostLink(true);
    setRfiForm({ popup: true, relevantUrl: url });
  };

  const onShare = (strip) => {
    navigator.clipboard.writeText(createPostLink(strip));
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  };

  const onSearch = (k, v) => Common.Generic.SearchRoute(post, { [k]: Text.Strip(v) });


  const onTranslate = () => {
    if (post.getIn(['body', 'text/plain'], null, '').length < 5000) {
      setTranslation(!translation);
    } else {
      SearchActions.set(['search', 'info', 'message'], Messages.TranslationLimit);
    }
  };

  const onUpThread = () => {
    const type = post.get('basetypes').slice(-2).first();
    const subtype = post.get('basetypes').slice(-1).first();
    SearchActions.upCommentThread(type, subtype);
  };

  const createDateList = () => {
    // Create a list of JSX to determine how to render it based on compact
    const userDateList = [];
    if (post.hasIn(['site_actor', 'fpid'])) {
      userDateList.push((
        <ContextMenuTrigger id="global.context" data={post} options={usernameContextOptions} key="username">
          <InternalLink
            key="username"
            to={onSearch('author',
              `${post.getIn(['site_actor', 'fpid'])}::${post.getIn(['site_actor', 'names', 'handle'])}`)}
            data-for="global.tooltip"
            data-tip={post.hasIn(['site_actor', 'names', 'aliases'])
              ? aliases()
              : 'null'}
            className={cx([
              style.h4, 'h4',
              style.mont, 'mont',
              style.normal, 'normal',
              style.a, 'a',
              style.author])}>
            {Text.Highlight(
              post.getIn(['highlight', 'site_actor.names.handle', 0]) ||
              post.getIn(['site_actor', 'names', 'handle']) ||
              post.getIn(['highlight', 'site_actor.username', 0]) ||
              post.getIn(['site_actor', 'username']) ||
              post.getIn(['site_actor', 'name']))}
          </InternalLink>
        </ContextMenuTrigger>
      ));
    } else {
      userDateList.push((
        <ContextMenuTrigger id="global.context" data={post} options={usernameContextOptions} key="username">
          <InternalLink
            key="username"
            to={onSearch('author', post.getIn(['native_id']))}
            className={cx([
              style.h4, 'h4',
              style.mont, 'mont',
              style.normal, 'normal',
              style.a, 'a',
              style.author,
              post.get('basetypes').includes('boards') && style.disabled])}>
            {Text.Highlight(
              post.getIn(['highlight', 'native_id', 0]) ||
              post.getIn(['native_id']))}
          </InternalLink>
        </ContextMenuTrigger>
      ));
    }
    userDateList.push((
      <div className={cx([!compact && style.h3, style.time])} key="date">
        {`${compact ? '' : 'on'} ${moment
          .utc(post.getIn(['sort_date']))
          .format('MMM DD, YYYY HH:mm:ss')}`}
      </div>
    ));

    setDateList(userDateList);
  };

  const displayTranslate = (
    translate &&
    (post.get('body') !== undefined) &&
    (post.getIn(['body', 'text/plain']) || '').trim() !== '' &&
    (!post.get('basetypes').includes('forum')));

  useEffect(() => {
    createDateList();
  }, [post]);

  useEffect(() => {
    if (showReplies) setCommentsFetched(true);
  }, [showReplies]);

  useEffect(() => {
    if (!translate || !translation) return;
    const id = post.get('fpid');
    const key = ['body', 'text/plain'];
    const q = {
      fpid: post.get('fpid'),
      first: isFirst,
      native_id: post.get('native_id'),
      legacy_id: post.get('legacy_id'),
      index: post.getIn(['parent_message', 'fpid']),
      basetype: post.has('basetypes') ? post.get('basetypes').toJS().join() : 'forum',
    };
    const text = Text.Post(
      post.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
      post.getIn(['body', 'text/html+sanitized']) ||
      post.getIn(['highlight', 'body.text/plain', 0]) ||
      post.getIn(['body', 'text/plain'])).toString();
    if (!post.has('translation')) SearchActions.searchTranslate(id, key, q, text);
  }, [translation]);

  const postBody = (
    query_i18n && post.getIn(['body', 'text/plain_en'])) ||
    post.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
    post.getIn(['body', 'text/html+sanitized']) ||
    post.getIn(['highlight', 'body.text/plain', 0]) ||
    post.getIn(['body', 'text/plain'],
  );

  const mayConstainJSON = postBody?.startsWith('{') && postBody?.endsWith('}');

  return (
    <div
      data-fpid={post.get('fpid')}
      id={moment.utc(post.getIn(['sort_date'])).unix()}
      name={moment.utc(post.getIn(['sort_date'])).unix()}
      className={cx([
        style.post,
        classes.post,
        parent && style.parent,
        compact && style.compact])}>
      {index &&
      <div className={cx([style.index, style.h4, 'h4', active && style.active])}>
        {post.getIn(['container_position', 'index_number']).toLocaleString() || '-'}
      </div>}
      <Paper className={cx([
        style.card,
        active && style.active,
        translate && translation && post.get('translation') && style.wide])}>
        <div className={style.header}>
          <div>
            <div>
              {((compact) ? dateList.reverse() : dateList).map(v => v)}
            </div>
          </div>
          <div className={style.tools}>
            {prm.some(v => /dat.rfi.w/.test(v)) &&
            <Icon
              data-for="global.tooltip"
              data-tip="Request for Information"
              onClick={() => onRfi()}>
              assignment
            </Icon>}
            {prm.some(v => /org.fp.r/.test(v)) &&
            <Icon
              data-for="global.tooltip"
              data-tip="Label post"
              onClick={event => onAction(event, 'tag', post)}>
              tag
            </Icon>}
            {displayTranslate &&
            <React.Fragment>
              {translation && !post.has('translation') &&
              <CircularProgress id="spinner" size={25} thickness={2} />}
              <Icon
                data-for="global.tooltip"
                data-tip="Translate post. Translations are limited to 5000 characters."
                onClick={() => onTranslate()}>
                g_translate
              </Icon>
            </React.Fragment>}
            <Icon
              data-for="global.tooltip"
              data-tip="Report Post"
              onClick={() => onReport()}>
              priority_high
            </Icon>
            <Icon
              data-for="global.tooltip"
              data-tip="Generate share link"
              onClick={event => setDialog({ key: 'share', target: event.target })}>
              share
            </Icon>
            <Popover
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog && dialog.key === 'share')}
              onClose={() => setDialog()}
              onClick={() => setDialog()}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
              {[window.location.search.includes('query=') &&
                'Share URL with <x-fp-highlight>highlighting</x-fp-highlight>',
                'Share URL']
                .filter(v => v)
                .map((v, k) => (
                  <ListItem
                    key={v}
                    onClick={() => onShare(!!k)}>
                    {Text.Highlight(v)}
                  </ListItem>))}
            </Popover>
            <div className={style.bookmark}>
              <Bookmark
                author={post.getIn(['site_actor', 'names', 'handle']) ||
                  post.getIn(['site_actor', 'username'])}
                timestamp={post.getIn(['sort_date'])}
                text={post.getIn(['body', 'text/plain'])}
                entity={post} />
            </div>
          </div>
        </div>
        {post.get('basetypes').includes('boards') && post.get('native_id') &&
        <div className={cx([style.h3, style.site])}>
          <p>
            Post ID:
            <span className={style.sitetitle}>
              {post.get('native_id')}
            </span>
          </p>
        </div>}
        { post.get('basetypes').includes('boards') && post.has('title') &&
        <div className={cx([style.h3, style.site])}>
          <p>
            Post Title:
            <span className={style.sitetitle}>
              {post.get('title')}
            </span>
          </p>
        </div>}
        {post.getIn(['location', 'country', 'full_name']) &&
        <div className={style.country}>
          { `Country: ${post.getIn(['location', 'country', 'full_name'])}` }
        </div>}
        {post.hasIn(['enrichments', 'quotes']) &&
        <div className={style.quotes}>
          {post.getIn(['enrichments', 'quotes'])
            .entrySeq()
            .map(([k, v]) => (
              <div key={k}>
                <div
                  key={k}
                  className={cx([style.quote, search.getIn(['reference', 'chan', v.get('native_id')]) && search.getIn(['reference', 'chan', v.get('native_id')]).isEmpty() && style.disabled])}
                  role="link"
                  name="post.body.quote"
                  tabIndex={0}
                  onKeyUp={() => null}
                  onMouseEnter={() => { ReactTooltip.rebuild(); onQuoteHover(v); }}
                  onFocus={() => false}
                  dangerouslySetInnerHTML={{ __html: tooltip(`>>${v.get('native_id')}`, search.getIn(['reference', 'chan', v.get('native_id')])) }} />
              </div>))}
        </div>}
        <div className={style.container}>
          <div>
            {translation && <div className={style.original}>Original Text:</div>}
            <div
              dir="auto"
              className={cx([
                style.body,
                translation && post.get('translation') && style.ogtext])}>
              {/* text */}
              {!Text.Empty(post.getIn(['body']))
                && post.getIn(['body', 'text/plain'])
                && post.getIn(['body', 'text/plain']) !== '[]' &&
              Text.Highlight(
                `${Text.Post(postBody)}<br/>
                <span class="translation">${query_i18n && post.hasIn(['body', 'text/plain_native_language']) ? `Translated from <b>${Iso?.[post.getIn(['body', 'text/plain_native_language'])]?.name || 'Unknown'}</b> to <b>English</b>` : ''}</span>`,
                false,
                (mayConstainJSON ? style.containsJSON : undefined),
              )}
              {!post.getIn(['body', 'text/plain']) && post.has('url') &&
              post.get('url')}
              {/* placeholder text - qq/discord empty body stored as [] */}
              {(!post.getIn(['body', 'text/plain']) || post.getIn(['body', 'text/plain']) === '[]')
                && !post.has('media_v2') && !post.has('url') &&
                <div className={style.placeholder}>This post contains no text.</div>}
              {/* media */}
              {post.get('media_v2') &&
              !hidden &&
              !['webpage'].includes(post.getIn(['media_v2', 'type'])) &&
              post.get('media_v2')
              .filter((media) => {
                if (!media) return false;
                // Filter out thumbnails
                const filename = media.get('filename') || media.get('file_name');
                if (/^\/\.media\/t_/i.test(filename)) {
                  return false;
                }

                // Filter out duplicate images
                const sha1 = media.get('sha1', '');
                if (!sha1) return false;
                if (!imageDupes[`${sha1}`]) {
                  imageDupes[`${sha1}`] = true;
                  return true;
                }

                return false;
              })
              .map(media => (
                <Media
                  expanded
                  data={media}
                  key={media.get('fpid')}
                  onMediaClick={(src, data) => onMediaClick(src, fromJS({ media: data, post }))}
                  postFPID={media?.get('fpid')} />))}
            </div>
          </div>
          {translation && post.get('translation') &&
          <div>
            <div className={style.translation}>
              <div
                className={cx([style.icon, style.original])}
                dangerouslySetInnerHTML={{ __html: Icons.googleTranslate }} />
              <div className={style.original}>translated from
                <b>&nbsp;{(Iso[post.getIn(['translation', 'detectedLanguage'])] || { name: '-' }).name}</b> to
                <b>&nbsp;{(Iso[post.getIn(['translation', 'translationLanguage'])] || { name: '-' }).name}</b>
              </div>
            </div>
            <div className={cx([style.body, style.item, style.translated])} dir="auto">
              {Text.Highlight(
                Text.Post(
                  Text.Translate(post.getIn(['translation', 'translation']))))}
            </div>
          </div>}
        </div>
        {(post.has('num_replies')
          || post.has('parent_message')
          || (post.get('basetypes').includes('comment') && !post.has('parent_message')))
          &&
          <div className={style.nested}>
            <div className={style.load}>
              <div
                role="link"
                tabIndex={0}
                onKeyUp={() => null}
                className={cx([style.link, style.a, 'a'])}
                onClick={() => (isFirst ? onUpThread() : onLoadComments(post.get('num_replies')))}>
                {!isFirst && post.get('num_replies') > 0 && (repliesLoaded || !loadReplies) &&
                <div>
                  {!showReplies &&
                  <div>
                    Show Replies
                    <Icon className={cx([style.icon, style.action, 'material-icons'])}>
                      keyboard_arrow_down
                    </Icon>
                  </div>}
                  {showReplies &&
                  <div>
                    Hide Replies
                    <Icon className={cx([style.icon, style.action, 'material-icons'])}>
                      keyboard_arrow_up
                    </Icon>
                  </div>}
                </div>}
                {!isFirst && !repliesLoaded && loadReplies &&
                <CircularProgress />}
                {isFirst && (post.has('parent_message')
                || (post.get('basetypes').includes('comment') && !post.has('parent_message'))) &&
                <div className={cx([style.link, style.a, 'a'])}>
                  View parent level thread
                  <Icon className={cx([style.icon, style.action, 'material-icons'])}>
                    keyboard_arrow_up
                  </Icon>
                </div>}
              </div>
            </div>
          </div>}
      </Paper>
    </div>
  );
};

Post.propTypes = {
  active: PropTypes.bool,
  compact: PropTypes.bool,
  index: PropTypes.bool,
  isFirst: PropTypes.bool,
  onAction: PropTypes.func,
  onMediaClick: PropTypes.func,
  parent: PropTypes.bool,
  post: PropTypes.object,
  repliesLoaded: PropTypes.bool,
  search: PropTypes.object,
  showReplies: PropTypes.bool,
  toggleReplies: PropTypes.func,
  translate: PropTypes.bool,
};

Post.defaultProps = {
  active: false,
  compact: false,
  index: false,
  isFirst: false,
  onAction: null,
  onMediaClick: null,
  parent: false,
  post: map(),
  repliesLoaded: false,
  search: map(),
  showReplies: false,
  toggleReplies: null,
  translate: false,
};

export default Post;
