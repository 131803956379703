/* eslint-disable arrow-parens */
import { decodeNestedURI, generateUrl, routeMapper } from '../useRouteMapper/useRouteMapper';
import {
  knownLinkTransformRules,
  knownSupportedSearchParams,
  knownUnsupportedPatterns,
  simpleTopLevelDomainRemaps,
} from '../knownTransforms/knownTransforms';

/**
 * @remarks
 * this is a curry (function that builds an anoymous contextual function)
 * Try to translate the link.
 * short circuit known unsupported urls
 * dont pass the routeMapper junk falsey values
 */

function isSpecificReportPage(url: string) {
  return url.includes('/home/intelligence/reports/');
}

const fpidRegex = /[&?]fpid=(.*?)(?=&|$)/;
function findFpidinQuery(url: string) {
  const fpid = decodeNestedURI(url).match(fpidRegex)?.[1];
  return fpid;
}

function makeSearchContextCommunitiesByFpidPath(fpid: string) {
  return `/search/context/communities/${fpid}`;
}

function hasUnsupportedSearchParams(url: string) {
  return Array.from(new URL(generateUrl(url)).searchParams.keys()).some(
    (param) => !knownSupportedSearchParams.includes(param),
  );
}

function isUnsupportedLegacySearch(url: string) {
  return url.startsWith('https://fp.tools/home/ddw?') && hasUnsupportedSearchParams(url);
}

export function makeLinkJanitor(activeLinks = knownLinkTransformRules) {
  return (url: string) => {
    if (!url) return url;

    const fpid = findFpidinQuery(url);
    const isSearch = url.includes('home/search');
    const isTwitterData = url.includes('home/ddw/twitter/tweet');
    const isMediaData = url.includes('home/assets/media/items');
    // we don't support twitter data yet in ignite so we don't have an associated fpid
    // media data is handled in routeMapper logic
    if (fpid && !isSpecificReportPage(url) && !isTwitterData && !isMediaData && !isSearch) {
      return makeSearchContextCommunitiesByFpidPath(fpid);
    }

    // Since we are sunsetting fp tools, we can just drop bookmarks that aren't supported
    if (knownUnsupportedPatterns.find((prefix) => url.startsWith(prefix))) {
      return undefined;
    }

    if (isUnsupportedLegacySearch(url)) return undefined;

    const rewrite = routeMapper(activeLinks, url);
    if (rewrite) return rewrite;

    // link transformer can't handle simple top level domain changes
    // something to do with react matchRoutes and new URL().pathname being returning "/"
    /* eslint-disable */
    for (const topLevelRemap of simpleTopLevelDomainRemaps)
      for (const oldRoute of topLevelRemap.oldRoutes)
        if (url.startsWith(oldRoute)) return url.replace(oldRoute, topLevelRemap.newRoute);
    /* eslint-enable */

    return undefined;
  };
}
