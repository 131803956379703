import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import moment from 'moment';
import { List as list, Map as map } from 'immutable';

import style from './reports.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import Apps from '../../constants/Apps';
import Report from '../../components/reports/Report';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import { SearchContext, UserContext } from '../../components/utils/Context';

const Reports = ({
  location,
  match: {
    params,
  },
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);

  const pageHistory = ({ type = '', id = '' }) => {
    if (!type.includes('.')) {
      const { search: searchQuery, pathname } = History.getCurrentLocation();
      const now = +moment.utc().unix();
      const app = Apps.find(v => v.value === `collections.${type}.view`);
      const title = search.getIn(['result', ...(app.title || 'title')]);
      document.title = Text.Strip(`Flashpoint - ${Text.StripHtml(Text.Sentence(title))}`);
      const saveQuery = map({
        path: `${pathname}${searchQuery}`,
        title,
        created: now,
      });

      SearchActions.set(['search', 'viewed'], search.get('viewed').push(id));
      UserActions.saveHistory('items', saveQuery);
    }
  };

  useEffect(async () => {
    setTimeout(() => window.scrollTo(0, 0));
    SearchActions.search('reports', params.id);

    return () => {
      SearchActions.set(['search', 'result', 'reports'], map());
    };
  }, [params.id]);

  useEffect(() => {
    if (!search.getIn(['result', 'reports', 'id'])) return;
    pageHistory({ params, type: 'reports' });
  }, [search.getIn(['result', 'reports', 'id'])]);

  return (
    <div className={style.reports}>
      <Report
        apps={user.get('apps')}
        location={location}
        defaults={search.getIn(['defaults', 'reports']) || map()}
        anchor={params.anchor}
        related={search.getIn(['result', 'reports', 'related']) || list()}
        report={search.getIn(['result', 'reports'])}
        glossary={search.getIn(['resources', 'glossary'])}
        cdnTag={user.getIn(['prefs', 'image_cdn']) ? '&cdn=true' : ''}
        glossaryHighlight={user.getIn(['prefs', 'glossary_highlight'])} />
    </div>
  );
};

Reports.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Reports;
