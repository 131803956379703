import React from 'react';

import PropTypes from 'prop-types';

import style from './errorboundary.module.scss';
import Invalid from './Invalid/Invalid';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      hasError: false,
      prod: !('_self' in React.createElement('div')),
    };
  }

  componentDidUpdate({ location }) {
    const { location: { pathname } } = this.props;
    const { error } = this.state;

    if (pathname !== location.pathname && error) {
      this.setState({
        error: '',
        hasError: false,
      });
    }
  }

  componentDidCatch(error) {
    this.setState({ error, hasError: true });
  }

  render() {
    const { children } = this.props;
    const { error, hasError, prod } = this.state;

    return (
      <div className={style.errorboundary}>
        {hasError &&
        <div className={style.empty}>
          <Invalid
            inline
            icon="error"
            subject="fp.tools - error"
            title="Error loading page">
            <div>Oops, something went wrong while loading this page.
              Our team is aware and is working on the issue. Try one of the following:
            </div>
            <ul>
              <li>
                <b>
                  <a
                    role="link"
                    tabIndex={0}
                    onKeyUp={() => null}
                    onClick={() => window.location.reload(true)}>Reload
                  </a>
                </b> the current page
              </li>
              <li><b><a href="/home">Visit</a></b> your homepage</li>
              <li><b><a href="/home/help/settings">Configure</a></b> your settings</li>
            </ul>
            <pre>{!prod && `${window.location.href}<br/>${error.toString()}`}</pre>
          </Invalid>
        </div>}
        {!hasError && children}
      </div>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

ErrorBoundary.defaultProps = {
  children: null,
  location: {},
};

export default ErrorBoundary;
