import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';

import ReactTooltip from 'react-tooltip';
import { Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import Button from '@mui/material/Button';

import style from './alertingdashboard.module.scss';
import StackedBar from '../../widget/StackedBar/StackedBar';
import Table from '../../widget/Table/Table';
import Dashboard from '../Dashboard';
import Hero from '../../widget/Hero/Hero';
import Query from './query';
import Common from '../../../utils/common';
import History from '../../../utils/history';
import TimeoutMessage from '../../alerting/TimeoutMessage';
import easyUseState from '../../../hooks/easyUseState';
import useDidUpdate from '../../../hooks/useDidUpdate';
import easyUseEffect from '../../../hooks/easyUseEffect';
import easyMemo from '../../../components/utils/Memoize';

const AlertingDashboard = ({
  filter,
  prm,
}) => {
  const mounted = useRef();
  const [data, setData] = easyUseState(map());
  const [noHits, setNoHits] = easyUseState();
  const [skips, setSkips] = easyUseState({ noisy: 0, empty: 0 });
  const [loaded, setLoaded] = easyUseState(false);
  const limit = 10;

  const [curationType, setCurationType] = easyUseState('ALL');
  // This filter function is used to determine which keywords to display
  // Depending on which curation view we are in
  const filterKeywordWithCurationType = (keyword) => {
    switch (curationType) {
      case 'ALL': return true;
      case 'AUTOMATED': return !keyword.get('is_curated');
      case 'CURATED': return keyword.get('is_curated');
      default: return true;
    }
  };
  // This function builds a locally held set of filters
  // That we combine with our filter prop when fetching metrics
  const buildLocalFilter = () => {
    switch (curationType) {
      case 'ALL': return {};
      case 'AUTOMATED': return { tags: '-sent' };
      case 'CURATED': return { tags: 'sent' };
      default: return {};
    }
  };
  const localFilter = buildLocalFilter();

  const canEditKeywords = prm.some(p => /kw.*.w/.test(p));

  const onLoad = async () => {
    setLoaded(false);
    const combinedFilter = {
      ...filter.toJS(),
      ...localFilter,
    };
    setNoHits();
    setData(map());
    await Promise.all([
      Query.onGetTotalActiveKeywords(combinedFilter),
      Query.onGetTotalHits(combinedFilter),
      Query.onGetHitsBySource(combinedFilter),
      Query.onGetHitsByKeywords(combinedFilter),
    ]).then(async ([totalActiveKeywords, totalHits, hitsBySource, hitsByKeyword]) => {
      const filteredHitsBySource = {
        ...hitsBySource,
      };

      let total = 0;
      filteredHitsBySource.data.forEach((v) => {
        total += v.Twitter;
        return v;
      });

      const removeTypes = (type) => {
        filteredHitsBySource.basetypeMappings = filteredHitsBySource.basetypeMappings
          .filter(v => v.key !== type);
        filteredHitsBySource.data = filteredHitsBySource.data.filter((v) => {
          const pt = v;
          delete pt[String(type)];
          return pt;
        });
      };

      /*
       * Remove invalid data and basetypes if user doesn't have permission to see it
       * or there are no hits
      */
      [
        'cookie_domain',
        'enterprise_affected_domain',
        (total === 0 || !prm.some(p => /twtr/.test(p))) && 'twitter',
      ]
        .filter(v => v)
        .forEach(type => removeTypes(type));

      const result = {
        totalActiveKeywords,
        totalHits,
        hitsBySource: {
          data: { data: filteredHitsBySource.data },
          aggColors: filteredHitsBySource.colors,
          basetypeMappings: filteredHitsBySource.basetypeMappings,
        },
        hitsByKeyword,
        keywordData: totalActiveKeywords,
      };
      return result;
    })
      .then((results) => {
        // Don't rerun search query if it's been run already
        // This metric doesn't change on toggle
        if (!results.totalPostsScanned) {
          Promise.all([
            Query.onGetTotalPostsScanned(filter.toJS()),
          ]).then(([totalPostsScanned]) => {
            if (!mounted.current) return;
            setData(
              fromJS({
                ...results,
                totalPostsScanned,
              }),
            );
          });
        } else {
          setData(
            fromJS({
              ...results,
            }),
          );
        }
      })
      .finally(() => {
        if (mounted.current) setLoaded(true);
      });
  };

  const onKeywordAlerts = (keyword) => {
    ReactTooltip.hide();
    const id = keyword.get('id');
    const name = keyword.get('name');
    const ownerId = filter.get('ownerId', filter.get('owner_id'));
    const { query } = History.getCurrentLocation();
    History.navigateTo({
      pathname: '/home/alerting/alerts',
      query: {
        ...query,
        owner_id: ownerId,
        keyword: `${name}::${id}`,
        notification_profile_id: true, // display alerts even if not subbed
      },
    });
  };

  const onKeywordClick = (keyword) => {
    ReactTooltip.hide();
    const id = keyword.get('id');
    const ownerId = filter.get('ownerId', filter.get('owner_id'));
    const { query } = History.getCurrentLocation();
    History.navigateTo({
      pathname: '/home/alerting/keywords',
      query: {
        ...query,
        owner_id: ownerId,
        keyword_id: id,
      },
    });
  };

  const onSourceClick = (type, query) => {
    const platform = Common.Basetypes.LabelToSearchType(type);
    const route = ['code_repository'].includes(platform) ? 'dea' : 'alerts';
    History.navigateTo({
      pathname: `/home/alerting/${route}`,
      query: {
        platform,
        owner_id: filter.get('ownerId', filter.get('owner_id')),
        date: query.key,
        since: moment.utc(query.key).startOf('day').format(),
        until: moment.utc(query.key).endOf('day').format(),
      },
    });
  };

  const formatFilterForComparison = (filterToFormat) => {
    const newFilters = filterToFormat.toJS();
    delete newFilters.owner_id;
    // TODO: when the user clicks between 'Alerts' and 'Overview',
    // the '-archived' tag drops from filters
    // which makes some unecessary renders and loads
    return newFilters;
  };

  useDidUpdate(() => {
    if (filter.isEmpty() ||
      !filter.get('basetypes') ||
      !filter.get('keywords') ||
      !filter.get('keywordClasses') ||
      !filter.get('ownerId')) return;
    setData(map());
    onLoad();
  }, [formatFilterForComparison(filter)]);

  useDidUpdate(() => {
    setData(map());
    if (!filter.isEmpty()) onLoad();
  }, [curationType]);

  easyUseEffect(() => {
    if (!data || data.isEmpty()) return;
    if (!data?.get('hitsByKeyword')?.toJS() && !data?.get('totalActiveKeywords')?.toJS()) return;
    const noHitsWorker = new Worker('/nohits.worker.js', import.meta.url);
    noHitsWorker.postMessage({
      source: 'alerting',
      data: [
        data?.get('hitsByKeyword')?.toJS(),
        data?.get('totalActiveKeywords')?.toJS(),
        curationType,
      ],
    });
    noHitsWorker.onmessage = (e) => {
      setNoHits(fromJS(e.data));
    };
  }, [data]);

  easyUseEffect(() => {
    mounted.current = true;
    if (!filter.isEmpty()) onLoad();
    return (() => { mounted.current = false; });
  }, []);


  return (
    <React.Fragment>
      <Col xs={12} className={style.containers}>
        <div className={style.curation}>
          {/* Button styles here are emulating the header menu */}
          <Button
            onClick={() => setCurationType('ALL')}
            className={cx(['h4', 'mont', style.button,
              curationType === 'ALL' && style.active])}>
            ALL
          </Button>
          <Button
            onClick={() => setCurationType('AUTOMATED')}
            className={cx(['h4', 'mont', style.button,
              curationType === 'AUTOMATED' && style.active])}>
            AUTOMATED
          </Button>
          <Button
            onClick={() => setCurationType('CURATED')}
            className={cx(['h4', 'mont', style.button,
              curationType === 'CURATED' && style.active])}>
            CURATED
          </Button>
        </div>
      </Col>
      <Col xs={3} className={style.containers}>
        <Dashboard data={data} title="Alerting Overview">
          <div className={style.sections}>
            <div className={style.section}>
              <Hero
                labels={fromJS(['Since'])}
                values={fromJS([{ key: 'key' }])}
                results={fromJS({ key: filter.get('date') })}
                styles={{ width: '100%' }}
                onClick={() =>
                  History.navigateTo({
                    pathname: '/home/alerting/alerts',
                    query: {
                      owner_id: filter.get('ownerId', filter.get('owner_id')),
                    },
                  })} />
            </div>
            <div className={style.section}>
              <Hero
                labels={fromJS(['posts scanned'])}
                values={fromJS([{ key: 'total' }])}
                results={fromJS({ total: data.get('totalPostsScanned') })}
                styles={{ width: '50%' }}
                disabled />
              <Hero
                labels={fromJS(['active keywords'])}
                values={fromJS([{ key: 'total' }])}
                results={fromJS({ total: data.get('totalActiveKeywords', list()).size })}
                styles={{ width: '50%' }}
                onClick={() =>
                  History.navigateTo({
                    pathname: '/home/alerting/keywords',
                    query: {
                      owner_id: filter.get('ownerId', filter.get('owner_id')),
                      status: 'ACTIVE',
                    },
                  })
                } />
            </div>
            <div className={style.section}>
              <Hero
                labels={fromJS(['active keywords with hits'])}
                values={fromJS([{ key: 'total' }])}
                results={fromJS({
                  total: (data
                    .get('hitsByKeyword') || list())
                    .filter(v => v.get('count') > 0)
                    // Joining this with keyword data so we can filter to make
                    // sure we only display num keywords of correct curation status
                    .map(v => data.get('keywordData', list())
                      .find(k => k.get('id') === v.get('value'), null, map()))
                    .filter(filterKeywordWithCurationType)
                    .size,
                })}
                styles={{ width: '50%' }}
                onClick={() =>
                  History.navigateTo({
                    pathname: '/home/alerting/keywords',
                    query: {
                      owner_id: filter.get('ownerId', filter.get('owner_id')),
                    },
                  })
                } />
              <Hero
                labels={fromJS(['hits (active and inactive)'])}
                values={fromJS([{ key: 'total' }])}
                results={fromJS({ total: data.get('totalHits') })}
                tooltip={`${data?.get('totalHits')?.toLocaleString() || '0'} hits`}
                styles={{ width: '50%' }}
                onClick={() =>
                  History.navigateTo({
                    pathname: '/home/alerting/alerts',
                    query: {
                      owner_id: filter.get('ownerId', filter.get('owner_id')),
                    },
                  })
                } />
            </div>
          </div>
        </Dashboard>
      </Col>
      <Col xs={9} className={style.containers} data-testid="alerting.hits-by-source">
        <Dashboard data={data} title="Hits By Source">
          <StackedBar
            legend
            scale="auto"
            results={data.getIn(['hitsBySource', 'data'])}
            labels={fromJS([{ key: 'key' }])}
            colors={data.getIn(['hitsBySource', 'aggColors'])}
            keyOrder={(data.getIn(['hitsBySource', 'basetypeMappings']) || list())
              .map(v => v.get('label'))}
            onCellClick={(v, k, e) => onSourceClick(k, e)}
            emptyMessage="No hits to display"
            filteredHitsBySource={filter.get('hitsBySource')} />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers} data-testid="alerting.most-active">
        <Dashboard data={data} title="Most Active Keywords">
          <Table
            actionable={{
              ...canEditKeywords && {
                edit: {
                  onClick: v => onKeywordClick(v),
                  icon: 'edit',
                  tooltip: 'Edit the keyword',
                },
              },
              search: {
                onClick: v => onKeywordAlerts(v),
                icon: 'book',
                tooltip: 'View alerts from this keyword',
              },
            }}
            styles={{ marginRight: '25px' }}
            pagination={false}
            onClick={() => false}
            widget={fromJS({ limit })}
            results={fromJS({
              data: (data
                .get('hitsByKeyword') || list())
                .map(v => data.get('keywordData', list())
                  .find(k => k.get('id') === v.get('value'), null, map())
                  .set('count', v.get('count')))
                .filter(filterKeywordWithCurationType)
                .filter(v => Boolean(v.get('active')))
                .slice(skips.noisy, skips.noisy + limit),
            })}
            values={fromJS([
              { key: 'name', label: 'Name', tooltip: true },
              { key: 'value', label: 'Keyword', tooltip: true },
              { key: 'count', label: '# of Hits', render: (v = '') => v.toLocaleString(), tooltip: true },
            ])}
            labels={fromJS({ key: 'key' })}
            emptyMessage="No keywords to display" />
        </Dashboard>
      </Col>
      <Col xs={6} className={style.containers} data-testid="alerting.keywords-no-hits">
        <Dashboard data={noHits} title="Keywords with No Hits">
          <Table
            actionable={{
              ...canEditKeywords && {
                edit: {
                  onClick: v => onKeywordClick(v),
                  icon: 'edit',
                  tooltip: 'Edit the keyword',
                },
              },
            }}
            pagination
            onClick={() => false}
            onOffset={v => setSkips({ ...skips, empty: v })}
            widget={fromJS({ limit })}
            results={fromJS({
              skip: skips.empty,
              data: noHits?.slice(skips.empty, skips.empty + limit) || null,
              total: noHits?.size || null,
            })}
            values={fromJS([
              { key: 'name', label: 'Name', tooltip: true },
              { key: 'value', label: 'Keyword', tooltip: true },
            ])}
            labels={fromJS({ key: 'key' })}
            emptyMessage="No keywords without hits" />
        </Dashboard>
      </Col>
      {loaded && data.isEmpty() &&
        <Col xs={12} className={style.containers} style={{ marginTop: '2rem' }}>
          <TimeoutMessage loaded={false} />
        </Col>}
    </React.Fragment>
  );
};

AlertingDashboard.propTypes = {
  filter: PropTypes.object,
  prm: PropTypes.object,
};

AlertingDashboard.defaultProps = {
  filter: map(),
  prm: map(),
};

export default easyMemo(AlertingDashboard);
