import html2pdf from 'html2pdf.js';
import { fromJS } from 'immutable';

import Common from '../../../utils/common';
import History from '../../../utils/history';
import SearchUtils from '../../../utils/searchUtils';
import {
  assignFiltersToSavedSearch,
  makeFiltersFromSavedSearch,
} from '../../../utils/SavedSearch';

import { makeQueryOfType } from './makeCopyPastedQueryTypes';

const downloadPng = () => {
  const opts = {
    filename: 'exposuredashboard.png',
    image: { type: 'png' },
    html2canvas: {
      imageTimeout: 0,
    },
    jsPDF: { unit: 'px', format: [1920, 1080], orientation: 'landscape' },
  };

  // Grab the dashboard
  const dashEl = document.querySelector('[class*=homeDashboard_]');

  // Clone it and style it for the screenshot
  const renderEl = dashEl.cloneNode(true);
  renderEl.style.backgroundColor = '#e8e8e8';
  renderEl.style.padding = '16px';
  renderEl.style.height = '100%';

  // Render clone to a png
  html2pdf()
    .set(opts)
    .from(renderEl)
    .toCanvas()
    .outputImg('img')
    .then((res) => {
      // Create a virtual download link to get around
      // browser security
      const downloadLink = document.createElement('a');
      downloadLink.href = res.src;
      downloadLink.download = opts.filename;
      downloadLink.click();
      document.removeChild(downloadLink);
    });
};

const prepareArgsForMakeQuery = (filters) => {
  const { since, until, sinceDate, untilDate } = SearchUtils.parseDate(filters);
  return { since, until, sinceDate, untilDate };
};

const savedSearchToQuery = (savedSearch, baseFilters) => {
  const savedSearchFilters = makeFiltersFromSavedSearch(savedSearch);
  const querylessBaseFilters = { ...baseFilters };
  delete querylessBaseFilters.query;
  const type = savedSearch.type?.searchType;
  const filters = {
    ...savedSearchFilters,
    ...querylessBaseFilters,
  };

  const argsForMakeQuery = prepareArgsForMakeQuery(filters);
  return makeQueryOfType({ type, filters, ...argsForMakeQuery });
};

const filtersToQuery = filters => makeQueryOfType({ type: 'communities', filters });

const navigateFromExpoDashSavedSearch = (savedSearch, filters = {}) => {
  const limitedFilters = { ...filters };
  delete limitedFilters.query;
  // these 'breached_' filters were interfering with the 'author' filter
  // is that by design or is it a bug in Common.SavedSearch.Route?
  delete limitedFilters.breached_at;
  delete limitedFilters.breached_since;
  delete limitedFilters.breached_until;
  Common.SavedSearch.Route(
    fromJS(
      assignFiltersToSavedSearch(savedSearch, limitedFilters),
    ),
  );
};

const navigateFromExpoDashGeneric = (filters = {}, basetype = 'communities') => {
  History.navigateTo(
    Common.Generic.SearchRoute(
      fromJS({ type: { value: basetype } }),
      filters,
    ),
  );
};

const navigateFromExpoDashMedia = (filters = {}) => (navigateFromExpoDashGeneric(filters, 'media'));

export const navigateFromExpoDash = ({
  savedSearch = null,
  filters = {},
  media = false,
}) => {
  if (savedSearch) {
    navigateFromExpoDashSavedSearch(savedSearch, filters);
  } else if (media) {
    navigateFromExpoDashMedia(filters);
  } else {
    navigateFromExpoDashGeneric(filters || {});
  }
};

export {
  downloadPng,
  savedSearchToQuery,
  filtersToQuery,
};
