export default [
  {
    fpid: '_UvkF0E1X-iQT4U0x4dzKw',
    name: 'Reputation',
    children: [
      {
        fpid: 'wCxlLFgwUoaNoL_rq8LJow',
        name: 'Top-Tier',
      },
      {
        fpid: 'xur8eBpjVJGCoxE3Edq8qA',
        name: 'Entry-Level',
      },
      {
        fpid: 'MaFZNH6OXgCitJ1d5zhjNQ',
        name: 'Official',
      },
      {
        fpid: '91NjJIp3WyOrvilzQxGaQw',
        name: 'Mirror',
      },
      {
        fpid: '6dRfzf8AW1GytfLzRulnNw',
        name: 'Low Activity',
      },
      {
        fpid: 'HUt1GxlqVMa2d5aao0BY7Q',
        name: 'Middle-Tier',
      },
    ],
  },
  {
    fpid: '_S6JtsqPU3iHHR6VvyPbgw',
    name: 'Cyber Threat',
    children: [
      {
        fpid: '3gelicE1XrmNN5EnNusRCg',
        name: 'Marketplace Forums',
      },
      {
        fpid: 'kloM7hgeVGepay4S4Y3qFA',
        name: 'Hacking',
      },
      {
        fpid: '5TcPeiRiX7KoA-QyZJJnUQ',
        name: 'Fraud',
      },
      {
        fpid: 'wE5OSP52Vempo57PthQ4ZA',
        name: 'Malware',
      },
      {
        fpid: 'j4qUjKdfWXqpwDvXMSmQ8A',
        name: 'Hacktivism',
      },
      {
        fpid: 'jelT44ZtVVSKA8STbfr8MA',
        name: 'Carding',
      },
      {
        fpid: 'LufNj56fXuy8rCFWYLBRqQ',
        name: 'Security',
      },
    ],
  },
  {
    fpid: 'y3PBAGwtVGOFVJk97FOleg',
    name: 'Accessibility',
    children: [
      {
        fpid: 'HPXedUz2X1SmiQJs79F5BQ',
        name: 'Password-Protected',
      },
      {
        fpid: 'WCS3k2Z5XMin09PWDY1GqQ',
        name: 'Offline',
      },
      {
        fpid: '5A8jJH_7WbC5_-k2M1bVMg',
        name: 'Vetted',
      },
      {
        fpid: 'YJQdyEsgWf-7u5u83hgKGA',
        name: 'Tor',
      },
      {
        fpid: '',
        name: 'Clearnet',
      },
    ],
  },
  {
    fpid: '0TLBF9N2UGyNlu5mJmFQWw',
    name: 'Physical Threat',
    children: [
      {
        fpid: 'cFqK6quFWSOoz3tlcCg9yg',
        name: 'ISIS (Islamic State)',
      },
      {
        fpid: 'm2KAU7ruUm2SCL0Jqk-eXw',
        name: 'Hezbollah',
      },
      {
        fpid: '8rxIEEcfWbK9uh5iqMgirg',
        name: 'Shabaab',
      },
      {
        fpid: 'I_-vwNc8U7SmxnaniHKOow',
        name: 'Jihadist',
      },
      {
        fpid: 'Kf8KGbDZXVGTdK9hWqJ-Yw',
        name: 'CBRNE',
      },
      {
        fpid: 'Gk6-QHxUVUSIwlG4AM4v0g',
        name: 'Right Wing Extremist',
      },
      {
        fpid: 'Lnc3TWBTVP2rAgSjTdlk3w',
        name: 'White Supremacist',
      },
      {
        fpid: 'SpEBJ8kSXPCq2AyuW9ntig',
        name: 'Communities in Conflict',
      },
    ],
  },
  {
    fpid: 'LEpTeIzMWoq8lV3E9YG6rQ',
    name: 'Language',
    children: [
      {
        fpid: 'VqZhk3QOWBSEjwI0lcZOkw',
        name: 'English',
      },
      {
        fpid: '0oxd9NEvVx-Sa4lNyy-t8g',
        name: 'Russian',
      },
      {
        fpid: 'kA4caWEMW-KD-i8oC6sVfQ',
        name: 'Chinese',
      },
      {
        fpid: 'UMgt9xoXU1yYWNVoiZ3rUQ',
        name: 'Persian',
      },
      {
        fpid: '8VQiZtdkU02mcJkgxJ3e_g',
        name: 'Arabic',
      },
      {
        fpid: '-oHRuiUEW5i-ZCh0B1f5vg',
        name: 'Urdu',
      },
      {
        fpid: '9CO2j0yKUU-l3UHVZyEHSw',
        name: 'Spanish',
      },
      {
        fpid: 'RwrmJ4E-XE68hMF8tBUUHA',
        name: 'Portuguese',
      },
      {
        fpid: 'iuvlRtFCW6iPdJvVqMA06Q',
        name: 'French',
      },
      {
        fpid: 'dtl2l0wlV2q9U9qKyC-E8Q',
        name: 'German',
      },
      {
        fpid: 'xPb9hGI3VV-4mhQO4ks26g',
        name: 'Pashto',
      },
      {
        fpid: 'Yhx4rDO4WZSFyL9b81N7Ew',
        name: 'Albanian',
      },
      {
        fpid: 'gLq01p5sUKis911h61s4Ow',
        name: 'Turkish',
      },
      {
        fpid: '1KGJWfg1Ua-BNsIdwMmnag',
        name: 'Bahasa',
      },
      {
        fpid: 'Y9UOil7kWE64lhSYYRi62A',
        name: 'Polish',
      },
      {
        fpid: 't1pLZJjkUCCTmNJ1-Ah10A',
        name: 'Bengali',
      },
      {
        fpid: 'fSaFsy-hWmqNG9HIv07cfg',
        name: 'Romanian',
      },
      {
        fpid: '-NXyTFnTUxOwJKZXmtB3LA',
        name: 'Somali',
      },
      {
        fpid: 'MR1Wpnk7UBSNB0UQr4flKA',
        name: 'Korean',
      },
      {
        fpid: '3sT-Gve1UwiKk82ZuSELLg',
        name: 'Japanese',
      },
      {
        fpid: 'u_fdy_fvUw-ThErBGXWbJg',
        name: 'Vietnamese',
      },
      {
        fpid: 'fwg5TD4GXW2_cgLvPj3_2A',
        name: 'Italian',
      },
    ],
  },
  {
    fpid: 'Le4Idn3hW5SySQRCqRo5oA',
    name: 'State Actors',
    children: [],
  },
  {
    fpid: '9AGq6JHRXduu2BR3tExrMw',
    name: 'Violent Non-State Actors',
    children: [
      {
        fpid: 'Q8rZYbHjWyGT9_RwaEo7Kg',
        name: 'Al-Qaida',
      },
      {
        fpid: 'Sdyi-louXxS_z7BarPLFfQ',
        name: 'Sunni',
      },
    ],
  },
  {
    fpid: 'fYisFPZTWR6Bo8i6UjBngg',
    name: 'Drugs',
    children: [],
  },
  {
    fpid: 'DYy3emVJW5-G7OziZFWqIQ',
    name: 'Ukrainian',
    children: [],
  },
  {
    fpid: 'mXhERuoyXGKfPVMiyFqnaQ',
    name: 'Esoteric Extremism',
    children: [],
  },
];
