import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { debounce, isEmpty } from 'lodash';

import cx from 'classnames';
import moment from 'moment';
import { fromJS, Map as map } from 'immutable';

import Dropzone from 'react-dropzone-uploader';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  ListItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

import style from './credentials.module.scss';
import Pager from '../../utils/Pager';
import { SearchContext, UserContext } from '../../utils/Context';
import Api from '../../../utils/api';
import Invalid from '../../utils/Invalid/Invalid';
import Messages from '../../../constants/Messages';
import Text from '../../../utils/text';
import SearchActions from '../../../actions/searchActions';
import Bookmark from '../../utils/Bookmark';
import History from '../../../utils/history';
import CustomerCredentialResult from './CustomerCredentialResults';

const useStyles = makeStyles({
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0',
  },
  inputCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center ',
  },
});

const CustomerCredentials = ({ params }) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);
  const classes = useStyles();
  const { category } = params;
  const { pathname: currentPath, query: currentQuery } = History.getCurrentLocation();
  const startingValues = map({ credential: '', domain: '', hash_algorithm: 'sha256', hash_response: [], page_size: 100 });
  const ccmcUrl = `/api/v4/credentials${'_self' in React.createElement('div') ? '' : ''}`;
  const hasHostDataPrm = user.get('prm').some(v => /dat.ccm.cus.hd.r/.test(v));
  const hasCookiesPrm = user.get('prm').some(v => /dat.ccm.cus.ha.r/.test(v));

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState('100');
  const [previousScrollIds, setPreviousScrollIds] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [filters, setFilters] = useState({});
  const [searchType, setSearchType] = useState('username');
  const [sha256, setSha256] = useState([]);
  const [values, setValues] = useState(startingValues);
  const [request, setRequest] = useState({});
  const [response, setResponse] = useState(map());
  const [isUploaded, setIsUploaded] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const analysisHelperText = 'Separate usernames with commas. Can input up to 1000 at a time.';
  const domainHelperText = 'Separate domains with commas. Can input up to 10 at a time.';
  const searchHelperText = 'Suggested use case: One credential per request. Can input Username:Password hash(es) or username and password combination(s) in Username:Password format.';

  const dropzoneStyles = {
    dropzone: {
      backgroundColor: '#fcfcfc',
      border: (errorText && isUploaded) ? '1px solid #d32f2f' : 0,
      margin: 0,
      overflow: 'hidden',
      minHeight: '20px',
    },
    inputLabelWithFiles: { alignSelf: 'center' },
    inputLabel: { justifyContent: 'flex-start' },
    preview: { border: 0, padding: '10px 3%' },
  };

  const ccmcType = () => {
    let type = '';
    switch (searchType) {
      case 'analysis':
        type = 'username';
        break;
      case 'domain':
        type = 'affected_domain';
        break;
      case 'credentials-search':
        type = 'username-password';
        break;
      default:
        type = 'username';
    }
    return type;
  };

  const onValidate = (file) => {
    setErrorText('');
    const { name, size } = file.meta;
    const maxsize = 10000000;
    const err = size > maxsize
      ? `${name}: file exceeds size ${maxsize} bytes`
      : '';
    if (err) {
      setErrorText(err);
      file.remove();
    }
    return false;
  };

  const columns = () => {
    switch (category) {
      case ('credentials-search'): {
        return [
          { id: 'username',
            label: 'Username',
            text: 'Username',
            render: v => Text.Highlight(v.get('username')) },
          { id: 'password',
            label: 'Password',
            text: 'Password',
            render: v => Text.Highlight(v.get('password')) },
        ];
      }
      default: {
        return [
          { id: 'breach.first_observed_at.date-time|username|password|affected_domain',
            label: 'Credential',
            labels: 'date|username|password|affected domain',
            text: 'Username/Password/Domain',
            render: (v) => {
              const pswd = v.getIn(['highlight', 'password', 0]) || v.getIn(['password']);
              const username = v.getIn(['highlight', 'username', 0]) || v.getIn(['username']);
              return Text.Highlight(`
              <b>Date</b>: ${moment.utc(v.getIn(['breach', 'first_observed_at', 'date-time'])).format('MMM D, YYYY HH:mm')}<br/>
              <b>Password</b>: ${pswd}<br/>
              <b>Username</b>: ${username}<br/>
              <b>Affected Domain</b>: ${v.get('affected_domain')}<br />`); },
          },
          { id: 'breach.created_at.date-time|breach.title|breach.context|breach.source|previously_observed_at.date-time',
            label: 'Breach Details',
            labels: 'date of breach|title|context|sourced from|previously observed at',
            text: 'Details about the breach',
            render: v => Text.Highlight(
              `<b>Date of Breach</b>: ${v.getIn(['breach', 'created_at', 'date-time'])
                ? moment.utc(v.getIn(['breach', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                : '-'}<br/>
              <b>Title</b>: ${(v.getIn(['breach', 'title']))}<br/>
              <b>Source</b>: ${(v.getIn(['breach', 'source']))}<br/>
              <b>Context</b>: ${(v.getIn(['breach', 'context'])) || '-'}<br/>
              <b>Previously Observed At</b>: ${v.getIn(['previously_observed_at', 'date-time'])
                ? moment.utc(v.getIn(['previously_observed_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                : '-'}<br/>`) },
          ...(hasHostDataPrm || hasCookiesPrm) ?
          [{ id: search.get('exportAdditionalData') ? `infected_host_attributes.fpid|infected_host_attributes.host_id|infected_host_attributes.ip|infected_host_attributes.location.city_name|infected_host_attributes.location.subdivision_1_name|infected_host_attributes.location.country_name|infected_host_attributes.malware.family|infected_host_attributes.malware.scanned_at.date-time|infected_host_attributes.machine.os|infected_host_attributes.machine.cpu|infected_host_attributes.machine.user|infected_host_attributes.isp.isp|infected_host_attributes.machine.computer_name|infected_host_attributes.machine.path|infected_host_attributes.machine.file_location|${hasCookiesPrm ? 'cookies' : ''}` : '',
            label: 'Host Data',
            labels: search.get('exportAdditionalData') ? `FPID|host id|IP address|location city|location state|location country|malware family|scan timestamp|machine operating system|machine cpu|machine local username|isp name|computer name|path|file location|${hasCookiesPrm ? 'cookies' : ''}` : '',
            text: 'Details about the host data',
              render: v => (
                hasCookiesPrm
                  ? Text.Highlight(
                      `<b>Host Attributes</b>: ${v.get('infected_host_attributes') ? 'Yes' : 'No'}<br/>
                      <b>Cookies</b>: ${v.get('cookies') ? 'Yes' : 'No'}`)
                  : Text.Highlight(
                    `<b>Cookies</b>: ${v.get('cookies') ? 'Yes' : 'No'}`)) }] : [],
          { id: 'credentials.bookmark',
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                timestamp={v.getIn(['last_observed_at', 'date-time'])}
                title={`${v.getIn(['breach', 'title'])}`}
                entity={v}
                tooltipPos="bottom" />),
          }];
      }
    }
  };

  const onChange = debounce((k, v) => {
    const trimmedValues = v.split(',').map(val => val.trim())?.filter(val => !isEmpty(val));
    setErrorText('');
    setValues(values.set(k, v));
    let error = '';
    if (k === 'analysis' || k === 'domain' || k === 'credentials-search') {
      const usrRegex = /^([a-zA-Z0-9_-]+)$/;
      // eslint-disable-next-line security/detect-unsafe-regex
      const emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
      if (!v || v === '') {
        setErrorText('');
        setSha256([]);
        return;
      }
      switch (searchType) {
        case 'affected_domain': {
          // eslint-disable-next-line security/detect-unsafe-regex
          const domainRegex = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
          const ipAddressRegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          // eslint-disable-next-line security/detect-unsafe-regex
          const validDomain = trimmedValues
            .every(value => (domainRegex.test(value) || ipAddressRegEx.test(value)));
          if (v.split(',').length > 10) error = 'Too many domains, please enter up to 10.';
          if (!validDomain) error = 'Invalid domain format';
          break;
        }
        case 'username': {
          if (!trimmedValues.every(val => (usrRegex.test(val) || emailRegex.test(val)))) error = 'Invalid credential format';
          break;
        }
        case 'username-password': {
          const pwdRegex = /^.+$/;
          const sha256Regex = /^[a-fA-F0-9]{64}$/;
          // if values are a hash, skip rest of validation. Otherwise first check for ':', then validate both sides (username:password) from input
          if (trimmedValues.every(val => sha256Regex.test(val))) break;
          if (!trimmedValues.every(val => val.includes(':'))) error = 'Invalid format, credentials must be entered as username:password';
          else if (!trimmedValues.every(val => (usrRegex.test(val.split(':')[0]) || emailRegex.test(v.split(':')[0])))) error = 'Invalid username';
          else if (!trimmedValues.every(val => pwdRegex.test(val.split(':')[1]))) error = 'Invalid password';
          break;
        }
        default: {
         break;
        }
      }
      if (['analysis', 'credentials-search'].includes(k) && v.split(',').length > 1000) {
        error = 'Too many usernames, please enter up to 1000.';
      }
      if (!error && k !== 'domain') {
        const sha256Regex = /^[a-fA-F0-9]{64}$/;
        if (trimmedValues.every(val => sha256Regex.test(val))) {
          setSha256(trimmedValues);
        } else {
          const promises = trimmedValues.map(cred => Text.SHA256(cred));
          Promise.all(promises).then(hashes => setSha256(hashes));
        }
      }
    }
  setErrorText(error);
  }, 300);

  const onCheck = (type, key, value) => {
    const current = [...values.get(type)];
    if (value) current.push(key);
    else current.splice(current.indexOf(key), 1);
    setValues(values.set(type, current));
  };

  const onFile = async (status, files, type) => {
    if (status === 'removed') {
      setSha256([]);
      setErrorText('');
      setIsUploaded(false);
      return [];
    }
    if (status !== 'done') return [];
    return Promise.all(
      files.map(({ meta, file }) =>
        new Promise(((resolve) => {
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onload = () =>
            resolve({
              type,
              name: meta.name,
              data: reader.result,
            });
        }))))
      .then((res) => {
        if (res[0].data) {
          const csvString = res[0].data.replaceAll('\n', '').split('\r').join(',');
          onChange('analysis', csvString);
          setIsUploaded(true);
        }
      });
  };

  const onSearch = (scrollId) => {
    let apiScrollId = scrollId;
    setIsLoading(true);
    if (!scrollId || (!scrollId && previousScrollIds.length === 1)) {
      setPreviousScrollIds([]);
    } else if (previousScrollIds.includes(scrollId)) {
      /* eslint-disable-next-line eqeqeq */
      apiScrollId = previousScrollIds.filter(v => v != scrollId).slice(-1).toString();
      /* eslint-disable-next-line eqeqeq */
      setPreviousScrollIds(previousScrollIds.filter(v => v != scrollId));
    } else {
      setPreviousScrollIds(previousScrollIds.length
        ? [...previousScrollIds, scrollId]
        : [scrollId]);
    }

    const url = `${ccmcUrl}/${category === 'credentials-search' ? 'search' : 'analysis'}${apiScrollId ? '/scroll' : ''}?page_size=${pageSize}&scroll=true`;
    const toSend = !apiScrollId ? request : { scroll_id: apiScrollId };
    Api.post(url, toSend, [200, 400, 500, 501, 502, 503, 504])
      .then(res => (res.ok ? res.data : null))
      .then(res => ({
        ...res,
        results: res.results?.filter(v => v.results?.length),
        total: res.results?.filter(v => v.results?.length)?.length,
        type: 'customer-credentials',
      }))
      .then(res => ({
          ...res,
          results: res.results?.map(v => ({
            ...v,
            results: v.results?.map(result => ({
                ...result,
                    basetypes: ['customer-credentials'],
                    key: v.key || '',
            })),
          })),
      }))
      .then((res) => {
        setResponse(fromJS(res), category, values.get('page_size'));
      })
      .then(() => setIsLoading(false))
      .catch((err) => {
        if (err.message !== 'canceled') {
          setResponse(map(), category);
          SearchActions.set(['search', 'info', 'message'], err.message);
        }
        setIsLoading(false);
        return err;
      });
  };

  const addFilters = (filterObj) => {
    setFilters((current) => {
      const updatedFilter = { ...current, ...filterObj };
      return updatedFilter;
    });
  };

  const removeFilter = (value) => {
    setFilters((current) => {
      const copy = { ...current };
      // eslint-disable-next-line security/detect-object-injection
      delete copy[value];
      return copy;
    });
  };

  const parseDateFilters = (date) => {
    const dateFields = ['breached_at', 'breached_since', 'since', 'date'];
    const dateFilters = Object.entries(date).filter(v => dateFields.includes(v[0]));
    if (!date.breached_since) delete filters['breach.created_at.date-time'];
    if (!date.since) delete filters['breach.created_at.date-time'];
    if (!date.date) {
      delete filters['breach.first_observed_at.date-time'];
    }
    dateFilters.map((filter) => {
      const filterName = filter[0];
      const filterKey = () => {
        let key = '';
        if (filterName === 'since' || filterName === 'date') {
          key = 'breach.first_observed_at.date-time';
        } else if (filterName === 'breached_since' || filterName === 'breached_at') {
          key = 'breach.created_at.date-time';
        }
        return key;
      };

      if (filter[0] === 'date' || filter[0] === 'breached_at') {
        const dateRange = filter[1].split(' - ');
        addFilters({
          [filterKey()]: {
            gte: moment.utc(dateRange[0]).toISOString(),
            lte: moment.utc(dateRange[1]).toISOString(),
          },
        });
        return null;
      }

      switch (filter[1]) {
        case 'now-24h':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(1, 'days').toISOString() } });
          break;
        case 'now-48h':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(2, 'days').toISOString() } });
          break;
        case 'now-7d':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(7, 'days').toISOString() } });
          break;
        case 'now-30d':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(30, 'days').toISOString() } });
          break;
        case 'now-60d':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(60, 'days').toISOString() } });
          break;
        case 'now-90d':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(90, 'days').toISOString() } });
          break;
        case 'now-120d':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(30, 'days').toISOString() } });
          break;
        case 'now-1y':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(1, 'years').toISOString() } });
          break;
        case 'now-2y':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(2, 'years').toISOString() } });
          break;
        case 'now-3y':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(3, 'years').toISOString() } });
          break;
        case 'now-1M/M':
          addFilters({ [filterKey()]: { gte: moment().utc().subtract(1, 'months').toISOString() } });
          break;
        default:
          if (filter[0] === 'since' || filter[0] === 'breached_since') break;
          removeFilter(filterKey());
          break;
      }
      return null;
    });
  };

  const updateAdditionalFilters = (q) => {
    const availableFilters = ['affected_domain', 'password', 'username', 'breach.context', 'breach.fpid', 'breach.source', 'breach.title'];
    const breachFields = ['context', 'fpid', 'source', 'title', 'title_exact'];
    const filtersToBeRemoved = Object.keys(filters)
      .filter(v => availableFilters.includes(v))
      .filter(v => !Object.keys(q).map(val => `breach.${val}`).includes(v));
    Object.entries(q).forEach((v) => {
      if (!availableFilters.find(f => f.includes(v[0]))) return;
      const filterKey = v[0];
      const filterValue = v[1];
      if (breachFields.find(b => v[0].includes(b))) {
        if (['title', 'source'].includes(filterKey) && q.title_exact !== 'true') addFilters({ [`breach.${filterKey}`]: { contains: filterValue } });
        else addFilters({ [`breach.${filterKey}`]: filterValue });
      } else {
        addFilters({ [filterKey]: filterValue });
      }
    });
    if (filtersToBeRemoved.length) filtersToBeRemoved.map(v => removeFilter(v));
  };

  useEffect(() => {
    if (category === 'domain') {
      const tmp = map()
        .set('type', searchType)
        .set('include_cookies', hasCookiesPrm || hasHostDataPrm)
        .set('include_infected_host_details', hasHostDataPrm)
        .set('hash_response', values.get('hash_response').length > 0 ? values.get('hash_response') : null)
        .set('hash_algorithm', values.get('hash_response').length > 0 ? values.get('hash_algorithm') : null)
        .set('keys', values.get(category)?.length > 0 ? values.get(category).split(',').map(v => v.trim()) : null)
        .set('type', ccmcType(category))
        .set('filters', isEmpty(filters) ? null : filters)
        .filter(v => v)
        .toJS();
      setRequest(tmp);
    } else {
      const tmp = map()
        .set('keys', sha256)
        .set('type', searchType)
        .set('include_cookies', hasCookiesPrm || hasHostDataPrm)
        .set('include_infected_host_details', hasHostDataPrm)
        .set('hash_response', values.get('hash_response').length > 0 ? values.get('hash_response') : null)
        .set('hash_algorithm', values.get('hash_response').length > 0 ? values.get('hash_algorithm') : null)
        .set('filters', (category === 'credentials-search' || isEmpty(filters)) ? null : filters)
        .filter(v => v)
        .toJS();
      setRequest(tmp);
    }
  }, [category, values, filters, sha256, searchType]);

  useEffect(() => {
    if (!Object.entries(response).length) return;

    SearchActions.set(['search', 'results', 'customer-credentials'], map({ response, request })); // need to keep initial scrollID - add map of scrolls
    SearchActions.search.completed();
  }, [response]);

  useEffect(() => {
    const { pathname } = History.getCurrentLocation();
    if (category === 'credentials-search') History.replace(pathname);
    setErrorText('');
    setResponse(map());
    setValues(startingValues);
    setSearchType(category === 'domain' ? 'domain' : 'username');
  }, [category]);

  useEffect(() => {
    if (!currentQuery.date) {
      delete currentQuery.sinceDate;
      delete currentQuery.untilDate;
      History.replace({ pathname: currentPath, query: currentQuery });
    }
    setPreviousScrollIds([]);
    parseDateFilters(currentQuery);
    updateAdditionalFilters(currentQuery);
  }, [History.getCurrentLocation().search]);

  useEffect(() => {
    setSearchInput('');
    setSha256([]);
  }, [searchType]);

  return (
    <Grid
      fluid
      name="component.credentials"
      style={{ padding: 0 }}>
      {(!category || category === 'analysis') &&
        <Col>
          <Row between="xs" className={classes.inputRow}>
            <Col className={classes.inputCol} xs={10}>
              <Row>
                <span className={cx([style.label])}>Username(s):</span>
              </Row>
              <Row>
                <FormControl>
                  <OutlinedInput
                    autoFocus
                    error={Boolean(errorText)}
                    disabled={isUploaded}
                    onChange={event => onChange(category || 'analysis', event.target.value)}
                    placeholder="hello@user.com, username2, username3"
                    value={isUploaded ? 'File has been been uploaded' : undefined}
                    variant="outlined" />
                  <FormHelperText style={{ margin: 0 }}>{analysisHelperText}</FormHelperText>
                  <FormHelperText style={{ color: '#d32f2f', marginLeft: 0 }}>{errorText}</FormHelperText>
                </FormControl>
              </Row>
            </Col>
            <Col style={{ alignItems: 'center', display: 'flex' }} xs={2}>
              <Dropzone
                maxFiles={1}
                accept=".csv"
                validate={file => onValidate(file)}
                onChangeStatus={(file, status, files) => onFile(status, files, '.csv')}
                inputContent={<Tooltip title="Upload Usernames"><Icon>cloud_upload</Icon></Tooltip>}
                inputWithFilesContent={<Tooltip title="Upload Usernames"><Icon>cloud_upload</Icon></Tooltip>}
                styles={dropzoneStyles}
              />
            </Col>
          </Row>
        </Col>}
      {(category === 'domain') &&
        <Col>
          <Row start="xs" style={{ display: 'flex', flexDirection: 'column', padding: '5px 0' }}>
            <Col style={{ padding: 0 }} xs={2}>
              <span className={cx([style.label])}>Affected Domain(s):</span>
            </Col>
            <Col>
              <FormControl>
                <OutlinedInput
                  autoFocus
                  error={Boolean(errorText)}
                  onChange={event => onChange(category, event.target.value)}
                  placeholder={category === 'domain' ? 'domain1, domain2, domain3' : 'hello@user.com, username2, username3'}
                  variant="outlined" />
                <FormHelperText style={{ margin: 0 }}>{domainHelperText}</FormHelperText>
                <FormHelperText style={{ color: 'red', marginLeft: 0 }}>{errorText}</FormHelperText>
              </FormControl>
            </Col>
          </Row>
        </Col>}
      {category === 'credentials-search' &&
        <Col>
          <Row start="xs" className={classes.inputRow}>
            <Col style={{ padding: 0 }} xs={2}>
              <span className={cx([style.label])}>I want to search for:</span>
            </Col>
            <Col xs={10}>
              <FormControl>
                <RadioGroup
                  defaultValue="Username"
                  row
                  name="type"
                  className={style.radio}
                  value={searchType}
                  onChange={(event) => {
                    setSearchType(event.target.value);
                  }}>
                  <FormControlLabel
                    label="Username"
                    placeholder="username"
                    value="username"
                    control={<Radio sx={{ '&.Mui-checked': { color: '#5c6ae0 ' } }} />} />
                  <FormControlLabel
                    label="Username:Password"
                    placeholder="username-password"
                    value="username-password"
                    control={<Radio sx={{ '&.Mui-checked': { color: '#5c6ae0 ' } }} />} />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
          <Row between="xs" className={classes.inputRow}>
            <Col className={classes.inputCol} xs={10}>
              <Row>
                <span className={cx([style.label])}>{searchType === 'username' ? 'Username(s):' : 'Username:Password:' }</span>
              </Row>
              <Row>
                <FormControl>
                  <OutlinedInput
                    autoFocus
                    disabled={isUploaded}
                    error={Boolean(errorText)}
                    onChange={(event) => {
                      setSearchInput(event.target.value);
                      onChange(category, event.target.value);
                    }}
                    placeholder={searchType === 'username' ? 'example@example.com' : 'example@example.com:123456'}
                    variant="outlined"
                    value={isUploaded ? 'File has been been uploaded' : searchInput}/>
                  <FormHelperText style={{ margin: 0 }}>{searchHelperText}</FormHelperText>
                  <FormHelperText style={{ color: 'red', marginLeft: 0 }}>{errorText}</FormHelperText>
                </FormControl>
              </Row>
            </Col>
            <Col style={{ alignItems: 'center', display: 'flex' }} xs={2}>
              <Dropzone
                maxFiles={1}
                accept=".csv"
                validate={file => onValidate(file)}
                onChangeStatus={(file, status, files) => onFile(status, files, '.csv')}
                inputContent={<Tooltip title="Upload Usernames"><Icon>cloud_upload</Icon></Tooltip>}
                inputWithFilesContent={<Tooltip title="Upload Usernames"><Icon>cloud_upload</Icon></Tooltip>}
                styles={dropzoneStyles}
              />
            </Col>
          </Row>
        </Col>
        }
      <Col>
        <Row start="xs" style={{ alignItems: 'center', padding: '10px 0' }}>
          <Col style={{ padding: 0 }} xs={2}>
            <span className={cx([style.label])}>Hashed Response:</span>
          </Col>
          <Col xs={10}>
            <FormControl>
              <FormGroup row>
                <FormControlLabel
                  label="Username"
                  control={<Checkbox
                    checked={values.get('hash_response').includes('username')}
                    onChange={event => onCheck('hash_response', 'username', event.target.checked)} />}
                />
                <FormControlLabel
                  label="Password"
                  control={<Checkbox
                    checked={values.get('hash_response').includes('password')}
                    onChange={event => onCheck('hash_response', 'password', event.target.checked)} />}
                />
              </FormGroup>
            </FormControl>
          </Col>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="caption">To receive username or password back as a hash.</Typography>
        </Row>
        <Row start="xs" style={{ alignItems: 'center', padding: '10px 0' }}>
          <Col style={{ padding: 0 }} xs={2}>
            <span className={cx([style.label])}>Hashed Response Algorithm:</span>
          </Col>
          <Col xs={10}>
            <FormControl>
              <RadioGroup
                color="primary"
                row
                name="hash-algorithm"
                value={values.get('hash_algorithm') || 'sha256'}
                onChange={event => onChange('hash_algorithm', event.target.value)}>
                <FormControlLabel
                  label="SHA-1"
                  value="sha1"
                  control={<Radio sx={{ '&.Mui-checked': { color: '#5c6ae0 ' } }} />} />
                <FormControlLabel
                  label="SHA-256"
                  value="sha256"
                  control={<Radio sx={{ '&.Mui-checked': { color: '#5c6ae0 ' } }} />} />
                <FormControlLabel
                  label="MD-5"
                  value="md5"
                  control={<Radio sx={{ '&.Mui-checked': { color: '#5c6ae0 ' } }} />} />
              </RadioGroup>
            </FormControl>
          </Col>
          <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="caption">Choose one for password and username (if selected).</Typography>
        </Row>
        <Row>
          <Col>
            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>Results Per Credential:
              <Select value={pageSize} placeholder="Page Size" onChange={event => setPageSize(event.target.value)}>
                <ListItem key="25" value="25">25</ListItem>
                <ListItem key="50" value="50">50</ListItem>
                <ListItem key="75" value="75">75</ListItem>
                <ListItem key="100" value="100">100</ListItem>
              </Select>
              <Tooltip title="100 results is the max per credential per page.">
                <Icon style={{ paddingLeft: '5px' }}>help_outline</Icon>
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row between="xs">
          <Col>
            <Button
              disabled={isLoading || Boolean(errorText)}
              size="medium"
              color="secondary"
              style={{ margin: '20px 0' }}
              variant="contained"
              onClick={() => {
                if (request?.keys?.length) onSearch();
                else setErrorText('Field must not be blank');
              }}
              name="credentials_analysis_search">Submit
            </Button>
          </Col>
          <Col>
            {!!previousScrollIds.length &&
              <Button
                disabled={isLoading}
                size="medium"
                color="secondary"
                style={{ margin: '20px 5px' }}
                variant="contained"
                onClick={() => {
                  if (previousScrollIds.length === 1) onSearch();
                  else onSearch(previousScrollIds.slice(-1).toString());
                }}
                title="Go back to previous set of results for credentials"
                name="credentials_analysis_search_back">
                Back
              </Button>}
            {response.get('scroll_id') &&
              <Button
                disabled={isLoading}
                size="medium"
                color="secondary"
                style={{ margin: '20px 5px' }}
                variant="contained"
                onClick={() => onSearch(response.get('scroll_id'))}
                title="Load next set of results for credentials"
                name="credentials_analysis_search_forward">
                Load Next
              </Button>}
          </Col>
        </Row>
        {isLoading &&
          <Row center="xs"><CircularProgress /></Row> }
        {response.get('total') === 0 && !isLoading &&
          <Row><Invalid title={Messages.SearchEmpty} help={[]} /></Row> }
        {response.get('total') > 0 && !isLoading &&
          <Row between="xs" style={{ marginBottom: '10px', padding: '0 5px' }}>
            <Col xs={8}>
              <span className={cx([style.label])}>
                Displaying {response.get('num_results')} result(s) found from {response.get('total')} credential match(es).
              </span>
              <Pager
                data={fromJS(response)}
                exporting
                fields={columns()}
                filters={fromJS({ sort: currentQuery.sort })}
                prm={user.get('prm')}
                save
                saved={user.getIn(['prefs', 'search'])}
                sort
                sorts={search.getIn(['sorts', 'customer-credentials'])}
                share
                type="customer-credentials"/>
            </Col>
            <Col>
              {response.get('scroll_id') &&
              <span className={cx([style.label])}>
                Click Load Next to retrieve the next set of credential results.
              </span>}
            </Col>
          </Row> }
        {response.get('total') > 0 && !isLoading &&
          response.get('results')
            .filter(v => v.get('results').size)
            .map((v, k) => (
              <CustomerCredentialResult
                category={category}
                columns={columns}
                request={request}
                response={response}
                result={v}
                sort={currentQuery.sort}
                index={k}/>
            ))
        }
      </Col>
    </Grid>
  );
};

CustomerCredentials.propTypes = {
  params: PropTypes.object,
};

CustomerCredentials.defaultProps = {
  params: {},
};

export default CustomerCredentials;
