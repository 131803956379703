import * as React from 'react';
import { useState, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import ReactTooltip from 'react-tooltip';

import RecentSearchesTableRow from './RecentSearchesTableRow';
import UserActions from '../../../../../actions/userActions';
import { RecentSearch } from '../../../../../core/types/RecentSearch';
import { BaseSearch } from '../../../../../core/types/BaseSearch';
import {
  Table,
  TableBody,
  TableContainer,
} from '../../../../../shared/data';
import { UserPreferences } from '../../../../../core/types/UserPreferences';
import { DefaultThemeType } from '../../../../../theme/light/defaultTheme';

const MaxRows = 15;

const useStyles = makeStyles((theme:DefaultThemeType) => ({
  empty: {
    color: '#a4a4a5',
    margin: '2rem 0 1rem 0',
  },
  emptyRow: {
    color: '#a4a4a5;',
    margin: '2rem 0 1rem 0',
    height: '32px',
  },
  tableContainerContainer: {
    width: '100%',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  table: {
    '& .MuiTableCell-head': {
      color: '#000',
      fontWeight: 'bold',
      textTransform: 'none',
      position: 'sticky',
      top: 0,
      backgroundColor: `${theme.palette.background.paper}`,
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f7f7f8',
    },
    '& tr:hover': {
      backgroundColor: '#f1f1f2',
    },
    '& tr.MuiTableRow-head:hover': {
      backgroundColor: 'initial',
    },
  },
  headerRow: {
    borderBottom: '1px #e5e5e6 solid',
  },
}));
interface RecentSearchesWidgetProps {
  /** User preferences object */
  data: UserPreferences,
}

/** These limitedFilters reflect the user's view when the search  was originally executed */
export const limitedFilters = (myFiltersString:string) =>
  myFiltersString.split('&').filter(
    (filterStr: string) => !filterStr.match(/^(since|until|date)=.+/),
  ).join('&');

/** uniquely identify recent search items */
const key = (v: BaseSearch) => `
${'title' in v ? v.title : v.name}
${v.value}
${
  limitedFilters(v.filters)
  .slice(1)
  .split('&')
  .sort()
  .join('&')
}`;

/** dedupe list of searches based on unique key */
export const dedupe = (arr: BaseSearch[]):BaseSearch[] => {
  const dedupeTable: Map<string, BaseSearch> = new Map();
  arr.forEach(v => dedupeTable.set(key(v), v));
  return Array
    .from(dedupeTable.values())
    .sort((a, b) => (b.created - a.created));
};
const preprocessRows = (rawRows: BaseSearch[]):BaseSearch[] => (
  dedupe(rawRows).slice(0, MaxRows)
);

/** Displays the ten most recent searches for a user */
export const RecentSearchesWidget = ({
  data,
}: RecentSearchesWidgetProps): JSX.Element => {
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  /** Remove selected search item from users history */
  const onDelete = (e: React.MouseEvent, search: RecentSearch) => {
    e.stopPropagation();
    const history: RecentSearch[] = rows
      ?.filter(v => key(v) !== key(search));
    const updatedPrefs = {
      ...data,
      history: {
        ...data?.history,
        search: history,
      },
    };
    setRows(preprocessRows(history));
    UserActions.savePrefs(updatedPrefs);
  };

  useEffect(() => {
    if (!data) return;
    const history = data?.history?.search;
    setRows(preprocessRows(history));
  }, [data]);

  if (!rows || rows.length < 1) {
    return <div className={classes.empty}>No recent searches to display</div>;
  }

  return (
    <div className={classes.tableContainerContainer} onMouseEnter={() => ReactTooltip.rebuild()}>
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {
              rows.map(v => (
                <RecentSearchesTableRow
                  key={v.created}
                  data={v}
                  onDelete={onDelete} />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

