import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';

import { List as list, Map as map } from 'immutable';

import Text from '../../utils/text';

const useStyles = makeStyles(theme => ({
  'nested-checkbox': {
    '& .MuiListItem-dense': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  nested: {
    padding: `0 0 0 ${theme.spacing(4)}`,
  },
}));

const NestedCheckbox = ({
  disabled,
  options,
  parent,
  values,
  onChange,
}) => {
  const classes = useStyles();
  const [checkboxState, setCheckboxState] = useState(map());
  const [isOpen, setIsOpen] = useState(parent.get('open') || false);

  const generateArrow = () => (isOpen
    ? (<ExpandLess onClick={() => setIsOpen(false)} />)
    : (<ExpandMore onClick={() => setIsOpen(true)} />));

  useEffect(() => {
    let ids = list();
    if (typeof parent.get('id') === 'string' && options.isEmpty()) {
      ids = list([parent.get('id')]);
    } else if (typeof parent.get('id') === 'string') {
      ids = options.map(v => v.get('id'));
    } else {
      ids = parent.get('id');
    }
    let currentState = checkboxState;

    ids?.forEach((v) => {
      currentState = currentState.set(v, values.includes(v));
    });
    currentState = currentState.set(parent.get('id'), ids?.every(v => currentState.get(v)));
    setCheckboxState(currentState);
  }, [values]);

  function onCheck(id, checked, isParent = false) {
    let newState = checkboxState;
    if (isParent) {
      newState = (checked)
        ? newState.map(() => true)
        : newState.map(() => false);
    } else if (checked) {
      newState = newState.set(id, true);
      if (newState.filterNot((v, k) => !v || k === parent.get('id')).size === options.count()) newState = newState.set(parent.get('id'), true);
    } else {
      newState = newState.set(id, false);
      newState = newState.set(parent.get('id'), false);
    }
    setCheckboxState(newState);
    onChange(newState);
  }

  return (
    <FormControl className={classes['nested-checkbox']} fullWidth={false}>
      <ListItem
        key={parent.get('id')}
        button={false}
        disableGutters>
        <FormControlLabel
          label={parent.has('children')
            ? `${Text.Sentence(parent.get('name'))} (${checkboxState.filterNot((v, k) => !v || k === parent.get('id')).size})`
            : `${Text.Sentence(parent.get('name'))}`}
          control={<Checkbox
            checked={checkboxState.get(parent.get('id'), false)}
            disabled={disabled}
            onChange={(event, togg) => onCheck(parent.get('id'), togg, true)}
            indeterminate={checkboxState.some(v => v) && !checkboxState.get(parent.get('id'), false)} />} />
        {parent.has('children') && !parent.get('hideToggle')
          ? generateArrow()
          : null}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options
            .toJS()
            .map(c => (
              <ListItem
                key={c.id}
                button={false}
                className={classes.nested}>
                <FormControlLabel
                  label={Text.Sentence(c.name)}
                  control={<Checkbox
                    checked={checkboxState.get(c.id, false)}
                    disabled={disabled}
                    onChange={(event, togg) => onCheck(c.id, togg)}
                    data-testid={`nestedcheckbox.${c?.name}`} />} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </FormControl>);
};

NestedCheckbox.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.object,
  parent: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
};

NestedCheckbox.defaultProps = {
  disabled: false,
  options: list(),
  parent: map(),
  values: list(),
  onChange: null,
};

export default NestedCheckbox;
