import { cachedPost } from '../../../utils/api';

const AlertingUrl = '/ui/v4/alerts';
const Status = [200, 201, 204, 500, 501, 502, 503, 504];

const saveAlertingKeyword = (payload, ownerId) => cachedPost(`${AlertingUrl}/keywords.bulk?owner_id=${ownerId}`, [payload], Status)
    .then(res => (res.ok ? res : ({})));

const subscription = payload => cachedPost(`${AlertingUrl}/keyword_subscriptions`, [payload], Status)
    .then(res => (res.ok ? res.data : []));


export default { saveAlertingKeyword, subscription };
