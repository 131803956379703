import Api from '../../../utils/api';

const KEYWORD_CLASS_URI = '/ui/v4/alerts/keyword_classes';

const cache = {};

const KeywordClasses = async () =>
  (cache?.classes
    ? cache?.classes
    : Api.get(KEYWORD_CLASS_URI)
      .then(res => res.data)
      .then(res => Object.assign(cache, { ...cache, classes: res }))
      .then(() => cache?.classes));

export default {
  KeywordClasses,
};
