import { Breakpoints } from './Breakpoints';

export const Grid = {
  container: `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: .5rem;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      grid-template-columns: repeat(8, 1fr);
      column-gap: 1rem;
    }

    @media only screen and (min-width: ${Breakpoints.md}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `,
};
