import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useOktaAuth } from '@okta/okta-react';
import style from './tokens.module.scss';
import Query from './query';
import Invalid from '../../utils/Invalid/Invalid';
import Prompt from '../../utils/Prompt';
import Messages from '../../../constants/Messages';
import UserActions from '../../../actions/userActions';
import SearchActions from '../../../actions/searchActions';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .MuiFormControlLabel-label': {
      textTransform: 'none',
    },
    '& .Mui-disabled': {
      color: 'inherit !important',
    },
  },
  ccmCheckbox: {
    alignItems: 'flex-start',
  },
  ccmText: {
    margin: '1.6rem 0 1.6rem 2.1rem',
  },
}));

const Tokens = ({
  apps,
  message,
  prm,
  tokens,
  user,
}) => {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const [status, setStatus] = useState();
  const [token, setToken] = useState();
  const [dialog, setDialog] = useState();
  const [values, setValues] = useState({ username: user.get('usn') });
  const [ccmAgree, setCCMAgree] = useState();

  const { payload: { idp = '' } } = oktaAuth.token.decode(oktaAuth.getIdToken());
  const FlashpointIdp = '00o2sl9rcCkwazW3K5d6';
  const isFpLogin = Boolean(idp === FlashpointIdp);

  const onAgree = () => {
    UserActions.savePrefs({ ccm_c: moment.utc().unix() });
    setDialog('api');
  };

  const onCopy = () => {
    navigator.clipboard.writeText(`${token || ''}`);
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  };

  const onGenerateToken = async () => {
    if (!values.push) {
      setStatus();
    }
    const _values = {
      ...values,
      id_token: await oktaAuth.getIdToken(),
    };
    Query.GenerateToken(_values)
      .then((res) => {
        if (res.status === 202) {
          /* Push MFA 1. Decode the token and check for push MFA */
          const value = res?.data?.token.split('|')[0];
          const decoded = JSON.parse(atob(value));
          if (decoded?.type === 'push') {
            /*
             * Push MFA 2. If it is push MFA, hide the OTP field and enter a fake
             * value for the OTP
             */
            setStatus('MFA enabled. Push sent.');
            setValues(current => ({
              ...current,
              totp: res.data.token,
              push: true,
              otp: 1234567890 }));
          } else {
            setStatus('MFA enabled. Please submit OTP token');
            setValues(current => ({ ...current, totp: res.data.token }));
          }
        }
        else {
          UserActions.set(['user', 'tokens'], tokens.push(_values.token_name));
          setToken(res.data.bearer);
          setValues({ ..._values, push: false });
        }
      })
      .catch((err) => {
        setValues({
          ..._values,
          token: undefined,
          push: false,
          password: undefined,
          totp: undefined,
          otp: undefined,
        });
        if (/timeout/ig.test(err.message)) {
          setDialog('');
          SearchActions.set(['search', 'info', 'message'], Messages.MfaTimeout);
          return;
        }
        setStatus(err?.response?.data?.detail || err?.response?.data?.errorSummary);
      });
  };

  const onRevokeToken = (token_name) => {
    Query.RevokeToken({ token_name })
      .then(() => {
        UserActions.set(['user', 'tokens'], tokens.filter(v => v !== token_name));
        SearchActions.set(['search', 'info', 'message'], Messages.TokenRevoked);
      })
      .catch((err) => {
        setStatus(err.response.data.detail);
      })
      .finally(() => setDialog());
  };

  const columns = [
    { id: 'label', label: 'Name',
      text: 'Name of generated token',
      style: { width: '175px' },
      tooltip: v => Text.StripHtml(v)
        .match(/(.{1,65})(?:\n|$| )/g) || []
        .join('<br />'),
      render: v => v },
    { id: 'token.revoke', label: 'Revoke Token',
      revoke: true,
      text: 'Revoke API token',
      style: { textAlign: 'right', width: '45px' },
      render: (v, handleClick) => (
        <Icon onClick={() => handleClick(v)}>clear</Icon>) },
  ];

  const reset = () => {
    setValues({
      ...values,
      token_name: undefined,
      token: undefined,
      push: false,
      password: undefined,
      totp: undefined,
      otp: undefined,
    });
    setToken();
    setStatus();
  };

  useEffect(() => {
    if (message && !/troubleshooting/gi.test(message)) setDialog('');
  }, [message]);

  /* Push MFA 3. If a Push MFA is in progress, send the data to the API */
  useEffect(() => {
    if (values.push) {
      onGenerateToken();
    }
  }, [values]);


  return (
    <Grid
      fluid
      name="component.tokens"
      className={cx([
        style.base,
        style.tokens,
        classes.tokens,
      ])}>
      {/* api-token */}
      {prm.some(p => /api\.prv/.test(p)) &&
      prm.some(p => /acc\.api/.test(p)) &&
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.body}>
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Generate API Token</div>
              <div>Generate an API token for the following API endpoints:</div>
              <div className={style.api}>
                {apps
                  .filter(v => v.get('api'))
                  .map(v => (
                    <div key={v.get('value')}>
                      {prm.some(p => v.get('test').test(p)) &&
                        <Icon>
                          check
                        </Icon>}
                      {!prm.some(p => v.get('test').test(p)) &&
                        <Icon
                          data-for="api.tooltip"
                          data-tip="For access to this dataset, please reach out to the customer success team via your team's dedicated support address.">
                          help
                        </Icon>}
                      {v.get('label')}
                    </div>))}
              </div>
              <div className={style.input}>
                {oktaAuth.isAuthenticated() ?
                  <Button
                    color="secondary"
                    variant="contained"
                    className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
                    onClick={() => {
                      reset();
                      setDialog({ key: !prm.some(p => /ccm.cus/.test(p))
                        || user.getIn(['prefs', 'ccm_c'])
                        || prm.some(p => /acc.api.only/.test(p)) // api-only
                      ? 'api'
                      : 'ccm' });
                    }}>
                    Generate Token
                  </Button> :
                  <Button
                    color="secondary"
                    variant="contained"
                    className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
                    disabled
                  >
                    Token Generation Disabled
                  </Button>}
                <Dialog
                  className={classes.dialog}
                  open={Boolean(dialog && dialog.key === 'ccm')}
                  onClose={() => setDialog()}>
                  <DialogTitle>CCM-C API Agreement</DialogTitle>
                  <DialogContent>
                    <div>
                      <FormControlLabel
                        className={classes.ccmCheckbox}
                        label="By clicking here, You represent that you are authorized
                        by your company to retrieve the data in scope, and will
                        not use the CCM-C Service or any data beyond their intended
                        scope, nor in violation of applicable law or regulation.
                        You may only access the CCM-C Service for the evaluation
                        period provided by Flashpoint, and Flashpoint may terminate
                        your access at any time."
                        control={<Checkbox
                          checked={ccmAgree}
                          onChange={(_, v) => setCCMAgree(v)} />} />
                    </div>
                    <div className={classes.ccmText}>
                      By clicking agree, you accept the terms and conditions{' '}
                      <Link
                        target="_blank"
                        to="/home/resources/agreements/ccmc"
                        className={cx([style.a, 'a', style.info])}>
                        here
                      </Link>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setDialog('')}>
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      disabled={!ccmAgree}
                      onClick={() => onAgree()}>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  className={classes.dialog}
                  open={Boolean(dialog && dialog.key === 'api' && !token)}
                  onClose={() => setDialog()}>
                  <DialogTitle>Generate API Token</DialogTitle>
                  <DialogContent>
                    <FormControl variant="outlined">
                      <InputLabel>Token Label</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="Token Label"
                        value={values.token_name || ''}
                        onChange={(event) => {
                          const token_name = event.target.value;
                          setValues(vals => ({ ...vals, token_name }));
                        }} />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel>FP.Tools Username</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="FP.Tools Username"
                        id="username"
                        value={user.get('usn')}
                        disabled />
                    </FormControl>
                    {isFpLogin &&
                      <FormControl variant="outlined">
                        <InputLabel>FP.Tools Password</InputLabel>
                        <OutlinedInput
                          data-lpignore="true"
                          placeholder="FP.Tools Password"
                          id="password"
                          type="password"
                          value={values.password || ''}
                          onChange={(event) => {
                            const password = event.target.value;
                            setValues(vals => ({ ...vals, password }));
                          }} />
                      </FormControl>
                    }
                    {values?.totp && !values?.push && isFpLogin &&
                      <FormControl variant="outlined">
                        <InputLabel>OTP Token</InputLabel>
                        <OutlinedInput
                          data-lpignore="true"
                          placeholder="OTP Token"
                          value={values.otp || ''}
                          onChange={(event) => {
                            const otp = event.target.value;
                            setValues(vals => ({ ...vals, otp }));
                          }} />
                      </FormControl>
                    }
                  </DialogContent>
                  <DialogActions>
                    <div>{status}</div>
                    <Button
                      onClick={() => {
                        setValues({ username: user.get('usn') });
                        setStatus('');
                        setDialog('');
                      }}>
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => onGenerateToken()}>
                      Generate
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {token &&
                <div>
                  <div>Warning - Save your key now! After leaving this page you
                    will no longer be able to retrieve this key.
                  </div>
                  <div className={style.token}>{token}</div>
                  <Button
                    className={cx([style.a, 'a'])}
                    onClick={() => onCopy()}>
                    Copy to clipboard
                  </Button>
                </div>}
            </div>
          </Paper>
        </Col>
      </Row>}
      {/* existing-api-tokens */}
      {prm.some(p => /api\.prv/.test(p)) &&
      prm.some(p => /acc\.api/.test(p)) &&
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.body}>
              {tokens.size > 0 &&
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Existing API Tokens</div>}
              {tokens.size === 0 &&
                <Invalid
                  icon=""
                  inline
                  title="No API tokens have been generated"
                  help={['Click "Generate Token" above']} />}
              {tokens.size > 0 &&
              <TableContainer>
                <Table size="small" className={style.apitoken}>
                  <TableHead>
                    <TableRow>
                      {columns.map(col => (
                        <TableCell
                          key={col.id}
                          className={col.className}
                          style={col.style}>
                          {col.text &&
                            <div
                              data-for="header.tooltip"
                              data-tip={col.text}
                              className={cx([col.sort ? style.sort : null])}
                              name={`table.header.${col.label}`}>{col.label}
                            </div>}
                          {!col.text && <div>{col.label}</div>}
                        </TableCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tokens.map(row => (
                      <TableRow
                        name={`table.item.${row}`}
                        key={row}
                        selected={false}>
                        {columns.map(col => (
                          <TableCell
                            key={col.id}
                            align={col.align}
                            name={`table.item.${row}.${col.id}`}
                            style={col.style}>
                            {col.render(row, (col.revoke ? () => setDialog({ key: 'revoke', target: row }) : null))}
                          </TableCell>))}
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>}
            </div>
          </Paper>
          <Prompt
            open={Boolean(dialog?.key === 'revoke')}
            title="Warning: Delete Token?"
            acceptText="Continue"
            accept={() => onRevokeToken(dialog?.target)}
            cancelText="Cancel"
            cancel={() => setDialog()}>
            You are about to permanently delete this token. Are you sure?
          </Prompt>
        </Col>
      </Row>}
      <ReactTooltip id="api.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="header.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Tokens.propTypes = {
  apps: PropTypes.object,
  message: PropTypes.string,
  prm: PropTypes.object,
  tokens: PropTypes.object,
  user: PropTypes.object,
};

Tokens.defaultProps = {
  apps: list(),
  message: '',
  prm: list(),
  tokens: list(),
  user: map(),
};

export default Tokens;
