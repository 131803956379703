import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './iocs.module.scss';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import InternalLink from '../utils/InternalLink';
import MISP from '../../constants/MISP';
import Api from '../../utils/api';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  report_tag_link: {
    display: 'inline-block',
  },
}));

const IOCs = ({
  data,
}) => {
  const classes = useStyles();
  const [malwares, setMalwares] = useState();
  const [configs, setConfigs] = useState();

  const onLoadConfigs = async (uuid) => {
    const id = Text.StripHighlight(uuid);
    const url = '/ui/v4/all/search';
    const query = {
      q: `basetypes:(indicator_attribute) AND Event.uuid:${id} AND Event.Tag.name:"extracted_config:true" AND type:text`,
      source: true,
    };
    const config = await Api.post(url, query)
      .then(res => res.data)
      .then(res => res.map(v => get(v, ['value', 'text'], '')))
      .then(res => res.map((v) => {
        try {
          return JSON.stringify(JSON.parse(v), null, 2);
        } catch (err) {
          return '';
        }
      }))
      .then(res => res.filter(v => v));
    setConfigs(config);
  };

  const onLinkSearch = (k, v) => {
    const report = [...v.matchAll(/report:(.*?)$/ig)][0];
    return report
      ? { pathname: `/home/intelligence/reports/report/${report[1]}` }
      : { pathname: '/home/search/iocs', query: { query: '', [k]: v } };
  };

  const onLoadMalwares = async () => {
    const url = '/ui/v4/documents';
    const tagging = data.getIn(['Event', 'Tag'], list()).map(v => v.get('name'));
    Api.get(`${url}/malware/wiki`)
      .then(res => (res.ok ? res.data : null))
      .then(res => res.data.filter(v => tagging.some(tag => v.misp_tags.includes(tag)
        // eslint-disable-next-line security/detect-non-literal-regexp
        || (v?.malware_family_name ? new RegExp(v.malware_family_name, 'ig').test(tag) : false))))
      .then((res) => {
        const title = res?.[0]?.malware_family_name;
        SearchActions.set(['search', 'result', 'iocs', 'Event', 'title'], title);
        return res;
      })
      .then(res => setMalwares(res));
  };

  useEffect(() => {
    if (!data.get('fpid')) return;
    const fpid = Text.StripHighlight(data.get('fpid'));
    onLoadConfigs(fpid);
    onLoadMalwares();
  }, [data.get('fpid')]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [malwares]);

  return (
    <Grid
      fluid
      name="component.iocs"
      className={cx([
        style.base,
        style.iocs,
        classes.iocs,
      ])}>
      {!data.has('fpid') &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {data.has('fpid') &&
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.body}>
              <div className={style.table}>
                <div>
                  {malwares?.map(malware => (
                    <div
                      key={malware?.fpid}
                      className={classes.malware_button}>
                      <Button
                        key={malware.fpid}
                        data-for="global.tooltip"
                        data-tip="Go to Malware Wiki Page"
                        color="secondary"
                        variant="contained"
                        endIcon={<Icon>launch</Icon>}
                        onClick={() => History.navigateTo(`/home/wiki/malware/${malware.fpid}`)}>
                        {malware.malware_family_name || ''}
                      </Button>
                    </div>))}
                </div>
              </div>
              <div className={style.table}>
                <div className={cx([style.h2, 'h2', style.mont, 'mont', 'title'])}>Name:</div>
                <div>
                  <InternalLink
                    className={cx([style.a, 'a'])}
                    to={onLinkSearch('query', `"${data.getIn(['Event', 'info'])}"`)}>
                    {Text.Highlight(
                      data.getIn(['highlight', 'Event.info', 0]) ||
                        data.getIn(['Event', 'info']))}
                  </InternalLink>
                </div>
              </div>
              <div className={style.table}>
                <div className={cx([style.h2, 'h2', style.mont, 'mont', 'title'])}>Created:</div>
                <div>{moment.utc(data.getIn(['Event', 'date'])).format('MMM D, YYYY')}</div>
              </div>
              {data.getIn(['Event', 'attack_ids'])?.length &&
              <div>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>ATT&CK:</div>
                {data.getIn(['Event', 'attack_ids']).map(id => (
                  <InternalLink
                    key={id}
                    className={cx([style.a, 'a'])}
                    style={{ padding: '0 5px 0 0' }}
                    to={onLinkSearch('attack_ids', id)}>
                    {(MISP.find(v => v.id === id) || {}).name}
                  </InternalLink>))}
              </div>}
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>
                Tags
              </div>
              <div className={style.description}>
                {data.getIn(['Event', 'Tag'], list())
                  .entrySeq()
                  .filter(([, t]) => /^(malware|tool|actor|report)/ig.test(t.get('name')))
                  .map(([k, t]) => (
                    <InternalLink
                      key={k}
                      data-for="global.tooltip"
                      data-tip={/report:/ig.test(t.get('name')) ? 'Go To Intel Report' : ''}
                      className={cx([style.flex, style.a, 'a'])}
                      to={onLinkSearch('ioc_tags', t.get('name'))}>
                      <div className={cx([style.icon, style.color])} style={{ backgroundColor: t.get('colour') }} />
                      <div>
                        {Text.Highlight(
                            t.get('name') === Text.Strip(data.getIn(['highlight', 'Event.Tag.name', 0]))
                              ? (data.getIn(['highlight', 'Event.Tag.name', 0]) || '').replace(/misp.*?="(.*?)"/ig, 'ATT&CK:$1')
                              : (t.get('name') || '').replace(/misp.*?="(.*?)"/ig, 'ATT&CK:$1'))}
                      </div>
                      {/report:/ig.test(t.get('name')) &&
                      <div className={cx([style.icon])}>
                        <Icon>lightbulb_outline</Icon>
                      </div>}
                    </InternalLink>))}
              </div>
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Configuration</div>
              <div className={style.configs}>
                {!configs && <CircularProgress />}
                {configs &&
                <pre>
                  {!configs.length
                    ? 'No extracted configurations'
                    : configs.map(config => config)}
                </pre>}
              </div>
            </div>
          </Paper>
        </Col>
      </Row>}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
    </Grid>
  );
};

IOCs.propTypes = {
  data: PropTypes.object,
};

IOCs.defaultProps = {
  data: map(),
};

export default IOCs;
