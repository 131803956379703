import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { List as list, Map as map } from 'immutable';
import {
  Icon,
  Button,
  Popover,
} from '@mui/material';

import SearchFields from '../../constants/SearchFields';
import style from './searchTypeSelection.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import SearchTypesMenu from '../utils/SearchTypesMenu';

const SearchTypeSelection = ({
  apps,
  parentTypes,
  route,
  setParentTypes,
  prm,
  onSearch,
}) => {
  const [dialog, setDialog] = useState({});
  const [searchLabel, setSearchLabel] = useState('');
  const [searchTypes, setSearchTypes] = useState(parentTypes || '');

  const searchTypeHeaders = (
    apps
      .filter(v => v.get('search'))
      .filter(v => !v.get('all'))
      .filter(v => !v.get('exclude') || !prm.some(p => v.get('exclude').test(p)))
      .filter(v => (!v.get('internal') || prm.some(p => /org.fp.r/.test(p))))
      .filter(v => prm.some(p => v.get('test').test(p)))
      .filter(v => v.get('value') !== 'search.all')
      .map(v => ((v.has('subHeader')) ? v.get('subHeader').toLowerCase() : null))
      .filter(v => v)
      .toSet()
      .toList()
  );

  const appSearchLabel = (app) => {
    if (!app) return 'All Communities';
    return app.get('subHeader')
      ? `${app.get('subHeader')} > ${Text.Sentence(app.get('searchBar') || app.get('label'))}`
      : Text.Sentence(app.get('searchBar') || app.get('label'));
  };

  const onSetSearchLabel = () => {
    let currentLabel = 'Custom';
    const types = (searchTypes || '').split(',')
      ?.filter(v => !searchTypeHeaders.includes(v))
      ?.filter(v => !v.includes('customer-credentials'))
      ?.filter(v => v);
    const assets = SearchFields.TypeGroups.allAssets.split(',');
    const media = SearchFields.TypeGroups.allMedia.split(',');
    const communities = SearchFields.TypeGroups.allCommunities.split(',');
    const shops = SearchFields.TypeGroups.allShops.split(',');
    const technical = SearchFields.TypeGroups.allTechnical.split(',');

    if (types.length === 0) {
      // default to all communities
      currentLabel = 'All Communities';
    } else if (types.length === 1) {
      const app = apps.find(v => v.get('searchType') === searchTypes);
      currentLabel = appSearchLabel(app);
    } else {
      let allMatch;
      [communities, assets, media, shops, technical]
      .forEach((v, i) => {
        if (types.length === v.length &&
          types.sort().every((value, index) => value === v.sort()[Number(index)])) {
          allMatch = i;
        }
      });
      currentLabel = (allMatch != null)
        ? `All ${Text.Sentence(searchTypeHeaders.get(allMatch))}`
        : `Custom (${types.length})`;
    }

    setSearchLabel(currentLabel);
  };

  const onChangeSearchTypes = (groupState) => {
    let changes = '';
    groupState.forEach((gv, gk) => {
      changes = changes
        .split(',')
        .filter(c => ((c !== gk)
          ? true
          : gv))
        .concat(gv && !changes.includes(gk) ? gk : [])
        // PLATFORM-3608 type headers should not be included in selections
        .filter(c => !searchTypeHeaders.includes(c))
        .filter(c => c)
        .join();
    });
    setSearchTypes(changes || '');
  };

  const onSearchType = (open) => {
    onSetSearchLabel();
    if (!open && !searchTypes) {
      // set search types to all communities by default
      setSearchTypes(SearchFields.TypeGroups.allCommunities);
    }
  };

  const searchMenu = () => {
    const topLevel = {};
    const headers = [];
    apps
      .filter(v => v.get('search'))
      .filter(v => !v.get('all'))
      .filter(v => !v.get('exclude') || !prm.some(p => v.get('exclude').test(p)))
      .filter(v => (!v.get('internal') || prm.some(p => /org.fp.r/.test(p))))
      .filter(v => prm.some(p => v.get('test').test(p)))
      .filter(v => v.get('searchType') !== 'customer-credentials')
      .filter(v => ((v.get('searchType') === 'twitter') ? (prm.some(p => /twtr/ig.test(p))) : true))
      .filter(v => v.get('value') !== 'search.all')
      .forEach((v) => {
        if (!v.get('dropdown')) {
          topLevel[v.get('label')] = {
            label: v.get('label'),
            id: v.get('searchType'),
            name: v.get('searchBar') || v.get('label'),
          };
        } else if (topLevel[v.get('subHeader')]) {
          topLevel[v.get('subHeader')].children.push({
            label: v.get('label'),
            id: v.get('searchType'),
            name: v.get('searchBar') || v.get('label'),
          });
        } else {
          headers.push(v.get('subHeader').toLowerCase());
          topLevel[v.get('subHeader')] = {
            label: v.get('subHeader'),
            id: v.get('subHeader').toLowerCase(),
            name: v.get('subHeader'),
            open: true,
            hideToggle: true,
            children: [{
              label: v.get('label'),
              id: v.get('searchType'),
              name: v.get('searchBar') || v.get('label'),
            }],
          };
        }
      });

    const preselected = useMemo(() => searchTypes);

    const menuConfig = useMemo(() => (
      {
        topLevel: Object.values(topLevel).map(v => ({
          ...v,
          value: v.id,
          children: v?.children?.map(c => ({ ...c, value: c.id, checked: false })),
        })),
        preselected,
      }
    ), [searchTypes]);

    const menu = (
      <div className={style['search-menu']}>
        <div className={style['menu-items']}>
          <SearchTypesMenu config={menuConfig} onChange={onChangeSearchTypes} />
        </div>
        <div className={style.footer}>
          <div className={style.actions}>
            <Button onClick={() => setSearchTypes(`${SearchFields.TypeGroups.allCommunities},communities`)}>
              Reset
            </Button>
            <Button
              className={style.active}
              onClick={() => {
                setDialog();
                onSearch();
              }}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    );
    return menu;
  };

  useEffect(() => {
    const { query: { all } } = History.getCurrentLocation();
    const value = (route?.get?.('value')) ? route?.get?.('value').split('.') : [];
    if (value.includes('reports') || value.includes('intelligence')) {
      setSearchTypes('reports');
    } else if (value.includes('wiki')) {
      setSearchTypes('reports');
    } else if (value.includes('analytics')) {
      // default to all communities
      setSearchTypes(SearchFields.TypeGroups.allCommunities || '');
    } else if (value.includes('view')) {
      const type = value?.splice(-2, 1)?.[0] || SearchFields.TypeGroups.allCommunities;
      setSearchTypes(type);
    } else if (!value.includes('view')) {
      const type = route?.get?.('searchType');
      if (type === 'all') {
        setSearchTypes(all);
      } else if (type === 'media') {
        setSearchTypes(SearchFields.TypeGroups.allMedia);
      } else if (type === 'communities') {
        setSearchTypes(all || SearchFields.TypeGroups.allCommunities);
      } else if (type) {
        setSearchTypes(type);
      } else {
        setSearchTypes(SearchFields.TypeGroups.allCommunities);
      }
      onSetSearchLabel();
    }
  }, [route]);

  useEffect(() => {
    if (parentTypes.length && parentTypes !== searchTypes) {
      setSearchTypes(parentTypes);
    }
  }, [parentTypes]);

  useEffect(() => {
    setParentTypes(searchTypes);
    onSetSearchLabel();
  }, [searchTypes]);

  return (
    <div className={style['search-type-selection']}>
      <div className={style.type}>
        <Button
          className={style.dropdown}
          endIcon={<Icon className={cx([style.icon])}>keyboard_arrow_down</Icon>}
          onClick={e => setDialog(({ target: e.currentTarget, key: 'overlay' }))}>
          {searchLabel || 'All'}
        </Button>
        <Popover
          className={style.popover}
          anchorEl={dialog && dialog.target}
          open={!!(dialog && dialog.key === 'overlay')}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={() => { onSearchType(); setDialog(); }}>
          {searchMenu()}
        </Popover>
      </div>
    </div>
  );
};

SearchTypeSelection.propTypes = {
  apps: PropTypes.object,
  route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  parentTypes: PropTypes.string,
  setParentTypes: PropTypes.func,
  prm: PropTypes.object,
  onSearch: PropTypes.func,
};

SearchTypeSelection.defaultProps = {
  apps: list(),
  route: map(),
  parentTypes: '',
  setParentTypes: null,
  prm: map(),
  onSearch: null,
};

export default SearchTypeSelection;
