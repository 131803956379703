import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Icon,
  ListItem,
  Popover,
  Switch,
} from '@mui/material';
import { useRecoilValue } from 'recoil';

import style from './aggregations.module.scss';
import Histogram from '../utils/Histogram';
import PieTable from '../widget/PieTable/PieTable';
import History from '../../utils/history';
import Text from '../../utils/text';
import SearchActions from '../../actions/searchActions';
import SearchSectionsSearch from '../../stores/recoil/searchSectionsSearch';
import Messages from '../../constants/Messages';
import Svgs from '../../constants/Svgs';
import { sessionSet, sessionGet } from '../../utils/sessionStorage';

const DID_SHOW_ANALYTICS_MSG_KEY = 'fp-did-show-analytics-message';

const useStyles = makeStyles(theme => ({
  aggregations: {
    '& .MuiButton-root': {
      margin: `0 ${theme.spacing(1)}`,
    },
  },
}));

const Aggregations = ({
  basetypes,
  data,
  filters,
  prefs,
  type,
}) => {
  const classes = useStyles();
  const [pieData, setPieData] = useState({});
  const [pieKey, setPieKey] = useState('');
  const [dialog, setDialog] = useState();
  const [histogramData, setHistogramData] = useState(list());
  const [ignoreAggregations, setIgnoreAggregations] = useState(true);
  const [showOther, setShowOther] = useState(false);
  const [loadingBarChart] = useState(Svgs.barchart(200));
  const selectedSection = useRecoilValue(SearchSectionsSearch.selectedSection(type));

  const histogramTypes = [
    'accounts',
    'blogs',
    'ransomware',
    'boards',
    'cards',
    'chats',
    'communities',
    'credentials',
    'cves',
    'exploits',
    'iocs',
    'forums',
    'marketplaces',
    'pastes',
    'social',
  ];

  const pieKeys = [
    'affected_domain',
    'author',
    'breach_source',
    'channel',
    'domain',
    'platform',
    'site',
    'thread',
  ];

  const pieTypes = [
    'blogs',
    'ransomware',
    'boards',
    'chats',
    'communities',
    'credentials',
    'forums',
    'pastes',
    'social',
  ];

  // basetypes without metadata aggregations
  const excludedTypes = [
    'image-enrichment',
  ];

  const hasPieChart = Boolean(
    pieTypes.includes(selectedSection) &&
    basetypes.every(v => !excludedTypes.includes(v)),
  );

  const isLoading = (agg) => {
    let loading = true;
    if (typeof agg === 'string') return data.isEmpty() || (data.getIn([agg, 'buckets']) || list()).isEmpty();
    agg.forEach((v) => {
      if (!data.isEmpty() && !(data.getIn([v, 'buckets']) || list()).isEmpty()) loading = false;
    });
    return loading;
  };

  const isEmpty = (agg) => {
    let empty = true;
    if (isLoading(agg)) return true;
    if (typeof agg === 'string') return (data.getIn([agg, 'buckets']) || list()).isEmpty();
    agg.forEach((v) => {
      if (!(data.getIn([v, 'buckets']) || list()).isEmpty()) empty = false;
    });
    return empty;
  };

  const loadAnalytics = () => {
    const { query } = History.getCurrentLocation();
    setIgnoreAggregations(false);
    SearchActions.search(type, '', false, { ...query, loadAggregations: true });
    if (!sessionGet(DID_SHOW_ANALYTICS_MSG_KEY)) {
      sessionSet(DID_SHOW_ANALYTICS_MSG_KEY, true);
      SearchActions.set(['search', 'info'], map({
        message: Messages.SearchAnalytics,
        action: 'Manage Settings',
        fn: () => History.navigateTo('/home/help/settings#search_analytics', true) }));
    }
  };

  const onClick = (key, value, exclude = false) => {
    const { pathname, query } = History.getCurrentLocation();
    const mapping = (filter) => {
      query[String(filter)] = value === 'other'
        ? pieData[String(key)]
          .get('buckets')
          .filter(v => v.get('key'))
          .map(v => v.get('key'))
          .concat(...(query[String(filter)] || '').split())
          .join()
        : value;
      query[`${filter}_exact`] = true;
      if (value === 'other' || exclude) {
        query[`exclude_${filter}`] = true;
      } else {
        query[`exclude_${filter}`] = undefined;
      }
    };

    switch (key) {
      case 'author': ['author'].map(mapping); break;
      case 'platform': ['all'].map(mapping); break;
      case 'site': ['sites'].map(mapping); break;
      case 'thread': ['page', 'channel', 'thread_title'].map(mapping); break;
      case 'breach_source': ['source_type'].map(mapping); break;
      default: [key].map(mapping); break;
    }

    History.push({ pathname, query: { ...query, key } });
  };

  useEffect(() => {
    if (data.isEmpty()) return;

    const {
      'date-histogram': histograms = map(),
      'date-histogram-cardinality': histogramCardinality,
      ...pies
    } = data.toObject();

    const histogramAggData = histogramCardinality
      ? (histogramCardinality.get('buckets') || list())
      : histograms.get('buckets') || list();
    setHistogramData(histogramAggData);

    const key = filters.get('key')
      && Object.keys(pies).includes(filters.get('key'))
      ? filters.get('key')
      : Object.keys(pies).slice(0, 1).join();
    setPieData(pies);
    setPieKey(key);
  }, [data]);

  useEffect(() => {
    setIgnoreAggregations(!ignoreAggregations ? false : !prefs.get('search_analytics'));
  }, [prefs]);

  useEffect(() => {
    setIgnoreAggregations(!prefs.get('search_analytics'));
  }, [type]);

  return (
    <Grid
      fluid
      className={cx([
        style.aggregations,
        classes.aggregations,
        (ignoreAggregations || data.get('status')) && isEmpty(['date-histogram-cardinality', 'date-histogram']) && style.ignored,
      ])}>
      {histogramTypes.includes(selectedSection) && isLoading(['date-histogram-cardinality', 'date-histogram']) &&
      <Row className={style.loading}>
        {ignoreAggregations &&
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          className={cx([style.message, style.ignored])}
          onClick={() => loadAnalytics()}>
          Click to load search result analytics
        </div>}
        {data.get('status') &&
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          className={cx([style.message, style.ignored])}
          onClick={() => loadAnalytics()}>
          Unable to load charts. Please try again later.
        </div>}
        <Col xs={12} sm={hasPieChart ? 8 : 12}>
          <div className={cx([style.placeholder, (ignoreAggregations || data.get('status')) && style.error])}>
            {loadingBarChart}
          </div>
        </Col>
        {hasPieChart &&
        <Col xs={4}>
          <div className={cx([style.placeholder, (ignoreAggregations || data.get('status')) && style.error])}>
            {Svgs.piechart}
          </div>
        </Col>}
      </Row>}
      {!isLoading(['date-histogram-cardinality', 'date-histogram']) &&
      !isEmpty(['date-histogram-cardinality', 'date-histogram']) &&
      <Row>
        <Col sm={12} md={6} lg={hasPieChart ? 8 : 12}>
          {histogramTypes.includes(selectedSection) && data &&
            <Histogram
              type={selectedSection}
              xAxis="key"
              yAxis="doc_count"
              yAxisLabel="Total Count"
              data={histogramData}
              filters={filters}
              buckets={!prefs.getIn(['disable_graph_overlays'])} />}
        </Col>
        {hasPieChart &&
        <Col sm={12} md={6} lg={4}>
          <div className={style.options}>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont', style.title])}>
                {`Top 5 ${pieKey !== 'domain' ? Text.Sentence(pieKey) : 'Email Domain'}s`}
              </div>
              <Button
                variant="contained"
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={event => setDialog({ key: 'select', target: event.target })}
                data-testid="aggregations.top-5-select">
                {pieKey !== 'domain' ? Text.Sentence(pieKey) : 'Email Domain'}
              </Button>
              <Popover
                anchorEl={dialog && dialog.target}
                open={Boolean(dialog && dialog.key === 'select')}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => setDialog()}>
                {pieKeys
                  .filter(v => Object.keys(pieData).includes(v))
                  .map(v => (
                    <ListItem
                      key={v}
                      value={v}
                      onClick={() => {
                        setDialog();
                        setPieKey(v);
                      }}>
                      {v !== 'domain' ? Text.Sentence(v) : 'Email Domain'}
                    </ListItem>))}
              </Popover>
            </div>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.switch])}>Other:</div>
              <Switch
                className={style.toggle}
                color="secondary"
                checked={showOther}
                onChange={() => setShowOther(!showOther)}
                inputProps={{ 'data-testid': 'aggregations.other' }}/>
            </div>
          </div>
          {pieKey && data.getIn([pieKey, 'buckets']) &&
          <PieTable
            actionable={{
              exclude: {
                onClick: v => onClick(pieKey, v.get('key'), true),
                icon: 'remove_circle_outline',
                tooltip: `Exclude this ${pieKey || 'value'} from search`,
              },
              include: {
                onClick: v => onClick(pieKey, v.get('key')),
                icon: 'add_circle_outline',
                tooltip: `Search only for this ${pieKey || 'value'}`,
              },
            }}
            color="#F88670"
            header={false}
            pagination={false}
            size={{ height: 150 }}
            styles={{
              transparent: true,
              padding: '15px 0 0',
            }}
            limit={showOther ? 6 : 5}
            onClick={v => onClick(pieKey, v.get('key'))}
            cells={fromJS([{ key: 'doc_count' }])}
            labels={fromJS([{ key: 'key', render: v =>
              ((pieKey === 'domain' && prefs.getIn(['blur_credentials']))
                ? Text.BlurDomain(v, true)
                : v) }])}
            values={fromJS([
              { key: 'key', render: v =>
                ((pieKey === 'domain' && prefs.getIn(['blur_credentials']))
                  ? Text.BlurDomain(v, true)
                  : v) },
              {
                key: 'doc_count',
                style: { width: '100px', textAlign: 'right' },
                render: v => Text.AbbreviateNumber(v),
              },
            ])}
            results={fromJS({
              data: [
                ...data
                  .getIn([pieKey, 'buckets'])
                  .slice(0, 5),
                ...showOther
                  ? [{
                      key: 'other',
                      doc_count: data.getIn([pieKey, 'sum_other_doc_count']),
                    }]
                  : [],
              ],
              total: showOther ? 6 : 5,
            })}
           />}
        </Col>}
      </Row>}
      <ReactTooltip id="kb.tooltip" html place="right" effect="solid" />
    </Grid>
  );
};

Aggregations.propTypes = {
  basetypes: PropTypes.object,
  data: PropTypes.object,
  filters: PropTypes.object,
  prefs: PropTypes.object,
  type: PropTypes.string,
};

Aggregations.defaultProps = {
  basetypes: list(),
  data: map(),
  filters: map(),
  prefs: map(),
  type: '',
};

export default Aggregations;
