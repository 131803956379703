import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import cx from 'classnames';

import {
  Dialog,
  DialogTitle,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import SettingsButton from './SettingsButton';

import useStyles from './style';

const REQUIRED_ERROR_MESSAGE = 'This field is required';

export const API_KEY_REGEX = /^[\w]*$/;

const ClDialogAccount = ({ open, selected, onSubmit, onCancel, dialog, errorMessage }) => {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)} className="cloudInfraAccount">
        <Grid container>
          <Grid item xs={12} className={classes.dialogBody}>
            <FormGroup className={classes.dialogFormGroup}>
              <DialogTitle className={classes.dialogTitle}>{selected ? 'Read-Only Access' : 'Add Read-Only Access'}</DialogTitle>
              <Typography className={cx([classes.fieldGap, classes.errorText])}>
                {errorMessage && `*${errorMessage}`}
              </Typography>
              <div className={classes.fieldGap}>
                <Controller
                  name="account_name"
                  defaultValue={selected?.account_name || ''}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 255,
                  }}
                  shouldUnregister
                  render={({ field }) => <TextField
                    error={errors?.account_name}
                    disabled={selected !== null && dialog.key !== 'edit'}
                    label="Account Name"
                    autoComplete="new-password"
                    variant="outlined"
                    data-testid="account-name-field"
                    {...field} />}
                  />
                <div className={classes.errorText}>
                  {errors?.account_name?.type === 'required' && REQUIRED_ERROR_MESSAGE}
                  {errors?.account_name?.type === 'maxLength' && 'Account must not be longer than 255 characters'}
                </div>
              </div>
              <div className={classes.fieldGap}>
                <Controller
                  name="access_key_id"
                  defaultValue={selected?.credential.access_key_id || ''}
                  control={control}
                  autoComplete="new-password"
                  type={dialog.key === 'create' ? null : 'password'}
                  rules={{
                    required: true,
                    minLength: 16,
                    maxLength: 128,
                    pattern: API_KEY_REGEX,
                  }}
                  shouldUnregister
                  render={({ field }) => <TextField
                    error={errors?.access_key_id}
                    disabled={selected !== null && dialog.key !== 'edit'}
                    label="Access Key ID"
                    autoComplete="new-password"
                    variant="outlined"
                    data-testid="access-key-id-field"
                    {...field} />}
                  />
                <div className={classes.errorText}>
                  {errors?.access_key_id?.type === 'required' && REQUIRED_ERROR_MESSAGE}
                  {errors?.access_key_id?.type === 'pattern' && 'Invalid character'}
                  {(errors?.access_key_id?.type === 'minLength' || errors?.access_key_id?.type === 'maxLength') && 'API key length must be between 16 and 128 characters'}
                </div>
              </div>
              <div className={classes.fieldGap}>
                <Controller
                  name="secret_key"
                  defaultValue={selected?.credential.secret_key || ''}
                  control={control}
                  autoComplete="new-password"
                  type={dialog.key === 'create' ? null : 'password'}
                  rules={{
                    required: true,
                  }}
                  shouldUnregister
                  render={({ field }) => <TextField
                    error={errors?.secret_key}
                    disabled={selected !== null && dialog.key !== 'edit'}
                    label="Secret Key"
                    autoComplete="new-password"
                    variant="outlined"
                    data-testid="secret-key-field"
                    {...field} />}
                  />
                <div className={classes.errorText}>
                  {errors?.secret_key && REQUIRED_ERROR_MESSAGE}
                </div>
              </div>
            </FormGroup>
          </Grid>
          <SettingsButton onCancel={onCancel} isForm={selected !== null && dialog.key !== 'edit'} />
        </Grid>
      </form>
    </Dialog>
  );
};

ClDialogAccount.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  dialog: PropTypes.object,
  errorMessage: PropTypes.string,
};

ClDialogAccount.defaultProps = {
  open: false,
  selected: {},
  onSubmit: null,
  onCancel: null,
  dialog: {},
  errorMessage: '',
};

export default ClDialogAccount;
