import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Icon,
  Paper,
} from '@mui/material';

import style from './invalid.module.scss';

const Invalid = ({ classes, children, dark, denied, help, icon, styles, subtitle, title }) => (
  <Grid
    fluid
    className={cx([
      style.invalid,
      dark && style.dark,
      ...classes])}>
    <Row>
      <Col xs={12}>
        <Paper className={style.card} style={styles}>
          {icon &&
          <Icon className={style.icon}>{icon}</Icon>}
          <div className={style.text}>
            <div className={cx([style.h1, 'h1', style.open])}>{title}</div>
            {children && children}
            {!help && !children &&
            <div
              className={style.subtitle}
              dangerouslySetInnerHTML={{ __html: subtitle ||
              `It looks like you're trying to view a URL ${!denied ?
                'that does not exist. ' : 'you don\'t have access to. If your account was recently created, try logging out and back in to view this page.'} We're sorry for the inconvenience.
                If you need additional assistance, please contact us at <a href="mailto:feedback@flashpoint-intel.com">
                feedback@flashpoint-intel.com</a>` }} />}
            {help && Array.isArray(help) &&
              <ul className={style.list}>
                {help.map(item => <li key={item} dangerouslySetInnerHTML={{ __html: item }} />)}
              </ul>}
            {help && !Array.isArray(help) &&
            <ul className={style.list}>
              <li>{help}</li>
            </ul>}
          </div>
        </Paper>
      </Col>
    </Row>
  </Grid>);

Invalid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.array,
  dark: PropTypes.bool,
  denied: PropTypes.bool,
  help: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  styles: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Invalid.defaultProps = {
  children: null,
  classes: [],
  dark: false,
  denied: false,
  help: null,
  icon: '',
  styles: {},
  subtitle: '',
  title: 'Permission Denied',
};

export default Invalid;
