import React from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';
import { fromJS } from 'immutable';
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Common from '../../../utils/common';
import Text from '../../../utils/text';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    borderRadius: '2px !important',
    boxShadow: '0 0 6px rgba(0, 0, 0, .12), 0 0px 4px rgba(0, 0, 0, .24) !important',
    fontSize: '12px',
    margin: '15px 0',
    padding: '20px',
  },
  preview: {
    whiteSpace: 'normal',
  },
});

const SearchApiHits = ({
  data,
  searchPath,
}) => {
  const classes = useStyles();

  const handleClick = (e) => {
    e.preventDefault();
    /* eslint-disable-next-line security/detect-non-literal-fs-filename */
    window.open(searchPath, '_blank', 'noopener');
  };

  return (
    <Paper className={classes.card}>
      <TableContainer onClick={handleClick}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Details</TableCell>
              <TableCell>Preview</TableCell>
            </TableRow>
          </TableHead>
          {data?.hits?.map((v) => {
            const date = moment.utc(v?._source?.sort_date).format('MMM D, YYYY HH:mm');
            const site = v?._source?.site?.source_uri;
            const source = fromJS(v?._source);
            const platform = Common.Basetypes.BasetypeToSearchType(source);
            const highlight = Text.Highlight(v?.highlight?.['body.text/plain']?.[0]) || '-';
            return (
              <TableBody key={v._id}>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2"><b>Date: </b>{date}</Typography>
                    <Typography variant="body2"><b>Platform: </b>{platform}</Typography>
                    <Typography variant="body2"><b>Site: </b>{site}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.preview} variant="body2">{highlight}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </Paper>
  );
};

SearchApiHits.propTypes = {
    data: PropTypes.object.isRequired,
    searchPath: PropTypes.string.isRequired,
};

export default SearchApiHits;
