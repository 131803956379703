/* eslint-disable no-param-reassign */
import * as React from 'react';
import { useState } from 'react';
import * as PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  Paper,
  Tooltip,
  TableSortLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  Chip,
  Theme,
  SelectChangeEvent,
} from '@mui/material';
import { Search, ShowChart, DeleteForever } from '@mui/icons-material';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteBinConfirm from './DeleteBinConfirm';
import Editor from '../Editor';
import History from '../../../../utils/history';
import CSVImport from './CSVImport/CSVImport';
import BinPaginationActions from './BinPaginationAction';
import BulkEditBins from './BulkEditBins';
import query, { deleteBin } from './query';
import { devClient } from '../../../../gql/apolloClient';
import BinsExport from '../BinsExport';

const useStyles = makeStyles((theme: Theme) => ({
  rootRow: {
    '&:nth-of-type(odd)': {
      background: 'none',
    },
  },
  rootDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '75%',
    },
  },
  table: {
    width: '100%',
    '& .MuiTableBody-root .MuiTableCell-sizeSmall': {
      paddingTop: '0.75rem',
    },
  },
  editButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#5c6ae0',
      color: '#fff',
      marginRight: '1.5rem',
    },
    paddingBottom: '1.5rem',
  },
  buttons: {
    '& .MuiIconButton-root': {
      borderRadius: '15%',
      padding: '0px 12px 0px 12px',
    },
  },
  actions: {
    paddingLeft: '3rem',
  },
  pagination: {
    '& .MuiTablePagination-toolbar': {
      float: 'left',
      minHeight: 0,
      marginLeft: '0.5rem',
    },
  },
  default: {
    textAlign: 'center',
    '& .MuiButtonBase-root': {
      margin: '1.5rem',
    },
  },
  progress: {
    marginTop: '1rem',
    marginLeft: '0.5rem',
  },
  bulkEdit: {
    backgroundColor: '#5c6ae0 !important',
    color: 'white',
  },
  filterSection: {
    display: 'flex',
    minWidth: '55%',
    margin: '0.5rem',
    position: 'relative',
  },
  formHelperTextSection: {
    position: 'absolute',
    top: '38px',
    color: 'red',
  },
  clearButton: {
    padding: '2px 0 0 0',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tooltip: {
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: '0.75rem 1.75rem',
  },
}));

type Bin = {
  id: number;
  name: string;
};

interface Bins {
  id: number;
  name: string;
  salesforceId: string;
  binTags: Bin[];
}

interface customerBins {
  binTags: Bins[];
  id: number;
  number: number;
  roi: number;
}
interface BinData {
  salesforceId: number;
  size: number;
  skip: number;
  sortBy: string;
  binsFilter: string;
  tagsFilter: string[];
}

interface FilterInput {
  binsInput: string;
  tagsInput: string[];
}


interface BinsData {
  customerBinsLength: number;
  customerBins: customerBins[];
}

interface BinsTableData {
  variables?: BinData;
  data?: BinsData;
  size: number;
  skip: number;
  sortBy: string;
  tagName: string[];
  binsFilter: string;
  tagsFilter: string[];
  initialLoad: boolean;
  setSize: (setSize: number) => void;
  setSkip: (setSkip: number) => void;
  setSortBy: (setSortBy: string) => void;
  setBinsFilter: (setBinsFilter: string) => void;
  setTagsFilter: (setTagsFilter: string[]) => void;
}

const ManageBinsTable = ({
  variables,
  data,
  size,
  skip,
  sortBy,
  tagName,
  binsFilter,
  tagsFilter,
  setSize,
  setSkip,
  setSortBy,
  setBinsFilter,
  setTagsFilter,
  initialLoad,
}: BinsTableData) => {
  const classes = useStyles();
  const [binToDelete, setBinToDelete] = useState<number | null>();
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [bulkEditTags, setBulkEditTags] = useState(false);
  const [selected, setSelected] = useState([]);
  const [csvDialog, setCSVDialog] = useState(false);
  const [binsFilterError, setBinsFilterError] = useState('');
  const [openBinsExport, setOpenBinsExport] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [tagChipSelected, setTagChipSelected] = useState<string[]>(tagsFilter);
  const [filterInput, setFilterInput] = useState<FilterInput>({
    binsInput: binsFilter,
    tagsInput: tagsFilter,
  });
  const headerData = [
    { id: 'bin', label: 'Bin' },
    { id: 'binPFL', label: 'Bin Potential Fraud Loss' },
    { id: 'binTag', label: 'Bin Tags' },
    { id: 'actions', label: 'Actions' },
  ];

  const { query: q } = History.getCurrentLocation();

  const [binDelete] = useMutation(deleteBin, {
    client: devClient,
    refetchQueries: [{ query, variables }],
  });


  // #region Event Handlers

  const selectAllHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // put all bins in an array, selected
    if (event.target.checked) {
      setSelected(data.customerBins.map((bin: customerBins) => (bin)));
      return;
    }
    setSelected([]);
  };

  const rowClickHandler = (event: React.MouseEvent<unknown>, binData: customerBins) => {
    const selectedIndex = selected.indexOf(binData);
    let newSelected: customerBins[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, binData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const searchAllCommunitiesHandler = (bins: number) => {
    const pathname = '/home/search/communities';

    History.navigateTo({ pathname, query: { bins } });
  };

  const chartActionHandler = (BIN: number) => {
    History.navigateTo({
      pathname: '/home/fraud/mycards/overview',
      query: { ...q, BIN },
    });
  };

  const addHandler = () => {
    setDialog(true);
  };

  const deleteHandler = (bin: number) => {
    setConfirm(true);
    setBinToDelete(bin);
  };

  const deleteConfirmedHandler = () => {
    binDelete({
      variables: {
        ...variables,
        binNumber: binToDelete,
      },
    });

    setConfirm(false);
    setSelected([]);
  };
  // #endregion

  const control = () =>
    (selected.length === 0
      ? 'Add / Edit'
      : `Edit ${selected.length > 1 ? `s (${selected.length})` : ''}`);

  const isSelected = (binData: unknown) => selected.includes(binData);

  const onCSVImport = () => setCSVDialog(true);

  const handleChangePage = (event: unknown, newPage: number) => {
    setSize(size);
    setSkip(newPage * size);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(parseInt(event.target.value, 10));
    setSkip(0);
  };

  const onBulkEdit = () => setBulkEditTags(true);

  const onBinsFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!/^\d+$/.test(e.target.value) && e.target.value) {
      setBinsFilterError('BIN should not contain non numerical values!');
      setFilterInput({
        ...filterInput,
        binsInput: e.target.value,
      });
      setSubmitButton(true);
    }

    else if (e.target.value?.length > 6) {
      setBinsFilterError('BIN cannot be more than 6 digits');
      setFilterInput({
        ...filterInput,
        binsInput: e.target.value,
      });
      setSubmitButton(true);
    }

    else {
      setFilterInput({
        ...filterInput,
        binsInput: e.target.value,
      });
      setBinsFilterError('');
      setSubmitButton(false);
    }
  };

  const onFilterSubmit = () => {
    setSize(25);
    setSkip(0);
    setBinsFilter(filterInput.binsInput);
    setTagsFilter(filterInput.tagsInput);
  };

  const onClearSubmit = () => {
    setBinsFilter('');
    setTagsFilter([]);
    setFilterInput({
      binsInput: '',
      tagsInput: [],
    });
  };

  const onSortByHandler = () => {
    if (sortBy === 'asc') {
      setSortBy('desc');
    }

    else {
      setSortBy('asc');
    }
  };

  const tagChipSelectedHandler = (e: SelectChangeEvent<string[]>) =>
  {
    const input = e.target.value as string[];
    setFilterInput({
      ...filterInput,
      tagsInput: input,
    });
    setTagChipSelected(input);
    setSubmitButton(false);
  };

  const handleChipDelete = (value: string) => {
    setTagChipSelected(tagChipSelected.filter((tag: string) => tag !== value));
    setFilterInput({
      ...filterInput,
      tagsInput: tagChipSelected.filter((tag: string) => tag !== value),
    });
  };

  const handleOpenBinsExport = () => setOpenBinsExport(true);

  const handleCloseBinsExport = () => setOpenBinsExport(false);

  return (
    <Paper>
      { (!data.customerBins || data.customerBins.length === 0) && initialLoad ?
        <div className={classes.default}>
          <p>
            No BINs have been added to BIN Management.
            Please add BINs by using one of the below options:
          </p>
          <React.Fragment>
            <Button
              onClick={addHandler}
              variant="contained">
              Add BINs
            </Button>
            <Button
              variant="contained"
              onClick={onCSVImport}>
              Import BINs via CSV
            </Button>
          </React.Fragment>
        </div> :
        <div>
          <div className={classes.editButton}>
            { selected.length > 1 ?
              <Button
                variant="contained"
                onClick={onBulkEdit}
                className={classes.bulkEdit}>
                Bulk Edit ({selected.length})
              </Button> :
              <React.Fragment>
                <Button
                  onClick={addHandler}
                  variant="contained">
                  {control()}
                </Button>
                <Button
                  variant="contained"
                  onClick={onCSVImport}>
                  Import BINs via CSV
                </Button>
                <Button
                  variant="contained"
                  onClick={handleOpenBinsExport}>
                  Export BINs
                </Button>
              </React.Fragment>
            }
          </div>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch" padding={1}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={5}
              count={data.customerBinsLength}
              rowsPerPage={size}
              page={skip / size}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.pagination}
              ActionsComponent={BinPaginationActions}
            />
            <Box display="flex" justifyContent="flex-end">
              <FormControl variant="outlined" className={classes.filterSection}>
                <InputLabel id="bin-tags-label">BIN Tags</InputLabel>
                <Select
                  labelId="bin-tags-label"
                  id="bin-tags-filter"
                  multiple
                  value={tagChipSelected}
                  onChange={tagChipSelectedHandler}
                  renderValue={tagSelected => (
                    <div className={classes.chips}>
                      {tagSelected.map((value: string) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          onDelete={() => handleChipDelete(value)}
                          onMouseDown={(e: { stopPropagation: () => void; }) => {
                            e.stopPropagation(); }}
                          />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {tagName.map(tag => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.filterSection}>
                <InputLabel id="bin-label">BIN</InputLabel>
                <OutlinedInput
                  label="bin-label"
                  id="bins-filter"
                  placeholder="Filter BIN"
                  value={filterInput.binsInput}
                  onChange={onBinsFilterChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className={classes.clearButton} onClick={onClearSubmit} size="large">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className={classes.formHelperTextSection}>
                  {binsFilterError}
                </FormHelperText>
              </FormControl>
              <IconButton onClick={onFilterSubmit} disabled={submitButton} size="large">
                <SearchIcon />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table id="bins-table" className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 &&
                              selected.length < data.customerBins.length}
                      checked={data.customerBins.length > 0 &&
                              selected.length === data.customerBins.length}
                      onChange={selectAllHandler}
                    />
                  </TableCell>
                  {headerData.map(headCell => (
                    <TableCell key={headCell.id} className={headCell.label === 'Actions' ? classes.actions : ''}>
                      {headCell.label}
                      <TableSortLabel
                        active={headCell.label === 'Bin'}
                        direction={sortBy === 'asc' ? 'desc' : 'asc'}
                        onClick={onSortByHandler}
                        hideSortIcon
                        />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { data.customerBins && data.customerBins.map((row: customerBins) => (
                  <TableRow
                    className={classes.rootRow}
                    hover
                    key={row.id}
                    onClick={(e) => { rowClickHandler(e, row); }}
                    selected={isSelected(row.number)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected(row)} />
                    </TableCell>
                    <TableCell>{row.number}</TableCell>
                    <TableCell>{row.roi ? `\$${row.roi}` : row.roi}</TableCell>
                    <TableCell>{row.binTags && row.binTags.map(tag => tag.name).join(' ')}</TableCell>
                    <TableCell>
                      <div className={classes.buttons}>
                        <Tooltip title="Search" placement="top-start" classes={{ tooltip: classes.tooltip }} >
                          <IconButton onClick={() => { searchAllCommunitiesHandler(row.number); }} size="large" >
                            <Search />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="My Cards" placement="top-start" classes={{ tooltip: classes.tooltip }}>
                          <IconButton onClick={() => { chartActionHandler(row.number); }} size="large">
                            <ShowChart />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top-start" classes={{ tooltip: classes.tooltip }}>
                          <IconButton onClick={() => { deleteHandler(row.number); }} size="large">
                            <DeleteForever />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            { data.customerBins.length === 0 ?
              <p>
                No BINs match that search please try again with another filter!
              </p>
            : null }
          </div>
        </div>
      }
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle id="form-dialog-title">
          {selected.length === 0 ? 'Add Bins' : 'Edit Bins'}
        </DialogTitle>
        <Editor
          variables={variables}
          values={selected && selected[0]}
          bins={data.customerBins}
          close={() => setDialog(false)}
          unselect={() => setSelected([])}
          edit={selected.length !== 0}
        />
      </Dialog>
      <Dialog open={csvDialog} onClose={() => setCSVDialog(false)}>
        <DialogTitle id="form-dialog-title">
          Upload Bins
        </DialogTitle>
        <CSVImport
          variables={variables}
          close={() => setCSVDialog(false)}
        />
      </Dialog>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle id="form-dialog-title">{`Confirm Delete BIN ${binToDelete}`}</DialogTitle>
        <DeleteBinConfirm
          close={() => setConfirm(false)}
          confirmed={() => deleteConfirmedHandler()}
        />
      </Dialog>
      <Dialog open={bulkEditTags} onClose={() => setBulkEditTags(false)}>
        <DialogTitle id="form-dialog-title">Edit BINs</DialogTitle>
        <BulkEditBins
          variables={variables}
          bins={selected}
          close={() => setBulkEditTags(false)}
          unselect={() => setSelected([])}
        />
      </Dialog>
      <Dialog open={openBinsExport} onClose={handleCloseBinsExport} className={classes.rootDialog}>
        <DialogTitle id="form-dialog-title">Export BINs</DialogTitle>
        <BinsExport />
      </Dialog>
    </Paper>
  );
};

ManageBinsTable.propTypes = {
  variables: PropTypes.object,
  data: PropTypes.object,
  size: PropTypes.number,
  skip: PropTypes.number,
  sortBy: PropTypes.string,
  tagName: PropTypes.array,
  binsFilter: PropTypes.string,
  tagsFilter: PropTypes.array,
  setSize: PropTypes.func,
  setSkip: PropTypes.func,
  setSortBy: PropTypes.func,
  setBinsFilter: PropTypes.func,
  setTagsFilter: PropTypes.func,
  initialLoad: PropTypes.bool,
};

ManageBinsTable.defaultProps = {
  variables: {},
  data: {},
  size: 0,
  skip: 0,
  sortBy: 'asc',
  tagName: [],
  binsFilter: '',
  tagsFilter: [],
  setSize: (): number => null,
  setSkip: (): number => null,
  setSortBy: (): string => null,
  setBinsFilter: (): [] => null,
  setTagsFilter: (): [] => null,
  initialLoad: true,
};

export default ManageBinsTable;
