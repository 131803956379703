import light from './lightV2';
import dark from './dark';

const theme = (type = '') => {
  let config;

  switch (type) {
    case 'dark':
      config = dark;
      break;
    default:
      config = light;
  }

  return config;
};

export default theme;
