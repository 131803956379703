import React, { useContext } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import Looker from '../../utils/Looker';
import {
  UserContext,
} from '../../../components/utils/Context';

const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
  },
}));

const RansomwareIndustry = () => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const sid = user.get('sid');

  if (!sid) {
    return <CircularProgress />;
  }

  return (
    <Grid fluid className={classes.container}>
      <Row>
        <Col xs={12}>
          <Looker
            height="160rem"
            dashboard="/dashboards-next/ransomware_production::ransomware"
            sid={sid} />
        </Col>
      </Row>
    </Grid>
  );
};

RansomwareIndustry.propTypes = {};

export default RansomwareIndustry;
