import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { faSparkles } from '@flashpoint/fp-icons/pro-light-svg-icons';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { grey, red } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Checkbox, Autocomplete as MuiAutocomplete, ListItemIcon, ListItemText } from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import KeyboardDatePicker from '@mui/lab/DatePicker';
import MuiPickersUtilsProvider from '@mui/lab/LocalizationProvider';
import Dropzone from 'react-dropzone-uploader';
import Holidays from 'date-holidays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import { escape, isEmpty } from 'lodash';
import { pipe } from 'lodash/fp';
import { fromJS } from 'immutable';

import Text from '../../../utils/text';
import TagTextField from './TagTextField';
import Fields from './FormFields';
import { FpHolidays } from '../../../constants/Holidays';
import Token from '../../../utils/token';
import { Organizations } from '../query';
import History from '../../../utils/history';

import 'react-dropzone-uploader/dist/styles.css';
import style from './styles.module.scss';

const SUN = 0;
const THU = 4;
const FRI = 5;
const SAT = 6;

const useStyles = makeStyles(() => ({
  root: {
    '& label': {
      color: '#000',
    },
  },
  body: {
    fontSize: '1.6rem',
  },
  button: {
    padding: '.75rem 1.5rem',
    textTransform: 'uppercase',
  },
  dropzone: {
    '& .dzu-dropzone': {
      borderStyle: 'dashed',
      overflow: 'auto',
    },
  },
  radioGroup: {
    margin: '2rem 0 2rem 3rem',
  },
  error: {
    color: red[500],
    fontSize: '1.2rem',
    padding: '.5rem 0 0',
  },
  fieldHint: {
    color: grey[500],
    fontSize: '1.2rem',
    padding: '.5rem 0 0',
  },
  grid: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '800px',
  },
  space: {
    marginBottom: '2.4rem',
  },
  title: {
    padding: '0.5rem 1.5rem',
  },
}));

/*
 * *** Date Utilities ***
 * Must be outside of component to work properly
 */

const hd = new Holidays('US');
const PublicHolidays = hd.getHolidays(moment.utc().format('Y'))
  .filter(v => FpHolidays.includes(v.name))
  .map(v => v.date);

// Ensures date doesn't fall on a weekend
const clampWeekend = (date) => {
  if (date.getDay() === SAT) {
    date.setDate(date.getDate() + 2);
  }
  else if (date.getDay() === SUN) {
    date.setDate(date.getDate() + 1);
  }
  return date.getDate();
};

// Ensures date doesn't fall on a weekend day or holiday
const clampDate = (date) => {
  date.setDate(clampWeekend(date));

  if (PublicHolidays.includes(moment(date).format('yyyy-MM-DD 00:00:00'))) {
    return date.setDate(date.getDate() + 1);
  }
  date.setDate(clampWeekend(date));
  return date;
};

// Adjusts a date to skip the weekend if it falls on a Thursday or Friday
// Critical requests (0 day offset) can occur on THUR/FRI
const weekendOffset = (dateTime, offset) => {
  if (dateTime.getDay() === THU || dateTime.getDay() === FRI) {
    return !offset ? offset : offset + 2;
  }
  return offset;
};


// Returns the date, 2 days in the future. Used to block user from selecting invalid dates
const getMinDate = (formData = {}) => {
  const critical = Boolean(formData?.isCritical === 'Yes');
  const minDate = new Date(Date.now());
  const offset = weekendOffset(minDate, critical ? 0 : 2);
  minDate.setDate(minDate.getDate() + offset);
  return moment(clampDate(minDate)).startOf('day');
};

const IGNITE_RFI_URL = '//app.flashpoint.io/rfi';

const RfiForm = ({
  onSubmit,
  onRefresh,
  queryParams,
  data,
  loading,
}) => {
  const location = useLocation();
  const [presets, setPresets] = useState({});
  const [orgId, setOrgId] = useState();
  const [formData, setFormData] = useState({
    dueDate: '',
    format: 'Either Email or PDF',
    targetAudience: 'Submitting Team',
    teamSubmitting: '',
    subject: '',
    questions: '',
    recipients: [],
    relevant_urls: [],
    relevant_files: {},
    legalOptIn: 'no',
    isCritical: 'No',
    criticalType: '',
    criticalReason: '',
  });
  const [errors, setErrors] = useState({});
  const [owners, setOwners] = useState(fromJS([]));
  const classes = useStyles();
  const options = {
    criticalReason: [],
    isCritical: ['Yes', 'No'],
    criticalType: [
      'Threat to Life',
      'Critical Active Incident',
      'Critical Acquisition',
      'Critical Engagement',
      'Other',
    ],
  };

  // Dedupes arrays of `{ label, value }` pairs
  const dedupe = (recipients) => {
    const processed = [];
    const dupes = [];
    recipients.forEach((v) => {
      if (!v.value) return;
      if (!dupes.includes(v.value)) {
        dupes.push(v.value);
        processed.push(v);
      }
    });
    return processed;
  };

  // Used by date picker to disable invalid dates
  const disableDates = (dateArg) => {
    const date = typeof dateArg?.isSameOrBefore === 'function' ? dateArg : moment(dateArg);
    const invalid = formData.isCritical === 'Yes'
      ? date.isSameOrBefore(getMinDate(formData))
      : date.isBefore(getMinDate(formData));

    if (!date || invalid) {
      return true;
    } else if (date.day() === SUN || date.day() === SAT) {
      return true;
    }
    if (PublicHolidays.includes(date.format('yyyy-MM-DD 00:00:00'))) {
      return true;
    }
    return false;
  };

  // Returns a regex used to determine if a user submitted email is from a known domain
  const getEmailRegex = (userEmail) => {
    if (!userEmail || !userEmail.includes('@')) {
      return null;
    }
    const fpDomain = 'flashpoint-intel.com';
    const userDomain = userEmail?.includes('@') ? userEmail.split('@')[1] : null;
    // ESLint doesn't like using vars in regex. So disable the warning and make it more secure
    // eslint-disable-next-line security/detect-non-literal-regexp
    const re = userDomain ? new RegExp(`${fpDomain}|${userDomain}`) : new RegExp(`${fpDomain}`);
    return re;
  };

  // Check for valid required date
  const validateDate = date => date && !disableDates(date);

  // Checks for valid email local and domain
  const validateEmail = (text) => {
    if (!text) return true;
    // These regexes need to be inline otherwise they don't validate properly
    const domain = !/(yahoo.com|gmail.com|hotmail.com|protonmail.com|outlook.com|yandex.com|hushmail.com|aol.com|baidu.com|yahoo.cn|qq.com|sohu.com|126.com|163.com)/gi.test(text);
    // OWASP Email regex:
    // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
    // eslint-disable-next-line security/detect-unsafe-regex
    const email = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/gi.test(text);
    return domain && email;
  };

  const validateData = (_data, requireCheck = false) => {
    const {
      dueDate,
      isCritical,
      criticalType,
      criticalReason,
      questions,
      recipients,
      relevant_urls,
    } = _data;
    const errs = {};
    if (!dueDate || !validateDate(dueDate)) {
      errs.dueDate = `Please enter a date on or after ${getMinDate(formData).format('MM/DD/YYYY')} excluding weekends`;
    }

    if (requireCheck && !questions) {
      errs.questions = 'This field is required';
    } else if (questions.length > 0 && questions.length < 16) {
      errs.questions = 'Please enter at least 16 characters';
    }

    if (requireCheck && isEmpty(recipients) && isEmpty(presets.recipients)) {
      errs.recipients = 'This field is required';
    } else {
      recipients?.forEach((v) => {
        if (!validateEmail(v.value)) {
          errs.recipients = 'Please enter valid email addresses.';
        }
      });
    }

    relevant_urls?.forEach((v) => {
      if (v.value.length < 6) {
        errs.relevant_urls = 'Each URL must be at least 6 characters';
      }
    });

    if (requireCheck && isCritical === 'Yes') {
      if (criticalType === 'Other' && !criticalReason) errs.criticalReason = 'This field is required when critical type is "other"';
      else if (!criticalType) errs.criticalType = 'This field is required when request is critical';
    }

    setErrors(errs);
    return (isEmpty(errs));
  };

  const sanitizeData = (_data) => {
    const sanitizedData = {};
    const sd = {
      ..._data,
      relevant_urls: _data?.relevant_urls?.map(v => v.value),
    };
    Object.entries(sd).forEach(([k, v]) => {
      if (typeof v === 'string') {
        sanitizedData[String(k)] = Text.StripHtml(v);
      } else if (Array.isArray(v)) {
        sanitizedData[String(k)] = v.map((_v) => {
          if (typeof _v === 'string') {
            return Text.StripHtml(_v);
          } else if (typeof _v === 'object') {
            Object.entries(_v).map(([key, value]) => {
              const o = {};
              o[String(key)] = Text.StripHtml(value);
              return o;
            });
          }
          return _v;
        });
      } else {
        sanitizedData[String(k)] = v;
      }
    });
    return sanitizedData;
  };

  const formatData = (_data) => {
    const {
      dueDate,
      format,
      targetAudience,
      teamSubmitting,
      subject,
      questions,
      recipients,
      relevant_urls,
      relevant_files,
      legalOptIn,
      isCritical,
      criticalType,
      criticalReason,
    } = _data;

    const sid = Token.get('sf_id');
    const isAlternateOrg = (orgId?.salesforce_id && orgId?.salesforce_id !== sid);
    const organization = isAlternateOrg ? orgId.name : Token.get('ogn');
    const account_id = isAlternateOrg ? orgId.salesforce_id : undefined;

    return {
      desired_due_date: `${dueDate.toISOString().split('.').shift()}Z`,
      requested_format: format,
      target_audience: targetAudience,
      team_submitting: teamSubmitting,
      description: questions,
      subject: `Flashpoint RFI // ${organization} ${teamSubmitting || ''}: ${subject || ''}`,
      rfi_recipients: dedupe([...recipients]).map(v => v.value),
      relevant_urls,
      relevant_files,
      threat_actor_engagement: (legalOptIn === 'yes'),
      procurement: (legalOptIn === 'yes'),
      account_id,
      is_critical: isCritical,
      ...formData.isCritical === 'Yes'
        ? { critical_type: criticalType, critical_reason: criticalReason }
        : {},
    };
  };

  // Inserts and validates a value into the `formData` state
  const insert = (key, value) => {
    const d = {
      ...formData,
    };
    // Validate incoming key
    if (!Object.keys(formData).includes(key)) return;
    // eslint-disable-next-line security/detect-object-injection
    d[key] = (key === 'recipients') ? dedupe(value) : value;
    validateData(d);
    setFormData(d);
  };

  const handleSubmit = (event) => {
    event.nativeEvent.preventDefault();
    if (!validateData(formData, true)) return;

    const _data = pipe(
      sanitizeData,
      formatData,
    )(formData);

    if (onSubmit) onSubmit(_data);
  };

  const handleFileChangeStatus = ({ file }, status) => {
    const newFiles = formData.relevant_files;
    switch (status) {
      case 'ready':
      case 'done':
        newFiles[file.name] = file;
        break;
      case 'removed':
        delete newFiles[file.name];
        break;
      default:
        /* Do nothing */
        break;
    }
    setFormData({
      ...formData,
      relevant_files: {
        ...newFiles,
      },
    });
  };

  const handleChangeOrg = (option) => {
    setOrgId(option);
    if (onRefresh) {
      onRefresh(option?.salesforce_id);
    }
  };

  useEffect(() => {
    Organizations().then(res => setOwners(fromJS(res)));
  }, []);

  useEffect(() => {
    const id = owners.find(v => v.get('salesforce_id') === Token.get('sf_id'));
    setOrgId(id?.toJS());
  }, [owners]);

  useEffect(() => {
    validateData(formData, false);
  }, [formData]);

  useEffect(() => {
    let relevant_urls;
    try {
      if (queryParams.relevant_url) {
        relevant_urls = [{ type: 'optional', value: decodeURI(queryParams.relevant_url) }];
      }
    } catch (err) {
      relevant_urls = [{ type: 'optional', value: `Error parsing URL: ${err.message}` }];
    }

    const question = escape(queryParams.question);
    const _recipients = data?.rfi_recipients?.concat(data?.rfi_contacts);
    const otherRecipients = _recipients ?
      _recipients
        ?.filter(v => (v !== data?.cs_lead_email && v !== data?.contract_owner_email))
        ?.map((v) => {
          const rg = getEmailRegex(data?.user_email);
          if (rg.test(v)) {
            return { type: 'optional', value: v };
          }
          return { type: 'warning', value: v };
        }) :
      [];

    const recipients = dedupe([
      { type: 'required', value: data?.user_email },
      { type: 'required', value: data?.cs_lead_email },
      { type: 'optional', value: data?.contract_owner_email },
      ...otherRecipients,
    ]);
    const presetData = {
      relevant_urls,
      question,
      recipients,
    };
    setPresets(presetData);
    setFormData({
      ...formData,
      ...presetData,
    });
  }, [queryParams, data]);

  return (
    <form
      className={cx([classes.root, style.rfi])}
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }}>
      <MuiPickersUtilsProvider dateAdapter={AdapterMoment}>
        <Grid className={classes.grid} container spacing={4}>
          {location?.pathname?.includes('rfi') &&
          <MenuItem
            dense
            data-testid="search.rfi.ignite-link"
            tabIndex={0}
            className={cx([style.listitem, style.ignite])}
            onClick={() => History.navigateTo(IGNITE_RFI_URL, true)}>
            <ListItemIcon className={cx([style.icon, style.search])} >
              <FontAwesomeIcon icon={faSparkles} />
            </ListItemIcon>
            <ListItemText
              primary="See your historical RFI data and more in Ignite." />
            <ListItemText className={style.textlink}>Try it now</ListItemText>
          </MenuItem>}
          {location?.pathname?.includes('rfi') &&
          <div className={cx([classes.title, 'h0', 'raj', 'cap'])}>
            Request for Information
          </div>}
          <Grid container item spacing={1}>
            <Grid item xs={12} className={classes.space}>
              {Token.get('prm').some(p => /org.fp.r/ig.test(p)) &&
              Token.get('prm').some(p => /dat.rta.r/ig.test(p)) &&
                <div>
                  {owners.count() > 1 &&
                  <FormControl>
                    <InputLabel variant="outlined" id="goalLabel">Organization*</InputLabel>
                    <MuiAutocomplete
                      fullWidth
                      autoSelect
                      openOnFocus
                      blurOnSelect
                      disableCloseOnSelect
                      value={owners.find(v => v?.get('salesforce_id') === orgId?.salesforce_id && v?.get('name') === orgId?.name)?.toJS() || null}
                      options={owners
                          .sortBy(v => v.get('name').toLowerCase())
                          .toJS()}
                      getOptionLabel={option => option.name}
                      onChange={(event, option) => handleChangeOrg(option)}
                      renderInput={parameters => (
                        <TextField
                          {...parameters}
                          variant="outlined"
                          InputProps={{ ...parameters.InputProps, 'data-testid': 'rfiform.organization' }} />
                          )}
                        />
                  </FormControl>}
                  {owners.count() === 1 &&
                  <div>
                    <span>{Text.Sentence(orgId?.name)}</span>
                  </div>}
                </div>
              }
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  label="This is a Critical Request"
                  control={<Checkbox
                    checked={Boolean(formData.isCritical === 'Yes')}
                    onChange={e => insert('isCritical', e.target.checked ? 'Yes' : 'No')} />}
                />
              </FormControl>
              <div className={classes.error}>
                {!!errors.isCritical && errors.isCritical}
              </div>
            </Grid>
            <Grid container item spacing={1} className={classes.space}>
              {formData.isCritical === 'Yes' &&
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel id="criticalType" variant="outlined">Critical Type*</InputLabel>
                  <Select
                    name="criticalType"
                    value={formData.criticalType}
                    onChange={e => insert('criticalType', e.target.value)}
                    labelId="criticalType"
                    variant="outlined"
                    inputProps={{ 'data-testid': 'rfiform.critical-type' }}>
                    {options?.criticalType?.map(v =>
                      <MenuItem key={v} value={v}>{Text.Sentence(v)}</MenuItem>)}
                  </Select>
                </FormControl>
                <div className={classes.error}>
                  {!!errors.criticalType && errors.criticalType}
                </div>
              </Grid>}
              {formData.isCritical === 'Yes' &&
              formData.criticalType === 'Other' &&
              <Grid item xs={12}>
                <TextField
                  name="criticalReason"
                  value={formData.criticalReason}
                  onChange={e => insert('criticalReason', e.target.value)}
                  multiline
                  label="Please describe the reason*"
                  defaultValue={presets.criticalReason}
                  variant="outlined"
                  maxrows={16}
                  InputProps={{ 'data-testid': 'rfiform.crtical-reason' }}
                  />
                <div className={classes.error}>
                  {!!errors.criticalReason && errors.criticalReason}
                </div>
              </Grid>}
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="due-date" variant="outlined">Due Date*</InputLabel>
                <KeyboardDatePicker
                  name="dueDate"
                  shouldDisableDate={disableDates}
                  value={formData.dueDate}
                  onChange={e => insert('dueDate', e)}
                  format="MM/DD/YYYY"
                  inputVariant="outlined"
                  variant="inline"
                  renderInput={myParams =>
                    <TextField
                      {...myParams}
                      InputProps={{
                      ...myParams.InputProps,
                      'data-testid': 'rfiform.due-date',
                    }} />}
                  />
              </FormControl>
              <div className={classes.error}>
                {!!errors.dueDate && errors.dueDate}
              </div>
              <Typography className={classes.fieldHint}>
                This date may be adjusted due to public holidays
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="format" variant="outlined">Requested Format*</InputLabel>
                <Select
                  name="format"
                  labelId="format"
                  variant="outlined"
                  value={formData.format}
                  onChange={e => insert('format', e.target.value)}
                  inputProps={{ 'data-testid': 'rfiform.request-format' }}
                >
                  { Fields.format.map(v =>
                    <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
                </Select>
              </FormControl>
              <div className={classes.error}>{!!errors.format && errors.format}</div>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="target-audience" variant="outlined">Target Audience*</InputLabel>
                <Select
                  name="targetAudience"
                  value={formData.targetAudience}
                  onChange={e => insert('targetAudience', e.target.value)}
                  labelId="target-audience"
                  variant="outlined"
                  inputProps={{ 'data-testid': 'rfiform.target-audience' }}
                  >
                  { Fields.recipients.map(v =>
                    <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
                </Select>
              </FormControl>
              <div className={classes.error}>
                {!!errors.targetAudience && errors.targetAudience}
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="teamSubmitting"
                value={formatData.teamSubmitting}
                onChange={e => insert('teamSubmitting', e.target.value)}
                label="Team Submitting"
                variant="outlined"
                InputProps={{ 'data-testid': 'rfiform.team-submitting' }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <TextField
              name="subject"
              value={formData.subject}
              onChange={e => insert('subject', e.target.value)}
              label="Subject Line"
              variant="outlined"
              InputProps={{ 'data-testid': 'rfiform.subject-line' }}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="questions"
              value={formData.questions || presets.questions}
              onChange={e => insert('questions', e.target.value)}
              multiline
              label="What specific questions would you like addressed as part of this Request for Information?*"
              defaultValue={presets.question}
              variant="outlined"
              maxrows={16}
              InputProps={{ 'data-testid': 'rfiform.question' }}
            />
            <Typography className={classes.fieldHint}>
              Along with your questions, please provide any context
              that will help assist Flashpoint in addressing your request
            </Typography>
            <div className={classes.error}>
              {!!errors.questions && errors.questions}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="format" variant="outlined">Recipients*</InputLabel>
              <TagTextField
                name="recipients"
                defaultValue={presets.recipients}
                onChange={e => insert('recipients', e)}
                regEx={getEmailRegex(data?.user_email)}
                placeholder="Enter an email address and press [enter] to add it to the list"
                inputProps={{ 'data-testid': 'rfiform.recipients' }} />
            </FormControl>
            <div className={classes.error} data-testid="rfiform.recipients.error">
              {!!errors.recipients && errors.recipients}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="format" variant="outlined">Relevant URLs</InputLabel>
              <TagTextField
                name="urls"
                defaultValue={presets.relevant_urls}
                onChange={e => insert('relevant_urls', e)}
                placeholder="Enter a URL and press [enter] to add it to the list"
                inputProps={{ 'data-testid': 'rfiform.relevant_urls' }}
              />
            </FormControl>
            <Typography className={classes.fieldHint}>
              Please include any fp.tools links and/or URLs from open source
              that your team has identified in your research
            </Typography>
            <div className={classes.error} data-testid="rfiform.relevant-urls.error">
              {!!errors.relevant_urls && errors.relevant_urls}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.dropzone} data-testid="rfiform.relevant-files">
              <InputLabel variant="outlined" >Relevant Files</InputLabel>
              <Dropzone
                autoUpload={false}
                onChangeStatus={handleFileChangeStatus}
                accept="application/pdf,image/jpg,image/jpeg,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                inputContent={<CloudUploadIcon style={{ color: grey[600] }} key="dropzone" />}
              />
            </FormControl>
            <Typography className={classes.fieldHint}>
              Please include any relevant attachments that your team has identified in your research.{' '}
              Accepted file types include: PDF, JPG, PNG and excel files
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.body} variant="body1" gutterBottom>
              If this Request for Information will require Flashpoint to proactively engage a threat actor or to{' '}
              acquire new data that is not currently in Flashpoint's collections, please confirm that you consent{' '}
              to Flashpoint taking such actions on your behalf in connection with this request:
            </Typography>
            <FormControl component="fieldset" className={classes.radioGroup}>
              <RadioGroup
                name="legalOptIn"
                value={formData.legalOptIn || 'no'}
                onChange={e => insert('legalOptIn', e.target.value)}
                data-testid="rfiform.consent"
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Typography className={[classes.body, classes.space].join(' ')} variant="body1" gutterBottom>
              If responding to an RFI requires Flashpoint to make a payment to the applicable threat actor,{' '}
              Flashpoint reserves the right to pass through the additional charges to you, and will obtain your{' '}
              consent before any such fees are incurred.  Without this consent, Flashpoint may be unable to{' '}
              complete your request.
            </Typography>
            <Typography className={classes.body} variant="body1" gutterBottom>
              To learn more about Flashpoint’s RFI service and best practices for submitting a well crafted RFI,{' '}
              please <Link href="https://docs.flashpoint.io/flashpoint/docs/request-for-information-rfi-guide" target="_blank" color="primary">visit the RFI Guide</Link>
            </Typography>
          </Grid>
          <Grid container item spacing={1} justifyContent="center">
            {
            loading ?
              <CircularProgress size={48} color="secondary" /> :
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  data-testid="rfiform.organization">Submit Request
                </Button>
              </Grid>
            }
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </form>
  );
};

RfiForm.propTypes = {
  /** Called when the user submits the form */
  onSubmit: PropTypes.func,

  /** Requests a refresh of the prepopulated field. `onRefresh(salesforceId)` */
  onRefresh: PropTypes.func,

  /** Any params from the query string to pass into the form */
  queryParams: PropTypes.object,

  /** Initial fields from Salesforce to populate the form with */
  data: PropTypes.object,

  /** Flag to let form know data is loading */
  loading: PropTypes.bool,
};

RfiForm.defaultProps = {
  onSubmit: null,
  onRefresh: null,
  queryParams: {},
  data: null,
  loading: false,
};

export default RfiForm;
