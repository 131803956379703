import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { fromJS, Iterable, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  CircularProgress,
  Icon,
  Input,
  Paper,
} from '@mui/material';

import style from './admin.module.scss';
import History from '../../utils/history';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';

const Admin = ({
  data,
  defaults,
  help,
  id,
  prefs,
}) => {
  const [text, setText] = useState(id);

  const localPrefs = window.localStorage.prefs
    ? fromJS(JSON.parse(window.localStorage.prefs))
    : map();

  const onChange = (v) => {
    setText(v.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));
  };

  const onClearPreference = (key) => {
    const value = { ...data.toJS(), [key]: defaults.get(key) };
    UserActions.adminSavePrefs(value, id);
  };

  const onOverwritePreference = (key) => {
    const timeout = moment().utc().add(60, 'minute').unix();
    const stored = window.localStorage.prefs;
    if (!stored || !JSON.parse(stored).time) {
      const toStore = {
        time: timeout,
        data: prefs.toJS(),
      };
      window.localStorage.prefs = JSON.stringify(toStore);
    }
    const overwrite = (key === 'all')
      ? data.toJS()
      : {
        ...data.toJS(),
        [key]: defaults.get(key),
      };
    UserActions.adminSavePrefs(overwrite);
  };

  const onReset = () => {
    setText('');
  };

  const onResetPreferences = () => {
    const value = localPrefs;
    if (value.isEmpty() || !value.has('time')) return;
    const now = moment().utc().unix();
    if (value.get('time') <= now) {
      window.localStorage.removeItem('prefs');
      return;
    }
    UserActions.adminSavePrefs(value.get('data').toJS());
    window.localStorage.removeItem('prefs');
  };

  const onSearch = () => {
    setText('');
    SearchActions.set(['search', 'admin', 'user'], map());
    SearchActions.userPreferences(text);
    History.push({ pathname: `/home/profile/admin/users/${text}` });
  };

  return (
    <Grid
      fluid
      name="component.admin"
      className={cx([style.base, style.admin])}>
      <div className={style['search-container']}>
        <form
          className={style.form}
          onSubmit={(event) => { event.preventDefault(); onSearch(); }}>
          <Icon
            className={cx([style.icon, style.search, 'material-icons'])}
            onClick={(event) => { event.preventDefault(); onSearch(); }}>search
          </Icon>
          <Input
            autoFocus
            fullWidth
            disableUnderline
            id="text"
            data-lpignore="true"
            value={text || ''}
            className={style.input}
            placeholder="Search exact user id"
            onChange={event => onChange(event.target.value)} />
          {text &&
          <Icon
            onClick={() => onReset()}
            className={cx([style.icon, style.close, 'material-icons'])}>close
          </Icon>}
        </form>
        {!localPrefs.isEmpty() && localPrefs.has('time') &&
        <Button
          color="secondary"
          variant="contained"
          name="button.reset.own"
          onClick={() => onResetPreferences()}>
          Reset your preferences
        </Button>}
      </div>
      {data.isEmpty() && id &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {data.has('invalid') &&
      <Paper className={style.card}>
        <div className={style.text}>
          Invalid User ID
        </div>
      </Paper>}
      {!data.isEmpty() && id && !data.has('invalid') &&
      <div>
        <Row>
          <Col xs={12} className={style.header}>
            <div className={style.title}>
              <div
                className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
                name="admin.user.id">
                {`User ID: ${id}`}
              </div>
            </div>
            <Button
              color="secondary"
              variant="contained"
              name="button.overwrite.all"
              onClick={() => onOverwritePreference('all')}>
              Overwrite your preferences (1 hour)
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Paper className={style.card}>
              {data
                .filter(v => !Iterable.isIterable(v))
                .keySeq()
                .map(k => (
                  <div className={style.table} key={k}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>{defaults.has(k)
                        ? (
                          <div>
                            <span>{k}:</span>
                            <Icon
                              data-for="admin.tooltip"
                              data-tip={help.get(k)}
                              className={cx([style.icon, 'material-icons', style.help])}>
                              help
                            </Icon>
                          </div>
                        )
                        : `${k} (deprecated):`}
                    </div>
                    <div className={style.value}>{(data.get(k) != null ? data.get(k) : 'null').toString()}</div>
                    <Button
                      color="secondary"
                      variant="contained"
                      name={`button.clear.${k}`}
                      onClick={() => onClearPreference(k)}>
                      {defaults.has(k) ? 'Reset Preference' : 'Clear Preference'}
                    </Button>
                  </div>
                ))}
            </Paper>
          </Col>
        </Row>
        {data
          .filter(v => Iterable.isIterable(v))
          .keySeq()
          .map(k => (
            <Row key={k}>
              <Col xs={12}>
                <Paper className={style.card}>
                  <Col xs={12} className={style.header}>
                    <div className={style.title}>
                      <div
                        className={cx([style.h3, style.cap, 'cap'])}
                        name={`admin.user.${k}`}>
                        {defaults.has(k)
                            ? (
                              <div>
                                <span>{k}:</span>
                                <Icon
                                  data-for="admin.tooltip"
                                  data-tip={help.get(k)}
                                  className={cx([style.icon, 'material-icons', style.help])}>
                                  help
                                </Icon>
                              </div>
                            )
                            : `${k} (deprecated):`}
                      </div>
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      name={`button.clear.${k}`}
                      onClick={() => onClearPreference(k)}>
                      {defaults.has(k) ? 'Reset Preference' : 'Clear Preference'}
                    </Button>
                  </Col>
                  {data
                    .get(k)
                    .keySeq()
                    .map((vk) => {
                      const value = data.get(k).get(vk);
                      const count = (list.isList(value) ? value.count() : null);
                      return (
                        <div className={style.table} key={`${k}.${vk}`}>
                          <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>{count ? `${vk} (${count})` : vk}:</div>
                          <pre>{JSON.stringify(data.get(k).get(vk), null, 2)}</pre>
                        </div>
                      );
                    })}
                  {data
                    .get(k)
                    .keySeq()
                    .isEmpty() && (
                      <Paper className={style.card}>
                        <div className={style.text}>
                          Empty Preference
                        </div>
                      </Paper>
                    )}
                </Paper>
              </Col>
            </Row>
          ))}
      </div>}
      <ReactTooltip id="admin.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Admin.propTypes = {
  data: PropTypes.object,
  defaults: PropTypes.object,
  help: PropTypes.object,
  id: PropTypes.string,
  prefs: PropTypes.object,
};

Admin.defaultProps = {
  data: map(),
  defaults: map(),
  help: map(),
  id: '',
  prefs: map(),
};

export default Admin;
