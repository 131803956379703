import React from 'react';

import { FlashpointLogo } from '../../Logo';
import { Loader } from '../../Loader';

import styles from './authLoading.module.scss';

export const AuthLoading = (): JSX.Element => (
  <div className={`${styles.container}`}>
    <FlashpointLogo />
    <Loader type="spinner" size="xs" />
  </div>
);
