import { atom, selector } from 'recoil';

import { fromJS, List as list, Map as map } from 'immutable';
import History from '../../utils/history';

const activeOrgs = atom({
  key: 'alerting.activeOrgs',
  default: list(),
});

const alerts = atom({
  key: 'alerting.alerts',
  default: map(),
});

const basetypes = atom({
  key: 'alerting.basetypes',
  default: list(),
});

const defaultFilters = atom({
  key: 'alerting.defaultFilters',
  default: map({
    skip: '0',
    limit: '50',
  }),
});

const enterpriseDomainKeyclassId = atom({
  key: 'alerting.enterpriseDomainKeyclassId',
  default: '',
});

export const makeFiltersFromQueryString = () => {
  const { query } = History.getCurrentLocation();
  const opts = { ...query };
  if (query.owner_id) {
    opts.owner_id = query.owner_id;
  }
  return fromJS(opts).filter(v => v);
};

const filters = atom({
  key: 'alerting.filters',
  default: makeFiltersFromQueryString(),
});

const keywordClasses = atom({
  key: 'alerting.keywordClasses',
  default: list(),
});

const keywords = atom({
  key: 'alerting.keywords',
  default: map(),
});

const keywordError = atom({
  key: 'alerting.keywordError',
  default: false,
});

const keywordsFilter = atom({
  key: 'alerting.keywordsFilter',
  default: list(),
});

const notificationProfiles = atom({
  key: 'alerting.notificationProfiles',
  default: map(),
});

const owners = atom({
  key: 'alerting.owners',
  default: list(),
});

const products = atom({
  key: 'alerting.products',
  default: list(),
});

const type = atom({
  key: 'alerting.type',
  default: '',
});

const loadErr = atom({
  key: 'alerting.loadErr',
  default: false,
});

// selectors
const alertsFilters = selector({
  key: 'alerting.alertsFilters',
  get: ({ get }) => {
    const storedFilters = get(filters);
    const storedDefaults = get(defaultFilters);
    const defaults = storedDefaults;
    return storedFilters.filterNot(v => defaults.has(v));
  },
});

const ownerId = selector({
  key: 'alerting.ownerId',
  get: ({ get }) => {
    const storedFilters = get(filters);
    const storedOwners = get(owners);

    if (storedFilters.get('owner_id')) {
      return storedFilters.get('owner_id');
    }

    if (storedOwners.isEmpty()) return '';
    return storedOwners
      ?.sortBy(owner => owner.get('updated_at') !== null)
      ?.first()
      ?.get('id');
  },
});

const owner = selector({
  key: 'alerting.owner',
  get: ({ get }) => {
    const id = get(ownerId);
    const data = get(owners);
    if (data.isEmpty()) return map();
    const index = (id) ? data.findIndex(v => v.get('id') === id) : 0;
    const selected = data.get(index, map());

    return selected || map();
  },
});

const Alerting = {
  activeOrgs,
  alerts,
  alertsFilters,
  basetypes,
  defaultFilters,
  enterpriseDomainKeyclassId,
  filters,
  keywordClasses,
  keywords,
  keywordError,
  keywordsFilter,
  notificationProfiles,
  owner,
  ownerId,
  owners,
  products,
  type,
  loadErr,
};

export default Alerting;
