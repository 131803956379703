import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { isEmpty, isEqual } from 'lodash';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import useQueryString from '../../../hooks/useQueryString';
import Looker from '../../utils/Looker';
import History from '../../../utils/history';
import { getSfid } from '../../../stores/recoil/app';
import { cfmFiltersFc } from '../../../stores/recoil/cfm';
import { sanitizeFilters } from '../../../hooks/useLookerApi';

export const fraudCardStyles = {
  cards: {
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
  },
  tabBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    marginTop: '1.6rem',
    justifyContent: 'center',
    '& .MuiButton-root': {
      borderRadius: '5px',
      color: '#4f5269',
      minHeight: '2.5rem',
      minWidth: 0,
      padding: '0 1rem',
      '&:hover': {
        color: '#2a2c3c',
        backgroundColor: '#e5e7fa',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
};

const useStyles = makeStyles(() => fraudCardStyles);

const FullCards = () => {
  const [bin] = useQueryString('BIN');
  const [binTags] = useQueryString('BIN Tags');
  const [dataType] = useQueryString('Data Type');
  const [date] = useQueryString('Date');
  const [siteActor] = useQueryString('Site Actor');
  const [sourceName] = useQueryString('Source Name');
  const [uniqueCards] = useQueryString('Unique Cards');

  const classes = useStyles();
  const sid = useRecoilValue(getSfid);
  const [fullCardsFilters, setFullCardsFilters] = useRecoilState(cfmFiltersFc);

  const filterReset = useResetRecoilState(cfmFiltersFc);
  const match = useRouteMatch();
  const params = useParams();

  const { pathname, query } = History.getCurrentLocation();

  useEffect(() => {
    setFullCardsFilters({
      BIN: bin,
      'BIN Tags': binTags,
      'Data Type': dataType,
      Date: date,
      'Site Actor': siteActor,
      'Source Name': sourceName,
      'Unique Cards': uniqueCards,
    });
  }, [bin, binTags, dataType, date, siteActor, sourceName, uniqueCards]);

  useEffect(() => {
    const sanitized = sanitizeFilters(fullCardsFilters);
    if (!isEqual(query, sanitized)) {
      if (!isEmpty(sanitized)) {
        setFullCardsFilters({
          ...sanitized,
        });

        History.push({
          pathname,
          query: {
            ...sanitized,
          },
        });
      }
    }
  }, [fullCardsFilters]);

  useEffect(() => () => {
    filterReset();
  }, []);

  if (!sid) {
    return <CircularProgress />;
  }

  return (
    <Grid fluid className={classes.cards}>
      <Row>
        <Col xs={12}>
          <nav className={classes.tabBar}>
            <Button
              onClick={() => History.push({
                pathname: 'overview',
                query,
              })}
              disabled={match?.url?.includes('/fullcards/overview')}
            >
              Overview
            </Button>
            <Button
              onClick={() => History.push({
                pathname: 'export',
                query,
              })}
              disabled={match?.url?.includes('fullcards/export')}
            >
              Export
            </Button>
          </nav>

          {['overview'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::full_cards_overview"
            filters={fullCardsFilters}
            setFilters={setFullCardsFilters}
            sid={sid} />}
          {['export'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::full_cards_export"
            filters={fullCardsFilters}
            setFilters={setFullCardsFilters}
            sid={sid} />}
        </Col>
      </Row>
    </Grid>
  );
};

export default FullCards;

