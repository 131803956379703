
import Api from '../../../utils/api';
import Text from '../../../utils/text';

const SearchUrl = '/ui/v4/all/search';
const Status = [200, 201, 204, 500, 501, 502, 503, 504];

const Media = async (type, fpid, media_fpid, sha1, text) => {
  const validType = Boolean(['chat'].includes(type));
  const buildQuery = (_fpid, _sha1, _text) => {
    if (_sha1 && validType) {
      return `+basetypes:(${type})\
        +(_exists_:media.storage_uri)\
        ${_sha1 ? `+(media.sha1:"${_sha1 || ''}"` : ''})\
        ${_text ? `+(${_text || ''})` : ''}`;
    } else if (_text && validType) {
      return `+basetypes:(${type})\
        +_exists_:media.storage_uri\
        ${_text ? `+(${_text || ''})` : ''}`;
    }
    return `+basetypes:(image-enrichment OR video-enrichment) +(|fpid:"${fpid}"\
      ${_sha1 ? `|sha1:"${_sha1 || ''}` : ''})`;
  };

  const querySha1 = async (_fpid) => {
    const _query = {
      query: `+(fpid:"${_fpid}")`,
      traditional_query: true,
      size: 1,
      sort: ['sort_date:desc'],
    };
    const data = await Api.post(SearchUrl, _query, Status, 30000)
      .then(res => (res.ok ? res.data : []))
      .then(res => res?.hits)
      .then(res => ({
        ...res,
        media_v2: [res.hits?.[0]?._source?.media]
            .sort(a => (a?.fpid === media_fpid ? -1 : 1))?.[0],
      }))
      .then(res => ({
        sha1: res.hits[0]?._source?.sha1,
        storage_uri: res.hits[0]?._source?.storage_uri,
      }));
    return data;
  };

  const extracts = (res, obj, extraction) => ({
    skip: 0,
    total: res?.media_v2?.[0]?.[`${res?.media_type}_enrichment`]?.enrichments?.v1?.[`${res?.media_type}-analysis`]?.[String(extraction)]?.length || obj?._source?.enrichments.v1[`${res?.media_type}-analysis`]?.[String(extraction)]?.length,
    data: res?.media_v2?.[0]?.[`${res?.media_type}_enrichment`]?.enrichments?.v1?.[`${res?.media_type}-analysis`]?.[String(extraction)] || obj?._source?.enrichments.v1[`${res?.media_type}-analysis`]?.[String(extraction)]
      ?.map(v => (Text.StripHighlight(res?.media_v2?.[0]?.[`${res?.media_type}_enrichment`]?.enrichments.v1[[`${res?.media_type}-analysis`]]?.[String(extraction)]?.value || obj?._source?.enrichments.v1[[`${res?.media_type}-analysis`]]?.[String(extraction)]?.value).includes(v.value)
        ? { value: res?.media_v2?.[0]?.[`${res?.media_type}_enrichment`]?.enrichments.v1[[`${res?.media_type}-analysis`]]?.[String(extraction)]?.value || obj?._source?.enrichments.v1[[`${res?.media_type}-analysis`]]?.[String(extraction)]?.value }
        : { value: v?.value })),
  });

  const img = await querySha1(fpid);
  const shaOne = sha1 || img.sha1;
  const storageUri = img.storage_uri;

  // invalid sha1/fpid combination
  if (!shaOne) {
    return {
      skip: 0,
      total: 0,
      data: [],
    };
  }

  const limit = 1;
  const query = {
    query: buildQuery(fpid, shaOne, text),
    traditional_query: true,
    size: limit,
    sort: ['sort_date:desc'],
  };

  return Api.post(SearchUrl, query, Status, 30000)
    .then(res => (res.ok ? res.data : []))
    .then(res => Api.mapHighlightToSource(res))
    .then(res => (res?.hits?.hits?.length > 0 ? res.hits : { hits: [{}] }))
    .then(res => ({
      ...res,
      media_v2: /enrichment/.test(type)
        ? [res?.hits?.[0]?._source]
        : [res.hits?.[0]?._source?.media],
    }))
    .then(res => ({
      ...res,
      media_type: /enrichment/.test(type)
        ? type?.replace(/-enrichment/, '')
        : res?.media_v2?.[0]?.media_type,
      media_v2: res.media_v2.sort((a) => {
        if (shaOne) {
          return (a?.sha1 === shaOne ? -1 : 1);
        }
        return (a?.fpid === media_fpid ? -1 : 1);
      }),
    }))
    .then(res => ({
      skip: 0,
      filename: res.media_v2?.[0]?.filename
        || res?._source?.filename
        || sha1,
      first_seen: res.hits?.slice(-1)?.[0]?._source?.sort_date,
      last_seen: res.hits?.[0]?._source?.sort_date,
      total: res?.total,
      data: res?.hits.map(v => ({
        ...v?._source,
        media_v2: res?.media_v2,
        highlight: v?.highlight,
        src: encodeURI(`/ui/v4/media/assets/?asset_id=${res?.media_v2?.[0]?.storage_uri || v?._source?.storage_uri || storageUri}`),
        classifications: extracts(res, v, 'classifications'),
        logos: extracts(res, v, 'logos'),
        text: extracts(res, v, 'text'),
      })),
    }));
};

const Mentions = async (sha1) => {
  if (!sha1) return null;
  const sha1Query = `-basetypes:(image-enrichment OR video-enrichment) +(|media.sha1:"${sha1}" |media_v2.sha1:"${sha1}" |sha1:"${sha1})"`;

  const firstQuery = {
    query: sha1Query,
    traditional_query: true,
    size: 1,
    sort: ['sort_date:asc'],
  };

  const lastQuery = {
    ...firstQuery,
    size: 25,
    sort: ['sort_date:desc'],
  };

  const first = Api.post(SearchUrl, firstQuery, Status, 30000);
  const last = Api.post(SearchUrl, lastQuery, Status, 30000);

  return Promise.all([first, last])
    .then((values) => {
      const [_first, _last] = values;
      if (!_first.ok && !_last.ok) return {};
      return {
        total: _last?.data?.hits?.total,
        data: _last?.data?.hits?.hits?.map(v => v?._source),
        firstSeen: _first?.data?.hits?.hits[0]?._source?.sort_date,
        lastSeen: _last?.data?.hits?.hits[0]?._source?.sort_date,
      };
    })
    .catch(() => ({}));
};

export default {
  Mentions,
  Media,
};
