import * as React from 'react';

import { useRecoilValue } from 'recoil';
import { Grid, Row, Col } from 'react-flexbox-grid';
import makeStyles from '@mui/styles/makeStyles';

import Looker from '../../utils/Looker';
import { getSfid } from '../../../stores/recoil/app';

const useStyles = makeStyles({
  accessAuctionsDashboard: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
});

const AccessAuctionsDashboard = (): JSX.Element => {
  const classes = useStyles();
  const sid = useRecoilValue(getSfid);

  return (
    <Grid className={classes.accessAuctionsDashboard}>
      <Row>
        <Col xs={12}>
          <Looker
            height="120rem"
            dashboard="/dashboards-next/data_warehouse_intel_views::access_auctions"
            sid={sid}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export { AccessAuctionsDashboard };

