import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import qs from 'qs';
import cx from 'classnames';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Fab,
  Icon,
  CircularProgress,
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import style from './wiki.module.scss';
import Text from '../../utils/text';
import Card from '../reports/Card';
import Malware from './malware/Malware';
import Topic from './topic/Topic';
import Query from './query';
import Invalid from '../utils/Invalid/Invalid';
import Prompt from '../utils/Prompt';
import History from '../../utils/history';
import TextFilter from '../../components/filters/TextFilter';

const useStyles = makeStyles(theme => ({
  wiki: {
    '& .MuiFab-root': {
      margin: theme.spacing(1),
    },
  },
  topicCard: {
    '& [class*=content_]': {
      width: '100%',
    },
  },
}));

const archivedOptions = [{ label: 'All', value: 'all' }, { label: 'Active', value: 'active' }, { label: 'Archived', value: 'archived' }];
const sortOptions = [{ label: 'Alphabetical Ascending', value: 'ascending' }, { label: 'Alphabetical Descending', value: 'descending' }];

const Wiki = ({
  fpid,
  orgName,
  orgId,
  prm,
  type,
  subtype,
}) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [dialog, setDialog] = useState();
  const [filters, setFilters] = useState();
  const [userOrg, setUserOrg] = useState({ label: orgName, value: orgId });
  const [visibleOrgs, setVisibleOrgs] = useState([]);
  const [allOrgs, setAllOrgs] = useState();
  const [error, setError] = useState('');

  const icons = {
    apt: 'bug_report',
    malware: 'bug_report',
    topics: '',
  };

  const search = {
    apt: '/home/intelligence/apt',
    malware: '/home/intelligence/malware',
    topics: '/home/explore/topics',
  };

  const bodies = {
    apt: 'body',
    malware: 'body',
    topics: 'body',
  };

  const sorts = {
    apt: 'apt_group',
    malware: 'malware_family_name',
    topics: 'topic_name',
  };

  const titles = {
    apt: 'apt_group',
    malware: 'malware_family_name',
    topics: 'topic_name',
  };

  const topicEditor = (entry) => {
    const published_at = entry.has('published_at')
      ? `<b>Created</b>: ${entry.getIn(['published_at', 'usn'], '')} ${entry.getIn(['published_at', 'date-time'])}`
      : '';
    const updated_at = entry.has('updated_at')
      ? `<b>Updated</b>: ${entry.getIn(['updated_at', 'usn'], '')} ${entry.getIn(['updated_at', 'date-time'])}`
      : '';
    return `${published_at}<br/>${updated_at}`;
  };

  const aptFilters = { name: 'apt', text: '', active: 'active', sort: 'ascending', data: [] };
  const malwareFilters = { name: 'malware', text: '', active: 'active', sort: 'ascending', data: [] };
  const topicFilters = { name: 'topics', text: '' };

  const categories = data
    ?.map(v => v.get('categories', list()))
    ?.reduce((a, b) => [...new Set([...a, ...b])], ['topics'].includes(type) ? ['trending_now'] : [])
    ?.filter(v => (subtype ? [subtype].includes(v) : v))
    ?.sort((a, b) => {
      if (a === 'trending_now') return -1;
      if (b === 'trending_now') return 1;
      if (a === 'private_topic') return -1;
      if (b === 'private_topic') return 1;
      return a.localeCompare(b);
    });

  const archivedFilter = (v, f) => {
    switch (f) {
      case 'all': return v;
      case 'active': return !v.get('is_archived');
      case 'archived': return v.get('is_archived');
      default: return v;
    }
  };

  const sortFilter = (a, b, f) => {
    const prev = a?.get(sorts?.[String(type)], '')?.toLowerCase();
    const next = b?.get(sorts?.[String(type)], '')?.toLowerCase();
    switch (f) {
      case 'ascending': return prev?.localeCompare(next, undefined, { numeric: true, sensitivity: 'base' });
      case 'descending':
      default: return next.localeCompare(prev, undefined, { numeric: true, sensitivity: 'base' });
    }
  };

  const sanitizeRegex = text => text?.replace(/[*+?()\[\]\s]/g, '\\$&');

  const filtered = (category) => {
    switch (type) {
      case 'apt':
      case 'malware': return data
        ?.filter?.(v => v.get('is_published') || prm.some(p => /org\.fp\.r/ig.test(p)))
        ?.filter?.(v => archivedFilter(v, filters.active))
        ?.filter?.(v => (filters?.data?.length ? filters.data.includes(v) : v))
        ?.filter?.(v =>
        // eslint-disable-next-line security/detect-non-literal-regexp
          new RegExp(sanitizeRegex(filters.text) || '', 'ig').test(v.get(titles[String(type)])) ||
        // eslint-disable-next-line security/detect-non-literal-regexp
          new RegExp(sanitizeRegex(filters.text) || '', 'ig').test(v.get(bodies[String(type)])))
        ?.sort?.((a, b) => data && type && sortFilter(a, b, filters.sort));
      default: return data
        ?.filter(v => v?.get('is_published') || prm?.some(p => /org\.fp\.r/ig.test(p)))
        ?.filter(v => (['trending_now'].includes(category)
          ? Boolean(v.get('is_trending'))
          : v.get('categories').includes(category)))
        ?.filter(v =>
          // eslint-disable-next-line security/detect-non-literal-regexp
          new RegExp(sanitizeRegex(filters.text) || '', 'ig').test(v.get(titles[String(type)])) ||
            // eslint-disable-next-line security/detect-non-literal-regexp
          new RegExp(sanitizeRegex(filters.text) || '', 'ig').test(v.get(bodies[String(type)])))
          ?.filter?.((v) => {
            if (v.get('categories').includes('private_topic')) {
              return v?.get('organizations')?.includes(filters?.organization);
            }
            return true;
        })
        ?.sortBy(v => data && type &&
          v?.get('is_published') &&
          (v.get(sorts[String(type)], '').toLowerCase() ||
            -moment.utc(v.getIn(['updated_at', 'date-time'])).unix()));
      }
    };// By default, function filters topics

  const onEdit = (event, id = uuidv4()) => {
    event.preventDefault();
    event.stopPropagation();
    History.navigateTo({
      pathname: `/home/wiki/${type}/${id}/edit`,
    });
  };

  const onFilter = (filter = {}) => {
    if (filter.organization && allOrgs) {
      setUserOrg({
        label: allOrgs.find(v => (v.salesforce_id === filter.organization)).name,
        value: filter.organization,
      });
    }
    setFilters({ ...filters, ...filter });
  };

  const onFilterSelector = (event) => {
    const selected = event.target.value;
    setFilters({ ...filters, data: typeof value === 'string' ? selected.split(',') : selected });
  };

  const onLoad = () => {
    // restrict topics based on perm/roles
    const restrictCVEs = entries => (!prm.some(p => /(vln|cve).r/.test(p))
      ? entries.filter(v => !v?.categories?.some(c => /cve/ig.test(c)))
      : entries);

    Query
      .Load(type)
      .then(res => restrictCVEs(res?.data))
      .then(res => setData(fromJS(res)))
      .catch(err => setError(err.message));
  };

  const onRoute = (entry) => {
    switch (type) {
      case 'apt':
      case 'malware':
        return { pathname: `/home/wiki/${type}/${encodeURIComponent(entry.get('fpid'))}` };
      case 'topics': {
        const pathname = entry
          ?.get('queries', list())
          ?.find(v => v.get('name') === 'search', map())
          ?.get('query', '')
          ?.split('.tools')?.[1];
        const params = qs.stringify({
          ...entry.get('is_org_pattern')
            ? { status: 'topic_is_org_pattern' }
            : {},
        });
        return `${pathname}&${params}`;
      }
      default:
        return {};
    }
  };

  const onRemove = (id = '') => {
    const payload = data.filter(v => v.get('fpid') !== id || fpid);
    return Query.Save(type, { data: payload.toJS() })
      .then(() => setData(payload))
      .then(() => setDialog())
      .then(() => History.navigateTo(search[String(type)]));
  };

  const onSave = (patch) => {
    const index = data.findIndex(v => v.get('fpid') === fpid);
    const payload = index < 0
      ? data.push(patch)
      : data.update(index, v => v.merge(patch));

    return Query.Save(type, { data: payload.toJS() })
      .then(() => setData(payload))
      .then(() => History.navigateTo(search[String(type)]));
  };

  useEffect(() => {
    const skip = 0;
    setData();
    if (['apt'].includes(type)) setFilters(aptFilters);
    if (['malware'].includes(type)) setFilters(malwareFilters);
    if (['topics'].includes(type)) setFilters(topicFilters);
    onLoad(type, skip);
  }, [type]);

  useEffect(() => {
    if (!data || data.isEmpty()) return;
    Query.Organizations()
      .then(res => ((res.length > 0) ? res : [{ name: orgName, salesforce_id: orgId }]))
      .then((res) => {
        const orgIds = [];
        data
          .filter(v => !!v.get('is_private_topic'))
          .forEach(v => (v.get('organizations').forEach((v2) => {
            if (!orgIds.includes(v2)) {
              orgIds.push(v2);
            }
          })));
        const visOrgs = orgIds
          .map(v => ({
            label: res?.find(v2 => v2.salesforce_id === v)?.name,
            value: v,
          }))
          .sort((a, b) => (a?.label?.localeCompare(b?.label)));
        const org = (visOrgs.length > 0)
          ? visOrgs?.find(v => v.value === orgId) || visOrgs[0]
          : { label: orgName, value: orgId };

        setVisibleOrgs(visOrgs);
        setAllOrgs(res);
        setUserOrg(org);
        setFilters({
          ...filters,
          organization: org.value,
        });
      });
  }, [data]);

  return (
    <Grid fluid className={cx([style.wiki, classes.wiki])}>
      {error?.includes('403') && <Invalid icon="error_outline" denied />}
      {!data && !error && <CircularProgress />}
      {data && !fpid && /* header */
      <Row>
        <Col xs={12} className={style.controls}>
          <Fab
            color="secondary"
            size="small"
            name="wiki.header.add"
            onClick={onEdit}
            style={{ visibility: prm.some(p => /dat.rep.w/.test(p)) ? '' : 'hidden', minWidth: '40px' }}>
            <Icon>add</Icon>
          </Fab>
          <div className={style.filter}>
            {type === 'topics'
            && subtype === 'private_topic'
            && prm.some(p => /dat.rep.w/.test(p))
            && (visibleOrgs?.length > 0) &&
            <TextFilter
              fields={[
              { type: 'dropdown', value: 'organization', label: 'Organization', className: style.filterDropdown, key: 'name', opts: fromJS(visibleOrgs || []) },
              ]}
              className={style.textFilter}
              filters={fromJS(filters)}
              onFilter={onFilter}
              stacked
            />}
            {['apt', 'malware'].includes(type) &&
            <TextFilter
              fields={[
                { type: 'dropdown', value: 'sort', className: style.filterDropdown, key: 'value', opts: fromJS(sortOptions) },
                { type: 'dropdown', value: 'active', className: style.filterDropdown, key: 'value', opts: fromJS(archivedOptions) },
              ]}
              className={style.textFilter}
              filters={fromJS(filters)}
              onFilter={onFilter}
              stacked
            />}
            {['malware'].includes(type) && filters?.name === 'malware' &&
            <FormControl
              fullWidth={false}
              className={style.multiselect}>
              <Select
                multiple
                value={filters.data}
                onChange={e => onFilterSelector(e)}
                input={<OutlinedInput placeholder="Malware Family"/>}
                renderValue={(selected) => {
                  if (selected.length === 0) return <div>Malware Family</div>;
                  return selected?.map(v => v?.get('malware_family_name')).join(', ');
                }}
                className={style.select}>
                {data.sortBy(v => v?.get('malware_family_name')).map(v => (
                  <MenuItem key={v.get('fpid')} value={v}>
                    <Checkbox checked={filters?.data?.indexOf(v) > -1}/>
                    <ListItemText primary={v.get('malware_family_name')} />
                  </MenuItem>))}
              </Select>
            </FormControl>}
            {['apt'].includes(type) && filters?.name === 'apt' &&
            <FormControl
              fullWidth={false}
              className={style.multiselect}>
              <Select
                multiple
                value={filters.data}
                onChange={e => onFilterSelector(e)}
                input={<OutlinedInput placeholder="APT Group"/>}
                renderValue={(selected) => {
                  if (selected.length === 0) return <div>APT Group</div>;
                  return selected?.map(v => v?.get('apt_group')).join(', ');
                }}
                className={style.select}>
                {data.sortBy(v => v?.get('apt_group')).map(v => (
                  <MenuItem key={v.get('fpid')} value={v}>
                    <Checkbox checked={filters?.data?.indexOf(v) > -1}/>
                    <ListItemText primary={v.get('apt_group')} />
                  </MenuItem>))}
              </Select>
            </FormControl>}
            <FormControl
              fullWidth={false}
              className={style.formControl}
              variant="outlined">
              <OutlinedInput
                notched
                data-lpignore="true"
                value={filters.text}
                placeholder="Quick Filter..."
                onChange={event => onFilter({
                text: event.target.value.replace(String.fromCharCode(92), ''),
              })}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon color="primary">search</Icon>
                  </InputAdornment>
              )}
                endAdornment={filters.text && (
                <InputAdornment position="end">
                  <Icon
                    color="primary"
                    onClick={() => onFilter({ text: '' })}>
                    close
                  </Icon>
                </InputAdornment>
              )} />
              <FormHelperText />
            </FormControl>
          </div>
        </Col>
      </Row>}
      {data && !fpid && userOrg && categories
      .map(category => (/* search */
        <Row key={category}>
          <Col xs={12} className={style.grid}>
            {filtered(category).isEmpty() &&
            <div className={style.empty}>
              <Invalid
                title={`No ${type} entries found.`}
                help={['Update or clear your filters.']} />
            </div>}
            {filtered(category)
              ?.map?.(entry => (
                <div
                  key={entry.get('fpid')}
                  className={style.card}>
                  <Card
                    mini
                    className={classes.topicCard}
                    key={entry.get('fpid')}
                    icon={icons[String(type)]}
                    onRoute={onRoute(entry)}
                    report={fromJS({
                      tags: [],
                      archived: true,
                      body: entry.getIn(['body', 'raw']),
                      title: entry.get(titles[String(type)]),
                      category: ['topics'].includes(type)
                        ? `${entry.get('categories')
                          .join()
                          .replace('private_topic', `${userOrg.label} Explore Topics`)} ${entry.get('is_trending')
                            ? ' · Trending'
                            : ''}`
                        : '',
                      version_posted_at: entry.getIn(['published_at', 'date-time']),
                    })}
                    statusbar={
                      <div className={style.footer}>
                        <div>
                          {entry.getIn(['queries', 0, 'hits'], 0)
                            ? `${Text.AbbreviateNumber(entry.getIn(['queries', 0, 'hits']))} hits`
                            : ''}
                        </div>
                        {prm.some(p => /dat.rep.w/.test(p)) &&
                        prm.some(p => /org.fp.r/.test(p)) &&
                        <div>
                          <Icon
                            data-for="global.tooltip"
                            data-testid="wiki.topics.info"
                            data-tip={topicEditor(entry)}>
                            info_outline
                          </Icon>
                          {!entry.get('is_published') &&
                          <Icon>visibility_off</Icon>}
                          <Icon
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setDialog({ target: 'remove', value: entry.get('fpid') });
                            }}
                            color="error">
                            delete
                          </Icon>
                          <Icon
                            onClick={event => onEdit(event, entry.get('fpid'))}
                            color="primary">
                            edit
                          </Icon>
                        </div>}
                      </div>} />
                </div>))}
          </Col>
        </Row>))}
      {['apt', 'malware'].includes(type) && data && fpid && /* add/edit/view */
      <Malware
        source={data.find(v => v.get('fpid') === fpid, null, map({ fpid }))}
        category={type}
        save={onSave}
        prm={prm} />}
      {['topics'].includes(type) && data && fpid && /* add/edit/view */
      <Topic
        categories={fromJS(data
          ?.map?.(v => v.get('categories', list()))
          ?.reduce?.((a, b) => [...new Set([...a, ...b])], []))}
        source={data.find(v => v?.get('fpid') === fpid, null, map({ fpid }))}
        organizations={allOrgs}
        remove={onRemove}
        save={onSave} />}
      <Prompt
        open={Boolean(dialog?.target === 'remove')}
        title="Warning: Delete Entry"
        acceptText="Continue"
        accept={() => onRemove(dialog?.value)}
        cancelText="Cancel"
        cancel={() => setDialog()}>
        You are about to permanently delete this entry. Are you sure?
      </Prompt>
    </Grid>
  );
};

Wiki.propTypes = {
  fpid: PropTypes.string,
  orgName: PropTypes.string,
  orgId: PropTypes.string,
  prm: PropTypes.object,
  type: PropTypes.string,
  subtype: PropTypes.string,
};

Wiki.defaultProps = {
  fpid: '',
  orgName: '',
  orgId: '',
  prm: list(),
  type: '',
  subtype: '',
};

export default Wiki;
