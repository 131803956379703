import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@mui/material/Grid';

import GridSizes from '../../../../constants/Grid';

import useStyles from './style';

const RowText = ({ title, data }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.row}>
      <Grid
        item
        xs={GridSizes.sm}
        md={GridSizes.md}
        lg={GridSizes.lg}
        className={classes.subHeading}>
        {title}:
      </Grid>
      <Grid item>{data}</Grid>
    </Grid>
  );
};

RowText.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any.isRequired,
};

RowText.defaultProps = {
  title: '',
};

export default RowText;

