import density from './density';
import palette from './palette';
import props from './props';
import typography from './typography';
import overrides from './overrides';

const type = 'dark';

export default {
  density,
  palette,
  typography,
  overrides,
  props,
  type,
};
