type RawBucketTuple = {
  doc_count: number,
  key: string,
};

type DistinceTermsBucketItem = {
  doc_count: number,
  key: string,
  'distinct-terms': {
    buckets: RawBucketTuple[],
  }
};

export type AnnotatedBucketItem = {
  doc_count: number,
  key: string,
  field?: string,
  site_type?: string,
};

type DateHistogramCardinalityTuple = {
  date_histogram_cardinality: {
    value: number,
  },
  key: string,
}

type AggregationsRes = {
  actor: {
    buckets: RawBucketTuple[],
  },
  source: {
    buckets: RawBucketTuple[],
  },
  'date-histogram': {
    buckets: RawBucketTuple[]
  }
  'date-histogram-cardinality': {
    buckets: DateHistogramCardinalityTuple[]
  },
  'terms-by-terms': {
    buckets: RawBucketTuple[],
  },
};

type AggregationsCurrentRes = {
  hits: {
    total: number
  },
  aggregations: AggregationsRes,
}

const addPlatformToSourceBuckets = (aggregations: AggregationsRes):AnnotatedBucketItem[] => (
  aggregations?.['terms-by-terms'] ?
  aggregations['terms-by-terms'].buckets
    .map((rawBucketTuple: DistinceTermsBucketItem) => ({
      doc_count: rawBucketTuple.doc_count,
      key: rawBucketTuple.key,
      site_type: rawBucketTuple['distinct-terms'].buckets[0]?.key,
    }))
  : []
);

const makeSourceBuckets = (aggregations: AggregationsRes):AnnotatedBucketItem[] => {
  const sourceBuckets = aggregations?.source?.buckets;
  if (!sourceBuckets) return sourceBuckets;
  const bucketsWithPlatform = addPlatformToSourceBuckets(aggregations);
  return bucketsWithPlatform.concat(sourceBuckets.slice(bucketsWithPlatform.length))
    .map((rawBucketTuple: RawBucketTuple) => ({ ...rawBucketTuple, ...{ field: 'container.title.keyword' } }));
};

const makeActorBuckets = (aggregations: AggregationsRes):AnnotatedBucketItem[] => (
  aggregations?.actor?.buckets
  .map((rawBucketTuple:RawBucketTuple) => ({ ...rawBucketTuple, ...{ field: 'site_actor.names.handle.keyword' } }))
);

const include = (value:{key: string}):boolean => !/anonymous|guest|flashpoint/ig.test(value?.key);

export const communityMentionsReturnValue = ({
  keyword,
  label,
  current,
  last,
}:{
  keyword: string,
  label: string,
  current: AggregationsCurrentRes,
  last: AggregationsCurrentRes,
}) => {
  const currentActorBuckets = makeActorBuckets(current?.aggregations);
  const lastActorBuckets = makeActorBuckets(last?.aggregations);
  const currentSourceBuckets = makeSourceBuckets(current?.aggregations);
  const lastSourceBuckets = makeSourceBuckets(last?.aggregations);

  return ({
    organization: keyword,
    mentions: {
      timePeriod: label,
      total: {
        now: current?.hits?.total,
        previous: last?.hits?.total,
      },
      chart: current?.aggregations?.['date-histogram']?.buckets?.map(v => ({
        count: v?.doc_count,
        date: v.key,
      })),
    },
    actors: {
      timePeriod: label,
      name: {
        now: currentActorBuckets?.find(include)?.key,
        previousRaw: lastActorBuckets?.find(include)?.key,
        previous: (currentActorBuckets?.find(include)?.key ===
          lastActorBuckets?.find(include)?.key)
          ? `Same As ${label}`
          : `${label}: ${lastActorBuckets?.find(include)?.key}`,
      },
      total: {
        now: currentActorBuckets?.length,
        previous: lastActorBuckets?.length,
      },
      chart: current?.aggregations?.['date-histogram-cardinality']?.buckets?.map(v => ({
        count: v?.date_histogram_cardinality?.value,
        date: v.key,
      })),
      currentBuckets: currentActorBuckets?.filter(v => include(v)),
      previousBuckets: lastActorBuckets?.filter(v => include(v)),
    },
    sources: {
      name: {
        now: currentSourceBuckets?.[0]?.key,
        previousRaw: lastSourceBuckets?.[0]?.key,
        previous: (currentSourceBuckets?.[0]?.key ===
            lastSourceBuckets?.[0]?.key)
          ? `Same As ${label}`
          : `${label}: ${lastSourceBuckets?.[0]?.key}`,
      },
      currentBuckets: currentSourceBuckets?.filter(v => include(v)),
      previousBuckets: lastSourceBuckets?.filter(v => include(v)),
    },
  });
};

export type CommunityMentionsReturnType = ReturnType<typeof communityMentionsReturnValue>;
