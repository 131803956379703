import PropTypes from 'prop-types';
import React, { useState } from 'react';

import moment from 'moment';

import { fromJS, Map as map } from 'immutable';
import {
  Divider,
  Icon,
  ListItem,
  ListSubheader,
  Popover,
} from '@mui/material';

import UserActions from '../../actions/userActions';
import Common from '../../utils/common';
import SaveSearchDialog from './SaveSearchDialog';
import Apps from '../../constants/Apps';
import History from '../../utils/history';
import style from './saveSearchIcon.module.scss';

const SaveSearchIcon = (props) => {
  const { alerting, filters, saved, type } = props;

  const [dialogOpen, setDialogOpen] = useState();
  const [menuOpen, setMenuOpen] = useState();

  function onSave() {
    const { hash, search, query } = History.getCurrentLocation();
    const id = +hash.split(':').pop();
    const index = saved.get('data').findIndex(v => v.get('created') === id);
    const update = saved.mergeIn(['data', index], map({
      value: query.query || '',
      filters: search.replace(/query=.*?(&|$)|transformed=.*?(&|$)/ig, '') || '',
      updated: +moment.utc().unix() }));
    UserActions.saveSearch(update, saved.getIn(['data', index, 'name']));
  }

  return (
    <div className={style.save}>
      <Icon
        data-for="global.tooltip"
        data-tip="Save active search"
        onClick={event => setMenuOpen(event.target)}>
        save
      </Icon>
      <Popover
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen()}
        onClick={() => setMenuOpen()}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
        <ListItem
          onClick={event => ((window.location.hash.split(':')[0] === '#save') ? onSave() : setDialogOpen(event.target))}>
          Save
        </ListItem>
        {window.location.hash.split(':')[0] === '#save' &&
        <ListItem
          onClick={event => setDialogOpen(event.target)}>
          Save As...
        </ListItem>}
        {saved.get('data') && saved.get('data').filter(v => (!alerting ? v.getIn(['type', 'searchType']) === type : v.getIn(['type', 'value']) === 'alerting.alerts')).count() > 0 &&
        <React.Fragment>
          <Divider style={{ height: '2px' }} />
          <ListSubheader style={{ fontSize: '12px', lineHeight: 'inherit' }}>Related Searches</ListSubheader>
          {saved.get('data').filter(v => (!alerting ? v.getIn(['type', 'searchType']) === type : v.getIn(['type', 'value']) === 'alerting.alerts')).map(v => (
            <ListItem
              key={v.get('created')}
              onClick={() => { setMenuOpen(); Common.SavedSearch.Route(v); }}>
              {v.get('name')}
            </ListItem>
          ))}
        </React.Fragment>}
      </Popover>
      <SaveSearchDialog
        apps={fromJS(Apps)}
        alerting={alerting}
        filters={filters}
        onClose={() => setDialogOpen(false)}
        open={Boolean(dialogOpen)}
        saved={saved}
        type={type} />
    </div>
  );
};

SaveSearchIcon.propTypes = {
  alerting: PropTypes.bool,
  filters: PropTypes.object,
  saved: PropTypes.object,
  type: PropTypes.string,
};

SaveSearchIcon.defaultProps = {
  alerting: false,
  filters: map(),
  saved: map(),
  type: '',
};

export default SaveSearchIcon;
