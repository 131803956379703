import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  OutlinedInput,
  Radio,
  RadioGroup,
} from '@mui/material';

import style from './ccmc.module.scss';
import CCMcWebhookUtils from './CCMcWebhookUtils';

const CCMcWebhook = ({
  setResponse,
}) => {
  const [errorText, setErrorText] = useState(map());
  const [values, setValues] = useState(fromJS({ batch_size: 1, events: ['compromised_credential'], hash_response: [], hash_algorithm: 'sha256' }));

  const onChange = (k, v) => {
    setValues(vals => vals.set(k, v));
    if (k === 'batch_size') {
      if (!v || v < 1 || v > 1000) {
        const error = 'Invalid. Must be between 1 and 1000';
        setErrorText(errorText.set(k, error));
      } else {
        setErrorText(errorText.set(k, ''));
      }
    }
  };

  const onCheck = (type, key, value) => {
    const current = [...values.get(type).toJS()];
    if (value) current.push(key);
    else current.splice(current.indexOf(key), 1);
    setValues(vals => vals.set(type, fromJS(current)));

    if (type === 'events') {
      const error = (current.length === 0) ? 'Must have one event' : '';
      setErrorText(errorText.set(type, error));
    }
  };

  const onSearch = (page) => {
    const curPage = page || 0;
    const response = {
      num_results: values.get('events').size,
      results: [],
    };
    const headers = [];
    if (values.get('events', []).includes('compromised_credential')) {
      const creds = CCMcWebhookUtils.getCompromisedCredentialEvents(values.toJS(), curPage);
      response.results.push({ key: 'compromised_credential', results: creds });
      headers.push((
        <React.Fragment>
          <Row middle="xs">
            <Col xs={3}>
              <span className={cx([style.label])}>Event Type:</span>
            </Col>
            <Col xs={9}>
              Compromised Credential
            </Col>
          </Row>
          <Row middle="xs">
            <div className={cx([style['button-container']])}>
              <Button
                color="secondary"
                variant="contained"
                name="search"
                className={cx([style.mont, 'mont', style.button])}
                disabled={errorText.some(v => v)}
                onClick={() => onSearch(curPage + 1)}>
                Send Another Test Event
              </Button>
            </div>
          </Row>
        </React.Fragment>
      ));
    }
    if (values.get('events', []).includes('load_status')) {
      const load = CCMcWebhookUtils.generateLoadStatusEvent();
      response.results.push({ key: 'load_status', results: load });
      headers.push((
        <React.Fragment>
          <Row middle="xs">
            <Col xs={3}>
              <span className={cx([style.label])}>Event Type:</span>
            </Col>
            <Col xs={9}>
              Load Status
            </Col>
          </Row>
        </React.Fragment>
      ));
    }
    setResponse(fromJS(response), 'webhook', headers);
  };

  return (
    <React.Fragment>
      <Row middle="xs" className={cx([style.row, style.horizontal])}>
        <Col xs={3}>
          <span className={cx([style.label])}>Events:</span>
        </Col>
        <Col xs={9} className={style.horizontal}>
          <FormGroup row>
            <FormControlLabel
              label="Credential Alert"
              control={<Checkbox
                checked={values.get('events', list()).includes('compromised_credential')}
                onChange={event => onCheck('events', 'compromised_credential', event.target.checked)} />} />
            <FormControlLabel
              label="Customer Replacement"
              control={<Checkbox
                checked={values.get('events', list()).includes('load_status')}
                onChange={event => onCheck('events', 'load_status', event.target.checked)} />} />
          </FormGroup>
        </Col>
        {errorText.get('events') &&
        <Col xs={12} className={style.horizontal}>
          <Col xs={3} />
          <Col xs={9}>
            <div className={style.error}>
              {errorText.get('events')}
            </div>
          </Col>
        </Col>}
      </Row>
      <Row middle="xs" className={style.row}>
        <Col xs={3}>
          <span className={cx([style.label])}>Batch Size:</span>
        </Col>
        <Col xs={9} className={style.text}>
          <FormControl variant="outlined">
            <OutlinedInput
              fullWidth
              name="batch-size"
              data-lpignore="true"
              error={Boolean(errorText.get('batch_size'))}
              value={values.get('batch_size', '')}
              disabled={!values.get('events', list()).includes('compromised_credential')}
              type="number"
              min="1"
              max="1000"
              onChange={event => onChange('batch_size', parseInt(event.target.value, 10))} />
            <FormHelperText>
              {errorText.get('batch_size')}
            </FormHelperText>
          </FormControl>
        </Col>
      </Row>
      <Row middle="xs" className={cx([style.row, style.horizontal])}>
        <Col xs={3}>
          <span className={cx([style.label])}>Hashed Response:</span>
        </Col>
        <Col xs={9} className={style.horizontal}>
          <FormGroup row>
            <FormControlLabel
              label="Username"
              control={<Checkbox
                disabled={!values.get('events', []).includes('compromised_credential')}
                checked={values.get('hash_response', []).includes('username')}
                onChange={event => onCheck('hash_response', 'username', event.target.checked)} />} />
            <FormControlLabel
              label="Password"
              control={<Checkbox
                disabled={!values.get('events', []).includes('compromised_credential')}
                checked={values.get('hash_response', []).includes('password')}
                onChange={event => onCheck('hash_response', 'password', event.target.checked)} />} />
          </FormGroup>
        </Col>
      </Row>
      {values.get('hash_response') && values.get('hash_response').size > 0 &&
      <Row middle="xs" className={cx([style.row])}>
        <Col xs={3}>
          <span className={cx([style.label])}>Hashed Response Algorithm:</span>
        </Col>
        <Col xs={9}>
          <FormControl>
            <RadioGroup
              name="hash-algorithm"
              disabled={!values.get('events', list()).includes('compromised_credential')}
              value={values.get('hash_algorithm', 'sha256')}
              onChange={event => onChange('hash_algorithm', event.target.value)}>
              <FormControlLabel
                label="SHA-1"
                value="sha1"
                control={<Radio />} />
              <FormControlLabel
                label="SHA-256"
                value="sha256"
                control={<Radio />} />
              <FormControlLabel
                label="MD-5"
                value="md5"
                control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>}
      <div className={cx([style['button-container']])}>
        <Button
          color="secondary"
          variant="contained"
          name="search"
          className={cx([style.mont, 'mont', style.button])}
          disabled={errorText.some(v => v)}
          onClick={() => onSearch()}>
          Send Test Event
        </Button>
      </div>
    </React.Fragment>
  );
};

CCMcWebhook.propTypes = {
  setResponse: PropTypes.func,
};

CCMcWebhook.defaultProps = {
  setResponse: null,
};

export default CCMcWebhook;
