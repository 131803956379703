import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { escape } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Bookmark,
  BookmarkBorder,
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Icon,
  InputAdornment,
  ListItem,
  OutlinedInput,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import style from './searches.module.scss';
import Editor from './Editor';
import Pager from '../utils/Pager';
import Invalid from '../utils/Invalid/Invalid';
import InternalLink from '../utils/InternalLink';
import Text from '../../utils/text';
import History from '../../utils/history';
import Common from '../../utils/common';
import UserActions from '../../actions/userActions';

export const QuickFilter = ({ filters, onFilter }) => (
  <div className={style.form}>
    <FormControl variant="outlined" name="text">
      <OutlinedInput
        placeholder="Quick filter..."
        value={filters.get('text', '')}
        onChange={event => onFilter(
                    filters.setIn(['text'],
                    event.target.value.replace(String.fromCharCode(92), ''),
                  ))}
        startAdornment={(
          <InputAdornment position="start">
            <Icon>
              search
            </Icon>
          </InputAdornment>)}
        endAdornment={filters.get('text', '') && (
        <InputAdornment position="end">
          <Icon
            color="primary"
            onClick={() => onFilter(filters.setIn(['text'], ''))}>
            close
          </Icon>
        </InputAdornment>
                  )} />
    </FormControl>
  </div>
);

QuickFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
};

const Searches = ({
  apps,
  data,
  location,
  type,
}) => {
  const [dialog, setDialog] = useState();
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState(map());
  const [filtered, setFiltered] = useState(list());
  const [limited, setLimited] = useState(map());

  const onFilter = (values) => {
    const text = values.get('text') || '';
    const pinned = values.get('pinned');
    const source = data.get('data');
    // eslint-disable-next-line security/detect-non-literal-regexp
    const test = new RegExp(text, 'i');
    const filteredValues = source
      .filter(v => ['cat', 'name', 'value'].some(t => test.test(v.get(t))))
      .filter(v => (pinned === true ? v.get('pin') : v))
      .filter(v => (pinned === false ? !v.get('pin') : v));
    setFilters(values);
    setFiltered(data.set('data', filteredValues));
  };

  const onDelete = () => {
    const source = data.get('data');
    const deletions = source.filterNot(v => selected.includes(v.get('created')));
    const update = map({ total: deletions.size, data: deletions });
    UserActions.saveSearch(update);
  };

  const onSave = (_, values) => {
    const source = data.get('data');
    const now = +moment.utc().unix();
    const updates = values
      .map(v => v
        .set('name', escape(v.get('name')))
        .set('cat', escape(v.get('cat')))
        .set('updated', +now)
        .set('created', v.get('created') || +now)
        .set('value', Text.StripHtml(v.get('value').replace(/[,]/gm, ' '))));
    const search = map({
      total: !selected.length ? source.size + 1 : source.size,
      data: !selected.length ? source.concat(updates) :
        source.map(v => (selected.includes(v.get('created')) ?
          updates.find(s => s.get('created') === v.get('created')) : v)),
    });
    UserActions.saveSearch(search);
    setDialog();
    setSelected([]);
  };

  const onSelect = (value) => {
    const source = filtered.get('data');
    switch (value) {
      case 'pinned':
        setSelected(source
          .filter(v => v.get('pin'))
          .map(v => v.get('created')).toJS());
        break;
      case 'not_pinned':
        setSelected(source
          .filterNot(v => v.get('pin'))
          .map(v => v.get('created')).toJS());
        break;
      case 'all':
        if (selected.length === filtered.get('data').count()) return;
        setSelected(selected.length !== filtered.get('data').count() ?
          source.map(v => v.get('created')).toJS() :
          []);
        break;
      case 'none':
        setSelected([]);
        break;
      default:
        setSelected(selected.includes(value) ?
          selected.filter(v => v !== value) :
          selected.concat(value));
        break;
    }
  };

  const onPin = (id) => {
    const index = data
      .get('data')
      .findIndex(v => v.get('created') === +id);
    const pin = data.getIn(['data', index, 'pin']);
    const update = data.setIn(['data', index, 'pin'], !pin);
    UserActions.saveSearch(update);
  };

  const onReset = () => {
    setSelected([]);
    onFilter(map());
  };

  useEffect(() => {
    setDialog();
    setFilters(map());
  }, [type]);

  useEffect(() => {
    onFilter(filters);
  }, [data]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    onFilter(map({ ...filters.toJS(), ...query }));
    setDialog({ key: query.edit ? 'editor' : '' });
  }, [location]);

  useEffect(() => {
    if (!filtered.has('total')) return;
    const limit = filters.get('limit') || '50';
    const skip = filters.get('skip') || '0';
    const limitedValue = (filtered.get('data') || list())
      .slice(parseInt(skip, 10), parseInt(skip, 10) + parseInt(limit, 10));
    setLimited(filtered.set('data', limitedValue));
  }, [filters, filtered]);


  const columns = () => {
    switch (type) {
      case 'searches':
      default:
        return [
          { id: 'name',
            label: 'Search Name',
            text: 'Label for the search',
            render: v => v.get('name') },
          { id: 'type',
            label: 'Source',
            text: 'Data source associated with query',
            render: v => Text.Highlight(v.getIn(['type', 'label'])) },
          { id: 'cat',
            label: 'Category',
            text: 'Categorization for the saved search',
            render: v => v.get('cat') },
          { id: 'value',
            label: 'Query',
            text: 'Search string',
            render: v => v.get('value') },
          { id: 'updated',
            label: 'Last Edited (UTC)',
            text: 'Date when search was updated',
            render: (v) => {
              const timestamp = v.get('updated');
              if (moment.utc(timestamp).year() !== 1970) {
                return (
                  <span>
                    {moment.utc(timestamp).format('MMM D, YYYY')}
                    <small>{` ${moment.utc(timestamp).format('HH:mm')}`}</small>
                  </span>
                );
              }
              return (
                <span>
                  {moment.unix(timestamp).utc().format('MMM D, YYYY')}
                  <small>{` ${moment.unix(timestamp).utc().format('HH:mm')}`}</small>
                </span>
              );
            },
          },
        ];
    }
  };

  const control = () => {
    const size = selected.length;
    return size === 0
      ? 'Add New +'
      : `Edit Search${size > 1
        ? `es (${size})`
        : ''}`;
  };

  const options = () => [{
    id: 'search.editor',
    type: ['searches'],
    dialog: (
      <Editor
        fields={[
          { value: 'name',
            label: 'Search Name',
            type: 'text',
            req: true },
          { value: 'cat',
            label: 'Input Custom Search Category',
            type: 'text',
            bulk: true,
          },
          { value: 'type',
            label: 'Source',
            type: 'dropdown',
            bulk: true,
            key: 'value',
            valueKey: 'value',
            options: apps
              .filter(v => !v.get('hidden'))
              .filter(v => v.get('value').includes('search')),
          },
          { value: 'value',
            label: 'Query',
            type: 'textarea',
           },
          { value: 'filtered',
            label: 'Include filters',
            type: 'checkbox',
            name: 'searches.filtered',
            help: `*Note: date filters, once applied, will be fixed in
              accordance with the date on which the search was saved.`,
            bulk: true,
           },
          { value: 'pin',
            label: 'Pin search',
            name: 'searches.pin',
            checked: <Bookmark />,
            unchecked: <BookmarkBorder />,
            type: 'checkbox',
            bulk: true,
          },
        ]}
        type={type}
        data={((filtered || map()).get('data') || list())
          .filter(v => selected.includes(v.get('created')))}
        save={(typeValue, values) => onSave(typeValue, values)}
        deleteActions={typeValue => onDelete(typeValue)}
        toggle={() => { setDialog(); setSelected([]); }} />
    ),
  }];

  return (
    <Grid
      fluid
      name="component.searches"
      className={cx([style.base, style.search])}>
      {limited.has('total') &&
      <Row>
        <Col xs={12} className={style.filters}>
          {['searches'].includes(type) &&
          <div>
            <div>
              <Button
                className={style.dropdown}
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={e => setDialog({ key: 'select', target: e.currentTarget })}>
                <Icon className={style.icon}>
                  {selected.length > 0 ? 'check_box' : 'crop_square'}
                </Icon>Select
              </Button>
              <Popover
                className="dropdown"
                open={Boolean(dialog && dialog.key === 'select')}
                anchorEl={dialog && dialog.target}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                onClose={() => setDialog()}>
                {['all', 'none', 'pinned', 'not_pinned'].map(v => (
                  <ListItem
                    key={v}
                    className={style.listItem}
                    onClick={() => {
                      onSelect(v);
                      setDialog();
                    }}>
                    {Text.Sentence(v)}
                  </ListItem>))}
              </Popover>
            </div>
            <div>
              <Button
                className={style.dropdown}
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={e => setDialog({ key: 'filter', target: e.currentTarget })}>
                <Icon className={style.icon}>
                  {!filters.filterNot((v, k) => ['skip', 'limit'].includes(k)).isEmpty() ? 'check_box' : 'crop_square'}
                </Icon>Filter
              </Button>
              <Popover
                className="dropdown"
                open={Boolean(dialog && dialog.key === 'filter')}
                anchorEl={dialog && dialog.target}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                onClose={() => setDialog()}>
                {['pinned', 'not_pinned'].map(v => (
                  <ListItem
                    key={v}
                    className={style.listItem}
                    onClick={() => {
                      onFilter(filters.set('pinned', v === 'pinned'));
                      setDialog();
                    }}>
                    {Text.Sentence(v)}
                  </ListItem>))}
              </Popover>
            </div>
            <QuickFilter
              filters={filters}
              onFilter={onFilter}
            />
            <Button
              color="primary"
              className={cx([style.button])}
              onClick={() => onReset()}>Clear All Filters
            </Button>
            <div className={style.header}>
              {!!selected.length &&
              <Button
                color="secondary"
                variant="contained"
                className={cx([style.button, style.edit])}
                onClick={() => setDialog({ key: 'editor' })}>
                {control()}
              </Button>}
            </div>
          </div>}
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <div className={cx([style.card, data.has('total') && data.get('total') && style.loaded])}>
            <div className={style.header}>
              <div>
                {limited.has('total') && limited.get('total') > 0 &&
                <Pager
                  limit
                  paginated
                  filters={filters}
                  data={fromJS({ total: filtered.get('data').count() })}
                  limits={fromJS([
                    { value: 25, label: '25' },
                    { value: 50, label: '50' },
                    { value: 75, label: '75' },
                    { value: 100, label: '100' },
                  ])} />}
              </div>
            </div>
            {!data.has('total') && <CircularProgress />}
            {data.has('total') && data.get('total') === 0 &&
            <div className={style.empty}>
              <Invalid
                inline
                icon="warning"
                classes={['link']}
                title="You haven't saved any searches yet." />
            </div>}
            {data.has('total') && data.get('total') > 0 &&
            <TableContainer className={style.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.length >= limited.get('data', list()).count()}
                        onChange={() => onSelect(selected.length >= limited.get('data', list()).count() ? 'none' : 'all')} />
                    </TableCell>
                    <TableCell padding="checkbox">Pin</TableCell>
                    {columns().map(column => (
                      <TableCell
                        key={column.id}
                        style={column.style}>
                        {column.text &&
                        <div
                          data-for="header.tooltip"
                          data-tip={column.text.match(/(.{1,75})(?:\n|$| )/g).join('<br />')}
                          className={cx([column.sort ? style.sort : null])}>
                          {column.label}
                        </div>}
                        {!column.text && <div>{column.label}</div>}
                      </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {limited.get('data', list())
                  .sortBy(v => -(v.get('updated')))
                  .sortBy(v => -(v.has('pin') && v.get('pin')))
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      key={`${row.get('created')}.${row.get('updated')}`}
                      selected={selected.includes(row.get('created'))}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.includes(row.get('created'))}
                          onChange={() => onSelect(row.get('created'))} />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <FormControl>
                          <FormControlLabel
                            label=""
                            control={<Checkbox
                              name="searches.pin"
                              checkedIcon={<Bookmark />}
                              icon={<BookmarkBorder />}
                              checked={row.get('pin')}
                              onChange={() => onPin(row.get('created'))} />} />
                        </FormControl>
                      </TableCell>
                      {columns().map(column => (
                        <TableCell
                          key={column.id}
                          style={column.style}>
                          <InternalLink
                            to={Common.SavedSearch.Route(row, false)}>
                            {column.render(row, rowIndex)}
                          </InternalLink>
                        </TableCell>))}

                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>}
          </div>
        </Col>
      </Row>
      {options()
        .filter(v => v.type.includes(type))
        .filter(v => v.dialog)
        .map(option => (
          <Dialog
            fullWidth
            key={option.id}
            className={style.dialog}
            open={!!(dialog && dialog.key === 'editor')}
            onClose={() => setDialog()}>
            <DialogTitle>
              {`${!selected.length ? 'Add New Item' : 'Edit Selected'}
                ${selected.length ? `(${selected.length})` : ''}`}
            </DialogTitle>
            <DialogContent>
              {option.dialog}
            </DialogContent>
          </Dialog>))}
      <ReactTooltip id="header.tooltip" html place="top" effect="solid" />
    </Grid>
  );
};

Searches.propTypes = {
  apps: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
  type: PropTypes.string,
};

Searches.defaultProps = {
  apps: list(),
  data: list(),
  location: {},
  type: '',
};

export default Searches;
