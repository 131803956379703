import Token from './token';

export const Track = (event, type = 'Search') => {
  const { pendo } = window;
  const { fpid = '' } = event;
  const uid = Token.get('uid');
  const sid = Token.get('sid');
  const ffs = Token.get('ffs');
  const key = 'X-FP-Search-Click-Resource-FPID';

  sessionStorage?.setItem(key, fpid);
  if (ffs?.some(p => /log.red.qry/.test(p))) return;
  pendo?.track?.(type, { uid, sid, ...event });
};
