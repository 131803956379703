import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import SanctionsSearchResults from '../SanctionsSearchResults';
import ReportTerms from './ReportTerms';
import Token from '../../../utils/token';
import Api from '../../../utils/api';
import Messages from '../../../constants/Messages';
import SearchActions from '../../../actions/searchActions';

const SanctionsReport = (({
  match: {
    params,
  },
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    const { id } = params;
    if (!loading) {
      setLoading(true);
    }
    Api.get(`/ui/v4/sanctions/report/monthly/${id}/report_json`, {}, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        if (res.report_json && res.report_date) {
          const resultList = Object.values(res.report_json.resultList);
          const availableLists = {
            unAvailable: res.report_json.unAvailable,
            ofacAvailable: res.report_json.ofacAvailable,
            euAvailable: res.report_json.euAvailable,
            chainalysisAvailable: res.report_json.chainalysisAvailable,
          };
          setData({
            results: resultList,
            availableLists,
            responseDateTime: res.report_date,
            prevResponseDateTime: res.previous_report_date,
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data: d } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(d?.detail, d));
        setLoading(false);
      });
    return function cleanup() {
      isOnPage = false;
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        {loading && <CircularProgress />}
        <SanctionsSearchResults data={data} isRetro />
      </div>
      <div>
        <ReportTerms id={params.id} />
      </div>
    </React.Fragment>
  );
});

SanctionsReport.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SanctionsReport;
