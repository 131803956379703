import * as React from 'react';
import { useEffect, useState } from 'react';

import { GraphMenu, SavedSearchesMenu, TabForm } from '../TabForm';
import { dedupe } from '../../Widgets/RecentSearchesWidget/RecentSearchesWidget';
import { EditWidgetDialog } from '../EditWidgetDialog';
import { getTabNumber, getTabString } from '../shared/DialogUtils';
import type { SavedSearch } from '../../../../../core/types/SavedSearch';
import { SettingsTab } from '../SettingsTab';
import { TabDatum } from '../../../../../core/interfaces/ExpoDash';
import type { WidgetEntry } from '../../../../../core/types/ExpoDashPreferences';

/** User preferences object */
type ExpoWidgetPref = {
  topActorWidget?: {
    savedSearch: SavedSearch,
  },
  topSourceWidget?: {
    savedSearch: SavedSearch,
  }
};

export type ExpoData = {
  recentSearches: SavedSearch[],
  preferences: {
    expo_dash_widgets: ExpoWidgetPref,
    search: {
      data: SavedSearch[],
    }
  }
}
interface TopRowEditDialogProps {
  open: boolean;
  settings: WidgetEntry,
  selectedTab?: number;
  onCancel: () => void;
  onApply: (userPrefsCandidate: object) => void;
  data: ExpoData;
}

const TabStrings = [
  'result_count',
  'top_source',
  'actor_count',
  'top_actor',
];

export const TopRowEditDialog = ({
  open,
  selectedTab,
  settings,
  onCancel,
  onApply,
  data,
}: TopRowEditDialogProps): JSX.Element => {
  const [tab, setTab] = useState<number>(0);
  const [widgetSettings, setWidgetSettings] = useState<WidgetEntry>({});

  useEffect(() => {
    if (settings) {
      setWidgetSettings({ ...settings });
    }
  }, [settings]);

  const handleSavedSearchChange = (value: SavedSearch) => {
    setWidgetSettings({
      ...widgetSettings,
      savedSearch: value,
      type: getTabString(tab, TabStrings),
    });
  };

  const handleGraphChange = (value: string): void => {
    setWidgetSettings({
      ...widgetSettings,
      graphType: value,
      type: getTabString(tab, TabStrings),
    });
  };

  /*
   * To Add Tab Content
   * Replace the `content` fields in the array below with your React component:
   * ```
   * {
   *   ...
   *   content: <TopSourceTab data={data} />
   * }
   * ```
  */
  const tabData: TabDatum[] = [
    {
      id: 0,
      label: 'Result Count',
      content: (): JSX.Element =>
        <TabForm
          title="Count of Results"
          description="This widget will display a count and trend line of one of your saved searches."
          savedSearch={
            <SavedSearchesMenu
              rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
              savedSearch={widgetSettings?.savedSearch}
              updateSavedSearch={handleSavedSearchChange}
            />
          }
          graph={
            <GraphMenu
              type={widgetSettings?.graphType}
              onChange={handleGraphChange}
            />
          }
        />,
    },
    {
      id: 1,
      label: 'Top Source',
      content: (): JSX.Element =>
        <SettingsTab
          headerText="Top Source"
          explanationText="This widget will highlight the channel seen most frequently in your saved search results."
        >
          <SavedSearchesMenu
            rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
            savedSearch={widgetSettings?.savedSearch}
            updateSavedSearch={handleSavedSearchChange}
          />
        </SettingsTab>,
    },
    {
      id: 2,
      label: 'Actor Count',
      content: (): JSX.Element =>
        <TabForm
          title="Count of Actors Posting"
          description="This widget will display a count and trend line of actors posting about one of your saved searches."
          savedSearch={
            <SavedSearchesMenu
              rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
              savedSearch={widgetSettings?.savedSearch}
              updateSavedSearch={handleSavedSearchChange}
            />
          }
          graph={
            <GraphMenu
              type={widgetSettings?.graphType}
              onChange={handleGraphChange}
            />
          }
        />,
    },
    {
      id: 3,
      label: 'Top Actor',
      content: (): JSX.Element =>
        <SettingsTab
          headerText="Top Actor"
          explanationText="This widget will highlight the actor seen most frequently in your saved search results."
        >
          <SavedSearchesMenu
            rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
            savedSearch={widgetSettings?.savedSearch}
            updateSavedSearch={handleSavedSearchChange}
          />
        </SettingsTab>,
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      setTab(selectedTab);
    }
    if (widgetSettings) {
      const { type } = widgetSettings;
      const openTab = getTabNumber(type, TabStrings);
      setTab(openTab);
    }
  }, [selectedTab, widgetSettings]);

  const handleApply = (_tab: number): void => {
    if (onApply) {
      const t = getTabString(_tab, TabStrings);
      onApply({
        ...widgetSettings,
        type: t,
      });
    }
    onCancel();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  return (
    <EditWidgetDialog
      open={open}
      selectedTab={tab}
      tabData={tabData}
      onApply={handleApply}
      onCancel={handleCancel} />
  );
};

TopRowEditDialog.defaultProps = {
  selectedTab: null,
};
