import React, { useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircleOutline, CloudOff, ErrorOutline, ExpandMore, GetApp } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import style from '../sanctions.module.scss';

const UNResults = ((props) => {
  const [downloading, setDownloading] = useState(false);
  const unRef = useRef(null);

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;
    // attach to body for Firefox
    document.body.appendChild(link);
    // simulate a click
    link.click();
    // cleanup
    document.body.removeChild(link);
  };

  const handleDownload = (ref, filename) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    html2canvas(ref.current, { scale: 2, height: Math.min(ref.current.clientHeight, 8192) })
      .then((canvas) => {
        saveImage(canvas.toDataURL(), filename);
      })
      .then(() => {
        setDownloading(false);
      });
  };

  // All UN entries have an ALIAS attribute (either INDIVIDUAL_ALIAS or ENTITY_ALIAS)
  // Even when there is no known alias,
  // the ALIAS attribute simply contains no data, but still exists
  const isIndividual = (unEntry) => {
    if (unEntry.INDIVIDUAL_ALIAS) {
      return true;
    }
    return false;
  };

  const isEntity = (unEntry) => {
    if (unEntry.ENTITY_ALIAS) {
      return true;
    }
    return false;
  };

  // method to parse the Date of Birth string from a UN DoB entry
  const parseUNDateOfBirth = (unDOBEntry) => {
    let output = '';
    if (unDOBEntry.TYPE_OF_DATE !== 'EXACT') {
      output = `${unDOBEntry.TYPE_OF_DATE} `;
    }
    if (unDOBEntry.NOTE) {
      output = `${output + unDOBEntry.NOTE} `;
    }
    if (unDOBEntry.DATE) {
      return output + unDOBEntry.DATE;
    }
    if (unDOBEntry.YEAR) {
      return output + unDOBEntry.YEAR;
    }
    if (unDOBEntry.FROM_YEAR && unDOBEntry.TO_YEAR) {
      return `${output + unDOBEntry.FROM_YEAR} AND ${unDOBEntry.TO_YEAR}`;
    }
    if (output) {
      return output;
    }
    throw new TypeError('Error while parsing Date of Birth from a UN Entry.');
  };

  // helper method to help determine if a UN section contains all null values
  const isUNSectionEmpty = (unEntry) => {
    if (Array.isArray(unEntry)) {
      return unEntry.every(unObj => unObj === null || Object.values(unObj).every(p => p === null));
    }
    return Object.values(unEntry).every(p => p === null);
  };

  return (
    <Paper ref={unRef} className={style.card}>
      <Accordion data-cy="unAccordion" className={style.resultaccordion}>
        <AccordionSummary
          className={style.accordionsummary}
          expandIcon={<ExpandMore />}
        >
          <div data-cy="unList" className={style.accordionsummarycontent}>
            <div className={style.accordiontitle}>
              <div data-cy="unListTitle" className={style.listtitle}>
                <div className={style.medtitle}>UN List</div>
                {props.listAvailable ?
                  <>
                    {props.result.un.results && props.result.un.results.length !== 0 &&
                      <span className={style.someresults}>
                        <ErrorOutline className={style.resultswarn} />
                        {props.result.un.results.length} Result
                        {props.result.un.results.length > 1 && <span>s</span>}
                      </span>
                    }
                    {props.result.un.results && props.result.un.results.length === 0 &&
                      <span className={style.noresults}>
                        <CheckCircleOutline className={style.noresultscheck} />
                        No Results
                      </span>
                    }
                  </>
                :
                  <>
                    <span className={style.offlineresults}>
                      <CloudOff className={style.offlinecloud} />
                      List Offline
                    </span>
                  </>
                }
              </div>
              {downloading && <CircularProgress />}
              <button
                type="button"
                className={style.resultdownload}
                onClick={(event) => {
                event.stopPropagation();
                handleDownload(unRef, `${props.result.searchTerm}-UN-results.png`);
              }}><GetApp />
              </button>
            </div>
            <div>Term Searched:   {props.result.searchTerm}</div>
            {props.listAvailable &&
              <>
                <div>URL:   <a target="_blank" href={props.result.un.resourceUrl} rel="noreferrer">{props.result.un.resourceUrl}</a></div>
                <div className={style.searchgrid}>
                  <div>
                    Data Source Published Date:   {props.result.un.resourcePublishDate}
                  </div>
                  <div>
                    Total Records Searched:   {props.result.un.totalNumberOfRecordsInDataSource}
                  </div>
                  <div>
                    Date Searched:   {new Date(props.result.un.responseDateTime).toLocaleString()}
                  </div>
                  <div>
                    Data Source Hash Value:   {props.result.un.sha256}
                  </div>
                </div>
              </>
            }
          </div>
        </AccordionSummary>
        <AccordionDetails className={style.accordiondetails}>
          {!props.listAvailable ?
            <>
              <div data-cy="unListOffline" className={style.noresultsmessage}>
                <div className={style.offlineunderline}> </div>
                <div className={style.resulttitle}>List Offline</div>
                <div className={style.lighttext}>Search for{' '}
                  <span className={style.bolded}>"{props.result.searchTerm}"</span>
                  {' '}could not be completed on{' '}
                  {props.result.un.responseDateTime ?
                    new Date(props.result.un.responseDateTime).toLocaleString()
                    : 'Undefined Date'
                  }.
                </div>
              </div>
            </>
          :
            <>
              {props.result.un.results && props.result.un.results.length !== 0 &&
                <div className={style.resultunderline}> </div>
              }
              {props.result.un.results && props.result.un.results.length === 0 &&
                <div className={style.noresultsmessage}>
                  <div className={style.noresultunderline}> </div>
                  <div className={style.resulttitle}>No Results Found</div>
                  <div className={style.lighttext}>Search for <span className={style.bolded}>"{props.result.searchTerm}"</span> returned no results on {props.result.un.responseDateTime ? props.result.un.responseDateTime : 'Undefined Date'}.</div>
                </div>
              }
              {props.result.un.results && props.result.un.results.map((entry, index) => (
                <React.Fragment key={
                    `${entry.REFERENCE_NUMBER && `${entry.REFERENCE_NUMBER}-`}
                    ${entry.FIRST_NAME && `${entry.FIRST_NAME}`},
                    ${entry.SECOND_NAME && `${entry.SECOND_NAME}`},
                    ${entry.THIRD_NAME && `${entry.THIRD_NAME}`},
                    ${entry.FOURTH_NAME && `${entry.FOURTH_NAME}`}`
                  }>
                  <div className={style.resulttitle}>Result #{index + 1}</div>
                  <div className={style.searchsection}>
                    <div className={style.subtitle}>Details</div>
                    <div className={style.detailsgrid}>
                      <div>Type:   {isIndividual(entry) ? 'Individual' : isEntity(entry) && 'Entity'}</div>
                      <div>First Name:   {entry.FIRST_NAME ? entry.FIRST_NAME : ''}</div>
                      <div>Second Name:   {entry.SECOND_NAME ? entry.SECOND_NAME : ''}</div>
                      <div>Third Name:   {entry.THIRD_NAME ? entry.THIRD_NAME : ''}</div>
                      <div>Fourth Name:   {entry.FOURTH_NAME ? entry.FOURTH_NAME : ''}</div>
                      <div>List Type:   {entry.UN_LIST_TYPE ? entry.UN_LIST_TYPE : ''}</div>
                      <ul>Nationality:   {entry.NATIONALITY && entry.NATIONALITY.VALUE && (
                        Array.isArray(entry.NATIONALITY.VALUE) ?
                        entry.NATIONALITY.VALUE.map(item => (
                          item && <li key={item}>{item}</li>
                        ))
                      :
                        entry.NATIONALITY.VALUE && entry.NATIONALITY.VALUE
                      )}
                      </ul>
                      <div>Place of Birth:   {entry.INDIVIDUAL_PLACE_OF_BIRTH && (
                        Array.isArray(entry.INDIVIDUAL_PLACE_OF_BIRTH) ?
                        entry.INDIVIDUAL_PLACE_OF_BIRTH.map(item => (
                          item.COUNTRY && <li key={JSON.stringify(item)}>{item.CITY && `${item.CITY}, `}{item.STATE_PROVINCE && `${item.STATE_PROVINCE}, `}{item.COUNTRY}</li>
                        ))
                      :
                        entry.INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY &&
                        <React.Fragment>
                          {entry.INDIVIDUAL_PLACE_OF_BIRTH.CITY && `${entry.INDIVIDUAL_PLACE_OF_BIRTH.CITY}, `}{entry.INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE && `${entry.INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE}, `}{entry.INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY}
                        </React.Fragment>
                      )}
                      </div>
                      <ul>Date of Birth:   {entry.INDIVIDUAL_DATE_OF_BIRTH && (
                        Array.isArray(entry.INDIVIDUAL_DATE_OF_BIRTH) ?
                        entry.INDIVIDUAL_DATE_OF_BIRTH.map(item => (
                          item.TYPE_OF_DATE &&
                          <li key={parseUNDateOfBirth(item)}>
                            {parseUNDateOfBirth(item)}
                          </li>
                        ))
                      :
                        entry.INDIVIDUAL_DATE_OF_BIRTH.TYPE_OF_DATE &&
                          parseUNDateOfBirth(entry.INDIVIDUAL_DATE_OF_BIRTH)
                      )}
                      </ul>
                      <div>Name in Original Script:   {entry.NAME_ORIGINAL_SCRIPT ? entry.NAME_ORIGINAL_SCRIPT : ''}</div>
                      <div>Reference Number:   {entry.REFERENCE_NUMBER ? entry.REFERENCE_NUMBER : ''}</div>
                    </div>
                  </div>
                  {entry.COMMENTS1 && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Comments</div>
                      <div>{entry.COMMENTS1}</div>
                    </div>
                  )}
                  {entry.INDIVIDUAL_DOCUMENT &&
                  !isUNSectionEmpty(entry.INDIVIDUAL_DOCUMENT) && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Documents</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>City of Issue</TableCell>
                            <TableCell>Issuing Country</TableCell>
                            <TableCell>Date of Issue</TableCell>
                            <TableCell>Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.INDIVIDUAL_DOCUMENT) ?
                              entry.INDIVIDUAL_DOCUMENT.map(item => (
                                <TableRow key={JSON.stringify(item)}>
                                  <TableCell>{item && item.TYPE_OF_DOCUMENT ? `${item.TYPE_OF_DOCUMENT}${item.TYPE_OF_DOCUMENT2 ? ` (${item.TYPE_OF_DOCUMENT2})` : ''}` : ''}</TableCell>
                                  <TableCell>{item && item.NUMBER ? item.NUMBER : ''}</TableCell>
                                  <TableCell>{item && item.CITY_OF_ISSUE ? item.CITY_OF_ISSUE : ''}</TableCell>
                                  <TableCell>{item && item.COUNTRY_OF_ISSUE ? item.COUNTRY_OF_ISSUE : ''}</TableCell>
                                  <TableCell>{item && item.DATE_OF_ISSUE ? item.DATE_OF_ISSUE : ''}</TableCell>
                                  <TableCell>{item && item.NOTE ? item.NOTE : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.TYPE_OF_DOCUMENT ? `${entry.INDIVIDUAL_DOCUMENT.TYPE_OF_DOCUMENT}${entry.INDIVIDUAL_DOCUMENT.TYPE_OF_DOCUMENT2 ? ` (${entry.INDIVIDUAL_DOCUMENT.TYPE_OF_DOCUMENT2})` : ''}` : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.NUMBER ? entry.INDIVIDUAL_DOCUMENT.NUMBER : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.CITY_OF_ISSUE ? entry.INDIVIDUAL_DOCUMENT.CITY_OF_ISSUE : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.COUNTRY_OF_ISSUE ? entry.INDIVIDUAL_DOCUMENT.COUNTRY_OF_ISSUE : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.DATE_OF_ISSUE ? entry.INDIVIDUAL_DOCUMENT.DATE_OF_ISSUE : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_DOCUMENT.NOTE ? entry.INDIVIDUAL_DOCUMENT.NOTE : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.INDIVIDUAL_ALIAS && !isUNSectionEmpty(entry.INDIVIDUAL_ALIAS) && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Aliases</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Quality</TableCell>
                            <TableCell>Note</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.INDIVIDUAL_ALIAS) ?
                              entry.INDIVIDUAL_ALIAS.map(item => (
                                item.ALIAS_NAME &&
                                <TableRow key={item.ALIAS_NAME}>
                                  <TableCell>{item && item.ALIAS_NAME ? item.ALIAS_NAME : ''}</TableCell>
                                  <TableCell>{item && item.QUALITY ? item.QUALITY : ''}</TableCell>
                                  <TableCell>{item && item.NOTE ? item.NOTE : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                            entry.INDIVIDUAL_ALIAS.ALIAS_NAME &&
                              <TableRow>
                                <TableCell>{entry.INDIVIDUAL_ALIAS.ALIAS_NAME ? entry.INDIVIDUAL_ALIAS.ALIAS_NAME : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ALIAS.QUALITY ? entry.INDIVIDUAL_ALIAS.QUALITY : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ALIAS.NOTE ? entry.INDIVIDUAL_ALIAS.NOTE : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.ENTITY_ALIAS && !isUNSectionEmpty(entry.ENTITY_ALIAS) && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Aliases</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Quality</TableCell>
                            <TableCell>Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.ENTITY_ALIAS) ?
                              entry.ENTITY_ALIAS.map(item => (
                                item.ALIAS_NAME &&
                                <TableRow key={item.ALIAS_NAME}>
                                  <TableCell>{item && item.ALIAS_NAME ? item.ALIAS_NAME : ''}</TableCell>
                                  <TableCell>{item && item.QUALITY ? item.QUALITY : ''}</TableCell>
                                  <TableCell>{item && item.NOTE ? item.NOTE : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              entry.ENTITY_ALIAS.ALIAS_NAME &&
                              <TableRow>
                                <TableCell>{entry.ENTITY_ALIAS.ALIAS_NAME ? entry.ENTITY_ALIAS.ALIAS_NAME : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ALIAS.QUALITY ? entry.ENTITY_ALIAS.QUALITY : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ALIAS.NOTE ? entry.ENTITY_ALIAS.NOTE : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.INDIVIDUAL_ADDRESS &&
                  !isUNSectionEmpty(entry.INDIVIDUAL_ADDRESS) && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Addresses</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State/Province</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.INDIVIDUAL_ADDRESS) ?
                              entry.INDIVIDUAL_ADDRESS.map(item => (
                                <TableRow key={JSON.stringify(item)}>
                                  <TableCell>{item && item.STREET ? item.STREET : ''}</TableCell>
                                  <TableCell>{item && item.CITY ? item.CITY : ''}</TableCell>
                                  <TableCell>{item && item.STATE_PROVINCE ? item.STATE_PROVINCE : ''}</TableCell>
                                  <TableCell>{item && item.ZIP_CODE ? item.ZIP_CODE : ''}</TableCell>
                                  <TableCell>{item && item.COUNTRY ? item.COUNTRY : ''}</TableCell>
                                  <TableCell>{item && item.NOTE ? item.NOTE : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.STREET ? entry.INDIVIDUAL_ADDRESS.STREET : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.CITY ? entry.INDIVIDUAL_ADDRESS.CITY : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.STATE_PROVINCE ? entry.INDIVIDUAL_ADDRESS.STATE_PROVINCE : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.ZIP_CODE ? entry.INDIVIDUAL_ADDRESS.ZIP_CODE : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.COUNTRY ? entry.INDIVIDUAL_ADDRESS.COUNTRY : ''}</TableCell>
                                <TableCell>{entry.INDIVIDUAL_ADDRESS.NOTE ? entry.INDIVIDUAL_ADDRESS.NOTE : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {entry.ENTITY_ADDRESS && !isUNSectionEmpty(entry.ENTITY_ADDRESS) && (
                    <div className={style.searchsection}>
                      <div className={style.subtitle}>Addresses</div>
                      <Table className={style.resultstable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State/Province</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(entry.ENTITY_ADDRESS) ?
                              entry.ENTITY_ADDRESS.map(item => (
                                <TableRow key={JSON.stringify(item)}>
                                  <TableCell>{item && item.STREET ? item.STREET : ''}</TableCell>
                                  <TableCell>{item && item.CITY ? item.CITY : ''}</TableCell>
                                  <TableCell>{item && item.STATE_PROVINCE ? item.STATE_PROVINCE : ''}</TableCell>
                                  <TableCell>{item && item.ZIP_CODE ? item.ZIP_CODE : ''}</TableCell>
                                  <TableCell>{item && item.COUNTRY ? item.COUNTRY : ''}</TableCell>
                                  <TableCell>{item && item.NOTE ? item.NOTE : ''}</TableCell>
                                </TableRow>
                              ))
                            :
                              <TableRow>
                                <TableCell>{entry.ENTITY_ADDRESS.STREET ? entry.ENTITY_ADDRESS.STREET : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ADDRESS.CITY ? entry.ENTITY_ADDRESS.CITY : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ADDRESS.STATE_PROVINCE ? entry.ENTITY_ADDRESS.STATE_PROVINCE : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ADDRESS.ZIP_CODE ? entry.ENTITY_ADDRESS.ZIP_CODE : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ADDRESS.COUNTRY ? entry.ENTITY_ADDRESS.COUNTRY : ''}</TableCell>
                                <TableCell>{entry.ENTITY_ADDRESS.NOTE ? entry.ENTITY_ADDRESS.NOTE : ''}</TableCell>
                              </TableRow>
                            }
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          }
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
});

UNResults.propTypes = {
  result: PropTypes.object,
  listAvailable: PropTypes.bool,
};

UNResults.defaultProps = {
  result: undefined,
  listAvailable: true,
};

export default UNResults;
