import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import { Edit, OpenInNew } from '@mui/icons-material';
import PropTypes from 'prop-types';

import style from './sanctions.module.scss';
import Token from '../../utils/token';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

const SanctionsSearchReports = ((props) => {
  const [editUrls, setEditUrls] = useState(false);
  const [urls, setUrls] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  useEffect(() => {
    if (props.data && props.data.reports) {
      setUrls(props.data.reports.map(report => report.url));
      setTitles(props.data.reports.map(report => report.title));
    }
    return function cleanup() {
      isOnPage = false;
    };
  }, [props.data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!props.data || !props.data.id) {
      return;
    }
    if (!loading) {
      setLoading(true);
    }
    Api.patchWithParams(`/ui/v4/sanctions/saved_search/${props.data.id}/fpUrls`,
      { flashpointURLs: urls.filter(url => url.length !== 0).join() },
      [200, 400, 500, 501, 502, 503, 504],
      30000,
      { Authorization: `Bearer ${Token.cke()}` },
    )
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then(() => {
        if (!isOnPage) {
          return;
        }
        Api.get(`/ui/v4/sanctions/savedSearch/${props.data.id}`,
          {},
          [200],
          30000,
          { Authorization: `Bearer ${Token.cke()}` },
        )
          .then(res => (res.ok ? res.data : null))
          .then((res) => {
            if (res.display === false) {
              return { data: [] };
            }
            return res;
          })
          .then((res) => {
            if (!isOnPage) {
              return;
            }
            if (res && res.results_json) {
              setUrls(res.fp_urls.map(report => report.url));
              setTitles(res.fp_urls.map(report => report.title));
            } else {
              setUrls(null);
              setTitles(null);
            }
            setLoading(false);
          })
          .catch((e) => {
            if (!isOnPage) {
              return;
            }
            const { data } = e.response;
            SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(data?.detail, data));
            setLoading(false);
          });
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexepectedError(data?.detail, data));
        setLoading(false);
      });
    setEditUrls(false);
  };

  const handleUrlChange = (event, index) => {
    const temp = urls.slice();
    temp[Number(index)] = event.target.value;
    setUrls(temp);
  };

  const handleAddUrl = (count) => {
    let temp = urls.slice();
    const emptyUrls = Array(count).fill('');
    temp = temp.concat(emptyUrls);
    setUrls(temp);
  };

  return (
    <React.Fragment>
      <div className={style.reportscontent}>
        {editUrls ?
          (
            <React.Fragment>
              <div className={style.reportformwrap}>
                <form onSubmit={handleSubmit} className={style.reportform}>
                  <div className={style.titleedit}>
                    <div className={style.subtitle}>
                      Flashpoint URLs
                    </div>
                    <input className={style.sectionsubmit} type="submit" value="Save" />
                  </div>
                  {urls.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <input className={style.reportinput} key={index} type="text" name="term" value={item} onChange={event => handleUrlChange(event, index)} />
                ))}
                </form>
                <button type="button" color="primary" onClick={() => { handleAddUrl(1); }} className={style.addnewobject}>+ Add another URL</button>
              </div>
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <div className={style.titleedit}>
                <div className={style.subtitle}>
                  Report Titles
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setEditUrls(true);
                    handleAddUrl(3);
                  }}
                  className={style.editbutton}><Edit />Edit
                </button>
              </div>
              {loading && <CircularProgress />}
              {Object.keys(urls).length === 0 &&
              <div className={style.note}>
                No reports yet
              </div>
            }
              <div className={style.urls}>
                <Table className={style.resultstable}>
                  <TableBody>
                    {urls.map((url, index) => (
                      <TableRow key={url}>
                        <TableCell>
                          <a target="_blank" href={url} rel="noreferrer">{titles.length > index && titles[Number(index)] ? titles[Number(index)] : 'Title not found'} <OpenInNew /></a>
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </div>
            </React.Fragment>
          )}
      </div>
    </React.Fragment>
  );
});

SanctionsSearchReports.propTypes = {
  data: PropTypes.object,
};

SanctionsSearchReports.defaultProps = {
  data: undefined,
};

export default SanctionsSearchReports;
