import React from 'react';
import Api from '../../../../utils/api';

const LOCAL_STORAGE_KEY = 'fp-dm-visited-links';
const SESSION_STORAGE_KEY = 'fp-dm-filters';
const SESSION_SKIP_KEY = 'fp-dm-skip';

const dev = '_self' in React.createElement('div') ? '/dev' : '';

const getAllIncidents = () => Api.get(`/ui/v4/domain-monitoring${dev}/incidents`).then(request => request.data);

const getAllBrands = () => Api.get(`/ui/v4/domain-monitoring${dev}/brands`).then(request => request.data);

const getVisitedLinks = () => {
  const store = window.localStorage;
  if (!store) return {};
  return JSON.parse(store.getItem(LOCAL_STORAGE_KEY));
};

const setVisitedLink = (linkId) => {
  const links = getVisitedLinks();
  const newLinks = {
    ...links,
  };
  newLinks[String(linkId)] = true;
  const store = window.localStorage;
  if (!store) return;
  store.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newLinks));
};

const getSessionFilters = () => {
  const store = window.sessionStorage;
  if (!store) return null;
  return JSON.parse(store.getItem(SESSION_STORAGE_KEY));
};

const setSessionFilters = (filters) => {
  const store = window.sessionStorage;
  if (!store) return;
  store.setItem(SESSION_STORAGE_KEY, JSON.stringify(filters));
};

const getSessionSkip = () => {
  const store = window.sessionStorage;
  if (!store) return null;
  return JSON.parse(store.getItem(SESSION_SKIP_KEY));
};

const setSessionSkip = (skip) => {
  const store = window.sessionStorage;
  if (!store) return;
  store.setItem(SESSION_SKIP_KEY, JSON.stringify(skip));
};

export {
  getAllIncidents,
  getAllBrands,
  getVisitedLinks,
  setVisitedLink,
  getSessionFilters,
  setSessionFilters,
  getSessionSkip,
  setSessionSkip,
};
