import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';

import style from './settings.module.scss';
import Text from '../../../utils/text';
import Api from '../../../utils/api';
import UserActions from '../../../actions/userActions';
import SearchActions from '../../../actions/searchActions';
import Messages from '../../../constants/Messages';
import OrgProfile from './OrgProfile/OrgProfile';
import Fraudwatch from './Fraudwatch/Fraudwatch';
import Query from './query';
import InfrastructureMonitoring from './InfrastructureMonitoring/InfrastructureMonitoring';
import CloudInfrastructure from './CloudInfrastructureMonitoring/CloudInfrastructureMonitoring';
import TwitterSettings from './Twitter/TwitterSettings';
import { SafeSearchTypes } from '../../../constants/Media';
import WithAttrs from '../../utils/WithAttrs';
import Prompt from '../../utils/Prompt';
import easyMemo from '../../utils/Memoize';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
  },
  settings: {
    '& .MuiPaper-root': {
      margin: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
  },
  notificationIcon: {
    background: '#5c6ae0',
    borderRadius: '10px',
    display: 'inline-block',
    height: '10px',
    position: 'relative',
    top: '-8px',
    width: '10px',
  },
}));

const IsRc = /rc/.test(window.location.href);

const Settings = ({
  apps,
  location,
  prm,
  prefs,
  user,
}) => {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const [dialog, setDialog] = useState('');
  const [values, setValues] = useState({});
  const [settingHelp, setSettingHelp] = useState(map());
  const [sfidProfile, setSfidProfile] = useState(map());
  const [fwBrandLimits, setFwBrandLimits] = useState({});
  const [fwNotification, setFwNotification] = useState(false);
  const [isFlashpointLogin, setIsFlashpointLogin] = useState(true);
  const [alertingKeywordClasses, setAlertingKeywordClasses] = useState({});
  const [online, setOnline] = useState(true);
  const [alert, setAlert] = useState();

  const Alerts = [
    { label: 'As They\'re Published',
      description: 'The moment a report is published, you will receive it as an email' },
    { label: 'In A Daily Digest',
      description: 'An aggregation of all reports published in the last 24 hours in a single email' },
  ];

  const onChangePassword = async () => {
    const accessToken = oktaAuth.getAccessToken();
    SearchActions.set(['search', 'info', 'message'], Messages.MutationProgress);
    const expr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z\d\s])(?=.{8,})/g;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      uid = user.get('uid'),
    } = values;
    const query = {
      oldPassword: { value: currentPassword },
      newPassword: { value: newPassword },
    };
    if (!newPassword || !confirmPassword) {
      SearchActions.set(['search', 'info', 'message'], Messages.PasswordMissing);
    } else if (newPassword !== confirmPassword) {
      SearchActions.set(['search', 'info', 'message'], Messages.PasswordComplexity);
    } else if (!expr.test(newPassword)) {
      SearchActions.set(['search', 'info', 'message'], Messages.PasswordMismatch);
    } else {
      Api.post(`/api/v4/reset-password-okta/${uid}/credentials/change_password`, query, [200, 201], 3000, {
        Authorization: `Bearer ${accessToken}`,
      })
        .then(() => SearchActions.set(['search', 'info', 'message'], Messages.PasswordSuccess))
        .then(() => setDialog())
        .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err?.message)));
    }
  };

  const onClearPrefs = () => {
    const defaults = user.get('defaults').toJS();
    const tags = JSON.parse(localStorage.tags || JSON.stringify([]));
    const threats = tags.find(v => v.name === 'Threat Type');
    const replacements = [
      'auth_ack',
      'ccm_c',
      'filters',
      'enable_pii',
      'glossary_highlight',
      'home',
      'image_cdn',
      'navigation',
      'notify',
      'safe_search_toggle',
      'tags',
    ].reduce((result, key) => ({ ...result, [key]: defaults[String(key)] }), {});
    const update = prefs
      .set('tags', (threats ? threats.children : []))
      .toJS();
    UserActions.savePrefs({ ...update, ...replacements });
    UserActions.logout();
  };

  const onSfidProfileSelect = (profile) => {
    if (!profile.has('salesforce_id')) return;
    UserActions.loadOrgProfiles(profile.get('salesforce_id'));
  };

  const onUpdate = (k, v) => {
    SearchActions.set(['search', 'info', 'message'], Messages.MutationProgress);
    UserActions.savePrefs({ [k]: v }, { optimistic: true });
    UserActions.savePrefs.completed.listen(() =>
      SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess));
  };

  const onToggle = (value, toggle) => {
    const vals = toggle ?
      prefs.get('alerts').push(value) :
      prefs.get('alerts').filterNot(v => v.equals(value));
    onUpdate('alerts', vals);
  };

  const onRefreshBrandLimits = async () => {
    Query.readBrandsLimit(sfidProfile.get('salesforce_id'))
      .then(data => setFwBrandLimits(data));
  };

  const initOnline = () => {
    const offline = user.get('offline');
    setOnline(!offline);
    if (offline) setDialog(JSON.stringify({ key: 'disablePrefs' }));
  };

  const initOrg = () => {
    if (!sfidProfile.isEmpty()) return;
    if (!user.get('activeOrganizations').isEmpty() || !user.get('sfids').isEmpty()) {
      const sfids = user.get('activeOrganizations').isEmpty() ? user.get('sfids') : user.get('activeOrganizations');
      const sid = sfidProfile.get('salesforce_id') || user.getIn(['defaults', 'orgProfile']) || user.get('sid');
      setSfidProfile(sfids.find(v => v.get('salesforce_id') === sid));
    }
  };

  const initScroll = () => {
    const { hash } = location;
    const scrolledElement = document.querySelectorAll(`[name="${hash.slice(1)}"]`)[0];
    if (scrolledElement) {
      const label = scrolledElement.closest('div[class*=table]');
      if (label) {
        label.querySelector('div[class*=h4]').setAttribute('style', 'color: #674ea7; font-weight: 900 !important;');
      }
      scrolledElement.scrollIntoView({
        block: 'start',
      });
      window.scrollBy(0, (-1 * scrolledElement.offsetHeight));
    }
  };

  useEffect(() => {
    if (!fwBrandLimits) {
      return;
    }
    const {
      domain_monitoring_count,
      domain_monitoring_limit,
      mobile_app_monitoring_count,
      mobile_app_monitoring_limit,
      social_media_brand_count,
      social_media_brand_limit,
      social_media_executive_count,
      social_media_executive_limit,
    } = fwBrandLimits;
    if ((domain_monitoring_count < domain_monitoring_limit)
      || (mobile_app_monitoring_count < mobile_app_monitoring_limit)
      || (social_media_brand_count < social_media_brand_limit)
      || (social_media_executive_count < social_media_executive_limit)) {
      setFwNotification(true);
    } else {
      setFwNotification(false);
    }
  }, [fwBrandLimits]);

  useEffect(() => {
    onRefreshBrandLimits();
    onSfidProfileSelect(sfidProfile);
  }, [sfidProfile]);

  useEffect(() => {
    initScroll();
    initOnline();
    initOrg();
  }, [user]);

  useEffect(() => {
    // autohide pop-up alerts according to length of alert message
    if (alert) {
      const base = 6000;
      const timeout = base + (Math.floor(alert.length / 120) * 1000);
      const timer = setTimeout(() => setAlert(), timeout);
      setAlert(alert);
      return () => clearTimeout(timer);
    }
    return setAlert();
  }, [alert]);

  useEffect(() => {
    // if user is offline attempt to reconnect to profile service
    if (!online) UserActions.loadPrefs();
  }, []);

  useEffect(() => {
    const { idp = '' } = oktaAuth.token.decode(oktaAuth.getIdToken());
    const FlashpointIdp = '00o2sl9rcCkwazW3K5d6';
    setSettingHelp(v => v
      .set('safe_search_toggle', Messages.SettingsSafeSearchToggle)
      .set('safe_search_ocr', Messages.SettingSafeSearchOcr));
    setIsFlashpointLogin(idp === FlashpointIdp);
    Query.readKeywordClasses()
      .then(data => setAlertingKeywordClasses(data));
  }, []);

  const WrappedFormControl = WithAttrs(FormControl, { disabled: !online });
  const WrappedButton = WithAttrs(Button, { disabled: !online });
  const WrappedAutocomplete = WithAttrs(Autocomplete, { disabled: !online });

  // Returns an empty object if the JSON string is invalid
  // Eslint throws an exception on this construct so disabling it
  // https://github.com/eslint/eslint/issues/10026
  // eslint-disable
  const safeParseJSON = (s) => {
    try {
      return JSON.parse(s);
    } catch (error) {
      return ({});
    }
  };
  // eslint-enable

  return (
    <Grid
      fluid
      name="component.settings"
      className={cx([style.base, style.settings, classes.settings])}>
      {
        dialog &&
        <Prompt
          open={Boolean(safeParseJSON(dialog).key === 'disablePrefs')}
          title="Settings Error"
          acceptText="Continue"
          accept={() => setDialog('')} >
          {`<div>
             We could not connect to the user preferences service. The rest of the application is functional, but you will not be able to change your settings until the issue is resolved.
          </div>`}
        </Prompt>
      }
      <Row>{/* general */}
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])} name="general">
              General
            </div>
            {
              IsRc &&
                <p><strong>Username:</strong>{' '}{user?.get('usn') ? user.get('usn') : 'Unknown user'}</p>
            }
            <WrappedFormControl variant="outlined" name="homepage">
              <InputLabel>Default Homepage</InputLabel>
              {prm
                .filter(v => apps.some(a => a.get('test').test(v)))
                .count() > 2 && (
                <Select
                  value={apps.find(v => v.get('home') && v.get('value') === prefs.getIn(['home', 'value']))}
                  onChange={event => onUpdate('home', event.target.value)}>
                  <ListItem disabled>Select...</ListItem>
                  {apps
                    .filter(v => !v.get('exclude') || !prm.some(p => v.get('exclude').test(p)))
                    .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
                    .filter(v => prm.some(p => v.get('test').test(p)))
                    .filter(v => v.get('home'))
                    .sortBy(v => v.get('alt') || v.get('label'))
                    .map(v => (
                      <ListItem
                        key={v.get('value')}
                        value={v}
                        className={cx([style.item, `${v.get('label')}`, prefs.getIn(['home', 'value']) === v.get('value') && style.active])} >
                        {Text.Sentence(v.get('alt') || v.get('label'))}
                      </ListItem>))}
                </Select>)}
              <FormHelperText />
            </WrappedFormControl>
            <WrappedFormControl variant="outlined" nam="links">
              <InputLabel>Default Action When Opening Links</InputLabel>
              <Select
                value={prefs.get('navigation')}
                onChange={event => onUpdate('navigation', event.target.value)}>
                <ListItem
                  key="self"
                  value="self"
                  className={cx([style.item, prefs.get('navigation') === 'self' && style.active])}>
                  Self
                </ListItem>
                <ListItem
                  key="tab"
                  value="tab"
                  className={cx([style.item, prefs.get('navigation') === 'tab' && style.active])}>
                  New Tab
                </ListItem>
              </Select>
              <FormHelperText>
                {settingHelp.get('navigation')}
              </FormHelperText>
            </WrappedFormControl>
            {prm.some(p => /ui\.href\.display/.test(p)) &&
            <WrappedFormControl variant="outlined" name="display">
              <InputLabel>Link Display</InputLabel>
              <Select
                value={prefs.get('href')}
                onChange={event => onUpdate('href', event.target.value)}>
                {[
                  { value: '', label: 'Default' },
                  { value: 'active', label: 'Always Active' },
                ].map(v => (
                  <ListItem
                    key={v.value}
                    value={v.value}
                    className={cx([style.item, prefs.get('href') === v.value && style.active])}>
                    {v.label}
                  </ListItem>
                ))}
              </Select>
              <FormHelperText>{settingHelp.get('href')}</FormHelperText>
            </WrappedFormControl>}
            <WrappedFormControl variant="outlined" name="safesearchinline">
              <InputLabel>Inline Media Preferences</InputLabel>
              <Select
                name="settings.safesearch"
                value={prefs.get('safe_search_toggle')}
                onChange={event => (event.target.value !== 'NONE'
                  ? setDialog(`safeSearch:${event.target.value}`)
                  : onUpdate('safe_search_toggle', event.target.value))}>
                <ListItem
                  key="NONE"
                  value="NONE"
                  className={cx([style.item, prefs.get('safe_search_toggle') === 'NONE' && style.active])}>
                  Block Media
                </ListItem>
                <ListItem
                  key="TOGGLE"
                  value="TOGGLE"
                  className={cx([style.item, prefs.get('safe_search_toggle') === 'TOGGLE' && style.active])}>
                  Allow Media
                </ListItem>
                <ListItem
                  key="SAFE"
                  value="SAFE"
                  className={cx([style.item, prefs.get('safe_search_toggle') === 'SAFE' && style.active])}>
                  Allow Media + Display Safe Images by Default
                </ListItem>
                {prm.find(v => v === 'org.fp.r') &&
                <ListItem
                  key="ALL"
                  value="ALL"
                  className={cx([style.item, prefs.get('safe_search_toggle') === 'ALL' && style.active])}>
                  Display All Media (INTERNAL ONLY)
                </ListItem>}
              </Select>
              <FormHelperText>
                {settingHelp.get('safe_search_toggle')}
              </FormHelperText>
              <Dialog
                open={!!(dialog && dialog.includes('safeSearch'))}
                onClose={() => setDialog('')}>
                <DialogTitle>
                  Warning: Document Previews
                </DialogTitle>
                <DialogContent>
                  <div>The files provided by Flashpoint are simply re-hosted
                    versions of the original source files, and as such might
                    contain malicious, harmful, inappropriate, or even
                    potentially illicit content. Please ensure that your
                    internet browser is updated to the latest version to
                    minimize security risks. <br /><br />
                    By clicking CONFIRM, you acknowledge that by turning on
                    document previews or downloading the file, you are assuming risk.
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialog()}>
                    Cancel
                  </Button>
                  <Button
                    className={style.active}
                    onClick={() => {
                      onUpdate('safe_search_toggle', dialog.split(':').splice(-1).shift());
                      setDialog();
                    }}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </WrappedFormControl>
            <WrappedFormControl variant="outlined" name="safesearchocr">
              <InputLabel>Media SafeSearch Preference</InputLabel>
              <Select
                name="settings.safesearchocr"
                value={prefs.get('safe_search_ocr')}
                onChange={event => onUpdate('safe_search_ocr', event.target.value)}>
                {Object.entries(SafeSearchTypes).map(([k, v]) => (
                  <ListItem
                    key={k}
                    value={v}
                    className={cx([style.item, prefs.get('safe_search_ocr') === v && style.active])}>
                    {Text.Sentence(k)}
                  </ListItem>))}
              </Select>
              <FormHelperText>
                {settingHelp.get('safe_search_ocr')}
              </FormHelperText>
            </WrappedFormControl>
            {(prm.some(p => /dat.edm.w/.test(p)) || prefs.get('blur_credentials')) &&
            <WrappedFormControl variant="outlined" name="blur">
              <FormControlLabel
                label="Blur compromised credentials for demo purposes"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('blur_credentials')}
                    onChange={() => onUpdate('blur_credentials', !prefs.get('blur_credentials'))} />}
              />
              <FormHelperText>{settingHelp.get('blur_credentials')}</FormHelperText>
            </WrappedFormControl>}
            <WrappedFormControl name="cdn">
              <FormControlLabel
                label="Use CDN For Images"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('image_cdn')}
                    onChange={() => onUpdate('image_cdn', !prefs.get('image_cdn'))} />}
              />
              <FormHelperText>{settingHelp.get('image_cdn')}</FormHelperText>
            </WrappedFormControl>
            <WrappedFormControl name="graphs">
              <FormControlLabel
                label="Disable Graph Overlays"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('disable_graph_overlays')}
                    onChange={() => onUpdate('disable_graph_overlays', !prefs.get('disable_graph_overlays'))} />}
              />
              <FormHelperText>Hide filter dropdowns on search aggregation charts</FormHelperText>
            </WrappedFormControl>
            <WrappedFormControl name="pii">
              <FormControlLabel
                label="View Identifying Details in Shops"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('enable_pii')}
                    onChange={() =>
                      onUpdate('enable_pii', !prefs.get('enable_pii'))} />}
              />
            </WrappedFormControl>
            <WrappedFormControl name="glossary">
              <FormControlLabel
                label="Glossary Definitions in Reports"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('glossary_highlight')}
                    onChange={() => onUpdate('glossary_highlight', !prefs.get('glossary_highlight'))} />}
              />
            </WrappedFormControl>
            <WrappedFormControl name="highlighting">
              <FormControlLabel
                label="Search Highlighting"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('search_highlighting')}
                    onChange={() => onUpdate('search_highlighting', !prefs.get('search_highlighting'))} />}
              />
              <FormHelperText>{settingHelp.get('search_highlighting')}</FormHelperText>
            </WrappedFormControl>
            <WrappedFormControl name="search_analytics">
              <FormControlLabel
                label="Show Search Analytics"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('search_analytics')}
                    onChange={() => onUpdate('search_analytics', !prefs.get('search_analytics'))} />}
              />
              <FormHelperText>{settingHelp.get('search_analytics')}</FormHelperText>
            </WrappedFormControl>
            <WrappedFormControl name="compact_view">
              <FormControlLabel
                label="Compact View (where available)"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('compact')}
                    onChange={() => onUpdate('compact', !prefs.get('compact'))} />}
              />
              <FormHelperText>
                If efficiently viewing messages is more important that aesthetics,
                enable this option for a more condensed viewing experience for content
                like Chat Services
              </FormHelperText>
            </WrappedFormControl>
            {/* Platform-1278 */}
            {/* <FormControl name="tracking">
              <FormControlLabel
                label="Tracking Opt Out"
                control={
                  <Switch
                    color="secondary"
                    checked={prefs.get('track_opt_out')}
                    onChange={() => onUpdate('track_opt_out', !prefs.get('track_opt_out'))} />}
              />
              <FormHelperText />
            </FormControl> */}
            <FormControl name="clear_preferences">
              <WrappedButton
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => setDialog('clear')}>Clear Preferences
              </WrappedButton>
              <Dialog
                open={dialog === 'clear'}
                onClose={() => setDialog()}>
                <DialogTitle>
                  Clear Preferences
                </DialogTitle>
                <DialogContent>
                  This will delete your stored user preferences and <b>log you out</b>.
                  Are you sure you want to continue?
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialog()}>
                    Cancel
                  </Button>
                  <Button
                    className={style.active}
                    onClick={() => onClearPrefs()}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </FormControl>
            <FormControl name="password">
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                disabled={!isFlashpointLogin || !online}
                onClick={() => setDialog('password')}>{isFlashpointLogin ? 'Change Password' : 'Your password is managed by your identity provider'}
              </Button>
              <Dialog
                className={classes.dialog}
                open={Boolean(dialog === 'password')}
                onClose={() => setDialog('')}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                  <FormControl variant="outlined">
                    <InputLabel>Current Password</InputLabel>
                    <OutlinedInput
                      type="password"
                      data-lpignore="true"
                      placeholder="Current Password"
                      value={values.currentPassword || ''}
                      onChange={(event) => {
                        const currentPassword = event.target.value;
                        setValues(vals => ({ ...vals, currentPassword }));
                      }} />
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>New Password</InputLabel>
                    <OutlinedInput
                      type="password"
                      data-lpignore="true"
                      placeholder="New Password"
                      value={values.newPassword || ''}
                      onChange={(event) => {
                        const newPassword = event.target.value;
                        setValues(vals => ({ ...vals, newPassword }));
                      }} />
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>Confirm New Password</InputLabel>
                    <OutlinedInput
                      type="password"
                      data-lpignore="true"
                      placeholder="Confirm New Password"
                      value={values.confirmPassword || ''}
                      onChange={(event) => {
                        const confirmPassword = event.target.value;
                        setValues(vals => ({ ...vals, confirmPassword }));
                      }} />
                  </FormControl>
                  {user.getIn(['tfa', 'enabled']) &&
                  <FormControl variant="outlined">
                    <InputLabel>TFA Token</InputLabel>
                    <OutlinedInput
                      data-lpignore="true"
                      placeholder="TFA Token"
                      value={values.otpPassword || ''}
                      onChange={(event) => {
                        const otpPassword = event.target.value;
                        setValues(vals => ({ ...vals, otpPassword }));
                      }} />
                  </FormControl>}
                  <div>
                    <div>
                      Your password must meet the following requirements:
                    </div>
                    <ul>
                      <li>At least 8 characters</li>
                      <li>A lowercase letter</li>
                      <li>An uppercase letter</li>
                      <li>A number</li>
                      <li>A symbol</li>
                      <li>No parts of your username</li>
                      <li>Does not include your first name</li>
                      <li>Does not include your last name</li>
                      <li>Your password cannot be any of your last 10 passwords</li>
                      <li>
                        At least 1 day(s) must have elapsed since you last{' '}
                        changed your password
                      </li>
                    </ul>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialog('')}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    name="button.confirm"
                    className={style.active}
                    onClick={() => onChangePassword()}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </FormControl>
            <FormControl name="privacy">
              <WrappedButton
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => window.open('https://www.flashpoint-intel.com/privacy-policy')}>
                Privacy Policy
              </WrappedButton>
            </FormControl>
            <FormControl name="terms">
              <WrappedButton
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => window.open('https://www.flashpoint-intel.com/terms-of-service/')}>
                Terms & Conditions
              </WrappedButton>
            </FormControl>
            <FormControl name="subscription">
              <WrappedButton
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => window.open('https://www.flashpoint-intel.com/legal-subscription-agreement/')}>
                Flashpoint Subscription Agreement
              </WrappedButton>
            </FormControl>
            <div className={style.disclaimer}>THIS SERVICE MAY CONTAIN TRANSLATIONS
              POWERED BY GOOGLE.
              GOOGLE DISCLAIMS ALL WARRANTIES RELATED TO THE TRANSLATIONS, EXPRESS OR
              IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, RELIABILITY, AND ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NONINFRINGEMENT.
            </div>
          </Paper>
        </Col>
      </Row>
      <Row>{/* two-factor */}
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])} name="2fa">
              Two-Factor Authentication
            </div>
            <div>
              <FormControl name="MFA settings">
                <WrappedButton
                  disableElevation
                  color="secondary"
                  variant="contained"
                  onClick={() => window.open('mailto:support@flashpoint-intel.com')}>
                  Please contact support@flashpoint-intel.com to change MFA settings
                </WrappedButton>
              </FormControl>
            </div>
          </Paper>
        </Col>
      </Row>
      {/* organization profiles */}
      {prm.some(p => /edm/.test(p)) &&
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])} name="organization_profiles">
              Organization Profiles
            </div>
            {prm.some(p => /dat.edm.w/.test(p)) &&
            (!user.get('activeOrganizations') || !user.get('sfids').isEmpty()) &&
            <WrappedAutocomplete
              name="ccm-org-select"
              value={{
                label: sfidProfile.get('name'),
                value: sfidProfile.get('salesforce_id'),
              }}
              options={(user.get('activeOrganizations').isEmpty()
                ? user.get('sfids')
                : user.get('activeOrganizations'))
                  .sortBy(v => v.get('name').toLowerCase())
                  .toJS()
                  .map(v => ({
                    label: v.name,
                    value: v.salesforce_id,
                  }))}
              onChange={(event, v) => setSfidProfile(typeof v.value !== 'string' ? map(v.value) : map({ name: v.label, salesforce_id: v.value }))}
              getOptionLabel={v => Text.BlurOrg(Text.Sentence(v.label), user.getIn(['prefs', 'blur_credentials']))}
              renderInput={params => (
                <TextField
                  {...params}
                  data-testid="settings.orgprofile-dropdown"
                  variant="outlined"
                  label="Organization" />
            )} />}
            {(user.hasIn(['org_profiles', sfidProfile.get('salesforce_id')]) ? user.getIn(['org_profiles', sfidProfile.get('salesforce_id')]).toList() : list())
              .map(v => (
                <OrgProfile
                  key={v.get('type')}
                  canEdit={prm.some(p => /edm(.org)?.w/.test(p))}
                  disabled={!online}
                  fields={v.get('mapping')}
                  profile={v}
                  sid={v.get('sid')} />
              ))
            }
          </Paper>
        </Col>
      </Row>}
      {/* IP Address alerts */}
      {prm.some(p => /dea/.test(p)) &&
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <fieldset className={style.border}>
              <legend>Internet Infrastructure Monitoring</legend>
              <InfrastructureMonitoring
                user={user}
                keywordClass={alertingKeywordClasses?.internetInfrastructure}
                disabled={!online}
                />
            </fieldset>
          </Paper>
        </Col>
      </Row>}
      {/* Cloud Infrastructure alerts */}
      {prm.some(p => /dea/.test(p)) &&
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <fieldset className={style.border} >
              <legend>Cloud Infrastructure Monitoring</legend>
              <CloudInfrastructure
                disabled={!online}
                user={user}
                keywordClass={alertingKeywordClasses?.cloudInfrastructure}
                />
            </fieldset>
          </Paper>
        </Col>
      </Row>}
      {/* Twitter alerts */}
      {prm.some(p => /twtr/ig.test(p)) &&
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <fieldset className={style.border}>
              <legend>Twitter Monitoring</legend>
              <TwitterSettings
                disabled={!online}
                user={user}
                keywordClasses={alertingKeywordClasses?.twitter}
                />
            </fieldset>
          </Paper>
        </Col>
      </Row>}
      {/* phishing profiles */}
      {prm.some(p => /dat.dm.(adm.)?r/.test(p)) &&
      // TODO get rid of WrappedFieldsset, the;'re not doing anything
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <fieldset className={style.border} disabled={!online} >
              <legend>
                Domain Monitoring
                {fwNotification && <span className={classes.notificationIcon} />}
              </legend>
              <Fraudwatch
                profile={sfidProfile}
                brandLimits={fwBrandLimits}
                onRefreshBrandLimits={onRefreshBrandLimits}
                user={user}
                />
            </fieldset>
          </Paper>
        </Col>
      </Row>}
      {/* alert-settings */}
      {prm.some(p => /rep/.test(p)) &&
      <Row>
        <Col xs={6} sm={8} md={11} lg={12}>
          <Paper>
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])} name="alerts">
              Email Subscriptions
            </div>
            <div>
              Manage email preferences for Flashpoint Intel Reports:
            </div>
            {list([...new Set(['cyber', 'jihadi', 'standup']
            .reduce((a, b) => a.concat(b === 'all reports' ? ['cyber', 'jihadi', 'standup'] : b), []))])
            .map(v => (v === 'jihadi' ? 'jihadist' : v))
            .entrySeq()
            .map(([index, type]) => (
              <WrappedFormControl key={index}>
                <FormLabel
                  component="legend">
                  {`${(() => { switch (type) {
                    case 'jihadist': return 'physical reports';
                    case 'standup': return 'daily standup';
                    default: return `${type} reports`;
                  } })()} Email Frequency`}:
                </FormLabel>
                <FormGroup>
                  {fromJS(Alerts)
                    .map(v => (
                      <FormControlLabel
                        key={v.get('label')}
                        label={v.get('label')}
                        control={<Switch
                          color="secondary"
                          checked={prefs.get('alerts').includes(v.merge({ type }))}
                          onChange={(_, toggle) => onToggle(v.merge(map({ type })), toggle)} />}
                      />))}
                </FormGroup>
              </WrappedFormControl>))}
          </Paper>
        </Col>
      </Row>}
      {alert &&
        <Alert
          severity="info"
          onClose={() => setAlert()}>
          {alert}
        </Alert>}
      <ReactTooltip id="help.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="api.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="header.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Settings.propTypes = {
  apps: PropTypes.object,
  location: PropTypes.object,
  prm: PropTypes.object,
  prefs: PropTypes.object,
  user: PropTypes.object,
};

Settings.defaultProps = {
  apps: list(),
  location: {},
  prm: list(),
  prefs: map(),
  user: map(),
};

export default easyMemo(Settings);
