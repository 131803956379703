import PropTypes from 'prop-types';
import React, { useState } from 'react';

import cx from 'classnames';

import style from './basicPagination.module.scss';

const BasicPagination = ({ count, limit, onSkip }) => {
  const [skip, setSkip] = useState(0);

  const onPage = (direction) => {
    if (direction === 'next') {
      setSkip(skip + limit);
      onSkip(skip + limit);
    } else if (direction === 'prev') {
      const newSkip = skip - limit;
      setSkip(newSkip > 0 ? newSkip : 0);
      onSkip(newSkip > 0 ? newSkip : 0);
    }
  };

  return (
    <div className={style['basic-pagination']}>
      <div className={style.actions}>
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          className={cx([style.link, style.prev, skip === 0 && style.disabled])}
          onClick={() => onPage('prev')}>
          Prev
        </div>
        <div className={style.line} />
        <div
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          className={cx([style.link, style.next, skip + limit >= count && style.disabled])}
          onClick={() => onPage('next')}>
          Next
        </div>
      </div>
    </div>
  );
};

BasicPagination.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
  onSkip: PropTypes.func,
};

BasicPagination.defaultProps = {
  count: 0,
  limit: 5,
  onSkip: null,
};

export default BasicPagination;
