import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  TwitterTweetEmbed as Tweet,
} from 'react-twitter-embed';
import { Accordion, AccordionSummary, AccordionDetails, CircularProgress, Icon } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import style from './twitter.module.scss';
import Query from './query';
import History from '../../../utils/history';
import Text from '../../../utils/text';
import Messages from '../../../constants/Messages';
import Invalid from '../../utils/Invalid/Invalid';
import SearchActions from '../../../actions/searchActions';

const useStyles = makeStyles(theme => ({
  twitter: {
    '& .MuiAccordion-root': {
      border: `1px solid ${theme.palette.primary.accent}`,
      boxShadow: 'none',
      margin: '1.5rem 0',
      padding: '0',
      '&:before': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
    },
    '& .MuiAccordionSummary-content': {
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
    '& .MuiAccordionSummary-root.Mui-disabled': {
      backgroundColor: '#fff',
      opacity: 1,
    },
    '& .twitter-tweet': {
      overflow: 'hidden',
    },
  },
}));

const Twitter = ({
  handle,
  fpid,
  id,
}) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [txn, setTxn] = useState();

  const onCopy = () => {
    const url = `https://twitter.com/${handle}/status/${id}`;
    navigator.clipboard.writeText(url);
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  };

  const onTranslate = async (post) => {
    setTxn();
    await Query
      .Translate(post?.fpid, post?.body?.['text/plain'])
      .then(res => setTxn(res))
      .then(() => ReactTooltip.rebuild());
  };

  const onLoadReplies = async () => {
    const { query: { query } } = History.getCurrentLocation();
    Promise.all([
      Query.Post(fpid),
      Query.Text(query, fpid),
      Query.Keywords(fpid),
    ]).then(([post, text, keywords]) => {
      setData(v => ({
        ...v,
        post,
        keywords: [
          ...text,
          ...keywords,
        ],
      }));
      onTranslate(post);
    });
  };

  // oembed by default adds interactive
  // elements (like, share, etc) to dom
  // remove these elements from the embedded iframe
  const removeActions = (el) => {
    const tweet = el;
    tweet.style.opacity = 1;
    tweet.style.pointerEvents = 'none';
  };

  useEffect(() => {
    if (!id) return;
    SearchActions.set(['search', 'result', 'meta', 'twitter', 'title'], `@${handle}`); // set page header
    onLoadReplies();
  }, [id]);

  return (
    <Grid
      fluid
      name="component.twitter"
      className={cx([style.base, style.twitter, classes.twitter])}>
      {!data?.keywords &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <div className={style.tweet}>
            {data?.keywords &&
            <div className={style.tools}>
              <Icon
                data-for="global.tooltip"
                data-tip="Copy Link to Tweet"
                className={style.tool}
                onClick={() => onCopy()}>
                link
              </Icon>
              {txn &&
              <Icon
                data-for="global.tooltip"
                data-tip={!txn?.translation ? 'Translating...' : txn?.translation}
                className={style.tool}>
                g_translate
              </Icon>}
            </div>}
            <Tweet
              tweetId={id}
              style={{ opacity: 0 }}
              onLoad={el => removeActions(el)}
              options={{
                align: 'center',
                conversation: 'none',
                chrome: 'noborders',
                width: '550',
                dnt: true,
              }} />
          </div>
        </Col>
      </Row>
      {data?.keywords &&
      <Row>
        <Col xs={12} className={style.timeline}>
          {data?.keywords.map(keyword => (
            <Accordion
              key={keyword?.id}
              disabled={Boolean(!keyword?.data?.length)}>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}>
                {`${Text.Sentence(keyword?.label)} Replies (${Number(keyword?.data?.length)})`}
              </AccordionSummary>
              <AccordionDetails>
                {keyword?.data?.slice(0, 10).map(reply => (
                  <div key={reply?.native_id}>
                    <Tweet
                      tweetId={reply?.native_id}
                      onLoad={el => removeActions(el)}
                      options={{
                        align: 'center',
                        conversation: 'none',
                        chrome: 'noborders',
                        width: '550',
                        dnt: true,
                      }} />
                  </div>))}
              </AccordionDetails>
            </Accordion>))}
        </Col>
      </Row>}
      {data?.total === 0 &&
      <Invalid
        help=""
        icon="error_outline"
        title="Item not found" />}
      <ReactTooltip id="help.tooltip" html place="right" effect="solid" />
    </Grid>
  );
};

Twitter.propTypes = {
  id: PropTypes.string.isRequired,
  fpid: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
};

export default Twitter;
