import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ContentLoader from 'react-content-loader';

import style from './placeholder.module.scss';

const Placeholder = ({ height, width, total }) => {
  useEffect(() => {
    setTimeout(() => {
      const anchor = document.getElementById((total / 2).toString());
      if (anchor) {
        const rec = anchor.getBoundingClientRect();
        const top = rec.top + window.pageYOffset;
        const mid = top - (window.innerHeight / 2);
        window.scrollTo(0, mid);
      }
    });
  }, [total]);

  return (
    <div style={{ minHeight: `${height * total}px` }}>
      {[...Array(total).keys()].map(v => (
        <div
          id={v}
          key={v}
          className={style.placeholder}
          style={{ height: `${height}px`, width: `${width}px` }}>
          <div className={style.card}>
            <ContentLoader width={400} height={80}>
              <circle cx="132" cy="26.05" r="1" />
              <circle cx="201" cy="9.05" r="1" />
              <circle cx="16" cy="9" r="9" />
              <rect x="50" y="0" width="135" height="10" />
              <rect x="50" y="20" width="350" height="10" />
              <rect x="50" y="40" width="350" height="10" />
              <rect x="50" y="60" width="350" height="10" />
            </ContentLoader>
          </div>
        </div>))}
    </div>
  );
};

Placeholder.propTypes = {
  height: PropTypes.number,
  total: PropTypes.number,
  width: PropTypes.number,
};

Placeholder.defaultProps = {
  height: 0,
  total: 1,
  width: 0,
};

export default Placeholder;
