import Api from '../../../utils/api';

import type {
  Label,
  Post,
  Tag as TagProps,
} from './types';

interface Tag {
  fpid: string,
  type: string,
  post?: Post,
  labels?: string[],
}

const Tag = {
  id: 'tag',
  base: '/ui/v4/labeling/labels',
  getLabels: async ({
    fpid,
    type,
  }: Tag): Promise<Label[]> => {
    const params = new URLSearchParams({
      resource_fpid: fpid,
      resource_type: type,
    });
    const url = `${Tag.base}?${params.toString()}`;
    const result = await Api
      .get(url)
      .then(res => res?.data?.labels)
      .then(res => res?.sort((a, b) => a?.name?.localeCompare(b?.name)));
    return result;
  },
  setLabels: async ({
    type,
    post,
    labels,
  }: Tag): Promise<string[]> => {
    const data: TagProps = {
      labels,
      resource_type: type,
      resource_fpid: post?.fpid,
      resource_text: post?.body?.['text/plain'],
      resource_site: post?.site?.title,
    };

    const result: string[] = await Api
      .post(Tag.base, data)
      .then(() => labels);

    return result;
  },
};

export default {
  Tag,
};
