import React from 'react';

import { Button, Grid } from '@mui/material';

import PropTypes from 'prop-types';

import useStyles from './style';

const SettingsButton = ({ onCancel, isForm }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container item xs={12} justifyContent="flex-end" className={classes.dialogFooter}>
        <Grid item>
          <Button onClick={() => {
              onCancel();
            }}
          >
            {isForm ? 'Close' : 'Cancel'}
          </Button>
        </Grid>
        { !isForm && <Grid item><Button color="secondary" type="submit" >Save</Button></Grid> }
      </Grid>
    </>
  );
};

SettingsButton.propTypes = {
  onCancel: PropTypes.func,
  isForm: PropTypes.bool,
};

SettingsButton.defaultProps = {
  onCancel: null,
  isForm: false,
};


export default SettingsButton;
