import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { CircularProgress } from '@mui/material';
import { List as list } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './syntax.module.scss';

const Syntax = ({ data }) => (
  <Grid
    fluid
    name="component.syntax"
    className={cx([style.base, style.syntax])}>
    <Row>
      <Col xs={12}>
        {data.isEmpty() && <CircularProgress />}
        {!data.isEmpty() && data.map(v => (
          <div className={style.card} key={v.get('title')}>
            <div className={cx([style.h0, 'h0', style.raj, 'raj'])}>{v.get('operator')}</div>
            <div className={style.body}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>{v.get('title')}</div>
              <div className={style.info}>{v.get('info')}</div>
              <div>{v.get('example')}</div>
              {v.has('code') && <pre>{v.get('code')}</pre>}
              {v.has('footnote') &&
                <div dangerouslySetInnerHTML={{ __html: v.get('footnote') }} />}
            </div>
          </div>))}
      </Col>
    </Row>
  </Grid>);

Syntax.propTypes = {
  data: PropTypes.object,
};

Syntax.defaultProps = {
  data: list(),
};

export default Syntax;
