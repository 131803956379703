import React from 'react';

import { Col } from 'react-flexbox-grid/lib';
import { useRecoilValue } from 'recoil';

import style from './exposuredashboard.module.scss';
import Looker from '../../utils/Looker';
import { getSfid } from '../../../stores/recoil/app';
import Token from '../../../utils/token';

const ExposureDashboard = () => {
  const sid = useRecoilValue(getSfid);
  // User has paid for upgraded exposure monitoring
  const upgrade = Token
    ?.get('prm')
    ?.some(p => /dat\.edm\.hd(\.org)?\.r/.test(p));

  return (
    <React.Fragment>
      <Col xs={12} className={style.containers}>
        {sid &&
        <Looker
          height="250rem"
          dashboard={`/dashboards-next/ccm_e_views::my_credential_exposure${upgrade ? '' : '__basic'}`}
          sid={sid} />}
      </Col>
    </React.Fragment>
  );
};

export default ExposureDashboard;
