import * as React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { fromJS } from 'immutable';
import ReactTooltip from 'react-tooltip';
import { DefaultThemeType } from '../../../../../theme/light/defaultTheme';
import { makeFiltersFromSavedSearch } from '../../../../../utils/SavedSearch';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import easyUseState from '../../../../../hooks/easyUseState';
import Query from '../../query';
import { CommunityMentionsReturnType, AnnotatedBucketItem } from '../../queryTS';
import { SavedSearch } from '../../../../../core/types/SavedSearch';
import { PercentChangeNode } from '../TopFilterTableWidget/PercentChangeNode';
import { TotalHitsNode } from '../TopFilterTableWidget/TotalHitsNode';
import { filtersTooltip } from '../RecentSearchesWidget/RecentSearchesTableRow';
import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import Hero from '../../../../widget/Hero/Hero';
import { navigateFromExpoDash } from '../../utils';
import { capitalizeWords } from '../../Dialogs/EditIntelReportsDialog/EditIntelReportsDialog';

import Dashboard from '../../../Dashboard';
import easyMemo from '../../../../utils/Memoize';

type HitsTuple = {
  name: string,
  hits: number,
  field: string,
  platform?: string,
}

export type StoryBookRawBucketData = {
  currentBuckets: AnnotatedBucketItem[],
  previousBuckets: AnnotatedBucketItem[],
}

const findHitsTuple = (tupleName:string, hitsTuples: HitsTuple[]) => (
  hitsTuples.filter((hitsTuple:HitsTuple) => hitsTuple.name === tupleName)?.[0]
);

const calculatePercentChange = (currentHits:number, prevHits:number):number => (
  prevHits === 0 ? 100 : ((currentHits - prevHits) * 100.0) / prevHits
);

const calculateTopHits = (currentRows:HitsTuple[]):number => (
  currentRows ? Math.max(...currentRows.map((v:HitsTuple) => v.hits)) : 0
);

const calculateTopHitsWidth = (topHits:number):number => {
  if (!topHits) return 0;
  const topHitsDiv = document.createElement('div');
  topHitsDiv.setAttribute('style', `
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
  `);
  topHitsDiv.innerHTML = topHits.toString();
  document.body.appendChild(topHitsDiv);
  const topHitsWidth = topHitsDiv.clientWidth;
  document.body.removeChild(topHitsDiv);
  return topHitsWidth;
};

export const savedSearchName = (savedSearch:SavedSearch, defaultQueryString:string):string =>
  savedSearch?.name || defaultQueryString;

const fieldKeyToQueryParam = (fieldKey:string):string => {
  switch (fieldKey) {
    case 'site_actor.names.handle.keyword': return 'author';
    case 'site.title.keyword': return 'sites';
    case 'container.title.keyword': return 'channel';
    default: throw new Error('this field key can\'t be translated into a query param');
  }
};

const dataPath = (filterType:string) => {
  switch (filterType) {
    case 'actor': return 'actors';
    case 'source': return 'sources';
    default: throw new Error('filter type currently be either "actors" or "sources"');
  }
};

const transformBucketItems = (bucketItems:AnnotatedBucketItem[]):HitsTuple[] => (
  bucketItems ? bucketItems.slice(0, 20)
    .map((tuple:AnnotatedBucketItem) => (
      { name: tuple.key, hits: tuple.doc_count,
        field: tuple.field, platform: tuple.site_type }),
    )
  : []
);

const limitedFilters = (filters:object) => (
  Object.fromEntries(Object.entries(filters).filter(
    ([filterKey]:string[]) =>
      !filterKey.toLowerCase().match(/(since|until|date|query_i18n)/),
  ))
);

const useFilterTableStyles = makeStyles((theme: DefaultThemeType) => ({
  tableConainer: {
    marginTop: '3px',
  },
  table: {
    maxWidth: '100%',
    tableLayout: 'fixed',
    '& .MuiTableCell-head': {
      color: '#000',
      fontWeight: 'bold',
      textTransform: 'none',
      position: 'sticky',
      top: 0,
      backgroundColor: `${theme.palette?.background?.paper}`,
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f7f7f8',
    },
    '& tr:hover': {
      backgroundColor: '#f1f1f2',
    },
    '& tr.MuiTableRow-head:hover': {
      backgroundColor: 'initial',
    },
    '& thead': {
      maxWidth: '100%',
    },
    '& tbody': {
      maxWidth: '100%',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      border: 'solid 1px #f1f1f2',
    },
  },
  headerRow: {
    borderBottom: '1px #e5e5e6 solid}',
  },
  tableHeadCellText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
  },
  tableBodyCellText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19px',
  },
  leftColumn: {},
  leftMiddleColumn: {},
  middleColumn: {},
  rightColumn: {},
  actorHeaderRow: {
    '& .leftColumn': {
      width: '25%',
    },
    '& .middleColumn': {
      width: '50%',
    },
    '& .rightColumn': {
      width: '25%',
    },
  },
  sourceHeaderRow: {
    '& .leftColumn': {
      width: '20%',
    },
    '& .leftMiddleColumn': {
      width: '20%',
    },
    '& .middleColumn': {
      width: '40%',
    },
    '& .rightColumn': {
      width: '20%',
    },
  },
  reactTooltip: {
    overflowY: 'hidden',
  },
  heroWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface TopFilterTableWidgetProps {
  savedSearch: SavedSearch;
  settings: VoidFunction;
  storyBookRawBucketData?: StoryBookRawBucketData;
  baseFilters: ExpoDashFilters;
  filterType: 'actor'|'source';
  title: string;
  help: string;
}

const MyTopFilterTableWidget = ({
  settings,
  savedSearch,
  storyBookRawBucketData,
  baseFilters,
  filterType,
  title,
  help,
}: TopFilterTableWidgetProps): JSX.Element => {
  const classes = useFilterTableStyles();
  const [currentRows, setCurrentRows] = easyUseState(null);
  const [previousRows, setPreviousRows] = easyUseState(null);
  const topHits = calculateTopHits(currentRows);
  const topHitsWidth = calculateTopHitsWidth(topHits);
  const filters = savedSearch ?
    { ...makeFiltersFromSavedSearch(savedSearch), ...{ date: baseFilters.date } }
    : baseFilters;

  const setUpStorybookData = (myStoryBookRawBucketData:StoryBookRawBucketData) => {
    setCurrentRows(transformBucketItems(myStoryBookRawBucketData.currentBuckets));
    setPreviousRows(transformBucketItems(myStoryBookRawBucketData.previousBuckets));
  };

  const requestNewTopFilterData = () => {
    Query.CommunityMentions(savedSearch, filters)
      .then((res:CommunityMentionsReturnType) => {
        const mentionsBuckets = res[dataPath(filterType)];
        if (mentionsBuckets) {
          setCurrentRows(transformBucketItems(mentionsBuckets.currentBuckets));
          setPreviousRows(transformBucketItems(mentionsBuckets.previousBuckets));
        }
      },
    );
  };

  easyUseEffect(() => {
    if (storyBookRawBucketData) {
      setUpStorybookData(storyBookRawBucketData);
    } else {
      if (currentRows?.length > 0) {
        setCurrentRows(null);
        setPreviousRows(null);
      }
      requestNewTopFilterData();
    }
  }, [savedSearch, baseFilters]);

  const onClickRow = (tupleName: string, tupleField: string):void => {
    const queryParamKey = fieldKeyToQueryParam(tupleField);
    navigateFromExpoDash({ savedSearch,
      filters: { ...filters, ...{ [queryParamKey]: tupleName } } });
  };

  const makeLeftmostHeaderCells = () => {
    switch (filterType) {
      case 'actor': return [
        <TableCell className={cx(['leftColumn', classes.tableHeadCellText])} key="actor_name">
          <div>Actor Name</div>
        </TableCell>,
      ];
      case 'source': return [
        <TableCell className={cx(['leftColumn', classes.tableHeadCellText])} key="channel_site">
          <div>Channel/Site</div>
        </TableCell>,
        <TableCell className={cx(['leftMiddleColumn', classes.tableHeadCellText])} key="platform">
          <div>Platform</div>
        </TableCell>,
      ];
      default: throw new Error('filter type currently be either "actors" or "sources"');
    }
  };

  const headerRowClass = ():string => (filterType === 'source' ? classes.sourceHeaderRow : classes.actorHeaderRow);

  const makeHeaderRow = () => (
    <TableRow className={cx([classes.headerRow, headerRowClass()])}>
      {/* 38, 38, 24 are the proportions in the mockup */}
      {makeLeftmostHeaderCells()}
      <TableCell className={cx(['middleColumn', classes.tableHeadCellText])} key="total_hits">
        <div>Total Hits</div>
      </TableCell>
      <TableCell className={cx(['rightColumn', classes.tableHeadCellText])} key="percent_change">
        <div>Percent Change</div>
      </TableCell>
    </TableRow>
  );

  const makeLeftmostTableCells = (hitsTuple:HitsTuple) => {
    switch (filterType) {
      case 'actor': return [
        <TableCell className={classes.tableBodyCellText} key={`actor_name-${hitsTuple.name}`}>
          <div>{hitsTuple.name}</div>
        </TableCell>,
      ];
      case 'source': return [
        <TableCell className={classes.tableBodyCellText} key={`site_channel_name-${hitsTuple.name}`}>
          <div>{hitsTuple.name}</div>
        </TableCell>,
        <TableCell className={classes.tableBodyCellText} key={`platform-${hitsTuple.name}`}>
          <div>{hitsTuple.platform}</div>
        </TableCell>,
      ];
      default: throw new Error('filter type currently be either "actors" or "sources"');
    }
  };

  const makeTableRow = (hitsTuple:HitsTuple) => {
    const queryParam = fieldKeyToQueryParam(hitsTuple.field);
    return (
      <TableRow
        onClick={() => { onClickRow(hitsTuple.name, hitsTuple.field); }}
        data-for="global.tooltip"
        data-place="top"
        data-class={classes.reactTooltip}
        data-tip={filtersTooltip({ filtersObj:
          { ...limitedFilters(filters), ...{ [queryParam]: hitsTuple.name } },
        })}
      >
        {makeLeftmostTableCells(hitsTuple)}
        <TableCell className={classes.tableBodyCellText} key={`total_hits-${hitsTuple.name}`}>
          <TotalHitsNode
            totalHits={hitsTuple.hits}
            topHits={topHits}
            topHitsWidth={topHitsWidth}
          />
        </TableCell>
        <TableCell className={classes.tableBodyCellText} key={`percent_change-${hitsTuple.name}`}>
          <PercentChangeNode
            rawPercentChange={calculatePercentChange(
              hitsTuple.hits,
              findHitsTuple(hitsTuple.name, previousRows)?.hits || 0,
            )}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Dashboard
      data={currentRows}
      title={title}
      help={help}
      settings={settings ? () => settings() : null}>
      {
        currentRows?.length > 0 ?
          <div style={{ width: '100%' }} onMouseEnter={() => ReactTooltip.rebuild()}>
            <TableContainer className={classes.tableConainer}>
              <Table className={classes.table}>
                <TableHead>
                  {makeHeaderRow()}
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {currentRows && previousRows && currentRows.map(makeTableRow)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        :
          <div className={classes.heroWrapper}>
            <Hero
              text
              narrowStyle
              labels={fromJS([' '])}
              values={fromJS([{ key: 'name' }])}
              results={fromJS({ name: `No Top ${capitalizeWords(filterType)}` })}
            />
          </div>
      }
    </Dashboard>
  );
};

MyTopFilterTableWidget.defaultProps = {
  storyBookRawBucketData: null,
};
export const TopFilterTableWidget = easyMemo(MyTopFilterTableWidget);
