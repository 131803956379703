import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import { red } from '@mui/material/colors';

import qs from 'qs';

import RfiDialog from './RfiDialog/RfiDialog';
import RfiForm from './RfiForm/RfiForm';
import Query from './query';
import handleSubmit from './util';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';
import { UserContext } from '../utils/Context';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    paddingLeft: '75px',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1',
    pointerEvents: 'none',
  },
  error: {
    color: red[500],
    display: 'flex',
    marginLeft: '2.4rem',
  },
}));

const RfiPopupForm = ({ forceOpen, postUrl, onClose }) => {
  const [closed, setClosed] = useState(false);
  const [data, setData] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const classes = useStyles();

  const handleRefresh = async sid => (
    Query.getFormData(sid)
      .then(res => setData({
        ...res,
        user_email: user.get('email'),
        organization: user.get('ogn'),
      }))
  );

  useEffect(() => {
    SearchActions.loadResources();

    /* Get URL Params */
    const query = window.location.search.replace(/\?/, '&');
    const params = qs.parse(query);

    /* Choose a default relevant_url... */
    let url = params?.relevant_url ? params.relevant_url : window.location.href;
    /* ...but always prefer the user's pick */
    if (postUrl) {
      url = postUrl;
    }
    setQueryParams({
      ...params,
      relevant_url: url,
    });

    setClosed(!forceOpen);
    if (params?.relevant_url || params?.question || params.rfi) {
      setClosed(false);
    }

    /* Load in the data from Salesforce */
    if (user) {
      handleRefresh(user.get('sf_id'));
    }
  }, [postUrl]);

  useEffect(() => {
    if (closed) {
      setClosed(!forceOpen);
    }
  }, [forceOpen]);

  const handleClose = () => {
    setClosed(true);
    if (onClose) {
      onClose();
    }
  };

  const _setLoading = v => setLoading(v);
  const _setError = (v) => {
    setClosed(false);
    setLoading(false);
    setError(v);
  };
  const done = () => {
    if (error) return;
    SearchActions.set(['search', 'info', 'message'], Messages.RfiPostSuccess);
    setLoading(false);
    setClosed(true);
  };

  if (closed) return null;

  return (
    <div className={[classes.root].join(' ')}>
      <RfiDialog state="normal" onClose={handleClose}>
        { error && <div className={classes.error}><WarningIcon /> {error}</div> }
        <RfiForm
          onSubmit={d => handleSubmit(d, _setLoading, _setError, done)}
          onRefresh={handleRefresh}
          queryParams={queryParams}
          data={data}
          loading={loading} />
      </RfiDialog>
    </div>
  );
};

RfiPopupForm.propTypes = {
  forceOpen: PropTypes.bool,
  postUrl: PropTypes.string,
  onClose: PropTypes.func,
};

RfiPopupForm.defaultProps = {
  forceOpen: false,
  postUrl: null,
  onClose: null,
};

export default RfiPopupForm;
