
import { List as list, Map as map } from 'immutable';
import {
  atom,
  selector,
} from 'recoil';

export const takedownBackends = atom({
  key: 'takedownBackends',
  default: list(),
});

export const takedownSite = atom({
  key: 'takedownSite',
  default: '',
});

export const takedownBackend = selector({
  key: 'takedownBackend',
  get: ({ get }) => {
    const backends = get(takedownBackends);
    const site = get(takedownSite);
    return backends.find(v => v.get('name') === site, null, map());
  },
});
