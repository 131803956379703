import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import get from 'lodash/get';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

import style from './forumsFilter.module.scss';
import History from '../../utils/history';
import SearchActions from '../../actions/searchActions';
import { Track } from '../../utils/track';

const useStyles = makeStyles(theme => ({
  forumsfilter: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
  },
}));

const ForumsFilter = ({
  advanced,
  filters,
  onApply,
  onFilter,
  properties,
  sites,
  toggle,
  type,
}) => {
  const classes = useStyles();
  const [active, setActive] = useState([]);
  const [includedForums, setIncludedForums] = useState([]);
  const [excludedForums, setExcludedForums] = useState([]);
  const [languageOptions, setLanguageOptions] = useState(map());
  const [tierOptions, setTierOptions] = useState(map());
  const [activityOptions, setActivityOptions] = useState(map());
  const [accessibilityOptions, setAccessibilityOptions] = useState(map());
  const [topics, setTopics] = useState(map());
  const [tags, setTags] = useState([]);

  useEffect(() => {
    SearchActions.loadSites('forums');
  }, []);

  useEffect(() => {
    setLanguageOptions(properties.find(v => v.get('name') === 'Language') || map());
    setAccessibilityOptions(properties.find(v => v.get('name') === 'Accessibility') || map());
    const reputation = properties.find(v => v.get('name') === 'Reputation') || map();
    const tier = (reputation.get('children') || list())
      .filter(v => ['Entry-Level', 'Middle-Tier', 'Top-Tier'].includes(v.get('name')))
      .sortBy(v => ['Entry-Level', 'Middle-Tier', 'Top-Tier'].indexOf(v.get('name')));
    const activity = (reputation.get('children') || list())
      .filter(v => ['Low Activity', 'Mirror', 'Official'].includes(v.get('name')))
      .sortBy(v => ['Low Activity', 'Mirror', 'Official'].indexOf(v.get('name')));
    setTierOptions(fromJS({ fpid: '1-tier', name: 'Tier', children: tier }));
    setActivityOptions(fromJS({ fpid: '1-activity', name: 'Activity', children: activity }));
  }, [properties]);

  useEffect(() => {
    const included = filters
      .get('sites', '')
      .split(',')
      .filter(v => v)
      .map(v => sites.find(s => v.toLowerCase() === s.get('title').toLowerCase(), null, map()).toJS());
    const excluded = filters
      .get('exclude_sites', '')
      .split(',')
      .filter(v => v)
      .map(v => sites.find(s => v.toLowerCase() === s.get('title').toLowerCase(), null, map()).toJS());
    const tagging = filters
      .get('site_tags', '')
      .split(',')
      .filter(v => v);
    setIncludedForums(included);
    setExcludedForums(excluded);
    setTags(tagging);
  }, [filters, sites]);

  const tagsToString = nonStateTopics =>
    (nonStateTopics || topics)
      .filter((v, k) => !['sites', 'exclude_sites'].includes(k))
      .map((v) => {
        // If all is selected, no need to specify tags in category
        // since already being searched for
        if ([...v.keys()].includes('all')) {
          return '';
        }
        return [...v.keys()].join(',');
      })
      .filter(v => v)
      .join(':');

  const prepareTopics = (actual) => {
    const included_sites = actual.get('sites');
    const exclude_sites = actual.get('exclude_sites');
    const tag = actual.filter((v, k) => !['sites', 'exclude_sites'].includes(k));
    const site_tags = tagsToString(tag);
    return map({ sites: included_sites, exclude_sites, site_tags });
  };

  const onChipChange = (option, values) => {
    const setValues = (option === 'included') ? setIncludedForums : setExcludedForums;
    const category = (option === 'included') ? 'sites' : 'exclude_sites';
    const actual = topics.set(category, fromJS(values.map(v => v.title).join(',')));
    setValues(values);
    setTopics(actual);


    if (onFilter) onFilter(prepareTopics(actual));
  };

  const onReset = () => {
    setIncludedForums([]);
    setExcludedForums([]);
    setTopics(map());
    setTags([]);
  };

  const onSearch = () => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const include = includedForums.length > 0 ? includedForums.map(v => v.title).join(',') : '';
    const exclude = excludedForums.length > 0 ? excludedForums.map(v => v.title).join(',') : '';
    const site_tags = tagsToString().split(',').filter(v => v);
    const forumQuery = fromJS({
      ...query,
      skip: undefined,
      sites: include,
      exclude_sites: exclude,
      site_tags: site_tags.concat(tags).join(),
    }).filter(v => v).toJS();

    toggle();
    if (onApply) {
      onApply(fromJS(forumQuery));
      return;
    }

    const event = {
      type,
      filters: { ...forumQuery },
      origin: '.forumsfilter',
    };

    Track(event, 'Search');

    History.push({
      pathname,
      query: forumQuery,
      hash,
    });
  };

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.forumsfilter,
        classes.forumsfilter,
        !advanced && style.picker,
      ])}>
      {properties.isEmpty() &&
      <div>
        <CircularProgress />
      </div>}
      {!properties.isEmpty() &&
      <div>
        {properties
          .filter(v => ['Cyber Threat', 'Physical Threat'].includes(v.get('name')))
          .sortBy(v => ['Cyber Threat', 'Physical Threat'].indexOf(v.get('name')))
          .toArray()
          .map(prop => (
            <Row key={prop.get('fpid')} className={style.row}>
              <Col xs={12}>
                <div className={style.label}>{prop.get('name')}</div>
                <div className={style.options}>
                  {prop
                    .get('children')
                    .sortBy(v => v.get('name'))
                    .toArray()
                    .map(v => (
                      <Button
                        key={v.get('fpid')}
                        className={cx([
                          style.button,
                          style.option,
                          tags.includes(v.get('name')) && style.selected,
                        ])}
                        onClick={() => {
                          const selection = tags.includes(v.get('name'))
                            ? tags.filter(t => t !== v.get('name')) // removing
                            : tags.concat(v.get('name')); // adding
                          const update = [...new Set(selection)].filter(t => t);
                          setTags(update);
                          if (onFilter) {
                            onFilter({ site_tags: update.join() });
                          }
                        }}>
                        {v.get('name')}
                      </Button>))}
                </div>
              </Col>
            </Row>
          ))}
        <Row>
          <Col xs={12}>
            <div className={cx([style.autocomplete, includedForums.length > 0 && style.changed])}>
              <Autocomplete
                multiple
                clearOnBlur
                selectOnFocus
                name="included.forums"
                value={includedForums}
                getOptionLabel={option => option.title}
                options={sites.sortBy(v => v.get('title')).toJS()}
                className={cx(['autocomplete', active.includes('included.forums') && 'active'])}
                onBlur={() => setActive(state => state.filter(v => v !== 'included.forums'))}
                onFocus={() => setActive(state => [...state, 'included.forums'])}
                onChange={(event, values) => onChipChange('included', values)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Included Forums" />
                )} />
            </div>
            <div className={cx([style.autocomplete, excludedForums.length > 0 && style.changed])}>
              <Autocomplete
                multiple
                clearOnBlur
                selectOnFocus
                name="excluded.forums"
                value={excludedForums}
                getOptionLabel={option => option.title}
                options={sites.sortBy(v => v.get('title')).toJS()}
                className={cx(['autocomplete', active.includes('excluded.forums') && 'active'])}
                onBlur={() => setActive(state => state.filter(v => v !== 'excluded.forums'))}
                onFocus={() => setActive(state => [...state, 'excluded.forums'])}
                onChange={(event, values) => onChipChange('excluded', values)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Excluded Forums" />
                )} />
            </div>
          </Col>
        </Row>
        <Row className={style.row}>
          {[languageOptions, tierOptions, activityOptions, accessibilityOptions]
            .filter(prop => !prop.isEmpty())
            .map((prop) => {
              const render = prop.renderValue
                ? prop.renderValue
                : v => get(v, 'name', v);
              const options = prop
                .get('children')
                .sortBy(v => v.get('name'))
                .map(v => v.get('name'))
                .toJS();
              const value = tags.filter(v => options.map(option => option).includes(v));
              return (
                <div key={prop.get('fpid')} className={style.autocomplete}>
                  <Autocomplete
                    multiple
                    clearOnBlur
                    selectOnFocus
                    name={prop.get('name')}
                    value={value}
                    options={options}
                    className={cx(['autocomplete', active.includes(prop.get('name')) && 'active'])}
                    onBlur={() => setActive(state => state.filter(v => v !== prop.get('name')))}
                    onFocus={() => setActive(state => [...state, prop.get('name')])}
                    onChange={(event, values) => {
                      const selection = [...new Set(tags
                        .filter(v => !options.includes(v))
                        .concat(values.map(v => render(v))))]
                        .filter(v => v);
                      setTags(selection);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={prop.get('name')} />
                    )} />
                </div>
              );
            })}
        </Row>
        {toggle &&
        <Row>
          <Col xs={12} className={cx([style.footer, advanced && style.advanced])}>
            <div className={style.error} />
            <div className={style.actions}>
              <Button
                name="forumsfilter.clearall"
                className={style.left}
                onClick={() => onReset()}>
                Clear All
              </Button>
              <Button
                name="forumsfilter.cancel"
                onClick={() => toggle()}>
                Cancel
              </Button>
              <Button
                className={style.active}
                name="forumsfilter.apply"
                onClick={() => onSearch()}>
                Apply
              </Button>
            </div>
          </Col>
        </Row>}
      </div>}
    </Grid>
  );
};

ForumsFilter.propTypes = {
  advanced: PropTypes.bool,
  filters: PropTypes.object,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  properties: PropTypes.object,
  sites: PropTypes.object,
  toggle: PropTypes.func,
  user: PropTypes.object,
  type: PropTypes.string,
};

ForumsFilter.defaultProps = {
  advanced: false,
  filters: map(),
  onApply: null,
  onFilter: null,
  properties: map(),
  sites: list(),
  toggle: null,
  user: null,
  type: null,
};

export default ForumsFilter;
