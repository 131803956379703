import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Icon,
} from '@mui/material';

import style from './ransomware.module.scss';
import Text from '../../../utils/text';
import Common from '../../../utils/common';
import Invalid from '../../utils/Invalid/Invalid';
import Post from '../../threads/Post/Post';
import Placeholder from '../../utils/Placeholder';
import InternalLink from '../../utils/InternalLink';

const Ransomware = ({
  data,
}) => {
  const [images, setImages] = useState([]);
  const [carousel, setCarousel] = useState(-1);
  const onSearch = (k, v) => Common.Generic.SearchRoute(data, { [k]: Text.Strip(v) });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  const onMediaClick = (src) => {
    if (src === -1) {
      setImages([]);
      setCarousel(-1);
    } else {
      setImages([{ source: src }]);
      setCarousel(0);
    }
  };

  return (
    <Grid
      name="component.ransomware"
      className={cx([style.base, style.ransomware])}>
      <Row>
        <Col className={cx([style.column, style.op])}>
          {data.isEmpty() &&
          <Placeholder height={450} width={950} total={1} />}
          {!data.isEmpty() &&
          <div>
            <div className={style.header}>
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                {Text.Highlight(data.getIn(['site', 'title'], '-'))}
              </div>
              <div className={style.title}>
                <Icon className={style.icon}>lock</Icon>
                <div className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                  {Text.Highlight(Text.Post(data.getIn(['title'], '-')))}
                </div>
              </div>
              <div className={style.title}>
                <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                  Created By:
                </div>
                <InternalLink
                  key="username"
                  to={onSearch('author',
                    `${data.getIn(['container', 'site_actor', 'fpid'])
                      ? data.getIn(['container', 'site_actor', 'fpid'])
                      : data.getIn(['site_actor', 'fpid'])}::${data.getIn(['container', 'site_actor', 'names', 'handle'])
                        ? data.getIn(['container', 'site_actor', 'names', 'handle'])
                        : data.getIn(['site_actor', 'names', 'handle'])}`)}
                  className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal', style.a, 'a'])}>
                  &nbsp;{data.getIn(['container', 'site_actor', 'names', 'handle'])
                    || data.getIn(['container', 'site_actor', 'names', 'username'])
                    || data.getIn(['site_actor', 'names', 'handle'])
                    || data.getIn(['site_actor', 'names', 'username'])
                    || data.getIn(['native_id'])
                    || '-'}
                </InternalLink>
              </div>
              <div className={cx([style.h4, 'h4', style.normal, 'normal', style.wrap])}>
                URL: {data.get('raw_href')}
              </div>
              {(data.getIn(['container', 'first_observed_at', 'timestamp']) || data.getIn(['first_observed_at', 'timestamp'])) &&
              <div className={style.title}>
                <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                  {`First Observed on ${moment.utc((data.getIn(['container', 'first_observed_at', 'timestamp']) || data.getIn(['first_observed_at', 'timestamp'])) * 1000).format('MMMM DD, YYYY')}`}
                </div>
              </div>}
              {(data.getIn(['container', 'last_observed_at', 'timestamp']) || data.getIn(['last_observed_at', 'timestamp'])) &&
              <div className={style.title}>
                <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>
                  {`Last Observed on ${moment.utc((data.getIn(['container', 'last_observed_at', 'timestamp']) || data.getIn(['last_observed_at', 'timestamp'])) * 1000).format('MMMM DD, YYYY')}`}
                </div>
              </div>}
            </div>
            <Post
              post={data}
              index={false}
              key={data.get('fpid')}
              onMediaClick={onMediaClick} />
          </div>}
        </Col>
      </Row>
      <ModalGateway>
        {Boolean(images.length && carousel > -1) &&
        <Modal onClose={() => onMediaClick(-1)}>
          <Carousel
            views={images}
            className={style.carousel}
            currentIndex={carousel} />
        </Modal>}
      </ModalGateway>
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
      <ReactTooltip id="date.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="offline.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="ransomware.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Ransomware.propTypes = {
  data: PropTypes.object,
};

Ransomware.defaultProps = {
  data: map(),
};

export default Ransomware;
