import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
} from '@mui/material';

import style from './sidebar.module.scss';
import Channels from './Channels';
import Threads from './Threads';
import Board from './Board';
import News from './News';
import Iso from '../../constants/Iso';
import Text from '../../utils/text';
import Hooks from '../../utils/hooks';
import History from '../../utils/history';
import Tagger from '../tagging/Tagger';
import InternalLink from '../utils/InternalLink';
import {
  MetaDataContext,
  SearchContext,
  UserContext,
} from '../../components/utils/Context';

const Sidebar = ({
  pin,
  type,
}) => {
  const metaData = useContext(MetaDataContext) || map();
  const search = useContext(SearchContext) || map();
  const user = useContext(UserContext) || map();

  const [pinned, setPinned] = useState(false);
  const [middleElement, setMiddleElement] = useState(null);

  const blur = user?.getIn(['prefs', 'blur_credentials']);
  const prm = user?.get('prm');
  const result = search?.getIn(['result', type], list());
  const route = user.get('route', map());
  const username = user.get('usn');
  const { query: { query_i18n } } = History.getCurrentLocation();

  const onLink = (basetype, k, v) => ({
    pathname: `/home/search/${basetype}`,
    query: { query: '', [k]: v },
  });

  const channelLink = (resource, chatType) => {
    const chatUsername = resource?.getIn(['container', 'username']);
    const chatChannelId = resource?.getIn(['container', 'native_id']);
    const chatServerId = resource?.getIn(['container', 'container', 'native_id']);
    switch (chatType) {
      case 'Telegram':
        if (chatUsername) {
          return `https://t.me/${chatUsername}`;
        } else {
          return 'Not available';
        }
      case 'Discord':
        if (chatChannelId && chatServerId) {
          return `https://discord.com/channels/${chatServerId}/${chatChannelId}`;
        } else {
          return 'Not available';
        }
      default:
        return 'Not available';
    }
  };

  const details = () => {
    const storeTypes = ['board', 'channels', 'threads', 'tagging.channels'];
    const resource = (storeTypes.includes(type))
      ? search.getIn(['result', 'meta', type])
      : result;
    if (!resource || resource.isEmpty()) return '';

    switch (type) {
      case 'board':
      case 'boards': {
        const title = `${resource.getIn(['container', 'container', 'title'])} / ${resource.getIn(['container', 'native_id'])}`;
        const actor = resource.getIn(['site_actor', 'names', 'handle']) || resource.getIn(['site_actor', 'names', 'username']) || '-';
        return (
          <>
            <b>Board</b>:{' '}
            <InternalLink
              data-cy="board"
              className={cx([style.a, 'a'])}
              to={onLink('boards', 'board', title)}>
              {title}
            </InternalLink><br />
            <b>User</b>:{' '}
            <InternalLink
              data-cy="board"
              className={cx([style.a, 'a'])}
              to={onLink('boards', 'author', actor)}>
              {actor}
            </InternalLink><br />
            <b>Created On</b>: {moment.utc(resource.getIn(['sort_date'])).format('MMMM DD, YYYY')} <br />
          </>
        );
      }
      case 'channels':
      case 'chats':
      case 'tagging.channels': {
        const chatType = resource.getIn(['site', 'title']);
        const chatUsername = resource.getIn(['container', 'username']);
        const server = resource.getIn(['container', 'container', 'name']);
        const serverId = resource.getIn(['container', 'container', 'native_id']);
        const createdOn = Math.min(resource.getIn(['container', 'created_at', 'timestamp']) || Infinity, moment.utc(resource.getIn(['sort_date'])).unix());
        const actor = resource.getIn(['site_actor', 'names', 'handle']) || resource.getIn(['site_actor', 'names', 'username']) || '-';
        const channelDescription = resource.getIn(['container', 'description']) || '-';
        return (
          <>
            <b>Chat Type</b>:{' '}
            <InternalLink
              data-cy="chat"
              className={cx([style.a, 'a'])}
              to={onLink('chats', 'sites', chatType)}>
              {resource.getIn(['site', 'title']) || '-'}
            </InternalLink><br />
            <b>{resource.get('basetypes').some(b => /element/.test(b)) ? 'Room' : 'Channel'}</b>:{' '}
            <InternalLink
              data-cy="chat"
              className={cx([style.a, 'a'])}
              to={onLink('chats', 'channel', `${resource.getIn(['container', 'fpid'])}::${resource.getIn(['container', 'name'])}`)}>
              {resource.getIn(['container', 'name']) || '-'}
            </InternalLink><br />
            <b>{resource.get('basetypes').some(b => /element/.test(b)) ? 'Room' : 'Channel'} ID:</b>{' '}
            <InternalLink
              data-cy="chat"
              className={cx([style.a, 'a'])}
              to={onLink('chats', 'channel_id', resource.getIn(['container', 'native_id']))}>
              {resource.getIn(['container', 'native_id']) || '-'}
            </InternalLink><br />
            <b>Channel Link</b>: {channelLink(resource, chatType)}<br />
            <b>Channel Description</b>: {channelDescription}<br />
            {(server) ?
              <>
                <b>Server</b>:{' '}
                <InternalLink
                  data-cy="chat"
                  className={cx([style.a, 'a'])}
                  to={onLink('chats', 'server', `"${server}"`)}>
                  {server}
                </InternalLink><br />
              </> :
              null}
            {(server) ?
              <>
                <b>Server ID</b>:{' '}
                <InternalLink
                  data-cy="chat"
                  className={cx([style.a, 'a'])}
                  to={onLink('chats', 'server_id', serverId)}>
                  {serverId}
                </InternalLink><br />
              </> :
              null}
            <b>User</b>:{' '}
            <InternalLink
              data-cy="chat"
              className={cx([style.a, 'a'])}
              to={onLink('chats', 'author', actor)}>
              {actor}
            </InternalLink><br />
            <b>Username</b>:{' '}
            {chatUsername ?
              <>
                <InternalLink
                  data-cy="chat"
                  className={cx([style.a, 'a'])}
                  to={onLink('chats', 'author', chatUsername)}>
                  {chatUsername}
                </InternalLink>
              </> :
              'Not available'}<br />
            <b>Created On</b>: {moment.utc(createdOn * 1000).format('MMMM DD, YYYY')} <br />
          </>
        );
      }
      case 'forums':
      case 'threads': {
        const thread = resource.getIn(['container', `title${query_i18n ? '_en' : ''}`]);
        const room = resource.getIn(['container', 'container', `title${query_i18n ? '_en' : ''}`]);
        const actor = resource.getIn(['site_actor', 'names', 'handle']) || resource.getIn(['site_actor', 'names', 'username']) || '-';
        const language = resource.hasIn(['container', 'title_native_language']);

        return (
          <>
            <b>Forum</b>:{' '}
            <InternalLink
              data-cy="forum"
              className={cx([style.a, 'a'])}
              to={onLink('forums', 'sites', resource.getIn(['site', 'title']))}>
              {resource.getIn(['site', 'title']) || '-'}
            </InternalLink><br />
            <b>{`Room ${query_i18n ? 'Translated' : ''}`}</b>:{' '}
            <InternalLink
              data-cy="forum"
              className={cx([style.a, 'a'])}
              to={onLink('forums', 'room_title', `${resource.getIn(['container', 'container', 'fpid'])}::${resource.getIn(['container', 'container', 'title'])}`)}>
              {room || '-'}
            </InternalLink><br />
            <b>{`Thread ${query_i18n ? 'Translated' : ''}`}</b>:{' '}
            <InternalLink
              data-cy="forum"
              className={cx([style.a, 'a'])}
              to={onLink('forums', 'thread_title', `"${resource.getIn(['container', 'title'])}"`)}>
              {thread || '-'}
            </InternalLink><br />
            <b>User</b>:{' '}
            <InternalLink
              data-cy="forum"
              className={cx([style.a, 'a'])}
              to={onLink('forums', 'author', actor)}>
              {actor}
            </InternalLink><br />
            <span className="translation">
              {query_i18n && language
                ? `Translated from ${Iso?.[String(language)]?.name || 'Unknown'} to English`
                : ''}
            </span>
          </>
        );
      }
      default: return '';
    }
  };

  const label = () => {
    switch (type) {
      case 'board': return `${search.getIn(['result', 'meta', 'board', 'site', 'title']) || ''} / ${search.getIn(['result', 'meta', 'board', 'container', 'container', 'title']) || ''} / ${search.getIn(['result', 'meta', 'board', 'container', 'native_id']) || ''}`;
      case 'threads': return search.getIn(['result', 'meta', 'threads', 'title']);
      case 'news': return search.getIn(['result', 'meta', 'news', 'title']);
      case 'channels':
      case 'tagging.channels': return search.getIn(['result', 'meta', 'channels', 'title']);
      default: return '';
    }
  };

  const loading = () => {
    switch (type) {
      case 'board':
      case 'threads':
      case 'news':
      case 'channels':
      case 'tagging.channels': return false;
      default: return result.isEmpty();
    }
  };

  const maintainScrollPos = () => {
    if (middleElement) {
      middleElement.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  };

  const onClose = () => {
    const { pathname, query } = History.getCurrentLocation();
    History.push({ pathname, query, hash: null });
    window.scrollTo(0, window.scrollY);
  };

  const onRender = () => {
    if (!pinned) return null;
    switch (type) {
      case 'board': return <Board meta={search.getIn(['result', 'meta', 'board'])} inline={search.getIn(['result', 'inline', 'board'])} filters={search.get('filters_inline')} search={search} />;
      case 'threads': return <Threads meta={search.getIn(['result', 'meta', 'threads'])} inline={search.getIn(['result', 'inline', 'threads'])} filters={search.get('filters_inline')} search={search} />;
      case 'news': return <News meta={search.getIn(['result', 'meta', 'news'])} inline={search.getIn(['result', 'inline', 'news'])} filters={search.get('filters_inline')} search={search} />;
      case 'chats': return <Tagger fpid={result.getIn(['container', 'fpid'])} prm={prm} metaData={metaData} username={username} />;
      case 'channels':
      case 'tagging.channels': return <Channels meta={search.getIn(['result', 'meta', 'channels'])} inline={search.getIn(['result', 'inline', 'channels'])} filters={search.get('filters_inline')} search={search} prm={prm} metaData={metaData} username={username} />;
      default: return null;
    }
  };

  const removeLabel = (
    ['board', 'threads', 'news', 'channels', 'tagging.channels'].includes(type)
  );

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const elements = document.querySelectorAll('[class*="thread"] [class*="post"]');
    let hasBeenFound = false;
    elements.forEach((e) => {
      if (hasBeenFound) return;
      const pos = e.getBoundingClientRect().top;
      // if an element is more or less in the middle of the viewport
      if (pos > viewportHeight / 2.5 && pos < viewportHeight / 1.5) {
        setMiddleElement(e);
        hasBeenFound = true;
      }
    });
    setPinned(!['save'].includes(type) && pin);
  }, [pin, type]);

  Hooks.useEventListener('transitionend', maintainScrollPos, document.querySelector('[class*="sidebar"]'));

  return (
    <Grid className={cx([style.sidebar, pinned && style.pinned])}>
      {loading() &&
      <Row>
        <Col xs={12} className={style.header}>
          <CircularProgress size={25} thickness={2} className={style.progress} />
        </Col>
      </Row>}
      {!loading() &&
      <Row>
        <Col xs={12} className={style.header}>
          {!removeLabel && route &&
          <Icon className={cx([style.icon])}>
            {route.get('icon') || ''}
          </Icon>}
          <div>
            {!removeLabel &&
            <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>
              {Text.Highlight(Text.BlurFirstDomainInline(Text.StripHtml(label()), blur))}
            </div>}
            <div className={cx([style.h3, style.mont, 'mont'])}>
              {details()}
            </div>
          </div>
          <Icon
            className={cx([style.icon, style.close])}
            onClick={() => onClose()}>
            close
          </Icon>
        </Col>
        <Col xs={12}>
          {onRender()}
        </Col>
      </Row>}
    </Grid>
  );
};

Sidebar.propTypes = {
  pin: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default Sidebar;
