import makeStyles from '@mui/styles/makeStyles';

import { grey } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
  breakline: {
    borderBottom: '2px solid #999999',
    lineHeight: '.1em',
    margin: '10px 0 20px',
    textAlign: 'center',
    width: '100%',
  },
  breaklineInner: {
    background: 'white',
    color: '#999999',
    padding: '0 10px',
  },
  dialogBody: {
    padding: '0 2.4rem 2.4rem 2.4rem',
  },
  dialogBucketUploadFailed: {
    color: '#FFA21A',
    fontSize: '1.8rem',
    padding: '0 0 7px 20px',
  },
  dialogBucketUploadContainer: {
    padding: '2rem',
  },
  dialogBucketUploadFailedContent: {
    display: 'flex',
  },
  dialogBucketUploadFailedMessage: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px',
  },
  dialogBucketUploadTitle: {
    fontSize: '2.4rem',
    paddingBottom: '10px',
  },
  dialogBucketUploadSuccess: {
    color: 'green',
    fontSize: '1.8rem',
    padding: '0 0 7px 20px',
  },
  dialogBucketUploadSuccessContainerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogFooter: {
    borderTop: `1px ${grey[200]} solid`,
  },
  dialogFormGroup: {
    padding: '0 2.4rem',
  },
  dialogTitle: {
    padding: '1.6rem 0',
    '& .MuiTypography-root': {
      fontSize: '2.4rem',
      fontWeight: '600',
    },
  },
  errorText: {
    color: '#CD211F',
    fontSize: '1.2rem',
  },
  fieldGap: {
    marginBottom: '2rem',
  },
}));

export default useStyles;
