import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import {
  CircularProgress,
  Icon,
  Paper,
} from '@mui/material';

import style from './dashboard.module.scss';

const Dashboard = ({
  children,
  data,
  help,
  padding,
  settings,
  title,
  transparent,
  vertical,
  onViewClick,
}) => {
  const testId = title?.toLowerCase()?.replace(/\s/ig, '-');
  const loading = () => {
    if (!data && data !== 0) return true;
    else if (Array.isArray(data) && data.length === 0) return false;
    else if (map.isMap(data) && data.isEmpty()) return true;
    else if (!Object.keys(data).length) return true;
    return false;
  };

  return (
    <div className={style.dashboard}>
      <Paper
        elevation={2}
        className={cx([style.card, transparent && style.transparent])}
        style={{ padding: 0 }}>
        <div className={style.header}>
          <div className={style.title} data-testid={`dashboard.title-${testId}`}>{title}</div>
          <div className={style.help}>
            {help &&
              <Icon
                onClick={typeof help === 'string' ? null : help}
                className={cx([style.icon, 'material-icons'])}
                data-for="help.tooltip"
                data-tip={typeof help === 'string' ? help : 'Help'}>
                info_outline
              </Icon>}
          </div>
          {onViewClick &&
            <div className={style.viewlink}>
              <a onClick={onViewClick}>View All</a>
            </div>}
          <div className={style.actions}>
            {settings &&
            <Icon
              onClick={settings}
              className={cx([style.icon, 'material-icons'])}
              data-for="settings.tooltip"
              data-tip="Widget settings">
              settings
            </Icon>}
          </div>
        </div>
        {loading() && <div className={style.loader}><CircularProgress /></div>}
        {!loading() &&
          <div className={cx([style.content, vertical && style.vertical])} style={{ padding }}>
            {children}
          </div>}
      </Paper>
      <ReactTooltip id="settings.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="help.tooltip" html place="right" effect="solid" className={style.reactTooltip} />
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  help: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  padding: PropTypes.string,
  settings: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  transparent: PropTypes.bool,
  vertical: PropTypes.bool,
  onViewClick: PropTypes.func,
};

Dashboard.defaultProps = {
  children: null,
  data: null,
  help: null,
  padding: '0 1.5rem 1.5rem 1.5rem',
  settings: null,
  title: '',
  transparent: false,
  vertical: false,
  onViewClick: null,
};

export default Dashboard;
