import moment from 'moment';

import {
  fromJS,
  List as list,
  Map as map,
} from 'immutable';

import History from './history';
import Common from './common';
import Dates from '../constants/Dates';
import SearchActions from '../actions/searchActions';
import UserActions from '../actions/userActions';

let SearchFields;
import('../constants/SearchFields').then((res) => {
  SearchFields = res.default;
});

const BasetypeMapping = Common.Basetypes.SearchTypesToBasetypesQuery;
/**
 *
 * @param {map|{}} filters
 * @param {string} format
 * @return {string} since
 * @return {string} until
 * @return {string} sinceDate formatted date representation of since
 * @return {string} untilDate formatted date representation of until
 * determine relative date range for a given set of filters
 * there are two types of dates
 *   relative: last n d|m|y valid for v4 api
 *   static: Y-m-d TO Y-m-d valid for v3 api
 * relative date ranges will return the string syntax for the range
 *   last 3 days:
 *      since: now-3d,
 *      until: now,
 *      sinceDate: moment.utc().subtract(3, days),
 *      untilDate: moment.utc()
 * static date range will return date formatted string for range
 *   2001-01-01 - 2010-01-01:
 *      since: 2001-01-01,
 *      until: 2010-01-01,
 *      sinceDate: 2001-01-01
 *      untilDate: 2010-01-01
 */
const parseDate = (value = {}, format) => {
  const filter = map.isMap(value) ? value.toJS() : value;
  const entry = Dates.find(v => v.date === filter.date) || filter || {};
  const [, sinceTime, sinceScale] = (entry.since || '').match(/(\d+)(\D)/) || [null, 1, 'm'];
  const [, untilTime, untilScale] = (entry.until || '').match(/(\d+)(\D)/) || [null, 0, 'm'];

  // check for date math rounding; down for since, up for until
  const startOf = /\//.test(entry.since) ? entry.since.split('/').slice(-1).join() : '';
  const endOf = /\//.test(entry.until) ? entry.until.split('/').slice(-1).join() : 'day';

  const {
    date = entry.date || 'All Time',
    since = entry.since || '*',
    until = entry.until || 'now',
    sinceDate = /last/ig.test(entry.date)
      ? moment.utc().subtract(+sinceTime, sinceScale).startOf(startOf).format(format)
      : moment.utc(!/all/ig.test(entry.date)
        ? entry.since || 0
        : 0).format(format),
    untilDate = /last/ig.test(entry.date)
      ? moment.utc().subtract(+untilTime, untilScale).endOf(endOf).format(format)
      : moment.utc(!/all/ig.test(entry.date)
        ? entry.until || {}
        : {}).format(format),
  } = entry;

  return { ...filter, date, since, until, sinceDate, untilDate };
};

const idQueryString = (type = '', id = '') => {
  if (!type || !id) return '';
  switch (type) {
    case 'forums':
      return `+fpid:"${id.split('|')[0]}"`;
    default:
      return `+fpid:"${id}"`;
  }
};

const escapeOneFilter = filterString => (filterString ? filterString.replace(/([\(\)])/g, '\\$1') : filterString);
const addExactKeyword = (attrName, exactFilters) => (exactFilters.includes(attrName) ? '.keyword' : '');
const escapeExactFilter = filterString => escapeOneFilter(filterString.replace(/"/g, '\\"'));
const escapeIfNoStar = v => (v.includes('*') ? v : `${escapeExactFilter(v)}`);
const deleteStars = v => v.replace('*', '');
const escapeFiltersList = ({ stringOfFilters, whenFilterContainsStar, customJoin }) => {
  const escapedFilters = stringOfFilters.split(',').map((v) => {
    let res;
    switch (whenFilterContainsStar) {
      case 'preventEscape':
        res = escapeIfNoStar(v); break;
      case 'deleteStars':
        res = escapeExactFilter(deleteStars(v)); break;
      default:
        res = escapeExactFilter(v);
        break;
    }
    return `"${res}"`;
  });
  if (customJoin) {
    return escapedFilters.join(customJoin);
  }
  return escapedFilters.join();
};

const computeSitesQueryConditions = (filters, exactFilters) => {
  if (!filters.sites && !filters.exclude_sites) return '';
  return `${filters.exclude_sites ? '-' : '+'}site.title${addExactKeyword('sites', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.exclude_sites === 'true' ? filters.sites : filters?.exclude_sites ?? filters.sites })})`;
};

const computeAuthorsExcludeQueryConditions = (filters, excludeFilters, exactFilters) => (
  filters.author
    ? `${filters.author.includes(':')
      ? `${!excludeFilters.includes('author') ? '+' : '-'}site_actor.fpid:"${filters.author.split(':').shift()}"`
      : `${!excludeFilters.includes('author') ? '+' : '-'}site_actor.names.handle${addExactKeyword('author', exactFilters)}:(${escapeFiltersList({ stringOfFilters: filters.author })})`}`
    : null
);

const buildQuery = (type = '', id = '', filters = {}) => {
  if (!type) return {};
  let platforms = [type];

  if (type === 'communities') {
    platforms = (filters.all)
      ? filters.all.split(',').filter(v => Common.Basetypes.CommunitySearchTypes().includes(v))
      : Common.Basetypes.CommunitySearchTypes();
  } else if (filters.all) platforms = filters.all.split(',');

  // v3/aggregint
  const queryParams = {};

  // v4/ES
  const queryParts = [
    `+basetypes:(${BasetypeMapping(platforms)})`,
    id ? idQueryString(type, id) : null,
  ];

  // Determine a list of filters that are allowed. Filters in both the type and
  // the common are overridden by what is in the specific type's filters. Also,
  // filter out Common's defaultDate if one already exists in the specific type's
  // filters
  const allowedFilters = SearchFields.AllowedFilters[String(type)]
    ?.concat(SearchFields.AllowedFilters.common
      ?.filter(v => SearchFields.AllowedFilters[String(type)]
        ?.findIndex(f => f.filter === v.filter) === -1
        && (!v.defaultDate
          || SearchFields.AllowedFilters[String(type)].findIndex(f => f.defaultDate) === -1)));

  allowedFilters?.forEach((v) => {
    const {
      exact: exactField,
      exclude,
      generate,
      filter,
      filterCorollary,
      field,
      fpid: fpidField,
      multiple,
      date,
      defaultDate,
      isQuery,
    } = v;
    const filterVal = filter === 'query' ? filters[String(filter)] : escapeOneFilter(filters[String(filter)]);
    const excluded = exclude && (filters[`exclude_${filter}`] === 'true' || filters[`exclude_${filter}`]);
    const exact = exactField || filters[`${filter}_exact`] === 'true';
    const fpid = (filterVal || '').includes('::') ? filterVal.split('::').shift() : '';

    if ((filterVal != null && filterVal !== '') || defaultDate || filters[`exclude_${filter}`]) {
      if (!field && !isQuery) {
        queryParams[String(filter)] = generate
          ? generate(filterVal, filters)
          : filterVal;
      } else if (generate) {
        // for complex query generation, it is easier to provide a method to create
        // the portion of the string and passing it the value
        queryParts.push(generate(filterVal, filters));
      } else {
        let value = '';
        if (!multiple) {
          value = exact ? `"${escapeExactFilter(filterVal || '')}"` : filterVal;
        } else if (filters[`exclude_${filter}`]) {
          value = (filterVal || filters[`exclude_${filter}`] || '').split(',').map(f => (exact ? `"${escapeExactFilter(f)}"` : f)).join();
        } else {
          value = (filterVal || '').split(',').map(f => (exact ? `"${escapeExactFilter(f)}"` : f)).join();
        }

        const filterParts = [
          !excluded ? '+' : '-',
          fpid ? fpidField : field,
          date
            ? `:[${!value || value === '*' ? '*' : value} TO ${filters[String(filterCorollary)] || 'now'}]`
            : [field ? ':(' : '(', fpid || value, ')'].join(''),
        ].filter(f => f);

        queryParts.push(filterParts.join(''));
      }
    }
  });

  const query = map()
    .set('query', queryParts.filter(v => v).join(' '))
    .set('traditional_query', true)
    .set('size', typeof filters.size !== 'undefined' && filters.size !== null ? filters.size : null)
    .set('source', filters.source ? filters.source.split(',') : null)
    .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields[String(type)])
    .set('_source_includes', id ? null : SearchFields.Includes[String(type)])
    .set('sort', filters.sort ? filters.sort.split(',') : (SearchFields.Sorts[String(type)] && [SearchFields.Sorts[String(type)][0].value]) || [])
    .merge(queryParams)
    .filter(v => typeof v !== 'undefined' && v !== null)
    .toJS();

  return query;
};

const parseFilters = (type = '', query = {}, exporting = false, defaults = {}) => {
  const { pathname, query: curQuery } = History.getCurrentLocation();
  const exportSize = 10000;
  const inlineQuery = { ...query };
  const params = { ...defaults[String(type)] || {}, ...curQuery };
  params.query = (params.query) && params.query.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
  const limit = exporting ? exportSize : inlineQuery.limit || params.limit;
  const skip = exporting ? 0 : inlineQuery.skip || params.skip;

  // Add backwards compatability for alerting urls on old thread requests
  if (type === 'threads' && !params.fpid) {
    if (!moment(params.id).isValid()) {
      params.fpid = params.id;
      params.id = undefined;
      History.push({ pathname, query: params });
    }
  }
  // Sorts may have changed in the past causing saved searches to fail,
  // double check the sorts of the query
  if (params.sort && !['all', 'communities'].includes(type)) {
    const availableSorts = (SearchFields.Sorts[String(type)]) ? SearchFields.Sorts[String(type)].map(v => v.value.split(':')[0]) : list();
    if (!availableSorts.some(v => params.sort.includes(v))) params.sort = null;
  }

  // Handle relative date search filters
  if (params.date && !params.since) {
    const { since, until } = parseDate(params);
    params.since = since;
    params.until = until;
  }
  if (inlineQuery.date && !inlineQuery.since) {
    const { since, until } = parseDate(inlineQuery);
    inlineQuery.since = since;
    inlineQuery.until = until;
  }

  // Handle enrichments in the query
  let hasChanges = false;
  if (['all', 'accounts', 'boards', 'blogs', 'cards', 'chats', 'communities', 'forums', 'marketplaces', 'media', 'pastes', 'ransomware', 'social', 'twitter'].includes(type)) {
    const enrichmentRegex = [
      { param: 'bins', transformed: 'bin', test: new RegExp(/\b(\s?\d{6}[*]\s?\|?)|\b(\s?\d{6}[?]{4,13}\s?\|?)/, 'gm') }, // bins
      { param: 'emails', transformed: 'email', extraParams: [{ key: 'email_exact', value: 'true' }], test: new RegExp(/\b([a-zA-Z\d._%+-]{1,256}@[a-zA-Z\d}.-]+\.[a-zA-Z]{1,256})\s?\|?/, 'gm') }, // emails
      { param: 'ips', transformed: 'ips', test: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'g') }, // ips
      { param: 'cves', tranformed: 'cves', test: new RegExp(/\b(CVE|cve)-\d{4}-(0\d{3}|[1-9]\d{3,})/, 'gm') }, // cves
    ];
    enrichmentRegex.forEach((v) => {
      const inSearch = (params.query || '').match(v.test) || [];
      if (inSearch.length > 0) {
        const paramValue = inSearch.map(m => m.replace(/\||\*|\?|\s/gm, ''))
          .filter(m => m)
          .join();
        const newQuery = (params.query || '')
          .replace(v.test, '')
          .replace(/(\|\s?)(\*|''|""|\s?\)|$)/gmi, '$2') // remove excess
          .trim();
        // only change the search if the full query is an enrichment. ie a list of bins or emails
        if (newQuery === '') {
          params[v.param] = paramValue;
          params.query = newQuery;
          params.transformed = v.transformed;
          if (v.extraParams) {
            v.extraParams.forEach((p) => {
              params[p.key] = p.value;
            });
          }
          hasChanges = true;
        }
      }
    });
  }

  // Handle author manipulation if quoted
  if (params.author && /".*?"/.test(params.author)) {
    // remove quotes
    params.author = params.author.replace(/^"(.*)"$/, '$1');
    params.author_exact = 'true';
    hasChanges = true;
  }

  if (hasChanges) {
    params.query = (params.query || '')
      .replace(/\(\s*\)|"\s*"|'\s*'/gm, '') // remove any excess () "" '' that wrapped enrichments
      .trim();
    History.replace({ pathname, query: params });
    // TODO: figure out a good way to stop the inital request from running
  }

  const filters = fromJS({ ...{}, ...params });
  const inline = fromJS({ ...{}, ...inlineQuery });

  // query has a pages attribute when exporting
  const combined = Object.keys(inlineQuery).filter(v => v !== 'pages').length
    ? fromJS({ ...defaults[String(type)], ...inline.toJS() })
    : fromJS({ ...defaults[String(type)], ...filters.toJS() });
  return [combined.merge({ limit, skip }).filter(v => v != null && v !== '').toJS(), filters, inline];
};

const loadFilters = (type = '', query = {}, exporting = false, defaults = {}) => {
  const parsed = parseFilters(type, query, exporting, defaults);
  const params = {
    ...defaults[String(type)] || {},
    ...History.getCurrentLocation().query,
  };

  // Load the org profile of the organization being searched
  if (type === 'credentials' && params.customer_id) {
    UserActions.loadOrgProfiles(params.customer_id);
  }

  const filters = parsed[1];
  const inline = parsed[2];
  SearchActions.set(['search', 'filters'], filters, false);
  SearchActions.set(['search', 'filters_inline'], inline, false);
  SearchActions.set(['search', 'type'], type.split('.').slice(-1).join(), false);

  return parsed;
};

const defaultDateFilter = {
  date: 'Last 7 Days',
  since: 'now-7d',
  until: 'now',
};

const defaultMediaFilter = {
  date: 'Last 24 Hours',
  since: 'now-24h',
  until: 'now',
};

const mergeDefaults = (queryObj, type = '') => {
  /* Updates the query object with default filters. These filters will not override existing values.
   *
   * @param queryObj  Query object to which defaults will be merged
   */
  let queryWithDefaults = queryObj;
  // Don't apply default date filter to report searches
  if (!queryObj.date && type !== 'reports') {
    queryWithDefaults = { ...queryObj, ...defaultDateFilter };
  }
  if (type === 'media') {
    queryWithDefaults = { ...queryObj, ...defaultMediaFilter };
  }
  return queryWithDefaults;
};

const SearchUtils = {
  buildQuery,
  parseDate,
  loadFilters,
  parseFilters,
  mergeDefaults,
  addExactKeyword,
  escapeExactFilter,
  escapeFiltersList,
  computeSitesQueryConditions,
  computeAuthorsExcludeQueryConditions,
};

export default SearchUtils;
