import { atom, selector } from 'recoil';

import { isEmpty, uniq } from 'lodash';

const ageFrom = atom({
  key: 'ageFrom',
  default: [],
});

const ageTo = atom({
  key: 'ageTo',
  default: [],
});

const biographicalLocation = atom({
  key: 'biographicalLocation',
  default: [],
});

const connection = atom({
  key: 'connection',
  default: [],
});

const domain = atom({
  key: 'domain',
  default: [],
});

const dueDate = atom({
  key: 'dueDate',
  default: '',
});

const email = atom({
  key: 'email',
  default: [],
});

const facebookID = atom({
  key: 'facebookID',
  default: [],
});

const incidentDetails = atom({
  key: 'incidentDetails',
  default: '',
});
const ipAddress = atom({
  key: 'ipAddress',
  default: [],
});

const job = atom({
  key: 'job',
  default: [],
});

const legalOptIn = atom({
  key: 'legalOptIn',
  default: false,
});

const locationOne = atom({
  key: 'locationOne',
  default: {},
});

const locationNumber = atom({
  key: 'locationNumber',
  default: 0,
});

const locationTwo = atom({
  key: 'locationTwo',
  default: {},
});

const locationThree = atom({
  key: 'locationThree',
  default: {},
});

const name = atom({
  key: 'name',
  default: [],
});

const phone = atom({
  key: 'phone',
  default: [],
});

const school = atom({
  key: 'school',
  default: [],
});

const threatLevel = atom({
  key: 'threatLevel',
  default: 'Undetermined',
});

const username = atom({
  key: 'username',
  default: [],
});

const ageRange = selector({
  key: 'ageRange',
  get: ({ get }) => {
    if (isEmpty(get(ageFrom)) && isEmpty(get(ageTo))) return null;
    const age = { from: get(ageFrom).toString(), to: get(ageTo).toString() };
    return age;
  },
});

const formData = selector({
  key: 'formData',
  get: ({ get }) => {
    const data = {
      threat_level: get(threatLevel),
      due_date: get(dueDate),
      name: uniq(get(name)),
      email: uniq(get(email)),
      phone: uniq(get(phone)),
      username: uniq(get(username)),
      location: uniq(
        [get(locationOne), get(locationTwo), get(locationThree), ...get(biographicalLocation)],
      ).filter(v => !isEmpty(v)),
      incident_details: get(incidentDetails),
      ip_address: uniq(get(ipAddress)),
      domain: uniq(get(domain)),
      age_range: get(ageRange),
      connection: uniq(get(connection)),
      job: uniq(get(job)),
      school: uniq(get(school)),
      legal_opt_in: get(legalOptIn),
    };

    const cleanedFormData = Object.fromEntries(Object.entries(data).filter(v => !isEmpty(v[1]) || typeof v[1] === 'boolean'));
    return cleanedFormData;
  },
});

const PersonSearchState = {
  ageFrom,
  ageRange,
  ageTo,
  biographicalLocation,
  connection,
  domain,
  dueDate,
  email,
  facebookID,
  formData,
  incidentDetails,
  ipAddress,
  job,
  legalOptIn,
  locationOne,
  locationNumber,
  locationTwo,
  locationThree,
  name,
  phone,
  school,
  threatLevel,
  username,
};

export default PersonSearchState;
