import qs from 'qs';
import Axios from 'axios';
import set from 'lodash/set';
import merge from 'lodash/merge';
import { v4 as uuidv4 } from 'uuid';

import Token from './token';

const SEARCH_UUID_KEY = 'X-FP-Search-UUID';
const SEARCH_FPID_KEY = 'X-FP-Search-Click-Resource-FPID';

const shouldAddStackHeader = () => Token.cke('X-FP-Stack');

const defaultHeaders = () => ({
  'Content-Type': 'application/json',
  'X-FP-Version': process.env.VERSION,
  ...(shouldAddStackHeader() && { 'X-FP-Stack': Token.cke('X-FP-Stack') }),
});

const cache = {
  /**
   * Read cache
   * @param {string} ckey cache selector
   * @param {string} key unique idenitifier within cache
   * @returns {object|undefined} cache value
   */
  get: (ckey, key) => cache?.[String(ckey)]?.[String(key)],
  /**
   * Write to cache
   * @param {string} ckey cache selector
   * @param {string} key unique idenitifier within cache
   * @param {object} value value associated with unique idenifier
   * @returns {object} cache value
   */
  set: (ckey, key, value) => {
    Object.assign(cache, {
      ...cache,
      [String(ckey)]: {
        ...cache?.[String(ckey)],
        [String(key)]: value,
      },
    });
    return value;
  },
};

const queryKey = (url, queryObj) => `${url}+${qs.stringify(queryObj)}`;
const getCachedPromise = (ckey, url, queryObj) => cache.get(ckey, queryKey(url, queryObj));

const Api = {
  pendingRequests: {},
  cancelRequests: () => {
    Object.keys(Api.pendingRequests).forEach((key) => {
      const source = Api.pendingRequests[String(key)];
      source.cancel('canceled');
    });
    Api.pendingRequests = {};
  },
  delete: (url, query, status = [200], timeout = 30000, body = {}, headers = {}) =>
    Axios.delete(url, {
      headers: {
        ...defaultHeaders(),
        ...headers,
      },
      params: query,
      data: body,
      timeout,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
    })
      .then(res => ({ ...res, ok: status.includes(res.status) })),
  /*
  GET requests can be canceled via an Axios CancelToken. By default, any GET
  requests will be canceled, unless the persist parameter is included. At the
  moment, all methods within Home.js bootstrap method are not canceled due to
  their importance of being run. By storing the non persistent requests within
  the api object, we can cancel them on Route changes in app.js via the onChange
  hook. When a request is canceled, an Error is thrown with the message 'canceled'
  which is used in the catch statements of GET requests to determine whether to
  show error messages or not.
  */
  get: (
    url,
    query = {},
    status = [200],
    timeout = 30000,
    headers = {},
    persist = false,
  ) => {
    const token = Axios.CancelToken;
    const source = token.source();
    const key = `${url}+${qs.stringify(query)}`;

    if (!persist) {
      if (Api.pendingRequests[String(key)]) Api.pendingRequests[String(key)].cancel('canceled');
      Api.pendingRequests[String(key)] = source;
    }

    return Axios.get(url, {
      headers: {
        ...defaultHeaders(),
        ...headers,
      },
      params: query,
      timeout: 120000,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
      cancelToken: source.token,
    })
      .then((res) => {
        delete Api.pendingRequests[String(key)];
        return res;
      })
      .then(res => ({ ...res, ok: status.includes(res.status) }));
  },
  post: (
    url,
    query = {},
    status = [200, 204],
    timeout = 120000,
    headers = {},
    persist = false,
  ) => {
    const { href } = window.location;
    const token = Axios.CancelToken;
    const source = token.source();
    const key = `${url}+${qs.stringify(query)}`;
    const isDetail = [/\/ddw/].some(v => v.test(href)); // detail request
    const isSearch = [/\/search/].some(v => v.test(href)); // search request
    const fpid = sessionStorage?.getItem(SEARCH_FPID_KEY);
    const uuid = isSearch ? uuidv4() : sessionStorage?.getItem(SEARCH_UUID_KEY);
    const startMark = `API.post:start-${uuid}`;
    const endMark = `API.post:end-${uuid}`;
    const measureMark = `API.post:${uuid}`;
    // Persist search queries that load the sites by default
    const fullPersist = persist || (query && query.query && query.query.indexOf('+basetypes:(site)') !== -1);

    if (!fullPersist) {
      if (Api.pendingRequests[String(key)]) Api.pendingRequests[String(key)].cancel('canceled');
      Api.pendingRequests[String(key)] = source;
    }

    if (isSearch) {
      sessionStorage?.removeItem(SEARCH_FPID_KEY);
      sessionStorage?.setItem(SEARCH_UUID_KEY, uuid);
      performance.mark(startMark);
    }

    if (isDetail) {
      sessionStorage?.removeItem(SEARCH_FPID_KEY);
    }

    return Axios.post(url, query, {
      headers: {
        ...defaultHeaders(),
        ...headers,
        [SEARCH_FPID_KEY]: fpid || '',
        [SEARCH_UUID_KEY]: uuid || '',
      },
      timeout: 120000,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
      withCredentials: true,
      cancelToken: source.token,
    })
      .then((res) => {
        delete Api.pendingRequests[String(key)];
        return res;
      })
      .then(res => ({ ...res, ok: status.includes(res.status) }))
      .then((res) => {
        if (isSearch) {
          performance.mark(endMark);
          performance.measure(measureMark, startMark, endMark);
        }
        return res;
      })
      .catch((err) => {
        if (isSearch && err.message !== 'canceled' && /ECONNABORTED/.test(err.code)) {
          // timed out
          performance.mark(endMark);
          performance.measure(measureMark, startMark, endMark);
        }
        throw err;
      })
      .finally((res) => {
        performance.clearMarks(startMark);
        performance.clearMarks(endMark);
        performance.clearMeasures(measureMark);
        return res;
      });
  },
  put: (url, query = {}, status = [200], timeout = 30000, headers = {}) =>
    Axios.put(url, query, {
      headers: {
        ...defaultHeaders(),
        ...headers,
      },
      timeout,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
    })
      .then(res => ({ ...res, ok: status.includes(res.status) })),
  patch: (url, query = {}, status = [200], timeout = 30000, headers = {}) =>
    Axios.patch(url, query, {
      headers: {
        ...defaultHeaders(),
        ...headers,
      },
      timeout,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
    })
      .then(res => ({ ...res, ok: status.includes(res.status) })),
  patchWithParams: (url, query = {}, status = [200], timeout = 30000, headers = {}) =>
    Axios.patch(url, query, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'X-FP-Version': process.env.VERSION,
      },
      params: query,
      timeout,
      responseType: headers?.responseType || 'json',
      validateStatus: resStatus => status.includes(resStatus),
    })
      .then(res => ({ ...res, ok: status.includes(res.status) })),

  log: () => null,

  // unwrap highlight field data to _source object where applicable
  mapHighlightToSource: (data, translate = false) => ({
    ...data,
    hits: {
      ...data.hits,
      hits: data.hits.hits
        .map(hit => ({
          ...hit,
          _source: {
            ...hit?._source,
            enrichments: {
              ...hit?._source?.enrichments,
              v1: {
                ...hit?._source?.enrichments?.v1,
                translation: merge({}, ...hit?._source?.enrichments?.v1?.translation || []),
              },
            },
          },
        }))
        .map(hit => ({
          ...hit,
          _source: merge(
            hit?._source,
            ...Object
              .entries(hit?.highlight || {})
              .filter(([_k]) => !/basetypes|aliases/ig.test(_k))
              .map(([_k, _v]) => set(
                {},
                _k.replace(/\.keyword/ig, ''),
                /id$/ig.test(_k)
                  ? _v[0]?.replace(/<\/?x-fp-highlight>/ig, '')
                  : _v[0])),
          ),
        }))
        .map(hit => ({
          ...hit,
          _source: merge(
            hit?._source,
            translate
              ? Object.entries(hit?._source?.enrichments?.v1?.translation || {})
                  ?.filter(([_k]) => !/path/ig.test(_k))
                  ?.reduce((a, [_k, _v]) => ({ ...a, [_k]: _v }), {})
              : {},
          ),
        })),
    },
  }),
};
export const cachedRequest = (requestFunc, url, query = {}, status, timeout, headers, persist) => {
  const cachedPromise = getCachedPromise('post', url, query);
  if (cachedPromise) return cachedPromise;

  const promise = requestFunc(url, query, status, timeout, headers, persist).then((res) => {
    cache.set('post', queryKey(url, query), null);
    return res;
  });

  cache.set('post', queryKey(url, query), promise);

  return promise;
};

// args: url, query, status, timeout, headers, persist
export const cachedPost = (...requestArgs) => (
  cachedRequest(Api.post, ...requestArgs)
);

export const cachedGet = (...requestArgs) => (
  cachedRequest(Api.get, ...requestArgs)
);

export const cachedPut = (...requestArgs) => (
  cachedRequest(Api.put, ...requestArgs)
);

export const cachedDelete = (...requestArgs) => (
  cachedRequest(Api.delete, ...requestArgs)
);

export default Api;
