import React, { useEffect, useState } from 'react';

import { isEmpty, uniq } from 'lodash';
import { CircularProgress, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BiographicalData from './BiographicalData';
import ClearDataComponent from './ClearDataComponent';
import InputComponent from './InputComponent';
import PersonSearchError from '../PersonSearchError';
import SearchApiHits from './SearchApiHits';
import UsernameCheck from './UsernameCheck';
import Query from '../query';
import History from '../../../utils/history';
import SearchFields from '../../../constants/SearchFields';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    borderRadius: '2px !important',
    boxShadow: '0 0 6px rgba(0, 0, 0, .12), 0 0px 4px rgba(0, 0, 0, .24) !important',
    fontSize: '12px',
    margin: '15px 0',
    padding: '20px',
  },
  grid: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '800px',
  },
});

const PersonSearchResults = () => {
  const classes = useStyles();
  const [clearData, setClearData] = useState([]);
  const [customer, setCustomer] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputData, setInputData] = useState({});
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [searchApiLoading, setSearchApiLoading] = useState(true);
  const [searchApiHits, setSearchApiHits] = useState({});
  const [searchEnrichments, setSearchEnrichments] = useState('');
  const [usernameCheckData, setUsernameCheckData] = useState([]);

  const searchPath = `${window.location.origin}/home/search/communities?query=${encodeURIComponent(searchEnrichments)}&query_i18n=en`;
  const currentPath = History.getCurrentLocation().pathname;
  const reportId = currentPath.split('/').splice(-1);

  const noResultsMessage = () => <Typography variant="subtitle2">No results found.</Typography>;

  const getSearchApiHits = () => {
    if (searchApiHits?.total) {
      return (
        <Grid>
          <Link
            color="secondary"
            gutterBottom
            href={searchPath}
            target="_blank"
            variant="caption">{searchApiHits?.total} hits
          </Link>
          <SearchApiHits data={searchApiHits} searchPath={searchPath}/>
        </Grid>
      );
    } else {
      return noResultsMessage();
    }
  };

  const getClearData = () => clearData?.results?.map((v) => {
    const resultNumber = clearData.results.indexOf(v) + 1;
    const resultLength = clearData.results.length;
    return (
      <ClearDataComponent
        key={v.id}
        result={v.data}
        resultLength={resultLength}
        resultNumber={resultNumber}
        searchParamMatch={v.search_parameters}/>
     );
  });

  const getUsernameCheckData = () => usernameCheckData?.results?.map((v) => {
    const resultNumber = usernameCheckData.results.indexOf(v) + 1;
    const resultLength = usernameCheckData.results.length;
    return (
      <UsernameCheck
        key={v.id}
        result={v.data}
        resultNumber={resultNumber}
        resultLength={resultLength}/>
    );
  });

  useEffect(() => {
    const queryReport = () => Query.getResults(reportId)
      .then((res) => {
        if (res.status === 'COMPLETED') {
          setInputData(res.input_data);
          setClearData(res.results?.clear_persons_search);
          setCustomer(res.user?.username);
          setProcessing(false);
          setUsernameCheckData(res.results?.username_check);
        } else if (res.status === 'PROCESSING') {
          setTimeout(queryReport, 2000);
          setProcessing(true);
        }
       })
      .catch((err) => {
        setError(true);
        setErrorMessage(err.response?.data?.detail);
        throw err;
      })
      .finally(() => setLoading(false));

    queryReport();
  }, []);

  useEffect(() => {
    const { domain, email, ip_address, username } = inputData;
    const type = 'all';
    const clearEmail = clearData.results?.map(v => v.data.RecordDetails['ns3:PersonResponseDetail'].EmailAddress).filter(v => v);
    const emailAggregation = email || [];

    if (clearEmail) clearEmail.map(v => [...emailAggregation, v]);

    // if no enrichments are available to search on, skip the api call
    if (!domain && !ip_address && !emailAggregation.length && !username) {
      if (!isEmpty(inputData)) setSearchApiLoading(false);
      return;
    }

    const baseQueryString = '+basetypes:(paste OR chan OR gab OR reddit OR ransomware OR blog OR (forum AND post) OR twitter OR (chat AND message) OR generic-product OR card OR account OR vulnerability OR exploit OR indicator_attribute) +sort_date:[* TO now] +(';
    const enrichmentString = [
      ip_address
        ? `|enrichments.v1.ip_addresses.ip_address:(${ip_address.map(v => `"${v}"`).join(' OR ')})`
        : null,
      emailAggregation.length
        ? `|enrichments.v1.email_addresses.email_address:(${uniq(emailAggregation).map(v => `"${v}"`).join(' OR ')})`
        : null,
      domain
        ? `${domain
          .toString()
          .replace(/\*/ig, '')
          .split(',')
          .map(v => `|enrichments.v1.urls.domain:"${v}"`)
          .join(' ')}`
        : null,
      username
        ? `${username
          .toString()
          .replace(/\*/ig, '')
          .split(',')
          .map(v => `|site_actor.names.handle:"${v}"|enrichments.v1.social_media.handle:"${v}"`)
          .join(' ')}`
        : null,
    ].filter(v => v).join(' ');

    // need to handle single enrichment
    setSearchEnrichments(enrichmentString);
    const queryString = baseQueryString.concat(`${enrichmentString})`);
    const query = {
      _source_includes: SearchFields.Includes[String(type)],
      fields: SearchFields.Fields[String(type)],
      highlight: true,
      highlight_size: 250,
      query: queryString,
      sort: ['sort_date:desc'],
      size: 10,
    };

    Query.Hits(query)
      .then(res => setSearchApiHits(res?.data?.hits))
      .catch((err) => {
        setError(true);
        setErrorMessage(err.response?.data?.detail);
        throw err;
      })
      .finally(() => setSearchApiLoading(false));
  }, [loading, processing]);

  if (error) {
    return <Grid xs={12}><PersonSearchError message={errorMessage} setError={setError}/></Grid>;
  }
  if (processing) {
    return (
      <Grid alignItems="center" container direction="column" style={{ marginTop: '8rem' }}>
        <Typography variant="h5">Processing request</Typography>
        <CircularProgress size={25} style={{ marginTop: '4rem' }} />
      </Grid>
    );
  }

  return (
    <Grid className={classes.grid} container>
      {!loading &&
        <Grid>
          <Grid className={classes.card}>
            <InputComponent customer={customer} inputData={inputData}/>
          </Grid>
          <Grid className={classes.card}>
            <Typography gutterBottom variant="h5">Biographical Data</Typography>
            <BiographicalData inputData={inputData}/>
          </Grid>
          <Grid className={classes.card}>
            <Typography gutterBottom variant="h5">CLEAR Results</Typography>
            {!clearData?.num_results ? noResultsMessage() : getClearData()}
          </Grid>
          <Grid className={classes.card}>
            <Typography gutterBottom variant="h5">Username Check Results</Typography>
            {!usernameCheckData?.num_results ? noResultsMessage() : getUsernameCheckData()}
          </Grid>
          <Grid className={classes.card}>
            <Typography variant="h5">FP Tools Results</Typography>
            {searchApiLoading ? <CircularProgress /> : getSearchApiHits()}
          </Grid>
        </Grid>}
    </Grid>
  );
};

export default PersonSearchResults;
