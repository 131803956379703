import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { useRecoilValue } from 'recoil';
import { isEmpty, isEqual } from 'lodash';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import useQueryString from '../../../hooks/useQueryString';
import Looker from '../../utils/Looker';
import History from '../../../utils/history';
import { getSfid } from '../../../stores/recoil/app';
import { sanitizeFilters } from '../../../hooks/useLookerApi';
import { fraudCardStyles } from './FullCards';

const useStyles = makeStyles(() => fraudCardStyles);

const MyCards = () => {
  const classes = useStyles();
  const sid = useRecoilValue(getSfid);
  const [lookerDash, setLookerDash] = useState();

  const [address] = useQueryString('Address');
  const [bin] = useQueryString('BIN');
  const [binTags] = useQueryString('BIN Tags');
  const [cvv] = useQueryString('CVV Code');
  const [city] = useQueryString('City');
  const [country] = useQueryString('Country');
  const [dateRange] = useQueryString('Date Range');
  const [expiration] = useQueryString('Expiration');
  const [firstName] = useQueryString('First Name');
  const [lastName] = useQueryString('Last Name');
  const [lastNumbers] = useQueryString('Last Numbers');
  const [pii] = useQueryString('PII Fields Available');
  const [region] = useQueryString('Region');
  const [shopName] = useQueryString('Shop Name');
  const [sourceType] = useQueryString('Source Type');
  const [zipcode] = useQueryString('Zipcode');

  const [myCardFilters, setMyCardFilters] = useState({});

  const match = useRouteMatch();
  const params = useParams();

  const { pathname, query } = History.getCurrentLocation();

  // handles filters from query string
  useEffect(() => {
    const filters = {
      Address: address,
      BIN: bin,
      'BIN Tags': binTags,
      'CVV Code': cvv,
      City: city,
      Country: country,
      'Date Range': dateRange,
      Expiration: expiration,
      'First Name': firstName,
      'Last Name': lastName,
      'Last Numbers': lastNumbers,
      'PII Fields Available': pii,
      Region: region,
      'Shop Name': shopName,
      'Source Type': sourceType,
      Zipcode: zipcode,
    };

    setMyCardFilters(filters);

    if (!lookerDash) return;
    lookerDash.send('dashboard:filters:update', {
      filters,
    });
    lookerDash.send('dashboard:run');
  }, [address, bin, binTags, cvv, city, country, dateRange, expiration,
    firstName, lastName, lastNumbers, pii, region, shopName, sourceType, zipcode]);

  // handles filters from iframe
  useEffect(() => {
    const sanitized = sanitizeFilters(myCardFilters);
    if (!isEqual(query, sanitized)) {
      if (!isEmpty(sanitized)) {
        setMyCardFilters({
          ...sanitized,
        });

        History.push({
          pathname,
          query: {
            ...sanitized,
          },
        });
      }
    }
  }, [myCardFilters]);

  if (!sid) {
    return <CircularProgress />;
  }

  return (
    <Grid fluid className={classes.cards}>
      <Row>
        <Col xs={12}>
          <nav className={classes.tabBar}>
            <Button
              onClick={() => History.push({
                pathname: 'overview',
                query,
              })}
              disabled={match?.url?.includes('mycards/overview')}
            >
              Overview
            </Button>
            <Button
              onClick={() => History.push({
                pathname: 'shops',
                query,
              })}
              disabled={match?.url?.includes('mycards/shops')}
            >
              Shops
            </Button>
            <Button
              onClick={() => History.push({
                pathname: 'releases',
                query,
              })}
              disabled={match?.url?.includes('mycards/releases')}
            >
              Releases
            </Button>
            <Button
              onClick={() => History.push({
                pathname: 'bins',
                query,
              })}
              disabled={match?.url?.includes('mycards/bins')}
            >
              Bins
            </Button>
            <Button
              onClick={() => History.push({
                pathname: 'export',
                query,
              })}
              disabled={match?.url?.includes('mycards/export')}
            >
              Export
            </Button>
          </nav>

          {['overview'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::my_cards"
            filters={myCardFilters}
            setFilters={setMyCardFilters}
            setupDashboard={(dash) => { setLookerDash(dash); }}
            sid={sid} />}
          {['shops'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::all_shops"
            filters={myCardFilters}
            setFilters={setMyCardFilters}
            setupDashboard={(dash) => { setLookerDash(dash); }}
            sid={sid} />}
          {['releases'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::all_releases"
            filters={myCardFilters}
            setFilters={setMyCardFilters}
            setupDashboard={(dash) => { setLookerDash(dash); }}
            sid={sid} />}
          {['bins'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::my_bins"
            filters={myCardFilters}
            setFilters={setMyCardFilters}
            setupDashboard={(dash) => { setLookerDash(dash); }}
            sid={sid} />}
          {['export'].includes(params.subtype) && <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::export"
            filters={myCardFilters}
            setFilters={setMyCardFilters}
            setupDashboard={(dash) => { setLookerDash(dash); }}
            sid={sid} />}
        </Col>
      </Row>
    </Grid>
  );
};

export default MyCards;
