/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Button } from '@mui/material';
import { List as list, Map as map } from 'immutable';
import { Row, Col } from 'react-flexbox-grid/lib';

import MetaDataActions from '../../actions/metaDataActions';
import Text from '../../utils/text';
import style from './tagger.module.scss';

const Tagger = ({
  filter,
  fpid,
  metaData,
  prm,
  selected,
  username,
  onAction,
  onChange,
  styles,
}) => {
  const [primaryTags, setPrimaryTags] = useState(list());
  const [secondaryTags, setSecondaryTags] = useState(list());
  const [primaryTagsConst, setPrimaryTagsConst] = useState(list());
  const [secondaryTagsConst, setSecondaryTagsConst] = useState(list());
  const [primaryChip, setPrimaryChip] = useState('');
  const [secondaryChip, setSecondaryChip] = useState('');
  const [untagged, setUntagged] = useState(false);
  const [entityTags, setEntityTags] = useState(list());
  const [readOnlyTags, setReadOnlyTags] = useState(list());
  const [noChannel, setNoChannel] = useState(true);

  const chatTagPermission = prm.some(p => /meta.chat.w/.test(p));
  const chatTagReadPermission = prm.some(p => /meta.r/.test(p));

  const setStartingTags = () => {
    const allTags = metaData.getIn(['tags']) || list();
    const current = allTags.filter(v => entityTags.map(t => t.get('tag_id')).includes(v.get('id')));
    const primary = current
      .filter(v => v.get('primary_tag') == null)
      .map(v => v.get('id'));
    const secondary = current
      .filter(v => v.get('primary_tag') != null)
      .map(v => v.get('id'));

    setPrimaryTags(primary);
    setSecondaryTags(secondary);
    setReadOnlyTags(current);
  };

  useEffect(() => {
    if (!fpid || list.isList(fpid)) return;
    if (chatTagReadPermission) {
      MetaDataActions.getChannel(fpid, chatTagPermission);
      MetaDataActions.getChannelTags(fpid, chatTagPermission);
    }
  }, [fpid]);

  useEffect(() => {
    const channels = metaData.getIn(['channels', 'data']);
    if (!list.isList(fpid)) {
      if (channels && !channels.isEmpty()) {
        const index = channels.find(v => v.get('fpid') === fpid);
        setNoChannel(index === -1);
      }
      if (metaData.getIn(['tagged', fpid])) setEntityTags(metaData.getIn(['tagged', fpid]));
    } else {
      // you can only  bulk edit tags on the channels tagging page, so the channels exist
      setNoChannel(false);
    }
    if (!(metaData.get('tags') || list()).isEmpty()) {
      const allTags = metaData.get('tags');
      setPrimaryTagsConst(allTags.filter(v => v.get('primary_tag') == null));
      setSecondaryTagsConst(allTags.filter(v => v.get('primary_tag') != null));
    }
  }, [metaData]);

  useEffect(() => {
    if (filter && selected) {
      if (selected.get(0) === 'Untagged') {
        setUntagged(true);
        return;
      }
      setUntagged(false);
      // const allTags = metaData.getIn(['tags']);
      // const current = allTags.filter(v => selected.includes(v.get('id')));
      const primary = selected
        .filter(v => v.get('primary_tag') == null)
        .map(v => v.get('id'));
      const secondary = selected
        .filter(v => v.get('primary_tag') != null)
        .map(v => v.get('id'));

      setPrimaryTags(primary);
      setSecondaryTags(secondary);
    }
  }, [selected]);

  useEffect(() => {
    if (!filter) {
      setStartingTags();
    }
  }, [entityTags]);

  useEffect(() => {
    if (!primaryChip) return;
    let primary = primaryTags;
    let secondary = secondaryTags;
    if (primary.includes(primaryChip)) {
      primary = primaryTags.filterNot(v => v === primaryChip);
      const allTags = metaData.getIn(['tags']);
      if (!allTags) return;
      secondary = secondaryTags.filterNot((v) => {
        const tag = allTags.find(t => t.get('id') === v);
        return tag.get('primary_tag') === primaryChip;
      });
      setSecondaryTags(secondary);
    } else {
      primary = primaryTags.push(primaryChip);
    }
    setPrimaryTags(primary);

    if (onChange) {
      const tags = primary.concat(secondary).filter(v => v);
      onChange(tags);
    }
    setPrimaryChip('');
    if (!primary.isEmpty() || !secondary.isEmpty()) setUntagged(false);
  }, [primaryChip]);

  useEffect(() => {
    if (!secondaryChip) return;
    let primary = primaryTags;
    let secondary = secondaryTags;
    const allTags = metaData.getIn(['tags']);
    if (!allTags) return;
    if (secondary.includes(secondaryChip)) {
      secondary = secondaryTags.filter(x => x !== secondaryChip);
    } else {
      secondary = secondaryTags.push(secondaryChip);
    }
    setSecondaryTags(secondary);

    secondary.forEach((v) => {
      const tag = allTags.find(t => t.get('id') === v);
      const parentTagId = tag.get('primary_tag');
      if (parentTagId && !primary.includes(parentTagId)) {
        primary = primary.push(parentTagId);
      }
    });
    setPrimaryTags(primary);

    if (onChange) {
      const tags = primary.concat(secondary).filter(v => v);
      onChange(tags);
    }
    setSecondaryChip('');
    if (!primary.isEmpty() || !secondary.isEmpty()) setUntagged(false);
  }, [secondaryChip]);

  useEffect(() => {
    if (!untagged) return;
    setPrimaryTags(list());
    setSecondaryTags(list());
    if (onChange) {
      onChange(list(), true);
    }
  }, [untagged]);

  const onCancel = () => {
    setStartingTags();
    if (onAction) onAction();
  };

  const onSaveTags = () => {
    const toSaveFpids = (list.isList(fpid)) ? fpid.toJS() : [fpid];

    toSaveFpids.forEach((entityFpid) => {
      const entityLoadedTags = (!entityTags.isEmpty()) ? entityTags : metaData.getIn(['tagged', entityFpid]) || list();
      const newTags = primaryTags.concat(secondaryTags).filter(v => v);
      const startingTags = entityLoadedTags.map(v => v.get('tag_id'));
      // at this point, deleted is a list of tag ids, need to get list of association ids
      const deleted = startingTags.filter(x => !newTags.includes(x));
      const added = newTags.filter(x => !startingTags.includes(x));
      const tagsUsername = Text.getUsernameFromEmail(username);

      if (!added.isEmpty()) {
        MetaDataActions.addTagAssociations(entityFpid, added, tagsUsername);
      }
      if (!deleted.isEmpty()) {
        const deletedAssociations = entityLoadedTags
          .filter(v => deleted.includes(v.get('tag_id')))
          .map(v => v.get('id'));
        MetaDataActions.deleteTagAssociations(entityFpid, deletedAssociations);
      }
    });
    if (onAction) onAction();
  };

  return (
    <div className={style.tagger} style={styles}>
      {chatTagReadPermission && !chatTagPermission &&
      <div>
        <Row className={style.row}>
          <Col xs={12}>
            <h2>Channel Tags</h2>
            {readOnlyTags.isEmpty() &&
            <Button
              key="untagged"
              className={cx([style.button, style.option, style.selected])}
              disabled>
              Untagged
            </Button>}
            {readOnlyTags
              .filter(v => v)
              .map(v => (
                <Button
                  key={v.get('id')}

                  label={v.get('tag_name')}
                  className={cx([style.button, style.option, style.selected])}
                  disabled />
              ))}
          </Col>
        </Row>
      </div>}
      {chatTagPermission && (!noChannel || filter) &&
        <div>
          <Row className={style.row}>
            <Col xs={12}>
              {filter &&
              <Button
                key="untagged"
                className={cx([style.button, style.option,
                  untagged && style.selected])}
                onClick={() => setUntagged(!untagged)}>
                Untagged
              </Button>}
              <div className={cx([style.label])}>Primary Tags:</div>
              <div className={style.options}>
                {primaryTagsConst
                  .map(v => (
                    <Button
                      key={v.get('id')}
                      className={cx([style.button, style.option,
                        primaryTags.includes(v.get('id')) && style.selected])}
                      onClick={() => setPrimaryChip(v.get('id'))}>
                      {v.get('tag_name')}
                    </Button>))}
              </div>
            </Col>
          </Row>
          <Row className={style.row}>
            <Col xs={12}>
              <div className={cx([style.label])}>Secondary Tags:</div>
              <div className={style.options}>
                {secondaryTagsConst
                  .map(v => (
                    <Button
                      key={v.get('id')}
                      className={cx([style.button, style.option,
                        secondaryTags.includes(v.get('id')) && style.selected])}
                      onClick={() => setSecondaryChip(v.get('id'))}>
                      {v.get('tag_name')}
                    </Button>))}
              </div>
            </Col>
          </Row>
          {!filter &&
          <Row>
            <Col className={style.tags} xs={12}>
              <div className={style.error} />
              <div className={style.actions}>
                <div className={style.buttoncontainer}>
                  <Button
                    className={cx([style.button, style.cancel])}
                    name="tagger.cancel"
                    onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Button
                    className={style.button}
                    name="tagger.apply"
                    onClick={() => onSaveTags()}>
                    Apply
                  </Button>
                </div>
              </div>
            </Col>
          </Row>}
        </div>}
      {chatTagPermission && noChannel && !filter &&
      <div>
        <Row className={cx([style.row, style['no-channel']])}>
          <Col xs={12}>
            <h2>Unable to Tag Channel</h2>
            <div>
              Please reach out to #team-tagging with the following message:
              "I am unable to tag the channel with the fpid {fpid} because it
              is not in the metadata-api"
            </div>
          </Col>
        </Row>
      </div>}
    </div>
  );
};

Tagger.propTypes = {
  filter: PropTypes.bool,
  fpid: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  metaData: PropTypes.object,
  prm: PropTypes.object,
  selected: PropTypes.object,
  styles: PropTypes.object,
  username: PropTypes.string,
  onAction: PropTypes.func,
  onChange: PropTypes.func,
};

Tagger.defaultProps = {
  filter: false,
  fpid: '',
  metaData: map(),
  prm: list(),
  selected: null,
  styles: {},
  username: '',
  onAction: null,
  onChange: null,
};

export default Tagger;
