import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import { escape } from 'lodash';
import moment from 'moment';
import { Map as map, List as list } from 'immutable';
import {
  Icon,
} from '@mui/material';

import style from './bookmark.module.scss';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import Text from '../../utils/text';
import Common from '../../utils/common';
import Editor from '../manage/Editor';
import Messages from '../../constants/Messages';
import { UserContext } from '../utils/Context';

const Bookmark = ({
  author,
  entity,
  timestamp,
  title,
  type,
}) => {
  const uid = window.location.pathname.split('/').slice(-1).join();
  const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  const user = useContext(UserContext);
  const [bookmarkPath, setBookmarkPath] = useState({ uid, path });
  const currentBookmarks = user.getIn(['prefs', 'bookmarks']);

  useEffect(() => {
    if (entity.isEmpty()) return;
    const pathname = !entity.hasIn(['data', 0])
      ? Common.Bookmark.Path(entity)
      : { uid, path };
    setBookmarkPath(pathname);
  }, [entity]);

  function onSave(_, values, bookmarks, globalDialogParams) {
    const updates = values
      .map(v => v
        .set('name', escape(v.get('name')))
        .set('folders', v.get('folders').map(f => escape(f)))
        .set('path', bookmarkPath.path)
        .set('updated', v.get('updated') || moment.utc().unix()));
    const final = new Map().set(bookmarkPath.uid, updates.first());
    const update = map({
      bookmarks: bookmarks.concat(final).filter(v => v !== undefined),
    });

    UserActions.savePrefs(update.toJS());
    SearchActions.set(['search', 'info', 'message'], Messages.BookmarkUpdated);
    globalDialogParams.close();
  }

  function onDelete(bookmarks) {
    const update = bookmarks.delete(bookmarkPath.uid);
    UserActions.savePrefs({ bookmarks: update });
    SearchActions.set(['search', 'info', 'message'], Messages.BookmarkDeleted);
  }

  function onDispatchDialogEvent(e, edit, bookmarks) {
    e.preventDefault();
    let tagList = [];
    if (bookmarks) {
      bookmarks.map((details) => {
        if (details.get('folders')) {
          tagList = tagList.concat(details.get('folders').toJS());
        }
        return true;
      });
    }
    tagList = Array.from(new Set(tagList));
    const selected = (!edit)
      ? null
      : bookmarks.get(bookmarkPath.uid);
    const dialogTitle = (edit) ? 'Edit Bookmark' : 'Add Bookmark';
    const globalDialog = {
      title: dialogTitle,
      dialog: globalDialogParams => (edit
        ? (
          <Editor
            fields={[
              { value: 'name', label: 'Bookmark Name', type: 'text', req: true },
              { value: 'folders', label: 'Bookmark Tags', type: 'chips', bulk: true, dataSource: selected && selected.get('folders').toArray() }]}
            type={type}
            data={((list().push(selected) || list()))}
            save={(saveType, values) => onSave(saveType, values, bookmarks, globalDialogParams)}
            deleteActions={() => onDelete(bookmarks)}
            toggle={() => globalDialogParams.close()} />
        )
        : (
          <Editor
            fields={[
              { value: 'name', label: 'Bookmark Name', type: 'text', req: true, defaults: author
                ? Text.StripHighlight(`${author} ${timestamp ? `- ${moment.utc(timestamp).format('MM/DD/YYYY HH:mm:ss')}` : ''}`)
                : Text.StripHighlight(title) },
              { value: 'folders', label: 'Bookmark Tags', type: 'chips', bulk: true, dataSource: tagList, defaults: [] }]}
            type={type}
            data={list()}
            save={(saveType, values) => onSave(saveType, values, bookmarks, globalDialogParams)}
            toggle={() => globalDialogParams.close()} />
        )),
    };
    Common.Events.DispatchGlobalDialogEvent(globalDialog, e);
  }

  return (
    <div className={style.bookmark}>
      <Icon
        data-for="global.tooltip"
        data-tip="Bookmark"
        className={cx([style.hover, 'active'])}
        onClick={e => onDispatchDialogEvent(e, user.hasIn(['prefs', 'bookmarks', bookmarkPath.uid]), currentBookmarks)}>
        {bookmarkPath && user.hasIn(['prefs', 'bookmarks', bookmarkPath.uid])
          ? 'bookmark'
          : 'bookmark_border'}
      </Icon>
    </div>
  );
};

Bookmark.propTypes = {
  author: PropTypes.string,
  entity: PropTypes.object,
  timestamp: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

Bookmark.defaultProps = {
  author: '',
  entity: map(),
  timestamp: '',
  title: '',
  type: '',
};

export default Bookmark;
