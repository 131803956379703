import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useInlineMemo from 'use-inline-memo';

import qs from 'qs';
import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import ReactTooltip from 'react-tooltip';

import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete as MuiAutocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  Icon,
  InputLabel,
  Link,
  ListItem,
  Popover,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';

import style from './header.module.scss';
import Text from '../../utils/text';
import Api from '../../utils/api';
import Common from '../../utils/common';
import Bookmark from '../utils/Bookmark';
import Messages from '../../constants/Messages';
import Find from './Find/Find';
import ProgressBar from './ProgressBar';
import DateFilter from '../filters/DateFilter';
import TextFilter from '../filters/TextFilter';
import UserActions from '../../actions/userActions';
import SearchActions from '../../actions/searchActions';
import History from '../../utils/history';
import AlertingStore from '../../stores/recoil/alerting';
import { appStore } from '../../stores/recoil/app';
import RfiBanner from '../rfi/RfiBanner/RfiBanner';
import RfiPopupForm from '../rfi/RfiPopupForm';
import rfiFormState from '../../stores/recoil/rfi';
import {
  SearchContext,
  UserContext,
} from '../../components/utils/Context';
import AlertQuery from '../../containers/Alerting/query';

const useStyles = makeStyles(theme => ({
  header: {
    '& .date': {
      margin: theme.spacing(1),
      padding: '1.5rem 0',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
        justifyContent: 'space-between',
      },
    },
    '& .controls': {
      margin: `0 ${theme.spacing(1)}`,
      '& .MuiAutocomplete-root': {
        '& .MuiAutocomplete-input': {
          padding: '.5rem !important',
          width: '12.5rem',
        },
        '& .MuiAutocomplete-endAdornment': {
          right: 0,
        },
      },
      '& .MuiIcon-root': {
        margin: `0 ${theme.spacing(1)}`,
      },
    },
  },
  subnav: {
    width: 'calc(100% - 70px)',
  },
  noHeader: {
    display: 'none',
  },
}));

const RfiLocations = [
  'Account',
  'Board',
  'Card',
  'Channel',
  'Exploits',
  'Forum Visit',
  'IOC',
  'Marketplace',
  'News',
  'Page',
  'Paste',
  'Post',
  'Release',
  'Report',
  'Thread',
  'Vulnerability (CVE)',
];

const Header = ({
  access,
  params,
  showSearchOverlay,
  setShowSearchOverlay,
}) => {
  const classes = useStyles();
  const search = useContext(SearchContext) || map();
  const user = useContext(UserContext) || map();
  const { query: { query_i18n } } = History.getCurrentLocation();

  const [dialog, setDialog] = useState();
  const [loading, setLoading] = useState();
  const [optionsDialog, setOptionsDialog] = useState();
  const [hasUiPermission, setHasUiPermission] = useState(false);
  const [title, setTitle] = useState('');
  const [selection, setSelection] = useState(map());
  const [values, setValues] = useState(map());
  const [dashboardProfile, setDashboardProfile] = useState(map());
  const [tempProfile, setTempProfile] = useState(map());
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [subnav, setSubnav] = useState();
  const [rfiBanner, setRfiBanner] = useState(false);
  const [appState, setAppState] = useRecoilState(appStore);
  const [alertingFilters, setFilters] = useRecoilState(AlertingStore.filters);
  const [basetypes] = useRecoilState(AlertingStore.basetypes);
  const [rfiForm, setRfiForm] = useRecoilState(rfiFormState);
  // const { openDialog } = useEmbedQuickAction();

  const owners = useRecoilValue(AlertingStore.owners);
  const owner = useRecoilValue(AlertingStore.owner);
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const apps = user.get('apps', list());
  const prefs = user.get('prefs', list());
  const prm = user.get('prm', list());

  // This function enumerates all the values that will be memoized
  // for use in the render function
  const memo = useInlineMemo('autoCompleteOwnerValue');

  const fields = () => {
    const id = selection.get('value');
    switch (true) {
      case /boards/.test(id):
        return [
          { id: 'sort_date',
            label: 'Date',
          },
          { id: 'native_id',
            label: 'Username',
          },
          { id: 'body.text/plain',
            label: 'Message',
          },
        ];
      case /channels/.test(id):
        return [
          { id: 'sort_date',
            label: 'Date',
          },
          { id: 'site_actor.names.handle',
            label: 'Username',
          },
          { id: 'site_actor.names.aliases',
            label: 'Aliases',
          },
          { id: 'body.text/plain',
            label: 'Message',
          },
          { id: 'media',
            label: 'Includes Media?',
          },
          { id: 'media.type',
            label: 'Media Type',
          },
        ];
      case /news/.test(id):
        return [
          { id: 'sort_date',
            label: 'Date',
          },
          { id: 'site_actor.names.handle',
            label: 'Username',
          },
          { id: 'body.text/plain',
            label: 'Message',
          },
        ];
      case /media/.test(id):
        return [
          { id: 'sort_date',
            labels: 'Date',
          },
          {
            id: 'fpid|storage_uri|sha1',
            labels: 'detail uri|image uri|sha1',
            transformations: ['Media.Detail.Uri', 'Media.Image.Uri', null],
          },
        ];
      case /threads/.test(id):
      default:
        return [
          { id: 'container_position.index_number',
            label: 'Index',
          },
          { id: 'sort_date',
            label: 'Date',
          },
          { id: 'site_actor.names.handle',
            label: 'Username',
          },
          { id: 'body.text/plain',
            label: 'Message',
          },
        ];
    }
  };

  const dashboardType = selection.get('value') === 'alerting.alerts'
    ? selection.get('value').split('.').splice(0, 1).shift()
    : selection.get('value', '').split('.').splice(-1).shift();

  const newProfile = fromJS({
    name: '',
    default: 'false',
    filters: user.getIn(['defaults', 'filters', dashboardType, 0, 'filters']),
  });

  const defaults = user.getIn(['defaults', 'filters', dashboardType, 0, 'filters'], map());
  let typeDates = search.get('dates', list());
  if (params && params.type) {
    if (['cves'].includes(params.type)) {
      typeDates = typeDates.filter(v => (!/all|year|60|90|120/ig.test(v.get('date'))));
    } else if (dashboardType === 'alerting') {
      typeDates = typeDates.filter(v => (!/all|year|120/ig.test(v.get('date'))));
    } else {
      typeDates = typeDates.filter(v => (!/all|year|90|120/ig.test(v.get('date'))));
    }
  }

  const options = !dashboardProfile.get('filters') ? [] : [
    { type: ['dashboards.alerting', 'alerting.alerts', 'dashboards.breach.removed', 'dashboards.domain.removed', 'dashboards.fraud', 'dashboards.cves'],
      value: 'name',
      dialog: (
        <TextFilter
          advanced
          fields={[
            { value: 'name', label: 'Name', type: 'text' },
            { value: 'default', label: 'Use as Default', type: 'checkbox', opts: fromJS([{ value: 'true', label: 'Use as Default' }]) },
          ]}
          defaults={map({ name: '', default: 'false' })}
          filters={tempProfile.isEmpty() ? dashboardProfile : tempProfile}
          onFilter={(v) => {
            const profileToUse = tempProfile.isEmpty()
              ? dashboardProfile
              : tempProfile;
            const profile = profileToUse.merge(v);
            setTempProfile(profile);
          }}
        />
      ),
    },
    { type: ['dashboards.alerting', 'alerting.alerts'],
      value: 'hits by source',
      dialog: (
        <TextFilter
          advanced
          fields={[
            { value: 'hitsBySource', label: 'Hits By Source', type: 'checkboxes', opts: fromJS(AlertQuery.genBasetypeMappings(fromJS(basetypes)).map(v => ({ value: v.label, label: v.label }))) },
          ]}
          defaults={map({ name: '', default: 'false' })}
          filters={tempProfile.isEmpty() ? dashboardProfile : tempProfile}
          onFilter={(v) => {
            const profileToUse = tempProfile.isEmpty()
              ? dashboardProfile
              : tempProfile;
            const profile = profileToUse.merge(v);
            setTempProfile(profile);
          }}
        />
      ),
    },
    { type: ['dashboards.alerting', 'alerting.alerts', 'dashboards.fraud', 'dashboards.cves'],
      value: 'date',
      dialog: (
        <FormControl
          variant="outlined"
          margin="normal"
          className={cx(['date', Boolean(optionsDialog && optionsDialog.key === 'header.date') && 'active'])}>
          <InputLabel>Date</InputLabel>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            name="advanced.date"
            onClick={e => setOptionsDialog(({ target: e.currentTarget, key: 'header.date' }))}
            endIcon={<Icon>keyboard_arrow_down</Icon>}>
            {tempProfile.isEmpty()
            ? dashboardProfile.get('filters').get('date') || 'All Time'
            : tempProfile.get('filters').get('date')}
          </Button>
          <Popover
            anchorEl={get(optionsDialog, 'target', '')}
            open={Boolean(optionsDialog && optionsDialog.key === 'header.date')}
            onClick={() => setOptionsDialog()}
            onClose={() => setOptionsDialog()}>
            <DateFilter
              disableCustom
              date={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('date')
                : tempProfile.get('filters').get('date')}
              since={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('since')
                : tempProfile.get('filters').get('since')}
              until={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('until')
                : tempProfile.get('filters').get('until')}
              dates={typeDates}
              toggle={() => setOptionsDialog()}
              onFilter={(v) => {
                const profileToUse = tempProfile.isEmpty()
                  ? dashboardProfile
                  : tempProfile;
                const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
                setTempProfile(profile);
              }} />
          </Popover>
        </FormControl>
      ) },
    { type: ['dashboards.breach.removed', 'dashboards.domain.removed'],
      value: 'date',
      dialog: (
        <FormControl
          variant="outlined"
          margin="normal"
          className={cx(['date', Boolean(optionsDialog && optionsDialog.key === 'header.date') && 'active'])}>
          <InputLabel>Observed</InputLabel>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            name="advanced.date"
            onClick={e => setOptionsDialog(({ target: e.currentTarget, key: 'header.date' }))}
            endIcon={<Icon>keyboard_arrow_down</Icon>}>
            {tempProfile.isEmpty()
              ? dashboardProfile.get('filters').get('date') || 'All Time'
              : tempProfile.get('filters').get('date')}
          </Button>
          <Popover
            PaperProps={{ style: { width: 'auto' } }}
            anchorEl={get(optionsDialog, 'target', '')}
            open={Boolean(optionsDialog && optionsDialog.key === 'header.date')}
            onClick={() => setOptionsDialog()}
            onClose={() => setOptionsDialog()}>
            <DateFilter
              disableCustom
              date={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('date')
                : tempProfile.get('filters').get('date')}
              since={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('since')
                : tempProfile.get('filters').get('since')}
              until={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('until')
                : tempProfile.get('filters').get('until')}
              dates={search.get('dates', null, list())
                .filter(v => !/all|year/ig.test(v.get('date')))}
              toggle={() => setOptionsDialog()}
              onFilter={(v) => {
                const profileToUse = tempProfile.isEmpty()
                  ? dashboardProfile
                  : tempProfile;
                const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
                setTempProfile(profile);
              }} />
          </Popover>
        </FormControl>
      ) },
    { type: ['dashboards.breach.removed', 'dashboards.domain.removed'],
      value: 'breached_at',
      dialog: (
        <FormControl
          variant="outlined"
          margin="normal"
          className={cx(['date', Boolean(optionsDialog && optionsDialog.key === 'header.breached') && 'active'])}>
          <InputLabel>Breach Date</InputLabel>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            name="advanced.breached_at"
            onClick={e => setOptionsDialog(({ target: e.currentTarget, key: 'header.breached' }))}
            endIcon={<Icon>keyboard_arrow_down</Icon>}>
            {tempProfile.isEmpty()
              ? dashboardProfile.get('filters').get('breached_at') || 'All Time'
              : tempProfile.get('filters').get('breached_at')}
          </Button>
          <Popover
            anchorEl={get(optionsDialog, 'target', '')}
            open={Boolean(optionsDialog && optionsDialog.key === 'header.breached')}
            onClick={() => setOptionsDialog()}
            onClose={() => setOptionsDialog()}>
            <DateFilter
              disableCustom
              date={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('breached_at')
                : tempProfile.get('filters').get('breached_at')}
              since={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('breached_since')
                : tempProfile.get('filters').get('breached_since')}
              until={tempProfile.isEmpty()
                ? dashboardProfile.get('filters').get('breached_until')
                : tempProfile.get('filters').get('breached_until')}
              dates={search.get('dates', null, list())
                .filter(v => !/all|year|90|120/ig.test(v.get('date')))}
              toggle={() => setOptionsDialog()}
              onFilter={(v) => {
                const profileToUse = tempProfile.isEmpty()
                  ? dashboardProfile
                  : tempProfile;
                const breached = fromJS({
                  breached_at: v.get('date'),
                  breached_since: v.get('since'),
                  breached_until: v.get('until'),
                });
                const profile = profileToUse.set('filters', profileToUse.get('filters').merge(breached));
                setTempProfile(profile);
              }} />
          </Popover>
        </FormControl>
      ) },
    { type: ['dashboards.fraud'],
      value: 'bin',
      label: 'Card Details',
      icon: 'credit_card',
      dialog: (
        <TextFilter
          advanced
          fields={[
            { value: 'card_category', label: 'Category', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'dump', label: 'Dumps' }, { value: 'cvv', label: 'Credit Cards (CVV)' }]) },
            { value: 'bin', label: 'BIN #', type: 'chips', placeholder: 'eg. 2345xx, 560xxx-560xyy Press enter to add' },
            { value: 'release_name', label: 'Release Names', type: 'chips' },
          ]}
          defaults={user.getIn(['defaults', 'filters', search.get('type'), 0, 'filters'])}
          filters={tempProfile.isEmpty()
            ? dashboardProfile.get('filters')
            : tempProfile.get('filters')}
          onFilter={(v) => {
            const profileToUse = tempProfile.isEmpty()
              ? dashboardProfile
              : tempProfile;
            const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
            setTempProfile(profile);
          }} />) },
    { type: ['dashboards.cves'],
      value: 'cve',
      label: 'CVE Details',
      icon: 'bug_report',
      dialog: (
        <TextFilter
          advanced
          fields={[
            { value: 'cves', label: 'CVEs', type: 'chips' },
            { value: 'cve_vendor_names', label: 'Vendor Name', type: 'chips', placeholder: 'eg. Microsoft, Oracle, Mozilla, Press enter to add' },
            { value: 'cve_product_names', label: 'Product Name', type: 'chips', placeholder: 'eg. Windows, Office, Redhat, Press enter to add' },
            { value: 'cve_severity', label: 'Severity', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'critical', label: 'Critical' }, { value: 'high', label: 'High' }, { value: 'medium', label: 'Medium' }, { value: 'low', label: 'Low' }]) },
          ]}
          defaults={user.getIn(['defaults', 'filters', search.get('type'), 0, 'filters'])}
          filters={tempProfile.isEmpty()
            ? dashboardProfile.get('filters')
            : tempProfile.get('filters')}
          onFilter={(v) => {
            const profileToUse = tempProfile.isEmpty()
              ? dashboardProfile
              : tempProfile;
            const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
            setTempProfile(profile);
          }} />) },
    { type: ['dashboards.breach.removed'],
      value: 'source',
      label: 'Breach Details',
      icon: 'lock_open',
      dialog: (
        <TextFilter
          advanced
          fields={[
            { value: 'source_type', label: 'Source Type', type: 'dropdown', opts: fromJS([{ value: '', label: 'All' }, { value: 'analyst research', label: 'Analyst Research' }, { value: 'credential stealer', label: 'Credential Stealers' }, { value: 'virustotal', label: 'VirusTotal' }, { value: 'paste', label: 'Paste' }]) },
            { value: 'breach_title', label: 'Breach Title', type: 'chips', placeholder: 'List of titles' },
          ]}
          defaults={dashboardProfile}
          filters={tempProfile.isEmpty()
            ? dashboardProfile.get('filters')
            : tempProfile.get('filters')}
          onFilter={(v) => {
              const profileToUse = tempProfile.isEmpty()
                ? dashboardProfile
                : tempProfile;
              const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
              setTempProfile(profile);
            }} />) },
    { type: ['dashboards.domain.removed'],
      value: 'source',
      label: 'Domain Details',
      icon: 'public',
      dialog: (
        <TextFilter
          advanced
          fields={[
            (prm.some(v => /dat\.org\.r/.test(v)) && {
              value: 'customer_id',
              label: 'Org',
              type: 'autocomplete',
              opts: user.get('activeOrganizations').isEmpty()
                ? user.get('sfids', list())
                : user.get('activeOrganizations', list())
                  .filter(v => v.get('name'))
                  .sortBy(v => v.get('name'))
                  .map(v => fromJS({
                    value: v.get('salesforce_id'),
                    label: v.get('name'),
                  })),
            }),
            {
              value: 'domain',
              label: 'Domains',
              type: 'multiselect',
              multiple: true,
              nullable: 'All Domains',
              opts: defaults.get('domain')
                ? fromJS(defaults.get('domain').split(',').map(v => fromJS({ value: v, label: v })))
                : list(),
            },
            {
              label: 'Meets Organization Profile Password Complexity?',
              value: 'meets_org_profile_password_complexity',
              type: 'toggle',
              link: '/home/help/settings#org_profile',
              tooltip: 'View Organization Profile',
            },
          ]}
          defaults={defaults}
          filters={tempProfile.isEmpty()
            ? dashboardProfile.get('filters')
            : tempProfile.get('filters')}
          blur={user.getIn(['prefs', 'blur_credentials'])}
          blurFields={['domain', 'customer_id']}
          blurFunc={Text.BlurOrg}
          onFilter={(v) => {
            if (v.customer_id) UserActions.loadOrgProfiles(v.customer_id);
            const profileToUse = tempProfile.isEmpty()
              ? dashboardProfile
              : tempProfile;
            const profile = profileToUse.set('filters', profileToUse.get('filters').merge(v));
            setTempProfile(profile);
          }} />) },
  ];

  const onBack = () => {
    window.history.back();
  };

  const onClientSelect = (selected) => {
    setDialog();
    setFilters(alertingFilters.set('owner_id', selected.id));
  };

  const onDeleteProfile = () => {
    const type = (selection.get('value') || '').split('.').splice(-1).shift();
    if (!isNewProfile && dashboardProfile) {
      const profiles = user.getIn(['prefs', 'filters', type]);
      const index = profiles.findIndex(v => v.get('name') === dashboardProfile.get('name'));
      let updatedProfiles = profiles.delete(index);
      if (updatedProfiles.count() === 0) {
        updatedProfiles = updatedProfiles.push(newProfile.set('name', 'Default').set('default', 'true'));
      }

      setDialog();
      UserActions.set(['user', 'prefs', 'filters', type], updatedProfiles);
      UserActions.savePrefs();
      setDashboardProfile(updatedProfiles.find(v => v.get('default') === 'true') || updatedProfiles.first() || map());
      UserActions.set(['user', 'tmp', type], { active: updatedProfiles.first().get('name') });
    }
  };

  const onDetail = () => {
    const { pathname, query, hash } = History.getCurrentLocation();
    History.replace({ pathname, query, hash: hash ? null : '#detail' });
  };

  const onExport = () => {
    const type = search?.get('type') ? search.get('type') : selection?.get('label')?.toLowerCase();
    const fieldsValues = fields();
    SearchActions.set(['search', 'info', 'message'], Messages.Exporting);
    SearchActions.set(['search', 'result', 'export', 'fields'], fieldsValues);
    SearchActions.search(type, params.id, true);
  };

  const onFavorite = (value, toggle) => {
    const home = toggle ? apps.find(v => v.get('value') === value) : Common.Generic.Homepage(user.get('prm'));
    SearchActions.set(['search', 'info'], map({
      action: 'Manage Settings',
      message: toggle ? `${selection.get('label')} set as default landing page` : null,
      fn: toggle ? () => History.navigateTo('/home/help/settings#homepage') : null }));
    UserActions.set(['user', 'prefs', 'home'], home);
    UserActions.savePrefs();
    SearchActions.set(['search', 'info', 'message'], Messages.PreferencesSaved);
  };

  const onFormat = (type) => {
    const { id } = params;
    const { origin } = window.location;
    const filename = `${type}-${id}.json`;
    const url = `${origin}/ui/v4/indicators/event/${id}?format=${type}&download=true`;
    FileSaver.saveAs(url, filename, { type: 'text/plain;charset=utf-8', autoBom: true });
  };

  const onImageSearch = (searchType, type) => {
    const fpid = ['result', 'meta', type, 'container', 'fpid'];
    const name = ['result', 'meta', type, 'container', 'name'];
    History.navigateTo({
      pathname: `/home/search/${searchType}`,
      query: {
        media: true,
        media_type: 'Image',
        search: 'image',
        channel: `${search.getIn(fpid, '')}::${search.getIn(name, '')}`,
        channel_exact: true,
      },
    });
  };

  const onJump = (location = '') => {
    const top = '';
    const type = search.get('type');
    const thread = search.getIn(['result', type]);
    const bottom = moment.utc().add(1, 'day').unix();
    const direction = location === 'top' ? 'next' : 'prev';
    const container = thread.merge({ offset: location === 'top' ? top : bottom });
    if ((container.get('data') || list()).count() === 0) return;
    SearchActions.set(['search', 'result', type, 'loaded'], false);
    SearchActions.set(['search', 'result', type, 'data'], list());
    SearchActions.searchThread(container, direction, true);
  };

  const onPrint = async (type) => {
    setLoading(true);
    let error = null;
    const { origin } = window?.location;
    const { pathname } = History.getCurrentLocation();
    const docId = search.getIn(['result', 'reports', 'google_document_id']);
    const body = Text.Strip(search.getIn(['result', 'reports', 'body', 'strippedInnerText']));
    const titleValue = search.getIn(['result', 'reports', 'title']);
    const date = search.getIn(['result', 'reports', 'version_posted_at']);
    const headline = Text.StripHighlight(`Flashpoint - ${Text.Sentence(titleValue)} (${moment.utc(date).format('MMMM DD, YYYY')})`);
    document.title = headline;

    const html = document.getElementById('report');

    switch (type) {
      case 'txt': {
        SearchActions.text(headline, body);
        setLoading();
        break;
      }
      case 'pdf': {
        if (!html) return;
        const status = [200];
        const timeout = 60000;
        const url = `${origin}${pathname}`;
        const query = { url, title: headline };
        const headers = { responseType: 'arraybuffer' };
        await Api.post('/ui/v4/reports/export', query, status, timeout, headers)
          .then(res => new Blob([res.data], { type: 'application/pdf;charset=utf-8' }))
          .then(res => FileSaver.saveAs(res, `${headline}.pdf`))
          .catch((err) => { error = err; });
        if (error && docId) {
          // fallback method for reports without an associated google doc id or 404 on id lookup
          await Api.get(`/ui/v4/assets/${docId?.split('::')?.slice(-1)?.join()}`, query, status, timeout, headers)
            .then(res => new Blob([res.data], { type: 'application/pdf;charset=utf-8' }))
            .then(res => FileSaver.saveAs(res, `${headline}.pdf`));
        }
        setLoading();
        break;
      }
      default: setTimeout(() => {
        window.print();
        setLoading();
      }, 1000); break;
    }
  };

  const onProfileSelect = (value, index) => {
    if (index >= values.count()) {
      setIsNewProfile(true);
      setTempProfile(value);
      // Open the settings dialog with new profile
      setDialog({ key: 'header.customize' });
    } else {
      setDialog();
      setDashboardProfile(value || map());
      UserActions.set(['user', 'tmp', dashboardType], { active: value.get('name') });
    }
  };

  const onReport = () => {
    const { id } = params;
    const { href } = window.location;
    const subject = `Media Report - ${id}`;
    const body = `I would like to report the media file with the id "${id}" located at ${Text.DefangLink(href)}.`;
    /* eslint-disable-next-line security/detect-non-literal-fs-filename */
    window.open(`mailto:feedback@flashpoint-intel.com?cc=tpollard@flashpoint-intel.com&subject=${subject}&body=${body}`);
  };

  const onRoute = (v) => {
    const { query: { limit, query, owner_id } } = History.getCurrentLocation();
    const defs = v.get('searchType') === 'media'
      ? { date: 'Last 24 Hours', since: 'now-24h', until: 'now' }
      : { date: 'last 7 days', since: 'now-7d', until: 'now' };
    const isSearch = v.get('search') && (v.get('searchType') !== 'customer-credentials');

    if (v.get('external')) {
      /* eslint-disable-next-line security/detect-non-literal-fs-filename */
      window.open(v.getIn(['path', 0]), '_blank');
      return;
    }
    const toggle = ['collections', 'vulndb'].includes(v.get('parent'));
    const alerting = v.get('parent') === 'alerting';
    const finalLimit = Array.isArray(limit) ? limit[0] : limit;

    let pathQuery = '';
    if (toggle) {
      pathQuery = isSearch ? { ...defs, limit: finalLimit, query } : {};
    } else if (alerting) {
      pathQuery = { owner_id };
    }

    setTimeout(() => {
      History.push({
        pathname: v.getIn(['path', 0]),
        query: pathQuery,
      });
    });
  };

  const onShare = (strip) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const { id, fpid, limit, skip, sort } = query;
    const searchValue = `${qs.stringify(strip ? { id, fpid, limit, skip, sort } : { ...query, id })}`;
    navigator.clipboard.writeText(`${window.location.origin}${pathname}?${searchValue}${hash}`);
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  };

  const onSettings = () => {
    if (tempProfile.isEmpty()) {
      // No settings were changed. Close dialog
      setDialog();
    } else {
      const type = (selection.get('value') || '').split('.').splice(-1).shift();
      let index = isNewProfile
        ? -1
        : values.findIndex(v => v.get('name') === dashboardProfile.get('name'));
      setDialog();
      const profiles = user.getIn(['prefs', 'filters', type]);
      const updatedProfiles = profiles.map(v => (tempProfile.get('default') === 'true' ? v.set('default', 'false') : v));
      if (index !== -1) {
        // Updating an existing profile
        UserActions.set(['user', 'prefs', 'filters', type], updatedProfiles.set(index, tempProfile));
        setDashboardProfile(tempProfile || map());
      } else {
        UserActions.set(['user', 'prefs', 'filters', type], updatedProfiles.push(tempProfile));
        index = updatedProfiles.count();
        setDashboardProfile(tempProfile || map());
      }
      UserActions.set(['user', 'tmp', type], { active: tempProfile.get('name') });
      UserActions.savePrefs();
    }

    setIsNewProfile(false);
    setTempProfile(map());
  };

  const onTranslate = (translate = 'en') => {
    const { pathname, query, hash } = History.getCurrentLocation();
    History.push({
      pathname,
      hash,
      query: {
        ...query,
        query_i18n: translate,
      },
    });

    // scroll to active element if one exists
    setTimeout(() =>
      document
        ?.getElementById(query?.id)
        ?.scrollIntoView(false));
  };

  const onCustomerSelect = (option) => {
    const { salesforce_id } = option;

    if (salesforce_id) {
      setAppState({ ...appState, salesforce_id });
    }
  };

  const onZip = async () => {
    const date = moment.utc().format('MMM-DD-YYYY_HHmm');
    if (search.getIn(['result', 'customer-credentials'])) {
      const creds = search.getIn(['result', 'customer-credentials']);
      const filename = `Flashpoint-Customer-Credentials-Detail-${creds.get('fpid')}-${date}`;
      const zip = new JSZip();
      zip
        .file(`${filename}.json`, JSON.stringify(creds.toJS()))
        .generateAsync({ type: 'blob' })
        .then((content) => {
          FileSaver.saveAs(content, `${filename}.zip`);
          SearchActions.set(['search', 'info', 'message'], null);
        });
      SearchActions.set(['search', 'result', 'customer-credentials'], null);
      return;
    }

    const searchUrl = '/ui/v4/all/search';
    const status = [200];
    const timeout = 30000;
    const fpids = search.getIn(['result', 'credentials', 'data'])
      .map(v => `"${v.get('fpid')}"`)
      .join(' OR ');
    const q = {
      query: `+basetypes:credential-sighting +fpid:(${fpids})`,
      limit: 1000,
      source: true,
      // _source_includes: ['infected_host_attributes', 'cookies'],
    };
    SearchActions.set(['search', 'info', 'message'], Messages.Exporting);
    await Api.post(searchUrl, q, status, timeout)
    .then((res) => {
      const filename = `Flashpoint-${params.type}Detail-${date}`;
      const zip = new JSZip();
      zip
        .file(`${filename}.json`, JSON.stringify(res.data))
        .generateAsync({ type: 'blob', compression: 'deflate' })
        .then((content) => {
          FileSaver.saveAs(content, `${filename}.zip`);
          SearchActions.set(['search', 'info', 'message'], null);
        });
    })
    .catch(err => SearchActions.set(['search', 'info', 'message'], err.message));
  };

  useEffect(() => {
    const { pathname } = History.getCurrentLocation();
    if (/assets\/media\/items/ig.test(pathname)) {
      const sha1 = search.getIn(['result', 'media', 'image', 'media_v2', 0, 'sha1']);
      setTitle(`SHA-1: ${sha1 || ''}`);
    } else {
      const text = selection.get('title')?.toJS() ||
        ['meta', params.subtype, `title${query_i18n ? '_en' : ''}`] ||
        [`title${query_i18n ? '_en' : ''}`];
      const label = selection.get('label');
      const titleValue = search.getIn(['result', ...text]) || label;
      setTitle(titleValue);
    }

    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [search, selection, query_i18n]);

  useEffect(() => {
    if (['alerting', 'breach.removed', 'domain.removed', 'cves', 'fraud'].includes(dashboardType)) {
      let filterValues = user.getIn(['prefs', 'filters', dashboardType]);
      if (map.isMap(filterValues)) {
        // Need to update the user preference into new format
        const defaultFilter = fromJS({ name: 'Default', filters: { ...filterValues.toJS() }, default: 'true' });
        filterValues = list([defaultFilter]);
        UserActions.set(['user', 'prefs', 'filters', dashboardType], filterValues, false);
      } else if (!filterValues) {
        filterValues = fromJS(user.getIn(['defaults', 'filters', dashboardType]));
        UserActions.set(['user', 'prefs', 'filters', dashboardType], filterValues, false);
      }
      setValues(filterValues);
      setDashboardProfile(dashboardProfile.isEmpty()
        ? filterValues.find(v => v.get('default') === 'true') || filterValues.first() || map()
        : filterValues.find(v => v.get('name') === dashboardProfile.get('name')) || map());
    }
    if (user && user.get('prm') && !hasUiPermission) {
      setHasUiPermission(user.get('prm').some(p => /acc.ui/.test(p)));
    }
  }, [search, selection, user]);

  useEffect(() => {
    const { pathname } = History.getCurrentLocation();
    const selectionValue = apps
      .filter(v => !v.get('nav'))
      .filter(v => !v.get('advanced'))
      .find(v => pathname.includes(v.getIn(['path', 0]))) || map();
    setSelection(selectionValue);

    // Generate subnav
    const subnavValues = apps
      .filter(v => !v.get('hidden') || !v.get('desc'))
      .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
      .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
      .filter(v => user.get('prm').some(p => v.get('test').test(p)))
      .filter(v => !v.get('ui') || (v.get('ui') && user.get('prm').some(p => /acc.ui/.test(p))))
      .filter(v => v.get('parent') === selectionValue.get('parent'))
      .filter(v => v.has('path') && v.get('label') && !v.get('dropdown'))
      .filter(v => v.get('subnav'))
      .map(v => v.merge({ value: v }));

    setSubnav(subnavValues);
  }, [apps, params]);

  //  Only set sfid if there isnt one in recoil
  useEffect(() => {
    if (!appState.salesforce_id && user.get('sid')) {
      setAppState({ ...appState, salesforce_id: user.get('sid') });
    }
  }, [appState, user]);

  useEffect(() => {
    /* Get URL Params */
    const query = window.location.search.replace(/\?/, '&');
    if (query) {
      const _params = qs.parse(query);
      if ((!prm.some(v => /dat.rfi.w/.test(v))) && (_params.question || _params.relevant_url || _params.rfi_banner)) {
        setRfiBanner(true);
      }
    }
  }, []);

  return (
    <Grid
      fluid
      name="component.header"
      className={cx([
        style.header,
        classes.header,
        ((!selection.get('search') && !selection.get('subnav')) || selection.get('subnav')) && style['has-subnav'],
        selection.get('noHeader') && classes.noHeader,
      ])}>
      {/* nav */}
      <Row>
        <Col xs={12} className={style.nav}>
          <Row>
            <Col xs={12} className={style.search}>
              {access && hasUiPermission &&
              <Find
                inline
                params={params}
                type={search.get('type')}
                showSearchOverlay={showSearchOverlay}
                setShowSearchOverlay={setShowSearchOverlay} />}
            </Col>
          </Row>
        </Col>
      </Row>
      <ProgressBar remaining={search.get('activeSearches')} />
      {/* subnav */}
      {access &&
      selection.get('subnav') &&
      <Row className={classes.subnav}>
        <Col xs={12} className={style.subnav}>
          {hasUiPermission &&
          !/\.view$/.test(selection.get('value')) &&
          <div className={cx([
            'controls',
            style.controls,
            style.left,
            ])}>
            <Icon
              data-for="comp.tooltip"
              data-tip="Set as default homepage"
              data-place="right"
              className={cx([style.icon, prefs.getIn(['home', 'value']) === selection.get('value') && 'active'])}
              onClick={() => onFavorite(selection.get('value'),
                prefs.getIn(['home', 'value']) !== selection.get('value'))}>
              {prefs.getIn(['home', 'value']) === selection.get('value')
                ? 'star'
                : 'star_border'}
            </Icon>
            <TextFilter
              fields={[{
                type: 'dropdown',
                value: 'selected',
                default: selection,
                key: 'value',
                opts: subnav,
                renderValue: value =>
                  <div data-testid={`header.subnav.view-dropdown.${value.get('value')}`}>Viewing <b>{value.get('label')}</b></div>,
                className: style.subnavdropdown,
              }]}
              className={style.textFilter}
              onFilter={({ selected }) => onRoute(selected)}
              testId="header.subnav"
              stacked />
          </div>}
          <div className={cx([
            'controls',
            style.controls,
            style.alertingorg,
            style.right,
            ])}>
            {/alerting/.test(selection.get('value')) && !owner.isEmpty() &&
            !/saved/.test(selection.get('value')) &&
            <div
              className={cx([
                'controls',
                style.controls,
                style.alerting,
                owners.count() === 1 && style.owner,
              ])}>
              {owners.count() > 1 &&
                <MuiAutocomplete
                  fullWidth
                  openOnFocus
                  blurOnSelect
                  disableCloseOnSelect
                  popupIcon={<Icon>keyboard_arrow_down</Icon>}
                  freeSolo
                  selectOnFocus
                  value={memo.autoCompleteOwnerValue((() => {
                    const currentOwner = owners.find(v => v.get('id') === ownerId);
                    return currentOwner ? currentOwner.toJS() : '';
                  })(), [ownerId])}
                  options={owners
                  .sortBy(v => v.get('name').toLowerCase())
                  .toJS()}
                  getOptionLabel={option => option.name}
                  onChange={(event, option, reason) => {
                    if (['selectOption'].includes(reason)) onClientSelect(option);
                  }}
                  renderInput={parameters => (
                    <TextField
                      {...parameters}
                      variant="outlined" />
                  )}
                />}
              {owners.count() === 1 &&
              <div>
                <span>{Text.Sentence(owner.get('name'))}</span>
              </div>}
            </div>}
            {((/(ato|dashboards)\.exposure/.test(selection.get('value')) &&
              user.get('prm').some(p => /org\.fp/ig.test(p)) &&
              user.get('prm').some(p => /dat\.edm\.r/.test(p))) ||
              (/(dashboards|fraud)\.(cards|fraud|manage|managebins|cfm|csb|domainmonitoring|fullcards)/.test(selection.get('value')) &&
                !/(fraud|dashboards)\.(reports|topics|ocr|bitcoin|fraud)/.test(selection.get('value')) &&
                user.get('prm').some(p => /org\.fp/ig.test(p)) &&
                user.get('sfids').size > 0 &&
                appState.salesforce_id)) &&
                <div
                  className={cx([
                  style.controls,
                  style.alerting,
                  classes.controls,
                  'customerSelect',
                ])}>
                  <MuiAutocomplete
                    autoSelect
                    openOnFocus
                    blurOnSelect
                    popupIcon={<Icon>keyboard_arrow_down</Icon>}
                    value={appState.salesforce_id}
                    options={
                      user
                        .get('sfids')
                        .sortBy(v => v.get('name').toLowerCase())
                        .toJS()
                    }
                    getOptionLabel={(option) => {
                      if (option.name) {
                        return option.name;
                      }
                      return user.get('sfids').find(v => v.get('salesforce_id') === appState.salesforce_id).get('name');
                    }}
                    onChange={(event, option, reason) => {
                      if (['selectOption'].includes(reason)) {
                        onCustomerSelect(option);
                      }
                    }}
                    renderInput={parameters => (
                      <TextField
                        {...parameters}
                        variant="filled" />
                    )}
                  />
                </div>
            }
            {!dashboardProfile.isEmpty() &&
            /dashboards/.test(selection.get('value')) &&
            ['cves', 'fraud'].includes(dashboardType) &&
            <React.Fragment>
              <Button
                color="secondary"
                variant="contained"
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={event => setDialog({ key: 'profile', target: event.target })}>
                {dashboardProfile.get('name', 'Default')}
              </Button>
              <Popover
                className="dropdown"
                anchorEl={get(dialog, 'target', '')}
                onClose={() => setDialog()}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={Boolean(dialog && dialog.key === 'profile')}>
                {values
                  .sortBy(v => v.get('default') !== 'true')
                  .entrySeq()
                  .map(([k, v]) => (
                    <ListItem
                      key={v.get('name')}
                      value={v}
                      onClick={() => onProfileSelect(v, k)}
                      className={cx([style.item, v.get('name') === dashboardProfile.get('name') && style.active])}>
                      {Text.Sentence(v.get('name'))}
                    </ListItem>))}
                <ListItem
                  value={newProfile}
                  className={cx([style.item])}
                  onClick={() => onProfileSelect(newProfile, values.count())}>
                  Create New Profile
                </ListItem>
              </Popover>
            </React.Fragment>}
            {selection.get('settings') &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Customize current page"
              className={cx([style.hover, style.settings])}
              onClick={event => setDialog({ key: 'header.customize', target: event.target })}>
              settings
            </Icon>}
          </div>
        </Col>
      </Row>}
      {/* contextnav */}
      {selection.get('contextnav') &&
      <Row>
        <Col xs={12} className={cx([style.subnav, style.comp])}>
          <div className={cx([
            'controls',
            style.controls,
            style.left])}>
            <Icon
              data-place="right"
              data-for="subnav.tooltip"
              data-tip="Back to Previous page"
              className={style.back}
              onClick={() => onBack()}>
              arrow_back
            </Icon>
            {['Thread'].includes(selection.get('label')) &&
            <Button
              className={style.translate}
              color="primary"
              variant="contained"
              onClick={() => onTranslate(query_i18n ? '' : 'en')}>
              {query_i18n ? 'Original Language' : 'Show in English'}
            </Button>}
          </div>
          {title &&
          <div className={cx([style.title])}>
            <Icon className={style.icon}>
              {selection.get('icon')}
            </Icon>
            {Text.StripHighlight(title)}
          </div>}
          {['person-search.results'].includes(selection.get('value')) &&
          <div className={style.title}>
            <Link
              className={cx([style.h4, 'h4', style.mont, 'mont', style.button, style.active])}
              href={'_self' in React.createElement('div') || window.location.pathname.includes('staging')
                ? window.location.pathname.replace('home', 'ui').replace('results', 'v1/dev/report').concat('/results')
                : window.location.pathname.replace('home', 'ui').replace('results', 'v1/report').concat('/results')}
              target="_blank"
              style={{ padding: '0 12px' }}
              underline="none">Raw
            </Link>
          </div>}
          <div className={cx([
            'controls',
            style.controls,
            style.right,
            ])}>
            {['Vulnerability (CVE)'].includes(selection.get('label')) &&
            <img
              data-for="global.tooltip"
              data-tip="Open in VulnDB"
              className={cx([style.icon, style.hover, 'active', 'vulnDb'])}
              style={{ fontSize: '2rem', width: '1em', margin: '0 8px' }}
              alt="VulnDB"
              src="/static/rbs-vdb__logo-icon_1.svg"
              onClick={() => {
                /* eslint-disable-next-line security/detect-non-literal-fs-filename */
                window.open(`https://vulndb.flashpoint.io/vulnerabilities/search?query=${title}`);
              }} />}
            {(prm.some(v => /dat.rfi.w/.test(v))) &&
            RfiLocations.includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Request for Information"
              className={cx([style.icon, style.hover])}
              onClick={() => setRfiForm({ ...rfiForm, popup: true })}>
              assignment
            </Icon>}
            <Bookmark
              title={title}
              entity={search.getIn(['result', search.get('type')])} />
            {['IOC'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Download IOC in FP, MISP CSV or STIX formats"
              className={style.hover}
              onClick={event => setDialog({ key: 'ioc.export', target: event.target })}>
              low_priority
            </Icon>}
            <Popover
              className="dropdown"
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog && dialog.key === 'ioc.export')}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClick={() => setDialog()}
              onClose={() => setDialog()}>
              {['FP', 'MISP', 'CSV', 'STIX'].map(v => (
                <ListItem
                  key={v}
                  onClick={() => onFormat(v)}
                  className={cx([style.item, style.h4, 'h4', style.normal, 'normal'])}>
                  {v}
                </ListItem>))}
            </Popover>
            {['Advanced Persistent Threats', 'Malware'].includes(selection.get('label')) &&
            user.get('prm').some(p => /org\.fp/ig.test(p)) &&
            user.get('prm').some(p => /dat\.rep\.w/ig.test(p)) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Edit Wiki Entry"
              className={style.hover}
              onClick={() => History.navigateTo({ pathname: `${window.location.pathname}/edit` })}>
              edit
            </Icon>}
            {['Channel'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="View channel images"
              className={cx([style.icon, style.hover])}
              onClick={() => onImageSearch('chats', 'channels')}>
              image_search
            </Icon>}
            {['Board', 'Channel', 'Page', 'Post', 'Thread'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Jump to top of thread"
              className={cx([style.icon, style.rotate, style.hover])}
              onClick={() => onJump('top')}>
              first_page
            </Icon>}
            {['Board', 'Channel', 'Page', 'Post', 'Thread'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Jump to bottom of thread"
              className={cx([style.icon, style.rotate, style.hover])}
              onClick={() => onJump('bottom')}>
              last_page
            </Icon>}
            {['Paste'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Jump to top of page"
              className={style.hover}
              onClick={() => window.scrollTo(0, 0)}>
              first_page
            </Icon>}
            {['Paste'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Jump to bottom of page"
              className={style.hover}
              onClick={() => window.scrollTo(0, document.documentElement.scrollHeight)}>
              last_page
            </Icon>}
            {['Report'].includes(selection.get('label')) && !loading &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Export report"
              className={style.hover}
              onClick={event => setDialog({ key: 'export.report', target: event.target })}>
              get_app
            </Icon>}
            {['Report'].includes(selection.get('label')) && loading &&
            <CircularProgress />}
            <Popover
              className="dropdown"
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog && dialog.key === 'export.report')}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClick={() => setDialog()}
              onClose={() => setDialog()}>
              <ListItem
                onClick={() => onPrint('pdf')}>
                Export .pdf
              </ListItem>
              <ListItem
                onClick={() => onPrint('txt')}>
                Export .txt
              </ListItem>
            </Popover>
            {['Enterprise Credentials', 'Customer Credentials'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Export zip file"
              className={style.hover}
              onClick={() => onZip()}>
              file_download
            </Icon>}
            <Icon
              data-for="subnav.tooltip"
              data-tip="Generate share link"
              className={style.hover}
              onClick={event => setDialog({ key: 'header.share', target: event.target })}>
              share
            </Icon>
            <Popover
              className="dropdown"
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog && dialog.key === 'header.share')}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClick={() => setDialog()}
              onClose={() => setDialog()}>
              {[search.getIn(['filters', 'query']) && 'Share URL with <x-fp-highlight>highlighting</x-fp-highlight>',
                'Share URL']
                .filter(v => v)
                .map((v, k) => (
                  <ListItem
                    key={v}
                    onClick={() => onShare(!!k)}>
                    {Text.Highlight(v)}
                  </ListItem>))}
            </Popover>
            {['Media'].includes(selection.get('label')) &&
            <Icon
              data-for="global.tooltip"
              data-tip="Report media"
              className={style.hover}
              onClick={() => onReport()}>
              priority_high
            </Icon>}
            {['Board', 'Channel', 'Thread', 'Media'].includes(selection.get('label')) &&
            <Icon
              data-for="subnav.tooltip"
              data-tip="Export to CSV <br> Excel limits 32k characters per cell"
              className={style.hover}
              onClick={() => onExport()}>
              file_download
            </Icon>}
            {['Board', 'Channel', 'News', 'Thread'].includes(selection.get('label')) && !params.featured &&
            <Icon
              color={window.location.hash ? 'secondary' : 'primary'}
              data-for="subnav.tooltip"
              data-tip="Toggle detail overlay"
              onClick={() => onDetail()}>
              info
            </Icon>}
          </div>
        </Col>
      </Row>}
      {rfiBanner &&
        <Row>
          <Col xs={12}>
            <RfiBanner onDismiss={() => setRfiBanner(false)} />
          </Col>
        </Row>}
      {(prm.some(v => /dat.rfi.w/.test(v))) &&
        RfiLocations.includes(selection.get('label')) &&
        <RfiPopupForm
          forceOpen={rfiForm.popup}
          postUrl={rfiForm.relevantUrl}
          onClose={() => setRfiForm({})}
          />}
      <Dialog
        open={Boolean(dialog && dialog.key === 'header.customize')}
        onClose={() => { setDialog(); setTempProfile(map()); }}>
        <DialogTitle>
          {`${selection.get('label')} Settings`}
        </DialogTitle>
        <DialogContent>
          {options
            .filter(v => v.type.includes(selection.get('value')))
            .filter(v => v.dialog)
            .map(option => (
              <div key={option.value}>
                {option.dialog}
              </div>))}
        </DialogContent>
        <DialogActions className={style.footer}>
          <Button
            color="primary"
            style={{ opacity: dashboardProfile.get('name') === '' ? 0 : 1 }}
            className={cx([style.delete])}
            onClick={() => onDeleteProfile()}>
            Delete
          </Button>
          <Button
            color="primary"
            onClick={() => { setDialog(); setTempProfile(map()); }}>
            Cancel
          </Button>
          <Button
            name="dialog.button.save"
            className={style.accept}
            disabled={tempProfile.has('name') && !tempProfile.get('name')}
            onClick={() => onSettings()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ReactTooltip id="nav.tooltip" html place="left" effect="solid" />
      <ReactTooltip id="comp.tooltip" html place="left" effect="solid" />
      <ReactTooltip id="subnav.tooltip" html place="bottom" effect="solid" globalEventOff="click" />
    </Grid>
  );
};

Header.propTypes = {
  access: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  showSearchOverlay: PropTypes.bool.isRequired,
  setShowSearchOverlay: PropTypes.func.isRequired,
};

export default Header;
