import Query from './query';
import SearchActions from '../../actions/searchActions';
import Token from '../../utils/token';
import Messages from '../../constants/Messages';
import UserActions from '../../actions/userActions';

/** Submits an RFI to Salesforce */
const handleSubmit = async (formData, setLoading, setError, done) => {
  /* Log out if user's auth cookie is expired */
  if (!Token.cke()) {
    UserActions.logout();
    window.location.href = '/';
  }

  /* Remove files from first submit to Salesforce */
  const files = formData.relevant_files;
  const fd = formData;
  delete fd.relevant_files;
  SearchActions.set(['search', 'info', 'message'], Messages.RfiPost);
  setLoading(true);

  /* Post RFI text data to Salesforce */
  Query.postForm(fd)
    .then(async (res) => {
      if (!files || files === {}) {
        /* Skip uploading if there are no files */
        return;
      }
      if (!res.ok || !res.data.id) {
        throw new Error('Salesforce case not created');
      }
      /* Post files to Salesforce, one by one */
      const { id } = res.data;
      const _files = Object.values(files);
      while (_files.length > 0) {
        const f = _files.pop();
        await Query.postFile(id, f);
      }
    })
    .finally(() => {
      done();
    })
    .catch((err) => {
      if (setError) {
        setError(`Unable to post RFI: ${err.message}`);
      }
      SearchActions.set(['search', 'info', 'message'], Messages.RfiPostError);
      throw err;
    });
};

export default handleSubmit;
