import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

import Text from './text';

const Cookie = Cookies.withConverter({ write: v => v, read: v => v });

// allow list for special org functions
// map to human-readable string based on sfid
// appneded to ffs claim
const OrgFlags = ({
  '001o000000cYpS0AAK': ['org.bhvos.r'],
  '001o000000yPXXuAAO': ['org.fp.r'],
});

const Domain = window.location.hostname === 'localhost'
  ? 'localhost'
  : `.${window.location.hostname.split('.').splice(-2).join('.')}`;

const roles = [
  { test: /api/i, prm: ['api.prv', 'api.rev', 'acc.api'] },
  { test: /all reports/i, prm: ['dat.rep.jih.r', 'dat.rep.cyb.r', 'dat.rep.ass.r'] },
  { test: /all forums/i, prm: ['dat.for.r', 'dat.mkt.r'] },
  { test: /chat/i, prm: ['dat.chat.r'] },
  { test: /reports jihadi/i, prm: ['dat.rep.jih.r', 'dat.rep.ass.r'] },
  { test: /reports cyber/i, prm: ['dat.rep.cyb.r', 'dat.rep.ass.r'] },
  { test: /all reports writer/i, prm: ['dat.rep.w'] },
  { test: /org alerting management/i, prm: ['dat.key.org.r', 'dat.key.org.w'] },
];

const Token = {
  flg: [],
  cke: (k = 'X-FP-Authorization', passDomain = true) => Cookie.get(k, (passDomain) ? { domain: Domain } : null) || '',
  del: (k = 'X-FP-Authorization', passDomain = true) => Cookie.remove(k, (passDomain) ? { domain: Domain } : null),
  set: (k = 'X-FP-Authorization', v, opts) => Cookie.set(k, v, { domain: Domain, ...opts }),
  lgy: (tok = '') => {
    const uid = Cookie.get('X-FP-UserId', { domain: Domain });
    const per = Cookie.get('X-FP-Permissions', { domain: Domain });
    const def = ['dat.prf.slf.w', 'dat.prf.slf.r', 'dat.gls.r'];
    const dat = [...new Set(JSON.parse(per || JSON.stringify([]))
      .map(v => roles.filter(r => r.test.test(v)).map(r => r.prm))
      .flat()
      .flat())];
    const api = !dat?.some(p => /acc.ui/.test(p)) && dat?.some(p => /acc.api/.test(p))
      ? ['api.doc', 'acc.api.only']
      : ['api.doc'];
    return { tok, uid, prm: [...def, ...dat, ...api] };
  },
  jwt: (tok = '') => {
    const def = ['dat.prf.slf.w', 'dat.prf.slf.r', 'dat.gls.r'];
    const clm = (tok.split('.')[1] || '').replace('-', '+').replace('_', '/');
    const dat = clm ? jwt_decode(clm, { header: true }) : { prm: [], ffs: [] };
    const ccmc = (dat.oid || dat.org_id) === 55 && dat?.prm?.some(v => /ccm.cus/.test(v)) ? ['demo.ccm.c'] : [];
    // Get nbf
    const nbf = Number(dat.not_bf || dat.nbf);
    // convert sid to 18 character;
    const sfkey = dat.sf_id || dat.sid;
    const sfids = Text.SFIDConversion(sfkey);
    const org = OrgFlags[sfids[18]] || [];
    const grp = dat?.groups;
    const api = !dat?.prm?.some(p => /acc.ui/.test(p)) && dat?.prm?.some(p => /acc.api/.test(p))
      ? ['acc.api.only', 'api.doc']
      : ['api.doc'];
    // use concat instead of destructuring dat.ffs because if
    // dat.ffs does not exist, it will throw an error.
    return {
      ...dat,
      sid: sfids['18'],
      sf_id: sfids['18'],
      oid: dat.org_id,
      nbf,
      prm: [...new Set(dat?.prm?.concat(
        dat.ffs,
        def,
        org,
        ccmc,
        api,
        grp,
        Token.flg)
      ?.filter(v => v))],
    };
  },
  get: (k = '', t = '') => {
    const tok = t || Token.cke();
    const lgy = Token.lgy(tok);
    const jwt = Token.jwt(tok);
    const dat = /token\s/i.test(tok) ? lgy : jwt;
    return !k ? dat : dat[String(k)] || '';
  },
};

export default Token;
