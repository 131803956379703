import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { createValueFromString } from 'react-rte';
import { Map as map, List as list, fromJS } from 'immutable';
import {
  Chip,
  CircularProgress,
} from '@mui/material';

import style from './topic.module.scss';
import Query from './query';
import WikiEdit from '../WikiEdit';
import SearchActions from '../../../actions/searchActions';
import Messages from '../../../constants/Messages';
import History from '../../../utils/history';
import { UserContext } from '../../../components/utils/Context';

const Topic = ({
  categories,
  organizations,
  remove,
  save,
  source,
}) => {
  const [data, setData] = useState();
  const [saving, setSaving] = useState(false);
  const user = useContext(UserContext);
  const [privateTopic, setPrivateTopic] = useState(false);
  const [orgFilter, setOrgFilter] = useState({
    label: 'Organizations',
    type: 'multiselect',
    value: 'organizations',
    multiple: true,
    opts: [],
    disabled: false,
    transformation: (v = []) => v.join(),
  });
  const [catFilter, setCatFilter] = useState({
    label: 'Category',
    value: 'categories',
    type: 'autocomplete',
    opts: [],
    freeSolo: true,
    required: true,
    transformation: (v = []) => v.join(),
  });

  const edit = /edit$/ig.test(window.location.pathname);

  const editFields = fromJS([
    // This structure allows for sections of fields
    {
      label: '',
      fields: [
        {
          label: 'Published',
          value: 'is_published',
          type: 'toggle',
        },
        {
          label: 'Trending',
          value: 'is_trending',
          type: 'toggle',
          disabled: () => privateTopic,
        },
        {
          label: 'Organization Pattern',
          value: 'is_org_pattern',
          type: 'toggle',
          help: 'Notify users that provided search should be modified with org patterns',
        },
        {
          label: 'Private Topic',
          value: 'is_private_topic',
          type: 'toggle',
          help: 'Only show this topic to the specified organizations',
          onChange: (label, value) => {
            setPrivateTopic(value);
          },
        },
        orgFilter,
        catFilter,
        {
          label: 'Name',
          value: 'topic_name',
          type: 'text',
          test: /^(.){3,21}$/ig,
          hint: 'Max length: 21',
          required: true,
        },
        {
          label: 'Description',
          value: 'body.raw',
          type: 'rte',
          required: true,
          test: /^.{3,160}$/ig,
          hint: 'Max length: 160',
          transformation: (v = '') => createValueFromString(v, 'html'),
        },
        {
          label: 'Relevant URL',
          value: 'queries.0.query',
          type: 'text',
          hint: 'Valid fp.tools search path or intelligence report. \nDate restricted to 30 days for search queries',
          test: /https:\/\/fp.tools\/home\/(search|intelligence)/ig,
          multiLine: true,
          required: true,
        },
      ],
    },
  ]);

  const onCancel = () => {
    History.navigateTo({
      pathname: '/home/explore/topics',
    });
  };

  const onRemove = async () => {
    remove()
      .then(() => setSaving(false))
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess))
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.AlertsError));
  };

  const onSave = async (values = map()) => {
    setSaving(true);
    const updated_at = `${moment.utc().format().slice(0, -1)}+00:00`;
    const published_at = source.get('published_at') ?? { usn: user.get('usn'), 'date-time': updated_at };
    const query = values.get('queries.0.query', '');
    const hits = await Query.Hits(query);
    const payload = map()
      .set('fpid', source.get('fpid'))
      .set('categories', fromJS(values.get('categories').split(',')))
      .set('topic_name', fromJS(values.get('topic_name')))
      .set('is_published', fromJS(Boolean(values.get('is_published'))))
      .set('is_org_pattern', fromJS(Boolean(values.get('is_org_pattern'))))
      .set('is_private_topic', fromJS(Boolean(values.get('is_private_topic'))))
      .set('organizations', fromJS(values.get('organizations').split(',')))
      .set('is_trending', fromJS(Boolean(values.get('is_trending'))))
      .set('published_at', fromJS(published_at))
      .set('updated_at', fromJS({ usn: user.get('usn'), 'date-time': updated_at }))
      .setIn(['body', 'raw'], fromJS(values.get('body.raw').toString('html')))
      .set('queries', fromJS([{
        ...hits,
        query,
        name: 'search',
      }]));

    save(payload)
      .then(() => setSaving(false))
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.MutationSuccess))
      .catch((err) => {
        setSaving(false);
        SearchActions.set(['search', 'info', 'message'], err.message || Messages.AlertsError);
      });
  };

  useEffect(() => {
    setOrgFilter({
      ...orgFilter,
      opts: !privateTopic
        ? []
        : (organizations?.map(v => fromJS({ label: v.name, value: v.salesforce_id })) || []),
      renderTags: (values, getTagProps) => (
        <>
          {values.map((v, i) => {
            const props = getTagProps(i);
            const label = organizations.find(v1 => v1.salesforce_id === v)?.name;
            return <Chip {...props} label={label} />;
          })}
        </>
      ),
      required: privateTopic,
      disabled: !privateTopic,
    });
    const newCats = privateTopic
      ? {
          ...catFilter,
          opts: ['Private Topic'],
          defaultValue: 'Private Topic',
          required: false,
          disabled: true,
        }
      : {
          ...catFilter,
          opts: categories.toJS(),
          defaultValue: undefined,
          required: true,
          disabled: false,
        };
    setCatFilter(newCats);
  }, [privateTopic, categories, organizations]);

  useEffect(() => {
    if (source.isEmpty()) return;
    if (source.equals(data)) return;
    if (!source.get('is_private_topic', false)) {
      setPrivateTopic(false);
      setOrgFilter({
        ...orgFilter,
        required: false,
        disabled: true,
      });
    }
    setData(source);
  }, [source]);

  return (
    <div className={style.topic}>
      {!data &&
      <CircularProgress />}
      {data && edit &&
      <WikiEdit
        source={data}
        saving={saving}
        fields={editFields}
        onCancel={onCancel}
        onRemove={onRemove}
        onSave={onSave} />}
    </div>
  );
};

Topic.propTypes = {
  categories: PropTypes.object,
  organizations: PropTypes.array,
  remove: PropTypes.func,
  save: PropTypes.func,
  source: PropTypes.object,
};

Topic.defaultProps = {
  categories: list(),
  organizations: [],
  remove: null,
  save: null,
  source: map(),
};

export default Topic;
