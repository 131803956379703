import React from 'react';
import Reflux from 'reflux';

import moment from 'moment';
import Cookies from 'js-cookie';
import * as FullStory from '@fullstory/browser';
import { fromJS, Iterable, List as list, Map as map } from 'immutable';
import { OktaAuth } from '@okta/okta-auth-js';

import Api from '../utils/api';
import History from '../utils/history';
import Token from '../utils/token';
import Text from '../utils/text';
import LaunchDarkly from '../utils/launchdarkly';
import Common from '../utils/common';
import { config } from '../constants/okta';
import Apps from '../constants/Apps';
import EdmProfile from '../constants/org_profiles/Edm';
import UserActions from '../actions/userActions';
import SearchActions from '../actions/searchActions';
import { SafeSearchTypes } from '../constants/Media';
import Messages from '../constants/Messages';
import { WidgetDefaults as ExpoDashPrefs } from '../constants/ExposureDashboard';

const SavePrefsUrl = `/ui/v4/documents${'_self' in React.createElement('div') ? '' : ''}/user_preferences`;
const AlertsUrl = '/ui/v4/alerts';
const EdmProfileUrl = `/ui/v4/documents${'_self' in React.createElement('div') ? '' : ''}/enterprise_domain_monitoring`;
const SalesforceIdsUrl = '/ui/v4/organizations';
const GetTokensUrl = '/ui/v4/login-policy/tokens';

const oktaAuth = new OktaAuth(config);

export const Defaults = {
  alerts: [],
  alerting: {
    keywords: {
      filters: [],
    },
  },
  auth_ack: null,
  bookmarks: {},
  blur_credentials: false,
  ccm_c: null,
  compact: true,
  enable_pii: false,
  eula_ack: false,
  expo_dash_widgets: ExpoDashPrefs,
  filters: {
    alerting: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    fraud: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    cves: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    home: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    iocs: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    breach: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now', breached_at: 'Last 90 Days', breached_since: 'now-90d', breached_until: 'now' }, default: 'true', org: 'true' }],
    domain: [{ name: 'Default', filters: { date: 'Last 120 Days', since: 'now-120d', until: 'now', breached_at: 'Last 90 Days', breached_since: 'now-90d', breached_until: 'now' }, default: 'true', org: 'true' }],
    ransomware: [{ name: 'Default', filters: { date: 'Last 30 Days', since: 'now-30d', until: 'now' }, default: 'true' }],
    expodash: [{ name: 'Default', filters: { date: 'Last 7 Days', since: 'now-7d', until: 'now' }, default: 'true' }],
  },
  glossary_highlight: true,
  disable_graph_overlays: false,
  history: {
    items: [],
    search: [],
  },
  href: '',
  home: {},
  image_cdn: true,
  navigation: 'self',
  notify: '',
  safe_search_toggle: 'SAFE',
  safe_search_ocr: SafeSearchTypes.MODERATE,
  search: {
    total: 0,
    data: [],
  },
  search_analytics: false,
  search_highlighting: true,
  tags: [],
  tags_cond: '',
  track_opt_out: false,
  twitter_ack: null,
};


const DefaultsHelp = {
  alerts: 'Alert preferences',
  auth_ack: 'When the user acknowledges FP Tools data is confidential',
  blur_credentials: 'Blur usernames, passwords, and domain details',
  bookmarks: 'User bookmark details',
  compact: 'View certain datasets in a more compact way',
  disable_graph_overlays: 'Enable/Disable selection options on search analytics graphs',
  enable_pii: 'Show PII in marketplace details automatically',
  history: 'Details about the user\'s activity on the site',
  home: 'Details about the user\'s homepage',
  href: 'How to display visited links',
  image_cdn: 'Whether to use a cdn to load images or not',
  filters: 'Filter preferences for the dashboards',
  glossary_highlight: 'Show Flashpoint term definitions in reports',
  navigation: 'Whether to open links within the same or new tab',
  notify: 'The time of the last viewed platform update',
  safe_search_toggle: 'Document previews displaying chat media',
  search: 'Saved search details',
  search_analytics: 'Display graphs/search analytics in search results automatically',
  search_highlighting: 'Highlight search results',
  tags: 'Report tag preferences',
  tags_cond: 'Report tag conditional logic',
};

const userPreferencesChannel = (window.BroadcastChannel) ? new BroadcastChannel('user_preferences') : false;

const UserStore = Reflux.createStore({
  listenables: [UserActions],

  init() {
    this.state = fromJS({
      sites: {},
      user: {
        message: '',
        loading: false,
        offline: false,
        activeOrganizations: [],
        alerts: [],
        apps: Apps,
        defaults: Defaults,
        defaultsHelp: DefaultsHelp,
        display: [],
        email: '',
        org_profiles: {},
        route: {},
        prefs: { ...Defaults },
        sfids: [],
        tokens: [],
      },
    });

    if (userPreferencesChannel) {
      userPreferencesChannel.onmessage = (e) => {
        UserActions.set(['user', 'prefs'], fromJS(e.data));
      };
    }
  },

  onAdminSavePrefs(prefs = {}, uid = '', reset = false) {
    let isLoggedIn = false;
    let updateUid = uid;
    if (!updateUid) {
      updateUid = this.state.getIn(['user', 'uid']);
      isLoggedIn = true;
    }
    const data = fromJS(prefs)
      .filterNot(v => v === undefined).toJS();
    Api.put(`${SavePrefsUrl}/${updateUid}`, data)
      .then(() => ((!isLoggedIn)
        ? SearchActions.set(['search', 'admin', 'user'], fromJS(data))
        : UserActions.set(['user', 'prefs'], fromJS(data))))
      .then(() => {
        SearchActions.set(['search', 'info', 'message'], !reset ? Messages.PreferencesSaved : Messages.PreferencesReset);
        UserActions.savePrefs.completed();
      })
      .catch(err => UserActions.savePrefs.failed(err.toString()));
  },

  /*
    Method to automatically reset an admin's user preferences after overwriting
    their own to another user's
  */
  onCheckAdminPrefs() {
    const prm = this.state.getIn(['user', 'prm']);
    if (!prm.some(p => /prf.w/.test(p))) return;
    const localPrefs = window.localStorage.prefs;
    if (!localPrefs || !JSON.parse(localPrefs).time) return;
    const stored = fromJS(JSON.parse(localPrefs));
    const now = moment().utc().unix();
    if (stored.get('time') <= now) {
      window.localStorage.removeItem('prefs');
      UserActions.adminSavePrefs(stored.get('data').toJS(), '', true);
    }
  },

  onPreview() {
    const { origin } = window.location;
    const { pathname, search, hash } = History.getCurrentLocation();
    const ProdUrl = 'https://fp.tools';
    const RcUrl = 'https://rc.fp.tools';
    const key = 'X-FP-RCBypass';
    const prm = this.state.getIn(['user', 'prm'], list());
    const cookie = Cookies.withConverter({ write: v => v, read: v => v });
    const href = `${pathname}${search}${hash}`;

    const domain = window.location.hostname === 'localhost'
      ? 'localhost'
      : `.${window.location.hostname.split('.').splice(-2).join('.')}`;

    // redirect internal users on prod without x-fp-bypassrc cookie to release candidate env
    if (!cookie.get(key, { domain }) &&
      origin.includes(ProdUrl) &&
      prm.includes('org.fp.r')) {
      window.location.href = `${RcUrl}${href}`;
    }
    // redirect external users with rc link to prod
    else if (!cookie.get(key, { domain }) &&
      origin.includes(RcUrl) &&
      !prm.includes('org.fp.r')) {
      window.location.href = `${ProdUrl}${href}`;
    }
  },

  onRoute() {
    const { pathname } = History.getCurrentLocation();
    let title = Text.Sentence(pathname.split('/').filter(v => v && v !== 'home').join(' - '));
    const route = this.state.getIn(['user', 'apps'])
      .filter(v => v.get('parent'))
      .filter(v => v.getIn(['path', 0]))
      // eslint-disable-next-line security/detect-non-literal-regexp
      .find(v => new RegExp(v.getIn(['path', 0])).test(pathname));

    UserActions.set(['user', 'route'], route);

    if (title.match(/dmtm/ig)) title = title.replace(/dmtm/ig, 'Domain Monitoring');
    if (title.match(/expodash/ig)) title = title.replace(/expodash/ig, 'Home (Beta)');

    document.title = Text.StripHtml(Text.Sentence(`Flashpoint - ${title}`));
  },

  onSaveHistory(type = '', query = map()) {
    const limit = 40;
    const optOut = this.state.getIn(['user', 'prefs', 'track_opt_out'])
      || this.state.getIn(['user', 'ffs'], []).some(p => /log.red.qry/.test(p))
      || this.state.getIn(['user', 'prm'], []).some(p => /log.red.qry/.test(p))
      || this.state.getIn(['user', 'groups'], []).some(p => /Tracking Opt-Out/.test(p));
    const historyList = fromJS({
      items: this.state.getIn(['user', 'prefs', 'history', 'items']) || list(),
      search: this.state.getIn(['user', 'prefs', 'history', 'search']) || list(),
    });

    let updates = list();
    if (type === 'items') {
      updates = historyList
        .get(type)
        .filterNot(v => v.get('title') === query.get('title'))
        .filterNot(v => v.get('path') === query.get('path'))
        .unshift(query);
    } else {
      updates = historyList
        .get(type)
        .unshift(query);
    }

    // if nothing was added, don't make a request
    if (historyList.get(type).count() === updates.size) return;
    updates = updates.slice(0, limit);


    // Don't want to store government activity
    if (optOut) {
      updates = list();
    }

    this.state = this.state.setIn(['user', 'prefs', 'history'], historyList.set(type, updates));
    UserActions.savePrefs();
  },

  onSaveOrgProfiles(profile = map(), sid) {
    if (profile.isEmpty()) return;
    const type = profile.get('type');
    const toSave = { ...profile
      .delete('type')
      .delete('mapping')
      .delete('domains')
      .map((v) => {
        if (v === '') return null;
        if (Iterable.isIterable(v)) return v.map(val => ((val === '') ? null : val));
        return v;
      })
      .toJS() };
    switch (type) {
      case 'edm': {
        if (!toSave.alerting.is_fresh) {
          toSave.alerting.is_fresh = null;
        }
        Api.put(`${EdmProfileUrl}/${sid}`, toSave)
          .then(() => UserActions.set(['user', 'org_profiles', sid, type], fromJS(profile)))
          .then(() => SearchActions.set(['search', 'info', 'message'], Messages.ProfileSaved))
          .catch((err) => {
            const id = moment.utc().unix();
            SearchActions.set(['search', 'info', 'message'], Messages.TroubleshootingId(err.response.data.detail, id));
          });
        break;
      }
      default: break;
    }
  },

  onSavePrefs(prefs = {}, opts = { optimistic: false }) {
    const uid = this.state.getIn(['user', 'uid']);
    const offline = this.state.getIn(['user', 'offline']);
    const valid = Object.keys(Defaults);
    const source = { ...this.state.getIn(['user', 'prefs']).toJS(), ...prefs };
    const data = Object.keys(source)
      .filter(key => valid.includes(key))
      .reduce((a, b) => ({ ...a, [b]: source[String(b)] }), {});

    // store user navigation preference in sessionStorage to make opening links easier
    if (sessionStorage) sessionStorage.setItem('navigation', data.navigation);
    if (offline) return false;
    if (opts.optimistic) {
      UserActions.set(['user', 'prefs'], fromJS(data));
      SearchActions.set(['search', 'prefs'], fromJS(data));
    }
    return Api.put(`${SavePrefsUrl}/${uid}`, data, [200], null, null, true)
      .then(() => UserActions.set(['user', 'prefs'], fromJS(data)))
      .then(() => SearchActions.set(['search', 'prefs'], fromJS(data)))
      .then(() => {
        if (userPreferencesChannel) userPreferencesChannel.postMessage(data);
        UserActions.savePrefs.completed();
      })
      .catch((err) => {
        const prm = this.state.getIn(['user', 'prm']);
        if (!prm.some(p => /prf.w/.test(p))) return;
        const localPrefs = window.localStorage.prefs;
        if (!localPrefs || !JSON.parse(localPrefs).time) return;
        const stored = fromJS(JSON.parse(localPrefs));
        UserActions.set(['user', 'prefs'], fromJS(stored));
        UserActions.savePrefs.failed(err.toString());
      });
  },

  onSaveSearch(search = map(), name = '') {
    const action = 'Manage Searches';
    const fn = () => History.navigateTo('/home/manage/searches');
    const message = `${name ? `Saved search as ${name}` : 'Changes Saved'}`;
    this.state = this.state.setIn(['user', 'prefs', 'search'], search);
    this.onSavePrefs()
      .then(() => SearchActions.set(['search', 'info'], map({
        message,
        timeout: name ? 3000 : null,
        action: name ? action : null,
        fn: name ? fn : () => null })));
  },

  onSet(k = '', v = '', update = true) {
    if (Array.isArray(k) && k.includes(-1)) return;
    this.state = Array.isArray(k) ? this.state.setIn(k, v) : this.state.set(k, v);
    if (update) this.trigger(this.state.toObject());
  },

  loadPrefs(prefs = {}) {
    const loc = History.getCurrentLocation();
    const user = this.state.get('user');
    const apps = this.state.getIn(['user', 'apps']);
    const prm = this.state.getIn(['user', 'prm']);
    const app = prefs.home && apps.find(v => v.get('value') === prefs.home.value);
    const acc = app && prm.some(p => app.get('test').test(p));
    const home = (acc && app) ? app : Common.Generic.Homepage(prm);
    const userHistory = prefs.history.items || [];
    const search = prefs.history.search || [];
    const saves = prefs.search.data || [];
    const apiOnly = prm.some(p => /acc.api.only/ig.test(p));
    const apiHome = apps.find(v => v.get('value') === 'integrations.tokens')?.getIn(['path', 0]);
    // History backwards compatibility
    const backwardsCompatibleItems = userHistory.filter(v => !v.time);
    const backwardsCompatibleSearch = search.filter(v => !v.time);
    const backwardsCompatibleSaves = saves.sort((a, b) => b.updated - a.updated);
    SearchActions.set(['search', 'prefs'], fromJS({ ...prefs, history: { items: backwardsCompatibleItems, search: backwardsCompatibleSearch }, search: { data: backwardsCompatibleSaves, total: prefs.search.total } }));
    UserActions.set('user', user.set('prefs', fromJS({ ...prefs, home, history: { items: backwardsCompatibleItems, search: backwardsCompatibleSearch }, search: { data: backwardsCompatibleSaves, total: prefs.search.total } })));
    // store user navigation preference in sessionStorage to make opening links easier
    if (sessionStorage) sessionStorage.setItem('navigation', prefs.navigation);
    // api-only access
    if (apiOnly) History.push(apiHome);
    // no ui access
    if (!prm.some(p => /acc.ui/ig.test(p)) && !apiOnly) UserActions?.setIn(['search', 'info', 'message'], Messages.InvalidUserProfile);
    // load default user home if none is specified
    if (loc.pathname === '/home') {
      // A 7 Day filter to collections search homepages
      const query = (['collections', 'search'].includes(home.get('parent', '')))
        ? { date: 'Last 7 Days', since: 'now-7d', until: 'now' }
        : null;
      History.push({ pathname: home.getIn(['path', 0]), query });
    }
  },

  async onLoadFlags() {
    const prm = this.state.getIn(['user', 'prm']);
    LaunchDarkly.load()
      .then((ld) => {
        if (!ld) return;
        const flags = Object.entries(ld).filter(([, v]) => v).map(([k]) => `ld.${k}`);
        Token.flg = flags;
        UserActions.set(['user', 'prm'], fromJS([...prm, ...flags]));
      })
      .then(() => UserActions.loadFlags.completed())
      .finally(() => UserActions.loadFlags.completed());
  },

  async onLoadPrefs() {
    UserActions.set(['user', 'offline'], false);
    const uid = this.state.getIn(['user', 'uid']);
    const defaultTags = [
      { id: 'CYBER_THREATS', name: 'Cyber Threats', path: 'Threat Type' },
      { id: 'PHYSICAL_THREATS', name: 'Physical Threats', path: 'Threat Type' }];
    Api.get(`${SavePrefsUrl}/${uid}`, null, [200, 403, 404], 30000, {}, true)
      .then(res => (res.ok ? res.data : {}))
      .then(res => ({
        ...Defaults,
        ...res,
        filters: { ...Defaults.filters, ...res.filters },
        tags: res.tags && res.tags.length ? res.tags : defaultTags }))
      .then(res => this.loadPrefs(res))
      .catch(() => {
        this.loadPrefs(Defaults);
        UserActions.set(['user', 'offline'], true);
      })
      .finally(() => {
        UserActions.loadPrefs.completed();
      });
  },

  async onLoadAnalytics() {
    const { pendo } = window;
    const { host } = window.location;
    const Intercom = window?.Intercom;
    const production = process.env.NODE_ENV !== 'development';
    const optOut = this.state.getIn(['user', 'prefs', 'track_opt_out'])
      || this.state.getIn(['user', 'ffs'], []).some(p => /log.red.qry/.test(p))
      || this.state.getIn(['user', 'prm'], []).some(p => /log.red.qry/.test(p))
      || this.state.getIn(['user', 'groups'], []).some(p => /Tracking Opt-Out/.test(p));

    const uid = this.state.hasIn(['user', 'fp_uid'])
      ? this.state.getIn(['user', 'fp_uid'])
      : this.state.getIn(['user', 'uid']);
    const sid = this.state.hasIn(['user', 'sf_id'])
      ? this.state.getIn(['user', 'sf_id'])
      : this.state.getIn(['user', 'sid']);
    const oid = Token.get('oid') || Token.get('org_ id');
    const ogn = Token.get('ogn');
    const usn = Token.get('usn');
    const eml = Token.get('email');
    const intercomAppID = 'h96kfhua';

    if (Intercom) {
      Intercom?.('boot', {
        app_id: intercomAppID,
        user_id: this.state.getIn(['user', 'uid']),
        name: usn,
        email: eml,
        company: {
          company_id: sid,
          name: ogn,
        },
      });
    }

    // do not track users who have opted out or are government users
    if (!optOut && production) {
      if (pendo) {
        pendo?.initialize?.({
          visitor: { id: uid },
          account: { id: sid },
        });
      }
      if (FullStory) {
        FullStory?.init?.({
          host: `//${host}/fsrelay`,
          script: `//${host}/fsrelay/s/fs.js`,
          orgId: '10SB9C',
          recordCrossDomainIFrames: true,
        });
        FullStory?.identify?.(uid, {
          sid_str: sid,
          oid_int: oid,
          ogn_str: ogn,
          displayName: usn,
          email: eml,
        });
      }
    }

    UserActions.loadAnalytics.completed();
  },

  async onLoadOrgProfiles(customer_id) {
    const salesforceId = customer_id || this.state.getIn(['user', 'defaults', 'orgProfile']) || this.state.getIn(['user', 'sid']);
    const salesforceIds = Text.SFIDConversion(salesforceId);
    const edmProfile = this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm']);
    const prm = this.state.getIn(['user', 'prm']);
    const edm = !edmProfile && Api.get(`${EdmProfileUrl}/${salesforceIds['18']}`, null, [200, 500, 501, 502, 503], 30000, {}, true)
      .then(res => (res.ok ? res.data : EdmProfile.default))
      .then((res) => {
        // check if org profile is old format, if it is, convert to new format and save
        if ('has_lowercase' in res.complexity) {
          const newComplexity = { ...res.complexity };
          newComplexity.num_optional_params_required = null;
          newComplexity.optional_fields = [];
          newComplexity.excluded_fields = [];
          newComplexity.required_fields = [];
          Object.keys(res.complexity).forEach((v) => {
            if (v.includes('has_')) {
              const field = v.replace('has_', '');
              const value = res.complexity[String(v)];
              delete newComplexity[String(v)];
              if (value === true) {
                newComplexity.required_fields.push({
                  field,
                  length: 1, // for the time being only require one character
                  ...(field === 'symbol' && { value: [] }),
                });
              } else if (value === false) {
                // must not include, add to excluded_fields
                newComplexity.excluded_fields.push({
                  field,
                  ...(field === 'symbol' && { value: [] }),
                });
              }
              // value of null or empty string represent may or may not contain
              // which are optional fields, but since this is new, we will not set optional fields
            }
          });
          const newRes = { ...res, complexity: newComplexity };
          return newRes;
        }
        return res;
      })
      .then(res => ({ ...res, mapping: EdmProfile.mapping, type: 'edm', sid: salesforceIds['18'] }))
      .then(res => UserActions.set(['user', 'org_profiles', salesforceIds['18'], 'edm'], fromJS(res)))
      .catch(() => UserActions.set(['user', 'org_profiles', salesforceIds['18'], 'edm'], fromJS({ ...EdmProfile.default, mapping: EdmProfile.mapping, type: 'edm', sid: salesforceIds['18'] })));

    // const hasEdm = prm.some(p => /edm/.test(p)) && edm;
    const cache = this.state.getIn(['user', 'sfids']);
    const enterprise_domain_id = 'bd813aa9-5e5e-407a-99e8-e81e00abe52e';
    const uSid = this.state.getIn(['user', 'sid']);
    const ogn = this.state.getIn(['user', 'ogn']);

    let sfids = cache;
    if (cache.isEmpty()) {
      // Grab orgs if this is enterprise domain monitoring admin
      if (prm.some(p => /dat.org.r/.test(p))) {
        sfids = await Api.get(`${SalesforceIdsUrl}/`, null, [200, 500, 501, 502, 503], 30000, {}, true)
          .then(res => (res.ok ? res.data : [{ salesforce_id: uSid, name: ogn }]))
          .then(res => fromJS(res).map(v => v.set('salesforce_id', Text.SFIDConversion(v.get('salesforce_id'))['18']).set('originalSID', v.get('salesforce_id'))))
          .catch(() => fromJS([{ salesforce_id: uSid, name: ogn }]));
      } else {
        sfids = fromJS([{ salesforce_id: uSid, name: ogn }]);
      }
      UserActions.set(['user', 'sfids'], sfids);
    }

    const owner = sfids.find(v => v.get('salesforce_id') === salesforceIds['18']);
    const domain_query = { salesforce_ids: [owner.get('salesforce_id'), owner.get('salesforce_id').slice(0, 15)].join(), keyclass_id: enterprise_domain_id };
    const domain = Api.get(`${AlertsUrl}/keywords`, domain_query,
      [200, 500, 501, 502, 503], 30000, {}, true)
      .then(res => (res.ok ? res.data : []))
      .then(res => res.filter(v => v.active).map(v => v.value))
      .then(res => res.flat().filter(v => v))
      .then(res => res.map(v => v.replace(/"/g, '')))
      .then(res => [...new Set([...res])])
      .then((res) => { UserActions.set(['user', 'org_profiles', salesforceIds['18'], 'edm', 'domains'], res.join()); return res; });

    return Promise.all([edm, domain])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(([_, domains]) => this.state.getIn(['user', 'defaults', 'filters'])
        .map((profile, type) => profile
          .map((p) => {
            if (p.has('org') && type !== 'breach') {
              const orgProfile = this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm']);
              UserActions.set(['user', 'defaults', 'orgProfile'], salesforceIds['18']);
              SearchActions.set(['search', 'org_profile'], orgProfile);
              return fromJS({
                ...p.toJS(),
                filters: {
                  ...p.get('filters').toJS(),
                  domain: domains.join(),
                  meets_org_profile_password_complexity: true,
                  alert_date_type: this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'alert_date_type']) || this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alert_date_type']), // changed structure, need to check both
                  password_length: this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'complexity', 'length']),
                  password_reset_policy: this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'password_reset_policy']) || this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'password_reset_policy']), // changed structure, need to check both
                  is_fresh: this.state.hasIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'is_fresh'])
                    ? (this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'is_fresh']) || '').toString()
                    : (this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'is_fresh']) || '').toString(),
                  ignore_hashes: this.state.hasIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'ignore_hashes'])
                    ? this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'alerting', 'ignore_hashes'])
                    : this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'ignore_hashes']),
                  customer_id: this.state.getIn(['user', 'org_profiles', salesforceIds['18'], 'edm', 'sid']),
                  complexity: {
                    required_fields: orgProfile.getIn(['complexity', 'required_fields'], []),
                    excluded_fields: orgProfile.getIn(['complexity', 'excluded_fields'], []),
                    optional_fields: orgProfile.getIn(['complexity', 'optional_fields'], []),
                    num_optional_params_required: orgProfile.getIn(['complexity', 'num_optional_params_required']),
                    ...orgProfile.getIn(['complexity', 'length']) != null && { meets_length: true },
                    ...(orgProfile.hasIn(['alerting', 'password_reset_policy'])
                      ? orgProfile.getIn(['alerting', 'password_reset_policy']) != null && { meets_reset_policy: true }
                      : orgProfile.getIn(['password_reset_policy']) != null && { meets_reset_policy: true }),
                  },
                },
              });
            }
            return p;
          })))
      .then((filters) => { UserActions.set(['user', 'defaults', 'filters'], filters); return filters; })
      .then((filters) => { UserActions.loadOrgProfiles.completed(); return filters; })
      .catch(() => UserActions.loadOrgProfiles.completed());
  },

  async onLoadTokens() {
    Api.get(GetTokensUrl, null, [200, 401, 403, 404, 500, 501, 502, 503], 30000, {}, true)
      .then(res => (res.ok ? res.data : {}))
      .then(res => UserActions.set(['user', 'tokens'], fromJS(res.tokens)))
      .then(() => UserActions.loadTokens.completed())
      .catch(() => SearchActions.set(['search', 'info', 'message'], Messages.SessionExpired));
  },

  async onLogout() {
    Token.del('X-FP-Ignite');
    Token.del(Storage.COOKIES_AUTHORIZATION);
    window?.Intercom('shutdown');
    window?.localStorage?.removeItem('prefs');
    oktaAuth.signOut();
  },

  async onSwitchEnv({ pathname, search, hash }) {
    const PROD_URL = 'https://fp.tools';
    const key = 'X-FP-RCBypass';
    const cookie = Cookies.withConverter({ write: v => v, read: v => v });
    const href = `${PROD_URL}${pathname}${search}${hash}`;
    const domain = window.location.hostname === 'localhost'
      ? 'localhost'
      : `.${window.location.hostname.split('.').splice(-2).join('.')}`;
    cookie.set(key, new Date().getTime(), { domain });
    window.location.href = href;
  },
});

export default UserStore;
