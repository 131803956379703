import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useRecoilState } from 'recoil';
import { Autocomplete as MuiAutocomplete, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';
import AdapterMoment from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MuiPickersUtilsProvider from '@mui/lab/LocalizationProvider';

import LocationGrid from './LocationGrid';
import PersonSearchTextField from './PersonSearchTextField';
import ThemeTooltip from './ThemeTooltip';
import PersonSearchState from '../../../stores/recoil/personSearch';


const useStyles = makeStyles(() => ({
  inputLabels: {
    fontSize: '16px',
    margin: '0 5px 5px 0',
  },
  tooltipIcon: {
    height: '18px',
    width: '18px',
    marginBottom: '5px',
  },
}));

const PageOne = ({
  dueDate,
  pageValidationError,
  setDueDate,
  setTextFieldValidationError,
  validationError,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useRecoilState(PersonSearchState.email);
  const [locationOne, setLocationOne] = useRecoilState(PersonSearchState.locationOne);
  const [locationNumber, setLocationNumber] = useRecoilState(PersonSearchState.locationNumber);
  const [locationTwo, setLocationTwo] = useRecoilState(PersonSearchState.locationTwo);
  const [locationThree, setLocationThree] = useRecoilState(PersonSearchState.locationThree);
  const [name, setName] = useRecoilState(PersonSearchState.name);
  const [phone, setPhone] = useRecoilState(PersonSearchState.phone);
  const [threatLevel, setThreatLevel] = useRecoilState(PersonSearchState.threatLevel);
  const [username, setUsername] = useRecoilState(PersonSearchState.username);

  const [dateTimeError, setDateTimeError] = useState(false);

  const dueDateTooltip = 'Enter the date and time this request is due. Time will be captured in your local timezone and converted to UTC for processing.';
  const emailTooltip = 'Enter a valid email address. If you have more than one email, separate them with a comma.';
  const nameTooltip = 'Enter the first and last name or full name of one individual. If the individual goes by more than one name, separate them with a comma.';
  const phoneTooltip = 'Enter a valid phone number. If you have more than one phone number, separate them with a comma.';
  const usernameTooltip = 'Enter a valid username. If you have more than one username, separate them with a comma.';

  const threatLevelOptions = ['Undetermined', 'Low', 'Moderate', 'High'];

  const handleDateTimeChange = (dateObj) => {
    const date = dateObj?.utc().toISOString();
    if (dateObj && (!dateObj._isValid || date < moment().utc().toISOString())) {
      setDateTimeError(true);
    } else {
      setDateTimeError(false);
      setDueDate(date || '');
    }
  };

  useEffect(() => {
    setTextFieldValidationError(dateTimeError);
  }, [dateTimeError]);

  return (
    <MuiPickersUtilsProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography display="inline" variant="subtitle1">One of these are required to begin search: name + location </Typography>
          <Typography display="inline" variant="subtitle1"><i><b>or</b></i> email <i><b>or </b></i> phone number <i><b>or</b></i> username. To learn more, visit the </Typography>
          <Typography display="inline" variant="subtitle1">
            <Link
              href="https://docs.flashpoint.io/flashpoint/docs/person-search-input-form-best-practices"
              target="_blank"
              underline="none"> Person Search Best Practices Guide.
            </Link>
          </Typography>
        </Grid>
        {pageValidationError && validationError()}
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <Typography className={classes.inputLabels}>Threat level</Typography>
            <MuiAutocomplete
              disableClearable
              openOnFocus
              value={threatLevel}
              onChange={e => setThreatLevel(e.target.textContent)}
              options={threatLevelOptions}
              renderInput={(params) => {
                const { inputProps } = params;
                inputProps.autoComplete = 'disable';
                return (
                  <TextField
                    {...params}
                    required
                    variant="outlined"/>
                );
              }}/>
          </Grid>
          <Grid item xs={6}>
            <Grid alignItems="center" container>
              <Typography className={classes.inputLabels}>Due date</Typography>
              <ThemeTooltip title={dueDateTooltip}>
                <HelpIcon className={classes.tooltipIcon} color="primary"/>
              </ThemeTooltip>
            </Grid>
            <DateTimePicker
              ampm={false}
              minDateTime={moment()}
              disablePast
              value={dueDate}
              onChange={value => handleDateTimeChange(value)}
              renderInput={(params) => {
                const { inputProps } = params;
                inputProps.placeholder = '';
                return (
                  <TextField
                    {...params}
                    error={dateTimeError}
                    helperText={dateTimeError && 'This field is not valid'}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <Grid alignItems="center" container>
              <Typography className={classes.inputLabels}>Name</Typography>
              <ThemeTooltip title={nameTooltip}>
                <HelpIcon className={classes.tooltipIcon} color="primary"/>
              </ThemeTooltip>
            </Grid>
            <PersonSearchTextField
              formField="name"
              placeholder="First and last"
              setTags={setName}
              setTextFieldValidationError={setTextFieldValidationError}
              tags={name}/>
          </Grid>
          <Grid item xs={6}>
            <Grid alignItems="center" container>
              <Typography className={classes.inputLabels}>Email </Typography>
              <ThemeTooltip title={emailTooltip}>
                <HelpIcon className={classes.tooltipIcon} color="primary"/>
              </ThemeTooltip>
            </Grid>
            <PersonSearchTextField
              formField="email"
              placeholder="example@me.com"
              setTags={setEmail}
              setTextFieldValidationError={setTextFieldValidationError}
              tags={email}/>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <Grid alignItems="center" container>
              <Typography className={classes.inputLabels}>Phone </Typography>
              <ThemeTooltip title={phoneTooltip}>
                <HelpIcon className={classes.tooltipIcon} color="primary"/>
              </ThemeTooltip>
            </Grid>
            <PersonSearchTextField
              formField="phone"
              placeholder="555-555-5555"
              setTags={setPhone}
              setTextFieldValidationError={setTextFieldValidationError}
              tags={phone}/>
          </Grid>
          <Grid item xs={6}>
            <Grid alignItems="center" container>
              <Typography className={classes.inputLabels}>Username </Typography>
              <ThemeTooltip title={usernameTooltip}>
                <HelpIcon className={classes.tooltipIcon} color="primary"/>
              </ThemeTooltip>
            </Grid>
            <PersonSearchTextField
              formField="username"
              setTags={setUsername}
              setTextFieldValidationError={setTextFieldValidationError}
              tags={username}/>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <LocationGrid
              currentLocation={locationOne}
              locationNumber={0}
              setCurrentLocation={setLocationOne}
              setLocationNumber={setLocationNumber}
              validationError={validationError} />
            {locationNumber > 0 &&
              <LocationGrid
                currentLocation={locationTwo}
                locationNumber={1}
                setCurrentLocation={setLocationTwo}
                setLocationNumber={setLocationNumber}
                validationError={validationError} />}
            {locationNumber > 1 &&
              <LocationGrid
                currentLocation={locationThree}
                locationNumber={2}
                setCurrentLocation={setLocationThree}
                setLocationNumber={setLocationNumber}
                validationError={validationError} />}
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

PageOne.propTypes = {
  dueDate: PropTypes.string.isRequired,
  pageValidationError: PropTypes.bool.isRequired,
  setDueDate: PropTypes.func.isRequired,
  setTextFieldValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.func.isRequired,
};

export default PageOne;
