/* eslint-disable arrow-parens */
import { matchPath } from 'react-router-dom';

export interface IRoutePathParams {
  [key: string]: string;
}

export interface IRouteMapperContext {
  // todo: maybe
  // routeConfig: IRouteMapperConfig;
  // originalRoute: string;
  // transformedRoute: string;
  pathParams: IRoutePathParams;
  newParams: URLSearchParams;
}

export interface IRouteQueryParamMapperConfig {
  /**
   * Old param name
   */
  oldParam: string;
  /**
   * New param name
   */
  newParam: string | undefined;
  /**
   * Transform the value
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer?: (paramConfig: IRouteQueryParamMapperConfig, value: any, context: IRouteMapperContext) => any;
}

export interface IRouteMapperConfig {
  /**
   * Old route parameterized. ex. /route/:routeParam/example
   * Has to be a valid url or just a path
   */
  oldRoute: string | string[];
  /**
   * New route parameterized using same param names as oldRoute. ex. /newRoute/:routeParam
   */
  newRoute: string;
  /**
   * Param config if you want param names or values transformed
   */
  queryParams?: IRouteQueryParamMapperConfig[];
  /**
   * If you want to handle the transformation yourself you can by passing a transformer.
   * We will still handle the initial transformation and pass it as the last param for you to access
   */
  transformer?: (
    routeConfig: IRouteMapperConfig,
    originalRoute: string,
    transformedRoute: string,
    context: IRouteMapperContext,
  ) => string | undefined;
}

/**
 *
 * @param routeConfigs List of potential configs
 * @param originalRoute MUST match one routeConfig(s).oldRoute exactly else will return undefined
 * @param forceTLD manually set the top-level domain
 * @returns string | undefined
 */

/* function decodeNestedURI returns the base URI of one that's been encoded multiple times */
export function decodeNestedURI(nestedURI: string) {
  let oldURI = nestedURI;
  let newURI;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // attempt to decode double/triple encoded urls
    // some urls appear to handle this poorly
    // revert to default behavior in those cases
    // to prevent the entire page from going down
    try {
      newURI = decodeURIComponent(oldURI);
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore:next-line
    } catch (err: Error) {
      newURI = oldURI;
      if (!err.message.includes('malformed')) {
        throw err;
      }
    }

    // quit when decoding didn't change anything
    if (newURI === oldURI) {
      break;
    }

    oldURI = newURI;
  }
  return newURI;
}

export function addEncodedPlaceholders(url: string) {
  return url.replace(/%2526/gi, 'ampersand_placeholder').replace(/%2523/gi, 'hash_placeholder');
}

export function tryValidUrlParse(url: string) {
  try {
    return new URL(decodeNestedURI(addEncodedPlaceholders(url)));
  } catch {
    return false;
  }
}

export function generateUrl(url: string) {
  const validUrl = tryValidUrlParse(url);
  if (validUrl) {
    return validUrl;
  }

  // This is only used to create a URL object, you have to provided a valid url
  // It's just to build out path and search params for use within the mapper
  return new URL(`https://flashpoint.io${url.startsWith('/') ? url : `/${url}`}`);
}

export function parsePathFromUrl(url: string) {
  return generateUrl(url).pathname;
}

function generateRouteObject(config: IRouteMapperConfig) {
  return {
    ...config,
    path: parsePathFromUrl(config.oldRoute as string),
  };
}

export function convertRouteConfigToBaseRouteObject(routeConfig: IRouteMapperConfig) {
  return Array.isArray(routeConfig.oldRoute)
    ? routeConfig.oldRoute.map((oldRoute) => generateRouteObject({ ...routeConfig, oldRoute }))
    : generateRouteObject(routeConfig);
}

export function transformParams(
  existingParams: URLSearchParams,
  paramConfigs: IRouteQueryParamMapperConfig[],
  pathParams: IRoutePathParams,
): URLSearchParams {
  return new URLSearchParams(
    paramConfigs.reduce((updatedParams, paramConfig) => {
      const existingParam = updatedParams.get(paramConfig.oldParam);

      if (!existingParam && paramConfig.oldParam) return updatedParams;

      updatedParams.delete(paramConfig.oldParam);
      const value = paramConfig.transformer
        ? paramConfig.transformer(paramConfig, existingParam, { pathParams, newParams: updatedParams })
        : existingParam;

      if (value !== null && value !== undefined && paramConfig.newParam !== undefined) {
        updatedParams.append(paramConfig.newParam as string, value);
      }
      return updatedParams;
    }, existingParams),
  );
}

export const routeMapper = (routeConfigs: IRouteMapperConfig[], originalRoute: string, forceTLD?: string) => {
  const reactRouterShape = routeConfigs.map(convertRouteConfigToBaseRouteObject).flat();

  const path = parsePathFromUrl(originalRoute);
  const matchedRoute = reactRouterShape.find((shape) =>
    matchPath(path, {
      ...shape,
      exact: true,
      strict: true,
    }),
  );

  if (!matchedRoute) {
    return undefined;
  }

  const { oldRoute, newRoute, queryParams = [], transformer } = matchedRoute;

  // + plus signs get converted into space for some reason, but we must honor the backend query syntax
  const cleanedOriginalRoute = addEncodedPlaceholders(originalRoute.replace(/%2B/gi, '+'));

  // later steps in the process will mangle the url params if we do not decode before new URL()
  const decodedOriginalRoute = decodeNestedURI(cleanedOriginalRoute);
  const originalRouteFullUrl = generateUrl(decodedOriginalRoute);

  const paths = originalRouteFullUrl.pathname.split('/');
  const pathParams: IRoutePathParams = {};
  let newParams: URLSearchParams = new URLSearchParams();

  let transformedRoute = generateUrl(oldRoute as string)
    .pathname.split('/')
    .reduce((updatedRoute, currentRouteParam, currentIndex) => {
      const isTokenized = currentRouteParam.startsWith(':');
      if (!isTokenized) return updatedRoute;
      pathParams[currentRouteParam as string] = paths[Number(currentIndex)];
      return updatedRoute.replace(currentRouteParam, pathParams[currentRouteParam as string]);
    }, newRoute);

  if (queryParams.length) {
    newParams = transformParams(originalRouteFullUrl.searchParams, queryParams, pathParams);
    let qs = newParams
      .toString()
      .replace(/\w+=&/g, '')
      .replace(/&\w+=$/, '')
      .replace(/ampersand_placeholder/g, '%26')
      .replace(/hash_placeholder/g, '%23');

    // don't leave a hanging '?' symbol for path only transformations
    qs = qs ? `?${qs}` : qs;
    transformedRoute = `${transformedRoute}${qs}`;
  }

  if (!transformer) {
    return transformedRoute;
  }

  let finalLink = transformer(matchedRoute, originalRoute, transformedRoute, { pathParams, newParams });
  if (forceTLD && finalLink) {
    finalLink = `${forceTLD}${finalLink}`;
  }
  return finalLink;
};
