import Reflux from 'reflux';

const UserActions = Reflux.createActions({
  adminSavePrefs: {},
  checkAdminPrefs: {},
  logout: {},
  loadAnalytics: { asyncResult: true },
  loadFlags: { asyncResult: true },
  loadOrgProfiles: { asyncResult: true },
  loadPrefs: { asyncResult: true },
  loadTokens: { asyncResult: true },
  changePassword: {},
  generateToken: {},
  preview: {},
  route: {},
  saveHistory: {},
  saveOrgProfiles: {},
  savePrefs: { asyncResult: true },
  saveSearch: {},
  set: {},
  switchEnv: {},
});

export default UserActions;
