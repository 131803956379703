import React from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { FlashpointLogo } from '../../Logo';
import { convert } from '../../../../utils/ignite/convert';

import styles from './authError.module.scss';

export const ErrorMessage = ({ error }: { error: Error }): JSX.Element => {
  if (error.name && error.message) {
    return (
      <div>
        {error.name}: {error.message}
      </div>
    );
  }
  return <div>{error.toString()}</div>;
};

export interface AuthErrorProps {
  error: Error;
}

const IGNITE_URI = '//app.flashpoint.io';
const FPTOOLS_404_REDIRECT = '//app.flashpoint.io/to/fptools/404';

export const AuthError = ({ error }: AuthErrorProps): JSX.Element => {
  const { oktaAuth } = useOktaAuth();

  if (/client application/gi.test(error?.message)) {
    const originalUri = oktaAuth.getOriginalUri() || '';
    const redirectUri = convert(originalUri);
    const igniteUri = redirectUri ? `${IGNITE_URI}${redirectUri}` : FPTOOLS_404_REDIRECT;
    window.location.href = igniteUri;
    return <div />;
  }

  return (
    <div className={styles.container}>
      <FlashpointLogo />
      <ErrorMessage error={error} />
    </div>
  );
};
