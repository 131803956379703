import React from 'react';
import { isEqual } from 'lodash';
import { deepDiff } from '../utils/object';

const prevState = ref => ref.current;

const easyUseState = (defState, opts = { debug: false }) => {
  const [currentState, setState] = React.useState(defState);
  const ref = React.useRef(defState);

  const setStateIsEqual = (newState) => {
    if (!isEqual(newState, prevState(ref))) {
      // eslint-disable-next-line no-console
      if (opts.debug) console.log(deepDiff(prevState, newState));
      setState(newState);
    }
    ref.current = newState;
  };

  return [currentState, setStateIsEqual];
};

export const easyDebugState = defState => (
  easyUseState(defState, { debug: true })
);

export default easyUseState;
