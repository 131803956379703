import Reflux from 'reflux';

const SearchActions = Reflux.createActions({
  export: {},
  loadResources: { asyncResult: true },
  loadSites: { asyncResult: true },
  loadTerms: { asyncResult: true },
  loadHistogram: { asyncResult: true },
  set: {},
  search: { asyncResult: true },
  searchComments: {},
  searchIntel: {},
  searchReference: {},
  searchRelated: {},
  searchThread: {},
  searchTranslate: {},
  text: {},
  upCommentThread: {},
  userPreferences: {},
});

export default SearchActions;
