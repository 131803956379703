import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import chunk from 'lodash/chunk';

import { List as list, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import style from './selectfilter.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import { Track } from '../../utils/track';

const useStyles = makeStyles({
  selectfilter: {
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

const SelectedFilter = ({
  advanced,
  inline,
  item,
  items,
  onApply,
  onFilter,
  option,
  toggle,
}) => {
  const classes = useStyles();
  const [selection, setSelection] = useState([]);
  const [initial, setInitial] = useState(true);
  const rows = items.size < 10 ? 3 : 5;

  useEffect(() => (!item
    ? setSelection((initial && items.toJS()) || [])
    : setSelection((item || '')
      .trim()
      .split(',')
      .filter(v => v))
  ), [item]);

  const onChange = (opt) => {
    setInitial(false);
    if (opt === 'all') {
      setSelection(items.toJS());
      if (onFilter) onFilter(fromJS({ [option]: items.join() }));
    } else if (opt === 'none') {
      setSelection([]);
      if (onFilter) onFilter(fromJS({ [option]: '' }));
    } else {
      const value = selection.includes(opt)
        ? selection.filter(v => v !== opt)
        : selection.concat(opt);
      const select = [...new Set(value)];
      setSelection(select);
      if (onFilter) onFilter(fromJS({ [option]: select.join() }));
    }
  };

  const onSearch = () => {
    if (inline) return;
    const value = selection.filter(v => v).join(',');
    const { pathname, query, hash } = History.getCurrentLocation();
    toggle();

    if (onApply) {
      onApply(fromJS({ [option]: value }));
      return;
    }

    const type = selection;

    const event = {
      type,
      filters: { query: query.query, type },
      origin: '.base .menu',
    };

    Track(event, 'Search');

    History.push({
      pathname,
      query: { ...query, [option]: value, skip: undefined },
      hash,
    });
  };

  return (
    <Grid
      fluid
      className={cx([
        style.base,
        style.selectfilter,
        inline && style.inline,
        advanced && style.advanced,
        classes.selectfilter,
      ])}>
      <Row>
        <Col xs={12} className={style.selection}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onChange('all')}
            data-testid="selectfilter.select-all">
            Select All
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onChange('none')}
            data-testid="selectfilter.unselect-all">
            Unselect All
          </Button>
        </Col>
        <Col xs={12} className={style.menu}>
          {chunk(items.sortBy(v => v).toArray(), rows)
            .map(values => (
              <FormControl key={values.slice(0, 1)}>
                {values.map(v => (
                  <FormControlLabel
                    key={v}
                    label={Text.Sentence(v)}
                    control={<Checkbox
                      checked={selection.includes(v)}
                      onChange={() => onChange(v)}
                      data-testid={`selectfilter.${v}`}/>
                  }
                />
              ))}
              </FormControl>
          ))}
        </Col>
        {toggle &&
        <Row>
          <Col xs={12} className={cx([style.footer, advanced && style.advanced])}>
            <div className={style.error} />
            <div className={style.actions}>
              <Button
                className={style.left}
                onClick={() => setSelection([])}
                data-testid="selectfilter.clear-all">
                Clear All
              </Button>
              <Button
                onClick={() => toggle()}
                data-testid="selectfilter.cancel">
                Cancel
              </Button>
              <Button
                className={style.active}
                onClick={() => onSearch(false)}
                data-testid="selectfilter.apply">
                Apply
              </Button>
            </div>
          </Col>
        </Row>}
      </Row>
    </Grid>
  );
};

SelectedFilter.propTypes = {
  advanced: PropTypes.bool,
  inline: PropTypes.bool,
  item: PropTypes.string,
  items: PropTypes.object,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  option: PropTypes.string,
  toggle: PropTypes.func,
};

SelectedFilter.defaultProps = {
  advanced: false,
  inline: false,
  item: '',
  items: list(),
  onApply: null,
  onFilter: null,
  option: '',
  toggle: null,
};

export default SelectedFilter;
