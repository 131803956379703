import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map } from 'immutable';
import {
    Icon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
  } from '@mui/material';

import style from './credentials.module.scss';
import Token from '../../../utils/token';

const CustomerCredentialResult = ({
  category,
  columns,
  index,
  response,
  result,
  sort,
}) => {
  const [showRow, setShowRow] = useState();
  const hasHostDataPrm = Token.get('prm').some(v => /dat.ccm.cus.hd.r/.test(v));
  const hasCookiesPrm = Token.get('prm').some(v => /dat.ccm.cus.ha.r/.test(v));
  const sortedResult = sort.includes('asc') ? result.get('results') : result.get('results').sortBy(v => v.getIn(['first_observed_at', 'date-time'])).reverse();

  return (
    <Row
      key={result.get('key')}
      className={cx([style.row, index !== response.get('results').size - 1 && style.line])}>
      <Col xs={12}>
        <TableContainer key={result.get('key')}>
          <Table>
            <TableHead>
              <TableRow
                onClick={(e) => {
                  e.preventDefault();
                  setShowRow(!showRow); }}>
                <TableCell style={{ width: category === 'credentials-search' ? '50%' : 'auto' }} sx={{ '&.MuiTableCell-head': { color: '#111321', fontWeight: 'bold', textTransform: 'none' } }}>
                  {category === 'domain' && `Matched on ${result.getIn(['results', 0, 'affected_domain'])}`}
                  {(!category || category !== 'domain') && `Matched on ${result.getIn(['results', 0, 'username'])}`}
                </TableCell>
                {category !== 'credentials-search' && hasHostDataPrm && hasCookiesPrm && <TableCell />}
                {category !== 'credentials-search' && <TableCell />}
                <TableCell align="right" style={{ width: category === 'credentials-search' ? '50%' : 'auto' }}>
                  <Tooltip title="Expand for credentials and breach details.">
                    <Icon style={{ color: '#111321' }}>keyboard_arrow_down</Icon>
                  </Tooltip>
                </TableCell>
              </TableRow>
              {showRow &&
                <TableRow>
                  {columns(result.get('key'))
                    .map(col => (
                      <TableCell
                        key={col.id}
                        style={col.style}>
                        {col.text &&
                          <div
                            data-for="global.tooltip"
                            data-tip={col.text.match(/(.{1,75})(?:\n|$| )/g).join('<br />')}
                            className={cx([col.sort ? style.sort : null])}>
                            {col.label}
                          </div>}
                        {!col.text && <div>{col.label}</div>}
                      </TableCell>))}
                </TableRow>}
            </TableHead>
            {showRow &&
              <TableBody>
                {(sortedResult || list())
                  .map((r, i) => (
                    <TableRow
                      hover
                      onClick={(e) => {
                        e.preventDefault();
                        if (!r.has('fpid') || e.target.parentNode.className.includes('bookmark')) return;
                        const { origin } = window.location;
                        window.open(`${origin}/home/compromised/customer-credentials/items/${r.get('fpid')}::${result.get('key')}`);
                      }}
                      key={r.get('fpid') || ''}>
                      {columns(result.get('key')).map(col => (
                        <TableCell
                          onMouseEnter={() => ReactTooltip.rebuild()}
                          key={col.id}
                          style={col.style}>
                          {col.render(r, i)}
                        </TableCell>))}
                    </TableRow>))}
              </TableBody>}
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

CustomerCredentialResult.propTypes = {
  category: PropTypes.string,
  columns: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  response: PropTypes.object,
  result: PropTypes.object,
  sort: PropTypes.string,
};

CustomerCredentialResult.defaultProps = {
  category: 'analysis',
  response: map(),
  result: map(),
  sort: 'desc',
};

export default CustomerCredentialResult;
