/* eslint react/prefer-stateless-function: "off" */
import React from 'react';

function withAttrs(WrappedComponent, attrs) {
  return class WithAttrs extends React.Component {
    render() {
      return <WrappedComponent {...attrs} {...this.props} />;
    }
  };
}

export default withAttrs;
