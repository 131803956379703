/* eslint-disable no-param-reassign */
import * as React from 'react';
import { useContext, useState, useEffect } from 'react';

import cx from 'classnames';
import { Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import style from './merchants.module.scss';

import merchant from '../../../../public/static/merchant.svg';

import cardReader from '../../../../public/static/card-reader.svg';
import creditCard from '../../../../public/static/credit-card.svg';

import Looker from '../../utils/Looker';
import query from './Merchants/query';
import { devClient } from '../../../gql/apolloClient';
import {
  UserContext,
} from '../../../components/utils/Context';

const useStyles = makeStyles({
    homeDashboard: {
        display: 'flex',
        flex: 1,
        flexFlow: 'column',
        height: '425px',
    },
    header: {
        flex: '0 0 88px',
        fontSize: '24px',
        fontWeight: '600',
        padding: '25px 0 15px;',
    },
    rowPadding: {
        paddingTop: '30px',
        justifyContent: 'space-between',
    },
    h0: {
      display: 'inline-block',
      fontSize: '18px !important',
      fontWeight: '400',
      padding: '15px 0 0',
    },
    col3: {
      flexBasis: '33%',
      maxWidth: '33%',
    },
    title: {
      fontWeight: '400',
      fontSize: '24px',
      margin: '0px 10px',
    },
    subTitle: {
      color: '#5c6ae0',
      fontWeight: '400',
      fontSize: '14px',
      margin: '0px 10px 15px',
    },
    body: {
      fontWeight: '400',
      fontSize: '16px',
      margin: '0px 10px 10px',
      height: '70px',
    },
    cta: {
      fontWeight: '600',
      fontSize: '16px',
      color: '#5c6ae0',
      cursor: 'pointer',
      justifyContent: 'right',
      display: 'flex',
      margin: '15px 10px 5px',
    },
    icon: {
      margin: '20px 10px 15px 10px',
    },
    dropdown: {
      margin: '15px 10px 10px',
    },
    button: {
      width: '100%',
      justifyContent: 'flex-start',
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: '10px',
    },
    },
  });

const Merchants = () => {
    const classes = useStyles();
    const user = useContext(UserContext);
    const sid = user.get('sid');
    const [bin, setBin] = useState();
    const [cpp, setCpp] = useState();
    const [fraud, setFraud] = useState();

    const { loading, data } = useQuery(query, {
      client: devClient,
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const oneHourStr = 'less than 1 hour ago';
    const oneDayStr = 'more than 24 hours ago';
    const oneHour = 1;
    const oneDay = 24;
    if (data?.merchantsLastUpdatedTime) {
      const now = moment();
      let duration = moment.duration(now.diff(moment(data.merchantsLastUpdatedTime.binAttack)));
      if (duration.asHours() < oneHour) {
        setBin(oneHourStr);
      } else if (duration.asHours() > oneDay) {
        setBin(oneDayStr);
      } else {
        setBin(`${Math.round(duration.asHours())} hours ago`);
      }
      duration = moment.duration(now.diff(moment(data.merchantsLastUpdatedTime.cppMerchants)));
      if (duration.asHours() < oneHour) {
        setCpp(oneHourStr);
      } else if (duration.asHours() > oneDay) {
        setCpp(oneDayStr);
      } else {
        setCpp(`${Math.round(duration.asHours())} hours ago`);
      }
      duration = moment.duration(now.diff(moment(data.merchantsLastUpdatedTime.highFraud)));
      if (duration.asHours() < oneHour) {
        setFraud(oneHourStr);
      } else if (duration.asHours() > oneDay) {
        setFraud(oneDayStr);
      } else {
        setFraud(`${Math.round(duration.asHours())} hours ago`);
      }
    }
  }, [data]);

  if (loading) {
    return <CircularProgress size={25} thickness={2} />;
  }
    return (
      <>
        <Row className={cx([classes.homeDashboard, style.padSmall])}>
          <Row style={{ width: '100%' }}>
            <Col xs={12}>
              <div className={classes.header}>High-Risk Merchants</div>
              <div style={{ fontSize: '18px' }}>Daily updates to the following lists of CPP merchants, high-fraud merchants, and BIN attack merchants – so you’re able to quickly spot and mitigate potential fraud.
              </div>
            </Col>
          </Row>
          <Row className={classes.rowPadding}>
            <Col xs={4} className={classes.col3}>
              <Paper className={style.card} style={{ padding: '1rem 1.5rem' }}>
                <img
                  data-for="global.tooltip"
                  data-tip="Card Reader"
                  alt="Card Reader"
                  src={cardReader}
                  className={classes.icon} />
                <div className={classes.title}>CPP Merchants</div>
                <div className={classes.subTitle}>Updated {cpp}</div>
                <div className={classes.body}>
                  Merchants identified as the Common Point of Purchase (CPP) based on observed
                  fraudulent activity.
                </div>
              </Paper>
            </Col>
            <Col xs={4} className={classes.col3}>
              <Paper className={style.card} style={{ padding: '1rem 1.5rem' }}>
                <img
                  data-for="global.tooltip"
                  data-tip="Merchant"
                  alt="Merchant"
                  src={merchant}
                  className={classes.icon} />
                <div className={classes.title}>High-Fraud Merchants</div>
                <div className={classes.subTitle}>Updated {fraud}</div>
                <div className={classes.body}>
                  Merchants found to be executing a high volume of fraudulent transactions.
                </div>
              </Paper>
            </Col>
            <Col xs={3} className={classes.col3}>
              <Paper className={style.card} style={{ padding: '1rem 1.5rem' }}>
                <img
                  data-for="global.tooltip"
                  data-tip="Credit Card"
                  alt="Credit Card"
                  src={creditCard}
                  className={classes.icon} />
                <div className={classes.title}>BIN Attack Merchants</div>
                <div className={classes.subTitle}>Updated {bin}</div>
                <div className={classes.body}>
                  Merchants used by illicit actors executing BIN attacks to carry out
                  card-not-present fraud.
                </div>
              </Paper>
            </Col>
          </Row>
        </Row>
        <Col xs={12}>
          <Looker
            height="160rem"
            dashboard="/dashboards-next/shops_production::merchants"
            sid={sid} />
        </Col>
      </>
    );
    };

export default Merchants;
