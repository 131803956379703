import React from 'react';

import moment from 'moment';
import { Icon } from '@mui/material';
import { List as list } from 'immutable';
import ReactTooltip from 'react-tooltip';

import Text from './text';
import Apps from '../constants/Apps';
import InternalLink from '../components/utils/InternalLink';

const cvesDatapoints = {
  icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
    (<Icon data-for="icon.tooltip" data-tip="Vulnerability" className="material-icons" style={{ fontSize: '14px' }}>bug_report</Icon>),
  },
  id: { key: 'id', type: 'text', label: 'ID', text: 'id of the cve' },
  total: { key: 'total', type: 'text', label: 'total', text: 'total results from search' },
  unique_total: { key: 'data', type: 'text', label: 'total', text: 'total results from search', render: v => [...new Set(v.map(d => d.get('title')))].length },
  created: { key: 'sort_date', type: 'date', label: 'created', text: 'date cve was created', render: v => (v ? moment.utc(v).format('MM/DD/YYYY HH:mm:ss') : '-') },
  updated: { key: 'cve.nist.updated_at.timestamp', type: 'date', label: 'updated', text: 'date cve was updated', style: { textAlign: 'right' }, render: v => (v ? moment.utc(v * 1000).format('MM/DD/YYYY HH:mm:ss') : '-') },
  key: { key: 'key', type: 'text', label: 'name', text: 'name of the record', render: v => v },
  doc_count: { key: 'doc_count', type: 'text', label: 'total', text: 'total results from search', style: { width: '65px', textAlign: 'right' }, render: v => v },
  docCount: { key: 'docCount', type: 'text', label: 'total', text: 'total results from search', style: { width: '65px', textAlign: 'right' }, render: v => v },
  mentions: { key: 'doc_count', type: 'text', label: 'mentions', text: 'total results from search', style: { width: '65px', textAlign: 'right' }, render: v => v },
  title: { key: 'title', type: 'text', label: 'cve id', text: 'id of the cve', style: { width: '105px' } },
  text: { key: 'cve.mitre.body.text/plain', type: 'text', label: 'mitre description', text: 'nvd description of the cve', style: { width: '55%' } },
  products: { key: 'cve.nist.products', type: 'text', label: 'products', text: 'products affected by the exploit', render: (v = list()) => Text.Highlight(v.map(p => `${p.get('product_name')}`).join('<br />')) || '-' },
  references: { key: 'cve.nist.references', type: 'text', label: 'reference', text: 'reference for the exploit', render: (v = list()) => Text.Highlight(v.map(p => `${p.get('name')}`).join('<br />')) || '-' },
  exploits: { key: 'cve.nist.products', type: 'text', label: '# exploits', text: 'number of exploits found', style: { width: '65px', textAlign: 'center' }, render: (v = list()) => v.count() },
  source: { id: 'site.title', key: 'site.title', type: 'text', label: 'source', text: 'site where the exploit was found', style: { width: '65px' } },
  site_uri: { id: 'site.source_uri', key: 'site.source_uri', type: 'text', label: 'site uri', text: 'site where the exploit was found', style: { width: '65px' } },
  source_uri: { id: 'source_uri', key: 'source_uri', type: 'text', label: 'source uri', text: 'source uri where exploit was found', style: { width: '65%' }, render: v => v },
  site_source_uri: { key: 'site.source_uri', type: 'text', label: 'domain', text: 'domain uri where exploit was found', style: { width: '105px' } },
  exploit_type: { key: 'id', type: 'text', label: 'ID', text: 'id of the cve' },
  exlpoit_title: { key: 'id', type: 'text', label: 'ID', text: 'id of the cve' },
  exploit_url: { key: 'id', type: 'text', label: 'ID', text: 'id of the cve' },
  exploit_total: { key: 'doc_count', type: 'text', label: '# of exploits', text: 'number of exploits' },
  advisory_type: { key: 'advisory_type', type: 'text', label: 'ID', text: 'id of the cve' },
  advisory_title: { key: 'advisory_title', type: 'text', label: 'ID', text: 'id of the cve' },
  advisory_url: { key: 'advisory_url', type: 'text', label: 'ID', text: 'id of the cve' },
};

const datapoints = {
  all: {
    icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '35px' }, render: (v) => {
      if (!v) return (<Icon data-for="icon.tooltip" data-tip="Other" className="material-icons" style={{ fontSize: '14px' }}>help_outline</Icon>);
      const value = v.slice(-1).join();
      const app = Apps.find(a => a.value.includes(`search.${value}`)) || {};
      return (<Icon data-for="icon.tooltip" data-tip={Text.Sentence(app.label)} className="material-icons" style={{ fontSize: '14px' }}>{app.icon}</Icon>);
    } },
    sort_date: { key: 'sort_date', type: 'date', label: 'date (utc)', text: 'date when created', render: v => (v ? moment.utc(v).format('MM/DD/YYYY HH:mm') : '-') },
    text: { key: 'body.text/plain', type: 'text', label: 'message', text: 'text', style: { width: '55%' } },
    type: { key: 'basetypes', type: 'type', label: 'type', text: 'type of record', style: { width: '65px' }, render: v => Text.Sentence(v.last()) },
    fpid: { key: 'fpid', type: 'text', label: 'FPID', text: 'fpid of the cve' },
    site_actor: { key: 'site_actor.names.handle', type: 'text', label: 'author', text: 'author', style: { width: '15%' }, render: v => v },
    site: { key: 'site.title', type: 'text', label: 'source', text: 'site', style: { width: '15%' }, render: v => v },
    container: { key: 'container.title', type: 'text', label: 'source', text: 'source', style: { width: '35%' }, render: v => v },
    body_text_html: { key: 'body.text/html+sanitized', type: 'text', label: 'message', text: 'text', style: { padding: '0 5px', whiteSpace: 'normal', width: '65%' }, render: v => Text.Trim(Text.StripHtml(v), 350) },
    body_text_plain: { key: 'body.text/plain', type: 'text', label: 'message', text: 'text', style: { padding: '10px', whiteSpace: 'normal', width: '65%' }, render: v => Text.Highlight(Text.Trim(Text.StripHtml(v), 350)) },
    thread_title: { key: 'thread_title', type: 'text', label: 'message', text: 'text', style: { padding: '10px', whiteSpace: 'normal' }, render: v => Text.Highlight(Text.Trim(Text.StripHtml(v), 350)) },
  },
  breach: {
    icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Vulnerability" className="material-icons" style={{ fontSize: '14px' }}>lock_open</Icon>),
    },
    searchIcon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Click to copy" className="material-icons" style={{ fontSize: '14px' }}>search</Icon>),
    },
    id: { key: 'id', type: 'text', label: 'ID', text: 'id of the breach' },
    key: { key: 'key', type: 'text', label: 'ID', text: 'id of the breach', render: v => v },
    name: { key: 'name', type: 'text', label: 'Name', text: 'name of the breach', render: v => v },
    doc_count: { key: 'doc_count', type: 'text', label: 'total', text: 'total results from search', style: { width: '105px', textAlign: 'right' }, render: v => Number(v || '').toLocaleString() },
    selected: { key: 'selected', type: 'object', label: 'selected breach', text: 'the selected  breach details', render: (v) => {
      const credsStealer = v.get('source_type') === 'Credential Stealer';
      let reportLink = '';
      if (credsStealer) {
        const malwareFpid = v.getIn(['malware', 0, 'fpid'], '');
        reportLink = malwareFpid ? `/home/wiki/malware/${malwareFpid}` : '';
      }
      return (
        <div style={{ fontSize: '12px', fontWeight: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <div
            style={{ fontSize: '14px', paddingBottom: '5px' }}
            onMouseEnter={() => ReactTooltip.rebuild()}
            data-for="global.tooltip"
            data-tip={v.get('title', '-').replace('Compromised Users from ', '')}>
            {v.get('title', '-').replace('Compromised Users from ', '')}
          </div>
          <div><strong>Source Type:</strong> {v.get('source_type', '-')}</div>
          <div><strong>Breach Date:</strong> {moment.utc(v.getIn(['created_at', 'timestamp']) * 1000).format('MMM DD, YYYY')}</div>
          {reportLink &&
          <InternalLink
            style={{ fontSize: '12px', color: '#5c6ae0', textDecoration: 'none' }}
            to={reportLink}
            name="accounts.body.source">
            Malware Family Report
          </InternalLink>}
        </div>
      );
    } },
    new: { key: 'new_records', type: 'text', label: 'New Records', text: 'total new records' },
    old: { key: 'old_records', type: 'text', label: 'Old Records', text: 'total old records' },
    total: { key: 'total_records', type: 'text', label: 'Total Records', text: 'total count of breaches' },
    unique: { key: 'unique_records', type: 'text', label: 'Total Unique', text: 'total count of unique breaches' },
    mention: { key: 'highlight', type: 'text', label: 'Indicator', text: 'name of the breach', render: v => Text.StripHighlight(v
      .filter((_, k) => k.includes('body'))
      .values()
      .next()
      .value
      .toJS()[0]) },
    credentials: { key: 'credentials', type: 'list', label: 'Credentials', text: 'total count of affected credentials' },
    query: {
      date: { key: 'query.date', type: 'since', label: 'created', text: 'date since' },
    },
  },
  cards: {
    icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Card Shops" className="material-icons" style={{ fontSize: '14px' }}>credit_card</Icon>),
    },
    id: { key: 'fpid', type: 'text', label: 'id', text: 'id of the card' },
    key: { key: 'key', type: 'text', label: 'name', text: 'name', render: v => v },
    bucket_total: { key: 'bucket_total', type: 'text', label: 'total', text: 'bucket_total' },
    total: { key: 'total', type: 'text', label: 'name', text: 'total' },
    created: { key: 'sort_date', type: 'date', label: 'created', text: 'date release occurred', render: v => (v ? moment.utc(v * 1000).format('MM/DD/YYYY HH:mm:ss') : '-') },
    base_title: { key: 'base.title', type: 'text', label: 'release', text: 'name of the release' },
    site_title: { key: 'site.title', type: 'text', label: 'shop', text: 'name of the card shop' },
    doc_count: { key: 'doc_count', type: 'text', label: 'count', text: 'number of items', style: { textAlign: 'right', width: '65px' }, render: v => (v ? Text.AbbreviateNumber(v) : '-') },
    query: {
      date: { key: 'query.date', type: 'since', label: 'created', text: 'date since' },
    },
  },
  chats: {
    fields: {
      fpid: { key: 'fpid', type: 'text', label: 'FPID', text: 'fpid of the cve' },
      key: { key: 'key', type: 'text', label: 'name', text: 'id of the chat' },
      type: { key: 'type', type: 'text', label: 'type', text: 'type', style: { width: '85px' }, render: () => 'Chat' },
      site_title: { key: '_source.container.name', type: 'text', label: 'container name', text: 'name of channel message was observed in' },
      site_type: { key: '_source.container.type', type: 'text', label: 'container type', text: 'type of channel message was observed in' },
      site_posts_count: { key: '_source.messages', type: 'number', label: 'search hits', text: 'number of hits within this channel' },
      site_actors_count: { key: '_source.actors', type: 'number', label: 'Contributors', text: 'Number of contributors in this channel' },
      site_actor_title: { key: '_source.site_actor.names.handle', type: 'text', label: 'username', text: 'username of the original poster' },
      body_text: { key: '_source.body.text/plain', type: 'text', label: 'message preview', text: 'message preview' },
      body_date: { key: '_source.sort_date', type: 'date', label: 'time ago', text: 'time since message was observed' },
      body_language: { key: '_source.body.enrichments.language', type: 'text', label: 'language', text: 'lnaguage of message' },
      body_is_media: { key: '_source.is_media', type: 'bool', label: 'media?', text: 'message contains media' },
      doc_count: { key: 'doc_count', type: 'text', label: 'count', text: 'total', style: { width: '65px', textAlign: 'right ' }, render: v => v || ''.toLocaleString() },
    },
  },
  cves: cvesDatapoints,
  exploits: cvesDatapoints,
  domain: {
    icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Vulnerability" className="material-icons" style={{ fontSize: '14px' }}>lock_open</Icon>),
    },
    searchIcon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Click to copy" className="material-icons" style={{ fontSize: '14px' }}>search</Icon>),
    },
    id: { key: 'id', type: 'text', label: 'ID', text: 'id of the breach' },
    key: { key: 'key', type: 'text', label: 'ID', text: 'id of the breach', render: v => v },
    name: { key: 'name', type: 'text', label: 'Name', text: 'name of the breach', render: v => v },
    doc_count: { key: 'doc_count', type: 'text', label: 'total', text: 'total results from search', style: { width: '105px', textAlign: 'right' }, render: v => v },
    total: { key: 'total', type: 'text', label: 'total', text: 'total results from search', style: { width: '105px', textAlign: 'right' }, render: v => v },
    query: {
      date: { key: 'query.date', type: 'since', label: 'created', text: 'date since' },
    },
  },
  iocs: {
    icon: { key: 'basetypes', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Vulnerability" className="material-icons" style={{ fontSize: '14px' }}>bug_report</Icon>),
    },
    searchIcon: { key: 'search.icon', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Click to search" className="material-icons" style={{ fontSize: '14px' }}>search</Icon>),
    },
    id: { key: 'id', type: 'text', label: 'ID', text: 'id of the ioc' },
    name: { key: 'name', type: 'text', label: 'Name', text: 'name of the ioc' },
    info: { key: 'info', type: 'text', label: 'Name', text: 'name of the event', render: v => v },
    key: { key: 'key', type: 'text', label: 'ID', text: 'id of the ioc', render: v => v },
    ioc: { key: 'ioc', type: 'text', label: 'ID', text: 'id of the ioc', render: v => v },
    event: { key: 'event', type: 'text', label: 'ID', text: 'id of the ioc', render: v => v },
    total: { key: 'doc_count', type: 'text', label: 'Total', text: 'total count of iocs' },
    mention: { key: 'highlight', type: 'text', label: 'Indicator', text: 'name of the indicator', render: v => Text.StripHighlight(v
      .filter((_, k) => k.includes('body'))
      .values()
      .next()
      .value
      .toJS()[0]) },
    type: { key: 'type', type: 'text', label: 'Type', text: 'type of the indicator', style: { width: '15%', whiteSpace: 'nowrap' } },
    value: { key: '', type: 'text', label: 'Value', text: 'value of the indicator', render: v => (v.get('type') === 'text'
      ? <pre style={{ whiteSpace: 'pre', margin: 0 }}>{JSON.stringify(JSON.parse(v.getIn(['value', v.get('type')], '{}')), null, 1).substr(0, 250)}[...]</pre>
      : v.getIn(['value', v.get('type')])) },
    related: {
      info: { key: 'Event.info', type: 'text', label: 'Name', text: 'name of the event', render: v => v },
      created: { key: 'Event.date', type: 'date', label: 'created at', text: 'date event occurred', style: { width: '25%' }, render: v => (v ? moment.utc(v).format('MM/DD/YYYY') : '-') },
    },
    indicators: {
      created: { key: 'Event.date', type: 'date', label: 'created', text: 'date event occurred', style: { width: '25%' }, render: v => (v ? moment.utc(v).format('MM/DD/YYYY') : '-') },
    },
    query: {
      date: { key: 'query.date', type: 'since', label: 'created', text: 'date since' },
    },
  },
  forums: {
    fields: {
      id: { key: 'fpid', type: 'text', label: 'id', text: 'id of the post' },
      key: { key: 'key', type: 'text', label: 'name', text: 'id of the post' },
      type: { key: 'type', type: 'text', label: 'type', text: 'type', style: { width: '85px' }, render: () => 'Forum' },
      text: { key: 'body.text/plain', type: 'text', label: 'message preview', text: 'message preview', style: { width: '50%' } },
      site_title: { key: 'site.title', type: 'text', label: 'forum', text: 'forum on which post appears' },
      site_thread: { key: 'container.container.title', type: 'text', label: 'thread', text: 'thread on which post appears' },
      site_actor: { key: 'site_actor.names.handle', type: 'text', label: 'author', text: 'author of the post' },
      doc_count: { key: 'doc_count', type: 'text', label: 'count', text: 'total', style: { width: '65px', textAlign: 'right ' }, render: v => v || ''.toLocaleString() },
      site_description: { key: 'embed.room.title', type: 'text', label: 'room', text: 'room title within forum where post appeared' },
      site_room: { key: 'embed.room.title', type: 'text', label: 'room', text: 'room title within forum where post appeared' },
      site_posts_count: { key: 'embed.thread.stats.posts', type: 'number', label: 'size', text: 'number of posts within thread' },
      enrichments_cve: { key: 'enrichments.v1.vulnerability.cve.vulnerability', type: 'text', label: 'cves', text: 'cves found within post', render: v => v.join() },
    },
  },
  marketplaces: {
    fields: {
      fpid: { key: 'fpid', type: 'text', label: 'FPID', text: 'fpid of the cve' },
      key: { key: 'key', type: 'text', label: 'name', text: '' },
      type: { key: 'type', type: 'text', label: 'type', text: 'type', style: { width: '85px' }, render: () => 'Marketplace' },
      site_title: { key: 'site.title', type: 'text', label: 'market', text: 'name of the market' },
      site_posts_count: { key: 'embed.thread.stats.posts', type: 'number', label: 'size', text: 'number of posts within thread' },
      site_actor_title: { key: 'site_actor.names.handle', type: 'text', label: 'vendor', text: 'name of the vendor' },
      body_date: { key: 'sort_date', type: 'date', label: 'date (utc)', text: 'date when item was observed' },
      body_text: { key: 'body.text/plain', type: 'text', label: 'item description', text: 'item description' },
      body_title: { key: 'title', type: 'text', label: 'item name', text: 'name of the item' },
      body_price: { key: 'prices.0.text', type: 'text', label: 'price', text: 'price of the item' },
      doc_count: { key: 'doc_count', type: 'text', label: 'count', text: 'total', style: { width: '65px', textAlign: 'right ' }, render: v => v || ''.toLocaleString() },
    },
  },
  reports: {
    icon: { key: '', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' }, render: () =>
      (<Icon data-for="icon.tooltip" data-tip="Reports" className="material-icons" style={{ fontSize: '14px' }}>lightbulb_outline</Icon>),
    },
    id: { key: 'fpid', type: 'text', label: 'ID', text: 'id of the report' },
    title: { key: 'title', type: 'text', label: 'recent reports', text: 'report title', style: { padding: '0 5px', whiteSpace: 'normal' } },
    created: { key: 'version_posted_at.timestamp', type: 'date', label: 'Posted At (UTC)', text: 'date report was posted (utc)', style: { width: '75px' }, render: v => moment.utc(v).format('MM/DD/YYYY') },
    is_featured: { key: 'is_featured', type: 'bool', label: 'featured?', text: 'featured report' },
    summary: { key: 'summary', type: 'text', label: 'report summary', text: 'report summary', style: { padding: '0 5px', whiteSpace: 'normal' }, render: v => Text.Highlight(v) },
    body_standup: { key: 'body', type: 'text', label: 'report body', text: 'report body', style: { padding: '0 5px', whiteSpace: 'normal' }, render: v => Text.Highlight(Text.Standup(v)) },
    body_text_plain: { key: 'body.text/plain', type: 'text', label: 'report body', text: 'report body', style: { padding: '0 5px', whiteSpace: 'normal' } },
    body_text_html: { key: 'body.text/html+sanitized', type: 'text', label: 'report body', text: 'report body', style: { padding: '0 5px', whiteSpace: 'normal' } },
  },
  user: {
    bookmarks: {
      icon: { key: 'path', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: (v) => {
        const value = (v.split('/')
          .slice(3)
          .shift() || '')
          .split('?')
          .shift();
        const app = Apps.find(a => a.value.includes(`search.${value}`)) || {};
        return (<Icon data-for="icon.tooltip" data-tip={Text.Sentence(app.label)} className="material-icons" style={{ fontSize: '14px' }}>{app.icon || 'help_outline'}</Icon>);
      } },
      name: { key: 'name', type: 'text', label: 'name', text: 'bookmark name' },
      folders: { key: 'folders', type: 'text', label: 'folders', text: 'the folders containing the bookmark', render: v => v.join(', '), default: 'Uncategorized' },
      source: { key: 'path', type: 'text', label: 'source', text: 'data source associated with query', render: v => Text.Sentence(v.split('/').slice(3).shift()) },
      updated: { key: 'updated', type: 'date', label: 'updated (utc)', style: { width: '75px' }, text: 'date bookmark was last edited', render: v => moment.utc(v * 1000).format('MM/DD/YYYY') },
    },
    search: {
      icon: { key: '', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: (v) => {
        const value = v.getIn(['type', 'searchType']);
        const app = Apps.find(a => a.value.includes(`search.${value}`)) || {};
        return (<Icon data-for="icon.tooltip" data-tip={Text.Sentence(app.label || value)} className="material-icons" style={{ fontSize: '14px' }}>{app.icon || 'help'}</Icon>);
      } },
      key: { key: 'key', type: 'text', label: 'id', text: 'id of the post' },
      doc_count: { key: 'doc_count', type: 'text', label: 'total', text: 'total results from search' },
      total: { key: 'total', type: 'text', label: 'total', style: { width: '115px' }, text: 'total results from search' },
      type: { key: 'type.label', type: 'text', label: 'type', text: 'type of result' },
      name: { key: 'name', type: 'text', label: 'name', text: 'label for the search', render: v => v },
      source: { key: 'type.label', type: 'text', label: 'source', text: 'data source associated with query' },
      value: { key: 'value', type: 'text', label: 'search pattern', text: 'value for the saved search', render: v => v },
      category: { key: 'cat', type: 'text', label: 'category', style: { width: '115px' }, text: 'categorization for the saved search' },
      last_updated_at: { key: 'updated', type: 'date', label: 'updated (utc)', style: { width: '75px' }, text: 'date bookmark was last edited', render: v => moment.utc(v).format('MM/DD/YYYY') },
    },
    history: {
      items: {
        icon: { key: '', type: 'icon', label: '', text: '', style: { overflow: 'visible', padding: '5px 0 0 0', width: '5px' }, render: (v) => {
          const value = !v.get('path') ? '' : v
            .get('path')
            .split('/')
            .slice(3)
            .shift()
            .split('?')
            .shift();
          const app = v.hasIn(['type', 'icon'])
            ? v.get('type').toJS()
            : Apps.find(a => a.value.includes(`search.${value}`)) || {};
          return (<Icon data-for="icon.tooltip" data-tip={Text.Sentence(app.alt || app.label || value)} className="material-icons" style={{ fontSize: '14px' }}>{app.icon || 'help'}</Icon>);
        } },
        path: { key: 'path', type: 'text', label: 'path', text: 'path of activity', render: v => `/${v.split('/').slice(2).join('/')}` },
        title: { key: '', type: 'text', label: 'title', text: 'page title', render: v => ((v.get('value') && !list.isList(v.get('value'))) ? v.get('value') : v.get('title') || '').replace(/(Flashpoint|Search|-)/g, '') || v.get('path') },
        source: { key: 'path', type: 'text', label: 'source', style: { width: '30%' }, text: 'data source associated with query', render: v => Text.Sentence(v.split('/').slice(3).shift()) },
        created: { key: 'created', type: 'date', label: 'visited (utc)', style: { width: '95px' }, text: 'date visited', render: v => moment.utc(v * 1000).fromNow() },
      },
      search: {
        icon: { key: 'type.icon', type: 'icon', label: '', text: '', style: { width: '20px', padding: 0 }, render: v => <Icon className="material-icons" style={{ fontSize: '16px' }}>{v}</Icon> },
        path: { key: 'path', type: 'text', label: 'path', style: { width: '30%' }, text: 'path of activity', render: v => Text.Sentence(v.split('/').slice(-2).shift()) },
        source: { key: 'path', type: 'text', label: 'source', style: { width: '30%' }, text: 'data source associated with query', render: v => Text.Sentence(v.split('/').slice(3).shift()) },
        created: { key: 'created', type: 'date', label: 'visited (utc)', style: { width: '95px' }, text: 'date visited', render: v => moment.utc(v * 1000).fromNow() },
      },
    },
  },
};

export default datapoints;
