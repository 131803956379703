/* eslint-disable security/detect-non-literal-regexp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import AdapterMoment from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MuiPickersUtilsProvider from '@mui/lab/LocalizationProvider';
import {
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  InputAdornment,
  Menu,
  ListItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import style from './ccmc.module.scss';
import Text from '../../utils/text';
import Api from '../../utils/api';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

const ccmcUrl = `/ui/v4/credentials${'_self' in React.createElement('div') ? '/dev' : ''}`;

const useStyles = makeStyles(theme => ({
  ccmcapi: {
    '& .MuiButton-contained': {
      margin: `${theme.spacing(1)} 0`,
      textTransform: 'inherit',
    },
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
    },
    '& .filter': {
      alignItems: 'center',
    },
    '& .MuiFab-sizeSmall': {
      height: '2.4rem',
      marginLeft: theme.spacing(2),
      minHeight: '2.4rem',
      width: '2.4rem',
      '& .MuiIcon-root': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const CCMcApi = ({
  setResponse,
  triggerScroll,
  hostAttributes,
  setHostAttributes,
}) => {
  const classes = useStyles();
  const format = 'MM/DD/YYYY HH:mm';
  const startingValues = map({ credential: '', domain: '', hash_algorithm: 'sha256', hash_response: [], page_size: 100 });
  const emptyFilter = map({ field: null, op: null, value: null });
  const [dialog, setDialog] = useState({});
  const [currentScrollId, setCurrentScrollId] = useState('');
  const [errorText, setErrorText] = useState(map({ credential: '', domain: '', filters: {}, page_size: '' }));
  const [filters, setFilters] = useState(list([emptyFilter]));
  const [hashSize, setHashSize] = useState('full');
  const [searchEnabled, setSearchEnabled] = useState(true);
  const [searchType, setSearchType] = useState(null);
  const [sha256, setSha256] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);
  const [useCase, setUseCase] = useState('search');
  const [values, setValues] = useState(startingValues);
  const [request, setRequest] = useState({});
  const [requestString, setRequestString] = useState('');
  const [onlyCookies, setOnlyCookies] = useState(true);

  const filterFields = fromJS([
    { field: 'affected_domain', type: 'string' },
    { field: 'affected_url', type: 'string' },
    { field: 'breach.context', type: 'string' },
    { field: 'breach.created_at.data-time', type: 'date' },
    { field: 'breach.first_observed_at.date-time', type: 'date' },
    { field: 'breach.fpid', type: 'string' },
    { field: 'breach.source', type: 'string' },
    { field: 'breach.title', type: 'string' },
    { field: 'fpid', type: 'string' },
    { field: 'previously_observed_at.date-time', type: 'date' },
    { field: 'password', type: 'string' },
    { field: 'username', type: 'string' },
  ]);

  const operators = fromJS([
    { op: 'is', label: 'IS', types: ['string', 'date'] },
    { op: 'or', label: 'OR', types: ['string'] },
    { op: 'not', label: 'NOT', types: ['string', 'date'] },
    { op: 'isnull', label: 'IS NULL', types: ['string', 'date'] },
    { op: 'gt', label: '>', types: ['date'] },
    { op: 'gte', label: '>=', types: ['date'] },
    { op: 'lt', label: '<', types: ['date'] },
    { op: 'lte', label: '<=', types: ['date'] },
  ]);

  const curlRequest = () => {
    const url = `${ccmcUrl}/${useCase === 'search' ? 'search' : 'analysis'}`;
    const curl = `curl -X POST '${url}?page_size=${values.get('page_size')}' -H 'Content-Type: application/json' -H "Authorization: Bearer $FPT_TOKEN" -d '${JSON.stringify(request)}'`;
    return curl;
  };

  const getResponseHeader = (v, k) => (
    <React.Fragment>
      {useCase !== 'domain' &&
      <Row middle="xs">
        <Col xs={3}>
          <span className={cx([style.label])}>Credential:</span>
        </Col>
        <Col xs={9}>
          {(values.get('credential') || '').split(',')[String(k)]}
        </Col>
      </Row>}
      <Row middle="xs">
        <Col xs={3}>
          <span className={cx([style.label])}>{useCase !== 'domain' ? 'SHA256' : 'Domain:'}</span>
        </Col>
        <Col xs={9}>
          {v.key}
        </Col>
      </Row>
    </React.Fragment>
  );
  const onAddFilter = () => {
    setFilters(filters.push(emptyFilter));
  };

  const onChange = (k, v) => {
    setValues(values.set(k, v));
    if (k === 'credential') setSha256([]);
    // handle checking if v is invalid
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      let error = '';
      if (k === 'credential' || k === 'domain') {
        // eslint-disable-next-line security/detect-unsafe-regex
        const validDomain = v.split(',').every(value => /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value.trim()));
        if (!v || v === '') {
          setErrorText(errorText.set(k, ''));
          setSha256([]);
          return;
        }
        switch (useCase) {
          case 'domain': {
            if (!validDomain) error = 'Invalid domain format';
            break;
          }
          case 'analysis':
          default: {
            const usrRegex = '([a-zA-Z0-9_-]+)';
            /* eslint-disable-next-line no-useless-escape */
            const emailRegex = "[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
            const pwdRegex = '.+';
            // eslint-disable-next-line security/detect-non-literal-regexp
            const singleUsernameEmailRegex = new RegExp(`^((${usrRegex}|${emailRegex}))$`, 'ig');
            // eslint-disable-next-line security/detect-non-literal-regexp
            const usernameEmailRegex = new RegExp(`^((${usrRegex}|${emailRegex})(?:,(${usrRegex}|${emailRegex}))*)$`, 'ig');
            const singleUsernameEmailPasswordRegex = new RegExp(`^((${usrRegex}|${emailRegex}):${pwdRegex})$`, 'ig');
            if (!validDomain && k === 'domain') error = 'Invalid domain format';
            let validCredential = true;
            if (useCase === 'search') {
              validCredential = (searchType !== 'username-password')
                ? singleUsernameEmailRegex.test(v)
                : singleUsernameEmailPasswordRegex.test(v);
            } else {
              validCredential = usernameEmailRegex.test(v);
            }
            if (!validCredential && k !== 'domain') {
              error = (searchType !== 'username-password')
                ? `${useCase === 'search' ? 'Invalid credential format' : 'Invalid credential csv format'}`
                : 'Invalid format: credential:password';
            }
            break;
          }
        }
        if (!error && k !== 'domain') {
          const promises = v.split(',').map(cred => Text.SHA256(cred));
          Promise.all(promises).then(hashes => setSha256(hashes));
        }
      } else if (k === 'page_size') {
        if (!v || v < 1 || v > 100) {
          error = 'Invalid. Must be between 1 and 100';
        }
      }
      setErrorText(errorText.set(k, error));
    }, 500);
    setTypingTimer(timer);
  };

  const onChangeFilter = (index, type, value) => {
    const current = filters.get(index);
    let updated = current.set(type, value);
    if (['field', 'op'].includes(type)) {
      updated = updated.set('value', null);
    }
    if (updated.get('field') && updated.get('op') && !updated.getIn(['op', 'types']).includes(updated.getIn(['field', 'type']))) {
      updated = updated.set('op', null);
    }
    setFilters(filters.set(index, updated));
  };

  const onCheck = (type, key, value) => {
    const current = [...values.get(type)];
    if (value) current.push(key);
    else current.splice(current.indexOf(key), 1);
    setValues(values.set(type, current));
  };

  const onDeleteFilter = (index) => {
    const updated = filters.delete(index);
    setFilters(updated);
  };

  const onSearch = (scrollId) => {
    setSearchEnabled(false);
    const url = `${ccmcUrl}/${useCase === 'search' ? 'search' : 'analysis'}${scrollId ? '/scroll' : ''}?page_size=${values.get('page_size')}&scroll=true`;
    const toSend = !scrollId ? request : { scroll_id: scrollId };
    Api.post(url, toSend, [200, 400, 500, 501, 502, 503, 504])
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        const headers = res.results.map((v, k) => getResponseHeader(v, k));
        setCurrentScrollId(res.scroll_id || '');
        setResponse(fromJS(res), useCase, headers, values.get('page_size'));
      })
      .then(() => setSearchEnabled(true))
      .catch((err) => {
        if (err.message !== 'canceled') {
          setCurrentScrollId('');
          setResponse(map(), useCase);
          SearchActions.set(['search', 'info', 'message'], err.message);
        }
        setSearchEnabled(true);
        return err;
      });
  };

  const parseFilters = () => {
    const filterErrors = {};
    const filtersRequest = {};
    if (hostAttributes && useCase === 'analysis') filtersRequest.affected_domain = values.get('domain') ? values.get('domain').split(',') : [];
    if (onlyCookies && hostAttributes && ['domain', 'analysis'].includes(useCase)) filtersRequest.cookies = { isnull: 'false' };
    filters.forEach((v, k) => {
      if (v.get('field') && v.get('op') && v.get('value') != null) {
        // has pieces to be a valid filter
        switch (v.getIn(['op', 'op'])) {
          case 'not': {
            const ored = v.get('value').split(',');
            filtersRequest[v.getIn(['field', 'field'])] = (ored.length > 1)
              ? { not: ored }
              : { not: ored[0] };
            break;
          }
          case 'isnull':
            filtersRequest[v.getIn(['field', 'field'])] = { isnull: v.get('value') === 'true' };
            break;
          case 'gt':
          case 'gte':
          case 'lt':
          case 'lte':
            filtersRequest[v.getIn(['field', 'field'])] = { [v.getIn(['op', 'op'])]: moment.utc(v.get('value')).format() };
            break;
          default: {
            const ored = v.get('value').split(',');
            filtersRequest[v.getIn(['field', 'field'])] = (ored.length > 1)
              ? ored
              : ored[0];
            break;
          }
        }
      } else if ((v.get('field') || v.get('op') || v.get('value') != null) && (v.get('field') == null || v.get('op') == null || v.get('value') == null || v.get('value') === '')) {
        // at least one filter portion is not set
        filterErrors[String(k)] = 'Invalid filter';
      }
      // empty filter so ignore
    });
    // setErrorText(errorText.set('filters', map(filterErrors)));
    return Object.keys(filtersRequest).length > 0 ? filtersRequest : null;
  };

  useEffect(() => {
    setErrorText(errorText.set('credential', '').set('page_size', ''));
    let type = null;
    if (useCase === 'analysis') type = 'username';
    if (useCase === 'domain') type = 'affected_domain';
    setSearchType(type);
    setValues(startingValues);
    setSha256([]);
    setCurrentScrollId('');
    setResponse(map(), useCase);
  }, [useCase]);

  useEffect(() => {
    const requestFilters = parseFilters();
    if (useCase === 'domain') {
      const tmp = map()
        .set('keys', values.get('domain') ? values.get('domain').split(',').map(v => v.trim()) : [])
        .set('type', searchType)
        .set('show_host_attributes', hostAttributes)
        .set('hash_response', values.get('hash_response').length > 0 ? values.get('hash_response') : null)
        .set('hash_algorithm', values.get('hash_response').length > 0 ? values.get('hash_algorithm') : null)
        .set('filters', requestFilters)
        .filter(v => v)
        .toJS();
      setRequest(tmp);
      setRequestString(JSON.stringify(tmp, null, 2));
    } else if (sha256.length === 0) {
      setRequest({});
      setRequestString('');
    } else {
      const tmp = map()
        .set('keys', (hashSize === 'full') ? sha256 : sha256.map(v => v.slice(0, 8)))
        .set('type', searchType)
        .set('show_host_attributes', hostAttributes)
        .set('hash_response', values.get('hash_response').length > 0 ? values.get('hash_response') : null)
        .set('hash_algorithm', values.get('hash_response').length > 0 ? values.get('hash_algorithm') : null)
        .set('filters', requestFilters)
        .filter(v => v)
        .toJS();
      setRequest(tmp);
      setRequestString(JSON.stringify(tmp, null, 2));
    }
    setCurrentScrollId('');
    setResponse(map(), useCase);
  }, [useCase, values, sha256, hashSize, filters, hostAttributes, onlyCookies]);

  useEffect(() => {
    if (triggerScroll) {
      onSearch(currentScrollId);
    }
  }, [triggerScroll]);

  return (
    <div className={classes.ccmcapi}>
      <Row className={cx([useCase !== 'search' && style.row])} middle="xs">
        <Col xs={3}>
          <span className={cx([style.label])}>Use Case:</span>
        </Col>
        <Col xs={9}>
          <Button
            color="primary"
            variant="contained"
            onClick={event => setDialog({ key: 'use.case', target: event.target })}
            endIcon={<Icon>keyboard_arrow_down</Icon>}>
            {useCase || 'Select...'}
          </Button>
          <Menu
            value={useCase}
            onClick={() => setDialog()}
            anchorEl={get(dialog, 'target', '')}
            open={Boolean(dialog && dialog.key === 'use.case')}>
            <ListItem
              value="search"
              className={cx([style.item, useCase === 'search' && style.active])}
              onClick={() => setUseCase('search')}>
              Search
            </ListItem>
            <ListItem
              value="analysis"
              className={cx([style.item, useCase === 'analysis' && style.active])}
              onClick={() => setUseCase('analysis')}>
              Analysis
            </ListItem>
            <ListItem
              value="domain"
              className={cx([style.item, useCase === 'domain' && style.active])}
              onClick={() => setUseCase('domain')}>
              Web Domain
            </ListItem>
          </Menu>
        </Col>
      </Row>
      {useCase === 'search' &&
      <Row className={style.row} middle="xs">
        <Col xs={3} />
        <Col xs={9}>
          <span className={style.case}>
            This use case is designed for performance.<br />
            We suggest using one credential per request.
          </span>
        </Col>
      </Row>}
      <Row className={style.row}>
        <Col xs={12}>
          <strong>I want to look up data with:</strong>
          {useCase === 'search' &&
          <FormControl>
            <RadioGroup
              row
              name="type"
              className={style.radio}
              value={searchType}
              onChange={(event) => {
                onChange('credential', '');
                setSearchType(event.target.value);
              }}>
              <FormControlLabel
                label="Username"
                placeholder="username"
                value="username"
                control={<Radio />} />
              <FormControlLabel
                label="Username:Password"
                placeholder="username-password"
                value="username-password"
                control={<Radio />} />
            </RadioGroup>
          </FormControl>}
          {useCase === 'analysis' &&
          <div>
            Usernames
          </div>}
          {useCase === 'domain' &&
          <div>
            My company's web domain (sub domains included)
          </div>}
        </Col>
      </Row>
      {((useCase === 'search' && searchType) || useCase !== 'search') &&
      <Row>
        <Col xs={12}>
          {['search', 'analysis'].includes(useCase) &&
          <Row middle="xs" className={style.row}>
            <Col xs={3}>
              <span className={cx([style.label])}>Credential:</span>
            </Col>
            <Col xs={9} className={style.text}>
              <FormControl variant="outlined">
                <OutlinedInput
                  name="credential"
                  data-lpignore="true"
                  error={Boolean(errorText.get('credential'))}
                  value={values.get('credential', '')}
                  placeholder={searchType !== 'username-password'
                    ? `${useCase === 'search' ? 'hello@user.com' : 'hello@user.com,username2,username3'}`
                    : 'hello@user.com:password'}
                  onChange={event => onChange('credential', event.target.value)}
                  endAdornment={values.get('credential') && (
                    <InputAdornment position="end">
                      <Icon
                        onClick={() => onChange('credential', '')}>
                        close
                      </Icon>
                    </InputAdornment>
                  )} />
                <FormHelperText>
                  {errorText.get('credential')}
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>}
          {useCase === 'domain' &&
          <Row middle="xs" className={style.row}>
            <Col xs={3}>
              <span className={cx([style.label])}>Domain:</span>
            </Col>
            <Col xs={9} className={style.text}>
              <FormControl variant="outlined">
                <OutlinedInput
                  name="domain"
                  data-lpignore="true"
                  error={Boolean(errorText.get('domain'))}
                  value={values.get('domain', '')}
                  placeholder="domain1,domain2"
                  onChange={event => onChange('domain', event.target.value)}
                  endAdornment={values.get('domain') && (
                    <InputAdornment position="end">
                      <Icon
                        onClick={() => onChange('domain', '')}>
                        close
                      </Icon>
                    </InputAdornment>
                  )} />
                <FormHelperText>
                  {errorText.get('domain')}
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>}
          {['search', 'analysis'].includes(useCase) &&
          <Row middle="xs" className={style.row}>
            <Col xs={3} />
            <Col xs={9}>
              <FormControl>
                <RadioGroup
                  row
                  name="hash"
                  value={hashSize || 'full'}
                  onChange={event => setHashSize(event.target.value)}>
                  <FormControlLabel
                    label="Full Hash"
                    value="full"
                    control={<Radio />} />
                  <FormControlLabel
                    label="Partial Hash"
                    value="partial"
                    control={<Radio />} />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>}
          {['search', 'analysis'].includes(useCase) &&
          <Row middle="xs" className={style.row}>
            <Col xs={3}>
              <span className={cx([style.label])}>Credential SHA256 Hashes:</span>
            </Col>
            <Col xs={9} className={style.text}>
              <FormControl variant="outlined">
                <OutlinedInput
                  disabled
                  name="sha256"
                  data-lpignore="true"
                  value={values.get('credential')
                    ? `${(hashSize === 'full') ? sha256.join() : sha256.map(v => v.slice(0, 8)).join()}`
                    : ''}
                  endAdornment={sha256 && sha256.length > 0 && (
                    <InputAdornment position="end">
                      <Icon
                        onClick={() => {
                          navigator.clipboard.writeText((hashSize === 'full') ? sha256.join() : sha256.map(v => v.slice(0, 8)).join());
                          SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
                        }}>
                        assignment
                      </Icon>
                    </InputAdornment>
                  )} />
              </FormControl>
            </Col>
          </Row>}
          <Row middle="xs" className={style.row}>
            <Col xs={3}>
              <span className={cx([style.label])}>Results Per Credential:</span>
            </Col>
            <Col xs={9} className={style.text}>
              <FormControl variant="outlined">
                <OutlinedInput
                  name="result-count"
                  data-lpignore="true"
                  value={values.get('page_size')}
                  error={Boolean(errorText.get('page_size'))}
                  type="number"
                  min="1"
                  max="100"
                  onChange={event => onChange('page_size', parseInt(event.target.value, 10))} />
                <FormHelperText>
                  {errorText.get('page_size')}
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>
          <Row middle="xs" className={cx([style.row, style.horizontal])}>
            <Col xs={3}>
              <span className={cx([style.label])}>Hashed Response:</span>
            </Col>
            <Col xs={9} className={style.horizontal}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    label="Username"
                    control={<Checkbox
                      checked={(values.get('hash_response') || []).includes('username')}
                      onChange={event => onCheck('hash_response', 'username', event.target.checked)} />} />
                  <FormControlLabel
                    label="Password"
                    control={<Checkbox
                      checked={(values.get('hash_response') || []).includes('password')}
                      onChange={event => onCheck('hash_response', 'password', event.target.checked)} />} />
                </FormGroup>
              </FormControl>
            </Col>
          </Row>
          {values.get('hash_response') && values.get('hash_response').length > 0 &&
          <Row middle="xs" className={cx([style.row])}>
            <Col xs={3}>
              <span className={cx([style.label])}>Hashed Response Algorithm:</span>
            </Col>
            <Col xs={9}>
              <FormControl>
                <RadioGroup
                  row
                  name="hash-algorithm"
                  value={values.get('hash_algorithm', 'sha256')}
                  onChange={event => onChange('hash_algorithm', event.target.value)}>
                  <FormControlLabel
                    label="SHA-1"
                    value="sha1"
                    control={<Radio />} />
                  <FormControlLabel
                    label="SHA-256"
                    value="sha256"
                    control={<Radio />} />
                  <FormControlLabel
                    label="MD-5"
                    value="md5"
                    control={<Radio />} />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>}
        </Col>
      </Row>}
      {['domain', 'analysis'].includes(useCase) &&
      <Row middle="xs" className={style.row}>
        <Col xs={3}>
          <span className={cx([style.label])}>Include Cookies:</span>
        </Col>
        <Col xs={9}>
          <FormControl>
            <RadioGroup
              row
              name="cookies"
              value={hostAttributes}
              onChange={() => setHostAttributes(!hostAttributes)}>
              <FormControlLabel
                label="Yes"
                value="true"
                control={<Radio />} />
              <FormControlLabel
                label="No"
                value="false"
                control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>
      }
      {['domain', 'analysis'].includes(useCase) && hostAttributes &&
      <Row middle="xs" className={style.row}>
        <Col xs={3}>
          <span className={cx([style.label])}>Show Only Results with Cookies:</span>
        </Col>
        <Col xs={9}>
          <FormControl>
            <RadioGroup
              row
              name="onlyCookies"
              value={onlyCookies}
              onChange={() => setOnlyCookies(!onlyCookies)}>
              <FormControlLabel
                label="Yes"
                value="true"
                control={<Radio />} />
              <FormControlLabel
                label="No"
                value="false"
                control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>
      }
      {useCase === 'analysis' && hostAttributes &&
      <Row middle="xs" className={style.row}>
        <Col xs={3}>
          <span className={cx([style.label])}>Affected Domain: (required)</span>
        </Col>
        <Col xs={9} className={style.text}>
          <FormControl variant="outlined">
            <OutlinedInput
              name="domain"
              data-lpignore="true"
              error={Boolean(errorText.get('domain'))}
              value={values.get('domain', '')}
              placeholder="domain1,domain2"
              onChange={event => onChange('domain', event.target.value)}
              endAdornment={values.get('domain') && (
              <InputAdornment position="end">
                <Icon
                  onClick={() => onChange('domain', '')}>
                  close
                </Icon>
              </InputAdornment>
                  )} />
            <FormHelperText>
              {errorText.get('domain')}
            </FormHelperText>
          </FormControl>
        </Col>
      </Row>}
      {useCase !== 'search' &&
      <Row className={cx([style.row, style.filters, classes.ccmcapi])}>
        <Col xs={3}>
          <span className={cx([style.label])}>Filters:</span>
        </Col>
        <Col xs={9}>
          {filters.map((v, k) => (
            <Row key={v.get('field')} className={cx(['filter', style.filter])}>
              <Col>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => setDialog({ key: 'filters.field', target: event.target })}
                  endIcon={<Icon>keyboard_arrow_down</Icon>}>
                  {filterFields
                    .find(f => v.get('field') && f.get('field') === v.getIn(['field', 'field']), null, map())
                    .get('field', 'Field')}
                </Button>
                <Menu
                  value={filterFields.find(f => v.get('field') && f.get('field') === v.getIn(['field', 'field']))}
                  onClick={() => setDialog()}
                  anchorEl={get(dialog, 'target', '')}
                  open={Boolean(dialog && dialog.key === 'filters.field')}>
                  <ListItem disabled>Field</ListItem>
                  {filterFields
                    .map(f => (
                      <ListItem
                        value={f}
                        key={f.get('field')}
                        onClick={() => onChangeFilter(k, 'field', f)}
                        className={cx([style.item, v.get('field') && f.get('field') === v.getIn(['field', 'field']) && style.active])}>
                        {f.get('field')}
                      </ListItem>))}
                </Menu>
              </Col>
              <Col>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => setDialog({ key: 'filters.operator', target: event.target })}
                  endIcon={<Icon>keyboard_arrow_down</Icon>}>
                  {operators
                    .find(o => v.get('op') && o.get('op') === v.getIn(['op', 'op']), null, map())
                    .get('op', 'Operator')}
                </Button>
                <Menu
                  value={operators.find(o => v.get('op') && o.get('op') === v.getIn(['op', 'op']))}
                  onClick={() => setDialog()}
                  anchorEl={get(dialog, 'target', '')}
                  open={Boolean(dialog && dialog.key === 'filters.operator')}>
                  <ListItem disabled>Operator</ListItem>
                  {operators
                    .filter(o => !v.get('field') || o.get('types').includes(v.getIn(['field', 'type'])))
                    .map(o => (
                      <ListItem
                        key={o.get('op')}
                        value={o}
                        className={cx([style.item, v.get('op') && o.get('op') === v.getIn(['op', 'op']) && style.active])}
                        onClick={() => onChangeFilter(k, 'op', o)}>
                        {o.get('label')}
                      </ListItem>))}
                </Menu>
              </Col>
              {v.get('field') && v.get('op') &&
              <Col className={style.text}>
                {v.getIn(['field', 'type']) === 'string' && v.getIn(['op', 'op']) !== 'isnull' &&
                <FormControl variant="outlined">
                  <OutlinedInput
                    name={`field.value.${k}`}
                    data-lpignore="true"
                    value={v.get('value', '') || ''}
                    placeholder={v.getIn(['op', 'op']) === 'is' ? 'value' : 'value1,value2,value3'}
                    onChange={event => onChangeFilter(k, 'value', event.target.value)} />
                </FormControl>}
                {v.getIn(['field', 'type']) === 'date' && v.getIn(['op', 'op']) !== 'isnull' &&
                <MuiPickersUtilsProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    renderInput={myParams => <TextField {...myParams} />}
                    autoOk
                    disableFuture
                    format={format}
                    variant="inline"
                    inputVariant="outlined"
                    animateYearScrolling={false}
                    label=""
                    value={v.get('value')
                      ? moment.utc(v.get('value'))
                      : ''}
                    onChange={date => onChangeFilter(k, 'value', date.format(format))} />
                </MuiPickersUtilsProvider>}
                {v.getIn(['op', 'op']) === 'isnull' &&
                <FormControl>
                  <RadioGroup
                    row
                    name={`isnull.${k}`}
                    className={cx([style.horizontal])}
                    value={v.get('value')}
                    onChange={event => onChangeFilter(k, 'value', event.target.value)}>
                    <FormControlLabel
                      label="True"
                      value="true"
                      control={<Radio />} />
                    <FormControlLabel
                      label="False"
                      value="false"
                      control={<Radio />} />
                  </RadioGroup>
                </FormControl>}
              </Col>}
              {filters.size - 1 > k &&
              <Col>
                <Fab
                  size="small"
                  color="primary"
                  className={cx([style.delete])}
                  onClick={() => onDeleteFilter(k)}>
                  <Icon>
                    close
                  </Icon>
                </Fab>
              </Col>}
              {filters.size - 1 === k && v.get('field', '') && v.get('op') && v.get('value') &&
              <Col>
                <Fab
                  size="small"
                  color="secondary"
                  className={cx([style.add])}
                  onClick={() => onAddFilter()}>
                  <Icon className={cx(['material-icons'])} style={{ fontSize: '16px' }}>
                    add
                  </Icon>
                </Fab>
              </Col>}
              {false && errorText.getIn(['filters', k.toString()]) &&
              <div>{errorText.getIn(['filters', k.toString()])}</div>}
            </Row>
          ))}
        </Col>
      </Row>}
      {request.keys && request.keys.length > 0 &&
      <div className={cx([style['button-container']])}>
        <Button
          color="secondary"
          variant="contained"
          name="search"
          className={cx([style.mont, 'mont', style.button])}
          disabled={!searchEnabled || errorText.some((v, k) => {
            if (k === 'filters') return v.size > 0;
            return v;
          })}
          onClick={() => onSearch()}>
          Perform Request
        </Button>
      </div>}
      {request.keys && request.keys.length > 0 &&
      <Row className={style.request}>
        <Col xs={12}>
          <Row middle="xs" className={cx([style.row])}>
            <Col xs={3}>
              <span className={cx([style.label])}>Request Body:</span>
            </Col>
            <Col xs={9}>
              <pre>
                {requestString}
              </pre>
            </Col>
          </Row>
          <Row middle="xs" className={cx([style.row])}>
            <Col xs={3}>
              <span className={cx([style.label])}>CURL Request:</span>
            </Col>
            <Col xs={9} className={style.curl}>
              {curlRequest()}
            </Col>
          </Row>
        </Col>
      </Row>}
    </div>
  );
};

CCMcApi.propTypes = {
  triggerScroll: PropTypes.bool,
  setResponse: PropTypes.func,
  hostAttributes: PropTypes.bool,
  setHostAttributes: PropTypes.func,
};

CCMcApi.defaultProps = {
  triggerScroll: false,
  setResponse: null,
  hostAttributes: true,
  setHostAttributes: null,
};

export default CCMcApi;
