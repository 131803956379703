export default {
  /* Static */
  AdBlock: 'Please disable ad-block for flashpoint domains',
  AggregationsError: 'Unable to load charts... please try again later',
  AlertsEmpty: 'Sorry, you don\'t have any alerts',
  AlertsError: 'Sorry, something went wrong... please try again',
  AlertsImageDuplicates: 'Warning: Enabling the Image data source may lead to similar and duplicate notifications. Images does not deduplicate alerting content by text or hash.',
  BookmarkDeleted: 'Bookmark(s) Deleted',
  BookmarkUpdated: 'Bookmark(s) Updated',
  ChannelsUpdated: 'Channels Updated',
  CloudBucketFetchError: 'Error: Unable to load Buckets or Accounts',
  CredentialsRevoked: 'Credential Revoked',
  DateSinceError: 'Since date needs to occur before until date',
  DateToFromError: 'Both from and to dates are required',
  DomainMessageFetchError: 'Unable to fetch messages.',
  DomainTakedownFetchError: 'Unable to fetch takedown limits.',
  Exporting: 'Exporting',
  ExportTimeout: 'Export has timed out. Please try again',
  ExportError: 'Export has failed. Please try again',
  FileBeingProcessed: 'File Being Processed',
  FraudwatchApiError: 'There was an error. Please reach out to your Customer Success representative to complete set up.',
  KeywordsDeleted: 'Keyword(s) Deleted',
  KeywordsUpdated: 'Keyword(s) Updated',
  IncidentFetchError: 'Unable to fetch incidents',
  IncidentBrandError: 'Unable to fetch brands',
  IncidentCreation: 'New incident created',
  IncidentUrlError: 'Unable to fetch URLs',
  InvalidPageNumber: 'Invalid page number',
  InvalidUserProfile: 'Invalid user profile. Please reload',
  IpFetchError: 'Error: Unable to load IP Addresses',
  ListOptionError: 'At least one option must be selected',
  MfaTimeout: 'MFA push timed out',
  MutationProgress: 'Saving changes...',
  MutationError: 'Sorry, something went wrong... please try to saving again or try again later',
  MutationSuccess: 'Changes Saved!',
  PasswordComplexity: 'Troubleshooting: Password does not match complexity requirements',
  PasswordMissing: 'Troubleshooting: All fields are required',
  PasswordMismatch: 'Troubleshooting: Passwords do not match',
  PasswordSuccess: 'Your password has been successfully changed.',
  PdfError: 'PDF not found. Generating inline document...',
  PreferencesSaved: 'Preferences Saved',
  PreferencesReset: 'User Preferences Reset',
  ProfileAdded: 'Profile Added',
  ProfileSaved: 'Profile Saved',
  QuickFilterError: 'Quick filters limited to five (5) items. Please remove another item',
  RfiPost: 'Sending RFI and uploading file(s)...',
  RfiPostError: 'Unable to post RFI',
  RfiPostSuccess: 'We\'ve received your request! Our analyst team will respond shortly. If this request is urgent and requires immediate assistance, please contact your Customer Success representative directly.',
  ReportAnchorError: 'Anchor not found in report or may have been removed',
  ReportDeletion: 'Deletion Succesful, it may take a minute for the report to go away as the elasticsearch index updates.',
  ReportDeletionError: 'Error deleting report. Please try again in a few moments',
  RecipientsDeleted: 'Recipients Deleted',
  SearchAnalytics: 'You can choose to see search analytics automatically by updating your settings',
  SearchOrgPattern: 'For best results, Flashpoint recommends appending your organization search patterns to this query',
  SearchError: 'Sorry, something went wrong... please simplify your search or try again later',
  SearchIOCEventError: 'Sorry, there are no events that meet your search criteria',
  SearchEmpty: 'Sorry, your search didn\'t yield any results',
  SearchRangeEmpty: 'No results in this time range',
  SearchQueryLength: 'Due to query length, highlighting may affect performance.',
  SearchTimeout: 'Your search query timed out',
  SessionExpired: 'Session expired',
  SettingsSaveFailed: 'Unable to save settings. Try again later',
  SettingUpdateAction: 'Update Setting',
  SettingsSafeSearchToggle: 'Toggle inline media visibility for community content',
  SettingsSafeSearchOcr: 'Toggle safe search threshold for OCR media content',
  SettingsUpdated: 'Settings Updated',
  TagsUpdated: 'Tags Updated',
  TopicDateLimit: 'Date range must exist and cannot exceed 30 days',
  TakedownError: 'Unable to process takedown request',
  TakedownSuccess: 'Request received. This post will be taken down within 24 hours.',
  TestEventSent: 'Test Event Sent',
  TokenRevoked: 'Token Revoked',
  TranslationLimit: 'Translations limited to posts shorter than 5000 characters',
  TwitterKeywordError: 'Unable to load Twitter keywords',
  TwitterEula: '<p>By clicking here, and before submitting a Twitter keyword to the Flashpoint Services, You hereby acknowledge and agree that you may only do so in connection with one of the following approved use cases:</p><ul><li>Cyber Security (Data Recon, Data Theft and Leakage, Exploit Distribution and Disclosure, and Ransomware Events)</li><li>Corporate Security and Physical Threats (Threats against People, Places, and Assets)</li><li>Brand Disclosure (Brand Hijacking, Trademark or IP Misuse, Counterfeiting)</li></ul><p>Your use of this Service must be in compliance at all times with Twitter’s applicable terms and conditions located here <a herf=\"https://twitter.com/en/tos\" target=\"_blank\">https://twitter.com/en/tos</a>. Without limiting the generality of the foregoing, for the specific use cases Physical Security and Brand Monitoring (i.e., bullets #2 and #3 above), the keyword that you submit must have Your Company\'s person, place, or asset contained in the keyword itself in order for it to be \"Twitter approved.\" In addition, You may not use this Service in violation of applicable law or regulation, and Flashpoint may terminate your access at any time.  You agree to indemnify, defend and hold Flashpoint harmless for any and all damages and liabilities arising in connection with any use of the Service in violation of the foregoing, including a violation of the Twitter terms and conditions and/or applicable law.</p>',
  UploadSizeWarning: 'This is a large file and may take a long time to upload. Please leave this page open until notified the file has been uploaded',
  UserPrefsError: 'Unable to contact user profile service',
  WebhookFailure: 'Unable to test webhook. Try again later',
  KeywordAggregationsNote: 'Note: The histogram may not match actual alert and search results on images and videos due to how media are deduplicated and associated with individual messages in those results.',

  /* Dynamic */
  CopiedClipboard: (id = '') => `Copied to clipboard: ${id}`,
  HashAdditionError: (error, total) => `Unable to add hashes ${error}. Added ${total} hashes`,
  HashDeletionError: (error, total) => `Unable to delete hashes ${error}. Deleted ${total} hashes`,
  TroubleshootingId: (detail = 'Error', id = '') => `${detail} Troubleshooting ID: ${id}`,
  SearchFailedRequests: (failures = '') => `Warning: Search Results Incomplete. ${failures} are not included. Please simplify your query`,
  UnexpectedError: (data = '', detail = '') => `An unexpected error occurred ${data} ${detail}`,
};
