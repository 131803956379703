import Api from '../../../../utils/api';

const ALERTING_URI = '/ui/v4/alerts/keywords';

const saveIpAddress = (ownerId, keyclassId, name, value) => (
  Api.post(ALERTING_URI, {
    active: true,
    is_curated: false,
    keyclass_id: keyclassId,
    name,
    value,
    owner_id: ownerId,
  },
  [200, 201, 400]));

const loadAllIpAddresses = (ownerId, keyclassId) => Api.get(`${ALERTING_URI}?owner_id=${ownerId}&keyclass_id=${keyclassId}`)
  .then(res => res.data);

const deleteIpAddress = (ownerId, keywordId) =>
  Api.delete(
    `${ALERTING_URI}?owner_id=${ownerId}&keyword_id=${keywordId}`,
    {},
    [200, 204, 400],
  );

export { saveIpAddress, loadAllIpAddresses, deleteIpAddress };
