/* eslint-disable security/detect-object-injection */
import _ from 'lodash';

/**
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451?permalink_comment_id=3415430#gistcomment-3415430
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
export const deepDiff = (fromObj, toObj) => {
  const changes = {};

  const buildPath = (path, obj, key) =>
      (_.isUndefined(path) ? key : `${path}.${key}`);

  const walk = (fromObject, toObject, path) => {
      for (const key of _.keys(fromObject)) {
          const currentPath = buildPath(path, fromObject, key);
          if (!_.has(toObject, key)) {
              changes[currentPath] = { from: _.get(fromObject, key), to: undefined };
          }
      }

      for (const [key, to] of _.entries(toObject)) {
          const currentPath = buildPath(path, toObject, key);
          if (!_.has(fromObject, key)) {
              changes[currentPath] = { from: undefined, to };
          } else {
              const from = _.get(fromObject, key);
              if (!_.isEqual(from, to)) {
                  if (_.isObjectLike(to) && _.isObjectLike(from)) {
                      walk(from, to, currentPath);
                  } else {
                      changes[currentPath] = { from, to };
                  }
              }
          }
      }
  };

  walk(fromObj, toObj);

  return changes;
};
