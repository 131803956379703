import { useState, useCallback } from 'react';
import qs from 'qs';

import History from '../utils/history';

const getQueryStringValue = (key) => {
  const { query } = History.getCurrentLocation();
  const queryObj = qs.parse(query);

  return queryObj[String(key)];
};

const setQueryStringValue = (key, value) => {
  const { pathname, query } = History.getCurrentLocation();

  if (!value) {
    delete query[String(key)];
    History.push({
      pathname,
      query,
    });
  } else {
    History.push({
      pathname,
      query: {
        ...query,
        [key]: value,
      },
    });
  }
};

function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key],
  );

  return [value, onSetValue];
}

export default useQueryString;
