import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone-uploader';
import { fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Icon,
  TextField,
} from '@mui/material';

import 'react-dropzone-uploader/dist/styles.css';
import { useOktaAuth } from '@okta/okta-react';
import style from './brand.module.scss';
import Text from '../../../../utils/text';
import TextFilter from '../../../filters/TextFilter';

const Brand = ({
  assets,
  brand,
  limits,
  save,
  update,
  cancel,
}) => {
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [opts, setOpts] = useState([]);
  const [brandCountList, setBrandCountList] = useState([]);
  const [showNameField, setShowNameField] = useState(true);
  const [userFullName, setUserFullName] = useState();
  const [orgName, setOrgName] = useState('Unknown Organization');
  const [loaChecked, setLoaChecked] = useState(false);
  const dimensions = [300, 300];
  const maxsize = 10000000;

  const { OktaAuth } = useOktaAuth();

  const attachments = {
    logo: `Upload brand images. Required filetype: image/png. Dimenions: ${dimensions.join('x')}`,
    attachment: `Provide any trademark data you have for brands to be protected. Max filesize: ${Text.Filesize(maxsize)}`,
  };

  const getLoaText = org => `${org} hereby authorizes Flashpoint to act on its behalf in all matters relating to the takedown or disabling of fake, fraudulent, and otherwise unauthorized websites.<br/><br/>Any and all reasonable acts relating to the above mentioned requirements carried out by Flashpoint on our behalf shall have the same effect as acts of our own.<br/><br/>SIGNED ON BEHALF OF ${org.toUpperCase()}, DULY AUTHORIZED.`;

  const { state, attachedFiles } = brand;
  const existingAssets = {
    logo: attachedFiles?.filter(item => item.type === 'logo'),
    attachment: attachedFiles?.filter(item => item.type === 'attachment'),
    letter: attachedFiles?.filter(item => item.type === 'letter'),
  };

  const onValidate = (file, type) => {
    setError();
    const { height, name, width, size } = file.meta;


    let err;

    switch (type) {
      case 'logo':
        err = width > dimensions[0] || height > dimensions[1]
          ? `${name}: file exceeds dimensions ${dimensions.join('x')}`
          : '';
        break;
      default:
        err = size > maxsize
          ? `${name}: file exceeds size ${maxsize} bytes`
          : '';
        break;
    }

    if (err) {
      setError(err);
      file.remove();
    }

    return false;
  };

  const validateFields = (_brand, _assets) => {
    if (!_brand?.type || _brand?.type === '') {
      setError('Please choose a type.');
      return false;
    }
    if (!opts.find(v => (v.value === _brand?.type))) {
      setError('Please choose a valid type.');
      return false;
    }
    if (!_brand?.name || _brand?.name === '') {
      setError('Please enter a valid brand name.');
      return false;
    }
    if (!_brand?.allowed_domains || _brand?.allowed_domains === '') {
      setError('Please enter a valid domain.');
      return false;
    }
    if ((!_brand?.trademark_links || _brand?.trademark_links === '') && _brand?.type !== 'executive social media') {
      setError('Please enter a valid trademark link.');
      return false;
    }
    let logo = false;
    _assets.forEach((item) => {
      if (item.type === 'logo') logo = true;
    });
    if (!logo && !existingAssets?.logo?.length > 0 && _brand?.type !== 'executive social media') {
      setError('Please add a valid brand logo.');
      return false;
    }
    const n = userFullName?.trim();
    if ((!n || n.length < 3) && (state !== 'edit')) {
      setError('Please enter your full name');
      return false;
    }
    if (!loaChecked) {
      setError('Please check the Flashpoint Authorization checkbox.');
      return false;
    }
    setError('');
    return true;
  };

  const onApply = () => {
    if (data?.brand) {
      data.brand.authorization_text =
        `${getLoaText(orgName)}<br/><br/>${userFullName}, ${orgName}`
          .replaceAll('<br/>', '\n');
    }
    if (validateFields(data?.brand, data?.assets)) {
      if (state === 'edit') {
        update(data?.brand, data?.assets);
      } else {
        save(data?.brand, data?.assets);
      }
    }
  };

  const onFile = async (status, files, type) => {
    if (status !== 'done') return [];
    return Promise.all(
      files.map(({ meta, file }) =>
        new Promise(((resolve) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () =>
            resolve({
              type,
              name: meta.name,
              data: Text.ab2str(reader.result),
            });
        }))))
      .then(res =>
        setData(current => ({
          ...current,
          assets: current.assets
            .filter(v => v.type !== type)
            .concat(res),
        })));
  };

  useEffect(() => {
    OktaAuth.getUser()
      .then((res) => {
        if (res?.name) {
          setUserFullName(res?.name);
          setShowNameField(false);
        }
      });
    const token = OktaAuth.getAccessToken();
    const decoded = OktaAuth.token.decode(token);
    setOrgName(decoded?.payload?.ogn);
  }, [brand]);

  useEffect(() => {
    setData({ assets, brand });
    if (brand?.state === 'view') {
      setLoaChecked(true);
    }
  }, [assets, brand]);

  useEffect(() => {
    let _opts = [];
    const _countList = [];
    const {
      domain_monitoring_count,
      domain_monitoring_limit,
      mobile_app_monitoring_count,
      mobile_app_monitoring_limit,
      social_media_brand_count,
      social_media_brand_limit,
      social_media_executive_count,
      social_media_executive_limit,
    } = limits;

    /* Generate type options */
    const dmOpt = { label: 'Domain Monitoring', value: 'domain' };
    const mobOpt = { label: 'Mobile App Monitoring', value: 'mobile app' };
    const brandOpt = { label: 'Social Media Brand Monitoring', value: 'brand social media' };
    const execOpt = { label: 'Social Media Executive Monitoring', value: 'executive social media' };
    _opts = [dmOpt, mobOpt, brandOpt, execOpt];

    /* Generate brand limit counts and add them to list */

    /* Check the limit and add it if there are slots remaining */
    if (domain_monitoring_count < domain_monitoring_limit) {
      _countList.push({
        left: domain_monitoring_limit - domain_monitoring_count,
        total: domain_monitoring_limit,
        name: dmOpt.label,
      });
    } else if (!['edit', 'view'].includes(brand?.state)) {
      /*
       * Otherwise remove the option from the dropown if the
       * user is trying to add a new brand and there are no slots left
       */
      _opts = _opts.filter(v => v.value !== dmOpt.value);
    }

    if (mobile_app_monitoring_count < mobile_app_monitoring_limit) {
      _countList.push({
        left: mobile_app_monitoring_limit - mobile_app_monitoring_count,
        total: domain_monitoring_limit,
        name: mobOpt.label,
      });
    } else if (!['edit', 'view'].includes(brand?.state)) {
      _opts = _opts.filter(v => v.value !== mobOpt.value);
    }

    if (social_media_brand_count < social_media_brand_limit) {
      _countList.push({
        left: social_media_brand_limit - social_media_brand_count,
        total: social_media_brand_limit,
        name: brandOpt.label,
      });
    } else if (!['edit', 'view'].includes(brand?.state)) {
      _opts = _opts.filter(v => v.value !== brandOpt.value);
    }

    if (social_media_executive_count < social_media_executive_limit) {
      _countList.push({
        left: social_media_executive_limit - social_media_executive_count,
        total: social_media_executive_limit,
        name: execOpt.label,
      });
    } else if (!['edit', 'view'].includes(brand?.state)) {
      _opts = _opts.filter(v => v.value !== execOpt.value);
    }

    if (_opts.length < 1) {
      _opts.push({ label: '- BRAND LIMITS EXCEEDED -', value: 'brand limits exceeded' });
    }

    /* Update dialog state values */
    setOpts(_opts);
    setBrandCountList(_countList);

    /* Set prechecked type in dropdown */
    if (!['edit', 'view'].includes(brand?.state)) {
      const _brand = {
        ...brand,
        type: _opts[0].value,
      };
      setData({ assets, brand: _brand });
    }
  }, [limits]);

  return (
    <Grid className={[style.brand, 'brandDialog'].join(' ')}>
      {!data && <div><CircularProgress /></div>}
      {data &&
      <React.Fragment>
        <Row>
          <Col xs={12}>
            { (brandCountList.length > 0) && brand?.state !== 'view' &&
              <ul>
                {
                  brandCountList.map(v =>
                    (
                      <li key={v.name} className={style.listItem}>
                        Complete {v.name} profile setup -{' '}
                        {v.left} of{' '}
                        {v.total} available
                      </li>
                    ),
                  )
                }
              </ul>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={style.filters}>
            <TextFilter
              onFilter={v => setData(current => ({
                ...current,
                brand: { ...current.brand, ...v },
              }))}
              fields={[
                { value: 'type', label: 'Type *', type: 'dropdown', opts: fromJS(opts), help: 'Please choose the type of brand you want protected.', disabled: ['edit', 'view'].includes(state) },
                { value: 'name', label: 'Brand Name *', type: 'text', help: 'Please name the brand you want protected.', disabled: ['edit', 'view'].includes(state) },
                { value: 'allowed_domains', label: 'Valid domain, social media or mobile app URLs *', type: 'chips', disabled: state === 'view' },
                { value: 'trademark_links', label: `Trademark Links ${data?.brand.type !== 'executive social media' ? '*' : ''}`, type: 'chips', disabled: state === 'view' },
              ]}
              defaults={fromJS(data?.brand)}
              filters={fromJS(data?.brand)}
              />
          </Col>
        </Row>
        {Object.entries(attachments).map(([type, help]) => (
          <Row key={type}>
            <Col xs={12} className={style.dropzone}>
              <FormLabel>{type} {type !== 'attachment' && data?.brand.type !== 'executive social media' && '*'}</FormLabel>
              <div className={style.assetList}>
                {existingAssets[String(type)]?.map(item =>
                  (<span key={item.id} className={style.assetListItem}>{item.name}</span>))}
              </div>
              <Dropzone
                accept={type === 'logo' ? 'image/png,image/jpeg' : '.pdf'}
                validate={file => onValidate(file, type)}
                onChangeStatus={(file, status, files) => onFile(status, files, type)}
                inputContent={<Icon>cloud_upload</Icon>}
                inputWithFilesContent={<Icon>cloud_upload</Icon>}
                disabled={state === 'view'}
                />
              <FormHelperText>{help}</FormHelperText>
            </Col>
          </Row>))}
        <Row>
          <Col xs={12} className={style.filters}>
            <div dangerouslySetInnerHTML={{ __html: getLoaText(orgName) }} className={style.loa} />
            <div className={['MuiFormHelperText-root', style.textMargins].join(' ')}>
              Authorization is needed to allow Flashpoint to initiate takedowns on your{' '}
              organization's behalf.
            </div>
            {showNameField && (state !== 'edit') &&
              <div className={style.fieldMargins}>
                <TextField
                  onChange={v => setUserFullName(v.target.value)}
                  label="Name *"
                  variant="outlined"
                  placeholder="Enter your name"
                />
              </div>}
            <div className={style.textMargins}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="LoaCheckbox"
                    onChange={v => setLoaChecked(v.target.checked)}
                    checked={loaChecked}
                    disabled={state === 'view'}
                    className={style.checkbox}
                  />
                }
                label="I authorize Flashpoint to initiate takedowns on my organization's behalf *"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {state === 'view' &&
              <div className={style.disclaimer}>
                Please reach out to your Customer Success{' '}
                rep to make any changes to your brand setup
              </div>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={style.footer}>
            <div className={style.status}>{error}</div>
            <div>
              <Button
                name="brand.cancel"
                onClick={cancel}
                className="cancelBtn">
                Cancel
              </Button>
              {(!data?.brand?.id || state !== 'view') &&
              <Button
                name="brand.apply"
                color="secondary"
                onClick={() => onApply(data?.brand, data?.assets)}
                disabled={state === 'view'}
                className="applyBtn">
                Apply
              </Button>}
            </div>
          </Col>
        </Row>
      </React.Fragment>}
    </Grid>
  );
};

Brand.propTypes = {
  assets: PropTypes.array,
  brand: PropTypes.object,
  limits: PropTypes.object,
  cancel: PropTypes.func,
  save: PropTypes.func,
  update: PropTypes.func,
};

Brand.defaultProps = {
  assets: [],
  brand: {},
  limits: {},
  cancel: null,
  save: null,
  update: null,
};

export default Brand;
