import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Pagination } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

import style from '../sanctions.module.scss';
import Token from '../../../utils/token';
import Api from '../../../utils/api';
import Messages from '../../../constants/Messages';
import SearchActions from '../../../actions/searchActions';

const MonthlyReports = () => {
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState();
  const [isRunningReport, setIsRunningReport] = useState(false);
  // used to update the list of reports
  const [updateReports, setUpdateReports] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;
  useEffect(() => {
    const query = { page };
    if (!loadingReports) {
      setLoadingReports(true);
    }
    Api.get('/ui/v4/sanctions/report/monthly', query, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        setReports(res);
        setLoadingReports(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setLoadingReports(false);
      });
    return function cleanup() {
      isOnPage = false;
    };
  }, [page, updateReports]);

  const handleRunSanctionsReport = useCallback(async () => {
    // check if we are already generating a report
    if (isRunningReport) {
      return;
    }
    setIsRunningReport(true);
    Api.post('/ui/v4/sanctions/report/run', {}, [200], 30000, { Authorization: `Bearer ${Token.cke()}` })
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res !== null) {
          SearchActions.set(['search', 'info', 'message'], res);
        }
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then(() => {
        if (!isOnPage) {
          return;
        }
        setIsRunningReport(false);
        setUpdateReports(!updateReports);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setIsRunningReport(false);
      });
  }, [isRunningReport]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      <div className={style.sanctions}>
        <div className={style.sanctionssection}>
          <div className={style.title} data-cy="runNewReportButton">
            All Reports
            <button
              type="button"
              disabled={isRunningReport}
              onClick={handleRunSanctionsReport}
              className={style.runnewreport}>
              + Run New Report
            </button>
            {isRunningReport && <CircularProgress />}
          </div>
          <Paper className={style.card}>
            <Table aria-label="simple table" data-cy="monthlyReportsTable">
              <TableHead>
                <TableRow>
                  <TableCell className={style.savedcellhead}>Report Date</TableCell>
                  <TableCell className={style.savedcellhead}>Info</TableCell>
                  <TableCell className={style.savedcellhead}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports && reports.reports && reports.reports.map(report => (
                  <TableRow key={report.report_date}>
                    <TableCell
                      className={style.savedcell}>
                      {new Date(report.report_date).toLocaleString()}
                    </TableCell>
                    <TableCell className={style.savedcell}>
                      {report.any_positive_results === false ? (
                        <React.Fragment>
                          <div className={style.reportinfo}>
                            <CheckCircleOutline className={style.noresultscheck} />
                            No new risk factors for {report.terms_searched_count}{' '}
                            previously searched terms
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className={style.reportinfo}>
                            <ErrorOutline className={style.resultswarn} />
                            {Object.keys(report.report_json.resultList).length}{' '}
                            out of {report.terms_searched_count}{' '}
                            previously searched terms returned new risk factors
                          </div>
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell className={style.linkcell}><a href={`/home/sanctions/report/${report.id}`}>View</a></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loadingReports && <CircularProgress />}
            {reports && reports.pageInfo && reports.reports && (
              <div className={style.savedpaginate} data-cy="monthlyReportsPagination">
                {(reports.pageInfo.pageSize * (reports.pageInfo.pageNumber - 1)) + 1}
                -
                {(reports.pageInfo.pageSize * (reports.pageInfo.pageNumber - 1))
                  + reports.reports.length}{' '}
                of {reports.pageInfo.totalResults}
                <Pagination
                  count={Math.ceil(reports.pageInfo.totalResults / reports.pageInfo.pageSize)}
                  page={page}
                  onChange={handlePageChange} />
              </div>
            )}
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthlyReports;
