import PropTypes from 'prop-types';
import React from 'react';

import { Map as map } from 'immutable';

const CommentThread = React.lazy(() => import('./CommentThread'));

const CommentThreadContainer = ({
  location,
  meta,
  onAction,
  onMediaClick,
  post,
  replies,
  showReplies,
  setShowReplies,
}) => (
  <CommentThread
    location={location}
    meta={meta}
    onAction={onAction}
    onMediaClick={onMediaClick}
    post={post}
    replies={replies}
    showReplies={showReplies}
    setShowReplies={setShowReplies} />
);

CommentThreadContainer.propTypes = {
  location: PropTypes.object,
  meta: PropTypes.object,
  onAction: PropTypes.func,
  onMediaClick: PropTypes.func,
  post: PropTypes.object,
  replies: PropTypes.object,
  showReplies: PropTypes.object,
  setShowReplies: PropTypes.func,
};

CommentThreadContainer.defaultProps = {
  location: {},
  meta: map(),
  onAction: null,
  onMediaClick: null,
  post: map(),
  replies: map(),
  showReplies: map(),
  setShowReplies: null,
};

export default CommentThreadContainer;
