import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue, useRecoilState } from 'recoil';
import moment from 'moment';
import { fromJS } from 'immutable';

import TwitterDialog from './TwitterDialog';
import SettingsList from '../shared/SettingsList';
import AlertingStore from '../../../../stores/recoil/alerting';
import Prompt from '../../../utils/Prompt';
import { readAllKeywords, createKeyword, updateKeyword, deleteKeyword } from './query';
import SearchActions from '../../../../actions/searchActions';
import UserActions from '../../../../actions/userActions';
import Messages from '../../../../constants/Messages';
import Text from '../../../../utils/text';
import Query from '../../../../containers/Alerting/query';

const useStyles = makeStyles(() => ({
  addButton: {
    marginTop: '1.6rem',
  },
}));

const TwitterSettings = ({ user, keywordClasses, disabled }) => {
  const [dialog, setDialog] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState();
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const [owners, setOwners] = useRecoilState(AlertingStore.owners);
  const classes = useStyles();

  useEffect(() => {
    Query.load('owners', user.get('prm')).then(res => setOwners(fromJS(res.owners)));
    setOwners(owners);
  },
  []);

  useEffect(() => {
    if (keywordClasses && keywordClasses.length > 0) {
      const alertGoals = keywordClasses.map(v => ({ value: v?.id, label: Text.Sentence(v?.name)?.replace(/twitter/ig, '') }));
      setGoals(alertGoals);
      setSelectedGoal(alertGoals[0].value);
    }
  }, [keywordClasses]);

  useEffect(() => {
    if (!ownerId || !keywordClasses) return;
    readAllKeywords(ownerId, keywordClasses)
      .then(data => setKeywords(data)) // filter by date
      .catch((err) => {
        SearchActions.set(['search', 'info', 'message'], Messages.TwitterKeywordError);
        throw err;
      });
  }, [ownerId, keywordClasses, refresh]);

  const onCreateKeyword = data => createKeyword(ownerId, data.goal, data.name, data.keyword);

  const onUpdateKeyword = data => updateKeyword(data.goal, data.name, data.keyword, data.keywordId);

  const onSubmit = (data) => {
    if (dialog?.key !== 'edit' && dialog?.key !== 'create') {
      setDialog({});
      SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(dialog?.key));
      return;
    }
    const doFunc = dialog?.key === 'edit' ? onUpdateKeyword : onCreateKeyword;
    doFunc(data)
      .then((res) => {
        if (res.status === 400) {
          if (/duplicate keyword/ig.test(res.data.title)) {
            throw new Error(`Duplicate keyword search term "${data.keyword}"`);
          }
          throw new Error(`${res.status} ${res.statusText}`);
        }
        return res.data;
      })
      .then(() => setRefresh(!refresh))
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.KeywordsUpdated))
      .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
    setDialog();
  };

  const onViewClick = (index) => {
    const kw = keywords[Number(index)];
    setSelectedKeyword(kw);
    setSelectedGoal(kw.keyclass_id);
    setDialog({ key: 'view' });
  };

  const openAndClearDialog = () => {
    setSelectedKeyword(null);
    // Open the correct dialog depending on whether user has agreed to EULA
    // eslint-disable-next-line no-unused-expressions
    user.getIn(['prefs', 'twitter_ack']) ? setDialog({ key: 'create' }) : setDialog({ key: 'eula' });
  };

  const onDelete = (keyword) => {
    deleteKeyword(keyword.owner_id, keyword.id)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.KeywordsDeleted))
      .then(() => setRefresh(!refresh))
      .finally(() => setDialog({}))
      .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
  };

  return (
    <div className="twitterSettings">
      <SettingsList
        disabled={disabled}
        items={keywords.map(v => ({
          ...v,
          itemSubtitle: goals.find(_v => _v.value === v.keyclass_id)?.label,
        }))}
        onView={(item, index) => onViewClick(index)}
        onEdit={item => setDialog({ key: 'edit', value: item })}
        onDelete={item => setDialog({ key: 'delete', value: item })}
        options={{
          emptyMessage: 'You do not have any Twitter alerts set up. Click on the "Add New Twitter Keyword" button to get started',
          editPermission: 'dat.twtr.org.w|dat.twtr.adm',
          user,
        }}
      />
      { user.get('prm').some(p => /dat.twtr.org.w|dat.twtr.adm/ig.test(p)) &&
        <Button
          disabled={disabled}
          onClick={() => openAndClearDialog()}
          className={classes.addButton}
          variant="contained"
          color="secondary"
          data-testid="twitter.add-keyword">
          Add a new Twitter keyword
        </Button>
      }
      <TwitterDialog
        open={['create', 'view', 'edit'].includes(dialog?.key)}
        user={user}
        keyword={selectedKeyword}
        onSubmit={onSubmit}
        onCancel={() => setDialog({})}
        options={{
          dialog,
          setDialog: v => setDialog(v),
          goals,
          initialGoal: selectedGoal,
        }}
        />
      <Prompt
        open={Boolean(dialog?.key === 'delete')}
        title="Warning: Delete Keyword?"
        acceptText="Continue"
        accept={() => onDelete(dialog?.value)}
        cancelText="Cancel"
        cancel={() => setDialog({})}>
        You are about to permanently delete this keyword. Are you sure?
      </Prompt>
      <Prompt
        open={dialog?.key === 'eula'}
        title="Twitter EULA Acknowledgement"
        acceptText="I Accept"
        accept={() => {
          UserActions.set(['user', 'prefs', 'twitter_ack'], moment.utc().unix());
          UserActions.savePrefs();
          setDialog({ key: 'create' });
        }}
        cancelText="Decline"
        cancel={() => setDialog({})}
       >
        {Messages.TwitterEula}
      </Prompt>
      <Prompt
        open={dialog?.key === 'agreement'}
        title="Twitter EULA"
        acceptText="Close"
        accept={() => setDialog({ ...dialog.previous })}
       >
        {Messages.TwitterEula}
      </Prompt>
    </div>
  );
};

TwitterSettings.propTypes = {
  user: PropTypes.object,
  keywordClasses: PropTypes.array,
  disabled: PropTypes.bool,
};

TwitterSettings.defaultProps = {
  user: null,
  keywordClasses: [],
  disabled: false,
};

export default TwitterSettings;
