import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import 'lodash.combinations';
import cx from 'classnames';
import moment from 'moment';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { Autocomplete as MuiAutoComplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Icon,
  InputAdornment,
  InputLabel,
  ListItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import PickersDay from '@mui/lab/PickersDay';
import AdapterMoment from '@mui/lab/AdapterMoment';
import MuiPickersUtilsProvider from '@mui/lab/LocalizationProvider';

import makeStyles from '@mui/styles/makeStyles';

import style from './orgProfile.module.scss';
import Text from '../../../../utils/text';
import UserActions from '../../../../actions/userActions';
import WithAttrs from '../../../utils/WithAttrs';

const useStyles = makeStyles(theme => ({
  complexityFields: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    margin: 0,
    '& > div:first-of-type': {
      borderRight: `1px solid ${theme.palette.primary.main}`,
      '& > div': {
        borderTopLeftRadius: '5px',
      },
    },
    '& > div:last-of-type': {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      '& > div': {
        borderTopRightRadius: '5px',
      },
    },
    '& .MuiFormControl-root': {
      margin: '0 !important',
    },
    '& .MuiInputBase-root': {
      margin: '0 !important',
    },
    '& .MuiFormLabel-root': {
      transform: 'translate(0, 8px) scale(1)',
    },
  },
  dayPicker: {
    maxWidth: '300px',
  },
  selection: {
    '& .MuiInputBase-root': {
      margin: '0 .5rem !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '.5rem 3.2rem .5rem 1rem',
    },
  },
}));


const OrgProfile = ({
  canEdit,
  disabled,
  fields,
  profile,
  sid,
}) => {
  const [values, setValues] = useState(map());
  const [hasChanged, setHasChanged] = useState(false);
  const [hover, setHover] = useState('');
  const [monthlyReportDate, setMonthlyReportDate] = useState(profile.getIn(['ccm_e_monthly_reports_config', 'day_of_month']) || 1);
  const classes = useStyles();

  const allowedFields = ['uppercase', 'lowercase', 'symbol', 'number'];
  const onChange = (k, v) => {
    setValues(values.setIn(k, v));
    setHasChanged(true);
  };
  const complexityFields = [
    { id: 'required_fields', header: 'Password must include', between: 'AND' },
    { id: 'optional_fields', header: (
      <React.Fragment>
        Must match
        <Select
          disabled={disabled}
          variant="outlined"
          fullWidth={false}
          value={values.getIn(['complexity', 'num_optional_params_required']) == null ? 1 : values.getIn(['complexity', 'num_optional_params_required'])}
          onChange={event => onChange(['complexity', 'num_optional_params_required'], event.target.value)}>
          {[1, 2, 3].map(v => (
            <ListItem
              key={v}
              value={v}
              disabled={v > values.getIn(['complexity', 'optional_fields'], list()).size - 1}>
              {v}
            </ListItem>
          ))}
        </Select>
      </React.Fragment>
    ), between: 'OR' },
    { id: 'excluded_fields', header: 'Cannot include', between: 'NOR' },
  ];

  const onAddField = (k, v) => {
    const keys = ['required_fields', 'optional_fields', 'excluded_fields'];
    let hasBeenUsedIn = '';
    keys.forEach((key) => {
      const keyValues = values.getIn(['complexity', key], list()).map(f => f.get('field'));
      if (keyValues.includes(v)) hasBeenUsedIn = key;
    });
    if (hasBeenUsedIn === '') {
      // Add the field to the list
      let keyValues = values.getIn(['complexity', k], list());
      keyValues = keyValues.push(map({ field: v, length: 1 }));
      onChange(['complexity', k], keyValues);
    } else if (hasBeenUsedIn !== k) {
      // remove the field from one list and put it into the selected
      let updatedComplexity = values.get('complexity', map());
      const usedInCurrentFields = values.getIn(['complexity', hasBeenUsedIn], list());
      const usedInNewFields = usedInCurrentFields.filterNot(f => f.get('field') === v);
      updatedComplexity = updatedComplexity.set(hasBeenUsedIn, usedInNewFields);
      const keyValuesCurrentFields = values.getIn(['complexity', k], list());
      const keyValuesNewFields = keyValuesCurrentFields.push(map({ field: v, length: 1 }));
      updatedComplexity = updatedComplexity.set(k, keyValuesNewFields);
      onChange(['complexity'], updatedComplexity);
    }
  };

  const onChangeField = (k, i, v) => {
    const keys = ['required_fields', 'optional_fields', 'excluded_fields'];
    let hasBeenUsedIn = '';
    keys.forEach((key) => {
      const keyValues = values.getIn(['complexity', key], list()).map(f => f.get('field'));
      if (keyValues.includes(v)) hasBeenUsedIn = key;
    });
    if (hasBeenUsedIn === '') {
      // Update the field
      const currentField = values.getIn(['complexity', k, i]);
      onChange(['complexity', k, i], currentField.set('field', v));
    } else if (hasBeenUsedIn === k) {
      // Remove the field since the selected value already exists in this list
      onChange(['complexity', k], values.getIn(['complexity', k]).filterNot((_f, fi) => fi === i));
    } else {
      // Remove field from other list and update the field
      let updatedComplexity = values.get('complexity', map());
      const usedInCurrentFields = values.getIn(['complexity', hasBeenUsedIn], list());
      const usedInNewFields = usedInCurrentFields.filterNot(f => f.get('field') === v);
      updatedComplexity = updatedComplexity.set(hasBeenUsedIn, usedInNewFields);
      const keyValuesCurrentFields = values.getIn(['complexity', k], list());
      const keyValuesNewFields = keyValuesCurrentFields.setIn([i, 'field'], v);
      updatedComplexity = updatedComplexity.set(k, keyValuesNewFields);
      onChange(['complexity'], updatedComplexity);
    }
  };

  const onDeleteField = (k, i) => {
    setHover('');
    onChange(['complexity', k], values.getIn(['complexity', k]).filterNot((_f, fi) => fi === i));
  };

  function onSave() {
    const updatedComplexity = values.get('complexity')
      .map((v, k) => {
        if (k === 'num_optional_params_required') {
          // check to see if any optional_fields are set, if not, set to null
          if (values.getIn(['complexity', 'optional_fields'], list()).size === 0) return null;
        }
        return (v !== '' ? v : null);
      });
    const nulledValues = values.set('complexity', updatedComplexity);
    // update the old format of the alerting fields into the new format
    let newFormat = nulledValues;
    fields.forEach((v) => {
      const id = v.get('id');
      if (v.get('fields')) {
        v.get('fields').forEach((f) => {
          const fid = f.get('id');
          if (!newFormat.hasIn([id, fid]) && fid !== 'fields') {
            // if the field isn't in the container, add from the top level, or default
            if (newFormat.has(fid)) {
              newFormat = newFormat.setIn([id, fid], newFormat.get(fid));
              newFormat = newFormat.delete(fid);
            } else {
              newFormat = newFormat.setIn([id, fid], profile.getIn(['default', id, fid]));
            }
          } else if (fid !== 'fields') {
            // if the field already is in the container, delete top level if exists
            newFormat = newFormat.delete(fid);
          }
        });
      }
    });
    UserActions.saveOrgProfiles(newFormat, sid);
    setHasChanged(false);
  }

  const WrappedFormControl = WithAttrs(FormControl, { disabled });
  const WrappedButton = WithAttrs(Button, { disabled });

  function renderField(field, value) {
    const key = value.split('.');

    switch (field.get('type')) {
      case 'number':
      case 'text':
        /* eslint-disable react/jsx-indent */
        return (
          <FormControl variant="outlined" disabled={disabled}>
            <InputLabel>{field.get('label')}</InputLabel>
            <OutlinedInput
              key={field.get('id')}
              fullWidth
              data-lpignore="true"
              multiline={field.get('multiLine')}
              name={`text.${field.get('id')}`}
              value={values.getIn(key) != null ? values.getIn(key) : field.get('default') || ''}
              inputProps={{ min: field.get('min'), max: field.get('max') }}
              className={cx([values.getIn(key) && style.changed])}
              type={field.get('type') === 'number' ? 'number' : null}
              endAdornment={values.getIn(key, '') && (
                <InputAdornment position="end">
                  <Icon
                    color="primary"
                    onClick={() => onChange(key, field.get('default', ''))}>
                    close
                  </Icon>
                </InputAdornment>
              )}
              onChange={event => onChange(key, event.target.value)}
            />
          </FormControl>
        );
      case 'toggle':
        return (
          <WrappedFormControl>
            <FormControlLabel
              label={field.get('label')}
              control={
                <Switch
                  key={field.get('id')}
                  color="secondary"
                  checked={values.getIn(key) == null ? false : values.getIn(key)}
                  onChange={(event, v) => onChange(key, v)} />}
            />
          </WrappedFormControl>
        );
      case 'checkbox':
        return (
          <WrappedFormControl>
            <FormLabel>{field.get('label')}</FormLabel>
            {field.get('opts').map(opt => (
              <Checkbox
                key={opt.get('label')}
                label={opt.get('label')}
                checked={(values.getIn(key) == null ? false : values.getIn(key)) === opt.get('value')}
                onChange={(_, v) => {
                  const falsey = v && opt.get('value') === false ? false : '';
                  const actual = v && opt.get('value') === true ? true : falsey;
                  onChange(key, actual);
                }}
                style={style} />))}
          </WrappedFormControl>
        );
      case 'radio':
        return (
          <WrappedFormControl key={field.get('id')}>
            <FormLabel>{field.get('label')}</FormLabel>
            <RadioGroup
              name={field.get('id')}
              className={style.radio}
              value={values.getIn(key) !== null ? values.getIn(key) : field.get('default')}
              onChange={(event) => {
                let val = event.target.value;
                switch (val) {
                  case 'true':
                    val = true;
                    break;
                  case 'false':
                    val = false;
                    break;
                  default:
                    break;
                }
                onChange(key, val);
              }}>
              {field.get('opts').map(opt => (
                <FormControlLabel
                  key={`${opt.get('label')}`}
                  label={opt.get('label')}
                  value={opt.get('value')}
                  control={<Radio color="secondary" />} />))}
            </RadioGroup>
          </WrappedFormControl>
        );
      case 'dropdown':
        return (
          <WrappedFormControl variant="outlined">
            <InputLabel>{field.get('label')}</InputLabel>
            <Select
              key={field.get('id')}
              value={values.getIn(key) ? field.get('opts').find(v => v.get('value') === values.getIn(key)).get('value') : field.get('default')}
              onChange={event => onChange(key, event.target.value)}>
              {field.get('opts')
                .sortBy(v => v.get('label'))
                .map(v => (
                  <ListItem
                    key={v.get('label')}
                    value={v.get('value')}>
                    {v.get('label', '').split(':').slice(-1).join()}
                  </ListItem>))}
            </Select>
          </WrappedFormControl>
        );
      case 'chips': {
        const render = field.get('selectionValue')
          ? field.get('selectionValue')
          : v => v;
        const options = field.get('opts', list()).toJS();
        const section = fields.find(f => f.get('id') === key[0]) || map();
        const chipValue = values.getIn(key)
          ? values.getIn(key).map((c) => {
            const chipField = section.get('fields', list()).find(f => f.get('id') === c);
            return { label: chipField.get('label'), value: chipField.get('id') };
          })
          : list();

        return (
          <MuiAutoComplete
            key={field.get('id')}
            multiple
            selectOnFocus
            value={chipValue.toJS()}
            options={options || []}
            className={style.chips}
            getOptionLabel={option => option.label}
            onChange={(event, vals) => {
              const selection = vals.map(v => render(v));
              let updates = values.setIn(key, fromJS(selection));
              // need to update the optional field's value to '' when added to optional fields
              selection.forEach((s) => {
                updates = updates.setIn([key[0], s], '');
              });
              onChange([key[0]], fromJS(updates).get(key[0]));
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={field.get('label')}
                helperText={`${field.placeholder
                  ? field.placeholder
                  : 'Single or comma separated values. Press enter to add'}`} />
            )} />
        );
      }
      case 'calendarDay': {
        const monthDays = ['1', '2', '3', '4', '5', '6',
          '7', '8', '9', '10', '11', '12', '13', '14', '15',
          '16', '17', '18', '19', '20', '21', '22', '23',
          '24', '25', '26', '27', '28', '29', '30', '31'];
        return (
          <WrappedFormControl>
            <MuiPickersUtilsProvider dateAdapter={AdapterMoment}>
              <div className={classes.dayPicker}>
                {monthDays.map(v => (
                  <PickersDay
                    day={moment(`2022-10-${v}`, 'YYYY-MM-DD')}
                    key={v}
                    outsideCurrentMonth={false}
                    selected={monthlyReportDate === parseInt(v, 10)}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: '#5c6ae0',
                      },
                    }}
                    onDaySelect={e => onChange([field.get('id'), 'day_of_month'], e.date())} />
                ))}
              </div>
            </MuiPickersUtilsProvider>
            <FormHelperText margin="dense">
              The data in the email will reflect the prior
              30 days from the date selected for delivery.
            </FormHelperText>
          </WrappedFormControl>
        );
      }
      default: return (<div />);
    }
  }

  useEffect(() => {
    if (profile.has('complexity')) {
      const updatedComplexity = profile.get('complexity').map((v, k) => {
        if (k === 'num_optional_params_required') return v;
        return (v != null ? v : '');
      });
      const quotedValues = profile.set('complexity', updatedComplexity);
      setValues(quotedValues);
    } else {
      setValues(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (values && values.has('complexity')) {
      const optionalFieldsCount = values.getIn(['complexity', 'optional_fields'], list()).size;
      const optionalRequiredCount = values.getIn(['complexity', 'num_optional_params_required']) || 1;
      if (optionalFieldsCount > 0 && optionalRequiredCount > optionalFieldsCount - 1) {
        onChange(['complexity', 'num_optional_params_required'], optionalFieldsCount - 1 === 0 ? 1 : optionalFieldsCount - 1);
      }
    }
    if (values && (values.has('ccm_e_monthly_reports_config') || values.get('send_ccm_e_monthly_reports') === true)) {
      const dayOfMonth = values.getIn(['ccm_e_monthly_reports_config', 'day_of_month']) || monthlyReportDate;
      if (!values.has('ccm_e_monthly_reports_config')) onChange(['ccm_e_monthly_reports_config', 'day_of_month'], dayOfMonth);
      setMonthlyReportDate(dayOfMonth);
    }
  }, [values]);

  return (
    <Grid className={cx([style.profile, classes.profile])}>
      <fieldset className={style.border} disabled={disabled}>
        <legend>{profile.get('profile')}</legend>
        {!values.isEmpty() &&
          fields
            .filter(v => (values.get('send_ccm_e_monthly_reports') === true ? v : !(v.get('id') === 'ccm_e_monthly_reports_config')))
            .map(v => (
              <Col xs={12} className={style.section} key={v.get('id')}>
                <div className={cx([style.h3, 'h3'])}>
                  {v.get('title') || v.get('label')}
                </div>
                <div className={cx([style.value, canEdit && style.editable])}>
                  {v.has('fields') &&
                  <div className={style.fields}>
                    {v.get('fields').map(f => (f.get('id') !== 'fields' ? (
                      <div key={f.get('id')} className={style.field}>
                        {!canEdit &&
                        <div>{(profile.getIn([v.get('id'), f.get('id')]) || '-').toString()}</div>}
                        {canEdit &&
                        <div>{renderField(f, `${v.get('id')}.${f.get('id')}`)}</div>}
                      </div>
                    ) : (
                      <Row key={f.get('id')} className={cx([style.complexityFields, classes.complexityFields])}>
                        {complexityFields.map(c => (
                          <Col key={c.id} xs={12 / complexityFields.size}>
                            <Row className={cx([classes.selection, style.selection])}>
                              {c.header}
                            </Row>
                            {values.getIn(['complexity', c.id], list()).map((r, i) => (
                              <React.Fragment key={r.get('field')}>
                                <Row className={style.field} onMouseEnter={() => setHover(`${c.id}.${i}`)} onMouseLeave={() => setHover('')}>
                                  {/* <Col xs={3}></Col> */}
                                  <Col xs={hover === `${c.id}.${i}` ? 11 : 12}>
                                    <WrappedFormControl variant="outlined">
                                      <Select
                                        value={r.get('field')}
                                        onChange={e => onChangeField(c.id, i, e.target.value)}>
                                        {allowedFields
                                          .map(o => (
                                            <ListItem
                                              key={o}
                                              value={o}>
                                              {Text.Sentence(o)}
                                            </ListItem>))}
                                      </Select>
                                    </WrappedFormControl>
                                  </Col>
                                  {hover === `${c.id}.${i}` &&
                                  <Icon
                                    color="primary"
                                    onClick={() => onDeleteField(c.id, i)}>
                                    close
                                  </Icon>}
                                </Row>
                                {i !== values.getIn(['complexity', c.id], list()).size - 1 &&
                                <Row className={style.field}>
                                  <Col xs={12}>
                                    <span className={style.between}>{c.between}</span>
                                  </Col>
                                </Row>}
                              </React.Fragment>
                            ))}
                            {values.getIn(['complexity', c.id], list()).size < allowedFields.length &&
                            <React.Fragment>
                              {values.getIn(['complexity', c.id], list()).size > 0 &&
                              <Row className={style.field}>
                                <Col xs={12}>
                                  <span className={style.between}>{c.between}</span>
                                </Col>
                              </Row>}
                              <Row className={style.field}>
                                {/* <Col xs={3}></Col> */}
                                <Col xs={12}>
                                  <WrappedFormControl variant="outlined">
                                    <Select
                                      value=""
                                      onChange={event => onAddField(c.id, event.target.value)}>
                                      {allowedFields
                                        .map(o => (
                                          <ListItem
                                            key={o}
                                            value={o}>
                                            {Text.Sentence(o)}
                                          </ListItem>))}
                                    </Select>
                                  </WrappedFormControl>
                                </Col>
                              </Row>
                            </React.Fragment>}
                          </Col>
                        ))}
                      </Row>
                    )))}
                  </div>}
                  {!v.has('fields') && !canEdit &&
                  <div>{(profile.getIn([v.get('id')]) || '-').toString()}</div>}
                  {canEdit &&
                  <div>{renderField(v, v.get('id'))}</div>}
                </div>
              </Col>
            ))
        }
        {hasChanged &&
        <Row>
          <Col xs={12} className={style.actions}>
            <WrappedButton
              color="secondary"
              variant="contained"
              name="button.profile.save"
              onClick={() => onSave()}>
              Save
            </WrappedButton>
          </Col>
        </Row>}
      </fieldset>
    </Grid>
  );
};

OrgProfile.propTypes = {
  canEdit: PropTypes.bool,
  disabled: PropTypes.bool,
  fields: PropTypes.object,
  profile: PropTypes.object,
  sid: PropTypes.string,
};

OrgProfile.defaultProps = {
  canEdit: false,
  disabled: false,
  fields: list(),
  profile: map(),
  sid: '',
};

export default OrgProfile;
