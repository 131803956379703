export const oldTags = new Set([
  'actor profile',
  'actors',
  'aeronautics & aerospace',
  'africa',
  'agriculture',
  'asia pacific',
  'automotive',
  'best practices',
  'central & south america',
  'central & south asia',
  'cyber threats',
  'cybercrime',
  'cybersecurity & internet governance',
  'ddos',
  'dns hijacking',
  'data breaches',
  'defacement',
  'defense',
  'doxxing',
  'e-commerce',
  'education',
  'energy',
  'entertainment',
  'espionage',
  'europe',
  'exploits & vulnerabilities',
  'financial',
  'fraud',
  'glossary',
  'government & policymakers',
  'hacktivism',
  'healthcare',
  'hospitality & gaming',
  'indicators of compromise',
  'industrial & scada',
  'insider',
  'intelligence report',
  'jihadist',
  'knowledge base',
  'law enforcement & military',
  'legal',
  'logistics',
  'malware',
  'manuals',
  'manufacturing',
  'media & telecom',
  'middle east & north africa',
  'ngos',
  'north america',
  'pharmaceutical',
  'phishing',
  'physical threats',
  'policy',
  'propaganda',
  'protests',
  'recruitment',
  'retail',
  'russia',
  'sql injection',
  'social engineering',
  'standup',
  'state-sponsored',
  'technology & internet',
  'terrorism',
  'transportation',
  'vnsa',
]);

export default [
  {
    children: [
      {
        id: 'INTELLIGENCE_REPORT',
        name: 'Intelligence Report',
        path: 'Intelligence Type',
      },
      {
        id: 'STANDUP',
        name: 'Standup',
        path: 'Intelligence Type',
      },
      {
        id: 'ACTOR PROFILE',
        name: 'Actor Profile',
        path: 'Intelligence Type',
      },
      {
        id: 'GLOSSARY',
        name: 'Glossary',
        path: 'Intelligence Type',
      },
      {
        id: 'KNOWLEDGE_BASE',
        name: 'Knowledge Base',
        path: 'Intelligence Type',
      },
      {
        id: 'BEST_PRACTICES',
        name: 'Best Practices',
        path: 'Intelligence Type',
      },
      {
        id: 'INDICATORS_OF_COMPROMISE',
        name: 'Indicators of Compromise',
        path: 'Intelligence Type',
      },
    ],
    id: 'INTELLIGENCE_TYPE',
    name: 'Intelligence Type',
  },
  {
    children: [
      {
        id: 'CYBER_THREATS',
        name: 'Cyber Threats',
        path: 'Threat Type',
      },
      {
        id: 'PHYSICAL_THREATS',
        name: 'Physical Threats',
        path: 'Threat Type',
      },
    ],
    id: 'THREAT_TYPE',
    name: 'Threat Type',
  },
  {
    children: [
      {
        id: 'CYBERCRIME',
        name: 'Cybercrime',
        path: 'Content Category',
      },
      {
        id: 'JIHADIST',
        name: 'Jihadist',
        path: 'Content Category',
      },
      {
        id: 'VNSA',
        name: 'VNSA',
        path: 'Content Category',
      },
      {
        id: 'HACKTIVISM',
        name: 'Hacktivism',
        path: 'Content Category',
      },
      {
        id: 'STATE-SPONSORED',
        name: 'State-Sponsored',
        path: 'Content Category',
      },
      {
        id: 'ESPIONAGE',
        name: 'Espionage',
        path: 'Content Category',
      },
      {
        id: 'CYBERSECURITY_&_INTERNET_GOVERNANCE',
        name: 'Cybersecurity & Internet Governance',
        path: 'Content Category',
      },
      {
        id: 'POLICY',
        name: 'Policy',
        path: 'Content Category',
      },
      {
        id: 'INSIDER',
        name: 'Insider',
        path: 'Content Category',
      },
      {
        id: 'DATA_BREACHES',
        name: 'Data Breaches',
        path: 'Content Category',
      },
      {
        id: 'PROTESTS',
        name: 'Protests',
        path: 'Content Category',
      },
    ],
    id: 'CONTENT_CATEGORY',
    name: 'Content Category',
  },
  {
    children: [
      {
        id: 'AGRICULTURE',
        name: 'Agriculture',
        path: 'Verticals',
      },
      {
        id: 'AUTOMOTIVE',
        name: 'Automotive',
        path: 'Verticals',
      },
      {
        id: 'DEFENSE',
        name: 'Defense',
        path: 'Verticals',
      },
      {
        id: 'EDUCATION',
        name: 'Education',
        path: 'Verticals',
      },
      {
        id: 'ENERGY',
        name: 'Energy',
        path: 'Verticals',
      },
      {
        id: 'FINANCIAL',
        name: 'Financial',
        path: 'Verticals',
      },
      {
        id: 'HEALTHCARE',
        name: 'Healthcare',
        path: 'Verticals',
      },
      {
        id: 'HOSPITALITY_&_GAMING',
        name: 'Hospitality & Gaming',
        path: 'Verticals',
      },
      {
        id: 'NGOS',
        name: 'NGOs',
        path: 'Verticals',
      },
      {
        id: 'MEDIA & TELECOM',
        name: 'Media & Telecom',
        path: 'Verticals',
      },
      {
        id: 'RETAIL',
        name: 'Retail',
        path: 'Verticals',
      },
      {
        id: 'TECHNOLOGY_&_INTERNET',
        name: 'Technology & Internet',
        path: 'Verticals',
      },
      {
        id: 'E-COMMERCE',
        name: 'E-Commerce',
        path: 'Verticals',
      },
      {
        id: 'TRANSPORTATION',
        name: 'Transportation',
        path: 'Verticals',
      },
      {
        id: 'ENTERTAINMENT',
        name: 'Entertainment',
        path: 'Verticals',
      },
      {
        id: 'MANUFACTURING',
        name: 'Manufacturing',
        path: 'Verticals',
      },
      {
        id: 'GOVERNMENT_&_POLICYMAKERS',
        name: 'Government & Policymakers',
        path: 'Verticals',
      },
      {
        id: 'INDUSTRIAL_&_SCADA',
        name: 'Industrial & SCADA',
        path: 'Verticals',
      },
      {
        id: 'LAW_ENFORCEMENT_&_MILITARY',
        name: 'Law Enforcement & Military',
        path: 'Verticals',
      },
      {
        id: 'AERONAUTICS_&_AEROSPACE',
        name: 'Aeronautics & Aerospace',
        path: 'Verticals',
      },
      {
        id: 'LOGISTICS',
        name: 'Logistics',
        path: 'Verticals',
      },
      {
        id: 'LEGAL',
        name: 'Legal',
        path: 'Verticals',
      },
      {
        id: 'PHARMACEUTICAL',
        name: 'Pharmaceutical',
        path: 'Verticals',
      },
    ],
    id: 'VERTICALS',
    name: 'Verticals',
  },
  {
    children: [
      {
        id: 'DDOS',
        name: 'DDoS',
        path: 'Methods',
      },
      {
        id: 'DEFACEMENT',
        name: 'Defacement',
        path: 'Methods',
      },
      {
        id: 'DNS_HIJACKING',
        name: 'DNS Hijacking',
        path: 'Methods',
      },
      {
        id: 'EXPLOITS_&_VULNERABILITIES',
        name: 'Exploits & Vulnerabilities',
        path: 'Methods',
      },
      {
        id: 'MALWARE',
        name: 'Malware',
        path: 'Methods',
      },
      {
        id: 'PHISHING',
        name: 'Phishing',
        path: 'Methods',
      },
      {
        id: 'SOCIAL_ENGINEERING',
        name: 'Social Engineering',
        path: 'Methods',
      },
      {
        id: 'SQL_INJECTION',
        name: 'SQL Injection',
        path: 'Methods',
      },
      {
        id: 'DOXXING',
        name: 'Doxxing',
        path: 'Methods',
      },
      {
        id: 'TERRORISM',
        name: 'Terrorism',
        path: 'Methods',
      },
      {
        id: 'PROPAGANDA',
        name: 'Propaganda',
        path: 'Methods',
      },
      {
        id: 'MANUALS',
        name: 'Manuals',
        path: 'Methods',
      },
      {
        id: 'RECRUITMENT',
        name: 'Recruitment',
        path: 'Methods',
      },
      {
        id: 'FRAUD',
        name: 'Fraud',
        path: 'Methods',
      },
    ],
    id: 'METHODS',
    name: 'Methods',
  },
  {
    children: [
      {
        id: 'NORTH_AMERICA',
        name: 'North America',
        path: 'regions',
      },
      {
        id: 'RUSSIA',
        name: 'Russia',
        path: 'regions',
      },
      {
        id: 'ASIA_PACIFIC',
        name: 'Asia Pacific',
        path: 'regions',
      },
      {
        id: 'MIDDLE_EAST_&_NORTH_AFRICA',
        name: 'Middle East & North Africa',
        path: 'regions',
      },
      {
        id: 'EUROPE',
        name: 'Europe',
        path: 'regions',
      },
      {
        id: 'CENTRAL_&_SOUTH_AMERICA',
        name: 'Central & South America',
        path: 'regions',
      },
      {
        id: 'CENTRAL_&_SOUTH_ASIA',
        name: 'Central & South Asia',
        path: 'regions',
      },
      {
        id: 'AFRICA',
        name: 'Africa',
        path: 'regions',
      },
    ],
    id: 'regions',
    name: 'regions',
  },
];
