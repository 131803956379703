import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { Icon } from '@mui/material';
import { Map as map, fromJS } from 'immutable';
import { useQuery } from '@apollo/client';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './cvedashboard.module.scss';
import Dashboard from '../Dashboard';
import Hero from '../../widget/Hero/Hero';
import Tab from '../../widget/Tab/Tab';
import PieTable from '../../widget/PieTable/PieTable';
import Pie from '../../widget/Pie/Pie';
import Table from '../../widget/Table/Table';
import Query from './query';
import SearchUtils from '../../../utils/searchUtils';
import History from '../../../utils/history';

const CveDashboard = ({
  profile,
}) => {
  const [skip, setSkip] = useState(0);
  const [filters, setFilters] = useState(map());
  const [data, setData] = useState();
  const nodes = ['Exploits', 'Mentions', 'Vulnerabilities', 'Reports'];

  const normalize = (options) => {
    const normalized = Object.keys(options)
      .reduce((a, c) => {
        const acc = a;
        if (typeof options[String(c)] === 'string' && options[String(c)] !== '') {
          acc[String(c)] = options[String(c)];
        }

        if (Array.isArray(options[String(c)]) && options[String(c)].length > 0) {
          acc[String(c)] = options[String(c)];
        }
        return acc;
      }, {});

    return normalized;
  };

  nodes.map(node => ({
    [node]: useQuery(Query[String(node)], {
      skip: !filters.get('date'),
      fetchPolicy: 'no-cache',
      onCompleted: v => setData({ ...data, [node]: { data: v } }),
      variables: normalize({
        date: filters.get('date'),
        start: filters.get('since'),
        end: filters.get('until'),
        v3severity: filters.get('cve_severity') ? filters.get('cve_severity').split(',') : [],
        cves: filters.get('cves') ? filters.get('cves').split(',') : [],
        products: filters.get('cve_product_names') ? filters.get('cve_product_names').split(',') : [],
        vendors: filters.get('cve_vendor_names') ? filters.get('cve_vendor_names').split(',') : [],
      }),
    }),
  }));

  useEffect(() => {
    const { sinceDate, untilDate } = SearchUtils.parseDate(profile);
    const normalized = profile
      .set('since', moment.utc(sinceDate).startOf('day').format())
      .set('until', moment.utc(untilDate).endOf('day').format());
    if (normalized.equals(filters)) return;
    setFilters(normalized);
    setData();
  }, [profile]);

  return (
    <Grid
      className={style.cvedashboard}>
      <Row>
        <Col xs={4} className={style.containers}>
          <Dashboard data={get(data, 'Vulnerabilities.data', {})} title={`MITRE & NVD UPDATES (${filters.get('date')})`}>
            <div className={style.sections}>
              <div className={style.section}>
                <Hero
                  labels={fromJS(['recently_reported_(mitre)'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Vulnerabilities.data.cveDashboard.cves.mitre', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                  History.navigateTo({
                    pathname: '/home/search/cves',
                    query: {
                      cve_reporter: 'mitre',
                      cve_product_names: get(filters, 'products', []).join(),
                      cve_vendor_names: get(filters, 'vendors', []).join(),
                      cves: get(filters, 'cves', []).join(),
                      date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                      skip: 0,
                    },
                  })
                } />
                <Hero
                  labels={fromJS(['recently_updated_(nvd)'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Vulnerabilities.data.cveDashboard.cves.nist', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                  History.navigateTo({
                    pathname: '/home/search/cves',
                    query: {
                      cve_reporter: 'nist',
                      cve_product_names: get(filters, 'products', []).join(),
                      cve_vendor_names: get(filters, 'vendors', []).join(),
                      cves: get(filters, 'cves', []).join(),
                      date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                      skip: 0,
                    },
                  })
                } />
              </div>
              <div className={style.section}>
                <Pie
                  legend
                  aid="CVE Product Names"
                  color="#eb002b"
                  titles={fromJS(['CVE Product Names'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalCVEs' }])}
                  results={fromJS({
                    data: get(data, 'Vulnerabilities.data.cveDashboard.cves.products', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/cves',
                      query: {
                        cve_reporter: 'nist',
                        cve_product_names: v.get('key'),
                        date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="CVE Severity"
                  color="#25a277"
                  titles={fromJS(['CVE Severity'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalCVEs' }])}
                  results={fromJS({
                    data: get(data, 'Vulnerabilities.data.cveDashboard.cves.severity', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/cves',
                      query: {
                        cve_reporter: 'nist',
                        cve_severity: v.get('key'),
                        date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="Vulnerability Types"
                  color="#008BD8"
                  titles={fromJS(['Vulnerability Types'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalCVEs' }])}
                  results={fromJS({
                    data: get(data, 'Vulnerabilities.data.cveDashboard.cves.vulnerability', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/cves',
                      query: {
                        cve_reporter: 'nist',
                        cve_vulnerability_types: v.get('key'),
                        date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
              </div>
              <div className={style.section}>
                <Table
                  pagination
                  onOffset={s => setSkip(s)}
                  widget={fromJS({ limit: 5 })}
                  results={fromJS({
                    data: get(data, 'Reports.data.reports.reports', []),
                    total: get(data, 'Reports.data.reports.total', 0),
                    skip,
                  })}
                  values={fromJS([
                    {
                      key: 'title',
                      style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                      render: () => (
                        <Icon
                          data-for="icon.tooltip"
                          data-tip="Reports"
                          className="material-icons"
                          style={{ fontSize: '14px' }}>
                          lightbulb_outline
                        </Icon>),
                    },
                    {
                      key: 'title',
                      label: 'recent reports',
                    },
                  ])}
                  labels={fromJS([])}
                  filters={filters}
                  onClick={v =>
                    History.navigateTo({
                    pathname: `/home/intelligence/reports/report/${v.get('fpid')}`,
                  })
                } />
              </div>
            </div>
          </Dashboard>
        </Col>
        <Col md={4} className={style.containers}>
          <Dashboard data={get(data, 'Mentions.data', {})} title={`THREAT ACTOR DISCUSSIONS (${filters.get('date')})`}>
            <div className={style.sections}>
              <div className={style.section}>
                <Hero
                  labels={fromJS(['Total Discussed'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Mentions.data.cveDashboard.tad.totalMentions', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                      pathname: '/home/search/communities',
                      query: {
                        required_enrichments: 'cves::cves',
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                        cve_product_names: get(filters, 'products', []).join(),
                        cve_vendor_names: get(filters, 'vendors', []).join(),
                        cves: get(filters, 'cves', []).join(),
                      },
                    })
                  } />
                <span style={{ padding: '15px 0 0' }}>for</span>
                <Hero
                  labels={fromJS(['CVEs'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Mentions.data.cveDashboard.tad.totalUnique', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                      pathname: '/home/search/cves',
                      query: {
                        date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                        skip: 0,
                        cve_product_names: get(filters, 'products', []).join(),
                        cve_vendor_names: get(filters, 'vendors', []).join(),
                        cves: get(filters, 'cves', []).join(),
                      },
                    })
                  } />
                <span style={{ width: '20%' }}>&nbsp;</span>
                <Hero
                  labels={fromJS(['Critical CVE Discussions'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Mentions.data.cveDashboard.tad.totalCriticalMentions', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                      pathname: '/home/search/communities',
                      query: {
                        cves: [...new Set(get(data, 'Mentions.data.cveDashboard.tad.topMentions.severity', [])
                        .filter(v => /critical/ig.test(v.key))
                        .map(v => v.cves)
                        .reduce((a, b) => [...a, ...b], []))]
                        .join(),
                        cve_product_names: get(filters, 'products', []).join(),
                        cve_vendor_names: get(filters, 'vendors', []).join(),
                        required_enrichments: 'cves::cves',
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <span style={{ padding: '15px 0 0' }}>for</span>
                <Hero
                  labels={fromJS(['Critical CVEs'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Mentions.data.cveDashboard.tad.totalCriticalUnique', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                    pathname: '/home/search/cves',
                    query: {
                      cve_product_names: get(filters, 'products', []).join(),
                      cve_vendor_names: get(filters, 'vendors', []).join(),
                      cves: [...new Set(get(data, 'Mentions.data.cveDashboard.tad.topMentions.severity', [])
                        .filter(v => /critical/ig.test(v.key))
                        .map(v => v.cves)
                        .reduce((a, b) => [...a, ...b], []))]
                        .join(),
                      date: filters.get('date'),
                      since: filters.get('since'),
                      until: filters.get('until'),
                      skip: 0,
                    },
                  })
                } />
              </div>
              <div className={style.section}>
                <Pie
                  legend
                  aid="CVE Product Names"
                  color="#eb002b"
                  titles={fromJS(['CVE Product Names'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalMentionsCount' }])}
                  results={fromJS({
                    total: 5,
                    data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.products', [])
                      .map(v => ({ ...v, cves: v.cves.join() })),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/communities',
                      query: {
                        // cve list > 250 crashes browser
                        cves: v.get('cves').split(',').slice(0, 250).join(),
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="CVE Severity"
                  color="#25a277"
                  titles={fromJS(['CVE Severity'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalMentionsCount' }])}
                  results={fromJS({
                      total: 5,
                      data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.severity', [])
                        .map(v => ({ ...v, cves: v.cves.join() })),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/communities',
                      query: {
                        // cve list > 250 crashes browser
                        cves: v.get('cves').split(',').slice(0, 250).join(),
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="Vulnerability Types"
                  color="#008BD8"
                  titles={fromJS(['Vulnerability Types'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalMentionsCount' }])}
                  results={fromJS({
                    total: 5,
                    data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.vulnerability', [])
                      .map(v => ({ ...v, cves: v.cves.join() })),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/communities',
                      query: {
                        // cve list > 250 crashes browser
                        cves: v.get('cves').split(',').slice(0, 250).join(),
                      },
                    })
                  } />
              </div>
              <div className={style.section}>
                <Tab
                  legend
                  limit={5}
                  cells={fromJS({
                    cves: [{ key: 'totalMentionsCount' }],
                    collections: [{ key: 'totalMentionsCount' }],
                    titles: [{ key: 'totalMentionsCount' }],
                  })}
                  pagination={false}
                  tabs={fromJS({
                    cves: ['cves'],
                    collections: ['collections'],
                    titles: ['titles'],
                  })}
                  labels={fromJS({
                    cves: [{ key: 'key' }],
                    collections: [{ key: 'key' }],
                    titles: [{ key: 'key' }],
                  })}
                  values={fromJS({
                    cves: [
                      {
                        key: 'key',
                        label: 'cve',
                        style: { width: '55%' },
                        link: true,
                      },
                      {
                        key: 'totalMentionsCount',
                        label: 'mentions',
                        style: { textAlign: 'right', width: '25%' },
                      },
                      {
                        key: 'vulnDb',
                        render: () => (
                          <div>
                            <img
                              data-for="global.tooltip"
                              data-tip="Open in VulnDB"
                              className={cx([style.hover, 'active', 'vulnDb', style.icon, style.vulnDB])}
                              alt="VulnDB"
                              src="/static/rbs-vdb__logo-icon_1.svg" />
                          </div>),
                      },
                    ],
                    collections: [
                      {
                        key: 'key',
                        label: 'name',
                      },
                      {
                        key: 'totalMentionsCount',
                        label: 'mentions',
                        style: { textAlign: 'right' },
                      },
                    ],
                    titles: [
                      {
                        key: 'key',
                        label: 'name',
                      },
                      {
                        key: 'totalMentionsCount',
                        label: 'mentions',
                        style: { textAlign: 'right' },
                      },
                    ],
                  })}
                  results={fromJS({
                    cves: {
                      total: 5,
                      data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.cves', []),
                    },
                    collections: {
                      total: 5,
                      data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.collection', [])
                        .filter(v => v.key !== 'message')
                        .map(v => ({ ...v, cves: v.cves.join() })),
                    },
                    titles: {
                      total: 5,
                      data: get(data, 'Mentions.data.cveDashboard.tad.topMentions.title', [])
                        .map(v => ({ ...v, cves: v.cves.join() })),
                    },
                  })}
                  onClick={fromJS({
                    cves: (v, k) => {
                      const fpid = v.get('fpid') || k.get('fpid');
                      const pathname = `/home/technical_data/cves/items/${fpid}`;
                      const query = { cve_title: v.get('key') };
                      return History.navigateTo({ pathname, query });
                    },
                    collections: (v, k) => {
                      const pathname = `/home/search/${k.get('key') || v.get('key')}`;
                      return History.navigateTo({
                        pathname,
                        query: {
                          date: filters.get('date'),
                          since: filters.get('since'),
                          until: filters.get('until'),
                          required_enrichments: 'cves::cves',
                        },
                      });
                    },
                    titles: (v, k, index) => {
                      const payload = k.getIn(['data', index], v);
                      const basetypes = payload
                        .get('basetypes')
                        .slice(-1)
                        .get(0);
                      const pathname = `/home/search/${basetypes}`;
                      return History.navigateTo({
                        pathname,
                        query: {
                          date: filters.get('date'),
                          since: filters.get('since'),
                          until: filters.get('until'),
                          sites: payload.get('key'),
                          required_enrichments: 'cves::cves',
                          ...['chats'].includes(basetypes)
                            ? { sites: '', channel: payload.get('key') }
                            : {},
                        },
                      });
                    },
                  })}>
                  <PieTable />
                </Tab>
              </div>
            </div>
          </Dashboard>
        </Col>
        <Col md={4} className={style.containers}>
          <Dashboard data={get(data, 'Exploits.data', {})} title={`RECENT EXPLOITS (${filters.get('date')})`}>
            <div className={style.sections}>
              <div className={style.section}>
                <Hero
                  labels={fromJS(['Exploits'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Exploits.data.cveDashboard.exploits.totalExploits', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                      pathname: '/home/search/exploits',
                      query: {
                        cve_product_names: get(filters, 'products', []).join(),
                        cve_vendor_names: get(filters, 'vendors', []).join(),
                        cves: get(filters, 'cves', []).join(),
                        cve_reporter: 'nist',
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <span style={{ width: '20%' }}>&nbsp;</span>
                <Hero
                  labels={fromJS(['Critical Exploits'])}
                  values={fromJS([{ key: 'total' }])}
                  results={fromJS({ total: get(data, 'Exploits.data.cveDashboard.exploits.totalCriticalExploits', []) })}
                  styles={{ width: '50%' }}
                  onClick={() =>
                    History.navigateTo({
                      pathname: '/home/search/exploits',
                      query: {
                        cve_product_names: get(filters, 'products', []).join(),
                        cve_vendor_names: get(filters, 'vendors', []).join(),
                        cves: get(filters, 'cves', []).join(),
                        cve_severity: 'critical',
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
              </div>
              <div className={style.section}>
                <Pie
                  legend
                  aid="CVE Product Names"
                  color="#eb002b"
                  titles={fromJS(['CVE Product Names'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalExploitedCVEs' }])}
                  results={fromJS({
                    total: 5,
                    data: get(data, 'Exploits.data.cveDashboard.exploits.products', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/exploits',
                      query: {
                        cve_product_names: v.get('key'),
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="CVE Severity"
                  color="#25a277"
                  titles={fromJS(['CVE Severity'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalExploitedCVEs' }])}
                  results={fromJS({
                    total: 5,
                    data: get(data, 'Exploits.data.cveDashboard.exploits.severity', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/exploits',
                      query: {
                        cve_severity: v.get('key'),
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
                <Pie
                  legend
                  aid="Vulnerability Types"
                  color="#008BD8"
                  titles={fromJS(['Vulnerability Types'])}
                  labels={fromJS([{ key: 'key' }])}
                  values={fromJS([{ key: 'totalExploitedCVEs' }])}
                  results={fromJS({
                    total: 5,
                    data: get(data, 'Exploits.data.cveDashboard.exploits.vulnerability', []),
                  })}
                  styles={{ width: '33%' }}
                  onClick={v =>
                    History.navigateTo({
                      pathname: '/home/search/exploits',
                      query: {
                        cve_vulnerability_types: v.get('key'),
                        date: filters.get('date'),
                        since: filters.get('since'),
                        until: filters.get('until'),
                        skip: 0,
                      },
                    })
                  } />
              </div>
              <div className={style.section}>
                <Table
                  pagination={false}
                  onOffset={s => setSkip(s)}
                  widget={fromJS({
                    limit: 5,
                  })}
                  results={fromJS({
                    data: get(data, 'Exploits.data.cveDashboard.exploits.cves', []),
                    total: 5,
                    skip,
                  })}
                  values={fromJS([
                    {
                      key: '',
                      style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                      render: () => (
                        <Icon
                          data-for="icon.tooltip"
                          data-tip="Vulnerability"
                          className="material-icons"
                          style={{ fontSize: '14px' }}>
                          bug_report
                        </Icon>),
                    },
                    {
                      key: 'key',
                      label: 'name',
                    },
                    {
                      key: 'totalExploitedCVEs',
                      label: '# OF EXPLOITS',
                    },
                  ])}
                  labels={fromJS({ key: 'key' })}
                  filters={filters}
                  onClick={(v) => {
                      const pathname = `/home/technical_data/cves/items/${v.get('fpid')}`;
                      History.navigateTo({ pathname });
                    }}
                  />
              </div>
            </div>
          </Dashboard>
        </Col>
      </Row>
    </Grid>
  );
};

CveDashboard.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default CveDashboard;


