import { gql } from '@apollo/client';

const merchants = gql`
query merchants{
    merchantsLastUpdatedTime{
      binAttack
      cppMerchants
      highFraud
    }
  }
`;

export default merchants;
