import Api from '../../../utils/api';

const Cards = async (filters) => {
  const url = '/ui/v4/all/search';
  const fields = [
  ];

  const query = {
    q: `
    basetypes:(card)
    ${filters.bin ? `AND bin:(${filters.bin.split(',').join(' OR ')})` : ''}
    ${filters.release_name ? `AND base.title:(${filters.release_name.split(',').map(v => (v.includes('*') ? v : `"${v}"`)).join()})` : ''}
    ${filters.since ? `AND sort_date:[${filters.since} TO ${filters.until}]` : ''}`,
    aggregations: {
      'date-histogram': { field: 'sort_date', interval: 'day' },
      'multiple-terms': {
        'base-titles': { terms: { field: 'base.title.keyword', size: 10000 } },
        'site-titles': { terms: { field: 'site.title.keyword', size: 100 } },
        'card-locations': { terms: { field: 'cardholder_information.location.country.raw.keyword', size: 10000 } },
        'card-payment-methods': { terms: { field: 'payment_method.keyword', size: 5 } },
        'card-types': { terms: { field: 'card_type.keyword', size: 5 } },
        'card-bins': { terms: { field: 'bin', size: 5 } },
        'card-track-info': { terms: { field: 'track_information.keyword', size: 5 } },
        'card-service-codes': { terms: { field: 'service_code', size: 5 } },
      },
    },
    sort: ['sort_date:desc'],
    _source_includes: fields || [],
    size: 0,
  };

  return Api.post(url, query)
    .then(res => (res.ok ? res.data : ''))
    .then(res => ({
      total: res.hits.total,
      histogram: res.aggregations['date-histogram'].buckets,
      releases: {
        data: res.aggregations['base-titles'].buckets,
        total: res.aggregations['base-titles'].buckets.length,
      },
      shops: {
        data: res.aggregations['site-titles'].buckets,
        total: res.aggregations['site-titles'].buckets.length,
      },
      locations: res.aggregations['card-locations'].buckets,
      methods: res.aggregations['card-payment-methods'].buckets,
      types: res.aggregations['card-types'].buckets,
      bins: res.aggregations['card-bins'].buckets,
      tracks: res.aggregations['card-track-info'].buckets,
      codes: res.aggregations['card-service-codes'].buckets,
    }));
};

export default {
  Cards,
};
