import * as React from 'react';
import { useContext, useState } from 'react';
import { Map, List, fromJS } from 'immutable';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import easyMemo from '../../../../utils/Memoize';
import { SearchContext } from '../../../../utils/Context';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import { CategoryMenu } from '../../../../utils/CategoryMenu/CategoryMenu';
import { MenuItem } from '../../../../utils/CategoryMenu/MenuItem';

type tagObj = {
  name: string,
  id: string,
  checked: boolean,
}

type tagCategoryObj = {
  children: tagObj[],
  id: number,
  name: string,
}

interface formattedTagObj {
  name: string;
  id: string;
  checked: boolean;
}

export type CheckedObj = {
  checked: boolean
}

interface formattedTagData {
  [key: string]: formattedTagObj[]
}

export const editBottomRowDialogStyles = ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
  closeIcon: {
    marginLeft: 'auto',
    marginRight: '1rem',
  },
  content: {
    borderTop: `1px ${grey[300]} solid`,
    display: 'flex',
    flexFlow: 'column',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  description: {
    marginTop: '20px',
  },
  contentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
} as object);

const useStyles = makeStyles(() => editBottomRowDialogStyles);

interface EditIntelReportsDialogProps {
  open: boolean;
  onCancel: () => void;
  onApply: (userPrefsCandidate: object) => void;
  tagIdPrefs?: string[];
}

export const capitalizeWords = (myStr:string):string => (
  myStr.split(' ').map((word:string):string => word[0].toUpperCase() + word.substring(1)).join(' ')
);

// Search query strings use a tag's name, not its id (which is odd to me)
const getCapitalizedChildrenArray = (tagCategoryObj: tagCategoryObj, tagIdPrefs: string[]) =>
  tagCategoryObj.children.map((tag: tagObj) => (
  { name: capitalizeWords(tag.name), id: tag.name, checked: tagIdPrefs.includes(tag.name) }
));

const formatTagIdPrefs = (tags: Map<string, unknown>, tagIdPrefs: string[]):string[] => (
  tagIdPrefs.length > 0 ? tagIdPrefs :
    Object.values(tags.toJS())
    .map((tagCategoryObj: tagCategoryObj) => tagCategoryObj.children)
    .flat()
    .map((tagObj: tagObj) => tagObj.name)
);

/**
 * formatTagData() returns a prefernces model which is used to create a CategoryMenu and its MenuItems
 * This model is later packaged up by convertFormattedTagDataToIdArray to be saved in User Preferences
 * In the result, each key is an Intel Report Category Name (the menuText of CategoryMenu)
 *  and each value contains { name, id, checked? } attributes (the itemText itemChecked of MenuItem)
 * An example might look like this:
 * {
 *   "Verticals": [
 *     { name: 'Agriculture', id: 'AGRICULTURE', checked: true },
 *     { name: 'Defense', id: 'DEFENSE', checked: false },
 *   ],
 *   "Methods": [ ... ],
 *   "Threat Type": [ ... ],
 * }
 */
const formatTagData = (tags: Map<string, unknown>, tagIdPrefs: string[]):object => (
  tags
  .sortBy((v:Map<string, Map<string, unknown>>) => v.get('name'))
  .toJS()
  .reduce((acc:object, tagCategoryObj: tagCategoryObj) => (
    { ...acc,
      [capitalizeWords(tagCategoryObj.name)]:
      getCapitalizedChildrenArray(tagCategoryObj, tagIdPrefs),
    }
  ), {})
);

export type setFunction = (v: unknown) => void;

const returnEmptyIfAllAreChecked = (totalCount: number, checkedIds: string[]) => (
  checkedIds.length === totalCount ? [] : checkedIds);

const convertFormattedTagDataToIdArray = (tags: formattedTagData) => (
  returnEmptyIfAllAreChecked(
    Object.values(tags).flat().length,
    Object.values(tags)
    .flat()
    .filter((tagObj:formattedTagObj) => tagObj.checked)
    .map((tagObj:formattedTagObj) => tagObj.id),
  )
);

const tagMap = (tags: formattedTagData) => (fromJS(tags) as Map<string, List<unknown>>);
const atLeastOneItemIsChecked = (myTags:formattedTagData) =>
  Object.values(myTags).flat().filter((v:CheckedObj) => v.checked).length;

export const EditIntelReportsDialog = easyMemo(({
  open,
  onCancel,
  onApply,
  tagIdPrefs,
}: EditIntelReportsDialogProps): JSX.Element => {
  const classes = (useStyles() as { [key:string]: never });
  const [openMenu, setOpenMenu] = useState({});
  const search = (useContext(SearchContext) as Map<string, Map<string, unknown>>);
  const [tags, setTags] = (useState({}) as [formattedTagData, setFunction]);

  easyUseEffect(() => {
    setTags(formatTagData(search.get('tags'), formatTagIdPrefs(search.get('tags'), tagIdPrefs)));
  }, [tagIdPrefs]);

  if (!(Object.keys(tags).length > 0)) return null;

  const toggleCheckboxPath = (categoryName: string, tagIndex: number) => {
    const newTags = tagMap(tags).updateIn([categoryName, tagIndex, 'checked'], checked => (!checked)).toJS();
    if (atLeastOneItemIsChecked(newTags)) {
      setTags(newTags);
    }
  };

  const handleApply = (): void => {
    if (onApply) onApply(convertFormattedTagDataToIdArray(tags));
    onCancel();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  const onClickMenu = (tagCategoryName: string) => {
    const el = document.querySelector('#dialog-settings .MuiDialogContent-root');
    el.scrollTo(0, 0);
    if (tagCategoryName === openMenu) {
      setOpenMenu(null);
    } else {
      setOpenMenu(tagCategoryName);
    }
  };

  return (
    <Dialog open={open} className={classes.root} id="dialog-settings">
      <div className={classes.header}>
        <DialogTitle>
          Recent Intelligence Reports
        </DialogTitle>
        <IconButton onClick={handleCancel} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.contentRoot}>
        <Box className={classes.content}>
          <div className={classes.description}>
            Refine your list of recent intelligence reports
            by selecting categories you are interested in.
          </div>
          <div>
            {Object.entries(tags)
            .map(([tagCategoryName, menuChildren]: [string, formattedTagObj[]]) => (
              <>
                <CategoryMenu
                  className={classes.categoryMenu}
                  menuText={tagCategoryName}
                  isOpen={tagCategoryName === openMenu}
                  onToggle={onClickMenu}
                  menuChildren={menuChildren}
                  makeMenuChild={(tagObj:tagObj, myIndex: number) => (
                    <MenuItem
                      itemText={tagObj.name}
                      checked={tagObj.checked}
                      onChange={() => toggleCheckboxPath(tagCategoryName, myIndex)}
                    />
                  )}
                />
              </>
            ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleCancel}
          id="ds-cancelBtn"
            >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={handleApply}
          id="ds-applyBtn"
            >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});

EditIntelReportsDialog.defaultProps = {
  tagIdPrefs: [],
};
