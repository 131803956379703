import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import style from '../search/Table/table.module.scss';

const ToolTip = ({
  col,
  row,
  index,
}) => {
  const tooltipRef = useRef();
  const [isSet, setIsSet] = useState(false);
  const [reload, setReload] = useState(false);
  const [isRight, setIsRight] = useState(false);

  const isRtl = () => {
    let rtlBool = false;
    const rtlChar = /^[\u0590-\u083F]|[\u08A0-\u08FF]|[\uFB1D-\uFDFF]|[\uFE70-\uFEFF]$/mg;
    if (col.render(row, index).props !== undefined
      && col.render(row, index).props.dangerouslySetInnerHTML !== undefined) {
      const isRightToLeft =
        rtlChar.test(String(col.render(row, index).props.dangerouslySetInnerHTML.__html));
      if (isRightToLeft) {
        rtlBool = true;
      } else {
        rtlBool = false;
      }
    }
    setIsRight(rtlBool);
  };

  const checkForEllipsis = () => {
    const el = tooltipRef.current.children[0].children[0];
    if (el !== undefined && (el.offsetWidth < el.scrollWidth || el.innerText.indexOf('[...]') !== -1)) {
      setReload(true);
    }
  };

  useEffect(() => {
    if (isSet !== true) {
      checkForEllipsis();
      setIsSet(true);
    }
    isRtl();
  }, []);

  return (
    <div ref={tooltipRef}>
      {reload &&
      <div
        data-class={isRight ? style.right : style.left}
        data-for={col.tooltip && col.tooltip(row, index) && 'global.tooltip'}
        data-tip={`<div dir="auto">${((col.tooltip && col.tooltip(row, index).match(/(.{1,75})(?:\n|$| )/g)) || []).join('<br />')}</div>`}>
        {col.render(row, index)}
      </div>}
      {!reload &&
      <div>{col.render(row, index)}</div>}
    </div>
  );
};

ToolTip.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  col: PropTypes.object,
};

ToolTip.defaultProps = {
  row: {},
  index: 0,
  col: {},
};

export default ToolTip;
