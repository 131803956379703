import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Icon,
  InputAdornment,
  ListItem,
  OutlinedInput,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import style from './subscriptions.module.scss';
import Text from '../../utils/text';
import Pager from '../utils/Pager';
import Query from '../../containers/Alerting/query';
import AlertingStore from '../../stores/recoil/alerting';
import Token from '../../utils/token';

const useStyles = makeStyles(theme => ({
  alertingDialog: {
    '& .filters': {
      display: 'flex',
      flexDirection: 'row',

      '& .search': {
        marginLeft: 'auto',
      },
    },
    '& .filters .MuiButton-root': {
      margin: `0 ${theme.spacing(1)}`,
    },
  },
}));

const Subscriptions = ({
  email,
  existing,
  filter,
  selected,
  onChange,
}) => {
  const classes = useStyles();
  const [data, setData] = useState(map());
  const [filterText, setFilterText] = useState('');
  const [fullRecipients, setFullRecipients] = useState(map());
  const [originalRecipientSubscriptions, setOriginalRecipientSubscriptions] = useState(list());
  const [popover, setPopover] = useState();
  const [subscriptions, setSubscriptions] = useState(list());
  const [subscriptionFilters, setSubscriptionFilters] = useState(map());
  const [subscriptionCategory, setSubscriptionCategory] = useState('');
  const [loading, setLoading] = useState(false);

  const setAlerts = useSetRecoilState(AlertingStore.alerts);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);
  const [keywords, setKeywords] = useRecoilState(AlertingStore.keywords);
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const prm = Token.get('prm');
  const recipients = useRecoilValue(AlertingStore.notificationProfiles);
  const type = useRecoilValue(AlertingStore.type);

  const onSave = (newSubscriptions) => {
    // if we are looking at a keyword, its original subscriptions were already determined
    // if we are looning at a recipient, its subscriptions get overwritten, so use the list
    // created when loading keywords
    const currentSubscriptions = (type === 'keywords')
      ? subscriptions
      : originalRecipientSubscriptions;
    const changedSubscriptions = newSubscriptions.map((v) => {
      const currentSub = currentSubscriptions.find(s => s.get('id') === v.get('id'));
      return v
        .set('hasAdded', !currentSub || (v.get('isSubscribed') !== currentSub.get('isSubscribed') && v.get('isSubscribed')))
        .set('hasDeleted', currentSub && v.get('isSubscribed') !== currentSub.get('isSubscribed') && !v.get('isSubscribed'))
        .set('hasChangedEmail', currentSub && v.get('receivesEmail') !== currentSub.get('receivesEmail'));
    })
      .filter(v => v.get('hasAdded') || v.get('hasDeleted') || v.get('hasChangedEmail'));
    if (type === 'keywords') {
      // update the keywordSubscriptions on the keyword
      const index = (keywords.get('data') || list()).findIndex(v => v.get('id') === selected.get('id'));
      const subscribed = newSubscriptions
        .filter(v => v.get('isSubscribed'))
        .map(v => map({
          endpoint: v.get('endpoint'),
          id: v.get('id'),
          isSubscribed: v.get('isSubscribed'),
          receivesEmail: v.get('receivesEmail'),
        }));
      onChange(changedSubscriptions, subscribed);
      const updated = keywords.setIn(['data', index, 'keywordSubscriptions'], subscribed);
      setKeywords(updated);
    } else {
      // update the keywordSubscriptions on the originalRecipientSubscriptions
      let updated = originalRecipientSubscriptions;
      changedSubscriptions.forEach((v) => {
        if (v.get('hasAdded')) {
          updated = updated.push(v.delete('hasAdded').delete('hasDeleted').delete('hasChangedEmail'));
        } else if (v.get('hasDeleted')) {
          const index = updated.find(s => s.get('id') === v.get('id'));
          updated = updated.delete(index);
        } else {
          const index = updated.find(s => s.get('id') === v.get('id'));
          updated = updated.setIn([index, 'receivesEmail'], v.get('receivesEmail'));
        }
      });
      setOriginalRecipientSubscriptions(updated);
      onChange(changedSubscriptions, updated);
    }
    if (selected.has('value') && selected.get('value').toLowerCase() === email.toLowerCase()) {
      setAlerts(map());
    }
  };

  const onEmail = (index) => {
    const updatedData = data.get('data');
    if (typeof index === 'string') {
      const updated = data.get('data').map(v => fromJS({
        ...v.toJS(),
        isSubscribed: index === 'all' ? true : v.get('isSubscribed'),
        receivesEmail: index === 'all',
      }));
      const union = _.unionBy(updated.toJS(), subscriptions.toJS(), 'id');
      setSubscriptions(fromJS(union));
      setData(data.setIn(['data'], updated));
      if (type === 'keywords') {
        // update fullRecipients for handling pagination
        setFullRecipients(fullRecipients.setIn(['data'], updated));
      }
      if (type === 'recipients') {
        Query.bulkSaveSubscriptions(selected.get('id'), ownerId, {
          status: index,
          category: (subscriptionFilters.get('category')) ? keywordClasses.find(v => v.get('name') === subscriptionFilters.get('category')) : null,
          receivesEmail: index === 'all',
          keywordText: filterText || '',
        });
      } else {
        onSave(fromJS(union));
      }
    } else {
      const row = updatedData.get(index);
      const receivesEmail = !row.get('receivesEmail');
      const updatedRow = row
        .set('isSubscribed', !receivesEmail ? row.get('isSubscribed') : true)
        .set('receivesEmail', receivesEmail);
      if (type === 'keywords') {
        // update fullRecipients for handling pagination
        setFullRecipients(fullRecipients.setIn(['data', index], updatedRow));
      }
      const existingSubscriptionIndex = subscriptions.findIndex(v => v.get('id') === row.get('id'));
      const updatedSubscriptions = subscriptions;
      let newSubscriptions;
      if (existingSubscriptionIndex !== -1) {
        newSubscriptions = updatedSubscriptions.set(existingSubscriptionIndex, updatedRow);
        setSubscriptions(newSubscriptions);
      } else {
        newSubscriptions = updatedSubscriptions.push(updatedRow);
        setSubscriptions(newSubscriptions);
      }
      setData(data.setIn(['data', index], updatedRow));
      // save
      onSave(newSubscriptions);
    }
  };

  const onSubscribe = (index) => {
    const updatedData = data.get('data');
    if (typeof index === 'string') {
      const updated = data.get('data').map(v => fromJS({
        ...v.toJS(),
        isSubscribed: index === 'all',
        receivesEmail: index === 'all' ? v.get('receivesEmail') : false,
      }));
      const union = _.unionBy(updated.toJS(), subscriptions.toJS(), 'id');
      setSubscriptions(fromJS(union));
      setData(data.setIn(['data'], updated));
      if (type === 'keywords') {
        // update fullRecipients for handling pagination
        setFullRecipients(fullRecipients.setIn(['data'], updated));
      }
      if (type === 'recipients') {
        Query.bulkSaveSubscriptions(selected.get('id'), ownerId, {
          status: index,
          category: (subscriptionFilters.get('category')) ? keywordClasses.find(v => v.get('name') === subscriptionFilters.get('category')) : null,
          keywordText: filterText || '',
        });
      } else {
        onSave(fromJS(union));
      }
    } else {
      const row = updatedData.get(index);
      const isSubscribed = !row.get('isSubscribed');
      const updatedRow = row
        .set('isSubscribed', isSubscribed)
        .set('receivesEmail', isSubscribed ? row.get('receivesEmail') : false);
      if (type === 'keywords') {
        // update fullRecipients for handling pagination
        setFullRecipients(fullRecipients.setIn(['data', index], updatedRow));
      }
      const existingSubscriptionIndex = subscriptions.findIndex(v => v.get('id') === row.get('id'));
      const updatedSubscriptions = subscriptions;
      let newSubscriptions;
      if (existingSubscriptionIndex !== -1) {
        newSubscriptions = updatedSubscriptions.set(existingSubscriptionIndex, updatedRow);
        setSubscriptions(newSubscriptions);
      } else {
        newSubscriptions = updatedSubscriptions.push(updatedRow);
        setSubscriptions(newSubscriptions);
      }
      setData(data.setIn(['data', index], updatedRow));
      // save
      onSave(newSubscriptions);
    }
  };

  const loadKeywords = async () => {
    const keyclass = (subscriptionFilters.get('category')) ? keywordClasses.find(v => v.get('name') === subscriptionFilters.get('category')) : null;
    const size = subscriptionFilters.get('limit', 25);
    const page = Math.floor(subscriptionFilters.get('skip', 0) / size) + 1;
    const text = (filterText !== '') ? filterText : null;
    setLoading(true);
    await Promise.all([
      Query.loadKeywords(
        ownerId,
        keywordClasses,
        {
          page_size: size,
          page_number: page,
          status: 'ACTIVE',
          keyclass: keyclass ? keyclass.get('id') : null,
          sort: 'keyclass_id',
          text,
        },
        null,
        fromJS(prm),
        true, // Filter keywords based on user permissions
      ),
    ]).then(([res]) => {
      let profileSubscriptions = list();
      const updatedData = res.keywords.get('data', list()).map((v) => {
        const selectedId = selected.get('id');
        const keywordSubscribedProfileIds = v.get('keyword_subscriptions', list()).map(s => s.get('notification_profile_id'));
        const receviesEmailProfileIds = v.get('keyword_subscriptions', list()).map(s => s.get('receives_email') && s.get('notification_profile_id'));
        const isSubscribed = keywordSubscribedProfileIds.includes(selectedId);
        const receivesEmail = receviesEmailProfileIds.includes(selectedId);
        const keyword = v
          .set('keyclass', v.getIn(['keyclass', 'name']))
          .set('isSubscribed', isSubscribed)
          .set('receivesEmail', receivesEmail);
        if (keyword.get('isSubscribed')) profileSubscriptions = profileSubscriptions.push(keyword);
        return keyword;
      });
      // Catching case where text filter has been applied, but page is now out of limit
      // resulting in a page > 1 but an empty result. In this case, reload with skip=0
      if (updatedData.size === 0 && subscriptionFilters.get('skip', 0) > 0) {
        setSubscriptionFilters(subscriptionFilters.set('skip', 0));
      }
      setData(res.keywords.set('data', updatedData));
      // merge with existing subscriptions for current list of all selected keywords
      let union = _.unionBy(subscriptions.toJS(), profileSubscriptions.toJS(), 'id');
      setSubscriptions(fromJS(union));
      // merge with original list of recipient subscriptions to keep track of subscription changes
      union = _.unionBy(originalRecipientSubscriptions.toJS(), profileSubscriptions.toJS(), 'id');
      setOriginalRecipientSubscriptions(fromJS(union));
    })
      .finally(() => setLoading(false));
  };

  const filterKeywords = useCallback(
    _.debounce(() => {
      loadKeywords();
    }, 300),
    [filterText],
  );

  useEffect(() => {
    if (['recipients'].includes(type) && (!filterText || filterText.length > 2)) {
      filterKeywords();
    }
  }, [filterText]);

  useEffect(() => {
    if (type === 'keywords') {
      // looking at a single keyword, assign recipients to data and provide keyword subscriptions
      const subscribedIds = selected.get('keywordSubscriptions', list()).map(v => v.get('isSubscribed') && v.get('id'));
      const receivesEmailIds = selected.get('keywordSubscriptions', list()).map(v => v.get('receivesEmail') && v.get('id'));
      const existingSubscribedIds = existing ? existing.map(v => v.get('isSubscribed') && v.get('id')) : list();
      const existingReceivesEmailIds = existing ? existing.map(v => v.get('receivesEmail') && v.get('id')) : list();
      const updatedData = recipients.get('data').map((v) => {
        const id = v.get('id');
        return v
          .set('isSubscribed', subscribedIds.includes(id) || existingSubscribedIds.includes(id))
          .set('receivesEmail', receivesEmailIds.includes(id) || existingReceivesEmailIds.includes(id));
      });
      const updated = recipients.set('data', updatedData);
      setFullRecipients(updated);
      const limit = subscriptionFilters.get('limit') || '50';
      const skip = subscriptionFilters.get('skip') || '0';
      const paged = updated.set('data', updated.get('data')
        .slice(parseInt(skip, 10), parseInt(skip, 10) + parseInt(limit, 10)));
      if (filter) {
        setData(paged);
      } else {
        setData(updated);
      }
      setSubscriptions(existing || selected.get('keywordSubscriptions', list()));
    } else {
      // looking at a single notification profile, get the page of keywords and
      // provide subscriptions
      loadKeywords();
    }
  }, [selected]);

  useEffect(() => {
    if (subscriptionFilters.isEmpty()) return;
    if (type === 'keywords') {
      // we have loaded all recipients already, so manually determine how much to display
      const limit = subscriptionFilters.get('limit') || '50';
      const skip = subscriptionFilters.get('skip') || '0';
      const paged = fullRecipients.set('data', fullRecipients.get('data')
        .slice(parseInt(skip, 10), parseInt(skip, 10) + parseInt(limit, 10)));
      setData(paged);
    } else {
      loadKeywords();
    }
  }, [subscriptionFilters]);

  const doneLoadingZeroResults = !loading && (data?.isEmpty() || data?.get('count') === 0);
  const noResultsDiv = <div style={{ margin: '8px' }}>No subscriptions to manage</div>;

  return (
    <div className={cx([style.subscriptions, classes.subscriptions])}>
      {filter &&
      <div className={style.table}>
        <div className={style.label}>{type === 'keywords' ? 'Keyword:' : 'Recipient:'}</div>
        <pre>{selected.get('value')}</pre>
      </div>}
      <div className={style.selection}>
        {filter &&
        <div className="filters">
          {['recipients'].includes(type) &&
          <React.Fragment>
            <Button
              color="primary"
              variant="contained"
              endIcon={<Icon>keyboard_arrow_down</Icon>}
              onClick={event => setPopover({ key: 'recipient.filter', target: event.target })}>
              {subscriptionCategory || 'Filter'}
            </Button>
            <Popover
              anchorEl={popover && popover.target}
              open={Boolean(popover && popover.key === 'recipient.filter')}
              onClose={() => setPopover()}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <ListItem
                value="ALL"
                className={cx([style.item, subscriptionCategory === 'ALL' && style.active])}
                onClick={() => {
                  setPopover();
                  setSubscriptionFilters(subscriptionFilters.set('category', ''));
                  setSubscriptionCategory('ALL');
                }}>
                All
              </ListItem>
              {keywordClasses
                .filter((v) => {
                  /* Filter which classes user sees based on permissions */
                  if (/twitter/ig.test(v.get('name')) && !prm.some(p => /twtr/.test(p))) return false;
                  if (/bin/ig.test(v.get('name')) && !prm.some(p => /csb/.test(p))) return false;
                  if (/internet_infrastructure|cloud_infrastructure/ig.test(v.get('name')) && !prm.some(p => /dm/.test(p))) return false;
                  if (/enterprise_domain|email_domain/ig.test(v.get('name')) && !prm.some(p => /edm/.test(p))) return false;
                  if (/cookie_domain/ig.test(v.get('name'))) return false;
                  if (/enterprise_affected_domain/ig.test(v.get('name'))) return false;
                  return true;
                })
                .sortBy(v => v.get('name'))
                .map(v => (
                  <ListItem
                    key={v.get('name')}
                    value={v.get('name')}
                    className={cx([
                      style.item,
                      subscriptionCategory === v.get('name') && style.active,
                    ])}
                    onClick={() => {
                      setPopover();
                      setSubscriptionFilters(subscriptionFilters.set('category', v.get('name')));
                      setSubscriptionCategory(v.get('name'));
                    }}>
                    {Text.Sentence(v.get('name'))}
                  </ListItem>))}
            </Popover>
          </React.Fragment>}
          <Pager
            limit
            paginated
            filters={subscriptionFilters}
            onFilter={setSubscriptionFilters}
            data={fromJS({ total: data.get('total', 0) })}
            limits={fromJS([
              { value: 10, label: '10' },
              { value: 25, label: '25' },
              { value: 50, label: '50' },
              { value: 75, label: '75' },
              { value: 100, label: '100' },
            ])} />
          {['recipients'].includes(type) && data.has('total') &&
          <div className="search">
            <FormControl
              fullWidth={false}
              variant="outlined">
              <OutlinedInput
                data-lpignore="true"
                name="text"
                value={filterText}
                placeholder="Quick Filter"
                onChange={event => setFilterText(event.target.value.replace(String.fromCharCode(92), ''))}
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon>
                      search
                    </Icon>
                  </InputAdornment>)}
                endAdornment={(
                  <InputAdornment position="end">
                    <Icon
                      color="primary"
                      onClick={() => setFilterText('')}>
                      close
                    </Icon>
                  </InputAdornment>
                )} />
            </FormControl>
          </div>}
        </div>}
        {doneLoadingZeroResults ? noResultsDiv : (data &&
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['keywords'].includes(type) &&
                <TableCell
                  key="recipient">
                  <div>Recipient</div>
                </TableCell>}
                {['recipients'].includes(type) &&
                <React.Fragment>
                  <TableCell
                    key="curated"
                    style={{ width: '75px' }}>
                    <div>Curated</div>
                  </TableCell>
                  <TableCell
                    key="category"
                    style={{ width: '125px' }}>
                    <div>Category</div>
                  </TableCell>
                  <TableCell
                    key="name"
                    style={{ width: '200px' }}>
                    <div>Name</div>
                  </TableCell>
                  <TableCell
                    key="keyword">
                    <div>Keyword</div>
                  </TableCell>
                </React.Fragment>}
                <TableCell
                  key="automated"
                  style={{ width: '200px' }}
                  className={style['header-column']}>
                  <React.Fragment>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<Icon>keyboard_arrow_down</Icon>}
                      onClick={event => setPopover({ key: 'automated.selection', target: event.target })}>
                      Show hits in platform
                    </Button>
                    <Popover
                      anchorEl={popover && popover.target}
                      open={Boolean(popover && popover.key === 'automated.selection')}
                      onClose={() => setPopover()}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                      <ListItem
                        value="all"
                        className={cx([style.item])}
                        onClick={() => {
                        setPopover();
                        onSubscribe('all');
                      }}>
                        {`Select all (${data.get('total', 0)})`}
                      </ListItem>
                      <ListItem
                        value="none"
                        className={cx([style.item])}
                        onClick={() => {
                        setPopover();
                        onSubscribe('none');
                      }}>
                        Unselect All
                      </ListItem>
                    </Popover>
                  </React.Fragment>
                </TableCell>
                {['keywords', 'recipients'].includes(type) &&
                <TableCell
                  key="email"
                  style={{ width: '210px' }}
                  className={style['header-column']}>
                  <React.Fragment>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<Icon>keyboard_arrow_down</Icon>}
                      onClick={event => setPopover({ key: 'email.selection', target: event.target })}>
                      Receive hits via email
                    </Button>
                    <Popover
                      anchorEl={popover && popover.target}
                      open={Boolean(popover && popover.key === 'email.selection')}
                      onClose={() => setPopover()}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                      <ListItem
                        value="all"
                        className={cx([style.item])}
                        onClick={() => {
                        setPopover();
                        onEmail('all');
                      }}>
                        {`Select all (${data.get('total', 0)})`}
                      </ListItem>
                      <ListItem
                        value="none"
                        className={cx([style.item])}
                        onClick={() => {
                        setPopover();
                        onEmail('none');
                      }}>
                        Unselect All
                      </ListItem>
                    </Popover>
                  </React.Fragment>
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.get('data', list()))
                .map((row, rowIndex) => (
                  <TableRow
                    hover
                    key={row.get('id')}>
                    {['keywords'].includes(type) &&
                    <TableCell
                      key="recipient">
                      <div
                        role="link"
                        tabIndex={0}
                        onKeyUp={() => null}
                        onClick={() => onSubscribe(rowIndex)}>
                        {row.get('endpoint')}
                      </div>
                    </TableCell>}
                    {['recipients'].includes(type) &&
                    [{ key: 'is_curated', width: '75px' }, { key: 'keyclass', width: '125px' }, { key: 'name', width: '200px' }, { key: 'value' }].map(v => (
                      <TableCell
                        key={v.key}
                        style={{ width: v.width }}>
                        <div
                          role="link"
                          tabIndex={0}
                          onKeyUp={() => null}
                          onClick={() => onSubscribe(rowIndex)}>
                          {v.key === 'is_curated' ? `${row.get(v.key) ? 'Y' : 'N'}` : row.get(v.key)}
                        </div>
                      </TableCell>
                    ))}
                    {['keywords', 'recipients'].includes(type) &&
                    <TableCell
                      key="automated"
                      style={{ width: '210px', textAlign: 'center' }}>
                      <Checkbox
                        checked={row.get('isSubscribed')}
                        onChange={() => onSubscribe(rowIndex)} />
                    </TableCell>}
                    {['keywords', 'recipients'].includes(type) &&
                    <TableCell
                      key="email"
                      style={{ width: '210px', textAlign: 'center' }}>
                      <Checkbox
                        checked={row.get('receivesEmail')}
                        onChange={() => onEmail(rowIndex)} />
                    </TableCell>}
                  </TableRow>))}
              {data.get('data', list()).count() === 0 &&
              <TableRow
                hover
                key="none">
                {!selected.has('keywordSubscriptions') &&
                <TableCell
                  key="nocolumn"
                  style={{ width: '100%' }}>
                  <CircularProgress />
                </TableCell>}
                {selected.has('keywordSubscriptions') &&
                <TableCell
                  key="nocolumn"
                  style={{ width: '100%' }}>
                  No {type === 'keywords' ? 'recipients' : 'keywords'} to subscribe
                </TableCell>}
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>)}
      </div>
    </div>
  );
};

Subscriptions.propTypes = {
  email: PropTypes.string,
  existing: PropTypes.object,
  filter: PropTypes.bool,
  selected: PropTypes.object,
  onChange: PropTypes.func,
};

Subscriptions.defaultProps = {
  email: '',
  existing: null,
  filter: true,
  selected: map(),
  onChange: null,
};

export default Subscriptions;
