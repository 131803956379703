import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import style from './taxii.module.scss';
import Query from './query';
import Messages from '../../../constants/Messages';
import Invalid from '../../utils/Invalid/Invalid';
import Prompt from '../../utils/Prompt';
import SearchActions from '../../../actions/searchActions';
import { UserContext } from '../../utils/Context';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .MuiFormControlLabel-label': {
      textTransform: 'none',
    },
    '& .Mui-disabled': {
      color: 'inherit !important',
    },
  },
}));

const Taxii = () => {
  const classes = useStyles();

  const [status, setStatus] = useState();
  const [credential, setCredential] = useState();
  const [credentials, setCredentials] = useState();
  const [values, setValues] = useState();
  const [dialog, setDialog] = useState();

  const user = useContext(UserContext);
  const prm = user.get('prm');
  const apps = user.get('apps');

  const onCopy = (k, v) => {
    navigator.clipboard.writeText(v || '');
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard(k));
  };

  const onLoad = () => {
    Query.LoadCredentials()
      .then(res => setCredentials(res))
      .catch(err => SearchActions.set(['search', 'info', 'message'], err));
  };

  const onGenerate = () => {
    if (!values.push) {
      setStatus();
    }
    Query.GenerateCredential(values)
      .then((res) => {
        if (res.status === 202) {
          /* Push MFA 1. Decode the token and check for push MFA */
          const value = res?.data?.token.split('|')[0];
          const decoded = JSON.parse(atob(value));
          if (decoded?.type === 'push') {
            /*
             * Push MFA 2. If it is push MFA, hide the OTP field and enter a fake
             * value for the OTP
             */
            setStatus('MFA enabled. Push sent.');
            setValues(vals => ({
              ...vals,
              totp: res.data.token,
              push: true,
              otp: 1234567890,
            }));
          } else {
            setStatus('MFA enabled. Please submit OTP token');
            setValues(vals => ({
              ...vals,
              totp: res.data.token,
            }));
          }
        }
        else {
          const usr = res?.data?.basic_auth_username;
          const pwd = res?.data?.basic_auth_password;
          const auth = Buffer.from(`${usr}:${pwd}`).toString('base64');
          const basic_auth_header = `Authorization: Basic ${auth}`;
          const cred = { ...res.data, basic_auth_header, created_by_user: values?.username };
          setCredentials(creds => creds.concat(cred));
          setCredential(cred);
          setValues({
            ...values,
            push: false,
          });
        }
      })
      .catch((err) => {
        setValues({
          ...values,
          credential: undefined,
          push: false,
          password: undefined,
          totp: undefined,
          otp: undefined,
        });
        if (/timeout/ig.test(err.message)) {
          setDialog();
          SearchActions.set(['search', 'info', 'message'], Messages.MfaTimeout);
          return;
        }
        setStatus(err?.response?.data?.detail || err?.response?.data?.errorSummary);
      });
  };

  const onReset = () => {
    setValues({
      ...values,
      credential_name: undefined,
      credential: undefined,
      push: false,
      username: undefined,
      password: undefined,
      totp: undefined,
      otp: undefined,
    });
    setCredential();
    setStatus();
  };

  const onRevoke = ({ credential_name }) => {
    Query.RevokeCredential({ credential_name })
      .then(() => {
        setCredentials(credentials.filter(v => v?.credential_name !== credential_name));
        SearchActions.set(['search', 'info', 'message'], Messages.CredentialsRevoked);
      })
      .catch((err) => {
        setStatus(err.response.data.detail);
      })
      .finally(() => setDialog());
  };

  const columns = [
    { id: 'created_by_user',
      label: 'Created By',
      text: 'Name of user who generated credential',
      style: { width: '75px' },
      tooltip: v => Text.StripHtml(v)
        .match(/(.{1,65})(?:\n|$| )/g) || []
        .join('<br />'),
      render: v => v?.created_by_user },
    { id: 'credential_name',
      label: 'Name',
      text: 'Name of generated credential',
      style: { width: '175px' },
      tooltip: v => Text.StripHtml(v)
        .match(/(.{1,65})(?:\n|$| )/g) || []
        .join('<br />'),
      render: v => v?.credential_name },
    { id: 'credential.revoke',
      label: 'Revoke Credential',
      revoke: true,
      text: 'Revoke Credential',
      style: { textAlign: 'right', width: '45px' },
      render: (v, handleClick) => (
        <Icon onClick={() => handleClick(v)}>clear</Icon>) },
  ];

  /* Push MFA 3. If a Push MFA is in progress, send the data to the API */
  useEffect(() => {
    if (values?.push) {
      onGenerate();
    }
  }, [values]);

  useEffect(() => {
    onReset();
    onLoad();
  }, []);

  return (
    <Grid
      fluid
      name="component.taxii"
      className={cx([
        style.base,
        style.taxii,
        classes.taxii,
      ])}>
      {prm.some(p => /taxii-endpoints/.test(p)) &&
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.body}>
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Generate TAXII Credentials</div>
              <div>Generate a login and password for accessing TAXII:</div>
              <div className={style.api}>
                {apps
                  .filter(v => v.get('taxii'))
                  .map(v => (
                    <div key={v.get('value')}>
                      {prm.some(p => v.get('test').test(p)) &&
                        <Icon>
                          check
                        </Icon>}
                      {!prm.some(p => v.get('test').test(p)) &&
                        <Icon
                          data-for="taxii.tooltip"
                          data-tip="For access to this dataset, please reach out to the customer success team via your team's dedicated support address.">
                          help
                        </Icon>}
                      {v.get('label')}
                    </div>))}
              </div>
              <div className={style.input}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
                  onClick={() => {
                    onReset();
                    setDialog({ key: 'credential' });
                  }}>
                  Generate Credentials
                </Button>
                <Dialog
                  className={classes.dialog}
                  open={Boolean(dialog && dialog.key === 'credential' && !credential)}
                  onClose={() => setDialog()}>
                  <DialogTitle>Generate Credentials</DialogTitle>
                  <DialogContent>
                    <FormControl variant="outlined">
                      <InputLabel>Credential Label</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="Credential Label"
                        value={values?.credential_name || ''}
                        onChange={(event) => {
                          const credential_name = event.target.value;
                          setValues(vals => ({ ...vals, credential_name }));
                        }} />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel>FP.Tools Username</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="FP.Tools Username"
                        id="username"
                        value={values?.username || ''}
                        onChange={(event) => {
                          const username = event.target.value;
                          setValues(vals => ({ ...vals, username }));
                        }} />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel>FP.Tools Password</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="FP.Tools Password"
                        id="password"
                        type="password"
                        value={values?.password || ''}
                        onChange={(event) => {
                          const password = event.target.value;
                          setValues(vals => ({ ...vals, password }));
                        }} />
                    </FormControl>
                    {values?.totp && !values?.push &&
                    <FormControl variant="outlined">
                      <InputLabel>OTP Token</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        placeholder="OTP Token"
                        value={values?.otp || ''}
                        onChange={(event) => {
                          const otp = event.target.value;
                          setValues(vals => ({ ...vals, otp }));
                        }} />
                    </FormControl>}
                  </DialogContent>
                  <DialogActions>
                    <div>{status}</div>
                    <Button
                      onClick={() => {
                        setValues();
                        setStatus();
                        setDialog();
                      }}>
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => onGenerate()}>
                      Generate
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {credential &&
              <div>
                <div>Warning - Save your credentials now! After leaving this page you
                  will no longer be able to retrieve them.
                </div>
                <div className={style.token}>
                  <b>Credential Name:</b> {credential?.credential_name}<br />
                  <b>Basic Auth Username:</b> {credential?.basic_auth_username}
                  <Icon className={style.icon} onClick={() => onCopy('Username', credential?.basic_auth_username)}>content_copy</Icon><br />
                  <b>Basic Auth Password:</b> {credential?.basic_auth_password}
                  <Icon className={style.icon} onClick={() => onCopy('Password', credential?.basic_auth_password)}>content_copy</Icon>
                </div>
              </div>}
            </div>
          </Paper>
        </Col>
      </Row>}
      {prm.some(p => /taxii-endpoints/.test(p)) &&
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.body}>
              {credentials?.length > 0 &&
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Existing Credentials</div>}
              {credentials?.length === 0 &&
                <Invalid
                  icon=""
                  inline
                  title="No credentials have been generated"
                  help={['Click "Generate Credentials" above']} />}
              {credentials?.length > 0 &&
              <TableContainer>
                <Table size="small" className={style.apitoken}>
                  <TableHead>
                    <TableRow>
                      {columns.map(col => (
                        <TableCell
                          key={col.id}
                          className={col.className}
                          style={col.style}>
                          {col.text &&
                            <div
                              data-for="header.tooltip"
                              data-tip={col.text}
                              className={cx([col.sort ? style.sort : null])}
                              name={`table.header.${col.label}`}>{col.label}
                            </div>}
                          {!col.text && <div>{col.label}</div>}
                        </TableCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {credentials.map(row => (
                      <TableRow
                        name={`table.item.${row?.credential_name}`}
                        key={row}
                        selected={false}>
                        {columns.map(col => (
                          <TableCell
                            key={col.id}
                            align={col.align}
                            name={`table.item.${row?.credential_name}.${col.id}`}
                            style={col.style}>
                            {col.render(row, (col.revoke ? () => setDialog({ key: 'revoke', target: row }) : null))}
                          </TableCell>))}
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>}
            </div>
          </Paper>
          <Prompt
            open={Boolean(dialog?.key === 'revoke')}
            title="Warning: Delete Credential?"
            acceptText="Continue"
            accept={() => onRevoke(dialog?.target)}
            cancelText="Cancel"
            cancel={() => setDialog()}>
            You are about to permanently delete this credential. Are you sure?
          </Prompt>
        </Col>
      </Row>}
      <ReactTooltip id="taxii.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="header.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

export default Taxii;
