import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  CircularProgress,
  Tab as TabUI,
} from '@mui/material';

import style from './tab.module.scss';
import Text from '../../../utils/text';

const useStyles = makeStyles({
  tab: {
    '& .MuiTabPanel-root': {
      padding: 0,
      '& .recharts-wrapper': {
        marginTop: '-3.5rem',
      },
    },
  },
});

const Tab = ({
  children,
  cells,
  color,
  config,
  filters,
  header,
  index,
  labels,
  limit,
  pagination,
  tabs,
  legend,
  titles,
  results,
  scale,
  values,
  widget,
  onClick,
  onOffset,
  onHover,
}) => {
  const classes = useStyles();
  const [tab, setTab] = useState();

  const onToggle = (value) => {
    setTab(value);
    onHover({ tab: tabs.valueSeq().getIn([value, 0]) });
  };

  useEffect(() => {
    if (results.isEmpty()) return;
    const selection = results
      .keySeq()
      .first();
    onToggle(selection);
  }, [results]);

  return (
    <div
      className={cx([
        style.tab,
        classes.tab,
      ])}>
      {!tab &&
      <CircularProgress />}
      {tab &&
      <TabContext value={tab}>
        <TabList
          variant="fullWidth"
          indicatorColor="secondary"
          onChange={(event, val) => setTab(val)}
          className={style.tabs}>
          {results
            .entrySeq()
            .filter(([aid]) => !filters.get('type') || aid.includes(filters.get('type')))
            .map(([aid]) => (
              <TabUI
                key={tabs.getIn([aid, 0], aid)}
                value={tabs.getIn([aid, 0], aid)}
                label={Text.Sentence(tabs.getIn([aid, 0], aid))} />))
            .toArray()}
        </TabList>
        {results
            .entrySeq()
            .filter(([aid]) => !filters.get('type') || aid.includes(filters.get('type')))
            .map(([aid]) => (
              <TabPanel
                key={aid}
                value={aid}>
                <div className={style.body}>
                  {React.cloneElement(children, {
                    aid,
                    color,
                    header,
                    index,
                    legend,
                    pagination,
                    widget,
                    config,
                    scale,
                    limit,
                    cells: cells.get(aid),
                    labels: labels.get(aid),
                    titles: titles.get(aid),
                    values: values.get(aid),
                    results: results.get(aid),
                    onBlur: () => onToggle(tab),
                    onOffset: skip => (onOffset
                      ? onOffset(tabs.getIn([aid, 0]) || aid, skip)
                      : false),
                    onHover: v => onHover({ tab: tabs.valueSeq().getIn([tab, 0]), ...v.toJS() }),
                    onClick: (v, k, rest) => onClick.get(aid)(v, k, rest) })}
                </div>
              </TabPanel>))}
      </TabContext>}
    </div>
  );
};

Tab.propTypes = {
  cells: PropTypes.object,
  children: PropTypes.object,
  color: PropTypes.string,
  config: PropTypes.object,
  filters: PropTypes.object,
  header: PropTypes.bool,
  index: PropTypes.number,
  labels: PropTypes.object,
  legend: PropTypes.bool,
  limit: PropTypes.number,
  pagination: PropTypes.bool,
  tabs: PropTypes.object,
  results: PropTypes.object,
  scale: PropTypes.string,
  titles: PropTypes.object,
  values: PropTypes.object,
  widget: PropTypes.object,
  onClick: PropTypes.object,
  onOffset: PropTypes.func,
  onHover: PropTypes.func,
};

Tab.defaultProps = {
  cells: map(),
  children: null,
  color: '',
  config: map(),
  filters: list(),
  header: false,
  index: 0,
  labels: map(),
  legend: false,
  limit: -1,
  pagination: true,
  tabs: map(),
  results: map(),
  scale: '',
  titles: map(),
  values: map(),
  widget: map(),
  onClick: map(),
  onOffset: null,
  onHover: () => null,
};

export default Tab;
