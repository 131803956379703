import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  InputAdornment,
  InputLabel,
  Link,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Switch,
  TextField,
  Tooltip,
 Autocomplete } from '@mui/material';

import style from './editor.module.scss';
import History from '../../utils/history';
import Text from '../../utils/text';
import NestedCheckbox from '../utils/NestedCheckbox';
import EditableChip from '../utils/EditableChip';
import CheckboxButton from './CheckboxButton';

const useStyles = makeStyles(theme => ({
  editor: {
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
      },
    },
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .checkboxes .MuiFormControl-root': {
      margin: 0,
    },
  },
  inlineField: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: '.8rem 0',

    '& > div': {
      display: 'flex !important',
      flexWrap: 'nowrap !important',
      padding: '0 1rem 0 0 !important',
    },
  },
}));

const Editor = ({
  canEdit,
  data,
  deleteActions,
  extraActions,
  fields,
  onEdit,
  save,
  saveLabel,
  toggle,
  type,
}) => {
  const classes = useStyles();
  const [errorCount, setErrorCount] = useState(0);
  const [errorText, setErrorText] = useState(map());
  const [changed, setChanged] = useState([]);
  const [dialog, setDialog] = useState();
  const [values, setValues] = useState(list());

  const chipInput = useRef(null);
  const multiple = data.size > 1;

  const replaceSmartChars = (value) => {
    if (typeof value === 'string') {
      return Text.ReplaceSmartChars(value);
    } else if (list.isList(value)) {
      return value.map(v => (typeof v === 'string' ? Text.ReplaceSmartChars(v) : v));
    }
    return value;
  };

  const onChange = (k, v) => {
    const dataValues = values.map(value => value.setIn(k, replaceSmartChars(v)));
    setChanged(changed.concat(k.join('.')));
    setValues(dataValues);
    let updatedErrorText = errorText;
    // clear any error message on change
    updatedErrorText = updatedErrorText.set(k.join('.'), null);
    setErrorText(updatedErrorText);
    if (onEdit) onEdit(dataValues, k.join('.'));
  };

  const onChangeMultiple = (k, e) => {
    const { value } = e.target;
    const dataValues = values.map(v => v.setIn(k, value));
    setChanged(changed.concat(k.join('.')));
    setValues(dataValues);
    let updatedErrorText = errorText;
    // clear any error message on change
    updatedErrorText = updatedErrorText.set(k.join('.'), null);
    setErrorText(updatedErrorText);
    if (onEdit) onEdit(dataValues, k.join('.'));
  };

  const multiSelectValue = (field) => {
    if (!field.bulk && multiple) return [];
    if (list.isList(values.getIn([0, ...field.value.split('.')]))) {
      return values.getIn([0, ...field.value.split('.')]).toJS();
    }
    return values.getIn([0, ...field.value.split('.')], []);
  };

  const onCheck = (k, value, checked, parent) => {
    const dataValues = values.updateIn(k, list(), (v) => {
      if (checked) {
        return (!list.isList(value)) ? v.push(value) : v.concat(value);
      }
      let updated = v;
      if (!list.isList(value)) {
        updated = updated.delete(updated.indexOf(value));
      } else {
        value.forEach((val) => {
          updated = updated.delete(updated.indexOf(val));
        });
      }
      if (parent) updated = updated.delete(updated.indexOf(parent));
      return updated;
    });
    setChanged(changed.concat(k.join('.')));
    setValues(dataValues);
    let updatedErrorText = errorText;
    // clear any error message on change
    updatedErrorText = updatedErrorText.set(k.join('.'), null);
    setErrorText(updatedErrorText);
    if (onEdit) onEdit(dataValues, k.join('.'));
  };

  const onDelete = () => {
    const { pathname, query } = History.getCurrentLocation();
    History.push({ pathname, query, hash: null });
    deleteActions(type);
    toggle();
  };

  const onGroupChange = (k, groupState) => {
    const dataValues = values.updateIn(k, list(), (v) => {
      let updated = v;
      groupState.forEach((gv, gk) => {
        if (gv && !updated.includes(gk)) updated = updated.push(gk);
        else if (!gv && updated.includes(gk)) updated = updated.delete(updated.indexOf(gk));
      });
      return updated;
    });
    setChanged(changed.concat(k.join('.')));
    setValues(dataValues);
    let updatedErrorText = errorText;
    // clear any error message on change
    updatedErrorText = updatedErrorText.set(k.join('.'), null);
    setErrorText(updatedErrorText);
    if (onEdit) onEdit(dataValues, k.join('.'));
  };

  const checkboxSelect = (field, checked) => {
    let groupState = map();
    field.options.forEach((v) => {
      if (!v.has('children')) {
        const id = v.get(field.valueKey);
        if (typeof id === 'string') groupState = groupState.set(id, checked);
        else {
          id.forEach((i) => {
            groupState = groupState.set(i, checked);
          });
        }
      } else {
        const parentId = v.get('id');
        const childrenIds = v.get('children').map(c => c.get('id'));
        childrenIds.forEach((c) => {
          groupState = groupState.set(c, checked);
        });
        if (typeof parentId === 'string') {
          groupState = groupState.set(parentId, checked);
        }
      }
    });
    onGroupChange([0, ...field.value.split('.')], groupState);
    let updatedErrorText = errorText;
    // clear any error message on change
    updatedErrorText = updatedErrorText.set(field.value, null);
    setErrorText(updatedErrorText);
  };

  const onSave = () => {
    const inlineFields = fields
      .filter(v => v.type.includes('inline'))
      .map(v => v.fields)
      .flat();
    const toggleWithDefaults = fields.concat(inlineFields)
      .filter(v => v.type === 'toggle' && v.default != null)
      .map(v => v.value);
    let change = values
      .map(v => v.filter((_, k) => {
        // eslint-disable-next-line security/detect-non-literal-regexp
        const hasChanged = changed.some(c => new RegExp(k || '', 'gi').test(c));
        if (hasChanged) return true;
        const field = fields.concat(inlineFields).find(f => f.value === k);
        // If the field hasn't been changed but has a default value for bulk
        // editing, save the defaults
        return (field && field.bulk && multiple && field.defaults);
      }));
    // Handle toggles with default cases that havent been changed
    toggleWithDefaults.forEach((k) => {
      // if the toggle was the same for each and wasn't changed, set it to be
      // the value of the original state instead of default
      const allSame = !data.isEmpty()
        && data
          .every(v => v.getIn([...k.split('.')]) === !!data.getIn([0, ...k.split('.')]));
      change = change
        .map(v => ((v.getIn([...k.split('.')]) == null) && !allSame
          ? v.setIn([...k.split('.')], fields.concat(inlineFields).find(f => f.value === k).default)
          : v));
    });
    const updates = data.isEmpty()
      ? values.map((v, k) => v.merge(change.get(k)))
      : data.map((v, k) => v.merge(change.get(k)));

    let updatedErrorText = map();

    fields.concat(inlineFields).forEach((field) => {
      let errors = [];
      if (field.validate) {
        errors = field.validate(field, values);
      } else {
        // Validate emails
        if (field.type === 'email') {
          const domains = field.allowed_domains;
          const value = values.getIn([0, field.value]);
          // This is OWASP's safe email regex
          // eslint-disable-next-line security/detect-unsafe-regex
          const isValidEmail = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i.test(value);
          if (!isValidEmail) errors.push('Invalid Email Address');
          if (domains && isValidEmail) {
            /* eslint-disable-next-line no-useless-escape */
            const regex = `^(([^<>()\\[\\]\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\.,;:\\s@"]+)*)|(".+"))@(${domains.join('|') || ''})$`;
            // eslint-disable-next-line security/detect-non-literal-regexp
            const regexp = new RegExp(regex, 'i');
            const isValidDomain = regexp.test(value);
            if (!isValidDomain) errors.push({ field: field.value, error: `Invalid Email Domain: [${domains.join(', ')}]` });
          }
        }
        if (field.balancedString && ((multiple && field.bulk) || !multiple)) {
          const misbalanced = Text.BalancedString(values.getIn([0, field.value])) !== true;
          if (misbalanced) errors.push({ field: field.value, error: 'Malformed string' });
        }
      }
      if (field.req && ((multiple && field.bulk) || !multiple)) {
        const missingValue = !values.getIn([0, field.value]) || (typeof values.getIn([0, field.value]) === 'string' && values.getIn([0, field.value]).trim() === '');
        if (missingValue) errors.push({ field: field.value, error: 'Field is required' });
      }
      errors.forEach((v) => {
        const errorList = updatedErrorText.get(v.field, []);
        errorList.push(v.error);
        updatedErrorText = updatedErrorText.set(v.field, [...new Set(errorList)]);
      });
    });

    const numErrors = updatedErrorText.filter(v => v && v.length > 0).size;
    if (numErrors === 0) {
      save(type, updates);
    } else {
      setErrorText(updatedErrorText);
      setErrorCount(numErrors);
    }
  };

  const generateInlineField = (field) => {
    if (!field.hidden || !field.hidden(values.get(0) || map())) {
      // eslint-disable-next-line no-use-before-define
      let inlineFields = field.fields.map(v => generateField(v));
      // remove xs={12} on these fields
      inlineFields = inlineFields.map((v, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { props: { xs, ...restProps }, ...rest } = v;
        return {
          ...rest,
          key: field.fields[Number(i)].value,
          props: restProps,
        };
      });
      return (
        <Col xs={12} className={classes.inlineField}>
          {field.label &&
          <div
            className={cx([style.label])}>
            {field.label}
            {field.icon &&
            <Icon
              data-for="icon.tooltip"
              data-tip={field.iconText}>
              {field.icon}
            </Icon>}
          </div>}
          {inlineFields}
        </Col>);
    }
    return '';
  };

  const generateField = (field) => {
    if (field.type.includes('inline')) return generateInlineField(field);
    switch (field.type) {
      case 'text':
      case 'textarea':
      case 'email':
        return (
          <Col xs={12}>
            <FormControl variant="outlined" name={field.value} error={errorText.get(field.value) && errorText.get(field.value).length > 0}>
              <InputLabel>{field.label}</InputLabel>
              <OutlinedInput
                readOnly={field.readOnly}
                maxRows={field.type === 'textarea' ? 7 : 1}
                disabled={field.readOnly || (!field.bulk && multiple)}
                multiline={field.type === 'textarea'}
                type={field.type === 'textarea' ? 'textarea' : 'text'}
                placeholder={field.placeholder}
                inputProps={{ 'data-testid': `editor.${field?.value}`, maxLength: field.length }}
                value={!field.bulk && multiple ? 'Multiple Values' :
                  values.getIn([0, ...field.value.split('.')]) || ''}
                onChange={event => onChange(field.value.split('.'), event.target.value)}
                startAdornment={field.icon && field.iconText && (
                  <InputAdornment position="start">
                    <Icon
                      data-for="icon.tooltip"
                      data-tip={field.iconText}>
                      {field.icon}
                    </Icon>
                  </InputAdornment>)}
                endAdornment={values.getIn([0, ...field.value.split('.')]) && field.bulk && (
                  <InputAdornment position="end">
                    <Icon
                      color="primary"
                      onClick={() => onChange(field.value.split('.'), '')}>
                      close
                    </Icon>
                  </InputAdornment>
                )} />
              {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
              <FormHelperText>
                {errorText.get(field.value).map(e => (
                  <li key={e}>{e}</li>
                ))}
              </FormHelperText>}
            </FormControl>
          </Col>
        );
      case 'dropdown':
        return (
          <Col xs={12} className={style.dropdown}>
            <FormControl variant="outlined" name="homepage" error={errorText.get(field.value) && errorText.get(field.value).length > 0}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                readOnly={field.readOnly}
                disabled={field.readOnly || (!field.bulk && multiple)}
                inputProps={{ disabled: field.disabled, 'data-testid': `editor.${field?.value}` }}
                value={!field.bulk && multiple
                  ? false
                  : field.options
                    .find(v => (v.get(field.valueKey) === values.getIn([0, ...field.value.split('.'), field.valueKey])), null, '')}
                onChange={event => onChange(field.value.split('.'), event.target.value)}>
                {!field.bulk && multiple &&
                <ListItem
                  key="multiple"
                  value={false}>
                  Multiple Values
                </ListItem>}
                {!(!field.bulk && multiple) && field.placeholder &&
                <ListItem
                  key="placeholder"
                  value=""
                  disabled>
                  {field.placeholder}
                </ListItem>}
                {field.options
                .sortBy(v => v.get('alt') || v.get('label'))
                .map(v => (
                  <ListItem
                    key={v.get(field.valueKey)}
                    value={v}>
                    {Text.Sentence(v.get('alt') || v.get('label') || v.get(field.key))}
                  </ListItem>))}
              </Select>
              {field?.helperText &&
                <FormHelperText>
                  {field.helperText}
                </FormHelperText>}
              {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
              <FormHelperText>
                {errorText.get(field.value).map(e => (
                  <li key={e}>{e}</li>
                ))}
              </FormHelperText>}
            </FormControl>
          </Col>
        );
      case 'multiselect':
        return (
          <Col xs={12} className={style.dropdown}>
            <FormControl variant="outlined" name="multiselect" error={errorText.get(field.value) && errorText.get(field.value).length > 0}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                disabled={!field.bulk && multiple}
                value={multiSelectValue(field)}
                multiple
                inputProps={{ 'data-testid': `editor.${field?.value}` }}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value.get(field.valueKey)} label={Text.Sentence(value.get('alt') || value.get('label') || value.get(field.key))} className={classes.chip} />
                    ))}
                  </div>
                )}
                onChange={event => onChangeMultiple(field.value.split('.'), event)}>
                {!field.bulk && multiple &&
                <ListItem
                  key="multiple"
                  value={false}>
                  Multiple Values
                </ListItem>}
                {field.options
                .sortBy(v => v.get('alt') || v.get('label') || v.get(field.key))
                .map(v => (
                  <MenuItem key={v.get(field.valueKey)} value={v}>
                    <Checkbox checked={values.getIn([0, ...field.value.split('.')], []).map(f => f.get(field.valueKey)).includes(v.get(field.valueKey))} />
                    <ListItemText primary={Text.Sentence(v.get('alt') || v.get('label')) || v.get(field.key)} />
                  </MenuItem>))}
              </Select>
              {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
              <FormHelperText>
                {errorText.get(field.value).map(e => (
                  <li key={e}>{e}</li>
                ))}
              </FormHelperText>}
            </FormControl>
          </Col>
        );
      case 'toggle': {
        let toggled = null;
        if (!field.bulk && multiple) {
          toggled = null;
        } else if (values.every(v => v.getIn([...field.value.split('.')]) === !!values.getIn([0, ...field.value.split('.')]))) {
          toggled = (!!values.getIn([0, ...field.value.split('.')]) == null) ? field.default : !!values.getIn([0, ...field.value.split('.')]);
        } else {
          toggled = field.default;
        }
        // Due to https://jira.s.fpint.net/browse/PLATFORM-353 and not wanting
        // to change underlying datastructure, we are allowing option to switch
        // toggled values visually but maintain the existing values
        if (field.opposite && toggled != null) toggled = !toggled;
        return (
          <Col xs={12} className={style.toggle}>
            <div className={cx([style.label, changed.includes(field.value) && style.changed])}>
              {`${field.label}:`}
              {field.icon && field.iconText &&
              <Tooltip title={field.iconText}>
                <Icon fontSize="small">
                  {field.icon}
                </Icon>
              </Tooltip>}
            </div>
            <Switch
              color="secondary"
              disabled={(!field.bulk && multiple) ||
                (field.disable && (values.getIn([0, ...field.value.split('.')]) == null
                ? field.disable.create(values.get(0))
                : field.disable.edit(values.get(0))))}
              checked={toggled}
              id={field.testId}
              data-testid={`editor.${field?.value}`}
              onChange={(_, v) => onChange(field.value.split('.'), (!field.opposite) ? v : !v)} />
            {field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0)) : field.disable.edit(values.get(0))) &&
              <div className={style['disable-text']}>
                <div>{field.disable.text}</div>
              </div>}
          </Col>);
      }
      case 'checkboxes': {
        if (!field.hidden || !field.hidden(values.get(0) || map())) {
          return (
            <React.Fragment>
              <Col xs={12} className={cx(['checkboxes', style.checkboxes, field.inline && style.inline])}>
                <div
                  className={cx([
                    style.label,
                    changed.includes(field.value) && style.changed])}>
                  {field.label ? `${field.label}:` : 'Products:'}
                  {field.selectAll &&
                  <div className={style.action}>
                    <Link
                      color="secondary"
                      onClick={() => checkboxSelect(field, false)}
                      style={{ paddingRight: '2rem' }}>
                      Unselect All
                    </Link>
                    <Link
                      color="secondary"
                      onClick={() => checkboxSelect(field, true)}>
                      Select All
                    </Link>
                  </div>}
                  {field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())) &&
                    <div className={style['disable-text']}>
                      <div>{field.disable.text}</div>
                    </div>
                  }
                </div>
                <div className={style.items} style={field.styles}>
                  {field.options
                    .map((v, i) => {
                      if (!v.has('children') && !v.get('useNested')) {
                        const isChecked = values.getIn([0, ...field.value.split('.')], list()).includes(v.get(field.valueKey, v));
                        return (
                          <FormControl key={v.get('id')} fullWidth={!field.inline}>
                            <FormGroup row>
                              {(!field.control || field.control === 'checkbox') &&
                              <FormControlLabel
                                label={Text.Sentence(v.get(field.key || 'name'))}
                                control={<Checkbox
                                  checked={isChecked}
                                  disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                                  onChange={event => onCheck([0, ...field.value.split('.')], v.get(field.valueKey) || v, event.target.checked)} />}
                              />}
                              {field.control === 'button' && !field.colors &&
                              <Button
                                variant={isChecked ? 'contained' : 'outlined'}
                                color="secondary"
                                disableElevation
                                disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                                onClick={() => onCheck([0, ...field.value.split('.')], v.get(field.valueKey) || v, !isChecked)}>
                                {Text.Sentence(v.get(field.key || 'name'))}
                              </Button>}
                              {field.control === 'button' && field.colors &&
                              <CheckboxButton
                                color={field.colors[Number(i)]}
                                disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                                initialState={isChecked}
                                tooltip={v.getIn(['app', 'tooltip'], '')}
                                label={Text.Sentence(v.get(field.key || 'name'))}
                                onClick={value => onCheck([0, ...field.value.split('.')], v.get(field.valueKey) || v, value)} />}
                            </FormGroup>
                          </FormControl>
                        );
                      } else if (field.control === 'button') {
                        const isChecked = v.get('id').every(id => values.getIn([0, ...field.value.split('.')], list()).includes(id));
                        return (
                          <FormControl key={v.get('id').join()} fullWidth={!field.inline}>
                            <FormGroup row>
                              {!field.colors &&
                              <Button
                                variant={isChecked ? 'contained' : 'outlined'}
                                color="secondary"
                                disableElevation
                                data-for="global.tooltip"
                                data-tip={field.tooltip}
                                disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                                onClick={() => onCheck([0, ...field.value.split('.')], v.get(field.valueKey) || v, !isChecked)}>
                                {Text.Sentence(v.get(field.key || 'name'))}
                              </Button>}
                              {field.colors &&
                              <CheckboxButton
                                color={field.colors[Number(i)]}
                                disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                                initialState={isChecked}
                                label={Text.Sentence(v.get(field.key || 'name'))}
                                onClick={value => onCheck([0, ...field.value.split('.')], v.get(field.valueKey) || v, value)} />}
                            </FormGroup>
                          </FormControl>
                        );
                      }
                      return (
                        <NestedCheckbox
                          key={v.get('id')}
                          disabled={(!field.bulk && multiple) || (field.disable && (values.getIn([0, ...field.value.split('.')]) === undefined ? field.disable.create(values.get(0) || map()) : field.disable.edit(values.get(0) || map())))}
                          options={v.get('children')}
                          parent={v}
                          values={fromJS(values.getIn([0, ...field.value.split('.')]) || list())}
                          onChange={groupState => onGroupChange([0, ...field.value.split('.')], groupState)} />
                      );
                    })}
                </div>
              </Col>
              {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
              <Col xs={12}>
                <div className={style.error}>
                  {errorText.get(field.value).map(e => (
                    <li key={e}>{e}</li>
                  ))}
                </div>
              </Col>}
            </React.Fragment>
          );
        }
        return '';
      }
      case 'checkbox':
        return (
          <React.Fragment>
            <Col xs={12} className={style.checkbox}>
              <FormControl>
                <FormControlLabel
                  label={`${field.label}:`}
                  name={field.name}
                  disabled={!field.bulk && multiple}
                  control={<Checkbox
                    checkedIcon={field.checked ? field.checked : <CheckBox />}
                    icon={field.unchecked ? field.unchecked : <CheckBoxOutlineBlank />}
                    checked={!field.bulk && multiple ? null :
                      !!values.getIn([0, ...field.value.split('.')])}
                    onChange={event => onChange(field.value.split('.'), event.target.checked)} />} />
                <FormHelperText>
                  {field.help}
                </FormHelperText>
              </FormControl>
            </Col>
            {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
            <Col xs={12}>
              <div className={style.error}>
                {errorText.get(field.value).map(e => (
                  <li key={e}>{e}</li>
                ))}
              </div>
            </Col>}
          </React.Fragment>
        );
      case 'chips': {
        let defaultValue = [];
        const val = values.getIn([0, ...field.value.split('.')]);
        if (!field.bulk && multiple) {
          defaultValue = ['Multiple Values'];
        } else if (list.isList(val)) {
          defaultValue = val.toJS();
        } else if (Array.isArray(val)) {
          defaultValue = val;
        } else if (val) {
          defaultValue = [val];
        }
        return (
          <Col xs={12}>
            <Autocomplete
              multiple
              freeSolo
              selectOnFocus
              ref={chipInput}
              name={field.value}
              clearOnBlur
              disabled={!field.bulk && multiple}
              value={defaultValue}
              options={field.dataSource || []}
              onChange={(event, vals) => {
                const updated = vals.map(v => v.split(',').map(f => f.trim())).flat();
                const selection = fromJS(updated);
                onChange(field.value.split('.'), selection);
              }}
              onBlur={(event) => {
                const { value } = event.target;
                if (value === '') return;
                let selection = defaultValue;
                const vals = value.split(',').map(f => f.trim());
                selection = selection.concat(vals);
                onChange(field.value.split('.'), fromJS(selection));
              }}
              renderInput={params => (
                <FormControl variant="outlined" name="autocomplete" error={errorText.get(field.value) && errorText.get(field.value).length > 0}>
                  <TextField
                    {...params}
                    variant="outlined"
                    error={errorText.get(field.value) && errorText.get(field.value).length > 0}
                    label={field.label}
                    placeholder={field.placeholder
                      ? `${defaultValue.length === 0 ? field.placeholder : ''}`
                      : 'Press enter to add'
                    } />
                  {errorText.get(field.value) && errorText.get(field.value).length > 0 &&
                  <FormHelperText>
                    {errorText.get(field.value).map(e => (
                      <li key={e}>{e}</li>
                    ))}
                  </FormHelperText>}
                </FormControl>
              )}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <EditableChip
                  {...getTagProps({ index })}
                  inputLabel={field.label}
                  label={option}
                  onEdit={(value) => {
                    const selection = defaultValue;
                    selection[Number(index)] = value;
                    if (value === '') selection.splice(index, 1);
                    onChange(field.value.split('.'), fromJS(selection));
                  }} />
              ))} />
          </Col>
        );
      }
      case 'popover':
        return (
          <FormControl
            variant="outlined"
            className={cx(['date', Boolean(dialog && dialog.key === 'popover') && 'active'])}>
            <InputLabel>{field?.label}</InputLabel>
            <Button
              color="primary"
              variant="outlined"
              onClick={event => setDialog(({ target: event.currentTarget, key: 'popover' }))}
              endIcon={<Icon>keyboard_arrow_down</Icon>}>
              {field?.placeholder || field?.label || 'Select...'}
            </Button>
            <Popover
              anchorEl={dialog?.target}
              open={Boolean(dialog && dialog.key === 'popover')}
              onClose={() => {
                setDialog();
                const changedValues = field.onChangeValues();
                onChange(field.value.split('.'), fromJS(changedValues));
              }}
              PaperProps={{ style: {
                height: '35rem',
                width: `${dialog?.target?.scrollWidth / 10}rem`,
              } }}>
              {React.cloneElement(field?.component, { existing: values.getIn([0, ...field.value.split('.')]) }) || <div />}
            </Popover>
          </FormControl>
        );
      default:
        return (<div />);
    }
  };

  useEffect(() => {
    let dataValues = data;
    const inlineFields = fields
      .filter(v => v.type.includes('inline'))
      .map(v => v.fields)
      .flat();
    const bulk = fields.concat(inlineFields).filter(v => v.bulk).map(v => v.value);
    const bulkToggles = fields.concat(inlineFields).filter(v => v.bulk && v.type === 'toggle').map(v => v.value);
    dataValues = dataValues.isEmpty() ? list([map()]) : dataValues;
    if (multiple) {
      bulk.forEach((k) => {
        if (bulkToggles.includes(k)) {
          const allSame = dataValues.every(v => v.getIn([...k.split('.')]) === !!dataValues.getIn([0, ...k.split('.')]));
          dataValues = values.map(v => v.setIn([...k.split('.')], (allSame) ? !!v.getIn([...k.split('.')]) : null));
        } else {
          dataValues = values.map(v => v.setIn([...k.split('.')], null));
        }
      });
    }
    const defaultFields = fields.concat(inlineFields).filter(v => v.defaults);
    defaultFields.forEach((v) => {
      const { value } = v;
      dataValues = dataValues.map((d) => {
        if (!d.hasIn(value.split('.')) || d.getIn(value.split('.')) == null) {
          return d.setIn(value.split('.'), fromJS(v.defaults));
        }
        return d;
      });
    });
    setValues(dataValues);
  }, [data]);

  return (
    <Grid
      fluid
      name="component.editor"
      className={cx([style.editor, classes.editor])}>
      {fields
        .map((field, fIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={fIndex}>
            {generateField(field)}
          </Row>))}
      <Row>
        <Col xs={12} className={style.footer}>
          <div className={style.actions}>
            {(data.getIn([0, 'created']) || data.getIn([0, 'updated']) ||
              data.getIn([0, 'endpoint'])) &&
              <Button
                style={{ opacity: data.size ? 1 : 0 }}
                className={cx([
                  errorCount === 0 && style.delete,
                ])}
                disabled={!canEdit}
                onClick={() => setDialog('delete')}>
                Delete
              </Button>}
            {extraActions.map(action => (
              <Button
                key={action.label}
                disabled={!canEdit || (!action.bulk && multiple)}
                onClick={() => action.onClick(values.get(0))}>
                {action.label}
              </Button>
            ))}
            <Button
              className={style.cancel}
              data-testid="editor.actions.cancel"
              onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              disabled={!canEdit}
              className={style.active}
              data-testid="editor.actions.save"
              onClick={() => onSave()}>
              {(typeof saveLabel === 'string' ? saveLabel : saveLabel(values))}
            </Button>
          </div>
        </Col>
      </Row>
      {(data.getIn([0, 'created']) || data.getIn([0, 'updated']) ||
        data.getIn([0, 'endpoint'])) &&
        <Dialog
          className={cx([style.dialog])}
          open={dialog === 'delete'}>
          <DialogTitle>
            {`Confirm deletion of ${data.size} selected item(s)`}
          </DialogTitle>
          <DialogContent>
            <ul>
              {data.map(v => (
                <li key={v.get('created') || v.get('updated') || v.get('endpoint')}>
                  {v.get('name') || v.get('value')}
                </li>))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              data-testid="editor.actions.cancel"
              onClick={() => setDialog('')}>
              Cancel
            </Button>
            <Button
              className={style['confirm-delete']}
              data-testid="editor.actions.delete"
              onClick={() => onDelete()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>}
      <ReactTooltip id="icon.tooltip" place="bottom" effect="solid" />
    </Grid>
  );
};

Editor.propTypes = {
  canEdit: PropTypes.bool,
  data: PropTypes.object,
  deleteActions: PropTypes.func,
  extraActions: PropTypes.array,
  fields: PropTypes.array,
  onEdit: PropTypes.func,
  save: PropTypes.func,
  saveLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  toggle: PropTypes.func,
  type: PropTypes.string,
};

Editor.defaultProps = {
  canEdit: true,
  data: list(),
  deleteActions: null,
  extraActions: [],
  fields: map(),
  onEdit: null,
  save: null,
  saveLabel: 'Save',
  toggle: null,
  type: '',
};

export default Editor;
