/* eslint-disable @typescript-eslint/no-unused-vars */
import { Map as map, fromJS } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import SearchUtils from '../../../utils/searchUtils';
import Common from '../../../utils/common';
import Iso from '../../../constants/Iso';
import SearchFields from '../../../constants/SearchFields';
import { knownHashes } from '../../../constants/org_profiles/Edm';
import Token from '../../../utils/token';
import { ReportsUrl } from '../../../stores/searchStore';
import { cachedGet } from '../../../utils/api';
import Text from '../../../utils/text';

const {
  addExactKeyword,
  escapeExactFilter,
  escapeFiltersList,
  computeSitesQueryConditions,
} = SearchUtils;
const BasetypeMapping = Common.Basetypes.SearchTypesToBasetypesQuery;

const queryTypeMap = fromJS({
  accounts: 'accountsQuery',
  blogs: 'blogsQuery',
  posts: 'postsQuery',
  'inline.posts': 'postsInlineQuery',
  'meta.posts': 'postsMetaQuery',
  ransomware: 'ransomwareQuery',
  cards: 'cardsQuery',
  board: 'boardQuery',
  boards: 'boardsQuery',
  media: 'mediaQuery',
  'inline.board': 'boardInlineQuery',
  'meta.board': 'boardMetaQuery',
  credentials: 'credentialsQuery',
  cves: 'cvesQuery',
  'cves.inline': 'cvesInlineQuery',
  'cves.meta': 'cvesMetaQuery',
  exploits: 'exploitsQuery',
  'exploits.inline': 'exploitsInlineQuery',
  'exploits.meta': 'exploitsMetaQuery',
  iocs: 'iocsQuery',
  'iocs.meta': 'iocsMetaQuery',
  forums: 'forumsQuery',
  social: 'socialQuery',
  twitter: 'twitterQuery',
  news: 'newsQuery',
  'inline.news': 'newsInlineQuery',
  'meta.news': 'newsMetaQuery',
  marketplaces: 'marketplacesQuery',
  pastes: 'pastesQuery',
  reports: 'reportQuery',
  threads: 'threadQuery',
  'inline.threads': 'threadInlineQuery',
  'meta.threads': 'threadMetaQuery',
  chats: 'chatsQuery',
  channels: 'channelQuery',
  'inline.channels': 'channelInlineQuery',
  'meta.channels': 'channelMetaQuery',
  communities: 'communitiesQuery',
});

const makeAllQueryTypes = ({
  bins = null,
  channelFilter = '',
  customerIdFilter = null,
  exactFilters = [],
  excludeFilters = [],
  exporting = false,
  filters = {},
  hashTypesQuery = '',
  highlightEnabled = false,
  highlightExcludedFields = [],
  highlightSize = 250,
  id = '',
  ignoreAggregations = false,
  interval = '12h',
  key = ['search', 'results'],
  limit = 25,
  mediaType = '',
  query = { skip: undefined },
  scroll = '2m',
  serverFilter = '',
  since = 'now-7d',
  sinceDate = moment().format('YYYY-MM-DDTHH:mm:ssZ'),
  skip = 0,
  state = fromJS({}),
  subField = 'fpid',
  tags = '',
  type = 'communities',
  until = 'now',
  untilDate = moment().subtract(7, 'd').format('YYYY-MM-DDTHH:mm:ssZ'),
}) => {
  const enrichmentsQuery = [
    !id && filters.required_enrichments
      ? `${filters.enrichments_cond !== 'true' ? '+(' : ''}${filters.required_enrichments
        .split(',')
        .map(v => v.split('::').slice(0, -1))
        .map(v => `_exists_:enrichments.${v}`)
        .join(filters.enrichments_cond === 'true' ? ' +' : '|')}${filters.enrichments_cond !== 'true' ? ')' : ''}`
      : null,
    !id && filters.cves
      ? `+enrichments.v1.vulnerability.cve.vulnerability.keyword:(${filters.cves
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `"${escapeExactFilter(v)}"`)
        .join(' OR ')})`
      : null,
    !id && filters.bins
      ? `+enrichments.card-numbers.card-numbers.bin:(${filters.bins.replace(/\*/ig, '').split(',').map(v => `"${v}"`).join(' OR ')})`
      : null,
    !id && filters.ips
      ? `+enrichments.v1.ip_addresses.ip_address:(${filters.ips.split(',').map(v => `"${v}"`).join(' OR ')})`
      : null,
    !id && filters.emails
      ? `+enrichments.v1.email_addresses.email_address${filters.email_exact ? '.keyword' : ''}:(${filters.emails.split(',').map(v => `"${v}"`).join(' OR ')})`
      : null,
    !id && filters.domains
      ? `+(${filters.domains
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.urls.domain:"${v}"`)
        .join(' ')})`
      : null,
    !id && filters['monero-wallets']
      ? `+(${filters['monero-wallets']
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.monero_addresses.monero_address:${v}`)
        .join(' ')})`
      : null,
    !id && filters['ethereum-wallets']
      ? `+(${filters['ethereum-wallets']
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.ethereum_addresses.ethereum_address:${v}`)
        .join(' ')})`
      : null,
    !id && filters['bitcoin-wallets']
      ? `+(${filters['bitcoin-wallets']
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.bitcoin_addresses.bitcoin_address:${v}`)
        .join(' ')})`
      : null,
    !id && filters.handles
      ? `+(${filters.handles
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.social_media.handle:${v}`)
        .join(' ')})`
      : null,
    !id && filters.profiles
      ? `+(${filters.profiles
        .replace(/\*/ig, '')
        .split(',')
        .map(v => `|enrichments.v1.social_media.site:${v}`)
        .join(' ')})`
      : null,
  ];

  const iocsQuery = map()
    .set('query', [
      'header_.is_visible:(true)',
      `+basetypes:(${BasetypeMapping(['iocs'])})`,
      id ? `+Event.uuid:"${id}"` : null,
      filters.uuid ? `+Event.uuid:"${filters.uuid}"` : null,
      filters.query ? `+(|${filters.query} |Event.Attribute.value:${filters.query})` : null,
      filters.event_type
        ? `+Event.Tag.name:("${filters.event_type.split('::').shift()}")`
        : null,
      filters.ioc_tags
        ? `+Event.Tag.name:(${escapeFiltersList({ stringOfFilters: filters.ioc_tags, whenFilterContainsStar: 'preventEscape', customJoin: filters.ioc_tags_cond === 'true' ? ' +' : ' ,' })})` : null,
      filters.exclude_ioc_tags
        ? `-Event.Tag.name:(${(escapeFiltersList({ stringOfFilters: filters.exclude_ioc_tags, whenFilterContainsStar: 'preventEscape' }))})` : null,
      filters.required_ioc_tags
        ? filters.required_ioc_tags
          .split(',')
          .map(v => (v === 'attack_ids'
            ? '+_exists_:Event.attack_ids'
            : `+Event.Tag.name:(${v.split('::').shift()}*)`))
          .join(' ')
        : null,
      filters.attack_ids ? `+Event.attack_ids:(${filters.attack_ids.split(',').map(v => `"${v.split(':').shift()}"`).join()})` : null,
      filters.ioc_category ? `+category:(${escapeFiltersList({ stringOfFilters: filters.ioc_category })})` : null,
      filters.ioc_type ? `+type:(${escapeFiltersList({ stringOfFilters: filters.ioc_type, whenFilterContainsStar: 'preventEscape' })})` : null,
      filters.exclude_ioc_type ? `-type:(${escapeFiltersList({ stringOfFilters: filters.exclude_ioc_type, whenFilterContainsStar: 'preventEscape' })})` : null,
      filters.ioc_value
        ? `${filters.ioc_type
          ? `+(${filters.ioc_type.split(',').map(v => `|value.${v.replace('|', '\\|')}:(${filters.ioc_value.split(',').join()})`).join(' ')})`
          : `+value.\\*:(${filters.ioc_value.split(',').join()})`}`
        : null,
      filters.has_related_events ? `${filters.has_related_events === 'true' ? '+_exists_:Event.RelatedEvent' : '-_exists:Event.RelatedEvent'}` : null,
      filters.has_report ? `${filters.has_report === 'true' ? '+Event.Tag.name:(report*)' : '-Event.Tag.name:(report*)'}` : null,
      filters.has_config
        ? `${filters.has_config === 'true'
          ? '+Event.Tag.name:("extracted_config:true") +type:text'
          : '-Event.Tag.name:("extracted_config:true")'}`
        : null,
      !filters.since
        ? '+Event.date:[* TO now]'
        : `+Event.date:[${filters.since} TO ${filters.until || 'now'}]`,
      ...enrichmentsQuery,
    ].filter(v => v).join(' '))
    .set('highlight', highlightEnabled)
    .set('traditional_query', true)
    .set('from', id ? null : +skip)
    .set('source', filters.source ? filters.source.split(',') : null)
    .set('scroll', exporting && scroll)
    .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.iocs)
    .set('_source_includes', id || exporting ? null : [...highlightExcludedFields, 'Event.Attribute', 'Event.Tag', 'Event.attribute_count', 'Event.RelatedEvent', 'attack_ids', 'category'])
    .set('aggregations',
      {
        ...(id || exporting || ignoreAggregations || type === 'all'
          ? {}
          : {
            ...(filters.aggregations
              ? filters.aggregations
              : {
                'date-histogram': {
                  field: 'date',
                  interval,
                },
              }
            ),
          }
        ),
      },
    )
    .set('sort', filters.sort ? filters.sort.split(',') : ['Event.date:desc'])
    .set('size', +limit)
    .filter(v => v)
    .set('highlight_size', highlightSize)
    .toJS();

  const reportQuery = {
    ...filters,
    tags,
    all: null,
    remove_styles: !!exporting,
    fields: filters.fields || SearchFields.Includes.reports.join(),
    highlight: highlightEnabled,
    highlight_size: highlightSize,
    highlight_body: filters.body || filters.query,
    highlight_title: filters.title || filters.query,
    traditional_query: true,
    report_ids: id || undefined,
    body: filters.body || '',
    title: filters.title || '',
    query: filters.query || '',
    basetypes: ['report', 'reports'],
    // if no query provided use date in place of relevancy
    sort: [filters?.query, filters?.body, filters?.title].every(v => !v)
      ? SearchFields?.Sorts?.reports?.[1]?.value
      : `${filters?.sort ? filters?.sort : SearchFields?.Sorts?.reports?.[0]?.value}`,
    skip: id ? 0 : +skip,
    limit: id ? 1 : +limit,
    ...sinceDate ? { since: sinceDate } : {},
    ...untilDate ? { until: untilDate } : {},
  };

  const credentialSince = filters.password_reset_policy &&
    filters.password_reset_policy !== null
    ? `${since === '*' || moment.utc().subtract(parseInt(filters.password_reset_policy, 10), 'days').isAfter(moment.utc(sinceDate))
      ? `now-${filters.password_reset_policy}d`
      : since}`
    : since;

  let optionalQuery = null;
  if (filters.optional && filters.num_optional) {
    const optional = filters.optional.split(',');
    const numRequired = parseInt(filters.num_optional, 10);
    const indicies = [...Array(numRequired).keys()];
    switch (numRequired) {
      case 1:
        optionalQuery = `+(${optional.map(v => `|password_complexity.has_${v}:(true)`).join(' ')})`;
        break;
      default:
        optionalQuery = `+(${_.combinations(optional, numRequired).map(v => `|(${indicies.map(i => `+password_complexity.has_${v[Number(i)]}:(true)`).join(' ')})`).join(' ')})`;
        break;
    }
  }

  const credentialsQuery = map()
      .set('query', [
        `+basetypes:(${BasetypeMapping(['credentials'])})`,
        `+breach.${filters.alert_date_type && filters.alert_date_type === 'breached_at'
          ? 'created_at'
          : 'first_observed_at'}.date-time:[${credentialSince} TO ${until}]`,
        id ? `${id.includes('::') ? `+credential_record_fpid:("${id.split('::')[0]}")` : `+fpid:("${id}")`}` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.domain ? `${filters.exclude_domain ? '-' : '+'}domain:(${filters.domain.split(',').map(v => `"${v}"`).join(' OR ')})` : null,
        filters.affected_domain ? `${filters.exclude_affected_domain ? '-' : '+'}affected_domain:(${filters.affected_domain.split(',').map(v => `"${v}"`).join(' OR ')})` : null,
        filters.email ? `+email:("${filters.email}")` : null,
        filters.password ? `+password:(${filters.password})` : null,
        filters.hash_types ? hashTypesQuery : null,
        filters.customer_id ? `+customer_id:("${customerIdFilter['15']}" | "${customerIdFilter['18']}")` : null,
        filters.source_type ? `+breach.source_type:("${filters.source_type}")` : null,
        filters.meets_org_profile_password_complexity === 'true' && filters.ignore_hashes === 'true' ? `-password_complexity.probable_hash_algorithms.keyword:(${knownHashes.map(v => `"${v}"`).join(' | ')})` : null,
        filters.is_fresh === 'true' ? '+is_fresh:(true)' : null,
        filters.length ? `+password_complexity.length:[${filters.length} TO *]` : null,
        filters.required ? filters.required.split(',').map(v => `+password_complexity.has_${v}:(true)`).join(' ') : null,
        filters.excluded ? filters.excluded.split(',').map(v => `-password_complexity.has_${v}:(true)`).join(' ') : null,
        optionalQuery,
        filters.title
          ? `${filters.title.includes('::')
            ? `+breach.fpid:"${filters.title.split('::').shift()}"`
            : `+breach.title${(filters.title_exact || filters.title_exact === 'true') && filters.title_exact !== 'false' ? `.keyword:("${filters.title}")` : `:("${filters.title}")`}`}`
          : null,
        filters.breached_since
          ? `+breach.created_at.date-time:[${filters.breached_since || '*'} TO ${filters.breached_until || 'now'}]`
          : `+breach.created_at.date-time:[* TO ${filters.breached_until || 'now'}]`,
        ...enrichmentsQuery,
      ]
        .filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('traditional_query', true)
      .set('scroll', exporting && scroll)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.credentials)
      .set('_source_includes', id || exporting ? null : SearchFields.Includes.credentials)
      .set('from', id ? null : +skip)
      .set('sort', filters.sort ? filters.sort.split(',') : ['breach.first_observed_at.timestamp:desc'])
      .set('size', id ? 100 : +limit)
      .set('aggregations', {
        ...(id || exporting || ignoreAggregations || type === 'all'
          ? {
            'multiple-terms': {
              domain: { terms: { field: 'domain.keyword', size: 5000 } },
              affected_domain: { terms: { field: 'affected_domain.keyword', size: 5000 } },
            },
          }
          : {
            'date-histogram': {
              field: 'breach.first_observed_at.date-time',
              interval,
            },
            'multiple-terms': {
              breach_source: { terms: { field: 'breach.source_type.keyword', size: 5 } },
              domain: { terms: { field: 'domain.keyword', size: 5000 } },
              affected_domain: { terms: { field: 'affected_domain.keyword', size: 5000 } },
            },
          }),
      })
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();

    const cvesQuery = map()
      .set('query', [
        `basetypes:(${BasetypeMapping(['cves.vulnerability'])})`,
        id ? `+fpid:"${id}"` : null,
        filters.query
          ? `+(${/".*?"/.test(filters.query) ? filters.query : `"${filters.query}"`})`
          : null,
        filters.cves
          ? `+title.keyword:(${escapeFiltersList({ stringOfFilters: filters.cves, whenFilterContainsStar: 'deleteStars' })})`
          : null,
        filters.cve_reporter
          ? `+_exists_:${filters.cve_reporter === 'nist' ? '(nist.cvssv2 OR nist.cvssv3)' : filters.cve_reporter}`
          : null,
        filters.cve_base_score_2
          ? `+nist.cvssv2.base_score:[${filters.cve_base_score_2.split(',')[0]} TO ${filters.cve_base_score_2.split(',')[1]}]`
          : null,
        filters.cve_base_score_3
          ? `+nist.cvssv3.base_score:[${filters.cve_base_score_3.split(',')[0]} TO ${filters.cve_base_score_3.split(',')[1]}]`
          : null,
        filters.cve_vulnerability_types
          ? `+nist.vulnerability_types.keyword:(${filters.cve_vulnerability_types})`
          : null,
        filters.cve_vendor_names || filters.cve_product_names
          ? `${filters.cve_vendor_names
            ? `+(|${filters.cve_vendor_names.split(',').map(v => `nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
            : ''}${filters.cve_product_names
            ? `(|${filters.cve_product_names.split(',').map(v => `nist.products.product_name:(${v})`).join(' |')})`
            : ''}`
          : null,
        filters.required_cve_tags
          ? filters.required_cve_tags
            .split(',')
            .map(v => `+_exists_:(${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.required_fields
          ? filters.required_fields
            .split(',')
            .map(v => `+_exists_:(${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.cve_severity
          ? `+nist.cvssv3.severity:(${filters.cve_severity})`
          : null,
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('traditional_query', true)
      .set('from', id ? null : +skip)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('scroll', exporting && scroll)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.cves)
      .set('_source_includes', id || exporting ? null : [...highlightExcludedFields, 'nist.products', 'nist.configurations', 'nist.vulnerability_types'])
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null
        : { 'date-histogram': { field: 'sort_date', interval },
        })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();
    const exploitsQuery = map()
      .set('query', [
        id ? `+fpid:"${id}"` : null,
        `+basetypes:(${BasetypeMapping(['cves.exploit'])})`,
        filters.query
          ? `+(${/".*?"/.test(filters.query) ? filters.query : `"${filters.query}"`})`
          : null,
        filters.cves
          ? `+title.keyword:(${escapeFiltersList({ stringOfFilters: filters.cves, whenFilterContainsStar: 'deleteStars' })})`
          : null,
        filters.cve_reporter
          ? `+_exists_:${filters.cve_reporter === 'nist' ? '(cve.nist.cvssv2 OR cve.nist.cvssv3)' : filters.cve_reporter}`
          : null,
        filters.cve_base_score_2
          ? `+cve.nist.cvssv2.base_score:[${filters.cve_base_score_2.split(',')[0]} TO ${filters.cve_base_score_2.split(',')[1]}]`
          : null,
        filters.cve_base_score_3
          ? `+cve.nist.cvssv3.base_score:[${filters.cve_base_score_3.split(',')[0]} TO ${filters.cve_base_score_3.split(',')[1]}]`
          : null,
        filters.cve_vulnerability_types
          ? `+cve.nist.vulnerability_types.keyword:(${filters.cve_vulnerability_types})`
          : null,
        filters.cve_vendor_names || filters.cve_product_names
          ? `${filters.cve_vendor_names
            ? `+(|${filters.cve_vendor_names.split(',').map(v => `cve.nist.products.vendor_name:(${v})`).join(' |')}) ${filters.cve_product_names ? ' +' : ''}`
            : ''}${filters.cve_product_names
            ? `(|${filters.cve_product_names.split(',').map(v => `cve.nist.products.product_name:(${v})`).join(' |')})`
            : ''}`
          : null,
        filters.required_cve_tags
          ? filters.required_cve_tags
            .split(',')
            .map(v => `+_exists_:(cve.${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.required_fields
          ? filters.required_fields
            .split(',')
            .map(v => `+_exists_:(cve.${v.split('::').shift()})`)
            .join(' ')
          : null,
        filters.cve_severity
          ? `+cve.nist.cvssv3.severity:(${filters.cve_severity})`
          : null,
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
      ].filter(v => v).join(' '))
      .set('highlight', highlightEnabled)
      .set('traditional_query', true)
      .set('from', id ? null : +skip)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('scroll', exporting && scroll)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.exploits)
      .set('_source_includes', id || exporting ? null : [...highlightExcludedFields, 'cve.nist.products', 'cve.nist.configurations', 'cve.nist.vulnerability_types', 'cve.title', 'source_uri'])
      .set('aggregations', id || exporting || ignoreAggregations || type === 'all' ? null
        : { 'date-histogram': { field: 'sort_date', interval },
        })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();

    const chatsQuery = map()
      .set('query', [
        `+basetypes:${BasetypeMapping(['chats'])}`,
        id ? `+fpid:"${id.split('|')[0]}"` : null,
        filters.query ? `+(${filters.query})` : null,
        filters.author
          ? `${filters.author.includes('::')
            ? `${!excludeFilters.includes('author') ? '+' : '-'}site_actor.fpid:("${filters.author.split('::').shift()}")`
            : `+(${!excludeFilters.includes('author') ? '|' : '-'}site_actor.names.aliases${addExactKeyword('author', exactFilters)}:(${filters.author.split(',').map(v => `${v.replace(/(["~])/g, '\\$1')}`).join()})
                ${!excludeFilters.includes('author') ? '|' : '-'}site_actor.names.handle${addExactKeyword('author', exactFilters)}:(${filters.author.split(',').map(v => `${v.replace(/(["~])/g, '\\$1')}`).join()}))`}`
          : null,
        filters.server
          ? serverFilter
          : null,
        filters.server_id ? `+(|container.container.native_id:(${filters.server_id}) |container.server.native_id:(${filters.server_id})) |server.native_id:(${filters.server_id})` : null,
        filters.channel
          ? channelFilter
          : null,
        filters.channel_id
          ? `+(|container.native_id:(${filters.channel_id.split(',').join(' OR ')}) |channel.native_id:(${filters.channel_id.split(',').join(' OR ')}))`
          : null,
        filters.author_id ? `+site_actor.native_id:(\"${filters.author_id}\")` : null,
        filters.message ? `+body.text/plain:(${filters.message})` : null,
        filters.media ? `${filters.media === 'true' ? '+_exists_:media +_exists_:media_v2.storage_uri' : '-_exists_:media_v2'}` : null,
        filters.media_type && !filters.mime_type ? `+media_v2.mime_type.keyword:(${mediaType}) ${filters.media_type === 'Application' ? '-media_v2.filename:("sticker.webp" OR "AnimatedSticker.tgs")' : ''}` : null,
        filters.mime_type ? `+media_v2.mime_type:(${filters.mime_type})` : null,
        filters.media_caption ? `+media_v2.title:(${filters.media_caption})` : null,
        filters.filename ? `+(media_v2.filename:("${filters.filename}") |media_v2.file_name:("${filters.filename}") |media_v2.storage_uri:("${filters.filename}") |media_v2.sha256:("${filters.filename.split('.')[0]}"))` : null,
        filters.sha1 ? `${!excludeFilters.includes('sha1') ? '+' : '-'}media_v2.sha1:(${filters.sha1})` : null,
        filters.phash ? `+media_v2.phash:(${filters.phash.split('').map((v, k) => `${filters.phash.substr(0, k)}?${filters.phash.substr(k + 1)}`).slice(1).join(' | ')})` : null,
        filters.language ? `+body.enrichments.language:(${Object.entries(Iso).find(v => v?.[1]?.name === filters.language)?.[0] || ''})` : null,
        filters.sites
          ? `+site.title${exactFilters.includes('sites') ? '.keyword' : ''}:(${escapeFiltersList({ stringOfFilters: filters.sites })})`
          : null,
        computeSitesQueryConditions(filters, exactFilters),
        !filters.since
          ? '+sort_date:[* TO now]'
          : `+sort_date:[${filters.since} TO ${filters.until || 'now'}]`,
        ...enrichmentsQuery,
      ].filter(v => v).join(' '))
      .set('highlight', filters.group !== 'channels' && highlightEnabled)
      .set('traditional_query', true)
      .set('source', filters.source ? filters.source.split(',') : null)
      .set('scroll', exporting && scroll)
      .set('fields', filters.fields ? filters.fields.split(',') : SearchFields.Fields.chats)
      .set('_source_includes', id || exporting ? null : SearchFields.Includes.chats)
      .set('from', id ? null : +skip)
      .set('group', filters.group ? filters.group : 'messages')
      .set('aggregations', filters.group === 'channels'
        ? {
          ...!ignoreAggregations && {
            'date-histogram': {
              field: 'sort_date',
              interval,
            } },
          'top-hits-messages-by-container': { size: 250, top_hits_size: 1 },
          'container-names-by-container-fpid': { size: 250 },
          'site-actors-cardinality-by-container': { size: 250 },
          'multiple-terms': {
            author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
            channel: { terms: { field: 'container.title.keyword', size: 5 } },
          },
        }
        : { ...!ignoreAggregations && {
          'date-histogram-cardinality': {
            field: 'sort_date',
            interval,
            sub_field: subField,
          },
          'multiple-terms': {
            author: { terms: { field: 'site_actor.names.handle.keyword', size: 5 } },
            channel: { terms: { field: 'container.name.keyword', size: 5 } },
          },
        } })
      .set('sort', filters.sort ? filters.sort.split(',') : ['sort_date:desc'])
      .set('size', filters.group === 'channels' ? 1 : +limit)
      .filter(v => v)
      .set('highlight_size', highlightSize)
      .toJS();

  return {
    iocsQuery,
    credentialsQuery,
    reportQuery,
    cvesQuery,
    exploitsQuery,
    chatsQuery,
  };
};

export const makeQueryOfType = ({
  bins, channelFilter, customerIdFilter, exactFilters, excludeFilters, exporting, filters,
  hashTypesQuery, highlightEnabled, highlightExcludedFields, highlightSize, id,
  ignoreAggregations, interval, key, limit, mediaType, query, scroll, serverFilter,
  since, sinceDate, skip, state, subField, tags, type, until, untilDate,
}) => {
  const allQueryTypes = makeAllQueryTypes({ bins, channelFilter, customerIdFilter, exactFilters,
    excludeFilters, exporting, filters, hashTypesQuery, highlightEnabled, highlightExcludedFields,
    highlightSize, id, ignoreAggregations, interval, key, limit, mediaType, query, scroll,
    serverFilter, since, sinceDate, skip, state, subField, tags, type, until, untilDate });
  const queryTypeName = queryTypeMap.get(type);
  // eslint-disable-next-line security/detect-object-injection
  const finalQuery = allQueryTypes[queryTypeName];
  return finalQuery || SearchUtils.buildQuery(type, '', filters);
};

const getStrippedInnerText = (htmlString) => {
  const tempDivElement = document.createElement('div');
  // Hide the new temporary div
  tempDivElement.style = { visibility: 'hidden', opacity: 0, position: 'absolute', left: 0, top: 0, width: 1, height: 1 };
  tempDivElement.innerHTML = htmlString;
  // innerText can only intuit how the div is rendered if you add it to the document
  document.body.appendChild(tempDivElement);
  const tempInnerText = tempDivElement.innerText;
  // Run the extra sanitization steps just in case
  let strippedInnerText = Text.StripHtml(Text.ReportCard(Text.StripCss(tempInnerText)));
  // Replace all single \n with double \n\n for readability
  strippedInnerText = strippedInnerText.replace(/[^\n]\n[^\n]/g, match => match.replace(/\n/g, '\n\n'));

  tempDivElement.remove();
  return strippedInnerText;
};

export const reports = ({
  reportQuery,
  filters = {},
  id = '',
}) => {
  const prm = Token.get('prm');
  const lookup = JSON.stringify(filters);

  if (prm.some(p => /rep|ddw/.test(p))) {
    return cachedGet(`${ReportsUrl}`, reportQuery, [200, 400, 500, 501, 502, 503, 504])
      .then(res => (res.ok && res.data))
      .then(res => ({ ...res, lookup }))
      .then(res => ({
        ...res,
        lookup,
        requestBody: reportQuery,
        data: res.data.map(v => ({
          ...v,
          fpid: v.id,
          sources: v.sources.map(s => ({
            ...s,
            title: (s.title || '').replace('/api/v4/indicators/event',
              '/home/technical_data/iocs/items'),
            original: (s.original || '').replace('/api/v4/indicators/event',
              '/home/technical_data/iocs/items'),
          })),
          body: { 'text/html+sanitized': v.body, 'text/plain': Text.StripHtml(Text.ReportCard(Text.StripCss(v.body))), strippedInnerText: getStrippedInnerText(v.body) },
          basetypes: ['report', 'reports'] })) }))
      .then(res => (id ? { ...res.data[0], related: [] } : res));
  }
  return false;
};
