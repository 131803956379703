import React, { useRef, useState } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircleOutline, CloudOff, ErrorOutline, GetApp } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import style from '../sanctions.module.scss';

const ChainalysisResults = ((props) => {
  const [downloading, setDownloading] = useState(false);
  const chainalysisRef = useRef(null);

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;
    // attach to body for Firefox
    document.body.appendChild(link);
    // simulate a click
    link.click();
    // cleanup
    document.body.removeChild(link);
  };

  const handleDownload = (ref, filename) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    html2canvas(ref.current, { scale: 2, height: Math.min(ref.current.clientHeight, 8192) })
      .then((canvas) => {
        saveImage(canvas.toDataURL(), filename);
      })
      .then(() => {
        setDownloading(false);
      });
  };

  return (
    <Paper ref={chainalysisRef} className={style.card}>
      <div className={style.resultaccordion}>
        <div data-cy="chainalysisList" className={style.accordionsummarycontent}>
          <div className={style.accordiontitle}>
            <div data-cy="chainalysisListTitle" className={style.listtitle}>
              <div className={style.medtitle}>Chainalysis Search</div>
              {props.listAvailable ?
                <>
                  {props.result.chainalysis.rating && props.result.chainalysis.rating === 'highRisk' &&
                    <span className={style.someresults}>
                      <ErrorOutline className={style.resultswarn} />
                      High Risk
                    </span>
                  }
                  {props.result.chainalysis.rating && props.result.chainalysis.rating !== 'highRisk' &&
                    <span className={style.noresults}>
                      <CheckCircleOutline className={style.noresultscheck} />
                      {props.result.chainalysis.rating === 'lowRisk' ? 'Low Risk' : 'Unknown Risk'}
                    </span>
                  }
                </>
                :
                <>
                  <span className={style.offlineresults}>
                    <CloudOff className={style.offlinecloud} />
                    Chainalysis Offline
                  </span>
                </>
              }
            </div>
            {downloading && <CircularProgress />}
            <button
              type="button"
              className={style.resultdownload}
              onClick={(event) => {
                event.stopPropagation();
                handleDownload(chainalysisRef, `${'wallet'}-Chainalysis-results.png`);
              }}><GetApp />
            </button>
          </div>
          <div className={style.chainalysisdetails}>
            <div>Wallet:   {props.result.chainalysis.address}</div>
            {props.listAvailable &&
              <>
                <div>Currency Type:   {props.result.chainalysis.asset}</div>
                <div>Date Searched:   {
                    new Date(props.result.chainalysis.responseDateTime).toLocaleString()
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {!props.listAvailable ?
        <>
          <div data-cy="chainalysisListOffline" className={style.noresultsmessage}>
            <div className={style.offlineunderline}> </div>
            <div className={style.resulttitle}>List Offline</div>
            <div className={style.lighttext}>Search for{' '}
              <span className={style.bolded}>"{props.result.searchTerm}"</span>
              {' '}could not be completed on{' '}
              {props.result.chainalysis.responseDateTime ?
                new Date(props.result.chainalysis.responseDateTime).toLocaleString()
                : 'Undefined Date'
              }.
            </div>
          </div>
        </>
      :
        <>
          {props.result.chainalysis.rating && props.result.chainalysis.rating === 'highRisk' &&
            <div className={style.resultunderlinechain}> </div>
          }
          {props.result.chainalysis.rating && props.result.chainalysis.rating !== 'highRisk' &&
            <div className={style.noresultunderline}> </div>
          }
          <React.Fragment>
            <div className={style.searchsection}>
              <Table className={style.resultstable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Cluster</TableCell>
                    <TableCell>{props.result.previous_risk_scores ? 'New ' : ''}Rating</TableCell>
                    {props.result.previous_risk_scores &&
                    <TableCell>Old Rating</TableCell>
                    }
                    <TableCell>Custom Address</TableCell>
                    <TableCell>Chainalysis Identification</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{props.result.chainalysis.cluster ? props.result.chainalysis.cluster.name : ''}</TableCell>
                    <TableCell>
                      {props.result.chainalysis.rating === 'lowRisk' && <span>Low Risk</span>}
                      {props.result.chainalysis.rating === 'highRisk' && <span>High Risk</span>}
                      {props.result.chainalysis.rating !== 'lowRisk' && props.result.chainalysis.rating !== 'highRisk' && <span>Unknown Risk</span>}
                    </TableCell>
                    {props.result.previous_risk_scores &&
                    <TableCell>
                      {props.result.previous_risk_scores.at(-1) === 'lowRisk' && <span>Low Risk</span>}
                      {props.result.previous_risk_scores.at(-1) === 'highRisk' && <span>High Risk</span>}
                      {props.result.previous_risk_scores.at(-1) !== 'lowRisk' && props.result.previous_risk_scores.at(-1) !== 'highRisk' && <span>Unknown Risk</span>}
                    </TableCell>
                    }
                    <TableCell>{props.result.chainalysis.customAddress}</TableCell>
                    <TableCell>{props.result.chainalysis.chainalysisIdentification}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </React.Fragment>
        </>
      }
    </Paper>
  );
});

ChainalysisResults.propTypes = {
  result: PropTypes.object,
  listAvailable: PropTypes.bool,
};

ChainalysisResults.defaultProps = {
  result: undefined,
  listAvailable: true,
};

export default ChainalysisResults;
