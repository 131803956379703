import React from 'react';
import Api from '../../../utils/api';

import Token from '../../../utils/token';

const CCMCUrl = `/api/v4/credentials${'_self' in React.createElement('div') ? '' : ''}`;

const Status = [200, 201, 204, 500, 501, 502, 503, 504];
const hasHostDataPrm = Token.get('prm').some(v => /dat.ccm.cus.hd.r/.test(v));
const hasCookiesPrm = Token.get('prm').some(v => /dat.ccm.cus.ha.r/.test(v));
const customerCredentialsAnalysis = query => Api.post(CCMCUrl, query, Status);

const getCredentialDetails = async (fpid, key) => {
  const sha256Regex = /^[a-fA-F0-9]{64}$/;
  const type = sha256Regex.test(key) ? 'username' : 'affected_domain';
  const detailsQuery = {
    filters: { fpid },
    keys: [key],
    include_cookies: hasCookiesPrm || hasHostDataPrm,
    include_infected_host_details: hasHostDataPrm,
    type,
   };
    const details = await Api.post(`${CCMCUrl}/analysis`, detailsQuery);
    return details;
};


export default {
    customerCredentialsAnalysis,
    getCredentialDetails,
};
