import * as React from 'react';

import Query from '../../query';
import { SavedSearch } from '../../../../../core/types/SavedSearch';
import easyUseEffect from '../../../../../hooks/easyUseEffect';
import easyUseState from '../../../../../hooks/easyUseState';
import { TopFilterWidget } from '../TopFilterWidget';
import { topFilterStyles } from '../TopFilterWidget/TopFilterWidget';
import Dashboard from '../../../Dashboard';
import { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';

type SettingsFunction = () => object;

interface TopSourceWidgetProps {
  savedSearch: SavedSearch;
  filters: ExpoDashFilters;
  styles: topFilterStyles;
  settings: SettingsFunction;
}

export const TopSourceWidget = ({
  savedSearch,
  filters,
  settings,
  styles,
}: TopSourceWidgetProps) => {
  if (!filters?.date) return <></>;
  const [data, setData] = easyUseState({});

  easyUseEffect(() => {
    const keyword = filters?.query || '';
    if (keyword) {
      Query.CommunityMentions(savedSearch, filters)
        .then(res => setData((v:object) => ({ ...v, ...res })));
      if (Object.keys(data).length > 0) {
        setData({});
      }
    }
  }, [savedSearch, filters.date]);

  return (
    <Dashboard
      data={data}
      title={savedSearch?.name || filters?.query}
      settings={settings}
    >
      {(savedSearch || filters.query) &&
        <TopFilterWidget
          queryString="Top Source"
          baseFilters={filters}
          savedSearch={savedSearch}
          topFilterData={data?.sources?.name}
          filterKey="channel"
          humanReadableFilterName="Source"
          styles={styles}
          tooltip="Highlights the source seen most frequently in your saved search results"
        />
      }
    </Dashboard>
  );
};
