import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  CircularProgress,
  Icon,
  ListItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import style from './alerts.module.scss';
import Messages from '../../constants/Messages';
import Text from '../../utils/text';
import Common from '../../utils/common';
import Infinite from '../utils/Infinite/Infinite';
import Invalid from '../utils/Invalid/Invalid';
import ToolTip from '../utils/Ellipsis';
import ContextMenuTrigger from '../utils/ContextMenuTrigger';
import AlertingStore from '../../stores/recoil/alerting';
import Query from '../../containers/Alerting/query';
import History from '../../utils/history';
import useDidUpdate from '../../hooks/useDidUpdate';

const Alerts = ({
  prm,
  user,
}) => {
  const [dialog, setDialog] = useState();
  const [hasWritePerm, setHasWritePerm] = useState(false);
  const [loaded, setLoaded] = useState(list());
  const [loadedIndex, setLoadedIndex] = useState(0);
  // isCurrentlyLoading is the only reliable way to tell if the alerts request has come back
  const [isCurrentlyLoading, setIsCurrentlyLoading] = useState(false);

  const [alerts, setAlerts] = useRecoilState(AlertingStore.alerts);
  const basetypes = useRecoilValue(AlertingStore.basetypes);
  const filters = useRecoilValue(AlertingStore.alertsFilters);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);
  const recipients = useRecoilValue(AlertingStore.notificationProfiles);
  const type = useRecoilValue(AlertingStore.type);

  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition
    ? wrapper(children)
    : children
  );

  const ownerIdMatchesRecipientOrg = () => {
    const ownerId = filters?.get('owner_id');
    if (!ownerId || !recipients) {
      return true;
    }
    const recipient = recipients?.get('data')?.find(v => (v?.get('owner_id') === ownerId));
    if (!recipient) {
      return false;
    }
    return true;
  };

  const loadAlerts = async (currentAlerts) => {
    // Set alerts to empty if there is an ownerId mistmatch
    if (!ownerIdMatchesRecipientOrg()) {
      const oldAlerts = alerts?.toJS();
      setAlerts(fromJS({
        ...oldAlerts,
        data: [],
        size: 0,
        no_profile: true,
      }));
      return;
    }

    setIsCurrentlyLoading(true);

    // pass in alerts when changing location because "alerts" haven't been set
    // at time of call
    const toUse = currentAlerts || alerts;
    await Promise.all([
      Query.loadAlerts(user.get('email'), toUse.get('cursor_id'), type === 'dea', toUse, recipients.get('data', list()), keywordClasses, basetypes, prm),
    ]).then(([res]) => {
      if (res === 'canceled') return;
      setAlerts(res);
    })
      .finally(() => {
        setIsCurrentlyLoading(false);
      });
  };

  const onAction = async (event, action, row) => {
    event.preventDefault();
    const tags = row.get('tags') || map();
    let promise;
    if (!tags.has(action)) {
      promise = Query.saveAlertTags('add', [{
        alert_id: row.get('alert_id'),
        tags: tags.set(action, null).toJS(),
      }], alerts.get('data'));
    } else {
      promise = Query.saveAlertTags('delete', [{
        alert_id: row.get('alert_id'),
        tags: { [action]: null },
      }], alerts.get('data'));
    }
    await Promise.all([
      promise,
    ]).then(([res]) => {
      const updatedAlerts = alerts.setIn(['data', res.index], res.alert);
      setAlerts(updatedAlerts);
    });
  };

  const onLoadMoreAlerts = () => {
    if (isCurrentlyLoading) return;
    if (alerts.get('cursor_id')) {
      loadAlerts();
    }
  };

  const tooltip = (v) => {
    const stripped = (v) ? Text.StripHtml(v) : '-';
    return (
      `<span
        data-for="global.tooltip"
        data-tip="${Text.StripQuotes(Text.Strip(stripped))}">${stripped}
      </span>`
    );
  };

  // `args` can be any number of types, impossible to validate
  /* eslint-disable security/detect-object-injection */
  function memoize(fn) {
    return function memoizeFnc(...args) {
      // eslint-disable-next-line no-param-reassign
      fn.cache = fn.cache || {};
      if (fn.cache[args]) {
        return fn.cache[args];
      }
      // eslint-disable-next-line no-param-reassign
      fn.cache[args] = fn.apply(this, args);
      return fn.cache[args];
    };
  }
  /* eslint-enable security/detect-object-injection */

  const route = row => (
    Common.Generic.Route(row.get('source'), {
      query: row.get('keyword_text'),
    })
  );

  const mergeRowAttributes = (row) => {
    let details = list(row.getIn(['details', 1]));
    const mediaHash = row.getIn(['source', 'media', 'sha1']);
    if (mediaHash) {
      details = details.push(fromJS({ fieldName: 'Media', value: mediaHash }));
    }
    return details;
  };

  const columns = () => {
    switch (type) {
      case 'alerts':
      case 'dea':
        return [
          { id: 'id',
            label: '#',
            render: (v, k) => k + 1,
          },
          { id: 'time_stamp',
            label: 'Date (UTC)',
            labels: 'Alert date',
            text: 'Date alert was created',
            sort: true,
            render: v => (
              <span>
                {moment.utc(v.getIn(['time_stamp']) * 1000).format('MMM D, YYYY')}
                <small>{` ${moment.utc(v.getIn(['time_stamp']) * 1000).format('HH:mm')}`}</small>
              </span>),
          },
          { id: 'detail',
            label: 'Details',
            text: 'Details for result',
            contextMenu: {
              items: v => (mergeRowAttributes(v))
                .map(f => fromJS({
                  action: ['copy'],
                  field: [],
                  fieldName: f.get('fieldName'),
                  fieldValue: f.get('value'),
                })),
            },
            tooltip: memoize(v => v.getIn(['details', 0])),
            render: memoize(v => (
              Text.Highlight(
                mergeRowAttributes(v)
                  .filter(_v => _v.get('value'))
                  .map(_v => `<b>${_v.get('fieldName')}</b>: ${tooltip(_v.get('value'))}<Icon class="material-icons icon">${_v.get('icon', '')}</Icon>`)
                  .join('<br/>'), true)
            )),
          },
          { id: 'keyword_details',
            label: 'Keyword',
            labels: 'keyword_details',
            text: 'Keyword',
            render: memoize(v => Text.Highlight(
              `${v.getIn(['source', 'account_name']) ? `<b>Name</b>: ${tooltip(v.getIn(['source', 'account_name']))}` : `<b>Name</b>: ${tooltip(v.getIn(['keyword_name']))}`}<br/>
            ${v.getIn(['source', 'basetypes']).includes('cloud') ? '' : `<b>Keyword</b>: ${tooltip(v.getIn(['keyword_text']))}`}<br/>
            ${v.getIn(['source', 'basetypes']).includes('shodan') ? `<b>IP Address</b>: ${tooltip(v.getIn(['keyword_ip']))}` : ''}`
              , true)),
          },
          { id: 'highlights',
            label: 'Highlights',
            labels: 'highlights',
            text: 'Highlights',
            class: 'description',
            tooltip: memoize(v => (Text.Strip(v.getIn(['highlights', 0]) || '-')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('')),
            render: memoize(v => (
              Text.Highlight(
                Text.Trim(
                  Text.StripCss(
                    v.getIn(['highlights', 0]) || '-'), 500), true))),
          },
          { id: 'tags',
            label: 'Tags',
            actions: true,
            text: 'Tags',
            render: (row) => {
              const isFlagged = (row.get('tags') || map()).has('flagged');
              const isArchived = (row.get('tags') || map()).has('archived');
              return (
                <div>
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    onClick={e => onAction(e, 'flagged', row)}
                    data-for="global.tooltip"
                    data-testid="alerts.actions-star"
                    data-tip={isFlagged ? 'Unstar Alert' : 'Star Alert'}>
                    {(row.get('tags') || map()).has('flagged') ? 'star' : 'star_border'}
                  </Icon>
                  <Icon
                    className={cx([style.icon, 'material-icons'])}
                    onClick={e => onAction(e, 'archived', row)}
                    data-for="global.tooltip"
                    data-testid="alerts.actions-archive"
                    data-tip={isArchived ? 'Unarchive Alert' : 'Archive Alert'}>
                    {(row.get('tags') || map()).has('archived') ? 'archive' : 'move_to_inbox'}
                  </Icon>
                  {row.getIn(['source', 'enriched_secrets'])?.size ?
                    <Icon
                      className={cx(['material-icons'])}
                      data-for="global.tooltip"
                      data-tip="Secret Detected">
                      lock
                    </Icon> : ''}
                </div>
              );
            } },
        ];
      case 'keywords':
      default:
        return [];
    }
  };

  const onCellClick = (rowIndex, colIndex) => {
    const usingColumns = columns();
    const column = usingColumns[Number(colIndex)];
    const row = loaded.get(rowIndex);
    const shodanUrl = row.getIn(['source', 'shodan_url']);
    if (!column.actions) {
      const url = shodanUrl || route(loaded.get(rowIndex));
      if (loaded.get(rowIndex).get('basetypes').includes('cloud')) return; // check if basetypes is cloud, exit conditional
      History.navigateTo(url);
    }
  };

  const alertPrereqsNotMet = () => !recipients || recipients.isEmpty() || !user.get('email');

  useDidUpdate(() => {
    if (alertPrereqsNotMet()) return;
    if (!alerts || alerts.isEmpty()) {
      setAlerts(map());
      loadAlerts();
    }
  }, [recipients.toJS()]);

  useEffect(() => {
    if (alertPrereqsNotMet()) return;
    setAlerts(map());
    loadAlerts(map());
  }, [type, filters]);

  useEffect(() => {
    const value = (alerts && alerts.has('data')) ? alerts.get('data') : list();
    setLoadedIndex(0);
    setLoaded((value || list()).slice(0, 25));
  }, [type]);

  useEffect(() => {
    if (prm.some(p => /kw.*.w|np.*.w/.test(p))) {
      setHasWritePerm(true);
    }
  }, [prm]);

  const noProfileFound = (
    <div className={style.none}>
      <Paper className={style.profile}>
        <div className={style.body}>
          <div className={cx([style.h0, 'h0', style.raj, 'raj'])}>No Alerting Profile Found</div>
          <div>
            Please visit the
            <div
              role="link"
              name="recipients"
              tabIndex={0}
              onKeyUp={() => null}
              onClick={() => History.replace('/home/alerting/recipients')}
              className={cx([style.a, 'a'])}>
              recipients tab
            </div>
            to add your email address or reach out to your customer
            success representative.
          </div>
          {user.get('prm').some(p => /dat.rta.r/.test(p)) &&
          <div>
            Select a recipient to view the alerts they have access to
            <div>
              <Button
                color="primary"
                variant="contained"
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                onClick={event => setDialog({ key: 'profile', target: event.target })}
                name="notification_profile_select">
                Select
              </Button>
              <Popover
                anchorEl={dialog && dialog.target}
                open={Boolean(dialog && dialog.key === 'profile')}
                onClose={() => setDialog()}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <ListItem
                  key="empty"
                  disabled
                  value=""
                  name="notification_profile_select.empty"
                  className={cx([style.item])}>
                  Select
                </ListItem>
                {recipients
                  .get('data', list())
                  .map(v => (
                    <ListItem
                      key={v.get('id')}
                      value={v.get('id')}
                      name={`notification_profile_select.${v.get('id')}`}
                      onClick={() => {
                        setDialog();
                        const { pathname, query } = History.getCurrentLocation();
                        History.push({
                          pathname,
                          query: { ...query, notification_profile: v.get('id', '') },
                        });
                      }}>
                      {v.get('endpoint', '')}
                    </ListItem>))}
              </Popover>
            </div>
          </div>}
        </div>
      </Paper>
    </div>);

  const noResultsFound = (<Invalid
    inline
    icon="notifications"
    title={Messages.AlertsEmpty}
    help={['Try modifying your filters', 'Try subscribing to a new keyword']} />);

  const infiniteResultsTable = (
    <Infinite
      data={alerts.get('data')}
      maxRecords={50}
      setLoaded={setLoaded}
      setIndex={setLoadedIndex}
      requestCount={25}
      offset={alerts.getIn(['data', loadedIndex + 25, 'alert_id'])}
      hasPrev={false}
      hasNext={!!alerts.get('cursor_id')}
      loadNext={() => onLoadMoreAlerts()}
      loadPrev={() => false}>
      {loaded.size === 0 &&
      <div />}
      {loaded.size > 0 &&
      <TableContainer onMouseEnter={() => ReactTooltip.rebuild()}>
        <Table>
          <TableHead>
            <TableRow>
              {columns().map((col) => {
                if (col.actions && !hasWritePerm) return null;
                return (
                  <TableCell
                    key={col.id}
                    style={col.style}
                    className={col.className}>
                    {col.text &&
                      <div
                        data-for="global.tooltip"
                        data-tip={col.text}
                        className={cx([col.sort ? style.sort : null])}
                        name={`table.header.${col.label}`}>{col.label}
                      </div>}
                    {!col.text && <div>{col.label}</div>}
                    {col.sort && filters.hasIn(['sort', 'value']) &&
                    <Icon>
                      {`keyboard_arrow_${filters
                        .getIn(['sort', 'value'])
                        .split(':')[1] === 'asc' ? 'up' : 'down'}`}
                    </Icon>}
                  </TableCell>);
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loaded
              .map((row, rowIndex) => (
                <TableRow
                  hover
                  key={row.get('alert_id')}
                  name={row.get('alert_id')}>
                  {columns().map((column, columnIndex) => {
                    const isCloud = loaded.get(rowIndex).get('basetypes').includes('cloud');
                    const queryObj = route(row);
                    let href = '';
                    if (typeof queryObj === 'string') {
                      href = queryObj;
                    } else {
                      const query = History.buildSearchString(queryObj.query);
                      const hash = queryObj.hash || '';
                      href = queryObj.pathname + query + hash;
                    }
                    if (column.actions && !hasWritePerm) return null;
                    return (
                      <TableCell
                        key={column.id}
                        style={column.style}
                        className={cx([column.class, isCloud && style.disableRow])}
                        onClick={() => onCellClick(rowIndex, columnIndex)}>
                        <ConditionalWrapper
                          condition={(column.contextMenu && row.getIn(['details', 1], list()).size > 0)}
                          wrapper={children => <ContextMenuTrigger id="global.context" type={type} data={row} options={column.contextMenu}><div>{children}</div></ContextMenuTrigger>}>
                          <a
                            href={href}
                            onClick={e => e.preventDefault()}
                            className={isCloud && column.id !== 'tags' ? style.disableLink : undefined}
                            >
                            <ToolTip
                              rowId={row.get('alert_id')}
                              colId={column.id}
                              col={column}
                              row={row}
                              index={rowIndex + loadedIndex} />
                          </a>
                        </ConditionalWrapper>
                      </TableCell>);
                  })}
                </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>}
    </Infinite>
  );

  return (
    <Grid fluid className={style.alerts}>
      <Row>
        <Col xs={12}>
          <div className={cx([style.card, alerts.has('total') && alerts.get('total') && loaded.size > 0 && style.loaded])}>
            { (() => {
              if (alerts.has('total')) {
                if (alerts.get('total') > 0) {
                  return infiniteResultsTable;
                } else if (alerts.get('total') === 0) {
                  if (alerts.has('no_profile')) {
                    return noProfileFound;
                  }
                  return noResultsFound;
                }
              }
              return <CircularProgress />;
            })() }
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

Alerts.propTypes = {
  prm: PropTypes.object,
  user: PropTypes.object,
};

Alerts.defaultProps = {
  prm: list(),
  user: map(),
};

export default Alerts;
