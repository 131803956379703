const MediaTypes = {
  'application/octet-stream': '.bin',
  'application/ogg': '.ogg',
  'application/pdf': '.pdf',
  'application/vnd.android.package-archive': '.apk',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xslx',
  'application/vnd.ms-excel': '.xls',
  'application/x-7z-compressed': '.7z',
  'application/x-bittorrent': '.torrent',
  'application/x-font-ttf': '.ttf',
  'application/x-ms-dos-executable': '.exe',
  'application/x-dosexec': '.exe',
  'application/x-executable': '',
  'application/x-xpinstall': '.xpi',
  'application/rar': '.rar',
  'application/x-rar': '.rar',
  'application/zip': '.zip',
  'application/x-tar': '.tar',
  'application/msword': '.doc',
  'audio/aac': '.aac',
  'audio/x-hx-aac-adts': '.aac',
  'audio/mp3': '.mp3',
  'audio/m4a': '.m4a',
  'audio/x-m4a': '.m4a',
  'audio/mp4': '.m4a',
  'audio/mpeg': '.mpeg',
  'audio/ogg': '.ogg',
  'audio/wav': '.wav',
  'audio/x-wav': '.wav',
  'document/webp': '.webp',
  'image/': '.jpg',
  'image/gif': '.gif',
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/webp': '.webp',
  'photo/jpeg': '.jpg',
  'video/': '.mp4',
  'video/3gpp': '.3gpp',
  'video/3gpp2': '.3g2',
  'video/mp4': '.mp4',
  'video/ogg': '.ogv',
  'video/mpeg': '.mpeg',
  'video/quicktime': '.mov',
  'video/webm': '.webm',
  'video/x-flv': '.flv',
  'video/x-ms-asf': '.wmv',
  'video/x-msvideo': '.avi',
  'text/html': '.html',
  'text/plain': '.txt',
  'text/matlab': '.m',
  'text/x-matlab': '.m',
  'text/x-c': '.c',
  'text/x-makefile': '.make',
  'application/x-shellscript': '.sh',
  'application/x-sharedlib': '.so',
  'application/x-makefile': '',
  'application/x-coredump': '',
  'application/xml': '.xml',
  'application/json': '.json',
};

const SafeSearchTypes = {
  STRICT: '1::Strict',
  MODERATE: '3::Moderate',
  NONE: '5::None',
};

export {
  MediaTypes,
  SafeSearchTypes,
};
