import Reflux from 'reflux';

const CredentialsActions = Reflux.createActions({
  addCustomers: {},
  deleteCustomers: {},
  getCustomerReplaceUrl: {},
  getCustomerImportStatus: {},
  getCustomerStats: {},
  getSettings: {},
  saveSettings: {},
  testSettings: {},
  uploadCustomerFile: {},
  parseFilters: {},
  set: {},
});

export default CredentialsActions;
