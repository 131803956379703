import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import {
  Icon,
  ListItem,
  ListItemIcon,
  Popover,
} from '@mui/material';
import { useRecoilValue } from 'recoil';

import style from './aggregations.module.scss';
import Hero from '../widget/Hero/Hero';
import Text from '../../utils/text';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import AlertingStore from '../../stores/recoil/alerting';

const Aggregations = ({
  allowedCurated,
  editable,
  filters,
  onFilter,
}) => {
  const [curatedData, setCuratedData] = useState(map());
  const [data, setData] = useState(map());
  const [dialog, setDialog] = useState();
  const [options, setOptions] = useState([]);

  const owner = useRecoilValue(AlertingStore.owner);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);
  // const keywords = useRecoilValue(AlertingStore.keywords);

  const stats = {
    patterns: keywordClasses
      .toJS()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(v => ({
        key: ['keyclass', 'name'],
        label: Text.Sentence(v.name),
        value: v.id,
        name: v.name,
      })),
  };

  const onToggle = (evt, value, toggle = true) => {
    evt.preventDefault();
    const limit = 5;
    if (toggle && options.length >= limit) {
      SearchActions.set(['search', 'info', 'message'], Messages.QuickFilterError);
      return false;
    }
    const opts = toggle
      ? [...options, value]
      : options.filter(v => v !== value);
    setOptions(opts);
    return UserActions.savePrefs({ alerting: { keywords: { filters: opts } } });
  };

  useEffect(() => {
    const opts = filters.isEmpty()
      ? ['pattern', 'threat_actor', 'fqdn', 'name', 'person']
      : filters.toJS();
    setOptions(opts);
  }, [filters]);

  const genFilteredCuratedData = (curatedStatsByKeyclass) => {
    // Filter keyword curated stats by keyclass, and gen curated data metrics post-filtering
    const keywordClassIds = keywordClasses.map(kc => kc.get('id'));

    const curatedStats = {
      automatedActive: 0,
      automatedInactive: 0,
      curatedActive: 0,
      curatedInactive: 0,
    };
    for (const [k, v] of curatedStatsByKeyclass.entries()) {
      if (keywordClassIds.includes(k)) {
        curatedStats.automatedActive += v.get('automated_active', 0);
        curatedStats.automatedInactive += v.get('automated_inactive', 0);
        curatedStats.curatedActive += v.get('curated_active', 0);
        curatedStats.curatedInactive += v.get('curated_inactive', 0);
      }
    }
    return curatedStats;
  };

  useEffect(() => {
    if (!owner.get('keyword_statistics')) return;
    setData(owner.get('keyword_statistics', map()));
    setCuratedData(genFilteredCuratedData(owner.get('keyword_curated_statistics_by_keyclass') || map()));
  }, [owner]);

  return (
    <Grid fluid className={style.aggregations}>
      {!data.isEmpty() &&
      <Row>
        <Col xs={4}>
          <div className={style.charts}>
            <Hero
              text
              key="automated"
              labels={fromJS(['Automated Keywords'])}
              values={fromJS([{ key: 'value' }])}
              results={fromJS({
                value: `${curatedData.automatedActive}/${curatedData.automatedInactive}`,
              })}
              styles={{ width: '50%' }}
              tooltip="Active/Inactive"
              onClick={() => onFilter('delivery', 'AUTOMATED')} />
            <Hero
              text
              key="curated"
              labels={fromJS(['Curated Keywords'])}
              values={fromJS([{ key: 'value' }])}
              results={fromJS({
                value: `${curatedData.curatedActive}/${allowedCurated}`,
              })}
              styles={{ width: '50%', ...(curatedData.curatedActive > allowedCurated && { color: 'red' }) }}
              tooltip="Active/Allowed"
              onClick={() => onFilter('delivery', 'CURATED')} />
          </div>
        </Col>
        <Col xs={8}>
          <div className={style.charts}>
            {stats.patterns
              .filter(v => options.includes(v.name))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(pattern => (
                <Hero
                  text
                  key={pattern.label}
                  labels={fromJS([pattern.label])}
                  values={fromJS([{ key: 'value' }])}
                  results={fromJS({
                    value: `${data
                      .getIn([pattern.value, 'active'], 0)}/${(data
                        .getIn([pattern.value, 'total'], 0)) - data
                        .getIn([pattern.value, 'active'], 0)}`,
                  })}
                  styles={{ width: '50%' }}
                  tooltip="Active/Inactive"
                  onClick={() => onFilter('category', pattern.name)} />))}
            {editable &&
            <div className={style.edit}>
              <Icon
                onClick={event => setDialog({ key: 'pattern', target: event.target })}>
                edit
              </Icon>
              <Popover
                anchorEl={dialog && dialog.target}
                onClose={() => setDialog()}
                open={Boolean(dialog && dialog.key === 'pattern')}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                {stats.patterns.map(pattern => (
                  <ListItem
                    key={pattern.value}
                    onClick={event => onToggle(
                      event,
                      pattern.name,
                      !options.includes(pattern.name),
                    )}>
                    <ListItemIcon>
                      <Icon>
                        {options.includes(pattern.name)
                        ? 'check'
                        : ''}
                      </Icon>
                    </ListItemIcon>
                    {pattern.label}
                  </ListItem>))}
              </Popover>
            </div>}
          </div>
        </Col>
      </Row>}
      <ReactTooltip id="aggregations.tooltip" html place="right" effect="solid" />
    </Grid>
  );
};

Aggregations.propTypes = {
  allowedCurated: PropTypes.number,
  editable: PropTypes.bool,
  filters: PropTypes.object,
  onFilter: PropTypes.func,
};

Aggregations.defaultProps = {
  allowedCurated: 0,
  editable: false,
  filters: list(),
  onFilter: null,
};

export default Aggregations;
