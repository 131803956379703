import * as React from 'react';
import * as PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  delete: {
    paddingLeft: '2.5rem',
  },
  button: {
    margin: '1%',
  },
}));

interface DeleteBinActionsProps {
  close: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  confirmed: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const DeleteBinConfirm = ({ close, confirmed }: DeleteBinActionsProps) => {
  const classes = useStyles();
  return (
    <div>
      <p className={classes.delete}>
        Are you sure you want to delete this bin?
      </p>
      <Button className={classes.button} onClick={confirmed}>Yes</Button>
      <Button onClick={close}>No</Button>
    </div>
  );
};

DeleteBinConfirm.propTypes = {
  close: PropTypes.func.isRequired,
  confirmed: PropTypes.func.isRequired,
};

export default DeleteBinConfirm;
