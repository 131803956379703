import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Chip,
  CircularProgress,
  Icon,
  Paper,
  Switch,
} from '@mui/material';

import style from './cards.module.scss';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import InternalLink from '../utils/InternalLink';
import { UserContext } from '../utils/Context';

const Cards = ({ data }) => {
  const user = useContext(UserContext);

  const [showBody, setShowBody] = useState(false);

  const onSearch = (k, v) => {
    const pathname = '/home/search/cards';
    const query = { query: '', [k]: v };
    return { pathname, query };
  };

  return (
    <Grid
      fluid
      name="component.cards"
      className={cx([style.base, style.cards])}>
      {data.isEmpty() &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {!data.isEmpty() && data.has('id') &&
      <Row>
        <Col xs={12} className={style.header}>
          <div>
            <div
              name="cards.header.title"
              className={cx([style.h4, 'h4', style.cap, 'cap'])}>
              {Text.StripHighlight(data.getIn(['site', 'title']))}
            </div>
            {data.getIn(['site', 'description', 'raw']) &&
            <Icon
              name="cards.header.description"
              data-for="market.tooltip"
              data-tip={data
                .getIn(['site', 'description', 'raw'])
                .match(/(.{1,75})(?:\n|$| )/g)
                .join('<br />')}
              className={style.help}>
              info_outline
            </Icon>}
          </div>
          <div className={style.title}>
            <Icon className={style.icon}>
              credit_card
            </Icon>
            <div
              className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
              name="cards.body.title">
              {Text.Highlight(data.get('title'))}
            </div>
          </div>
          {data.getIn(['sort_date']) &&
          <div>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Date:</div>
              <div>
                {moment.utc(data.getIn(['sort_date'])).format('MMM D, YYYY')}
              </div>
            </div>
            <Icon
              name="cards.sort_date"
              data-for="date.tooltip"
              data-tip={`This field is the first date that Flashpoint has seen this card.<br />
                This date field will not change if we re-scrape<br />
                the page and observe the same account a second time.`}
              className={style.help}>
              help
            </Icon>
          </div>}
          {data.getIn(['base', 'release_date', 'timestamp']) &&
          <div>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Release Date:</div>
              <div>
                {data.getIn(['base', 'release_date', 'timestamp'])
                  ? moment.utc(data.getIn(['base', 'release_date', 'timestamp']) * 1000).format('MMM D, YYYY')
                  : '-'}
              </div>
              <Icon
                name="cards.date.tooltip"
                data-for="offline.tooltip"
                data-tip="The date the card became available on the card shop as part of a release."
                className={style.help}>
                help
              </Icon>
            </div>
          </div>}
        </Col>
        <Col xs={12}>
          <Paper className={style.card}>
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Shop:</div>
              <InternalLink
                name="cards.body.title"
                className={cx([data.getIn(['site', 'title']) && style.a, 'a'])}
                to={onSearch('sites', data.getIn(['site', 'title']))}>
                {Text.Highlight(data.getIn(['site', 'title'])) || '-'}
              </InternalLink>
            </div>
            {data.getIn(['basetypes']) &&
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Category:</div>
              <InternalLink
                name="cards.body.category"
                className={cx([style.a, 'a'])}
                to={onSearch('card_category', data.getIn(['basetypes'])
                .toJS()
                .splice(-3)
                .shift())}>
                {data.getIn(['basetypes']).includes('dump')
                  ? 'Card Present (dumps)'
                  : 'Card Not Present (cvv)'}
              </InternalLink>
            </div>}
            <div className={style.table}>
              <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Release:</div>
              <div>
                {data.getIn(['base', 'title']) || '-'}
              </div>
            </div>
            <div className={style.hero}>
              <div>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Price:</div>
                {data.getIn(['prices']) &&
                <div name="cards.body.price">
                  {data.getIn(['prices']).entrySeq().map(([k, v]) => (
                    <div
                      key={k}
                      className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}>
                      {v.getIn(['raw']) ||
                      `${v.getIn(['currency', 'abbreviation'])}${v.getIn(['value'])}` ||
                      '-'}
                    </div>))}
                </div>}
              </div>
            </div>
          </Paper>
          <Paper className={style.card}>
            {data.getIn(['cardholder_information']) && user.getIn(['prefs', 'enable_pii']) &&
            <div className={style.pii}>
              <div className={style.warning}>
                <div
                  data-tip="If you enable, you may be accessing PII data"
                  data-for="body.tooltip"
                  className={cx([style.h4, 'h4', style.mont, 'mont'])}>View Identifying Details:
                </div>
                <Switch
                  color="secondary"
                  className={style.toggle}
                  checked={showBody}
                  onChange={() => setShowBody(!showBody)} />
              </div>
              {showBody &&
              <div className={style.body}>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Payment Methods:</div>
                  <div>{data.getIn(['payment_method']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Expiration:</div>
                  <div>{data.getIn(['expiration']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last 4 Digits:</div>
                  <div>{data.getIn(['last4']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Track Information:</div>
                  <div>{data.getIn(['track_information']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>First Name:</div>
                  <div>{data.getIn(['cardholder_information', 'first']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Last Name:</div>
                  <div>{data.getIn(['cardholder_information', 'last']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>City:</div>
                  <div>{data.getIn(['cardholder_information', 'location', 'city']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Region:</div>
                  <div>{data.getIn(['cardholder_information', 'location', 'region', 'raw']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Country:</div>
                  <div>{data.getIn(['cardholder_information', 'location', 'country', 'raw']) || '-'}</div>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Zip Code:</div>
                  <div>{data.getIn(['cardholder_information', 'location', 'zip_code']) || '-'}</div>
                </div>
              </div>}
            </div>}
            <div className={style.body}>
              <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Card Details</div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Card Type:</div>
                <InternalLink
                  name="cards.body.type"
                  className={cx([data.getIn(['card_type']) && style.a, 'a'])}
                  to={onSearch('card_type', data.getIn(['card_type']))}>
                  {Text.Highlight(data.getIn(['card_type']) || '-')}
                </InternalLink>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Card BIN:</div>
                <InternalLink
                  name="cards.body.bin"
                  className={cx([data.getIn(['bin']) && style.a, 'a'])}
                  to={onSearch('bin', data.getIn(['bin']))}>
                  {data.getIn(['bin']) || '-'}
                </InternalLink>
              </div>
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Bank:</div>
                {data.getIn(['bank_name']) || '-'}
              </div>
              {data.has('is_verified_by_visa') &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Verified By Visa:</div>
                <InternalLink
                  name="cards.body.visa"
                  className={cx([data.getIn(['is_verified_by_visa']) !== undefined && style.a, 'a'])}
                  to={onSearch('is_verified_by_visa', data.getIn(['is_verified_by_visa']))}>
                  {data.get('is_verified_by_visa')}
                </InternalLink>
              </div>}
              {data.has('is_pin_available') &&
              <div className={style.table}>
                <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Has Pin?:</div>
                <div>{data.get('is_pin_available')}</div>
              </div>}
              <div className={cx([style.h2, 'h2', style.mont, 'mont', style.inline])}>
                {Text.Highlight(data.getIn(['site', 'title']))} categories
              </div>
              <div
                className={style.chips}
                name="cards.body.categories">
                {(data.getIn(['site', 'tags']) ||
                  list([map({ name: data.get('category') })]) || list())
                  .sortBy(v => v.get('name'))
                  .filter(v => !['Carding'].includes(v.get('name')))
                  .map(v => (
                    <Chip
                      key={v.get('name')}
                      color="secondary"
                      name={`cards.body.categories.${v.get('name')}`}
                      className={style.chip}
                      icon={<Icon>local_offer</Icon>}
                      label={v.get('name')} />
                  ))}
              </div>
            </div>
          </Paper>
        </Col>
      </Row>}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
      <ReactTooltip id="date.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="offline.tooltip" html place="right" effect="solid" />
      <ReactTooltip id="market.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="body.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

Cards.propTypes = {
  data: PropTypes.object,
};

Cards.defaultProps = {
  data: map(),
};

export default Cards;
