import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import HeightIcon from '@mui/icons-material/Height';
import IconButton from '@mui/material/IconButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import makeStyles from '@mui/styles/makeStyles';
import MaximizeIcon from '@mui/icons-material/Maximize';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    '& .MuiToolbar-gutters': {
      padding: '0 16px 0 16px',
    },
    padding: '0',
    pointerEvents: 'auto',
  },
  header: {
    backgroundColor: '#000',
    height: '64px',
    padding: '0',
  },
  children: {
    padding: '3.2rem 1.6rem 1.6rem 1.6rem',
    overflow: 'scroll',
    height: '400px',
  },
  hide: {
    display: 'none',
  },
  icon: {
    color: '#fff',
  },
  iconButton: {
    padding: '8px',
  },
  maximized: {
    margin: 'auto',
    maxHeight: '464px',
    maxWidth: '800px',
  },
  minimized: {
    bottom: '0',
    maxWidth: '600px',
    position: 'absolute',
    right: '32px',
  },
  normal: {
    bottom: '0',
    maxHeight: '464px',
    maxWidth: '600px',
    position: 'absolute',
    right: '32px',
  },
  rotatedIcon: {
    transform: 'rotate(45deg)',
  },
  title: {
    background: 'none',
    color: '#fff',
    flexGrow: '1',
    fontSize: '2.4rem',
    fontWeight: '600',
    marginLeft: '.5rem',
    textAlign: 'left',
  },
}));

const DialogStates = {
  Minimized: 'minimized',
  Normal: 'normal',
  Maximized: 'maximized',
};

/** A generic dialog box used for displaying information */
const RfiDialog = ({ state, title, onClose, children }) => {
  const [dialogState, setDialogState] = useState('normal');
  const classes = useStyles();

  useEffect(() => setDialogState(state), [state]);

  const StateStyles = {
    minimized: classes.minimized,
    normal: classes.normal,
    maximized: classes.maximized,
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleMaximize = () => {
    if (dialogState !== DialogStates.Maximized) {
      setDialogState(DialogStates.Maximized);
      return;
    }
    setDialogState(DialogStates.Normal);
  };

  return (
    <Paper className={[classes.root, StateStyles[String(dialogState)]].join(' ')} elevation={4}>
      <AppBar className={classes.header} position="relative">
        <Toolbar>
          <AssignmentIcon />
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
          {
            (dialogState === DialogStates.Normal) ?
              <IconButton
                className={classes.iconButton}
                onClick={() => setDialogState(DialogStates.Minimized)}
                size="large">
                <MinimizeIcon className={classes.icon} />
              </IconButton> :
              <IconButton
                className={classes.iconButton}
                onClick={() => setDialogState(DialogStates.Normal)}
                size="large">
                <MaximizeIcon className={classes.icon} />
              </IconButton>
          }
          <IconButton className={classes.iconButton} onClick={handleMaximize} size="large">
            <HeightIcon className={[classes.icon, classes.rotatedIcon].join(' ')} />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={handleClose} size="large">
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={dialogState === 'minimized' ? classes.hide : null}>
        <div className={classes.children}>
          {children}
        </div>
      </div>
    </Paper>
  );
};

RfiDialog.propTypes = {
  /** Display state of the dialog. */
  state: PropTypes.string,

  /** Title that appears in the header bar */
  title: PropTypes.string,

  /** Called when the user closes the dialog */
  onClose: PropTypes.func,

  /** The dialog's content. Appears under the header bar */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

RfiDialog.defaultProps = {
  state: DialogStates.Minimized,
  title: 'Request for Information',
  onClose: null,
  children: <p>This dialog is empty</p>,
};

export default RfiDialog;
