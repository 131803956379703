import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import style from './mediaGridImage.module.scss';
import ContextMenuTrigger from '../utils/ContextMenuTrigger';
import Text from '../../utils/text';

const MediaGridImage = ({
  contextMenuOptions,
  overlay,
  photo,
  post,
}) => (
  <div className={cx([style['media-grid-image']])}>
    <img
      alt={photo.title}
      loading="lazy"
      {...photo}
    />
    <div
      className={style.overlay}
      onMouseEnter={() => ReactTooltip.rebuild()}
      data-for="global.tooltip"
      data-tip={Text.Strip(overlay)}>
      <ContextMenuTrigger
        id="global.context"
        data={post}
        options={contextMenuOptions}
        triangleStyle={{ borderWidth: '0 17px 17px 0', right: '5px' }}>
        <div />
      </ContextMenuTrigger>
    </div>
  </div>
);

MediaGridImage.propTypes = {
  contextMenuOptions: PropTypes.object.isRequired,
  overlay: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default MediaGridImage;
