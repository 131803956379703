import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Token from '../utils/token';

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${Token.cke()}`,
  },
}));

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(createHttpLink({
    uri: '/graphql',
  })),
});

const devClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(createHttpLink({
    uri: '/graphql/dev',
  })),
});

export {
  client,
  devClient,
};
