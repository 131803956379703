import * as React from 'react';
import { useEffect, useState } from 'react';

import { pipe } from 'lodash/fp';

import { makeStyles } from '@mui/styles';

import type { ExpoData } from '../TopRowEditDialog';
import type { SavedSearch } from '../../../../../core/types/SavedSearch';
import { dedupe } from '../../Widgets/RecentSearchesWidget/RecentSearchesWidget';
import { SavedSearchesMenu, TabForm } from '../TabForm';
import { EditWidgetDialog } from '../EditWidgetDialog';
import { getTabNumber, getTabString } from '../shared/DialogUtils';
import type { WidgetEntry } from '../../../../../core/types/ExpoDashPreferences';
import { TabDatum } from '../../../../../core/interfaces/ExpoDash';

interface CenterRowEditDialogProps {
  data: ExpoData;
  open: boolean;
  settings: WidgetEntry,
  selectedTab?: number;
  onCancel: () => void;
  onApply: (userPrefsCandidate: object) => void;
  defaultQuery: string;
}

const TabStrings = [
  'media',
  'my_alerts',
  'my_recent_searches',
  // 'my_saved_searches',
  'top_actors',
  'top_sources',
];

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '1.6rem',
    fontWeight: 300,
  },
}));

export const CenterRowEditDialog = ({
  data,
  open,
  selectedTab,
  settings,
  onCancel,
  onApply,
  defaultQuery,
}: CenterRowEditDialogProps): JSX.Element => {
  const [tab, setTab] = useState<number>(0);
  const [widgetSettings, setWidgetSettings] = useState<WidgetEntry>({});
  const classes = useStyles();

  useEffect(() => {
    if (settings) {
      setWidgetSettings({ ...settings });
    }
  }, [settings]);

  const handleSavedSearchChange = (value: SavedSearch) => {
    setWidgetSettings({
      ...widgetSettings,
      savedSearch: value,
      type: getTabString(tab, TabStrings),
    });
  };

  const mediaSearches = (savedSearches: SavedSearch[]): SavedSearch[] => savedSearches
    .filter((v: SavedSearch) => ['media', 'images', 'videos'].includes(v.type.searchType));

  /*
   * To Add Tab Content
   * Replace the `content` fields in the array below with your React component:
   * ```
   * {
   *   ...
   *   content: <TopSourceTab data={data} />
   * }
   * ```
   * NOTE: These will need to be renumbered when they are all available
  */
  const tabData: TabDatum[] = [
    {
      id: 0,
      label: 'Media',
      content: (): JSX.Element => (
        <>
          <TabForm
            title="Media"
            description="Displays the ten most recent media hits for your saved search."
            savedSearch={
              <SavedSearchesMenu
                rows={pipe(
                  mediaSearches,
                  dedupe,
                  )(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
                savedSearch={widgetSettings?.savedSearch}
                updateSavedSearch={handleSavedSearchChange}
                />
            }
          />
          <p>
            NOTE: Only saved searches that include media as a source are
            {' '}available in the dropdown for this widget
          </p>
        </>
      ),
    },
    {
      id: 1,
      label: 'My Alerts',
      content: (): JSX.Element => (
        <>
          <h2 className={classes.title}>My Alerts</h2>
          <p>Trend information around your subscribed alerts.</p>
        </>
      ),
    },
    {
      id: 2,
      label: 'My Recent Searches',
      content: (): JSX.Element => (
        <>
          <h2 className={classes.title}>My Recent Searches</h2>
          <p>Quick access to your recent searches.</p>
        </>
      ),
    },
    // {
    //   id: 3,
    //   label: 'My Saved Searches',
    //   content: (): JSX.Element => (
    //     <>
    //       <h2 className={classes.title}>My Saved Searches</h2>
    //       <p>Quick access to your saved searches.</p>
    //     </>
    //   ),
    // },
    {
      id: 3,
      label: 'Top Actors',
      content: (): JSX.Element => (
        <TabForm
          title={`Top Actors for ${widgetSettings?.savedSearch?.name || defaultQuery}`}
          description="Trend information around the actors mentioning your saved searches most frequently."
          savedSearch={
            <SavedSearchesMenu
              rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
              savedSearch={widgetSettings?.savedSearch}
              updateSavedSearch={handleSavedSearchChange}
            />
          }
        />
      ),
    },
    {
      id: 4,
      label: 'Top Sources',
      content: (): JSX.Element => (
        <TabForm
          title={`Top Sources for ${widgetSettings?.savedSearch?.name || defaultQuery}`}
          description="Trend information around the sources seen most frequently in your saved search results."
          savedSearch={
            <SavedSearchesMenu
              rows={dedupe(data?.preferences?.search?.data || []) as unknown as SavedSearch[]}
              savedSearch={widgetSettings?.savedSearch}
              updateSavedSearch={handleSavedSearchChange}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      setTab(selectedTab);
    }
    if (widgetSettings) {
      const { type } = widgetSettings;
      const openTab = getTabNumber(type, TabStrings);
      setTab(openTab);
    }
  }, [selectedTab, widgetSettings]);

  const handleApply = (_tab: number): void => {
    if (onApply) {
      const t = getTabString(_tab, TabStrings);
      onApply({
        ...widgetSettings,
        type: t,
      });
    }
    onCancel();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  return (
    <EditWidgetDialog
      open={open}
      selectedTab={tab}
      tabData={tabData}
      onApply={handleApply}
      onCancel={handleCancel} />
  );
};

CenterRowEditDialog.defaultProps = {
  selectedTab: null,
};
