import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Typography,
} from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import { fromJS } from 'immutable';

import ImDialog from './ImDialog';
import SettingsList from '../shared/SettingsList';
import AlertingStore from '../../../../stores/recoil/alerting';
import Prompt from '../../../utils/Prompt';
import Messages from '../../../../constants/Messages';
import Query from '../../../../containers/Alerting/query';
import { loadAllIpAddresses, saveIpAddress, deleteIpAddress } from './query';
import SearchActions from '../../../../actions/searchActions';

const InfrastructureMonitoring = ({ user, keywordClass, disabled }) => {
  const [dialog, setDialog] = useState({});
  const [ipAddresses, setIpAddresses] = useState([]);
  const [owners, setOwners] = useRecoilState(AlertingStore.owners);
  const [refresh, setRefresh] = useState(false);
  const [selectedIp, setSelectedIp] = useState(null);
  const ownerId = useRecoilValue(AlertingStore.ownerId);

  useEffect(() => {
    Query.load('owners', user.get('prm')).then(res => setOwners(fromJS(res.owners)));
    setOwners(owners);
  }, []);

  useEffect(() => {
    if (!ownerId || !keywordClass) return;
    loadAllIpAddresses(ownerId, keywordClass.id)
      .then(data => data.filter(item => item.active))
      .then(data => (data.sort((a, b) => {
        const left = new Date(a.created_timestamp);
        const right = new Date(b.created_timestamp);
        if (left > right) {
          return -1;
        } else if (left < right) {
          return 1;
        }
        return 0;
      })))
      .then(data => setIpAddresses(data))
      .catch((err) => {
        if (/cancel/ig.test(err.message)) {
          /* Skip */
          return;
        }
        SearchActions.set(['search', 'info', 'message'], Messages.IpFetchError);
        throw err;
      });
  }, [keywordClass, ownerId, refresh]);

  const onSubmit = (data) => {
    saveIpAddress(ownerId, keywordClass.id, data.name, data.address)
      .then((res) => {
        if (res.status === 400) {
          if (/duplicate keyword/ig.test(res.data.title)) {
            throw new Error('Duplicate IP Address Exists');
          }
          throw new Error(`${res.status} ${res.statusText}`);
        }
        return res.data;
      })
      .then(() => setRefresh(!refresh))
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.SettingsUpdated))
      .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
    setDialog(false);
  };

  const onViewClick = (index) => {
    setSelectedIp(ipAddresses[Number(index)]);
    setDialog({ key: 'create' });
  };

  const openAndClearDialog = () => {
    setSelectedIp(null);
    setDialog({ key: 'create' });
  };

  const onDelete = (keyword) => {
    deleteIpAddress(ownerId, keyword.id)
      .then(() => SearchActions.set(['search', 'info', 'message'], Messages.KeywordsDeleted))
      .then(() => setRefresh(!refresh))
      .finally(() => setDialog({}))
      .catch(err => SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(err.message)));
  };

  return (
    <div className="internetInfrastructure">
      <Typography>IP Addresses</Typography>
      <SettingsList
        disabled={disabled}
        items={ipAddresses}
        onView={(item, index) => onViewClick(index)}
        onDelete={item => setDialog({ key: 'delete', value: item })}
        options={{
          emptyMessage: 'Nothing to show. Click on the \'Add New IP Address\' button to get started.',
        }}
      />
      <Button
        onClick={() => openAndClearDialog()}
        variant="contained"
        color="secondary"
        disabled={disabled}
        className="addBtn"
        data-testid="internetinfra.add-ip">
        Add a new IP address
      </Button>
      <ImDialog
        open={dialog?.key === 'create'}
        selectedIp={selectedIp}
        onSubmit={onSubmit}
        onCancel={() => setDialog({})}
        />
      <Prompt
        open={Boolean(dialog?.key === 'delete')}
        title="Warning: Delete IP address?"
        acceptText="Continue"
        accept={() => onDelete(dialog?.value)}
        cancelText="Cancel"
        cancel={() => setDialog({})}>
        You are about to permanently delete this IP address. Are you sure?
      </Prompt>
    </div>
  );
};

InfrastructureMonitoring.propTypes = {
  user: PropTypes.object,
  keywordClass: PropTypes.object,
  disabled: PropTypes.bool,
};

InfrastructureMonitoring.defaultProps = {
  user: null,
  keywordClass: null,
  disabled: false,
};

export default InfrastructureMonitoring;
