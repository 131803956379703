import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';

import cx from 'classnames';
import get from 'lodash/get';
import { Col } from 'react-flexbox-grid/lib';
import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  Icon,
  InputLabel,
  ListItem,
  ListItemIcon,
  Select,
  Typography,
} from '@mui/material';

import style from './homedashboard.module.scss';
import Dashboard from '../Dashboard';
import Hero from '../../widget/Hero/Hero';
import Table from '../../widget/Table/Table';
import Query from './query';
import Common from '../../../utils/common';
import Text from '../../../utils/text';
import History from '../../../utils/history';
import {
  UserContext,
} from '../../../components/utils/Context';

const useStyles = makeStyles(theme => ({
  dashboard: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .MuiInputLabel-outlined': {
      backgroundColor: '#fff',
    },
  },
}));

const HomeDashboard = ({
  profile,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [data, setData] = useState({});
  const [saves, setSaves] = useState([]);
  const [active, setActive] = useState([]);
  const [selection, setSelection] = useState({});
  const [filters, setFilters] = useState(map());
  const [skips, setSkips] = useState({ history: 0, bookmarks: 0, mentions: 0, search: 0 });
  const [limits] = useState({ history: 10, bookmarks: 10, mentions: 10, search: 5 });

  const onLoadHits = async (key = '', searches = []) => {
    const queries = Array.isArray(searches) ? searches : [searches];
    Promise.all([
      ...queries.map(query => Query.Hits(query)),
    ]).then(hits => setData(state => ({
      ...state,
      [key]: hits,
    })));
  };

  const onSelect = (key, skip = skips[String(key)], select = selection) => {
    setSelection(select);
    setSkips({ ...skips, [key]: skip });
    setData({ ...data, [key]: null });
    onLoadHits(key, select);
  };

  useEffect(() => {
    if (profile.equals(filters)) return;
    const saved = user
      .getIn(['prefs', 'search', 'data'], list())
      .filter(v => !/alert/.test(v.getIn(['type', 'value'])))
      .map(v => ({
        ...v.toJS(),
        parsed: {
          ...Common.SavedSearch.Filters(v),
          type: get(Common.Generic.PathToApp(v.getIn(['type', 'path'])), ['searchType'], 'all'),
        },
      }))
      .toJS();
    const pinned = saved
      .filter(v => v.pin)
      .slice(0, 5)
      .map(v => ({
        ...v,
        parsed: {
          ...v.parsed,
          filters: {
            ...v.parsed.filters,
            size: 1,
          },
        },
      }));
    const [selected] = saved
      .sort((a, b) => get(a, 'name', '').localeCompare(get(b, 'name', '')))
      .sort((a, b) => b.pin - a.pin);
    setSaves(saved);
    setFilters(profile);
    onLoadHits('pinned', pinned);
    onSelect('mentions', skips.mentions, selected);
  }, [profile, user]);

  return (
    <React.Fragment>
      <Col xs={6} className={style.containers}>
        <Dashboard data={data} title="Pinned Search Mentions">
          {get(data, ['pinned'], [])
            .map(hit => (
              <Hero
                key={get(hit, ['created'], '')}
                abbreviate
                styles={{ width: '25%' }}
                labels={fromJS([get(hit, ['label'], '')])}
                values={fromJS([{ key: 'total' }])}
                results={fromJS({
                  total: `${get(hit, ['total'], '')}<br/>foo`,
                })}
                onClick={() => Common.SavedSearch.Route(fromJS(hit.source))} />
            ))}
        </Dashboard>
        <Dashboard
          data={data}
          title="Saved Searches"
          settings={() => History.navigateTo('/home/manage/searches')}>
          <Table
            pagination
            onOffset={v => setSkips({ ...skips, search: v })}
            limit={get(limits, ['search'], 0)}
            results={fromJS({
              data: user
                .getIn(['prefs', 'search', 'data'], list())
                .sort((a, b) => a.get('name', '').localeCompare(b.get('name', '')))
                .slice(skips.search, skips.search + limits.search),
              total: user
                .getIn(['prefs', 'search', 'data'], [])
                .size,
              skip: skips.search,
            })}
            values={fromJS([
              {
                key: 'type',
                style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                render: () => (
                  <Icon
                    data-for="icon.tooltip"
                    data-tip="Reports"
                    className="material-icons"
                    style={{ fontSize: '14px' }}>
                    lightbulb_outline
                  </Icon>),
              },
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'value',
                label: 'Search Pattern',
              },
            ])}
            labels={fromJS([])}
            filters={filters}
            onClick={v => History.navigateTo(v)} />
        </Dashboard>
      </Col>
      <Col xs={3} className={style.containers}>
        <Dashboard data={data} title="Recent Activity">
          <Table
            pagination
            onOffset={history => setSkips({ ...skips, history })}
            limit={limits.history}
            results={fromJS({
              data: user
                .getIn(['prefs', 'history', 'items'], list())
                .concat(user.getIn(['prefs', 'history', 'search'], list()))
                .map(v => v.set('type', v.has('value') ? 'search' : 'item'))
                .slice(skips.history, skips.history + limits.history),
              total: user
                .getIn(['prefs', 'history', 'items'], [])
                .concat(user.getIn(['prefs', 'history', 'search'], list()))
                .size,
              skip: skips.history,
            })}
            values={fromJS([
              {
                key: '',
                style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                render: v => (
                  <Icon
                    data-for="icon.tooltip"
                    data-tip={get(Common.Generic.PathToApp(v.get('path')), ['label'], '')}
                    className="material-icons"
                    style={{ fontSize: '14px' }}>
                    {['item'].includes(v.get('type'))
                      ? get(Common.Generic.PathToApp(v.get('path')), ['icon'], '')
                      : 'search' }
                  </Icon>),
              },
              {
                key: 'title',
                label: 'Title',
              },
            ])}
            labels={fromJS([])}
            filters={filters}
            onClick={(v) => {
              const route = map.isMap(v) ? Common.RecentSearch.Activity(v) : v;
              History.navigateTo(route);
            }} />
        </Dashboard>
      </Col>
      <Col xs={3} className={style.containers}>
        <Dashboard
          data={data}
          title="My Bookmarks"
          settings={() => History.navigateTo('/home/manage/bookmarks')}>
          <Table
            pagination
            onOffset={bookmarks => setSkips({ ...skips, bookmarks })}
            widget={fromJS({ limit: limits.bookmarks })}
            results={fromJS({
              data: user
                .getIn(['prefs', 'bookmarks'], [])
                .slice(skips.bookmarks, skips.bookmarks + limits.bookmarks),
              total: user.getIn(['prefs', 'bookmarks'], []).size,
              skip: skips.bookmarks,
            })}
            values={fromJS([
              {
                key: '',
                style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                render: v => (
                  <Icon
                    data-for="icon.tooltip"
                    data-tip={get(Common.Generic.PathToApp(v.get('path')), ['label'], '')}
                    className="material-icons"
                    style={{ fontSize: '14px' }}>
                    {['item'].includes(v.get('type'))
                      ? get(Common.Generic.PathToApp(v.get('path')), ['icon'], '')
                      : 'search' }
                  </Icon>),
              },
              {
                key: 'name',
                label: 'Name',
              },
            ])}
            labels={fromJS([])}
            filters={filters}
            onClick={(v, k, idx) => {
              const path = k.getIn(['data', idx, 'path'], '');
              History.navigateTo(path);
            }} />
        </Dashboard>
      </Col>
      <Col
        xs={12}
        className={cx([
          style.containers,
          classes.dashboard,
        ])}>
        <Dashboard
          data={get(data, ['mentions', 0, 'data'])}
          title="Saved Search Detail"
          settings={() => History.navigateTo('/home/manage/searches')}>
          <div className={style.sections}>
            <div className={style.section}>
              <FormControl
                name="detail"
                variant="outlined"
                className={cx(['dropdown', active.includes('detail') && 'active'])}>
                <InputLabel>Saved Search Selection</InputLabel>
                <Select
                  value={selection}
                  onOpen={() => setActive(state => [...state, 'detail'])}
                  onClose={() => setActive(state => state.filter(v => v !== 'detail'))}
                  onChange={event => onSelect('mentions', 0, event.target.value)}>
                  <ListItem disabled>Select Saved Search...</ListItem>
                  {saves
                  .sort((a, b) => get(a, 'name', '').localeCompare(get(b, 'name', '')))
                  .sort((a, b) => b.pin - a.pin)
                  .map(v => (
                    <ListItem
                      key={`${v.created}.${v.name}`}
                      value={v}
                      className={cx([style.item, selection === v && style.active])}>
                      <ListItemIcon>
                        {!v.pin ? null : <Icon>bookmark</Icon>}
                      </ListItemIcon>
                      <Typography>
                        {Text.Sentence(v.name)}
                      </Typography>
                    </ListItem>))}
                </Select>
              </FormControl>
            </div>
            <Table
              pagination
              onOffset={skip => onSelect('mentions', skip)}
              limit={limits.mentions}
              results={fromJS({
                data: get(data, ['mentions', 0, 'data']),
                total: get(data, ['mentions', 0, 'total'], 0),
                skip: skips.mentions,
              })}
              values={fromJS([
                {
                  key: '',
                  style: { overflow: 'visible', padding: '5px 0 0 0', width: '15px' },
                  render: v => (
                    <Icon
                      data-for="icon.tooltip"
                      data-tip={Text.Sentence(Common.Basetypes.BasetypeToSearchType(v))}
                      className="material-icons"
                      style={{ fontSize: '14px' }}>
                      {Common.Generic.Icon(v.get('basetypes', list()))}
                    </Icon>),
                },
                {
                  key: 'site.title',
                  label: 'Source',
                },
                {
                  key: 'site_actor.names.handle',
                  label: 'Author',
                },
                {
                  key: 'body.text/plain',
                  label: 'Message',
                  render: v => Text.Trim(v, 250),
                },
              ])}
              labels={fromJS([])}
              onClick={v => History.navigateTo(v)} />
          </div>
        </Dashboard>
      </Col>
    </React.Fragment>
  );
};

HomeDashboard.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default HomeDashboard;
