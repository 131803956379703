import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { List as list, Map as map, fromJS } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  Link,
  ListItem,
  OutlinedInput,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useRecoilValue } from 'recoil';

import AlertingStore from '../../stores/recoil/alerting';
import Text from '../../utils/text';
import Pager from '../utils/Pager';
import Subscriptions from './Subscriptions';

const useStyles = makeStyles(() => ({
  keywordsReview: {
    marginBottom: '4rem',
    '& .MuiTableCell-head .MuiButton-root': {
      padding: 0,
    },
    '& .MuiTableCell-root': {
      border: 'none',
      paddingLeft: '0',
      '& div': {
        overflow: 'visible',
      },
    },
  },
  datasources: {
    marginBottom: '3rem',
  },
  body: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '5px',
    },
    '& .MuiButton-root': {
      height: '3.6rem',
      paddingBottom: '1rem',
      paddingTop: '1rem',
    },
    '& .MuiButton-label': {
      textTransform: 'capitalize',
    },
    '& .MuiSwitch-root': {
      marginTop: '.5rem',
      '& .MuiSwitch-switchBase': {
        paddingTop: '3px',
      },
    },
    '& .MuiTableCell-root > .MuiIcon-root': {
      marginTop: '1rem',
    },
  },
}));

const KeywordsReview = ({ canCurate, email, errors, keywords, prm, selected, onChange }) => {
  const classes = useStyles();
  const [data, setData] = useState(list());
  const [datasourceDisplay, setDatasourceDisplay] = useState(true);
  const [datasources, setDatasources] = useState(list());
  const [pagedData, setPagedData] = useState(list());
  const [pagerFilters, setPagerFilters] = useState(map({ limit: 10 }));
  const [popover, setPopover] = useState();
  const [sourceOptions, setSourceOptions] = useState(list());

  const basetypes = useRecoilValue(AlertingStore.basetypes);
  const keywordClasses = useRecoilValue(AlertingStore.keywordClasses);

  const onChangeField = (k, v, i) => {
    if (i != null) {
      const index = i + pagerFilters.get('skip', 0);
      setData(data.setIn([index, k], v));
    } else {
      setData(data.map(d => d.set(k, v)));
    }
  };

  const onDelete = (i) => {
    setData(data.delete(i));
  };

  const popoverOptions = (key, index) => {
    let options = [];
    switch (key) {
      case 'category':
        options = [
          ...keywordClasses
            .map(v => ({ value: v.get('id'), label: Text.Sentence(v.get('name')) })).toJS(),
        ];
        break;
      case 'active':
        options = [{ value: true, label: `All (${data.size})` }, { value: false, label: 'None' }];
        break;
      case 'is_curated':
        options = [{ value: true, label: `All (${data.size})` }, { value: false, label: 'None' }];
        break;
      case 'keywordSubscriptions':
        return (
          <Subscriptions
            email={email}
            filter={false}
            selected={selected}
            existing={index != null ? data.getIn([index, 'keywordSubscriptions'], list()) : list()}
            onChange={(changedSubscriptions, subscriptionsList) => {
              const added = subscriptionsList.map(v => fromJS({
                ...v.toJS(),
                hasAdded: true,
                hasChangedEmail: v.get('receivesEmail'),
              }));
              onChangeField('keywordSubscriptions', added, index);
            }} />
        );
      default:
        return (<div />);
    }
    return options.map(v => (
      <ListItem
        key={v.value}
        value={v.value}
        onClick={() => {
          setPopover();
          onChangeField(key, key === 'category' ? fromJS(v) : v.value, index);
        }}>
        {v.label}
      </ListItem>
    ));
  };

  useEffect(() => {
    const value = keywords.get(0, map());
    const selectedSources = [...value.get('dataSources', []), ...value.get('dea', [])];
    let sourceOptionsList = list();
    basetypes
      .filter(v => prm.some(p => v.get('test').test(p)))
      .forEach((v) => {
        if (!v.has('children')) sourceOptionsList = sourceOptionsList.push(v);
        else {
          const parentId = v.get('id');
          sourceOptionsList = sourceOptionsList.concat((typeof parentId === 'string')
            ? list([v, ...v.get('children')])
            : list([...v.get('children')]));
        }
      });
    const sources = sourceOptionsList.filter(v => ((!v.get('useNested')
      ? selectedSources.includes(v.get('id'))
      : v.get('id').every(i => selectedSources.includes(i)))));
    setDatasources(sources);
    setSourceOptions(sourceOptionsList);
    setData(keywords);
  }, [keywords]);

  useEffect(() => {
    onChange(data.toJS());
  }, [data]);

  useEffect(() => {
    const skip = pagerFilters.get('skip', 0);
    const limit = pagerFilters.get('limit', 10);
    const paged = data.slice(skip, skip + limit);
    setPagedData(paged);
  }, [data, pagerFilters]);

  return (
    <Grid
      fluid
      className={classes.keywordsReview}>
      <Row className={classes.datasources}>
        <Col xs={12}>
          <div>These keywords will be searched across:</div>
          {datasourceDisplay &&
          <div>
            <b>{datasources.map(v => Text.Sentence(v.get('name'))).join(', ')}</b>
            <Link color="secondary" onClick={() => setDatasourceDisplay(false)} style={{ paddingLeft: '5px' }}>
              edit
            </Link>
          </div>}
          {!datasourceDisplay &&
          <div>
            <FormControl
              variant="outlined">
              <FormGroup row>
                {sourceOptions.map(opt => (
                  <FormControlLabel
                    key={opt.get('name')}
                    label={Text.Sentence(opt.get('name'))}
                    control={<Checkbox
                      checked={Boolean(
                        datasources.map(v => v.get('id')).includes(opt.get('id')))}
                      onChange={(event) => {
                        const { checked } = event.target;
                        const options = [...new Set(datasources
                          .filter(v => (checked ? true : v.get('id') !== opt.get('id')))
                          .concat(checked ? [opt] : [])
                          .filter(v => v))];
                        setDatasources(options);
                        onChangeField('dataSources', fromJS(options.map(v => v.get('id'))));
                      }} />} />
                  ))}
              </FormGroup>
            </FormControl>
          </div>}
        </Col>
      </Row>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                key="name"
                style={{ width: '200px' }}>
                <div>Name</div>
              </TableCell>
              <TableCell
                key="keyword">
                <div>Keyword</div>
              </TableCell>
              <TableCell
                key="category"
                style={{ width: '175px' }}
                className={classes['header-column']}>
                <Button
                  color="primary"
                  endIcon={<Icon>keyboard_arrow_down</Icon>}
                  onClick={event => setPopover({ key: 'category', target: event.target })}>
                  Category
                </Button>
              </TableCell>
              <TableCell
                key="keywordSubscriptions"
                style={{ width: '175px' }}
                className={classes['header-column']}>
                <Button
                  color="primary"
                  endIcon={<Icon>keyboard_arrow_down</Icon>}
                  onClick={event => setPopover({ key: 'keywordSubscriptions', target: event.target })}>
                  Recipients
                </Button>
              </TableCell>
              <TableCell
                key="active"
                style={{ width: '100px' }}
                className={classes['header-column']}>
                <Button
                  color="primary"
                  endIcon={<Icon>keyboard_arrow_down</Icon>}
                  onClick={event => setPopover({ key: 'active', target: event.target })}>
                  Active
                </Button>
              </TableCell>
              <TableCell
                key="curated"
                style={{ width: '100px' }}
                className={classes['header-column']}>
                <Button
                  color="primary"
                  disabled={!canCurate}
                  endIcon={<Icon>keyboard_arrow_down</Icon>}
                  onClick={event => setPopover({ key: 'is_curated', target: event.target })}>
                  Curated
                </Button>
              </TableCell>
              <TableCell style={{ width: '75px' }}>
                <div>Remove</div>
              </TableCell>
            </TableRow>
            <Popover
              anchorEl={popover && popover.target}
              open={Boolean(popover)}
              onClose={() => setPopover()}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
              {popover && popoverOptions(popover.key, popover.index)}
            </Popover>
          </TableHead>
          <TableBody className={classes.body}>
            {pagedData
              .map((row, rowIndex) => (
                <TableRow
                  key={row.get('key')}
                  hover>
                  <TableCell style={{ width: '200px' }}>
                    <FormControl variant="outlined" name={`name.${rowIndex}`}>
                      <OutlinedInput
                        type="text"
                        value={row.get('name')}
                        onChange={event => onChangeField('name', event.target.value, rowIndex)} />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl variant="outlined" name={`value.${rowIndex}`} error={errors.has(row.get('key')) && errors.get(row.get('key')) !== ''}>
                      <OutlinedInput
                        type="text"
                        value={row.get('value')}
                        onChange={event => onChangeField('value', event.target.value, rowIndex)} />
                      {errors.get(row.get('key')) && errors.get(row.get('key')) &&
                      <FormHelperText>
                        {errors.get(row.get('key'))}
                      </FormHelperText>}
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ width: '175px' }}>
                    <Button
                      variant="outlined"
                      endIcon={<Icon>keyboard_arrow_down</Icon>}
                      onClick={event => setPopover({ key: 'category', target: event.target, index: rowIndex })}>
                      {row.getIn(['category', 'label'], '')}
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: '175px' }}>
                    <Button
                      variant="outlined"
                      endIcon={<Icon>keyboard_arrow_down</Icon>}
                      onClick={event => setPopover({ key: 'keywordSubscriptions', target: event.target, index: rowIndex })}>
                      {row.get('keywordSubscriptions', list()).size === 0 ? 'No recipients selected' : `${row.get('keywordSubscriptions', list()).size} recipients selected`}
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: '100px' }}>
                    <Switch
                      color="secondary"
                      checked={row.get('active')}
                      onChange={(_, v) => onChangeField('active', v, rowIndex)} />
                  </TableCell>
                  <TableCell style={{ width: '100px' }}>
                    {/* disable if cannot curate */}
                    <Switch
                      color="secondary"
                      disabled={!canCurate}
                      checked={row.get('is_curated')}
                      onChange={(_, v) => onChangeField('is_curated', v, rowIndex)} />
                  </TableCell>
                  <TableCell style={{ width: '75px' }}>
                    <Icon
                      color="primary"
                      onClick={() => onDelete(rowIndex)}>
                      delete
                    </Icon>
                  </TableCell>
                </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
      {(data.size || 0) > 10 &&
      <Row>
        <Col xs={12}>
          <Pager
            limit
            paginated
            filters={pagerFilters}
            onFilter={setPagerFilters}
            data={fromJS({ total: data.size || 0 })}
            limits={fromJS([
              { value: 10, label: '10' },
            ])} />
        </Col>
      </Row>}
    </Grid>
  );
};

KeywordsReview.propTypes = {
  canCurate: PropTypes.bool,
  email: PropTypes.string,
  errors: PropTypes.object,
  keywords: PropTypes.object,
  prm: PropTypes.object,
  selected: PropTypes.object,
  onChange: PropTypes.func,
};

KeywordsReview.defaultProps = {
  canCurate: false,
  email: '',
  errors: map(),
  keywords: list(),
  prm: list(),
  selected: map(),
  onChange: null,
};

export default KeywordsReview;
