/* eslint-disable no-param-reassign, react/no-array-index-key, no-shadow */
import * as React from 'react';
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';

import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import { sanitizeBinTags, sanitizeBinPFL } from '../../../../utils/cfm';

import { devClient } from '../../../../gql/apolloClient';
import query, { bulkEditBins, ownedBin } from '../ManageBins/query';

const useStyles = makeStyles((theme: Theme) => ({
  rootRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eff0fb',
    },
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      padding: '10px 12px 6px 16px',
    },
    '& .MuiTableCell-root div': {
      overflow: 'visible',
    },
  },
  error: {
    color: '#f00',
    display: 'block',
    margin: 0,
    position: 'absolute',
    marginLeft: '4%',
  },
  warning: {
    display: 'flex',
    margin: 0,
    position: 'absolute',
  },
  success: {
    marginTop: '3%',
    marginBottom: '5%',
    marginLeft: '4%',
  },
  button: {
    margin: '2.5%',
  },
  bulkAddButton: {
    marginLeft: '3.5%',
    width: '25%',
  },
  chips: {
    paddingTop: '-2.5rem',
    paddingBottom: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  addBinButton: {
    textAlign: 'center',
    width: '95%',
    marginLeft: '2.5%',
    marginRight: '2.5%',
    marginTop: '5%',
  },
  deleteBinButton: {
    '& .MuiIconButton-root': {
      paddingTop: '0.5rem',
      marginLeft: '10%',
    },
  },
  headerSubtext: {
    fontSize: '50%',
    marginTop: '-5%',
    marginBottom: '-5%',
  },
  shopsError: {
    marginTop: '3%',
    marginLeft: '4%',
    marginRight: '4%',
  },
}));

type Bin = {
  id: number;
  name: string;
};

type BinTags = {
  name: string[];
};

type Bins = {
  id: number;
  name: string;
  salesforceId: string;
  binTags: Bin[];
};
interface BinData {
  bins: Bins[];
  salesforceId: number;
  size: number;
  skip: number;
  sortBy: string;
  binsFilter: string;
  tagsFilter: string | string[];
}

interface BinsData {
  variables: BinData[];
  close: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const BulkAddBinsTable = ({ variables, close }: BinsData) => {
  const classes = useStyles();

  const [addMoreButton, setAddMoreButton] = useState(true);
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState({
    binNumber: '',
    binRoi: {},
    binTags: [],
  });
  const [binError, setBinError] = useState('');
  const [duplicateBins, setDuplicateBins] = useState([]);
  const disableDeleteIcon = true;

  const [bulkAdd, {
    loading,
    error,
    data,
  }] = useMutation(bulkEditBins, {
    refetchQueries: [{ query, variables }],
    client: devClient,
  });

  const [binsCheck, {
    loading: ownedBinLoading,
  }] = useLazyQuery(ownedBin, {
    client: devClient,
    onCompleted: (completedData) => {
      if (completedData.ownedOrWatchedBins) {
        const duplicates = completedData.ownedOrWatchedBins.map(b => b.number);
        setDuplicateBins(duplicates);

        const updateData = {
          ...variables,
          bins: rows.filter(r => !duplicates.includes(r.binNumber)),
        };

        bulkAdd({ variables: updateData });
      } else {
        const updateData = {
          ...variables,
          bins: rows,
        };

        bulkAdd({ variables: updateData });
      }
    },
  });

  const onSubmit = () => {
    // update bins to be in the form of {'name': 'tag_name'} and cast binNumber
    rows.forEach((r) => {
      r.binTags = r.binTags ? r.binTags.map((name: string) => ({ name })) : [];
      r.binNumber = Number(r.binNumber);
    });

    setRows(rows);

    binsCheck({
      variables: {
        salesforceId: variables.salesforceId,
        binNumbers: rows.map(r => r.binNumber),
      },
    });

    setAddMoreButton(true);
  };

  const onRowSubmit = () => {
    // make sure the essential row data is filled in
    if (row.binNumber) {
      rows.push(row);
      setRows(rows);
    }
    setRow({
      binNumber: '',
      binRoi: {},
      binTags: [],
    });
    setBinError('');
  };

  const deleteHandler = (binNumber: number) => {
    // find the index of the bin number
    const updatedRows = [...rows];
    const deleteIndex = updatedRows.findIndex(r => r.binNumber === binNumber);

    // if index found, delete from current state of rows
    if (deleteIndex > -1) {
      updatedRows.splice(deleteIndex, 1);
    }

    // update state
    setRows(updatedRows);
  };

  const onRowBinNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    row.binNumber = e.target.value;
    setRow(row);

    if (row.binNumber.length !== 6) {
      setBinError('BIN needs to be 6 digits');
      setAddMoreButton(true);
    }

    else if (!/^\d+$/.test(row.binNumber)) {
      setBinError('BIN should not contain non numerical values!');
      setAddMoreButton(true);
    }

    else if (rows.some(r => r.binNumber === row.binNumber)) {
      setBinError('BIN has already been saved to be added!');
      setAddMoreButton(true);
    }

    else {
      setBinError('');
      setAddMoreButton(false);
    }
  };

  const onRowBinTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // convert bin tags to array
    row.binTags = e.target.value.split(',');
    row.binTags = sanitizeBinTags(row.binTags);

    setRow(row);
  };

  const displayBinTags = (binTags: BinTags[] | string[][]) => {
    if (binTags) {
      // this is needed post submit, the rows array was transformed during the onSubmit method
      // this is done to avoid 'object Object' appearing in the binTags
      if (typeof binTags[0] === 'object' && binTags !== null) {
        binTags = binTags.map(a => a.name);
      }

      return binTags.join(', ');
    }

    return '';
  };

  const onRowBinPFLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 15) {
      setBinError('Potential Fraud Loss should be at most 15 digits!');
      setAddMoreButton(true);
    }

    else if (e.target.value && !/^\d{0,15}\.?(\d{0,2})$/.test(e.target.value)) {
      setBinError('Potential Fraud should not contain more than two decimal places or non numerical values!');
      setAddMoreButton(true);
    }

    else {
      row.binRoi = sanitizeBinPFL(e.target.value);
      setRow(row);
      setBinError('');
      setAddMoreButton(false);
    }
  };

  const displayBinPFL = (binPfl: string[]) => {
    if (binPfl && Object.keys(binPfl).length !== 0) {
      // similar to BIN Tags, this is needed post submit -- the rows array was transformed during
      // the onSubmit method this is done to avoid 'object Object' appearing as the BIN PFL
      if (typeof binPfl === 'object' && binPfl !== null) {
        binPfl = binPfl.value;
      }

      return `\$${binPfl}`;
    }

    return '';
  };

  const displayErrorMessage = (message: string) => {
    const checkForSixDigitBIN = /^[\w: ]+\d{6}[\w ]+/.test(message);

    if (checkForSixDigitBIN) {
      const erroredBIN = message.match(/\d{6}/).join('');
      return (
        <p>
          Unable to add BIN {erroredBIN}, please reach out to your Customer Success
          Representative.
          <br /><br />
          <b>
            NOTE: Only the valid BINs before this BIN were added and the data for them will
            appear in approximately 24 hours.
            You will need to refresh your screen to see the most updated list.
          </b>
        </p>);
    }

    return (
      <p>
        Please reach out to your Customer Success Representative.
      </p>
    );
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Bin</TableCell>
              <TableCell>
                Bin PFL <br />
                <p className={classes.headerSubtext}>
                  Potential Fraud Loss
                </p>
              </TableCell>
              <TableCell>
                Bin Tags <br />
                <p className={classes.headerSubtext}>
                  Tags as a comma-separated list
                </p>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows[0] && rows.map((r, index) => (
              <TableRow key={index} className={classes.rootRow}>
                <TableCell>
                  <TextField
                    id="bin-input"
                    variant="outlined"
                    value={r.binNumber}
                    disabled={rows.length > 0}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="pfl-input"
                    variant="outlined"
                    value={displayBinPFL(r.binRoi)}
                    disabled={rows.length > 0}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="tag-input"
                    variant="outlined"
                    value={displayBinTags(r.binTags)}
                    disabled={rows.length > 0}
                  />
                </TableCell>
                <TableCell>
                  <div className={classes.deleteBinButton}>
                    <IconButton onClick={() => { deleteHandler(r.binNumber); }} size="large">
                      <DeleteForever />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>))
            }
            <TableRow key={rows.length} className={classes.rootRow}>
              <TableCell>
                <TextField
                  id="bin-input"
                  variant="outlined"
                  onChange={onRowBinNumberChange}
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="pfl-input"
                  variant="outlined"
                  onChange={onRowBinPFLChange}
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="tag-input"
                  variant="outlined"
                  onChange={onRowBinTagsChange}
                />
              </TableCell>
              <TableCell>
                <div className={classes.deleteBinButton}>
                  <IconButton disabled={disableDeleteIcon} size="large">
                    <DeleteForever />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <p className={classes.error}>
          {binError}
        </p>
        <Button
          className={classes.addBinButton}
          onClick={onRowSubmit}
          disabled={addMoreButton}>
          Save and Add Another Bin
        </Button>
        {(loading || ownedBinLoading) && <CircularProgress size={25} thickness={2} />}
        {!data &&
          <p className={classes.success}>
            NOTE: Only 500 BINs can be uploaded through this feature. Please reach out to your
            customer success representative, if you have more than 500 BINs.
          </p>
        }
        {data &&
          <div className={classes.success}>
            BINs have been saved successfully!
            <p className={classes.warning}>
              NOTE: Data for newly added or modified BINs will appear in approximately 24 hours.
            </p>
          </div>
        }
        {duplicateBins.length !== 0 && data &&
          <div className={classes.success}>
            NOTE: Below are the BINs that were not added along with the reason.
            <p className={classes.warning}>
              - BIN is already being tracked: {duplicateBins.join(', ')}
            </p>
          </div>
        }
        {error &&
          <div className={classes.shopsError}>
            An error has occurred while adding BINs. {displayErrorMessage(error.message)}
          </div>}
        {
          !(loading || ownedBinLoading) && !data && rows.length > 0 ?
            <React.Fragment>
              <Button className={classes.button} type="submit" onClick={onSubmit}>Submit</Button>
              <Button onClick={close}>Close</Button>
            </React.Fragment> :
            <Button className={classes.button} onClick={close}>Close</Button>
        }
      </TableContainer>
    </Paper>
  );
};

BulkAddBinsTable.propTypes = {
  variables: PropTypes.object,
  close: PropTypes.func.isRequired,
};

BulkAddBinsTable.defaultProps = {
  variables: {},
};

export default BulkAddBinsTable;
