import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Icon,
  Switch,
} from '@mui/material';

import style from './tags.module.scss';
import History from '../../utils/history';
import ListFilter from '../filters/ListFilter';

const Tags = ({
  prefs,
  tags,
}) => {
  const [dialog, setDialog] = useState();
  const [filters, setFilters] = useState();
  const [toggled, setToggled] = useState();
  const defaults = [{ id: 'CYBER_THREATS' }, { id: 'PHSYICAL_THREATS' }];

  const onEncode = (params = '') =>
    encodeURIComponent(params.replace(/_/ig, ' '))
      .replace(/%2B/ig, '+')
      .replace(/%26/ig, '&')
      .replace(/%2C/ig, ',');

  const onFilter = (toggle = false, data = filters) => {
    if (toggle) {
      const query = onEncode(data.join());
      const pathname = `/home/intelligence/latest/${query}`;
      History.push({ pathname });
    } else {
      const pathname = '/home/intelligence/latest/';
      History.push({ pathname });
    }
    setToggled(toggle);
  };

  const onRoute = (tag) => {
    const pathname = `/home/intelligence/latest/${tag}`;
    History.push({ pathname });
  };

  useEffect(() => {
    const data = prefs.get('tags').map(v => v.get('id'));
    const query = onEncode(data.join());
    const toggle = window.location.pathname.includes(query);
    setFilters(data);
    setToggled(toggle);
  }, [prefs]);

  return (
    <Grid fluid className={style.tags}>
      <Row>
        <Col xs={12} className={style.header}>
          <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>My Topics</div>
          <Switch
            className={style.toggle}
            checked={!!toggled}
            onChange={() => onFilter(!toggled)} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {prefs
            .get('tags', defaults)
            .toJS()
            .map(v => v?.id?.replace(/_/ig, ' '))
            .map(chip => (
              <Chip
                key={chip}
                disabled={!toggled}
                className={cx([style.h4, 'h4', style.chip, !toggled && style.disabled])}
                icon={<Icon className={style.icon}>local_offer</Icon>}
                onClick={() => onRoute(chip)}
                label={chip} />))}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
            startIcon={<Icon className={cx([style.icon])}>mode_edit</Icon>}
            onClick={() => setDialog(true)}>
            Edit Topics
          </Button>
          <Dialog
            maxWidth="md"
            scroll="body"
            open={Boolean(dialog || false)}
            onClose={() => setDialog()}>
            <DialogContent>
              <ListFilter
                custom
                required
                option="tags"
                options={tags}
                filters={prefs}
                toggle={(selected) => {
                  setDialog(false);
                  onFilter(false, selected);
                }} />
            </DialogContent>
          </Dialog>
        </Col>
      </Row>
    </Grid>
  );
};

Tags.propTypes = {
  prefs: PropTypes.object,
  tags: PropTypes.object,
};

Tags.defaultProps = {
  prefs: map(),
  tags: list(),
};

export default Tags;
