import * as React from 'react';
import { useState, useRef } from 'react';
import { pipe } from 'lodash/fp';

import makeStyles from '@mui/styles/makeStyles';
import * as moment from 'moment';

import easyUseEffect from '../../../../../hooks/easyUseEffect';
import { ExploreTopic } from '../../../../../core/types/ExploreTopic';
import History from '../../../../../utils/history';
import Dashboard from '../../../Dashboard';
import Query from '../../query';
import Token from '../../../../../utils/token';
import '../../../../../theme/createFpTheme';

const useStyles = makeStyles({
  empty: {
    color: '#7a84a1',
    margin: '2rem 0 1rem 0',
  },
  root: {
    display: 'grid',
    columnGap: '2rem',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    margin: '1rem .5rem .5rem .5rem',
    rowGap: '2rem',
    width: '100%',
  },
  card: {
    backgroundColor: '#4f5269',
    borderRadius: '1rem',
    color: '#fff',
    cursor: 'pointer',
    padding: '1rem',
    '&:hover': {
      backgroundColor: '#2a2c3c',
    },
  },
  cardTitle: {
    fontSize: '1.8rem',
    lineHeight: '1.25',
    margin: '.25rem 0',
    padding: '0',
  },
  cardText: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    lineHeight: '1.5',
    margin: '0',
    padding: '0',
  },
});

const MaxTopics = 4;
interface ExploreTopicsWidgetProps {
  /** Array of topics to display */
  categoryIds: string[],
  settings: VoidFunction;
}

const intersection = (arrA:unknown[], arrB:unknown[]) => arrA.filter(x => arrB.includes(x));

const filterExploreTopicsByCategory = (exploreTopics:ExploreTopic[], categoryIds:string[]) => (
  categoryIds.length > 0 ? exploreTopics.filter((topic: ExploreTopic) =>
    intersection(categoryIds, topic.categories).length > 0) : exploreTopics
);

const limitPrivateTopicsBySfId = (exploreTopics: ExploreTopic[]) => (
  exploreTopics.filter((et: ExploreTopic) => (
    et.is_private_topic ? et.organizations.includes(Token.get('sf_id')) : true
  ))
);

/** Displays 4 explore topics in a grid */
export const ExploreTopicsWidget = ({
  categoryIds,
  settings,
}: ExploreTopicsWidgetProps): JSX.Element => {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const exploreTopicsPromise = (useRef(Query.ExploreTopics()) as
    { current: Promise<ExploreTopic[]> });
  const classes = useStyles();

  easyUseEffect(() => {
    exploreTopicsPromise.current.then((res:ExploreTopic[]) => {
      setData(filterExploreTopicsByCategory(limitPrivateTopicsBySfId(res), categoryIds));
    });
  }, [categoryIds]);
  const cveAccess = Boolean(Token.get('prm').some((p: string) => /cve.r/ig.test(p)));

  const onRoute = (entry: ExploreTopic) => {
    if (entry?.queries?.length >= 1) {
      const pathname = new URL(entry.queries[0].query);
      History.navigateTo((`${pathname.pathname}${pathname.search}`), false);
    }
  };

  const sortTopicsByDate = (myData: ExploreTopic[]): ExploreTopic[] => (
    myData.sort((a, b) => {
      const left = new Date(a?.updated_at?.['date-time'])?.getTime();
      const right = new Date(b?.updated_at?.['date-time'])?.getTime();
      return right - left;
    })
  );

  type filterableAttr = 'is_published' | 'is_trending' | 'is_private_topic';
  const filterExploreTopics = (myData: ExploreTopic[], attr: filterableAttr,
    dontNegate = true): ExploreTopic[] => (
    myData.filter((v: ExploreTopic) => v[attr as filterableAttr] === dontNegate)
  );
  const filterPublished = (myData: ExploreTopic[]): ExploreTopic[] => filterExploreTopics(myData, 'is_published');
  const filterTrending = (myData: ExploreTopic[]): ExploreTopic[] => filterExploreTopics(myData, 'is_trending');
  const filterNotTrending = (myData: ExploreTopic[]): ExploreTopic[] => filterExploreTopics(myData, 'is_trending', false);
  const filterNotPrivateTopics = (myData: ExploreTopic[]): ExploreTopic[] => filterExploreTopics(myData, 'is_private_topic', false);
  const filterCve = (myData: ExploreTopic[]) => myData.filter(v => (cveAccess ? v : !v?.categories?.some(t => /cves/ig.test(t))));
  const limitTrending = (myData: ExploreTopic[]) => myData.slice(0, MaxTopics);
  const computeRemainder = (myData: ExploreTopic[]) => MaxTopics - myData.length;
  const addExtraTopicsIfNecessary = (myData: ExploreTopic[]) => (
    computeRemainder(myData) > 0 ?
      myData.concat(sortTopicsByDate(filterNotTrending(data).slice(0, computeRemainder(myData))))
      : myData);

  easyUseEffect(() => {
    if (!data) return;
    pipe(
      filterPublished,
      filterNotPrivateTopics,
      filterCve,
      sortTopicsByDate,
      filterTrending,
      limitTrending,
      addExtraTopicsIfNecessary,
      setRows)(data);
  }, [data]);


  if (!rows || rows.length < 1) {
    return <div className={classes.empty}>No topics to display</div>;
  }

  const topicNameTranformer = (name: string) => {
    const maxLength = 26;
    if (name.length > maxLength) {
     return `${name.substring(0, maxLength - 3)}...`;
    }
    return name;
  };

  return (
    <Dashboard
      data={data}
      title="Recent Explore Topics"
      help="Offers quick access to our latest explore topics. Click the gear icon to customize your topics."
      settings={settings ? () => settings() : null}>
      <div className={classes.root}>
        {rows.map((v: ExploreTopic) => {
          const updateTime = v?.published_at?.['date-time'] ? moment(v.published_at['date-time']).fromNow() : 'unknown';
          return (
            <div key={v.fpid} onClick={() => onRoute(v)} className={`topicCard ${classes.card}`}>
              <h3 className={classes.cardText}>{v?.categories[0] || ''}</h3>
              <h2 className={classes.cardTitle}>{topicNameTranformer(v?.topic_name) || 'Untitled'}</h2>
              <p className={classes.cardText}>Published {updateTime}</p>
            </div>
            );
          },
        )}
      </div>
    </Dashboard>
  );
};

export { ExploreTopicsWidgetProps };
