import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Map as map } from 'immutable';
import { ContextMenuTrigger as CMT } from 'react-contextmenu';

import style from './contextMenuTrigger.module.scss';

function ContextMenuTrigger(props) {
  const { children, data, options, triangleStyle, type } = props;

  const [toSend, setToSend] = useState({});

  useEffect(() => {
    const attr = {
      ...options,
      data,
      type,
    };
    setToSend(attr);
  }, [options, data, type]);

  function collect() {
    return toSend;
  }

  return (
    <CMT id="global.context" collect={() => collect()} attributes={{ className: style.wrapper }}>
      <span
        className={style.triangle}
        style={triangleStyle}
        data-for="global.tooltip"
        data-tip="Right click for actions" />
      {children}
    </CMT>
  );
}

ContextMenuTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  options: PropTypes.object,
  triangleStyle: PropTypes.object,
  type: PropTypes.string,
};

ContextMenuTrigger.defaultProps = {
  options: {},
  data: map(),
  triangleStyle: {},
  type: '',
};

export default ContextMenuTrigger;
