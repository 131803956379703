import * as React from 'react';

import {
  Table as MuiTable,
} from '@mui/material';

interface TableProps {
  /** The content of the table */
  children?: React.ReactNode;
  className?: string;
}

/** The root table element */
export const Table = ({
  children,
  className,
  ...rest
}: TableProps): JSX.Element =>
  <MuiTable {...rest} className={className} >
    {children}
  </MuiTable>;

Table.defaultProps = {
  children: '',
  className: '',
};
