import * as React from 'react';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface ArrowIndicatorProps {
  percent: number;
  timePeriod: string;
  className?: string;
}

export const ArrowIndicator = ({
  percent,
  timePeriod,
  className,
}: ArrowIndicatorProps): JSX.Element => {
  const Threshold = 5;

  const getIcon = (v: number): JSX.Element => {
    if (v < 0) return <ArrowDropDown />;
    if (v > 0) return <ArrowDropUp />;
    return null;
  };

  const getColor = (v: number): string => {
    if (v < -Threshold) return '#25a277';
    if (v > Threshold) return '#eb002b';
    return '#a4a4a5';
  };

  const getLabel = (v: number): string => {
    if (v < -Threshold || v > Threshold) return `${Math.abs(percent)}% ${timePeriod}`;
    return 'About the same';
  };

  const iconStyle = {
    color: getColor(percent),
  };
  const label = getLabel(percent);

  return <div className={className} style={iconStyle}>{getIcon(percent)}{label}</div>;
};

ArrowIndicator.defaultProps = {
  className: '',
};
