import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map } from 'immutable';
import { Pagination,
  CircularProgress,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table as TableUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@mui/material';

import style from './table.module.scss';
import Text from '../../../utils/text';
import Common from '../../../utils/common';
import Invalid from '../../utils/Invalid/Invalid';

const Table = ({
  actionable,
  filter,
  header,
  limit,
  pagination,
  topPagination,
  leftPagination,
  results,
  styles,
  values,
  widget,
  onBlur,
  onOffset,
  onSearch,
  onClick,
  labels,
  blur,
  blurFunc,
  onHover,
  emptyMessage,
  skipSlice,
}) => {
  if (!results) return <div>An unknown error occured</div>;
  const [offset, setOffset] = useState(0);
  const [focused, setFocused] = useState(false);
  const [textFilter, setTextFilter] = useState(filter);
  const [resetOffset, setResetOffset] = useState(false);
  const [sortDirections, setSortDirections] = useState({});
  const [resultsPage, setResultsPage] = useState(list());

  const esLimit = 10000; // es record limit 10 000
  const size = limit || widget.get('limit') || 10;
  const pages = Math.ceil((results.get('total') > esLimit ? esLimit : results.get('total')) / size);
  const total = results.get('bucket_total') || results.get('total') > esLimit ? esLimit : results.get('total') || 0;
  const isLoading = results.isEmpty();
  const isEmpty = results.has('data') && results.get('data') && !results.get('data').size;

  if (isLoading && !isEmpty) return (<CircularProgress />);

  if ((offset > 0) && (results.has('skip')) && (results.get('skip') > total)) {
    setOffset(0);
    setResetOffset(true);
  }

  const isPaging = pagination
    && results.has('skip')
    && (offset * limit) !== results.get('skip')
    && !resetOffset;

  useEffect(() => {
    if (filter) {
      setTextFilter(filter);
      onOffset(0, filter);
      setFocused(true);
      setTimeout(() => setFocused(false), 1000);
    }
  }, [filter]);

  useEffect(() => {
    if (results.isEmpty()) return;
    setOffset((results.get('skip') || results.getIn(['filters', 'skip']) || 0) / limit);
  }, [results]);

  useEffect(() => {
    const resultsData = results.get('data') || list();
    let resultsToRender = (resultsData.size <= size || offset == null)
      ? resultsData
      : resultsData.slice(offset * size, (offset * size) + size);
    /* If paged data is refreshed on each page, skip slicing the data */
    if (skipSlice) resultsToRender = resultsData.slice(0, size);

    setResultsPage(resultsToRender);
  }, [results, offset, resetOffset]);

  /** Universal sort. Handles strings, numbers, dates and IP addresses */
  const defaultSort = (a, b, direction) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return (direction === 'asc' ? 1 : -1) * Math.sign(a - b);
    }
    if (typeof a !== 'string' || typeof b !== 'string') {
      /* If it's not a string, don't sort it */
      return 0;
    }
    return (direction === 'asc' ? 1 : -1) * a.localeCompare(b);
  };

  const handleSort = (v) => {
    /* Swap the sort direction for the column the user clicked on */
    const key = v?.get('key');
    const dir = sortDirections[String(key)] === 'asc' ? 'desc' : 'asc';
    const sorts = {};
    sorts[String(key)] = dir;

    /* Sort the results */
    const sortFunc = v?.get('sortFunc') || defaultSort;
    const sortedPage = resultsPage.sort((a, b) => sortFunc(a?.get(key), b?.get(key), dir));

    /* Update the table's state with new view */
    setSortDirections(sorts);
    setResultsPage(sortedPage);
  };

  return (
    <div
      style={{ height: '100%', width: '100%', ...styles }}
      className={cx([
        style.table,
        focused && style.focused,
        (isPaging || isLoading) && style.loading,
        styles && styles.transparent && style.transparent,
      ])}>
      {onSearch &&
        <form
          className={style.form}
          onSubmit={(event) => {
            event.preventDefault();
            onOffset(0, textFilter);
          }}>
          <FormControl variant="outlined">
            <InputLabel>Filter Text</InputLabel>
            <OutlinedInput
              id="text"
              data-lpignore="true"
              value={textFilter || ''}
              className={style.input}
              onChange={event => setTextFilter(event.target.value)}
              startAdornment={(
                <InputAdornment position="start">
                  <Icon
                    color="secondary"
                    onClick={() => onOffset(0, textFilter)}>
                    search
                  </Icon>
                </InputAdornment>
              )}
              endAdornment={textFilter && (
                <InputAdornment position="end">
                  <Icon
                    onClick={() => {
                      setTextFilter();
                      onOffset(0);
                    }}>
                    close
                  </Icon>
                </InputAdornment>
              )} />
          </FormControl>
        </form>}
      {!isLoading && isEmpty &&
      <Invalid
        icon="error_outline"
        title="No Results"
        subtitle={emptyMessage || 'Try selecting a different record'} />}
      {!isLoading && !isEmpty &&
      <React.Fragment>
        {topPagination &&
          <div className={[style.pager, ...[leftPagination ? style.pagerLeft : '']].join(' ')}>
            <TablePagination
              rowsPerPageOptions={[limit]}
              count={total}
              rowsPerPage={limit}
              page={offset}
              onPageChange={() => null}
              labelDisplayedRows={({ from, to }) =>
                `${from.toLocaleString()}-${to.toLocaleString()} of ${total.toLocaleString()}`} />
            <Pagination
              siblingCount={0}
              page={offset + 1}
              count={pages}
              onChange={(event, page) => {
                setOffset(page - 1);
                if (onOffset) onOffset((page - 1) * size);
              }} />
          </div>}
        <TableContainer className={style.chart}>
          <TableUI data-testid="widgets.table">
            {header &&
            <TableHead>
              <TableRow>
                {values
                  .entrySeq()
                  .map(([k, v]) => (
                    <TableCell
                      key={`${k}.${v.get('key')}`}
                      sortDirection={sortDirections[v?.get('key')]}
                      style={{ ...v.toJS().style }}
                      className={style.header}>
                      {v.get('sort') &&
                        <TableSortLabel
                          active={sortDirections[v?.get('key')] !== undefined}
                          direction={sortDirections[v?.get('key')]}
                          onClick={() => handleSort(v)}
                          >
                          {Text.Sentence(v.get('label'))}
                        </TableSortLabel>}
                      {!v?.get('sort') && Text.Sentence(v.get('label'))}
                    </TableCell>))}
                {Object.keys(actionable).length > 0 &&
                <TableCell />}
              </TableRow>
            </TableHead>}
            <TableBody>
              {resultsPage
                .entrySeq()
                .map(([rowIndex, row]) => (
                  <TableRow
                    key={`${rowIndex}.${row.get('fpid')}`}
                    hover>
                    {values
                      .entrySeq()
                      .map(([columnIndex, column]) => {
                        let html = 'cat';
                        let plainText = '';
                        if (column.has('render')) {
                          let displayText = row.getIn(column.get('key').split('.'));
                          if (labels.includes('Password')) {
                            displayText = blurFunc
                              ? blurFunc(row.getIn(column.get('key').split('.')), blur)
                              : row.getIn(column.get('key').split('.'));
                          }
                          html = (column.get('render')(!column.get('key') ? row : displayText));
                        } else {
                          plainText = (list.isList(column) ? column.map(i => row.getIn(i.get('key').split('.'))).find(v => v !== undefined) : row.getIn(column.get('key').split('.'))) || '-';
                          html = (list.isList(column) ? column.map(i => row.getIn(i.get('key').split('.'))).find(v => v !== undefined) : Text.Highlight(row.getIn(column.get('key').split('.'))) || '-');
                        }
                        return (
                          <TableCell
                            key={`${columnIndex}.${column.get('key')}`}
                            data-for="global.tooltip"
                            data-tip={column.get('tooltip') ? (plainText || html) : null}
                            style={{ ...column.toJS().style }}
                            onMouseOut={() => (onBlur ? onBlur() : null)}
                            onMouseEnter={() => {
                              if (onHover) onHover(resultsPage.get(rowIndex));
                              ReactTooltip.rebuild();
                            }}
                            onClick={(event) => {
                              if (column.get('key', '') === 'search.icon') {
                                if (onSearch(resultsPage.get(rowIndex))) {
                                  return `${onClick
                                  ? onClick(
                                    Common.Generic.Route(resultsPage.get(rowIndex)),
                                    results,
                                    rowIndex)
                                  : null}`;
                                }
                              } else if ((column.get('link', '') || column.get('key', '')) && column.get('key', '') !== 'vulnDb') {
                                return `${onClick
                                  ? onClick(
                                    Common.Generic.Route(resultsPage.get(rowIndex)),
                                    results,
                                    rowIndex)
                                  : null}`;
                              }
                              if (column.get('key', '') === 'vulnDb') {
                                event.stopPropagation();
                                // eslint-disable-next-line security/detect-non-literal-fs-filename
                                window.open(`https://vulndb.flashpoint.io/vulnerabilities/search?query=${event.target.closest('tr').children[0].innerText}`);
                              }
                               return false; }}>
                            {html}
                          </TableCell>
                        );
                      })}
                    {Object.keys(actionable).length > 0 &&
                    <TableCell>
                      <div className={style.actions}>
                        {Object.keys(actionable)
                          .map(v => (
                            <Icon
                              key={v}
                              data-for="global.tooltip"
                              data-tip={actionable[String(v)].tooltip || ''}
                              data-testid={`table.actions-${v?.toLowerCase()?.replace(/\s/, '-')}`}
                              onClick={(e) => {
                              e.stopPropagation();
                              actionable[String(v)].onClick(row);
                            }}
                              className={cx([style.icon, 'material-icons'])}>
                              {actionable[String(v)].icon}
                            </Icon>))}
                      </div>
                    </TableCell>}
                  </TableRow>
                ))}
            </TableBody>
          </TableUI>
        </TableContainer>
        {pagination &&
        <div className={[style.pager, ...[leftPagination ? style.pagerLeft : '']].join(' ')}>
          <TablePagination
            rowsPerPageOptions={[limit]}
            count={total}
            rowsPerPage={limit}
            page={offset}
            onPageChange={() => null}
            labelDisplayedRows={({ from, to }) =>
              `${from.toLocaleString()}-${to.toLocaleString()} of ${total.toLocaleString()}`} />
          <Pagination
            siblingCount={0}
            page={offset + 1}
            count={pages}
            onChange={(event, page) => {
              setOffset(page - 1);
              if (onOffset) onOffset((page - 1) * size);
              /* Clear sort when user changes the page */
              setSortDirections({});
            }} />
        </div>}
        <ReactTooltip id="icon.tooltip" html place="right" effect="solid" />
        <ReactTooltip id="export.tooltip" html place="right" effect="solid" />
      </React.Fragment>}
      {(isPaging || isLoading) &&
      <div className={style.spinner}>
        <CircularProgress />
      </div>}
    </div>
  );
};

Table.propTypes = {
  actionable: PropTypes.object,
  filter: PropTypes.string,
  header: PropTypes.bool,
  limit: PropTypes.number,
  pagination: PropTypes.bool,
  topPagination: PropTypes.bool,
  leftPagination: PropTypes.bool,
  results: PropTypes.object,
  styles: PropTypes.object,
  values: PropTypes.object,
  widget: PropTypes.object,
  onBlur: PropTypes.func,
  onOffset: PropTypes.func,
  onSearch: PropTypes.func,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  labels: PropTypes.object,
  blur: PropTypes.bool,
  blurFunc: PropTypes.func,
  emptyMessage: PropTypes.string,
  skipSlice: PropTypes.bool,
};

Table.defaultProps = {
  actionable: {},
  filter: '',
  header: true,
  limit: 10,
  pagination: true,
  topPagination: false,
  leftPagination: false,
  results: list(),
  styles: {},
  values: list(),
  widget: map(),
  onBlur: null,
  onOffset: null,
  onSearch: null,
  onClick: null,
  onHover: null,
  labels: list(),
  blur: false,
  blurFunc: null,
  emptyMessage: null,
  skipSlice: false,
};

export default Table;
