import React from 'react';

import { Col } from 'react-flexbox-grid/lib';
import { useRecoilValue } from 'recoil';

import style from '../BreachDashboard/breachdashboard.module.scss';
import Looker from '../../utils/Looker';
import { getSfid } from '../../../stores/recoil/app';

const CredentialBreachDashboard = () => {
  const sid = useRecoilValue(getSfid);

  return (
    <React.Fragment>
      <Col xs={12} className={style.containers}>
        {sid &&
          <Looker
            height="250rem"
            dashboard="/dashboards-next/ccm_e_views::credential_breach_overview"
            sid={sid} />}
      </Col>
    </React.Fragment>
  );
};

export default CredentialBreachDashboard;

