import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import cx from 'classnames';
import FileSaver from 'file-saver';
import ReactTooltip from 'react-tooltip';
import { Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';

import style from './media.module.scss';
import Text from '../../utils/text';
import { MediaTypes } from '../../constants/Media';
import { UserContext } from '../utils/Context';

const Media = ({
  data,
  expanded,
  mini,
  onMediaClick,
  postFPID,
}) => {
  const user = useContext(UserContext);
  const [dialog, setDialog] = useState('');
  const [hidden, setHidden] = useState(false);
  const [toggled, setToggled] = useState(false);

  const prm = user.get('prm');
  const type = data.hasIn(['video_enricment']) ? 'video' : 'image';
  const canDownload = prm.some(p => /dat.med.d/.test(p));
  const safeSearchScores = data.getIn([`${type}_enrichment`, 'enrichments', 'v1', `${type}-analysis`, 'safe_search'])?.toJS();
  const meetsSafeDisplayRequirements = safeSearchScores && !Object.entries(safeSearchScores).filter(v => ['adult', 'racy'].includes(v[0])).find(v => v[1] > 1);
  const safeSearch = user.getIn(['prefs', 'safe_search_toggle']);
  const safeSession = sessionStorage.getItem('X-FP-Show-Media');
  const safeNone = safeSearch === 'NONE' && !safeSession;
  const safeToggle = safeSearch === 'TOGGLE';
  const safeDisplay = (safeSearch === 'SAFE' && meetsSafeDisplayRequirements) || toggled;
  const safeAll = safeSearch === 'ALL';
  const canViewPreview = (safeAll
    || safeDisplay
    || (safeToggle && toggled)
    || (safeSession && toggled))
    && !hidden;
  let fileName = data.get('filename') || data.get('file_name') || (data.get('storage_uri') || '').split('/').slice(-1).join();
  if (fileName && fileName.indexOf('.') === -1) fileName += MediaTypes[data.get('mime_type')];
  const extension = MediaTypes[data.get('mime_type')];
  const isPhoto = /photo|image/.test(data.get('mime_type') || '') || ['photo', 'image'].includes(data.get('type') || data.get('media_type'));
  const isPdf = (data.get('mime_type') || '').includes('pdf') || ['pdf'].includes(data.get('type') || data.get('media_type'));
  const isVideo = ((data.get('mime_type') || '').includes('video') || (data.get('type') || data.get('media_type')) === 'video') && !['.3gp'].includes(extension);
  const src = fileName ? encodeURI(`/ui/v4/media/assets/?asset_id=${data.get('storage_uri')}`) : '';
  const onDownload = () => {
    if (data.get('storage_uri')) {
      setDialog('');
      const url = encodeURI(`/ui/v4/media/assets/safe-download?asset_id=${data.get('storage_uri')}&blocking=true`);
      FileSaver.saveAs(url);
    }
  };

  const onReport = () => {
    setHidden(true);
    setToggled(false);
    const subject = `Media Report - ${data.get('fpid')}`;
    const body = `I would like to report the media file contained in the post "${postFPID}" with the id "${data.get('fpid')}".`;
    window.open(`mailto:feedback@flashpoint-intel.com?subject=${subject}&body=${body}`, '_blank').focus();
  };

  const onToggleMedia = (state) => {
    setHidden(!state);
    setToggled(state);
  };

  return (
    <div className={cx([style.base, style.media, mini && style.mini])}>
      <div>
        <div
          className={cx([style.details, style.file, !fileName && style.unavailable])}
          onMouseEnter={() => ReactTooltip.rebuild()}
          data-for="global.tooltip"
          data-tip={(safeNone && !mini)
              ? 'This content was blocked due to document previews being disabled. If you would like to view this content, update your "Inline Media Preferences" in user settings'
              : `${expanded && !mini ? 'This file could contain malicious, harmful, inappropriate, or even potentially illicit content. <br /> By clicking this here you are confirming that you recognize and assume the risk to view the content.' : ''}`}
          role="link"
          tabIndex={0}
          onKeyUp={() => null}
          onClick={() => {
              if (safeNone && !mini) {
                window.open('/home/help/settings#media', '_blank');
              } else if (expanded) {
                onToggleMedia(!toggled);
              }
            }}>
          <Icon className={cx([style.icon, 'material-icons', style.attach])}>
            attach_file
          </Icon>                | {!fileName ? 'Media Unavailable' : `${Text.Filesize(data.get('size') || '-')} ${extension} (${data.get('mime_type') || '-'}) | SHA1: ${data.get('sha1') || '-'} | Filename: ${fileName || '-'}`}
        </div>
      </div>
      {expanded && canViewPreview && fileName &&
          !isPhoto && !isPdf && !isVideo && !isPdf &&
          <div className={cx([style.content, style.expanded])}>
            <div className={style.preview}>
              <div className={style.document}>
                <Icon className={style.icon}>
                  insert_drive_file
                </Icon>                    <div className={style.extension}>{extension}</div>
              </div>
            </div>
            <Grid
              fluid
              name="component.media"
              className={style.details}>
              <Row>
                <Col xs={12}>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>
                      Full Filename:
                    </div>
                    <div>{fileName || '-'}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Filesize:</div>
                    <div>{Text.Filesize(data.get('size')) || '-'}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>SHA1 Hash:</div>
                    <div>{data.get('sha1') || '-'}</div>
                  </div>
                  <div className={style.table}>
                    <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Mime Type:</div>
                    <div>{data.get('mime_type') || '-'}
                    </div>
                  </div>
                  {canDownload &&
                  <span
                    onMouseEnter={() => ReactTooltip.rebuild()}
                    data-for="global.tooltip"
                    data-tip="Download"
                    className={style.download}>
                    <Button
                      variant="contained"
                      name="button.download"
                      className={cx([
                        style.h4, 'h4',
                        style.mont, 'mont',
                        style.button,
                        !canDownload && style.disabled,
                      ])}
                      endIcon={<Icon className={style.icon}>file_download</Icon>}
                      onClick={() => setDialog('download')}>
                      Download
                    </Button>
                  </span>}
                </Col>
              </Row>
            </Grid>
          </div>}
      {expanded && canViewPreview && fileName &&
          (isPhoto || isPdf || isVideo) &&
          <div className={cx([style.content, style.expanded, style.embed])}>
            <div
              className={style.container}
              onMouseEnter={() => ReactTooltip.rebuild()}
              data-for="global.tooltip"
              data-tip={`<b>Full Filename</b>: ${fileName || '-'}<br />
                        <b>Filesize</b>: ${Text.Filesize(data.get('size')) || '-'}<br />
                        <b>SHA1 Hash</b>: ${data.get('sha1') || '-'}<br />
                        <b>Mime Type</b>: ${data.get('mime_type') || '-'}<br />`}>
              {isPhoto &&
                <div
                  role="link"
                  tabIndex={0}
                  onKeyUp={() => null}
                  onClick={() => onMediaClick?.(src, data)}>
                  <img
                    src={src}
                    alt={fileName} />
                </div>}
              {isPdf &&
              <iframe
                src={src}
                rel="nofollow"
                title={fileName}
                height="300px"
                width="100%" />}
              {isVideo &&
                /* eslint-disable-next-line jsx-a11y/media-has-caption */
                <video id="video" src={src} type={data.get('mime_type')} controls height="100%" width="100%" />}
            </div>
            <Row>
              <Col xs={12}>
                <div className={cx([style.actions, style.table])}>
                  <div className={style['toggle-actions']}>
                    <Button
                      variant="contained"
                      name="button.toggle"
                      onMouseEnter={() => ReactTooltip.rebuild()}
                      data-for="global.tooltip"
                      data-tip="Hide Media"
                      className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
                      endIcon={<Icon className={style.icon}>visibility_off</Icon>}
                      onClick={() => onToggleMedia(false)} />
                  </div>
                  {isPhoto &&
                  <Button
                    variant="contained"
                    name="button.fullscreen"
                    onMouseEnter={() => ReactTooltip.rebuild()}
                    data-for="global.tooltip"
                    data-tip="Fullscreen"
                    className={cx([style.h4, 'h4', style.mont, 'mont', style.button])}
                    endIcon={<Icon className={style.icon}>fullscreen</Icon>}
                    onClick={() => onMediaClick?.(src, data)} />}
                  {canDownload &&
                  <span
                    onMouseEnter={() => ReactTooltip.rebuild()}
                    data-for="global.tooltip"
                    data-tip="Download"
                    className={style.download}>
                    <Button
                      variant="contained"
                      name="button.download"
                      className={cx([
                        style.h4, 'h4',
                        style.mont, 'mont',
                        style.button,
                        !canDownload && style.disabled,
                      ])}
                      endIcon={<Icon className={style.icon}>file_download</Icon>}
                      onClick={() => setDialog('download')} />
                  </span>}
                  <Button
                    variant="contained"
                    name="button.report"
                    onMouseEnter={() => ReactTooltip.rebuild()}
                    data-for="global.tooltip"
                    data-tip="Report"
                    className={cx([style.h4, 'h4', style.mont, 'mont', style.button, style.report])}
                    endIcon={<Icon className={style.icon}>priority_high</Icon>}
                    onClick={() => onReport()} />
                </div>
              </Col>
            </Row>
          </div>}
      <Dialog
        open={dialog.includes('download')}
        onClose={() => setDialog('')}>
        <DialogTitle>Warning: Document Download</DialogTitle>
        <DialogContent>
          WARNING! FILE IS POTENTIALLY MALICIOUS! <br /><br />
          By downloading this file, you acknowledge that you are downloading
          potentially malicious software that could harm your computer,
          your network, or your organization's assets. You are advised to
          take explicit precautionary measures to prevent this file from
          causing harm. <br /><br />
          FLASHPOINT WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES,
          OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL,
          EXEMPLARY, OR PUNITIVE DAMAGES. IN ALL CASES, FLASHPOINT WILL
          NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY
          FORESEEABLE. <br /><br />
          Click "CONFIRM" if you want to continue downloading this potentially
          malicious file, which will be delivered in a password-protect ZIP file.
          The password is "infected".
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialog('')}>
            Cancel
          </Button>
          <Button
            color="secondary"
            className={style.active}
            onClick={() => onDownload()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Media.propTypes = {
  data: PropTypes.object,
  expanded: PropTypes.bool,
  mini: PropTypes.bool,
  onMediaClick: PropTypes.func,
  postFPID: PropTypes.string,
};

Media.defaultProps = {
  data: map(),
  expanded: false,
  mini: false,
  onMediaClick: null,
  postFPID: '',
};

export default Media;
