import React from 'react';

import Api from '../../../../utils/api';

const dev = '_self' in React.createElement('div') ? '/dev' : '';
const FraudwatchUrl = `/ui/v4/domain-monitoring${dev}`;

const Assets = async (bid = '') => {
  if (!bid) return [];
  return Api.get(`${FraudwatchUrl}/brands/${bid}/assets`)
    .then(res => (res.ok ? res.data : []))
    .catch(() => []);
};

const Brands = async (sid = '') => {
  if (!sid) return [];
  return Api.get(`${FraudwatchUrl}/brands?embed=assets&asset_type=logo`)
    .then(res => (res.ok ? res.data : []))
    .then(res => res?.map(v => ({
      ...v,
      allowed_domains: v?.allowed_domains?.join(),
      trademark_links: v?.trademark_links?.join(),
    })))
    .catch(() => []);
};

const saveAssets = async (brand, assets, status, update) => {
  const _assets = await Api.post(`${FraudwatchUrl}/brands/${brand?.id}/assets`, assets, status)
    .then(res => (res.ok ? res.data : {}));
  return Api.post(`${FraudwatchUrl}/brands/${brand?.id}/send`, update, status)
    .then(() => ({ ...brand, assets: _assets }));
};

const getUpdatedFields = brand => ({
  ...brand,
  allowed_domains: brand?.allowed_domains?.split(',') || [],
  trademark_links: brand?.trademark_links?.split(',') || [],
});

const okStatusCodes = [200, 201];

const Brand = async (brand = {}, assets = []) => {
  const status = okStatusCodes;
  const update = getUpdatedFields(brand);
  const _brand = await Api.post(`${FraudwatchUrl}/brands`, update, status)
    .then(res => (res.ok ? res.data : {}))
    .then(res => ({
      ...res,
      allowed_domains: res?.allowed_domains?.join(),
      trademark_links: res?.trademark_links?.join(),
    }));
  return saveAssets(_brand, assets, status, update);
};

const BrandPov = async (brand = {}) => {
  const email = brand.email_overrides;
  const _brand = brand;
  delete _brand.email_overrides;
  const status = okStatusCodes;
  const update = getUpdatedFields(_brand);
  const createdBrand = await Api.post(`${FraudwatchUrl}/brands`, update, status)
    .then(res => (res.ok ? res.data : {}))
    .then(res => ({
      ...res,
      allowed_domains: res?.allowed_domains?.join(),
      trademark_links: res?.trademark_links?.join(),
    }));
  return Api.post(`${FraudwatchUrl}/brands/${createdBrand?.id}/send?email_overrides=${email}`, update, status)
    .then(() => ({ ...createdBrand, assets: {} }));
};

const BrandUpdate = async (brand = {}, assets = []) => {
  const status = okStatusCodes;
  const update = getUpdatedFields(brand);
  const _brand = await Api.put(`${FraudwatchUrl}/brands/${brand?.id}`, update, status)
    .then(res => (res.ok ? res.data : {}))
    .then(res => ({
      ...res,
      allowed_domains: res?.allowed_domains?.join(),
      trademark_links: res?.trademark_links?.join(),
    }));
  if (!assets || assets === []) return new Promise(resolve => resolve({ ..._brand, assets: [] }));
  return saveAssets(_brand, assets, status, update);
};

const BrandDelete = async (bid = '') => {
  Api.delete(`${FraudwatchUrl}/brands/${bid}`, null, [200, 204]);
};

const BrandsLimit = async (sid = '') => {
  if (!sid) return {};
  return Api.get(`${FraudwatchUrl}/organizations/${sid}`)
    .then(res => (res.ok ? res?.data : {}))
    .catch(() => ({}));
};

export default {
  Assets,
  Brands,
  Brand,
  BrandPov,
  BrandUpdate,
  BrandDelete,
  BrandsLimit,
};
