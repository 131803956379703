import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './demos.module.scss';
import CCMc from '../../components/demo/CCMc';
import { UserContext } from '../../components/utils/Context';

const Demos = ({
  location,
}) => {
  const user = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  return (
    <Grid
      fluid
      className={cx([style.demos])}>
      <Row>
        <Col xs={12}>
          {location.pathname === '/home/integrations/ccmc' &&
          <CCMc user={user} />}
        </Col>
      </Row>
    </Grid>
  );
};

Demos.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Demos;
