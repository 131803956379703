import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import 'react-dropzone-uploader/dist/styles.css';
import { Button, Dialog, DialogTitle, Grid } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { fromJS } from 'immutable';
import Dropzone from 'react-dropzone-uploader';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import TextFilter from '../../../filters/TextFilter';
import { postNewIncident, uploadAttachment } from './query';
import History from '../../../../utils/history';

const useStyles = makeStyles(() => ({
  dropzone: {
    '& .dzu-dropzone': {
      borderStyle: 'dashed',
      overflow: 'auto',
    },
  },
}));

const ReportIncidentDialog = ({ onClose, onSubmit, open, brands }) => {
  const [errors, setErrors] = useState([]);
  const [brandOptions, setBrandOptions] = useState([{ value: 'choose', label: '- choose a brand -' }]);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    brand_name: 'choose',
    type: 'Phishing',
  });
  const [formData, setFormData] = useState({ files: {} });
  const classes = useStyles();

  useEffect(() => {
    const _brands = brands
      .filter(b => b)
      .filter(b => b?.active)
      .map(b => ({ value: b.id, label: b.name }));
    _brands.unshift({ value: 'choose', label: '- choose a brand -' });
    setBrandOptions(_brands);
    if (brands.length > 0) {
      setData({ ...data, brand_name: brands[0].id });
    }
  }, [brands]);

  const validate = () => {
    const _errors = [];
    if (!data.brand_name || data.brand_name === 'choose') {
      _errors.push('Brand is required');
    }
    if (!data.type) {
      _errors.push('Incident Type is required');
    }
    if (!data.urls) {
      _errors.push('Offending URL/IP Address/Domain is required');
    }
    if (typeof data.urls !== 'string') {
      _errors.push('Please enter a valid url');
    }
    setErrors(_errors);
    if (_errors.length > 0) {
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
      setFormData({ files: {} });
    }
  };
  const handleFileChangeStatus = ({ file }, status) => {
    const newFiles = formData.files;
    switch (status) {
      case 'ready':
      case 'done':
        newFiles[file.name] = file;
        break;
      case 'removed':
        delete newFiles[file.name];
        break;
      default:
        /* Do nothing */
        break;
    }

    setFormData({
      ...formData,
      files: {
        ...newFiles,
      },
    });
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    const brand = brands.find(v => v.id === data.brand_name);
    const details = {
      brand_name: brand.name,
      evidence: data.evidence,
      urls: data.urls.split(','),
      type: data.type,
    };
    let _success = false;
    postNewIncident(details)
      .then((res) => {
        setData({
          brand_name: 'choose',
          type: 'Phishing',
        });
        if (!_.isEmpty(formData.files)) {
          const _formData = new FormData();
          for (const key of Object.keys(formData.files)) {
            _formData.append('attachments', formData.files[`${key}`]);
          }
          uploadAttachment(res.data.id, _formData)
          .catch((err) => {
            if (err?.message === 'canceled') return;
            if (err?.response.data.message.includes('max')) {
              setErrors(['Upload failed. Too many files. Upload limit is 5 files. Please try again in the support chat.']);
              throw err;
            }
            else if (err?.response.data.message.includes('large')) {
              setErrors(['Upload failed. File too large. File size limit is 10mb. Please try again in the support chat.']);
              throw err;
            }
            else if (err?.response.data.message.includes('format')) {
              setErrors(['Upload failed. Non-compatible file format. Please reach out to your customer success representative for assistance.']);
              throw err;
            }
            else {
              setErrors(['Upload failed. Please try again in the support chat.']);
              throw err;
            }
          });
        }
        setErrors(['Incident created.']);
        _success = true;
        setSuccess(_success);
        History.navigateTo(`incidents/items/${res.data.id}`);
      })
      .catch((err) => {
        if (err?.message === 'canceled') return;
        setErrors([`Unable to create incident: ${err.message}`]);
        throw err;
      })
      .finally(() => {
        if (onSubmit) {
          onSubmit(_success);
        }
        if (onClose && _success) {
          onClose();
          setErrors([]);
          setSuccess(false);
          setFormData({ files: {} });
          setData({
            brand_name: brands[0]?.id ? brands[0].id : 'choose',
            type: 'Phishing',
          });
        }
      });
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
        setFormData({ files: {} });
      }}
      open={open}>
      <DialogTitle>Report an Incident</DialogTitle>
      <Grid container spacing={1} style={{ justifyContent: 'flex-end', padding: '2rem' }}>
        <Grid item xs={12}>
          <TextFilter
            onFilter={v => setData({ ...data, ...v })}
            fields={[
              { type: 'dropdown', value: 'brand_name', label: 'Brand', key: 'value', opts: fromJS(brandOptions) },
              { type: 'dropdown', value: 'type', label: 'Incident Type', opts: fromJS([
                { value: 'Phishing', label: 'Phishing' },
                { value: 'Vishing', label: 'Vishing' },
                { value: 'Brand Abuse', label: 'Brand Abuse' },
                { value: 'Social Media Brand Abuse', label: 'Social Media Brand Abuse' },
                { value: 'Mobile App (Unauthorized)', label: 'Mobile App (Unauthorized)' },
                { value: 'Messaging', label: 'Messaging' },
              ]) },
              { type: 'chips', value: 'urls', label: 'Offending URL/IP Address/Domain', opts: fromJS([]) },
              { type: 'text', value: 'evidence', label: 'Evidence/Logs', hint: 'Please provide details about the offending URL available', multiLine: true },
            ]}
            filters={fromJS(data)}
            inline
            />
        </Grid>
        <Grid item xs={12} style={{ color: success ? green[500] : red[500] }}>
          {
            (errors.length > 0) && errors.map(v => <p>{v}</p>)
          }
        </Grid>
        <Grid item xs={12} className={classes.dropzone}>
          <Dropzone
            autoUpload={false}
            onChangeStatus={handleFileChangeStatus}
            maxFiles={5}
            maxSizeBytes={1024 * 1024 * 10}
            inputWithFilesContent={files => `${5 - files.length} more`}
            accept="application/pdf,image/jpg,image/jpeg,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            inputContent={<CloudUploadIcon style={{ color: 'grey', fontSize: '40px' }} key="dropzone" />}
              />
          <Typography>
            Please upload any other relevant evidence or logs
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" onClick={handleCancel}>{ success ? 'Close' : 'Cancel' }</Button>
        </Grid>
        <Grid item>
          {!success && <Button variant="contained" size="large" color="secondary" onClick={handleSubmit}>Submit</Button>}
        </Grid>
      </Grid>
    </Dialog>
  );
};

ReportIncidentDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  brands: PropTypes.array,
};

ReportIncidentDialog.defaultProps = {
  onClose: null,
  onSubmit: null,
  open: false,
  brands: [],
};

export default ReportIncidentDialog;
