import React from 'react';

export default {
  barchart: (height) => {
    const maxHeight = height - (height / 10);
    return (
      <svg
        style={{ opacity: 0.1 }}
        height={height}
        width="100%"
      >
        <rect x="20" y="0" rx="0" ry="0" width="1" height={maxHeight} />
        <rect x="20" y={maxHeight} rx="0" ry="0" width="90%" height="1" />
        {Array.from({ length: 40 }, (v, i) => (i + 2) * 20).map((v) => {
          const barHeight = (Math.ceil(Math.random() * 10)) * (height / 10);
          return (
            <rect key={`${v}.${barHeight}`} x={v} y={maxHeight - barHeight} rx="0" ry="0" width="15" height={barHeight} />
          );
        })}
      </svg>
    );
  },
  piechart: (
    <svg
      style={{ opacity: 0.1 }}
      viewBox="0 0 400 200"
      height={200}
      width={400}
    >
      <rect x="0" y="5" rx="0" ry="0" width="400" height="15" />
      <circle cx="80" cy="75" r="50" />
      <rect x="150" y="50" rx="0" ry="0" width="7" height="7" />
      <rect x="170" y="50" rx="0" ry="0" width="75" height="7" />
      <rect x="150" y="64" rx="0" ry="0" width="7" height="7" />
      <rect x="170" y="64" rx="0" ry="0" width="75" height="7" />
      <rect x="150" y="78" rx="0" ry="0" width="7" height="7" />
      <rect x="170" y="78" rx="0" ry="0" width="75" height="7" />
      <rect x="150" y="92" rx="0" ry="0" width="7" height="7" />
      <rect x="170" y="92" rx="0" ry="0" width="75" height="7" />
    </svg>
  ),
};
