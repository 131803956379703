import { fromJS } from 'immutable';

export default {
  mapping: [
    { id: 'complexity', type: 'section', required: false, fields: [
      { id: 'length', type: 'number', min: 0, required: false, default: null, label: 'Minimum length for password' },
      { id: 'fields' },
    ], label: 'Password Complexity' },
    { id: 'alerting', type: 'section', required: false, fields: [
      { id: 'alert_date_type', type: 'dropdown', required: false, default: 'discovered_at', label: 'Alert based on', opts: fromJS([{ value: 'discovered_at', label: 'When the breach was discovered by Flashpoint' }, { value: 'breached_at', label: 'When the breach occurred' }]) },
      { id: 'password_reset_policy', type: 'number', required: false, default: null, label: 'Only alert on credentials within the last x days' },
      { id: 'is_fresh', type: 'toggle', required: false, default: true, label: 'Only alert on credentials the first time seen by Flashpoint', labels: true },
      { id: 'ignore_hashes', type: 'toggle', required: false, default: false, label: 'Ignore known hashes', labels: true },
    ], label: 'Alerting' },
    { id: 'send_ccm_e_monthly_reports', type: 'toggle', required: false, default: false, label: 'Subscribe to Monthly Credential Exposure Report', title: 'Subscribe to Monthly Reports', labels: false },
    { id: 'ccm_e_monthly_reports_config', type: 'calendarDay', required: false, default: 1, label: 'Select date to receive Monthly Credential Exposure Report', title: 'Date to receive report', labels: false },
  ],
  default: {
    profile: 'Compromised Credentials Monitoring - Enterprise',
    send_ccm_e_monthly_reports: false,
    alerting: {
      alert_date_type: 'discovered_at',
      password_reset_policy: null,
      is_fresh: true,
      ignore_hashes: false,
    },
    complexity: {
      length: null,
      required_fields: [],
      excluded_fields: [],
      optional_fields: [],
      num_optional_params_required: 0,
    },
  },
};

export const knownHashes = [
  'MD5',
  'SHA-1',
  'SHA-256',
  'bcrypt',
  'phpBB v3.x',
  'MyBB ≥ v1.2+',
  'Wordpress v2.6.0/2.6.1',
  'Wordpress ≥ v2.6.2',
  'vBulletin ≥ v3.8.5',
  'MySQL5.x',
  'MySQL4.1',
];
