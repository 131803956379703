import moment from 'moment';
import data from './CCMcWebhookData';

const hashed = {
  password: {
    md5: 'PKe6XbfTa3Vo_F3dlt',
    sha256: '5e884898da28047151d0e56f8dc6292773603d0d6aabbdd62a11ef721d1542d8',
    sha1: '5baa61e4c9b93f3f0682250b6cf8331b7ee68fd8',
  },
  username: {
    md5: '55502f40dc8b7c769880b10874abc9d0',
    sha256: '973dfe463ec85785f5f95af5ba3906eedb2d931c24e69824a89ea65dba4e813b',
    sha1: '567159d622ffbb50b11b0efd307be358624a26ee',
  },
};

let cachedData = null;

const CCMcWebhookUtils = {
  generateCompromisedCredentialEvent: (config) => {
    if (cachedData) return cachedData;
    const { hash_response, hash_algorithm } = config;
    const event_data = data.map((v, k) => {
      const date = moment.utc(v.breach.first_observed_at);
      const dayOfMonth = date.date();
      let firstObservedAt = moment.utc();
      switch (true) {
        case dayOfMonth <= 10:
          firstObservedAt = firstObservedAt.subtract(2, 'days');
          break;
        case dayOfMonth <= 20:
          firstObservedAt = firstObservedAt.subtract(1, 'days');
          break;
        default:
          break;
      }
      firstObservedAt = firstObservedAt.set({
        hour: date.hour(),
        minute: date.minutes(),
        second: date.seconds(),
      });
      const createdAt = firstObservedAt.set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      return {
        breach: {
          ...v.breach,
          created_at: createdAt,
          first_observed_at: firstObservedAt.format('YYYY-MM-DDTHH:MM:SSZ'),
          fpid: '8yRoU8_HVHGL1VRotXHjIK',
        },
        fpid: `9JcpM2_ktMN3xKcABe${k}`,
        password: !hash_response.includes('password') ? v.password : hashed.password[String(hash_algorithm)],
        sha256: v.sha256,
        ...!hash_response.includes('username') && { username: v.username },
        ...hash_response.includes('username') && { username: hash_algorithm === 'sha256' ? v.sha256 : hashed.username[String(hash_algorithm)] },
      };
    });
    cachedData = {
      event: {
        event_data,
        event_type: 'compromised_credential',
        fpid: '4TmLR7XDq7hreYU4Tg',
      },
    };
    return cachedData;
  },
  generateLoadStatusEvent: () => ({
    event: {
      event_data: [
        {
          fpid: 'PKe6XbfTa3Vo_F3dlt',
          received_at: '2020-07-20T15:31:23.129000',
          result: 'SUCCEEDED',
          rows: 100000,
        },
      ],
      event_type: 'load_status',
      fpid: 'yH8SGV1xt_JO9tcYf',
    },
  }),
  getCompromisedCredentialEvents: (config, page) => {
    const { batch_size } = config;
    const events = CCMcWebhookUtils.generateCompromisedCredentialEvent(
      { ...config, batch_size: 1000 },
    );
    let event_data = [];
    for (let i = page; i >= 0; i -= 1) {
      event_data = event_data.concat(
        events.event.event_data.slice(i * batch_size, (i + 1) * batch_size));
    }
    return {
      event: {
        ...events.event,
        event_data,
      },
    };
  },
};

export default CCMcWebhookUtils;
