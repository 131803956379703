import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { TabPanel } from './TabPanel';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '620px',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    '& h2': {
      paddingBottom: '4px',
    },
  },
  closeIcon: {
    marginLeft: 'auto',
    marginRight: '1rem',
  },
  content: {
    borderTop: `1px ${grey[300]} solid`,
    columnGap: '1rem',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    paddingRight: '24px',
    minHeight: '464.5px',
  },
  contentRoot: {
    padding: '20px 0 0 0',
  },
  tabMenu: {
    width: '200px',
    borderRight: `1px ${grey[300]} solid`,
    marginRight: '15px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      alignItems: 'flex-start',
      color: 'unset',
      textTransform: 'unset',
    },
    '& .Mui-selected': {
      backgroundColor: grey[100],
      color: 'unset',
    },
  },
}));

interface TabDatum {
  id: number;
  label: string;
  content: () => JSX.Element;
}

interface EditWidgetDialogProps {
  open: boolean,
  tabData: TabDatum[],
  selectedTab?: number,
  onCancel: () => void,
  onApply: (value: number) => void,
}

export const EditWidgetDialog = ({
  open,
  selectedTab,
  tabData,
  onCancel,
  onApply,
}: EditWidgetDialogProps): JSX.Element => {
  const [tab, setTab] = useState<number>(0);
  const classes = useStyles();


  useEffect(() => {
    if (selectedTab) {
      setTab(selectedTab);
    }
  }, [selectedTab]);

  const handleApply = (): void => {
    if (onApply) {
      onApply(tab);
    }
    onCancel();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog open={open} className={classes.root} id="dialog-settings">
      <div className={classes.header}>
        <DialogTitle>
          Update Widget
        </DialogTitle>
        <IconButton onClick={handleCancel} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.contentRoot}>
        <Box className={classes.content}>
          <Box className={classes.tabMenu}>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              orientation="vertical"
            >
              {tabData.map(v => <Tab label={v.label}/>)}
            </Tabs>
          </Box>
          <Box>
            {tabData.map((v) => {
              const Content = v.content;
              return (
                <TabPanel value={tab} index={v.id}>
                  <Content />
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleCancel}
          id="ds-cancelBtn"
            >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={handleApply}
          id="ds-applyBtn"
            >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditWidgetDialog.defaultProps = {
  selectedTab: null,
};
