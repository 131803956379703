
const customColors = {
  themeForegroundPrimary: 'rgba(0, 0, 0, 0.87)', // dark grey
  themeBackgroundPrimary: '#fafafa', // white-ish
  themeBackgroundSecondary: '#1a1b33', // midnight blue
  themeBorders: 'rgba(0, 0, 0, 0.54)', // part transparent
  vtRed500: '#C20000',
  vtYellow500: '#F4BA0B',
  vtBlue500: '#5c6ae0',
  vtOrange500: '#5c6ae0',
  vtGreen500: '#14967A',
  vtWhite: '#FFF',
  vtGrey500: '#808285',
  vtTabPadding: '#FFF',
};

const style = () => `
  html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    min-height: 100%;
  }
  body {
    color: ${customColors.themeForegroundPrimary};
    background: ${customColors.themeBackgroundPrimary};
    -webkit-font-smoothing: antialiased;
    height: 100%;
    margin: 0;
    text-decoration: none;
    width: 100%;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding: 0.625rem 2rem;
    border-bottom: 1px solid ${customColors.themeBorders};
    background: ${customColors.themeBackgroundSecondary};
  }
  header nav {
    display: flex;
    align-items: center;
    height: 3.25rem;
  }
  section {
    padding: 1.5rem 1.5rem;
  }
  section[rel="iocs"],
  section[rel="graph"] {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  section[rel="error"] {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  section[rel="error"] .vt-reload-btn {
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.75rem 2.25rem;
    font-size: 1.5rem;
    background: ${customColors.themeForegroundPrimary};
    color: ${customColors.themeBackgroundPrimary};
    border: 0;
    border-radius: 3px;
  }
  section[rel="error"] + footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 4rem);
  }
  footer {
    text-align: center;
    font-size: 0.9rem;
    padding: 2rem;
  }
  footer a {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: none;
  }
  footer a:hover {
    text-decoration: underline;
  }
  button.tab {
    text-align: left;
    border: 0;
    outline: 0;
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    color: ${customColors.themeBackgroundPrimary};
    background: ${customColors.themeBackgroundSecondary};
    padding: 0;
  }
  button.tab[active] span {
    border-bottom: 3px solid ${customColors.themeBackgroundPrimary};
  }
  button.tab:not([active]) {
    cursor: pointer;
  }
  button[rel=graph]{
    display: none !important;
  }
  .tab-separator {
    height: 2rem;
    margin: 0 1rem;
  }
  hr {
    margin: 1rem -1.5rem;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-color: ${customColors.themeBorders};
  }
  *[hidden] {
    display: none !important;
  }
  .vt-close-widget {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: -20px;
    padding: 8px 20px 4px 20px;
  }
  .vt-close-widget svg {
    width: 24px;
    height: 24px;
    fill: ${customColors.vtWhite};
  }
  .vt-logo-link {
    display: flex;
    margin-left: 0.5rem;
  }
  .vt-logo-link--wider {
    width: 7.5rem;
  }
  .vt-logo-link--shorter {
    width: 24px;
    height: 24px;
    fill: ${customColors.themeForegroundPrimary};
  }
  .vt-logo-link svg {
    fill: ${customColors.vtWhite};
  }
  .back-to-top {
    display: none;
    /* Changed by onscroll event handler */
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    fill: ${customColors.themeForegroundPrimary};
    background: ${customColors.themeBorders};
    border-radius: 5px;
  }
  .vt-alert-panel {
    display: flex;
    color: ${customColors.themeForegroundPrimary};
    background: ${customColors.themeBackgroundSecondary};
    border-radius: 5px;
    padding: 15px 10px;
    margin-top: 1.5rem;
  }
  .vt-alert-panel__button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    background: ${customColors.themeBorders};
    color: ${customColors.themeForegroundPrimary};
    border: 0;
    border-radius: 3px;
    white-space: nowrap;
    margin-left: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  /* VT SUMMARY COMPONENT */
  .vt-summary {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .vt-summary__scoring {
    padding-right: 1rem;
    border-right: solid ${customColors.themeBorders} 1px;
  }
  .vt-summary__info {
    width: 100%;
    line-height: 1.5rem;
    padding-left: 1rem;
    border-left: solid ${customColors.themeBorders} 1px;
    margin-left: -1px;
  }
  .vt-summary__info__tags {
    margin-top: 10px;
  }
  .vt-summary__info__tags .vt-utils-pivot {
    color: ${customColors.themeBackgroundPrimary};
    padding: 1rem;
  }
  .vt-summary__info__tag {
    background: ${customColors.themeForegroundPrimary};
    margin-right: 1rem;
    border-radius: 9px;
    font-size: 0.9rem;
    padding: 0.125rem 0.05rem;
  }
  .vt-summary__info__list {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }
  .vt-summary__info__list a,
  [data-pivot-val^="size"],
  [data-pivot-val^="type"] {
    color: ${customColors.themeForegroundPrimary} !important;
    pointer-events: none;
    text-decoration: none;
  }
  .vt-summary__info__list__separator {
    border-right: 1px solid ${customColors.themeBorders};
    height: 4rem;
  }
  .vt-summary__info__list > * {
    margin-right: 1.25rem;
  }
  .vt-summary__info__flag {
    width: 1.5rem;
    margin-right: 5px;
    vertical-align: sub;
  }
  .vt-summary__info__list__icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${customColors.themeBorders};
    vertical-align: middle;
    background-color: ${customColors.themeBackgroundPrimary};
    padding: 3px;
    margin-right: 10px;
  }
  .vt-summary__info__list__icon img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    image-rendering: pixelated;
  }
  .vt-summary__info__list__type-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 3px;
    border: 1px solid ${customColors.themeBorders};
  }
  .vt-summary__info__list__type-icon svg {
    fill: ${customColors.themeBorders};
  }
  /* COMMOM VT INFO BOX COMPONENT */
  .vt-generic-box {
    margin: 1.5rem 0;
  }
  .vt-generic-box__header {
    margin-bottom: 1rem;
    border-bottom: 1px solid ${customColors.themeBorders};
    padding-bottom: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .vt-generic-box__header__action {
    height: 24px;
    width: 24px;
  }
  .vt-generic-box__header__action svg {
    width: 24px;
    height: 24px;
    fill: ${customColors.themeForegroundPrimary};
  }
  .vt-generic-box__header__title {
    font-weight: bold;
    text-transform: uppercase;
  }
  .vt-generic-box__subtitle {
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  /* COMMON VT TABLE COMPONENT */
  .vt-table {
    display: table;
  }
  .vt-table--expandable:not([expanded]) > div:nth-child(n+6) {
    display: none;
  }
  .vt-table__expand-action {
    display: block;
    margin-top: 1rem;
    height: 24px;
    /* IE11 */
    flex: 0 0 100%;
  }
  .vt-table__expand-action svg {
    width: 2rem;
    fill: ${customColors.themeForegroundPrimary};
  }
  .vt-table__more-results {
    color: ${customColors.themeForegroundPrimary};
    text-decoration: none;
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
  .vt-table__more-results:hover {
    text-decoration: underline;
  }
  .vt-table__row {
    display: table-row;
    line-height: 1.25rem;
  }
  .vt-table__col {
    display: table-cell;
    white-space: nowrap;
    padding: 4px 0;
  }
  .vt-table__col:not(:last-of-type) {
    padding-right: 25px;
    font-weight: bold;
  }
  .vt-table__col--breakable {
    word-break: break-all;
    white-space: inherit;
  }
  .vt-table__col--icon {
    max-width: 24px;
    padding-right: 0.5rem;
  }
  .vt-table__col--icon:not(:last-of-type) {
    padding-right: 0.5rem;
  }
  .vt-error {
    text-align: center;
    padding: 0 3rem 0 3rem;
  }
  .vt-error__title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .vt-error__icon svg {
    margin-bottom: 2rem;
    width: 100px;
    height: 100px;
    fill: ${customColors.themeBorders};
  }
  /* VT DETECTIONS COMPONENT */
  .vt-detections {
    background-color: lightgrey;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .vt-detections svg circle {
    stroke: ${customColors.vtGreen500};
  }
  .vt-detections.has-malicious svg circle {
    stroke: ${customColors.vtRed500};
  }
  .vt-detections__circle {
    background-color: ${customColors.themeBackgroundPrimary};
    border-radius: 50%;
    height: 100%;
    width: 100%;
    text-align: center;
    color: ${customColors.themeForegroundPrimary};
    padding-top: 15px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }
  .vt-detections__circle__positives {
    font-size: 2rem;
    line-height: 2rem;
  }
  .vt-detections__circle__total {
    color: ${customColors.themeBackgroundSecondary};
    font-size: 1rem;
  }
  .vt-detections__progressbar {
    transform: rotate(-90deg);
    position: relative;
    top: -90px;
    left: -10px;
    stroke-linecap: round;
  }
  /* DETECTION LIST COMPONENT */
  .vt-detection-list {
    display: flex;
    flex-wrap: wrap;
  }
  .vt-detection-list__result {
    flex: 0 0 50%;
  }
  /* LAST HTTP CERTIFICATE COMPONENT */
  .vt-last-http-certificate {
    font-size: 0.9rem;
    font-family: monospace;
    line-height: 1.25rem;
    word-break: break-word;
  }
  /* SCREENSHOTS COMPONENT */
  .vt-screenshots {
    display: flex;
    flex-wrap: wrap;
  }
  .vt-screenshots__screenshot {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    text-align: center;
  }
  .vt-screenshots__screenshot img {
    width: 150px;
    border: 2px solid ${customColors.themeBorders};
  }
  /* WHOIS COMPONENT */
  .vt-whois {
    font-family: monospace;
    font-size: 0.9rem;
    line-height: 1.25rem;
  }
  /* VT LAST DNS CERTIFICATE COMPONENT */
  .vt-last-dns-certificate div:nth-child(1) {
    max-width: 90px;
  }
  .vt-last-dns-certificate div:nth-child(2) {
    max-width: 75px;
  }
  .vt-last-dns-certificate div:nth-child(3) {
    max-width: 100%;
  }
  /* VT COMMUNITY SCORE COMPONENT */
  .vt-community-score {
    margin-top: 1rem;
  }
  .vt-community-score__count {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
  .vt-community-score__text {
    line-height: 0.9rem;
    font-size: 0.9rem;
  }
  /* VT GRAPH COMPONENT */
  .vt-graph-app {
    background: white;
    border: 1px solid ${customColors.themeBorders};
  }
  /* VT YARA COMPONENT */
  .vt-yara svg {
    margin-right: 3px;
  }
  .vt-yara__rule {
    display: flex;
    align-items: center;
  }
  .vt-yara__rule svg {
    width: 24px;
    height: 24px;
    fill: inherit;
  }
  .vt-yara__description {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    font-style: italic;
    margin: 3px 0 1rem 2.5rem;
  }
  .vt-yara__description svg {
    width: 20px;
    height: 20px;
    fill: ${customColors.themeForegroundPrimary};
  }
  /* VT SIGMA COMPONENT */
  .vt-sigma__rule {
    display: flex;
    align-items: center;
    fill: ${customColors.themeForegroundPrimary};
  }
  .vt-sigma__rule svg {
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }
  /* VT ACTIONS COMPONENT */
  .vt-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vt-actions__action {
    display: inline-block;
    text-align: center;
  }
  .vt-actions__action svg {
    height: 32px;
    width: 32px;
    fill: ${customColors.themeForegroundPrimary};
  }
  /* UTILS */
  .vt-utils-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: bottom;
  }
  .vt-utils-mt-1_5rem {
    margin-top: 1.5rem;
  }
  .vt-utils-mb-1rem {
    margin-bottom: 1rem;
  }
  .vt-utils-mw-200 {
    max-width: 200px;
  }
  .vt-utils-flex {
    display: flex;
    align-items: center;
  }
  .vt-utils-w-400 {
    width: 400px;
  }
  .vt-error-color--svg {
    fill: ${customColors.vtRed500};
  }
  .vt-warning-color--svg {
    fill: ${customColors.vtBlue500};
  }
  .vt-success-color--svg {
    fill: ${customColors.vtGreen500};
  }
  .vt-fg-color--svg {
    fill: ${customColors.themeForegroundPrimary};
  }
  .highlighted-text,
  .malicious-color {
    color: ${customColors.vtRed500} !important;
  }
  .success-text,
  .undetected-color {
    color: ${customColors.vtGreen500} !important;
  }
  .vt-utils-tab--x2 {
    padding-left: calc(${customColors.vtTabPadding} * 2);
  }
  .vt-utils-tab--x3 {
    padding-left: calc(${customColors.vtTabPadding} * 3);
  }
  .vt-utils-tab--x4 {
    padding-left: calc(${customColors.vtTabPadding} * 4);
  }
  .vt-utils-tab--x5 {
    padding-left: calc(${customColors.vtTabPadding} * 5);
  }
  .vt-utils-pre {
    white-space: pre;
  }
  .vt-utils-relative {
    position: relative;
  }
  .vt-utils-pivot,
  .vt-utils-pivot:visited,
  .vt-utils-pivot:active {
    color: ${customColors.vtBlue500};
    text-decoration: none;
    font-weight: normal;
  }
  .vt-utils-pivot[href]:hover {
    text-decoration: underline;
  }
  .vt-utils-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vt-utils-toggle-ellipsis {
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 5px;
    vertical-align: super;
    position: absolute;
    top: 0;
    right: -1.5rem;
  }
  .vt-utils-caps {
    text-transform: uppercase;
  }
  .vt-utils-expandable-action {
    font-size: 1.5rem;
    width: 2rem;
    display: inline-flex;
    justify-content: center;
    outline: 0;
    border: 0;
    background: none;
    color: ${customColors.themeForegroundPrimary};
    cursor: pointer;
  }
  .vt-utils-expandable-content {
    border-left: 1px solid ${customColors.themeBorders};
    padding-left: 1rem;
    margin: 0.5rem 0 0.5rem 2.8rem;
    font-size: 0.9rem;
  }
  .vt-utils-engine-verdict {
    display: inline-block;
    max-width: 300px;
  }
  .vt-utils-svg svg {
    width: 24px;
    height: 24px;
    fill: inherit;
  }
  .vt-utils-disabled {
    pointer-events: none;
    opacity: 25%;
  }
  .vt-utils-blur {
    text-shadow: 0 0 8px ${customColors.themeForegroundPrimary};
    color: rgba(255, 255, 255, 0);
  }
  .vt-utils-center {
    text-align: center;
  }
  .vt-utils-w-full {
    width: 100%;
  }
  .vt-utils-hide {
    display: none !important;
  }
  .vt-utils-disabled{
    display: none !important;
  }
  .vt-utils-small-text {
    font-size: 13px;
  }
  /* ACCENT COLORS */
  .vt-blue,
  .vt-blue:visited,
  .vt-blue:active {
    color: var(--vt-blue-500);
    fill: var(--vt-blue-500);
  }
  .vt-red,
  .vt-red:visited,
  .vt-red:active {
    color: ${customColors.vtRed500};
    fill: ${customColors.vtRed500};
  }
  .vt-orange,
  .vt-orange:visited,
  .vt-orange:active {
    color: ${customColors.vtBlue500};
    fill: ${customColors.vtBlue500};
  }
  span[class~="vt-orange"] {
    color: ${customColors.vtGrey500};
    fill: ${customColors.vtGrey500};
  }
  .vt-yellow,
  .vt-yellow:visited,
  .vt-yellow:active {
    color: ${customColors.vtYellow500};
    fill: ${customColors.vtYellow500};
  }
  /* TOOLTIP */
  .tippy-box {
    color: ${customColors.vtWhite};
    background: ${customColors.themeForegroundPrimary};
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    padding: 16px;
  }
  /* MEDIA QUERIES */
  @media (max-width: 650px) {
    .hide-on-narrower {
      display: none;
    }
    .show-on-narrower {
      display: block !important;
    }
    .vt-detection-list__result {
      flex: 0 0 100%;
    }
  }
`;

export default style;
