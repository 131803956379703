import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import Query from '../query';
import StatusDisplay from './StatusDisplay';
import Text from '../../../../utils/text';
import LightboxImage from './LightboxImage';

import DetailsColumnCard from './DetailColumnCard';

import useStyles from './style';

import RowText from './RowText';

/** Displays the details of a Phishing incident  */
const DetailsColumn = ({ incident, width, limitReached }) => {
  const classes = useStyles();
  const timeFormat = 'M/D/Y h:mm A';
  const startTime = moment(incident.get('opened_at')).format(timeFormat);
  const endTime = incident.get('closed_at') ? moment(incident.get('closed_at')).format(timeFormat) : '-';
  const timeDiff = Text.timeDiff(incident.get('opened_at'), incident.get('closed_at') || moment(new Date()));
  const duration = timeDiff
    ? `${timeDiff.days} days ${timeDiff.hours} hours ${timeDiff.minutes} minutes`
    : '-';
  const brandName = incident.get('brand_name') || 'Unknown Brand';
  const type = incident.get('type') || 'N/A';
  const subType = incident.get('sub_type') || null;
  const provider = {
    name: incident.getIn(['forensics', 'provider', 'name']) || '-',
    country: incident.getIn(['forensics', 'provider', 'country']) || '-',
  };
  const nameServers = incident.getIn(['forensics', 'nameservers'])?.toJS() || ['-'];
  const domainRegistrar = {
    name: incident.getIn(['forensics', 'domain_registrar', 'name']) || '-',
    url: incident.getIn(['forensics', 'domain_registrar', 'url']) || '-',
    email: incident.getIn(['forensics', 'domain_registrar', 'email']) || '-',
  };
  const imageSrc = Query.getImageSrc(incident.get('id'), incident.getIn(['images', 'active']));
  const data = [
    { title: 'Site Owner', incident: {
      name: incident.getIn(['forensics', 'site_owner', 'name']) || '-',
      country: incident.getIn(['forensics', 'site_owner', 'country']) || '-',
      email: incident.getIn(['forensics', 'site_owner', 'email']) || '-',
    },
    },
    { title: 'Site Admin', incident: {
      name: incident.getIn(['forensics', 'site_admin', 'name']) || '-',
      country: incident.getIn(['forensics', 'site_admin', 'country']) || '-',
      email: incident.getIn(['forensics', 'site_admin', 'email']) || '-',
    },
    },
    { title: 'Domain Admin', incident: {
      name: incident.getIn(['forensics', 'domain_admin', 'name']) || '-',
      country: incident.getIn(['forensics', 'domain_admin', 'country']) || '-',
      email: incident.getIn(['forensics', 'domain_admin', 'email']) || '-',
    },
    },
    { title: 'IP Provider', incident: {
      name: incident.getIn(['forensics', 'ip_provider', 'name']) || '-',
      country: incident.getIn(['forensics', 'ip_provider', 'country']) || '-',
      email: incident.getIn(['forensics', 'ip_provider', 'email']) || '-',
    },
    },
  ];


  const incidentDetails = [
    {
      title: 'Brand', incident: brandName,
    },
    {
      title: 'Type', incident: `${type} ${subType ? ` - ${subType}` : ''}`,
    },
    {
      title: 'URL', incident: brandName,
    },
    {
      title: 'Incident Start Time', incident: startTime,
    },
    {
      title: 'Incident End Time', incident: endTime,
    },
    {
      title: 'Incident Duration', incident: duration,
    },
  ];

  const forensics = [
    {
      title: 'Provider Name', incident: provider.name,
    },
    {
      title: 'Provider Country', incident: provider.country,
    },
  ];

  const domainRegistrarObj = [
    {
      title: 'Name', incident: domainRegistrar.name,
    },
    {
      title: 'URL', incident: <Link href={domainRegistrar.url} target="_blank" rel="noreferrer" className={classes.link}>{domainRegistrar.url}</Link>,
    },
    {
      title: 'Email', incident: /\S+@\S+\.\S+/.test(domainRegistrar.email) ?
        <Link href={`mailto:${domainRegistrar.email}`} target="_blank" rel="noreferrer" className={classes.link}>{domainRegistrar.email}</Link>
        :
        <Typography>{domainRegistrar.email}</Typography>,
    },
  ];
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {brandName} {incident.get('urls') ? ` - ${incident.get('urls').get(0)}` : ''}
        </Typography>
        <StatusDisplay incident={incident} limitReached={limitReached} />
      </Grid>
      <Grid container item xs={width} className={classes.body}>
        <Grid item xs={12}>
          <Paper elevation={5}>
            <Grid container>
              {/* Header */}
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={[classes.heading, classes.extraTopMargin].join(' ')}>
                  Incident Details
                </Typography>
              </Grid>
              {/* Left col */}
              <Grid item xs={7}>
                {incidentDetails.map(incidentData =>
                  <RowText title={incidentData.title} data={incidentData.incident} />)
                }
                <Typography variant="h2" className={[classes.heading, classes.headingRow].join(' ')}>Forensics</Typography>
                {/* Start of forensics */}
                {forensics.map(incidentData =>
                  <RowText title={incidentData.title} data={incidentData.incident} />,
                  )}
                {nameServers.map(item => (
                  <RowText title="Name Server" data={item} />
                ))}
                {/* End of forensics */}
                <Typography variant="h2" className={[classes.heading, classes.headingRow].join(' ')}>Domain Registrar</Typography>
                {domainRegistrarObj.map(incidentData =>
                  <RowText title={incidentData.title} data={incidentData.incident} />)}
                {
                  data.map(incidentData =>
                    (<DetailsColumnCard
                      title={incidentData.title}
                      incident={incidentData.incident}

                    />),
                    )
                  }
              </Grid>
              {/* Right col */}
              <Grid container item xs={5} alignContent="flex-start">
                <LightboxImage src={imageSrc} className={classes.thumbnail} alt="Screenshot" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

DetailsColumn.propTypes = {
  /** Details of the incident to be displayed. */
  incident: PropTypes.object,
  /** Width of the component in number of columns. (1-12) */
  width: PropTypes.number,
  /** Setting to `true` indicates the user has no takedown requests left */
  limitReached: PropTypes.bool,
};

DetailsColumn.defaultProps = {
  incident: {},
  width: 12,
  limitReached: true,
};

export default DetailsColumn;
