import React, { useState } from 'react';

import { Col } from 'react-flexbox-grid/lib';
import { useRecoilValue } from 'recoil';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import style from './iocdashboard.module.scss';
import Looker from '../../utils/Looker';
import { getSfid } from '../../../stores/recoil/app';


const IocDashboard = () => {
  const sid = useRecoilValue(getSfid);
  const [tab, setTab] = useState('ioc_dash');

  const handleChange = (event, tabName) => {
    setTab(tabName);
  };

  const getDashboard = (dashboardName, height) => (
        sid &&
          <Looker
            height={height || '100vh'}
            dashboard={`/dashboards-next/data_warehouse_indicators_views::${dashboardName}`}
            sid={sid}
        />
      );

  return (
    <Col xs={12} className={style.containers}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Malware IOCs" value="ioc_dash" />
            <Tab label="Security Tool IOCs" value="mal_sec_tool_dash" />
          </TabList>
        </Box>
        <TabPanel value="ioc_dash">{getDashboard('ioc_dashboard', '180vh')}</TabPanel>
        <TabPanel value="mal_sec_tool_dash">{getDashboard('malicious_security_tool_usage')}</TabPanel>
      </TabContext>
    </Col>
  );
};

IocDashboard.propTypes = {};

export default IocDashboard;
