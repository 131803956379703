import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
 Autocomplete as MuiAutocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey, red } from '@mui/material/colors';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue, useRecoilState } from 'recoil';
import { fromJS, Map as map } from 'immutable';

import AlertingStore from '../../../../stores/recoil/alerting';
import Query from '../../../../containers/Alerting/query';
import Text from '../../../../utils/text';

const REQUIRED_ERROR_MESSAGE = 'This field is required';

const useStyles = makeStyles(() => ({
  checkbox: {
    margin: '.8rem',
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginBottom: '.25rem',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '1.2rem',
      textTransform: 'none',
    },
  },
  dialogBody: {
    padding: '0 2.4rem 2.4rem 2.4rem',
  },
  dialogFooter: {
    borderTop: `1px ${grey[200]} solid`,
  },
  dialogFormGroup: {
    padding: '0 2.4rem',
  },
  dialogTitle: {
    padding: '1.6rem 0',
    '& .MuiTypography-root': {
      fontSize: '2.4rem',
      fontWeight: '600',
    },
  },
  errorText: {
    color: red[500],
    fontSize: '1.4rem',
  },
  fieldGap: {
    marginBottom: '2rem',
  },
}));

const TwitterDialog = ({ open, user, keyword, onSubmit, onCancel, options }) => {
  const [selectedGoal, setSelectedGoal] = useState();
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [owners, setOwners] = useRecoilState(AlertingStore.owners);
  const owner = useRecoilValue(AlertingStore.owner);
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const [alertingFilters, setFilters] = useRecoilState(AlertingStore.filters);
  const { control, handleSubmit, formState: { errors } } = useForm();
  const classes = useStyles();

  const {
    dialog,
    setDialog,
    goals,
    initialGoal,
  } = options;

  useEffect(() => {
    if (dialog?.key === 'edit') {
      setSelectedKeyword(dialog?.value);
    } else {
      setSelectedKeyword(keyword);
    }
  }, [options]);

  useEffect(() => {
    if (initialGoal) {
      setSelectedGoal(initialGoal);
    }
  }, [initialGoal]);

  useEffect(() => {
    if (!owners) return;

    /*
     * If a user doesn't have permission to change their org then make sure
     * it's set to something sensible, like their org in salesforce.
     */
    if (open && !user.get('prm').some(p => /dat.twtr.adm/ig.test(p))) {
      const sfid = user.get('sf_id');
      const userOwner = owners.find(item => item.get('salesforce_id') === sfid);
      if (userOwner) {
        setFilters(alertingFilters.set('owner_id', userOwner.get('id')));
      }
    }
  }, [ownerId, owners]);

  useEffect(() => {
    if (open) {
      Query.load('owners', user.get('prm')).then(res => setOwners(fromJS(res.owners)));
      setOwners(owners);
    }
  },
  []);

  const isCyberSecurity = (id) => {
    const cyber = goals?.find(v => /cyber security/ig.test(v.label));
    return id === cyber?.value;
  };

  const onClientSelect = (selected) => {
    setFilters(alertingFilters.set('owner_id', selected.id));
  };

  const _onSubmit = (data) => {
    /*
     * HACK: Our old version of React Hook Forms doesn't play nice with the MUI select component.
     * So we manually grab the keyclass_id (goal) and keywordId to prevent form from breaking.
     */
    const _data = {
      ...data,
      goal: selectedGoal,
      keywordId: selectedKeyword?.id,
    };

    if (onSubmit) {
      onSubmit(_data);
    }
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(_onSubmit)} className="twitterForm">
        <Grid container>
          <Grid item xs={12} className={classes.dialogBody}>
            <DialogTitle
              className={classes.dialogTitle}>
              {dialog?.key === 'create' ? 'Add new Twitter Keyword' : 'Twitter Keyword' }
            </DialogTitle>
            <FormGroup className={classes.dialogFormGroup}>
              {open && !owner.isEmpty() &&
                <div className={classes.fieldGap}>
                  {user.get('prm').some(p => /dat.twtr.adm/ig.test(p)) && owners.count() > 1 &&
                  <FormControl>
                    <InputLabel variant="outlined" id="goalLabel">Organization</InputLabel>
                    <MuiAutocomplete
                      fullWidth
                      autoSelect
                      openOnFocus
                      blurOnSelect
                      disableCloseOnSelect
                      disabled={dialog?.key === 'view'}
                      value={owners.find(v => v.get('id') === ownerId).toJS()}
                      options={owners
                        .sortBy(v => v.get('name').toLowerCase())
                        .toJS()}
                      getOptionLabel={option => option.name}
                      onChange={(event, option, reason) => {
                        if (['selectOption'].includes(reason)) onClientSelect(option);
                      }}
                      renderInput={parameters => (
                        <TextField
                          {...parameters}
                          variant="outlined" />
                        )}
                      />
                  </FormControl>}
                  {owners.count() === 1 &&
                  <div className={classes.fieldGap}>
                    <span>{Text.Sentence(owner.get('name'))}</span>
                  </div>}
                </div>}
              <div className={classes.fieldGap}>
                <InputLabel variant="outlined" id="goalLabel">Use Case</InputLabel>
                <Select
                  onChange={(event) => {
                    const goal = event?.target?.value;
                    if (goal) {
                      setSelectedGoal(goal);
                    }
                  }}
                  defaultValue={initialGoal}
                  disabled={dialog?.key === 'view'}
                  labelId="goalLabel"
                  variant="outlined" >
                  {goals.map(v =>
                    <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>,
                  )}
                </Select>
              </div>
              <div className={classes.fieldGap}>
                <Controller
                  name="name"
                  defaultValue={selectedKeyword?.name}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 500,
                  }}
                  shouldUnregister
                  render={({ field }) =>
                    <TextField
                      error={errors?.name !== undefined}
                      disabled={dialog?.key === 'view'}
                      label="Name"
                      variant="outlined"
                      data-testid="name-field"
                      {...field} />
                    }
                  />
                <div className={classes.errorText}>{errors?.name && REQUIRED_ERROR_MESSAGE}</div>
                <div className={classes.errorText}>{errors?.name?.type === 'maxLength' && 'Please enter less than 500 characters'}</div>
              </div>
              <div className={classes.fieldGap}>
                <Controller
                  name="keyword"
                  defaultValue={selectedKeyword?.value}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 1500,
                  }}
                  shouldUnregister
                  render={({ field }) =>
                    <TextField
                      error={errors?.keyword !== undefined}
                      disabled={dialog?.key === 'view'}
                      label="Keyword"
                      placeholder="Valid search string"
                      variant="outlined"
                      data-testid="ip-field"
                      {...field} />
                    }
                  />
                <div className={classes.errorText}>
                  {errors?.keyword?.type === 'required' && REQUIRED_ERROR_MESSAGE}
                </div>
                <div className={classes.errorText}>
                  {errors?.keyword?.type === 'maxLength' && 'Please enter less than 1500 characters'}
                </div>
              </div>
              <div className={classes.checkbox}>
                <FormControlLabel
                  disabled={dialog?.key === 'view'}
                  control={
                    <Controller
                      name="checkboxOne"
                      control={control}
                      defaultValue={dialog?.key === 'view'}
                      rules={{
                        required: true,
                      }}
                      shouldUnregister
                      render={({ field }) => <Checkbox {...field} />}
                  />
                }
                  label={
                    <div>
                      Check here to confirm that this search is compliant with our{' '}
                      <Link
                        onClick={() => setDialog({ key: 'agreement', previous: { ...dialog } })}>
                        Twitter Search Agreement
                      </Link>
                    </div>
                    } />
                <div className={classes.errorText}>
                  {errors?.checkboxOne && REQUIRED_ERROR_MESSAGE}
                </div>
              </div>
              {!isCyberSecurity(selectedGoal) &&
                <>
                  <div className={classes.checkbox}>
                    <FormControlLabel
                      disabled={dialog?.key === 'view'}
                      shouldUnregister
                      control={
                        <Controller
                          name="checkboxTwo"
                          control={control}
                          defaultValue={dialog?.key === 'view'}
                          rules={{
                            required: true,
                          }}
                          shouldUnregister
                          render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                      label="Check here to confirm you have included brand information that makes this search specific to your company" />
                    <div className={classes.errorText}>
                      {errors?.checkboxTwo && REQUIRED_ERROR_MESSAGE}
                    </div>
                  </div>
                  <div>
                    <Controller
                      name="keywordId"
                      control={control}
                      defaultValue={selectedKeyword?.id}
                      shouldUnregister
                      render={({ field }) => <input type="hidden" name="keywordId" {...field} />}
                    />
                  </div>
                </>}
            </FormGroup>
            <Grid container item xs={12} justifyContent="flex-end" className={classes.dialogFooter}>
              <Grid item>
                <Button onClick={onCancel}>
                  {dialog?.key === 'view' ? 'Close' : 'Cancel'}
                </Button>
              </Grid>
              {(dialog?.key === 'create' || dialog?.key === 'edit')
                && <Grid item><Button color="secondary" type="submit">Save</Button></Grid>}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

TwitterDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  keyword: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  options: PropTypes.object,
};

TwitterDialog.defaultProps = {
  open: false,
  user: map(),
  keyword: {},
  onSubmit: null,
  onCancel: null,
  options: {},
};

export default TwitterDialog;
