/* eslint-disable newline-per-chained-call */
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import React, { useContext, useLayoutEffect, useEffect, useState } from 'react';

import qs from 'qs';
import cx from 'classnames';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactTooltip from 'react-tooltip';
import { faSparkles } from '@flashpoint/fp-icons/pro-light-svg-icons';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Icon,
  Paper,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Table as TableUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './table.module.scss';
import Query from './query';
import Pager from '../../utils/Pager';
import Invalid from '../../utils/Invalid/Invalid';
import ToolTip from '../../utils/Ellipsis';
import Bookmark from '../../utils/Bookmark';
// react-photo-gallery somehow messes up flex if not imported at beginning instead of lazy loaded
import MediaGrid from '../../utils/MediaGrid';
import InternalLink from '../../utils/InternalLink';
import Text from '../../../utils/text';
import Common from '../../../utils/common';
import Messages from '../../../constants/Messages';
import SearchSectionsSearch from '../../../stores/recoil/searchSectionsSearch';
import History from '../../../utils/history';
import AlertingStore from '../../../stores/recoil/alerting';
import Virustotal from '../../utils/Virustotal/virustotal';
import easyUseState from '../../../hooks/easyUseState';
import { Tag } from '../../threads/Tag';
import { Slide } from './Slide';
import { SearchContext, UserContext } from '../../utils/Context';
import { knownHashes } from '../../../constants/org_profiles/Edm';
import { appStore } from '../../../stores/recoil/app';
import { Track } from '../../../utils/track';

// Lazy loaded modules dependent on type
let Iso = null;
let MediaTypes = null;
let CCM = null;
let MetaDataActions = null;
let Tagger = null;

const makeDataSlice = (data, startIndex, filters) => (
  !data.get('loading') && data.has('total') && data.get('total') > 0 &&
    data.get('data').slice(startIndex, startIndex + parseInt(filters.get('limit', 25) || 25, 10))
);
const makeDataSliceId = currentDataSlice => (
  currentDataSlice && typeof currentDataSlice.map === 'function' ?
    currentDataSlice.map(row => (row.get('id', row.get('fpid')))).join(',') :
    null
);
const makeFiltersId = filters => (
  filters.toArray().sort().join(',')
);

const IGNITE_REPORT_URL = '//app.flashpoint.io/cti/intelligence/chat';
const IGNITE_APT_URL = '//app.flashpoint.io/search/actor-profile';

const Table = ({
  apps,
  blur,
  data,
  filters,
  groups,
  userHistory,
  limits,
  metaData,
  pager,
  prm,
  route,
  saved,
  sorts,
  type,
}) => {
  const user = useContext(UserContext);
  const search = useContext(SearchContext);

  const [dialog, setDialog] = useState();
  const [selected, setSelected] = easyUseState([]);
  const [carousel, setCarousel] = useState(-1);
  // Even though setImages is called in a useEffect hook with well-chosen dependencies
  // There are still cases where we must compare previous to incoming state for images
  const [images, setImages] = easyUseState([]);
  const [hidden, setHidden] = easyUseState([]);

  const selectedSection = useRecoilValue(SearchSectionsSearch.selectedSection(type));
  const [keywordClasses, setKeywordClasses] = useRecoilState(AlertingStore.keywordClasses);
  const [appState, setAppState] = useRecoilState(appStore);
  const chatTagReadPermission = prm.some(p => /meta.r/.test(p));
  const startIndex = filters?.get('all')?.includes('reports') ? parseInt(filters.get('skip') || 0, 10) : 0;
  const { pathname: currentPath, query: currentQuery } = History.getCurrentLocation();

  const currentDataSlice = makeDataSlice(data, startIndex, filters);
  const dataSliceId = makeDataSliceId(currentDataSlice);
  const filtersId = makeFiltersId(filters);

  // didDrag tracks whether the cursor clicked and released
  // or if it clicked and dragged
  let didDrag = false;
  let mouseDown = false;
  let downX = 0;
  let downY = 0;

  const onBulkSave = (selectedValues, values) => {
    const value = { ...values.toJS() };
    switch (selectedSection) {
      case 'tagging.channels':
        MetaDataActions.updateChannels(value, selectedValues);
        break;
      default: break;
    }
  };

  const onReport = (id, href) => {
    const subject = `Media Report - ${id}`;
    const body = `I would like to report the media file with the id "${id}" located at ${Text.DefangLink(href)}.`;
    setHidden(v => [...new Set([...v, id])]);
    /* eslint-disable-next-line security/detect-non-literal-fs-filename */
    window.open(`mailto:feedback@flashpoint-intel.com?cc=tpollard@flashpoint-intel.com&subject=${subject}&body=${body}`);
  };

  const onSelect = (rows) => {
    const limited = data
      .get('data')
      .slice(startIndex, startIndex + parseInt(filters.get('limit') || 25, 10));
    const selectedValues = rows === 'all'
      ? limited.map(v => v.get('id') || v.get('fpid')).toJS()
      : rows;
    setSelected(selectedValues);
  };

  const onSort = (order) => {
    const sort = sorts.getIn([selectedSection, +(order === 'desc'), 'value']);
    const { pathname, query } = History.getCurrentLocation();
    History.push({
      pathname,
      query: { ...query, sort },
    });
  };

  const onAction = (event, key = '', context = map()) => {
    event.preventDefault();
    event.stopPropagation();
    const basetype = Common.Basetypes.ExtractMainBasetype(context);
    switch (key) {
      case 'tag': {
        const component = <Tag
          type={basetype}
          post={context.toJS()}
          onCancel={() => setDialog()} />;
        setDialog({
          key,
          component,
          text: 'Tag selected post',
          target: event.target,
        });
        break;
      }
      case 'tagger': {
        const component = <Tagger
          prm={prm}
          fpid={context.get('fpid')}
          username={user.get('usn')}
          metaData={metaData}
          selected={context.get('tags')}
          // styles={{ width: '600px' }}
          onAction={() => setDialog()} />;
        setDialog({
          key,
          component,
          text: 'Tag selected post',
          target: event.target,
        });
        break;
      }
      default: break;
    }
  };

  const onIgniteAPT = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { value = '' } = event.target.dataset;
    const params = { author: value, handle: value, date: 'All Time' };
    const query = qs.stringify(params, { format: 'RFC1738', encodeValuesOnly: true });
    History.navigateTo(`${IGNITE_APT_URL}?${query}`, true);
  };

  const selectable = (
    ['tagging.channels'].includes(selectedSection)
  );

  function memoize(fn) {
    return function memoizeFnc(...args) {
      // eslint-disable-next-line no-param-reassign
      fn.cache = fn.cache || {};
      // eslint-disable-next-line security/detect-object-injection
      if (fn.cache[args]) {
        // eslint-disable-next-line security/detect-object-injection
        return fn.cache[args];
      }
      // eslint-disable-next-line
      fn.cache[args] = fn.apply(this, args);
      // eslint-disable-next-line security/detect-object-injection
      return fn.cache[args];
    };
  }

  const error = (status) => {
    // server conn reset
    if (/ECONNABORTED/.test(status)) {
      return {
        title: Messages.SearchTimeout,
        help: [
          'Try lowering the number of keywords.',
          'Or click <a href="#" onClick="location.reload(true)">here</a> to try again.',
        ],
      };
    // no ioc events found
    } else if (['event'].includes(filters.get('group'))) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { location: { search, pathname } } = window;
      const path = `${pathname}${search.replace(/(date|since|until|group)=.*?(&|$)/g, '')}&group=indicator`;
      return {
        title: Messages.SearchIOCEventError,
        help: <a href="#" onClick={() => History.navigateTo(path)}>Switch to Indicators View</a>,
      };
    // no records found for current date
    } else if (filters.has('date')) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { location: { search, pathname } } = window;
      const path = `${pathname}${search.replace(/(date|since|until)=.*?(&|$)/g, '')}`;
      return {
        title: Messages.SearchRangeEmpty,
        help: <div>Click <a href="#" onClick={() => History.push(path)}>here</a> to search across all time</div>,
      };
    }
    return {
      title: Messages.SearchEmpty,
      help: ['Try different keywords', 'Try modifying your filters'],
    };
  };

  const tooltip = memoize((v, k, format, href = '', fpid = '', basetype = '', alt = '') => {
    const linkType = v && k ? format : '';
    switch (linkType) {
      case 'table': {
        if (!v.isEmpty()) {
          // headers should be removed for ocr enricments datapoints
          const headers = !(/image-analysis|video-analysis/ig.test(k) && basetype.includes('media'));
          const title = Text.Sentence(k.split('.').slice(-1).join(' ').replace(/-/g, ' '));
          switch (k) {
            case 'cves': return (
            `<div>
              ${headers ? `<b>${title}</b>:` : ''}
              ${v.map(_v => (
              `<div class="${style.link} ${style.hover}" onClick="
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              var input = document.querySelectorAll('[name=menuOptions]');
              var inputList = Array.prototype.slice.call(input);
              var isOpen = this.nextElementSibling.style.display == 'block' ? true : false;
              inputList.forEach(element => element.style.display = 'none');
              if (!isOpen) { this.nextElementSibling.style.display = 'block'; isOpen = false}">${Text.StripHtml(_v)}</div>
                <ol name="menuOptions" class="${style.hoverMenu}">
                  <li class="${style.hoverRow} filter">
                    <a
                      data-for="global.tooltip"
                      href="${currentPath}${History.buildSearchString({ ...currentQuery, [k]: Text.StripHighlight(_v) })}"
                      draggable=false>
                      Filter by: ${Text.StripHtml(_v)}
                    </a>
                  </li>
                  <li class="${style.hoverRow} vulnDb" onClick="
                  event.preventDefault();
                  event.stopPropagation();
                  event.stopImmediatePropagation();
                  window.open('https://vulndb.flashpoint.io/vulnerabilities/search?query=${Text.StripHtml(_v)}', '_blank')
                  event.target.parentElement.style.display = 'none';">Open in VulnDB
                  <span class="material-icons ${style.launch}">launch</span></li>
                </ol>
               `))
              .join('')}
            </div>`);
          default: return (
            `<div>
              ${headers ? `<b>${title}</b>:<br />` : ''}
              ${v.map(_v => (
              `<a
                  data-for="global.tooltip"
                  data-tip="${Text.StripHighlight(_v)}"
                  href="${currentPath}${History.buildSearchString({ ...currentQuery, [k]: Text.StripHighlight(_v) })}"
                  class="${style.link}"
                  draggable=false>
                  ${Text.StripHtml(_v)}
                </a>`))
              .join('<br />')}
            </div>`);
          }
        }
        return '';
      }
      case 'json': {
        try {
          return (
            `<pre style="white-space: pre; margin: 0;">${v ? JSON.stringify(JSON.parse(v), null, 1).substr(0, 250) : '-'}[...]</pre>`
          );
        } catch (err) {
          return (
            `<span
              data-for="global.tooltip"
              data-tip="${Text.StripQuotes(Text.Strip(v ? Text.StripHtml(v) : '-'))}">
              ${v ? Text.StripHtml(v) : '-'}
            </span>`
          );
        }
      }
      case 'link': {
        // Make sure we get the correct value
        const _value = list.isList(v) ? v.get(0) : v;
        const value = Text.StripHighlight(_value.replace(/"/ig, '\\"'));
        const link = fpid && ['author'].includes(k) ? `${fpid}::${value}` : `${value}`;
        return (
          `<a
              href="${!k ? href : `${currentPath}${History.buildSearchString({ ...currentQuery, [k]: link, all: basetype })}`}"
              class="${style.link}"
              data-for="global.tooltip"
              data-tip="${Text.StripQuotes(value)}"
              draggable=false>
              ${_value}
            </a>
            ${k === 'author' && !alt ?
            ReactDOMServer.renderToString(
              <FontAwesomeIcon
                icon={faSparkles}
                name="ignite"
                data-value={value}
                data-for="global.tooltip"
                data-tip="Check out Threat Actor Profiles in Ignite"
                data-testid="table.author.ignite-link"
                className={cx([style.listitem, style.ignite, style.inline, style.icon])} />,
              ) : ''}
          </>`
        );
      }
      default: {
        const makeDefaultTooltipString = _v => (
          `<span
            data-for="global.tooltip"
            data-tip="${Text.StripQuotes(Text.Strip(_v ? Text.StripHtml(_v) : '-'))}">
            ${_v ? Text.StripHtml(_v) : '-'}
          </span>`
        );
        if (typeof v?.map === 'function') {
          return v.map(_v => makeDefaultTooltipString(_v)).join('<br />');
        }

        return makeDefaultTooltipString(v);
      }
    }
  });

  const rightClick = (v, k) => {
    const title = Text.Sentence(k.split('.').slice(-1).join(' ').replace(/-/g, ' '));
    const stringLiteral = _v => `<a
      data-for="global.tooltip"
      class="${style.hoverRowString}"
      href="${currentPath}${History.buildSearchString({ ...currentQuery, [k]: Text.StripHighlight(_v) })}"
      draggable=false>
      Filter by: ${Text.StripHtml(_v)}
      </a>`;

    return (
      <div>
        <b>{v.size ? <b>{title}:</b> : ''}</b>
        {
          v.map(_v => (
            <div key={_v + k}>
              <div
                className={style.link}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const input = document.querySelectorAll('[name=menuOptions]');
                  const inputList = Array.prototype.slice.call(input);
                  let isOpen = event.target.nextElementSibling.style.display === 'block';
                  inputList.forEach((element) => { const el = element; el.style.display = 'none'; });
                  if (!isOpen) {
                    const popover = event.target.nextElementSibling;
                    popover.style.display = 'block';
                    let left = event.target.innerText.length;
                    left = left > 35 ? left *= 5 : left *= 6.5;
                    popover.style.marginLeft = `${left}px`;
                    isOpen = false;
                    }
              }}>
                {Text.StripHtml(_v)}
              </div>
              <ol name="menuOptions" className={style.hoverMenu}>
                <li className={style.hoverRow}>
                  <div dangerouslySetInnerHTML={{ __html: stringLiteral(_v) }}/>
                </li>
                <li
                  className={style.hoverRow}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const menuOption = document.querySelectorAll('[name=menuOptions]');
                    const inputList = Array.prototype.slice.call(menuOption);
                    inputList.forEach((element) => { const el = element; el.style.display = 'none'; });
                    }}
                >
                  <Virustotal tableData={_v} title="VirusTotal"/>
                </li>
              </ol>
            </div>
           ))
        }
      </div>);
  };

  const media = (records = list(), display = false) => {
    const [value, ...additonal] = records;
    const total = records?.size;
    const mediaType = (value?.get?.('mime_type') || '')
      .split('/')[0];
    let fileName = value?.get?.('filename')
      || value?.get?.('file_name')
      || (value?.get?.('storage_uri') || '')
        .split('/')
        .slice(-1)
        .join();
    if (fileName && fileName.indexOf('.') === -1) fileName += MediaTypes[value?.get('mime_type')];
    if (!['image', 'photo', 'video'].includes(mediaType)) {
      return (
        <div>
          {Text.Highlight(`<b>Media</b>: ${tooltip(value?.get('mime_type'))}`)}
          {Text.Highlight(`<b>Filename</b>: ${tooltip(fileName)}`)}
        </div>
      );
    }
    const src = encodeURI(`/ui/v4/media/assets/?asset_id=${value.get('storage_uri')}`);
    const canView = user.getIn(['prefs', 'safe_search_toggle']) === 'ALL';

    if (display) {
      return (
        <div className={style.images}>
          {value?.get('media_type') === 'image' &&
          <img
            src={src}
            className={style.image}
            alt=""
            height="200"
            width="200" />}
          {value?.get('media_type') === 'video' &&
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <video
            controls
            autoPlay={false}
            id={data.get('fpid')}
            src={src}
            height="200"
            width="200" />}
          <div className={style.placeholders}>
            {additonal?.slice(0, 2)?.map(v => (
              <img
                key={v.get('fpid')}
                className={cx([style.image, style.placeholder])}
                alt=""
                height="200"
                width="200" />))}
            <div className={style.placeholder}>{total > 1 ? `+${total - 1}` : ''}</div>
          </div>
        </div>
      );
    } else if (canView) {
      return (
        <div
          data-for="global.tooltip"
          data-tip={ReactDOMServer.renderToStaticMarkup(<img src={src} alt="" height="200" width="200" />)}
          data-html>
          <b>Media</b>: {`${Text.Sentence(mediaType)} (hover for image preview)`}
          {Text.Highlight(`<b>SHA-1</b>: ${tooltip(value.get('sha1'))}`)}
          {Text.Highlight(`<b>Filename</b>: ${tooltip(fileName)}`)}
        </div>);
    }
    return (
      <div>
        <b>Media</b>: {Text.Sentence(mediaType)}
        {Text.Highlight(`<b>SHA-1</b>: ${tooltip(value.get('sha1'))}`)}
        {Text.Highlight(`<b>Filename</b>: ${tooltip(fileName)}`)}
      </div>);
  };

  const bulkActions = (value) => {
    switch (value) {
      case 'tagging.channels':
        return fromJS([
          { prm,
            label: 'Edit Tags',
            inputLabel: 'Tags',
            type: 'tagger',
            field: 'tags',
            metaData,
            username: user.get('usn'),
          },
          { label: 'Edit Source Added By',
            inputLabel: 'Source Added By',
            type: 'text',
            field: 'assigned_to',
            onApply: onBulkSave },
        ]);
      default:
        return fromJS([]);
    }
  };

  const canExport = !['tagging.channels'].includes(selectedSection);

  const columns = () => {
    const group = filters.get('group', '');
    switch (selectedSection) {
      case 'all':
      case 'communities':
        return [
          { id: 'detail',
            label: 'Details',
            text: 'Details for result',
            labels: 'detail',
            navigateOnClick: true,
            render: memoize((v, _, href) => (
              <div>
                {Text.Highlight(
                  v.getIn(['detail', 1], list())
                    .filter(_v => _v.get('value'))
                    .map(_v => `<b>${_v.get('fieldName')}</b>: ${tooltip(_v.get('value'), _v.get('filter'), 'link', href, _v.getIn(['record', 'site_actor', 'fpid']), _v.get('type'))}`)
                    .join('<br/>'), true)}
                {v.get('type') === 'media' &&
                  media(fromJS({
                    type: 'image',
                    media_type: 'image',
                    mime_type: 'image/jpeg',
                    sha1: v.get('sha1'),
                    storage_uri: v.get('storage_uri'),
                  }), true)}
              </div>)) },
          ['all', 'accounts', 'boards', 'blogs', 'ransomware', 'cards', 'chats', 'communities', 'forums', 'marketplaces', 'media', 'pastes', 'social', 'twitter'].includes(type)
            ? { id: 'enrichments',
              label: 'Enrichments',
              text: 'Enrichments for result',
              navigateOnClick: true,
              render: memoize(v => v
                .get('enrichments', map())
                .entrySeq()
                .filter(([k]) =>
                  [
                    'cves',
                    'bins',
                    'emails',
                    'ips',
                    'urls',
                    'domains',
                    'ethereum-wallets',
                    'monero-wallets',
                    'bitcoin-wallets',
                    'image-analysis-classifications',
                    'image-analysis-text',
                    'image-analysis-logos',
                    'video-analysis-classifications',
                    'video-analysis-text',
                    'video-analysis-logos',
                    'handles',
                    'profiles',
                    ...prm.some(p => /ui.enrichments.display/ig.test(p))
                      ? []
                      : [],
                  ].includes(k))
                .map(([k, e]) => (
                  <div key={`${k}.${e}`}>
                    {['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table', null, null, v.get('basetypes')), true)}
                    {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                  </div>))) }
            : {},
          { id: 'description',
            label: 'Preview',
            text: 'Preview text for result',
            class: 'description',
            tooltip: memoize((v) => {
              let desc;
              if (v.getIn(['description']) !== '-') {
                desc = v.getIn(['description']);
              } else {
                desc = v.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                  v.getIn(['body', 'text/html+sanitized']) ||
                  v.getIn(['highlight', 'body.text/plain', 0]) ||
                  v.getIn(['body', 'text/plain']) || '-';
                desc = Text.StripHtml(desc);
              }
              return (Text.Trim(desc, 2500).match(/(.{1,65})(?:\n|$| )/g) || []).join('<br />');
            }),
            render: memoize((v) => {
              let desc;
              if (v.getIn(['description']) !== '-') {
                desc = v.getIn(['description']);
              } else {
                desc = v.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                  v.getIn(['body', 'text/html+sanitized']) ||
                  v.getIn(['highlight', 'body.text/plain', 0]) ||
                  v.getIn(['body', 'text/plain']) || '-';
                desc = Text.StripHtml(desc);
              }

              return (
                Text.Highlight(
                  Text.Trim(
                    Text.StripCss(desc))));
            }) },
          ...(['boards', 'blogs', 'ransomware', 'chats', 'communities', 'forums', 'pastes', 'social', 'twitter'].includes(type) && prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'communities.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
          { id: 'all.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark entity={v} tooltipPos="bottom" />
            ),
          },
        ];
      case 'reports':
        return [
          { id: 'version_posted_at|title|tags',
            label: 'Title',
            labels: 'date|title|tags',
            text: 'Report Details',
            navigateOnClick: true,
            render: memoize((v, _, href) => Text.Highlight(
              `<b>Date</b>: ${moment.utc(v.get('version_posted_at')).format('MMM D, YYYY HH:mm')}<br/>
              <b>Title</b>: ${tooltip(v.getIn(['title']))}<br/>
              <b>Tags</b>: ${v.get('tags').sortBy(_v => !new RegExp(_v, 'ig') // eslint-disable-line security/detect-non-literal-regexp
                .test(filters.get('tags', []).concat(filters.get('types', [])))).take(3).map(_v => tooltip(_v, 'tags', 'link', href)).join(', ')}
              ${v.get('published_status') === 'draft' ? '<br/><b>Draft</b>' : ''}`, true)) },
          { id: 'body.text/html+sanitized',
            label: 'Report Preview',
            text: 'Preview of the report',
            class: 'description',
            render: memoize(v =>
              Text.Highlight(
                Text.Trim(v.getIn(['body', 'strippedInnerText']), 250), true)) },
          { id: 'report.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark entity={v} tooltipPos="bottom" />
            ) },
        ];
      case 'blogs':
        return [
          { id: 'sort_date|site.title|title|basetypes|site_actor.names.handle|raw_href',
            label: 'Blog Details',
            labels: 'date|site|title|category|author|url',
            text: 'Blog details',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                ${v.getIn(['site', 'title']) ? `<b>Blog</b>: ${tooltip(Text.Sentence(v.getIn(['highlight', 'site.title', 0], v.getIn(['site', 'title']))), 'sites', 'link', href)}` : ''}<br/>
                <b>Title</b>:${tooltip(v.getIn(['highlight', 'title', 0]) ||
                  v.getIn(['title']) ||
                  v.getIn(['highlight', 'container.title', 0]) ||
                  v.getIn(['container', 'title']), 'title', 'link', href)}<br/>
                <b>Category</b>: ${tooltip(v.getIn(['basetypes']).toJS().splice(-4, 1).map(t => (/comment/i.test(t) ? 'Comment' : 'Post')))}<br/>
                ${v.getIn(['site_actor', 'names', 'handle']) ? `<b>Author</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) || v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}` : ''}<br/>
                ${v.getIn(['raw_href']) ? `<b>URL</b>: ${tooltip(v.getIn(['highlight', 'raw_href', 0]) || v.getIn(['raw_href']))}` : ''}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/plain',
            label: 'Blog Preview',
            labels: 'body',
            text: 'Preview of the blog post',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v => Text.Highlight(
              `${Text.Trim(Text.StripCss(v.get('strippedBody')), 250)}`)) },
           ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'blogs.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
          { id: 'blogs.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={`${v.getIn(['highlight', 'title', 0]) ||
                v.getIn(['title']) ||
                v.getIn(['highlight', 'container.title']) ||
                v.getIn(['container', 'title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'ransomware':
        return [
          { id: 'sort_date|site.title|basetypes|site_actor.names.handle|raw_href|media_v2',
            label: 'Ransomware Details',
            labels: 'date|site|category|author|url|media_v2',
            text: 'Ransomware details',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                ${v.getIn(['site', 'title']) ? `<b>Blog</b>: ${tooltip(Text.Sentence(v.getIn(['highlight', 'site.title', 0], v.getIn(['site', 'title']))), 'sites', 'link', href)}` : ''}<br/>
                <b>Category</b>: ${tooltip(v.getIn(['basetypes']).toJS().splice(-4, 1).map(t => (/comment/i.test(t) ? 'Comment' : 'Post')))}<br/>
                ${v.getIn(['site_actor', 'names', 'handle']) ? `<b>Author</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) || v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}` : ''}<br/>
                ${v.getIn(['raw_href']) ? `<b>URL</b>: ${tooltip(v.getIn(['highlight', 'raw_href', 0]) || v.getIn(['raw_href']))}` : ''}<br/>
                <b>Media</b>: ${v?.getIn(['media_v2', 0, 'fpid']) ? 'Yes' : 'No'}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'title|body.text/plain',
            label: 'Blog Preview',
            labels: 'title|body',
            text: 'Preview of the blog post',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v => Text.Highlight(
              `<b>Title</b>:${tooltip(v.getIn(['highlight', 'title', 0]) ||
                v.getIn(['title']) ||
                v.getIn(['highlight', 'container.title', 0]) ||
                v.getIn(['container', 'title']))}<br/>
              <b>Post</b>: ${Text.Trim(Text.StripCss(v.get('strippedBody')), 250)}`)) },
           ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'ransomware.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
                { id: 'ransomware.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={`${v.getIn(['highlight', 'title', 0]) ||
                v.getIn(['title']) ||
                v.getIn(['highlight', 'container.title']) ||
                v.getIn(['container', 'title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'cards':
        return [
          { id: 'sort_date|basetypes|site.title',
            label: 'Shop Details',
            labels: 'date|category|site',
            transformations: [null, 'Cards.Category', null],
            text: 'Details about the shop',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.get('sort_date')).format('MMM D, YYYY HH:mm')}<br/>
                <b>Category</b>: ${tooltip(v.getIn(['basetypes']).includes('dump') ? 'Card Present (dumps)' : 'Card Not Present (cvv)', '', 'link', href)}<br>
                <b>Shop</b>: ${tooltip(v.getIn(['highlight', 'site.title', 0]) || v.getIn(['site', 'title']), 'sites', 'link', href)}`, true)) },
          { id: 'base.title|card_type|bin|prices',
            label: 'Card Details',
            labels: 'base_title|card type|bin|prices',
            text: 'Details about the card/dump',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Release Title</b>: ${tooltip(v.getIn(['highlight', 'base.title', 0]) ||
                  v.getIn(['base', 'title']), 'release_name', 'link', href)}<br/>
                <b>Card Type</b>: ${tooltip(v.getIn(['highlight', 'card_type', 0]) ||
                  v.getIn(['card_type']))}<br/>
                ${user.get('prm').some(p => /csb/.test(p)) ? `<b>Bin</b>: ${tooltip(v.getIn(['highlight', 'bin', 0]) ||
                  v.getIn(['bin']), 'bin', 'link', href)}<br/>` : ''}
                <b>Price</b>: ${tooltip(v.getIn(['highlight', 'prices.raw', 0]) ||
                  (v.getIn(['prices']) && v.getIn(['prices', 0, 'raw'])) ||
                  `${v.getIn(['prices', 0, 'currency', 'abbreviation']) || ''}${v.getIn(['prices', 0, 'value']) || '-'}`)}`, true)) },
          { id: 'payment_method|expiration|last4|track_information|cardholder_information.first|cardholder_information.last|cardholder_information.location.city|cardholder_information.location.region.raw|cardholder_information.location.country.raw|cardholder_information.location.zip_code',
            label: 'Account Details',
            labels: 'payment_method|expiration|last4|track_information|first_name|last_name|city|region|country|zip_code',
            requires: 'prefs.enable_pii',
            navigateOnClick: true,
            text: 'Details about the account',
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Payment Method</b>: ${tooltip(v.get('payment_method') || '-')}<br/>
                <b>Expiration</b>: ${tooltip(v.get('expiration') || '-')}<br/>
                <b>Last 4</b>: ${tooltip(v.get('last4') || '-')}<br/>
                <b>Track Information</b>: ${tooltip(v.get('track_information') || '-')}<br/>
                <b>First Name</b>: ${tooltip(v.getIn(['cardholder_information', 'first']) || '-')}<br/>
                <b>Last Name</b>: ${tooltip(v.getIn(['cardholder_information', 'last']) || '-')}<br/>
                <b>City</b>: ${tooltip(v.getIn(['cardholder_information', 'location', 'city']) || '-')}<br/>
                <b>State/Region</b>: ${tooltip(v.getIn(['cardholder_information', 'location', 'region', 'raw']) || '-')}<br/>
                <b>Country</b>: ${tooltip(v.getIn(['cardholder_information', 'location', 'country', 'raw']) || '-', 'country', 'link', href)}<br/>
                <b>Zip Code</b>: ${tooltip(v.getIn(['cardholder_information', 'location', 'zip_code']) || '-', 'zip_code', 'link', href)}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'card.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                timestamp={v.getIn(['sort_date'])}
                title={`${v.getIn(['highlight', 'card_type', 0]) ||
                v.get('card_type')}: ${v.getIn(['highlight', 'bin', 0]) || v.getIn(['bin'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'accounts':
        return [
          { id: 'sort_date|site.title|account_organization|email_domain',
            label: 'Account Details',
            text: 'Details about the account',
            labels: 'date|source|site|email_domain',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.get('sort_date')).format('MMM D, YYYY HH:mm')}<br>
                <b>Source</b>: ${tooltip(v.getIn(['highlight', 'site.title', 0], v.getIn(['site', 'title'], '-')), 'sites', 'link', href)}<br>
                <b>Site</b>: ${tooltip(v.getIn(['highlight', 'account_organization', 0], v.getIn(['account_organization'], '-')), 'account_organization', 'link', href)}<br/>
                ${!v.hasIn(['email_domain']) ? '' : `<b>Email Domain</b>:
                ${tooltip(v.getIn(['highlight', 'email_domain', 0]) ||
                v.getIn(['email_domain']), 'email_domain', 'link', href)}`}`, true)) },
          { id: 'site_actor.names.handle|prices',
            label: 'Seller Details',
            labels: 'seller|price',
            text: 'Details about the sale',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `${!v.getIn(['site_actor', 'names', 'handle'])
                  ? ''
                  : `<b>Seller</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                    v.getIn(['site_actor', 'names', 'handle']), 'seller', 'link', href)}<br/>`}
                <b>Price</b>: ${v.getIn(['prices', 0, 'value']) ? v.getIn(['prices', 0, 'currency', 'abbreviation']) : ''}
                ${v.getIn(['prices', 0, 'value']) ? v.getIn(['prices', 0, 'value']).toFixed(2) : v.getIn(['prices', 0, 'raw'])}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/plain',
            label: 'Account Details',
            labels: 'account details',
            requires: 'prefs.enable_pii',
            class: 'description',
            text: 'Details about the account',
            tooltip: memoize(v => ((v.get('strippedBody')).length > 65
              ? (v.get('strippedBody').match(/(.{1,65})(?:\n|$| )/g)).join('<br />')
              : '')),
            render: memoize(v =>
              Text.Highlight(
                Text.Trim(v.get('strippedBody'), 250))) },
          { id: 'account.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                timestamp={v.getIn(['sort_date'])}
                title={`${v.getIn(['site', 'title'])} ${v.getIn(['highlight', 'account_organization', 0]) ||
                v.getIn(['account_organization'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'boards':
        return [
          { id: 'sort_date|site.title|container.container.title|container.native_id|site_actor.names.handle|media_v2',
            label: 'Board Details',
            labels: 'date|site|board|thread|author|media_v2',
            text: 'Board details',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'], '')).format('MMM D, YYYY HH:mm')}<br/>
                <b>Site</b>: ${tooltip(Text.Sentence(v.getIn(['site', 'title'])), 'sites', 'link', href)}<br/>
                <b>Board</b>: ${tooltip(v.getIn(['container', 'container', 'title']) || '-', 'board', 'link', href)}<br/>
                <b>Thread</b>: ${tooltip(v.getIn(['container', 'native_id']) || '-', 'thread_id', 'link', href)}<br/>
                <b>Author</b>: ${tooltip(v.getIn(['site_actor', 'names', 'handle']) || v.getIn(['native_id']) || '-', 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}<br/>
                <b>Media</b>: ${v?.getIn(['media_v2', 0, 'fpid']) ? 'Yes' : 'No'}`,
              true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/html+sanitized',
            label: 'Message Preview',
            labels: 'message',
            text: 'Preview of the message',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v =>
              Text.Highlight(
                Text.ExtractReply(
                  Text.Trim(
                    Text.StripCss(v.get('strippedBody')), 250)))) },
          ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'boards.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
                { id: 'boards.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                author={v.getIn(['container', 'container', 'title'])}
                timestamp={v.getIn(['sort_date'])}
                title={v.getIn(['container', 'container', 'title'])}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'chats':
        return [
          ...(['channels'].includes(group) ?
            [{ id: 'site.title|container.container.name|container.name|container.type',
              label: 'Channel Details',
              labels: 'chat type|server|channel|channel type',
              text: 'Details about the channel',
              navigateOnClick: true,
              render: memoize((v, _, href) =>
                Text.Highlight(
                  `<b>Chat Type</b>: ${v.getIn(['site', 'title'])}<br>
                    ${v.getIn(['container', 'container', 'name']) ? `<b>Server</b>: ${tooltip(v.getIn(['highlight', 'container.container.name.keyword', 0]) || v.getIn(['container', 'container', 'name']))}<br/>` : ''}
                  <b>Channel</b>: ${tooltip(v.getIn(['highlight', 'container.name', 0]) ||
                    v.getIn(['container', 'name']), '', 'link', href)}
                  ${v.hasIn(['container', 'type']) ? `<br/><b>Channel Type</b>: ${tooltip(v.getIn(['highlight', 'contaner.type']) ||
                  v.getIn(['container', 'type']))}` : ''}`, true)) }] : []),
          ...(['messages', ''].includes(group) ?
            [{
              id: 'sort_date|site.title|container.container.title|container.container.native_id|container.name|container.native_id|container.type|site_actor.names.handle|site_actor.names.aliases|site_actor.native_id|body.enrichments.language|media.type|media|media.sha1',
              label: 'Message Details',
              labels: 'date|chat type|server|server id|channel|channel id|channel type|author|author aliases|user id|language|has media|filename|SHA1',
              text: 'Details about message',
              navigateOnClick: true,
              render: memoize((v, _, href) => (
                <div>{Text.Highlight(
                  `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm:ss')}<br/>
                  <b>Chat Type</b>: ${v.getIn(['site', 'title'])}<br>
                  ${v.hasIn(['container', 'container']) ? `<b>Server</b>: ${tooltip(v.getIn(['highlight', 'container.container.title', 0]) ||
                    v.getIn(['container', 'container', 'title']), 'server', 'link', href)}<br/>` : ''}
                  ${v.hasIn(['container', 'container'])
                    ? `<b>Server ID</b>: ${tooltip(v.getIn(['container', 'container', 'native_id']), 'server_id', 'link', href)}<br/>`
                    : ''}
                  <b>${v.get('basetypes').some(b => /element/.test(b)) ? 'Room' : 'Channel'}</b>: ${tooltip(v.getIn(['highlight', 'container.name', 0]) ||
                    v.getIn(['container', 'name']), 'channel', 'link', href)}
                  ${v.getIn(['container', 'name']) !== v.getIn(['container', 'native_id'])
                    ? `<br/><b>${v.get('basetypes').some(b => /element/.test(b)) ? 'Room' : 'Channel'} ID</b>: ${tooltip(v.getIn(['container', 'native_id']) || '-', 'channel_id', 'link', href)}`
                    : ''}
                  ${v.hasIn(['container', 'type']) ? `<br/><b>Channel Type</b>: ${tooltip(v.getIn(['highlight', 'contaner.type']) ||
                    v.getIn(['container', 'type']))}` : ''}<br/>
                  <b>Username</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                    v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}<br/>
                  <b>Aliases</b>: ${[...new Set(v
                    ?.getIn(['site_actor', 'names', 'aliases'])
                    ?.map(a => (tooltip(a, 'author', 'link', undefined, undefined, undefined, 'alias'))))]
                    ?.join()}<br/>
                  <b>User ID</b>: ${tooltip(v.getIn(['site_actor', 'native_id']), 'author_id', 'link', href)}<br/>
                  ${Text.StripHighlight(v.getIn(['body', 'enrichments', 'language'])) ? `<b>Language</b>: ${tooltip(Iso[Text.StripHighlight(v.getIn(['body', 'enrichments', 'language'])) || 'n/a'].name, 'language', 'link', href)}<br/>` : ''}`, true)}
                  {(v?.get('media_v2')?.size > 0 && !['webpage'].includes(v.getIn(['media_v2', 'type'])) ?
                    media(v?.get('media_v2')) : Text.Highlight(v?.get('media_v2')?.size > 0 ? '<b>Media</b>: -' : ''))}
                </div>)) }] : []),
          ...(['messages', ''].includes(group) ?
            [{ id: 'enrichments',
              label: 'Enrichments',
              text: 'Enrichments for result',
              navigateOnClick: true,
              render: memoize(v => v
                .get('enrichments', map())
                .entrySeq()
                .filter(([k]) =>
                  [
                    'cves',
                    'bins',
                    'emails',
                    'ips',
                    'urls',
                    'domains',
                    'ethereum-wallets',
                    'monero-wallets',
                    'bitcoin-wallets',
                    'handles',
                    'profiles',
                    ...prm.some(p => /ui.enrichments.display/ig.test(p))
                      ? []
                      : [],
                  ].includes(k))
                .map(([k, e]) => (
                  <div key={`${k}.${e}`}>
                    { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                    {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                  </div>))) }] : []),
          ...(['messages', ''].includes(group) ?
            [{ id: 'body.text/plain',
              label: 'Message Preview',
              labels: 'Message',
              text: 'Message preview',
              class: 'description',
              tooltip: memoize(v => (v.get('strippedBody')
                .match(/(.{1,65})(?:\n|$| )/g) || [])
                .join('<br />')),
              render: v =>
                // We pass .selectable-text-inline because chat previews rarely contain other tags
                Text.Highlight(
                  Text.Trim(
                    Text.StripCss(v.get('strippedBody')), 250), false, style['selectable-text-inline']) }] : []),
          ...(['channels'].includes(group) ?
            [{ id: 'messages',
              label: 'Search Hits',
              text: 'Number of relevant hits within channel',
              render: v => (v.get('messages') || '0').toLocaleString() }] : []),
          ...(['messages', ''].includes(group) && chatTagReadPermission ?
            [{ id: 'chat.preview',
              preview: true,
              text: 'Tagging',
              class: 'actions',
              navigateOnClick: false,
              render: v => (
                ['Telegram'].includes(v.getIn(['site', 'title'], '')) &&
                <Icon className={cx([style.icon, style.hover, 'material-icons'])}>
                  remove_red_eye
                </Icon>) }] : []),
          ...(['messages', ''].includes(group) && prm.some(v => /org.fp.r/.test(v)) ?
            [{ id: 'chats.tag',
              text: 'Tagging',
              class: 'actions',
              navigateOnClick: false,
              render: v => (
                <Icon
                  className={cx([style.icon, style.hover, 'material-icons'])}
                  onClick={event => onAction(event, 'tag', v)}>
                  tag
                </Icon>) }] : []),
          ...(['messages', ''].includes(group) ?
            [{ id: 'chat.bookmark',
              navigateOnClick: false,
              actions: true,
              class: 'actions',
              render: v => (
                <Bookmark
                  timestamp={v.getIn(['sort_date'])}
                  author={v.getIn(['site_actor', 'names', 'handle'])}
                  text={v.getIn(['body', 'text/plain'])}
                  entity={v}
                  tooltipPos="bottom" />
              ) }] : []),
        ];
      case 'media':
      case 'images':
      case 'videos':
        return [
          {
            id: 'sort_date',
            label: 'Message Details',
            labels: 'collected',
            text: 'Details about message',
            media: true,
             render: memoize(v => (
               <div>
                 {Text.Highlight(`<b>Collected</b><span>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm:ss')}</span><br/>`)}
                 {Text.Highlight(`<b>Type</b><span>: ${Text.Sentence(v.getIn(['site', 'title']))}</span><br/>`)}
                 {media(v.get('media_v2'), true)}
               </div>)) },
          {
            id: 'fpid|media_v2.storage_uri|media_v2.sha1',
            hidden: true,
            labels: 'detail uri|media uri|sha1',
            transformations: ['Media.Detail.Uri', null, null],
            render: v => Text.Highlight(
              `<b>Details URI</b>: ${tooltip(`https://fp.tools/home/assets/media/items/${v.get('fpid')}`)}<br/>
              <b>Media URI</b>: ${tooltip(`https://fp.tools/ui/v4/media/assets?asset_id=${v.get('storage_uri')}`)}<br/>
              <b>SHA-1</b>: ${tooltip(v.get('sha1'))}`),
          },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          ...['classifications', 'logos', 'text']
            .map(field => (
              { id: `enrichments.${selectedSection.slice(0, -1)}-analysis-${field}`,
                label: Text.Sentence(field),
                text: `${field} for result`,
                omitExport: true,
                render: memoize((v, _, href) => {
                  const fieldDataImage = v.getIn(['enrichments', `image-analysis-${field}`, 'data'], list());
                  const fieldTotalImage = v.getIn(['enrichments', `image-analysis-${field}`, 'total']);
                  const fieldDataVideo = v.getIn(['enrichments', `video-analysis-${field}`, 'data'], list());
                  const fieldTotalVideo = v.getIn(['enrichments', `video-analysis-${field}`, 'total']);
                  // We use the 'null' format for enrichments
                  // If we used the 'table' format, Text.Highlight would just strip out anchor tags created by the 'tooltip' function
                  return (
                    <div key={field}>
                      {Text.Highlight(tooltip(fieldDataImage, `image-analysis-${field}`, null, href, null, v.get('basetypes')))}
                      {fieldTotalImage > fieldDataImage.size
                        ? <span>{`+${fieldTotalImage - fieldDataImage.size} ${field}`}</span>
                        : ''}
                      {Text.Highlight(tooltip(fieldDataVideo, `video-analysis-${field}`, null, href, null, v.get('basetypes')))}
                      {fieldTotalVideo > fieldDataVideo.size
                        ? <span>{`+${fieldTotalVideo - fieldDataVideo.size} ${field}`}</span>
                        : ''}
                    </div>); }),
              })),
          { id: 'media.actions',
            label: '',
            navigateOnClick: false,
            actions: true,
            text: 'Actions',
            class: 'actions',
            render: (v, k) => (
              <div className={style.actions}>
                <Icon
                  data-for="global.tooltip"
                  data-tip="Report media"
                  className={style.hover}
                  onClick={() => onReport(v.get('fpid'), `${window.origin}/home/assets/media/items/${v.get('fpid')}`)}>
                  priority_high
                </Icon>
                <Icon
                  data-tip="Show Posts"
                  data-for="global.tooltip"
                  onClick={() => {
                    const basetype = Common.Basetypes.BasetypeToSearchType(v.set('basetypes', v.get('source_basetypes')));
                    History.navigateTo({
                      pathname: `/home/search/${basetype}`,
                      query: {
                        sha1: v?.getIn(['media_v2', 0, 'sha1']),
                      },
                    });
                  }}
                  className={cx([style.icon, style.hover, 'material-icons'])}>
                  view_column
                </Icon>
                <Icon
                  data-tip="Expand Media"
                  data-for="global.tooltip"
                  onClick={() => setCarousel(k)}
                  className={cx([style.icon, style.hover, 'material-icons'])}>
                  open_with
                </Icon>
                <Icon
                  data-tip="See Media Details"
                  data-for="global.tooltip"
                  onClick={() => {
                    const { query } = History.getCurrentLocation();
                    const path = Common.Generic.Route(v, query);
                    History.navigateTo(path);
                  }}
                  className={cx([style.icon, style.hover, 'material-icons'])}>
                  find_in_page
                </Icon>
                <Bookmark
                  title={v.getIn(['enrichments', 'v1', `${selectedSection.slice(0, -1)}-analysis`, 'text', 0, 'value'])}
                  entity={v}
                  tooltipPos="bottom" />
              </div>) },
        ];
      case 'exploits':
        return [
          { id: 'sort_date|cve.title|cve.nist.cvssv2.severity|cve.nist.cvssv3.severity|cve.nist.vulnerability_types|cve.nist.configurations.cpe23_uri|cve.mitre.status|cve.sort_date|stats.mentions.doc_count',
            label: 'Exploit Details',
            labels: 'date|title|severity (cvss v2)|severity (cvss v3)|vuln types|cpe|status|mitre created at|nvd updated at|mentions',
            text: 'Details about the exploit',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MM/DD/YYYY') || '-'}<br/>
                <b>CVE Title</b>: ${tooltip(v.getIn(['cve', 'title'], '-'), 'cves', 'link', href)}<br/>
                <b>Severity (CVSS v3)</b>: ${tooltip(v.getIn(['cve', 'nist', 'cvssv3', 'severity']) || '-')}<br/>
                <b>Severity (CVSS v2)</b>: ${tooltip(v.getIn(['cve', 'nist', 'cvssv2', 'severity']) || '-')}<br/>
                <b>Products Affected</b>: ${tooltip((v.getIn(['cve', 'nist', 'products']) || list()).count())}<br/>
                <b>Vuln Types</b>: ${(v.getIn(['highlight', 'cve.nist.vulnerability_types']) ||
                  v.getIn(['cve', 'nist', 'vulnerability_types']) || list()).map(_v => tooltip(_v, 'cve_vulnerability_types', 'link', href)).join(', ')}<br/>
                <b>CPEs</b>: ${v.getIn(['cve', 'nist', 'configurations'], list()).count()}<br/>
                <b>Status</b>: ${tooltip(v.getIn(['highlight', 'cve.mitre.status', 0]) ||
                  v.getIn(['cve', 'mitre', 'status']))}<br/>
                <b>DDW Mentions</b>: ${v.getIn(['stats', 'mentions', 'doc_count']) || '-'}`, true)) },
          { id: 'cve.nist.products.vendor_name',
            label: 'Vendor',
            omitExport: true,
            text: 'Details about affected product vendors',
            navigateOnClick: true,
            render: memoize((v, _, href) => v.getIn(['cve', 'nist', 'products'], list())
              .groupBy(k => k.get('vendor_name'))
              .map(k => k.first())
              .toList()
              .entrySeq()
              .map(([k, p]) => (
                <div key={k}>
                  {Text.Highlight(tooltip(`${Text.Strip(v.getIn(['highlight', 'nist.products.vendor_name', 0])).includes(p.get('vendor_name'))
                    ? v.getIn(['highlight', 'nist.products.vendor_name', 0])
                    : p.get('vendor_name')}`, 'cve_vendor_names', 'link', href), true)}
                </div>))) },
          { id: 'cve.nist.products',
            label: 'Product',
            text: 'Details about affected product',
            navigateOnClick: true,
            render: memoize((v, _, href) => v.getIn(['cve', 'nist', 'products'], list())
              .entrySeq()
              .map(([k, p]) => (
                <div key={k}>
                  {Text.Highlight(
                      tooltip(`${Text.Strip(v.getIn(['highlight', 'nist.products.product_name', 0])).includes(p.get('product_name'))
                        ? v.getIn(['highlight', 'nist.products.product_name', 0])
                        : p.get('product_name')}`, 'cve_product_names', 'link', href), true)}
                </div>))) },
          { id: 'source_uri',
            label: 'Exploit URI',
            labels: 'source_uri',
            text: 'URI for the exploit',
            navigateOnClick: true,
            class: 'description',
            render: memoize((v, _, href) =>
              Text.Highlight(
                `${tooltip(v.getIn(['source_uri']), 'source_uri', 'link', href)}`, true)) },
          { id: 'exploits.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={(Text.StripHtml(v.getIn(['title']))
                  .match(/(.{1,65})(?:\n|$| )/g) || [])
                  .join('<br />').toString()}
                entity={v}
                tooltipPos="bottom" />
            ),
          },
        ];
      case 'cves':
        return [
          { id: 'sort_date|title|nist.cvssv2.severity|nist.cvssv3.severity|nist.vulnerability_types|nist.configurations|mitre.status|stats.mentions.doc_count|stats.exploits.doc_count',
            label: 'CVE Details',
            labels: 'date|title|severity (cvss v2)|severity (cvss v3)|vulnerability types|cpes|status',
            text: 'Details about the cve',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MM/DD/YYYY') || '-'}<br/>
                <b>Title</b>: ${tooltip(v.getIn(['title']), 'cves', 'link', href)}<br>
                <b>Severity (CVSS v3)</b>: ${tooltip(v.getIn(['cve', 'nist', 'cvssv3', 'severity']) || '-')}<br/>
                <b>Severity (CVSS v2)</b>: ${tooltip(v.getIn(['cve', 'nist', 'cvssv2', 'severity']) || '-')}<br/>
                <b>Products Affected</b>: ${tooltip((v.getIn(['cve', 'nist', 'products']) || list()).count())}<br/>
                <b>Vuln Types</b>: ${tooltip((v.getIn(['highlight', 'cve.nist.vulnerability_types']) ||
                  v.getIn(['cve', 'nist', 'vulnerability_types'])), 'cve_vulnerability_types', 'link', href)}<br/>
                <b>CPEs</b>: ${v.getIn(['nist', 'configurations'], list()).count()}<br>
                <b>Status</b>: ${tooltip(v.getIn(['highlight', 'cve.mitre.status', 0]) ||
                  v.getIn(['cve', 'mitre', 'status']))}<br/>
                <b>DDW Mentions</b>: ${v.getIn(['stats', 'mentions', 'doc_count']) || '-'}<br/>
                <b>Exploits</b>: ${v.getIn(['stats', 'exploits', 'doc_count']) || '-'}`, true)) },
          { id: 'cve.nist.products.vendor_name',
            label: 'Vendor',
            omitExport: true,
            text: 'Details about affected product vendors',
            navigateOnClick: false,
            render: memoize(v =>
              v.getIn(['cve', 'nist', 'products'], list())
                .groupBy(k => k.get('vendor_name'))
                .map(k => k.first())
                .toList()
                .entrySeq()
                .map(([k, p]) => (
                  <div key={k}>
                    <div
                      className={cx([style.link, style.hover])}
                      onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      const input = document.querySelectorAll('[name=menuOptions]');
                      const inputList = Array.prototype.slice.call(input);
                      let isOpen = event.target.nextElementSibling.style.display === 'block';
                      inputList.forEach((element) => { const el = element; el.style.display = 'none'; });
                      if (!isOpen) {
                        const popover = event.target.nextElementSibling;
                        popover.style.display = 'block';
                        let left = event.target.innerText.length;
                        left = left > 35 ? left *= 5 : left *= 6.5;
                        popover.style.marginLeft = `${left}px`;
                        isOpen = false; } }}>
                      {Text.Strip(v.getIn(['highlight', 'nist.products.vendor_name', 0])).includes(p.get('vendor_name'))
                            ? v.getIn(['highlight', 'nist.products.vendor_name', 0])
                            : p.get('vendor_name')}
                    </div>
                    <ol
                      name="menuOptions"
                      className={cx([style.hoverMenu])}>
                      <li
                        className={cx([style.hoverRow, 'filter'])}
                        onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const { pathname, query } = History.getCurrentLocation();
                        History.push({
                          pathname,
                          query: { ...query, cve_vendor_names: Text.Strip(v.getIn(['highlight', 'nist.products.vendor_name', 0])).includes(p.get('vendor_name'))
                              ? v.getIn(['highlight', 'nist.products.vendor_name', 0])
                              : p.get('vendor_name') },
                        });
                      }}>Filter By: {Text.Strip(v.getIn(['highlight', 'nist.products.vendor_name', 0])).includes(p.get('vendor_name'))
                              ? v.getIn(['highlight', 'nist.products.vendor_name', 0])
                              : p.get('vendor_name')}
                      </li>
                      <li
                        className={cx([style.hoverRow, 'vulnDb'])}
                        onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      let cpe = '';
                      for (const item of v.getIn(['nist', 'configurations'])) {
                        if (item.getIn(['cpe23_uri']).includes(p.get('vendor_name'))) {
                          cpe = item.getIn(['cpe23_uri']);
                          break;
                        }
                      }
                      /* eslint-disable-next-line security/detect-non-literal-fs-filename */
                      window.open(`https://vulndb.flashpoint.io/vulnerabilities/search?query=${cpe}`, '_blank');
                      const cvePopover = event.target;
                      cvePopover.parentElement.style.display = 'none'; }}>Open in VulnDB
                        <span className={cx(['material-icons', style.launch])}>launch</span>
                      </li>
                    </ol>
                  </div>))) },
          { id: 'cve.nist.products',
            label: 'Product',
            text: 'Details about affected product',
            navigateOnClick: false,
            render: memoize(v =>
              v.getIn(['cve', 'nist', 'products'], list())
                .entrySeq()
                .map(([k, p]) => (
                  <div key={k}>
                    <div
                      className={cx([style.link, style.hover])}
                      onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const input = document.querySelectorAll('[name=menuOptions]');
                    const inputList = Array.prototype.slice.call(input);
                    let isOpen = event.target.nextElementSibling.style.display === 'block';
                    inputList.forEach((element) => { const el = element; el.style.display = 'none'; });
                    if (!isOpen) {
                      const popover = event.target.nextElementSibling;
                      popover.style.display = 'block';
                      let left = event.target.innerText.length;
                      left = left > 35 ? left *= 5 : left *= 6.5;
                      popover.style.marginLeft = `${left}px`;
                      isOpen = false; } }}>
                      {Text.Strip(v.getIn(['highlight', 'nist.products.product_name', 0])).includes(p.get('product_name'))
                          ? v.getIn(['highlight', 'nist.products.product_name', 0])
                          : p.get('product_name')}
                    </div>
                    <ol
                      name="menuOptions"
                      className={cx([style.hoverMenu])}>
                      <li
                        className={cx([style.hoverRow, 'filter'])}
                        onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      const { pathname, query } = History.getCurrentLocation();
                      History.push({
                        pathname,
                        query: { ...query, cve_product_names: Text.Strip(v.getIn(['highlight', 'nist.products.product_name', 0])).includes(p.get('product_name'))
                          ? v.getIn(['highlight', 'nist.products.product_name', 0])
                          : p.get('product_name') },
                      });
                    }}>Filter by: {Text.Strip(v.getIn(['highlight', 'nist.products.product_name', 0])).includes(p.get('product_name'))
                       ? v.getIn(['highlight', 'nist.products.product_name', 0])
                       : p.get('product_name')}
                      </li>
                      <li
                        className={cx([style.hoverRow, 'vulnDb'])}
                        onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      let cpe = '';
                      for (const item of v.getIn(['nist', 'configurations'])) {
                        if (item.getIn(['cpe23_uri']).includes(p.get('product_name'))) {
                          cpe = item.getIn(['cpe23_uri']);
                          break;
                        }
                      }
                      /* eslint-disable-next-line security/detect-non-literal-fs-filename */
                      window.open(`https://vulndb.flashpoint.io/vulnerabilities/search?query=${cpe}`, '_blank');
                      const cvePopover = event.target;
                      cvePopover.parentElement.style.display = 'none'; }}
                      >Open in VulnDB
                        <span className={cx(['material-icons', style.launch])}>launch</span>
                      </li>
                    </ol>
                  </div>))) },
          { id: 'cve.mitre.body.text/html+sanitized',
            label: 'Description Preview',
            labels: 'description',
            text: 'Preview of CVE description',
            class: 'description',
            render: v => Text.Highlight(
              Text.Trim(
                v.getIn(['highlight', 'mitre.body.text/html+sanitized', 0]) ||
                  v.getIn(['cve', 'mitre', 'body', 'text/html+sanitized']), 250)) },
          { id: 'cve.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={(Text.StripHtml(v.getIn(['title']))
                .match(/(.{1,65})(?:\n|$| )/g) || [])
                .join('<br />').toString()}
                entity={v}
                tooltipPos="bottom" />
            ),
          },
          { id: 'cve.vulnDB',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v =>
              <div onClick={() => {
                /* eslint-disable-next-line security/detect-non-literal-fs-filename */
                window.open(`https://vulndb.flashpoint.io/vulnerabilities/search?query=${Text.StripHighlight(v.getIn(['title']))}`); }}>
                <img
                  data-for="global.tooltip"
                  data-tip="Open in VulnDB"
                  data-offset="{'top': 0, 'left': 60}"
                  className={cx([style.hover, 'active', 'vulnDb', style.icon, style.vulnDB])}
                  alt="VulnDB"
                  src="/static/rbs-vdb__logo-icon_1.svg" />
              </div>,
          },
        ];
      case 'iocs':
        return [
          { id: 'Event.date|category',
              label: 'IOC',
              labels: 'date|category',
              text: 'Details about the IOC',
              navigateOnClick: true,
              render: memoize(v => Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['Event', 'date'])).format('MMM D, YYYY')}<br>
                <b>Category</b>: ${tooltip(Text.Sentence(v.getIn(['category'])) || '-')}`, true)) },
          { id: 'Event.Tag',
            label: 'Malware & Tools',
            labels: 'tags',
            text: 'Malware & Tools',
            render: v => v.getIn(['Event', 'Tag'], list())
              .entrySeq()
              .filter(([, t]) => /^(malware|tool)/ig.test(t.get('name')))
              .map(([k, t]) => (
                <div key={k} style={{ display: 'flex', margin: '.5rem 0' }}>
                  <div className={cx([style.icon, style.color])} style={{ backgroundColor: t.get('colour') }} />
                  <div>
                    {Text.Highlight(
                      tooltip(
                        t.get('name') === Text.Strip(v.getIn(['highlight', 'Event.Tag.name', 0]))
                          ? (v.getIn(['highlight', 'Event.Tag.name', 0]) || '')
                          : (t.get('name') || '')))}
                  </div>
                </div>)) },
          { id: 'Event.Actor',
            label: 'Actor',
            text: 'Actor',
            omitExport: true,
            render: v => v.getIn(['Event', 'Tag'], list())
              .entrySeq()
              .filter(([, t]) => /^actor/ig.test(t.get('name')))
              .map(([k, t]) => (
                <div key={k} style={{ display: 'flex', margin: '.5rem 0' }}>
                  <div className={cx([style.icon, style.color])} style={{ backgroundColor: t.get('colour') }} />
                  <div>
                    {Text.Highlight(
                      tooltip(
                        t.get('name') === Text.Strip(v.getIn(['highlight', 'Event.Tag.name', 0]))
                          ? (v.getIn(['highlight', 'Event.Tag.name', 0]) || '')
                          : (t.get('name') || '')))}
                  </div>
                </div>)) },
          { id: 'type|value',
            label: 'Sample Indicators',
            labels: 'indicators',
            text: 'Indicator Description',
            class: 'description',
            render: v => Text.Highlight(
              `<b>Type</b>: ${v?.get('type')}<br/>
              <b>Value</b>: ${v?.getIn(['value', Text.StripHighlight(v?.get('type'))])}`, true,
            ) },
          { id: 'Event.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Bookmark
                  timestamp={v.getIn(['Event', 'publish_timestamp'])}
                  title={v.getIn(['Event', 'info'])}
                  entity={v}
                  tooltipPos="bottom" />
                {/* VT only available for IOCs with md5, sha hash */}
                {v?.getIn(['Event', 'Attribute'])?.find(attrib => ['sha1', 'sha256', 'sha512', 'md5']
                 .includes(attrib.get('type'))) &&
                 <Virustotal rowData={v} type="ioc"/>}
              </div>
            ) },
        ];
      case 'social':
        return [
          { id: 'sort_date|site.title|container|site.title|container.title|site_actor.names.handle|parent_message|media_v2',
            label: 'Details',
            text: 'Details',
            labels: 'date|site|sub|page type|title|author|type|media',
            transformations: [null, null, 'Social.Sub', 'Social.PageType', null, null, 'Social.Type'],
            navigateOnClick: true,
            render: memoize((v, _, href) => {
              let highlightString = '';
              const title = v.getIn(['highlight', 'title', 0])
                || v.getIn(['title'])
                || v.getIn(['highlight', 'container.title', 0])
                || v.getIn(['container', 'title']);
              if (v.get('basetypes').includes('gab')) {
                highlightString += `<b>Page Type</b>: ${tooltip((v.getIn(['container', 'basetypes']) || list()).includes('group') ? 'Group' : 'Profile')}<br/>`;
              } else {
                const subreddit = v.hasIn(['container', 'container'])
                  ? v.getIn(['highlight', 'container.container.name', 0]) || v.getIn(['container', 'container', 'name'])
                  : v.getIn(['highlight', 'container.name', 0]) || v.getIn(['container', 'name']);
                highlightString += `<b>Subreddit</b>: ${tooltip(subreddit, 'subreddit', 'link', href)}<br/>`;
              }
              return Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Site</b>: ${tooltip(v.getIn(['site', 'title'], '-'), 'sites', 'link', href)}<br/>
                ${highlightString}
                <b>Title</b>: ${tooltip(title, 'page', 'link', href)}<br/>
                <b>Author</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) || v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href)}<br/>
                <b>Type</b>: ${tooltip(v.get('basetypes').includes('post') && !v.has('parent_message') ? 'Post' : 'Comment Thread')}<br/>
                 <b>Media</b>: ${v?.getIn(['media_v2', 0, 'fpid']) ? 'Yes' : 'No'}`, true);
            }) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  {['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/plain',
            label: 'Message Preview',
            text: 'Preview of the message',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize((v) => {
              const desc = v.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                v.getIn(['body', 'text/html+sanitized']) ||
                v.getIn(['highlight', 'body.text/plain', 0]) ||
                v.getIn(['body', 'text/plain']) || '-';
              return Text.Highlight(
                Text.Trim(
                  desc,
                250,
              )); }) },
          ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'social.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
                { id: 'social.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={`${v.getIn(['container', 'title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'pastes':
        return [
          { id: 'sort_date|site.title|title|site_actor.names.handle|raw_href',
            label: 'Paste Details',
            labels: 'date|site|title|author|url',
            text: 'Paste details',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Site</b>: ${tooltip(v.getIn(['highlight', 'site.title', 0]) ||
                  v.getIn(['site', 'title']), 'sites', 'link', href)}<br/>
                <b>Title</b>: ${tooltip(v.getIn(['highlight', 'title', 0]) ||
                  v.getIn(['title']), 'title', 'link', href)}<br/>
                <b>Author</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                  v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href)}<br/>
                <b>URL</b>: ${tooltip(v.getIn(['highlight', 'raw_href', 0]) || v.getIn(['raw_href']))}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/plain',
            label: 'Paste Preview',
            labels: 'paste',
            text: 'Preview of the paste content',
            class: 'description',
            tooltip: memoize(v => (Text.Trim(v.getIn(['body', 'text/plain']), 2500)
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v =>
              Text.Highlight(
                Text.Trim(v.get('strippedBody'), 250))) },
          ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'pastes.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
          { id: 'pastes.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={`${v.getIn(['highlight', 'title', 0]) ||
                  v.getIn(['highlight', 'title', 0]) || v.getIn(['title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'credentials':
        return [
          { id: 'breach.first_observed_at.date-time|email|password|username|password_complexity.probable_hash_algorithms|affected_domain|has_plaintext_recovered|original_password',
            label: 'Account Details',
            labels: 'date|email|password|username|probable hash type(s)|affected_domain|cracked by flashpoint|original password',
            transformations: [null, null, null, null, 'Credentials.Hash', null, null, null],
            text: 'Details about the account',
            navigateOnClick: true,
            render: memoize((v, _, href) => {
              // Only show the meets profile specs if a specific org is selected
              const sid = prm.some(p => /dat.edm.w/.test(p)) ? filters.get('customer_id') : user.get('sid');
              const profile = user.getIn(['org_profiles', sid, 'edm']) || map();
              const meetsOrgPasswordComplexity = CCM.MeetsOrgProfile(v, profile, 'checkPasswordComplexity');
              const meetsOrgProfile = CCM.MeetsOrgProfile(v, profile);
              const email = Text.BlurEmail(v.getIn(['highlight', 'email', 0]) || v.getIn(['email']), blur);
              const pswd = Text.BlurPassword(v.getIn(['highlight', 'password', 0]) || v.getIn(['password']), blur);
              const username = Text.BlurPassword(v.getIn(['highlight', 'username', 0]) || v.getIn(['username']), blur);
              const affectedDomain = v.getIn(['highlight', 'affected_domain']);
              const firstTimeSeen = v.get('is_fresh') ? 'true' : 'false';
              const hashes = v.getIn(['password_complexity', 'probable_hash_algorithms'])
                .filter(val => (knownHashes.includes(val) ? val : false));
              return Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['breach', 'first_observed_at', 'date-time'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Email</b>: ${tooltip(email, 'email', 'link', href)}<br/>
                <b>Password</b>: ${tooltip(pswd, 'password', 'link', href)}<br/>
                <b>Username</b>: ${tooltip(username, 'username', 'link', href)}<br/>
                <b>Probable Hash Type(s)</b>: ${hashes.size > 0 ? tooltip(hashes.join(), 'hash_types', 'link', href) : '-'}<br/>
                <b>Affected Domain</b>: ${tooltip(affectedDomain, 'affectedDomain', 'link', href)}<br/>
                <b>Total Sightings in Listed Breach</b>: ${v.get('total_sightings')}<br/>
                ${v.get('has_plaintext_recovered') ? '<b>Cracked by Flashpoint</b>: true <br/>' : ''}
                ${v.get('has_plaintext_recovered') ? `<b>Original Password</b>: ${v.get('original_password', '')}<br/>` : ''}
                <b>First Time Seen</b>: ${firstTimeSeen}<br/>
                ${!profile.isEmpty() && prm.some(p => /org.fp.r/.test(p)) ? `<b>Meets Org Password Complexity</b>: ${meetsOrgPasswordComplexity}` : ''}
                ${!profile.isEmpty() && !prm.some(p => /org.fp.r/.test(p)) ? `<b>Meets Organization Profile</b>: ${meetsOrgProfile}` : ''}`, true);
            }) },
          { id: 'breach.created_at.date-time|breach.title|breach.source_type|breach.context',
            label: 'Breach Details',
            labels: 'date of breach|title|source|context',
            text: 'Details about the breach',
            navigateOnClick: true,
            class: 'description',
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date of Breach</b>: ${tooltip(v.getIn(['breach', 'created_at', 'date-time'])
                  ? moment.utc(v.getIn(['breach', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                  : '-')}<br/>
                <b>Title</b>: ${tooltip((v.getIn(['highlight', 'breach.title', 0]) ||
                  v.getIn(['breach', 'title'])), 'title', 'link', href)}<br/>
                <b>Source</b>: ${tooltip(v.getIn(['breach', 'source_type']), 'source_type', 'link', href)}`, true)) },
          ...(prm.some(p => /dat.edm.hd/ig.test(p)) ?
            [{ id: search.get('exportAdditionalData') ? 'infected_host_attributes.fpid|infected_host_attributes.host_id|infected_host_attributes.ip|infected_host_attributes.location.city_name|infected_host_attributes.location.subdivision_1_name|infected_host_attributes.location.country_name|infected_host_attributes.malware.family|infected_host_attributes.malware.scanned_at.date-time|infected_host_attributes.machine.os|infected_host_attributes.machine.cpu|infected_host_attributes.machine.user|infected_host_attributes.isp.isp|infected_host_attributes.machine.computer_name|infected_host_attributes.machine.path|infected_host_attributes.machine.file_location|cookies' : '',
            label: 'Host Data',
            labels: search.get('exportAdditionalData') ? 'FPID|host id|IP address|location city|location state|location country|malware family|scan timestamp|machine operating system|machine cpu|machine local username|isp name|computer name|path|file location|cookies' : '',
            text: 'Details about the host data',
            navigateOnClick: true,
            render: memoize(v =>
              Text.Highlight(
                `<b>Host Attributes</b>: ${v.get('infected_host_attributes') ? 'Yes' : 'No'}<br/>
                <b>Cookies</b>: ${v.get('cookies') ? 'Yes' : 'No'}`),
            ) }] : []),
          { id: 'credentials.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                timestamp={v.getIn(['last_observed_at', 'date-time'])}
                title={`${v.getIn(['title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ),
          },
        ];
        case 'customer-credentials':
          return [
            { id: 'breach.first_observed_at.date-time|email|password|username|password_complexity.probable_hash_algorithms|affected_domain|has_plaintext_recovered|original_password',
            label: 'Account Details',
            labels: 'date|email|password|username|probable hash type(s)|affected_domain|cracked by flashpoint|original password',
            transformations: [null, null, null, null, 'Credentials.Hash', null, null, null],
            text: 'Details about the account',
            navigateOnClick: true,
            render: memoize((v, _, href) => {
              // Only show the meets profile specs if a specific org is selected
              // const sid = prm.some(p => /dat.edm.w/.test(p)) ? filters.get('customer_id') : user.get('sid');
              const email = Text.BlurEmail(v.getIn(['highlight', 'email', 0]) || v.getIn(['email']), blur);
              const pswd = Text.BlurPassword(v.getIn(['highlight', 'password', 0]) || v.getIn(['password']), blur);
              const username = Text.BlurPassword(v.getIn(['highlight', 'username', 0]) || v.getIn(['username']), blur);
              const affectedDomain = v.get('affected_domain');
              // const firstTimeSeen = v.get('is_fresh') ? 'true' : 'false';
              return Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['breach', 'first_observed_at', 'date-time'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Email</b>: ${tooltip(email, 'email', 'link', href)}<br/>
                <b>Password</b>: ${tooltip(pswd, 'password', 'link', href)}<br/>
                <b>Username</b>: ${tooltip(username, 'username', 'link', href)}<br/>
                <b>Affected Domain</b>: ${tooltip(affectedDomain, 'affectedDomain', 'link', href)}<br/>
                ${v.get('has_plaintext_recovered') ? '<b>Cracked by Flashpoint</b>: true <br/>' : ''}
                ${v.get('has_plaintext_recovered') ? `<b>Original Password</b>: ${v.get('original_password', '')}<br/>` : ''}`, true);
            }) },
          { id: 'breach.created_at.date-time|breach.title|breach.source_type|breach.context',
            label: 'Breach Details',
            labels: 'date of breach|title|source|context',
            text: 'Details about the breach',
            navigateOnClick: true,
            class: 'description',
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date of Breach</b>: ${tooltip(v.getIn(['breach', 'created_at', 'date-time'])
                  ? moment.utc(v.getIn(['breach', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                  : '-')}<br/>
                <b>Title</b>: ${tooltip((v.getIn(['highlight', 'breach.title', 0]) ||
                  v.getIn(['breach', 'title'])), 'title', 'link', href)}<br/>
                <b>Source</b>: ${tooltip(v.getIn(['breach', 'source_type']), 'source_type', 'link', href)}`, true)) },
            ...(prm.some(p => /dat.edm.hd/ig.test(p)) ?
            [{ id: search.get('exportAdditionalData') ? 'infected_host_attributes.fpid|infected_host_attributes.host_id|infected_host_attributes.ip|infected_host_attributes.location.city_name|infected_host_attributes.location.subdivision_1_name|infected_host_attributes.location.country_name|infected_host_attributes.malware.family|infected_host_attributes.malware.scanned_at.date-time|infected_host_attributes.machine.os|infected_host_attributes.machine.cpu|infected_host_attributes.machine.user|infected_host_attributes.isp.isp|cookies' : '',
            label: 'Host Data',
            labels: search.get('exportAdditionalData') ? 'FPID|host id|IP address|location city|location state|location country|malware family|scan timestamp|machine operating system|machine cpu|machine local username|isp name|cookies' : '',
            text: 'Details about the host data',
            navigateOnClick: true,
            render: memoize(v =>
              Text.Highlight(
                `<b>Host Attributes</b>: ${v.get('infected_host_attributes') ? 'Yes' : 'No'}<br/>
                <b>Cookies</b>: ${v.get('cookies') ? 'Yes' : 'No'}`),
            ) }] : []),
          { id: 'credentials.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                timestamp={v.getIn(['last_observed_at', 'date-time'])}
                title={`${v.getIn(['title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ),
          },
        ];
      case 'marketplaces':
        return [
          { id: 'sort_date|site.title|site.source_uri|site_actor.names.handle|title|prices.0.raw',
            label: 'Detail',
            labels: 'date|site|uri|seller|title|price',
            text: 'Detail about the posting',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Market</b>: ${tooltip(v.getIn(['highlight', 'site.title', 0]) ||
                  v.getIn(['site', 'title']), 'sites', 'link', href)}<br/>
                <b>URI</b>: ${tooltip(v.getIn(['highlight', 'site.source_uri', 0]) ||
                  v.getIn(['site', 'source_uri']), 'source_uri', 'link', href)}<br/>
                <b>Vendor</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) ||
                  v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}<br/>
                <b>Item</b>: ${tooltip(v.getIn(['highlight', 'title', 0]) ||
                  v.getIn(['title']), 'title', 'link', href)}<br/>
                <b>Price</b>: ${tooltip(v.getIn(['highlight', 'prices.raw', 0]) ||
                  (v.getIn(['prices']) && v.getIn(['prices', 0, 'raw'])) ||
                    `${v.getIn(['prices', 0, 'currency', 'abbreviation'])}${v.getIn(['prices', 0, 'value'])}`)}`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/plain',
            label: 'Item Description',
            labels: 'description',
            text: 'Item description on the marketplace',
          class: 'description',
            tooltip: memoize(v => ((v.get('strippedBody')).length > 65
              ? (v.get('strippedBody').match(/(.{1,65})(?:\n|$| )/g)).join('<br />')
              : '')),
            render: memoize((v) => {
              const desc = v.getIn(['highlight', 'body.text/html+sanitized', 0]) ||
                v.getIn(['body', 'text/html+sanitized']) ||
                v.getIn(['highlight', 'body.text/plain', 0]) ||
                v.getIn(['body', 'text/plain']) || '-';
              return Text.Highlight(
                Text.Trim(
                  desc,
                250,
              )); }) },
          { id: 'market.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark title={v.getIn(['site', 'title'])} entity={v} tooltipPos="bottom" />
            ),
          },
        ];
      case 'twitter':
        return [
          { id: 'sort_date|basetypes|site_actor.names.handle|owners.category_id',
            label: 'Details',
            text: 'Details',
            labels: 'date|platform|author|use case',
            navigateOnClick: true,
            render: memoize((v, _, href) => (
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Platform</b>: Twitter<br/>
                <b>Username</b>: ${tooltip(v.getIn(['site_actor', 'names', 'handle'], ''), 'author', 'link', href)}${v.getIn(['site_actor', 'is_verified']) === 'true' ? '<Icon data-for="global.tooltip" data-tip="Verified" class="material-icons icon twitter">check_circle</Icon>' : ''}<br/>
                <b>Use Case</b>: ${Text.Sentence(keywordClasses.find(k => k.get('id') === Text.StripHighlight(v.getIn(['owners', 'category_id'], '')))?.get('name', '')?.replace(/twitter_/ig, ''))}`, true)
            )) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data').size ? <span>{`+${e.get('total') - e.get('data').size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'body.text/html+sanitized',
            label: 'Message Preview',
            text: 'Preview of the message',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v =>
              Text.Highlight(
                Text.Trim(v.get('strippedBody'), 250))) },
          ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'twitter.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
          { id: 'twitter.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <Bookmark
                title={`${v.getIn(['container', 'title'])}`}
                entity={v}
                tooltipPos="bottom" />
            ) },
        ];
      case 'tagging.channels':
        return [
          { id: 'tagging.channel.preview',
            preview: true,
            text: 'Preview item',
            class: 'actions',
            render: () => (
              <Icon className={cx([style.icon, style.hover, 'material-icons'])}>
                remove_red_eye
              </Icon>) },
          { id: 'channel_added_at|channel_name|channel_id|channel_type|service_name',
            label: 'Detail',
            labels: 'date|channel_name|channel_id|channel_type|service_name',
            text: 'Detail about the source',
            render: v => Text.Highlight(
              `<b>Date Joined</b>: ${moment.utc(v.getIn(['channel_added_at'])).format('MMM D, YYYY HH:mm')}<br/>
              <b>Source Name</b>: ${tooltip(v.getIn(['channel_name']))}<br/>
              <b>Source ID</b>: ${tooltip(v.getIn(['channel_id']))}<br/>
              <b>Source Type</b>: ${tooltip(v.getIn(['channel_type']))}<br/>
              <b>Service</b>: ${tooltip(v.getIn(['service_name']))}<br/>`) },
          { id: 'assigned_to',
            label: 'Source Added By',
            labels: 'assigned_to',
            text: 'Analyst who should tag the source',
            render: v => Text.Highlight(`${tooltip(v.getIn(['assigned_to']))}`) },
          { id: 'tags',
            label: 'Tags',
            actions: true,
            text: 'Tags assigned to source',
            render: v => (
              <div className={style.tagging}>
                {v.get('tags', list(['No tags set'])) &&
                <ul>
                  {v.get('tags', list(['No tags set']))
                    .map(t => <li key={t.get('id')}>{t.get('tag_name')}</li>)}
                </ul>}
                <Icon
                  className={style.icon}
                  onClick={event => onAction(event, 'tagger', v)}>
                  edit
                </Icon>
              </div>) },
          { id: 'channel_tag_ids',
            label: 'Tagging History',
            labels: 'tagging history',
            text: 'Details of the channel tagging',
            render: v => v.get('channel_tag_ids').groupBy(x => x.get('tagged_at')).toList().map(x => (
              <div key={x.getIn([0, 'id'])} className="foo">
                {
                  Text.Highlight(`
                    <b>Tagged By</b>: ${tooltip(x.getIn([0, 'tagged_by']))}<br/>
                    <b>Tagged At</b>: <span>
                      ${
                        x.getIn([0, 'tagged_at']) ? `
                        <span>
                          ${moment.utc(x.getIn([0, 'tagged_at'])).format('MMM D, YYYY')}
                          <small>${moment.utc(x.getIn([0, 'tagged_at'])).format('HH:mm')}</small>
                        </span>` : '-'
                      }
                      </span><br/>
                    `)
                }
                <hr />
              </div>
            )) },
        ];
      case 'threads.forums':
        return [
          { id: 'message_count.last_resource.container.title',
            label: 'Thread Title',
            labels: 'thread title',
            text: 'Thread Title',
            tooltip: v => v.getIn(['message_count', 'last_resource', 'container', 'title']),
            render: v => Text.Highlight(
              v.getIn(['highlight', 'message_count.last_resource.container.title', 0])
              || v.getIn(['message_count', 'last_resource', 'container', 'title']), true) },
          { id: 'message_count.count',
            label: '# of Posts',
            labels: '# of posts',
            text: '# of Posts',
            render: v => v.getIn(['message_count', 'count'], '-') },
          { id: 'message_count.last_resource.site.title|message_count.last_resource.container.container.title',
            label: 'Posted In',
            labels: 'site|room',
            text: 'Details for the thread',
            render: v => Text.Highlight(
              `<b>Site</b>: ${tooltip(v.getIn(['highlight', 'message_count.last_resource.site.title', 0]) || v.getIn(['message_count', 'last_resource', 'site', 'title']))}<br/>
              <b>Room</b>: ${tooltip(v.getIn(['highlight', 'message_count.last_resource.container.container.title', 0]) || v.getIn(['message_count', 'last_resource', 'container', 'container', 'title']))}<br/>`, true) },
          { id: 'message_count.first_resource.created_at|message_count.last_resource.created_at|site_actor_count.count',
            label: 'Stats',
            labels: 'oldest post|newest post|authors participating',
            text: 'Stats for the thread',
            render: v => Text.Highlight(
              `<b>Oldest Post</b>: ${tooltip(v.getIn(['message_count', 'first_resource', 'created_at'])
                ? moment.utc(v.getIn(['message_count', 'first_resource', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                : '-')}<br/>
              <b>Newest Post</b>: ${tooltip(v.getIn(['message_count', 'last_resource', 'created_at']) ? moment.utc(v.getIn(['message_count', 'last_resource', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm') : '-')}<br/>
              <b>Authors Participating</b>: ${v.getIn(['site_actor_count', 'count'])}`, true),
          },
        ];
      case 'forums':
      case 'posts.forums':
      default:
        return [
          { id: 'sort_date|site.title|container.container.title|site_actor.names.handle',
            label: 'Details',
            labels: 'date|site|room|author',
            text: 'Details for the post',
            navigateOnClick: true,
            render: memoize((v, _, href) =>
              Text.Highlight(
                `<b>Date</b>: ${moment.utc(v.getIn(['sort_date'])).format('MMM D, YYYY HH:mm')}<br/>
                <b>Forum</b>: ${tooltip(v.getIn(['highlight', 'site.title', 0]) || v.getIn(['site', 'title']), 'sites', 'link', href)}<br/>
                <b>Room</b>: ${tooltip(v.getIn(['highlight', 'container.container.title', 0]) || v.getIn(['container', 'container', 'title']), 'room_title', 'link', href)}<br/>
                <b>Author</b>: ${tooltip(v.getIn(['highlight', 'site_actor.names.handle', 0]) || v.getIn(['site_actor', 'names', 'handle']), 'author', 'link', href, v.getIn(['site_actor', 'fpid']))}<br/>`, true)) },
          { id: 'enrichments',
            label: 'Enrichments',
            text: 'Enrichments for result',
            navigateOnClick: true,
            render: memoize(v => v
              .get('enrichments', map())
              .entrySeq()
              .filter(([k]) =>
                [
                  'cves',
                  'bins',
                  'emails',
                  'ips',
                  'urls',
                  'domains',
                  'ethereum-wallets',
                  'monero-wallets',
                  'bitcoin-wallets',
                  'handles',
                  'profiles',
                  ...prm.some(p => /ui.enrichments.display/ig.test(p))
                    ? []
                    : [],
                ].includes(k))
              .map(([k, e]) => (
                <div key={`${k}.${e}`}>
                  { ['domains', 'ips'].includes(k) ? rightClick(e.get('data'), k) : Text.Highlight(tooltip(e.get('data'), k, 'table'), true)}
                  {e.get('total') > e.get('data', []).size ? <span>{`+${e.get('total') - e.get('data', []).size} ${k}`}</span> : ''}
                </div>))) },
          { id: 'container.title|body.text/plain',
            label: 'Post Preview',
            labels: 'thread|post',
            text: 'A glimpse of the post which contains a match for your query',
            class: 'description',
            tooltip: memoize(v => (v.get('strippedBody')
              .match(/(.{1,65})(?:\n|$| )/g) || [])
              .join('<br />')),
            render: memoize(v => Text.Highlight(
              `<b>${v.hasIn(['container', 'title_native_language']) ? 'Translated ' : ''}Thread</b>: ${tooltip(
                v.getIn(['container', 'title_en']) ||
                v.getIn(['highlight', 'container.title', 0]) ||
                v.getIn(['container', 'title']))}<br/>
              <b>${v.hasIn(['body', 'text/plain_native_language']) ? 'Translated ' : ''}Post</b>: ${Text.Trim(Text.StripCss(v.getIn(['body', 'text/plain_en']) ||
                v.get('strippedBody')), 250)}<br/>
              <span class="translation">${v.hasIn(['body', 'text/plain_native_language'])
                || v.hasIn(['container', 'title_native_language']) ? `Translated from
                  <b>${Iso?.[v.getIn(['body', 'text/plain_native_language']) || v.getIn(['container', 'title_native_language'])]?.name || 'Unknown'}</b> to <b>English</b>` : ''}</span>`)) },
          ...(prm.some(v => /org.fp.r/.test(v)) ?
              [{ id: 'forums.tag',
                text: 'Tagging',
                class: 'actions',
                navigateOnClick: false,
                render: v => (
                  <Icon
                    className={cx([style.icon, style.hover, 'material-icons'])}
                    onClick={event => onAction(event, 'tag', v)}>
                    tag
                  </Icon>) }] : []),
          { id: 'forum.bookmark',
            navigateOnClick: false,
            actions: true,
            class: 'actions',
            render: v => (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '7rem' }}>
                <Bookmark
                  timestamp={v.getIn(['sort_date'])}
                  author={v.getIn(['site_actor', 'names', 'handle'])}
                  text={v.getIn(['body', 'text/plain'])}
                  entity={v}
                  tooltipPos="bottom" />
              </div>
            ) },
        ];
    }
  };

  const getColumns = () => {
    const usingColumns = columns(user)
      .filter(v => !v.hidden)
      .filter(v => !v.requires || user.getIn([...v.requires.split('.')]))
      .filter(col => col.render);
    return usingColumns;
  };

  const tryToPivot = (event, column) => {
    event.preventDefault();
    const href = event?.target?.getAttribute('href');
    if (!didDrag && column?.navigateOnClick && href) {
      History.navigateTo(href);
      event.stopPropagation();
    }
  };

  const preview = (row) => {
    const { pathname, query } = History.getCurrentLocation();
    const hash = (selectedSection !== 'credentials')
      ? `#${selectedSection}:${row.get('fpid')}`
      : `#${selectedSection}:${row.get('credential_record_fpid')}::${row.get('fpid')}`;
    return ({ pathname, query, hash });
  };

  const routing = (row) => {
    const { query } = History.getCurrentLocation();
    if (row.get('basetypes').includes('tagging')) {
      return Common.Tagging.Route(row, query);
    }
    return Common.Generic.Route(row, query);
  };

  const onCellClick = (rowIndex, colIndex) => {
    const rows = data
      .get('data')
      .slice(startIndex, startIndex + parseInt(filters.get('limit') || 25, 10));
    const usingColumns = getColumns(user);
    const column = usingColumns[Number(colIndex)];
    if (!column || column.navigateOnClick || column.contextLinks || column.actions) return;
    if (column.media) {
      // determine image offset from selected row fpid
      const row = rows.get(rowIndex);
      const index = images?.findIndex(v => v?.media_fpid === row.getIn(['media_v2', 0, 'fpid']));
      setCarousel(index);
    } else if (column.preview) {
      History.navigateTo(preview(rows.get(rowIndex), rowIndex));
    } else {
      History.navigateTo(routing(rows.get(rowIndex)));
    }
  };

  useLayoutEffect(() => {
    if (!currentDataSlice) return;
    document.getElementsByName('ignite').forEach((el) => {
      el.addEventListener('click', onIgniteAPT);
    });
  }, [currentDataSlice]);

  useEffect(() => {
    if (!['images', 'videos', 'media'].includes(type)) return;
    const sources = data
      .get('data', list())
      .toJS()
      .map(v => v?.media_v2.map(_v => ({
        ..._v,
        fpid: v?.fpid,
        site: v?.site,
        container: v?.container,
        media_fpid: _v?.fpid,
        media_sha1: v?.media?.sha1,
        media_type: _v?.media_type,
        basetype: Common.Basetypes.ExtractMainBasetype(fromJS({ basetypes: v?.source_basetypes })),
        basetypes: v?.source_basetypes,
        author: v?.site_actor?.names?.handle,
        room: v?.container?.title || v?.container?.container?.title,
        sort_date: v?.sort_date,
        body: v?.body?.['text/plain'],
        source: encodeURI(`/ui/v4/media/assets/?asset_id=${_v?.storage_uri}`),
        analysis: _v?.[`${_v?.media_type}_enrichment`]?.enrichments?.v1[`${_v?.media_type}-analysis`],
      })))
      .flat();
    setImages(sources);
  }, [dataSliceId, filtersId]);

  useEffect(() => {
    switch (type) {
      case 'chats':
      case 'media':
      case 'forums':
      case 'images':
      case 'videos':
      case 'all':
        import('../../../constants/Iso')
          .then((module) => {
            Iso = module.default; // using the default export
          });
        import('../../../constants/Media')
          .then((module) => {
            MediaTypes = module.MediaTypes; /* eslint-disable-line prefer-destructuring */
          });
        import('../../tagging/Tagger')
          .then((module) => {
            Tagger = module.default;
          });
        break;
      case 'credentials':
        import('../../../utils/ccm')
          .then((module) => {
            CCM = module.default;
          });
        break;
      case 'exploits':
      case 'tagging.channels':
        import('../../../actions/metaDataActions')
          .then((module) => {
            MetaDataActions = module.default;
          });
        import('../../tagging/Tagger')
          .then((module) => {
            Tagger = module.default;
          });
        break;
      case 'twitter':
        if (!keywordClasses.isEmpty()) break;
        Query.KeywordClasses()
          .then(res => setKeywordClasses(fromJS(res)));
        break;
      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    const { anchor } = appState;
    if (!document.getElementsByName(anchor).length) return;
    const el = document.getElementsByName(anchor)[0];
    setTimeout(() => (el ? el.scrollIntoView() : null), 300);
  }, []);

  const CarouselFooter = (args) => {
    const slide = images?.[args?.currentIndex];
    const context = Common.Generic.Route(fromJS(slide));

    return args.isModal &&
    <>
      <Slide data={slide} type={slide?.media_type} />
      <div {...args.innerProps} className="caption">
        {args.currentIndex > 0 &&
        <Button
          color="secondary"
          onClick={() => setCarousel(index => index - 1)}>Previous
        </Button>}
        {args.currentIndex < images?.length &&
        <Button
          color="secondary"
          onClick={() => setCarousel(index => index + 1)}>Next
        </Button>}
        <InternalLink
          to={context}
          className={cx([style.a, 'a'])}>
          <Button
            color="secondary"
            onClick={() => setCarousel(-1)}>See Context
          </Button>
        </InternalLink>
        <InternalLink
          to={{
            pathname: `/home/assets/media/items/${slide?.fpid}`,
            query: {
              media_type: slide?.media_type,
              type: slide?.basetype,
              fpid: Text.StripHighlight(slide?.media_fpid),
              sha1: Text.StripHighlight(slide?.media_sha1),
            },
          }}
          className={cx([style.a, 'a'])}>
          <Button
            color="secondary"
            onClick={() => setCarousel(-1)}>See Media Details
          </Button>
        </InternalLink>
      </div>
    </>;
  };


  return (
    <Grid fluid className={cx([style.table, style[String(type)]])}>
      <Row>
        <Col xs={12}>
          <div
            name="component.table"
            className={cx([
              style.card,
              !data.get('loading') && data.has('total') && data.get('total') && style.loaded])}>
            <div className={style.header}>
              {pager && !data.get('loading') && data.has('total') &&
              <Pager
                share={!['tagging.channels'].includes(selectedSection)}
                save={!['tagging.channels'].includes(selectedSection)}
                limit
                paginated
                translations={['forums'].includes(selectedSection)}
                apps={apps}
                data={data}
                saved={saved}
                filters={filters}
                limits={limits}
                prm={prm}
                type={selectedSection}
                bulkActions={bulkActions(selectedSection, user)}
                selected={list(selected)}
                fields={columns()}
                sort={!['all'].includes(selectedSection)}
                group={['chats'].includes(selectedSection) && !filters.get('search')}
                exporting={canExport}
                groups={groups.getIn([selectedSection])}
                sorts={sorts.getIn([selectedSection])} />}
              {/^(\/home\/intelligence\/reports\/search)/i.test(window.location.pathname) && !data.get('loading') && data.has('total') &&
              <MenuItem
                dense
                data-testid="search.reports.ignite-link"
                tabIndex={0}
                className={cx([style.listitem, style.ignite])}
                onClick={() => History.navigateTo(IGNITE_REPORT_URL, true)}>
                <ListItemIcon className={cx([style.icon, style.search])} >
                  <FontAwesomeIcon icon={faSparkles} />
                </ListItemIcon>
                <ListItemText
                  primary="Ignite provides a summary on topics you are interested in." />
                <ListItemText className={style.textlink}>Try it now</ListItemText>
              </MenuItem>}
            </div>
            {(data.get('loading') || !data.has('total')) &&
            <span className={style.loading}>
              Searching against:
              {['all', 'communities'].includes(selectedSection)
                  ? (
                    <span className={style.source}>
                      {!filters.get('all')
                        ? (apps.find(a => a.get('value').indexOf(`search.${type}`) !== -1) || map()).get('label') || ''
                        : filters.get('all').split(',').map(f => ((apps.find(a => a.get('value').indexOf(`search.${f}`) !== -1) || map()).get('label') || '')).join(', ')}
                    </span>)
                  : (
                    <span className={style.source}>
                      {(apps.find(a => a.get('value').indexOf(`search.${type}`) !== -1) || map()).get('label') || ''}
                    </span>)}
              <CircularProgress />
            </span>}
            {!data.get('loading') && data.has('total') && data.get('total') === 0 &&
            <Invalid
              icon={route.get('icon')}
              styles={{ margin: '50px 0' }}
              title={error(data.get('status'))?.title}
              help={error(data.get('status'))?.help} />}
            {!data.get('loading') && data.has('total') && data.get('total') > 0 && !filters.get('search') &&
            <TableContainer component={Paper}>
              <TableUI onMouseEnter={() => ReactTooltip.rebuild()} data-testid="table">
                <TableHead data-testid="table.head" className={cx([selectable && style.selectable])}>
                  <TableRow>
                    {selectable &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 &&
                          selected.length < currentDataSlice.count()}
                        checked={selected.length >= currentDataSlice.count()}
                        onChange={() => onSelect(!selected.length ? 'all' : [])} />
                    </TableCell>}
                    {getColumns()
                    .map(column => (
                      <TableCell
                        key={column.id}
                        className={column.className}
                        style={column.style}>
                        {column.text &&
                          <div
                            data-for="global.tooltip"
                            data-tip={column.text}
                            className={cx([column.sort ? style.sort : null])}
                            name={`table.header.${column.label}`}>{column.label}
                          </div>}
                        {!column.text && <div>{column.label}</div>}
                        {column.sort && filters.get('sort') &&
                        <Icon
                          onClick={() => onSort(filters
                          .get('sort')
                          .split(':')[1])}
                          className={style.icon}>
                          {`keyboard_arrow_${filters
                            .get('sort')
                            .split(':')[1] === 'asc' ? 'up' : 'down'}`}
                        </Icon>}
                      </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody data-testid="table.body">
                  {
                        currentDataSlice && typeof currentDataSlice.map === 'function' ?
                        currentDataSlice.map((row, rowIndex) => {
                          const queryObj = routing(row);
                          const query = History.buildSearchString(queryObj.query);
                          const hash = queryObj.hash || '';
                          const href = queryObj.pathname + query + hash;
                          return (
                            <TableRow
                              hover
                              name={`table.item.${row.get('fpid')}`}
                              key={row.get('id', row.get('fpid'))}
                              selected={selected.includes(row.get('id') || row.get('fpid'))}
                              className={cx([
                                window.location.hash && window.location.hash.includes(row.get('fpid'))
                                  ? style.active
                                  : null,
                                selectable && style.selectable,
                                hidden?.includes(row.get('fpid')) && style.hidden,
                              ])}
                              data-testid="table.row">
                              {selectable &&
                              <TableCell padding="checkbox" data-testid="table.checkbox">
                                <Checkbox
                                  checked={selected.includes(row.get('id') || row.get('fpid'))}
                                  onChange={event => onSelect(event.target.checked
                                    ? [...selected, row.get('id') || row.get('fpid')]
                                    : selected.filter(v => ![row.get('id'), row.get('fpid')].includes(v)))
                                  } />
                              </TableCell>}
                              {getColumns()
                                .map((column, columnIndex) => (
                                  <TableCell
                                    key={column.id}
                                    name={`table.item.${row.get('fpid')}.${column.id}`}
                                    style={column.style}
                                    className={cx([
                                      column.class,
                                      !user.getIn(['prefs', 'href']) && userHistory
                                        .find(v => Common.Generic.FpidFromHistoryItem(v.get('path')) === row.get('fpid'))
                                        ? style.viewed
                                        : null,
                                    ])}
                                    onMouseDown={(e) => {
                                      mouseDown = true; didDrag = false;
                                      downX = e.clientX; downY = e.clientY;
                                    }}
                                    onMouseUp={() => { mouseDown = false; }}
                                    onMouseMove={(e) => {
                                      const mag = Math.sqrt(((e.clientX - downX) ** 2) +
                                        ((e.clientY - downY) ** 2));
                                      didDrag = mouseDown && mag > 1;
                                    }}
                                    onClick={() => {
                                      const info = {
                                        type,
                                        fpid: row?.get('fpid'),
                                        origin: `.search .results .table[${rowIndex}][${columnIndex}]`,
                                        filters: filters.toJS(),
                                      };
                                      Track(info, 'Result');
                                      setTimeout(() => setAppState({ ...appState, anchor: `table.item.${row.get('fpid')}` }), 300);
                                      if (!didDrag) {
                                        if (column.navigateOnClick) {
                                          History.navigateTo(href);
                                        } else if (!didDrag) {
                                          onCellClick(rowIndex, columnIndex);
                                        }
                                      }
                                    }}
                                    data-testid={`table.cell-${column.id}`}>
                                    {!column.navigateOnClick &&
                                    <a
                                      className={style['selectable-text-link']}
                                      href={href}
                                      draggable={false}
                                      onClick={e => tryToPivot(e, column)}
                                    >
                                      <ToolTip
                                        col={column}
                                        row={row}
                                        index={rowIndex} />
                                    </a>}
                                    {column.navigateOnClick &&
                                    <a
                                      className={style['selectable-text-link']}
                                      href={href}
                                      draggable={false}
                                      onClick={e => tryToPivot(e, column)}
                                    >
                                      {column.render(row, rowIndex, href)}
                                    </a>}
                                  </TableCell>))}
                            </TableRow>
                          );
                      }) : null
                    }
                </TableBody>
              </TableUI>
            </TableContainer>}
            {!data.get('loading') && data.has('total') && filters.get('search') &&
            <MediaGrid
              type={filters.get('search')}
              media={(data.get('data') || list()).map(v => ({
                media: v.getIn(['media_v2', 0]),
                post: v,
                route: routing(v),
              }))} />}
            <div className={cx([style.header, style.footer])}>
              {pager && !data.get('loading') && data.has('total') && data.get('total') > 0 &&
              <Pager
                limit
                paginated
                apps={apps}
                data={data}
                saved={saved}
                fields={columns()}
                filters={filters}
                limits={limits}
                type={selectedSection} />}
            </div>
          </div>
        </Col>
      </Row>
      <Dialog open={Boolean(dialog && dialog?.key)}>
        <DialogTitle>{dialog?.text}</DialogTitle>
        <DialogContent>
          {dialog?.component}
        </DialogContent>
      </Dialog>
      <ModalGateway>
        {Boolean(images?.length && carousel > -1) &&
        <Modal onClose={() => setCarousel(-1)}>
          <Carousel
            styles={{
              view: base => ({
                ...base,
                '& img': { opacity: images?.[Number(carousel)]?.media_type === 'video' ? 0 : 1 },
              }),
            }}
            views={images}
            currentIndex={carousel}
            hideControlsWhenIdle={false}
            components={{
              FooterCaption: CarouselFooter,
            }} />
        </Modal>}
      </ModalGateway>
    </Grid>
  );
};

Table.propTypes = {
  apps: PropTypes.object,
  blur: PropTypes.bool,
  data: PropTypes.object,
  filters: PropTypes.object,
  groups: PropTypes.object,
  userHistory: PropTypes.object,
  limits: PropTypes.object,
  metaData: PropTypes.object,
  pager: PropTypes.bool,
  prm: PropTypes.object,
  route: PropTypes.object,
  saved: PropTypes.object,
  sorts: PropTypes.object,
  type: PropTypes.string,
};

Table.defaultProps = {
  apps: list(),
  blur: false,
  data: map(),
  filters: map(),
  groups: map(),
  userHistory: list(),
  limits: map(),
  metaData: map(),
  pager: true,
  prm: list(),
  route: map(),
  saved: map(),
  sorts: map(),
  type: '',
};

export default Table;
