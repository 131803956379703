import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  CircularProgress,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Query from './query';
import History from '../../utils/history';
import AlertingStore from '../../stores/recoil/alerting';
import SearchUtils from '../../utils/searchUtils';
import AlertingDashboard from '../../components/dashboard/AlertingDashboard/AlertingDashboard';
import Management from '../../components/alerting/Management';
import Filters from '../../components/filters/Filters/Filters';
import Alerts from '../../components/alerting/Alerts';
import style from './alerting.module.scss';
import Invalid from '../../components/utils/Invalid/Invalid';
import useDidUpdate from '../../hooks/useDidUpdate';
import usePrevState from '../../hooks/usePrevState';
import { SearchContext, UserContext } from '../../components/utils/Context';
import Messages from '../../constants/Messages';
import SearchActions from '../../actions/searchActions';

const useStyles = makeStyles({
  alerting: {},
});

// Define a set of filters that the page will default too on first load
const defaultFilters = {
  tags: '-archived',
  date: 'Last 7 Days',
};

const Alerting = ({
  location,
  match: {
    params,
  },
}) => {
  const classes = useStyles();
  const [basetypes, setBasetypes] = useRecoilState(AlertingStore.basetypes);
  const defaults = useRecoilValue(AlertingStore.defaultFilters);
  const setEnterpriseDomainKeyclassId = useSetRecoilState(AlertingStore.enterpriseDomainKeyclassId);
  const ownerId = useRecoilValue(AlertingStore.ownerId);
  const [filters, setFilters] = useRecoilState(AlertingStore.filters);
  const [owners, setOwners] = useRecoilState(AlertingStore.owners);
  const [keywords, setKeywords] = useRecoilState(AlertingStore.keywords);
  const setKeywordError = useSetRecoilState(AlertingStore.keywordError);
  const [keywordClasses, setKeywordClasses] = useRecoilState(AlertingStore.keywordClasses);
  const [notificationProfiles, setNotificationProfiles]
    = useRecoilState(AlertingStore.notificationProfiles);
  const [products, setProducts] = useRecoilState(AlertingStore.products);
  const [type, setType] = useRecoilState(AlertingStore.type);
  const [loadErr, setLoadErr] = useRecoilState(AlertingStore.loadErr);

  const search = useContext(SearchContext);
  const user = useContext(UserContext);

  const computeStartingPrereqStep = () => (owners?.size > 0 ? 2 : 1);
  const [loadStep, incrementLoadStep] = useReducer(ls => ls + 1, 1);
  const [prereqStep, incrementPrereqStep] = useReducer(pr => pr + 1, computeStartingPrereqStep());

  // move forward one load step when owners are fully loaded (in Home.js)
  const ownersDidLoad = owners?.size > 0;
  const prereqsAreNotDoneYet = prereqStep === 1;
  const loadingCannotProceed = prereqsAreNotDoneYet && loadStep >= 2;

  const prevState = usePrevState({
    location,
    filters,
    params,
    type,
    query: History.getCurrentLocation().query,
  });

  const getCuratedLimit = async () => {
    await Promise.all([
      Query.curatedLimit(ownerId, owners),
    ]).then(([res]) => {
      if (!res) return;
      // update owners curated_limit and industries
      const index = owners.findIndex(v => v.get('id') === ownerId);
      setOwners(owners
        .setIn([index, 'curated_limit'], res.curated_limit)
        .setIn([index, 'industries'], res.industries)
        .setIn([index, 'keyword_statistics'], res.keyword_statistics)
        .setIn([index, 'num_active_curated_keywords'], res.num_active_curated_keywords)
        .setIn([index, 'keyword_curated_statistics_by_keyclass'], res.keyword_curated_statistics_by_keyclass)
        .setIn([index, 'has_enterprise_domains'], res.has_enterprise_domains));
    });
  };

  const loadKeywords = async (kc) => {
    if (!filters.get('text')) setKeywords(map());
    const keyclass = filters.get('category') && kc ? kc?.find(v => v.get('name') === filters.get('category')) : null;
    const size = filters.get('limit', 50);
    let page = Math.floor(filters.get('skip', 0) / size) + 1;
    if (size >= (type === 'keywords' ? keywords : notificationProfiles).get('total')) page = 1;

    Query.loadKeywords(
      ownerId,
      keywordClasses,
      {
        page_size: size,
        page_number: page,
        status: filters.get('status', ''),
        keyclass: keyclass ? keyclass.get('id') : null,
        delivery: filters.get('delivery', ''),
        sources: filters.get('sources', ''),
        text: filters.get('text', null),
      },
      basetypes,
      user.get('prm'),
    ).then((res) => {
      if (res?.keywords) setKeywords(res.keywords);
      if (/Request failed with status code 400/gi.test(res.message)) {
        setKeywordError(true);
        SearchActions.set(
          ['search', 'info', 'message'],
          Messages.UnexpectedError(res.message),
      );
    }
    }).catch((err) => {
      if (/canceled/ig.test(err?.message)) {
        /* Skip */
        return;
      }
      throw err;
    });
  };

  const loadKeywordClasses = async () => Query.load('keyword_classes').then((res) => {
    const kc = fromJS(res.keywordClasses
      .filter((v) => {
        if (location.pathname.includes('alerts')) {
          // Alerts Tab
          if (/twitter/ig.test(v.get('name')) && !user.get('prm').some(p => /twtr/.test(p))) return false;
          if (/bin/ig.test(v.get('name')) && !user.get('prm').some(p => /csb/.test(p))) return false;
          if (/internet_infrastructure/ig.test(v.get('name'))) return false;
          if (/enterprise_domain/ig.test(v.get('name'))) return false;
          if (/email_domain/ig.test(v.get('name'))) return false;
          if (/cloud_infrastructure/ig.test(v.get('name'))) return false;
          if (/cookie_domain/ig.test(v.get('name'))) return false;
          if (/enterprise_affected_domain/ig.test(v.get('name'))) return false;
          return true;
        } else if (location.pathname.includes('dea')) {
          // Data Exposure Alerts Tab
          if (/twitter/ig.test(v.get('name'))) return false;
          if (/bin/ig.test(v.get('name')) && !user.get('prm').some(p => /csb/.test(p))) return false;
          return true;
        } else if (location.pathname.includes('keywords')) {
          // Keywords tab
          if (/bin/ig.test(v.get('name')) && !user.get('prm').some(p => /csb/.test(p))) return false;
          if (/internet_infrastructure/ig.test(v.get('name'))) return false;
          if (/cloud_infrastructure/ig.test(v.get('name'))) return false;
          if (/email_domain/ig.test(v.get('name'))) return false;
          if (/enterprise_domain/ig.test(v.get('name'))) return false;
          if (/twitter/ig.test(v.get('name'))) return false;
          if (/cookie_domain/ig.test(v.get('name'))) return false;
          if (/enterprise_affected_domain/ig.test(v.get('name'))) return false;
          return true;
        }
        // Everything else:
        if (/twitter/ig.test(v.get('name')) && !user.get('prm').some(p => /twtr/.test(p))) return false;
        return true;
      }));
    setKeywordClasses(kc);
    setEnterpriseDomainKeyclassId(res.enterpriseDomainKeyclassId);
    return kc;
  });

  const loadNotificationProfiles = async () => {
    setNotificationProfiles(map());
    Query.loadNotificationProfiles(ownerId, products)
    .then((res) => {
      setNotificationProfiles(res);
    }).finally(() => {
      if (!notificationProfiles) setNotificationProfiles(map());
    });
  };

  const dashboardFilters = () => {
    if (!ownerId || basetypes?.isEmpty() || keywords?.isEmpty() || keywordClasses?.isEmpty()) {
      return map();
    }

    const profiles = user.getIn(['prefs', 'filters', 'alerting']);
    const activeProfile = user.getIn(['tmp', 'alerting']);
    const profile = !activeProfile ? profiles.get(0) : profiles.filter(v => v.get('name') === activeProfile.active);
    const hitsBySource = () => {
      let sources = '';
      if (profile || profile.active) {
        sources = profile.getIn([0, 'hitsBySource']) ? profile.getIn([0, 'hitsBySource']).split(',') : '';
      }
      return sources;
    };
    // checks to see if profile has changed
    // If changed, pass updated filters for profile
    const existingFilters = filters.toJS();
    const base = fromJS({
      ...existingFilters,
      basetypes,
      ownerId,
      keywords,
      keywordClasses,
    });

    // Checks to see if the user has saved filters
    // pass saved filters if it exists
    if (user.hasIn(['prefs', 'filters', 'alerting'])) {
      const dashFilters = profile.get('filters') || profile.getIn([0, 'filters']);
      const { sinceDate, untilDate } = SearchUtils.parseDate(dashFilters);

      return base.merge(dashFilters.set('since', sinceDate).set('until', untilDate));
    }

    return base.merge(user.getIn(['defaults', 'filters', 'alerting', 0, 'filters']).set('hitsBySource', hitsBySource()));
  };

  const formatLocationForComparison = myLocation =>
   `${myLocation.pathname}${myLocation.search}`.split('').sort().join('');

  const loadStep1 = async () => {
    const [productsRes, basetypesRes] = await Promise.all([
      Query.load('products'),
      Query.basetypes(user.get('prm')),
    ]);
    setProducts(fromJS(productsRes));
    setBasetypes(basetypesRes);
    incrementLoadStep();
  };

  const loadStep2 = async () => {
    try {
      // Set terms after inital load is completed since keywords/recipients
      // reqire the above
      const { query } = History.getCurrentLocation();
      setType(params.type);
      setFilters(fromJS({
        ...query,
        owner_id: ownerId,
      }).filter(v => v));

      incrementLoadStep();
    } catch (err) {
      setLoadErr(true);
      /* But rethrow the error for debugging */
      throw err;
    }
  };

  const loadStep3 = async () => {
    // we need owners, products, and basetypes to load these data
    await Promise.all([
      getCuratedLimit(),
      loadNotificationProfiles()]);
    incrementLoadStep();
  };

  const loadStep4 = async () => {
    const kc = await loadKeywordClasses();
    await loadKeywords(kc);
    incrementLoadStep();
  };

  useDidUpdate(() => {
    const { query } = History.getCurrentLocation();
    if (!location) return undefined;
    if (prevState.location.pathname !== location.pathname) {
      setType(params.type);
    } else if (!_.isEqual(prevState.query, query)) {
      setFilters(Query.loadFilters(filters.toJS()));
    }

    loadKeywordClasses();
  }, [formatLocationForComparison(location)]);

  useDidUpdate(() => {
    if (!type) return undefined;
    if (prevState.type !== type) {
      setFilters(Query.loadFilters(filters.toJS()));
      // Add any default filters when the page is freshly visited
      const { pathname, query } = History.getCurrentLocation();
      History.push({
        pathname,
        query: { ...defaultFilters, ...query },
      });
    }
  }, [type]);

  useDidUpdate(() => {
    if (prevState.filters.isEmpty() || filters.isEmpty()) return undefined;
    // owner_id change is handled elsewhere
    if (prevState.filters.get('owner_id') !== filters.get('owner_id')) return undefined;
    // if only a keyword has been selected, through the dashboard, don't reload keywords
    if (!prevState.filters.get('keyword_id') && filters.get('keyword_id')) return undefined;
    // if only a keyword_id has been removed, don't reload keywords
    if (prevState.filters.get('keyword_id') && !filters.get('keyword_id')) return undefined;
    if (!prevState.filters.equals(filters)) {
      if (type === 'keywords') {
        loadKeywords(keywordClasses);
        getCuratedLimit();
      }
    }
  }, [filters]);

  useDidUpdate(() => {
    if (ownersDidLoad && prereqsAreNotDoneYet) {
      incrementPrereqStep();
    }
  }, [ownersDidLoad && prereqsAreNotDoneYet]);

  useEffect(() => {
    if (!ownerId) return undefined;
    const { pathname, query, hash } = History.getCurrentLocation();
    const urlOwnerId = query.owner_id;
    if (!filters.get('owner_id')
      || filters.get('owner_id') !== urlOwnerId
      || user.get('prm').some(p => /org.fp.r/.test(p))) {
      if (user.get('prm').some(p => /kw/.test(p)) && !keywordClasses.isEmpty()) {
        loadKeywords(keywordClasses);
        getCuratedLimit();
      }
      if (user.get('prm').some(p => /np/.test(p)) && !products.isEmpty()) loadNotificationProfiles();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { owner_id, ...clean } = query;
      const updatedQuery = (owners.count() > 1)
        ? { owner_id: (filters.get('owner_id') === ownerId) ? ownerId : urlOwnerId || ownerId, skip: undefined, ...clean, notification_profile: '' }
        : clean;
      if (updatedQuery?.owner_id) {
        History.replace({
          pathname,
          query: updatedQuery,
          hash,
        });
      }
    }
  }, [ownerId]);

  useEffect(() => {
    // Refresh notificationProfiles if they don't match the owner id
    if (!notificationProfiles || notificationProfiles?.get('count') < 1 || !filters?.get('owner_id')) {
      return undefined;
    }
    const recipient = notificationProfiles?.get('data')?.find(v => v?.get('owner_id') === filters?.get('owner_id'));
    if (!recipient) {
      loadNotificationProfiles();
    }
  }, [filters, notificationProfiles]);

  useEffect(() => {
    const computedLoadStep = loadingCannotProceed ? -1 : loadStep;
    switch (computedLoadStep) {
      case 1: loadStep1(); break;
      case 2: loadStep2(); break;
      case 3: loadStep3(); break;
      case 4: loadStep4(); break;
      default: break;
    }
  }, [loadStep + prereqStep]);

  if (loadErr) {
    return (
      <Invalid
        inline
        icon="notifications"
        title="You are not subscribed to any alerts"
        help={[
          'To subscribe to an alert, please set up your preferences in the Keywords and Recipients tabs',
          'Please contact customer success if you need further assistance',
        ]} />
    );
  }

  return (
    <Grid fluid className={cx([style.alerting, classes.alerting])}>
      {loadStep <= 1 && <CircularProgress />}
      <Row>
        {/* Popping overview dashboard out of parent Col */}
        {['overview'].includes(type) &&
        <AlertingDashboard
          filter={dashboardFilters()}
          prm={user.get('prm')}
        />}
        {['alerts', 'dea', 'keywords', 'recipients'].includes(type) &&
          <Row className={cx([style['alerting-filters'], ['alerts', 'dea'].includes(type) && style.sticky])}>{/* Filters */}
            <Col xs={12} className={style.filters}>
              <Filters
                user={user}
                store={search}
                type={`alerting.${type}`}
                defaults={defaults}
                filters={filters}
                searches={user.getIn(['prefs', 'search'])} />
            </Col>
          </Row>}
        {['keywords', 'recipients'].includes(type) &&
        <Management
          email={user.get('email')}
          prefs={user.get('prefs')}
          prm={user.get('prm')} />}
        {['alerts', 'dea'].includes(type) && notificationProfiles && !notificationProfiles.isEmpty() &&
        <Alerts
          prm={user.get('prm')}
          user={user}/>}
      </Row>
    </Grid>
  );
};

Alerting.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Alerting;
