const getFindText = () => {
  const input = document.querySelector('div[class^="find__"] #text');
  return input?.value || '';
};

const HtmlUtils = {
  getFindText,
};

export default HtmlUtils;
