import Jimp from 'jimp/browser/lib/jimp';

/*
 *  Compute Perceptual Hash of an Image
 *  Javascript equivalent of https://github.com/bjlittle/imagehash
 *
 *  These hashes differ by ~1-3 Hamming distance from original python implementation
 *  Modified version of https://gist.github.com/atwong/3da16d729b495f36ad5d09c68611172a
 */

const dct2d = (mat, m, n) => {
  // 0.0-1.0 2D matrix
  const isqrt2 = 1 / Math.sqrt(2);
  const px = Math.PI / m;
  const py = Math.PI / n;
  const r = [];
  for (let ry = 0; ry < n; ry += 1) {
    for (let rx = 0; rx < m; rx += 1) {
      const c = isqrt2 ** ((rx === 0) + (ry === 0));
      let t = 0;
      for (let y = 0; y < n; y += 1) {
        for (let x = 0; x < m; x += 1) {
          const v = mat[(y * m) + x];
          t += v * Math.cos(px * (x + 0.5) * rx) * Math.cos(py * (y + 0.5) * ry);
        }
      }
      r.push((c * t) / 4);
    }
  }
  return r;
};


const phash = (binary, hashsize = 8, hfreq_fact = 4) => {
  // ITU-R 601-2 luma RGB -> greyscale transform
  const LUMASCALE = [0.2989, 0.5870, 0.1140];
  const OCTLEN = 8;
  const imgsize = hashsize * hfreq_fact;
  const midpoint = Math.floor((hashsize * hashsize) / 2);
  const noctets = Math.floor((hashsize * hashsize) / OCTLEN);
  return new Promise((resolve) => {
    Jimp.read(binary).then((img) => {
      const pixels = [];
      const resized = img.resize(imgsize, imgsize);
      resized.scan(0, 0, imgsize, imgsize, (x, y, idx) => {
        pixels.push(
          (resized.bitmap.data[Number(idx)] * LUMASCALE[0]) +
          (resized.bitmap.data[Number(idx) + 1] * LUMASCALE[1]) +
          (resized.bitmap.data[Number(idx) + 2] * LUMASCALE[2]));
      });
      const coef = dct2d(pixels, imgsize, imgsize);
      const lofreqcoef = coef.filter(
        (v, idx) => (Math.floor(idx / imgsize) < hashsize) && ((idx % imgsize) < hashsize));
      const median = lofreqcoef.slice().sort((a, b) => a - b)[Number(midpoint)];
      const bits = new Uint8Array(noctets);
      for (let i = 0; i < noctets; i += 1) {
        /* eslint-disable-next-line no-param-reassign, no-return-assign */
        bits[Number(i)] = lofreqcoef.slice(i * OCTLEN, (i + 1) * OCTLEN).reduce((acc, val, idx) => acc |= ((val > median) ? '01' : '00') << (OCTLEN - idx - 1), '00');
      }
      /* eslint-disable-next-line no-param-reassign, no-return-assign */
      const hash = bits.reduce((acc, val) => acc += val.toString(16).padStart(2, '0'), '');
      resolve(hash);
    });
  });
};

export default phash;
