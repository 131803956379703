import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { List as list } from 'immutable';
import { CircularProgress } from '@mui/material';
import {
  Bar as B,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip } from 'recharts';

import style from './bar.module.scss';
import Text from '../../../utils/text';
import Api from '../../../utils/api';

const Bar = ({
  aid,
  color,
  labels,
  values,
  results,
  tooltip,
  onClick,
}) => {
  const [tooltipVisiblility, setTooltipVisibility] = useState('hidden');
  const [cache, setCache] = useState({});
  const CustomTooltip = v => (
    <div className={style.tooltip}>
      {!cache[v.label] &&
      <CircularProgress />}
      {cache[v.label] &&
      <div>
        {cache[v.label].map(c => (
          <div
            key={c.key}
            className={style.tip}>{`${Text.AbbreviateNumber(c.doc_count)} - ${c.key}`}
          </div>))}
      </div>}
    </div>
  );
  const onBlur = () => setTooltipVisibility('hidden');
  const onHover = async (label) => {
    setTooltipVisibility('visible');
    if (cache[String(label)]) return;
    const query = {
      query: `+basetypes:(card) +sort_date:[${moment.utc(label).startOf('day').format()} TO ${moment.utc(label).endOf('day').format()}]`,
      size: 0,
      aggregations: { terms: { field: 'base.title.keyword', size: 10 } },
    };
    await Api.post('/ui/v4/all/search', query)
      .then(res => (res.ok ? res.data : {}))
      .then(res => res.aggregations.terms.buckets)
      .then(res => setCache({ ...cache, [label]: res }));
  };

  return (
    <div style={{ width: '100%', minHeight: '400px' }}>
      <ResponsiveContainer className={style.bars}>
        <BarChart
          margin={{ left: -15, top: 0 }}
          className={style.chart}
          data={results.toJS()}>
          <XAxis
            label={false}
            dataKey={labels.getIn([0, 'key'])}
            tickFormatter={v => moment.utc(v).format('MM/DD/YYYY')} />
          <YAxis
            label={false}
            tickFormatter={v => Text.AbbreviateNumber(v)} />
          {tooltip &&
          <Tooltip
            wrapperStyle={{ visibility: tooltipVisiblility }}
            cursor={{ fill: '#f7f5f5' }}
            content={<CustomTooltip />} />}
          <B
            name="mentions"
            isAnimationActive={false}
            fill={Text.Color(0, aid, false, color)}
            onBlur={() => onBlur()}
            onFocus={v => onHover(v[labels.getIn([0, 'key'])])}
            onMouseOut={() => onBlur()}
            onMouseOver={v => onHover(v[labels.getIn([0, 'key'])])}
            dataKey={values.getIn([0, 'key'])}
            onClick={(v) => {
              const since = moment.utc(v[labels.getIn([0, 'key'])]).startOf('day');
              const until = moment.utc(v[labels.getIn([0, 'key'])]).endOf('day');
              onClick(results, {
                date: `${since.format()} - ${until.format()}`,
                since: since.format(),
                until: until.format() });
              }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};


Bar.propTypes = {
  aid: PropTypes.string,
  color: PropTypes.string,
  labels: PropTypes.object,
  values: PropTypes.object,
  results: PropTypes.object,
  tooltip: PropTypes.bool,
  onClick: PropTypes.func,
};

Bar.defaultProps = {
  aid: '',
  color: '',
  labels: list(),
  values: list(),
  results: list(),
  tooltip: false,
  onClick: null,
};

export default Bar;
