// map of status codes to human readable status
export const searchStatusEnum = {
  PREPAYMENT_CHECK: 1,
  PAYMENT_MADE: 2,
  EXCLUDED: 3,
};

// map the status enum to the string the backend returns
export const getStatusString = ((status) => {
  switch (status) {
    case searchStatusEnum.PREPAYMENT_CHECK:
      return 'prepaymentCheck';
    case searchStatusEnum.PAYMENT_MADE:
      return 'paymentMade';
    case searchStatusEnum.EXCLUDED:
      return 'excluded';
    default:
      return 'null';
  }
});

export const getStatusEnumFromString = ((status) => {
  switch (status) {
    case 'prepaymentCheck':
      return searchStatusEnum.PREPAYMENT_CHECK;
    case 'paymentMade':
      return searchStatusEnum.PAYMENT_MADE;
    case 'excluded':
      return searchStatusEnum.EXCLUDED;
    default:
      return 'null';
  }
});

export const getPrettyStatusString = ((status) => {
  switch (status) {
    case searchStatusEnum.PREPAYMENT_CHECK:
      return 'Pre-Payment Check';
    case searchStatusEnum.PAYMENT_MADE:
      return 'Payment Made';
    case searchStatusEnum.EXCLUDED:
      return 'Excluded';
    default:
      return 'null';
  }
});
