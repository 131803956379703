import * as React from 'react';

import { Col, Row } from 'react-flexbox-grid/lib';
import cx from 'classnames';

import { WidgetSelector } from './WidgetSelector';
import type { ExpoDashFilters } from '../../../../core/types/ExpoDashFilters';
import type { ExpoDashData } from '../../../../core/types/ExpoDashPreferences';

import style from '../homedashboard.module.scss';

interface CustomizableWidgetsProps {
  data: ExpoDashData,
  filters: ExpoDashFilters,
  user: Map<string, object>,
  setDialog: (value: object) => object,
  setLook: (value: object) => object,
}

export const CustomizableWidgets = ({
  data,
  filters,
  user,
  setLook,
  setDialog,
}: CustomizableWidgetsProps): JSX.Element => {
  if (!data?.expoDashWidgets?.topRow) {
    return (
      <Col xs={12} className={cx([style.containers])}>
        <p>Unable to load Customizable Widgets</p>
      </Col>
    );
  }

  return (
    <>
      <Row className={style.smallRow} style={{ width: '100%' }}>
        {data?.expoDashWidgets?.topRow?.map(widgetSettings => (
          <Col xs={3} key={widgetSettings.id} className={cx([style.containers])}>
            <WidgetSelector
              data={data}
              filters={filters}
              user={user}
              settings={widgetSettings}
              setLook={setLook}
              setDialog={setDialog}
              dialogKey="topRow" />
          </Col>
        ))}
      </Row>
      <Row className={style.middleRow} style={{ width: '100%' }}>
        {data?.expoDashWidgets?.centerRow?.map(widgetSettings => (
          <Col xs={6} key={widgetSettings.id} className={cx([style.containers])}>
            <WidgetSelector
              data={data}
              filters={filters}
              user={user}
              settings={widgetSettings}
              setLook={setLook}
              setDialog={setDialog}
              dialogKey="centerRow" />
          </Col>
        ))}
      </Row>
    </>
  );
};
