import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/SendOutlined';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
  messageArea: {
    border: `1px ${grey[500]} solid`,
    borderRadius: '4px',
    padding: '.5rem',
    '&:focus-within': {
      borderColor: grey[900],
    },
  },
  messageTextArea: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
  },
  messageFilePreview: {
    alignItems: 'center',
    backgroundColor: grey[100],
    display: 'flex',
    padding: '.5rem .5rem .5rem 1rem',
  },
  messageCancelBtn: {
    marginLeft: 'auto',
  },
  sendIcon: {
    transform: 'rotate(-45deg)',
    color: '#5c6ae0',
  },
});

const MessageForm = ({ onSubmit, scrollRef }) => {
  const uploadFileRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');
  const [file, setFile] = useState(null);
  const classes = useStyles();

  const handleFileUpload = () => {
    uploadFileRef.current.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const text = userMessage.trim();
    if ((text && text.length) > 0 || file) {
      onSubmit(text, file);
      setUserMessage('');
      setFile(null);
    }
  };

  const handleFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const newFile = event.target.files[0];
    setFile(newFile);
  };

  return (
    <div className={classes.messageArea}>
      <input type="file" onChange={handleFileChange} ref={uploadFileRef} style={{ display: 'none' }} />
      {
        file &&
        <div className={classes.messageFilePreview}>
          {file.name}
          <IconButton
            className={classes.messageCancelBtn}
            onClick={() => setFile(null)}
            size="large">
            <CancelIcon />
          </IconButton>
        </div>
      }
      <TextField
        className={classes.messageTextArea}
        value={userMessage}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(e);
          }
        }}
        onInput={e => setUserMessage(e.target.value)}
        onChange={
          () => scrollRef.current.scrollIntoView(false)
        }
        placeholder="Add comment"
        multiline
        minRows={1}
        maxRows={5}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleFileUpload} size="large">
                <AttachFileIcon />
              </IconButton>
              <IconButton onClick={handleSubmit} size="large">
                <SendIcon className={classes.sendIcon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        />
    </div>
  );
};

MessageForm.propTypes = {
  onSubmit: PropTypes.func,
  scrollRef: PropTypes.object,
};

MessageForm.defaultProps = {
  onSubmit: null,
  scrollRef: null,
};

export default MessageForm;
