import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { fromJS, List as list, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Paper,
} from '@mui/material';

import SearchActions from '../../actions/searchActions';
import Api from '../../utils/api';
import style from './card.module.scss';
import Text from '../../utils/text';
import History from '../../utils/history';
import Messages from '../../constants/Messages';
import InternalLink from '../utils/InternalLink';
import { UserContext } from '../utils/Context';

const useStyles = makeStyles(theme => ({
  card: {
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
  },
}));

const Card = ({
  cdnTag,
  userHistory,
  icon,
  mini,
  onRoute,
  report,
  standup,
  statusbar,
  className,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [showDelete, setShowDelete] = useState(false);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState();

  const formatReport = () => {
    const noAlis = document.querySelectorAll('.standup ul li span a');
    const lies = document.querySelectorAll('.standup ul li');

    if (!standup) {
      setTitle(report.get('title'));
      if (report.has('version_posted_at')) {
        setDate(moment.utc(report.get('version_posted_at')).format('MMMM DD, YYYY'));
      }
    } else if (standup && report.get('title').indexOf('-') !== -1) {
      setTitle(report.get('title').split('-')[0].trim());
      setDate(moment.utc(report.get('title').split('-')[1].trim()).format('MMMM DD, YYYY'));
    } else {
      const match = report.get('title').match(/(0?[1-9]|1[012]).(0?[1-9]|[12][0-9]|3[01]).\d{4}/g);
      setTitle('Daily Standup');
      if (match?.[0]) {
        setDate(moment.utc(match?.[0], 'MM.DD.YYYY').format('MMMM DD, YYYY'));
      }
    }

    if (noAlis.length > 0) {
      // eslint-disable-next-line no-param-reassign
      noAlis.forEach((x) => { x.textContent = ''; });
    }
    if (lies.length > 0) {
      // eslint-disable-next-line no-param-reassign
      lies.forEach((el) => { el.textContent = `${el.textContent.substring(0, 250)}`; });
    }
    const items = document.querySelectorAll('.standup *');
    // eslint-disable-next-line no-param-reassign
    items.forEach((el) => { el.style.display = (el.textContent.length < 30) ? 'none' : ''; });
  };

  const onDelete = (reportId) => {
    Api.delete(`/ui/v4/reports/${reportId}`, null, [200, 204], 30000, {})
       .then(res => (res.ok
        ? SearchActions.set(['search', 'info', 'message'], Messages.ReportDeletion)
        : new Error(Messages.ReportDeletionError)))
      .catch(() => {
        SearchActions.set(['search', 'info', 'message'], Messages.ReportDeletionError);
      });
  };

  const onLocalRoute = () => {
    const { query } = History.getCurrentLocation();
    const update = fromJS({ ...query })
      .delete('skip')
      .delete('limit')
      .toJS();
    const featured = report.get('is_featured') || standup ? '/featured' : '';
    const pathname = `/home/intelligence/reports/report/${report.get('id')}${featured}`;
    return ({ pathname, query: update });
  };

  const onTag = (event, tag) => {
    event.stopPropagation();
    event.preventDefault();
    return History.push(`/home/intelligence/latest/${encodeURIComponent(tag)}`);
  };

  useEffect(() => {
    formatReport();
  }, [standup]);

  return (
    <InternalLink
      className={style.link}
      to={onRoute || onLocalRoute()}>
      <Paper
        key={report.get('id')}
        className={cx([
          className,
          style.card,
          classes.card,
          mini && style.mini,
          standup && style.standup,
          report.get('category') && style.category,
          report.get('body').startsWith('<html>') && style.google,
          report.get('published_status') === 'draft' && style.draft,
          !user.getIn(['prefs', 'href']) && userHistory
            .find(v => v.get('path')
            .split('/')
            .filter(f => f !== 'featured')
            .splice(-1)
            .join() === report.get('id')) && style.viewed,
          report.get('is_featured') && !mini && style.featured])}>
        <Icon
          className={cx([
            style.icon,
            report.get('published_status') === 'draft' ? style.drafticon : style.hidden,
          ])}>
          create
        </Icon>
        <div className={style.content}>
          {report.get('category') &&
          <div className={style.category}>
            {report.get('category')}
          </div>}
          <div className={style.header}>
            {standup &&
            <Icon color="secondary">access_time</Icon>}
            {report.get('published_status') === 'draft' &&
            <Button
              color="secondary"
              variant="contained"
              contained={{ backgroundColor: '#5c6ae0', labelColor: '#fff' }}
              onClick={(e) => { e.preventDefault(); setShowDelete(true); }}
              className={style.button}>
              Delete
            </Button>}
            {showDelete &&
            <Dialog
              open={showDelete}>
              <DialogTitle>Delete {report.get('title')}</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this report?
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => { setShowDelete(false); }}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => { onDelete(report.get('id')); setShowDelete(false); }}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>}
            <div>
              <div
                className={cx([
                  style.raj, 'raj',
                  style.title,
                  style.trim,
                  ...report.get('is_featured') && !mini ? [style.h0, 'h0'] : [style.h1, 'h1']])}
                onMouseEnter={() => ReactTooltip.rebuild()}
                data-for="global.tooltip"
                data-tip={title?.replace(/\(Analyst Knowledge Page\)/gi, '')}>
                {icon &&
                <Icon color="secondary">{icon}</Icon>}
                {Text.Highlight(title?.replace(/\(Analyst Knowledge Page\)/gi, ''), true)}
              </div>
              {date && !mini &&
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])} data-cy="posted_at">
                {date}
              </div>}
              {!report.get('archived') && date && mini && moment(report.get('updated_at')).isAfter(moment().subtract(7, 'days')) &&
              <div className={style.date} data-cy="posted_at_1">
                <span>{`Updated ${moment(report.get('updated_at')).from(moment())}`}</span>
                <span>{date}</span>
              </div>}
            </div>
          </div>
          {!standup &&
          <div className={style.summary}>
            {Text.Highlight(
            Text.Trim(
              Text.StripHtml(
                Text.ReportCard(
                  Text.StripCss(
                    report.get('summary') || report.getIn(['body'])))), mini ? 160 : 175), true)}
          </div>}
          {standup && !report.get('body').startsWith('<html>') &&
          <div className={style.summary}>
            {Text.Highlight(Text.Standup(report.get('body')), true)}
          </div>}
          {standup && report.get('body').startsWith('<html>') &&
          <div className={cx([style.summary, 'standup', style.google])}>
            {Text.Highlight(
              Text.GoogleStandup(
                report.get('body'),
              ),
            true)}
            {!!requestAnimationFrame(() => formatReport())}
          </div>}
          {!mini &&
          <div className={style.tags}>
            {report
            .get('tags')
            .filterNot(v => v === 'Standup' || (standup && v === 'Intelligence Report'))
            .entrySeq()
            .take(3)
            .map(([k, tag]) => (
              <span
                role="link"
                tabIndex={0}
                onKeyUp={() => null}
                key={tag}
                className={cx([style.h4, 'h4', style.mont, 'mont', style.a, 'a'])}
                onClick={event => onTag(event, tag)}>
                {`${k > 0 ? ', ' : ''}${tag}`}
              </span>))}
          </div>}
          {statusbar}
        </div>
        {!standup && !mini && report.get('title_asset') &&
        <img
          loading="lazy"
          height="135"
          width="135"
          alt={Text.Strip(report.get('title'))}
          className={cx([style.image, style.blur])}
          src={report.get('title_asset') ?
            `/ui/v4${report.get('title_asset')}?size=thumbnail${cdnTag.toString()}` :
            '/static/placeholder.svg'} />}
        {!mini && report.get('is_featured') &&
        <div className={style.ribbon}>
          <div>Featured</div>
          <div />
        </div>}
      </Paper>
    </InternalLink>
  );
};

Card.propTypes = {
  cdnTag: PropTypes.string,
  userHistory: PropTypes.object,
  icon: PropTypes.string,
  mini: PropTypes.bool,
  onRoute: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  report: PropTypes.object,
  standup: PropTypes.bool,
  statusbar: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  className: PropTypes.string,
};

Card.defaultProps = {
  cdnTag: '',
  userHistory: list(),
  icon: '',
  mini: false,
  onRoute: null,
  report: map(),
  standup: false,
  statusbar: null,
  className: '',
};

export default React.memo(Card);
