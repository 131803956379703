const necessaryLegacyStylesRoot = `
html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: inherit;
}

strong, b {
  font-weight: 700;
}

body {
  background-color: #fcfcfc;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
  margin: 0;
}
@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #fafafa;
}

a {
  color: #5c6ae0;
  cursor: pointer;
  text-decoration: none;
}

blockquote {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

*:focus {
  outline: none;
}

*::-moz-placeholder {
  text-transform: none;
}

*::-webkit-input-placeholder {
  text-transform: none;
}

input:-webkit-autofill {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

input:-webkit-autofill:hover {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

[data-id='tooltip'] {
  background: #222;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  max-height: 35rem;
  max-width: 45rem;
  overflow-x: hidden;
  overflow-y: auto;
}

[data-id='tooltip'] * {
  background: #222;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: initial;
}

.glossary-term {
  border-bottom: .2rem dashed #5c6ae0;
  cursor: pointer;
  word-break: normal;
}

.highlight {
  background-color: #5c6ae0;
  border-radius: .5rem;
  font-weight: 400;
  color: #fff !important;
  padding: 0 .5rem;
  word-break: normal;
}

.a {
  color: #5c6ae0 !important;
  cursor: pointer;
  text-decoration: none;
}

.a:hover {
  color: #3547d9;
}

.h0 {
  color: #000;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.h1 {
  color: #000;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.h2 {
  font-size: 1.8rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}

.h3 {
  color: #9e9e9e;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
}

.h4 {
  color: #9e9e9e;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.raj {
  font-family: Rajdhani;
}

.mont {
  font-family: Montserrat;
}

.normal {
  text-transform: initial;
}

.right {
  direction: rtl !important;
}

.left {
  direction: ltr !important;
}

.srOnly {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.rdk-portal {
  background: var(--color-background);
  color: var(--color-on-background);
  font-family: var(--font-family);
  -moz-font-feature-settings: 'liga' on;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  line-height: 1.2;
  overflow: hidden;
  text-rendering: optimizeLegibility;
}

.rdk-portal a {
  color: var(--color-primary);
  text-decoration: none;
}

.rdk-portal code {
  font-family: var(--mono-font-family);
}

.rdk-portal input {
  font-family: var(--font-family);
}

.rdk-portal button {
  font-family: var(--font-family);
}

.rdk-portal h1 {
  font-weight: 100;
}

.rdk-portal h2 {
  font-weight: 100;
}

.rdk-portal h3 {
  font-weight: 100;
}

.rdk-portal a:hover {
  text-decoration: underline;
}

.uppy-Root.uppy-DragDrop-container {
  align-items: center;
  background: var(--color-input);
  border: 1px solid transparent;
  border-radius: 2px;
  color: var(--color-on-input);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.uppy-Root.uppy-StatusBar {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar:before {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar:not([aria-hidden='true']).is-waiting {
  background-color: var(--nav-background);
  border-top: 1px solid var(--nav-background);
}

.uppy-Root.uppy-StatusBar .uppy-StatusBar-progress {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar .uppy-StatusBar-actionBtn {
  background-color: var(--color-button-success);
}

.uppy-Root.uppy-StatusBar .uppy-StatusBar-statusPrimary {
  color: var(--color-on-surface);
}

.uppy-Root.uppy-StatusBar .uppy-StatusBar-statusIndicator {
  color: var(--color-error);
}

.uppy-Root.uppy-StatusBar .uppy-StatusBar-details {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar.is-uploading .uppy-StatusBar-statusIndicator {
  color: var(--color-primary);
}

.uppy-Root.uppy-StatusBar.is-uploading .uppy-StatusBar-progress {
  background-color: var(--color-primary);
}

.uppy-Root.uppy-StatusBar.is-uploading .uppy-StatusBar-spinner {
  fill: var(--color-primary);
}

.uppy-Root.uppy-StatusBar:not([aria-hidden='true']).is-waiting:before {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar:not([aria-hidden='true']).is-waiting .uppy-StatusBar-actions {
  background-color: var(--nav-background);
}

.uppy-Root.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: var(--color-error);
}

.uppy-Root.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: var(--color-error);
}

.uppy-Root.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: var(--color-success);
}

.uppy-Root.uppy-DragDrop-container:hover {
  outline: none;
}

.uppy-Root.uppy-DragDrop-container:active {
  outline: none;
}

.uppy-Root.uppy-DragDrop-container:focus {
  outline: none;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-inner {
  align-items: center;
  display: flex;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-arrow {
  fill: #e0e0e0;
  height: 16px;
  margin: 0 4px;
  width: 16px;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label {
  color: var(--color-on-input);
  font-size: 13px;
  margin-bottom: 0;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-note {
  font-size: 13px;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label:after {
  content: '.';
  margin-right: 4px;
}

.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label .uppy-DragDrop-browse {
  color: var(--color-primary);
}

.monaco-editor .details {
  background-color: var(--nav-background) !important;
  border-color: transparent !important;
}

.monaco-editor .monaco-hover {
  background-color: var(--nav-background) !important;
  border-color: transparent !important;
  color: #eefff6 !important;
  font-family: var(--mono-font-family) !important;
}

.monaco-editor .highlight {
  background-color: inherit;
  padding: 0;
}

.monaco-editor .suggest-widget .tree {
  background-color: var(--nav-background) !important;
  border-color: transparent !important;
}

.monaco-editor .suggest-widget .monaco-list-row.focused {
  background-color: var(--color-surface-primary) !important;
}

.monaco-editor .suggest-widget .monaco-list-row:hover {
  background-color: var(--color-surface-secondary) !important;
}

.public-DraftEditorPlaceholder-inner {
  color: var(--color-input-placeholder);
  user-select: none;
}


.MuiSvgIcon-root {
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 2.4rem;
  height: 1em;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  width: 1em;
}


.MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1.5rem;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.MuiTouchRipple-root {
  border-radius: inherit;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

@-webkit-keyframes MuiTouchRipple-keyframes-enter {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }

  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
@-webkit-keyframes MuiTouchRipple-keyframes-exit {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@-webkit-keyframes MuiTouchRipple-keyframes-pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.92);
  }

  100% {
    transform: scale(1);
  }
}


.MuiButtonBase-root {
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
}

.MuiButtonBase-root::-moz-focus-inner {
  border-style: none;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: default;
  pointer-events: none;
}
@media print {
  .MuiButtonBase-root {
    -webkit-print-color-adjust: exact;
  }
}


.MuiIconButton-root {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  flex: 0 0 auto;
  font-size: 2.4rem;
  overflow: visible;
  padding: 12px;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiIconButton-root.Mui-disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
}


.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}


@media print {
  .MuiDialog-root {
    position: absolute !important;
  }
}


.MuiTypography-root {
  margin: 0;
}


.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
}
.MuiDialogTitle-root .MuiIcon-root {
  float: right;
}


.MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.MuiDialogContent-root:first-child {
  padding-top: 20px;
}


.MuiSnackbar-root {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 8px;
  position: fixed;
  right: 8px;
  z-index: 1400;
}

.MuiSnackbar-root .MuiSnackbarContent-root {
  justify-content: center;
}

.MuiSnackbar-root .MuiSnackbarContent-action {
  color: #5c6ae0;
  cursor: pointer;
  text-transform: capitalize;
}


.MuiChip-root {
  align-items: center;
  background-color: #e0e0e0;
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  display: inline-flex;
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.3rem;
  height: 32px;
  justify-content: center;
  margin: 4px;
  outline: 0;
  padding: 0;
  text-decoration: none;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  white-space: nowrap;
}

.MuiChip-root.Mui-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.MuiChip-root .MuiChip-avatar {
  color: #616161;
  font-size: 1.2rem;
  height: 24px;
  margin-left: 5px;
  margin-right: -6px;
  width: 24px;
}

.MuiChip-root .MuiChip-avatarColorPrimary {
  background-color: rgb(110, 110, 110);
  color: #fff;
}

.MuiChip-root .MuiChip-avatarColorSecondary {
  background-color: rgb(178, 106, 0);
  color: #fff;
}

.MuiChip-root .MuiChip-avatarSmall {
  font-size: 1rem;
  height: 18px;
  margin-left: 4px;
  margin-right: -4px;
  width: 18px;
}


.MuiAutocomplete-root.Mui-focused .MuiAutocomplete-clearIndicatorDirty {
  visibility: visible;
}
@media (pointer: fine) {
  .MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicatorDirty {
    visibility: visible;
  }
}
.MuiAutocomplete-root.active .MuiButton-root {
  border-color: #5c6ae0;
}
.MuiAutocomplete-root.active .MuiFormLabel-root {
  color: #5c6ae0;
}
.MuiAutocomplete-root.active .MuiIconButton-root {
  color: #5c6ae0;
}
.MuiAutocomplete-root.active .MuiOutlinedInput-notchedOutline {
  border-color: #5c6ae0;
}
.MuiAutocomplete-root.active .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5c6ae0;
}


.MuiFormControl-root {
  border: 0;
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  vertical-align: top;
}

.MuiFormControl-root .MuiButton-label {
  color: #000;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.MuiFormControl-root .MuiButton-label .MuiIcon-root {
  color: #9e9e9e;
  font-size: 2.4rem;
}

.MuiFormControl-root.active .MuiButton-root {
  border-color: #5c6ae0;
}

.MuiFormControl-root.active .MuiIconButton-root {
  color: #5c6ae0;
}

.MuiFormControl-root.active .MuiFormLabel-root {
  color: #5c6ae0;
}

.MuiFormControl-root.active .MuiIcon-root {
  color: #5c6ae0;
}

.MuiFormControl-root.active .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
  border-color: #5c6ae0 !important;
}

.MuiFormControl-root.active .MuiSelect-icon {
  color: #5c6ae0 !important;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1.4rem;
  font-family: "Open Sans",Arial,sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.MuiFormLabel-root.Mui-focused {
  color: #5c6ae0;
}
.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.MuiFormLabel-root.Mui-error {
  color: #f44336;
}


.MuiInputLabel-root {
  display: block;
  transform-origin: top left;
}


.MuiButton-root {
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 16px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  text-decoration: none;
}

.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiButton-root:hover {
    background-color: transparent;
  }
}

.MuiButton-root:hover.Mui-disabled {
  background-color: transparent;
}


.MuiIcon-root {
  cursor: pointer;
  flex-shrink: 0;
  font-size: 2.4rem;
  height: 1em;
  overflow: visible;
  user-select: none;
  width: 1em;
}


.MuiPopover-root.dropdown .active {
  font-weight: bold;
}

.MuiPopover-root.dropdown .MuiPaper-root {
  max-height: 25rem;
  max-width: 25rem;
}


.MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}


.MuiListItem-root {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 8px;
  padding-top: 8px;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.MuiListItem-root.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.08);
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.MuiListItem-root.Mui-disabled {
  opacity: 0.5;
}


@-webkit-keyframes mui-auto-fill {}
@-webkit-keyframes mui-auto-fill-cancel {}

.MuiInputBase-root {
  align-items: center;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.1876em;
  position: relative;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}


.MuiInput-root {
  position: relative;
}


.PrivateNotchedOutline-root-16 {
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0 8px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
}


.MuiOutlinedInput-root {
  border-radius: 4px;
  position: relative;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87);
}
@media (hover: none) {
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #9e9e9e;
  border-width: 2px;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26);
}


.MuiFormHelperText-root {
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.66;
  margin: 0;
  margin-top: 3px;
  text-align: left;
}

.MuiFormHelperText-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
}


.MuiSelect-root .MuiListItemIcon-root {
  display: none;
}
.MuiSelect-root.active .MuiFormLabel-root {
  color: #5c6ae0;
}
.MuiSelect-root.active .MuiOutlinedInput-notchedOutline {
  border-color: #5c6ae0;
}
.MuiSelect-root.active .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5c6ae0;
}


.MuiCircularProgress-root {
  display: inline-block;
}


.MuiMenuItem-root {
  box-sizing: border-box;
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 48px;
  overflow: hidden;
  padding-bottom: 6px;
  padding-top: 6px;
  white-space: nowrap;
  width: auto;
}
@media (min-width:600px) {
  .MuiMenuItem-root {
    min-height: auto;
  }
}


.MuiCheckbox-root {
  color: rgba(0, 0, 0, 0.54);
}


.MuiInputAdornment-root {
  align-items: center;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  white-space: nowrap;
}


.MuiMenuItem-root {
  box-sizing: border-box;
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 48px;
  overflow: hidden;
  padding-bottom: 6px;
  padding-top: 6px;
  white-space: nowrap;
  width: auto;
}
@media (min-width:600px) {
  .MuiMenuItem-root {
    min-height: auto;
  }
}


.MuiListItemText-root {
  flex: 1 1 auto;
  margin-bottom: 4px;
  margin-top: 4px;
  min-width: 0;
}


.MuiSwitch-root {
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  vertical-align: middle;
  width: 58px;
  z-index: 0;
}
@media print {
  .MuiSwitch-root {
    -webkit-print-color-adjust: exact;
  }
}

.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}


.MuiCollapse-root {
  height: 0;
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.MuiListItemIcon-root {
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  flex-shrink: 0;
  margin: 0 .5rem;
  min-width: inherit;
}


.MuiToolbar-root {
  align-items: center;
  display: flex;
  position: relative;
}

.MuiGrid-root {
}


.MuiLinearProgress-root {
  height: 4px;
  overflow: hidden;
  position: relative;
}
@media print {
  .MuiLinearProgress-root {
    -webkit-print-color-adjust: exact;
  }
}

.MuiDrawer-root {
}


.MuiTable-root {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}
.MuiTable-root caption {
  color: rgba(0, 0, 0, 0.54);
  padding: 16px;
  font-size: 1.4rem;
  text-align: left;
  font-family: "Open Sans",Arial,sans-serif;
  font-weight: 400;
  line-height: 1.43;
  caption-side: bottom;
}


.MuiTableBody-root {
  display: table-row-group;
}


.MuiTableRow-root {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  background-color: rgba(255, 152, 0, 0.08);
}


.MuiTableCell-root {
  border-bottom: 1px solid
    rgba(224, 224, 224, 1);
  cursor: pointer;
  display: table-cell;
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
  max-width: 25rem;
  overflow: hidden;
  padding: 16px;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}

.MuiTableCell-root a {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTableCell-root div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTableCell-root.actions {
  max-width: 12rem;
  padding: .6rem 0;
  text-align: right;
}

.MuiTableCell-root.description {
  max-width: 40rem;
  white-space: normal;
}

.MuiTableCell-root .MuiButtonBase-root {
  padding-top: 0;
}


.MuiTableHead-root {
  display: table-header-group;
}


.MuiPaginationItem-root {
  border-radius: 16px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  height: 32px;
  line-height: 1.43;
  margin: 0 3px;
  min-width: 32px;
  padding: 0 6px;
  text-align: center;
}

.MuiPagination-root {

}


.MuiTablePagination-root {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.4rem;
  overflow: auto;
}

.MuiTablePagination-root:last-child {
  padding: 0;
}


.MuiTableContainer-root {
  background-color: #fff;
  overflow-x: scroll;
  padding: 0;
  width: 100%;
}


.MuiBadge-root {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
}


.MuiFab-root {
  background-color: #e0e0e0;
  border-radius: 50%;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans',Arial,sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  height: 56px;
  line-height: 1.75;
  min-height: 36px;
  min-width: 0;
  padding: 0;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 56px;
}

.MuiFab-root:active {
  box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2),0 12px 17px 2px rgba(0,0,0,0.14),0 5px 22px 4px rgba(0,0,0,0.12);
}

.MuiFab-root:hover {
  background-color: #d5d5d5;
  text-decoration: none;
}

.MuiFab-root.Mui-focusVisible {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12);
}

.MuiFab-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
  .MuiFab-root:hover {
    background-color: #e0e0e0;
  }
}

.MuiFab-root:hover.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
`;
export default necessaryLegacyStylesRoot;
