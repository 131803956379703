/*
Core Palette
============
- Midnight:       #1A1C33
- Midnight Light: #282a42
- Flame:          #FB8521
- Electric Rose:  #FF2454
- Mint:           #6EDBA8

Grayscale (never for copy)
=========
- Dark Grey:      #808285
- Medium Grey:    #BCBEC0
- Light Grey:     #F1F2F2
*/

export default {};
