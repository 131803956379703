import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { fromJS } from 'immutable';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, FormControl, Grid, InputLabel, TextField, Autocomplete as MuiAutocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';

import AlertingStore from '../../../../stores/recoil/alerting';
import TextFilter from '../../../filters/TextFilter';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.6rem',
  },
  form: {
    marginBottom: '.8rem',
  },
  error: {
    color: red[500],
  },
  footer: {
    borderTop: '1px solid #e6e7e8',
  },
}));

const ClientSetup = ({ onCancel, onSubmit }) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState(null);
  const [owners] = useRecoilState(AlertingStore.owners);
  const [alertingFilters, setFilters] = useRecoilState(AlertingStore.filters);
  const owner = useRecoilValue(AlertingStore.owner);
  const ownerId = useRecoilValue(AlertingStore.ownerId);

  const classes = useStyles();

  const validateData = (_data) => {
    const _errors = [];
    if (!ownerId) {
      _errors.push('Please choose a Client');
    }
    if (!_data.name) {
      _errors.push('Please enter a Name for this POV');
    }
    if (!_data.types) {
      _errors.push('Please choose at least one Client Type');
    }
    if (!_data.domains) {
      _errors.push('Please enter at least one Valid Domain');
    }
    setErrors(_errors);
    if (_errors.length > 0) {
      return false;
    }
    return true;
  };

  const processFormData = (_data) => {
    const types = data.types.split(',');
    const brands = types.map((type) => {
      const {
        name,
        email_overrides,
        domains,
        social_profiles,
        mobile_apps,
      } = _data;
      let urls = domains;
      switch (type) {
        case 'domain':
          /* Do nothing */
          break;
        case 'mobile app':
          urls = `${urls},${mobile_apps}`;
          break;
        case 'brand social media':
        case 'executive social media':
          urls = `${urls},${social_profiles}`;
          break;
        default:
          /* Do nothing */
          break;
      }
      return {
        allowed_domains: urls,
        salesforce_id: owners.find(v => v.get('id') === ownerId).get('salesforce_id'),
        name,
        type,
        email_overrides,
      };
    });
    return brands;
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = () => {
    if (!validateData(data)) return;
    const submitData = processFormData(data);
    if (onSubmit) {
      onSubmit(submitData);
    }
  };

  const onClientSelect = (selected) => {
    setFilters(alertingFilters.set('owner_id', selected.id));
  };

  return (
    <>
      <Grid container className={classes.root}>
        {!owner.isEmpty() &&
        <Grid item xs={12} className={classes.form}>
          {owners.count() > 1 &&
          <FormControl>
            <InputLabel variant="outlined" id="goalLabel">Organization*</InputLabel>
            <MuiAutocomplete
              fullWidth
              autoSelect
              openOnFocus
              blurOnSelect
              disableCloseOnSelect
              value={owners.find(v => v.get('id') === ownerId).toJS()}
              options={owners
                          .sortBy(v => v.get('name').toLowerCase())
                          .toJS()}
              getOptionLabel={option => option.name}
              onChange={(event, option, reason) => {
                          if (['selectOption'].includes(reason)) onClientSelect(option);
                        }}
              renderInput={parameters => (
                <TextField
                  {...parameters}
                  variant="outlined" />
                          )}
                        />
          </FormControl>}
          {owners.count() === 1 &&
          <div>
            <span>{Text.Sentence(owner.get('name'))}</span>
          </div>}
        </Grid>}
        <TextFilter
          inline
          onFilter={v => setData(current => ({
            ...current,
            ...v,
          }))}
          fields={[
            { value: 'name', label: 'Client Brand Name*', type: 'text', help: 'Please name the brand you want protected.' },
            { value: 'types', label: 'Client Types*', type: 'checkboxes', opts: fromJS([
              { value: 'domain', label: 'Domain Monitoring' },
              { value: 'mobile app', label: 'Mobile App Monitoring' },
              { value: 'brand social media', label: 'Social Media Brand Monitoring' },
              { value: 'executive social media', label: 'Social Media Executive Monitoring' },
            ]) },
            { value: 'email_overrides', label: 'Recipients', type: 'chips' },
            { value: 'domains', label: 'Valid Domains*', type: 'chips' },
            { value: 'social_profiles', label: 'Valid Social Media Profiles', type: 'chips' },
            { value: 'mobile_apps', label: 'Valid Mobile Apps', type: 'chips' },
          ]}
          defaults={fromJS(data)}
          filters={fromJS(data)}
          />
        {errors &&
          <ul>
            {errors.map(v => (<li key={v} className={classes.error}>{v}</li>))}
          </ul>
        }
      </Grid>
      <Grid container justifyContent="flex-end" spacing={1} className={classes.footer}>
        <Grid item>
          <Button
            onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            color="secondary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

ClientSetup.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ClientSetup.defaultProps = {
  onCancel: null,
  onSubmit: null,
};

export default ClientSetup;
