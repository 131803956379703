import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { List as list, Map as map, fromJS } from 'immutable';
import {
  makeStyles,
} from '@mui/styles';
import {
  CircularProgress,
  FormControlLabel,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Pagination,
  Popover,
  Select,
  Switch,
  TablePagination,
} from '@mui/material';

import Api from '../../utils/api';
import { SearchContext } from './Context';
import style from './pager.module.scss';
import SaveSearchIcon from '../utils/SaveSearchIcon';
import { SaveAlertIcon } from './Alerting/SaveAlertIcon';
import Text from '../../utils/text';
import Messages from '../../constants/Messages';
import History from '../../utils/history';
import Common from '../../utils/common';
import BulkEditButton from '../utils/BulkEditButton';
import SearchActions from '../../actions/searchActions';
import automateIcon from '../../../public/static/automate-icon.svg';

const useStyles = makeStyles({
  pager: {
    '& .page': {
      alignItems: 'center',
      display: 'flex',
      margin: '0 .5rem',
      '& .MuiInputBase-root': {
        margin: '0 .5rem',
      },
    },
    '& .controls': {
      '& .MuiIcon-root': {
        margin: '.5rem .5rem 0',
      },
    },
  },
});

const Pager = ({
  bulkActions,
  data,
  exporting,
  fields,
  filters,
  group,
  groups,
  limit,
  limits,
  paginated,
  prm,
  save,
  saved,
  selected,
  share,
  sort,
  sorts,
  translations,
  type,
  onFilter,
}) => {
  const classes = useStyles();
  const search = useContext(SearchContext);
  const [dialog, setDialog] = useState();
  const [loadingTotal, setLoadingTotal] = useState();
  const ExportStatus = {
    INACTIVE: '',
    ACTIVE: `Now processing ${data.get('total')?.toLocaleString()} rows for download`,
    ERROR: Messages.ExportTimeout,
  };

  const overflow = data.get('overflow') && !search.get('trueTotal');
  const page = Math.round((filters.get('skip', '0') || '0') / (filters.get('limit', '25') || '25')) + 1;
  const pages = data.get('total') < 10000
    ? Math.ceil(data.get('total') / (filters.get('limit', '25') || '25'))
    : Math.ceil(10000 / (filters.get('limit', '25') || '25'));

  const onGroup = (value) => {
    if (onFilter) return onFilter(filters.set('group', value).set('skip', 0));
    const { pathname, query } = History.getCurrentLocation();
    return History.push({
      pathname,
      query: { ...query, group: value, skip: '' },
    });
  };

  const onLimit = (value) => {
    if (onFilter) return onFilter(filters.set('limit', value).set('skip', 0));
    if (filters.get('limit') === value) return false;
    const { pathname, query } = History.getCurrentLocation();
    return History.push({
      pathname,
      query: { ...query, limit: value },
    });
  };

  const onPage = (value) => {
    const limitValue = filters.get('limit', '25') || '25';
    const skipValue = value === 1 || !value ? 0 : ((value - 1) * limitValue);
    if (onFilter) return onFilter(filters.set('skip', skipValue));
    const { pathname, query } = History.getCurrentLocation();
    return History.push({
      pathname,
      query: {
        ...query,
        skip: skipValue,
      },
    });
  };

  const onSort = (value) => {
    if (onFilter) return onFilter(filters.set('sort', value).set('skip', 0));
    const { pathname, query } = History.getCurrentLocation();
    return History.push({
      pathname,
      query: { ...query, sort: value },
    });
  };

  const onTranslate = (translate = 'en') => {
    const { pathname, query } = History.getCurrentLocation();
    return History.push({
      pathname,
      query: {
        ...query,
        query_i18n: translate,
      },
    });
  };

  const onExport = () => {
    SearchActions.set(['search', 'info'], fromJS({ message: ExportStatus.ACTIVE, timeout: 30000 }));
    SearchActions.set(['search', 'results', 'export', 'fields'], fields);
    SearchActions.search(type, null, true);
  };

  const onSearch = (value) => {
    const next = parseFloat(value);
    if (!Number.isNaN(next) && next > 0 && next <= pages) onPage(value);
    else SearchActions.set(['search', 'info', 'message'], Messages.InvalidPageNumber);
  };

  const onShare = (format, highlight = false) => {
    let value;
    switch (format) {
      case 'api':
        if (type === 'customer-credentials') {
          value = Common.Request.Translate.AsCurl(search.getIn(['results', type, 'request']), type);
          break;
        }
        value = Common.Request.Translate.AsCurl(data.get('requestBody'), type);
        break;
      case 'query':
        value = data.getIn(['requestBody', 'query']);
        break;
      default:
        value = Common.Request.Translate.AsLink(highlight);
        break;
    }
    navigator.clipboard.writeText(value);
    const id = new Date().toLocaleTimeString();
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard(id));
  };

  const loadTrueTotal = async (query) => {
    setLoadingTotal(true);
    const url = '/ui/v4/all/search';
    const req = query
      .set('size', 0)
      .set('track_total_hits', true)
      .toJS();
    await Api
      .post(url, req)
      .then((res) => {
        setLoadingTotal();
        SearchActions.set(['search', 'trueTotal'], res.data.hits.total);
      });
  };

  const selectedSave = () => (
    (saved
      .get('data') || list())
      .find(v => v.get('created') === +window.location.hash.split(':').pop())
  );

  return (
    <div className={cx([style.pager, classes.pager])}>
      {limit &&
      <>
        <TablePagination
          labelRowsPerPage="Rows"
          rowsPerPageOptions={limits.map(v => v.get('value')).toJS()}
          count={data.get('total')}
          rowsPerPage={+(filters.get('limit', 25) || 25)}
          page={page - 1}
          onPageChange={() => null}
          onRowsPerPageChange={event => onLimit(event.target.value)}
          labelDisplayedRows={({ from, to }) =>
            `${from?.toLocaleString()}-${to?.toLocaleString()} of
              ${overflow ? 'over' : ''} ${(search.get('trueTotal') || data.get('total'))?.toLocaleString()}`} />
        {overflow &&
        <>
          {loadingTotal && <CircularProgress />}
          {!loadingTotal &&
          <Icon
            data-tip="Click to retrieve total count"
            data-for="global.tooltip"
            onClick={() => loadTrueTotal(data.get('requestBody'))}>
            help
          </Icon>}
        </>}
      </>}
      {data.has('type') && (data.get('type') !== 'customer-credentials') && (
      <div className="page">
        Page
        <Select
          value={pages ? page : ''}
          placeholder="Page"
          onChange={event => onPage(event.target.value)}>
          {Array.from(Array(pages), (_, v) => v + 1).map(v => (
            <ListItem
              key={v}
              value={v}
              className={v === page ? 'active' : ''}>
              {v}
            </ListItem>
          ))}
        </Select>
      </div>)}
      {save && selectedSave() &&
      <div className={style.selected}>
        for <b>{`"${selectedSave().get('name')}"`}</b>
      </div>}
      {paginated && data.get('total') > 0 &&
      <div className={style.pagination}>
        <Pagination
          showFirstButton={!['all'].includes(type)}
          showLastButton={!['all'].includes(type)}
          page={page}
          count={pages}
          siblingCount={2}
          onChange={(event, value) => onSearch(value)} />
      </div>}
      <div className={cx([style.controls, 'controls'])}>
        {sort && data.get('total') > 0 &&
        <div className={cx([style.sort, filters.has('sort') && style.active])}>
          <Icon
            data-for="pager.tooltip"
            data-tip="Adjust sort order of search results"
            onClick={event => setDialog({ key: 'pager.sort', target: event.target })}>
            sort
          </Icon>
          <Popover
            anchorEl={dialog && dialog.target}
            open={Boolean(dialog && dialog.key === 'pager.sort')}
            onClick={() => setDialog()}
            onClose={() => setDialog()}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
            {sorts.map((v, k) => (
              <ListItem
                key={v.get('label')}
                onClick={() => onSort(v.get('value'))}>
                <ListItemIcon>
                  {((!filters.get('sort') && k === 0) || (filters.get('sort') === v.get('value')))
                    ? <Icon>check</Icon>
                    : ''}
                </ListItemIcon>
                <ListItemText>
                  {v.get('label')}
                </ListItemText>
              </ListItem>))}
          </Popover>
        </div>}
        {group &&
        <div className={cx([style.group, filters.has('group') && style.active])}>
          <Icon
            data-for="pager.tooltip"
            data-tip="Adjust grouping of results"
            onClick={event => setDialog({ key: 'pager.group', target: event.target })}>
            dashboard
          </Icon>
          <Popover
            anchorEl={dialog && dialog.target}
            open={Boolean(dialog && dialog.key === 'pager.group')}
            onClick={() => setDialog()}
            onClose={() => setDialog()}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
            {groups.map(v => (
              <ListItem
                key={v.get('label')}
                onClick={() => onGroup(v.get('value'))}>
                <ListItemIcon>
                  <Icon>{filters.get('group') === v.get('value') ? 'check' : ''}</Icon>
                </ListItemIcon>
                <ListItemText>
                  {v.get('label')}
                </ListItemText>
              </ListItem>))}
          </Popover>
        </div>}
        {exporting && data.get('total') > 0 &&
        <div className={style.export}>
          {data.get('export') === ExportStatus.ACTIVE
            ? <CircularProgress className={style.loading} />
            : (
              <Icon
                data-for="pager.tooltip"
                data-tip="Export up to 10,000 records to CSV <br>Excel limits 32k characters per cell<br>Exports with more than 1,000 records may take longer than expected"
                data-testid="pager.actions-download"
                onClick={() => {
                  onExport();
                  if (search.get('exportAdditionalData')) SearchActions.set(['search', 'exportAdditionalData'], false);
                }}>file_download
              </Icon>)}
        </div>}
        {share &&
        <div className={style.share}>
          <Icon
            data-for="pager.tooltip"
            data-tip="Generate share link"
            onClick={event => setDialog({ key: 'pager.share', target: event.target })}>
            share
          </Icon>
          <Popover
            anchorEl={dialog && dialog.target}
            open={Boolean(dialog && dialog.key === 'pager.share')}
            onClick={() => setDialog()}
            onClose={() => setDialog()}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
            {[{ value: 'link', label: 'Platform Link' },
              !['customer-credentials'].includes(type) ? { value: 'link-highlight', label: 'Platform Link with <x-fp-highlight>Highlighting</x-fp-highlight>' } : null,
              ...prm.some(v => /acc.flow|org.fp.r/.test(v)) && !['customer-credentials', 'reports'].includes(type)
                ? [{ value: 'query', label: 'Query Only - ', icon: (
                  <Icon className={cx([style.icon, style.flow])}>
                    <img src={automateIcon} alt="Automate" />
                  </Icon>
                ) }]
                : [],
              !['all'].includes(type) ? { value: 'api', label: 'API Request' } : null]
              .filter(v => v)
              .map(v => (
                <ListItem
                  key={v.value}
                  onClick={() => onShare(v.value, /highlight/ig.test(v.value))}>
                  {Text.Highlight(v.label)}
                  {v.icon && v.icon}
                </ListItem>))}
          </Popover>
        </div>}
        {save &&
        <SaveSearchIcon
          filters={filters}
          saved={saved}
          type={type} />}
        {save &&
          ![
            'accounts',
            'cards',
            'credentials',
            'cves',
            'customer-credentials',
            'exploits',
            'iocs',
            'reports',
            'twitter',
            'media',
            'images',
            'videos',
          ].includes(type) && <SaveAlertIcon filters={filters} data={data} />}
        {!bulkActions.isEmpty() && !selected.isEmpty() &&
        <div className={style.bulk}>
          <BulkEditButton
            actions={bulkActions}
            selected={selected} />
        </div>}
      </div>
      <div className={cx([style.controls, style.right])}>
        {exporting && data.has('export') && data.get('export') &&
        <div className={style.text}>
          {data.get('export')}
        </div>}
        {translations && data.get('total') > 0 &&
        <div className={style.translate}>
          <FormControlLabel
            label="Display translated results"
            control={
              <Switch
                color="secondary"
                checked={Boolean(filters.get('query_i18n'))}
                onChange={() => onTranslate(filters.get('query_i18n') ? '' : 'en')} />}
          />
          <Icon
            data-for="pager.tooltip"
            data-tip="For English search queries, automatically display matching results across all languages.">
            info
          </Icon>
        </div>}
      </div>
      <ReactTooltip id="pager.tooltip" html place="bottom" effect="solid" />
    </div>
  );
};

Pager.propTypes = {
  bulkActions: PropTypes.object,
  data: PropTypes.object,
  exporting: PropTypes.bool,
  fields: PropTypes.array,
  filters: PropTypes.object,
  group: PropTypes.bool,
  groups: PropTypes.object,
  limit: PropTypes.bool,
  limits: PropTypes.object,
  paginated: PropTypes.bool,
  prm: PropTypes.object,
  save: PropTypes.bool,
  saved: PropTypes.object,
  selected: PropTypes.object,
  share: PropTypes.bool,
  sort: PropTypes.bool,
  sorts: PropTypes.object,
  translations: PropTypes.bool,
  type: PropTypes.string,
  onFilter: PropTypes.func,
};

Pager.defaultProps = {
  bulkActions: list(),
  data: map(),
  exporting: false,
  fields: [],
  filters: map(),
  group: false,
  groups: list(),
  limit: false,
  limits: list(),
  paginated: false,
  prm: list(),
  save: false,
  saved: map(),
  selected: list(),
  share: false,
  sort: false,
  translations: false,
  sorts: list(),
  type: '',
  onFilter: null,
};

export default Pager;
