import _ from 'lodash';
import moment from 'moment';

import { cachedGet, cachedPost } from '../../../utils/api';
import MISP from '../../../constants/MISP';

const searchUrl = '/ui/v4/all/search';

const Breaches = (malware = '', skip) => {
  const _source_includes = [
    'created_at.date-time',
    'fpid',
    'title',
    'total_records',
    'unique_records',
  ];
  const query = {
    query: `+basetypes:(breach) +(${malware})`,
    fields: ['title'],
    _source_includes,
    highlight: false,
    size: 10,
    from: skip,
    sort: ['created_at.date-time:desc'],
    traditional_query: true,
  };

  return cachedPost(searchUrl, query)
    .then(res => (res.ok ? res.data : null))
    .then(res => ({
      total: res.hits.total,
      data: res.hits.hits.map(v => v._source),
      skip: skip || 0,
    }));
};

const Events = async (tags = [], info = '') => {
  const query = {
    q: `basetypes:misp
    AND Event.Tag.name.keyword:(${tags.map(v => `"${v}"`)})
    ${info ? `Event.info:"${info}"` : ''}`,
    aggregations: {
      'multiple-terms': {
        tags: {
          terms: {
            field: 'Event.Tag.name.keyword',
            size: 50,
          },
        },
        dates: {
          terms: {
            field: 'Event.date',
            size: 10000,
            order: { _key: 'asc' },
          },
        },
      },
    },
    size: 0,
    highlight: false,
  };

  return cachedPost(searchUrl, query)
    .then(res => (res.ok ? res.data : null))
    .then(res => ({
      dates: res.aggregations.dates.buckets
        .filter(v => v.key)
        .map(v => ({ date: moment.utc(v.key).format('YYYY-MM-DD'), count: v.doc_count })),
      tags: res.aggregations.tags.buckets
        .filter(v => v.key),
      total: res.hits.total,
    }));
};

const AttackIDs = async (tags = [], title = '') => {
  const query = {
    q: `basetypes:misp
    AND Event.Tag.name.keyword:(${tags.map(v => `"${v}"`)})
    AND attack_ids:(T*)
    ${title ? `Event.info:"${title}"` : ''}`,
    _source_includes: ['attack_ids'],
    size: 10000,
    highlight: false,
  };

  return cachedPost(searchUrl, query)
    .then(res => (res.ok ? res.data : null))
    .then(res => ({
      results: {
        ..._
          .chain(MISP)
          .groupBy(v => v.phase)
          .mapValues(() => [])
          .value(),
        ..._
          .chain(res.hits.hits
            .map(v => v._source.attack_ids)
            .flat()
            .map(v => MISP.find(m => v === m.id)))
          .filter(v => v && v.phase)
          .uniqBy(v => v.id)
          .groupBy(v => v.phase)
          .value(),
      },
    }));
};

const Indicators = async (tags = [], info = '', skip = 0) => {
  const size = 10;
  const query = {
    q: `basetypes:indicator_attribute
      AND Event.Tag.name.keyword:(${tags.map(v => `"${v}"`)})
      ${info ? `AND Event.info:"${info}"` : ''}`,
    aggregations: {
      terms: {
        field: 'type',
        size: 5,
      },
    },
    sort: ['Event.date:desc'],
    size,
    from: skip,
    highlight: false,
  };

  return cachedPost(searchUrl, query)
    .then(res => (res.ok ? res.data : null))
    .then(res => ({
      skip,
      total: res.hits.total,
      results: res.hits.hits.map(v => v._source),
      top: res.aggregations.terms.buckets,
    }));
};

const Mentions = (queries = []) => {
  // These basetypes and fields were copied over from what is present
  // in the query object of an All Communities search. Worth looking into
  // a way to configure these basetypes and fields without hardcoding
  const communityBasetypes = [
    'blog',
    'chan',
    'chat',
    '(forum AND post)',
    'paste',
  ];
  const communityFields = [
    'body.text/html+sanitized',
    'body.text/plain',
    'native_id',
    'site_actor.names.*',
    'title',
    'container.title',
    'container.container.title',
    'site.source_uri',
    'site.title',
  ];
  const query = {
    query: `+basetypes:(${communityBasetypes.join(' OR ')}) +(${queries.map(v => v.query).join(' | ')})`,
    fields: communityFields,
    highlight: false,
    size: 1,
    sort: ['sort_date:desc'],
    traditional_query: true,
    aggregations: {
      terms: {
        field: 'site.title.keyword',
        size: 5,
      },
    },
  };

  return cachedPost(searchUrl, query)
    .then(res => (res.ok ? res.data : null))
    .then((res) => {
      if (!res?.hits?.total || res?.hits?.total === 0) {
        return {};
      }
      return {
        communities: res.aggregations.terms.buckets,
        last_seen: res?.hits?.hits[0]?._source?.sort_date || '',
        total: res.hits.total,
        results: res.hits.hits,
      };
    });
};

const Reports = (queries = []) => {
  const url = '/ui/v4/reports';
  const query = {
    query: queries.map(v => v.query).join(' OR '),
    sort: 'version_posted_at:desc',
  };
  return cachedGet(url, query)
    .then(res => (res.ok ? res.data : null));
};

export default {
  Breaches,
  AttackIDs,
  Indicators,
  Events,
  Mentions,
  Reports,
};
