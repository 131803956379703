import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Cell, Legend, PieChart, ResponsiveContainer, Pie as PieUI, Tooltip } from 'recharts';

import style from './pie.module.scss';
import CWEs from '../../../constants/CWEs';
import MISP from '../../../constants/MISP';
import Text from '../../../utils/text';

const Pie = ({
  aid,
  color,
  compact,
  labels,
  legend,
  limit,
  margin,
  offset,
  size,
  styles,
  titles,
  values,
  results,
  onClick,
}) => {
  if (!results.get('data')) return null;
  const resultsToRender = results
    .get('data')
    .slice(offset, offset + limit);
  return (
    <div
      className={cx([
          style.pie,
          styles && styles.transparent && style.transparent,
          compact && style.compact && !legend && style.mini])}
      style={{ ...styles }}>
      {titles.get(0) && legend &&
        <div className={cx([style.cap, 'cap'])}>
          {Text.Sentence(
            (typeof titles.get(0) === 'string' && titles.get(0)) ||
            results.getIn(titles.getIn([0, 'key']).split('.')) ||
            '-')}
        </div>}
      <div className={cx([style.chart, compact && style.compact])}>
        {!results.isEmpty() &&
          <ResponsiveContainer
            width={size.width || '100%'}
            height={size.height || 225}>
            <PieChart margin={margin} data-testid="widgets.pie">
              <PieUI
                isAnimationActive={false}
                innerRadius={styles && styles.donut ? 35 : 0}
                outerRadius={size.outerRadius}
                dataKey={values.getIn([0, 'key'])}
                nameKey={labels.getIn([0, 'key'])}
                data={resultsToRender
                  .toJS()
                  .map(v => ({
                    ...v,
                    [labels.getIn([0, 'key'])]: ((!labels.hasIn([0, 'render']))
                      ? Text.Sentence(v[labels.getIn([0, 'key'])])
                      : labels.getIn([0, 'render'])(v[labels.getIn([0, 'key'])])) }))}>
                {resultsToRender
                  .map((v, k) => (
                    <Cell
                      onClick={() => (onClick ? onClick(v, results) : null)}
                      key={`cell-${v.getIn(labels.getIn([0, 'key']))}`}
                      fill={Text.Color(k, aid, false, color)} />))}
              </PieUI>
              <Tooltip content={({ active, payload }) => active && (
                <div className={style.tooltip}>
                  <div>{Text.Sentence(payload[0].name)}</div>
                  <div>{`Total: ${Text.AbbreviateNumber(payload[0].value)}`}</div>
                  {payload[0].payload.cves &&
                  <div>{`CVEs: ${Text.AbbreviateNumber(payload[0].payload.cves.split(',').length)}`}</div>}
                  {payload[0].payload.cves &&
                  <div>{CWEs[+payload[0].name.split('-').splice(-1).shift()] || ''}</div>}
                  {payload[0].payload.attack_id &&
                  <div>{MISP.find(v => v.id === payload[0].name).name || ''}</div>}
                </div>)} />
              {legend &&
              <Legend
                wrapperStyle={{ ...legend.wrapperStyle, maxWidth: '100%' }}
                align={legend.align || 'center'}
                layout={legend.layout || 'vertical'}
                iconType={legend.iconType || 'square'}
                verticalAlign={legend.verticalAlign || 'bottom'}
                payload={[...new Array(limit < 0 ? 5 : limit).keys()]
                  .map(v => resultsToRender.toJS()[Number(v)] || ({ [labels.getIn([0, 'key'])]: '' }))
                  .filter(v => v[labels.getIn([0, 'key'])])
                  .map((v, k) => ({
                    ...v,
                    index: k,
                    type: 'circle',
                    color: v[labels.getIn([0, 'key'])]
                      ? Text.Color(k, aid, false, color)
                      : '#FFF',
                    value: (
                      <span
                        role="presentation"
                        onClick={() => (onClick ? onClick(fromJS(v), results) : null)}
                        data-for="legend.tooltip"
                        data-tip={`
                          <div className={style.tooltip}>
                            <div>${Text.Sentence(v[labels.getIn([0, 'key'])])}</div>
                            <div>${(MISP.find(m => m.id === (v[labels.getIn([0, 'key'])])) || {}).name || ''}</div>
                            <div>${CWEs[(v[labels.getIn([0, 'key'])] || '')
                              .toString()
                              .split('-')
                              .splice(-1)
                              .shift()] || ''}</div>
                          </div>`}>
                        {Text.Sentence(v[labels.getIn([0, 'key'])])}
                      </span>
                    ),
                    }))
                  } />}
            </PieChart>
          </ResponsiveContainer>}
      </div>
      {titles.get(0) && !legend &&
        <div className={cx([style.cap, 'cap'])}>
          {Text.Sentence(
            (typeof titles.get(0) === 'string' && titles.get(0)) ||
            results.getIn(titles.getIn([0, 'key']).split('.')) ||
            '-')}
        </div>}
      <ReactTooltip html id="legend.tooltip" place="left" effect="solid" />
    </div>
  );
};

Pie.propTypes = {
  aid: PropTypes.string,
  color: PropTypes.string,
  compact: PropTypes.bool,
  labels: PropTypes.object,
  legend: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  limit: PropTypes.number,
  margin: PropTypes.object,
  offset: PropTypes.number,
  results: PropTypes.object,
  size: PropTypes.object,
  styles: PropTypes.object,
  titles: PropTypes.object,
  values: PropTypes.object,
  onClick: PropTypes.func,
};

Pie.defaultProps = {
  aid: '',
  color: '',
  compact: false,
  labels: list(),
  legend: {},
  limit: 5,
  margin: {},
  offset: 0,
  results: map(),
  size: {},
  styles: {},
  titles: list(),
  values: list(),
  onClick: null,
};

export default Pie;
