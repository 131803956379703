import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecoilState, useRecoilValue } from 'recoil';

import { isEmpty } from 'lodash';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';
import { Button, Grid, Link, Typography } from '@mui/material';
import { PostAddOutlined, PersonSearch, Send } from '@mui/icons-material';

import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PersonSearchState from '../../../stores/recoil/personSearch';

const useStyles = makeStyles(() => ({
  button: {
    padding: '7.5px 28px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'none',
  },
  error: {
    color: red[500],
    alignSelf: 'center',
  },
  grid: {
    margin: '0 auto',
    maxWidth: '800px',
    width: '100%',
  },
  header: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '4rem',
  },
  headerStep: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    background: '#fff',
    marginTop: '1.5rem',
    minHeight: '90vh',
    padding: '4rem',
  },
}));

const PersonSearchForm = ({
  handleSubmit,
}) => {
  const classes = useStyles();

  const [dueDate, setDueDate] = useRecoilState(PersonSearchState.dueDate);
  const ageRange = useRecoilValue(PersonSearchState.ageRange);
  const email = useRecoilValue(PersonSearchState.email);
  const formData = useRecoilValue(PersonSearchState.formData);
  const legalOptIn = useRecoilValue(PersonSearchState.legalOptIn);
  const name = useRecoilValue(PersonSearchState.name);
  const phone = useRecoilValue(PersonSearchState.phone);
  const username = useRecoilValue(PersonSearchState.username);

  const [page, setPage] = useState(0);
  const [pageValidationError, setPageValidationError] = useState(false);
  const [textFieldValidationError, setTextFieldValidationError] = useState(false);

  const getDefaultDueDateTime = () => {
    const dateTime = new Date();
    dateTime.setHours(dateTime.getHours() + 1);
    return dateTime.toISOString();
  };

  const validatePage = () => {
    let validated = false;
    const hasRequiredLocationFields = () => {
      let hasRequired = false;
      formData.location.forEach((v) => {
        if (v.country === 'US' && v.city && v.state) {
          hasRequired = true;
        } else if (v.country && v.country !== 'US' && v.city) {
          hasRequired = true;
        } else {
          hasRequired = false;
        }
      });
      return hasRequired;
    };

    if (!isEmpty(email) || !isEmpty(phone) || !isEmpty(username)) validated = true;
    if (!isEmpty(name) && !isEmpty(formData.location)) validated = true;
    if (!isEmpty(formData.location) && !hasRequiredLocationFields()) validated = false;
    if (!isEmpty(ageRange) && (!ageRange.from || !ageRange.to)) validated = false;
    return validated;
  };

  const validationError = () => (
    <Grid item>
      <Typography display="inline" className={classes.error} variant="caption">You are missing a required field. Use any of the combinations above or visit the </Typography>
      <Typography display="inline">
        <Link
          href="https://docs.flashpoint.io/flashpoint/docs/person-search-input-form-best-practices"
          style={{ color: red[500] }}
          target="_blank"
          variant="caption"
          underline="none">Person Search Best Practices Guide
        </Link>
      </Typography>
      <Typography className={classes.error} display="inline" variant="caption"> for more information.</Typography>
    </Grid>
  );

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    if (validatePage()) {
      setPageValidationError(false);
      setPage(pageNumber);
    } else {
      setPageValidationError(true);
    }
  };

  const determinePage = () => {
    switch (page) {
      case 0:
        return (<PageOne
          dueDate={dueDate}
          pageValidationError={pageValidationError}
          setDueDate={setDueDate}
          setTextFieldValidationError={setTextFieldValidationError}
          validatePage={validatePage}
          validationError={validationError} />);
      case 1:
        return (<PageTwo
          pageValidationError={pageValidationError}
          setTextFieldValidationError={setTextFieldValidationError}
          validationError={validationError} />);
      case 2:
        return (<PageThree />);
      default:
        return (<PageOne
          handlePageChange={handlePageChange}
          pageValidationError={pageValidationError}
          setTextFieldValidationError={setTextFieldValidationError}
          validationError={validationError} />);
    }
  };
  useEffect(() => {
    if (isEmpty(dueDate)) setDueDate(getDefaultDueDateTime());
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid className={classes.grid}>
        <Grid className={classes.header} item xs={12}>
          <Grid className={classes.headerStep}>
            <Grid style={{ paddingRight: '0.5rem' }}>
              <PersonSearch style={{ color: page === 0 ? '#5c6ae0' : '#808285' }}/>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">STEP 1</Typography>
              <Typography style={{ color: page === 0 ? '#5c6ae0' : '#808285', marginBottom: '0.5rem' }} variant="subtitle2">Basic Information</Typography>
            </Grid>
          </Grid>
          <Grid className={classes.headerStep}>
            <Grid style={{ paddingRight: '0.5rem' }}>
              <PostAddOutlined style={{ color: page === 1 ? '#5c6ae0' : '#808285' }}/>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">STEP 2</Typography>
              <Typography style={{ color: page === 1 ? '#5c6ae0' : '#808285', marginBottom: '0.5rem' }} variant="subtitle2">Additional Information</Typography>
            </Grid>
          </Grid>
          <Grid className={classes.headerStep}>
            <Grid style={{ paddingRight: '0.5rem' }}>
              <Send style={{ color: page === 2 ? '#5c6ae0' : '#808285' }}/>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">STEP 3</Typography>
              <Typography style={{ color: page === 2 ? '#5c6ae0' : '#808285', marginBottom: '0.5rem' }} variant="subtitle2">Acknowledge & Sign</Typography>
            </Grid>
          </Grid>
        </Grid>
        {determinePage()}
        <Grid align={page === 2 ? 'center' : 'right'} style={{ marginTop: page === 2 ? '8rem' : '4rem' }}>
          {page !== 0 &&
            <Button
              color="secondary"
              className={classes.button}
              disabled={textFieldValidationError}
              onClick={e => handlePageChange(e, page - 1)}
              variant="text">Back
            </Button>}
          {page === 2 &&
            <Button
              color="secondary"
              className={classes.button}
              disabled={!legalOptIn}
              onClick={e => handleSubmit(e, formData)}
              variant="contained">Submit request
            </Button>}
          {page !== 2 &&
            <Button
              color="secondary"
              className={classes.button}
              disabled={textFieldValidationError}
              onClick={e => handlePageChange(e, page + 1)}
              variant="contained">Next
            </Button>}
        </Grid>
      </Grid>
    </Grid>
  );
};

PersonSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default PersonSearchForm;
