import * as React from 'react';

import easyMemo from '../../../../utils/Memoize';
import {
  TopFilterTableWidget,
  StoryBookRawBucketData,
  savedSearchName,
} from '../TopFilterTableWidget/TopFilterTableWidget';
import { SavedSearch } from '../../../../../core/types/SavedSearch';
import type { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';

export interface TopActorsTableWidgetProps {
  savedSearch: SavedSearch;
  settings: VoidFunction;
  storyBookRawBucketData?: StoryBookRawBucketData;
  baseFilters: ExpoDashFilters,
}

export const TopActorsTableWidget = easyMemo(({
  settings,
  savedSearch,
  storyBookRawBucketData,
  baseFilters,
}: TopActorsTableWidgetProps): JSX.Element => (
  <TopFilterTableWidget
    settings={settings}
    savedSearch={savedSearch}
    storyBookRawBucketData={storyBookRawBucketData}
    baseFilters={baseFilters}
    filterType="actor"
    title={`Top Actors for "${savedSearchName(savedSearch, baseFilters?.query)}"`}
    help="Trend information around the actors mentioning your saved searches most frequently."
  />
));
