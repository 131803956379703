import React, { useContext, useEffect, useRef, useState } from 'react';
import { fromJS, List as list } from 'immutable';
import _ from 'lodash';
import moment from 'moment';

import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Button, FormControl, InputLabel, Link, Popover, TextField, InputAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import Table from '../../../widget/Table/Table';
import {
  getAllBrands,
  getAllUrls,
  getSessionFilters,
  setSessionFilters,
} from './query';
import SearchActions from '../../../../actions/searchActions';
import SearchUtils from '../../../../utils/searchUtils';
import {
  SearchContext,
} from '../../../../components/utils/Context';
import DateFilter from '../../../filters/DateFilter';
import TextFilter from '../../../filters/TextFilter';
import Messages from '../../../../constants/Messages';
import ReportIncidentDialog from '../shared/ReportIncidentDialog';

import VirusTotal from '../../../../components/utils/Virustotal/virustotal';

const DefaultFilters = { date: 'Last 30 Days', brand: 'all', type: 'all' };

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer',
  },
  incidentDetails: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    '& li': {
      margin: '0',
      padding: '0',
    },
  },
  filterRoot: {
    marginBottom: '2.4rem',
    '& .MuiFormControl-root ': {
      marginRight: '1rem',
    },
  },
  filterLabel: {
    color: grey[500],
    fontSize: '1.4rem',
    marginRight: '2rem',
    textTransform: 'uppercase',
  },
  filterSearch: {
    width: '252px',
    '& label.Mui-focused': {
      color: '#5c6ae0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#5c6ae0',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#5c6ae0',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5c6ae0',
      },
    },
  },
  visitedLink: {
    color: '#5c6ae0',
  },
  titleCase: {
    textTransform: 'capitalize',
  },
});

const MonitoredUrlsDashboard = () => {
  const search = useContext(SearchContext);

  const dialogEl = useRef(null);
  const [urls, setUrls] = useState([]);
  const [skip, setSkip] = useState(0);
  const [filters, setFilters] = useState(DefaultFilters);
  const [dialog, setDialog] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [queryFilter, setQueryFilter] = useState('');
  const classes = useStyles();

  const filterByDate = (data) => {
    const filter = SearchUtils.parseDate({ date: filters.date });
    return data.filter(item => new Date(item.created_at) >= new Date(filter.sinceDate));
  };

  const filterByBrand = (data) => {
    if (filters.brand === 'all') return data;
    return data.filter(item => item.brand_id === filters.brand);
  };

  const filterByType = (data) => {
    if (filters.type === 'all') return data;
    return data.filter(item => item.type === filters.type);
  };

  const handleFilterChange = (changedFilters) => {
    const oldFilters = {
      ...filters,
    };
    const newFilters = _.merge(oldFilters, changedFilters);
    setFilters(newFilters);
  };

  const handleSearchFilterChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const handleSubmitSearchFilter = (e) => {
    e.preventDefault();
    if (searchFilter) setQueryFilter(searchFilter.toLowerCase());
  };

  useEffect(() => {
    const presetFilters = getSessionFilters();
    if (presetFilters) setFilters(presetFilters);
  }, []);

  useEffect(() => {
    setSessionFilters(filters);
    getAllUrls(queryFilter)
      .then((results) => {
        const dateFiltered = filters.date ? filterByDate(results) : results;
        const brandFiltered = filters.brand ? filterByBrand(dateFiltered) : dateFiltered;
        const filtered = filters.type ? filterByType(brandFiltered) : brandFiltered;
        return setUrls(filtered);
      })
      .catch((error) => {
        if (error?.message === 'canceled') return;
        SearchActions.set(['search', 'info', 'message'], Messages.IncidentUrlError);
        throw error;
      });
  }, [filters, queryFilter]);

  useEffect(() => {
    getAllBrands()
      .then(results => setBrands(results))
      .catch((error) => {
        if (error?.message === 'canceled') return;
        SearchActions.set(['search', 'info', 'message'], Messages.IncidentBrandError);
        throw error;
      });
  }, [filters]);

  const brandOptions = brands ? [{ label: 'All', value: 'all' }, ...brands.map(item => ({ label: item.name, value: item.id }))] : [{ label: 'All', value: 'all' }];

  const onNewIncident = (submitted) => {
    if (submitted) {
      SearchActions.set(['search', 'info', 'message'], Messages.IncidentCreation);
    }
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.filterRoot}>
        <Grid item>
          <Typography className={classes.filterLabel}>
            Filters:
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmitSearchFilter} style={{ paddingRight: '1rem' }}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchFilter}
              onChange={(e) => { handleSearchFilterChange(e); }}
              className={classes.filterSearch}
              placeholder="Enter keyword and press enter"
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end">
                    <CloseIcon
                      className={classes.closeIcon}
                      fontSize="small"
                      onClick={() => {
                        setQueryFilter('');
                        setSearchFilter('');
                      }} />
                  </InputAdornment>,
              }}
            />
          </form>
        </Grid>
        <Grid item style={{ margin: '0 1rem .25rem 0' }}>
          <FormControl
            variant="outlined"
            name="date"
            ref={dialogEl}
          >
            <InputLabel>Date Added</InputLabel>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDialog({
                target: dialogEl.current,
                key: 'date',
              })}
              endIcon={<ExpandMoreIcon />}
            >
              {filters.date}
            </Button>
            <Popover
              className="popover"
              anchorEl={dialog && dialog.target}
              open={Boolean(dialog?.key === 'date')}
              onClose={() => setDialog({})}
            >
              <DateFilter
                disableCustom
                date={filters.date}
                dates={search.get('dates', list())}
                toggle={() => setDialog({})}
                onFilter={value => setFilters({ ...filters, date: value.get('date') })}
              />
            </Popover>
          </FormControl>
        </Grid>
        <TextFilter
          fields={[
            { type: 'dropdown', value: 'brand', label: 'Brand', key: 'value', opts: fromJS(brandOptions) },
            {
              type: 'dropdown', value: 'type', label: 'Types', opts: fromJS([
                { label: 'All', value: 'all' },
                { label: 'Parked Domain', value: 'parked domain' },
                { label: 'Social Media', value: 'social media' },
                { label: 'Brand Abuse', value: 'brand abuse' },
              ]),
            },
          ]}
          filters={fromJS(filters)}
          onFilter={e => handleFilterChange(e)}
          stacked
        />
        <Button onClick={() => {
          setFilters(DefaultFilters);
          setQueryFilter('');
          setSearchFilter('');
        }}>Clear All
        </Button>
        <Grid item style={{ marginLeft: 'auto' }}>
          Have a malicious link that you don't see here? <Link href="#" onClick={() => setDialogOpen(true)} color="primary">Request a takedown</Link>
        </Grid>
      </Grid>
      <Table
        pagination
        topPagination
        leftPagination
        onOffset={v => setSkip(v)}
        results={fromJS({
          data: urls,
          total: urls.length,
          skip,
        })}
        values={fromJS([
          { key: 'type', label: 'Type', sort: true, render: v => (<span className={classes.titleCase}>{v}</span> || 'N/A') },
          { key: 'brand_name', label: 'Brand', sort: true, render: v => (v || 'N/A') },
          { key: 'url', label: 'URL', sort: true, tooltip: v => v, render: v => (v || 'N/A') },
          { key: 'ip', label: 'IP Address', sort: true, render: v => (v || 'N/A') },
          { key: 'created_at', label: 'Date Added', sort: true, render: v => (moment(v).format('M/D/Y h:mm A') || 'N/A') },
          { key: 'last_scanned', label: 'Last Scanned', sort: true, render: v => (moment(v).format('M/D/Y h:mm A') || 'N/A') },
          { key: 'notes', label: 'Notes', sort: true, render: v => (v || 'N/A') },
          { key: 'url', label: 'VirusTotal', sort: true, render: v => <VirusTotal tableData={v}/> },
        ])}
      />
      <ReportIncidentDialog
        onClose={() => setDialogOpen(false)}
        onSubmit={onNewIncident}
        open={dialogOpen}
        brands={brands} />
    </>
  );
};

export default MonitoredUrlsDashboard;
