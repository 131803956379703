import * as React from 'react';
import { fromJS } from 'immutable';
import { omit } from 'lodash';

import { InfoOutlined } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';

import Hero from '../../../../widget/Hero/Hero';
import { navigateFromExpoDash } from '../../utils';
import { TopSourceDates } from '../../../../../constants/Dates';

import type { SavedSearch } from '../../../../../core/types/SavedSearch';
import { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';

const customHeroStyles = {
  paddingTop: '12px !important',
};
const customHeroLinkStyles = {
  padding: '2px 0 0 0',
  lineHeight: '1.43',
};

const labelText = {
  alignItems: 'center',
  color: '#a4a4a5',
  display: 'flex',
  fontSize: '1.2rem',
  marginTop: '.5rem',
};

const navigateToCommunity = (filters: object, extraFilter: object) => {
  navigateFromExpoDash({ filters: {
    ...omit(filters, 'size'),
    ...extraFilter,
  } });
};

const onClickText = ({ savedSearch, baseFilters, filterKey, queryParamVal }) => {
  if (savedSearch) {
    navigateFromExpoDash({ savedSearch, filters: {
      ...baseFilters,
      ...{ [filterKey]: queryParamVal },
    } });
    return;
  }
  navigateToCommunity(baseFilters, {
    [filterKey]: queryParamVal,
  });
};

const makeFiltersWithPrevDate = (baseFilters) => {
  const { date } = baseFilters;
  const { previous } = TopSourceDates[String(date.toLowerCase())];
  const { since, until } = previous;
  return { ...baseFilters, ...{ date: null, since, until } };
};

export type topFilterStyles = {
  topSourceFlexGrow: string,
  sourceText: string,
};

interface TopFilterWidgetProps {
  baseFilters: ExpoDashFilters;
  queryString: string;
  savedSearch: SavedSearch;
  topFilterData: {
    previous: string;
    now: string;
    previousRaw: string;
  };
  styles: topFilterStyles;
  filterKey: string;
  humanReadableFilterName: string;
  tooltip: string;
}

export const TopFilterWidget = ({
  baseFilters,
  queryString,
  savedSearch,
  filterKey,
  humanReadableFilterName,
  topFilterData,
  styles,
  tooltip,
}: TopFilterWidgetProps): JSX.Element => (
  <div className={styles.topSourceFlexGrow}>
    <div className={styles.sourceText} style={{ ...labelText }}>
      {queryString || baseFilters?.query}
      <InfoOutlined
        style={{
          fontSize: '1.8rem',
          marginLeft: '.5rem',
        }}
        data-for="help.tooltip"
        data-tip={tooltip}
        />
    </div>
    <Hero
      text
      narrowStyle
      styles={customHeroStyles}
      linkStyles={customHeroLinkStyles}
      labels={fromJS([topFilterData?.previous || ' -- '])}
      values={fromJS([{ key: 'name' }])}
      results={fromJS({ name: topFilterData?.now || `No Top ${humanReadableFilterName}` })}
      useEllipsis
      onClick={() => {
        const queryParamVal = topFilterData?.now || '';
        onClickText({ savedSearch, baseFilters, filterKey, queryParamVal });
      }}
      onClickLabel={() => {
        const queryParamVal = topFilterData?.previousRaw || '';
        onClickText({
          savedSearch,
          baseFilters: makeFiltersWithPrevDate(baseFilters),
          filterKey,
          queryParamVal,
        });
      }}
              />
    <ReactTooltip id="help.tooltip" html place="right" effect="solid" className={styles.tooltip} />
  </div>
);
