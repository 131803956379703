import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LookerEmbedSDK } from '@looker/embed-sdk';

import makeStyles from '@mui/styles/makeStyles';

import { useLookerApi } from '../../../hooks/useLookerApi';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
}));

const Looker = ({
  setFilters,
  width,
  height,
  dashboard,
  filters,
  sid,
  setupDashboard,
  styles,
}) => {
  const classes = useStyles();

  const { iframeUrl } = useLookerApi({
    dashboard,
    filters,
    sid,
  });

  useEffect(() => {
    if (iframeUrl) {
      const { host } = new URL(iframeUrl);

      // Need to clear out existing iframe, otherwise it will keep adding iframes to #dashboard
      document.getElementById('dashboard').innerHTML = '';

      LookerEmbedSDK.init(host);
      LookerEmbedSDK.createExploreWithUrl(iframeUrl)
        .appendTo(document.getElementById('dashboard'))
        .withClassName('embeddedDashboard')
        // https://docs.looker.com/reference/embedding/embed-javascript-events
        .on('dashboard:filters:changed', (e) => {
          const { dashboard_filters } = e.dashboard;
          setFilters(dashboard_filters);
        })
        .build()
        // If you want to send messages to and receive messages from the embedded element,
        // you need to call connect(), which returns a Promise that resolves to the communication
        // interface of the given element:
        // https://github.com/looker-open-source/embed-sdk/blob/master/demo/demo.ts
        .connect()
        .then(setupDashboard)
        .catch(() => false);

      document.getElementsByClassName('embeddedDashboard')[0].style.height = height;
      document.getElementsByClassName('embeddedDashboard')[0].style.width = width;
    }
  }, [iframeUrl]);

  return (
    <div className={classes.container}>
      <div id="dashboard" style={{ ...styles }} />
    </div>
  );
};

Looker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  dashboard: PropTypes.string.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  setupDashboard: PropTypes.func,
  sid: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

Looker.defaultProps = {
  width: '100%',
  height: '100vh',
  filters: {},
  setFilters: () => false,
  setupDashboard: () => false,
  styles: {},
};

export default Looker;
