import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import moment from 'moment';
import { List as list, Map as map } from 'immutable';
import {
  Bar as B,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis } from 'recharts';

import style from './stackedbar.module.scss';
import Text from '../../../utils/text';
import Invalid from '../../utils/Invalid/Invalid';
import UserActions from '../../../actions/userActions';
import { UserContext } from '../../utils/Context';

const StackedBar = ({
  aid,
  color,
  colors,
  format,
  keyOrder,
  labels,
  labellist,
  labellistFmt,
  legend,
  results,
  scale,
  blur,
  limit,
  blurFunc,
  onClick,
  onCellClick,
  emptyMessage,
  styles,
}) => {
  if (!results.get('data')) return null;
  const [selected, setSelected] = useState([]);
  const user = useContext(UserContext);
  const data = results.get('data').toJS();

  const profiles = user.getIn(['prefs', 'filters', 'alerting']);
  const defaultProfile = profiles.find(v => v.get('default') === 'true' || v.get('default') === '');
  const activeProfile = !user.getIn(['tmp', 'alerting'])
    ? defaultProfile
    : profiles.find(v => v.get('name') === user.getIn(['tmp', 'alerting']).active);

  let keys = [...new Set(data
    .map(v => Object.keys(v).filter(k => !['key'].includes(k)))
    .reduce((a, b) => a.concat(b), []))];
  if (keyOrder.size > 0) {
    // Create a list of keys with order specified by keyOrder
    const orderedKeys = [];
    for (const ko of keyOrder) {
      if (keys.includes(ko)) orderedKeys.push(ko);
    }
    // Add any keys not specified in keyOrder to the back of the list
    const unorderedTail = keys.filter(v => !orderedKeys.includes(v));
    keys = orderedKeys.concat(unorderedTail);
  }

  const filteredHitsBySource = activeProfile.get('hitsBySource') ? activeProfile.get('hitsBySource').split(',') : keys;

  const empty = data && !data.length;
  const timeout = results.get('timeout', false);
  const renderCustomizedLabel = (bar, fill) => {
    const { x, y, value, width, height } = bar;
    const padding = 10;
    const formattedValue = (labellistFmt
      // eslint-disable-next-line security/detect-non-literal-regexp
      ? value.replace(new RegExp(labellistFmt, 'ig'), '').trim()
      : value.trim());
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = '14px "Open Sans"';
    const textWidth = ctx.measureText(formattedValue).width;
    const textHeight = ctx.measureText('M').width; // capital M is a close representation of line height at given font
    // eslint-disable-next-line security/detect-non-literal-regexp
    const rx = new RegExp(`.{1,${Math.floor(formattedValue.length / Math.ceil(textWidth / width))}}`, 'g');
    const chunks = textWidth < width ? [] : formattedValue
      .replace(/-/g, ' ')
      .split(' ')
      .map(s => s.match(rx))
      .filter(v => v)
      .flat();
    const tspans = chunks.length
      ? chunks.map((s, i) => (
        <tspan x={0} y={textHeight} dy={(i * textHeight)} key={s}>
          {blurFunc ? blurFunc(s, blur) : s}
        </tspan>
      ))
      : [
        <tspan x={0} y={textHeight} dy={0} key={formattedValue}>
          {blurFunc ? blurFunc(formattedValue, blur) : formattedValue}
        </tspan>,
      ];

    const chunkedHeight = (chunks.length || 1) * textHeight;
    const outOfBar = chunkedHeight + (padding * 2) >= height;
    const translation = `${x + (width / 2)},${outOfBar
      ? y - (chunkedHeight + padding)
      : y + padding}`;

    return (
      <g
        transform={`translate(${translation})`}>
        <text width={width} height="auto" textAnchor="middle" fontSize={12} style={{ fill: (!outOfBar) ? fill : '#000' }}>
          {!tspans.length ? `${blurFunc ? blurFunc(formattedValue, blur) : formattedValue}` : tspans}
        </text>
      </g>
    );
  };

  const updateHitsBySource = (source) => {
    setSelected(source);
    const index = activeProfile.get('default') === 'true' ? 0 : profiles.findIndex(v => v.get('name') === activeProfile.get('name'));
    UserActions.set(['user', 'prefs', 'filters', 'alerting', index, 'hitsBySource'], source.join(','));
    UserActions.savePrefs();
  };

  const handleSourceClick = (entry, e) => {
    e.stopPropagation();
    if (keys.length === 1) return;
    const updated = selected.includes(entry.value)
      ? selected.filter(v => v !== entry.value)
      : selected.concat(entry.value);
    updateHitsBySource(updated);
  };

  useEffect(() => {
    if (keys.length === 1) {
      setSelected(keys[0]);
    } else {
      setSelected(filteredHitsBySource);
    }
  }, [activeProfile.get('name')]);

  return (
    <div style={{ width: '100%', height: '100%', ...styles }}>
      {empty &&
      <Invalid
        icon="error_outline"
        title={timeout ? 'Search timed out' : 'No Results'}
        subtitle={timeout ? 'Please try again' : emptyMessage || 'Try selecting a different record'} />}
      {!empty &&
      <ResponsiveContainer
        className={cx([
          style.stackedbar,
          styles && styles.transparent && style.transparent,
        ])}>
        <BarChart
          margin={{ left: -15, bottom: 20 }}
          className={style.chart}
          data={data.slice(0, limit)}
          onClick={(e) => {
            if (!onClick) return;
            const key = e.activePayload[0].payload;
            onClick((results.get('query') || map()).toJS(), key, e);
          }}>
          <CartesianGrid stroke="#fcfcfc" vertical />
          {!labellist &&
          <XAxis
            dataKey={labels.getIn([0, 'key'])}
            minTickGap={10}
            tickFormatter={v => (moment.utc(v).isValid()
              ? moment.utc(v).format(format)
              : v)} />}
          <YAxis
            label={false}
            scale={scale}
            tickFormatter={v => Text.AbbreviateNumber(v)} />
          <Tooltip
            cursor={{ fill: '#f7f5f5' }}
            content={({ active, payload }) => active && payload &&
            !!payload.filter(v => v.value).length && (
            <div className={style.tooltip}>
              {payload
                .filter(v => v.value)
                .sort((a, b) => b.value - a.value)
                .map(v => (
                  <div key={v.dataKey}>
                    <div style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: colors.isEmpty()
                        ? Text.Color(keys.indexOf(v.dataKey), aid, false, color)
                        : colors.get(v.dataKey) }} />
                    <div style={{ display: 'inline-block', padding: '0 0 0 5px' }}>
                      {`${blurFunc
                        ? blurFunc(Text.Sentence(v.dataKey), blur)
                        : Text.Sentence(v.dataKey)} - ${Text.AbbreviateNumber(v.value)}`}
                    </div>
                  </div>))}
            </div>)} />
          {legend &&
          <Legend
            align="right"
            layout="vertical"
            verticalAlign="top"
            onClick={(entry, k, e) => handleSourceClick(entry, e)}
            formatter={value => (
              <span
                data-testid={`stackedbar.legend-${value?.toLowerCase()?.replace(/\s/, '-')}`}
                className={cx([
                  style.entry,
                  selected.length && !selected.includes(value) && style.disabled,
                ])}>
                {value}
              </span>
            )}
            payload={keys
              .map(v => ({
                type: 'square',
                color: colors.isEmpty()
                  ? Text.Color(keys.indexOf(v), aid, false, color)
                  : colors.get(v),
                value: v,
              }))} />}
          {keys
            .filter(v => !selected.length || selected.includes(v))
            .map(v => (
              <B
                isAnimationActive={false}
                key={v}
                name="mentions"
                stackId="x"
                dataKey={v}
                onClick={payload => (onCellClick
                    ? onCellClick(payload.payload[String(v)], v, payload)
                    : null)}
                fill={colors.isEmpty()
                  ? Text.Color(keys.indexOf(v), aid, false, color)
                  : colors.get(v)}>
                {labellist &&
                <LabelList
                  dataKey="key"
                  position="top"
                  content={bar => renderCustomizedLabel(bar, colors.isEmpty() ? Text.Color(0, aid, true, color) : colors.get(''))} />}
              </B>))}
        </BarChart>
      </ResponsiveContainer>}
    </div>
  );
};

StackedBar.propTypes = {
  aid: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.object,
  format: PropTypes.string,
  keyOrder: PropTypes.object,
  labels: PropTypes.object,
  labellist: PropTypes.bool,
  labellistFmt: PropTypes.string,
  legend: PropTypes.bool,
  results: PropTypes.object,
  scale: PropTypes.string,
  styles: PropTypes.object,
  onClick: PropTypes.func,
  onCellClick: PropTypes.func,
  blur: PropTypes.bool,
  blurFunc: PropTypes.func,
  limit: PropTypes.number,
  emptyMessage: PropTypes.string,
};

StackedBar.defaultProps = {
  aid: '',
  color: '',
  colors: map(),
  format: 'MM/DD/YYYY',
  keyOrder: list(),
  labels: list(),
  labellist: false,
  labellistFmt: '',
  legend: false,
  results: list(),
  scale: 'auto',
  styles: null,
  onClick: null,
  onCellClick: null,
  blur: false,
  blurFunc: null,
  limit: undefined, // if no limit, use full data array
  emptyMessage: null,
};

export default StackedBar;
