import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import style from './search.module.scss';
import Aggregations from '../../components/search/Aggregations';
import CustomerCredentials from '../../components/content/Credentials/CustomerCredentials';
import Filters from '../../components/filters/Filters/Filters';
import Table from '../../components/search/Table/Table';
import SearchSectionsSearch from '../../stores/recoil/searchSectionsSearch';
import Invalid from '../../components/utils/Invalid/Invalid';
import SearchActions from '../../actions/searchActions';
import UserActions from '../../actions/userActions';
import Apps from '../../constants/Apps';
import Messages from '../../constants/Messages';
import Text from '../../utils/text';
import History from '../../utils/history';
import SearchUtils from '../../utils/searchUtils';
import { SearchContext, UserContext } from '../../components/utils/Context';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Search = ({
  location,
  match: {
    params,
  },
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);

  const [status, setStatus] = useState();
  const [dialog, setDialog] = useState(null);
  const [filters, setFilters] = useRecoilState(SearchSectionsSearch.sectionsFilterState);
  const setType = useSetRecoilState(SearchSectionsSearch.sectionsTypeState);
  const selectedDataState = useRecoilValue(SearchSectionsSearch.selectedDataState);
  const prevState = usePrevious({ location, params, query: History.getCurrentLocation().query });

  const { query: urlQuery } = History.getCurrentLocation();

  const app = user.get('apps')
    .filter(v => v.get('search'))
    .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
    .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
    .filter(v => user.get('prm').some(p => v.get('test').test(p)))
    .find(v => params.type.split('.').includes(v.get('searchType')));

  const pageHistory = ({ type = '', id = '' }, pageFilters) => {
    if (!pageFilters) return;
    const title = `Search - ${Text.Sentence(app?.get('label', '') || params.type)}`;
    document.title = Text.StripHtml(Text.Strip(`Flashpoint - ${title}`));

    if (!type.includes('.') && pageFilters.get('query')) {
      const { search: browserSearch } = History.getCurrentLocation();
      const now = +moment.utc().unix();
      const saveQuery = fromJS({
        value: pageFilters.get('query'),
        filters: browserSearch.replace(/query=.*?(&|$)/gi, '') || '',
        type: Apps.find(v => v.value === `search.${type}`),
        title,
        created: now,
      });

      SearchActions.set(['search', 'viewed'], search.get('viewed', list()).push(id));
      UserActions.saveHistory('search', saveQuery);
    }
  };

  const sidebar = (hash, { type: basetype }) => {
    const splitHash = hash
      .split(':');
    const id = !hash.includes('::') ? splitHash[splitHash.length - 1] : `${splitHash.slice(-3)[0]}::${splitHash.slice(-3)[2]}`;
    const name = hash.split(':').shift();
    const ignore = ['#help', '#save'];
    if (!hash || ignore.includes(name)) return;
    SearchActions.search(basetype === 'all' ? name : basetype, id);
  };

  const table = (pathname) => {
    if (!/^(\/home\/search|\/home\/intelligence)/i.test(pathname)) return;
    const defaults = search.get('defaults').toJS();
    const filtersFromUrl = SearchUtils.loadFilters(params.type, {}, false, defaults)[1];
    setFilters(fromJS(filtersFromUrl));
    const previousSearch = search.getIn(['type']);
    SearchActions.search(
      params.type,
      undefined,
      undefined,
      undefined,
      { previous: previousSearch },
    );
    pageHistory(params, filtersFromUrl);
  };

  const route = (sectionType, allow) => {
    const { pathname, query } = History.getCurrentLocation();
    const canViewMedia = allow
      || user.getIn(['prefs', 'safe_search_toggle']) !== 'NONE'
      || sessionStorage.getItem('X-FP-Show-Media');
    let extra = {};
    switch (sectionType) {
      case 'image':
        extra = { media_type: 'Image', media: 'true', search: 'image' };
        break;
      case 'fluid':
        extra = { media_type: 'Image', media: 'true', search: 'fluid' };
        break;
      case 'video':
        extra = { media_type: 'Video', media: 'true', search: 'video' };
        break;
      default:
        extra = { media_type: undefined, media: undefined, search: undefined };
        break;
    }

    if (['analysis', 'credentials-search', 'domain'].includes(sectionType)) {
      const newPath = pathname.includes(params.category)
        ? pathname.replace(params.category, sectionType)
        : pathname.concat(`/${sectionType}`);
      History.replace({
        pathname: newPath,
      });
      return;
    }

    if (canViewMedia || sectionType === 'all') {
      History.replace({
        pathname,
        query: { ...query, ...extra, skip: 0 },
      });
      table(pathname);
    } else {
      // Show confirm dialog
      setDialog(sectionType);
    }
  };

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
    setType(app?.get('searchType') || params.type);
    const { query } = History.getCurrentLocation();
    const { hash, pathname } = location;

    // all search without all params; route to communities
    if (['all'].includes(params.type) && !query.all) {
      History.push({
        pathname: '/home/search/communities',
        query,
      });
    }

    if (query.search) {
      const canViewMedia = user.getIn(['prefs', 'safe_search_toggle']) !== 'NONE' || sessionStorage.getItem('X-FP-Show-Media');
      if (!canViewMedia) {
        route(query.search);
        return;
      }
    }
    table(pathname);
    sidebar(hash, params);
  }, []);

  useEffect(() => {
    for (const [key, value] of Object.entries(filters.toJS())) {
      switch (key) {
        case 'status': {
          setStatus({
            key: `${key}=${value}`,
            severity: 'warning',
            message: Messages.SearchOrgPattern,
          });
          break;
        }
        default: break;
      }
    }
  }, [filters]);

  useEffect(() => {
    if (!location || !prevState) return;
    setType(app?.get('searchType') || params.type);
    const { query } = History.getCurrentLocation();
    const { hash, pathname } = location;
    const previous = fromJS(prevState.query)
      .filter(v => v)
      .filterNot((v, k) => k === 'section');
    const current = fromJS(query)
      .filter(v => v)
      .filterNot((v, k) => k === 'section');

    if ((previous.get('customer_id') && !current.get('customer_id'))) {
      UserActions.set(['user', 'defaults', 'orgProfile'], user.get('sid'));
    } else if (params.type === 'credentials' && !query.customer_id
      && user.getIn(['defaults', 'orgProfile'])
      && user.getIn(['defaults', 'orgProfile']) !== query.customer_id
      && user.getIn(['defaults', 'orgProfile']) !== user.get('sid')) {
      History.push({
        pathname,
        query: {
          ...query,
          customer_id: user.getIn(['defaults', 'orgProfile']),
        },
      });
    }

    // sidebar hash changed
    const _hash = hash;
    if (_hash !== prevState.location.hash) sidebar(_hash, params);
    // search type has changed
    if (pathname !== prevState.location.pathname) table(pathname);
    // query parameters have changed
    else if (!previous.equals(current)) table(pathname);
    ReactTooltip.rebuild();
  }, [location]);

  return (
    <React.Fragment>
      <Grid fluid className={cx([style.search, style[params?.type]])}>
        {/^(\/home\/search|\/home\/intelligence)/i.test(location.pathname) && app &&
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={3} />
              {['chats'].includes(params.type) &&
              !['channels'].includes(search.getIn(['filters', 'group'])) &&
              <Col xs={9} className={style.sections}>{/* search sections */}
                <Button
                  className={cx([!urlQuery.search && style.active])}
                  onClick={() => route('all')}
                  startIcon={<Icon className={style.icon}>search</Icon>}>
                  All
                </Button>
                <Button
                  className={cx([urlQuery.search === 'image' && style.active])}
                  onClick={() => route('image')}
                  startIcon={<Icon className={style.icon}>image</Icon>}>
                  Images (3x2)
                </Button>
                <Button
                  className={cx([urlQuery.search === 'fluid' && style.active])}
                  onClick={() => route('fluid')}
                  startIcon={<Icon className={style.icon}>image</Icon>}>
                  Images (original)
                </Button>
                <Button
                  className={cx([urlQuery.search === 'video' && style.active])}
                  onClick={() => route('video')}
                  startIcon={<Icon className={style.icon}>video_library </Icon>}>
                  Videos
                </Button>
              </Col>}
              {['customer-credentials'].includes(params.type) &&
                <Col xs={9} className={style.sections}>
                  <Button
                    className={cx([(params.type === 'analysis' || !params.type) && style.active])}
                    onClick={() => route('analysis')}
                    startIcon={<Icon className={style.icon}>table_chart</Icon>}>
                    Analysis
                  </Button>
                  <Button
                    className={cx([params.type === 'domain' && style.active])}
                    onClick={() => route('domain')}
                    startIcon={<Icon className={style.icon}>web_asset</Icon>}>
                    Domain
                  </Button>
                  <Button
                    className={cx([params.type === 'credentials-search' && style.active])}
                    onClick={() => route('credentials-search')}
                    startIcon={<Icon className={style.icon}>search</Icon>}>
                    Search
                  </Button>
                </Col>}
            </Row>
            <Row>{/* Filters */}
              <Col xs={12} className={style.filters}>
                <Filters
                  advanced={false}
                  user={user}
                  store={search}
                  type={params.type}
                  defaults={search.getIn(['defaults', params.type], map())}
                  filters={filters}
                  setFilters={params.type === 'customer-credentials' ? null : setFilters}
                  blurCreds={user.getIn(['prefs', 'blur_credentials'])}
                  searches={user.getIn(['prefs', 'search'])} />
              </Col>
            </Row>
          </Col>
        </Row>}
        {/^(\/home\/search|\/home\/intelligence)/i.test(location.pathname) &&
        <Row>{/* Aggregations */}
          <Col xs={12} className={style.aggregations}>
            <Aggregations
              type={params.type}
              basetypes={search.getIn(['results', params.type, 'data', 0, 'basetypes'])}
              data={search.getIn(['charts', params.type, 'aggregations'])}
              filters={search.getIn(['charts', params.type, 'filters'])}
              prefs={user.get('prefs')} />
          </Col>
        </Row>}
        {false && /^(\/home\/search|\/home\/intelligence)/i.test(location.pathname) &&
        <Invalid
          inline
          icon="notifications"
          title="Please enter a search term"
          help={['Add your search query to the search bar above', 'If you\'d like to see the "latest" data, click <a href="?latest=true" onClick="location.reload()">here</a>']} />}
        {location.pathname.includes('customer-credentials') &&
        <CustomerCredentials params={params}/>}
        {/^(\/home\/search|\/home\/intelligence)/i.test(location.pathname) && app && !location.pathname.includes('customer-credentials') &&
        <Row>{/* Table */}
          <Col xs={12} className={style.results}>
            <Table
              pager
              type={params.type}
              prm={user.get('prm')}
              groups={search.get('groups')}
              limits={search.get('limits')}
              sorts={search.get('sorts')}
              apps={user.get('apps')}
              route={user.get('route') || map()}
              data={(!selectedDataState || selectedDataState.isEmpty() || params.type !== 'forums')
              ? search.getIn(['results', params.type])
              : selectedDataState}
              filters={filters}
              userHistory={user.getIn(['prefs', 'history', 'items'])}
              saved={user.getIn(['prefs', 'search'])}
              blur={user.getIn(['prefs', 'blur_credentials'])}
          />
          </Col>
        </Row>}
      </Grid>
      <Dialog
        open={Boolean(dialog)}
        onClose={() => setDialog(null)}>
        <DialogTitle>Warning: Document Previews</DialogTitle>
        <DialogContent>
          The files provided by Flashpoint are simply re-hosted
          versions of the original source files, and as such might
          contain malicious, harmful, inappropriate, or even
          potentially illicit content. Please ensure that your
          internet browser is updated to the latest version to
          minimize security risks. <br /><br />
          By clicking ALLOW, you acknowledge that by turning on
          document previews or downloading the file, you are assuming risk.
        </DialogContent>
        <DialogActions>
          <Button
            className={style.left}
            onClick={() => {
                const { pathname, query } = History.getCurrentLocation();
                History.push({
                  pathname,
                  query: { ...query, search: undefined },
                });
                setDialog(null);
              }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
                sessionStorage.setItem('X-FP-Show-Media', true);
                route(dialog);
                setDialog(null);
              }}>
            Allow for one session
          </Button>
          <Button
            className={style.active}
            onClick={() => {
                UserActions.set(['user', 'prefs', 'safe_search_toggle'], 'TOGGLE');
                UserActions.savePrefs();
                route(dialog, true);
                setDialog(null);
              }}>
            Always Allow
          </Button>
        </DialogActions>
      </Dialog>
      {status &&
      <div className={style.alert}>
        <Alert
          className={style.alertToast}
          severity={status?.severity}
          onClose={() => {
            // eslint-disable-next-line security/detect-non-literal-regexp
            const expr = new RegExp(status?.key || '', 'ig');
            const path = window.location.href.replace(expr, '');
            History.update(path);
            setStatus();
          }}>
          {status?.message}
        </Alert>
      </div>}
    </React.Fragment>
  );
};

Search.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Search;
