import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    background: '#313131',
    color: '#fff',
    display: 'flex',
    fontSize: '1.6rem',
    padding: '1rem',
    width: 'calc(100% - 70px)',
  },
  body: {
    background: '#313131',
    color: '#fff',
    fontSize: '1.6rem',
    fontWeight: '500',
    margin: 'auto 0',
  },
  button: {
    marginLeft: 'auto',
  },
});

const RfiBanner = ({ onDismiss }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <div className={classes.root}>
      <p className={classes.body}>
        You do not have permission to submit a Request for Information.{' '}
        Please reach out to Customer Success if you have any questions
      </p>
      <Button onClick={handleClick} className={classes.button} color="secondary" size="small">Dismiss</Button>
    </div>
  );
};

RfiBanner.propTypes = {
  onDismiss: PropTypes.func,
};

RfiBanner.defaultProps = {
  onDismiss: null,
};

export default RfiBanner;
