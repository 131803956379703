import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { List, ListItem, ListItemText, Pagination } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import EditButtons from './EditButtons';

const useStyles = makeStyles(() => ({
  innerText: {
    color: '#6A6A6A',
  },
  list: {
    maxHeight: '32rem',
    width: '100%',
    overflow: 'auto',
    overflowWrap: 'anywhere',
    '&  li': {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& div:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  listItem: {
    maxWidth: '75%',
    '& .MuiListItemText-primary': {
      display: 'inline-block',
    },
    '& .MuiListItemText-secondary': {
      display: 'inline-block',
      marginLeft: '.5rem',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const SettingsList = ({
  items,
  onView,
  onEdit,
  onDelete,
  options,
  disabled,
  page,
  pagination,
  handleChangePage }) => {
  const classes = useStyles();

  // eslint-disable-next-line security/detect-non-literal-regexp
  const permsRegex = new RegExp(options?.editPermission || '', 'ig');
  return (
    <>
      <List className={classes.list} data-testid="settingslist">
        {
        (!items || items.length < 1) &&
        <ListItem disabled={disabled} >
          <ListItemText primary={options?.emptyMessage || options?.loading || 'Nothing to show'} />
        </ListItem>
      }
        {
        items && items.map((v, index) => (
          <ListItem key={v.id} disabled={disabled} data-testid={`settingslist.item-${index}`}>
            <ListItemText
              primary={<div>{v?.itemTitle || v?.name || v?.credential.name || v?.account_name} • <span className={classes.innerText}>{v?.itemSubtitle || v?.value || (v?.credential_type === 'aws_name_region' ? 'Bucket' : 'Account')}</span></div>}
              onClick={() => onView(v, index)}
              disabled={disabled}
              className={classes.listItem}
              />
            {!options?.editPermission &&
              <EditButtons
                item={v}
                index={index}
                disabled={disabled}
                onEdit={onEdit}
                onDelete={onDelete} />}
            {options?.editPermission && options?.user.get('prm').some(p => permsRegex.test(p)) &&
              <EditButtons
                item={v}
                disabled={disabled}
                onEdit={onEdit}
                onDelete={onDelete} />}
          </ListItem>
        ))
        }
      </List>
      <div className={classes.pagination}>
        {!isEmpty(pagination) &&
        <Pagination
          count={pagination.num_pages}
          page={page}
          onChange={handleChangePage}
            />}
      </div>
    </>
  );
};

SettingsList.propTypes = {
  items: PropTypes.array,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  page: PropTypes.number,
  pagination: PropTypes.object,
  handleChangePage: PropTypes.func,
};

SettingsList.defaultProps = {
  items: [],
  onView: null,
  onEdit: null,
  onDelete: null,
  options: {},
  disabled: false,
  page: 1,
  pagination: {},
  handleChangePage: null,
};

export default SettingsList;
