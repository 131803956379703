import * as React from 'react';

import { Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useMenuItemStyles = makeStyles(() => ({
  wrappableColumnlikeMenuItem: {
    width: '50%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
}));

interface MenuItemProps {
  itemText: string;
  checked: boolean;
  onChange: VoidFunction;
}

export const MenuItem = ({ itemText, checked, onChange }: MenuItemProps) => {
  const classes = useMenuItemStyles();
  return (
    <div
      className={classes.wrappableColumnlikeMenuItem}
      onClick={onChange}
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
      />
      {itemText}
    </div>
  );
};
