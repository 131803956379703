import React, { useState, useEffect } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Pagination } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import PropTypes from 'prop-types';

import style from '../sanctions.module.scss';
import History from '../../../utils/history';
import Token from '../../../utils/token';
import Api from '../../../utils/api';
import Messages from '../../../constants/Messages';
import SearchActions from '../../../actions/searchActions';

const ReportTerms = ((props) => {
  const [reportTerms, setReportTerms] = useState();
  const [page, setPage] = useState(1);
  const [filterClient, setFilterClient] = useState('');
  const [loading, setLoading] = useState(false);
  // variable to track if we have navigated away from the page to cancel callback logic
  let isOnPage = true;

  const handleFilterChange = () => {
    const query = { page };
    if (filterClient !== '') {
      Object.assign(query, { clientName: filterClient });
    }
    if (!loading) {
      setLoading(true);
    }
    Api.get(`/ui/v4/sanctions/report/monthly/${props.id}/search_terms`,
      query,
      [200],
      30000,
      { Authorization: `Bearer ${Token.cke()}` },
    )
      .then(res => (res.ok ? res.data : null))
      .then((res) => {
        if (res.display === false) {
          return { data: [] };
        }
        return res;
      })
      .then((res) => {
        if (!isOnPage) {
          return;
        }
        setReportTerms(res);
        setLoading(false);
      })
      .catch((e) => {
        if (!isOnPage) {
          return;
        }
        const { data } = e.response;
        SearchActions.set(['search', 'info', 'message'], Messages.UnexpectedError(data?.detail, data));
        setLoading(false);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => handleFilterChange(), 500);
    return () => {
      isOnPage = false;
      clearTimeout(timeoutId);
    };
  }, [page, filterClient]);

  useEffect(() => {
    const { query } = History.getCurrentLocation();
    if (query.filterClient) {
      setFilterClient(query.filterClient);
    }
  }, []);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={style.sanctions}>
      <div className={style.searchtermsgrid}>
        <div className={style.title}>All Searched Terms</div>
        <Paper className={style.card}>
          <div className={style.filters}>
            <label data-cy="filterClient" htmlFor="filterReportClient" className={style.pastsearchfilter}>
              <span className={style.insetspan}>Filter by Client Name:</span>
              <input
                type="text"
                name="client"
                id="filterReportClient"
                value={filterClient}
                onChange={(event) => {
                setFilterClient(event.target.value);
                setPage(1);
              }} />
            </label>
          </div>
          <Table aria-label="simple table" data-cy="reportSearchTermsTable">
            <TableHead>
              <TableRow>
                <TableCell className={style.savedcellhead}>Search Term</TableCell>
                <TableCell className={style.savedcellhead}>Info</TableCell>
                <TableCell className={style.savedcellhead}>Original Date(s) of Search</TableCell>
                <TableCell className={style.savedcellhead}>Affected Clients</TableCell>
                <TableCell className={style.savedcellhead}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportTerms
                && reportTerms.search_terms
                && reportTerms.search_terms.map(searchTerm => (
                  <TableRow key={searchTerm.search_string}>
                    <TableCell className={style.savedcell}><a href={`/home/sanctions/main?filterTerms=${searchTerm.search_string}`}>{searchTerm.search_string}</a></TableCell>
                    <TableCell className={style.savedcell}>
                      {searchTerm.new_results === false ? (
                        <React.Fragment>
                          <div className={style.reportinfo}>
                            <CheckCircleOutline className={style.noresultscheck} />
                            No new risk factors
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className={style.reportinfo}>
                            <ErrorOutline className={style.resultswarn} />
                            New risk factors
                          </div>
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell className={style.savedcell}>{[...new Set(searchTerm.original_search_dates.map(term => /^([0-9]+\/[0-9]+\/[0-9]+)/.exec(new Date(term).toLocaleString())[0]))].join(', ')}</TableCell>
                    <TableCell className={style.savedcell}>
                      {searchTerm.related_clients.length !== 0
                        ? searchTerm.related_clients.map((client, clientIndex) => (
                          <React.Fragment key={client}>
                            <a className={style.gridlink} href={`/home/sanctions/main?filterClient=${client}`}>{client}</a>{clientIndex < searchTerm.related_clients.length - 1 ? ', ' : ''}
                          </React.Fragment>
                    )) : 'None'}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          {loading && <CircularProgress />}
          {reportTerms && reportTerms.pageinfo && reportTerms.search_terms && (
            <div className={style.savedpaginate} data-cy="reportSearchTermsPagination">
              {(reportTerms.pageinfo.pageSize * (reportTerms.pageinfo.pageNumber - 1)) + 1}
              -
              {(reportTerms.pageinfo.pageSize * (reportTerms.pageinfo.pageNumber - 1))
                + reportTerms.search_terms.length} of {reportTerms.pageinfo.totalResults}
              <Pagination
                count={Math.ceil(reportTerms.pageinfo.totalResults / reportTerms.pageinfo.pageSize)}
                page={page}
                onChange={handlePageChange} />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
});

ReportTerms.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ReportTerms;
