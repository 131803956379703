import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { capitalize } from 'lodash';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { Link, List, ListItem, Typography } from '@mui/material';

const InputComponent = ({
  customer,
  inputData,
}) => {
  const [additionalFields, setAdditionalFields] = useState([]);
  const adjustedFields = ['age_range', 'due_date', 'legal_opt_in', 'location', 'name', 'threat_level'];
  const { age_range, due_date, location, name, threat_level } = inputData;

  const displayAdditionalFields = (v) => {
    const field = capitalize(v[0].replaceAll('_', ' '));
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const value = typeof v[1] === 'string' ? v[1] : v[1]?.join(', ');
    let urls = [];

    if ((v[0] === 'incident_details' || v[0] === 'comments') && urlRegex.test(value)) {
      urls = value.match(urlRegex);
    }

    if (urls.length) {
      return (
        <div>
          <Typography style={{ whiteSpace: 'pre-line' }} key={`${field}-${value}`} variant="body2" ><b>{field}:</b> {value.replace(urlRegex, '')}</Typography>
          <List>
            {urls.map(url => <ListItem style={{ paddingLeft: '0' }}><Link color="secondary" href={url} target="_blank">{url} </Link></ListItem>)}
          </List>
        </div>
      );
    } else {
      return (
        <Typography style={{ whiteSpace: 'pre-line' }} key={`${field}-${value}`} variant="body2" ><b>{field}:</b> {value}</Typography>
      );
    }
  };

  const displayLocation = value => value?.map(v => `${v.address_line_1 || ''} ${v.address_line_2 || ''} ${v.city || ''} ${v.state || ''} ${v.zip_code || ''} ${v.country || ''}`).join(',');

  const formatDueDateTime = (date) => {
    // date is an ISO 8601 formatted string (ex: 2011-10-05T14:48:00.000Z)
    const formattedDate = moment(date.substring(0, date.indexOf('T'))).format('MM/DD/YYYY');
    const formattedTime = date.substring(date.indexOf('T') + 1, date.lastIndexOf(':'));
    const formattedDateTime = formattedDate.concat(' ', formattedTime);
    return formattedDateTime;
  };

  useEffect(() => {
    setAdditionalFields(Object.entries(inputData).filter(v => !adjustedFields.includes(v[0])));
  }, [inputData]);

  return (
    <Grid style={{ padding: '5px' }}>
      <Row between="xs">
        <Col><Typography gutterBottom variant="h5">Input Data</Typography></Col>
        <Col><Typography variant="body2"><b>Customer:</b> {customer}</Typography></Col>
      </Row>
      <Row>
        <Col>
          {due_date && <Typography variant="body2"><b>Due Date (UTC):</b> {formatDueDateTime(due_date)}</Typography>}
          {threat_level && <Typography variant="body2"><b>Threat level:</b> {threat_level}</Typography>}
          {name && <Typography variant="body2"><b>Name:</b> {name?.toString()}</Typography>}
          {age_range && <Typography variant="body2"><b>Age Range:</b> {`${age_range.from} - ${age_range.to}`}</Typography>}
          {location &&
            <Row>
              <Typography style={{ padding: '0 5px 0 5px' }} variant="body2"><b>Location:</b></Typography>
              <Typography variant="body2">
                {displayLocation(location)}
              </Typography>
            </Row>
          }
          {additionalFields?.map(v => displayAdditionalFields(v))}
        </Col>

      </Row>
    </Grid>
  );
};

InputComponent.propTypes = {
  customer: PropTypes.string.isRequired,
  inputData: PropTypes.object.isRequired,
};

export default InputComponent;
