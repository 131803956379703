import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { ChartData } from '../../../../../core/types/ChartData';
import { MentionsChart } from './MentionsChart';
import { ArrowIndicator } from './ArrowIndicator';

export interface MentionsWidgetProps {
  /** Name of organization to display under the title */
  orgName: string;

  /** Number of `orgName` mentions for previous two time periods */
  mentions: {
    actor: string,
    total: {
      now: number;
      previous: number;
    },
    timePeriod: string;
    chart: ChartData[];
  };

  /** Mouse click handler */
  onClick?: () => void;
}

const useStyles = makeStyles(({
  root: {
    width: '100%',
  },
  organization: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1',
    margin: '1rem 0 0',
    overflow: 'hidden',
    padding: '0 0 .3rem 0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  contentGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    columnGap: '.1rem',
  },
  count: {
    fontFamily: "'Rajdhani', 'Open Sans', sans-seri",
    fontSize: '3.8rem',
    fontWeight: 500,
    lineHeight: '1',
    margin: '1rem 0 0',
    padding: '0',
  },
  chart: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '.5rem',
  },
  changeIndicator: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    margin: '0',
    padding: '0',
    lineHeight: '1',
  },
  linkEnabled: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  linkDisabled: {
    cursor: 'not-allowed',
  },
}));

/** Displays the number of mentions of an organization with a sparkline chart */
export const MentionsWidget = ({
  orgName,
  mentions,
  onClick,
}: MentionsWidgetProps): JSX.Element => {
  const styles = useStyles();

  const calculatePercentage = (now: number, previous: number): number => {
    let a = now || 0;
    let b = previous || 0;
    if (previous < now) {
      a = previous;
      b = now;
    }
    if (b === 0) {
      return a;
    }
    const percent = Math.round((a / b) * 100);
    return ((now > previous) ? percent : -(100 - percent));
  };

  const percent = calculatePercentage(mentions?.total?.now, mentions?.total?.previous);

  return (
    <div onClick={onClick} className={styles.root} style={{ cursor: onClick ? 'pointer' : 'not-allowed' }}>
      <h3 className={styles.organization}>{orgName || 'Unknown'}</h3>
      <div className={styles.contentGrid}>
        <div>
          <h2
            className={[onClick ? styles.linkEnabled : styles.linkDisabled, styles.count].join(' ')}>
            {mentions?.total?.now?.toLocaleString('en-US') || 0}
          </h2>
        </div>
        <MentionsChart data={mentions?.chart}/>
      </div>
      <ArrowIndicator
        percent={percent}
        timePeriod={mentions?.timePeriod}
        className={styles.changeIndicator}
      />
    </div>
  );
};

MentionsWidget.defaultProps = {
  onClick: null,
};
