import React from 'react';

import {
  KeyboardArrowDown,
} from '@mui/icons-material';

export default {
  MuiAccordion: {
    square: true,
  },
  MuiAutocomplete: {
    filterSelectedOptions: true,
    fullWidth: true,
    limitTags: 3,
    popupIcon: <KeyboardArrowDown />,
  },
  MuiCircularProgress: {
    size: 20,
  },
  MuiDialog: {
    fullWidth: true,
  },
  MuiFormControl: {
    component: 'fieldset',
    fullWidth: true,
  },
  MuiIcon: {
    color: 'primary',
    fontSize: 'small',
  },
  MuiInputLabel: {
    shrink: true,
  },
  MuiList: {
    component: 'nav',
  },
  MuiListItem: {
    button: true,
    dense: true,
  },
  MuiMenu: {
    keepMounted: true,
  },
  MuiOutlinedInput: {
    fullWidth: true,
    notched: true,
  },
  MuiPagination: {
    size: 'small',
  },
  MuiPopover: {
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    transformOrigin: { horizontal: 'left', vertical: -10 },
  },
  MuiRadio: {
    size: 'small',
  },
  MuiSelect: {
    displayEmpty: true,
    IconComponent: KeyboardArrowDown,
  },
  MuiSwitch: {
    size: 'small',
    color: 'secondary',
  },
  MuiCheckbox: {
    color: 'primary',
  },
  MuiTabList: {
    centered: true,
  },
  MuiTable: {
    size: 'small',
  },
  MuiTableCell: {
    align: 'left',
    size: 'small',
  },
  MuiTablePagination: {
    component: 'div',
  },
  MuiTextField: {
    fullWidth: true,
    size: 'medium',
  },
};
