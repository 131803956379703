/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import cx from 'classnames';
import style from './CSVImport.module.scss';

const CSVImport = ({ description, externalHandleDrop, formats, title }) => {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState();
  const dropRef = useRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    setError();
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    if (formats &&
        files.some(file =>
          !formats.some(format =>
            file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            setError('Only CSV formats are acceptable');
      return;
    }
    if (files) {
      const fileReader = new FileReader();
      fileReader.onload = (_e) => {
        const csvOutput = _e.target.result;
        if (csvOutput.split('\n').length > 1000) {
          setError('Upload exceeds 1000 buckets');
          return;
        }
        if (files && files.length) {
          externalHandleDrop(files);
        }
      };
      fileReader.readAsText(...files);
    }
  };

  const handleUploadClick = (e) => {
    setError();
    const files = [...e.target.files];
    if (formats &&
      files.some(file =>
        !formats.some(format =>
          file.name.toLowerCase().endsWith(format.toLowerCase())))) {
          setError('Only CSV formats are acceptable');
          return;
    }
    if (files) {
      const fileReader = new FileReader();
      fileReader.onload = (_e) => {
        const csvOutput = _e.target.result;
        if (csvOutput.split('\n').length > 1000) {
          setError('Upload exceeds 1000 buckets');
          return;
        }
        if (files && files.length) {
          externalHandleDrop(files);
        }
      };
      fileReader.readAsText(...files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    div.addEventListener('dragenter', handleDragEnter);
    div.addEventListener('dragleave', handleDragLeave);
    return () => {
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
      div.removeEventListener('dragenter', handleDragEnter);
      div.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  return (
    <div className={style.CSVImport}>
      <div className={style.title}>{title}</div>
      <div className={style.uploadContainer}>
        <label htmlFor="bucketFile">
          <fieldset
            className={cx([style.inputFile, dragging && style.inputFileDragging])}
            ref={dropRef}>
            <legend> Bulk Upload </legend>
            <CloudUploadIcon key="dropzone" className={style.cloudIcon} />
          </fieldset>
        </label>
        <input type="file" onChange={e => handleUploadClick(e)} className={style.inputFileButton} id="bucketFile" />
      </div>
      <div className={style.description} >{description}</div>
      <div className={cx([style.description, style.error])} >{error}</div>
    </div>);
    };

CSVImport.propTypes = {
  description: PropTypes.string,
  externalHandleDrop: PropTypes.func,
  formats: PropTypes.array,
  title: PropTypes.string,
};

CSVImport.defaultProps = {
  description: '',
  externalHandleDrop: null,
  formats: [],
  title: '',
};

export default CSVImport;
