export default {
  fontFamily: [
    '"Open Sans"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: 14, /* 1.4rem */
  htmlFontSize: 10, /* 1rem */
  button: {},
  body1: {
    fontSize: '1.4rem',
  },
  body2: {
    fontSize: '1.4rem',
  },
  caption: {},
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {},
  subtitle2: {},
};
