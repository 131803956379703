import { SavedSearch } from '../../../../../core/types/SavedSearch';
import { ExpoDashFilters } from '../../../../../core/types/ExpoDashFilters';
import { getLabel, makeCurrentAndLastQueries } from '../../utilsTS';

const queryAggregations = {
  'date-histogram': {
    field: 'sort_date',
    interval: 'day',
  },
  'date-histogram-cardinality': {
    field: 'sort_date',
    interval: 'day',
    sub_field: 'site_actor.names.handle.keyword',
  },
  'multiple-terms': {
    actor: {
      terms: {
        field: 'site_actor.names.handle.keyword',
        size: 5000,
      },
    },
  },
};

const Results = async (savedSearch: SavedSearch, filters: ExpoDashFilters) => {
  const [current, last] = await makeCurrentAndLastQueries({
    savedSearch, filters, aggregations: queryAggregations });

  return {
    timePeriod: getLabel(filters),
    total: {
      now: current?.aggregations?.actor?.buckets?.length,
      previous: last?.aggregations?.actor?.buckets?.length,
    },
    chart: current?.aggregations?.['date-histogram']?.buckets?.map(v => ({
      count: v?.doc_count,
      date: v.key,
    })),
  };
};

export default {
  Results,
};
