import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import FileSaver from 'file-saver';
import get from 'lodash/get';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import {
  Button,
  Icon,
  ListItem,
  Menu,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import style from './ccmc.module.scss';
import CCMcApi from './CCMcApi';
import CCMcWebhook from './CCMcWebhook';
import Invalid from '../utils/Invalid/Invalid';
import Text from '../../utils/text';
import Token from '../../utils/token';
import Messages from '../../constants/Messages';

const useStyles = makeStyles(theme => ({
  ccmc: {
    '& .MuiTabPanel-root': {
      padding: `${theme.spacing(1)} 0`,
    },
    '& .MuiButton-root': {
      marginRight: theme.spacing(1),
    },
  },
}));

const CCMc = () => {
  const cookieAccess = Boolean(Token.get('prm').some(p => /dat.ccm.cus.ha.r/.test(p)));

  const classes = useStyles();
  const [dialog, setDialog] = useState();
  const [header, setHeader] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [limited, setLimited] = useState(map());
  const [page, setPage] = useState(map());
  const [response, setResponse] = useState(map());
  const [selectedTab, setSelectedTab] = useState('input');
  const [testingType, setTestingType] = useState('');
  const [triggerScroll, setTriggerScroll] = useState(false);
  const [useCase, setUseCase] = useState('search');
  const [hostAttributes, setHostAttributes] = useState(cookieAccess);

  const onSearch = (res, resUseCase, resHeader, resLimit) => {
    if (!res.isEmpty()) {
      const newData = res.getIn(['results']) || list();
      let updatedRes = res;
      setLimited(res);
      newData.forEach((v, k) => {
        const existingData = response.getIn(['results', k, 'results']) || list();
        updatedRes = updatedRes.setIn(['results', k, 'results'], existingData.concat(v.get('results')));
      });
      setResponse(updatedRes);
    } else {
      setResponse(res);
    }
    setLimit(resLimit);
    setUseCase(resUseCase);
    setHeader(resHeader || []);
  };

  const tooltip = (v, k) => {
    switch (k) {
      case 'cookies':
        return (
          `<span
            data-html="true"
            data-for="global.tooltip"
            data-tip="${v
              .entrySeq()
              .map(([_k, _v]) => `${_k}: ${_v}`)
              .sort((a, b) => b.includes('key') - a.includes('key'))
              .join('<br>')}">
            ${Text.StripHtml(v.get('key') || '-')}
          </span>`
        );
      default:
        return (
          `<span
            data-for="global.tooltip"
            data-tip="${Text.StripQuotes(Text.StripHtml(Text.Strip(v)))}">${Text.StripHtml(v || '-')}
          </span>`
        );
    }
  };

  const columns = (key) => {
    switch (useCase) {
      case 'analysis':
      case 'domain': {
        return [
          { id: 'previously_observed_at',
            label: 'Observed At (UTC)',
            style: { width: '125px' },
            text: 'Previously Observed At (UTC)',
            render: v => (<span>{moment.utc(v.getIn(['previously_observed_at', 'date-time'])).format('MMM D, YYYY HH:mm')}</span>) },
          { id: 'username|password',
            label: 'Credential',
            text: 'Username/Password/Domain',
            render: v => Text.Highlight(`
              <b>Username</b>: ${tooltip(v.get('username'))}<br/>
              <b>Password</b>: ${tooltip(v.get('password'))}<br/>
              <b>Affected Domain</b>: ${tooltip(v.get('affected_domain'))}`) },
          { id: 'breach.created_at.date-time|breach.title|breach.source_type',
            label: 'Breach Details',
            labels: 'date of breach|title|source',
            text: 'Details about the breach',
            render: v => Text.Highlight(
              `<b>Date of Breach</b>: ${v.getIn(['breach', 'created_at', 'date-time'])
                ? moment.utc(v.getIn(['breach', 'created_at', 'date-time'])).format('MMM D, YYYY HH:mm')
                : '-'}<br/>
              <b>Title</b>: ${tooltip(v.getIn(['breach', 'title']))}<br/>
              <b>Source</b>: ${tooltip(v.getIn(['breach', 'source']))}<br/>`) },
          ...(hostAttributes
            ? [{
              id: 'cookies',
              label: 'Cookies',
              labels: 'Keys',
              text: 'Cookie Details',
              render: v => Text.Highlight(v
                ?.get('cookies')
                ?.map(cookie => tooltip(cookie, 'cookies'))
                ?.join('<br />'),
              ) }]
            : []),
        ];
      }
      case 'webhook': {
        return key === 'compromised_credential'
          ? [
            { id: 'username|password|sha256',
              label: 'Credential',
              text: 'Username/Password',
              render: v => Text.Highlight(`
                <b>Username</b>: ${tooltip(v.get('username'))}<br/>
                <b>Password</b>: ${tooltip(v.get('password'))}<br/>
                <b>Matched Hash</b>: ${tooltip(v.get('sha256'))}`) },
            { id: 'breach.created_at.date-time|breach.title|breach.source_type',
              label: 'Breach Details',
              labels: 'date of breach|title|source',
              text: 'Details about the breach',
              render: v => Text.Highlight(
                `<b>Date of Breach</b>: ${v.getIn(['breach', 'created_at'])
                  ? moment.utc(v.getIn(['breach', 'created_at'])).format('MMM D, YYYY HH:mm')
                  : '-'}<br/>
                <b>Title</b>: ${tooltip(v.getIn(['breach', 'title']))}<br/>
                <b>Source</b>: ${tooltip(v.getIn(['breach', 'source']))}<br/>`) },
          ]
          : [
            { id: 'received_at',
              label: 'Received At',
              render: v => (<span>{moment.utc(v.get('received_at')).format('MMM D, YYYY HH:mm')}</span>) },
            { id: 'result',
              label: 'Result',
              render: v => v.get('result') },
            { id: 'rows',
              label: 'Rows Loaded',
              render: v => v.get('rows').toLocaleString() },
          ];
      }
      default: {
        return [
          { id: 'username',
            label: 'Username',
            text: 'Username',
            render: v => Text.Highlight(tooltip(v.get('username'))) },
          { id: 'password',
            label: 'Password',
            text: 'Password',
            render: v => Text.Highlight(tooltip(v.get('password'))) },
        ];
      }
    }
  };

  const onLoad = (curPage) => {
    setPage(curPage);
    if (curPage < page || curPage <= maxPage) {
      // previous results. update limited results.key.results
      const lowerBound = curPage === 1 ? 0 : (curPage - 1) * limit;
      const upperBound = curPage * limit;
      let updated = limited;
      response.get('results').forEach((v, k) => {
        const data = v.get('results') || list();
        const sliced = data.slice(lowerBound, upperBound) || list();
        updated = updated.setIn(['results', k, 'results'], sliced);
      });
      setLimited(updated);
    } else {
      setMaxPage(curPage);
      setTriggerScroll(true);
    }
  };

  useEffect(() => {
    if (response.isEmpty()) {
      setLimit(0);
      setLimited(map());
      setPage(1);
      setMaxPage(1);
    }
    if (!response.isEmpty() && selectedTab !== 'results') {
      setSelectedTab('results');
      window.scrollTo(0, 0);
    }
    setTriggerScroll(false);
  }, [response]);

  const cookiesKey = (cookies) => {
    let cookieKey = '';
    cookies.forEach((cookie) => { cookieKey = cookieKey.concat(` ${cookie.get('key')}`); });
    return cookieKey;
  };

  const csvConverter = (data) => {
    let csv = '';
    if (useCase === 'domain') csv = `fpid, affected_domain, affected_url, domain, password, username${hostAttributes ? ',cookies' : ''}\n`;
    if (useCase === 'analysis') csv = `fpid, affected_domain, affected_url, date_of_breach, title, source,username${hostAttributes ? ',cookies' : ''}\n`;
    data.forEach((v) => {
      const getCookies = (v.get('cookies') ? `,${cookiesKey(v.get('cookies'))}` : ',-');
      const getAffectedURL = (v.get('affected_url') ? v.get('affected_url') : '-');
      const getAffectedDomain = (v.get('affected_domain') ? v.get('affected_domain') : '-');
      const getDomain = (v.get('domain') ? v.get('domain') : '-');
      const breachDate = v.getIn(['breach', 'first_observed_at', 'date-time']);
      const title = v.getIn(['breach', 'title']);
      const source = v.getIn(['breach', 'source']);
      if (useCase === 'domain') {
        csv = csv.concat(`${v.get('fpid')},${getAffectedDomain},${getAffectedURL},${getDomain},${v.get('password')},${v.get('username')}${hostAttributes ? getCookies : ''}\n`);
      }
      if (useCase === 'analysis') {
        csv = csv.concat(`${v.get('fpid')},${getAffectedDomain},${getAffectedURL},${breachDate},${title},${source},${v.get('username')}${hostAttributes ? getCookies : ''}\n`);
      }
    });
    return csv;
  };

  const onExport = () => {
    const csv = csvConverter(response.getIn(['results', 0, 'results']));
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    FileSaver.saveAs(csvData, `${response.getIn(['results', 0, 'key'])}.csv`);
  };

  return (
    <Grid
      fluid
      name="demo.ccmc"
      className={cx([style.base, style.ccmc, classes.ccmc])}>
      <Row>
        <Col xs={12}>
          <Paper className={style.card}>
            <TabContext value={selectedTab}>
              <TabList
                variant="fullWidth"
                indicatorColor="secondary"
                onChange={(event, val) => setSelectedTab(val)}
                className={style.tabs}>
                <Tab label="Input Fields" value="input" />
                <Tab label="Table Results" value="results" disabled={limited.isEmpty()} />
                <Tab label="API Response" value="response" disabled={response.isEmpty()} />
              </TabList>
              <TabPanel
                className={cx([style.tab, style.input])}
                value="input">
                <Row className={cx([style.row])} middle="xs">
                  <Col xs={3}>
                    <span className={cx([style.label])}>I want to test:</span>
                  </Col>
                  <Col xs={9}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={event => setDialog({ key: 'testing.type', target: event.target })}
                      endIcon={<Icon>keyboard_arrow_down</Icon>}>
                      {testingType || 'Select...'}
                    </Button>
                    <Menu
                      value={testingType}
                      onClick={() => setDialog()}
                      anchorEl={get(dialog, 'target', '')}
                      open={Boolean(dialog && dialog.key === 'testing.type')}>
                      <ListItem
                        value=""
                        disabled
                        className={cx([style.item])}>
                        Select...
                      </ListItem>
                      <ListItem
                        value="api"
                        className={cx([style.item, testingType === 'api' && style.active])}
                        onClick={() => setTestingType('api')}>
                        CCM-C API
                      </ListItem>
                      <ListItem
                        value="webhook"
                        className={cx([style.item, testingType === 'webhook' && style.active])}
                        onClick={() => setTestingType('webhook')}>
                        CCM-C Webhook
                      </ListItem>
                    </Menu>
                  </Col>
                </Row>
                {testingType === 'api' &&
                <CCMcApi
                  setResponse={onSearch}
                  triggerScroll={triggerScroll}
                  hostAttributes={hostAttributes}
                  setHostAttributes={setHostAttributes}/>}
                {testingType === 'webhook' &&
                <CCMcWebhook setResponse={onSearch} />}
              </TabPanel>
              <TabPanel
                className={cx([style.tab, style.results])}

                value="results">
                {limited.getIn(['timings_ms', 'total']) &&
                <Row middle="xs" className={cx([style.row])}>
                  <Col xs={3}>
                    <span className={cx([style.label])}>Timing (ms):</span>
                  </Col>
                  <Col xs={6}>
                    {limited.getIn(['timings_ms', 'total'])}
                  </Col>
                  <Col xs={3}>
                    <div onClick={() => onExport()} className={cx([style.download])}>
                      Export Records to CSV
                    </div>
                  </Col>
                </Row>}
                {limited.has('num_results') && limited.get('num_results') === 0 &&
                <Invalid
                  title={Messages.SearchEmpty}
                  help={[]} />}
                {limited.has('num_results') && limited.get('num_results') > 0 &&
                limited.get('results').map((v, k) => (
                  <Row
                    key={v.get('key')}
                    className={cx([style.row, k !== limited.get('results').size - 1 && style.line])}>
                    <Col xs={12}>
                      {header[String(k)] || ''}
                      <Row middle="xs">
                        <Col xs={3}>
                          <span className={cx([style.label])}>Number of Results on Page:</span>
                        </Col>
                        <Col xs={9}>
                          {useCase !== 'webhook' ? (v.get('results') || list()).size : (v.getIn(['results', 'event', 'event_data']) || list()).size}
                        </Col>
                      </Row>
                      {(v.get('results') || list()).size === 0 ? (
                        <Invalid
                          title={Messages.SearchEmpty}
                          help={[]} />
                      ) : (
                        <div>
                          {testingType === 'api' && k === 0 &&
                          <div style={{ textAlign: 'end' }} onMouseEnter={() => ReactTooltip.rebuild()}>
                            <Icon
                              disabled={page === 1}
                              data-for="global.tooltip"
                              data-tip="Previous Page"
                              onClick={() => onLoad(page - 1)}>
                              chevron_left
                            </Icon>
                            <Icon
                              disabled={!limited.get('scroll_id')}
                              data-for="global.tooltip"
                              data-tip="Next Page"
                              onClick={() => onLoad(page + 1)}>
                              chevron_right
                            </Icon>
                          </div>}
                          <TableContainer key={v.get('key')}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns(v.get('key')).map(col => (
                                    <TableCell
                                      key={col.id}
                                      style={col.style}>
                                      {col.text &&
                                        <div
                                          data-for="global.tooltip"
                                          data-tip={col.text.match(/(.{1,75})(?:\n|$| )/g).join('<br />')}
                                          className={cx([col.sort ? style.sort : null])}>
                                          {col.label}
                                        </div>}
                                      {!col.text && <div>{col.label}</div>}
                                    </TableCell>))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(useCase !== 'webhook' ? (v.get('results') || list()) : (v.getIn(['results', 'event', 'event_data']) || list()))
                                  .map((r, i) => (
                                    <TableRow
                                      key={`${r.get('fpid') || `${r.get('username')}:${r.get('password')}`}`}>
                                      {columns(v.get('key')).map(col => (
                                        <TableCell
                                          onMouseEnter={() => ReactTooltip.rebuild()}
                                          key={col.id}
                                          style={col.style}>
                                          {col.render(r, i)}
                                        </TableCell>))}
                                    </TableRow>))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {testingType === 'api' && k === limited.get('results').size - 1 &&
                          <div style={{ textAlign: 'end' }} onMouseEnter={() => ReactTooltip.rebuild()}>
                            <Icon
                              disabled={page === 1}
                              data-for="global.tooltip"
                              data-tip="Previous Page"
                              onClick={() => onLoad(page - 1)}>
                              chevron_left
                            </Icon>
                            <Icon
                              disabled={!limited.get('scroll_id')}
                              data-for="global.tooltip"
                              data-tip="Next Page"
                              onClick={() => onLoad(page + 1)}>
                              chevron_right
                            </Icon>
                          </div>}
                        </div>
                      )}
                    </Col>
                  </Row>
                ))}
              </TabPanel>
              <TabPanel
                className={cx([style.tab, style.response])}
                value="response">
                <Row>
                  <Col xs={12}>
                    {response.getIn(['timings_ms', 'total']) &&
                    <Row middle="xs" className={cx([style.row])}>
                      <Col xs={3}>
                        <span className={cx([style.label])}>Timing (ms):</span>
                      </Col>
                      <Col xs={9}>
                        {response.getIn(['timings_ms', 'total'])}
                      </Col>
                    </Row>}
                    <Row middle="xs" className={cx([style.row])}>
                      <Col xs={12}>
                        <pre>
                          {JSON.stringify(response.toJS(), null, 2)}
                        </pre>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPanel>
            </TabContext>
          </Paper>
        </Col>
      </Row>
      <ReactTooltip id="table.tooltip" html place="bottom" effect="solid" />
    </Grid>
  );
};

CCMc.propTypes = {};

CCMc.defaultProps = {};

export default CCMc;
