import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';

import Query from '../query';
import Messages from '../../../../constants/Messages';
import SearchActions from '../../../../actions/searchActions';

const useStyles = makeStyles({
  body: {
    fontSize: '1.6rem',
  },
  button: {
    fontWeight: '600',
    padding: '1rem',
    textTransform: 'uppercase',
  },
  statusDisplay: {
    display: 'inline-block',
    marginBottom: '2.4rem',
  },
  statusWarning: {
    color: '#5c6ae0',
    display: 'flex',
  },
});

const StatusDisplay = ({ incident, limitReached }) => {
  const [takedownRequested, setTakedownRequested] = useState(false);
  const classes = useStyles();

  const incidentId = incident?.get('id');
  const status = incident?.get('status') || 'error';
  const date = incident?.get('updated_at');
  const awaitingTakedownDate = incident?.get('takedown_requested_at') || new Date();
  const awaitingTakedown = takedownRequested || incident?.get('takedown_requested');

  useEffect(() => {
    if (takedownRequested) {
      Query.postTakedown(incidentId)
        .then((res) => {
          if (!res.ok) {
            SearchActions.set(['search', 'info', 'message'], Messages.TakedownError);
            setTakedownRequested(false);
          }
        })
        .catch(() => {
          SearchActions.set(['search', 'info', 'message'], Messages.TakedownError);
          setTakedownRequested(false);
        });
    }
  }, [takedownRequested]);

  const statusDate = date ? moment(date).format('M/D/Y') : 'an unknown date';

  /* Create fake status and date to display if user requested a takedown */
  const currentStatus = (status === 'Unresolved') && awaitingTakedown ? 'Takedown Requested' : status;
  const currentStatusDate = (status === 'Unresolved') && awaitingTakedown ? moment(awaitingTakedownDate).format('M/D/Y') : statusDate;

  switch (currentStatus) {
    case 'Takedown Requested':
      return (
        <Typography variant="body1" className={[classes.body, classes.statusDisplay].join(' ')}>
          <i>{`[This incident was requested to be taken down on ${currentStatusDate}]`}</i>
        </Typography>
      );
    case 'Removed':
      return (
        <Typography variant="body1" className={[classes.body, classes.statusDisplay].join(' ')}>
          <i>{`[This incident was removed on ${currentStatusDate}]`}</i>
        </Typography>
      );
    case 'Details Needed':
      return (
        <div className={classes.statusWarning}>
          <WarningIcon />
          {' '}
          <Typography
            variant="body1"
            className={[classes.body, classes.statusDisplay].join(' ')}
          >
            Details needed
          </Typography>
        </div>
      );
    case 'Unresolved':
      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={[classes.button, classes.statusDisplay].join(' ')}
            disabled={limitReached}
            onClick={() => setTakedownRequested(true)}
          >
            Request takedown
          </Button>
          {limitReached &&
            <Typography variant="body1" className={[classes.body, classes.statusDisplay].join(' ')}>
              You have used all of your purchased takedowns, please reach out to your Customer{' '}
              Support contact to purchase more.
            </Typography>}
        </>
      );
    default:
      return <div />;
  }
};

StatusDisplay.propTypes = {
  incident: PropTypes.object,
  limitReached: PropTypes.bool,
};

StatusDisplay.defaultProps = {
  incident: {},
  limitReached: true,
};

export default StatusDisplay;
