import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { List as list, fromJS } from 'immutable';
import { Grid, Row } from 'react-flexbox-grid/lib';

import style from './dashboards.module.scss';
import SearchUtils from '../../utils/searchUtils';
import History from '../../utils/history';
import CveDashboard from '../../components/dashboard/CveDashboard/CveDashboard';
import HomeDashboard from '../../components/dashboard/HomeDashboard/HomeDashboard';
import HomeBetaDashboard from '../../components/dashboard/HomeDashboard/HomeBetaDashboard';
import FraudDashboard from '../../components/dashboard/FraudDashboard/FraudDashboard';
import IocDashboard from '../../components/dashboard/IocDashboard/IocDashboard';
import CredentialBreachDashboard from '../../components/dashboard/CredentialBreachDashboard/CredentialBreachDashboard';
import RansomwareInternalDashboard from '../../components/dashboard/RansomwareDashboard/RansomwareInternalDashboard';
import RansomersDashboard from '../../components/dashboard/RansomwareDashboard/RansomersDashboard';
import RansomwareIndustryDashboard from '../../components/dashboard/RansomwareDashboard/RansomwareIndustryDashboard';
import ExposureDashboard from '../../components/dashboard/ExposureDashboard/ExposureDashboard';
import AntiPhishingDashboard from '../../components/dashboard/AntiPhishingDashboard/AntiPhishingDashboard';
import DomainMonitoringDashboard from '../../components/dashboard/DomainMonitoring/DomainMonitoringDashboard/DomainMonitoringDashboard';
import MonitoredUrlsDashboard from '../../components/dashboard/DomainMonitoring/MonitoredUrlsDashboard/MonitoredUrlsDashboard';
import MyCards from '../../components/dashboard/CardFraudDashboard/MyCards';
import FullCards from '../../components/dashboard/CardFraudDashboard/FullCards';
import ManagedBins from '../../components/dashboard/CardFraudDashboard/ManageBins';
import Merchants from '../../components/dashboard/CardFraudDashboard/Merchants';
import { UkraineCrisisDashboard } from '../../components/dashboard/UkraineDashboard/UkraineCrisisDashboard';
import { BotshopsDashboard } from '../../components/dashboard/LabsDashboard/BotshopsDashboard';
import { BitcoinDashboard } from '../../components/dashboard/LabsDashboard/BitcoinDashboard';
import { AccessAuctionsDashboard } from '../../components/dashboard/LabsDashboard/AccessAuctionsDashboard';

import {
  UserContext,
} from '../../components/utils/Context';

const Dashboards = ({
  match: {
    params,
  },
}) => {
  const user = useContext(UserContext);

  const getDates = (profile) => {
    if (!profile) {
      return null;
    }
    const filters = profile.toJS();
    // global dates
    const {
      date,
      since,
      until,
    } = SearchUtils.parseDate({
      date: filters.date,
      since: filters.date,
      until: filters.date,
    });

    // credential dates
    const {
      date: breached_at,
      since: breached_since,
      until: breached_until,
    } = SearchUtils.parseDate({
      date: filters.breached_at,
      since: filters.breached_since,
      until: filters.breached_until,
    });


    return profile
      .set('date', date)
      .set('since', since)
      .set('until', until)
      .set('breached_at', breached_at)
      .set('breached_since', breached_since)
      .set('breached_until', breached_until);
  };

  const getFilters = () => {
    const { type } = params;

    // checks to see if profile has changed
    // If changed, pass updated filters for profile
    const profile = user.getIn(['tmp', type]);

    if (profile && profile.active) {
      const activeProfile = user
        .getIn(['prefs', 'filters', type])
        .filter(v => v.get('name') === profile.active);
      return activeProfile.getIn([0, 'filters']);
    }

    // Checks to see if the user has saved filters
    // pass saved filters if it exists
    if (user.hasIn(['prefs', 'filters', type]) && list.isList(user.getIn(['prefs', 'filters', type]))) {
      let userDefaults = user
        .getIn(['prefs', 'filters', type], list())
        .filter(v => v.get('default') === 'true');

      if (userDefaults.isEmpty()) userDefaults = list([user.getIn(['prefs', 'filters', type, 0])]);
      let filters = userDefaults.getIn([0, 'filters']);
      // if filters are overridden via url parameters, include those
      const browserLocation = History.getCurrentLocation();
      const query = { ...History.createQueryObject(browserLocation.search) };
      filters = fromJS({ ...filters.toJS(), ...query });

      return filters;
    }

    // return defaults if no filters exists
    return user.getIn(['defaults', 'filters', type, 0, 'filters']);
  };

  const profile = getDates(getFilters());

  return (
    <Grid fluid className={style.dashboard}>
      <Row>
        {['cves'].includes(params.type) &&
        <CveDashboard profile={profile} />}
        {['fraud'].includes(params.type) &&
        <FraudDashboard profile={profile} />}
        {['iocs'].includes(params.type) &&
        <IocDashboard profile={profile} />}
        {['credbreaches'].includes(params.type) &&
        <CredentialBreachDashboard user={user} />}
        {['victims'].includes(params.type) &&
        <RansomwareInternalDashboard profile={profile} />}
        {['ransomers'].includes(params.type) &&
        <RansomersDashboard profile={profile} />}
        {['home'].includes(params.type) &&
        <HomeDashboard profile={profile} />}
        {['expodash'].includes(params.type) &&
        <HomeBetaDashboard profile={profile} user={user}/>}
        {['ransomware'].includes(params.type) &&
        <RansomwareIndustryDashboard profile={profile} />}
        {['exposure'].includes(params.type) &&
        <ExposureDashboard user={user} />}
        {['monitoring'].includes(params.type) &&
        <AntiPhishingDashboard
          user={user} />}
        {['incidents'].includes(params.type) &&
        <DomainMonitoringDashboard />}
        {['monitored-urls'].includes(params.type) &&
        <MonitoredUrlsDashboard />}
        {['mycards'].includes(params.type) &&
        <MyCards />}
        {['fullcards'].includes(params.type) &&
        <FullCards />}
        {['merchants'].includes(params.type) &&
        <Merchants />}
        {['managebins'].includes(params.type) &&
        <ManagedBins />}
        {['ukraine-crisis'].includes(params.type) &&
        <UkraineCrisisDashboard />}
        {['botshops'].includes(params.type) &&
        <BotshopsDashboard />}
        {['bitcoin'].includes(params.type) &&
        <BitcoinDashboard />}
        {['access-auctions'].includes(params.type) &&
        <AccessAuctionsDashboard />}
      </Row>
    </Grid>
  );
};

Dashboards.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Dashboards;
