export default [
  { date: 'All Time', since: '*', until: 'now' },
  { date: 'Last 24 Hours', since: 'now-24h', until: 'now' },
  { date: 'Last 48 Hours', since: 'now-48h', until: 'now' },
  { date: 'Last 7 Days', since: 'now-7d', until: 'now' },
  { date: 'Last 30 Days', since: 'now-30d', until: 'now' },
  { date: 'Last 60 Days', since: 'now-60d', until: 'now' },
  { date: 'Last 90 Days', since: 'now-90d', until: 'now' },
  { date: 'Last 120 Days', since: 'now-120d', until: 'now' },
  { date: 'Last Year', since: 'now-1y', until: 'now' },
  { date: 'Last 2 Years', since: 'now-2y', until: 'now' },
  { date: 'Last 3 Years', since: 'now-3y', until: 'now' },
  { date: 'Last Calendar Month', since: 'now-1M/M', until: 'now-1M/M' },
];

export const TopSourceDates = {
  'last 24 hours': {
    now: {
      since: 'now-24h',
      until: 'now',
    },
    previous: {
      since: 'now-48h',
      until: 'now-24h',
    },
    label: 'last 24 hours',
  },
  'last 48 hours': {
    now: {
      since: 'now-48h',
      until: 'now',
    },
    previous: {
      since: 'now-96h',
      until: 'now-48h',
    },
    label: 'last 48 hours',
  },
  'last 7 days': {
    now: {
      since: 'now-7d',
      until: 'now',
    },
    previous: {
      since: 'now-14d',
      until: 'now-7d',
    },
    label: 'last 7 days',
  },
  'last 30 days': {
    now: {
      since: 'now-30d',
      until: 'now',
    },
    previous: {
      since: 'now-60d',
      until: 'now-30d',
    },
    label: 'last 30 days',
  },
  'last 60 days': {
    now: {
      since: 'now-60d',
      until: 'now',
    },
    previous: {
      since: 'now-120d',
      until: 'now-60d',
    },
    label: 'last 60 days',
  },
  'last calendar month': {
    now: {
      since: 'now-1M/M',
      until: 'now-1M/M',
    },
    previous: {
      since: 'now-2M/M',
      until: 'now-1M/M',
    },
    label: 'last month',
  },
  custom: {
    label: 'last date range',
  },
};
