import PropTypes from 'prop-types';
import React from 'react';

import { Row, Col } from 'react-flexbox-grid/lib';
import { fromJS } from 'immutable';
import {
  MoreVert,
  Refresh,
} from '@mui/icons-material';

import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import moment from 'moment';
import cx from 'classnames';
import DateFilter from '../../../components/filters/DateFilter';
import Prompt from '../../utils/Prompt';
import Token from '../../../utils/token';
import Dates from '../../../constants/Dates';
import { downloadPng } from './utils';
import easyUseEffect from '../../../hooks/easyUseEffect';
import easyUseState from '../../../hooks/easyUseState';

import UserActions from '../../../actions/userActions';

import {
  ExploreTopicsWidget,
  RecentIntelReportsWidget,
} from './Widgets';
import {
  CenterRowEditDialog,
  EditExploreTopicsDialog,
  EditIntelReportsDialog,
  OrgDialog,
  TopRowEditDialog,
} from './Dialogs';
import { CustomizableWidgets } from './CustomizableWidgets';
import { WidgetDefaults, FilterDefaults } from '../../../constants/ExposureDashboard';

import style from './homedashboard.module.scss';

const prefsMigrations = [
  {
    version: '1',
    // v1 -> v2
    migrationFunc: prefs => ({ ...prefs, version: '2' }),
  },
];

export const runAllMigrations = (oldPrefs) => {
  const startingMigrationIndex = prefsMigrations.findIndex(prefsMigration => (
    prefsMigration.version === oldPrefs.version
  ));
  if (startingMigrationIndex === -1) {
    return WidgetDefaults;
  }

  return prefsMigrations
    .slice(startingMigrationIndex)
    .reduce((prefsInProgress, currentMigration) => (
      currentMigration.migrationFunc(prefsInProgress)
    ), oldPrefs);
};

const getExpoDashDefaults = prefs => (
  prefs?.version === WidgetDefaults?.version ? prefs : runAllMigrations(prefs)
);

const makeDataByPreferences = (user) => {
  const preferences = user?.get('prefs')?.toJS();
  const recentSearches = preferences?.history?.search;
  const savedSearches = preferences?.search;
  const expoDashWidgets = getExpoDashDefaults(preferences?.expo_dash_widgets);
  return { preferences, recentSearches, savedSearches, expoDashWidgets };
};

const makeFilters = profile => (
  {
    ...profile?.toJS(),
    query: profile.get('query') || Token.get('ogn'),
  }
);

const HomeBetaDashboard = ({
  profile,
  user,
}) => {
  const [data, setData] = easyUseState(makeDataByPreferences(user));
  const [look, setLook] = easyUseState();
  const [dialog, setDialog] = easyUseState();
  const [filters, setFilters] = easyUseState(makeFilters(profile));

  const defaultQuery = () => filters?.query;

  const onDownload = () => {
    setDialog();
    downloadPng();
  };

  const addQueryToFilters = (query) => {
    setFilters(v => ({ ...v, ...query }));
  };

  const setDataByPreferences = () => {
    setData(v => ({ ...v, ...makeDataByPreferences(user) }));
  };

  const onUpdateConfig = (config) => {
    UserActions.savePrefs({
      filters: {
        expodash: [{ name: 'Default', filters: config, default: 'true' }],
      },
    });
    setDialog();
    addQueryToFilters(config);
  };

  // Resets the dashboard to its default state
  const onResetDash = async () => {
    setDialog();
    const defaults = { ...FilterDefaults, query: Token.get('ogn') };
    await UserActions.savePrefs({
      filters: {
        expodash: [defaults],
      },
      expo_dash_widgets: WidgetDefaults,
    });
    addQueryToFilters(defaults.filters);
  };

  /**
   * The EditWidgetDialog updates the expo_dash_widgets user pref
   */
  easyUseEffect(() => {
    setDataByPreferences();
  }, [user?.get('prefs', 'expo_dash_widgets')?.toJS()]);

  easyUseEffect(() => {
    if (!look) return;
    look.send('dashboard:filters:update', {
      filters: {
        'Alert Created Date': filters?.date || '7 Day',
      },
    });
    look.send('dashboard:run');

    // Reload customizable widget data when filters change
    if (!data?.savedSearches || !data?.expoDashWidgets) {
      addQueryToFilters(filters?.query || '');
    }
  }, [filters]);

  easyUseEffect(() => {
    const query = makeFilters(profile);
    addQueryToFilters(query);
  }, []);

  const onApplyTopRow = (value) => {
    const updatedWidgets = fromJS(data.expoDashWidgets)
      .setIn(['topRow', value.id], value);
    UserActions.savePrefs({ expo_dash_widgets: updatedWidgets.toJS() });
  };

  const onApplyCenterRow = (value) => {
    const updatedWidgets = fromJS(data.expoDashWidgets)
      .setIn(['centerRow', value.id], value);
    UserActions.savePrefs({ expo_dash_widgets: updatedWidgets.toJS() });
  };

  const tagIds = () => (data?.expoDashWidgets?.bottomRow?.[0]?.tagIds || []);

  const exploreTopicCategoryIdPrefs = () => (
    data?.expoDashWidgets?.bottomRow?.[1]?.categoryIds || []);

  const onApplyEditIntelReportsDialog = (tagIdArray) => {
    const updatedWidgets = fromJS(data.expoDashWidgets)
      .setIn(['bottomRow', 0, 'tagIds'], tagIdArray);
    UserActions.savePrefs({ expo_dash_widgets: updatedWidgets.toJS() });
  };

  const onApplyExploreTopicsDialog = (tagIdArray) => {
    const updatedWidgets = fromJS(data.expoDashWidgets)
      .setIn(['bottomRow', 1, 'categoryIds'], tagIdArray);
    UserActions.savePrefs({ expo_dash_widgets: updatedWidgets.toJS() });
  };

  return (
    <>
      <Row className={style.homeDashboard} style={{ maxWidth: '100%' }}>
        <Row className={style.header} style={{ width: '100%' }}>
          <Col xs={6}>
            <div className={cx([style.h0, 'h0'])}>
              {moment().format('dddd, MMMM D')}
            </div>
          </Col>
          <Col xs={6}>
            <Row end="xs">
              <Col xs={12}>
                <>
                  <Button
                    color="primary"
                    id="date"
                    name="date"
                    className={cx([style.h4, 'h4', style.open, style.dropdown, style.date])}
                    onClick={e => setDialog(({ key: 'date', target: e.currentTarget }))}
                    endIcon={<Icon className={cx([style.icon])}>date_range</Icon>}>
                    {filters?.date}
                  </Button>
                  <Popover
                    anchorEl={dialog && dialog.target}
                    open={Boolean(dialog && dialog.key === 'date')}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    onClose={() => setDialog()}>
                    <DateFilter
                      disableCustom
                      date={filters?.date}
                      since={filters?.since}
                      until={filters?.until}
                      dates={fromJS(Dates.filter(v => !/all|calendar|year|60|90|120/ig.test(v?.date)))}
                      toggle={() => setDialog()}
                      onApply={dates => onUpdateConfig({ ...filters, ...dates.toJS() })} />
                  </Popover>
                </>
                <IconButton onClick={() => addQueryToFilters(filters)}>
                  <Refresh />
                </IconButton>
                <IconButton onClick={() => onDownload()}>
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  onClick={e => setDialog({ key: 'config', target: e.currentTarget })}
                  className="settings"
                  >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="home.header.config"
                  anchorEl={dialog?.target}
                  onClose={() => setDialog()}
                  open={Boolean(dialog?.key === 'config')}
                  className="settingsMenu">
                  <MenuItem
                    className={[style.item, 'resetBtn'].join(' ')}
                    onClick={() => setDialog({ key: 'reset' })}>
                    Reset Dashboard
                  </MenuItem>
                </Menu>
              </Col>
            </Row>
          </Col>
        </Row>
        <CustomizableWidgets
          data={data}
          filters={filters}
          user={user}
          setLook={setLook}
          setDialog={setDialog} />
        <Row className={style.bottomRow} style={{ width: '100%' }}>
          <Col xs={6} className={style.containers}>
            <div className={cx(['recentIntelReports', style.flexGrowContainer])}>
              <RecentIntelReportsWidget
                tagIds={tagIds()}
                settings={() => { setDialog({ key: 'editIntelReports' }); }}
              />
            </div>
          </Col>
          <Col xs={6} className={style.containers}>
            <div className={cx(['exploreTopics', style.flexGrowContainer])}>
              <ExploreTopicsWidget
                categoryIds={exploreTopicCategoryIdPrefs()}
                settings={() => { setDialog({ key: 'editExploreTopics' }); }}
              />
            </div>
          </Col>
        </Row>
      </Row>
      <OrgDialog
        open={dialog?.key === 'orgname'}
        defaultValue={defaultQuery()}
        onApply={query => onUpdateConfig({ ...filters, query })}
        onCancel={() => setDialog()}
      />
      <TopRowEditDialog
        open={dialog?.key === 'topRow'}
        selectedTab={dialog?.tab}
        settings={dialog?.settings}
        onCancel={() => setDialog()}
        data={data}
        onApply={onApplyTopRow}
      />
      <CenterRowEditDialog
        data={data}
        open={dialog?.key === 'centerRow'}
        selectedTab={dialog?.tab}
        settings={dialog?.settings}
        onCancel={() => setDialog()}
        onApply={onApplyCenterRow}
        defaultQuery={defaultQuery()}
      />
      <EditIntelReportsDialog
        open={dialog?.key === 'editIntelReports'}
        onCancel={() => setDialog()}
        tagIdPrefs={tagIds()}
        onApply={onApplyEditIntelReportsDialog}
      />
      <EditExploreTopicsDialog
        open={dialog?.key === 'editExploreTopics'}
        onCancel={() => setDialog()}
        categoryIdPrefs={exploreTopicCategoryIdPrefs()}
        onApply={onApplyExploreTopicsDialog}
      />
      <Prompt
        open={Boolean(dialog?.key === 'reset')}
        title="Warning: Reset Dashboard"
        acceptText="Yes"
        accept={() => onResetDash()}
        cancelText="No"
        cancel={() => setDialog()}>
        Resetting this dashboard will remove any customizations you have made and
        restore it to its default state. Are you sure you wish to do this?
      </Prompt>
    </>
  );
};

HomeBetaDashboard.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default HomeBetaDashboard;
