export default {
  MuiButton: {
    size: 'small',
  },
  MuiFilledInput: {
    margin: 'dense',
  },
  MuiFormControl: {
    margin: 'dense',
  },
  MuiFormHelperText: {
    margin: 'dense',
  },
  MuiIconButton: {
    size: 'small',
  },
  MuiInputBase: {
    margin: 'dense',
  },
  MuiInputLabel: {
    margin: 'dense',
  },
  MuiListItem: {
    dense: true,
  },
  MuiOutlinedInput: {
    margin: 'dense',
  },
  MuiFab: {
    size: 'small',
  },
  MuiTable: {
    size: 'small',
  },
  MuiTextField: {
    margin: 'dense',
  },
  MuiToolbar: {
    variant: 'dense',
  },
};
