import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@flashpoint/fp-icons/pro-regular-svg-icons/faSliders';
import { fromJS, List as list, Map as map } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import makeStyles from '@mui/styles/makeStyles';
import {
  Icon,
  IconButton,
  TextField,

} from '@mui/material';

import style from './find.module.scss';
import SearchOverlay from '../SearchOverlay';
import SearchTypeSelection from '../SearchTypeSelection';
import Token from '../../../utils/token';
import SearchActions from '../../../actions/searchActions';
import Text from '../../../utils/text';
import History from '../../../utils/history';
import Suggestions from '../Suggestions/Suggestions';
import {
  SearchContext,
  UserContext,
} from '../../../components/utils/Context';
import SearchUtils from '../../../utils/searchUtils';
import SearchSectionsSearch from '../../../stores/recoil/searchSectionsSearch';
import { Track } from '../../../utils/track';
import SearchApps from '../../../constants/SearchApps';

const searchApps = fromJS(SearchApps);


const useStyles = makeStyles(theme => ({
  find: {
    '& .MuiInputBase-multiline': {
      padding: 0,
      '& textarea, fieldset': {
        border: 'none',
      },
    },
    '& .submit': {
      backgroundColor: '#5c6ae0',
      borderRadius: '0 .3rem .3rem 0',
      height: '4.8rem',
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiIcon-root': {
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: '#3547D9',
      },
      '&:disabled': {
        backgroundColor: '#C4C4C5',
      },
    },
  },
}));

const Find = ({
  inline,
  params,
  showSearchOverlay,
  setShowSearchOverlay,
}) => {
  const search = useContext(SearchContext);
  const user = useContext(UserContext);
  const { query: { query } } = History.getCurrentLocation();

  const classes = useStyles();
  const inputEl = useRef();
  const [focus, setFocus] = useState(false);
  const [suggest, setSuggest] = useState();
  const [error, setError] = useState('');
  const [typingTimer, setTypingTimer] = useState(null);
  const [searchTypes, setSearchTypes] = useState('');
  const [text, setText] = useState(query);
  const filters = useRecoilValue(SearchSectionsSearch.sectionsFilterState);

  const allMedia = 'images,videos';
  const allCommunities = `forums,chats,blogs,ransomware,pastes,boards,social${
    (user.get('prm').some(p => /twtr/ig.test(p)))
      ? ',twitter' : ''}`;
  const type = search.get('type');
  const apps = user.get('apps', list());
  const route = user.get('route', map());
  const searchTypeHeaders = apps
    .filter(v => v.get('search'))
    .filter(v => !v.get('all'))
    .filter(v => !v.get('exclude') || !user.get('prm').some(p => v.get('exclude').test(p)))
    .filter(v => (!v.get('internal') || user.get('prm').some(p => /org.fp.r/.test(p))))
    .filter(v => user.get('prm').some(p => v.get('test').test(p)))
    .filter(v => v.get('value') !== 'search.all')
    .map(v => ((v.has('subHeader')) ? v.get('subHeader').toLowerCase() : null))
    .filter(v => v)
    .toSet()
    .toList();

  const determineType = () => {
    let typeValue;
    // On close handle updates to the search
    // If we are on a single search type or all communities, use specific search page
    // otherwise use all search with all filter
    const searchTypesValue = searchTypes.split(',')
      .filter(v => !searchTypeHeaders.includes(v))
      .filter(v => !v.includes('customer-credentials'))
      .filter(v => v);

    const media = allMedia.split(',');
    const communities = allCommunities.split(',');
    if (searchTypesValue.length === 1) {
      [typeValue] = searchTypesValue;
    } else if (searchTypesValue.length === 0
      || (searchTypesValue.every(v => communities.includes(v)))) {
      typeValue = 'communities';
    } else if (searchTypesValue.join() === media.join()) {
      typeValue = 'media';
    } else {
      typeValue = 'all';
    }

    return typeValue;
  };

  const onSearch = (value = '', clear = false) => {
    // Replace duplicated spaces with a single space
    const updatedValue = value.replace(/[ ]{2,}/, ' ').trim();
    setText(updatedValue);

    // clear error values
    SearchActions.set(['search', 'info'], map());
    setError();

    // clear text input but dont run search
    if (clear) return;

    // prevent lengthy searches
    if (updatedValue && updatedValue.length > 3000) {
      setError('Query length can not exceed 3000 characters. Please update your query');
      return;
    }
    const balancedString = Text.BalancedString(updatedValue);
    if (updatedValue && balancedString !== true) {
      setError(`Malformed query with ${balancedString}. Please update your query`);
      return;
    }
    const {
      hash,
      pathname,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: { query: queryValue, ...rest },
    } = History.getCurrentLocation();
    const determinedType = determineType();
    const shared = [ // filters shared across basetypes
      'date',
      'since',
      'until',
      'fpid',
      ...['all', 'communities'].includes(determinedType) ? ['all'] : [],
      ...['boards', 'blogs', 'ransomware', 'cards', 'chats', 'communities', 'forums', 'marketplaces', 'pastes', 'social', 'twitter'].includes(determinedType)
        ? ['required_enrichments', 'cves', 'bins', 'ips', 'emails', 'urls', 'domains', 'monero-wallets', 'ethereum-wallets', 'bitcoin-wallets', 'handles', 'profiles']
        : [],
    ];

    switch (determinedType) {
      case 'all': {
        rest.skip = null;
        rest.all = searchTypes.split(',')
          .filter(v => !searchTypeHeaders.includes(v))
          .filter(v => v).join();
        break;
      }
      case 'media': {
        rest.skip = null;
        rest.all = searchTypes
          ? searchTypes.split(',')
            .filter(v => !searchTypeHeaders.includes(v))
            .filter(v => v).join()
          : undefined;
        if ((rest.all || allMedia).split(',').sort().join() === allMedia.split(',').sort().join()) delete rest.all;
        break;
      }
      case 'communities': {
        rest.skip = null;
        rest.all = searchTypes
          ? searchTypes.split(',')
            .filter(v => !searchTypeHeaders.includes(v))
            .filter(v => v).join()
          : undefined;
        if ((rest.all || allCommunities).split(',').sort().join() === allCommunities.split(',').sort().join()) delete rest.all;
        break;
      }
      default:
        rest.skip = null;
        break;
    }

    // Update rest with all our filter values,
    // (omitting query, since that is being updated here)
    const { all, skip } = rest;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { query: queryFromFilters, ...restFromFilters } = filters.toJS();
    const restWithFilters = { ...rest, ...restFromFilters, all, skip };

    // Disable translations if toggled by user via pager
    const query_i18n = !restWithFilters?.original ? Text.DetectLanguage(updatedValue) : null;

    const initialQuery = {
      ...Object.keys(restWithFilters)
        .filter(v => shared.includes(v))
        .reduce((a, b) => ({ ...a, [String(b)]: restWithFilters[String(b)] }), {}),
      query: updatedValue,
      // determine query language
      query_i18n,
    };

    const totalQuery = {
      ...initialQuery,
      ...restWithFilters,
    };

    // if coming from the same search page, use all existing filters. if coming
    // from a different type or detail page, only use basic filters
    const finalQuery = (pathname.indexOf('search') !== -1
      && (type === determinedType || filters?.get('all') === determinedType))
      ? fromJS(totalQuery).filter(v => v).toJS()
      : SearchUtils.mergeDefaults(initialQuery, determinedType);

    // If the user switches from any other basetype to 'reports' clear the date filter
    if (search.get('type') !== 'reports' && determinedType === 'reports') {
      delete finalQuery.date;
      delete finalQuery.since;
      delete finalQuery.until;
    }

    // find, search overlay, all filters
    const event = {
      type: determinedType,
      filters: finalQuery,
      origin: '.header .find input#text',
    };

    Track(event, 'Search');

    // reset focus
    setFocus();
    setSuggest();

    const basePath = (determinedType === 'reports')
      ? '/home/intelligence/reports/search'
      : `/home/search/${(determinedType) === 'chan' ? 'boards' : (determinedType)}`;

    History.navigateTo({
      pathname: basePath,
      query: finalQuery,
      hash: ((!type || type === determinedType) && hash.indexOf('detail') === -1) ? hash : '',
    });
  };

  const onBlur = (event) => {
    // blur event outside of component
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocus();
    }
  };

  const onChange = (e, v) => {
    let updatedText = Text.ReplaceSmartChars(v);
    updatedText = updatedText.replace(/\n/g, ' ');
    setText(updatedText);

    // handle checking if query is imbalanced as they type
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      const balancedString = Text.BalancedString(updatedText);
      if (updatedText && balancedString !== true) {
        setError(`Malformed query with ${balancedString}. Please update your query`);
      } else {
        setError();
      }
    }, 500);
    setTypingTimer(timer);

    const { target } = e;
    const { scrollHeight } = target;
    setFocus(updatedText && scrollHeight > 28); // 2.8rem line height
  };

  const onFocus = (e) => {
    const shouldShowTutorial = !Token.cke('X-FP-Tutorial');
    const { target } = e;
    const { scrollHeight } = target;
    setFocus(text && scrollHeight > 28); // 2.8rem line height

    if (!shouldShowTutorial) return;
    setTimeout(() => {
      SearchActions.set(['search', 'info'], map({
        action: 'view help guide',
        message: 'Need help with your search?',
        fn: () => History.navigateTo({
          pathname: '/home/resources/syntax',
          query: '',
        }),
      }));
    });
    Token.set('X-FP-Tutorial');
  };

  const onKeyDown = (e) => {
    // We are using a textarea which allows for newlines, which we don't want
    // in our search query. Listen for enter button and instead of adding a
    // newline, perform the search
    switch (e.code) {
      case 'Enter': {
        e.preventDefault();
        onSearch(e.target.value);
        break;
      }
      case 'Tab':
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();
        setSuggest(true);
        break;
      default: break;
    }
  };

  useEffect(() => {
    if (query !== text) {
      setText(query);
    }
  }, [query]);

  useEffect(() => {
    const storeError = search.getIn(['info', 'error'], '');
    if (storeError) setError(storeError);
  }, [search]);

  useEffect(() => {
    if (['all', 'communities'].includes(params.type)) {
      const { query: { all } } = History.getCurrentLocation();
      setSearchTypes(all || '');
    } else if (['media'].includes(params.type)) {
      setSearchTypes(allMedia);
    }
  }, [params]);


  return (
    <Grid
      className={cx([
        style.find,
        classes.find,
        inline && style.inline,
      ])}
      onBlur={event => onBlur(event)}>
      <Row>
        <Col xs={12}>
          <div className={style.fields}>
            <form
              className={style.form}
              onSubmit={(event) => {
                event.preventDefault();
                onSearch(text);
              }}>
              <SearchTypeSelection
                apps={searchApps}
                parentTypes={searchTypes}
                route={route}
                onSearch={() => onSearch(text)}
                setParentTypes={setSearchTypes}
                prm={user.get('prm')} />
              <div
                onFocus={(event) => {
                  setFocus(event);
                  setSuggest();
                }}
                className={cx([
                  style['input-container'],
                  error && style.error,
                  focus && style.focused,
                ])}>
                <div
                  className={style.suggestions}
                  style={{ top: `${inputEl.current?.offsetHeight}px` }}>
                  <Suggestions
                    focus={Boolean(suggest)}
                    text={text || ''}
                    type={determineType()}
                    prm={user?.get('prm')?.toJS()}
                    onBlur={() => { setFocus(); setSuggest(); }}
                    onSearch={onSearch} />
                  {error &&
                  <div className={style.error}>{error}</div>}
                </div>
                <TextField
                  id="text"
                  data-lpignore="true"
                  multiline
                  margin="none"
                  className={style.input}
                  placeholder={`Try searching for ${route ? route?.get?.('placeholder', '') : ''}...`}
                  value={text || ''}
                  ref={inputEl}
                  inputProps={{
                    dir: 'auto',
                    'data-testid': 'find.search',
                    onFocus: event => onFocus(event),
                  }}
                  onChange={event => onChange(event, event.target.value)}
                  onKeyDown={event => onKeyDown(event)} />
                <div style={{ display: 'flex', paddingRight: '20px' }}>
                  {text &&
                  <Icon
                    onClick={() => onSearch('', !inline)}
                    className={cx([style.icon, style.hover, style.close, 'material-icons'])}>close
                  </Icon>}
                  <Icon
                    onClick={() => setShowSearchOverlay(true)}
                    className={cx([style.icon, style.hover, style.expand, 'material-icons'])}>
                    <FontAwesomeIcon icon={faSliders} />
                  </Icon>
                </div>
                {text && text.length > 200 &&
                <div
                  className={cx([
                    style.textlimit,
                    text.length > 3000 && style.error,
                  ])}>
                  {`${text.length}/3000`}
                </div>}
              </div>
              <IconButton
                type="submit"
                className="submit"
                disabled={Boolean(text && text.length > 3000)}
                size="large">
                <Icon>search</Icon>
              </IconButton>
            </form>
          </div>
          {showSearchOverlay &&
          <SearchOverlay
            apps={searchApps}
            filters={filters}
            route={route}
            onClose={() => setShowSearchOverlay(false)}
            search={search}
            show={showSearchOverlay}
            types={searchTypes}
            user={user} />}
        </Col>
      </Row>
      <ReactTooltip id="search.tooltip" place="right" effect="solid" />
    </Grid>
  );
};

Find.propTypes = {
  inline: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  showSearchOverlay: PropTypes.bool.isRequired,
  setShowSearchOverlay: PropTypes.func.isRequired,
};

export default Find;
