import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';

import RfiConfirmation from '../rfi/RfiConfirmation';
import RfiError from '../rfi/RfiError';
import RfiForm from '../rfi/RfiForm/RfiForm';
import Query from '../rfi/query';
import handleSubmit from '../rfi/util';
import SearchActions from '../../actions/searchActions';

const RequestForInformation = ({ user }) => {
  const [submitted, setSubmitted] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleRefresh = async sid => (
    Query.getFormData(sid)
      .then(res => setData({
        ...res,
        user_email: user.get('email'),
        organization: user.get('ogn'),
      }))
  );

  useEffect(() => window.scrollTo(0, 0), [submitted]);

  useEffect(() => {
    SearchActions.loadResources();

    /* Get URL Params */
    const query = window.location.search.replace(/\?/, '&');
    if (query) {
      const params = qs.parse(query);
      setQueryParams(params);
    }

    /* Load in the data from Salesforce */
    if (user) {
      handleRefresh(user.get('sf_id'));
    }
  }, [submitted]);

  const _setLoading = v => setLoading(v);
  const _setError = v => setError(v);
  const done = () => {
    setSubmitted(!submitted);
    setLoading(false);
  };

  if (error) {
    return (<RfiError
      onSubmit={() => {
        setSubmitted(!submitted);
        setError();
      }}
      message={error}
      />);
  } else if (submitted) {
    return <RfiConfirmation onSubmit={() => setSubmitted(!submitted)} />;
  }

  return (
    <RfiForm
      onSubmit={d => handleSubmit(d, _setLoading, _setError, done)}
      onRefresh={sid => handleRefresh(sid)}
      queryParams={queryParams}
      data={data}
      loading={loading}
      />
  );
};

RequestForInformation.propTypes = {
  user: PropTypes.object,
};

RequestForInformation.defaultProps = {
  user: null,
};

export default RequestForInformation;
