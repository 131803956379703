export default {
  type: 'light',
  primary: {
    main: '#5c6ae0',
    accent: '#3547D9',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#FFF',
    accent: '#F6F7FD',
    contrastText: '#2A2C3C',
  },
  tertiary: {
    main: '#7A84A1',
    dark: '#484E62',
  },
  navigation: {
    main: '#FFF',
    contrastText: '#2A2C3C',
  },
};
