/* eslint-disable no-param-reassign, react/no-array-index-key, no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import Papa from 'papaparse';

import {
  Button,
  CircularProgress,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GetAppIcon from '@mui/icons-material/GetApp';

import { sanitizeBinTags, sanitizeBinPFL } from '../../../../../utils/cfm';

import { devClient } from '../../../../../gql/apolloClient';
import query, { bulkEditBins, ownedBin } from '../query';

const useStyles = makeStyles(() => ({
  input: {
    marginLeft: '4%',
    marginTop: '3%',
    textAlign: 'center',
    margin: '0 auto',
  },
  warning: {
    display: 'flex',
    margin: 0,
    position: 'absolute',
  },
  errors: {
    overflow: 'hidden',
    marginLeft: '4%',
  },
  parserErrors: {
    overflow: 'hidden',
    marginLeft: '4%',
    marginTop: '4%',
    color: 'red',
    textAlign: 'center',
  },
  success: {
    marginTop: '3%',
    marginBottom: '5%',
    marginLeft: '4%',
  },
  button: {
    margin: '2.5%',
  },
  download: {
    textAlign: 'center',
    marginTop: '3%',
  },
  shopsError: {
    marginTop: '3%',
    marginLeft: '4%',
    marginRight: '4%',
  },
}));

const CSVImport = ({ variables, close }) => {
  const classes = useStyles();
  const [bins, setBins] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);
  const [largeFileSize, setLargeFileSize] = useState(false);
  const [parserError, setParserError] = useState(false);
  const [foundErrors, setFoundErrors] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    invalidData: {
      invalidNumberLength: [],
      invalidNumberContent: [],
      invalidBinTags: [],
      invalidBinPFLLength: [],
      invalidBinPFLContent: [],
    },
    alreadyTracking: [],
    duplicateBin: [],
    blanks: [],
  });

  const [bulkAdd, {
    loading,
    error,
    data,
  }] = useMutation(bulkEditBins, {
    refetchQueries: [{ query, variables }],
    client: devClient,
  });

  const [binsCheck, {
    loading: ownedBinLoading,
  }] = useLazyQuery(ownedBin, {
    client: devClient,
    onCompleted: (completedData) => {
      if (completedData.ownedOrWatchedBins) {
        const duplicates = completedData.ownedOrWatchedBins.map(b => b.number);
        errors.alreadyTracking = errors.alreadyTracking.concat(duplicates);
        setErrors(errors);

        const updateData = {
          ...variables,
          bins: bins.filter(r => !duplicates.includes(r.binNumber)),
        };

        bulkAdd({ variables: updateData });
      } else {
        const updateData = {
          ...variables,
          bins,
        };

        bulkAdd({ variables: updateData });
      }
    },
  });

  const onSubmit = () => {
    bins.forEach((r) => {
      r.binTags = r.binTags ? r.binTags.map(name => ({ name })) : [];
      r.binNumber = Number(r.binNumber);
    });

    setBins(bins);

    binsCheck({
      variables: {
        salesforceId: variables.salesforceId,
        binNumbers: bins.map(b => b.binNumber),
      },
    });

    setSubmitted(true);
  };

  const onClose = () => {
    setSubmitted(false);
    close();
  };

  const normalizeBinData = (bin) => {
    if (bin.BIN === undefined || bin['BIN Tags'] === undefined) {
      setParserError(true);
    }

    const normalizedBin = {
      binNumber: bin.BIN,
      binRoi: sanitizeBinPFL(bin['BIN Potential Fraud Loss']),
      binTags: bin['BIN Tags'] ? bin['BIN Tags'].split(',') : [],
    };

    normalizedBin.binTags = sanitizeBinTags(normalizedBin.binTags);

    return normalizedBin;
  };

  const validateBinNumber = (number) => {
    if (!number) {
      errors.blanks.push('blank');
      setErrors(errors);
      return false;
    }

    else if (number.toString().length > 6) {
      errors.invalidData.invalidNumberLength.push(number);
      setErrors(errors);
      return false;
    }

    else if (!/^\d+$/.test(number)) {
      errors.invalidData.invalidNumberContent.push(number);
      setErrors(errors);
      return false;
    }

    else if (number.toString().length < 6) {
      errors.invalidData.invalidNumberLength.push(number);
      setErrors(errors);
      return false;
    }

    else if (bins.some(r => r.binNumber === number)) {
      errors.duplicateBin.push(number);
      setErrors(errors);
      return false;
    }

    return true;
  };

  const validateBinTags = (number, tags) => {
    const bad_tags = [];

    tags.forEach((tag) => {
      if (/[^a-zA-Z0-9-_ ]/.test(tag)) {
        bad_tags.push(tag);
      }
    });

    if (bad_tags.length !== 0) {
      errors.invalidData.invalidBinTags.push(`${number}: ${bad_tags.join(', ')}`);
      setErrors(errors);
      return false;
    }

    return true;
  };

  const validateBinPFL = (number, pfl) => {
    if (pfl && pfl.length > 15) {
      errors.invalidData.invalidBinPFLLength.push(`${number}: ${pfl}`);
      setErrors(errors);
      return false;
    }

    else if (pfl && !/^\d{0,15}\.?(\d{0,2})$/.test(pfl)) {
      errors.invalidData.invalidBinPFLContent.push(`${number}: ${pfl}`);
      setErrors(errors);
      return false;
    }

    return true;
  };

  const validateBinData = (bin) => {
    if (!validateBinNumber(bin.binNumber)) {
      setFoundErrors(true);
      return false;
    }

    if (!validateBinPFL(bin.binNumber, bin?.binRoi?.value)) {
      setFoundErrors(true);
      return false;
    }

    if (!validateBinTags(bin.binNumber, bin.binTags)) {
      setFoundErrors(true);
      return false;
    }

    return true;
  };

  const validateFile = (file) => {
    if (file.size > 1000000) {
      setLargeFileSize(true);
    }

    else if (file.type === 'text/csv') {
      setInvalidFile(false);
    }

    else {
      setInvalidFile(true);
      setUploaded(false);
    }
  };

  const onUpload = (file) => {
    setLargeFileSize(false); // for retries of an upload after error with file size
    validateFile(file);

    if (!invalidFile) {
      setParserError(false); // for retries of an upload after an error found while parsing
      Papa.parse(file, {
        worker: true, // Don't bog down the main thread if its a big file
        header: true, // interpret the first row as field names
        // numeric and boolean data will be converted to their type instead of remaining strings
        dynamicTyping: true,
        skipEmptyLines: true,
        step: (result, parser) => {
          if (result.errors.length > 0) {
            // immediately abort when encountering an error on file
            setParserError(true);
            parser.abort();
          }
          const newBin = normalizeBinData(result.data);
          if (validateBinData(newBin)) {
            bins.push(newBin);
          }
        },
        complete: () => {
          setBins(bins);
          setUploaded(true);
        },
      });
    }
  };

  const displayErrorMessage = (message) => {
    const checkForSixDigitBIN = /^[\w: ]+\d{6}[\w ]+/.test(message);

    if (checkForSixDigitBIN) {
      const erroredBIN = message.match(/\d{6}/).join('');
      return (
        <p>
          Unable to add BIN {erroredBIN}, please reach out to your Customer Success
          Representative.
          <br /><br />
          <b>
            NOTE: Only the valid BINs before this BIN were added and the data for them
            will appear in approximately 24 hours.
            You will need to refresh your screen to see the most updated list.
          </b>
        </p>);
    }

    return (
      <p>
        Please reach out to your Customer Success Representative.
      </p>
    );
  };

  return (
    <Paper>
      <div className={classes.input}>
        <input
          type="file"
          accept=".csv"
          name="bin-list"
          onChange={e => onUpload(e.target.files[0])}
          disabled={uploaded && !parserError && !largeFileSize}
        />
        {!uploaded &&
          <p>
            NOTE: Only 500 BINs can be uploaded through this feature. Please reach out to your
            customer success representative, if you have more than 500 BINs.
          </p>
        }
      </div>
      {(!uploaded || parserError) &&
        <div className={classes.download}>
          <p>
            Don't have a CSV template?
          </p>
          <Button
            variant="contained"
            size="large"
            href="/static/bin_import_template.csv"
            endIcon={<GetAppIcon />}>
            Download One Here
          </Button>
        </div>
      }
      {(loading || ownedBinLoading) && <CircularProgress size={25} thickness={2} />}
      {data &&
        <div className={classes.success}>
          Bins has been saved successfully!
          <p className={classes.warning}>
            NOTE: Data for newly added or modified BINs will appear in approximately 24 hours.
          </p>
        </div>
      }
      {parserError &&
        <div className={classes.parserErrors}>
          This file is not formatted correctly. Please ensure your file is a CSV file and
          that the headers match the headers on the template!
        </div>
      }
      {largeFileSize &&
        <div className={classes.parserErrors}>
          This file is too large, the size of the file should be at most 1 MB!
        </div>
      }
      {foundErrors && submitted && !error && data &&
        <div className={classes.errors}>
          NOTE: Below are the bins that were not added along with the reason.
          {errors.invalidData.invalidNumberLength.length !== 0 &&
            <p>
              - BIN was not exactly 6 digits long: {
                  errors.invalidData.invalidNumberLength.toString()
                }
            </p>
          }
          {errors.invalidData.invalidNumberContent.length !== 0 &&
            <p>
              - BIN contained non numerical values: {
                  errors.invalidData.invalidNumberContent.toString()
                }
            </p>
          }
          {errors.invalidData.invalidBinPFLLength.length !== 0 &&
            <p>
              - BIN Potential Fraud Loss was greater than 15 digits: {
                  errors.invalidData.invalidBinPFLLength.toString()
                }
            </p>
          }
          {errors.invalidData.invalidBinPFLContent.length !== 0 &&
            <p>
              - BIN Potential Fraud Loss contained more than two decimal places or had
              non numerical values: {
                  errors.invalidData.invalidBinPFLContent.toString()
                }
            </p>
          }
          {errors.invalidData.invalidBinTags.length !== 0 &&
            <p>
              - BIN tags contained special characters: {
                  errors.invalidData.invalidBinTags.toString()
                }
            </p>
          }
          {errors.alreadyTracking.length !== 0 &&
            <p>
              - BIN is already being tracked: {errors.alreadyTracking.toString()}
            </p>
          }
          {errors.duplicateBin.length !== 0 &&
            <p>
              - Duplicate bin in CSV file: {errors.duplicateBin.toString()}
            </p>
          }
        </div>
      }
      {error &&
      <div className={classes.shopsError}>
        An error has occurred while adding BINs: {displayErrorMessage(error.message)}
      </div>}
      {
        !(loading || ownedBinLoading) && !data ?
          <React.Fragment>
            <Button
              className={classes.button}
              type="submit"
              onClick={onSubmit}
              disabled={!uploaded || parserError || largeFileSize}
              label="submit">
              Submit
            </Button>
            <Button onClick={close} label="close">Close</Button>
          </React.Fragment> :
          <Button className={classes.button} onClick={onClose} label="close">Close</Button>
      }
    </Paper>
  );
};

CSVImport.propTypes = {
  close: PropTypes.func.isRequired,
  variables: PropTypes.object,
};

CSVImport.defaultProps = {
  variables: {},
};

export default CSVImport;
