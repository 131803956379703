import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const SEARCH_HELP_URL = 'https://docs.flashpoint.io/flashpoint/docs/section-three-conducting-searches';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    padding: '14px 0 0 0',
  },
  topActorHeading: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    marginBottom: '14px',
  },
  explanationText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0.15px',
    marginBottom: '30px',
  },
  notSureHow: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
}));

interface SettingsTabProps {
  children: JSX.Element[] | JSX.Element;
  headerText: string;
  explanationText: string;
}

export const SettingsTab = ({
  children,
  headerText,
  explanationText,
}: SettingsTabProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}>
      <div className={classes.topActorHeading}>
        {headerText}
      </div>
      <div className={classes.explanationText}>
        {explanationText}
      </div>
      <div style={{ marginBottom: '2px' }}>
        Saved Search
      </div>
      <div>
        {children}
      </div>
      <div className={classes.notSureHow}>
        Not sure how to create a saved search? Learn how
        <a target="_blank" rel="noreferrer" href={SEARCH_HELP_URL}>
                  &nbsp;here.
        </a>
      </div>
    </div>
  );
};
