import PropTypes from 'prop-types';
import React, { useState } from 'react';

import qs from 'qs';
import cx from 'classnames';
import moment from 'moment';

import ReactTooltip from 'react-tooltip';
import { List as list, Map as map, fromJS } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Icon,
  ListItem,
  Paper,
  Popover,
} from '@mui/material';

import style from './cves.module.scss';
import Text from '../../utils/text';
import Invalid from '../utils/Invalid/Invalid';
import SearchActions from '../../actions/searchActions';
import Datapoints from '../../utils/datapoints';
import Table from '../widget/Table/Table';
import Messages from '../../constants/Messages';
import Common from '../../utils/common';
import InternalLink from '../utils/InternalLink';
import History from '../../utils/history';

// eslint-disable-next-line @typescript-eslint/no-shadow
export const CVSSV3Metrics = ({ data, style }) => (
  data.getIn(['cve', 'nist', 'cvssv3']) ?
    <div>
      <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>CVSSV3</div>
      <div className={style.table}>
        <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Base Score:</div>
        <div>{data.getIn(['cve', 'nist', 'cvssv3', 'base_score']) || '-'}</div>
      </div>
      <div className={style.table}>
        <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Vector:</div>
        <div>
          <a
            href={`https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${data.getIn(['cve', 'nist', 'cvssv3', 'vector_string']) || '-'}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => Common.Events.DispatchExternalLinkEvent(`https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${data.getIn(['cve', 'nist', 'cvssv3', 'vector_string']) || '-'}`, e)}>
            {data.getIn(['cve', 'nist', 'cvssv3', 'vector_string']) || '-'}
          </a>
        </div>
      </div>
    </div> : null
);

// eslint-disable-next-line @typescript-eslint/no-shadow
export const CVSSV2Metrics = ({ data, style }) => (
  data.getIn(['cve', 'nist', 'cvssv2']) ?
    <div>
      <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>CVSSV2</div>
      <div className={style.table}>
        <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Base Score:</div>
        <div>{data.getIn(['cve', 'nist', 'cvssv2', 'base_score']) || '-'}</div>
      </div>
      <div className={style.table}>
        <div className={cx([style.h4, 'h4', style.mont, 'mont'])}>Vector:</div>
        <div>
          <a
            href={`https://nvd.nist.gov/vuln-metrics/cvss/v2-calculator?vector=(${data.getIn(['cve', 'nist', 'cvssv2', 'vector_string']) || '-'})`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => Common.Events.DispatchExternalLinkEvent(`https://nvd.nist.gov/vuln-metrics/cvss/v2-calculator?vector=(${data.getIn(['cve', 'nist', 'cvssv2', 'vector_string']) || '-'})`, e)}>
            {data.getIn(['cve', 'nist', 'cvssv2', 'vector_string']) || '-'}
          </a>
        </div>
      </div>
    </div> : null
);

CVSSV3Metrics.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

CVSSV2Metrics.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export const onCopy = (value) => {
  navigator.clipboard.writeText(value);
  SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
};

export const onExport = (type, id, fields) => {
  SearchActions.set(['search', 'info', 'message'], Messages.Exporting);
  SearchActions.set(['search', 'result', 'export', 'fields'], fields);
  SearchActions.search(type, id, true);
};

export const onRoute = (row) => {
  const target = Common.Generic.Route(row);
  History.navigateTo(target);
};

export const linkToExploits = () => {
  const { query } = History.getCurrentLocation();
  const link = `/home/search/exploits?query=${query.query}`;
  return link;
};

const CVEs = ({
  data,
  exploits,
  mentions,
}) => {
  const [dialog, setDialog] = useState();

  const onShare = (strip) => {
    const { pathname, query, hash } = History.getCurrentLocation();
    const { limit, skip, sort } = query;
    const fpid = data.get('fpid');
    const search = `${qs.stringify(strip ? { fpid, limit, skip, sort } : { ...query, fpid })}`;
    navigator.clipboard.writeText(`${window.location.origin}${pathname}?${search}${hash}`);
    SearchActions.set(['search', 'info', 'message'], Messages.CopiedClipboard());
  };

  return (
    <Grid
      fluid
      name="component.vulnerabilities"
      className={cx([style.base, style.cves])}>
      {!data.has('id') &&
      <Row>
        <Col xs={12}>
          <CircularProgress />
        </Col>
      </Row>}
      {data.has('id') &&
      <div>
        <Row>
          <Col xs={12} className={style.header}>
            <div className={style.title}>
              <Icon className={style.icon}>
                bug_report
              </Icon>
              <div
                className={cx([style.h0, 'h0', style.raj, 'raj', style.cap, 'cap'])}
                name="cves.header.title">
                {Text.Highlight(data.getIn(['highlight', 'title', 0]) ||
                  data.get('title'))}
              </div>
            </div>
            {data.hasIn(['sort_date']) &&
            <div>
              <div className={cx([style.h4, 'h4', style.cap, 'cap'])}>Date:</div>
              <div
                className={cx([style.h4, 'h4', style.cap, 'cap'])}
                name="sort_date">
                {`${moment
                  .utc(data.getIn(['sort_date']))
                  .format('MMM DD, YYYY HH:mm')}`}
              </div>
              <Icon
                name="accounts.date"
                data-for="date.tooltip"
                data-tip="The date the CVE was last seen by Flashpoint."
                className={style.help}>
                help
              </Icon>
            </div>}
          </Col>
          <Col xs={12}>
            <Paper className={style.card}>
              <div className={style.body}>
                <div className={style.container}>
                  <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>MITRE Data</div>
                  <Icon
                    data-for="share.tooltip"
                    data-tip="Generate share link"
                    onClick={event => setDialog({ key: 'share', target: event.target })}>
                    share
                  </Icon>
                  <Popover
                    anchorEl={dialog && dialog.target}
                    open={Boolean(dialog && dialog.key === 'share')}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClick={() => setDialog()}
                    onClose={() => setDialog()}>
                    {[window.location.search.includes('query=') &&
                      'Share URL with <x-fp-highlight>highlighting</x-fp-highlight>',
                      'Share URL']
                      .filter(v => v)
                      .map((v, k) => (
                        <ListItem
                          key={v}
                          onClick={() => onShare(!!k)}
                          className={style.menu}>
                          {Text.Highlight(v)}
                        </ListItem>))}
                  </Popover>
                </div>
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Description:</div>
                  <div>{data.getIn(['cve', 'mitre', 'body', 'text/plain'])}</div>
                </div>
                <CVSSV3Metrics data={data} style={style}/>
                <CVSSV2Metrics data={data} style={style}/>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])} />
                <div>
                  <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>CVE Mentions</div>
                  {/* loading mentions */}
                  {!data.isEmpty() && mentions.isEmpty() &&
                  <Row>
                    <Col xs={12}>
                      <CircularProgress />
                    </Col>
                  </Row>}
                  {/* mentions loaded */}
                  {!data.isEmpty() && !mentions.isEmpty() && !mentions.get('data').isEmpty() &&
                  <Table
                    pagination
                    header
                    onOffset={skip => SearchActions.search('cves.inline', data.get('fpid'), false, { skip, cve_title: data.get('title') }, true)}
                    onClick={(_, v, k) => onRoute(v.getIn(['data', k]))}
                    results={fromJS({
                      total: mentions.get('total'),
                      data: mentions
                        .get('data')
                        .filter(v => v.hasIn(['sort_date']))
                        .sortBy(v => -(moment.utc(v.getIn(['sort_date'])).unix())),
                      skip: mentions.get('skip') })}
                    values={fromJS([
                        { key: 'sort_date', label: 'date', render: v => (v ? moment.utc(v).format('MM/DD/YYYY HH:mm:ss') : '-'), style: { width: '75px' } },
                        { key: 'site_actor.names.handle', label: 'author', style: { width: '25%' }, tooltip: true },
                        { key: 'container.title', label: 'source', tooltip: true },
                        { key: 'site.title', label: 'site', tooltip: true },
                      ])}
                  />}
                  {/* no mentions */}
                  {data.has('id') && mentions.get('data') && mentions.get('data').isEmpty() &&
                  <Row>
                    <Col xs={12}>
                      <Paper className={cx([style.card, style.disabled])}>
                        <div className={style.empty}><div>No CVE Mentions</div></div>
                      </Paper>
                    </Col>
                  </Row>}
                </div>
                <div className={cx([style.h2, 'h2', style.mont, 'mont'])} />
                <div className={style.table} />
                {data.getIn(['cve', 'nist', 'vulnerability_types']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Vulnerability: </div>
                  <div>{data
                      .getIn(['cve', 'nist', 'vulnerability_types'])
                      .map((v) => {
                        const vulnerabilityNum = v.split('-').pop();
                        return (vulnerabilityNum === 'Other'
                          ? <div>{v}</div>
                          :
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://cwe.mitre.org/data/definitions/${vulnerabilityNum}.html`}
                            onClick={e => Common.Events.DispatchExternalLinkEvent(`https://cwe.mitre.org/data/definitions/${vulnerabilityNum}.html`, e)}
                            key={v}
                            className={cx([style.h4, 'h4', style.mont, 'mont', style.normal, 'normal',
                              style.a, 'a', style.vulnerability])}>
                            {v}
                          </a>);
                      })}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'configurations']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>CPEs: </div>
                  <div>{data
                      .getIn(['cve', 'nist', 'configurations'])
                      .map(v => (
                        <InternalLink
                          key={`${v.get('cpe23_uri')}.${v.get('version_end_including')}`}
                          to={{
                            pathname: '/home/search/exploits',
                            query: { query: v.get('cpe23_uri', '') },
                          }}
                          className={cx([style.a, 'a'])}>
                          {v.get('cpe23_uri')}
                        </InternalLink>))}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'products']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>Products:</div>
                  <div>{Text.Highlight(data
                      .getIn(['cve', 'nist', 'products'])
                      .map(v => `${Text.Sentence(v.get('vendor_name'))} ${Text.Sentence(v.get('product_name'))}`)
                      .join('<br />'))}
                  </div>
                </div>}
                {data.getIn(['cve', 'nist', 'references']) &&
                <div className={style.table}>
                  <div className={cx([style.h4, 'h4', style.mont, 'mont', style.top])}>References:</div>
                  <div>{Text.Highlight(data
                      .getIn(['cve', 'nist', 'references'])
                      .map(v => `<b>Type</b> ${(v.get('tags') || []).join(', ') || '-'}<br />
                        <b>URL</b> <a class="a" target="_blank">${v.get('url') || '-'}</a>`)
                      .join('<br /><br />'))}
                  </div>
                </div>}
                {!exploits.isEmpty() && !exploits.get('data').isEmpty() &&
                <div>
                  <div className={style.header}>
                    <div className={cx([style.h2, 'h2', style.mont, 'mont'])}>Potential Exploits:</div>
                    <div className={style.tools}>
                      <Icon
                        name="table.tools.export"
                        data-for="export.tooltip"
                        data-tip="Export records"
                        onClick={() => onExport(
                          'cves.meta',
                          data.get('fpid'),
                          [
                            Datapoints.exploits.observed,
                            Datapoints.exploits.site_uri,
                            Datapoints.exploits.source_uri,
                          ])}>
                        get_app
                      </Icon>
                    </div>
                  </div>
                  <div
                    data-tip="Click to copy source URI to clipboard"
                    data-for="exploit.tooltip">
                    <Table
                      pagination
                      header
                      onClick={(_, v, k) => onCopy(
                        `https://${v.getIn(['data', k, 'site', 'source_uri'])}/${v.getIn(['data', k, 'source_uri'])}`)}
                      onOffset={skip => SearchActions.search('cves.meta', data.get('fpid'), false, { skip, cve_title: data.get('title') }, true)}
                      results={fromJS({
                        data: exploits.get('data'),
                        total: exploits.get('total'),
                        skip: exploits.get('skip'),
                        link: linkToExploits(),
                      })}
                      widget={fromJS({ limit: 25 })}
                      values={fromJS([
                        Datapoints.exploits.created,
                        Datapoints.exploits.source_uri,
                      ])}
                    />
                  </div>
                </div>}
              </div>
            </Paper>
          </Col>
        </Row>
      </div>}
      {data.has('total') && data.get('total') === 0 &&
      <Invalid icon="error_outline" title="Item not found" />}
      <ReactTooltip id="cve.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="date.tooltip" html place="bottom" effect="solid" />
      <ReactTooltip id="share.tooltip" place="left" effect="solid" />
      <ReactTooltip id="exploit.tooltip" place="right" effect="solid" />
      <ReactTooltip id="export.tooltip" place="right" effect="solid" />
    </Grid>
  );
};

CVEs.propTypes = {
  data: PropTypes.object,
  exploits: PropTypes.object,
  mentions: PropTypes.object,
};

CVEs.defaultProps = {
  data: map(),
  exploits: map(),
  mentions: list(),
};

export default CVEs;
