import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import ReactTooltip from 'react-tooltip';

import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import { fromJS, Map as map } from 'immutable';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete as MuiAutoComplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Icon,
  InputAdornment,
  InputLabel,
  ListItem,
  OutlinedInput,
  Select,
  Slider,
  Switch,
  TextField,
} from '@mui/material';

import EditableChip from '../utils/EditableChip';
import style from './textfilter.module.scss';
import ImagePhashFilter from './ImagePhashFilter';
import Autocomplete from '../../constants/Autocomplete';
import History from '../../utils/history';
import NestedCheckbox from '../utils/NestedCheckbox';
import Text from '../../utils/text';
import { Track } from '../../utils/track';

const useStyles = makeStyles(theme => ({
  textfilter: {
    '& .MuiAutocomplete-inputRoot': {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
    },
    '& .MuiFormControl-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& .MuiInputLabel-outlined': {
      backgroundColor: '#fff',
    },
    '& .MuiFormControlLabel-root': {
      'margin-left': '0px',
    },
  },
}));

const TextFilter = ({
  advanced,
  blur,
  blurFields,
  blurFunc,
  defaults,
  fields,
  filters,
  help,
  inline,
  loading,
  onApply,
  onFilter,
  onMount,
  stacked,
  testId,
  toggle,
  type,
  user,
  white,
  className,
}) => {
  const classes = useStyles();
  const [active, setActive] = useState([]);
  const [values, setValues] = useState(map());
  const [references, setReferences] = useState(map());
  const [asyncLoading, setAsyncLoading] = useState(false);

  useEffect(() => {
    setValues(filters);
  }, [filters]);

  useEffect(() => {
    const referenceValues = fields
      .filter(v => v.type === 'chips')
      .map(((v) => {
        const ref = React.createRef();
        return { [v.value]: ref };
      }));
    setReferences(map({
      ...references.toJS(),
      ...Object.assign({}, ...referenceValues),
    }));

    // Set chip input parent width to 100%
    const chipInputs = document.querySelectorAll('[class*="input"][class*="chip"] input');
    chipInputs.forEach((v) => {
      v.parentElement.style.width = '100%'; // eslint-disable-line no-param-reassign
    });
  }, [fields]);

  useEffect(() => {
    if (!values.get('customer_id')) return;
    if (user.getIn(['org_profiles', values.get('customer_id'), 'edm'])) {
      setAsyncLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!onMount) return;
    onMount();
  }, []);

  const onReset = () => {
    let updates = values;
    [...fields].forEach((v) => {
      updates = updates.set(v.value, defaults.get(v.value) || '');
    });
    setValues(updates);
  };

  const onChange = (k, v, event) => {
    const cursorStart = event?.target?.selectionStart;
    const cursorEnd = event?.target?.selectionEnd;
    if (onFilter) onFilter({ [k]: v });
    else {
      if (k === 'customer_id') {
        // Need to clear the domain/password complexity filters
        const updatedValues = values
          .set('meets_org_profile_password_complexity', '')
          .set('optional', '')
          .set('num_optional', '')
          .set('required', '')
          .set('excluded', '')
          .set('length', '')
          .set('domain', '')
          .set(k, v);
        setValues(updatedValues);
      }
      setValues(vals => vals.set(k, v));
    }
    // handle cursor placement after state update
    // PLATFORM-3095 https://github.com/mui-org/material-ui/issues/12779
    if (cursorStart && cursorEnd) {
      setTimeout(() => event.target.setSelectionRange(cursorStart, cursorEnd));
    }
    return values;
  };

  const onSearch = () => {
    let value = values;
    if (inline) return;
    if (value.get('search')) {
      // Need to make sure changes to filters still work while in image/video search
      const searchType = value.get('search');
      const hasMedia = value.get('media_v2') && value.get('media_v2') === 'true';
      const mediaType = value.get('media_type');
      switch (value.get('search')) {
        case 'video':
          value = value.set('search', (hasMedia && mediaType === 'Video') ? searchType : undefined);
          break;
        case 'image':
        case 'fluid':
          value = value.set('search', (hasMedia && mediaType === 'Image') ? searchType : undefined);
          break;
        default:
          break;
      }
    }
    if (onApply) {
      onApply(value);
      toggle();
      return;
    }
    toggle(value);
    const { pathname, query, hash } = History.getCurrentLocation();

    const event = {
      type,
      filters: { ...value.toJS() },
      origin: '.base .form input#textfilter',
    };

    Track(event, 'Search');

    History.push({
      pathname,
      query: fromJS({
        ...query,
        ...value.filter((v, k) => (v !== '' && v != null) || v !== filters.get(k)).toJS(),
        skip: undefined,
      }).filter(f => f != null).toJS(),
      hash,
    });
  };

  const isToggled = field => (
    values.has(field.value)
      ? ([true, 'true'].includes(values.get(field.value)))
      : defaults.get(field.value) || false);

  const fieldOpts = field => (!field.opts ? Autocomplete[field.value] :
    field.opts?.toJS?.() || field.opts);

  const computeValueArrayFromField = field =>
    values.get(field.value, '')
    .replace(/["]+/g, '')
    .split(',')
    .map(v => (v.includes('::')
    ? { label: v.split('::')[0], value: v.split('::')[1] }
    : v));


  return (
    <Grid
      fluid
      data-testid={testId}
      className={cx([style.base,
        style.textfilter,
        inline && style.inline,
        stacked && style.stacked,
        advanced && style.advanced,
        white && style.white,
        classes.textfilter,
        className,
      ])}>
      {help &&
      <Row>
        <Col xs={12} className={style.header}>
          <div>{help}</div>
        </Col>
      </Row>}
      {fields.map((field) => {
        switch (field.type) {
          case 'autocomplete': {
            const render = field.renderValue
              ? field.renderValue
              : v => get(v, 'value', v);
            const options = !field.opts
              ? Autocomplete[field.value]
              : field.opts?.toJS?.() || field.opts;
            const value = options.find(v => get(v, 'value', v) === values.get(field.value, '')) || field.defaultValue || '';
            return (
              <MuiAutoComplete
                freeSolo={Boolean(field.freeSolo)}
                filterSelectedOptions
                key={field.value}
                value={value}
                options={options}
                loading={onMount && !options?.length}
                noOptionsText={field?.noOptionsText || 'No options'}
                className={cx(['autocomplete', style.autocomplete, active.includes(field.label) && 'active', field?.className])}
                name={`textfilter.${field.value}`}
                getOptionLabel={(option) => {
                  if (field.optionLabel) return field.optionLabel(option);
                  const label = get(option, 'label', option);
                  if (field.label === 'Notification Profile') return label;
                  return blurFunc && blurFields.includes(field.value)
                    ? `${blurFunc(
                      Text.Sentence(label.includes(':')
                        ? label.split(':').slice(1).join(':')
                        : label), blur)}`
                    : `${Text.Sentence(label.includes(':')
                      ? label.split(':').slice(1).join(':')
                      : label)}`;
                }}
                onBlur={(event) => {
                  if (field.freeSolo) onChange(field.value, render(event.target.value));
                  setActive(state => state.filter(v => v !== field.label));
                }}
                onFocus={() => setActive(state => [...state, field.label])}
                onChange={(event, val) => {
                  if (field?.onChange) field.onChange(field.value, val);
                  onChange(field.value, render(val));
                }}
                onInputChange={(event, val) => {
                  if (field.onInputChange) {
                    const debounced = debounce(field.onInputChange, 300);
                    debounced(val);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.label}
                    placeholder={field?.placeholder}
                    disabled={field.disabled}
                    InputProps={{
                      ...params.InputProps,
                      'data-testid': `textfilter.${field?.value}`,
                    }} />
                )}
                disabled={field.disabled}
                />
            );
          }
          case 'multiselect': {
            const render = field.renderValue
              ? field.renderValue
              : v => get(v, 'value', v);
            const options = fieldOpts(field);
            const value = values.get(field.value, '')
            ? computeValueArrayFromField(field)
            .filter(v => options.map(opt => opt.value || opt).includes(v.value || v))
            : [];

            return (
              <MuiAutoComplete
                key={field.label}
                multiple
                openOnFocus
                filterSelectedOptions
                value={value}
                options={options}
                className={cx(['multiselect', style.multiselect, active.includes(field.label) && 'active', field?.ClassName])}
                name={`textfilter.${field.value}`}
                getOptionLabel={(option) => {
                  if (field.optionLabel) return field.optionLabel(option);
                  const label = get(option, 'label', option);
                  return blurFunc && blurFields.includes(field.value)
                    ? `${blurFunc(
                      Text.Sentence(label.includes(':')
                        ? label.split(':').slice(1).join(':')
                        : label), blur)}`
                    : `${Text.Sentence(label.includes(':')
                      ? label.split(':').slice(1).join(':')
                      : label)}`;
                }}
                loading={onMount && !options?.length}
                getOptionSelected={(opt, val) => opt.value === val}
                onBlur={() => setActive(state => state.filter(v => v !== field.label))}
                onFocus={() => setActive(state => [...state, field.label])}
                onChange={(event, vals) => {
                  const input = vals.map(v => render(v));
                  const current = values.has(field.value) ? values.get(field.value, '').split(',') : [];
                  const selection = [
                    ...new Set(current
                      .filter(v => !options.map(_v => _v.value || _v).includes(v))
                      .concat(input),
                    )]
                    .filter(v => v)
                    .join();
                  if (field?.onChange) field.onChange(field.value, selection);
                  onChange(field.value, selection);
                }}
                onInputChange={(event, val) => {
                  if (field.onInputChange) {
                    const debounced = debounce(field.onInputChange, 300);
                    debounced(val);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.label}
                    placeholder={value.length === 0
                      ? (field.placeholder || field.nullable || 'Single or comma separated values. Press enter to add')
                      : ''}
                    helperText={stacked ? '' : field?.hint || ''}
                    InputProps={{
                      ...params.InputProps,
                      'data-testid': `textfilter.${field?.value}`,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    disabled={field.disabled} />
                  )}
                renderTags={field?.renderTags}
                disabled={field.disabled} />
            );
          }
          case 'chips': {
            const render = field.renderValue
              ? field.renderValue
              : v => get(v, 'value', v)
                .replace(/"/ig, '')
                .split(',')
                .map(_v => _v.trim())
                .join(',');
            const options = fieldOpts(field);
            const value = values.get(field.value, '')
            ? computeValueArrayFromField(field)
            : [];
            const defaultValue = value;

            return (
              <MuiAutoComplete
                key={field.value}
                multiple
                freeSolo
                selectOnFocus
                autoSelect
                name={`textfilter.${field.value}`}
                value={value}
                options={options || []}
                loading={onMount && !options?.length}
                className={cx([style.chips, 'chips', active.includes(field.value) && 'active', field?.className])}
                onFocus={() => setActive(state => [...state, field.value])}
                onBlur={() => setActive(state => state.filter(v => v !== field.value))}
                onChange={(event, vals) => {
                  const selection = vals.map(v => render(v)).join();
                  if (field?.onChange) field.onChange(field.value, selection);
                  onChange(field.value, selection);
                }}
                disabled={field.disabled}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.label}
                    helperText={stacked ? '' : `${field.hint
                      ? field.hint
                      : ''}`}
                    placeholder={stacked ? '' : `${field.placeholder
                      ? field.placeholder
                      : 'Single or comma separated values. Press enter to add'}`}
                    disabled={field.disabled}
                    InputProps={{
                      ...params.InputProps,
                      'data-testid': `textfilter.${field?.value}`,
                    }} />
                )}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                  <EditableChip
                    {...getTagProps({ index })}
                    inputLabel={field.label}
                    label={option}
                    onEdit={(newValue) => {
                      const selection = defaultValue;
                      selection[Number(index)] = render(newValue);
                      if (newValue === '') selection.splice(index, 1);
                      onChange(field.value, selection.join(','));
                    }}
                    disabled={field.disabled}
                    />
                ))} />
            );
          }
          case 'text': {
            return (
              <Row key={field.value}>
                <Col xs={12}>
                  <form
                    className={style.form}
                    onSubmit={(event) => {
                      event.preventDefault();
                      onSearch();
                    }}>
                    <FormControl
                      variant="outlined"
                      className={cx(['dropdown', active.includes(field.label) && 'active', field?.className, style.textInput])}>
                      <InputLabel>{field.label}</InputLabel>
                      <OutlinedInput
                        data-lpignore="true"
                        data-testid={`textfilter.${field?.value}`}
                        multiline={field.multiLine}
                        type={blur ? 'password' : 'text'}
                        name={`textfilter.${field.value}`}
                        value={values.has(field.value) ? values.get(field.value) : ''}
                        onFocus={() => setActive(state => [...state, field.label])}
                        onBlur={() => setActive(state => state.filter(v => v !== field.label))}
                        onChange={(event) => {
                          if (field?.onChange) {
                            field.onChange(field.value, event.target.value, event);
                          }
                          onChange(field.value, event.target.value, event);
                        }}
                        startAdornment={field.canExclude && (
                          <InputAdornment position="start">
                            <Icon
                              color="primary"
                              data-for="text.tooltip"
                              style={{ marginTop: '-.7rem' }}
                              data-tip={(values.get(`exclude_${field.value}`) === 'true') ? 'Exclude From Search' : 'Include in Search'}
                              onClick={() => onChange(`exclude_${field.value}`, values.get(`exclude_${field.value}`) ? undefined : 'true')}>
                              {values.get(`exclude_${field.value}`) === 'true' ? '[-]' : '[+]'}
                            </Icon>
                          </InputAdornment>)}
                        endAdornment={values.get(field.value) && (
                          <InputAdornment position="end">
                            <Icon
                              color="primary"
                              onClick={() => onChange(field.value, defaults.get(field.value) || '')}>
                              close
                            </Icon>
                          </InputAdornment>
                        )}
                        disabled={field.disabled}
                        inputProps={{ 'data-testid': `textfilter.${field?.value}` }} />
                      <FormHelperText>{field.hint}</FormHelperText>
                    </FormControl>
                  </form>
                </Col>
              </Row>);
          }
          case 'toggle':
            return (
              <Row key={field.value}>
                <Col xs={12} className={[style.toggle, field?.className].join(' ')}>
                  <FormControl name="blur">
                    <FormControlLabel
                      label={typeof field.label === 'function'
                        ? field.label(values.get(field.value))
                        : (field.label || '')}
                      control={
                        <Switch
                          name={`textfilter.${field.value}`}
                          checked={isToggled(field)}
                          disabled={asyncLoading ||
                            (field.disabled && field.disabled(values.get(field.value)))}
                          onChange={(event, v) => {
                            if (field?.onChange) field.onChange(field.value, v);
                            onChange(field.value, v);
                          }}
                          inputProps={{ 'data-testid': `textfilter.${field?.value}` }}/>} />
                  </FormControl>
                </Col>
              </Row>);
          case 'range':
            return (
              <Row key={field.value}>
                <Col xs={12} className={[style.range, field?.className].join(' ')}>
                  <div className={cx([style.label,
                      values.get(field.value) && style.changed])}>
                    {field.label}:
                  </div>
                  <Slider
                    min={field.min || 0}
                    max={field.max || 10}
                    step={field.step || 0.1}
                    value={values.get(field.value)
                      ? values.get(field.value).split(',').map(v => +v)
                      : [fields.min || 0, fields.max || 10]}
                    name={`textfilter.${field.value}`}
                    valueLabelDisplay="auto"
                    onChange={(_, v) => {
                      if (field?.onChange) field.onChange(field.value, v.join());
                      onChange(field.value, v.join());
                    }}
                    disabled={field.disabled}
                    data-testid={`textfilter.${field?.value}`} />
                </Col>
              </Row>);
          case 'checkbox':
            return (
              <FormControl key={[field.label, field?.className].join(' ')}>
                <FormLabel>{field.label}</FormLabel>
                {field.opts.map(opt => (
                  <FormControlLabel
                    key={field?.key ? opt.get(field.key) : opt.get('value')}
                    label={opt.get('label')}
                    labelPlacement="end"
                    control={<Checkbox
                      checked={values.get(field.value) === opt.get('value')}
                      onChange={(event) => {
                        const v = event.target.checked;
                        const falsey = v && opt.get('value') === 'false' ? 'false' : '';
                        const value = v && opt.get('value') === 'true' ? 'true' : falsey;
                        if (field?.onChange) field.onChange(field.value, value);
                        onChange(field.value, value);
                      }}
                      disabled={field.disabled}
                      data-testid={`textfilter.${field?.value}`}
                    />}
                  />
                ))}
              </FormControl>
            );
          case 'checkboxes': {
            return (
              <FormControl
                key={field.label}
                variant="outlined"
                className={[style.checkboxes, field?.className].join(' ')}
                style={{
                  display: !field.flex ? 'block' : 'inline-block',
                  width: field.width,
                  ...field.style,
                }}>
                <FormLabel>{field.label}</FormLabel>
                <FormGroup row>
                  {field.opts.map((opt) => {
                  if (typeof opt === 'string') {
                    return (
                      <FormControlLabel
                        key={opt}
                        label={Text.Sentence(opt.split(':').slice(-1).join())}
                        control={
                          <Checkbox
                            checked={Boolean(values.get(field.value) &&
                              values.get(field.value).split(',').includes(opt))}
                            onChange={(event) => {
                              const { checked } = event.target;
                              const options = values
                                .get(field.value, '')
                                .split(',')
                                .filter(v => (checked ? true : v !== opt))
                                .concat(checked ? opt : [])
                                .filter(v => v)
                                .join();
                              if (field?.onChange) field.onChange(field.value, options);
                              onChange(field.value, options);
                            }}
                            disabled={field.disabled}
                            data-testid={`textfilter.${field?.value}`} />
                        }
                        disabled={field.disabled} />
                    );
                  } else if (!opt.has('children') && !opt.get('useNested')) {
                    return (
                      <FormControlLabel
                        key={opt.get('label', opt.get('id', ''))}
                        label={Text.Sentence(opt.get(get(field, 'key', 'label')))}
                        control={<Checkbox
                          checked={Boolean(values.get(field.value) &&
                            values.get(field.value).split(',').includes(opt.get(get(field, 'valueKey', 'value'))))}
                          onChange={(event) => {
                            const { checked } = event.target;
                            const options = [...new Set(values
                              .get(field.value, '')
                              .split(',')
                              .filter(v => (checked ? true : v !== opt.get('value')))
                              .concat(checked ? opt.get('value') : [])
                              .filter(v => v))]
                              .join();
                            if (field?.onChange) field.onChange(field.value, options);
                            onChange(field.value, options);
                          }}
                          data-testid={`textfilter.${field?.value}`} />}
                        disabled={field.disabled} />
                      );
                  }
                  return (
                    <NestedCheckbox
                      parent={opt}
                      key={opt.get(field.valueKey, 'id')}
                      options={opt.get('children')}
                      values={fromJS((values
                        .get(field.value) || '')
                        .split(','))}
                      onChange={(groupState) => {
                        let changes = values.get(field.value) || '';
                        groupState.forEach((gv, gk) => {
                          changes = changes
                            .split(',')
                            .filter(v => ((v !== gk)
                              ? true
                              : gv))
                            .concat(gv ? gk : [])
                            .filter(v => v)
                            .join();
                        });
                        if (field?.onChange) field.onChange(field.value, changes);
                        onChange(field.value, changes);
                      }} />
                  );
                })}
                </FormGroup>
              </FormControl>
            );
          }
          case 'dropdown':
            return (
              <FormControl
                key={field.value}
                variant="outlined"
                className={cx(['dropdown', active.includes(field.label) && 'active', field?.className])}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  name={`textfilter.${field.value}`}
                  disabled={field.disabled === true || (field.disabled &&
                    field.disabled(values))}
                  value={!values.has(field.value) ? ((field.default && field.opts.find(v => v.get('value') === field.default)) || field?.opts?.get(0)) : field.opts.find(v => v.get('value') === values.get(field.value))}
                  onOpen={() => setActive(state => [...state, field.label])}
                  onClose={() => setActive(state => state.filter(v => v !== field.label))}
                  onChange={(event) => {
                    if (field?.onChange) field.onChange(field.value, event.target.value.get('value'));
                    onChange(field.value, event.target.value.get('value'));
                  }}
                  renderValue={field?.renderValue}
                  inputProps={{ 'data-testid': `textfilter.dropdown.${field?.value}` }}>
                  {field?.opts?.sortBy(v => field.sort && v.get('label'))
                    .map(v => (
                      <ListItem
                        key={field?.key ? v.get(field.key) : v.get('label')}
                        value={v}
                        data-testid={`textfilter.dropdown.${field.value}.${v.get('label')}`}
                        name={`textfilter.source.${v.get('label')}`}
                        className={cx([style.item, `${v.get('label')}`, v.get('value') === values.get(field.value) && style.active])}>
                        {blurFunc && blurFields.includes(field.value)
                          ? blurFunc((v.get('label') || '').split(':').slice(-1).join(), blur)
                          : (v.get('label') || '').split(':').slice(-1).join()}
                      </ListItem>))}
                </Select>
                <FormHelperText>{field.hint}</FormHelperText>
              </FormControl>
            );
          case 'phash':
            return (
              <Row key={field.label} className={[style.phash, field?.className].join(' ')}>
                <Col xs={12}>
                  {values.get(field.value) &&
                  <p>Phash: {values.get(field.value)}</p>}
                  <ImagePhashFilter
                    onLoadImage={hash => onChange(field.value, hash)} />
                </Col>
              </Row>
            );
          default: return null;
        }
      })}
      {toggle &&
      <Row>
        <Col xs={12} className={cx([style.footer, advanced && style.advanced])}>
          <div>
            {loading && <CircularProgress />}
          </div>
          <div className={style.actions}>
            <Button
              name="textfilter.reset"
              className={style.left}
              onClick={() => onReset()}
              data-testid="textfilter.reset">
              Reset
            </Button>
            <Button
              name="textfilter.cancel"
              onClick={() => toggle()}
              data-testid="textfilter.cancel">
              Cancel
            </Button>
            <Button
              name="textfilter.apply"
              className={style.active}
              onClick={() => onSearch()}
              data-testid="textfilter.apply">
              Apply
            </Button>
          </div>
        </Col>
      </Row>}
      <ReactTooltip id="text.tooltip" html place="left" effect="solid" />
    </Grid>
  );
};

TextFilter.propTypes = {
  advanced: PropTypes.bool,
  blur: PropTypes.bool,
  blurFields: PropTypes.array,
  blurFunc: PropTypes.func,
  defaults: PropTypes.object,
  fields: PropTypes.array,
  filters: PropTypes.object,
  help: PropTypes.string,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  loading: PropTypes.bool,
  onApply: PropTypes.func,
  onFilter: PropTypes.func,
  onMount: PropTypes.func,
  stacked: PropTypes.bool,
  testId: PropTypes.string,
  text: PropTypes.string,
  toggle: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.object,
  white: PropTypes.bool,
  /** Adds a CSS class to the TextFilter's container element */
  className: PropTypes.string,
};

TextFilter.defaultProps = {
  advanced: false,
  blur: false,
  blurFields: [],
  blurFunc: null,
  defaults: map(),
  fields: map(),
  filters: map(),
  help: '',
  icon: '',
  inline: false,
  loading: false,
  onApply: null,
  onFilter: null,
  onMount: null,
  stacked: false,
  testId: undefined,
  text: '',
  toggle: null,
  type: '',
  user: map(),
  white: false,
  className: '',
};

export default TextFilter;
