import React from 'react';
import PropTypes from 'prop-types';

import { useRecoilState } from 'recoil';
import { Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';

import PersonSearchTextField from './PersonSearchTextField';
import ThemeTooltip from './ThemeTooltip';
import PersonSearchState from '../../../stores/recoil/personSearch';

const useStyles = makeStyles(() => ({
  inputLabels: {
    fontSize: '16px',
    margin: '0 5px 5px 0',
  },
  tooltipIcon: {
    height: '18px',
    width: '18px',
    marginBottom: '5px',
  },
}));

const PageTwo = ({
  pageValidationError,
  setTextFieldValidationError,
  validationError,
}) => {
  const classes = useStyles();
  const [ageFrom, setAgeFrom] = useRecoilState(PersonSearchState.ageFrom);
  const [ageTo, setAgeTo] = useRecoilState(PersonSearchState.ageTo);
  const [connection, setConnection] = useRecoilState(PersonSearchState.connection);
  const [domain, setDomain] = useRecoilState(PersonSearchState.domain);
  const [incidentDetails, setIncidentDetails] = useRecoilState(PersonSearchState.incidentDetails);
  const [ipAddress, setIpAddress] = useRecoilState(PersonSearchState.ipAddress);
  const [job, setJob] = useRecoilState(PersonSearchState.job);
  const [school, setSchool] = useRecoilState(PersonSearchState.school);

  const ageRangeTooltip = 'Enter an age range. The lowest possible age is 18, the highest is 100. Both fields are required.';
  const connectionTooltip = 'A connection is a friend, family member, or colleague. If you have more than one connection, separate them with a comma.';
  const domainTooltip = 'Enter a valid domain. If you have more than one domain, separate them with a comma.';
  const ipAddressTooltip = 'Enter a valid IP Address. If you have more than one IP Address, separate them with a comma.';
  const jobTooltip = 'Enter the person’s job title. If you have more than one job, separate them with a comma.';
  const schoolTooltip = 'Enter the person’s high school or college name.If you have more than one school, separate them with a comma.';

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography style={{ marginBottom: '1.5rem' }} variant="subtitle1">To learn more, please visit the
          <Link
            href="https://docs.flashpoint.io/flashpoint/docs/person-search-input-form-best-practices"
            target="_blank"
            >
            Person Search Best Practices Guide.
          </Link>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          If you have other information, such as an IP address or Facebook ID,
          you may add them below:
        </Typography>
      </Grid>
      {pageValidationError && validationError()}
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>IP Address</Typography>
            <ThemeTooltip title={ipAddressTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <PersonSearchTextField formField="ipAddress" setTags={setIpAddress} setTextFieldValidationError={setTextFieldValidationError} tags={ipAddress}/>
        </Grid>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>Domain</Typography>
            <ThemeTooltip title={domainTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <PersonSearchTextField formField="domain" setTags={setDomain} setTextFieldValidationError={setTextFieldValidationError} tags={domain}/>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>Age Range</Typography>
            <ThemeTooltip title={ageRangeTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <Grid style={{ display: 'flex' }}>
            <PersonSearchTextField formField="ageFrom" placeholder="20" setTags={setAgeFrom} setTextFieldValidationError={setTextFieldValidationError} tags={ageFrom} xs={3}/>
            <Typography style={{ padding: '0 5px' }} variant="h6">-</Typography>
            <PersonSearchTextField formField="ageTo" placeholder="40" setTags={setAgeTo} setTextFieldValidationError={setTextFieldValidationError} tags={ageTo} xs={3}/>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>Connection</Typography>
            <ThemeTooltip title={connectionTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <PersonSearchTextField formField="connection" setTags={setConnection} setTextFieldValidationError={setTextFieldValidationError} tags={connection}/>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>Job</Typography>
            <ThemeTooltip title={jobTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <PersonSearchTextField formField="job" setTags={setJob} setTextFieldValidationError={setTextFieldValidationError} tags={job}/>
        </Grid>
        <Grid item xs={6}>
          <Grid alignItems="center" container>
            <Typography className={classes.inputLabels}>School</Typography>
            <ThemeTooltip title={schoolTooltip}>
              <HelpIcon className={classes.tooltipIcon} color="primary"/>
            </ThemeTooltip>
          </Grid>
          <PersonSearchTextField formField="school" setTags={setSchool} setTextFieldValidationError={setTextFieldValidationError} tags={school}/>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Typography variant="h6">Incident Details</Typography>
        <TextField
          multiline
          name="incident_details"
          onChange={e => setIncidentDetails(e.target.value)}
          rows={5}
          variant="outlined"
          value={incidentDetails}/>
      </Grid>
    </Grid>
  );
};

PageTwo.propTypes = {
  pageValidationError: PropTypes.bool.isRequired,
  setTextFieldValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.func.isRequired,
};

export default PageTwo;
