import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';

import style from './imagePhashFilter.module.scss';
import phash from '../../utils/phash';

const ImagePhashFilter = ({ onLoadImage }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        const hashPromise = phash(binaryStr);
        hashPromise.then((img) => {
          onLoadImage(img);
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  return (
    <Grid
      fluid
      className={cx([style.base, style.phash])}>
      <Row>
        <Col xs={12}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div>
                <div {...getRootProps()}>
                  <input {...getInputProps()} data-testid="imagephashfilter" />
                  <p><b>Reverse Image Search</b></p>
                  <p>Drag 'n' drop an image, or click to select</p>
                  <div className={style.disclaimer}>Note: Flashpoint does not
                    upload or store the image dropped here, all processing is
                    done in the browser and then we send a fuzzy hash of the
                    image to run the search.
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>
    </Grid>
  );
};

ImagePhashFilter.propTypes = {
  onLoadImage: PropTypes.func,
};

ImagePhashFilter.defaultProps = {
  onLoadImage: null,
};

export default ImagePhashFilter;
