import Api from '../../utils/api';

const SalesForceUri = '/ui/v4/sail';
const GetUri = '/accounts';
const PostUri = '/rfis';

const Query = {
  getFormData: sfId => (
    Api.get(`${SalesForceUri}${GetUri}/${sfId}`)
      .then(res => res.data)
  ),
  postForm: data => Api.post(`${SalesForceUri}${PostUri}`, data, [200, 201, 204]),
  postFile: (id, file) => {
    const form = new FormData();
    form.append('infile', file);
    return Api.post(`${SalesForceUri}${PostUri}/${id}/attachments`, form, [200, 201, 204]);
  },
};

export const Organizations = async () => Api
  .get('/ui/v4/organizations/')
  .then(res => (res.ok ? res.data : []))
  .then(res => res.filter(v => v?.salesforce_id))
  .catch(() => ([]));

export default Query;
