import Api from '../../../utils/api';

import createVtStyles from './style';

const modifyInnerHtml = (htmlStr) => {
  // create a temp DOM tree so we can edit with JS methods
  const tempEl = document.createElement('html');
  tempEl.innerHTML = htmlStr;

  tempEl.querySelectorAll('link[rel="stylesheet"]').forEach((el) => {
    el.remove();
  });

  tempEl.querySelectorAll('style[data-themed]').forEach((el) => {
    el.remove();
  });

  const style = document.createElement('style');
  style.textContent = createVtStyles();
  tempEl.childNodes[0].appendChild(style);

  const iffe = document.createElement('script');
    iffe.innerHTML = `(function() { 
      const parent = window.parent;
      window.addEventListener('click', (e) => {
        const button = document.querySelector('button.vt-close-widget');
        if (e.target == button || button.contains(e.target)) {
          parent.postMessage('vtCloseWidget', e.origin);
        }
      });
    })();`;

  tempEl.childNodes[0].appendChild(iffe);
  //
  // convert back to raw string for iframe 'srcdoc' attr.
  const htmlRawStr = tempEl.innerHTML;
  return htmlRawStr;
};

const fetchVtHtmlUrl = (basetypeValue, setAlert) => Api.get(
    '/api/virustotal/api/v3/widget/url',
    { query: basetypeValue },
    [200],
    30000,
  ).then(res => res.data.data.url)
  .catch(err => setAlert(err.message));

const fetchVtHtml = url => Api.get(url)
  .then(res => res.data)
  .then(htmlStr => modifyInnerHtml(htmlStr));

export default {
  fetchVtHtmlUrl,
  fetchVtHtml,
};
