import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { List as list } from 'immutable';
import { Grid, Row, Col } from 'react-flexbox-grid/lib';
import {
  CircularProgress,
  Paper,
} from '@mui/material';

import style from './glossary.module.scss';

const Glossary = ({ anchor, data }) => {
  const keys = ['0-9']
    .concat([...Array(26)]
      .map((_, i) => String.fromCharCode('A'.charCodeAt(0) + i)));

  useEffect(() => {
    if (!document.getElementById(anchor)) return;
    const el = document.getElementById(anchor);
    el.scrollIntoView();
    window.scrollBy(0, -100);
  }, [anchor, data]);

  return (
    <Grid
      fluid
      name="component.glossary"
      className={cx([style.base, style.glossary])}>
      <Row>
        <Col xs={12} className={style.header}>
          <div>
            {keys.map(v => (
              <Link
                key={v}
                className={cx([style.h4, 'h4', style.mont, 'mont'])}
                to={`/home/resources/glossary/${v}`}>{v}
              </Link>))}
          </div>
        </Col>
        <Col xs={12}>
          {data.isEmpty() && <CircularProgress />}
          {!data.isEmpty() && keys.map(key => (
            <div key={key}>
              <div id={key} className={cx([style.h0, 'h0', style.raj, 'raj'])}>{key}</div>
              {data
                .filter((v) => {
                  const startIndex = (v.get('key').indexOf('<strong>') === -1 && v.get('key').indexOf('<strong>') !== 0) ? 0 : 8;
                  const firstChar = v.get('key').substr(startIndex, 1);
                  if (key === '0-9') {
                    return !Number.isNaN(parseFloat(firstChar));
                  }
                  return firstChar.toLowerCase().startsWith(key.toLowerCase());
                })
                .sortBy(v => v.get('key').toLowerCase())
                .map((v) => {
                  const div = document.createElement('div');
                  div.innerHTML = v.get('key');
                  const text = div.textContent.trim() || div.innerText.trim() || '';
                  return (
                    <Paper key={text} className={style.card} id={text}>
                      <div className={cx([style.h2, 'h2', style.mont, 'mont', style.cap, 'cap'])} dangerouslySetInnerHTML={{ __html: v.get('key') }} />
                      <div dangerouslySetInnerHTML={{ __html: v.get('value') }} />
                    </Paper>);
                })}
            </div>))}
        </Col>
      </Row>
    </Grid>
  );
};

Glossary.propTypes = {
  anchor: PropTypes.string,
  data: PropTypes.object,
};

Glossary.defaultProps = {
  anchor: '',
  data: list(),
};

export default Glossary;
