import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Map as map, List as list, fromJS } from 'immutable';

import DetailsColumn from './DetailsColumn/DetailsColumn';
import Query from './query';
import Messages from '../../../constants/Messages';
import MessageColumn from './MessageColumn/MessageColumn';
import SearchActions from '../../../actions/searchActions';

const useStyles = makeStyles({
  container: {
    padding: '0 50px 0 50px',
  },
  progress: {
    margin: '2rem',
  },
});

const DomainMonitoring = ({ id, user }) => {
  const [loading, setLoading] = useState(true);
  const [incident, setIncident] = useState(map());
  const [messages, setMessages] = useState(list());
  const [limitReached, setLimitReached] = useState(true);
  const classes = useStyles();

  const fifteenMinutes = 1000 * 60 * 15;

  useEffect(() => {
    SearchActions.loadResources();
    Query.getIncident(id)
      .then((incidentResult) => {
        setIncident(current => current.merge(fromJS(incidentResult)));
        setLoading(false);
      })
      .catch(() => {
        SearchActions.set(['search', 'info', 'message'], Messages.IncidentFetchError);
      });
    Query.getMessages(id).then(result => setMessages(fromJS(result)));
    Query.getBrandLimits(user.get('sf_id'))
      .then(res => setLimitReached((res?.takedown_count >= res?.takedown_limit)))
      .catch(() => {
        SearchActions.set(['search', 'info', 'message'], Messages.DomainMessageFetchError);
      });
  }, []);

  useEffect(() => {
    // Poll for messages every 15 min
    const interval = setInterval(() => {
      Query.getMessages(id)
        .then(result => setMessages(fromJS(result)))
        .catch(() => {
          clearInterval(interval);
          SearchActions.set(['search', 'info', 'message'], Messages.DomainMessageFetchError);
        });
    }, fifteenMinutes);

    return () => clearInterval(interval);
  }, []);

  const hasMessages = messages && messages.size > 0;
  const detailsWidth = 7;

  return (
    <Grid container spacing={3} alignItems="flex-start" className={classes.container}>
      {
        loading
          ? <CircularProgress size={25} thickness={2} className={classes.progress} />
          : <DetailsColumn incident={incident} width={detailsWidth} limitReached={limitReached} />
      }
      {
        hasMessages
          ? <MessageColumn
              incidentId={incident.get('id')}
              messages={messages}
              user={user}
            />
          : ''
      }
    </Grid>
  );
};

DomainMonitoring.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
};

DomainMonitoring.defaultProps = {
  id: '',
  user: {},
};

export default DomainMonitoring;
