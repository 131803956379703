import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBell,
  faBuildingColumns,
  faChartMixed,
  faClipboardList,
  faColumns3,
  faCompass,
  faGraduationCap,
  faLockKeyhole,
  faNetworkWired,
  faRadiation,
  faShieldCheck,
} from '@flashpoint/fp-icons/pro-regular-svg-icons';

library.add(
  faBell,
  faBuildingColumns,
  faChartMixed,
  faClipboardList,
  faColumns3,
  faCompass,
  faGraduationCap,
  faLockKeyhole,
  faNetworkWired,
  faRadiation,
  faShieldCheck,
);
