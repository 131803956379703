import React from 'react';
import PropTypes from 'prop-types';

import { Col, Grid, Row } from 'react-flexbox-grid/lib';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    borderRadius: '2px !important',
    boxShadow: '0 0 6px rgba(0, 0, 0, .12), 0 0px 4px rgba(0, 0, 0, .24) !important',
    padding: '20px',
    margin: '24px 0',
  },
  row: {
    borderRadius: '2px !important',
    boxShadow: '0 0 2px rgba(0, 0, 0, .12), 0 0px 2px rgba(0, 0, 0, .24) !important',
    margin: '8px 0',
    padding: '12px',
  },
  rowTitle: {
    boxShadow: '0px 1px rgba(0, 0, 0, .24) !important',
    fontSize: '14px',
    fontWeight: '75',
    marginBottom: '6px',
  },
  subHeaders: {
    margin: '0',
    fontSize: '24px',
    fontWeight: '100',
  },
  criminalHistory: {
    padding: '6px',
  },
  containerHeader: {
    fontWeight: 'bold',
  },
});

const ClearDataComponent = ({
  result,
  resultLength,
  resultNumber,
  searchParamMatch,
}) => {
  const classes = useStyles();
  const dominantValues = result?.DominantValues['ns3:PersonDominantValues'];
  const recordDetails = result?.RecordDetails['ns3:PersonResponseDetail'];
  const { AgeInfo } = dominantValues;

  const renderAddress = (values) => {
    if (values.City) {
      return (
        <Row>{`${values.Street || ''} ${values.City || ''} ${values.State || ''} ${values.ZipCode || ''}`}</Row>
      );
    }
    if (values.length) {
      return values.map(v => (
        <Row between="xs">
          <Col>{`${v.Address.Street || ''} ${v.Address.City || ''} ${v.Address.State || ''} ${v.Address.ZipCode || ''}`}</Col>
          <Col>{v.Address.ReportedDate || 'N/A'}</Col>
        </Row>
      ));
    }
    return null;
  };

  const renderMatchedBy = () => {
    const { city,
      first_name,
      middle_initial,
      last_name,
      state,
      street,
      zip_code,
    } = searchParamMatch;
    const groupedMatches = ['city', 'country', 'state', 'zip_code', 'street', 'first_name', 'last_name', 'middle_initial'];
    const matchedLocation = `${street || ''} ${city || ''} ${state || ''} ${zip_code || ''}`;
    const matchedName = `${first_name || ''} ${middle_initial || ''} ${last_name || ''}`;
    const nonGroupedMatches = Object.entries(searchParamMatch)
      .filter(v => !groupedMatches.includes(v[0]))
      .map(v => v[1])
      .toString();
    const matchedString = [matchedName, matchedLocation, nonGroupedMatches].filter(v => v.trim().length).toString().replace(/,\s*$/, '');
    return matchedString;
  };

  const renderName = () => {
    const { Name } = dominantValues;
    return `${Name?.FirstName || ''} ${Name?.MiddleName || ''} ${Name?.LastName || ''}`;
  };

  const renderPhoneNumbers = (numbers) => {
    const phoneNumbers = [];
    Object.values(numbers).map(v => phoneNumbers.push(v?.PhoneNumber));
    return phoneNumbers.map(v => <Row>{v}</Row>);
  };

  const hasHuntingLicense = () => {
    let huntingLicenseStatus = 'no';
    const huntingLicenseTypes = ['BIG GAME', 'SMALL GAME', 'SPORTSMAN', 'DEER', 'TURKEY', 'WATERFOWL'];
    const huntingLicense = Object.entries(recordDetails?._SourceDocuments)
      .filter(v => v[1].DocumentType === 'HuntFishLicenseRecord');

    huntingLicense.map((v) => {
      const permits = v[1].HuntFishPermit?.PermitClass;
      if (!permits) return null;
      if (typeof permits === 'string' && huntingLicenseTypes.includes(permits)) huntingLicenseStatus = 'yes';
      if (Array.isArray(permits) && permits.find(k => huntingLicenseTypes.includes(k))) huntingLicenseStatus = 'yes';
      return huntingLicenseStatus;
    });
    return huntingLicenseStatus;
  };

  const renderCriminalRecords = () => {
    const documentSourceTypes = ['ArrestRecord', 'CriminalRecord', 'CriminalExpansionRecord', 'FirearmsExplosivesLicenseRecord'];
    const criminalRecords = Object.entries(recordDetails?._SourceDocuments)
      .filter(v => documentSourceTypes.includes(v[1].DocumentType));

    return criminalRecords.map((v) => {
      const crimeState = v[1].SourceState;
      const crimeCounty = v[1].SourceCounty;

      if (v[1]?.DocumentType === 'ArrestRecord') {
        const arrestDate = v[1].ArrestInfo?.ArrestDate;
        const arrestCounty = v[1].ArrestInfo?.CountyOfCrime;
        const criminalOffense = v[1].CriminalCharges?.CriminalOffense;
        return (
          <Row className={classes.criminalHistory}>
            <Col>
              <Row className={classes.containerHeader}>Arrest Record</Row>
              {criminalOffense && <Row>Criminal Offense: {criminalOffense}</Row>}
              {arrestDate && <Row>Date: {arrestDate}</Row>}
              {crimeState && <Row>State: {crimeState}</Row>}
              {arrestCounty && <Row>County: {arrestCounty}</Row>}
            </Col>
          </Row>
        );
      }

      if (v[1]?.DocumentType === 'CriminalRecord') {
        const caseNumber = v[1].CourtInfo?.CaseInfo?.CaseNumber;
        const caseFiledDate = v[1].CourtInfo?.CaseInfo?.CaseFiling?.FiledDate;
        const courtName = v[1].CourtInfo?.CourtName;

        return (
          <Row className={classes.criminalHistory}>
            <Col>
              <Row className={classes.containerHeader}>Criminal Record</Row>
              {caseNumber && <Row>Case Number: {caseNumber}</Row>}
              {caseFiledDate && <Row>Case Filed Date: {caseFiledDate}</Row>}
              {courtName && <Row>Court Name: {courtName}</Row>}
              {crimeState && <Row>State: {crimeState}</Row>}
              {crimeCounty && <Row>County: {crimeCounty}</Row>}
            </Col>
          </Row>
        );
      }

      if (v[1]?.DocumentType === 'CriminalExpansionRecord') {
        const criminalOffense = v[1].OffenderInfo?.CriminalOffense;
        const caseNumber = v[1].OffenderInfo?.CaseInfo?.CaseNumber;
        const crimeDate = v[1].OffenderInfo?.CrimeDate;

        if (v[1].OffenderInfo instanceof Array) {
          return v[1].OffenderInfo.map(k => (
            <Row className={classes.criminalHistory}>
              <Col>
                <Row className={classes.containerHeader}>Criminal Record</Row>
                {k.CriminalOffense && <Row>Offense: {k.CriminalOffense}</Row>}
                {crimeState && <Row>State: {crimeState}</Row>}
                {k.CourtCounty && <Row>County: {k.CourtCounty}</Row>}
              </Col>
            </Row>));
        }
        return (
          <Row className={classes.criminalHistory}>
            <Col>
              <Row className={classes.containerHeader}>Criminal Record</Row>
              {criminalOffense && <Row>Offense: {criminalOffense}</Row>}
              {caseNumber && <Row>Case Number: {caseNumber}</Row>}
              {crimeDate && <Row>Date: {crimeDate}</Row>}
              {crimeState && <Row>State: {crimeState}</Row>}
              {crimeCounty && <Row>County: {crimeCounty}</Row>}
            </Col>
          </Row>
        );
      }
      return null;
    });
  };

  return (
    <Grid className={classes.root}>
      <Col>
        <Row between="xs" style={{ padding: '5px' }}>
          <Col>{!isEmpty(searchParamMatch) && <Typography variant="caption">Matched by: {renderMatchedBy()}</Typography>}</Col>
          <Col><Typography align="right" variant="caption">{resultNumber} of {resultLength}</Typography></Col>
        </Row>
        <Row className={classes.row}>
          <Col>
            <Typography variant="h6">{renderName()}</Typography>
            {AgeInfo?.PersonAge && <Typography display="block" variant="caption">Age: {AgeInfo.PersonAge}</Typography>}
            {AgeInfo?.PersonBirthDate && <Typography variant="caption">DOB: {AgeInfo.PersonBirthDate}</Typography>}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={12}>
            <Row className={classes.rowTitle}>Address History</Row>
            {dominantValues.Address && renderAddress(dominantValues.Address)}
            {recordDetails.KnownAddresses && renderAddress(recordDetails.KnownAddresses)}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={12}>
            <Row className={classes.rowTitle}>Phone Numbers</Row>
            {recordDetails?.AdditionalPhoneNumbers &&
              (renderPhoneNumbers(recordDetails.AdditionalPhoneNumbers))}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={12}>
            <Row className={classes.rowTitle}>Email Addresses</Row>
            {typeof recordDetails.EmailAddress === 'string' &&
              <Row>{recordDetails?.EmailAddress}</Row>}
            {recordDetails.EmailAddress && typeof recordDetails.EmailAddress !== 'string' &&
              Object.values(recordDetails.EmailAddress).map(v => <Row>{v}</Row>)}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={12}>
            <Row className={classes.rowTitle}>Criminal History</Row>
            {renderCriminalRecords()}
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={12}>
            <Row className={classes.rowTitle}>Firearm Possession</Row>
            <Row>Hunting License: {hasHuntingLicense()}</Row>
          </Col>
        </Row>
      </Col>
    </Grid>
  );
};

ClearDataComponent.propTypes = {
  result: PropTypes.object.isRequired,
  resultLength: PropTypes.number.isRequired,
  resultNumber: PropTypes.number.isRequired,
  searchParamMatch: PropTypes.object.isRequired,
};

export default ClearDataComponent;
